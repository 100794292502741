import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  announcementStatus,
  makeActionInDynamicForm,
} from "../redux/actions/loginDetailsActions";
import { withRouter } from "react-router";
import { DYNAMIC_FORM_URL } from "../config/keycloak-config";

const FormModel = ({ slug = "yuukke", userEmail = "NOT PRESENT" }) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const onHidePopup = () => {
    if (!loading) {
      setOpen(false);
      dispatch(makeActionInDynamicForm(null));
    }
  };
  const handleLoad = () => {
    setLoading(false);
  };
  const handleError = () => {
    alert("error");
  };
  return (
    <div>
      <Modal
        show={open}
        onHide={() => {}}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          <AiOutlineCloseCircle
            onClick={onHidePopup}
            className="mr-2 mt-2"
            style={{
              cursor: "pointer",
              float: "right",
              marginBottom: "8px",
              width: "20px",
              height: "20px",
              color: "#000000",
            }}
          />
        </div>

        <Modal.Body
          style={{ textAlign: "center" }}
          className="d-flex align-items-center flex-column text-center"
        >
          {loading && (
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
          <iframe
            onLoad={handleLoad}
            onError={handleError}
            title="Yuukke"
            src={`${DYNAMIC_FORM_URL}?form=${slug}&email=${userEmail}`}
            frameborder="0"
            style={{
              overflow: "scroll",
              height: "100vh",
              width: "100%",
              display: loading ? "none" : "block",
            }}
            height="100%"
            width="100%"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default withRouter(FormModel);
