import { Container } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router";
import LoadService from "../utils/LoadService";

class RedirectPage extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    LoadService.load(this.props.history);
  }

  render() {
    return (
      <Container>
        <div>Please wait a moment... App</div>
      </Container>
    );
  }
}

export default withRouter(RedirectPage);
