 
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import AuthService from "../../../session/AuthService";
import { Button, Form, Modal, InputGroup, FormControl } from "react-bootstrap";
import badges_hand from "../../../images/badge/Group 12770@2x.png";
import FriendsFinder from "../../../api/FriendsFinder";
import { connect } from "react-redux";
import { FiTwitter } from "react-icons/fi";
import { FiFacebook } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";
import { Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";

class FriendUserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addInterestState: false,
      file: "",
      image: "",
      coverPhotoState: false,
      friendId: "",
      profession: "",
      areaOfInterests:[],
      professionDatas: [
        {
          id: 1,
          name: "student",
        },
        {
          id: 2,
          name: "Working professional",
        },
        {
          id: 3,
          name: "practicing profession (Auditoer,lawyer and etc)",
        },
        {
          id: 4,
          name: "Entreprenur",
        },
        {
          id: 5,
          name: "Homemaker",
        },
        {
          id: 6,
          name: "Freelancer",
        },
      ],
    };

    this.unFriend = this.unFriend.bind(this);
    this.unFriendId = this.unFriendId.bind(this);
    this.UnFriendhandleClose = this.UnFriendhandleClose.bind(this);
    this.popUpUnfriend = this.popUpUnfriend.bind(this);
 
  }
  componentDidMount() {
 
     this.setState({
      areaOfInterests:this.props.areaOfInterest
     })
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
 
    console.log("this.state.areaOfInterests",this.state.areaOfInterests)
  }
 
  render() {
    const { name, coverPhoto, profilePhoto, designation, additionalInfo, pointsCount, userId, facebook,
      companyName, gender, personalLanguage, dob, linkedin, twitter, industry, jobFunctions, areaOfInterest,city,
      firstName, lastName, State, Region, biography, title,profession,
    } =
      this.props;
      const{t}=this.props;
     // alert(areaOfInterest)
    return (

      <div className="peopleCardWrapperGrouppopup">
        <img className="bannerImgPOpup" src={coverPhoto} alt={name}></img>
        <div className="w3-row">
          <div className="w3-col w3-container w3-green">
            <div>
              <img style={{ cursor: "pointer" }} className="userImg" src={profilePhoto} alt={name} />
            </div>
          </div>
          <Grid container spacing={1} style={{ padding: "6px" }}>

<Grid item md={5} sm={3} xs={3} lg={5}></Grid>
<Grid item md={7} sm={9} xs={9} lg={7}><p style={{ fontWeight: "bold",color: "#000000", fontSize: "15px",   }}>{name}</p></Grid>

 
</Grid>
<Grid container spacing={1} style={{ padding: "1px" }}>
           
              <Grid item md={5} sm={4} xs={4} lg={5} ></Grid>
              <Grid item md={1} sm={1} xs={1} lg={1} >
             {!twitter == "" && <div tabindex="1">
               <NavLink
                 className=""
                 to={""}
                 exact={true}
                 activeClassName="active"
                 onClick={() => window.open(twitter, "_blank")}

               >
                 <FiTwitter style={{ cursor: "pointer", width: "23px", height: "26px", color: "#F97516" }}
                 />


               </NavLink>
             </div>}
             </Grid>
             <Grid  item md={1} sm={1} xs={1} lg={1}    >
             {!facebook == "" && <div tabindex="1">
               <NavLink
                 className=""
                 to={""}
                 exact={true}
                 activeClassName="active"
                 onClick={() => window.open(facebook, "_blank")}

               >
               
                 <FiFacebook style={{ cursor: "pointer", width: "23px", height: "26px", color: "#F97516" }}
                 />
                     </NavLink>
                   </div>}
                   </Grid>
                 <Grid  item md={1} sm={1} xs={1} lg={1}   >
                 {!linkedin == "" && <div tabindex="1">
                   <NavLink
                     className=""
                     to={""}
                     exact={true}
                     activeClassName="active"
                     onClick={() => window.open(linkedin, "_blank")}

                   >
                     <FaLinkedinIn style={{ cursor: "pointer", width: "23px", height: "26px", color: "#F97516" }}
                     />

                   </NavLink>
                 </div>}
                 <Grid item md={4} sm={5} xs={5} lg={4} ></Grid>
           </Grid>
           {/* <Grid item md={6} sm={6} xs={6} lg={6}></Grid> */}
         </Grid>
        </div>
        
        <div className="peopleInvitationCardWrapperPopup">
          {/* <Grid container spacing={1}>
            <Grid container spacing={1} style={{ padding: "6px" }}>
              <Grid item md={3} sm={3} xs={3} lg={3}><p style={{ paddingLeft: "1px", fontWeight: "bold", color: "#000000", cursor: "pointer", fontSize: "15px" }}>FirstName :</p></Grid>
              <Grid item md={3} sm={3} xs={3} lg={3}><p style={{ color: "gray", fontSize: "15px", cursor: "pointer" }}>{firstName}</p></Grid>
              <Grid item md={3} sm={3} xs={3} lg={3}><p style={{ fontWeight: "bold", color: "#000000", cursor: "pointer", fontSize: "15px" }}>Last Name :</p></Grid>
              <Grid item md={3} sm={3} xs={3} lg={3}><p style={{ color: "gray", fontSize: "15px", cursor: "pointer" }}>{lastName}</p></Grid>
            </Grid>
          </Grid> */}
          
          <Grid container spacing={1} style={{ padding: "6px" }}>
            <Grid  item md={3} sm={12} xs={12} lg={3}><p style={{ fontWeight: "bold", color: "#000000" , fontSize: "15px" }}>{t("Area Of Interest")} :</p></Grid>
            <Grid item md={3} sm={12} xs={12} lg={3}><p style={{ color: "gray", fontSize: "14px",   }}>{areaOfInterest}</p></Grid>
            <Grid  item md={3} sm={12} xs={12} lg={3} ><p style={{ fontWeight: "bold", color: "#000000",  fontSize: "15px" }}>{t("Profession")} :</p></Grid>
            <Grid item md={3} sm={12} xs={12} lg={3}><p style={{ color: "gray", fontSize: "14px"  }}>{profession}</p></Grid>
            
          </Grid>
          {/* {this.state.areaOfInterests && this.state.areaOfInterests.map((photo) => (
                 <p>{photo}</p>
                ))} */}
          <Grid container spacing={1} style={{ padding: "6px" }}>
          <Grid  item md={3} sm={12} xs={12} lg={3} ><p style={{ fontWeight: "bold", color: "#000000" , fontSize: "15px" }}>{t("City")} :</p></Grid>
            <Grid item md={3} sm={12} xs={12} lg={3}><p style={{ color: "gray", fontSize: "15px" }}>{city}</p></Grid>
            <Grid item md={3} sm={12} xs={12} lg={3}><p style={{ fontWeight: "bold", color: "#000000" , fontSize: "15px" }}>{t("About")} : </p></Grid>
            <Grid item md={3} sm={12} xs={12} lg={3}><p style={{ color: "gray", fontSize: "15px" }}>{biography}</p></Grid>
             

          </Grid>
          {/* <Grid container spacing={1} style={{ padding: "6px" }}>

 
<Grid  item md={3} sm={3} xs={3} lg={3} ><p style={{ fontWeight: "bold", color: "#000000", cursor: "pointer", fontSize: "15px" }}>Job Functions :</p></Grid>
            <Grid item md={3} sm={3} xs={3} lg={3}><p style={{ color: "gray", fontSize: "15px", cursor: "pointer" }}>{this.state.profession}</p></Grid>
            <Grid  item md={3} sm={3} xs={3} lg={3} ><p style={{ fontWeight: "bold", color: "#000000", cursor: "pointer", fontSize: "15px" }}>CompanyName :</p></Grid>
            <Grid item md={3} sm={3} xs={3} lg={3}><p style={{ color: "gray", fontSize: "15px", cursor: "pointer" }}>{companyName}</p></Grid>
          </Grid> */}
          <Grid container spacing={1} style={{ padding: "6px" }}>
{/* 
            <Grid item md={3} sm={3} xs={3} lg={3}><p style={{ fontWeight: "bold", color: "#000000", cursor: "pointer", fontSize: "15px" }}>Title :</p></Grid>
            <Grid item md={3} sm={3} xs={3} lg={3}><p style={{ color: "gray", fontSize: "15px", cursor: "pointer" }}>{title}</p></Grid> */}
   
            {/* <Grid item md={6} sm={6} xs={6} lg={6} > </Grid> */}
          </Grid>
            {/* <Grid container spacing={1} style={{ padding: "6px" }}>
           <Grid  item md={5} sm={5} xs={5} lg={5}><p style={{ fontWeight: "bold", color: "#000000", cursor: "pointer", fontSize: "15px" }}>Industry :</p></Grid>
            <Grid item md={7} sm={7} xs={7} lg={7}><p style={{ color: "gray", fontSize: "10px", cursor: "pointer" }}>{industry}</p></Grid>
           <Grid item md={3} sm={3} xs={3} lg={3}><p style={{ fontWeight: "bold", color: "#000000", cursor: "pointer", fontSize: "15px" }}>JobFunction :</p></Grid>
            <Grid item md={3} sm={3} xs={3} lg={3}><p style={{ color: "gray", fontSize: "15px", cursor: "pointer" }}>{jobFunctions}</p></Grid>
            <Grid item md={3} sm={3} xs={3} lg={3} ><p style={{ fontWeight: "bold", color: "#000000", cursor: "pointer", fontSize: "15px" }}>Area of Interest :</p></Grid>
            <Grid item md={3} sm={3} xs={3} lg={3}><p style={{ color: "gray", fontSize: "15px", cursor: "pointer" }}>{areaOfInterest}</p></Grid>
              
              </Grid> */}
          {/* <Grid container spacing={1} style={{ padding: "1px" }}>
           
            <Grid item md={3} sm={6} xs={6} lg={3}><p style={{ color: "blue", fontSize: "15px",marginBottom:"5px" }}> <centre>Connection info:</centre> </p></Grid>
            <Grid item md={1} sm={2} xs={2} lg={1} >

              {!twitter == "" && <div tabindex="1">
                <NavLink
                  className=""
                  to={""}
                  exact={true}
                  activeClassName="active"
                  onClick={() => window.open(twitter, "_blank")}

                >
                  <FiTwitter style={{ cursor: "pointer", width: "23px", height: "26px", color: "#F97516" }}
                  />


                </NavLink>
              </div>}
              </Grid>
              <Grid item md={1} sm={2} xs={2} lg={1}  >
              {!facebook == "" && <div tabindex="1">
                <NavLink
                  className=""
                  to={""}
                  exact={true}
                  activeClassName="active"
                  onClick={() => window.open(facebook, "_blank")}

                >
                
                  <FiFacebook style={{ cursor: "pointer", width: "23px", height: "26px", color: "#F97516" }}
                  />
                      </NavLink>
                    </div>}
                    </Grid>
                  <Grid item md={1} sm={2} xs={2} lg={1} >
                  {!linkedin == "" && <div tabindex="1">
                    <NavLink
                      className=""
                      to={""}
                      exact={true}
                      activeClassName="active"
                      onClick={() => window.open(linkedin, "_blank")}

                    >
                      <FaLinkedinIn style={{ cursor: "pointer", width: "23px", height: "26px", color: "#F97516" }}
                      />

                    </NavLink>
                  </div>}
            </Grid>
            <Grid item md={6} sm={6} xs={6} lg={6}></Grid>
          </Grid> */}
        </div>
      </div >

    );
  }


unFriendId(fid) {
    this.setState({
      unfriendState: true,
      friendId: fid,
    });
  }

  unFriend(e) {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
    } else {
      let unFriend = {
        userId: this.props.loginDetails.uId,
        friendsId: this.props.userId,
      };
      console.log(unFriend);
      FriendsFinder.unfriendHit(unFriend)
        .then((response) => {
          this.UnFriendhandleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  popUpUnfriend() {
    const{t}=this.props;
    return (
      <div>
        <Modal show="true" onHide={this.UnFriendhandleClose}>
          <Modal.Header>Unfriend</Modal.Header>

          <Form
            id="profileForm"
            noValidate
            validated={this.state.validated}
            onSubmit={this.unFriend}
          >
            <Modal.Body>
              <Form.Group className="formInput">
                <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                  {t("Are you sure to unfriend ?")}
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.UnFriendhandleClose}>
                {t("Close")}
              </Button>
              <Button type="submit" variant="primary">
                {t("Remove")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }

  UnFriendhandleClose() {
    this.setState({ unfriendState: false });
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(FriendUserInfo));