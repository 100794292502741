import React, { useEffect, useState } from "react";

const Stepper = (props) => {
  // const [stepIndex, setStepIndex] = useState(0);
  const steps = props.steps;
  const stepIndex = props.changeIndex;

  const changeStatus = (index) => {
    // setStepIndex(index);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    props.changeStepper(index);
  };
  useEffect(() => {
    console.log("Child", props.changeIndex);
  });
  return (
    <div
    // style={{
    //   display: "flex",
    //   flexDirection: "column",
    //   gap: "2rem",
    //   // alignItems: "baseline",
    // }}
    >
      {steps &&
        steps.map((step, index) => (
          <div
            key={step.title}
            style={{
              // textAlign: "center",
              position: "relative",
              marginTop: "13px",
            }}
          >
            <div
              class="d-flex"
              style={{ cursor: "pointer" }}
              onClick={() => changeStatus(index)}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "28px",
                  height: "28px",
                  borderRadius: "50%",
                  backgroundColor: index <= stepIndex ? "#a00030" : "#ccc",
                  color: "white",
                  textAlign: "center",
                  lineHeight: "30px",
                  cursor: "pointer",
                  // marginBottom: "5px",
                  position: "relative",
                  zIndex: 1,
                  left: "8px",
                }}
              >
                {index + 1}
              </span>
              <div
                style={{
                  marginLeft: "12px",
                  backgroundColor: index === stepIndex ? "#f5f5f5" : "",
                  flex: "1",
                  // marginTop: "2px",
                  color: index === stepIndex ? "#a00030" : "black",
                  fontWeight: index === stepIndex ? "bold" : "normal",
                  padding: "3px 9px 5px 10px",
                  borderTopLeftRadius: "8px",
                  borderBottomLeftRadius: "8px",
                }}
              >
                {step.title}
              </div>
            </div>
            {index !== steps.length - 1 && (
              <div
                style={{
                  position: "absolute",
                  top: "calc(50% + 20px)",
                  // left: "calc(24% - 1px)",
                  left: "1.3rem",
                  // height: "calc(100% - 25px)",
                  height: "100%",
                  borderLeft:
                    index < stepIndex ? "3px solid #a00030" : "3px solid #ccc",
                  zIndex: 0,
                }}
              ></div>
            )}
          </div>
        ))}
    </div>
  );
};

export default Stepper;
