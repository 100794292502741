import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Keycloak from "keycloak-js";
import {
  keycloakLoginSuccess,
  setUserDetails,
  urlUpdateLoginDetails,
} from "../../redux/actions/loginDetailsActions";
import FullPageLoading from "../../components/FullPageLoading";
import session from "redux-persist/lib/storage/session";
import {
  getFundingImageState,
  getUserWorkSpaceList,
  getWorkSpaceOptionTypes,
  getWorkSpaceType,
} from "../../redux/actions/commonApiActions";
import FundingImage from "../Community/FundingImage";
import { toast } from "react-toastify";
import { getAllNotSawAskHelpListCount } from "../../redux/actions/myRequestActions";
import ErrorUser from "./ErrorUser";
class KeyCloakLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: this.props.keycloakAuthenticated,
      userDetailStatus: false,
      urlWorkspacePresent: false,
      urlPath: "default",
      workSpaceNamePresent: false,
    };
  }
  componentDidUpdate(prevProps) {
    let sectionData = sessionStorage.getItem("WORKSPACE");
    console.log("sectionData In", sectionData);
    if (
      this.props.loginDetails &&
      this.props.userDetails !== null &&
      this.props.authUser !== undefined &&
      // this.props.defaultWorkSpace &&
      this.props.authUser !== prevProps.authUser
    ) {
      console.log("UPDATE USER_DATA", this.props.defaultWorkSpace);
      this.props.getAllNotSawAskHelpListCount();
      if (this.props.defaultWorkSpace !== "default") {
        this.props.getWorkSpaceOptionTypes();
      }
    }

    //User login via default workspace

    // if (
    //   this.props.loginDetails &&
    //   this.props.userDetails !== null &&
    //   this.props.authUser !== undefined &&
    //   this.props.defaultWorkSpace &&
    //   this.props.authUser !== prevProps.authUser
    // ) {
    //   if (sectionData !== "" && sectionData !== null) {
    //     this.setUserUrlWorkSpace(sectionData, this.props.defaultWorkSpace);
    //   } else if (
    //     this.props.loginDetails?.profile_complete &&
    //     sessionStorage.getItem("SHARE_POST")
    //   ) {
    //     //Check Share post user present on workspace
    //     console.log("Checking Share post user");
    //     this.checkUserPresentOrNot(sessionStorage.getItem("SHARE_POST"));
    //   } else {
    //     let workSpaceName = this.props.defaultWorkSpace
    //       ? this.props.defaultWorkSpace.replace(/[, ]+/g, "-").trim()
    //       : "default";
    //     console.log("set-user-workspace-1", workSpaceName);
    //     sessionStorage.setItem("WORKSPACE", workSpaceName);
    //     this.props.getAllNotSawAskHelpListCount();
    //     if (workSpaceName !== "default") {
    //       this.props.getWorkSpaceOptionTypes();
    //     }
    //     this.setState({
    //       urlPath: workSpaceName,
    //       urlWorkspacePresent: false,
    //     });
    //     console.log(
    //       "sectionData User login via default workspace",
    //       workSpaceName
    //     );
    //   }

    //   console.log("sectionData", sectionData);
    // }

    // login without default workspace

    // if (
    //   this.props.defaultWorkSpace !== prevProps.defaultWorkSpace &&
    //   this.props.loginDetails &&
    //   (this.props.defaultWorkSpace === null ||
    //     this.props.defaultWorkSpace === undefined ||
    //     this.props.defaultWorkSpace === "")
    // ) {
    //   if (sectionData !== "" && sectionData !== null) {
    //     this.setUserUrlWorkSpace(sectionData);
    //   } else if (
    //     this.props.loginDetails?.profile_complete &&
    //     sessionStorage.getItem("SHARE_POST")
    //   ) {
    //     //Check Share post user present on workspace
    //     console.log("Checking Share post user");
    //     this.checkUserPresentOrNot(sessionStorage.getItem("SHARE_POST"));
    //   } else {
    //     sessionStorage.setItem("WORKSPACE", "default");
    //     console.log("set-user-workspace-2", "default");

    //     this.props.getAllNotSawAskHelpListCount();
    //     this.setState({
    //       urlWorkspacePresent: false,
    //     });
    //     console.log(
    //       "sectionData User login without default workspace",
    //       "default"
    //     );
    //     console.log("sectionData", sectionData);
    //   }
    // }
  }
  componentDidMount() {
    if (
      this.props.keycloakAuthenticated == true &&
      this.props.userDetails != null
    ) {
      if (sessionStorage.getItem("CHALLENGE_REDIRECT_PATH")) {
        this.props.history.push(
          sessionStorage.getItem("CHALLENGE_REDIRECT_PATH")
        );
      } else {
        this.props.history.push(`/${this.props.workspaceType}/home`);
      }
    } else {
      const key = Keycloak("/keycloak.json");

      key
        .init({
          onLoad: "login-required",
        })
        .then(async (authenticated) => {
          if (!!key.token) {
            console.log(key.token);
            let userInfo = await key.loadUserInfo();

            //storing token to the sessionStorage to adapt the olderFlow
            sessionStorage.setItem("TOKEN", key.token);

            //WORK SPACE TYPE SET
            // sessionStorage.setItem('WORKSPACE', 'Default')
            // this.props.getWorkSpaceType('Default', 0, 0)
            // this.props.getUserWorkSpaceList();
            // store keycloak information in redux
            this.props.keycloakLoginSuccess(key);

            // this.props.getFundingImageState(true);
            const firebase_token = sessionStorage.getItem("Firebase_Token");
            console.log("Firebase Token:", firebase_token);
            // store the info and send api
            this.props.setUserDetails(userInfo, firebase_token);
            if (sessionStorage.getItem("WORKSPACE") !== "") {
              console.log(
                "user login via url",
                sessionStorage.getItem("WORKSPACE")
              );
              this.setState({
                urlWorkspacePresent: true,
                workSpaceNamePresent: true,
              });
            } else if (sessionStorage.getItem("SHARE_POST")) {
              console.log(
                "user login via post share ulr",
                sessionStorage.getItem("WORKSPACE")
              );
              this.setState({
                urlWorkspacePresent: true,
                workSpaceNamePresent: true,
              });
            }
            // this.Mixpanelcall(userInfo);
          }
        });
    }
  }

  checkUserPresentOrNot = async (url) => {
    const path = url.split("/").slice(1, 2);
    console.log("share url path", path);
    if (path && path[0]) {
      let workSpace =
        this.props.loginDetails?.userWorkSpaceList &&
        this.props.loginDetails.userWorkSpaceList.find(
          (data) => data.slug == path[0]
        );
      console.log("User Login Via Share Url", workSpace);
      if (workSpace) {
        this.props.urlUpdateLoginDetails(workSpace);
        let workSpaceName = workSpace?.slug ? workSpace.slug : "default";
        this.setState({
          urlPath: workSpaceName,
        });
        sessionStorage.setItem("WORKSPACE", workSpaceName);
        console.log("set-user-workspace-3", workSpaceName);

        this.props.getAllNotSawAskHelpListCount();
        if (workSpaceName !== "default") {
          this.props.getWorkSpaceOptionTypes();
        }
      } else {
        sessionStorage.setItem("SHARE_POST", "");
        toast.error("Your not authorized in this workspace ,Please check Url");
        console.log("set-user-workspace-4", "default");

        sessionStorage.setItem("WORKSPACE", "default");
        this.props.getAllNotSawAskHelpListCount();
      }
    } else {
      sessionStorage.setItem("SHARE_POST", "");
      toast.error("Something went wrong,Please check Url");
      console.log("set-user-workspace-5", "default");

      sessionStorage.setItem("WORKSPACE", "default");
      this.props.getAllNotSawAskHelpListCount();
    }
    this.setState({
      urlWorkspacePresent: false,
    });
  };
  setUserUrlWorkSpace = async (urlWorkspace, defaultWorkSpace) => {
    console.log("login via url workspace", urlWorkspace);
    if (urlWorkspace) {
      let workSpace =
        this.props.loginDetails.userWorkSpaceList &&
        this.props.loginDetails.userWorkSpaceList.find(
          (data) => data.slug === urlWorkspace
        );
      console.log("login user workspace", workSpace);
      if (workSpace) {
        this.props.urlUpdateLoginDetails(workSpace);
        let workSpaceName = workSpace.slug ? workSpace.slug : "default";
        this.setState({
          urlPath: workSpaceName,
        });
        if (workSpaceName !== "default") {
          this.props.getWorkSpaceOptionTypes();
        }
        console.log("set-user-workspace-6", workSpaceName);

        sessionStorage.setItem("WORKSPACE", workSpaceName);
      } else {
        toast.warning("Your not authorized in this workspace ");
        if (defaultWorkSpace) {
          this.setState({
            urlPath: defaultWorkSpace,
          });
          console.log("set-user-workspace-7", defaultWorkSpace);

          sessionStorage.setItem("WORKSPACE", defaultWorkSpace);
          this.props.getAllNotSawAskHelpListCount(defaultWorkSpace);
        } else {
          console.log("set-user-workspace-8", "default");

          sessionStorage.setItem("WORKSPACE", "default");
          this.props.getAllNotSawAskHelpListCount("default");
        }
      }
    } else {
      console.log("set-user-workspace-9", "default");

      sessionStorage.setItem("WORKSPACE", "default");
      this.props.getAllNotSawAskHelpListCount("default");
    }
    this.setState({
      urlWorkspacePresent: false,
    });
  };
  render() {
    // if (
    //   this.props.userDetails !== null &&
    //   this.props.authUser &&
    //   !this.props.loginDetails?.profile_complete &&
    //   this.props.loginDetails.userWorkSpaceList?.length > 1
    // ) {
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: `/workspace`,
    //       }}
    //     />
    //   );
    // } else
    if (
      this.props.userDetails !== null &&
      this.props.authUser &&
      this.props.loginDetails
      // !this.state.urlWorkspacePresent
      // && this.props.defaultWorkSpace
    ) {
      // {
      //   return <FundingImage />;
      // } else if (
      //   !this.props.homeImageStatus &&
      //   this.props.userDetails != null &&
      //   this.props.authUser != undefined
      // )
      console.log("urlPath", this.props.defaultWorkSpace);
      return (
        <Redirect
          to={{
            // pathname: `/${
            //   this.state.workSpaceNamePresent
            //     ? this.state.urlPath
            //     : this.props.defaultWorkSpace
            //     ? this.props.defaultWorkSpace
            //     : "default"
            pathname: `/${
              this.props.defaultWorkSpace
                ? this.props.defaultWorkSpace
                : "default"
            }/home`,
          }}
        />
      );
    } else if (
      !this.props.userDetails &&
      (this.props.loginUserError === "blocked_by_admin" ||
        this.props.loginUserError === "some_thing_wrong")
    ) {
      return (
        <>
          <ErrorUser></ErrorUser>
        </>
      );
    } else {
      return (
        <>
          <FullPageLoading></FullPageLoading>
        </>
      );
    }
  }
}

const mapStateToProps = function (state) {
  return {
    keycloakAuthenticated: state.loginDetailsApi.keycloakAuthenticated,
    userDetails: state.loginDetailsApi.userDetails,
    authUser: state.loginDetailsApi.authUser,
    workspaceType: state.loginDetailsApi.loginDetails?.userWorkSpace?.slug,
    homeImageStatus: state.commonApi.homeImageState,
    loginDetails: state.loginDetailsApi.loginDetails,
    defaultWorkSpace: state.loginDetailsApi.loginDetails?.defaultWorkSpace,
    loginUserError: state.loginDetailsApi.loginUserError,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    urlUpdateLoginDetails: (name) => dispatch(urlUpdateLoginDetails(name)),
    keycloakLoginSuccess: (userDetails) =>
      dispatch(keycloakLoginSuccess(userDetails)),
    setUserDetails: (data, token) => dispatch(setUserDetails(data, token)),
    getFundingImageState: (status) => dispatch(getFundingImageState(status)),
    getUserWorkSpaceList: () => dispatch(getUserWorkSpaceList()),
    getWorkSpaceType: (type, role, id) =>
      dispatch(getWorkSpaceType(type, role, id)),
    getWorkSpaceOptionTypes: () => dispatch(getWorkSpaceOptionTypes()),
    getAllNotSawAskHelpListCount: () =>
      dispatch(getAllNotSawAskHelpListCount()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(KeyCloakLogin));
