import React, { Component } from "react";
import { Container } from "@material-ui/core";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class Redirect extends Component {
  componentDidMount() {
    let path = sessionStorage.getItem("APP_REDIRECT_PATH");

    if (path) {
      this.props.history.push(path);
      sessionStorage.removeItem("APP_REDIRECT_PATH");
    } else {
      sessionStorage.setItem("CHALLENGE_REDIRECT_PATH", "");
      this.props.history.push(`/${this.props.workspaceType}/home`);
    }
  }

  render() {
    return (
      <Container>
        <div className="tabWrapper">Please wait a moment...</div>
      </Container>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    workspaceType:
      state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(Redirect)));

// export default withTranslation()(withRouter(Redirect));
