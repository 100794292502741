import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PostService from "../../api/PostService";
import AnnouncementBox from "../Square/Home/AnnouncementBox";

class AnnouncementBoxContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolName: "",
      badgeslistCount: "",
      subjectlistCount: "",
      hobbieslistCount: "",
      announcementCount: "",
      surveyCount: 0,
      knowledgePointCount: 0,
      workSpaceId: 0,
    };
    this.getAnnouncementonlyOpen = this.getAnnouncementonlyOpen.bind(this);
    this.LoadAnnoncementFeebackList =
      this.LoadAnnoncementFeebackList.bind(this);
    this.openAnnoucmentScreen = this.openAnnoucmentScreen.bind(this);
    this.closeAnnoucmentScreen = this.closeAnnoucmentScreen.bind(this);
  }

  componentDidMount() {
    // this.getAnnouncementonlyOpen();
    // this.LoadAnnoncementFeebackList();
    this.getAdvertisementDetail();
  }

  componentDidUpdate() {
    // this.getAnnouncementonlyOpen();
    // this.LoadAnnoncementFeebackList();
  }

  render() {
    return (
      <>
        <AnnouncementBox
          closeAnnoucmentScreen={this.closeAnnoucmentScreen}
          imgUrl={this.state.image}
          title={this.state.title}
          content={this.state.strippedHtml}
          id={this.props.match.params.id}
          FeedbackList={this.state.FeedbackList}
          UpdateFeedbackList={this.UpdateFeedbackList}
          workSpaceId={this.state.workSpaceId}
        />
      </>
    );
  }
  getAdvertisementDetail = () => {
    var langs = {
      id: +this.props.match.params.id,
    };
    PostService.getAnnouncementonlyOpenById(langs)
      .then((response) => {
        var result = response.data.data.announcement;
        this.setState({
          id: result.id,
          title: result.title,
          image: result.file,
          strippedHtml: result.description,
          workSpaceId: result.workSpaceType,
        });
        // this.setState({ announcementData: result.data.announcement });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAnnouncementonlyOpen() {
    var langs = {
      lang: this.props.personalLanguage,
    };
    PostService.getAnnouncementonlyOpen(langs)
      .then((response) => {
        var result = response.data;

        this.setState({ announcementData: result.data.announcement });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  LoadAnnoncementFeebackList() {
    var form = {};
    PostService.getAnnouncementFeedbackList(form)
      .then((response) => {
        var result = response.data;
        if (result.data) {
          this.setState({ FeedbackList: result.data.feedbackList });
        } else {
          this.setState({ FeedbackList: [] });
        }
        if (this.props.match.params.id) {
          var val = this.state.announcementData.filter(
            (data) => data.id == this.props.match.params.id
          );
          if (val[0]) {
            this.openAnnoucmentScreen(
              val[0].id,
              val[0].title,
              val[0].file,
              val[0].description
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  openAnnoucmentScreen(id, title, image, strippedHtml) {
    this.setState({
      id: id,
      title: title,
      image: image,
      strippedHtml: strippedHtml,
    });
  }

  UpdateFeedbackList(feedbackrow) {
    var fb = this.state.FeedbackList.filter(
      (data) => data.announcementId !== feedbackrow.announcementId
    );
    fb.push(feedbackrow);

    this.setState({
      FeedbackList: fb,
    });
  }

  closeAnnoucmentScreen() {
    this.props.history.push(`/${this.props.workspaceType}/home`);
  }
}

const mapStateToProps = function (state) {
  return {
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(AnnouncementBoxContainer));

// export default withRouter(AnnouncementBoxContainer);
