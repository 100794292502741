import React from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { IoIosArrowForward, IoMdPricetag } from "react-icons/io";
import dummyimage from "../img/default_avatar.jpg";
import { BiShoppingBag } from "react-icons/bi";
import { FaPhoneAlt } from "react-icons/fa";

const ProductCard = ({ data }) => {
  const handleClickPhone = (event, number) => {
    window.location.href = `tel:${number}`;
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <>
      {data ? (
        <div className="product-container">
          <div>
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = dummyimage;
              }}
              alt=""
              src={data?.p_image}
              // onClick={() => window.open(data?.details_page, "_blank")}
            />
          </div>
          <div className="sub-product-container">
            <h4>{data?.p_name}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: data?.product_details,
              }}
            />
            {/* <p
              className="mt-2"
              style={{
                fontSize: "12px",
                cursor: "pointer",
                width: "fit-content",
              }}
              onClick={(e) => handleClickPhone(e, data?.phone)}
            >
              <FaPhoneAlt className="h-auto mr-2" style={{ width: "14px" }} />{" "}
              {data?.phone}
            </p> */}
            <p className="mt-2">
              <IoMdPricetag className="h-auto mr-1" style={{ width: "18px" }} />{" "}
              ₹ {data?.cost}
            </p>
            <button
              className="pay-button mt-auto ml-auto"
              onClick={() => window.open(data?.details_page, "_blank")}
            >
              <BiShoppingBag />
              Buy
              {/* <IoIosArrowForward /> */}
            </button>
          </div>
        </div>
      ) : (
        <div class="p-3 mb-2 bg-warning-post  m-2 mb-3 d-flex align-items-center justify-content-center text-danger">
          <AiOutlineWarning
            style={{ width: "24px", height: "auto" }}
            className="mr-2"
          />
          <p> Product not present !</p>
        </div>
      )}
    </>
  );
};

export default ProductCard;
