import auth from "./AuthAPI.js";
import Http from "./Http.js";
import * as APPCONFIG from "../config/app-config.js";
import api from "./BaseAPI.js";

class PremiumUserApi {

    saveOrUpdateActivatePermiumBadges(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveOrUpdateActivate-premium-badge`,
      data: formValues,
      config,
    });
  }


  getPremiumBadgeForUser () {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getPremiumBadgeForUser`,
      
    });
  }

}

export default new PremiumUserApi();