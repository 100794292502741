import React, { Component } from "react";
import UserImg from "../../../components/UserImg";
import Grid from "@material-ui/core/Grid";
//import dummyimage from "../../../img/avatar_dummy.png";

import { withRouter } from "react-router";
import dummyimage from "../../../img/default_avatar.jpg";
import ForumService from "../../../api/ForumService";
import FriendProfile from "../../Home/ProfileFriends/FriendProfile";

import * as APPCONFIG from "../../../config/app-config.js";
import { withTranslation } from "react-i18next";
import { premiumUser } from "../../../utils";

let myProfileDialog = {
  backgroundColor: "#D8D8D8",
  color: "#00897B",
  width: "90%",
  height: "560px",
  marginTop: "-300px",
  marginLeft: "-45%",
  paddingLeft: "0px",
  paddingRight: "0px",
  paddingTop: "30px",
};

class RecentlyAddedFriends extends Component {
  constructor(props) {
    super(props);

    this.state = {
      base64String: "",
      showFriendsProfile: false,
    };

    this.load = this.load.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
    this.showFriendsProfile = this.showFriendsProfile.bind(this);
    this.closeFriendsProfile = this.closeFriendsProfile.bind(this);
  }

  componentDidMount() {
    this.setState({}, () => {
      // this.load(this.props.userId);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId)
      this.setState({}, () => {
        // this.load(this.props.userId);
      });
  }

  render() {
    const {
      t,
      firstname,
      lastname,
      profilePhoto,
      userId,
      userName,
      profession,
      premiumData,
    } = this.props;
    return (
      <>
        <div className="friendOnline flexDefault" style={{ padding: "20px" }}>
          <Grid container spacing={1} alignItems="flex-end" wrap="nowrap">
            <Grid
              item
              lg={3}
              md={3}
              xs={3}
              sm={3}
              onClick={() => this.showFriendsProfile(userId)}
            >
              <UserImg
                size="smedium"
                imgSrc={
                  profilePhoto
                    ? `${APPCONFIG.API_URL}auth/image/${profilePhoto}`
                    : dummyimage
                }
                imgAlt={"empty"}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={6} sm={6}>
              <p
                style={{ marginLeft: "12px", cursor: "pointer" }}
                onClick={() => this.showFriendsProfile(userId)}
              >
                {userName}
                {premiumData && premiumUser(premiumData) && (
                  <img
                    src={premiumUser(premiumData)}
                    alt=""
                    className="ml-1"
                    style={{ width: "20px" }}
                  />
                )}
              </p>
              {/* {this.props.tagsValue && (
                <p style={{ fontSize: "10px", color: "#b3b3b3" }}>
                  <p>{this.getSearchedTagValue(this.props.tagsValue)}</p>
                </p>
              )} */}
              <p style={{ fontSize: "10px", color: "#b3b3b3" }}>{profession}</p>
              <p style={{ fontSize: "10px", color: "#b3b3b3" }}>
                {this.props.country} , {this.props.nameLocation}
              </p>
            </Grid>
            <Grid item lg={3} md={3} xs={3} sm={3}>
              <button
                className="cancel-btn"
                onClick={() => this.cancelReq(userId)}
              >
                {t("CANCEL")}
              </button>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
  cancelReq = (id) => {
    this.props.removeRequest(id);
  };
  getSearchedTagValue = (tags) => {
    const searchKeyword = this.props.searchData || "";

    console.log("Tag Value", searchKeyword);

    if (Array.isArray(tags) && tags.length > 0) {
      const result = tags.filter((val) =>
        val.tagName.toLowerCase().includes(searchKeyword.toLocaleLowerCase())
      );
      console.log("Tag searchvalue", result);

      if (result.length > 0) {
        return result[0]?.tagName;
      } else {
        return tags[0].tagName;
      }
    }
    return "-------";
  };
  load(data) {
    this.getBase64String(data);
  }

  getBase64String(userId) {
    let getBase64String = { userId: userId };
    var response = ForumService.getUserPhotoCache(getBase64String);
    if (response) {
      var value = response.data.data;
      if (value && value.userPhoto) {
        var base64String = "";
        base64String = `data:image;base64,${response.data.data.userPhoto}`;
        this.setState({ base64String: base64String, base64StringState: true });
      }
    } else {
      ForumService.getUserPhoto(getBase64String)
        .then((response) => {
          ForumService.setUserPhotoCache(getBase64String, response);
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.userPhoto
          ) {
            var base64String = "";
            base64String = `data:image;base64,${response.data.data.userPhoto}`;
            this.setState({
              base64String: base64String,
              base64StringState: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  showFriendsProfile(userId) {
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  }
  closeFriendsProfile() {
    this.setState({
      showFriendsProfile: false,
    });
  }
}
export default withTranslation()(withRouter(RecentlyAddedFriends));
