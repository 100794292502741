import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaRegUser } from "react-icons/fa";
import { MdOutlineMail, MdOutlineMailOutline } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  keyCloakLoader,
  updateKeyCloakToken,
} from "../../../redux/actions/loginDetailsActions";
import { isValidEmail, passwordCheck } from "../../../utils";
import LoginService from "../../../api/LoginService";
import Swal from "sweetalert2";
import { CLIENT_ID, KEY_CLOAK_URL } from "../../../config/keycloak-config";
import Notfound from "../../../images/icons/page-not-found.png";

const MailRegister = ({ signIn, emailRegisterSuccess, createAccountPage }) => {
  const [state, setState] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    confirmPassword: "",
    inputType: "password",
    cInputType: "password",
    forgot: false,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    const makeAction = (e) => {
      if (e.key === "Enter") {
        if (!signIn) {
        } else {
          if (!state.email || state.password.length < 5) {
            submitForm(null, true);
          }
        }
      }
    };
    window.addEventListener("keydown", makeAction);
    return () => window.removeEventListener("keydown", makeAction);
  }, [state]);
  const submitForm = (e, listener) => {
    if (!listener) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!state.email.trim() || (state.password.length < 5 && !state.forgot)) {
      toast.warning(
        !state.email ? "Enter valid email" : "Enter valid password"
      );
    } else if (!isValidEmail(state.email)) {
      toast.warning("Enter valid email");
    }
    // Forgot password
    else if (state.forgot) {
      dispatch(keyCloakLoader(true));
      const request = {
        uuid: state.email,
        action: "UPDATE_PASSWORD",
      };
      LoginService.forgotPassword(request)
        .then((response) => {
          onBack();
        })
        .catch((e) => {
          onBack();
          console.log("e", e);
        });
    } else if (!signIn) {
      // Register

      if (!state.firstName.trim() || !state.lastName.trim()) {
        toast.warning(
          !state.firstName ? "Enter valid firstName" : "Enter valid lastName"
        );
      } else if (!state.password || !state.confirmPassword) {
        toast.warning(
          !state.password ? "Enter password" : "Enter confirm Password"
        );
      } else if (state.password !== state.confirmPassword) {
        toast.warning("Password not matching");
      } else if (!passwordCheck(state.password)) {
        toast.warn(
          "Password should be at least 8 characters Long, It should contain one capital alphabet,one lowercase alphabet,one number and contain any one of below mentioned special character(#?!@*())"
        );
      } else {
        // console.log("state", state);
        dispatch(keyCloakLoader(true));
        try {
          const request = {
            username: state.email.trim(),
            firstName: state.firstName.trim(),
            enabled: true,
            emailVerified: false,
            totp: false,
            lastName: state.lastName.trim(),
            email: state.email.trim().toLowerCase(),
            requiredActions: ["VERIFY_EMAIL"],
            credentials: [
              {
                type: "password",
                value: state.password.trim(),
              },
            ],
          };

          console.log("Email_register_params ", request);

          LoginService.keyClockCreateUser(request)
            .then((response) => {
              console.log("response", response);
              const result = response.data.data;
              console.log("response", result);

              dispatch(keyCloakLoader(false));
              if (result === 1) {
                emailRegisterSuccess();
              } else {
                toast.error("User already exit !");
                setState({
                  ...state,
                  email: "",
                  password: "",
                  confirmPassword: "",
                });
              }
            })
            .catch((e) => {
              toast.error("User already exit !");
              console.error(e);
              dispatch(keyCloakLoader(false));
              setState({
                ...state,
                email: "",
                password: "",
                confirmPassword: "",
              });
            });
        } catch (e) {
          console.log("e", e);
          toast.error("Something went wrong ,please try after some time !");
          dispatch(keyCloakLoader(false));
        }
      }
    } else {
      //  Login
      dispatch(keyCloakLoader(true));
      try {
        const params = new URLSearchParams();
        params.append("grant_type", "password");
        params.append("client_id", CLIENT_ID);
        params.append("client_secret", "34471d72-6ade-4959-af93-67fa7760e64c");
        params.append("username", state.email.trim());
        params.append("password", state.password.trim());
        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        console.log(
          "Email_Login ",
          KEY_CLOAK_URL + "realms/yuukke/protocol/openid-connect/token"
        );
        console.log("Email_Login_params ", params);

        axios
          .post(
            KEY_CLOAK_URL + "realms/yuukke/protocol/openid-connect/token",
            params,
            config
          )
          .then((response) => {
            console.log("login-response", response);
            if (response.data?.access_token) {
              dispatch(updateKeyCloakToken(response.data));
            } else {
              Swal.fire({
                title: "INVALID CREDENTIALS",
                showCancelButton: true,
                confirmButtonText: "Create New Account",
                cancelButtonText: "Reenter Credential",
                reverseButtons: true,
                icon: "warning",
                iconHtml: `<img src=${Notfound} style="width: 50px; height: 50px;">`,
              }).then((result) => {
                if (result.value) {
                  // toast.error("Invalid credentials !");
                  createAccountPage(2);
                }
                updateState("password", "");
                dispatch(keyCloakLoader(false));
              });
            }
          })
          .catch((error) => {
            const status = error?.response?.data?.error_description;
            console.log("login-error", error.response);
            if (status === "Account disabled") {
              let data = {
                emailId: state.email,
                deviceType: "web",
              };
              LoginService.getBlockedUsersList(data)
                .then((response) => {
                  console.log("Update-trying", response.data);
                })
                .catch((error) => {
                  console.log("error", error);
                });
              toast.error("Account disabled");
              updateState("password", "");
              dispatch(keyCloakLoader(false));
            } else {
              Swal.fire({
                title: "INVALID CREDENTIALS",
                showCancelButton: true,
                confirmButtonText: "Create New Account",
                cancelButtonText: "Reenter Credential",
                reverseButtons: true,
                icon: "warning",
                iconHtml: `<img src=${Notfound} style="width: 50px; height: 50px;">`,
              }).then((result) => {
                if (result.value) {
                  // toast.error("Invalid credentials !");
                  createAccountPage(2);
                }
                updateState("password", "");
                dispatch(keyCloakLoader(false));
              });
            }
          });
      } catch (e) {
        console.log("login-error", e);
        toast.error("Something went wrong ,please try after some time !");
        dispatch(keyCloakLoader(false));
      }
    }
  };
  const onBack = () => {
    Swal.fire({
      // title: logoutTitle,
      text: `You should receive an email shortly with further instructions.`,
      icon: "success",
      type: "success",
    }).then((result) => {
      emailRegisterSuccess();
      dispatch(keyCloakLoader(false));
    });
  };
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const forgotPassword = () => {
    updateState("forgot", true);
  };
  return (
    <form
      className="login-details-wrapper"
      onSubmit={(e) => submitForm(e, false)}
    >
      {!signIn && (
        <>
          <label className="mt-2">First name</label>
          <div class="input-text">
            <input
              className="input-text-input"
              value={state.firstName}
              type="text"
              placeholder="Enter your name"
              required={true}
              onChange={(e) => updateState("firstName", e.target.value)}
            />
            <FaRegUser />
          </div>
          <label className="mt-2">Last name</label>
          <div class="input-text">
            <input
              className="input-text-input"
              value={state.lastName}
              type="text"
              placeholder="Enter your name"
              required={true}
              onChange={(e) => updateState("lastName", e.target.value)}
            />
            <FaRegUser />
          </div>
        </>
      )}
      <label className="mt-2">Email</label>

      <div class="input-text">
        <input
          className="input-text-input"
          value={state.email}
          type="email"
          // name="email"
          placeholder="Enter your email"
          required={true}
          // autoComplete="off"
          onChange={(e) => updateState("email", e.target.value)}
        />
        <MdOutlineMail />
      </div>
      {!state.forgot && (
        <>
          <label className="mt-2">Password</label>

          <div class="input-text">
            <input
              className="input-text-input"
              value={state.password}
              type={state.inputType}
              placeholder="Enter password"
              required={true}
              // autoComplete={signIn ? "on" : "off"}
              onChange={(e) => updateState("password", e.target.value)}
            />
            <span
              onClick={() =>
                updateState(
                  "inputType",
                  state.inputType === "password" ? "text" : "password"
                )
              }
              style={{ cursor: "pointer" }}
            >
              {state.inputType === "password" ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </>
      )}
      {!signIn && (
        <>
          <label className="mt-2">Confirm Password</label>

          <div class="input-text">
            <input
              className="input-text-input"
              value={state.confirmPassword}
              type={state.cInputType}
              placeholder="Enter confirm password"
              required={true}
              onChange={(e) => updateState("confirmPassword", e.target.value)}
            />
            <span
              onClick={() =>
                updateState(
                  "cInputType",
                  state.cInputType === "password" ? "text" : "password"
                )
              }
              style={{ cursor: "pointer" }}
            >
              {state.cInputType === "password" ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <p className="c-info">
            Use 8 or more characters letters and minimum 1 number and 1 special
            character.
          </p>
        </>
      )}
      {signIn && !state.forgot && (
        <a href="#" className="reg-btn" onClick={() => forgotPassword(true)}>
          Forgot Password
        </a>
      )}
      <button
        type="submit"
        style={{ color: "black", background: "white" }}
        className="login-options-btn mt-3 justify-content-center font-weight-bold btn-hover"
      >
        {state.forgot ? "Send Verification Mail" : "Continue"}
      </button>
      {state.forgot && (
        <p className="c-info email-warn">
          Enter your email address and we will send you instructions on how to
          create a new password.
        </p>
      )}
    </form>
  );
};

export default MailRegister;
