import auth from "./AuthAPI.js";
import Http from "./Http.js";
import * as APPCONFIG from "../config/app-config.js";

class FindFriendsFilter {
  //get all country
  getAllCountry() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallCountry`,
    });
  }

  //method to getallCityByCountryId
  getallCityByCountryId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallCityByCountryId`,
      data: JSON.stringify(formValues),
    });
  }
  //get all language
  getallLanguage() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallLanguage`,
    });
  }

  getAllSchool() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallSchool`,
    });
  }

  //method getallSchoolByCityId
  //   getallSchoolByCityId(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/getallSchoolByCityId`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }

  //method getallClassBySchoolId
  getallClassBySchoolId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallClassBySchoolId`,
      data: JSON.stringify(formValues),
    });
  }

  //onGet Search filter
  getListByFilter(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getFriendsDetailsFilter`,
      data: JSON.stringify(formValues),
    });
  }
  friendRequestCancel(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/friendRequestCancel`,
      data: JSON.stringify(formValues),
    });
  }
}

export default new FindFriendsFilter();
