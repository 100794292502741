import React, { useState } from "react";
import { getFlagEmoji } from "../../../utils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import CheckBox from "../../../components/CheckBox";
import ProfileService from "../../../api/ProfileService";
import AuthService from "../../../session/AuthService";
import { storeLoginDetails } from "../../../redux/actions/loginDetailsActions";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { toast } from "react-toastify";
import { storePersonalLanguage } from "../../../redux/actions/profileActions";
import BlockUi from "react-block-ui";

const LanguageContainer = (props) => {
  const { loginDetails, authUser } = useSelector(
    (state) => state.loginDetailsApi
  );
  const language = [
    { name: "English", value: "English", flag: <span>&#127760;</span> },
    { name: "Tamil", value: "தமிழ்", flag: getFlagEmoji("IN") },
    { name: "Hindi", value: "हिंदी", flag: getFlagEmoji("IN") },

    { name: "Gujarati", value: "ગુજરાતી", flag: getFlagEmoji("IN") },

    { name: "Kannadam", value: "ಕನ್ನಡ", flag: getFlagEmoji("IN") },

    { name: "Odia", value: "ଓଡିଆ", flag: getFlagEmoji("IN") },
    { name: "Telugu", value: "తెలుగు", flag: getFlagEmoji("IN") },
    { name: "Assamese", value: "অসমীয়া", flag: getFlagEmoji("IN") },
    { name: "Kashmiri", value: "کٲشُر", flag: getFlagEmoji("IN") },

    { name: "Bengali", value: "বাংলা", flag: getFlagEmoji("IN") },
    { name: "Arabic", value: "عربي", flag: getFlagEmoji("SA") },

    { name: "French", value: "Français", flag: getFlagEmoji("FR") },

    { name: "Spanish", value: "Española", flag: getFlagEmoji("ES") },
    { name: "Mandarin", value: "普通话", flag: getFlagEmoji("CN") },
  ];
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const handleChangeLanguage = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    if (value && value !== loginDetails?.pLanguage) {
      setLoader(true);
      const formData = new FormData();
      formData.append("personalLanguage", value);

      const config = { headers: { "content-type": "multipart/form-data" } };
      ProfileService.userPersonalLanguageSave(formData, config)
        .then((response) => {
          var result = response.data;
          // toast.success("Saved successfully");
          AuthService.registerAuth("", result.data);

          dispatch(storeLoginDetails(result.data, authUser));
          changeLanguage(result.data.pLanguage);
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    }
  };
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);

    if (lang === "English") {
      modifyLanguage("en");
    } else if (lang === "French") {
      modifyLanguage("fr");
    } else if (lang === "Luxembourgish") {
      modifyLanguage("lx");
    } else if (lang === "Tamil") {
      modifyLanguage("ta");
    } else if (lang === "Gujarati") {
      modifyLanguage("gu");
    } else if (lang === "Kannadam") {
      modifyLanguage("ka");
    } else if (lang === "Odia") {
      modifyLanguage("od");
    } else if (lang === "Hindi") {
      modifyLanguage("hi");
    } else if (lang === "Spanish") {
      modifyLanguage("sp");
    } else if (lang === "Telugu") {
      modifyLanguage("te");
    } else if (lang === "Arabic") {
      modifyLanguage("ar");
    } else if (lang === "Bengali") {
      modifyLanguage("be");
    } else if (lang === "Mandarin") {
      modifyLanguage("ma");
    } else if (lang === "Assamese") {
      modifyLanguage("as");
    } else if (lang === "Kashmiri") {
      modifyLanguage("ks");
    } else {
      modifyLanguage("en");
    }
  };
  const modifyLanguage = (lang) => {
    AuthService.storeCommonLanguages(lang);
    dispatch(storePersonalLanguage(lang));

    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    toast.success(`${props.t("Saved successfully")}`);
    setLoader(false);
    props.history.push(`/${loginDetails?.userWorkSpace?.slug}/home`);
  };
  return (
    <BlockUi
      tag="div"
      message={props.t("Loading, please wait")}
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={loader}
    >
      <div className="tabWrapperPoints p-4 d-flex align-items-center justify-content-center flex-column">
        <div className="d-flex mb-5 w-100">
          <h4 className="heading-h4-v2">{props.t("Available Language's")}</h4>
          <ArrowBackIcon
            className="ml-auto mr-2 c-pointer"
            onClick={() =>
              props.history.push(`/${loginDetails?.userWorkSpace?.slug}/home`)
            }
          />
        </div>
        <ul className="card l-none p-1 g-1" style={{ width: "21rem" }}>
          {language.map((item, i) => (
            <li
              onClick={(e) => handleChangeLanguage(e, item.name)}
              key={i}
              style={{
                border:
                  item.name === loginDetails?.pLanguage
                    ? "1px solid gainsboro"
                    : "none",
              }}
              className="d-flex m-1 p-1 c-pointer m-0 align-items-center justify-space-between  "
            >
              <h6 className="m-0">
                {item.value} {item.flag}
              </h6>
              <div className="ml-auto l-select">
                <CheckBox
                  label={null}
                  value={item.name === loginDetails?.pLanguage}
                  onChange={(e) => handleChangeLanguage(e, item.name)}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </BlockUi>
  );
};

export default withTranslation()(withRouter(LanguageContainer));
