import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CreateIcon from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import { MenuItem } from "@material-ui/core";

export default class EditableTextField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disbaledField: false,
      value: this.props.value,
    };

    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  handleClickEdit() {
    this.setState({
      disbaledField: false,
    });
    this.props.handleClickEdits();
  }

  handleChangeInput(event) {
    // if ((/^[aA-zZ\s]+$/i.test(event.target.value)) || (event.target.value == "")) {
    if (this.props.acceptSpecialCharacter) {
      this.props.getSelectedValue(event.target.value);
      this.props.handleClickEdits();
    } else if (
      /^[A-Za-z\s]+$/.test(event.target.value) ||
      event.target.value == ""
    ) {
      this.setState({
        value: event.target.value,
      });
      this.props.getSelectedValue(event.target.value);
      this.props.handleClickEdits();
      if ("checkUserTag" in this.props) {
        this.props.checkUserTag(event.target.value);
      }
    }
    //   const newValue = event.target.value;
    //   var iChars = "!@#$%^&*()+=-[]\\\';,./{}|\":<>?";
    //   for (var i = 0; i < event.target.value.length; i++) {
    //     if (iChars.indexOf(event.target.value.charAt(i)) != -1) {
    //         return false;
    //     }
    // }
  }

  render() {
    const { label } = this.props;
    const { disbaledField, value } = this.state;
    return (
      <div
        className={
          disbaledField
            ? "editableTextWrapper formFieldGroup disabledField"
            : "editableTextWrapper formFieldGroup"
        }
      >
        <label className="label-v2">{label}</label>
        <Grid container spacing={1} alignItems="flex-end" wrap="nowrap">
          <Grid item xs={11}>
            <TextField
              id="textField"
              label=""
              variant="outlined"
              // onKeyPress={(e) => {
              //   if (!/[a-zA-Z+]/.test(e.key) && this.props.alphabet && this.props.alphabet === true) {
              //     e.preventDefault();
              //   }
              // }}
              onInput={(e) => (e.target.value = e.target.value.slice(0, 30))}
              value={value}
              error={true === this.props.userTag}
              helperText={
                true === this.props.userTag
                  ? "Display name already exists!"
                  : " "
              }
              onChange={this.handleChangeInput}
              disabled={disbaledField}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={1}>
            {/* <IconButton
              aria-label="Edit"
              size="small"
              onClick={this.handleClickEdit}
            >
              <CreateIcon fontSize="inherit" />
            </IconButton> */}
          </Grid>
        </Grid>
      </div>
    );
  }
}
