import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import translationEN from "./locale/en/translation.json";
import translationFR from "./locale/fr/translation.json";
import translationLX from "./locale/lx/translation.json";
import translationTA from "./locale/ta/translation.json";
import translationHI from "./locale/hi/translation.json";
import translationSP from "./locale/sp/translation.json";
import translationTE from "./locale/te/translation.json";
import translationGU from "./locale/gu/translation.json";
import translationKA from "./locale/ka/translation.json";
import translationOD from "./locale/od/translation.json";
import translationAr from "./locale/ar/translation.json";
import translationBe from "./locale/be/translation.json";
import translationAs from "./locale/as/translation.json";
import translationKs from "./locale/ks/translation.json";
import translationMa from "./locale/ma/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  lx: {
    translation: translationLX,
  },
  ta: {
    translation: translationTA,
  },
  hi: {
    translation: translationHI,
  },
  sp: {
    translation: translationSP,
  },
  te: {
    translation: translationTE,
  },
  ka: {
    translation: translationKA,
  },
  gu: {
    translation: translationGU,
  },
  od: {
    translation: translationOD,
  },
  ar: {
    translation: translationAr,
  },
  be: {
    translation: translationBe,
  },
  as: {
    translation: translationAs,
  },
  ks: {
    translation: translationKs,
  },
  ma: {
    translation: translationMa,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: "en",
    // fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;

// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

//  import translationEN from './locale/en/translation.json'
//  import translationFR from './locale/fr/translation.json';
//  import translationLX from './locale/lx/translation.json';
//  const resources = {
//   en: {
//     translation: translationEN
//   },
//   fr: {
//     translation: translationFR
//   },
//   lx: {
//     translation: translationLX
//   }
// };
// i18n.use(initReactI18next).init({

//   fallbackLng: 'en',
//   // debug only when not in production
//   debug: process.env.NODE_ENV !== 'production',
//   ns: ['translations'],
//   defaultNS: 'translations',
//   keySeparator: false,
//   interpolation: {
//     escapeValue: false,
//     formatSeparator: ',',
//   },
//   react: {
//     wait: true,
//   },
// });

// export default i18n;
