import React from "react";
import moment from "moment";
import { withRouter } from "react-router";
import AuthService from "../../../session/AuthService_old";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { AiOutlineHeart } from "react-icons/ai";
import "react-image-lightbox/style.css";
import * as APPCONFIG from "../../../config/app-config.js";
import { connect } from "react-redux";
import VideoPostCard from "../../Home/HomeUpdated/VideoPostCard";
import dummyimage from "../../../img/default_avatar.jpg";
import { giveUserAction } from "../../../utils";
import { needPhotVerification } from "../../../redux/actions/commonApiActions";
let time;
class ChallengeInfoCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileData: [],
    };

    this.gotochallengeDescription = this.gotochallengeDescription.bind(this);
  }

  componentDidMount() {
    console.log("check");
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.getMessageFiles(this.props.data.challengeMediaFiles);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.data.challengeMediaFiles !== this.props.data.challengeMediaFiles
    ) {
      this.getMessageFiles(nextProps.data.challengeMediaFiles);
    }
  }

  render() {
    const { t } = this.props;
    const {
      caption,
      createdDate,
      subjectId,
      title,
      userName,
      age,
      categoryValue,
      contactNumber,
      userId,
      description,
      userLike,
      participation,
      teamLeader,
      challengeId,
      profilePhoto,
      alternativeEmail,
      challengeMediaFiles,
      category,
      address,
    } = this.props.data;
    console.log("description", this.props.data.description);
    return (
      <>
        {/* <div className="communityCard">
                    <div className="postCardHeader flexSpaceBetween">
                        <div className="postCardHeaderInfo ">
                            <div class="chatImg">
                                <img className="rounded-circle"
                                 onClick={()=>this.showFriendsProfile(userId)}
                                    style={{ width: "100%", height: "100%", cursor:"pointer" }}
                                    src={
                                        profilePhoto
                                            ? `${APPCONFIG.API_URL}auth/image/${profilePhoto}`
                                            : dummyimage
                                    }
                                    alt="user"

                                />
                            </div>
                            <div className="postInfo">
                                <p className="postUserName">{userName}</p>

                                <p className="postUploadTie">
                                    {moment(createdDate).format("DD MMM, h:mm a")}
                                </p>

                            </div>

                        </div>

                    </div>
                    <div style={{ margin: "10px", marginLeft: "100px" }}>
                    <div className="postDescription" style={{ marginTop: "10px"}}>

                        <p>{caption}</p>

                    </div>
                    <div className="postDescription" style={{ marginTop: "10px" }}>

                        <p dangerouslySetInnerHTML={{
                            __html: description,
                        }}></p>

                    </div>
                    <Grid
                        container
                        spacing={0}
                        style={{ paddingTop: "15px" ,paddingBottom:"15px",marginLeft: "20px"}}
                    >
                        <Grid item md={2} xs={2} sm={2} lg={2}>
                            {" "}
                            <p style={{ fontSize: "17px" }}>Participation : </p>
                        </Grid>
                        <Grid item md={2} xs={2} sm={2} lg={2}>
                            <p style={{ color: "gray", fontSize: "14px", padding: "2px" }}>
                                {" "}
                                {participation}
                            </p>
                        </Grid>
                        <Grid item md={2} xs={2} sm={2} lg={2}>
                            {" "}
                            <p style={{ fontSize: "17px" }}>Team Leader : </p>
                        </Grid>
                        <Grid item md={2} xs={2} sm={2} lg={2}>
                            <p style={{ color: "gray", fontSize: "14px", padding: "2px" }}>
                                {" "}
                                {teamLeader}
                            </p>
                        </Grid>
                    </Grid>
                    </div>
                    <div style={{marginTop:"12px",marginLeft:"100px"}}>
                 
                  </div>
                    <div className="writeCommentWrapper">
                        <div className="postCardFooter">
                            <Grid container spacing={1}>
                                <Grid item xs={0} sm={0} lg={12} md={12} >
                                    <button style={{ float: "right", cursor: "pointer" }} onClick={() => this.gotochallengeDescription(challengeId)}>vote</button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                </div> */}
        <div
          className="peopleCardWrapperGroup"
          style={{ cursor: "pointer" }}
          onClick={() => this.gotochallengeDescription(challengeId)}
        >
          <div style={{ position: "relative" }}>
            {this.state.fileData &&
              this.state.fileData.map((photo) => (
                <img class="challengeParticipantImg" src={photo.image} />
              ))}
          </div>
          <div className="">
            <div className="flexSpaceBetween">
              <div className="postGroupCardChallenge">
                <div class="chatImg">
                  <img
                    className="rounded-circle"
                    onClick={() => this.showFriendsProfile(userId)}
                    style={{
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    src={
                      profilePhoto
                        ? `${APPCONFIG.API_URL}auth/image/${profilePhoto}`
                        : dummyimage
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = dummyimage;
                    }}
                    alt="user"
                  />
                </div>
                <div className="postInfo">
                  <p className="challengeCardUserName">
                    {this.props.careerLabType !== 0 ? title : userName}
                  </p>

                  <p className="postUploadTie">
                    {moment(createdDate).locale("en").format("ll")}
                    {/* {moment(createdDate).format("DD MMM, h:mm a")} */}
                  </p>
                  <p style={{ fontSize: "12px" }}>{categoryValue}</p>
                </div>
              </div>
            </div>
            {this.props.careerLabType === 0 && (
              <Grid
                container
                spacing={0}
                style={{ paddingLeft: "12px", paddingRight: "12px" }}
              >
                <Grid item xs={6} sm={0} lg={6} md={6}>
                  <h6
                    className="challengeCardUserName"
                    style={{ fontSize: "18px" }}
                  >
                    {title}
                  </h6>
                </Grid>
                <Grid item xs={6} sm={6} lg={6} md={6}>
                  <button
                    style={{
                      float: "right",
                      cursor: "pointer",
                      marginBottom: "8px",
                    }}
                  >
                    {userLike ? t("voted") : t("vote")}
                  </button>
                </Grid>
              </Grid>
            )}
            <div className="mb-2">
              <p
                className="challengeCardDescription"
                style={{
                  paddingLeft: "12px",
                  padding: "12px",
                  fontSize: "14px",
                  paddingBottom: "12px",
                  height: `${this.props.careerLabType === 0 ? "4rem" : ""}`,
                  maxHeight: `${
                    this.props.careerLabType === 0 ? " " : "104px"
                  }`,
                }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
            {/* <p
                className="challengeCardOthers"
                style={{
                  paddingLeft: "12px",
                  fontSize: "12px",
                  paddingBottom: "12px",
                }}
              ></p> */}
          </div>
        </div>
      </>
    );
  }
  gotochallengeDescription(challengeId) {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      if (this.props.careerLabType === 0) {
        this.props.history.push(`/challenge-description/${challengeId}`);
      }
    }
  }

  async getMessageFiles(files) {
    let fileList = [];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        //fileList.push(`${APPCONFIG.API_URL}auth/video/${files[i].fileName}`);
        fileList.push({ file: files[i].streamUrl, image: files[i].thumbnail });
      }
      await this.setState({ fileData: fileList });
      //  alert(fileList)
    }
  }
  showFriendsProfile(userId) {
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  }
}
const mapStateToProps = function (state) {
  console.log("state.loginDetailsApi ", state.loginDetailsApi);
  return {
    subjectsList: state.commonApi.subjectslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ChallengeInfoCard)));
