import React, { memo, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./Image.css";

const ImageGrid = (props) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const { images } = props;

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setLightboxOpen(true);
  };

  const handleImageHover = () => {
    setZoomed(true);
  };

  const handleImageLeave = () => {
    setZoomed(false);
  };

  return (
    <div className="image-grid">
      {images?.length === 1 ? (
        <div
          className={`single-image ${zoomed ? "zoomed" : ""}`}
          onMouseEnter={handleImageHover}
          onMouseLeave={handleImageLeave}
          onClick={() => handleImageClick(0)}
        >
          <img
            src={images[0]}
            alt="Image"
            onClick={props.onClickImage}
            loading="lazy"
          />
        </div>
      ) : (
        images &&
        images.slice(0, 6).map((image, index) => (
          <div
            key={index}
            className={`position-relative image-item ${
              selectedImageIndex === index ? "zoomed" : ""
            }`}
            onMouseEnter={() => setSelectedImageIndex(index)}
            onMouseLeave={handleImageLeave}
            onClick={() => handleImageClick(index)}
          >
            <img
              src={image}
              alt=""
              onClick={props.onClickImage}
              loading="lazy"
            />
            {images?.length > 6 && index === 5 && (
              <div className="image-item count-item position-absolute">
                <span className="image-count">{`+${images.length - 6}`}</span>
              </div>
            )}
          </div>
        ))
      )}

      {lightboxOpen && (
        <Lightbox
          mainSrc={images[selectedImageIndex]}
          nextSrc={
            props?.images?.length === 1
              ? undefined
              : images[(selectedImageIndex + 1) % images.length]
          }
          prevSrc={
            props?.images?.length === 1
              ? undefined
              : images[(selectedImageIndex + images.length - 1) % images.length]
          }
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() =>
            setSelectedImageIndex(
              (selectedImageIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setSelectedImageIndex((selectedImageIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default memo(ImageGrid);

// import React, { Component } from 'react'
// import { Row, Col } from 'react-bootstrap'
// import { Grid } from '@material-ui/core'
// import PropTypes from 'prop-types'
// import Modal from './Modal'

// class Images extends Component {
//   static defaultProps = {
//     countFrom: 5,
//     hideOverlay: false,
//     images: [],
//     onClickEach: null,
//     overlayBackgroundColor: '#222222',
//     renderOverlay: () => 'Preview Image',
//     width: 100,
//     imageView: true,
//   }

//   constructor(props) {
//     super(props)

//     this.state = {
//       countFrom:
//         props.countFrom > 0 && props.countFrom < 5 ? props.countFrom : 5,
//       modal: false,
//     }

//     this.openModal = this.openModal.bind(this)
//     this.onClose = this.onClose.bind(this)

//     if (props.countFrom <= 0 || props.countFrom > 5) {
//       console.warn('countFrom is limited to 5!')
//     }
//   }

//   onClose() {
//     this.setState({ modal: false, imageView: true })
//   }

//   openModal(index) {
//     const { onClickEach, images } = this.props

//     if (onClickEach) {
//       return onClickEach({ index, src: images[index] })
//     }

//     this.setState({ index, modal: true, imageView: true })
//   }

//   renderOne() {
//     const { width, images } = this.props
//     const { countFrom } = this.state

//     const overlay =
//       images.length > countFrom && countFrom === 1
//         ? this.renderCountOverlay(true)
//         : this.renderOverlay()

//     return (
//       <Grid
//         style={{ width: `${width}%` }}
//         className={`height-${
//           images.length === 1 || countFrom === 1 ? 'one' : 'two'
//         }`}
//       >
//         <Row style={{ width: '100%', height: '100%' }} key={Math.random()}>
//           <Col
//             key={Math.random()}
//             xs={12}
//             md={12}
//             className="borders background-react-fb-image-grid"
//             onClick={this.openModal.bind(this, 0)}
//             style={{ height: '100%' }}
//           >
//             {/* {overlay} */}
//             <img
//               style={{ width: '100%' }}
//               src={images[0]}
//               onClick={this.props.onClickImage}
//             ></img>
//           </Col>
//         </Row>
//       </Grid>
//     )
//   }

//   renderOneOnFour() {
//     const { width, images } = this.props
//     const { countFrom } = this.state

//     const overlay =
//       images.length > countFrom && countFrom === 1
//         ? this.renderCountOverlay(true)
//         : this.renderOverlay()

//     return (
//       <Grid
//         style={{ width: `${width}%` }}
//         className={`height-${
//           images.length === 1 || countFrom === 1 ? 'one' : 'two'
//         }`}
//       >
//         <Row style={{ width: '100%', height: '100%' }} key={Math.random()}>
//           <Col
//             key={Math.random()}
//             xs={12}
//             md={12}
//             className="borders background-react-fb-image-grid"
//             onClick={this.openModal.bind(this, 0)}
//             style={{ background: `url(${images[0]})`, height: '100%' }}
//           >
//             {overlay}
//           </Col>
//         </Row>
//       </Grid>
//     )
//   }

//   renderTwo() {
//     const { width, images } = this.props
//     const { countFrom } = this.state
//     const overlay =
//       images.length > countFrom && [2, 3].includes(+countFrom)
//         ? this.renderCountOverlay(true)
//         : this.renderOverlay()
//     const height =
//       images.length === 2 ? '100%' : images.length === 3 ? '35%' : '65%'
//     const conditionalRender =
//       [3, 4].includes(images.length) ||
//       (images.length > +countFrom && [3, 4].includes(+countFrom))

//     return (
//       <Grid style={{ width: '100%', height }}>
//         <Row style={{ width: '100%', height: '100%' }} key={Math.random()}>
//           <Col
//             key={Math.random()}
//             xs={6}
//             md={6}
//             onClick={this.openModal.bind(this, conditionalRender ? 1 : 0)}
//             className="borders background-react-fb-image-grid"
//             style={{
//               background: `url(${conditionalRender ? images[1] : images[0]})`,
//               height: '100%',
//             }}
//           >
//             {this.renderOverlay()}
//           </Col>
//           <Col
//             key={Math.random()}
//             xs={6}
//             md={6}
//             onClick={this.openModal.bind(this, conditionalRender ? 2 : 1)}
//             className="borders background-react-fb-image-grid"
//             style={{
//               background: `url(${conditionalRender ? images[2] : images[1]})`,
//               height: '100%',
//             }}
//           >
//             {overlay}
//           </Col>
//         </Row>
//       </Grid>
//     )
//   }

//   renderThree() {
//     const { width, images } = this.props
//     const { countFrom } = this.state
//     const overlay =
//       !countFrom ||
//       countFrom > 5 ||
//       (images.length > countFrom && [4, 5].includes(+countFrom))
//         ? this.renderCountOverlay(true)
//         : this.renderOverlay()
//     const height = `${33 * (width / 100)}%`
//     const conditionalRender =
//       images.length === 4 || (images.length > +countFrom && +countFrom === 4)

//     return (
//       <Grid style={{ width: '100%', height: '35%' }}>
//         <Row style={{ width: '100%', height: '100%' }} key={Math.random()}>
//           <Col
//             key={Math.random()}
//             xs={6}
//             md={4}
//             onClick={this.openModal.bind(this, conditionalRender ? 1 : 2)}
//             className="borders background-react-fb-image-grid"
//             style={{
//               background: `url(${conditionalRender ? images[1] : images[2]})`,
//               height: '100%',
//             }}
//           >
//             {this.renderOverlay()}
//           </Col>
//           <Col
//             key={Math.random()}
//             xs={6}
//             md={4}
//             onClick={this.openModal.bind(this, conditionalRender ? 2 : 3)}
//             className="borders background-react-fb-image-grid"
//             style={{
//               background: `url(${conditionalRender ? images[2] : images[3]})`,
//               height: '100%',
//             }}
//           >
//             {this.renderOverlay()}
//           </Col>
//           <Col
//             key={Math.random()}
//             xs={6}
//             md={4}
//             onClick={this.openModal.bind(this, conditionalRender ? 3 : 4)}
//             className="borders background-react-fb-image-grid"
//             style={{
//               background: `url(${conditionalRender ? images[3] : images[4]})`,
//               height: '100%',
//             }}
//           >
//             {overlay}
//           </Col>
//         </Row>
//       </Grid>
//     )
//   }

//   renderOverlay() {
//     const {
//       hideOverlay,
//       renderOverlay,
//       overlayBackgroundColor,
//       width,
//     } = this.props
//     const fontSize = `${3 * (width / 100)}%`

//     if (hideOverlay) {
//       return false
//     }

//     // return [
//     //   <div
//     //     key={Math.random()}
//     //   className="background-react-fb-image-grid-cover slide"
//     //     style={{ backgroundColor: overlayBackgroundColor }}
//     //   />,
//     //   <div
//     //     key={Math.random()}
//     //     className="cover-text slide animate-text"
//     //     style={{ fontSize }}>
//     //     {/* {renderOverlay()} */}
//     //   </div>,
//     // ];
//   }

//   renderCountOverlay(more) {
//     const { images, width } = this.props
//     const { countFrom } = this.state
//     const extra = images.length - (countFrom && countFrom > 5 ? 5 : countFrom)
//     const fontSize = `${7 * (width / 100)}%`

//     return [
//       more && (
//         <div
//           key={Math.random()}
//           className="background-react-fb-image-grid-cover"
//         />
//       ),
//       more && (
//         <div key={Math.random()} className="cover-text" style={{ fontSize }}>
//           <p>+{extra}</p>
//         </div>
//       ),
//     ]
//   }

//   render() {
//     const { modal, index, countFrom } = this.state
//     const { images, width } = this.props
//     const imagesToShow = [...images]

//     if (countFrom && images.length > countFrom) {
//       imagesToShow.length = countFrom
//     }

//     return (
//       <div
//         className="react-fb-image-grid"
//         style={{
//           width: `${width}%`,
//           height: `${width}%`,
//         }}
//       >
//         {[1].includes(imagesToShow.length) && this.renderOne()}
//         {[3, 4].includes(imagesToShow.length) && this.renderOneOnFour()}

//         {imagesToShow.length >= 2 &&
//           imagesToShow.length !== 4 &&
//           this.renderTwo()}
//         {imagesToShow.length >= 4 && this.renderThree()}

//         {modal && (
//           <Modal onClose={this.onClose} index={index} images={images} />
//         )}
//       </div>
//     )
//   }
// }

// Images.propTypes = {
//   countFrom: PropTypes.number,
//   hideOverlay: PropTypes.bool,
//   onClickEach: PropTypes.func,
//   overlayBackgroundColor: PropTypes.string,
//   renderOverlay: PropTypes.func,
//   width: PropTypes.number,
// }

// export default React.memo(Images)
