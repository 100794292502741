import React from "react";
import NewPostDialogue from "./NewPostDialogue";
import AuthService from "../../../session/AuthService_old";
import i18n from "../../../i18n";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import "./myPost.css";
//import dummyimage from "../../../img/avatar_dummy.png";
import dummyimage from "../../../img/default_avatar.jpg";
import { connect } from "react-redux";
import * as APPCONFIG from "../../../config/app-config.js";
import { withRouter } from "react-router-dom";
import {
  generateMarketPlaceUlr,
  generateUlr,
  getWorkSpaceOption,
  giveUserAction,
  readOnlyWorkspace,
} from "../../../utils";
import { MdEventNote, MdOutlineAddBusiness } from "react-icons/md";
import { needPhotVerification } from "../../../redux/actions/commonApiActions";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { FiLayers, FiShoppingBag } from "react-icons/fi";
import { FaRegLightbulb } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import MemberEvent from "../../../components/MemberEvent.jsx";
// import AskHelpModel from "../../AskHelp/AskHelpQuestionModel.jsx";

const filterList = [
  {
    icon: <HiOutlinePencilAlt />,
    title: "Write your post",
    subTitle: "Write Normal, Polling, Article and Survey posts",
    id: 0,
  },
  {
    icon: <FiShoppingBag />,
    title: "Yuukke Market Place",
    subTitle: "Selling and Buying Products",
    id: 1,
  },
  {
    icon: <MdEventNote />,
    title: "Networking Event",
    subTitle: "Create virtual or in person events",
    id: 2,
  },
  // {
  //   icon: <FaRegLightbulb />,
  //   title: "Ask for Advice",
  //   subTitle: "Raise Your Query Here",
  //   id: 3,
  // },
  {
    icon: <CgProfile />,
    title: "Edit Profile",
    subTitle: "View & Editing your profile",
    id: 4,
  },
  {
    icon: <FiLayers />,
    title: "Business Directory",
    subTitle: "Explore Business Directory",
    id: 5,
  },
  // {
  //   icon: <MdOutlineAddBusiness />,
  //   title: "Add Product",
  //   subTitle: "Add your new products",
  // },
];
const buttonsList = [
  {
    icon: (
      <HiOutlinePencilAlt className="button-svg" style={{ color: "#0cbc87" }} />
    ),
    id: 0,
    title: "Write Post",
  },
  {
    icon: <FiShoppingBag className="button-svg" style={{ color: "#4f9ef8" }} />,
    id: 1,
    title: "Marketplace",
  },
  {
    icon: <MdEventNote className="button-svg" style={{ color: "#ff0000a8" }} />,
    id: 2,
    title: "Event",
  },
  // {
  //   icon: (
  //     <FaRegLightbulb className="button-svg" style={{ color: "#60c2e7" }} />
  //   ),
  //   id: 3,
  //   title: "Advice",
  // },
  {
    icon: "",
    id: 4,
    title: "More",
  },
];

class NewPostContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewPostModel: false,
      content: "",
      active: false,
      createPostPopUpState: false,
      type: "normal",
      postTypePopUpStatus: false,
      eventPage: false,
      askHelp: false,
      backPrevent: false,
      buttons: buttonsList,
      filterMenu: filterList,
    };
    this.handleClickUpload = this.handleClickUpload.bind(this);
    this.createPost = this.createPost.bind(this);
    this.fromPost = this.fromPost.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closeModel = this.closeModel.bind(this);
    this.createPostPopUp = this.createPostPopUp.bind(this);
    this.createPostOld = this.createPostOld.bind(this);
    this.fromMediaGroup = this.fromMediaGroup.bind(this);
  }
  handleClickUpload = async (type, isType) => {
    if (
      this.props.workspaceType !== "default" &&
      readOnlyWorkspace(
        this.props.userWorkSpace?.role,
        this.props.userWorkSpace?.typeHandle
      )
    ) {
      //Not need pop-up
    } else if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else if (isType) {
      await this.setState({
        showNewPostModel: !this.state.showNewPostModel,
        type: isType ? type : "normal",
        // postTypePopUpStatus: !this.state.postTypePopUpStatus,
      });
    } else {
      await this.setState({
        // showNewPostModel: !this.state.showNewPostModel,
        type: isType ? type : "normal",
        postTypePopUpStatus: !this.state.postTypePopUpStatus,
      });
    }
  };
  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    if (this.props.squarehome == "squarepost") {
      this.setState({
        postTypePopUpStatus: false,
        showNewPostModel: true,
        // postTypePopUpStatus: true,
      });
    }
    if (this.props.menuOptions) {
      this.getButtonData(this.props.menuOptions);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.squarehome !== prevProps.squarehome) {
      if (this.props.squarehome == "squarepost") {
        this.setState({
          postTypePopUpStatus: false,
          showNewPostModel: true,
          // postTypePopUpStatus: true,
        });
      }
    }
    if (this.props.menuOptions !== prevProps.menuOptions) {
      this.getButtonData(this.props.menuOptions);
    }
  }
  render() {
    const { t } = this.props;
    const data = this.props.loginDetails.profile_uuid;
    const userName = this.props.loginDetails.dName;
    const profileImage = data
      ? `${APPCONFIG.API_URL}auth/image/${data}`
      : dummyimage;

    return (
      <div className="communityCard newPostCardWrapper">
        <div className="d-flex align-items-center">
          <section className="mb-auto">
            <div className="postuserImg">
              <img
                className="rounded-circle"
                src={profileImage}
                style={{ width: "100%", height: "100%" }}
                alt="user"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyimage;
                }}
              />
            </div>
          </section>
          <section style={{ flex: "1" }}>
            <div className="postInput">
              <input
                value={this.state.shareValue}
                type="text"
                placeholder={t("What do you want to do")}
                onChange={this.handleForm}
                onKeyDown={this.handleClickUpload}
                onMouseDown={this.handleClickUpload}
              />
            </div>

            {/* <ul class="groupPost-nav groupPost-nav-pills groupPost-nav-stack groupPost-small groupPost-fw-normal mt-3 ml-4">
            <li
              class="groupPost-nav-item"
              onClick={() => this.handleClickUpload("article", true)}
            >
              <a
                class="groupPost-nav-link bg-light-new mb-0"
                href="#article"
                data-bs-toggle="modal"
                style={{ cursor: "pointer" }}
                data-bs-target="#feedActionPhoto"
              >
                {" "}
                <MdOutlineArticle
                  className="button-svg"
                  style={{
                    color: "#0cbc87",
                  }}
                />
                {t("Article")}
              </a>
            </li>
            <li
              class="groupPost-nav-item"
              onClick={() => this.handleClickUpload("polling", true)}
            >
              <a
                class="groupPost-nav-link bg-light-new   mb-0"
                href="#polling"
                data-bs-toggle="modal"
                style={{ cursor: "pointer" }}
                data-bs-target="#feedActionVideo"
              >
                {" "}
                <MdOutlinePoll
                  className="button-svg"
                  style={{
                    color: "#4f9ef8",
                  }}
                />{" "}
                {t("Polling")}
              </a>
            </li>
            <li
              class="groupPost-nav-item"
              onClick={() => this.handleClickUpload("survey", true)}
            >
              <a
                href="#survey"
                class="groupPost-nav-link bg-light-new  mb-0"
                data-bs-toggle="modal"
                style={{ cursor: "pointer" }}
                data-bs-target="#modalCreateEvents"
              >
                {" "}
                <RiSurveyLine
                  className="button-svg"
                  style={{
                    color: "#dc3545",
                  }}
                />{" "}
                {t("Survey")}
              </a>
            </li>
          </ul> */}
            <ul class="post-container-btns">
              {this.state.buttons.map((item, index) => (
                <li
                  key={index}
                  class=""
                  onClick={() => this.moveFilter(item.id, true)}
                >
                  <a
                    class="post-container-btn"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#feedActionPhoto"
                  >
                    {" "}
                    {item.icon}
                    <p>{t(`${item.title}`)}</p>
                  </a>
                </li>
              ))}
            </ul>
          </section>
        </div>

        {this.state.showNewPostModel && (
          <NewPostDialogue
            show={true}
            closeModel={this.closeModel}
            fromPostContent={this.createPost}
            fromMediaPost={this.fromPost}
            onHide={this.handleClickUpload}
            userName={this.props.loginDetails.dName}
            userId={this.props.loginDetails.uId}
            tagDatas={this.props.tagDatas}
            fromGroupContent={this.fromMediaGroup}
            fromMediaGroup={this.fromMediaGroup}
            type={this.state.type}
          />
        )}
        {this.state.postTypePopUpStatus && this.postTypePopUp()}
        {this.state.eventPage && (
          <MemberEvent
            handleClosePopUp={this.eventBack}
            backPrevent={this.state.backPrevent}
          />
        )}
        {/* {this.state.askHelp && (
          <AskHelpModel
            handleClosePopUp={this.eventBack}
            backPrevent={this.state.backPrevent}
          />
        )} */}
        {/* {this.state.productPage && (
          <MarketPlaceProducts handleClosePopUp={this.eventBack} />
        )} */}
      </div>
    );
  }
  getButtonData = (data) => {
    console.log("render-menu");
    if (
      this.props.workspaceType !== "default" &&
      !getWorkSpaceOption(data, "marketPlaceMenu")
    ) {
      const removeMenu = buttonsList.filter((item) => item.id !== 1);
      const removeListMenu = filterList.filter((item) => item.id !== 1);

      this.setState({
        buttons: removeMenu,
        filterMenu: removeListMenu,
      });
    } else {
      this.setState({
        buttons: buttonsList,
        filterMenu: filterList,
      });
    }
  };
  eventBack = (type) => {
    this.setState({
      eventPage: false,
      productPage: false,
      askHelp: false,
      postTypePopUpStatus: type === "close" ? false : true,
    });
  };

  handleForm = (event) => {
    if (
      this.props.workspaceType !== "default" &&
      readOnlyWorkspace(
        this.props.userWorkSpace?.role,
        this.props.userWorkSpace?.typeHandle
      )
    ) {
      this.setState({
        shareValue: "",
      });
      //Not need pop-up
    } else if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      this.props.needPhotVerification(true);
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  createPostPopUp() {
    const { t } = this.props;
    return (
      <div>
        {/* <ToastContainer position="top-right" autoClose={5000} /> */}
        <Modal centered show="true" onHide={this.handleClose}>
          <Modal.Header className="text-center">
            <Modal.Title
              id="contained-modal-title-center"
              className="uploadPostTitle"
            >
              <p>{t("Create Post")}</p>
              <span
                className="closeBtn"
                style={{ cursor: "pointer" }}
                onClick={this.handleClose}
              >
                X
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="uploadWrapper">
              <div className="uploadPostInput">
                <textarea
                  style={{ resize: "none" }}
                  placeholder={t("What do you want to share")}
                  value={this.state.content}
                  name="content"
                  onChange={this.handleForm}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="uploadFileModelFooter">
            <button onClick={this.createPost}>{t("Post")}</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  createPost(
    file,
    type,
    content,
    data,
    postingType,
    groupOrNot,
    groupId,
    articleHeading
  ) {
    this.closeModel();
    this.setState(
      {
        showNewPostModel: false,
        postTypePopUpStatus: false,
      },
      () => {
        this.props.fromPostFinalContent(
          file,
          type,
          content,
          data,
          postingType,
          groupOrNot,
          groupId,
          articleHeading
        );
      }
    );
  }

  fromMediaGroup(file, type, content, data, postingType, groupOrNot, groupId) {
    this.closeModel();
    this.setState(
      {
        showNewPostModel: false,
        postTypePopUpStatus: false,
      },
      () => {
        this.props.fromMediaGroup(
          file,
          type,
          content,
          data,
          postingType,
          groupOrNot,
          groupId
        );
      }
    );
  }

  closeModel() {
    this.setState({
      showNewPostModel: false,
      postTypePopUpStatus: false,
      type: "normal",
    });
    this.props.history.push(`/${this.props.workspaceType}/home`);
  }

  fromPost(file, type, content, active) {
    this.setState(
      {
        showNewPostModel: false,
        postTypePopUpStatus: false,
      },
      () => {
        this.props.fromPostFinal(file, type, content, active);
      }
    );
  }
  handleClose() {
    this.setState({ createPostPopUpState: false, content: "" });
  }
  createPostOld() {
    this.setState({ createPostPopUpState: true });
  }
  handleClosePopUp = () => {
    this.setState({ postTypePopUpStatus: false });
  };
  moveFilter = (index, isHome) => {
    if (
      this.props.workspaceType !== "default" &&
      readOnlyWorkspace(
        this.props.userWorkSpace?.role,
        this.props.userWorkSpace?.typeHandle
      )
    ) {
      //Not need pop-up
    } else if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      this.setState({
        backPrevent: isHome,
      });
      if (index === 1) {
        generateMarketPlaceUlr(this.props.marketplaceUrl);
        // window.open("https://marketplace.yuukke.com/openid/oauth2", "_blank");
      } else if (index === 2) {
        this.setState({
          eventPage: true,
          postTypePopUpStatus: false,
        });
        // this.props.history.push(`/${this.props.workspaceType}/mygroups`);
      } else if (index === 3) {
        this.setState({
          askHelp: true,
          postTypePopUpStatus: false,
        });
        // this.props.history.push(
        //   `/${this.props.workspaceType}/ask-help-form/${"new"}`
        // );
      } else if (index === 4 && isHome) {
        this.setState({
          postTypePopUpStatus: true,
        });
      } else if (index === 4) {
        this.props.history.push(`/my-profile`);
      } else if (index === 5) {
        this.props.history.push(`/${this.props.workspaceType}/directory`);
        // this.setState({
        //   productPage: true,
        //   postTypePopUpStatus: false,
        // });
      } else {
        this.setState({
          postTypePopUpStatus: false,
          showNewPostModel: true,
        });
      }
    }
  };
  postTypePopUp = () => {
    const { t } = this.props;
    return (
      <div>
        <Modal
          show="true"
          onHide={this.handleClosePopUp}
          style={{ borderRadius: "12px" }}
        >
          <Modal.Header className="text-center">
            <AiOutlineCloseCircle
              onClick={this.handleClosePopUp}
              style={{
                cursor: "pointer",
                marginLeft: "auto",
                marginBottom: "8px",
                width: "20px",
                height: "20px",
                color: "#000000",
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <ul className="menu-filter-list">
              {this.state.filterMenu.map((item, index) => (
                <>
                  <li
                    key={index}
                    onClick={() => this.moveFilter(item.id, false)}
                  >
                    {item.icon}
                    <div>
                      <h4>{t(`${item.title}`)}</h4>
                      <p className="text-secondary">{item.subTitle}</p>
                    </div>
                  </li>
                  <hr className="m-0" />{" "}
                </>
              ))}
            </ul>
          </Modal.Body>
        </Modal>
      </div>
    );
  };
}

const mapStateToProps = function (state) {
  // console.log("Loading request friend Props");
  // console.log(state.commonApi.friendslist);
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
    workspaceType: state.loginDetailsApi.loginDetails?.userWorkSpace?.slug,
    userWorkSpace: state.loginDetailsApi.loginDetails?.userWorkSpace,
    menuOptions: state.commonApi.getWorkSpaceOption,
    marketplaceUrl: state.commonApi.requireMentMenu?.marketplaceUrl,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(NewPostContainer)));
