import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import { withTranslation } from "react-i18next";
import { IoPersonRemoveOutline } from "react-icons/io5";
import { RiSendPlaneFill } from "react-icons/ri";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import MyYuukke from "../../api/MyYuukke";
import CsvUpload from "../../components/CsvUpload";
import InviteUser from "../../components/InviteUser";
import users from "../../img/workspace_user.csv";

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

export const UserList = (props) => {
  const { t } = props;
  const [userList, setUserList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [addUserStatus, setAddUserStatus] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [csvData, setCsvData] = useState(null);
  const [csvModel, setCsvModel] = useState(false);
  const workspaceType = useSelector(
    (state) => state.loginDetailsApi.loginDetails.userWorkSpace?.slug
  );
  const userRole = useSelector(
    (state) => state.loginDetailsApi.loginDetails?.userWorkSpace?.role
  );
  const loginUserId = useSelector(
    (state) => state.loginDetailsApi.loginDetails.uId
  );
  const workSpaceOwnerUserId = useSelector(
    (state) => state.loginDetailsApi.loginDetails?.userWorkSpace?.userId
  );
  useEffect(() => {
    seeMoreUser(1, "");
  }, []);

  const seeMoreUser = (pageNo, searchValue) => {
    var search = {
      searchKey: searchValue,
      page: pageNo - 1,
    };

    MyYuukke.work_space_user_list(search)
      .then((response) => {
        setUserList(response.data.data.workSpace.content);
        setTotalElements(response.data.data.workSpace.totalElements);
        setSearchData(searchValue);
        setPageNo(pageNo);
      })
      .catch(console.log("error"));
  };

  const seeMoreUserPagination = (pageNo) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    seeMoreUser(pageNo, searchData);
  };
  const removeUser = (id) => {
    var search = {
      userId: id,
      isJoin: false,
    };

    MyYuukke.work_space_join_or_unjoin(search)
      .then((response) => {
        toast.success("User Removed Successfully");
        const filterList = userList.filter((data) => data.userId !== id);
        setUserList(filterList);
      })
      .catch(console.log("error"));
  };
  const emailInvite = (value) => {
    MyYuukke.work_space_add_user(value)
      .then((response) => {
        toast.success("User invited successfully");
        seeMoreUser(pageNo, searchData);
        setAddUserStatus(false);
      })
      .catch((e) => console.log("error", e));
  };
  const handleChangeUserType = (e, uId) => {
    var search = {
      userId: uId,
      userModerate: e.target.value,
      isJoin: true,
    };

    MyYuukke.work_space_join_or_unjoin(search)
      .then((response) => {
        toast.success("User Role Changed Successfully");
        const userData = userList.map((element) => {
          if (element.userId === uId) {
            element.role = e.target.value;
          }
          return element;
        });
        setUserList(userData);
      })
      .catch(console.log("error"));
  };
  const loadCSVdata = (data, fileInfo) => {
    //alert(data.length)
    var ndata = [];
    for (var i = 0; i < data.length; i++) {
      var obj = data[i];
      ndata.push(obj);
    }
    setCsvData(ndata);
    console.log("ndata", ndata);
  };
  const uploadCsv = () => {
    if (!csvData) {
      toast.warning("Select CSV file");
    } else {
      console.log("csv", csvData);
      var search = {
        csvWorkSpaceUser: csvData,
      };

      MyYuukke.csv_work_space_add_user(search)
        .then((response) => {
          document.querySelector(".csv-input").value = "";
          setCsvData(null);
          toast.success("Csv uploaded successfully");
          seeMoreUser(1, "");
        })
        .catch(console.log("error"));
    }
  };

  return (
    <div style={{ paddingBottom: "7rem" }}>
      <h3 className="listHomeTitle">{t("Members List")}</h3>
      <div class="userListCard">
        <div className="userList mb-3">
          <div>
            {(userRole === 1 || userRole === 2) && (
              <button
                style={{
                  borderColor: "#088F8F",
                  background: "#088F8F",
                }}
                onClick={() => setAddUserStatus(!addUserStatus)}
              >
                + {t("Add User")}
              </button>
            )}
          </div>

          {addUserStatus && (
            <InviteUser
              emailData={emailInvite}
              closeInvitePage={() => setAddUserStatus(false)}
              workSpace={workspaceType}
            />
          )}
          {csvModel && (
            <CsvUpload
              closeCsvModel={() => setCsvModel(false)}
              exCsvFile={users}
              onUploadComplete={() => seeMoreUser(1, "")}
            />
          )}
          {(userRole === 1 || userRole === 2) && (
            <div>
              <button
                style={{
                  borderColor: "#088F8F",
                  background: "#088F8F",
                }}
                onClick={() => setCsvModel(true)}
              >
                {t("Upload Csv")}
              </button>
            </div>
          )}
          <div className=" userList-search ml-auto">
            <div className="search">
              <input
                id="searchData"
                type="search"
                name="searchData"
                // value={this.state.searchData}
                placeholder={"Search User"}
                onChange={(e) => seeMoreUser(1, e.target.value)}
                autocomplete="on"
              />
              <button type="search">{"Search"}</button>
            </div>
          </div>
        </div>

        {/* {(userRole === 1 || userRole === 2) && (
          <div className="d-flex flex-column mb-3">
            <div className="d-flex align-items-center" style={{ gap: "2rem" }}>
              <CSVReader
                cssClass="csv-button"
                parserOptions={papaparseOptions}
                onFileLoaded={(data, fileInfo) => loadCSVdata(data, fileInfo)}
              />
              <div style={{ position: "relative" }}>
                {" "}
                {csvData && (
                  <span class="badge badge-pill badge-warning user-count">
                    {csvData?.length} Users
                  </span>
                )}
                <RiSendPlaneFill className="svg-icons" onClick={uploadCsv} />
              </div>
            </div>
            <div>
              <a className="underline-text mt-2" target="_blank" href={users}>
                Download sample file
              </a>
            </div>
          </div>
        )} */}

        <div class="table-responsive">
          <table class="table  bg-white">
            <thead class="thead-light">
              <tr>
                <th scope="col">{t("Name")}</th>
                <th scope="col">{t("Location")} </th>
                <th scope="col">{t("Profession")} </th>
                {(userRole === 1 || userRole === 2) && (
                  <th scope="col">{t("Remove User")} </th>
                )}
                {userRole === 1 && <th scope="col">{t("Change To Admin")} </th>}
              </tr>
            </thead>
            <tbody>
              {userList &&
                userList.map((data) => (
                  <tr key={data.uId}>
                    <th scope="row">{data.userName ? data.userName : "-"}</th>
                    <td>{data.location ? data.location : "-"}</td>

                    <td>{data.professionName ? data.professionName : "-"}</td>

                    {(userRole === 1 || userRole === 2) && (
                      <td>
                        {(userRole === 2 || userRole === 1) &&
                        data.userId === loginUserId ? (
                          // "You"
                          <>
                            {userRole == 2 && <>Admin</>}
                            {userRole == 1 && <>Owner</>}
                          </>
                        ) : data.role === 1 && userRole == 2 ? (
                          <>{"Owner"}</>
                        ) : (
                          // <td style={{ color: "#a00030" }}>{"Owner"}</td>
                          <IoPersonRemoveOutline
                            style={{
                              width: "22",
                              height: "22",
                              cursor: "pointer",
                              color: "#a00030",
                            }}
                            onClick={() => removeUser(data.userId)}
                          />
                        )}
                      </td>
                    )}
                    {/* {data.role === 1 && data.userId === loginUserId && (
                      <td style={{ color: '#a00030' }}>You</td>
                    )} */}
                    {/* {data.role === 1 && userRole !== 1 && userRole !== 0 && (
                      <td style={{ color: '#a00030' }}>Owner</td>
                    )} */}
                    {userRole === 1 && (
                      <td style={{ color: "#a00030" }}>
                        {data.role === 1 && data.userId === loginUserId ? (
                          // "You"
                          <>
                            {data.role === 2 && <>Admin</>}
                            {data.role === 1 && <>Owner</>}
                          </>
                        ) : (
                          <FormControl>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={data.role === null ? 0 : data.role}
                              onChange={(e) =>
                                handleChangeUserType(e, data.userId)
                              }
                            >
                              <MenuItem value={0}>{"User"}</MenuItem>
                              <MenuItem value={2}>{"Admin User"}</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div>
          {userList && userList.length === 0 && <p>{"No users present.."}</p>}
        </div>
        {userList && userList.length !== 0 && (
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Pagination
              itemsCountPerPage={10}
              activePage={pageNo}
              totalItemsCount={totalElements}
              pageRangeDisplayed={5}
              onChange={seeMoreUserPagination}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(withRouter(UserList));
