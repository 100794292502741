import { STORE_PERSONAL_LANGUAGE } from "../types";
import { GET_LOGOUT_DATA } from "../types";
const initialState = {
  lang: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STORE_PERSONAL_LANGUAGE:
      console.log("STORE_PERSONAL_LANGUAGE");
      console.log(action.payload);
      return {
        ...state,
        lang: action.payload,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");

      return {
        ...initialState,
      };

    default:
      return state;
  }
}
