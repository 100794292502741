import HomeService from "../../api/HomeService";
import { setLocalStorage } from "../../utils";
import {
  GET_POST_LIST,
  GET_POST_LIST_LOADER_STATUS,
  NEW_POST,
  UPDATE_POST_LIKE,
  DELETE_POST,
  COMMENTS_COUNT_UPDATE,
  SUBSCRIBE_POST,
  UPDATE_POST,
  POST_LIKE_USER_LIST,
  POST_NOT_PRESENT,
  GET_CONNECTION_FRIEND_LIST,
  UPDATE_CONNECTION_FRIEND_LIST,
  LIKE_POINTS,
  LIKE_AND_COMMENT_UPDATE,
  LOAD_LOCAL_POST,
} from "../types";

export const getPostList = (type, pageNo, pagination) => async (dispatch) => {
  dispatch({
    type: GET_POST_LIST_LOADER_STATUS,
    payload: true,
  });
  try {
    HomeService.getPostByFilter(type)
      .then((response) => {
        if (response.data.data.userpost) {
          if (!pagination) {
            setLocalStorage("POSTS", response.data.data.userpost.slice(0, 5));
          }
          dispatch({
            type: GET_POST_LIST,
            payload: response.data.data,
            pagination: pagination,
            pageNo: pageNo,
          });
        } else {
          dispatch({
            type: GET_POST_LIST,
            payload: [],
            pagination: false,
            pageNo: pageNo,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: GET_POST_LIST,
          payload: [],
          pagination: false,
          pageNo: 0,
        });
      });
  } catch {
    dispatch({
      type: GET_POST_LIST,
      payload: [],
      pagination: false,
      pageNo: 0,
    });
  }
};

export const createNewPost = (data, config, toastData) => async (dispatch) => {
  try {
    HomeService.createHomePost(data, config)
      .then((response) => {
        var result = response.data.data;
        if (result) {
          dispatch({
            type: NEW_POST,
            payload: response.data.data,
            toastData: toastData,
          });
        } else {
          dispatch({
            type: NEW_POST,
            payload: {},
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: NEW_POST,
          payload: {},
        });
      });
  } catch {}
};
export const createNewPostV2 = (data, toastData) => async (dispatch) => {
  try {
    dispatch({
      type: NEW_POST,
      payload: data,
      toastData: toastData,
    });
  } catch (e) {
    dispatch({
      type: NEW_POST,
      payload: {},
    });
    console.log("error", e);
  }
};

export const createPostLike =
  (data, index, alreadyLiked) => async (dispatch) => {
    try {
      HomeService.createpostLike(data)
        .then((response) => {
          var result = response.data.data;
          if (response.data.status === -2) {
            dispatch({
              type: POST_NOT_PRESENT,
              payload: data.postId,
            });
          } else if (result) {
            dispatch({
              type: UPDATE_POST_LIKE,
              payload: result,
              alreadyLiked: alreadyLiked,
              userLike: data.userLike,
              activeIndex: index,
            });
          } else {
            dispatch({
              type: UPDATE_POST_LIKE,
              payload: {},
              activeIndex: -1,
            });
          }
        })
        .catch((e) => {
          dispatch({
            type: UPDATE_POST_LIKE,
            payload: {},
            activeIndex: -1,
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

export const deletePost = (id, toastData) => async (dispatch) => {
  try {
    HomeService.deletePost(id)
      .then((response) => {
        if (response) {
          dispatch({
            type: DELETE_POST,
            payload: id.postId,
            toastData: toastData,
          });
        } else {
          dispatch({
            type: DELETE_POST,
            payload: null,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: DELETE_POST,
          payload: null,
        });
      });
  } catch {
    dispatch({
      type: DELETE_POST,
      payload: null,
    });
  }
};
export const commentsCountUpdate = (postId, count) => async (dispatch) => {
  let data = {
    postId: postId,
    count: count ? count : 0,
  };
  dispatch({
    type: COMMENTS_COUNT_UPDATE,
    payload: data,
  });
};
export const sharePost = (data, toastData) => async (dispatch) => {
  dispatch({
    type: NEW_POST,
    payload: data,
    toastData: toastData,
  });
};
export const subscribePost =
  (postId, type, status, moderationId, bookmark) => async (dispatch) => {
    let data = {
      result: postId,
      type: type,
      status: status,
      moderationId: moderationId,
      bookmark: bookmark,
    };
    dispatch({
      type: SUBSCRIBE_POST,
      payload: data,
    });
  };
export const updatePostData = (data, toastData) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_POST,
      payload: data ? data : null,
      toastData: toastData,
    });
  } catch {
    dispatch({
      type: UPDATE_POST,
      payload: null,
    });
  }
};

export const getHashTagPost =
  (type, hash, pageNo, pagination) => async (dispatch) => {
    dispatch({
      type: GET_POST_LIST_LOADER_STATUS,
      payload: true,
    });
    try {
      let reqData = {
        hashTagKey: type + hash,
        page: pageNo,
      };
      HomeService.getPostByHashTag(reqData)
        .then((response) => {
          if (response.data.data.userpost) {
            dispatch({
              type: GET_POST_LIST,
              payload: response.data.data,
              pagination: pagination,
              pageNo: pageNo,
            });
          } else {
            dispatch({
              type: GET_POST_LIST,
              payload: [],
              pagination: false,
              pageNo: pageNo,
            });
          }
        })
        .catch((e) => {
          dispatch({
            type: GET_POST_LIST,
            payload: [],
            pagination: false,
            pageNo: 0,
          });
        });
    } catch (e) {
      dispatch({
        type: GET_POST_LIST,
        payload: [],
        pagination: false,
        pageNo: 0,
      });
    }
  };

export const getPostLikeUsers = (data, pagination, all) => async (dispatch) => {
  try {
    HomeService.getPostLikeUsers(data)
      .then((response) => {
        if (response.data.data.likeUsersList) {
          dispatch({
            type: POST_LIKE_USER_LIST,
            payload: response.data.data,
            pagination: pagination,
            pageNo: data.page,
            needCount: all,
            postId: data.postId,
          });
        } else {
          dispatch({
            type: POST_LIKE_USER_LIST,
            payload: [],
            pagination: false,
            pageNo: 0,
            needCount: "no",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: POST_LIKE_USER_LIST,
          payload: [],
          pagination: false,
          pageNo: 0,
          needCount: "no",
        });
      });
  } catch (e) {
    dispatch({
      type: POST_LIKE_USER_LIST,
      payload: [],
      pagination: false,
      pageNo: 0,
      needCount: "no",
    });
  }
};

export const removePost = (id, toastData) => async (dispatch) => {
  dispatch({
    type: DELETE_POST,
    payload: id,
    toastData: toastData,
  });
};

export const postNotPresent = (id) => async (dispatch) => {
  dispatch({
    type: POST_NOT_PRESENT,
    payload: id,
  });
};

export const getConnectionFriendList =
  (pagination, page, userId) => async (dispatch) => {
    let data = {
      userId: userId,
      page: page,
    };
    HomeService.friendList(data)
      .then((response) => {
        dispatch({
          type: GET_CONNECTION_FRIEND_LIST,
          payload: response.data.data,
          pagination: pagination,
          pageNo: page,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_CONNECTION_FRIEND_LIST,
          payload: [],
          pagination: false,
          pageNo: page,
        });
      });
  };

export const updateConnectionFriendList =
  (data, deleteData) => async (dispatch) => {
    dispatch({
      type: UPDATE_CONNECTION_FRIEND_LIST,
      payload: data,
      deleteData: deleteData,
    });
  };

export const getLikeCountPopUp = (data) => async (dispatch) => {
  dispatch({
    type: LIKE_POINTS,
    payload: data,
  });
};

export const likeAndCommentUpdate = (data) => async (dispatch) => {
  dispatch({
    type: LIKE_AND_COMMENT_UPDATE,
    payload: data,
  });
};

export const updateLocalPost = (data) => async (dispatch) => {
  dispatch({
    type: LOAD_LOCAL_POST,
    payload: data,
  });
};
