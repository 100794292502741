import * as APPCONFIG from "../config/app-config.js";
import auth from "./AuthAPI.js";
import Http from "./Http.js";
class AnnouncementService {
  getAnnouncementList() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallAnnouncement`,
    });
    // return axios.get(API_URL + "getallsubject")
  }

  getAnnouncementCount() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAnnouncementCount`,
    });
  }
  saveAnnouncement(formData) {
    console.log(formData);
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveAnnouncement`,
      data: formData,
    });
    //return axios.post(API_URL + 'savesubject', object)
  }

  getAnnouncementById(anounce) {
    console.log(APPCONFIG.API_URL);
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallAnnouncementbyid`,
      data: JSON.stringify(anounce),
    });
    // return axios.post(API_URL + `getallSubjectByid`, subject)
  }

  updateAnnouncement(formData) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateAnnouncementv2`,
      data: formData,
    });
    // return axios.post(API_URL + `getallSubjectByid`, subject)
  }

  delete(track) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteAnnouncement`,
      data: JSON.stringify(track),
    });
    // return axios.post(API_URL + `getallSubjectByid`, subject)
  }

  getAnnouncementReportById(value) {
    console.log(value);
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/generateAnnouncementReport`,
      data: value,
    });
  }
}
export default new AnnouncementService();
