import { TextField } from "@material-ui/core";
import React from "react";

const InputField = ({ label, type, value, error, onChange, required }) => {
  return (
    <div className=" col-12 col-md-6 col-lg-6 mt-3">
      <label>
        {label} {required && "*"}
      </label>
      <TextField
        fullWidth
        variant="outlined"
        type={type}
        required="required"
        name=""
        onChange={onChange}
        value={value}
      />
    </div>
  );
};
InputField.defaultProps = {
  label: "",
  type: "text",
  value: "",
  error: false,
  required: false,
};

export default InputField;
