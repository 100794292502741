import axios from "axios";
import React from "react";
import "./meeting.css";
import { connect } from "react-redux";
import moment from "moment";

import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CHAT_URL, JITSI_URL } from "../../config/app-config";

function Meeting({ userDetails }) {
  const en = moment().locale("en");

  const [meetingList, setMeetingsList] = React.useState([]);
  const [selectedMeeting, setSelectedMeeting] = React.useState(null);
  const [loginedUser, setLoginedUsers] = React.useState(null);
  const [meetingDetails, setMeetingDetails] = React.useState(false);
  React.useEffect(() => {
    if (userDetails) {
      setLoginedUsers(userDetails);
    }
    const getMeetingList = async () => {
      console.log("User Details", userDetails);
      // `http://localhost:5000/api/getAllMeeting`,
      const response = await axios.get(
        `${CHAT_URL}api/getAllMeeting/${userDetails?.loginDetails.uId}`
      );
      const result = response.data;
      console.log(result);
      setMeetingsList(result.data);
      // if (result.data.length > 0) {
      //     setSelectedMeeting(result.data[0])
      // }
    };
    if (!(window.innerWidth <= 768 || window.innerWidth == 768)) {
      window.scrollTo({
        top: 0,
        behavior: "auto",
        //  'smooth'
      });
    }
    getMeetingList();
  }, []);
  const handleSelectedMeeting = (data) => {
    setMeetingDetails(true);
    setSelectedMeeting(data);
  };

  const onClickMeetingClose = () => {
    setMeetingDetails(false);
  };

  return (
    <div className="screenInnerGap">
      <div
        className="homeMainWrapper"
        style={{ marginTop: "80px", marginLeft: "20px" }}
      >
        <>
          <div>
            <Grid container spacing={2}>
              <Grid item lg={9} md={8} sm={12} xs={12}>
                <h3 className="listHomeTitle">Meeting List</h3>
              </Grid>
            </Grid>
          </div>
        </>

        <div className="">
          <Grid container spacing={2} alignItems="flex-end" wrap="nowrap">
            <Grid item lg={4} md={4} xs={12} sm={12}>
              <div className="profileselectCard">
                <div className="meetingSideBar--Heading">Meeting List</div>
                {meetingList &&
                  meetingList.length > 0 &&
                  meetingList.map((meeting) => (
                    <div
                      className="meetingListCard"
                      onClick={() => handleSelectedMeeting(meeting)}
                    >
                      <p
                        style={{
                          color: "#000000",
                          padding: "8px 2px 0px 8px",
                          cursor: "pointer",
                        }}
                      >
                        {meeting?.message?.meeting?.meetingName ||
                          `${meeting?.senderName}'s Meeting`}{" "}
                      </p>
                      <p
                        style={{
                          color: "gray",
                          paddingLeft: "8px",
                          fontSize: "12px",
                          cursor: "pointer",
                        }}
                      >
                        {meeting?.message?.meeting?.meetingDescription ||
                          "Join Meeting"}
                      </p>
                    </div>
                  ))}
              </div>
            </Grid>
            <Grid item lg={8} md={8} xs={12} sm={12}>
              <div
                className="profileselectCard"
                style={{ position: "relative" }}
              >
                <div>
                  {!meetingDetails && (
                    <p
                      style={{
                        fontSize: "22px",
                        textAlign: "center",
                        color: "#A00030",
                        top: "0",
                        left: "0",
                      }}
                    >
                      Empty List
                    </p>
                    
                  )}
                </div>
                {meetingDetails && (
                  <>
                    <div className="meetingModal">
                      <Grid
                        container
                        spacing={2}
                        alignItems="flex-end"
                        wrap="nowrap"
                      >
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <p
                            style={{ fontSize: "22px" }}
                          >{`${selectedMeeting?.senderName} has invited you,`}</p>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <AiOutlineCloseCircle
                            style={{
                              cursor: "pointer",
                              float: "right",
                              marginBottom: "8px",
                              width: "20px",
                              height: "20px",
                              color: "#A00030",
                            }}
                            onClick={() => onClickMeetingClose()}
                          ></AiOutlineCloseCircle>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        alignItems="flex-end"
                        wrap="nowrap"
                      >
                        <Grid item lg={4} md={4} xs={12} sm={12}>
                          <div
                            className="meetingSelectCard"
                            style={{ position: "relative" }}
                          >
                            <h6 style={{ padding: "12px" }}>Title</h6>

                            <h6 style={{ padding: "12px" }}>Organizer</h6>

                            <h6 style={{ padding: "12px" }}>Date & Time</h6>

                            <h6 style={{ padding: "12px" }}>Description</h6>
                          </div>
                        </Grid>
                        <Grid item lg={8} md={8} xs={12} sm={12}>
                          <div className="meetingSelectRightCard">
                            {selectedMeeting ? (
                              <>
                                <h6 style={{ padding: "12px" }}>
                                  {selectedMeeting?.message?.meeting
                                    ?.meetingName ||
                                    `${selectedMeeting?.senderName}'s Meeting`}
                                </h6>

                                <h6
                                  style={{ padding: "12px" }}
                                >{`${selectedMeeting?.senderName}`}</h6>

                                <h6 style={{ padding: "12px" }}>
                                  {moment(
                                    selectedMeeting?.message?.meeting
                                      ?.meetingTime
                                  )
                                    .locale("en")
                                    .format("dddd, MMMM Do YYYY, h:mm ")}
                                </h6>

                                <h6 style={{ padding: "12px" }}>
                                  {
                                    selectedMeeting?.message?.meeting
                                      ?.meetingDescription
                                  }
                                </h6>

                                <button
                                  style={{ marginTop: "50px", color: "#fffff" }}
                                  className="meeting-btn"
                                >
                                  <a
                                    target="_blank"
                                    className="newanchor"
                                    href={`${JITSI_URL}/${selectedMeeting?.message?.meeting?.meetingLink}?authenticated=true#config`}
                                    style={{ color: "white" }}
                                  >
                                    {" "}
                                    Join Meeting
                                  </a>
                                </button>

                                {/* <div className='meetingMainBar--heading'>
                                                                            <h6 style={{ padding: "12px" }}></h6>
                                                                            <h6 style={{ padding: "12px" }}></h6>
                                                                            <span style={{ padding: "12px" }}></span>
                                                                            <div className=''></div>
                                                                                 
                                                                        </div> */}

                                {/* <div className='meetingMainBar--body ' style={{
                                                                            padding: "12px",
                                                                            justifyContent: selectedMeeting?.message?.meeting?.meetingDescription.length > 0 ? 'flex-start' : 'center'
                                                                        }}>
                                                                            {
                                                                                selectedMeeting?.message?.meeting?.meetingDescription.length > 0
                                                                                    ?
                                                                                    (
                                                                                        <>
                                                                                        </>
                                                                                    )
                                                                                    : (
                                                                                        <>
                                                                                            <button variant="contained" className='meeting-btn'>
                                                                                            <a target='_blank' className='meeting-link' href={`http://meeting.jitsi.betalearnings.com/${selectedMeeting?.message?.meeting?.meetingLink}?authenticated=true&userId=${loginedUser?.loginDetails?.uId}&username=${loginedUser?.loginDetails?.dName}`} style={{ color: "white" }}> Join Meeting</a>
                                                                                            </button>
                                                                                        </>
                                                                                    )
                                                                            }
                                                                        </div> */}
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%,-50%)",
                                  }}
                                >
                                  No Meeting Are Availiable
                                </div>
                              </>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    userDetails: state.loginDetailsApi,
    friendsList: state.commonApi.friendslist,
  };
};

export default connect(mapStateToProps)(Meeting);
