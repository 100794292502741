import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import mentionsInputStyle from "./mentionsInputStyle";
import { API_URL } from "../../config/app-config";
import dummyimage from "../../img/default_avatarOld.jpg";
import { useSelector } from "react-redux";
import { readOnlyWorkspace } from "../../utils";
import { withTranslation } from "react-i18next";

function CustomForm(props) {
  const [comments, setComments] = useState("");
  const [users, setUser] = useState([]);
  const loginDetails = useSelector(
    (state) => state.loginDetailsApi.loginDetails
  );
  useEffect(() => {
    addFriendList();
  }, [props.friendList]);
  useEffect(() => {
    if (props.needEdit) {
      console.log("editorValue", props.editorValue);
      props.changeNeedHelpState();
      setComments(props.editorValue);
    }
  }, [props.needEdit]);
  const addFriendList = () => {
    const filterList =
      props.friendList &&
      props.friendList.map((data) => ({
        id: data.userId,
        display: data.userName,
        // userId: data.userId,
      }));
    console.log("filterList", filterList);
    console.log("filterList", props.friendList);

    setUser(filterList);
  };

  const users1 = [
    {
      id: 814,
      display: "Rani",
    },
    {
      id: 814,
      display: "Sam",
    },
    {
      id: 814,
      display: "Mano",
    },
  ];
  const onChangeComment = (value) => {
    if (
      props.isGroup &&
      readOnlyWorkspace(
        loginDetails?.userWorkSpace?.role,
        loginDetails?.userWorkSpace?.typeHandle
      )
    ) {
      setComments("");
    } else {
      setComments(value);
      props.editorStateValue(value);
    }
  };

  return (
    <MentionsInput
      placeholder={`${props.t(props.placeholder)}`}
      value={comments}
      onChange={(e) => onChangeComment(e.target.value)}
      style={mentionsInputStyle}
      a11ySuggestionsListLabel={"Suggested mentions"}
      positionSuggestions="top"
    >
      <Mention
        style={{ backgroundColor: "#cee4e5", with: "100%" }}
        trigger="@"
        data={users}
        renderSuggestion={(suggestion, search, display) => (
          <UserMention
            display={display}
            id={suggestion.id}
            searchValue={search}
          />
        )}
      />
      {/* <Mention style={{ backgroundColor: "#cee4e5" }} data={users} /> */}
    </MentionsInput>
  );
}

const UserMention = ({ display, id, searchValue }) => {
  return (
    <div>
      <div
        key={id}
        className="d-flex align-items-center"
        style={{ gap: "1rem", width: "auto" }}
      >
        <img
          src={API_URL + "api/getUserPhoto/" + id + "/name"}
          alt={"name"}
          style={{ width: "48px", height: "48px" }}
          className=" rounded-circle"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = dummyimage;
          }}
        />{" "}
        <p> {display}</p>
      </div>{" "}
    </div>
  );
};

export default withTranslation()(CustomForm);
