import { profiles } from "../config/keycloak-config";
import AuthService from "../session/AuthService";

class http {
  HEADERS() {
    return {
      "Content-Type": "application/json",
      Accept: "*/*",
      //     Authorization: "JWT fefege...",
    };
  }
  AUTH_HEADERS() {
    return {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: AuthService.getToken(),
      // 'Client-Ip': AuthService.getIpAddress()
    };
  }
  WORK_SPACE() {
    return sessionStorage.getItem("WORKSPACE")
      ? sessionStorage.getItem("WORKSPACE")
      : "default";
  }
  isProfileExists(name) {
    if (profiles !== undefined && profiles != null) {
      return profiles.includes(name);
    } else {
      return false;
    }
  }
}
export default new http();
