import React, { useEffect, useRef, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PostService_old from "../../../api/PostService_old";
import { useDispatch, useSelector } from "react-redux";
import { getResourceMediaFiles } from "../../../redux/actions/subjectActions";
import { API_URL } from "../../../config/app-config";
import InfiniteScroll from "react-infinite-scroll-component";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const ResourceMediaFile = (props) => {
  const colors = ["red", "blue", "green", "black", "orange"];
  const divRef = useRef();
  const {
    subjectId,
    resourceType,
    resourceFilterType,
    t,
    playlistId,
    playList,
  } = props;
  const [mediaFiles, setMediaFiles] = useState([]);
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(0);
  const workspace = useSelector(
    (state) => state.loginDetailsApi.loginDetails.userWorkSpace?.slug
  );
  const { resourceMediaSeeMore, resourceMediaFiles } = useSelector(
    (state) => state.subjects
  );
  useEffect(() => {
    var data = {};
    if (playList && playlistId) {
      data = {
        subjectId: +subjectId,
        playlistId: playlistId,
        page: 0,
        size: 6,
      };
    } else if (resourceType === 0 && resourceFilterType) {
      const type =
        resourceFilterType === "document"
          ? "pdf"
          : resourceFilterType === "video"
          ? "video"
          : "image";
      data = {
        subjectId: +subjectId,
        type: type,
        page: 0,
        size: 6,
      };
    } else {
      data = {
        subjectId: +subjectId,
        page: 0,
        size: 6,
      };
    }
    setPageNo(0);
    dispatch(getResourceMediaFiles(data, false));
  }, [subjectId, resourceFilterType]);

  // useEffect(() => {
  //   setMediaFiles(resourceMediaFiles);
  // }, [resourceMediaFiles]);

  // useEffect(() => {
  //   if (resourceType === 0 && resourceFilterType) {
  //     const type =
  //       resourceFilterType === "document"
  //         ? "pdf"
  //         : resourceFilterType === "video"
  //         ? "video"
  //         : "image";
  //     console.log("type", type);
  //     const filterType = resourceMediaFiles.filter(
  //       (item) => item.postType === type
  //     );
  //     console.log("type", filterType);
  //     setMediaFiles(filterType);
  //   }
  // }, [resourceMediaFiles, resourceFilterType]);

  const needMoreData = () => {
    var data = {};
    if (playList && playlistId) {
      data = {
        subjectId: +subjectId,
        playlistId: playlistId,
        page: pageNo + 1,
        size: 6,
      };
    } else if (resourceType === 0 && resourceFilterType) {
      const type =
        resourceFilterType === "document"
          ? "pdf"
          : resourceFilterType === "video"
          ? "video"
          : "image";
      data = {
        subjectId: +subjectId,
        type: type,
        page: pageNo + 1,
        size: 6,
      };
    } else {
      data = {
        subjectId: +subjectId,
        page: pageNo + 1,
        size: 6,
      };
    }
    dispatch(getResourceMediaFiles(data, true));
    setPageNo(pageNo + 1);
  };
  console.log("resource-files", resourceMediaFiles);
  const moveDescription = (id) => {
    // /:type/resource-post/:id/:resourceType
    props.moveToMediaPage(id, props.resourceType);
  };
  console.log("resourceMediaSeeMore", resourceMediaSeeMore);
  return (
    <div className="resource-mediaFiles">
      <h4 className="mt-3">{t("Media Files")}</h4>
      {resourceMediaFiles?.length === 0 && <p>{t("No media files present")}</p>}

      {/* <div className="resource-overflow"> */}
      {/* <div className="resource-file-container"> */}
      <InfiniteScroll
        dataLength={resourceMediaFiles?.length ? resourceMediaFiles?.length : 0}
        next={needMoreData}
        hasMore={resourceMediaSeeMore}
        scrollableTarget="media_div"
        direction="down"
        //   scrollThreshold={0.95}
      >
        <div
          className="resource-file-container"
          id="media_div"
          style={{
            minHeight: "auto",
            maxHeight: "24.5rem",
            overflow: "auto",
            overflowX: "hidden",
          }}
          ref={divRef}
        >
          {resourceMediaFiles &&
            resourceMediaFiles.map((item, index) => (
              <>
                {item.fileType === "pdf" ? (
                  <Tooltip title={item?.originalFileName} placement="top">
                    <div
                      className="resource-content position-relative"
                      onClick={() => moveDescription(item.postId)}
                    >
                      <DescriptionOutlinedIcon
                        style={{ color: `${colors[index % colors.length]}` }}
                      />
                    </div>
                  </Tooltip>
                ) : item.fileType === "video" ? (
                  <Tooltip title={item?.originalFileName} placement="top">
                    <div
                      className="resource-content position-relative"
                      onClick={() => moveDescription(item.postId)}
                    >
                      <img src={item?.thumbnail} alt="video" />
                      <div class="position-absolute">
                        <PlayArrowIcon className="video-play-icon" />
                      </div>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={item?.originalFileName}
                    placement="top"
                    onClick={() => moveDescription(item.postId)}
                  >
                    <div className="resource-content position-relative">
                      <img
                        src={`${API_URL}auth/image/${item.fileName}`}
                        alt="img"
                      />
                    </div>
                  </Tooltip>
                )}
              </>
            ))}
        </div>
      </InfiniteScroll>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default withTranslation()(withRouter(ResourceMediaFile));
