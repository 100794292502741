import {
  GET_ALL_SUBJECT_lIST_BY_GROUPS,
  GET_ALL_SUBJECT_lIST_BY_EVENTS,
  GET_MY_GROUPS_BY_MESSAGES_AND_MEMBERS,
  GET_MY_EVENTS_AND_GROUPS,
  GET_PAST_EVENTS,
  GET_SUBJECT_REQUESTS_LIST,
  GET_All_MEMBER_LIST,
  GET_ALL_GROUPS_BY_MEMBER_COUNT,
  GET_CHALLENGES_AND_RESOURCES,
  GET_RESOURCE_POST_BY_DOCUMENT,
  GET_RESOURCE_POST_BY_IMAGE,
  GET_RESOURCE_POST_BY_VIDEO,
  GET_CHALLENGES_APPLY_LIST,
  GET_CHALLENGE_LIST,
  GET_RESOURCE_LIST,
  GET_SEARCH_CHALLENGE_AND_GROUPS,
  GET_MY_GROUPS_HOME,
  GET_ALL_TYPE_OF_COMMUNITY,
  GET_REQUESTS_PLAY_LIST,
  GET_REQUESTS_PLAY_LIST_DATA,
  GET_MY_COMMUNITY_LIST,
  GET_COMMUNITY_TAG_LIST,
  GROUP_LOADER,
  GET_ALL_MENTOR_LIST,
  GET_RESOURCE_POST_TAGS,
  GET_RESOURCE_POST,
  RESOURCE_POST_LOADER,
  GET_COMMUNITY_LIST_LOADER,
  MENTOR_LIST_LOADER,
  MENTOR_DETAILS,
  RESOURCE_MEDIA_FILES,
  UPDATE_MEMBER_LIST,
  SINGLE_RESOURCE_POST,
  GROUP_REQUEST_LIST,
  GET_RESOURCE_LIST_V2,
  GET_USER_COMMUNITY_LIST,
  GET_COMMUNITY_MEMBER_ID,
  TREADING_USER_LIST,
} from "../types";
import { SUBJECT_LOADER } from "../types";
import ForumService from "../../api/ForumService";
import MyYuukke from "../../api/MyYuukke";
import moment from "moment";
import PostService_old from "../../api/PostService_old";

export const getAllSubjectList = (type, page) => async (dispatch) => {
  dispatch(QuestionListLoader(true));
  try {
    let popularTopic = {
      type: type,
      page: page,
    };

    ForumService.getTopicFilterV2(popularTopic)
      .then((response) => {
        var result = response.data;
        console.log("inside action");
        console.log(result);
        var subjectData = result.data.list;
        const page = result.data.TotalPages;
        const subject_list = [];
        for (let j = 0; j < subjectData.length; j++) {
          var name = subjectData[j].subjectName.toLowerCase();
          subject_list.push({
            subjectId: subjectData[j].subjectId,
            tagDescription: subjectData[j].subjectName,
            subjectDescription: subjectData[j].subjectDescription,
            subjectNameLC: name,
            createdDate: moment(subjectData[j].createdDate).format(
              "DD-MM-YYYY"
            ),
            expiryDate: moment(subjectData[j].expiryDate).format("DD-MM-YYYY"),
            startDate: moment(subjectData[j].startDate).format("DD-MM-YYYY"),
            endDate: subjectData[j].expiryDate,
            status: subjectData[j].status,
            grouporeventFiles: subjectData[j].grouporeventFiles,
            displayName: subjectData[j].displayName,
            userId: subjectData[j].createdBy,
            fileType: subjectData[j].fileType,
            // "subjectFollowByUserCount": subjectData[j].follow,
            questionsCount: subjectData[j].question_count,
            memberShipPrice: subjectData[j].memberShipPrice,
            memberShipType: subjectData[j].memberShipType,
            type: subjectData[j].type,
            membersCount: subjectData[j].membersCount,
            //eventLocation: subjectData[j].grouporeventFiles.eventLocation,
            //  startTime: subjectData[j].grouporeventFiles.startTime,
            // endTime: subjectData[j].grouporeventFiles.endTime,
            tagListId: subjectData[j].tagsId,

            // "questionsAskedWeek": subjectData[j].weekcount,
          });
        }
        if (type == "Groups") {
          dispatch({
            type: GET_ALL_SUBJECT_lIST_BY_GROUPS,
            payload: subject_list,
            page: page,
          });
        } else if (type == "Events") {
          dispatch({
            type: GET_ALL_SUBJECT_lIST_BY_EVENTS,
            payload: subject_list,
            page: page,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_SUBJECT_lIST_BY_EVENTS,
      payload: [],
      page: 0,
    });
  }
  return [];
};

export const getAllPastEvents = () => async (dispatch) => {
  //dispatch(QuestionListLoader(true));
  try {
    let popularTopic = {
      type: "",
    };

    ForumService.getPastEvents(popularTopic)
      .then((response) => {
        var result = response.data;
        console.log("inside action");
        console.log(result);
        var subjectData = result.data.pastEvents;
        const subject_list = [];
        for (let j = 0; j < subjectData.length; j++) {
          var name = subjectData[j].subjectName.toLowerCase();
          subject_list.push({
            subjectId: subjectData[j].subjectId,
            tagDescription: subjectData[j].subjectName,
            subjectNameLC: name,
            createdDate: moment(subjectData[j].createdDate).format(
              "DD-MM-YYYY"
            ),

            expiryDate: moment(subjectData[j].expiryDate).format("DD-MM-YYYY"),
            // "subjectFollowByUserCount": subjectData[j].follow,
            questionsCount: 0,
            grouporeventFiles:
              subjectData[j].grouporeventFiles.grouporeventFiles,
            displayName:
              subjectData[j].grouporeventFiles.createEventUserDetails
                .displayName,
            userId:
              subjectData[j].grouporeventFiles.createEventUserDetails.userId,
            fileType: subjectData[j].fileType,
            memberShipPrice: subjectData[j].memberShipPrice,
            memberShipType: subjectData[j].memberShipType,
            type: subjectData[j].type,
            membersCount: subjectData[j].membersCount,
            subjectDescription: subjectData[j].subjectDescription,
            eventLocation: subjectData[j].grouporeventFiles.eventLocation,
            startTime: subjectData[j].grouporeventFiles.startTime,
            endTime: subjectData[j].grouporeventFiles.endTime,
            startDate: moment(subjectData[j].startDate).format("DD-MM-YYYY"),
            tagListId: subjectData[j].grouporeventFiles.tagListId,
            // "questionsAskedWeek": subjectData[j].weekcount,
          });
        }

        dispatch({
          type: GET_PAST_EVENTS,
          payload: subject_list,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_PAST_EVENTS,
      payload: [],
    });
  }
  return [];
};

export const getAllMyGroupEvents = (type, page) => async (dispatch) => {
  //dispatch(QuestionListLoader(true));
  try {
    let popularTopic = {
      type: type,
      page,
      // page,
    };

    ForumService.getmyGroupEventsEventsV2(popularTopic)
      .then((response) => {
        var result = response.data;
        console.log("inside action");
        console.log(result);
        var subjectData = result.data.list;
        const page = result.data.TotalPages;
        const subject_list = [];
        for (let j = 0; j < subjectData.length; j++) {
          var name = subjectData[j].subjectName.toLowerCase();
          subject_list.push({
            subjectId: subjectData[j].subjectId,
            tagDescription: subjectData[j].subjectName,
            subjectNameLC: name,
            createdDate: moment(subjectData[j].createdDate).format(
              "DD-MM-YYYY"
            ),
            expiryDate: moment(subjectData[j].expiryDate).format("DD-MM-YYYY"),
            startDate: moment(subjectData[j].startDate).format("DD-MM-YYYY"),
            endDate: subjectData[j].expiryDate,
            // "subjectFollowByUserCount": subjectData[j].follow,
            questionsCount: 0,
            memberShipPrice: subjectData[j].memberShipPrice,
            grouporeventFiles: subjectData[j].grouporeventFiles,
            fileType: subjectData[j].fileType,
            memberShipType: subjectData[j].memberShipType,
            type: subjectData[j].type,
            displayName: subjectData[j].displayName,
            userId: subjectData[j].createdBy,
            membersCount: subjectData[j].membersCount,
            subjectDescription: subjectData[j].subjectDescription,
            //eventLocation: subjectData[j].grouporeventFiles.eventLocation,
            // startTime: subjectData[j].grouporeventFiles.startTime,
            // endTime: subjectData[j].grouporeventFiles.endTime,
            tagListId: subjectData[j].tagsId,
            // messageCount: subjectData[j].messageCount,
            // "questionsAskedWeek": subjectData[j].weekcount,
          });
        }
        console.log("----------------------------", subject_list);
        if (type === "Groups") {
          dispatch({
            type: GET_MY_EVENTS_AND_GROUPS,
            payload: subject_list,
            page: page,
          });
        } else if (type === "Events") {
          dispatch({
            type: GET_MY_EVENTS_AND_GROUPS,
            payload: subject_list,
            page: page,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_MY_EVENTS_AND_GROUPS,
      payload: [],
      page: 0,
    });
  }
  return [];
};
export const getAllMyGroupEventsHome = () => async (dispatch) => {
  //dispatch(QuestionListLoader(true));
  try {
    let popularTopic = {};

    ForumService.getmyGroupEventsEventsHome(popularTopic)
      .then((response) => {
        var result = response.data;
        console.log("inside action");
        console.log(result);
        var subjectData = result.data.list;
        const subject_list = [];
        for (let j = 0; j < subjectData.length; j++) {
          var name = subjectData[j].subjectName.toLowerCase();
          subject_list.push({
            subjectId: subjectData[j].subjectId,
            tagDescription: subjectData[j].subjectName,
          });
        }
        console.log("----------------------------", subject_list);

        dispatch({
          type: GET_MY_GROUPS_HOME,
          payload: subject_list,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_MY_GROUPS_HOME,
      payload: [],
    });
  }
  return [];
};
export const getChallengesandResources = (type) => async (dispatch) => {
  //dispatch(QuestionListLoader(true));
  try {
    let popularTopic = {};

    ForumService.getChallengesandResources(popularTopic)
      .then((response) => {
        var result = response.data;
        console.log("inside action");
        console.log(result);
        var subjectData = result.data;
        const subject_list = [];
        for (let j = 0; j < subjectData.length; j++) {
          var name = subjectData[j].subjectName.toLowerCase();
          subject_list.push({
            subjectId: subjectData[j].subjectId,
            tagDescription: subjectData[j].subjectName,
            subjectNameLC: name,
            createdDate: moment(subjectData[j].createdDate).format(
              "DD-MM-YYYY"
            ),
            expiryDate: moment(subjectData[j].expiryDate).format("DD-MM-YYYY"),
            startDate: moment(subjectData[j].startDate).format("DD-MM-YYYY"),
            endDate: subjectData[j].expiryDate,
            // "subjectFollowByUserCount": subjectData[j].follow,
            questionsCount: 0,
            memberShipPrice: subjectData[j].memberShipPrice,
            grouporeventFiles:
              subjectData[j].grouporeventFiles.grouporeventFiles,
            fileType: subjectData[j].fileType,
            memberShipType: subjectData[j].memberShipType,
            type: subjectData[j].type,
            displayName:
              subjectData[j].grouporeventFiles.createEventUserDetails
                .displayName,
            userId:
              subjectData[j].grouporeventFiles.createEventUserDetails.userId,
            status: subjectData[j].grouporeventFiles.status,
            membersCount: subjectData[j].membersCount,
            subjectDescription: subjectData[j].subjectDescription,
            eventLocation: subjectData[j].grouporeventFiles.eventLocation,
            startTime: subjectData[j].grouporeventFiles.startTime,
            endTime: subjectData[j].grouporeventFiles.endTime,
            tagListId: subjectData[j].grouporeventFiles.tagListId,
            messageCount: subjectData[j].messageCount,
            // "questionsAskedWeek": subjectData[j].weekcount,
          });
        }
        console.log("----------------------------", subject_list);

        dispatch({
          type: GET_CHALLENGES_AND_RESOURCES,
          payload: subject_list,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_CHALLENGES_AND_RESOURCES,
      payload: [],
    });
  }
  return [];
};

export const getChallengesList = (page) => async (dispatch) => {
  //dispatch(QuestionListLoader(true));
  try {
    let popularTopic = {
      page: page,
    };

    ForumService.getChallengesListV2(popularTopic)
      .then((response) => {
        var result = response.data;
        console.log("inside action");
        console.log("challeges list>>", result.data.TotalPages - 1);
        var subjectData = result.data.list;
        const page = result.data.TotalPages;
        const subject_list = [];
        for (let j = 0; j < subjectData.length; j++) {
          var name = subjectData[j].subjectName.toLowerCase();
          subject_list.push({
            subjectId: subjectData[j].subjectId,
            tagDescription: subjectData[j].subjectName,
            subjectNameLC: name,
            createdDate: moment(subjectData[j].createdDate).format(
              "DD-MM-YYYY"
            ),
            expiryDate: moment(subjectData[j].expiryDate).format("DD-MM-YYYY"),
            startDate: moment(subjectData[j].startDate).format("DD-MM-YYYY"),
            endDate: subjectData[j].expiryDate,
            // "subjectFollowByUserCount": subjectData[j].follow,
            questionsCount: 0,
            memberShipPrice: subjectData[j].memberShipPrice,
            grouporeventFiles: subjectData[j].grouporeventFiles,
            fileType: subjectData[j].fileType,
            memberShipType: subjectData[j].memberShipType,
            type: subjectData[j].type,
            displayName: subjectData[j].displayName,
            userId: subjectData[j].createdBy,
            status: subjectData[j].status,
            membersCount: subjectData[j].membersCount,
            subjectDescription: subjectData[j].subjectDescription,
            eventLocation: "",
            // startTime: subjectData[j].grouporeventFiles.startTime,
            // endTime: subjectData[j].grouporeventFiles.endTime,
            tagListId: subjectData[j].tagsId,
            // messageCount: subjectData[j].messageCount
            // "questionsAskedWeek": subjectData[j].weekcount,
          });
        }
        console.log("----------------------------", subject_list);

        dispatch({
          type: GET_CHALLENGE_LIST,
          payload: subject_list,
          page: page,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_CHALLENGE_LIST,
      payload: [],
      page: 0,
    });
  }
  return [];
};

export const getResourcesList = (page) => async (dispatch) => {
  //dispatch(QuestionListLoader(true));
  try {
    let popularTopic = {
      page: page,
    };

    ForumService.getResourcesListV2(popularTopic)
      .then((response) => {
        var result = response.data;
        console.log("inside action");
        console.log(result);
        var subjectData = result.data.list;
        const page = result.data.TotalPages;
        const subject_list = [];
        for (let j = 0; j < subjectData.length; j++) {
          var name = subjectData[j].subjectName.toLowerCase();
          subject_list.push({
            subjectId: subjectData[j].subjectId,
            tagDescription: subjectData[j].subjectName,
            subjectNameLC: name,
            createdDate: moment(subjectData[j].createdDate).format(
              "DD-MM-YYYY"
            ),
            expiryDate: moment(subjectData[j].expiryDate).format("DD-MM-YYYY"),
            startDate: moment(subjectData[j].startDate).format("DD-MM-YYYY"),
            endDate: subjectData[j].expiryDate,
            // "subjectFollowByUserCount": subjectData[j].follow,
            questionsCount: 0,
            memberShipPrice: subjectData[j].memberShipPrice,
            grouporeventFiles: subjectData[j].grouporeventFiles,
            fileType: subjectData[j].fileType,
            memberShipType: subjectData[j].memberShipType,
            type: subjectData[j].type,
            displayName: subjectData[j].displayName,
            userId: subjectData[j].createdBy,
            status: subjectData[j].status,
            membersCount: subjectData[j].membersCount,
            subjectDescription: subjectData[j].subjectDescription,
            // eventLocation: subjectData[j].grouporeventFiles.eventLocation,
            // startTime: subjectData[j].grouporeventFiles.startTime,
            // endTime: subjectData[j].grouporeventFiles.endTime,
            tagListId: subjectData[j].tagsId,
            //  messageCount: subjectData[j].messageCount,
            // "questionsAskedWeek": subjectData[j].weekcount,
          });
        }
        console.log("----------------------------", subject_list);

        dispatch({
          type: GET_RESOURCE_LIST,
          payload: subject_list,
          page: page,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_RESOURCE_LIST,
      payload: [],
      page: 0,
    });
  }
  return [];
};

export const QuestionListLoader = (type) => async (dispatch) => {
  try {
    dispatch({
      type: SUBJECT_LOADER,
      payload: type,
    });
  } catch (error) {
    dispatch({
      type: SUBJECT_LOADER,
      payload: false,
    });
  }
  return false;
};

export const getSubjectsRequestList = (groupId) => async (dispatch) => {
  try {
    let getSubjectRequestList = {
      groupId: groupId,
    };
    MyYuukke.getRequestedSubjectsList(getSubjectRequestList)
      .then((response) => {
        var result = response.data;
        if (result.status === 1) {
          var stringArray = result.data.user;
          // AuthService.storeFriendsList(result.data.friendsList);
          dispatch({
            type: GET_SUBJECT_REQUESTS_LIST,
            payload: stringArray,
          });
        } else {
          dispatch({
            type: GET_SUBJECT_REQUESTS_LIST,
            payload: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_SUBJECT_REQUESTS_LIST,
      payload: [],
    });
  }
  return [];
};

export const getAllMembersDetails =
  (id, type, page, pagination, searchData) => async (dispatch) => {
    try {
      let getAllMembersDetails = {
        groupsOrEvents: id,
        type: type ? type : "all",
        page: page ? page : 0,
        search: searchData ? searchData : "",
      };
      MyYuukke.getAllMembersDetails(getAllMembersDetails)
        .then((response) => {
          var result = response.data;
          if (result.status === 1) {
            var stringArray = result.data;
            // AuthService.storeFriendsList(result.data.friendsList);
            dispatch({
              type: GET_All_MEMBER_LIST,
              payload: stringArray,
              pageNo: page,
              pagination: pagination,
            });
          } else {
            dispatch({
              type: GET_All_MEMBER_LIST,
              payload: [],
              pageNo: 0,
              pagination: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_All_MEMBER_LIST,
        payload: [],
        pageNo: 0,
        pagination: false,
      });
    }
    return [];
  };

export const getallSubjectGroupsByMembersCount =
  (pageNo) => async (dispatch) => {
    // dispatch(QuestionListLoader(true));
    dispatch({
      type: GROUP_LOADER,
      payload: true,
    });
    try {
      let page = {
        page: pageNo,
      };

      ForumService.getallSubjectGroupsByMembersCount(page)
        .then((response) => {
          var result = response.data.data;
          console.log("inside action groups my member");
          console.log(result);

          if (response.data.status === 1 && result.list) {
            dispatch({
              type: GET_ALL_GROUPS_BY_MEMBER_COUNT,
              payload: result ? result : [],
              seeMoreStatus: result?.TotalPages > pageNo ? true : false,
              pageNo: pageNo,
              statusError: false,
            });
          } else {
            dispatch({
              type: GET_ALL_GROUPS_BY_MEMBER_COUNT,
              payload: [],
              seeMoreStatus: false,
              pageNo: 0,
              statusError: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_ALL_GROUPS_BY_MEMBER_COUNT,
        payload: [],
        seeMoreStatus: false,
        pageNo: 0,
      });
    }
    return [];
  };

export const getMyGroupsByMembersandMessages = (type) => async (dispatch) => {
  dispatch(QuestionListLoader(true));
  try {
    let popularTopic = {
      type: type,
    };

    ForumService.getMyGroupsByMembersandMessagesV2(popularTopic)
      .then((response) => {
        var result = response.data;
        console.log("inside action");
        console.log(result);
        var subjectData = result.data.list;
        const subject_list = [];
        for (let j = 0; j < subjectData.length; j++) {
          var name = subjectData[j].subjectName.toLowerCase();
          subject_list.push({
            subjectId: subjectData[j].subjectId,
            tagDescription: subjectData[j].subjectName,
            subjectDescription: subjectData[j].subjectDescription,
            subjectNameLC: name,
            createdDate: moment(subjectData[j].createdDate).format(
              "DD-MM-YYYY"
            ),
            expiryDate: moment(subjectData[j].expiryDate).format("DD-MM-YYYY"),
            startDate: moment(subjectData[j].startDate).format("DD-MM-YYYY"),
            endDate: subjectData[j].expiryDate,
            status: subjectData[j].status,
            grouporeventFiles: subjectData[j].grouporeventFiles,
            displayName: subjectData[j].displayName,
            userId: subjectData[j].createdBy,
            fileType: subjectData[j].fileType,
            // "subjectFollowByUserCount": subjectData[j].follow,
            questionsCount: subjectData[j].question_count,
            memberShipPrice: subjectData[j].memberShipPrice,
            memberShipType: subjectData[j].memberShipType,
            type: subjectData[j].type,
            membersCount: subjectData[j].membersCount,
            eventLocation: subjectData[j].eventLocation,
            startTime: subjectData[j].startTime,
            endTime: subjectData[j].endTime,
            tagListId: subjectData[j].tagsId,

            // "questionsAskedWeek": subjectData[j].weekcount,
          });
        }

        dispatch({
          type: GET_MY_GROUPS_BY_MESSAGES_AND_MEMBERS,
          payload: subject_list,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_MY_GROUPS_BY_MESSAGES_AND_MEMBERS,
      payload: [],
    });
  }
  return [];
};

export const getResourceInspirationList =
  (pageNo, subjectId, type, tagList) => async (dispatch) => {
    try {
      let popularTopic = {
        groupId: subjectId,
        page: pageNo,
        type: type,
        resourceTagIds: tagList,
      };

      ForumService.getResourcePostByImage(popularTopic)
        .then((response) => {
          var result = response.data;
          console.log("inside action");
          console.log(result);
          var subject_list = result.data.resource;
          var totalItems = result.data.totalItems;

          dispatch({
            type: GET_RESOURCE_POST_BY_IMAGE,
            payload: subject_list,
            totalItems: totalItems,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_RESOURCE_POST_BY_IMAGE,
        payload: [],
        totalItems: 0,
      });
    }
    return [];
  };

export const getResourceVideoList =
  (pageNo, subjectId, type, tagList) => async (dispatch) => {
    // alert(subjectId)
    // alert(pageNo)
    try {
      let popularTopic = {
        groupId: subjectId,
        page: pageNo,
        type: type,
        resourceTagIds: tagList,
      };

      ForumService.getResourcePostByVideo(popularTopic)
        .then((response) => {
          var result = response.data;
          console.log("inside action");
          console.log(result);
          var subject_list = result.data.resource;
          var totalItems = result.data.totalItems;

          dispatch({
            type: GET_RESOURCE_POST_BY_VIDEO,
            payload: subject_list,
            totalItems: totalItems,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_RESOURCE_POST_BY_VIDEO,
        payload: [],
        totalItems: 0,
      });
    }
    return [];
  };

export const getResourceDocumentList =
  (pageNo, subjectId, type, tagList) => async (dispatch) => {
    try {
      let popularTopic = {
        groupId: subjectId,
        page: pageNo,
        type: type,
        resourceTagIds: tagList,
      };

      ForumService.getResourcePostByDocument(popularTopic)
        .then((response) => {
          var result = response.data;
          console.log("inside action");
          console.log(result);
          var subject_list = result.data.resource;
          var totalItems = result.data.totalItems;

          dispatch({
            type: GET_RESOURCE_POST_BY_DOCUMENT,
            payload: subject_list,
            totalItems: totalItems,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_RESOURCE_POST_BY_DOCUMENT,
        payload: [],
        totalItems: 0,
      });
    }
    return [];
  };

export const getChallengeApplyList =
  (pageNo, subjectId) => async (dispatch) => {
    try {
      let popularTopic = {
        subjectId: subjectId,
        page: pageNo,
      };

      ForumService.getAllChallengesApplied(popularTopic)
        .then((response) => {
          var result = response.data;
          console.log("inside action");
          console.log(result);
          var subject_list = result.data.content;
          var totalItems = result.data.totalElements;

          dispatch({
            type: GET_CHALLENGES_APPLY_LIST,
            payload: subject_list,
            totalItems: totalItems,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_CHALLENGES_APPLY_LIST,
        payload: [],
        totalItems: 0,
      });
    }
    return [];
  };

export const searchByGroupsAndChallenges =
  (page, groupOrChallenge, searchKey) => async (dispatch) => {
    //dispatch(QuestionListLoader(true));
    try {
      let popularTopic = {
        type: "Groups",
        page: page,
        groupOrChallenge: groupOrChallenge,
        searchKey: searchKey,
      };

      ForumService.searchByGroupsAndChallenges(popularTopic)
        .then((response) => {
          var result = response.data;
          console.log("inside action");
          console.log(result);
          var subjectData = result.data.list;
          const page = result.data.TotalPages;
          const NumberOfElements = result.data.NumberOfElements;
          const subject_list = [];
          for (let j = 0; j < subjectData.length; j++) {
            var name = subjectData[j].subjectName.toLowerCase();
            subject_list.push({
              subjectId: subjectData[j].subjectId,
              tagDescription: subjectData[j].subjectName,
              subjectNameLC: name,
              createdDate: moment(subjectData[j].createdDate).format(
                "DD-MM-YYYY"
              ),
              expiryDate: moment(subjectData[j].expiryDate).format(
                "DD-MM-YYYY"
              ),
              startDate: moment(subjectData[j].startDate).format("DD-MM-YYYY"),
              endDate: subjectData[j].expiryDate,
              // "subjectFollowByUserCount": subjectData[j].follow,
              questionsCount: 0,
              memberShipPrice: subjectData[j].memberShipPrice,
              grouporeventFiles: subjectData[j].grouporeventFiles,
              fileType: subjectData[j].fileType,
              memberShipType: subjectData[j].memberShipType,
              type: subjectData[j].type,
              displayName: subjectData[j].displayName,
              userId: subjectData[j].createdBy,
              membersCount: subjectData[j].membersCount,
              subjectDescription: subjectData[j].subjectDescription,
              //eventLocation: subjectData[j].grouporeventFiles.eventLocation,
              // startTime: subjectData[j].grouporeventFiles.startTime,
              // endTime: subjectData[j].grouporeventFiles.endTime,
              tagListId: subjectData[j].tagsId,
              tagsValue: subjectData[j].tagsValue,
              communityTypeName: subjectData[j].communityTypeName,

              // messageCount: subjectData[j].messageCount,
              // "questionsAskedWeek": subjectData[j].weekcount,
            });
          }

          dispatch({
            type: GET_SEARCH_CHALLENGE_AND_GROUPS,
            payload: subject_list,
            page: page,
            NumberOfElements: NumberOfElements,
            totalElements: result.data.TotalElements,
          });

          // else if (type === "Events") {
          //   dispatch({
          //     type: GET_MY_EVENTS_AND_GROUPS,
          //     payload: subject_list,
          //     page:page,
          //   });
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_SEARCH_CHALLENGE_AND_GROUPS,
        payload: [],
        page: 0,
      });
    }
    return [];
  };
export const getResourcePlayList = (groupId) => async (dispatch) => {
  try {
    let getSubjectRequestList = {
      subjectId: groupId,
    };
    MyYuukke.getResourcePlayList(getSubjectRequestList)
      .then((response) => {
        var result = response.data.data.playlist;
        console.log("GET_REQUESTS_PLAY_LIST", result);
        if (result.length !== 0) {
          dispatch({
            type: GET_REQUESTS_PLAY_LIST,
            payload: result,
          });
        } else {
          dispatch({
            type: GET_REQUESTS_PLAY_LIST,
            payload: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_REQUESTS_PLAY_LIST,
      payload: [],
    });
  }
  return [];
};

export const getResourceByPlaylist =
  (subjectId, playlistId, page) => async (dispatch) => {
    try {
      let getSubjectRequestList = {
        subjectId: subjectId,
        playlistId: playlistId,
        page: +page,
      };
      MyYuukke.getResourceByPlaylist(getSubjectRequestList)
        .then((response) => {
          var result = response.data.data;
          console.log("GET_REQUESTS_PLAY_LIST_DATA", result);
          if (result.length !== 0) {
            dispatch({
              type: GET_REQUESTS_PLAY_LIST_DATA,
              payload: result,
            });
          } else {
            dispatch({
              type: GET_REQUESTS_PLAY_LIST_DATA,
              payload: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_REQUESTS_PLAY_LIST_DATA,
        payload: [],
      });
    }
    return [];
  };
export const getAlltypeofCommunity = () => async (dispatch) => {
  try {
    let getSubjectRequestList = {};
    MyYuukke.getAlltypeofCommunity(getSubjectRequestList)
      .then((response) => {
        var subject_list = response.data.data;

        dispatch({
          type: GET_ALL_TYPE_OF_COMMUNITY,
          payload: subject_list,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_TYPE_OF_COMMUNITY,
      payload: [],
    });
  }
  return [];
};

export const getMyCommunityList = (data, pagination) => async (dispatch) => {
  //dispatch(QuestionListLoader(true));
  try {
    dispatch({
      type: GET_COMMUNITY_LIST_LOADER,
      payload: true,
    });
    ForumService.myCommunityFilter(data)
      .then((response) => {
        var group_list = response.data.data;
        if (group_list) {
          dispatch({
            type: GET_MY_COMMUNITY_LIST,
            payload: group_list,
            pagination: pagination,
            page: data.page,
          });
        } else {
          dispatch({
            type: GET_MY_COMMUNITY_LIST,
            payload: [],
            pagination: false,
            page: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_MY_COMMUNITY_LIST,
      payload: [],
      pagination: false,
      page: 0,
    });
  }
  return [];
};

export const getCommunityTags = (data) => async (dispatch) => {
  //dispatch(QuestionListLoader(true));
  try {
    let type = {
      type: data,
    };
    ForumService.getTagsByCommunityType(type)
      .then((response) => {
        var group_list = response.data.data.tags;
        if (response.data.data) {
          const newArr =
            group_list && group_list.map((v) => ({ ...v, isActive: false }));
          console.log("newArr", newArr);
          dispatch({
            type: GET_COMMUNITY_TAG_LIST,
            payload: newArr ? newArr : [],
          });
        } else {
          dispatch({
            type: GET_COMMUNITY_TAG_LIST,
            payload: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_MY_COMMUNITY_LIST,
      payload: [],
    });
  }
  return [];
};

export const getAllMentorList = (page, pagination) => async (dispatch) => {
  try {
    var data = {
      page: page,
    };
    dispatch({
      type: MENTOR_LIST_LOADER,
      payload: true,
    });
    PostService_old.getAllMentorUsers(data)
      .then((response) => {
        var mentorList = response.data.data;
        if (mentorList) {
          dispatch({
            type: GET_ALL_MENTOR_LIST,
            payload: mentorList,
            pagination: pagination,
            page: page,
          });
        } else {
          dispatch({
            type: GET_ALL_MENTOR_LIST,
            payload: [],
            pagination: false,
            page: 0,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch({
          type: GET_ALL_MENTOR_LIST,
          payload: [],
          pagination: false,
          page: 0,
        });
      });
  } catch (e) {}
};

export const getResourcePostTags = (data) => async (dispatch) => {
  // var data = {
  //   subjectId: id,

  // };
  PostService_old.getPostTags(data)
    .then((response) => {
      let result = response.data.data.tagsList;
      dispatch({
        type: GET_RESOURCE_POST_TAGS,
        payload: result ? result : [],
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: GET_RESOURCE_POST_TAGS,
        payload: [],
      });
    });
};
export const startResourceLoader = () => async (dispatch) => {
  dispatch({
    type: RESOURCE_POST_LOADER,
    payload: true,
  });
};

export const getResourcePostList = (data) => async (dispatch) => {
  dispatch({
    type: RESOURCE_POST_LOADER,
    payload: true,
  });
  PostService_old.resourcePostFilterByType(data)
    .then((response) => {
      var result = response.data.data;
      dispatch({
        type: GET_RESOURCE_POST,
        payload: result.resource ? result.resource : [],
        totalItems: result.totalItems,
      });
    })
    .catch((e) => {
      dispatch({
        type: GET_RESOURCE_POST,
        payload: [],
        totalItems: 0,
      });
      console.log(e);
    });
};

export const getSingleMentor = (id) => async (dispatch) => {
  var data = {
    mentorId: id,
  };
  PostService_old.getSingleMentorUser(data)
    .then((response) => {
      const result = response.data.data;
      dispatch({
        type: MENTOR_DETAILS,
        payload: result,
        status: response.data.status === 1 ? "success" : "fail",
      });
    })
    .catch((error) => {
      dispatch({
        type: MENTOR_DETAILS,
        payload: [],
        status: "fail",
      });
      console.log("e", error);
    });
};
export const getResourceMediaFiles = (data, pagination) => async (dispatch) => {
  PostService_old.getResourceMediaFiles(data)
    .then((response) => {
      var result = response.data.data;
      dispatch({
        type: RESOURCE_MEDIA_FILES,
        payload: result,
        pagination: pagination,
        pageNo: 0,
      });
    })
    .catch((e) => {
      dispatch({
        type: RESOURCE_MEDIA_FILES,
        payload: [],
        totalItems: 0,
        pagination: false,
        page: 0,
      });
      console.log(e);
    });
};

export const updateGroupMember = (id, status) => async (dispatch) => {
  let data = {
    id: id,
    status: status,
  };
  dispatch({
    type: UPDATE_MEMBER_LIST,
    payload: data,
  });
};

export const getSingleResourcePost = (id) => async (dispatch) => {
  var data = {
    postId: id,
  };
  dispatch({
    type: RESOURCE_POST_LOADER,
    payload: true,
  });
  PostService_old.getResourcePost(data)
    .then((response) => {
      const result = response.data.data.resourcePost;
      dispatch({
        type: SINGLE_RESOURCE_POST,
        payload: result,
      });
    })
    .catch((error) => {
      dispatch({
        type: SINGLE_RESOURCE_POST,
        payload: {},
      });
      console.log("e", error);
    });
};

export const getGroupRequestList = (data, pagination) => async (dispatch) => {
  PostService_old.requestlist(data)
    .then((response) => {
      const result = response.data.data;
      dispatch({
        type: GROUP_REQUEST_LIST,
        payload: result,
        pagination: pagination,
        pageNo: data.page,
      });
    })
    .catch((error) => {
      dispatch({
        type: GROUP_REQUEST_LIST,
        payload: [],
        pagination: false,
        pageNo: data.page,
      });
      console.log("e", error);
    });
};

export const getResourceList = (data, pagination) => async (dispatch) => {
  dispatch({
    type: SUBJECT_LOADER,
    payload: true,
  });
  PostService_old.getResourceList(data)
    .then((response) => {
      const result = response.data.data;
      dispatch({
        type: GET_RESOURCE_LIST_V2,
        payload: result,
        pagination: pagination,
        pageNo: data.page,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_RESOURCE_LIST_V2,
        payload: [],
        pagination: false,
        pageNo: data.page,
      });
      console.log("e", error);
    });
};

export const getUserCommunityList = () => async (dispatch) => {
  PostService_old.getUserCommunityList()
    .then((response) => {
      const result = response.data.data.communities;
      dispatch({
        type: GET_USER_COMMUNITY_LIST,
        payload: result ? result : [],
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_USER_COMMUNITY_LIST,
        payload: [],
      });
      console.log("e", error);
    });
};

export const getCommunityMemberId = (id) => async (dispatch) => {
  const data = {
    subjectId: id,
  };
  PostService_old.getCommunityMemberId(data)
    .then((response) => {
      const result = response.data.data;
      dispatch({
        type: GET_COMMUNITY_MEMBER_ID,
        payload: result ? result : [],
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_COMMUNITY_MEMBER_ID,
        payload: [],
      });
      console.log("e", error);
    });
};

export const getTreadingUsers = () => async (dispatch) => {
  dispatch({
    type: GROUP_LOADER,
    payload: true,
  });
  PostService_old.getTreadingUsers()
    .then((response) => {
      dispatch({
        type: TREADING_USER_LIST,
        payload: response.data.data?.users ? response.data.data.users : [],
      });
    })
    .catch((error) => {
      console.log("e", error);
    });
};
