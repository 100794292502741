import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Button, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AiOutlineCloseCircle } from "react-icons/ai";
import PostService_old from "../../api/PostService_old";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { API_URL } from "../../config/app-config";
import dummyimage from "../../img/default_avatar.jpg";

function RequestUserList(props) {
  const { t, closeModel, mentorId } = props;
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    let data = {
      mentorId: mentorId,
    };
    PostService_old.getMentorSelfAssessmentDatas(data)
      .then((response) => {
        setResponseData(response.data.data.userList);
        console.log("response", response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.listRefresh]);

  return (
    <div>
      <Dialog fullWidth maxWidth="lg" open={true} onClose={closeModel}>
        <DialogTitle>
          {t("Request User Lists")}{" "}
          <AiOutlineCloseCircle
            onClick={closeModel}
            style={{
              cursor: "pointer",
              float: "right",
              marginBottom: "8px",
              width: "20px",
              height: "20px",
              color: "#000000",
            }}
          />
        </DialogTitle>
        <DialogContent dividers="paper">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Time</TableCell>
                  {responseData &&
                    responseData?.questions.map((question) => (
                      <TableCell>{question?.question}</TableCell>
                    ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {responseData &&
                  responseData?.answerList.map((data) => (
                    <TableRow>
                      <TableCell>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => props.showFriendsProfile(data?.userId)}
                        >
                          <div className="post-avatar post-avatar-story postCardImg-me-2">
                            {" "}
                            <img
                              style={{ cursor: "pointer" }}
                              // onClick={() =>
                              //   this.showFriendsProfile(this.state.postValue.userId)
                              // }
                              className="post-avatar-img rounded-circle"
                              src={
                                API_URL +
                                "api/getUserPhoto/" +
                                data.userId +
                                "/" +
                                data.userName
                              }
                              alt="user"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = dummyimage;
                              }}
                            />{" "}
                          </div>
                          <p
                            style={{ cursor: "pointer" }}
                            className="ml-1 text-bold"
                          >
                            {data.userName}
                          </p>{" "}
                        </div>
                      </TableCell>
                      <TableCell>
                        <p className="slot-box mb-2">Day : {data?.day}</p>
                        <p className="slot-box">
                          {t("Slot")} : {data?.timeSlot?.name}
                        </p>
                      </TableCell>
                      {data?.userList.map((answer) => (
                        <>
                          <TableCell>{answer ? answer : "-"}</TableCell>
                        </>
                      ))}
                      <TableCell>
                        <div className="d-flex ">
                          <button
                            class="btn m-l-0  connectiongreenLabel"
                            onClick={() => props.acceptToMentor(data?.userId)}
                          >
                            {" "}
                            {t("Accept")}{" "}
                          </button>
                          <button
                            class="btn  connectionredLabel   me-2"
                            onClick={() => props.rejectToMentor(data?.userId)}
                          >
                            {" "}
                            {t("Busy")}{" "}
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {responseData && responseData?.answerList?.length === 0 && (
            <div className="d-flex justify-content-center mt-2">
              <p>{t("No more request present")}</p>
            </div>
          )}
          {/* {this.state.surveyQuestionsLoader ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "grid",
              placeContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <SurveyAnswersList data={this.state.surveyQuestions} />
        )} */}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "white", marginRight: "auto" }}
            onClick={() => closeModel()}
          >
            Close
          </Button>
          {responseData && responseData?.answerList?.length > 1 && (
            <Button
              style={{ color: "white" }}
              onClick={() => props.acceptAll()}
            >
              Accept all requests
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    loginUserType: state.loginDetailsApi.loginDetails?.uType,
  };
};
export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(RequestUserList)));
