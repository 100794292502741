import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import AuthService from "../../session/AuthService";
import FriendsFinder from "../../api/FriendsFinder";
import ProfileService from "../../api/ProfileService";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
import dummyimage from "../../img/default_avatar.jpg";
import { connect } from "react-redux";
import { API_URL } from "../../config/app-config";

class MyRequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      friendsList: [],
      userKnowledgepoint: "",
      coverPhotoImage: "",
    };
    this.movetomyrequest = this.movetomyrequest.bind(this);
    this.handleChangeRequestCard = this.handleChangeRequestCard.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Container>
          <>
            <div>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <h3 className="listHomeTitle">Others Queries</h3>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <button
                    style={{ float: "right" }}
                    onClick={this.movetomyrequest}
                  >
                    Back
                  </button>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="announcementWrapperOuter">
                  <Grid style={{ paddingTop: 12 }}>
                    {this.props.otherRequestList &&
                      this.props.otherRequestList.length > 0 &&
                      this.props.otherRequestList.map((request, index) => (
                        <div
                          className="peopleInvitationCardWrapper"
                          onClick={() =>
                            this.handleChangeRequestCard(request.jobId)
                          }
                        >
                          <div style={{ display: "inline-flex" }}>
                            {" "}
                            <div class="postuserImg">
                              <img
                                className="rounded-circle"
                                style={{ width: "100%", height: "100%" }}
                                src={
                                  API_URL +
                                  "api/getUserPhoto/" +
                                  request.userId +
                                  "/" +
                                  request.displayName
                                }
                                alt="user"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = dummyimage;
                                }}
                              />
                            </div>
                            <h6
                              style={{
                                color: "#000000",
                                paddingTop: "20px",
                                paddingLeft: "8px",
                                cursor: "pointer",
                              }}
                            >
                              {request.displayName}
                            </h6>
                          </div>
                          <p
                            style={{
                              color: "#000000",
                              padding: "8px",
                              cursor: "pointer",
                            }}
                          >
                            {request.natureOfRequest}
                          </p>
                          <p
                            style={{
                              color: "gray",
                              paddingLeft: "8px",
                              fontSize: "12px",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            {request.description}{" "}
                          </p>
                        </div>
                      ))}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </>
        </Container>
      </div>
    );
  }
  handleChangeRequestCard(e) {
    this.props.history.push(
      `/${this.props.workspaceName}/request-dis/${e}/${"my-req"}`
    );
  }
  movetomyrequest() {
    this.props.history.push(`/${this.props.workspaceType}/my-requests`);
  }
}

const mapStateToProps = function (state) {
  console.log("Loading Notification Props");
  console.log(state.loginDetailsApi.loginDetails);

  return {
    workspaceName: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    otherRequestList: state.myRequestReducers.otherRequestList,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(MyRequestList)));
