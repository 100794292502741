import React, { Component } from "react";
import UserImg from "./UserImg";
import AuthService from "../session/AuthService";
import FriendsFinder from "../api/FriendsFinder";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import dummyimage from "../img/default_avatar.jpg";
import ForumService from "../api/ForumService";
import {
  getAllChatFriendsList,
  getAllCommonFriendsList,
  getFriendsListBasedWorkSpace,
} from "../redux/actions/commonApiActions";
import { removeNotificationListById } from "../redux/actions/notificationActions";
import { API_URL } from "../config/app-config";
import { premiumUser } from "../utils";

class PeopleInvitationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      invitationState: false,
      invitedFriendsList: false,
      base64String: "",
      mutualFriendsCount: 0,
      minutesAgo: 0,
    };
    this.load = this.load.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
    this.friendAccept = this.friendAccept.bind(this);
    this.friendReject = this.friendReject.bind(this);
    this.gotoFriendsDescription = this.gotoFriendsDescription.bind(this);
    this.updatedFriendsList = this.updatedFriendsList.bind(this);
  }
  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.setState({}, () => {
      // this.load(this.props.userId);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId)
      this.setState({}, () => {
        // this.load(this.props.userId);
      });
  }

  render() {
    const { t } = this.props;

    const {
      profilePhoto,
      imgSrc,
      id,
      userId,
      name,
      Dname,
      mutualFriends,
      minutesAgo,
      premiumData,
    } = this.props;
    return (
      <div className="peopleInvitationCardWrapper flexDefault">
        <Grid container spacing={1}>
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            lg={12}
            style={{ display: "inline-flex" }}
          >
            <div
              onClick={() => this.gotoFriendsDescription(userId)}
              style={{ cursor: "pointer" }}
            >
              <UserImg
                size="small"
                imgSrc={
                  profilePhoto
                    ? API_URL + "auth/image/" + profilePhoto
                    : dummyimage
                }
                imgAlt={name}
              />
            </div>
            <div className="peopleInvitationUserInfo">
              <div
                style={{ cursor: "pointer" }}
                className="d-flex align-items-center "
                onClick={() => this.gotoFriendsDescription(userId)}
              >
                <h3 className="card-dName p-0">{name}</h3>

                {premiumData && premiumUser(premiumData) && (
                  <img
                    src={premiumUser(premiumData)}
                    alt=""
                    className="ml-1"
                    style={{ width: "20px", height: "20px" }}
                  />
                )}
              </div>
              {/* <h2
                onClick={() => this.gotoFriendsDescription(userId)}
                className="PIuserName"
                style={{ cursor: "pointer" }}
              >
                {name}
                {premiumData && premiumUser(premiumData) && (
                  <img
                    src={premiumUser(premiumData)}
                    alt=""
                    className="ml-1"
                    style={{ width: "20px" }}
                  />
                )}
              </h2> */}

              {/* <p className="PIFriend">{t('Added you as a friend')}</p> */}
              {this.props.tagsValue && (
                <p style={{ fontSize: "10px", color: "#b3b3b3" }}>
                  {this.getSearchedTagValue(this.props.tagsValue)}
                </p>
              )}
              <p style={{ fontSize: "10px", color: "#b3b3b3" }}>
                {this.props.country} , {this.props.nameLocation}
              </p>
              <p></p>
            </div>
          </Grid>
          <Grid item md={12} xs={12} sm={12} lg={12}>
            <div
              className="PICardAction"
              style={{ display: "inline-flex", width: "120px" }}
            >
              <button
                className="PIAcceptFilled"
                onClick={() => this.friendAccept(id, userId)}
              >
                {t("Accept")}
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                className="PIAcceptOutlinedNew"
                onClick={() => this.friendReject(id, userId)}
              >
                {t("Ignore")}
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  getSearchedTagValue = (tags) => {
    const searchKeyword = this.props.searchData || "";

    console.log("Tag Value", searchKeyword);

    if (Array.isArray(tags) && tags.length > 0) {
      const result = tags.filter((val) =>
        val.tagName.toLowerCase().includes(searchKeyword.toLocaleLowerCase())
      );
      console.log("Tag searchvalue", result);

      if (result.length > 0) {
        return result[0]?.tagName;
      } else {
        return tags[0].tagName;
      }
    }
    return "-------";
  };

  load(data) {
    this.getBase64String(data);
  }

  gotoFriendsDescription(userId) {
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  }

  getBase64String(userId) {
    let getBase64String = { userId: userId };
    var response = ForumService.getUserPhotoCache(getBase64String);
    if (response) {
      var value = response.data.data;
      if (value && value.userPhoto) {
        var base64String = "";
        base64String = `data:image;base64,${response.data.data.userPhoto}`;
        this.setState({ base64String: base64String });
      }
    } else {
      ForumService.getUserPhoto(getBase64String)
        .then((response) => {
          ForumService.setUserPhotoCache(getBase64String, response);
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.userPhoto
          ) {
            var base64String = "";
            base64String = `data:image;base64,${response.data.data.userPhoto}`;
            this.setState({ base64String: base64String });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  friendAccept(id, fid) {
    let friendAccept = {
      // "id": id,
      userId: fid,
      requestStatus: "accepted",
    };
    FriendsFinder.friendAcceptV2(friendAccept)
      .then((response) => {
        console.log("notificationId >>", response.data.notificationId);
        this.props.removeNotificationListById(
          response.data.notificationId,
          this.props.notificationList
        );
        this.props.getAllChatFriendsList();
        var result = response.data;
        this.props.getAllCommonFriendsList();
        this.props.getFriendsListBasedWorkSpace();

        //  this.updatedFriendsList(result.data)
        //this.getPost()
        console.log("datas : ", result.data.acceptUser);
        this.props.acceptSent(fid, result.data.acceptUser);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  friendReject(id, fid) {
    let friendReject = {
      // "id": id,
      friendsId: fid,
      requestStatus: "rejected",
    };
    FriendsFinder.friendReject(friendReject)
      .then((response) => {
        var result = response.data;
        //2
        this.props.rejectSent(fid);
        //toast.success("Rejected successfully...")

        // window.location.reload(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updatedFriendsList(newFriendsList) {
    // this.setState({
    //   oldFriendsList: this.props.FriendsList
    // })
    // if (this.state.oldFriendsList.length > 0) {
    //   var newFriends = this.state.oldFriendsList.concat(newFriendsList)
    //   this.setState({ newFriends: newFriends })
    //   AuthService.storeFriendsList(this.state.newFriends);
    // }
    // else {
    //   this.setState({ newFriends: [newFriendsList] })
    //   AuthService.storeFriendsList(this.state.newFriends);
    // }
  }
}

const mapStateToProps = function (state) {
  console.log("PROFILE_LANG_VALUE");
  console.log(state.commonApi.friendslist);
  return {
    FriendsList: state.commonApi.friendslist,
    notificationList: state.notification.list,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getFriendsListBasedWorkSpace: () =>
      dispatch(getFriendsListBasedWorkSpace()),
    getAllChatFriendsList: () => dispatch(getAllChatFriendsList()),
    getAllCommonFriendsList: () => dispatch(getAllCommonFriendsList()),
    removeNotificationListById: (notificationId, notificationList) =>
      dispatch(removeNotificationListById(notificationId, notificationList)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(PeopleInvitationCard)));
