import React, { Component } from "react";
import AuthService from "../session/AuthService";
import FriendsFinder from "../api/FriendsFinder";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";
import { withRouter } from "react-router";
import ForumService from "../api/ForumService";
//import dummyimage from '../img/avatar_dummy.png'
import dummyimage from "../img/default_avatar.jpg";
import ProfileService from "../api/ProfileService";
import banner from "../img/banner.jpg";
import { connect } from "react-redux";
import * as APPCONFIG from "../config/app-config.js";
import FriendProfile from "../screens/Home/ProfileFriends/FriendProfile";
import { API_URL } from "../config/app-config";
import { giveUserAction, premiumUser } from "../utils";
import { needPhotVerification } from "../redux/actions/commonApiActions";
let myProfileDialog = {
  backgroundColor: "#ffffff",
  color: "#000",
  width: "50%",
  minHeight: "250px",
  padding: "0px",
  borderRadius: "10px",
};

class SearchPeopleCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      base64String: "",
      coverPhoto: "",
      base64StringState: false,
      mutualFriendsCount: 0,
      showFriendsProfile: false,
      type: "",
    };
    this.friendIgnore = this.friendIgnore.bind(this);
    this.load = this.load.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
    this.getUserSchoolAndClass = this.getUserSchoolAndClass.bind(this);
    this.getCoverPhoto = this.getCoverPhoto.bind(this);
    this.showFriendsProfile = this.showFriendsProfile.bind(this);
    this.closeFriendsProfile = this.closeFriendsProfile.bind(this);
    this.getSchoolName = this.getSchoolName.bind(this);
    this.chatNow = this.chatNow.bind(this);
    this.friendRequestReDirect = this.friendRequestReDirect.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.setState({}, () => {
      this.load(this.props.type);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId)
      this.setState({}, () => {
        this.load(this.props.type);
      });
  }

  render() {
    const { t } = this.props;
    const {
      imgSrc,
      id,
      userId,
      name,
      mutualFriends,
      coverPhoto,
      profilePhoto,
      Dname,
      curriculum,
      type,
      countryName,
      nameLocation,
      profession,
      premiumData,
    } = this.props;
    return (
      <div className="peopleCardWrapperGroup">
        {/* <img
          className="bannerImg"
          src={
            coverPhoto == undefined || coverPhoto == ""
              ? banner
              : `${APPCONFIG.API_URL}auth/image/${coverPhoto}`
          }
          alt={name}
        ></img>

        <div className="w3-row">
          <div className="w3-col w3-container w3-green">
            <div>
              <img
                className="userImg"
                style={{ cursor: "pointer" }}
                onClick={()=>this.showFriendsProfile(userId)}
                src={
                  profilePhoto == undefined || profilePhoto == ""
                    ? dummyimage
                    : `${APPCONFIG.API_URL}auth/image/${profilePhoto}`
                }
                alt={name}
              /> */}
        {/* getUsercoverPhoto */}
        <img
          className="bannerImg"
          // src={
          //   coverPhoto == undefined || coverPhoto === ""
          //     ? banner
          //     : `data:image;base64,${coverPhoto}`
          // }
          src={
            coverPhoto == undefined || coverPhoto === ""
              ? banner
              : API_URL + "auth/image/" + coverPhoto
          }
          alt={name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = banner;
          }}
        ></img>
        {/* <img
                style={{ cursor: "pointer" }}
                onClick={() => this.showFriendsProfile(userId)}
                className="userImg"
                src={API_URL + "api/getUserPhoto/" + userId + "/" + Dname}
                alt="user"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyimage;
                }}
              /> */}
        <div className="w3-row">
          <div className="w3-col w3-container w3-green">
            <div>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => this.showFriendsProfile(userId)}
                className="userImg"
                src={
                  profilePhoto == undefined || profilePhoto === ""
                    ? dummyimage
                    : API_URL + "auth/image/" + profilePhoto
                }
                alt={name}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyimage;
                }}
              />
            </div>
          </div>
        </div>
        <div className="peopleCardInfor">
          <div
            style={{ cursor: "pointer" }}
            className="d-flex align-items-center justify-content-center"
            onClick={() => this.showFriendsProfile(userId)}
          >
            {/* {name}{" "} */}
            <h3 className="card-dName">{Dname}</h3>

            {premiumData && premiumUser(premiumData) && (
              <img
                src={premiumUser(premiumData)}
                alt=""
                className="ml-1 mr-3"
                style={{ width: "20px", height: "20px" }}
              />
            )}
          </div>
          {/* <p>{curriculum}</p> */}
          {/* {this.props.tagsValue && (
            <p>{this.getSearchedTagValue(this.props.tagsValue)}</p>
          )} */}
          {/* {this.props.tagsValue && <p>{this.props.tagsValue[0].tagName}</p>} */}
          <p>{profession}</p>
          <p>{countryName}</p>
          <p>{nameLocation}</p>
          {/* <h2>{(Dname && Dname !=="null" && Dname.length > 0) ? Dname :"-"}</h2> */}
          {/* <p>{this.state.schoolName} - {this.state.className}</p> */}
          {/* <p>{this.state.subjectslist}</p> */}
          {/* <p>{this.state.subjectslist ? this.state.subjectslist.substring(0,40):"-"} {(this.state.subjectslist && this.state.subjectslist.length >39) && "..."}</p> */}
        </div>
        <div className="peopleCardAddRemove flex">
          {this.state.type === "notFriends" && (
            <div
              className="peopleCardAdd"
              onClick={() => this.friendRequest(userId)}
            >
              <p>{t("Add Friend")}</p>
            </div>
          )}
          {this.state.type === "friends" && (
            <div
              className="peopleCardAdd"
              // onClick={() => {
              //   this.chatNow(userId);
              // }}
            >
              <p>{t("Message")}</p>
            </div>
          )}
          {this.state.type === "requestedFriends" && (
            <div
              className="peopleCardAdd"
              // onClick={() => this.friendRequestReDirect(userId)}
            >
              <p>{t("Requested")}</p>
            </div>
          )}
          {this.state.type === "rejected" && (
            <div
              className="peopleCardAdd"
              //  onClick={() => this.friendRequest(userId)}
            >
              <p>{t("Rejected")}</p>
            </div>
          )}
          {this.state.type === "requestPending" && (
            <div className="peopleCardAdd" onClick={this.friendRequestReDirect}>
              <p>{t("Request Pending")}</p>
            </div>
          )}
        </div>
        {/* <SkyLight
          dialogStyles={myProfileDialog}
          afterClose={this.closeFriendsProfile}
          hideOnOverlayClicked
          ref={(ref) => (this.simpleDialog = ref)}
        >
          {this.state.showFriendsProfile && <FriendProfile uid={userId} />}
        </SkyLight> */}
      </div>
    );
  }
  getSearchedTagValue = (tags) => {
    const searchKeyword = this.props.searchData || "";

    console.log("Tag Value", searchKeyword);

    if (Array.isArray(tags) && tags.length > 0) {
      const result = tags.filter((val) =>
        val.tagName.toLowerCase().startsWith(searchKeyword.toLocaleLowerCase())
      );
      console.log("Tag searchvalue", result);

      if (result.length > 0) {
        return result[0]?.tagName;
      } else {
        return tags[0].tagName;
      }
    }
    return "-------";
  };

  friendRequestReDirect() {
    this.props.ClearAll();
  }
  showFriendsProfile(userId) {
    // this.setState({
    //   showFriendsProfile: true,
    // });
    // this.simpleDialog.show();
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  }
  closeFriendsProfile() {
    this.setState({
      showFriendsProfile: false,
    });
  }

  load(type) {
    this.setState({
      type: type,
    });
    // this.getBase64String(data);
    // this.getUserSchoolAndClass(data);
    // this.getCoverPhoto(data)
    //  this.getSchoolName(subjects);
  }

  chatNow(user_id) {
    this.props.history.push(`/messages/${user_id}`);
  }
  getUserSchoolAndClass(data) {
    let schoolandclass = {
      userId: data,
    };

    FriendsFinder.getUserSchoolAndClass(schoolandclass)
      .then((response) => {
        var result = response.data;

        console.log(result.data);

        if (!result.data.hasOwnProperty("userSchoolAndClass")) {
          this.setState({ schoolName: "", className: "" });
        } else if (result.data.hasOwnProperty("userSchoolAndClass")) {
          this.setState({
            schoolName: result.data.userSchoolAndClass.schoolName,
            className: result.data.userSchoolAndClass.className,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getSchoolName(subjects) {
    var subjectName = new Array();
    for (let i = 0; i < this.props.subjectsList.data.length; i++) {
      for (let j = 0; j < subjects.length; j++) {
        if (
          this.props.subjectsList.data[i].subjectId == subjects[j].subjectId
        ) {
          subjectName.push(this.props.subjectsList.data[i].subjectName);
        }
      }
    }
    var subjects = subjectName.join(",");
    await this.setState({ subjectslist: subjects });
  }

  getCoverPhoto(uid) {
    let coverPhoto = {
      userId: uid,
    };
    var response = ForumService.getUserCoverPhotoCache(coverPhoto);
    console.log(response);
    if (response) {
      var value = response.data.data;
      if (value && value.coverPhoto) {
        this.setState({ coverPhoto: response.data.data.coverPhoto });
        // console.log("cache "+base64String)
      }
    } else {
      ProfileService.getUsercoverPhoto(coverPhoto)

        .then((response) => {
          ForumService.setUserCoverPhotoCache(coverPhoto, response);
          var result = response.data;
          this.setState({
            coverPhoto: result.data.coverPhoto,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getBase64String(userId) {
    let getBase64String = { userId: userId };
    var response = ForumService.getUserPhotoCache(getBase64String);
    if (response) {
      var value = response.data.data;
      if (value && value.userPhoto) {
        var base64String = "";
        base64String = `data:image;base64,${response.data.data.userPhoto}`;
        this.setState({ base64String: base64String, base64StringState: true });
      }
    } else {
      ForumService.getUserPhoto(getBase64String)
        .then((response) => {
          ForumService.setUserPhotoCache(getBase64String, response);
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.userPhoto
          ) {
            var base64String = "";
            base64String = `data:image;base64,${response.data.data.userPhoto}`;
            this.setState({
              base64String: base64String,
              base64StringState: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  friendIgnore(id) {
    let friendIgnore = {
      recommendfriendsId: id,
      status: "ignored",
    };
    FriendsFinder.recommendedfriendIgnore(friendIgnore)
      .then((response) => {
        this.props.ignoreSent();
        //toast.success("Ignored successfully...")
        var result = response.data;
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  friendRequest(id) {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      let friendRequest = {
        friendsId: id,
        requestStatus: "requested",
      };
      FriendsFinder.friendRequest(friendRequest)
        .then((response) => {
          this.setState({
            type: "requestedFriends",
          });
          // this.props.requestSent();
          //this.props.getUserRequestList();
          var result = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}

//export default withTranslation()(withRouter());
const mapStateToProps = function (state) {
  return {
    subjectsList: state.commonApi.subjectslist,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(SearchPeopleCard)));
