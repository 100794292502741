import React, { Component } from "react";
import ReactPlayer from "react-player";
import MyCourseService from "../../../api/MyCourseService";
import { withRouter } from "react-router";
import * as APPCONFIG from "../../../config/app-config.js";
import AnnouncementBox from "../Courses/AnnouncementBox";
class Articles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articleId: this.props.match.params.aid,
      base64String: "",
      duration: "",
      showAnnoucment: false,
      type: "",
    };

    this.backtocourse = this.backtocourse.bind(this);
    this.getArticleById = this.getArticleById.bind(this);
    this.videoCompleted = this.videoCompleted.bind(this);
    this.saveCompleted = this.saveCompleted.bind(this);
    this.openAnnoucmentScreen = this.openAnnoucmentScreen.bind(this);
    this.closeFeedBack = this.closeFeedBack.bind(this);
    this.checkContent = this.checkContent.bind(this);
    this.isYoutube = this.isYoutube.bind(this);
    this.checkVideoURL = this.checkVideoURL.bind(this);
    this.checkVimeoUrl = this.checkVimeoUrl.bind(this);
  }

  componentDidMount() {
    var aid = this.props.articleId;

    if (!aid) {
      aid = this.state.articleId;
    }

    let articleId = {
      articleId: aid,
    };

    MyCourseService.getArticleByArticleId(articleId)
      .then((response) => {
        var result = response.data;
        this.checkContent(result.data.articleContent);
        this.setState({ articleName: result.data.articleName });
        this.setState({ articleContent: result.data.articleContent });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onDuration = (duration) => {
    this.setState({ duration });
  };

  onProgress = (progress) => {
    if (!this.state.duration) {
      return;
    }
    const secondsElapsed = progress.played * this.state.duration;

    if (this.state.duration == secondsElapsed) {
      console.log("video fully played");
    }
  };

  videoCompleted() {
    this.saveCompleted();
  }

  saveCompleted() {
    let articleCompleted = {
      articleId: this.props.articleId,
    };

    MyCourseService.saveCompletedCourseByUserId(articleCompleted)
      .then((response) => {
        this.setState({ showAnnoucment: true });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  openAnnoucmentScreen() {
    this.setState({ showAnnoucment: true });
  }

  closeFeedBack() {
    this.setState({ showAnnoucment: false });
  }

  render() {
    const { type } = this.state;

    return (
      <div>
        <h2 style={{ textAlign: "center", margin: "12px" }}>
          {this.state.articleName}
        </h2>

        {!this.state.showAnnoucment && (
          <div>
            {type === "normal" ? (
              <div className="">
                <center>
                  <ReactPlayer
                    onEnded={() => this.videoCompleted()}
                    className="player"
                    controls
                    url={`${APPCONFIG.API_URL}auth/video/${this.state.articleContent}`}
                  />
                </center>
              </div>
            ) : type === "youtube" ? (
              <div className="">
                <center>
                  <ReactPlayer
                    onEnded={() => this.videoCompleted()}
                    className="player"
                    controls
                    url={this.state.articleContent}
                  />
                </center>
              </div>
            ) : type === "vimeo" ? (
              <div className="">
                <center>
                  <ReactPlayer
                    onEnded={() => this.videoCompleted()}
                    className="player"
                    controls
                    url={this.state.articleContent}
                  />
                </center>
              </div>
            ) : type === "video" ? (
              <div className="">
                <center>
                  <ReactPlayer
                    onEnded={() => this.videoCompleted()}
                    className="player"
                    controls
                    url={this.state.articleContent}
                  />
                </center>
              </div>
            ) : (
              ""
            )}
          </div>
        )}

        {this.state.showAnnoucment && (
          <div className="homeMainWrapper">
            <AnnouncementBox
              closeAnnoucmentScreen={this.closeAnnoucmentScreen}
              imgUrl={"announcement.img"}
              title={"announcement.title"}
              content={"announcement.content"}
              articleId={this.props.articleId}
              feedBackClose={this.closeFeedBack}
            />
          </div>
        )}
      </div>
    );
  }

  checkContent(url) {
    var type = "normal";
    if (url) {
      if (url.startsWith("http")) {
        if (this.checkVimeoUrl(url)) {
          type = "vimeo";
        } else if (this.isYoutube(url)) {
          type = "youtube";
        } else if (this.checkVideoURL(url)) {
          type = "video";
        }
      } else {
        type = "normal";
      }
    }
    this.setState({
      type: type,
    });
  }

  checkVimeoUrl(url) {
    url = url.toLowerCase();
    return url.includes("vimeo");
  }

  checkVideoURL(url) {
    url = url.toLowerCase();
    return url.match(/\.(mp4|webm|ogg)$/) != null;
  }

  isYoutube(getURL) {
    if (typeof getURL !== "string") return false;
    var newA = document.createElement("A");
    newA.href = getURL;
    var host = newA.hostname;
    var srch = newA.search;
    var path = newA.pathname;

    if (host.search(/youtube\.com|youtu\.be/) === -1) return false;
    if (host.search(/youtu\.be/) !== -1) return path.slice(1);
    if (path.search(/embed/) !== -1)
      return /embed\/([A-z0-9]+)(\&|$)/.exec(path)[1];
    var getId = /v=([A-z0-9]+)(\&|$)/.exec(srch);
    if (host.search(/youtube\.com/) !== -1) return !getId ? "" : getId[1];
  }

  backtocourse() {
    this.props.history.push(`/classroom`);
  }

  getArticleById() {}

  checkImageURL(url) {
    url = url.toLowerCase();
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }
}
export default withRouter(Articles);
