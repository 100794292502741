import { Paper } from "@material-ui/core";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getAdvertisementChatList,
  getAdvertisementData,
} from "../../../redux/actions/squareHomeActions";
import Images from "../HomeUpdated/ImageView/Images";
import * as APPCONFIG from "../../../config/app-config.js";
import AdvertisementChatReply from "./AdvertisementChatReply";
// import ChatList from "../../Chat/ChatList";
import { API_URL } from "../../../config/app-config";
import UserInfo from "../../Chat/UserInfo";
import {
  extractNumberFromHref,
  htmlValidation,
  validateSpanTag,
} from "../../../utils";
import { getNavigationType } from "../../../redux/actions/commonApiActions";

class AdvertisementChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postId: 0,
      typeData: "",
      chatUserName: "",
      chatUserId: "",
      friendChatStatus: false,
      fileImageData: [],
    };
  }

  componentDidMount() {
    this.setState({
      postId: +this.props.match.params.id,
      typeData: this.props.match.params.type,
      chatUserId: "",
    });
    this.props.getAdvertisementData(
      this.props.match.params.id,
      this.props.match.params.type
    );
    if (
      this.props.ownerUserId &&
      this.props.ownerUserId !== this.props.loginDetails.uId
    ) {
      this.setState({
        chatUserId: this.props.ownerUserId,
        chatUserName: this.props.adminUserName,
      });
      // this.props.getAdvertisementChatList(
      //   +this.props.match.params.id,
      //   this.props.match.params.type,
      //   this.props.ownerUserId
      // );
    }
  }
  componentDidUpdate(previous) {
    if (
      this.props.ownerUserId &&
      this.props.loginDetails.uId !== this.props.ownerUserId &&
      this.props.advertisementData !== previous.advertisementData
    ) {
      console.log("Get Advertisement Data", this.props.ownerUserId);
      this.props.getAdvertisementChatList(
        +this.props.match.params.id,
        this.props.match.params.type,
        this.props.ownerUserId
      );
    }
    if (
      this.props.ownerUserId !== previous.ownerUserId &&
      this.props.ownerUserId === this.props.loginDetails.uId
    ) {
      this.setState({
        chatUserId: "",
      });
    }
    if (
      this.props.ownerUserId &&
      this.props.ownerUserId !== previous.ownerUserId &&
      this.props.loginDetails.uId !== this.props.ownerUserId
    ) {
      this.setState({
        chatUserId: this.props.ownerUserId,
        chatUserName: this.props.adminUserName,
      });
    }
    if (this.props.imageFile && this.props.imageFile !== previous.imageFile) {
      const list = this.props.imageFile.map(
        (item) => `${APPCONFIG.API_URL}auth/image/${item.fileName}`
      );
      console.log("imageFile", list);
      this.setState({
        fileImageData: list,
      });
    }
  }
  openInNewTab = (url) => {
    console.log(url);
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  handleHashTag = (event) => {
    const clickedText = event.target.textContent;
    const regex = /^#[^\s]+$/;
    //Hash Tag validation
    if (regex.test(clickedText)) {
      const newStr = clickedText.replace(/#/g, "");
      this.props.history.push(
        `/${this.props.workspaceType}/${"hash"}/${newStr}`
      );
      return;
    }

    //User Profile validation
    let htmlElement = event.target;
    const htmlString = htmlElement?.outerHTML;
    console.log("givenString", htmlString);

    if (validateSpanTag(htmlString)) {
      this.moveToUserProfile(event, htmlString);
    }
  };
  moveToUserProfile = (event, htmlString) => {
    const id = extractNumberFromHref(htmlString);
    event.preventDefault();
    event.stopPropagation();
    this.props.getNavigationType(1);
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${id}`,
      { from: window.location.pathname }
    );
  };
  render() {
    const { t } = this.props;
    console.log("imageFile", this.state.fileImageData);
    return (
      <Paper style={{ paddingBottom: "7rem" }}>
        <div class="p-4">
          {/* {this.props.loginDetails.uId === this.props.ownerUserId && (
              <span
                style={{ cursor: "pointer" }}
                
                class="badge badge-info"
                onClick={() =>
                  window.open(
                    `${APPCONFIG.API_URL}auth/mediaType/${this.props.imageFile}`,
                    "_blank"
                  )
                }
              >
                Download your advertise attachment
              </span>
            )} */}
          {this.props.loginDetails.uId !== this.props.ownerUserId && (
            <span class="badge badge-info">Chat to advertisement owner</span>
          )}
          <p className="about-mb-2" onClick={this.handleHashTag}>
            {htmlValidation(
              this.props.fileContent ? this.props.fileContent : ""
            )}
          </p>

          <div class="adb-card mt-4">
            <div className="adb-card-wrap mr-2">
              {this.props.loginDetails.uId === this.props.ownerUserId && (
                <>
                  <div className="ChatListDetailsWrapper">
                    <UserInfo
                      imgUrl={
                        API_URL +
                        "api/getUserPhoto/" +
                        this.props.ownerUserId +
                        "/" +
                        this.props.adminUserName
                      }
                      userName={this.props.loginDetails.dName}
                      designation={this.props.loginDetails.biography}
                      status={"online"}
                    />
                    <div className="activeChats flexDefault">
                      <h4 className="activeChatTitle">{t("Active Chats")}</h4>
                    </div>
                    <div className="chatListsWrapper">
                      {/* {this.props.chatUser &&
                        this.props.chatUser.map((friends, index) => (
                          <ChatList
                            key={1}
                            imgUrl={
                              API_URL +
                              "api/getUserPhoto/" +
                              friends.userId +
                              "/" +
                              friends.userName
                            }
                            userName={friends.userName}
                            status={friends.status}
                            // tagLine={"Typing…"}
                            // date="08:05"
                            isActive={true}
                            onClick={() =>
                              this.onClickMessage(
                                friends.userId,
                                friends.userName
                              )
                            }
                          />
                        ))}
                      {this.props.chatUser &&
                        this.props.chatUser.length === 0 && (
                          <p style={{ color: "gray" }}>
                            {t("No chat users present")} ...
                          </p>
                        )} */}
                    </div>
                  </div>
                </>
              )}
              {this.props.loginDetails.uId !== this.props.ownerUserId && (
                <div>
                  {/* {this.props.imageFile &&
                      this.props.imageFile.map((item) => (
                        <img
                          alt="advertisement"
                          class="img-grid-chat"
                          src={`${APPCONFIG.API_URL}auth/image/${item.fileName}`}
                        />
                      ))} */}
                  {this.props.imageFile && this.state.fileImageData && (
                    <Images
                      images={this.state.fileImageData}
                      onClickImage={this.subscribeAdvertisement}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="adb-card-wrap">
              {this.state.chatUserId === "" &&
                this.props.loginDetails.uId === this.props.ownerUserId &&
                this.props.imageFile &&
                this.state.fileImageData && (
                  <Images
                    images={this.state.fileImageData}
                    onClickImage={this.subscribeAdvertisement}
                  />
                )}
              {this.state.chatUserId !== "" && (
                <AdvertisementChatReply
                  ownerUserId={this.props.ownerUserId}
                  postId={this.props.match.params.id}
                  typeData={this.props.match.params.type}
                  chatUser={this.props.chatUser}
                  chatUserName={this.state.chatUserName}
                  chatUserId={this.state.chatUserId}
                  chatList={this.props.chatList}
                  onChangeChatStatus={this.state.friendChatStatus}
                  loginDetails={this.props.loginDetails}
                />
              )}
            </div>
            <hr />
          </div>
        </div>
      </Paper>
    );
  }
  onClickMessage = (id, name) => {
    this.props.getAdvertisementChatList(
      this.state.postId,
      this.state.typeData,
      id
    );
    this.setState({
      chatUserName: name,
      chatUserId: id,
      friendChatStatus: !this.state.friendChatStatus,
    });
  };
  subscribeAdvertisement = () => {};
}

const mapDispacthToProps = (dispatch) => {
  return {
    getNavigationType: (type) => dispatch(getNavigationType(type)),

    getAdvertisementData: (id, type) =>
      dispatch(getAdvertisementData(id, type)),
    getAdvertisementChatList: (id, type, receiverId) =>
      dispatch(getAdvertisementChatList(id, type, receiverId)),
  };
};

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    advertisementData: state.surveyList.advertisementData,
    fileContent: state.surveyList.advertisementData?.advertisement?.fileContent,
    imageFile:
      state.surveyList.advertisementData?.advertisement?.postMediaFiles,

    adminUserName: state.surveyList.advertisementData?.advertisement?.userName,
    ownerUserId: state.surveyList.advertisementData?.advertisement?.userId,
    chatUser: state.surveyList.advertisementData?.chat_users,
    chatList: state.surveyList.chatListData,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(AdvertisementChat)));
