import React, { Component } from "react";
import MaterialTable from "material-table";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PostService from "../../../api/PostService";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import Swal from "sweetalert2";

class AnnouncementListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      announcement: [],
      title: "",
    };
  }
  componentDidMount() {
    PostService.getAnnouncementByWorkspace()
      .then((response) => {
        var result = response.data.data;
        this.setState({
          title: `Total announcement list - ${result.announcement?.length}`,
        });
        if (result.announcement) {
          const list = result.announcement.map((item) => {
            return {
              createdDate: moment(item.createdDate).format("DD-MM-yyyy"),
              description: item.description,
              endDate: moment(item.endDate).format("DD-MM-yyyy"),
              file: item.file,
              id: item.id,
              title: item.title,
            };
          });
          this.setState({
            announcement: list,
          });
        }
      })
      .catch((e) => {});
  }
  backToHome = () => {
    this.props.history.push(`/${this.props.workspaceType}/home`);
  };
  addClicked = () => {
    this.props.history.push(
      `/${this.props.workspaceType}/announcement-form/${"new"}/update-form`
    );
  };
  render() {
    return (
      <div>
        <Card>
          <CardHeader title="ANNOUNCEMENT LIST"></CardHeader>
          <CardContent className="boxPage">
            <div className="toolbar">
              <div className="rightBox">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.addClicked}
                  startIcon={<AddIcon />}
                >
                  {" "}
                  Create New Announcement
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  style={{ float: "right" }}
                  variant="contained"
                  color="primary"
                  onClick={() => this.backToHome()}
                  startIcon={<ArrowBackIcon />}
                >
                  {" "}
                  Back
                </Button>
              </div>
            </div>

            <div style={{ marginTop: "50px" }}>
              <MaterialTable
                style={{ width: "100%" }}
                columns={[
                  {
                    title: "Announcement Title",
                    field: "title",
                  },
                  {
                    title: "StartDate",
                    field: "createdDate",
                  },
                  {
                    title: "EndDate",
                    field: "endDate",
                  },
                ]}
                data={this.state.announcement}
                title={this.state.title}
                options={{
                  pageSize: 10,
                  exportButton: true,
                  search: true,
                  sorting: true,
                  headerStyle: {
                    backgroundColor: "#a00030",
                    color: "#FFF",
                  },
                  actionsColumnIndex: -1,
                }}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Edit Announcement",
                    onClick: (event, rowData) =>
                      this.onUpdate(
                        rowData.id
                      ) /*alert("You saved " + rowData.userId)*/,
                  },
                  (rowData) => ({
                    icon: "delete",
                    tooltip: "Delete Annouuncement",
                    onClick: (event, rowData) =>
                      this.onDelete(
                        rowData.id
                      ) /* alert("You want to delete " + rowData.userId),*/,
                  }),
                ]}
                // onRowClick={(event, rowData) => {
                //   console.log(rowData);
                // }}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
  onUpdate = (id) => {
    this.props.history.push(
      `/${this.props.workspaceType}/announcement-form/${id}/update-form`
    );
  };
  onDelete = (id) => {
    Swal.fire({
      title: "Are you sure to delete this announcement",
      text: "You won't be able to revert this!",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        let data = {
          id: id,
        };
        PostService.deleteAnnouncement(data)
          .then((response) => {
            Swal.fire({
              title: "Deleted Successfully",
              icon: "info",
              type: "info",
              showConfirmButton: true,
            });
            const deleteList = this.state.announcement.filter(
              (item) => item.id !== id
            );
            this.setState({
              announcement: deleteList,
              title: `Total announcement list - ${deleteList?.length}`,
            });
          })
          .catch((error) => {
            console.log("error");
            Swal.fire({
              title: "Error in delete",
              icon: "error",
              type: "error",
              showConfirmButton: true,
            });
          });
      }
    });
  };
}

const mapStateToProps = function (state) {
  return {
    workspaceType: state.loginDetailsApi.loginDetails?.userWorkSpace?.slug, //  tagDatas: state.subjects.myGroupHome,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(AnnouncementListContainer)));
