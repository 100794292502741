import React, { Component } from "react";
import AuthService from "../../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { BsCalendarDate } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import moment from "moment";
import { Grid } from "@material-ui/core";

class GroupInfoCard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    console.log("dob");
    console.log(this.props.country);
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div class="">
          <div class="about-card">
            <div class="about-card-header border-0 pb-0">
              <h5 class="about-card-title">{t("About")}</h5>
            </div>
            <div class="card-body position-relative pt-0">
              <p className="about-body-p">{this.props.bio}</p>

              <ul class="list-unstyled mt-3 mb-0">
               <li class="about-mb-2" >
                  
                  {/* <strong> */}
                    {" "}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.props.subjectDescription,
                      }}
                    />
                    {}{" "}
                  {/* </strong>{" "} */}
                </li>
                {/* {(this.props.expiry != "No" && this.props.expiryDate != "Invalid date" ) && (
                  <li class="about-mb-2">
                    {" "}
                    <BsCalendarDate
                      style={{ width: "15px", height: "15px", color: "gray" }}
                    ></BsCalendarDate>{" "}
                    Expiry Date :{" "}
                    <strong>
                      {" "}
                      {moment(this.props.expiryDate).locale('en').format('LL')}
                      
                     
                    </strong>{" "}
                  </li>
                )} */}
                
                {/* <GoLocation style={{ width: "15px", height: "15px", color: "gray" }}></GoLocation>  {this.props.email !== "" && <li class="about-mb-2"> <AiOutlineMail style={{ width: "15px", height: "15px", color: "gray" }}></AiOutlineMail> Email: <strong> {this.props.email} </strong> </li>
                                }  */}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {};
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(GroupInfoCard)));
