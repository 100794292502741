import React, { useEffect, useMemo, useState } from "react";
import Image from "../../images/yuukke.png";
import moment from "moment";
import { BsArrowUpRightSquare } from "react-icons/bs";
import { MenuItem, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CalenderService from "../../api/CalenderService.js";
import { JITSI_URL } from "../../config/app-config.js";
import { toast } from "react-toastify";
import { FaCircleNotch } from "react-icons/fa";
const EventPage = () => {
  const authenticated = useSelector(
    (state) => state.loginDetailsApi.keycloakAuthenticated
  );
  let currentDate = moment(new Date()).format("yyyy-MM-DD");

  const location = useLocation();
  const [state, setState] = useState({
    loading: true,
    data: "",
    error: "",
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    exStartDate: "",
    exEndDate: "",
    meeting: "",
  });
  const slug =
    location.pathname.slice(location.pathname.lastIndexOf("/") + 1) ?? "";
  console.log("slug", slug);
  useEffect(() => {
    let page = document.querySelector(".nav-menu-new");
    if (page && !authenticated) {
      page.classList.remove("nav-menu-new");
    }
  }, []);

  useEffect(() => {
    if (slug) {
      CalenderService.getEventDetails(slug)
        .then((response) => {
          let result = response.data.data;
          console.log("event details", response);
          if (result?.taskName) {
            setState({
              ...state,
              loading: false,
              title: result.taskName,
              description: result.taskDescription,
              meetingLink: result.url,
              startDate: result.startDate,
              endDate: result.endDate,
              meeting: result,
              exStartDate: moment(result.startDate).format("yyyy-MM-DD"),
            });
          } else {
            setState({
              ...state,
              loading: false,
              error: result,
            });
          }
        })
        .catch((e) => {
          console.log("e", e);
        });
    }
  }, []);
  const handleAddToGoogleCalendar = () => {
    const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      state.title
    )}&dates=${encodeURIComponent(
      moment(state.startDate)
        .toISOString()
        .replace(/-|:|\.\d\d\d/g, "")
    )}/${encodeURIComponent(
      moment(state.endDate)
        .toISOString()
        .replace(/-|:|\.\d\d\d/g, "")
    )}&details=${encodeURIComponent(
      state.description
    )}&location=${encodeURIComponent()}`;
    window.open(googleCalendarUrl, "_blank");
  };

  const handleAddToAppleCalendar = () => {
    const appleCalendarUrl = `data:text/calendar;charset=utf8,BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nDTSTART:${moment(
      state.startDate
    ).toISOString()}\nDTEND:${moment(state.endDate).toISOString()}\nSUMMARY:${
      state.title
    }\nDESCRIPTION:${
      state.description
    }\nLOCATION:${location}\nEND:VEVENT\nEND:VCALENDAR`;
    const blob = new Blob([appleCalendarUrl], {
      type: "text/calendar;charset=utf-8",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "event.ics");
    document.body.appendChild(link);
    link.click();
  };
  const handleAddToOutlookCalendar = () => {
    const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${moment(state.startDate).toISOString()}
DTEND:${moment(state.endDate).toISOString()}
SUMMARY:${state.title}
DESCRIPTION:${state.description}
 END:VEVENT
END:VCALENDAR`;

    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "event.ics");
    document.body.appendChild(link);
    link.click();
  };
  const addToMyCalendar = (e) => {
    if (e === "Google") {
      handleAddToGoogleCalendar();
    } else if (e === "Apple") {
      handleAddToAppleCalendar();
    } else {
      handleAddToOutlookCalendar();
    }
  };
  const joinMeeting = () => {
    let regex = state.meeting.url.replace(/&.*/, "");

    if (state.meeting.taskType === "mentor") {
      window.open(`${JITSI_URL}/${regex}?&sp=yes`, "_blank");
    } else if (!state.meeting.meetingInApplication) {
      window.open(state.meeting.url, "_blank");
    } else if (state.meeting.taskType === "public") {
      window.open(`${JITSI_URL}/${regex}?&isGlob=true&sp=yes#config`, "_blank");
    } else {
      window.open(
        `${JITSI_URL}/${regex}?&isGroup=true&sp=yes#config`,
        "_blank"
      );
    }
  };
  const showLink = () => {
    if (state?.meeting?.url) {
      let regex = state.meeting.url.replace(/&.*/, "");
      if (state.meeting.taskType === "mentor") {
        return `${JITSI_URL}/${regex}?&sp=yes#config`;
      } else if (!state.meeting.meetingInApplication) {
        return state.meeting.url;
      } else if (state.meeting.taskType === "public") {
        return `${JITSI_URL}/${regex}?&isGlob=true&sp=yes#config`;
      } else {
        return `${JITSI_URL}/${regex}?&isGroup=true&sp=yes#config`;
      }
    }
  };
  const handleCopy = async () => {
    let regex = state.meeting.url.replace(/&.*/, "");
    if (!state.meeting.meetingInApplication) {
      await navigator.clipboard.writeText(state.meeting.url);
    } else if (state.meeting.taskType === "mentor") {
      await navigator.clipboard.writeText(
        `${JITSI_URL}/${regex}?&sp=yes#config`
      );
    } else if (state.meeting.taskType === "public") {
      await navigator.clipboard.writeText(
        `${JITSI_URL}/${state.meeting.url}?&isGlob=true&sp=yes#config`
      );
    } else {
      await navigator.clipboard.writeText(
        `${JITSI_URL}/${regex}?&isGroup=true&sp=yes#config`
      );
    }
    toast.success("Link coped in you clip board");
  };
  const meetingCopy = useMemo(showLink, [state]);
  console.log("state", state);
  return (
    <div className="event-ref-page">
      <header>
        <img src={Image} alt="img" />
      </header>
      {state.loading ? (
        <div className="d-flex justify-content-center p-5">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : state.title ? (
        <>
          <section>
            <div style={{ minHeight: "17rem" }}>
              <h4 className="mb-3 font-weight-bold">{state.title}</h4>

              <p
                className="mb-3"
                style={{ fontSize: "14px", maxWidth: "50rem" }}
                dangerouslySetInnerHTML={{ __html: state.description }}
              />
              <span
                className="mt-3 font-weight-bold text-white"
                style={{ fontSize: "14px" }}
              >
                {moment(state.startDate).locale("en").format("LLL")}
              </span>
            </div>
          </section>
          <footer>
            <div className="meeting-box">
              <div
                className="d-flex align-items-center flex-wrap"
                style={{ gap: "1rem" }}
              >
                <h6 className="m-0">Meeting Link:</h6>
                <div className="meeting-copy" onClick={() => handleCopy()}>
                  <p>{meetingCopy}</p>
                  <p>Copy Link</p>
                </div>
              </div>

              {moment(state.exStartDate).isBefore(currentDate) ||
              moment(state.exStartDate).isSame(currentDate) ? (
                <span
                  className="meeting-box-btn"
                  onClick={(e) => joinMeeting(e)}
                >
                  JOIN MEETING
                  <BsArrowUpRightSquare className="btn-svg" />
                </span>
              ) : (
                <span
                  className="meeting-box-btn"
                  style={{ cursor: "not-allowed" }}
                >
                  MEETING NOT STARTED YET
                  <FaCircleNotch className="btn-svg" />
                </span>
              )}
            </div>
            <div className="meeting-box">
              <h6 className="m-0">Add meeting in your Calendar : </h6>
              <TextField
                style={{ minWidth: "15rem" }}
                className="text-filed-border mr-1"
                id="dropdown"
                select
                label=""
                variant="outlined"
                type="text"
                required=""
                name=""
                value=" "
                onChange={(e) => {
                  addToMyCalendar(e.target.value);
                }}
              >
                <MenuItem value=" ">
                  <em>ADD TO MY CALENDAR</em>
                </MenuItem>
                <MenuItem value="Google">
                  <em>Google</em>
                </MenuItem>
                <MenuItem value="Apple">
                  <em>Apple</em>
                </MenuItem>
                <MenuItem value="Outlook">
                  <em>Outlook</em>
                </MenuItem>
              </TextField>
            </div>
          </footer>
        </>
      ) : (
        <>
          <div>
            {state.error === "EVENT_EXPIRED"
              ? "Event already finished ... "
              : state.error === "EVENT_DELETED"
              ? "Event has been deleted ... "
              : "Some thing went wrong ... "}

            <a href="https://yuukke.com/">Visit Yuukke</a>
          </div>
        </>
      )}
    </div>
  );
};

export default EventPage;
