import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import i18n from "../../i18n";
import { withTranslation } from "react-i18next";
import "react-widgets/styles.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Grid } from "@material-ui/core";
import notepad from "../../images/notepad.png";
import MyRequestForm from "./MyRequestForm";
import MyRequestList from "./MyRequestList";
import {
  getJobRequestByUser,
  getJobRequestByOtherUser,
} from "../../redux/actions/myRequestActions";
import { Container } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { API_URL } from "../../config/app-config";
import dummyimage from "../../img/default_avatar.jpg";
import moment from "moment";
import { GoCalendar } from "react-icons/go";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import OtherRequestList from "./OtherRequestList";
import ProfileService from "../../api/ProfileService";
import BlockUi from "react-block-ui";
import { ToastContainer } from "react-toastify";
import { triggerTourPopup } from "../../redux/actions/commonApiActions";

class MyRequestContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postRequest: false,
      tabIndex: 0,
      selectAreaName: "",
      selectIndustryName: "",
      myRequestList: [],
      noMoreState: false,
      pageNo: 0,
      searchListPage: 0,
      viewOthersRequest: false,
      blocking: false,
    };
    this.onPostRequestForm = this.onPostRequestForm.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.viewOthersRequest = this.viewOthersRequest.bind(this);
    this.handleChangeRequestCard = this.handleChangeRequestCard.bind(this);
  }

  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
    });
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    //this.props.getJobRequestByOtherUser();
    if (this.props.otherRequestListCheck) {
      this.setState({ tabIndex: 1 });
    } else {
      this.setState({ tabIndex: 0 });
    }
    this.getUserList();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.toursStages &&
      this.props.firstTimeRegPage &&
      this.props.firstTimeRegPage !== prevProps.firstTimeRegPage
    ) {
      this.props.triggerTourPopup("askHelp");
      console.log("Im triggering tour popup");
    }
  }

  // componentWillReceiveProps(nextProps) {
  //    if (this.props.myRequestList !== nextProps.myRequestList) {
  //     this.setState({
  //       myRequestList: nextProps.myRequestList,
  //     });
  //     console.log("myRequestList props", nextProps.myRequestList);

  //   }
  // }
  render() {
    const { t } = this.props;
    const { tabIndex } = this.state;
    console.log("useRequestList 1", this.props.myRequestList);

    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />

        {!this.state.viewOthersRequest && !this.state.postRequest && (
          <div>
            <BlockUi
              tag="div"
              message={t("Loading, please wait")}
              style={{ minHeight: "130px" }}
              className="wallLoadingPage"
              blocking={this.props.requestLoaderStatus}
            >
              <>
                {!this.props.firstTimeRegPage &&
                  !this.props.requestLoaderStatus && (
                    <div style={{ paddingTop: "24px" }}>
                      {/* <h3 className="listHomeTitle" style={{paddingTop:"6rem"}}>Request Help</h3> */}
                      <div
                        className="askAQuestionCardWrapperNew"
                        style={{ height: "auto" }}
                      >
                        <button
                          style={{ float: "right", marginBottom: "5px" }}
                          onClick={this.viewOthersRequest}
                        >
                          {t("View others Requests")}
                        </button>
                        {!this.state.postRequest && (
                          <Grid container spacing={0} alignItems="flex-end">
                            <Grid item xs={12} md={12} lg={12} sm={12}>
                              <center>
                                {" "}
                                <img src={notepad} alt="img"></img>
                              </center>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              sm={12}
                              style={{ marginTop: "30px" }}
                            >
                              <Grid
                                container
                                spacing={0}
                                alignItems="flex-end"
                                style={{ margin: "30px" }}
                              >
                                <Grid item xs={12} md={4} lg={4} sm={12}>
                                  <h5 style={{ padding: "8px" }}>
                                    {"1." + t("Request help")}
                                  </h5>
                                  <p
                                    style={{
                                      color: "gray",
                                      padding: "8px",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {t(
                                      "Describe the service or partnership collaboration that you are searching for"
                                    )}
                                  </p>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4} sm={12}>
                                  <h5 style={{ padding: "8px" }}>
                                    {"2." + t("Get Replies")}
                                  </h5>
                                  <p
                                    style={{
                                      color: "gray",
                                      padding: "8px",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {t(
                                      "Sit back while some of the community members will provide a replay."
                                    )}
                                  </p>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4} sm={12}>
                                  <h5 style={{ padding: "8px" }}>
                                    {"3." + t("Choose and Connect")}
                                  </h5>
                                  <p
                                    style={{
                                      color: "gray",
                                      padding: "8px",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {t("Choose and Connect")}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sm={12}>
                              <center>
                                <button
                                  style={{ cursor: "pointer" }}
                                  onClick={this.onPostRequestForm}
                                >
                                  {t("Ask help")}
                                </button>
                              </center>
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    </div>
                  )}
                {this.props.firstTimeRegPage && (
                  <div className="" style={{ marginTop: "10px" }}>
                    <div className="d-flex align-items-center justify-content-between">
                      {this.state.tabIndex == 0 && (
                        <h3 className="listHomeTitle">{t("Ask Help")}</h3>
                      )}
                      {this.state.tabIndex == 1 && (
                        <h3 className="listHomeTitle">{t("Other Requests")}</h3>
                      )}

                      <button
                        className="ask-help-crate-tour"
                        style={{ float: "right" }}
                        onClick={this.onPostRequestForm}
                      >
                        {t("Ask Help")}
                      </button>
                    </div>

                    <div className="squareMainWrapper">
                      <div className="peopleCardWrapperGroups ">
                        <Container>
                          <div className="flexDefault  ">
                            <Tabs
                              TabIndicatorProps={
                                {
                                  // style: { background: "#f97516" },
                                }
                              }
                              value={tabIndex}
                              //   textColor="secondary"
                              textColor="primary"
                              onChange={this.handleChangeTab}
                              aria-label="disabled tabs example"
                            >
                              <Tab
                                style={{ textTransform: "none" }}
                                label={t("ASK HELP")}
                                className="ask-help-tour"
                              />
                              <Tab
                                style={{ textTransform: "none" }}
                                label={t("OTHER REQUESTS")}
                                className="other-request-tour"
                              />
                            </Tabs>
                          </div>
                        </Container>
                      </div>

                      {tabIndex === 0 ? (
                        <>
                          <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <div className="announcementWrapperOuter">
                                <Grid style={{ paddingTop: 12 }}>
                                  {this.props.myRequestList &&
                                    this.props.myRequestList?.length > 0 &&
                                    this.props.myRequestList.map(
                                      (request, index) => (
                                        <div
                                          className="peopleInvitationCardWrapper"
                                          onClick={() =>
                                            this.handleChangeRequestCard(
                                              request.jobId
                                            )
                                          }
                                        >
                                          <div class="d-flex align-items-center ">
                                            <p
                                              style={{
                                                color: "#000000",
                                                padding: "8px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {request.natureOfRequest}
                                            </p>
                                            <p
                                              style={{
                                                color: "gray",
                                                fontSize: "12px",
                                              }}
                                            >
                                              <GoCalendar
                                                style={{
                                                  width: "16px",
                                                  height: "16px",
                                                }}
                                              ></GoCalendar>{" "}
                                              {moment(request.createdDate)
                                                .locale("en")
                                                .format("ll")}
                                            </p>
                                          </div>
                                          <p
                                            style={{
                                              color: "gray",
                                              paddingLeft: "8px",
                                              fontSize: "12px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {" "}
                                            {request.description}{" "}
                                          </p>
                                        </div>
                                      )
                                    )}
                                </Grid>

                                {!this.props.requestLoaderStatus &&
                                  this.props.myRequestList?.length > 0 &&
                                  this.props.seeMorePage && (
                                    <div className="networkDetailsBtn">
                                      <button
                                        className="networkSeeMore"
                                        onClick={this.onChangeCallingSeeMore}
                                      >
                                        {t("See more")}
                                      </button>
                                    </div>
                                  )}
                                {this.props.firstTimeRegPage &&
                                  !this.props.requestLoaderStatus &&
                                  this.props.myRequestList?.length === 0 && (
                                    <div className="aboutMyRequestWrapper mt-1">
                                      <p style={{ textAlign: "center" }}>
                                        {t("List is empty")}
                                      </p>
                                    </div>
                                  )}
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <OtherRequestList
                            firstTimeRegPage={this.props.firstTimeRegPage}
                            interestList={this.props.areaofinterestList}
                            industryList={this.props.industryList}
                            otherRequestList={this.props.otherRequestList}
                            myRequestList={this.props.myRequestList}
                            tagList={this.props.tagList}
                            viewList={false}
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}{" "}
              </>
            </BlockUi>
          </div>
        )}
        {!this.state.viewOthersRequest && this.state.postRequest && (
          <MyRequestForm
            handleClickAway={this.handleClickAway}
            getRequestList={this.getUserList}
            isBack={true}
          ></MyRequestForm>
        )}
        {this.state.viewOthersRequest && (
          <OtherRequestList
            interestList={this.props.areaofinterestList}
            industryList={this.props.industryList}
            otherRequestList={this.props.otherRequestList}
            handleClickAway={this.handleClickAwaytoRequestList}
            tagList={this.props.tagList}
            myRequestList={this.props.myRequestList}
            viewList={true}
          />
        )}
      </div>
    );
  }
  getUserList = () => {
    this.setState({
      blocking: true,
    });
    var data = {
      page: 0,
    };

    // ProfileService.getJobRequestByUserIdV2(data)
    //   .then((response) => {
    //     var result = response.data.data.jobRequestData;

    //     if (response.data.data.jobRequestData.length > 0) {
    //       this.setState({
    //         noMoreState: true,
    //         myRequestList: result,
    //         searchListPage: response.data.data.totalPages,
    //         // pageNo: this.state.pageNo+1,
    //         firstTimeRegPage: false,
    //       });
    //     } else {
    //       this.setState({ noMoreState: false });
    //       this.setState({
    //         myRequestList: [],
    //         searchListPage: 0,
    //         firstTimeRegPage: true,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // this.setState({
    //   blocking: false,
    // });
    this.props.getJobRequestByUser(0);
  };
  onChangeCallingSeeMore = () => {
    this.props.getJobRequestByUser(this.state.pageNo + 1);
    // var data = {
    //   page: this.state.pageNo + 1,
    // };
    // this.setState({
    //   blocking: true,
    //   pageNo: this.state.pageNo + 1,
    // });
    // ProfileService.getJobRequestByUserIdV2(data)
    //   .then((response) => {
    //     var result = response.data.data.jobRequestData;

    //     if (result.length > 0) {
    //       var oldPostList = this.state.myRequestList;
    //       var newRequests = oldPostList.concat(result);

    //       this.setState({
    //         noMoreState: true,
    //         myRequestList: newRequests,
    //         // pageNo: this.state.pageNo + 1,
    //       });
    //     } else {
    //       this.setState({ noMoreState: false });
    //       this.setState({ myRequestList: this.state.myRequestList });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // this.setState({
    //   blocking: false,
    // });
  };

  handleClickAwaytoRequestList = () => {
    this.setState({
      viewOthersRequest: false,
      postRequest: true,
    });
  };

  handleChangeRequestCard(e) {
    this.props.history.push(
      `/${this.props.workspaceName}/request-dis/${e}/${"my-req"}`
    );
  }

  viewOthersRequest() {
    // this.props.history.push("/other-requests");
    this.setState({
      viewOthersRequest: true,
      postRequest: false,
    });
  }

  handleClickAway() {
    if (!this.props.firstTimeRegPage) {
      this.props.getJobRequestByUser(0);
      this.setState({ postRequest: false });
    } else {
      this.setState({ postRequest: false });
    }
  }
  onPostRequestForm() {
    this.setState({ postRequest: true });
  }
}

const mapStateToProps = function (state) {
  return {
    seeMorePage: state.myRequestReducers.seeMorePage,
    firstTimeRegPage: state.myRequestReducers.firstTimeRegPage,
    requestLoaderStatus: state.myRequestReducers.requestLoaderStatus,
    loginDetails: state.loginDetailsApi.loginDetails,
    myRequestList: state.myRequestReducers.myRequestList,
    otherRequestList: state.myRequestReducers.otherRequestList,
    industryList: state.commonApi.industrylist.data,
    areaofinterestList: state.commonApi.areaofinterestlist,
    tagList: state.commonApi.taglist.data,
    workspaceName: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    toursStages: state.commonApi.askHelpPopupStatus,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    triggerTourPopup: (slug) => dispatch(triggerTourPopup(slug)),
    getJobRequestByUser: (pageNo) => dispatch(getJobRequestByUser(pageNo)),
    getJobRequestByOtherUser: () => dispatch(getJobRequestByOtherUser()),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(MyRequestContainer)));
