import React from "react";
import Logo from "../../../images/yuukke.png";

const MailVerification = () => {
  return (
    <div className="login-container position-relative">
      <section className="email-verify">
        <img src={Logo} alt="logo" className="mb-5" />
        <div className="p-3 d-flex flex-column " style={{ gap: "1rem" }}>
          <h4 style={{ fontWeight: "500" }}>Email verification</h4>
          <div class="p-3 alert alert-warning" role="alert">
            You need to verify your email address to activity your account.
          </div>
          <p className="text-secondary">
            An email with instructions to verify your email address has been
            sent to you.
          </p>
          <p className="text-secondary">
            Haven't received a verification code in your email?
            <a href="#" className="ml-2 mr-2">
              Click here
            </a>{" "}
            to re-send the email.
          </p>
        </div>
      </section>
    </div>
  );
};

export default MailVerification;
