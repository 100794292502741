import auth from "./AuthAPI.js";
import Http from "./Http.js";
import * as APPCONFIG from "../config/app-config.js";
import api from "./BaseAPI.js";

class CalenderService {
  //job request
  getCalendarListByUserId(formValues = {}) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/v1/calendar/getListByUserId/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  showInterest(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/calendar/souInterest`,
      data: formValues,
    });
  }

  deleteCalendar(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteCalendar`,
      data: formValues,
    });
  }
  getEventDetails(slug) {
    return api({
      method: "get",
      headers: Http.HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/calendar/single/${slug}
      `,
    });
  }
}

export default new CalenderService();
