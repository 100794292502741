import ForumService from "../../api/ForumService";
import Utils from "../../utils/Utils";
import ProfileService from "../../api/ForumService";
import {
  DELETE_GROUP_POST,
  DELETE_POST,
  GET_SUBJECT_QUESTION_LIST,
} from "../types";
import { UPDATE_SUBJECT_QUESTION_LIST } from "../types";
import { SUBJECT_QUESTION_LOADER } from "../types";
import moment from "moment";
import FriendsFinder from "../../api/FriendsFinder";
import { GET_POSTS_OVERALL } from "../types";
// import AuthService from "../../session/AuthService";

export const getOverallPostList = (pageNo, subjectId) => async (dispatch) => {
  try {
    // alert()
    let interestingTop;
    interestingTop = {
      page: pageNo,
      subjectId: subjectId,
      // type: "groupOrEvents",
    };
    ProfileService.getPostOverall(interestingTop)
      .then((response) => {
        var result = response.data;
        console.log(result);
        console.log("overallList", result.data);
        if (response.data.status === 1) {
          var stringArray = result.data;
          dispatch({
            type: GET_POSTS_OVERALL,
            payload: stringArray,
          });
        } else {
          dispatch({
            type: GET_POSTS_OVERALL,
            payload: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_POSTS_OVERALL,
      payload: [],
    });
  }
  return [];
};

export const getSubjectQuestionsList =
  (typeQuestion, pageNo, subjectId, firstTimeLoad, secondTimeLoad, userId) =>
  async (dispatch) => {
    try {
      let interestingTop;

      // dispatch(SubjectQuestionListLoader(true));
      if (subjectId > 0) {
        interestingTop = {
          type: typeQuestion,
          page: pageNo - 1,
          subjectkey: subjectId,
        };
      } else {
        interestingTop = {
          type: typeQuestion,
          page: pageNo - 1,
          subjectkey: 0,
        };
      }

      //  if (firstTimeLoad) {
      ForumService.questionsearchList(interestingTop)
        .then((response) => {
          var result = response.data;
          console.log(result);

          if (result.data.questions.length >= 1) {
            var topqestList = [];
            const question_list = [];
            console.log("GET_SUBJECT_QUESTION_LIST", response.data);
            var userlist = [];
            for (let j = 0; j < result.data.questions.length; j++) {
              var createdDate = result.data.questions[j].createdDate;
              moment(createdDate).format("DD/MM/yyyy");
              var Dates = Utils.getLastAskedDate(createdDate);
              question_list.push({
                answer: result.data.questions[j].answer,
                answerSize: result.data.questions[j].answerSize,
                createdDate: result.data.questions[j].createdDate,
                questionDescription:
                  result.data.questions[j].questionDescription,
                questionId: result.data.questions[j].questionId,
                questionName: result.data.questions[j].questionName,
                questionTags: result.data.questions[j].questionTags,
                questionviewers: result.data.questions[j].questionviewers,
                grouporeventFiles: result.data.questions[j].grouporeventFiles,
                likesandcommentd: result.data.questions[j].likesandcommentd,
                questionType: result.data.questions[j].questionType,
                solved: result.data.questions[j].solved,
                updatedDate: result.data.questions[j].updatedDate,
                userId: result.data.questions[j].userId,
                userName: result.data.questions[j].userName,
                views: result.data.questions[j].views,
                votes: result.data.questions[j].votes,
                gradeId: result.data.questions[j].grade,
                questionFile: result.data.questions[j].questionFile,
                lastAsked: Dates,
                programId: result.data.questions[j].programId,
                questionLikesArray: result.data.questions[j].questionLikesArray,
                originalFileName: result.data.questions[j].originalFileName,
                markAsPrivate: result.data.questions[j].markAsPrivate,
                star: result.data.questions[j].questionLikesArray.filter(
                  (star) => star.userId == userId
                ),
                pageNo: pageNo,
                status: result.data.questions[j].status,
                answerBlockStatus: result.data.questions[j].answerBlockStatus,
                aicheck: result.data.questions[j].aicheck,
                filterCheck: result.data.questions[j].filterCheck,
              });
              userlist.push(result.data.questions[j].userId);
            }

            function onlyUnique(value, index, self) {
              return self.indexOf(value) === index;
            }
            var uniques = userlist.filter(onlyUnique);
            var newuni = [];
            for (var m = 0; m < uniques.length; m++) {
              var val = {
                userId: uniques[m],
              };
              var nnn = FriendsFinder.getFriendsDetailsCache(val);
              if (!nnn) {
                newuni.push(uniques[m]);
              }
            }
            if (newuni.length > 0) {
              FriendsFinder.getFriendsDetailsbyUserid(newuni)
                .then((response) => {
                  for (var k = 0; k < response.data.data.user.length; k++) {
                    // alert(response.data.data.user[k].userId)
                    FriendsFinder.setFriendsDetailsCache(
                      response.data.data.user[k].userId,
                      response.data.data.user[k]
                    );
                  }

                  console.log(question_list);
                  topqestList["topQuestList"] = question_list;
                  topqestList["totalItems"] = result.data.totalItems;
                  topqestList["updatedKey"] = "normal";
                  //if (firstTimeLoad && !secondTimeLoad) {
                  dispatch({
                    type: GET_SUBJECT_QUESTION_LIST,
                    payload: topqestList,
                  });
                  // } else {
                  //   // alert("2")
                  //   dispatch({
                  //     type: UPDATE_SUBJECT_QUESTION_LIST,
                  //     payload: topqestList,
                  //   });
                  // }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              console.log(question_list);
              topqestList["topQuestList"] = question_list;
              topqestList["totalItems"] = result.data.totalItems;
              topqestList["updatedKey"] = "normal";
              //if (firstTimeLoad && !secondTimeLoad) {
              dispatch({
                type: GET_SUBJECT_QUESTION_LIST,
                payload: topqestList,
              });
              // } else {
              //   // alert("2")
              //   dispatch({
              //     type: UPDATE_SUBJECT_QUESTION_LIST,
              //     payload: topqestList,
              //   });
              // }
            }
          } else if (result.data.questions.length == 0) {
            // alert("rrr")
            var topqestList = [];
            topqestList["topQuestList"] = [];
            topqestList["totalItems"] = 0;
            topqestList["updatedKey"] = "normal";
            // if (firstTimeLoad && !secondTimeLoad) {
            // alert("1")
            dispatch({
              type: GET_SUBJECT_QUESTION_LIST,
              payload: topqestList,
            });
            // } else {
            //   // alert("2")
            //   dispatch({
            //     type: UPDATE_SUBJECT_QUESTION_LIST,
            //     payload: topqestList,
            //   });
            // }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_SUBJECT_QUESTION_LIST,
        payload: [],
      });
    }
    return [];
  };

export const updateSubjectQuestionValues =
  (questionId, topQuestions, solved_total_items, userId) =>
  async (dispatch) => {
    try {
      let obj = {
        questionId: questionId,
      };

      ForumService.getLibraryQuestionById(obj)
        .then((response) => {
          var result = response.data.data;

          console.log("updateQuestionValue");
          console.log("===================");
          console.log(result);

          //const question_list = [];
          var questionData = topQuestions;
          var topqestList = [];
          for (let j = 0; j < questionData.length; j++) {
            if (questionData[j].questionId == questionId) {
              var tObj = result.questions.question;

              var createdDate = tObj.createdDate;
              moment(createdDate).format("DD/MM/yyyy");
              var Dates = Utils.getLastAskedDate(createdDate);
              questionData[j].answer = tObj.answer;
              questionData[j].answerSize = tObj.answerSize;
              questionData[j].createdDate = tObj.createdDate;
              questionData[j].questionDescription = tObj.questionDescription;
              questionData[j].questionId = tObj.questionId;
              questionData[j].questionName = tObj.questionName;
              questionData[j].questionTags = tObj.questionTags;
              questionData[j].questionviewers = tObj.questionviewers;
              questionData[j].questionType = tObj.questionType;
              questionData[j].solved = tObj.solved;
              questionData[j].updatedDate = tObj.updatedDate;
              questionData[j].userId = tObj.userId;
              questionData[j].userName = tObj.userName;
              questionData[j].views = tObj.views;
              questionData[j].votes = tObj.votes;
              questionData[j].gradeId = tObj.grade;
              questionData[j].questionFile = tObj.questionFile;
              questionData[j].lastAsked = Dates;
              questionData[j].programId = tObj.programId;
              questionData[j].originalFileName = tObj.originalFileName;
              questionData[j].markAsPrivate = tObj.markAsPrivate;
              questionData[j].star = tObj.questionLikesArray.filter(
                (star) => star.userId == userId
              );
              // questionData[j].pageNo = questionData[j].pageNo;
              questionData[j].status = tObj.status;
              questionData[j].answerBlockStatus = tObj.answerBlockStatus;
              questionData[j].aicheck = tObj.aicheck;
              questionData[j].filterCheck = tObj.filterCheck;
            } else {
            }
          }
          console.log("questionData start");
          console.log(questionData);
          console.log("questionData end");
          topqestList["topQuestList"] = questionData;
          topqestList["totalItems"] = solved_total_items;
          topqestList["updatedKey"] = "updated";
          dispatch({
            type: UPDATE_SUBJECT_QUESTION_LIST,
            payload: topqestList,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: UPDATE_SUBJECT_QUESTION_LIST,
        payload: [],
      });
    }
    return [];
  };

export const SubjectQuestionListLoader = (type) => async (dispatch) => {
  try {
    dispatch({
      type: SUBJECT_QUESTION_LOADER,
      payload: type,
    });
  } catch (error) {
    dispatch({
      type: SUBJECT_QUESTION_LOADER,
      payload: false,
    });
  }
  return false;
};

export const removeDeletePost = (subjectId) => async (dispatch) => {
  dispatch({
    type: DELETE_GROUP_POST,
    payload: subjectId,
  });
};
