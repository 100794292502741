import React, { Component } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import BlockUi from "react-block-ui";
import { ToastContainer, toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Form, Row, Container, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import ProfileService from "../../api/ProfileService";
import PublishIcon from "@material-ui/icons/Publish";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "password",
      enabled: true,
      totp: false,
      emailVerified: false,
      firstName: "",
      lastName: "",
      userDOB: "",
      email: "",
      gender: "",
      school: "",
      classes: "",
      biography: "",
      userType: "",
      schools: [],
      classDet: [],
      countrys: [],
      country: "",
      citys: [],
      city: "",
      successmessage: null,
      userRolesAll: [
        { roleId: 1, userRole: "Location" },
        { roleId: 2, userRole: "Options" },
        { roleId: 3, userRole: "Education" },
        { roleId: 4, userRole: "Course" },
        { roleId: 5, userRole: "Users" },
        { roleId: 6, userRole: "School" },
        { roleId: 7, userRole: "ReportAbuse" },
        { roleId: 8, userRole: "Support" },
      ],
      userRolesLimit: [
        { roleId: 1, userRole: "Users" },
        { roleId: 2, userRole: "School" },
        { roleId: 3, userRole: "ReportAbuse" },
        { roleId: 4, userRole: "Support" },
      ],
      userRolesModeration: [{ roleId: 9, userRole: "Support Cases" }],
      selectedRoles: [],
      selectedModerationRoles: [],
      selectedUserType: "",
      subjects: [],
      newUserRoleAll: [],
      newUserRoleLimit: [],
      newUserRoleModeration: [],
      password: "",
      moderationRole: false,
    };
    this.onSave = this.onSave.bind(this);
    this.handleusers = this.handleusers.bind(this);
    this.handleUserType = this.handleUserType.bind(this);
    this.onBack = this.onBack.bind(this);
    this.goUploadPage = this.goUploadPage.bind(this);
  }
  handleusers(event) {
    if (event.target.name === "city") {
      this.schoolLoadBaseCity(event.target.value);
    }
    if (event.target.name === "school") {
      this.classesLoadBaseSchool(event.target.value);
    }
    if (event.target.name === "country") {
      this.citysLoadBaseCountry(event.target.value);
    }
    console.log(event.target.value);
    console.log(event.target.name);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleUserType(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.value == 6) {
      this.setState({
        moderationRole: true,
        selectedUserType: event.target.value,
        selectedRoles: [],
      });
    } else {
      this.setState({
        moderationRole: false,
        selectedUserType: event.target.value,
        selectedModerationRoles: [],
      });
    }
  }

  handleUserRoleSelect(selectedRoles) {
    console.log("handleRoleSelect");
    console.log(selectedRoles);
    this.setState({
      selectedRoles: selectedRoles,
    });
    console.log(this.state.selectedRoles);
  }

  handleUserRoleModerationSelect(selectedRoles) {
    console.log("handleRoleSelect");
    console.log(selectedRoles);
    this.setState({
      selectedModerationRoles: selectedRoles,
    });
    console.log(this.state.selectedRoles);
  }

  onBack() {
    this.props.history.push("/home");
  }

  onSave(e) {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData();
    const form = e.currentTarget;

    if (this.state.firstName === "") {
      toast.warn("Please enter valid name");
    } else if (this.state.lastName === "") {
      toast.warn("Please enter valid lastName");
    } else if (this.state.email === "") {
      toast.warn("Please enter valid Phone email");
    } else if (this.state.courseName === "") {
      toast.warn("Please enter valid Phone courseName");
    } else {
      let value = {
        username: this.state.email,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        value: this.state.password,
      };

      ProfileService.createUser(value)
        .then((response) => {
          toast.success("User saved successfully");
          this.onBack();
        })
        .catch((error) => {
          toast.error("Error in saving issue");
        });
    }
  }

  goUploadPage() {
    this.props.history.push(`/user-upload`);
  }
  render() {
    console.log("gettin usertype from sesson");
    return (
      <div>
        <section id="ProfilePage" className="pageWrapperClassroom">
          <Container>
            <Grid container justify="center">
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <BlockUi
                  tag="div"
                  message="Loading, please wait"
                  color="#A00030"
                >
                  <div style={{ paddingTop: "50px" }}>
                    <Button
                      style={{ marginTop: "10px", marginBottom: "30px" }}
                      variant="contained"
                      color="primary"
                      onClick={this.goUploadPage}
                      startIcon={<PublishIcon />}
                    >
                      {" "}
                      Upload Users (CSV)
                    </Button>
                    <Card>
                      <ToastContainer position="top-right" autoClose={5000} />

                      <div style={{ margin: "14px" }}>
                        <Form id="profileForm" noValidate>
                          <h4 style={{ margin: "15px" }}>CREATE USER</h4>
                          {/* <Card.Body> */}
                          <div>
                            <Row>
                              <Col xs="12 " sm="12">
                                <Form.Group className="formInputs">
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Control
                                    onChange={this.handleusers}
                                    required
                                    type="text"
                                    name="firstName"
                                    placeholder="FirstName"
                                    value={this.state.firstName}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12 " sm="12">
                                <Form.Group className="formInputs">
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Control
                                    onChange={this.handleusers}
                                    required
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={this.state.lastName}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12 " sm="12">
                                <Form.Group className="formInputs">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control
                                    onChange={this.handleusers}
                                    required
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={this.state.email}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs="12 " sm="12">
                                <Form.Group className="formInputs">
                                  <Form.Label>Password</Form.Label>
                                  <Form.Control
                                    onChange={this.handleusers}
                                    required
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={this.state.password}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs="12 " sm="12" lg={12} md={12}>
                                &nbsp;&nbsp;&nbsp;
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ width: "100%" }}
                                  onClick={this.onSave}
                                  startIcon={<SaveIcon />}
                                >
                                  {" "}
                                  Create User
                                </Button>
                              </Col>
                            </Row>
                          </div>
                          {/* </Card.Body> */}
                        </Form>
                      </div>
                    </Card>
                  </div>
                </BlockUi>
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
    );
  }
}

export default withRouter(Users);
