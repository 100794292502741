import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { API_URL } from "../../config/app-config";
import dummyimage from "../../img/default_avatar.jpg";
import ForumService from "../../api/ForumService";
import ProfileService from "../../api/ProfileService";
import banner from "../../img/banner.jpg";
import { FiUserPlus } from "react-icons/fi";
import { BiMessageDetail } from "react-icons/bi";
import { MdRemove } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import FriendsFinder from "../../api/FriendsFinder";

import {
  getNavigationType,
  needPhotVerification,
} from "../../redux/actions/commonApiActions";
import { MdWorkOutline } from "react-icons/md";
import { setSelectedUserAction } from "../../redux/actions/chatActions";
import { giveUserAction, premiumUser } from "../../utils";
import Swal from "sweetalert2";
class FriendConnectionCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      friendsList: [],
      professionList: [
        {
          id: 1,
          name: "student",
        },
        {
          id: 2,
          name: "Working professional",
        },
        {
          id: 3,
          name: "practicing profession (Auditoer,lawyer and etc)",
        },
        {
          id: 4,
          name: "Entreprenur",
        },
        {
          id: 5,
          name: "Homemaker",
        },
        {
          id: 6,
          name: "Freelancer",
        },
      ],
    };
    this.chatNow = this.chatNow.bind(this);
    this.friendRequest = this.friendRequest.bind(this);
    this.friendIgnore = this.friendIgnore.bind(this);
    this.unFriendHit = this.unFriendHit.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    // this.getCoverPhoto(this.props.userId);
  }

  componentWillReceiveProps(nextProps) {}
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      console.log("fl 2");
      console.log(this.props.userId);
      // this.getCoverPhoto(this.props.userId);
    }
  }

  render() {
    const { t } = this.props;

    const { userId, displayName, type, userType, profilePhoto } = this.props;
    console.log("Update-try", this.props);
    return (
      <>
        {/* <div class="friendlist-col-6"> */}
        <div
          class="fcc-card fcc-shadow-none text-center fcc-h-100"
          style={{ minHeight: "16rem" }}
        >
          <div class="fcc-card-body fcc-p-2 fcc-pb-0">
            <div class="fcc-avatar avatar-story fcc-avatar-xl">
              <a href="#!">
                <img
                  class="fcc-avatar-img rounded-circle"
                  src={
                    API_URL + "api/getUserPhoto/" + userId + "/" + displayName
                  }
                  onClick={() => this.showFriendsProfile(userId)}
                  alt="user"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = dummyimage;
                  }}
                />
              </a>
            </div>
            <h6
              class="card-title mb-1 mt-3"
              style={{ color: "#14191e", textTransform: "capitalize" }}
              onClick={() => this.showFriendsProfile(userId)}
            >
              {displayName}
              {this.props.premiumUser &&
                premiumUser(this.props.premiumUser) && (
                  <img
                    src={premiumUser(this.props.premiumUser)}
                    alt="img"
                    className="ml-2 mt-auto ml-1"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "8px",
                    }}
                  />
                )}
            </h6>

            {this.props.professionId && (
              <li class="list-inline-item friend-text-correction d-flex align-items-center justify-center flex-column">
                <MdWorkOutline
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "0.25rem",
                  }}
                ></MdWorkOutline>{" "}
                <p className="">{this.props.professionId}</p>
              </li>
            )}

            {/* <p class="mb-0 small lh-sm">16 mutual connections</p> */}
          </div>

          {type == "friends" && (
            <div
              class="d-flex flex-wrap align-items-center justify-content-center p-2 border-0 mb-4"
              style={{ gap: "6px" }}
            >
              <button
                class="connectionListblueLabel"
                onClick={() =>
                  this.chatNow(userId, displayName, userType, profilePhoto)
                }
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Send message"
              >
                <BiMessageDetail
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "0.25rem",
                    color: "#ffffff",
                  }}
                ></BiMessageDetail>{" "}
              </button>
              <button
                class="connectionListredLabel"
                data-bs-toggle="tooltip"
                onClick={() => this.unFriendHit(userId)}
                data-bs-placement="top"
                title="Remove friend"
              >
                {" "}
                <MdRemove
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "0.25rem",
                    color: "#ffffff",
                  }}
                ></MdRemove>{" "}
              </button>
            </div>
          )}
          {type == "notFriends" && (
            <div
              class="d-flex flex-wrap align-items-center justify-content-center p-2 border-0 mb-4"
              style={{ gap: "6px" }}
            >
              <button
                class="connectionListgreenLabel"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Add friend"
                onClick={() => this.friendRequest(userId)}
              >
                <FiUserPlus
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "0.25rem",
                    color: "#ffffff",
                  }}
                ></FiUserPlus>{" "}
              </button>
              {/* <button class="connectionListredLabel" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove friend" onClick={() => this.friendIgnore(userId)}> <MdRemove style={{ width: "15px", height: "15px", marginRight: "0.25rem", color: "#ffffff" }}></MdRemove> </button> */}
            </div>
          )}
          {(type == "requestPending" || type == "requestedFriends") && (
            <div
              class="d-flex flex-wrap align-items-center justify-content-center p-2 border-0 mb-4"
              style={{ gap: "6px" }}
            >
              <button
                class="connectionListgrayLabel"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Requested"
                style={{ cursor: "not-allowed" }}
              >
                {t("Requested")}
              </button>
              {/* <button class="connectionListredLabel" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove friend" onClick={() => this.friendIgnore(userId)}> <MdRemove style={{ width: "15px", height: "15px", marginRight: "0.25rem", color: "#ffffff" }}></MdRemove> </button> */}
            </div>
          )}
        </div>
        {/* </div> */}
        {/* <div style={{ marginBottom: "20px" }} >
                    <div className="aboutFriendWrapper" style={{ padding: "12px" }} >
                                        <div className="peopleCardWrapperGroup">
                                            <img className="bannerImg"
                                                src={(this.state.coverPhoto == undefined || this.state.coverPhoto === "") ? banner : `data:image;base64,${this.state.coverPhoto}`}
                                                alt={displayName}></img>
                                            <div className="w3-row">
                                                <div className="w3-col w3-container w3-green">
                                                    <div>
                                                        <img style={{ cursor: "pointer" }}  className="userImg"
                                                            src={
                                                                API_URL +
                                                                "api/getUserPhoto/" +
                                                                userId +
                                                                "/" +
                                                                displayName
                                                            }
                                                            alt="user"
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null; // prevents looping
                                                                currentTarget.src = dummyimage;
                                                            }}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="friendCardInfor">

                                                <h2>{(displayName && displayName !== "null" && displayName.length > 0) ? displayName : displayName}</h2>
                                        

                                            </div>
                                        

                                        </div>
                           
                    </div>

                </div> */}
      </>
    );
  }

  getCoverPhoto(uid) {
    let coverPhoto = {
      userId: uid,
    };
    var response = ForumService.getUserCoverPhotoCache(coverPhoto);
    console.log(response);
    if (response) {
      var value = response.data.data;
      if (value && value.coverPhoto) {
        this.setState({ coverPhoto: response.data.data.coverPhoto });
        // console.log("cache "+base64String)
      }
    } else {
      ProfileService.getUsercoverPhoto(coverPhoto)

        .then((response) => {
          var result = response.data;
          ForumService.setUserCoverPhotoCache(coverPhoto, response);
          this.setState({
            coverPhoto: result.data.coverPhoto,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  chatNow(user_id, username, userType, profilePhoto) {
    var data = {
      userId: user_id,
      userName: username,
      userType: userType,
      profilePhoto: profilePhoto,
      // profilePhoto: profilePhoto
    };

    // this.props.history.push(`/messages/${user_id}`);
    this.props.setSelectedUserAction(data);
  }

  friendRequest(id) {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      let friendRequest = {
        friendsId: id,
        requestStatus: "requested",
      };
      FriendsFinder.friendRequest(friendRequest)
        .then((response) => {
          this.props.updateFriendsListStatus(id);

          // this.props.loadFriendsList();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  friendIgnore(id) {
    let friendIgnore = {
      recommendfriendsId: id,
      status: "ignored",
    };
    FriendsFinder.recommendedfriendIgnore(friendIgnore)
      .then((response) => {
        this.props.ignoreSent();
        var result = response.data;
        this.props.loadFriendsList();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  unFriendHit(uId) {
    let unFriend = {
      userId: this.props.loginDetails.uId,
      friendsId: uId,
    };
    Swal.fire({
      // title: logoutTitle,
      text: `${this.props.t("Are you sure remove this friend ")} ?`,
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        FriendsFinder.unfriendHit(unFriend)
          .then((response) => {
            this.props.removeFriend(uId);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  showFriendsProfile = (userId) => {
    // this.props.getNavigationType(1);
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  };
}
const mapDispacthToProps = (dispatch) => {
  return {
    getNavigationType: (type) => dispatch(getNavigationType(type)),
    setSelectedUserAction: (id) => dispatch(setSelectedUserAction(id)),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(FriendConnectionCard)));
