import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import i18n from "../../i18n";
import { withTranslation } from "react-i18next";
import "react-widgets/styles.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Grid } from "@material-ui/core";
import { Container } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { API_URL } from "../../config/app-config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as APPCONFIG from "../../config/app-config.js";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import moment from "moment";
import banner from "../../img/banner.jpg";
import ForumService from "../../api/ForumService";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import vote_thanks from "../../images/VOTE_THANK_YOU.png";
import vote from "../../images/VOTE.png";
import VideoPostCard from "../Home/HomeUpdated/VideoPostCard";
import Popover from "react-bootstrap/Popover";
import Share from "@material-ui/icons/Share";
import { getNavigationType } from "../../redux/actions/commonApiActions";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { OverlayTrigger } from "react-bootstrap";
import dummyimage from "../../img/default_avatar.jpg";
import { getVideoStatus } from "../../utils";
const currentDate = new Date().toString();

class ApplyChallengeDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postRequest: false,
      tabIndex: 0,
      name: "",
      createdDate: "",
      natureOfRequest: "",
      duration: "",
      description: "",
      industry: "",
      industryData: [],
      jobRequestsAoI: [],
      areaofinterestsList: [],
      industryNameList: [],
      displayName: "",
      photoAvailable: false,
      fileData: [],
      challengeData: {},
      likeStatusState: false,
      videoStatus: false,
      // fileImageData:""
    };

    this.backtoHome = this.backtoHome.bind(this);
    this.getChallengeApplybyid = this.getChallengeApplybyid.bind(this);
    this.getData = this.getData.bind(this);
    this.handleClickDefaultLike = this.handleClickDefaultLike.bind(this);
    this.showFriendsProfile = this.showFriendsProfile.bind(this);
  }

  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
    });
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    let id = this.props.match.params.id;
    this.getChallengeApplybyid(id);
  }

  render() {
    const { t } = this.props;
    const {
      tabIndex,
      name,
      createdDate,
      natureOfRequest,
      duration,
      description,
      industry,
      displayName,
      userId,
    } = this.state;
    return (
      <>
        <ToastContainer position="top-right" autoClose={5000} />
        <div
          className="screenInnerGap"
          style={{
            marginLeft: "22px",
            marginRight: "22px",
            marginBottom: "22px",
            marginTop: "80px",
          }}
        >
          <div
            style={{
              marginTop: "20px",
              marginRight: "12px",
              marginLeft: "0px",
              marginBottom: "12px",
            }}
            className="ansBackQuestion flexDefault uploader"
            onClick={() =>
              this.backtoHome(this.state.subjectId, this.state.subjectName)
            }
          >
            <ArrowBackIcon />
            <p>{t("Back to Participant List")}</p>
          </div>
          <Grid container spacing={2}>
            <Grid item md={9} xs={12} sm={12} lg={9}>
              <div className="myRequestCardWrapper" style={{ height: "auto" }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} lg={6} md={6}>
                    <h4 style={{ padding: "5px" }}>
                      {this.state.challengeData.title}{" "}
                    </h4>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} md={6}>
                    {moment(currentDate).format("yyyy-MM-DD") <=
                      moment(this.state.expiryDate).format("yyyy-MM-DD") && (
                      <p
                        style={{
                          display: "inline-flex",
                          cursor: "pointer",
                          float: "right",
                        }}
                        onClick={() => this.handleClickDefaultLike()}
                      >
                        <div
                          className="boxcolor"
                          style={{ display: "inline-flex" }}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              alignItems: "center",
                              fontWeight: "600",
                              padding: "15px",
                            }}
                          >
                            {this.state.likesLength}
                          </p>
                          {!this.state.likeStatusState ? (
                            <img
                              src={vote}
                              style={{
                                marginLeft: "8px",
                                width: "40px",
                                height: "40px",
                                color: "#F97516",
                              }}
                            />
                          ) : (
                            <img
                              src={vote_thanks}
                              style={{
                                marginLeft: "8px",
                                width: "40px",
                                height: "40px",
                                color: "#F97516",
                              }}
                            />
                          )}
                          {/* <p
                              style={{ paddingLeft: "8px", paddingRight: "8px" }}
                            >
                              {this.state.likesLength <= 1 ? "Vote" : "Votes"}
                            </p> */}
                        </div>
                      </p>
                    )}

                    {moment(currentDate).format("yyyy-MM-DD") >
                      moment(this.state.expiryDate).format("yyyy-MM-DD") && (
                      <div
                        className=""
                        style={{ margin: "8px", float: "right", color: "red" }}
                      >
                        <p>{t("CLOSED")}</p>
                      </div>
                    )}
                  </Grid>
                </Grid>

                <hr />
                <h4 style={{ padding: "5px" }}>
                  {this.state.challengeData.caption}{" "}
                </h4>
                <p
                  style={{ fontSize: "14px", marginTop: "18px" }}
                  dangerouslySetInnerHTML={{
                    __html: this.state.challengeData.description,
                  }}
                ></p>

                <Grid container spacing={0} style={{ paddingTop: "10px" }}>
                  <Grid item md={2} xs={2} sm={2} lg={2}>
                    {" "}
                    <p style={{ fontSize: "17px" }}>{t("Participation")} : </p>
                  </Grid>
                  <Grid item md={4} xs={4} sm={4} lg={4}>
                    <p
                      style={{
                        color: "gray",
                        fontSize: "14px",
                        padding: "2px",
                      }}
                    >
                      {" "}
                      {this.state.challengeData.participation}
                    </p>
                  </Grid>
                  {/* <Grid item md={3} xs={3} sm={3} lg={3}>
                          {" "}
                          <p style={{ fontSize: "17px" }}>Team Leader : </p>
                        </Grid>
                        <Grid item md={2} xs={2} sm={2} lg={2}>
                          <p style={{ color: "gray", fontSize: "14px", padding: "2px" }}>
                            {" "}
                            {this.state.challengeData.teamLeader}
                          </p>
        
                        </Grid> */}
                  <Grid item md={2} xs={2} sm={2} lg={2}>
                    {" "}
                    <p style={{ fontSize: "17px" }}>{t("Category")} : </p>
                  </Grid>
                  <Grid item md={4} xs={4} sm={4} lg={4}>
                    <p
                      style={{
                        color: "gray",
                        fontSize: "14px",
                        padding: "2px",
                      }}
                    >
                      {" "}
                      {this.state.categoryValue}
                    </p>
                  </Grid>
                </Grid>

                <div className="postCardBody">
                  <div style={{ marginTop: "12px" }}>
                    {this.state.fileData &&
                      this.state.fileData.map((photo) => (
                        <VideoPostCard
                          fileData={photo.file}
                          imageData={photo.image}
                          videoStatus={photo.steamStatus}
                        />
                      ))}
                  </div>
                </div>
                <div className="writeCommentWrapper">
                  <div className="postCardFooter">
                    <Grid container spacing={1}>
                      <Grid item md={4} xs={12} sm={12} lg={4}></Grid>
                      <Grid item md={4} xs={12} sm={12} lg={4}></Grid>
                      <Grid item md={4} xs={12} sm={12} lg={4}>
                        {this.props.loginDetails.uId ===
                          this.state.challengeData.userId && (
                          <div
                            className="postMoreInfo"
                            style={{ cursor: "pointer", float: "right" }}
                          >
                            <OverlayTrigger
                              trigger="click"
                              placement="top"
                              overlay={this.socialPopover()}
                              rootClose
                            >
                              <button
                                style={{
                                  display: "inline-flex",
                                  marginRight: "8px",
                                }}
                                //  onClick={this.shareOptionPopup}
                              >
                                <Share
                                  style={{
                                    //  marginLeft : "8px",
                                    width: "20px",
                                    height: "20px",
                                    color: "white",
                                  }}
                                />
                                <p
                                  style={{
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                  }}
                                >
                                  {t("Share")}
                                </p>
                              </button>
                            </OverlayTrigger>
                          </div>
                        )}{" "}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={3} xs={12} sm={12} lg={3}>
              <div
                className="myRequestCardWrapperUser"
                style={{ height: "auto" }}
              >
                <img
                  className="bannerImgHOme"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = banner;
                  }}
                  src={
                    this.state.coverPhoto
                      ? `${APPCONFIG.API_URL}auth/image/${this.state.coverPhoto}`
                      : banner
                  }
                  alt={this.state.challengeData.userName}
                ></img>
                <div className="w3-row">
                  <div className="w3-col w3-container w3-green">
                    <div>
                      <img
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = dummyimage;
                        }}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.showFriendsProfile(
                            this.state.challengeData.userId
                          )
                        }
                        className="userImg"
                        src={
                          API_URL +
                          "api/getUserPhoto/" +
                          this.state.challengeData.userId +
                          "/" +
                          this.state.challengeData.userName
                        }
                      />
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    color: "black",
                    fontSize: "18px",
                    marginTop: "12px",
                  }}
                >
                  <center>{this.state.challengeData.userName}</center>{" "}
                </p>
                <p
                  style={{ color: "gray", fontSize: "12px", marginTop: "12px" }}
                >
                  <center>{this.state.challengeData.age}</center>{" "}
                </p>
                <p
                  style={{ color: "gray", fontSize: "12px", marginTop: "12px" }}
                >
                  <center>{this.state.challengeData.alternativeEmail}</center>{" "}
                </p>
                <hr />
                <center>{t("Contestant Details")} </center>
                {/* <p style={{ color: "gray", fontSize: "12px", marginTop: "12px" }}>
                  <center>{this.state.challengeData.contactNumber}</center>{" "}
                </p>
                <p style={{ color: "gray", fontSize: "12px", marginTop: "12px" }}>
                  <center>{this.state.challengeData.address}</center>{" "}
                </p> */}
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
  showFriendsProfile(userId) {
    this.props.getNavigationType(1);
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  }
  backtoHome(id, name) {
    this.props.history.push(
      `/${this.props.workspaceType}/challengeDescription/${id}/${name}/participantList`
    );
  }

  getChallengeApplybyid(id) {
    var cId = { challengeId: id };

    ForumService.getSingleChallenge(cId)
      .then((response) => {
        console.log("Challenge Data", response.data.data);
        this.getData(response);
      })
      .catch((error) => {
        toast.error("Something went wrong ...");
        this.props.history.push(`/${this.props.workspaceType}/home`);
        console.log(error);
      });
  }

  async getData(response) {
    if (response.data.data.challenge) {
      await this.setState({
        challengeData: response.data.data.challenge,
        coverPhoto: response.data.data.coverPhoto,
        likesLength: response.data.data.likesCount,
        profilePhoto: response.data.data.profilePhoto,
        likeStatusState: response.data.data.userLike,
        categoryValue: response.data.data.categoryValue,
        subjectId: response.data.data.subjectId,
        subjectName: response.data.data.subjectName,
        expiryDate: response.data.data.expiryDate,
        videoStatus: response.data.data.challenge.videoStatus,
      });
      this.getMessageFiles(
        response.data.data.challenge.challengeMediaFiles,
        response.data.data.challenge.userId
      );
      console.log("Challenge", this.state.challengeData);
    } else {
      toast.error("Something went wrong ...");
      this.props.history.push(`/${this.props.workspaceType}/home`);
    }
  }

  async likeStatus() {
    await this.setState({ likeStatusState: !this.state.likeStatusState });
  }

  handleClickDefaultLike() {
    if (this.state.likeStatusState) {
      var postId = this.state.challengeData.challengeId;
      toast.warn("Already voted!");
      // this.likesButton(postId, 0);
    } else {
      var postId = this.state.challengeData.challengeId;

      this.likesButton(postId, 1);
    }
  }

  socialPopover() {
    // function removeHTML(str) {
    //   var tmp = document.createElement("DIV");
    //   tmp.innerHTML = str;
    //   return tmp.textContent || tmp.innerText || "";
    // }
    // const content =
    //   this.state.postValue.content != null
    //     ? this.state.postValue.content
    //     : this.state.postValue.fileContent;
    return (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Share in Social media</Popover.Title>
        <Popover.Content
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "4px",
          }}
        >
          <FacebookShareButton
            url={`${APPCONFIG.BASE_URL}link-challenge-Description/${this.state.challengeData.challengeId}`}
            title={`Kindly vote to my challenge ${this.state.challengeData.title}`}
            //quote={removeHTML(content)}
            //description={removeHTML(content)}
            //hashtag="#camperstribe"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>{" "}
          <TwitterShareButton
            url={`${APPCONFIG.BASE_URL}link-challenge-Description/${this.state.challengeData.challengeId}`}
            title={`Kindly vote to my challenge ${this.state.challengeData.title}`}
            //title={removeHTML(content)}
            hashtag="#camperstribe"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>{" "}
          <WhatsappShareButton
            url={`${APPCONFIG.BASE_URL}link-challenge-Description/${this.state.challengeData.challengeId}`}
            title={`Kindly vote to my challenge ${this.state.challengeData.title}`}
            //title={removeHTML(content)}
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>{" "}
          <TelegramShareButton
            title={`Kindly vote to my challenge ${this.state.challengeData.title}`}
            url={`${APPCONFIG.BASE_URL}link-challenge-Description/${this.state.challengeData.challengeId}`}
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
        </Popover.Content>
      </Popover>
    );
  }

  likesButton(pid, likeValue) {
    let createpostLike = {
      challengeId: pid,
      like: likeValue,
    };

    ForumService.saveChallengeLike(createpostLike)
      .then((response) => {
        var result = response.data;

        var likeCounts = result.data.likesCount;
        this.setState({ likesLength: likeCounts });
        this.likeStatus();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getMessageFiles(files, userId) {
    let fileList = [];
    if (files.length > 0) {
      await this.setState({
        fileData: getVideoStatus(
          files,
          this.props.loginDetails.uId,
          userId,
          this.props.loginDetails.uType
        ),
      });
      // for (let i = 0; i < files.length; i++) {
      //   //fileList.push(`${APPCONFIG.API_URL}auth/video/${files[i].fileName}`);
      //   fileList.push({ file: files[i].streamUrl, image: files[i].thumbnail });
      // }
      // await this.setState({ fileData: fileList });
      //  alert(fileList)
    }
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getNavigationType: (type) => dispatch(getNavigationType(type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ApplyChallengeDescription)));
