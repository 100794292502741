import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import CourseCard from "../../../components/CourseCard";
import MyCourseService from "../../../api/MyCourseService";
import Articles from "./Articles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import AuthService from "../../../session/AuthService";
import BlockUi from "react-block-ui";
import CustomToggleClassroomMyLibrary from "../../../components/CustomToggleClassroomMyLibrary";
const buttonsDatas = [
  {
    value: "latest",
    label: "Latest",
  },
  {
    value: "votes",
    label: "Votes",
  },
];

class MyLibraryCourses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      courseList: [],
      yuukkeProgramsArticle: "",
      articleFilterState: false,
      noarticleState: false,
      articleList: [],
      pageNo: 0,
      seeMoreButtonype: "",
      yuukkePageNo: 0,
      accreditedPageNo: 0,
      sponseredPageNo: 0,
      yuukkeMoreButtonState: true,
      peernoMoreButtonState: false,
      accreditedseeMoreButtonState: true,
      accreditednoMoreButtonState: false,
      sponsoredseeMoreButtonState: true,
      sponsoredpeernoMoreButtonState: false,
      blocking: false,
      toggleValue: "All",
      staredArticles: [],
      staredArticlesLength: true,
    };

    this.getallCourse = this.getallCourse.bind(this);
    this.articleList = this.articleList.bind(this);
    this.showArticleScreen = this.showArticleScreen.bind(this);
    this.showArticle = this.showArticle.bind(this);
    this.articleFilter = this.articleFilter.bind(this);
    this.articleFilterState = this.articleFilterState.bind(this);
    this.noarticleState = this.noarticleState.bind(this);
    this.noFilterDatas = this.noFilterDatas.bind(this);
    this.getMyArticles = this.getMyArticles.bind(this);
    this.getarticleList = this.getarticleList.bind(this);
    this.courseblock = this.courseblock.bind(this);
    this.starAction = this.starAction.bind(this);
    this.choosedValueData = this.choosedValueData.bind(this);
    this.getMyLibraryArticles = this.getMyLibraryArticles.bind(this);
    this.getMoreStarArticles = this.getMoreStarArticles.bind(this);
  }

  courseblock(blocking) {
    this.setState({ blocking: blocking });
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.setState({ blocking: true });
    this.getMyLibraryArticles();
  }

  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.showArticle ? (
          <div>
            <div className="questionWrapperTitle">
              <p className="titleLarge">{t("Course - Media")}</p>
            </div>
            <div className="questionsFilterWrapper">
              <div
                style={{
                  marginTop: "20px",
                  marginRight: "12px",
                  marginLeft: "50px",
                  marginBottom: "12px",
                }}
                className="ansBackQuestion flexDefault uploader"
                onClick={() => this.showArticleScreen(false)}
              >
                <ArrowBackIcon />
                <p>{t("Back to Course")}</p>
              </div>

              <Articles articleId={this.state.articleId}></Articles>
            </div>
          </div>
        ) : (
          <div>
            <div className="coursesMainWrapper">
              <h2 className="courseTitle alignLeft">My Library</h2>

              <div style={{ marginBottom: "20px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4}>
                    <div className="CTInputWrapper">
                      <CustomToggleClassroomMyLibrary
                        choosedValue={this.choosedValueData}
                        activeValue={this.state.toggleValue}
                        buttonValues={buttonsDatas}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}></Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    {this.state.toggleValue == "All" && (
                      <center>
                        <p className="greenLabel">
                          {t("Courses that you have completed appear here")}
                        </p>
                      </center>
                    )}
                    {this.state.toggleValue == "star" && (
                      <center>
                        <p className="greenLabel">
                          {t("Courses that you starred appear here")}
                        </p>
                      </center>
                    )}
                  </Grid>
                </Grid>
              </div>

              <BlockUi
                style={{ minHeight: "100px" }}
                tag="div"
                blocking={this.state.blocking}
                message={t("Loading, please wait")}
                color="#A00030"
              >
                {!this.state.articleFilterState && (
                  <div className="allCourses">
                    <div className="yuukkeProgramWrapper">
                      <h2 className="courseTitle alignCenter">
                        {this.state.yuukkePrograms}
                      </h2>
                      <Grid container spacing={2}>
                        {this.state.staredArticles &&
                          this.state.staredArticles.map((data, index) => (
                            <Grid item sm={6} md={4} key={data.articleId}>
                              <CourseCard
                                // img={courseImage}
                                videotitle={data.articleName}
                                time={data.duration}
                                title={data.articleName}
                                subtitle={data.articleDescription}
                                articleId={data.articleId}
                                articleContent={data.articleContent}
                                hastags={data.hashTag}
                                articleStarArray={data.articleStarArray}
                                showArticle={this.showArticle}
                                starAction={this.starAction}
                              />
                            </Grid>
                          ))}
                      </Grid>
                      {this.state.staredArticlesLength >
                        this.state.staredArticles.length &&
                        !this.state.blocking && (
                          <div className="peerProgramBtn alignCenter">
                            <button onClick={() => this.getMoreStarArticles()}>
                              {t("See more")}
                            </button>
                          </div>
                        )}
                      {this.state.staredArticlesLength <=
                        this.state.staredArticles.length && (
                        <div className="noMoreArticles alignCenter">
                          <p className="noMoreArticles">
                            {t("No More Articles")}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </BlockUi>
            </div>
            {this.state.articleFilterState && !this.state.noarticleState && (
              <div className="allCourses">
                <div className="sponsoredProgramsWrapper">
                  <h2 className="courseTitle alignCenter">
                    {t("SEARCH COURSE LIST")}
                  </h2>
                  <Grid container spacing={2}>
                    {this.state.articleFilterData &&
                      this.state.articleFilterData.map((data, index) => (
                        <Grid item sm={6} md={4} key={index}>
                          {/* <CourseCard
                            img={courseImage}
                            videotitle={data.articleName}
                            time={data.duration}
                            title={data.articleName}
                            subtitle={data.articleDescription}
                            articleId={data.articleId}
                            articleContent={data.articleContent}
                            hastags={data.hashTag}
                            articleStarArray={data.articleStarArray}
                            showArticle={this.showArticle}
                            starAction={this.starAction}
                          /> */}
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            )}
            {this.state.noarticleState && (
              <div className="accreditedProgramsWrapper">
                <h2 className="courseTitle alignCenter">
                  {t("No Course List Found")}{" "}
                </h2>
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  getallCourse() {
    this.courseblock(true);
    MyCourseService.courseList()
      .then((response) => {
        var result = response.data;

        this.setState({
          courseList: result.data.coursePhoto,
        });

        this.getMyArticles();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getMyArticles() {
    this.courseblock(true);
    let getMyArticles = {
      size: 9,
      page: 0,
    };
    MyCourseService.getMyArticles(getMyArticles)
      .then((response) => {
        this.courseblock(false);
        var result = response.data;
        this.setState({
          articleList: result.data.articles,
        });
        this.getarticleList(this.state.articleList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  articleList(courselist) {
    const yuukkePrograms = [];
    const AccreditedPrograms = [];
    const SponseredPrograms = [];
    for (let i = 0; i < courselist.length; i++) {
      if (courselist[i].courseDescription == "yuukke Programs") {
        this.setState({ PeerCoursePhoto: courselist[i].coursePhoto });
        for (let j = 0; j < courselist[i].articles.length; j++) {
          yuukkePrograms.push(courselist[i].articles[j]);
        }
        this.setState({ yuukkeProgramsArticle: yuukkePrograms });
      } else if (courselist[i].courseDescription == "Accredited Programs") {
        for (let j = 0; j < courselist[i].articles.length; j++) {
          AccreditedPrograms.push(courselist[i].articles[j]);
        }
        this.setState({ AccreditedProgramsArticle: AccreditedPrograms });
      } else if (courselist[i].courseDescription == "Sponsered Programs") {
        for (let j = 0; j < courselist[i].articles.length; j++) {
          SponseredPrograms.push(courselist[i].articles[j]);
        }
        this.setState({ SponseredProgramsArticle: SponseredPrograms });
      }
    }
  }

  getarticleList(articleList) {
    const yuukkePrograms = [];
    const AccreditedPrograms = [];
    const SponseredPrograms = [];
    for (let i = 0; i < this.state.courseList.length; i++) {
      if (this.state.courseList[i].courseDescription == "yuukke Programs") {
        var courseId = this.state.courseList[i].courseId;
        this.setState({ yuukkePrograms: "yuukke Programs" });

        for (let j = 0; j < articleList.length; j++) {
          if (articleList[j].courseId === this.state.courseList[i].courseId) {
            yuukkePrograms.push(articleList[j]);
          }
        }

        this.setState({ yuukkeProgramsArticle: yuukkePrograms });
      }

      if (this.state.courseList[i].courseDescription == "Accredited Programs") {
        this.setState({ AccreditedPrograms: "Accredited Programs" });
        for (let j = 0; j < articleList.length; j++) {
          if (articleList[j].courseId === this.state.courseList[i].courseId) {
            AccreditedPrograms.push(articleList[j]);
          }
        }

        this.setState({ AccreditedProgramsArticle: AccreditedPrograms });
      }

      if (this.state.courseList[i].courseDescription == "Sponsered Programs") {
        this.setState({ SponseredPrograms: "Sponsered Programs" });
        for (let j = 0; j < articleList.length; j++) {
          if (articleList[j].courseId === this.state.courseList[i].courseId) {
            SponseredPrograms.push(articleList[j]);
          }
        }

        this.setState({ SponseredProgramsArticle: SponseredPrograms });
      }
    }
  }

  block(blocking) {
    this.setState({ blocking: blocking });
  }

  async showArticle(id) {
    await this.setState({ articleId: id });

    this.showArticleScreen(true);
  }

  showArticleScreen(action) {
    this.setState({ showArticle: action });
  }

  articleFilter(articleFilterData) {
    this.setState({ articleFilterData: articleFilterData.articles });
  }

  articleFilterState(articleFilterState) {
    this.setState({ articleFilterState: articleFilterState });
  }

  noarticleState(noarticleState) {
    this.setState({ noarticleState: noarticleState });
  }

  noFilterDatas() {
    this.setState({
      articleFilterState: false,
      yuukkeMoreButtonState: true,
      blocking: false,
      peernoMoreButtonState: false,
      yuukkePageNo: 0,
      accreditedseeMoreButtonState: true,
      accreditednoMoreButtonState: false,
      accreditedPageNo: 0,
      sponsoredseeMoreButtonState: true,
      sponsorednoMoreButtonState: false,
      sponseredPageNo: 0,
    });
    this.getallCourse();
  }

  starAction() {
    this.setState({
      articleFilterState: false,
      yuukkeMoreButtonState: true,
      blocking: false,
      peernoMoreButtonState: false,
      yuukkePageNo: 0,
      accreditedseeMoreButtonState: true,
      accreditednoMoreButtonState: false,
      accreditedPageNo: 0,
      sponsoredseeMoreButtonState: true,
      sponsorednoMoreButtonState: false,
      sponseredPageNo: 0,
    });
    this.getMyLibraryArticles();
  }

  async choosedValueData(choosedValue) {
    this.setState({ toggleValue: "star" });
    if (choosedValue === "Star") {
      this.setState({
        yuukkeMoreButtonState: true,
        peernoMoreButtonState: false,
      });
      await this.setState(
        {
          typeQuestion: "star",
        },
        () => {
          this.getMyLibraryArticles();
        }
      );
    } else if (choosedValue === "All") {
      this.setState({ toggleValue: "All" });
      this.setState({
        yuukkeMoreButtonState: true,
        peernoMoreButtonState: false,
      });

      await this.setState(
        {
          typeQuestion: "All",
        },
        () => {
          this.getMyLibraryArticles();
        }
      );
    }
  }

  getMyLibraryArticles() {
    let getMyLibraryArticlesByUserId = {
      page: 0,
      size: 9,
      type: this.state.toggleValue,
    };

    MyCourseService.getMyLibraryArticlesByUserId(getMyLibraryArticlesByUserId)
      .then((response) => {
        this.courseblock(false);
        var result = response.data;
        if (result.data.articles == "") {
          this.setState({
            yuukkeMoreButtonState: false,
            peernoMoreButtonState: true,
          });
        }
        this.setState({
          staredArticles: result.data.articles,
          staredArticlesLength: result.data.totalItems,
          blocking: false,
          pageNo: 2,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getMoreStarArticles() {
    this.setState({ pageNo: this.state.pageNo + 1 });
    let getMyLibraryArticlesByUserId = {
      page: this.state.pageNo + 1,
      size: 3,
      type: this.state.toggleValue,
    };

    MyCourseService.getMyLibraryArticlesByUserId(getMyLibraryArticlesByUserId)
      .then((response) => {
        var result = response.data;

        if (result.data.articles == "") {
          this.setState({
            yuukkeMoreButtonState: false,
            peernoMoreButtonState: true,
          });
        }

        this.setState({
          staredArticles: this.state.staredArticles.concat(
            result.data.articles
          ),
          staredArticlesLength: result.data.totalItems,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
export default withTranslation()(MyLibraryCourses);
