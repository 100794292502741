import React from "react";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { withTranslation } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ChooseFileComponent(props) {
  const { t } = props;
  console.log("trans");
  console.log("acceptedType", props.acceptedType);
  return (
    <div className="">
      <div className="modal-content">
        <div className="modal-body p-0 ">
          <Dropzone
            accept={props.acceptedType}
            minSize={0}
            maxSize={52428800} // 50 MB
            // maxSize={31457280}
            maxFiles={10}
            multiple={true}
            onDrop={props.onUploadSuccess}
            onDropRejected={(e) => {
              console.log("error", e);
              if (
                e &&
                Array.isArray(e) &&
                e[0]?.errors[0]?.code === "file-invalid-type"
              ) {
                toast.warn(e[0]?.errors[0]?.message);
              } else if (e && Array.isArray(e) && e?.length > 10) {
                toast.warn("You should upload below 10 count of images");
              } else {
                toast.warn("Please upload file below 50MB", e);
              }
            }}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragReject,
              rejectedFiles,
            }) => (
              <section>
                <div
                  {...getRootProps()}
                  className="card page-background text-center file-uploader"
                >
                  <div className="card-body">
                    <div className="uploader">
                      <input {...getInputProps()} />
                      <div className="upload-icon">
                        <CloudUploadIcon fontSize="inherit" />
                      </div>
                      <div className="text-large-weighted mb-3">
                        {t(
                          "Drag and drop a file here or click to select a file"
                        )}

                        <p>
                          {t(
                            "Choose file below 50 mb else it will be auto removed from the list"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(ChooseFileComponent);

// import React, { Component } from "react";

// export default class ChooseFileComponent extends Component {
//   constructor(props) {
//     super(props);

//     this.handleChange = this.handleChange.bind(this);
//   }

//   componentDidMount() {

//   }
//   componentDidUpdate(prevProps, prevState) {

//   }

//   render() {
//     const handleDragEnter = e => {
//       e.preventDefault();
//       console.log("drag enter");
//     };

//     const handleDragLeave = e => {
//       e.preventDefault();
//       console.log("drag leave");
//     };

//     const handleDragOver = event => {
//       event.preventDefault();
//       console.log("drag over");

//     };

//     const handleDrop = event => {
//     //  e.preventDefault();
//       console.log("drag drop");
//       event.preventDefault();
//       let files = event.target.files[0]
//       console.log(event)
//       // let fileUrl = URL.createObjectURL(event.target.files[0])
//       // let types = event.target.files[0].type
//       // this.props.getFileData(files, fileUrl, types)

//     };

//     const handleInputChange = () => {
//       // let files = event.target.files[0]
//       // let fileUrl = URL.createObjectURL(event.target.files[0])
//       // let types = event.target.files[0].type
//       // this.props.getFileData(files, fileUrl, types)
//     };

//     return (
//       <div
//         className="dropzone"
//         onDrop={e => handleDrop(e)}
//         onDragOver={e => handleDragOver(e)}
//         onDragEnter={e => handleDragEnter(e)}
//         onDragLeave={e => handleDragLeave(e)}
//         draggable
//         onChange={e => handleInputChange(e)}
//       >

//         <div className="draggable-container">
//         {/* <label >Choose File</label> */}
//           <input

//             type="file"
//             accept={this.props.acceptedType}
//             name="file"
//             onChange={(e) => this.handleChange(e)}
//           />

//         </div>
//       </div>
//     );
//   }

//   handleChange(event) {

//     let files = event.target.files[0]
//     let fileUrl = URL.createObjectURL(event.target.files[0])
//     let types = event.target.files[0].type
//     let name = event.target.files[0].name

//     this.props.getFileData(files,fileUrl,types,name)

//   }
// }
