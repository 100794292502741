import { Chip, FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";
import BlockUi from "react-block-ui";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Multiselect } from "react-widgets";
import TextEditorQuestion from "../../components/TextEditorQuestion";
import { connect } from "react-redux";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import MessagesFileOption from "./Subjects/MessagesFileOption";
import ForumService from "../../api/ForumService";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import { getAdminCategoryList } from "../../redux/actions/commonApiActions";
import ProfileService from "../../api/ProfileService";
import AttachmentIcon from "@material-ui/icons/Attachment";
import * as APPCONFIG from "../../config/app-config.js";
import SearchTextField from "../../components/SearchTextField";

class NewCareerLab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      tagsList: [],
      questionDescribed: "",
      descriptionValue: "",
      optionTagList: [],
      resourceType: "",
      descriptionCount: 0,
      selectedCategory: [],
      selectedTagList: [],
      fileTypeState: false,
      file: null,
      expiryDate: "",
      scheduleValue: "",
      faqValue: "",
      scheduleValueCount: 0,
      faqValueCount: 0,
      termsValue: "",
      termsValueCount: 0,
      subjectId: 0,
      scheduleEditValue: "",
      faqEditValue: "",
      termsEditValue: "",
      descriptionEditValue: "",
      fileType: "",
      imageFile: [],
      editStatus: false,
      challengeType: 0,
    };
  }
  componentDidMount() {
    if (this.props.match.params.id !== "new") {
      this.setState({
        subjectId: this.props.match.params.id,
        editStatus: true,
      });
      this.editCareerLab();
    } else {
      if (
        this.props.adminCategoryList &&
        this.props.adminCategoryList.length === 0
      ) {
        this.props.getAdminCategoryList();
      }
    }
  }
  getOldTagList = async (oldTags) => {
    const filtered =
      oldTags &&
      oldTags.map((data) => {
        return { label: data.tagName, value: data.tagId };
      });

    await this.setState({
      selectedTagList: filtered ? filtered : [],
    });
  };
  render() {
    console.log("adminCategoryList", this.state.editStatus);
    const { t } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <div className="backEventorGroup flexDefault">
          <button onClick={this.backToCareerLabPage}>Back </button>
        </div>

        <div className="askAQuestionCardWrapper">
          <BlockUi
            tag="div"
            blocking={this.state.blocking}
            message={t("Loading, please wait")}
            color="#A00030"
          >
            <div className="askAQuestionInputGroup ">
              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <label className="">Title * </label>

                  <input
                    type="text"
                    name="title"
                    value={this.state.title}
                    maxLength="250"
                    onChange={this.handleForm}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="SYICInputWrapper ">
                    <label className="">
                      {t("Last date for application *")}
                    </label>
                    <DatePicker
                      minDate={new Date()}
                      value={this.state.expiryDate}
                      onChange={this.handleChangeInput}
                      format="MM/DD/YYYY"
                      containerStyle={{
                        width: "100%",
                      }}
                      style={{
                        height: "26px",
                        fontSize: "14px",
                        padding: "18px 12px",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="SYICInputWrapper ">
                  <label className="">{t("Select category *")}</label>
                  <Multiselect
                    style={{ fontColor: "gray" }}
                    required
                    data={this.props.adminCategoryList}
                    valueField="challengesId"
                    textField="challengesName"
                    name="selectedCategory"
                    value={this.state.selectedCategory}
                    onChange={(value) => this.handleCategoryMultiSelect(value)}
                  />
                </div>{" "}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="SYICInputWrapper ">
                  <label className="">{t("Tags *")}</label>
                  {/* <SearchTextField
                    updateTag={this.newTagList}
                    selectedTagList={this.state.selectedTagList}
                    editNeed={this.state.editStatus}
                  /> */}
                  <Multiselect
                    style={{ fontColor: "gray" }}
                    required
                    data={this.props.tagList}
                    valueField="tagId"
                    textField="tagName"
                    name="selectedTagList"
                    value={this.state.selectedTagList}
                    onChange={(value) => this.handleTagMultiSelect(value)}
                  />
                </div>
              </Grid>
            </Grid>
            {!this.state.editStatus && (
              <div className="mt-2 mb-3">
                <FormControl variant="outlined" fullWidth>
                  <label className="mt-2 mb-1">Career lab Type*</label>
                  <Select
                    labelId="userType"
                    name="challengeType"
                    value={this.state.challengeType}
                    onChange={this.handleForm}
                    label="User Type"
                  >
                    <MenuItem value={0}>Challenge</MenuItem>
                    <MenuItem value={1}>Course</MenuItem>
                    <MenuItem value={2}>Internship</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
            <div className="askAQuestionDescription">
              <label className="">{t("Description *")}</label>
              <TextEditorQuestion
                ref={this.myRef}
                editorStateValue={this.editorStateValuesDescription}
                editorValue={this.state.descriptionEditValue}
              />
              <p
                className="boxText"
                style={
                  this.state.descriptionCount > 2500
                    ? { color: "red" }
                    : { color: "green" }
                }
              >
                {" "}
                {2500 - this.state.descriptionCount} {t("characters left")}
              </p>
            </div>
            <div className="askAQuestionDescription mt-2">
              <label className="">{t("Terms and conditions *")}</label>
              <TextEditorQuestion
                ref={this.myRef}
                editorStateValue={this.editorStateValuesTerms}
                editorValue={this.state.termsEditValue}
              />
              <p
                className="boxText"
                style={
                  this.state.termsValueCount > 2500
                    ? { color: "red" }
                    : { color: "green" }
                }
              >
                {" "}
                {2500 - this.state.termsValueCount} {t("characters left")}
              </p>
            </div>
            <div className="askAQuestionDescription mt-2">
              <label className="">{t("Faq *")}</label>
              <TextEditorQuestion
                ref={this.myRef}
                editorStateValue={this.editorStateValuesFaq}
                editorValue={this.state.faqEditValue}
              />
              <p
                className="boxText"
                style={
                  this.state.faqValueCount > 2500
                    ? { color: "red" }
                    : { color: "green" }
                }
              >
                {" "}
                {2500 - this.state.faqValueCount} {t("characters left")}
              </p>
            </div>
            {/* <div className="askAQuestionDescription">
              <label className="">{t("Schedule *")}</label>
              <TextEditorQuestion
                ref={this.myRef}
                editorStateValue={this.editorStateValuesSchedule}
                editorValue={this.state.scheduleEditValue}
              />
              <p
                className="boxText"
                style={
                  this.state.scheduleValueCount > 2500
                    ? { color: "red" }
                    : { color: "green" }
                }
              >
                {" "}
                {2500 - this.state.scheduleValueCount} {t("characters left")}
              </p>
            </div> */}
            {this.state.imageFile.length === 0 && !this.state.fileTypeState && (
              <Grid
                container
                spacing={8}
                style={{ paddingTop: "22px", paddingBottom: "10px" }}
              >
                <Grid item md={5} sm={5} xs={5} lg={5}>
                  <div
                    style={{ cursor: "pointer", color: "#A00030" }}
                    onClick={() => this.keyFile("image")}
                  >
                    <CameraAltIcon /> Image
                  </div>{" "}
                </Grid>
              </Grid>
            )}
            {this.state.fileType === "image" &&
              this.state.imageFile &&
              this.state.imageFile.map((file, index) => (
                <div key={file.fileName}>
                  <Chip
                    icon={<AttachmentIcon />}
                    className="fileChip"
                    label={file.originalFileName}
                    size="small"
                    onDelete={() => this.removeFile(index)}
                    variant="outlined"
                  />
                  <img
                    style={{ margin: "8px", width: "80px", height: "80px" }}
                    src={`${APPCONFIG.API_URL}auth/image/${file.fileName}`}
                  ></img>
                </div>
              ))}

            {this.state.fileTypeState && (
              <div>
                {!this.state.fileImage && (
                  <AiOutlineCloseCircle
                    onClick={this.closeChoosedFileData}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      marginBottom: "8px",
                      width: "20px",
                      height: "20px",
                      color: "#000000",
                      marginTop: "8px",
                    }}
                  />
                )}

                <MessagesFileOption
                  fileTypeKey={this.state.fileTypeKey}
                  fileOption={this.state.fileOption}
                  fileOptionEnable={this.fileOptionEnable}
                  fromQuestionImage={this.getQuestionMediaImage}
                  fromQuestionMedia={this.getQuestionMediaFiles}
                ></MessagesFileOption>
              </div>
            )}
          </BlockUi>
          <div className="askAQuestionBtn mt-4">
            {this.props.match.params.id === "new" ? (
              <button onClick={(e) => this.onFormSubmit(e)}>
                Create CareerLab
              </button>
            ) : (
              <button onClick={(e) => this.onFormUpdate(e)}>
                Update CareerLab
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
  newTagList = (value) => {
    this.setState({
      selectedTagList: value,
    });
  };
  removeFile = () => {
    this.setState({
      imageFile: [],
      fileType: "text",
    });
  };
  editCareerLab = () => {
    let Edit = this.props.match.params.id;
    var subjectId = {
      subjectId: Edit,
    };
    ProfileService.editGroupsEventsV2(subjectId)
      .then((response) => {
        let result = response.data.data;
        this.setState({
          title: result.subjectName,
          // expiryDate: result.expiryDate,
          selectedCategory: result.challengesCategoryValue,
          // selectedTagList: result.tagsValue ,
          scheduleEditValue: result.subjectSchedule,
          faqEditValue: result.subjectFaq ? result.subjectFaq : "",
          termsEditValue: result.subjectCondition,
          descriptionEditValue: result.subjectDescription,
          imageFile: result.grouporeventFiles,
          fileType: result.fileType,
          subjectDescription: result.subjectDescription,
          termsValue: result.subjectCondition,
          faqValue: result.subjectFaq ? result.subjectFaq : "",
          scheduleValue: result.subjectSchedule,
          expiryDate: moment(result.expiryDate).format("MM/DD/YYYY"),
        });
        this.getOldTagList(result.tagsValue);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChangeInput = (value) => {
    this.setState({
      expiryDate: value,
    });
    // alert(moment(new Date(value)).format("MM/DD/YYYY"))
  };
  closeChoosedFileData = () => {
    this.setState({
      fileTypeState: false,
      fileImage: false,
      file: [],
    });
  };
  fileOptionEnable = () => {
    this.setState({
      file: null,
      fileOption: false,
      fileImage: false,
    });
  };

  getQuestionMediaImage = (files, type) => {
    this.setState({
      file: files,
      type: type,
      selectOption: false,
      fileImage: true,
    });
  };
  getQuestionMediaFiles = (files, type) => {
    this.setState({ file: files, type: type });
  };
  keyFile = (key) => {
    this.setState({ fileTypeKey: key, fileTypeState: true, fileType: key });
  };
  onFormSubmit = (e) => {
    if (
      this.state.title === "" ||
      this.state.selectedCategory.length === 0 ||
      this.state.selectedTagList.length === 0 ||
      this.state.expiryDate === "" ||
      this.state.subjectDescription === ""
    ) {
      toast.warning("Select all the field");
    } else if (this.state.faqValue === "") {
      toast.warning("Fill faq");
    } else if (this.state.termsValue === "") {
      toast.warning("Fill  terms and condition");
    }
    // else if (this.state.scheduleValue === "") {
    //   toast.warning("Fill schedule");
    // }
    else {
      const formData = new FormData();

      var tags = [];
      var category = [];
      this.state.selectedCategory.forEach((element) => {
        category.push(element.challengesId);
      });
      // this.state.selectedTagList.forEach((element) => {
      //   tags.push(element.tagId);
      // });
      const newTagList =
        this.state.selectedTagList &&
        this.state.selectedTagList.map((item) => {
          return { tagName: item.label, tagId: item.value };
        });
      if (this.state.file !== null) {
        formData.append("subjectName", this.state.title);
        formData.append("subjectDescription", this.state.subjectDescription);
        formData.append("isExpiry", "yes");
        formData.append("type", "groups");
        formData.append("fileType", "image");
        formData.append("status", "challenges");
        formData.append("memberShipType", "free");
        formData.append("tagsId", tags);
        formData.append("categoryId", category);
        formData.append("messgaeFiles", this.state.file[0]);
        formData.append("memberShipType", "free");
        formData.append("subjectSchedule", this.state.scheduleValue);
        formData.append("subjectFaq", this.state.faqValue);
        formData.append("subjectCondition", this.state.termsValue);
        formData.append("date", this.state.expiryDate);
        // formData.append(
        //   "newTagList",
        //   newTagList ? JSON.stringify(newTagList) : []
        // );
      } else {
        formData.append("subjectName", this.state.title);
        formData.append("subjectDescription", this.state.subjectDescription);
        formData.append("isExpiry", "yes");
        formData.append("type", "groups");
        formData.append("fileType", "text");
        formData.append("status", "challenges");
        formData.append("memberShipType", "free");
        formData.append("tagsId", tags);
        formData.append("categoryId", category);
        formData.append("memberShipType", "free");
        formData.append("subjectSchedule", this.state.scheduleValue);
        formData.append("subjectFaq", this.state.faqValue);
        formData.append("subjectCondition", this.state.termsValue);
        formData.append("date", this.state.expiryDate);
        // formData.append(
        //   "newTagList",
        //   newTagList ? JSON.stringify(newTagList) : []
        // );
      }
      formData.append("careerLabType", this.state.challengeType);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.setState({
        blocking: true,
      });
      ForumService.admin_resource_save(formData, config)
        .then((response) => {
          toast.success("CareerLab created successfully");
          this.setState({
            blocking: false,
          });
          this.backToCareerLabPage();
        })
        .catch((error) => {
          toast.error("Error in  saving");
          console.log(error);
        });
    }

    //}
  };

  onFormUpdate = (e) => {
    if (
      this.state.title === "" ||
      this.state.selectedCategory.length === 0 ||
      this.state.selectedTagList.length === 0 ||
      this.state.expiryDate === "" ||
      this.state.subjectDescription === ""
    ) {
      toast.warning("Select all the field");
    } else if (this.state.faqEditValue === "") {
      toast.warning("Fill faq");
    } else if (this.state.termsEditValue === "") {
      toast.warning("Fill  terms and condition");
    }
    // else if (this.state.scheduleEditValue === "") {
    //   toast.warning("Fill schedule");
    // }
    else {
      const formData = new FormData();

      var tags = [];
      var category = [];
      this.state.selectedCategory.forEach((element) => {
        category.push(element.challengesId);
      });
      // const newTagList =
      //   this.state.selectedTagList &&
      //   this.state.selectedTagList.map((item) => {
      //     return { tagName: item.label, tagId: item.value };
      //   });
      this.state.selectedTagList.forEach((element) => {
        tags.push(element.tagId);
      });

      if (
        this.state.fileType === "image" &&
        this.state.imageFile.length !== 0
      ) {
        formData.append("subjectId", this.state.subjectId);
        formData.append("subjectName", this.state.title);
        formData.append("subjectDescription", this.state.subjectDescription);
        formData.append("isExpiry", "No");
        formData.append("type", "groups");
        formData.append("fileType", "image");
        formData.append("status", "challenges");
        formData.append("memberShipType", "free");
        formData.append("tagsId", tags);
        formData.append("categoryId", category);
        formData.append("subjectId", this.state.subjectId);
        formData.append("memberShipType", "free");
        formData.append("subjectSchedule", this.state.scheduleValue);
        formData.append("subjectFaq", this.state.faqValue);
        formData.append("subjectCondition", this.state.termsValue);
        formData.append("date", this.state.expiryDate);
        // formData.append(
        //   "newTagList",
        //   newTagList ? JSON.stringify(newTagList) : []
        // );
      } else if (
        this.state.fileType === "image" &&
        this.state.imageFile.length === 0 &&
        this.state.file !== null
      ) {
        formData.append("subjectId", this.state.subjectId);
        formData.append("subjectName", this.state.title);
        formData.append("subjectDescription", this.state.subjectDescription);
        formData.append("isExpiry", "No");
        formData.append("type", "groups");
        formData.append("fileType", "image");
        formData.append("status", "challenges");
        formData.append("memberShipType", "free");
        formData.append("tagsId", tags);
        formData.append("categoryId", category);
        formData.append("subjectId", this.state.subjectId);
        formData.append("memberShipType", "free");
        formData.append("subjectSchedule", this.state.scheduleValue);
        formData.append("subjectFaq", this.state.faqValue);
        formData.append("subjectCondition", this.state.termsValue);
        formData.append("date", this.state.expiryDate);
        formData.append("messgaeFiles", this.state.file[0]);
        // formData.append(
        //   "newTagList",
        //   newTagList ? JSON.stringify(newTagList) : []
        // );
      } else {
        formData.append("subjectId", this.state.subjectId);
        formData.append("subjectName", this.state.title);
        formData.append("subjectDescription", this.state.subjectDescription);
        formData.append("isExpiry", "No");
        formData.append("type", "groups");
        formData.append("fileType", "text");
        formData.append("status", "challenges");
        formData.append("memberShipType", "free");
        formData.append("tagsId", tags);
        formData.append("categoryId", category);
        formData.append("subjectId", this.state.subjectId);
        formData.append("memberShipType", "free");
        formData.append("subjectSchedule", this.state.scheduleValue);
        formData.append("subjectFaq", this.state.faqValue);
        formData.append("subjectCondition", this.state.termsValue);
        formData.append("date", this.state.expiryDate);
        formData.append("resourceType", 0);
        // formData.append(
        //   "newTagList",
        //   newTagList ? JSON.stringify(newTagList) : []
        // );
      }
      // formData.append("careerLabType", this.state.challengeType);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.setState({
        blocking: true,
      });
      ForumService.admin_update_subject(formData, config)
        .then((response) => {
          if (response.data.status === -1) {
            toast.error("Error in saving");
            this.setState({
              blocking: false,
            });
          } else {
            this.setState({
              blocking: false,
            });
            toast.success("Career updated successfully");
            this.backToCareerLabPage();
          }
        })
        .catch((error) => {
          toast.error("Error in saving");
          console.log(error);
        });
    }

    //}
  };
  handleUserType = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  editorStateValuesDescription = (values) => {
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        subjectDescription: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        subjectDescription: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  };
  editorStateValuesTerms = (values) => {
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        termsValue: values,
        plainStringContent: "",
        termsValueCount: 0,
      });
    } else {
      this.setState({
        termsValue: values,
        plainStringContent: plainString,
        termsValueCount: plainString.length,
      });
    }
  };
  editorStateValuesFaq = (values) => {
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        faqValue: values,
        plainStringContent: "",
        faqValueCount: 0,
      });
    } else {
      this.setState({
        faqValue: values,
        plainStringContent: plainString,
        faqValueCount: plainString.length,
      });
    }
  };
  editorStateValuesSchedule = (values) => {
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        scheduleValue: values,
        plainStringContent: "",
        scheduleValueCount: 0,
      });
    } else {
      this.setState({
        scheduleValue: values,
        plainStringContent: plainString,
        scheduleValueCount: plainString.length,
      });
    }
  };
  backToCareerLabPage = () => {
    this.props.history.push(`/${this.props.workspaceType}/career-lab`);
  };

  handleForm = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleCategoryMultiSelect = (badges_value) => {
    this.setState({
      selectedCategory: badges_value,
    });
  };
  handleAreaOfInterestMultiSelect = (data) => {};

  handleTagMultiSelect = (data) => {
    this.setState({
      selectedTagList: data,
    });
  };
}
const mapStateToProps = function (state) {
  return {
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    tagList: state.commonApi.taglist.data,
    adminCategoryList: state.commonApi.adminCategoryList,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    getAdminCategoryList: () => dispatch(getAdminCategoryList(0)),
  };
};
export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(NewCareerLab)));
