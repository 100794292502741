import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AuthService from "../../session/AuthService";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import MyEventCard from "./MyEventCard";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { connect } from "react-redux";
import { HiOutlineDocument } from "react-icons/hi";
import { searchByGroupsAndChallenges } from "../../redux/actions/subjectActions";
import MyCommunityCard from "./MyCommunityCard";
import {
  getMyCommunityList,
  getCommunityTags,
} from "../../redux/actions/subjectActions";

class MyGroupsandeventsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupDataList: [],
      searchPageNo: 0,
      searchOptionSeeMore: false,
      searchDataValue: "",
      pageNo: 0,
    };
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    let data = {
      page: 0,
      searchKey: "",
      filterKeys: [],
      tagIds: [],
      type: this.props.type,
      loadingStats: false,
    };
    this.setState({
      pageNo: 0,
    });

    this.props.getMyCommunityList(data, false);
    this.props.getCommunityTags(this.props.type);
  }

  componentDidUpdate(prevProps, nextProps) {
    if (this.props.type && prevProps.type !== this.props.type) {
      this.setState({
        // groupDataList: [],
        // loadingStats: true,
        pageNo: 0,
      });
      let data = {
        page: 0,
        searchKey: "",
        filterKeys: [],
        tagIds: [],
        type: this.props.type,
      };
      this.props.getMyCommunityList(data, false);
      this.props.getCommunityTags(this.props.type);
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <MyCommunityCard
          // loadingStats={this.state.loadingStats}
          groupList={this.props.groupDataList}
          onCallingSeeMore={this.pageNo}
          searchListPage={this.props.communityTotalItems}
          pageNo={this.state.pageNo}
          removePageNumber={this.removePageNumber}
          filterList={this.props.communityFilterOptions}
          type={this.props.type}
          enterKey={this.props.enterKey}
        />
      </div>
    );
  }
  removePageNumber = () => {
    this.setState({
      pageNo: 0,
    });
  };

  pageNo = (searchValue, filterKeys, tagData) => {
    let page = this.state.pageNo + 1;
    this.setState({
      pageNo: page,
      // loadingStats: true,
    });
    let data = {
      page: page,
      searchKey: searchValue,
      filterKeys: filterKeys,
      type: this.props.type,
      tagIds: tagData,
    };

    this.props.getMyCommunityList(data, true);
    // this.props.searchByGroupsAndChallenges(page, "myCommunity", "");
  };

  block(blocking) {
    this.setState({ blocking: blocking });
  }
}

const mapDispacthToProps = (dispatch) => {
  return {
    getMyCommunityList: (data, pageNation) =>
      dispatch(getMyCommunityList(data, pageNation)),
    getCommunityTags: (data) => dispatch(getCommunityTags(data)),
    searchByGroupsAndChallenges: (page, groupOrChallenge, searchKey) =>
      dispatch(searchByGroupsAndChallenges(page, groupOrChallenge, searchKey)),
  };
};

const mapStateToProps = function (state) {
  return {
    // choosetagList: state.commonApi.taglist,
    // searchList: state.subjects.searchList,
    // searchListPage: state.subjects.searchListPage,
    groupDataList: state.subjects.myCommunityList
      ? state.subjects.myCommunityList
      : [],
    communityTotalItems: state.subjects.communityTotalItems,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(MyGroupsandeventsList)));
