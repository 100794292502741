import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import PeoplesCarousel from "./PeoplesCarousel";
import SearchPeoplesCarousel from "./SearchPeoplesCarouselV2";
import FriendsOnYuukke from "./FriendsOnYuukke";
import RecentlyAddedFriends from "./RecentlyAddedFriends";
import PeopleInvitations from "./PeopleInvitations";
import AuthService from "../../../session/AuthService";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import ProfileService from "../../../api/ProfileService";
import BlockUi from "react-block-ui";
import Grid from "@material-ui/core/Grid";
import FindFriendsFilter from "../../../api/FindFriendsFilter";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getFriendsRequestList,
  getRecommendedFriends,
  getFriendsList,
  getrecentlyAddedFriends,
  getUserRequestList,
  searchFriends,
  searchRequestedFriendsList,
  searchUserRequestList,
  searchRecomentedFriendsList,
} from "../../../redux/actions/PeerMenuListActions";
import { connect } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";

import {
  getAllCommonFriendsList,
  needPhotVerification,
  triggerTourPopup,
} from "../../../redux/actions/commonApiActions";

import MyYuukke from "../../../api/MyYuukke";
import "./Email.css";
import { FcConferenceCall } from "react-icons/fc";
import InfiniteScroll from "react-infinite-scroll-component";
import InviteUser from "../../../components/InviteUser";
import { giveUserAction } from "../../../utils";
class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blocking: false,
      requestBlock: false,
      suggestedBlock: false,
      tabIndex: 0,
      pagination: 0,
      pageNo: 0,
      searchKey: false,
      showAskQuestion: false,
      searchList: [],
      searchResults: [],
      showResults: false,
      reminderListDatas: [],
      reminderListDatasState: false,
      sizeofRecommendedList: 0,
      sizeofFriendsList: 0,
      recommendedList: [],
      FriendsList: [],
      requestedList: [],
      recentlyAddedFriendsList: [],
      countryList: [],
      filterValues: {},
      CityList: [],
      SchoolList: [],
      ClassList: [],
      LanguageList: [],
      SearchResultList: [],
      totalcount: 0,
      searchActivePage: 1,
      searchpage: 1,
      startPage: 0,
      gradeDetails: [],
      SearchResult: false,
      showQuestionFilter: false,
      invitePopup: false,
      email: "",
      items: [],
      value: "",
      error: null,
      friendsTabIndex: 0,
      searchValues: "",
      buttonColor: false,
      placeholder: this.props.t(
        "Search all users using name, location, country and area of interest..."
      ),
      emailVerification: [],
      searchListFriends: [],
      recommendedListSearch: [],
      recommendedFriendsListState: false,
      requestListSearch: [],
      requestList: [],
      friendsPage: 0,
      requestedPage: 0,
      userRequestPage: 0,
      recomendedPage: 0,
      hasMore: true,
      requestHasMore: true,
      searchInvitations: [],
      suggestHasMore: true,
      friendsHasMore: true,
      requestedFriendsPage: 0,
      recommendedFriendsPage: 0,
      firstFriendsPage: 0,
      userRequestedFriendsPage: 0,
    };
    this.block = this.block.bind(this);
    this.toUpCallListPage = this.toUpCallListPage.bind(this);
    this.getAllReminders = this.getAllReminders.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.requestSentData = this.requestSentData.bind(this);
    this.acceptSentData = this.acceptSentData.bind(this);
    this.rejectSentData = this.rejectSentData.bind(this);
    this.handleshowAskQuestion = this.handleshowAskQuestion.bind(this);
    this.handleViewAllQuestions = this.handleViewAllQuestions.bind(this);
    this.ignoreSentData = this.ignoreSentData.bind(this);
    this.requestblock = this.requestblock.bind(this);
    this.suggestedblock = this.suggestedblock.bind(this);
    this.onRecentSearchList = this.onRecentSearchList(this);
    this.getAllCountry = this.getAllCountry.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.LoadCityByCountryId = this.LoadCityByCountryId.bind(this);
    this.LoadClassesBySchoolId = this.LoadClassesBySchoolId.bind(this);
    this.OnSearchByFilter = this.OnSearchByFilter.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.ClearAllSearchresult = this.ClearAllSearchresult.bind(this);
    this.getAllProgram = this.getAllProgram.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.showQuestionFilter = this.showQuestionFilter.bind(this);
    this.closeInvitePage = this.closeInvitePage.bind(this);
    this.emailInvite = this.emailInvite.bind(this);
    this.inviteUser = this.inviteUser.bind(this);
    this.handleChangeTabValue = this.handleChangeTabValue.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.searchMembers = this.searchMembers.bind(this);
    this.usersList = this.usersList.bind(this);
    this.searchPagination = this.searchPagination.bind(this);
    this.allSearchPage = this.allSearchPage.bind(this);
  }
  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
      showAskQuestion: false,
    });
  }
  handleshowAskQuestion() {
    this.setState({
      showAskQuestion: !this.state.showAskQuestion,
      tabIndex: this.state.showAskQuestion ? 2 : null,
    });
    this.props.history.push(`/ask-a-question`);
  }

  handleViewAllQuestions() {
    this.setState({
      tabIndex: 0,
      showAskQuestion: false,
    });
  }
  //   scrollTrackFunc = () => {

  //      if(window.scrollY > 271){
  //         this.setState({
  //             navPageStatic:true,
  //         })

  //     }else{
  //         this.setState({
  //             navPageStatic:false,
  //         })
  //     }
  // }
  // componentWillUnmount() {

  //   window.removeEventListener("scroll", this.scrollTrackFunc);

  // }
  componentDidUpdate(previous) {
    console.log("com-com-update", this.props.inviteUser);

    if (this.props.inviteUser !== previous.inviteUser && this.props.invite) {
      this.setState({
        invitePopup: true,
      });
    }
  }
  componentDidMount() {
    // if (!(window.innerWidth <= 768 || window.innerWidth == 768)) {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "auto",
    //     //  'smooth'
    //   });
    // }
    // if (this.props.invite !== prevProps.invite) {
    //   console.log("invite inviteUser-update", this.props.inviteUser);
    //   this.setState({
    //     invitePopup: this.props.invite,
    //     inviteUser: this.props.inviteUser,
    //   });
    // }

    console.log("com-com-update", this.props.invite);
    if (this.props.invite) {
      this.setState({
        invitePopup: true,
      });
    }
    if (!this.props.toursStages) {
      console.log("Trigger Tour popup");
      this.props.triggerTourPopup("connection");
    }
    this.props.searchFriends(this.state.value, this.state.friendsPage);
    this.props.searchRequestedFriendsList(
      this.state.value,
      this.state.requestedPage
    );
    this.props.searchUserRequestList(
      this.state.value,
      this.state.userRequestPage
    );
    this.props.searchRecomentedFriendsList(
      this.state.value,
      this.state.recomendedPage
    );
    console.log("searchFriendsList", this.props.searchFriendsList);
    let auth = this.props.authUser.type;
    if (auth) {
      this.setState({
        FriendsList: this.props.searchFriendsList,

        FriendsListSearch: this.props.searchFriendsList,

        recommendedList: this.props.searchRecommendedFriendsList,

        recommendedListSearch: this.props.searchRecommendedFriendsList,

        searchInvitations: this.props.searchRequestedFriendsLists,

        invitationsList: this.props.searchRequestedFriendsLists,

        requestList: this.props.searchUserRequestedFriendsList,

        requestListSearch: this.props.searchUserRequestedFriendsList,
        requestedFriendsPage: this.props.searchRequestedFriendsPage,
        recommendedFriendsPage: this.props.searchRecommendedFriendsPage,
        firstFriendsPage: this.props.searchFriendsPage,
        userRequestedFriendsPage: this.props.searchUserRequestedFriendsPage,
      });

      // this.props.getFriendsRequestList();
      // this.props.getRecommendedFriends();
      // this.props.getUserRequestList();
      // this.getAllReminders();

      //  this.props.getrecentlyAddedFriends();
      // this.getAllProgram();
      // this.getallGrade();
    } else {
      this.props.history.push(`/msogin`);
    }
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    // this.allSearchPage();
    // console.log("123 searchInvitations", this.props.requestedList);
    // console.log(
    //   "123 this.props.userRequestedList",
    //   this.props.userRequestedList
    // );
  }

  componentWillReceiveProps(nextProps) {
    // if (this.props.invite !== nextProps.invite) {

    //   this.setState({
    //     invitePopup: this.props.invite,
    //     inviteUser: this.props.inviteUser,
    //   });
    // }

    let auth = this.props.authUser.type;
    if (auth) {
      if (this.state.friendsPage > 0) {
        if (this.props.searchFriendsList !== nextProps.searchFriendsList) {
          this.setState({
            FriendsList: this.state.FriendsList.concat(
              nextProps.searchFriendsList
            ),
            FriendsListSearch: this.state.FriendsList.concat(
              nextProps.searchFriendsList
            ),
          });
        }
      } else {
        if (this.props.searchFriendsList !== nextProps.searchFriendsList) {
          this.setState({
            FriendsList: nextProps.searchFriendsList,
            FriendsListSearch: nextProps.searchFriendsList,
          });
        }
      }
      if (this.state.recomendedPage > 0) {
        if (
          this.props.searchRecommendedFriendsList !==
          nextProps.searchRecommendedFriendsList
        ) {
          this.setState({
            recommendedList: this.state.recommendedList.concat(
              nextProps.searchRecommendedFriendsList
            ),
            recommendedListSearch: this.state.recommendedList.concat(
              nextProps.searchRecommendedFriendsList
            ),
          });
        }
      } else {
        if (
          this.props.searchRecommendedFriendsList !==
          nextProps.searchRecommendedFriendsList
        ) {
          this.setState({
            recommendedList: nextProps.searchRecommendedFriendsList,
            recommendedListSearch: nextProps.searchRecommendedFriendsList,
          });
        }
      }

      if (this.state.requestedPage > 0) {
        if (
          this.props.searchRequestedFriendsLists !==
          nextProps.searchRequestedFriendsLists
        ) {
          this.setState({
            searchInvitations: this.state.invitationsList.concat(
              nextProps.searchRequestedFriendsLists
            ),
            invitationsList: this.state.invitationsList.concat(
              nextProps.searchRequestedFriendsLists
            ),
          });
        }
      } else {
        if (
          this.props.searchRequestedFriendsLists !==
          nextProps.searchRequestedFriendsLists
        ) {
          this.setState({
            searchInvitations: nextProps.searchRequestedFriendsLists,
            invitationsList: nextProps.searchRequestedFriendsLists,
          });
        }
      }

      if (this.state.userRequestPage > 0) {
        if (
          this.props.searchUserRequestedFriendsList !==
          nextProps.searchUserRequestedFriendsList
        ) {
          this.setState({
            requestList: this.state.requestList.concat(
              nextProps.searchUserRequestedFriendsList
            ),
            requestListSearch: this.state.requestList.concat(
              nextProps.searchUserRequestedFriendsList
            ),
          });
        }
      } else {
        if (
          this.props.searchUserRequestedFriendsList !==
          nextProps.searchUserRequestedFriendsList
        ) {
          this.setState({
            requestList: nextProps.searchUserRequestedFriendsList,
            requestListSearch: nextProps.searchUserRequestedFriendsList,
          });
        }
      }
    }
  }

  handleChangeTabValue(index) {
    this.setState({
      friendsTabIndex: index,
      // buttonColor:true,
    });
    if (index === 1) {
      this.setState({
        FriendsList: this.props.searchFriendsList,
        // recommendedList: this.props.recommendedList,
        buttonColor: true,
      });
    } else {
      this.setState({
        FriendsList: this.props.searchFriendsList,
        // recommendedList: this.props.recommendedList,
        buttonColor: false,
      });
    }
  }

  async handleForm(event) {
    const { t } = this.props;
    // console.log("invitationsList", this.props.requestedList);

    await this.setState({
      [event.target.name]: event.target.value,
      searchData: "",
      friendsPage: 0,
      recomendedPage: 0,
      requestedPage: 0,
      userRequestPage: 0,
    });
    if (event.target.value === 0) {
      // this.searchMembers(this.state.searchData);
      this.setState({
        searchKey: false,
        placeholder: t(
          "Search all users using name, location, country and area of interest..."
        ),
      });
      this.props.searchFriends("", 0);
      this.props.searchRequestedFriendsList("", 0);
      this.props.searchUserRequestList("", 0);
      this.props.searchRecomentedFriendsList("", 0);
    } else if (event.target.value === 1) {
      this.setState({
        searchKey: false,
        placeholder: t(
          "Search connections using name, location, country and area of interest..."
        ),
      });
    } else if (event.target.value === 2) {
      //  this.searchMembers(this.state.searchData);
      this.setState({
        searchKey: false,
        placeholder: t(
          "Search suggested friends using name, location, country and area of interest..."
        ),
      });

      this.props.searchFriends("", 0);
      this.props.searchRequestedFriendsList("", 0);
      this.props.searchUserRequestList("", 0);
    } else if (event.target.value === 3) {
      this.setState({
        searchKey: false,
        placeholder: t(
          "Search invitations using name, location, country and area of interest..."
        ),
        // recommendedList: this.props.searchRecommendedFriendsList,
        // requestList: this.props.searchUserRequestedFriendsList,
        // FriendsList: this.props.searchFriendsList,
        // invitationsList: this.props.searchRequestedFriendsLists,
      });

      // if (this.state.FriendsList.length == 0) {
      this.props.searchFriends("", 0);
      // }
      //  if(this.state.invitationsList.length == 0)
      //  {
      //  this.props.searchRequestedFriendsList('',0);
      //  }
      //   if (this.state.requestList.length == 0) {
      this.props.searchUserRequestList("", 0);
      // }
      // if (this.state.recommendedList.length == 0) {
      this.props.searchRecomentedFriendsList("", 0);
      // }
    } else if (event.target.value === 4) {
      this.setState({
        searchKey: false,
        placeholder: t(
          "Search requested friends using name, location, country and area of interest...."
        ),
      });

      this.props.searchFriends("", 0);

      this.props.searchRequestedFriendsList("", 0);

      this.props.searchRecomentedFriendsList("", 0);
    }
  }

  allSearchPage() {
    // alert("xcf")

    if (this.state.friendsTabIndex === 4) {
      if (this.props.searchUserRequestedFriendsPage - 1 > 0) {
        // alert("inside")
        this.setState({
          hasMore: true,
        });
      } else {
        // alert("outside")
        this.setState({
          hasMore: false,
        });
      }
    } else if (this.state.friendsTabIndex === 3) {
      if (this.props.searchRequestedFriendsPage > 0) {
        this.setState({
          requestHasMore: true,
        });
      } else {
        this.setState({
          requestHasMore: false,
        });
      }
    } else if (this.state.friendsTabIndex === 2) {
      if (this.props.searchRecommendedFriendsPage > 0) {
        this.setState({
          suggestHasMore: true,
        });
      } else {
        this.setState({
          suggestHasMore: false,
        });
      }
    } else if (this.state.friendsTabIndex === 1) {
      if (this.props.searchFriendsPage > 0) {
        this.setState({
          friendsHasMore: true,
        });
      } else {
        this.setState({
          friendsHasMore: false,
        });
      }
    }
  }
  fetchMoreDataFriends = () => {
    // alert( this.props.searchFriendsPage )
    this.setState({
      friendsPage: this.state.friendsPage + 1,
    });
    this.props.searchFriends(this.state.value, this.state.friendsPage);
    if (this.props.searchFriendsPage - 1 > this.state.friendsPage) {
      this.setState({
        friendsHasMore: true,
      });
    } else {
      this.setState({
        friendsHasMore: false,
      });
    }
  };
  fetchMoreDataSuggestion = () => {
    // alert(this.props.searchRecommendedFriendsPage);
    this.setState({
      recomendedPage: this.state.recomendedPage + 1,
    });
    this.props.searchRecomentedFriendsList(
      this.state.value,
      this.state.recomendedPage
    );
    if (
      this.props.searchRecommendedFriendsPage - 1 >
      this.state.recomendedPage
    ) {
      this.setState({
        suggestHasMore: true,
      });
    } else {
      this.setState({
        suggestHasMore: false,
      });
    }
  };
  fetchMoreDataInvitation = () => {
    // alert(this.props.searchRequestedFriendsPage);
    this.setState({
      requestedPage: this.state.requestedPage + 1,
    });
    this.props.searchRequestedFriendsList(
      this.state.value,
      this.state.requestedPage
    );
    if (this.props.searchRequestedFriendsPage - 1 > this.state.requestedPage) {
      this.setState({
        requestHasMore: true,
      });
    } else {
      this.setState({
        requestHasMore: false,
      });
    }
  };
  fetchMoreData = () => {
    // alert(this.props.searchUserRequestedFriendsPage);
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    this.setState({
      userRequestPage: this.state.userRequestPage + 1,
    });
    this.props.searchUserRequestList(
      this.state.value,
      this.state.userRequestPage
    );
    if (
      this.props.searchUserRequestedFriendsPage - 1 >
      this.state.userRequestPage
    ) {
      this.setState({
        hasMore: true,
      });
    } else {
      this.setState({
        hasMore: false,
      });
    }
  };
  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }
  handleScroll = (e) => {
    console.log("e.trget", e.target.scrollHeight);
    console.log("e.trget1", e.target.scrollTop);
    console.log("e.trget2", e.target.clientHeight);
    let height = e.target.scrollHeight - e.target.scrollTop;
    console.log("e.trget3", height);
    const bottom = parseInt(height) === e.target.clientHeight;
    console.log("e.trget2", bottom);
    if (bottom) {
      this.setState({
        userRequestPage: this.state.userRequestPage + 1,
      });
      this.props.searchUserRequestList(
        this.state.value,
        this.state.userRequestPage
      );
      if (
        this.props.searchUserRequestedFriendsPage - 1 >
        this.state.userRequestPage
      ) {
        this.setState({
          hasMore: true,
        });
      } else {
        this.setState({
          hasMore: false,
        });
      }
    }
  };
  render() {
    const { t } = this.props;
    const { tabIndex, showAskQuestion, selectedValue, friendsTabIndex } =
      this.state;

    return (
      <>
        {/* <Container> */}

        {this.state.invitePopup && (
          <div>
            <InviteUser
              emailData={this.emailInvite}
              closeInvitePage={() => this.setState({ invitePopup: false })}
            />
          </div>
        )}
        <Grid container spacing={2}>
          <Grid item lg={9} md={8} sm={12} xs={12}>
            <h3 className="listHomeTitle">{t("Connections")}</h3>
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            {/* {this.props.loginDetails.uType === 6 && ( */}
            <button
              className="connection-tour-invite"
              style={{ float: "right" }}
              onClick={this.inviteUser}
            >
              {t("Invite Friend")}
            </button>
            {/* )} */}
          </Grid>
        </Grid>
        <div
          className="spaceFiletCard newPostCardWrapper"
          style={{ marginTop: "8px" }}
        >
          {/* <div class="btn-group"> */}

          <Grid container spacing={1}>
            <Grid
              item
              lg={8}
              md={8}
              sm={8}
              xs={8}
              className="connection-tour-search"
              //  style={{ marginTop: "12px" }}
            >
              <TextField
                id="subjects"
                label=""
                style={{ width: "90%", height: "100%" }}
                variant="outlined"
                onChange={(e) => this.searchMembers(e.target.value, "next")}
                value={this.state.searchData}
                placeholder={this.state.placeholder}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              {/* {friendsTabIndex == 1 && (
                    <TextField
                      id="subjects"
                      label=""
                      style={{ width: "90%", height: "100%" }}
                      variant="outlined"
                      onChange={(e) => this.searchMembers(e.target.value)}
                      placeholder={t(
                        "Search  connections and  suggested connection..."
                      )}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )} */}
            </Grid>
            <Grid
              item
              md={4}
              xs={4}
              sm={4}
              lg={4}
              className="connection-tour-type"
            >
              <TextField
                style={{
                  borderRadius: "10px",
                  paddingLeft: "2px",
                  marginBottom: "8px",
                }}
                fullWidth
                id="dropdown"
                select
                label=""
                variant="outlined"
                type="text"
                required=""
                name="friendsTabIndex"
                value={this.state.friendsTabIndex}
                onChange={this.handleForm}
              >
                <MenuItem value={0}>
                  <>{t("Search by Yuukkeans")}</>
                </MenuItem>
                <MenuItem value={1}>
                  <>{t("Search by Friends")}</>
                </MenuItem>
                <MenuItem value={2}>
                  <>{t("Search by Suggested Friends")}</>
                </MenuItem>
                <MenuItem value={3}>
                  <>{t("Search by Invitations")}</>
                </MenuItem>
                <MenuItem value={4}>
                  <>{t("Search by Requested Friends")}</>
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </div>
        {/* <h4>Connection</h4> */}

        {/* {this.props.loginDetails.uType === 6 && ( */}
        {/* <button
                          style={{ float: "right" }}
                          onClick={this.createGroup}
                        >
                          Create  
                        </button> */}

        {/* )} */}

        <div style={{ flex: 1 }}>
          {/* <div className="communityCardWrapper cardWrapper">
              <div className="communityCardQuestion">
                <Grid
                  item
                  lg={6}
                  md={7}
                  sm={7}
                  xs={7}
                  style={{ marginTop: "12px" }}
                >
                 

                  <div className="badgeMainWrapper"></div>
                </Grid> */}
          {/* 
              </div>
            </div> */}
          {/* <div className="QuestionFilteronMobile"> */}
          {/* <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} md={12}>         
                  <div className="CTSearchBtn" onClick={this.showQuestionFilter}>
            <button  class="text-left" style={{height:"59px"}}data-toggle="dropdown">{t('Filter friends')}
            {!this.state.showQuestionFilter &&<ArrowDropDownIcon style={{float:"right"}}className="text-right"/>}
            {this.state.showQuestionFilter &&<ArrowDropUpIcon style={{float:"right"}}className="text-right"/>}
              </button>            
              
              </div>
              </Grid>
            </Grid> */}
          {/* {    this.state.showQuestionFilter &&       
         <div className="courseToolbarWrapper">
              <div className="CTInputWrapper">

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={3}>
                    <div className="CTInputWrapper">
                      <label htmlFor="keywords" >{t('Keywords')}</label>

                      <div class="input-group mb-3" className="friendssearch">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1" style={{ background: "white" }}><AiOutlineSearch style={{ width: '20px', height: "20px", color: "#9c1c28" }} /></span>
                        </div>
                        <input type="text" class="form-control" aria-describedby="basic-addon1" name="searchKey" id="searchKey" placeholder={t('Search name,interest,phone number..')} value={this.state.searchKey} onKeyDown={(e)=>this.onEnter(e)} onChange={this.handleFilters}>
                        </input>
                      </div>

                    </div>
                  </Grid>


                  <Grid item xs={6} sm={6} md={2}>
                    <div className="CTInputWrapper">
                      <label htmlFor="level">{t('Program')}</label>

                      <select name="countryId" id="countryId" value={this.state.programId} onChange={this.handleFilters}>
                        <option value="0">Any</option>
                        {this.state.programDetails && this.state.programDetails.map((program) => (
                          <option value={program.curriculumId} key={program.curriculumId}>
                            {program.curriculumName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>


                  <Grid item xs={6} sm={6} md={2}>
                    <div className="CTInputWrapper">
                      <label htmlFor="level">{t('Grade')}</label>

                      <select name="cityId" id="cityId" value={this.state.filterValues.gradeId} onChange={this.handleFilters}>
                        <option value="0">Any</option>
                        {this.state.gradeDetails && this.state.gradeDetails.map((grade) => (
                          <option value={grade.gradeId} key={grade.gradeId}>
                            {grade.gradeName}
                          </option>
                        ))}
                      </select>

                    </div>
                  </Grid>


                  <Grid item xs={6} sm={6} md={2}>
                    <div className="CTInputWrapper">
                      <label htmlFor="level">Job Functions</label>

                      <select name="schoolId" id="schoolId" onChange={this.handleFilters}>
                        <option value="0">Any</option>
                        {this.props.schoolList && this.props.schoolList.map((School) => (
                          <option value={School.schoolId} key={School.schoolId}>
                            {School.schoolName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
          

                  <Grid item xs={6} sm={12} md={1}>
                    <div className="CTSearchBtn">
                      <button onClick={this.OnSearchByFilter}>{t('Search')}</button>
                    </div>
                  </Grid>

                </Grid>


              </div>

              </div>
          } */}
          {/* </div> */}
          {/* {           
        <div className="courseToolbarWrapper answerviewimginview">
              <div className="CTInputWrapper">

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={3}>
                    <div className="CTInputWrapper">
                      <label htmlFor="keywords" >{t('Search Members')}</label>

                      <div class="input-group mb-3" className="friendssearch">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1" style={{ background: "white" }}><AiOutlineSearch style={{ width: '40px', height: "20px", color: "#9c1c28" }} /></span>
                        </div>
                        <input style={{paddingLeft:"10px"}} type="text" class="form-control" aria-describedby="basic-addon1" name="searchKey" id="searchKey"  value={this.state.searchKey} onKeyDown={(e)=>this.onEnter(e)} onChange={this.handleFilters}>
                        </input>
                      </div>

                    </div>
                  </Grid>

  
                   <Grid item xs={6} sm={6} md={2}>
                    <div className="CTInputWrapper">
                      <label htmlFor="level">{t('Program')}</label>

                      <select name="countryId" id="countryId" value={this.state.programId} onChange={this.handleFilters}>
                        <option value="0">Any</option>
                        {this.state.programDetails && this.state.programDetails.map((program) => (
                          <option value={program.curriculumId} key={program.curriculumId}>
                            {program.curriculumName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>  


                  <Grid item xs={6} sm={6} md={2}>
                    <div className="CTInputWrapper">
                      <label htmlFor="level">{t('Grade')}</label>

                      <select name="cityId" id="cityId" value={this.state.filterValues.gradeId} onChange={this.handleFilters}>
                        <option value="0">Any</option>
                        {this.state.gradeDetails && this.state.gradeDetails.map((grade) => (
                          <option value={grade.gradeId} key={grade.gradeId}>
                            {grade.gradeName}
                          </option>
                        ))}
                      </select>

                    </div>
                  </Grid>  


                  <Grid item xs={6} sm={6} md={2}>
                    <div className="CTInputWrapper">
                      <label htmlFor="level">Job Functions</label>

                      <select name="schoolId" id="schoolId" onChange={this.handleFilters}>
                        <option value="0">Any</option>
                        {this.props.schoolList && this.props.schoolList.map((School) => (
                          <option value={School.schoolId} key={School.schoolId}>
                            {School.schoolName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
 

                  <Grid item xs={6} sm={12} md={1}>
                    <div className="QPSearchBtn">
                      <button onClick={this.OnSearchByFilter}>{t('Search')}</button>
                    </div>
                  </Grid>  
                </Grid>


              </div>

              </div>
          } */}
          <br />
        </div>
        {!this.state.SearchResult && !this.state.searchKey && (
          <Grid container spacing={1}>
            <>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <BlockUi
                  //style={{ minHeight: "100px",width:"152%" }}
                  tag="div"
                  blocking={this.state.blocking}
                  message={t("Loading, please wait")}
                  color="#A00030"
                >
                  <ToastContainer position="top-right" autoClose={5000} />

                  <div className="findfriends">
                    <>
                      <div className="">
                        <BlockUi
                          style={{ minHeight: "100px" }}
                          tag="div"
                          blocking={this.state.suggestedBlock}
                          message={t("Loading, please wait")}
                          color="#A00030"
                        >
                          {/* <div className="flexDefault flexAlignCenter flexJustifySpacebetween"> */}
                          <div>
                            <h3 className="listHomeTitle connection-tour-friend d-flex align-items-center">
                              {" "}
                              {t("Connections On YUUKKE")} -{" "}
                              <span className="badge badge-info ml-1">
                                {this.props.totalFriendsCount}
                              </span>
                            </h3>
                          </div>
                          {/* </div> */}
                          <InfiniteScroll
                            dataLength={this.state.FriendsList.length}
                            next={this.fetchMoreDataFriends}
                            hasMore={this.state.friendsHasMore}
                            scrollableTarget="scrollableDiv3"
                          >
                            <div
                              id="scrollableDiv3"
                              // onScroll={this.handleScroll}
                              style={{
                                minHeight: "auto",
                                maxHeight: "700px",
                                overflowY: "auto",
                                overflowX: "hidden",
                                // margin: "0px 22px 25px",
                              }}
                            >
                              {this.state.FriendsList && (
                                <FriendsOnYuukke
                                  recommendedListLength={
                                    this.state.FriendsList.length
                                  }
                                  searchvalue={this.state.value}
                                  recommended_friends_list={
                                    this.state.FriendsList
                                  }
                                  statefriendsPage={this.state.firstFriendsPage}
                                  propsfriendsPage={
                                    this.props.searchFriendsPage
                                  }
                                />
                              )}
                            </div>
                          </InfiniteScroll>
                        </BlockUi>
                      </div>
                      <div>
                        {/* <div className="communityCardWrapper  "> */}
                        {/* <div className="communityCardQuestion"> */}

                        {/* </div> */}
                      </div>
                    </>
                  </div>
                </BlockUi>
              </Grid>
            </>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <BlockUi
                style={{ minHeight: "100px" }}
                tag="div"
                blocking={this.state.requestBlock}
                message={t("Loading, please wait")}
                color="#A00030"
              >
                {/* <div className="flexDefault flexAlignCenter flexJustifySpacebetween">
                  <div>
                    <h3 className="listHomeTitle" style={{ color: "#f5f5f5" }}>
                      {t("Friends")}
                    </h3>
                  </div>
                </div> */}
                {this.state.invitationsList != undefined && (
                  <div style={{ marginTop: "12px" }}>
                    <div className="listCardWrapper cardWrapper  ">
                      <h3 className="listTitle d-flex align-items-center">
                        {t("Invitations")} -{" "}
                        <span className="badge badge-info ml-1">
                          {this.props.invitationCount}
                        </span>
                      </h3>
                      <div>
                        <InfiniteScroll
                          dataLength={this.state.invitationsList.length}
                          next={this.fetchMoreDataInvitation}
                          hasMore={this.state.requestHasMore}
                          scrollableTarget="scrollableDiv"
                        >
                          <div
                            id="scrollableDiv"
                            // onScroll={this.handleScroll}
                            style={{
                              minHeight: "auto",
                              maxHeight: "338px",
                              overflow: "auto",
                              overflowX: "hidden",
                              // margin: "0px 22px 25px",
                            }}
                          >
                            <PeopleInvitations
                              refreshFriendsList={
                                this.props.getAllCommonFriendsList
                              }
                              refreshRecentlyAddedFriends={
                                this.props.getrecentlyAddedFriends
                              }
                              acceptPageData={this.acceptSentData}
                              rejectPageData={this.rejectSentData}
                              requested_friends_list={
                                this.state.invitationsList
                              }
                              requestListLength={
                                this.props.searchRequestedFriendsPage
                              }
                              requestedPage={this.state.requestedFriendsPage}
                              searchData={this.state.searchData}
                            />
                          </div>
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                )}
              </BlockUi>

              <BlockUi
                style={{ minHeight: "100px" }}
                tag="div"
                blocking={this.state.requestBlock}
                message={t("Loading, please wait")}
                color="#A00030"
              >
                {this.state.requestList != undefined && (
                  <div style={{ marginTop: "12px" }}>
                    <div className="listCardWrapper cardWrapper connection-tour-request-other  ">
                      <h3 className="listTitle d-flex align-items-center">
                        {t("Requested Friends")}-{" "}
                        <span className="badge badge-info ml-1">
                          {this.props.requestedCount}
                        </span>
                      </h3>
                      <div>
                        {console.log(
                          "state length",
                          this.state.requestList.length
                        )}
                        <InfiniteScroll
                          dataLength={this.state.requestList.length}
                          next={() => this.fetchMoreData()}
                          hasMore={this.state.hasMore}
                          scrollableTarget="scrollableDiv"
                        >
                          <div
                            id="scrollableDiv"
                            //  onScroll={this.handleScroll}
                            style={{
                              minHeight: "auto",
                              maxHeight: "338px",
                              overflow: "auto",
                              overflowX: "hidden",
                              // margin: "0px 22px 25px",
                            }}
                          >
                            {this.state.requestList &&
                              this.state.requestList.map(
                                (friendData, index) => (
                                  <React.Fragment key={index}>
                                    <RecentlyAddedFriends
                                      workspaceType={this.props.workspaceType}
                                      removeRequest={this.removeRequest}
                                      firstname={friendData.fName}
                                      lastname={friendData.lName}
                                      userName={friendData.userName}
                                      profilePhoto={friendData.profilePhoto}
                                      userId={friendData.uId}
                                      nameLocation={
                                        friendData.location !== null
                                          ? friendData.location
                                          : "--------"
                                      }
                                      country={
                                        friendData.countryName !== null
                                          ? friendData.countryName
                                          : "--------"
                                      }
                                      profession={
                                        friendData.professionName !== null
                                          ? friendData.professionName
                                          : "--------"
                                      }
                                      tagsValue={
                                        friendData.tagsValue.length !== 0
                                          ? friendData.tagsValue
                                          : "---------"
                                      }
                                      searchData={this.state.searchData}
                                      premiumData={friendData?.premiumData}
                                    />
                                  </React.Fragment>
                                )
                              )}

                            {this.state.requestList.length == 0 && (
                              <div class="no-data">
                                <center>
                                  <div style={{ justifyContent: "center" }}>
                                    <FcConferenceCall
                                      style={{
                                        width: "55px",
                                        height: "55px",
                                        color: "#000000",
                                      }}
                                    />
                                    {this.state.requestList != undefined && (
                                      <>
                                        {this.state.userRequestedFriendsPage ==
                                          this.props
                                            .searchUserRequestedFriendsPage && (
                                          <p
                                            style={{
                                              margin: "8px",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {t(
                                              "No requested friends available"
                                            )}
                                          </p>
                                        )}
                                        {this.props
                                          .searchUserRequestedFriendsPage ==
                                          0 &&
                                          this.state
                                            .userRequestedFriendsPage !== 0 && (
                                            <p
                                              style={{
                                                margin: "8px",
                                                fontSize: "16px",
                                              }}
                                            >
                                              {t("No match found")}
                                            </p>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </center>
                              </div>
                            )}
                          </div>
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                )}
              </BlockUi>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <BlockUi
                // style={{ minHeight: "100px" }}
                tag="div"
                blocking={this.state.suggestedBlock}
                message={t("Loading, please wait")}
                color="#A00030"
              >
                <div>
                  <h3
                    className="d-flex align-items-center listHomeTitle connection-tour-suggested"
                    style={{ marginTop: "12px" }}
                  >
                    {t("Suggested Connections")}-{" "}
                    <span className="badge badge-info ml-1">
                      {this.props.recommendUsers}
                    </span>
                  </h3>

                  <InfiniteScroll
                    dataLength={this.state.recommendedList.length}
                    next={this.fetchMoreDataSuggestion}
                    hasMore={this.state.suggestHasMore}
                    scrollableTarget="scrollableDiv2"
                  >
                    <div
                      id="scrollableDiv2"
                      // onScroll={this.handleScroll}
                      style={{
                        minHeight: "auto",
                        maxHeight: "400px",
                        overflow: "auto",
                        overflowX: "hidden",
                        // overflow: "auto",
                        // margin: "0px 22px 25px",
                      }}
                    >
                      {/* <div className="peopleCarouselWrapper ">
                        <div className="searchHeading flexDefault flexAlignCenter flexJustifySpacebetween">
                          <Grid container spacing={2}>
                            {this.state.recommendedList &&
                              this.state.recommendedList.map(
                                (people, index) => {
                                  return (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      key={index}
                                    >
                                      <React.Fragment key={index}>
                                        <PeopleCard
                                          userId={people.uId}
                                          name={`${people.fName} ${people.lName}`}
                                          Dname={people.dName}
                                          country={
                                            people.countryName !== null
                                              ? people.countryName
                                              : "--------"
                                          }
                                          nameLocation={
                                            people.location != null
                                              ? people.location
                                              : "--------"
                                          }
                                          //   subjects={people.user.userLearn}
                                          biography={
                                            people.biography !== null
                                              ? people.biography
                                              : "--------"
                                          }
                                          requestSent={this.requestSentPage}
                                          ignoreSent={this.ignoreSentPage}
                                        />
                                      </React.Fragment>
                                    </Grid>
                                  );
                                }
                              )}
                          </Grid> */}
                      {this.state.recommendedList !== undefined && (
                        <>
                          {this.state.recommendedList && (
                            <PeoplesCarousel
                              ignoreSentPageData={this.ignoreSentData}
                              recommendedListLength={
                                this.state.recommendedList.length
                              }
                              requestSentPageData={this.requestSentData}
                              recommended_friends_list={
                                this.state.recommendedList
                              }
                              searchData={this.state.searchData}
                            />
                          )}{" "}
                        </>
                      )}
                      {/* </div>
                      </div> */}
                    </div>
                  </InfiniteScroll>
                </div>
              </BlockUi>

              {this.state.recommendedList.length === 0 && (
                <>
                  <div
                    className="communityCardWrapper cardWrapper"
                    style={{ padding: "40px", marginTop: "20px" }}
                  >
                    <center>
                      <div style={{ justifyContent: "center" }}>
                        <FcConferenceCall
                          style={{
                            width: "55px",
                            height: "55px",
                            color: "#000000",
                          }}
                        />
                        {this.props.searchRecommendedFriendsList !==
                          undefined && (
                          <>
                            {this.props.searchRecommendedFriendsPage ==
                              this.state.recommendedFriendsPage && (
                              <p style={{ margin: "8px", fontSize: "16px" }}>
                                {t("No suggested friends available")}
                              </p>
                            )}

                            {this.props.searchRecommendedFriendsPage == 0 &&
                              this.state.recommendedFriendsPage !== 0 && (
                                <p style={{ margin: "8px", fontSize: "16px" }}>
                                  {t("No match found")}
                                </p>
                              )}
                          </>
                        )}
                      </div>
                    </center>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        )}

        {!this.state.SearchResult && this.state.searchKey && (
          // <Grid xs={12} sm={12} md={12}>
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <BlockUi
                style={{ minHeight: "100px" }}
                tag="div"
                blocking={this.state.suggestedBlock}
                message={t("Loading, please wait")}
                color="#A00030"
              >
                <SearchPeoplesCarousel
                  ignoreSentPageData={this.ignoreSentData}
                  requestSentPageData={this.requestSentData}
                  totalcount={this.state.searchListFriends.length}
                  InSearchrequestSentPageData={this.OnSearchByFilter}
                  recommended_friends_list={this.state.searchListFriends}
                  ClearAll={this.ClearAllSearchresult}
                  pagination={this.state.pagination}
                  startPage={this.state.startPage}
                  searchPagination={this.searchPagination}
                  searchData={this.state.searchData}
                />
              </BlockUi>
            </Grid>
            {/* <br />
            <div className="myQuestionsPagination paginationWrapper">
              {" "}
              <Pagination
                activePage={this.state.searchActivePage}
                itemsCountPerPage={10}
                totalItemsCount={this.state.totalcount}
                pageRangeDisplayed={10}
                onChange={this.onChangePage}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div> */}
          </Grid>
        )}

        {/* </Container>{" "} */}
      </>
    );
  }
  searchPagination(value) {
    var page = this.state.startPage + 1;
    this.setState({
      startPage: page,
    });
    this.searchMembers(this.state.searchData, "notNext");
  }
  block(blocking) {
    this.setState({ blocking: blocking });
  }
  requestblock(blocking) {
    this.setState({ requestBlock: blocking });
  }
  suggestedblock(blocking) {
    this.setState({ suggestedBlock: blocking });
  }

  toUpCallListPage() {
    this.getAllReminders();
  }
  getAllReminders() {
    var getAllReminders = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    this.block(true);
    ProfileService.getAllReminders(getAllReminders)
      .then((response) => {
        var result = response.data;

        var stringArray = result.data;
        if (stringArray.length == 0) {
          this.setState({
            reminderListDatas: stringArray.reverse(),
            reminderListDatasState: false,
          });
        } else {
          this.setState({
            reminderListDatas: stringArray.reverse(),
            reminderListDatasState: true,
          });
        }
        this.block(false);
      })
      .catch((error) => {
        this.block(false);
        console.log(error);
      });
  }

  async ignoreSentData() {
    this.props.getRecommendedFriends();
  }
  async ignoreSentDataFriends() {
    this.props.getAllCommonFriendsList();
  }

  async requestSentData(e, f) {
    if (f === "recommend") {
      var postData = this.state.recommendedList;
      console.log("commend id :", e);
      const finalResult = postData.filter((person) => person.uId !== e);
      console.log("finalResult : ", finalResult);
      this.setState({
        recommendedList: finalResult,
      });
      this.props.searchUserRequestList("", 0);
    }
  }

  async acceptSentData(e, f, result) {
    // this.props.getFriendsRequestList();
    // this.props.getAllCommonFriendsList();
    // alert(this.props.searchFriendsPage)
    // alert(this.state.friendsPage);
    if (f === "invitation") {
      var postData = this.state.invitationsList;
      console.log("commend id :", e);
      const finalResult = postData.filter((person) => person.uId !== e);
      console.log("finalResult : ", finalResult);
      this.setState({
        invitationsList: finalResult,
      });
      if (this.props.searchFriendsPage == 0) {
        // alert("hi")
        var postData1 = this.state.FriendsList;
        console.log("commend id :", result);
        const finalResult1 = postData1.concat(result);
        console.log("finalResult : ", finalResult1);
        this.setState({
          FriendsList: finalResult1,
        });
      } else if (this.props.searchFriendsPage == 1) {
        // alert("hi")
        var postData1 = this.state.FriendsList;
        console.log("commend id :", result);
        const finalResult1 = postData1.concat(result);
        console.log("finalResult : ", finalResult1);
        this.setState({
          FriendsList: finalResult1,
        });
      } else if (this.state.friendsPage >= this.props.searchFriendsPage) {
        // alert("hi")
        var postData1 = this.state.FriendsList;
        console.log("commend id :", result);
        const finalResult1 = postData1.concat(result);
        console.log("finalResult : ", finalResult1);
        this.setState({
          FriendsList: finalResult1,
        });
      }
    }
  }
  async recentlyAddedFriends() {
    this.props.getrecentlyAddedFriends();
  }

  async rejectSentData(e, f) {
    // this.props.getFriendsRequestList();
    // this.props.getAllCommonFriendsList();
    // alert(e);
    // alert(f)
    if (f === "invitation") {
      var postData = this.state.invitationsList;
      console.log("commend id :", e);
      const finalResult = postData.filter((person) => person.uId !== e);
      console.log("finalResult : ", finalResult);
      this.setState({
        invitationsList: finalResult,
      });
    }
  }
  getFriendsList() {}

  onRecentSearchList() {
    // let getSearchedList = {};
    // FriendsFinder.onRecentSearchList(getSearchedList)
    //   .then((response) => {
    //     var result = response.data;
    //     this.setState({ searchList: result.data.searchList });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }
  getAllCountry() {
    FindFriendsFilter.getAllCountry()
      .then((response) => {
        var result = response.data;
        console.log(result);
        this.setState({ countryList: result.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAllProgram() {
    this.setState({
      programDetails: this.props.curriculumtrackList.curriculum,
    });
  }
  closeInvitePage() {
    this.setState({
      invitePopup: false,
    });
  }
  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;

        this.setState({ gradeDetails: result.data.grade });
      })
      .catch((error) => {
        console.log(error);
      });
    // let getGradeByProgramById = {
    //   "programId": programId
    // }

    // ProfileService.getGradeByProgramById(getGradeByProgramById)
    //   .then(
    //     response => {
    //       console.log(response.data.data.gradeListById)
    //       var result = response.data.data.gradeListById;
    //       this.setState({ gradeDetails: result })
    //     }
    //   )
    //   .catch(error => {
    //     console.log(error)
    //   }
    //   )
  }

  handleFilters(e) {
    var filters = this.state.filterValues;
    filters[e.target.name] = e.target.value;
    console.log(e.target.value);
    if (e.target.name == "countryId") {
      if (e.target.value == "0") {
        this.setState({
          GradeList: [],
        });
        this.state.filterValues.countryId = 0;
        this.state.filterValues.cityId = 0;
        this.state.filterValues.schoolId = 0;
        this.state.filterValues.classId = 0;
        this.state.filterValues.languageId = 0;
      }
    }
    if (e.target.name == "countryId") {
      var grade = [];
      grade.push(e.target.value);
      //  this.getallGrade(grade)
    }
    if (e.target.name == "schoolId") {
      this.LoadClassesBySchoolId(e.target.value);
    }
    this.setState({
      filterValues: filters,
    });
  }
  LoadCityByCountryId(countryId) {
    if (countryId) {
      var country = {
        countryId: countryId,
      };
      FindFriendsFilter.getallCityByCountryId(country)
        .then((response) => {
          var result = response.data;
          this.setState({
            CityList: result.data,
          });
        })
        .catch();
    } else {
      this.setState({
        CityList: [],
      });
    }
  }

  LoadClassesBySchoolId(schoolId) {
    if (schoolId) {
      var school = {
        schoolId: schoolId,
      };
      FindFriendsFilter.getallClassBySchoolId(school)
        .then((response) => {
          var result = response.data;
          this.setState({
            ClassList: result.data,
          });
        })
        .catch();
    } else {
      this.setState({
        ClassList: [],
      });
    }
  }

  OnSearchByFilter(page) {
    if (
      !this.state.filterValues.countryId ||
      this.state.filterValues.countryId == "0"
    ) {
      this.state.filterValues.countryId = 0;
    }
    if (
      !this.state.filterValues.cityId ||
      this.state.filterValues.cityId == "0"
    ) {
      this.state.filterValues.cityId = 0;
    }
    if (
      !this.state.filterValues.schoolId ||
      this.state.filterValues.schoolId == "0"
    ) {
      this.state.filterValues.schoolId = 0;
    }
    if (
      !this.state.filterValues.classId ||
      this.state.filterValues.classId == "0"
    ) {
      this.state.filterValues.classId = 0;
    }
    if (
      !this.state.filterValues.languageId ||
      this.state.filterValues.languageId == "0"
    ) {
      this.state.filterValues.languageId = 0;
    }
    if (!this.state.filterValues.searchKey) {
      this.state.filterValues.searchKey = "";
    }
    this.state.filterValues.page = this.state.searchpage - 1;
    this.state.filterValues.userId = this.props.loginDetails.uId;

    console.log(this.state.filterValues);
    if (
      this.state.filterValues.countryId ||
      this.state.filterValues.cityId ||
      this.state.filterValues.schoolId ||
      this.state.filterValues.classId ||
      this.state.filterValues.languageId ||
      this.state.filterValues.searchKey
    ) {
      FindFriendsFilter.getListByFilter(this.state.filterValues)
        .then((response) => {
          var result = response.data;
          console.log(result);
          if (result.data.user) {
            this.setState({
              SearchResultList: result.data.user,
              SearchResult: true,
              totalcount: result.data.totalItems,
            });
          } else {
            this.setState({
              SearchResultList: [],
              SearchResult: true,
              totalcount: 0,
            });
          }
        })
        .catch();
    } else {
      this.setState({
        SearchResultList: [],
        totalcount: 0,
      });
    }
  }
  onChangePage(page) {
    this.setState(
      {
        searchpage: page,
        searchActivePage: page,
      },
      () => {
        this.OnSearchByFilter();
      }
    );
  }
  ClearAllSearchresult() {
    const { t } = this.props;
    // this.setState({
    //   searchKey: false,
    //   searchData: "" ,
    // });
    this.setState({
      searchKey: false,
      searchData: "",
      placeholder: t(
        "Search all users using name, location, country and area of interest..."
      ),
      // recommendedList: this.props.searchRecommendedFriendsList,
      // requestList: this.props.searchUserRequestedFriendsList,
      // invitationsList: this.props.searchRequestedFriendsLists,
      // FriendsList: this.props.searchFriendsList,
      recommendedList: this.props.searchRecommendedFriendsList,
      requestList: this.props.searchUserRequestedFriendsList,
      invitationsList: this.props.searchRequestedFriendsLists,
      FriendsList: this.props.searchFriendsList,
    });
  }
  onEnter(e) {
    if (e.target.value && e.key == "Enter") {
      this.OnSearchByFilter(e);
    } else if (e.key == "Enter") {
      this.setState({
        SearchResult: false,
      });
    }
  }
  showQuestionFilter() {
    this.setState({
      showQuestionFilter: !this.state.showQuestionFilter,
    });
  }
  removeRequest = (id) => {
    let data = this.state.requestList.filter((list) => list.uId !== id);
    this.setState({
      requestList: data,
    });
    let config = {
      friendsId: id,
    };
    FindFriendsFilter.friendRequestCancel(config)
      .then((response) => {
        console.log(response);
        toast.success(
          `${this.props.t("Friend request cancelled successfully")}`
        );
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("cc", data);
  };
  async searchMembers(value, type) {
    this.setState({ searchData: value });
    let data = [];
    let tags = [];
    if (this.state.friendsTabIndex === 0) {
      //All
      if (value.length > 0) {
        if (type === "next") {
          await this.setState({
            searchValues: value,
            searchKey: true,
            startPage: 0,
          });
        } else {
          await this.setState({
            searchValues: value,
            searchKey: true,
          });
        }
      } else {
        await this.setState({
          searchValues: value,
          searchKey: false,
        });
      }
      let search;
      if (value !== null) {
        search = {
          searchKey: value,
          page: this.state.startPage,
          type: "allUser",
        };
      } else {
        search = {
          searchKey: "",
          type: "allUser",
        };
      }
      console.log("search", search);
      this.setState({
        suggestedBlock: true,
      });
      setTimeout(() => {
        MyYuukke.searchAllUsers(search)
          .then((response) => {
            var result = response.data;
            var stringArray = result.data.searchList;
            var pagination = result.data.totalPages;
            this.setState({
              pagination: pagination,
            });
            this.usersList(stringArray);
          })

          .catch((error) => {
            console.log(error);
          });
        this.setState({
          suggestedBlock: false,
        });
      }, 1000);
    } else if (this.state.friendsTabIndex === 1) {
      //Only connection
      await this.setState({
        searchKey: false,
        friendsPage: 0,
        value: value,
      });

      this.props.searchFriends(value, this.state.friendsPage);
      this.allSearchPage();
      // if (value.length > 0) {
      //   console.log("flist", this.state.FriendsListSearch);
      //   for (var i = 0; i < this.state.FriendsListSearch.length; i++) {
      //     var subject = this.state.FriendsListSearch[i];
      //     if (
      //       subject.userName
      //         .toUpperCase()
      //         .trim()
      //         .startsWith(value.toUpperCase())
      //     ) {
      //       data.push(subject);
      //     }

      //   }
      //   this.setState({ FriendsList: data });
      // } else {
      // this.setState({ FriendsList: this.props.searchFriendsList });
      // }
      console.log("flist", this.props.searchFriendsList);
    } else if (this.state.friendsTabIndex === 2) {
      //  SUGGESTED FRIENDS
      await this.setState({
        searchKey: false,
        recomendedPage: 0,
        value: value,
      });
      this.props.searchRecomentedFriendsList(value, this.state.recomendedPage);
      this.allSearchPage();
      // let Invitations = [];
      // if (value.length > 0) {
      //   for (var j = 0; j < this.state.recommendedListSearch.length; j++) {
      //     var subjects = this.state.recommendedListSearch[j];
      //     if (
      //       subjects.userName
      //         .toUpperCase()
      //         .trim()
      //         .startsWith(value.toUpperCase())
      //     ) {
      //       Invitations.push(subjects);
      //     }
      //   }

      //   this.setState({ recommendedList: Invitations });
      // } else {
      //   this.setState({
      //     recommendedList: this.props.searchRecommendedFriendsList,
      //   });
      // }
      // console.log("recommend list", this.state.recommendedList);
    } else if (this.state.friendsTabIndex === 3) {
      //Invitations
      await this.setState({
        searchKey: false,
        requestedPage: 0,
        value: value,
      });
      this.props.searchRequestedFriendsList(value, this.state.requestedPage);
      this.allSearchPage();
      // var Invitations = [];
      // if (value.length > 0) {
      //   console.log("123", value);
      //   for (var i = 0; i < this.state.searchInvitations.length; i++) {
      //     var subjects = this.state.searchInvitations[i];
      //     if (
      //       subjects.userName
      //         .toUpperCase()
      //         .trim()
      //         .startsWith(value.toUpperCase())
      //     ) {
      //       Invitations.push(subjects);
      //     }

      //     this.setState({ invitationsList: Invitations });
      //     console.log("123", value);
      //   }
      // } else {
      //   this.setState({
      //     invitationsList: this.props.searchRequestedFriendsLists,
      //   });
      // }
    } else if (this.state.friendsTabIndex === 4) {
      // alert("welcome");
      //Requested  requestListSearch
      await this.setState({
        searchKey: false,
        userRequestPage: 0,
        value: value,
      });

      // if (value.length > 0) {
      //   for (var i = 0; i < this.state.requestListSearch.length; i++) {
      //     var subject = this.state.requestListSearch[i];
      //     if (
      //       subject.userName.toUpperCase().startsWith(value.toUpperCase()) !==
      //       null
      //     ) {
      //       if (
      //         subject.userName
      //           .toUpperCase()
      //           .trim()
      //           .startsWith(value.toUpperCase())
      //       ) {
      //         data.push(subject);
      //       }
      //     }
      //   }
      //   this.setState({ requestList: data });
      this.props.searchUserRequestList(value, this.state.userRequestPage);
      this.allSearchPage();
      // if (this.state.userRequestPage > 0) {
      //   this.setState({
      //     requestList: this.state.requestList.concat(
      //       this.props.searchUserRequestedFriendsList
      //     ),
      //     hasMore: false,
      //   });
      // } else {
      //   alert("well");
      //   this.setState({
      //     requestList: this.props.searchUserRequestedFriendsList,
      //   });
      // }

      // }
      // console.log("invi r", this.props.userRequestedList);
    }
  }
  async usersList(stringArray) {
    let tags = [];
    var value = this.state.searchValues;

    if (stringArray.length > 0) {
      if (this.state.startPage > 0) {
        var searchList = this.state.searchListFriends;
        var list = searchList.concat(stringArray);
        this.setState({
          searchListFriends: list,
          serach: false,
        });
      } else {
        this.setState({
          searchListFriends: stringArray,
          serach: false,
        });
      }
    } else {
      this.setState({
        searchListFriends: [],
        serach: true,
      });
    }
  }

  inviteUser() {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      this.setState({
        invitePopup: true,
      });
    }
  }

  emailInvite(value) {
    console.log("value", value);
    MyYuukke.getReportEmail(value)
      .then((response) => {
        toast.success("User invited successfully");
        this.setState({
          items: [],
          emailVerification: [],
          value: "",
        });
        this.closeInvitePage();
      })
      .catch((error) => {
        toast.error("Error in saving issue");
        this.closeInvitePage();
      });
  }
}

const mapStateToProps = function (state) {
  console.log("Loading recommended friend Props");
  return {
    recommendUsers: state.PeerPageFriendsList.recommendUsers,
    requestedList: state.PeerPageFriendsList.friendsrequestlist,
    recommendedList: state.PeerPageFriendsList.recommendedfriendsList,
    recentlyAddedFriendsList:
      state.PeerPageFriendsList.recentlyaddedfriendsList,
    // FriendsList: state.commonApi.friendslist,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    schoolList: state.commonApi.schoollist,
    languageList: state.commonApi.languagelist.data,
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
    userRequestedList: state.PeerPageFriendsList.userRequestedList,
    searchFriendsList: state.PeerPageFriendsList.searchFriendsList,
    searchRequestedFriendsLists:
      state.PeerPageFriendsList.searchRequestedFriendsList,
    searchUserRequestedFriendsList:
      state.PeerPageFriendsList.searchUserRequestedFriendsList,
    searchRecommendedFriendsList:
      state.PeerPageFriendsList.searchRecommendedFriendsList,
    searchUserRequestedFriendsPage:
      state.PeerPageFriendsList.searchUserRequestedFriendsPage,
    searchRequestedFriendsPage:
      state.PeerPageFriendsList.searchRequestedFriendsPage,
    searchRecommendedFriendsPage:
      state.PeerPageFriendsList.searchRecommendedFriendsPage,
    searchFriendsPage: state.PeerPageFriendsList.searchFriendsPage,
    toursStages: state.commonApi.connectionPopupStatus,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    totalFriendsCount: state.PeerPageFriendsList.totalFriendsCount,
    invitationCount: state.PeerPageFriendsList.invitationCount,
    requestedCount: state.PeerPageFriendsList.requestedCount,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
    triggerTourPopup: (slug) => dispatch(triggerTourPopup(slug)),
    getFriendsRequestList: () => dispatch(getFriendsRequestList()),
    getRecommendedFriends: () => dispatch(getRecommendedFriends()),
    getFriendsList: () => dispatch(getFriendsList()),
    getrecentlyAddedFriends: () => dispatch(getrecentlyAddedFriends()),
    getAllCommonFriendsList: () => dispatch(getAllCommonFriendsList()),
    getUserRequestList: () => dispatch(getUserRequestList()),
    searchFriends: (value, page) => dispatch(searchFriends(value, page)),
    searchRequestedFriendsList: (value, page) =>
      dispatch(searchRequestedFriendsList(value, page)),
    searchUserRequestList: (value, page) =>
      dispatch(searchUserRequestList(value, page)),
    searchRecomentedFriendsList: (value, page) =>
      dispatch(searchRecomentedFriendsList(value, page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(Home)));
