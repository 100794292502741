import React, { Component } from "react";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import AuthService from "../../session/AuthService";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import * as APPCONFIG from "../../config/app-config.js";
import dummyimage from "../../img/banner.jpg";
import { withRouter } from "react-router";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import MyYuukke from "../../api/MyYuukke";
import Swal from "sweetalert2";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

class MyEventCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 0,
      topic_name: "",
      membersCount: "",
      fileData: [],
      showMenu: null,
      selectedMenus: [],
    };

    this.movesubjectidtosubjects = this.movesubjectidtosubjects.bind(this);
    this.getTagList = this.getTagList.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.editGroup = this.editGroup.bind(this);
    this.deletePost = this.deletePost.bind(this)
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.getMessageFiles(this.props.grouporeventFiles, this.props.fileType);
    this.getTagList(this.props.tagListId)
  }

  componentWillReceiveProps(nextProps) {
    this.getMessageFiles(nextProps.grouporeventFiles, nextProps.fileType);
    this.getTagList(nextProps.tagListId)
  }


  handleMenuClick(event, userId) {
    this.setState({ menuState: event.target });

  }
  handleMenuClose() {
    this.setState({
      menuState: false,
    });
  }

  render() {
    const { t } = this.props;
    const {
      subjectId,
      subjectName,
      questionsCount,
      createdDate,
      expiryDate,
      memberShipPrice,
      memberShipType,
      membersCount,
      fileType,
      grouporeventFiles,
      subjectDescription,
      displayName,
      userId,
      startTime,
      endTime,
      eventLocation,
      tagListId,
      startDate,
      type,
      sideMenu
    } = this.props;
    return (

      <div

        style={{ cursor: "pointer" }}
        
      >
        {!sideMenu && (
          <div className="cardWrapper updatesCardWrapperPR" style={{ cursor: "pointer", padding: "8px" }}
            >
            <Grid container spacing={1} >
              <Grid item lg={2} md={2} sm={2} xs={2} >
                <center><div  >
                  {this.state.fileData
                    .slice(0, 1)
                    .map((photo) => (
                      <img
                        className=""
                        style={{ width: "40px", height: "40px" }}
                        src={photo ? photo : dummyimage}
                        alt="user"

                      />
                    ))}
                </div></center>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8} >

                <div onClick={() =>
              this.movesubjectidtosubjects(
                subjectId,
                subjectName,
                grouporeventFiles,
                fileType,
                subjectDescription,
                memberShipPrice,
                memberShipType,
                expiryDate,
                createdDate,
                displayName,
                userId,
                startTime,
                endTime,
                eventLocation,
                tagListId,
                startDate,
              )
            }> 
                  <p style={{ fontSize: "18px", color: "#F97516", fontWeight: "bold" }}>{subjectName}</p>


                  <p style={{ fontSize: "12px", color: "#000000", height: "36px", paddingTop: "4px" }}>{this.state.questionTags ? this.state.questionTags.substring(0, 40) : "-"} {(this.state.questionTags && this.state.questionTags.length > 39) && "..."}</p>

                  {memberShipType === "free" ? (
                    <p style={{ fontSize: "10px", color: "#000000", paddingBottom: "10px" }}>FREE</p>
                  ) : (
                    <p style={{ fontSize: "10px", color: "#000000", display: "inline-flex" }}>{"Rs." + memberShipPrice + " per head"}</p>
                  )}
                </div>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2} s >
                <div className="postMoreInfo" style={{ cursor: "pointer",float:"right" }}>
                  <MoreHorizIcon
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => {
                      this.handleMenuClick(e);
                    }}
                  />     <Menu
                    id="simple-menu"
                    anchorEl={this.state.menuState}
                    keepMounted
                    open={this.state.menuState}
                    onClose={this.handleMenuClose}
                  >
                    <div
                      className="menuCard"
                      onClick={() => this.editGroup()}
                    >
                      <Edit style={{ cursor: "pointer", marginTop: "6px" }} />{" "}

                      <MenuItem>{t("Edit")}</MenuItem>
                    </div>
                    <div
                      className="menuCard"
                      onClick={(e) => this.deletePost(e, this.props.subjectId)}
                    >
                      <Delete tyle={{ cursor: "pointer", marginTop: "6px" }} />{" "}

                      <MenuItem>{t("Delete")}</MenuItem>
                    </div>
                  </Menu>
                </div>

              </Grid>
            </Grid>
          </div>
          //  <div  className="upcomingCardWrapper"> 
          // <div className="insideupcomingCardWrapper">
          //   <Grid container spacing={1} style={{ float: "right" }}>
          //     <Grid item lg={5} md={5} sm={5} xs={5}>
          //       <Grid item lg={14} md={14} sm={14} xs={14}>
          //         {memberShipType === "free" ? (
          //           <p style={{ paddingTop: "7%", paddingLeft: "6% ", float: "left", fontSize: "20px", fontWeight: "bold" }}>FREE</p>
          //         ) : (
          //           <p style={{ paddingTop: "7%", paddingLeft: "6% ", fontSize: "17px", fontWeight: "bold", display: "inline-flex" }}>{"Rs." + memberShipPrice + " per head"}</p>
          //         )}
          //       </Grid>
          //     </Grid>
          //     <Grid item lg={3} md={3} sm={3} xs={3}></Grid>

          //     <Grid item lg={4} md={4} sm={4} xs={4}>
          //       <p
          //         style={{
          //           padding: "12px",
          //           float: "right",
          //           color: "#6d064e",
          //         }}
          //       >
          //         {expiryDate}
          //       </p>
          //     </Grid>
          //   </Grid>

          //   {this.state.fileData &&
          //     this.state.fileData
          //       .slice(0, 1)
          //       .map((photo) => (
          //         <img src={photo}   className="bannerEventImg" />
          //       ))}
          //   {this.props.event ? (
          //     <></>
          //   ) : (
          //     <p className="engageLabel">{t("Engage with other people on")}</p>
          //   )}
          //   <div className="subjectTitleUE flexDefault">
          //     {this.props.event ? (
          //       <div
          //         className="subjectNameUE"
          //         style={{ "white-space": "normal" }}
          //       >
          //         <p className="subjectNameUE">{subjectName}</p>
          //         <p style={{ padding: "12px" }}>{this.state.questionTags ? this.state.questionTags.substring(0, 40) : "-"} {(this.state.questionTags && this.state.questionTags.length > 39) && "..."}</p>

          //       </div>
          //     ) : (
          //       <div className="subjectNameUE">
          //         <p className="subjectNameUE">{subjectName}</p>
          //       </div>
          //     )}
          //   </div>
          //   <div>
          //     <Grid
          //       className="borderLineUE"
          //       container
          //       spacing={1}
          //       style={{ float: "right" }}
          //     >          
          //       <Grid item lg={9} md={9} sm={9} xs={9}></Grid>
          //       <Grid item lg={3} md={3} sm={3} xs={3} >
          //         <div className="boxcolorRed">
          //           <p style={{ color: "#ffffff", paddingLeft: "28%", fontSize: "15px" }}>View</p>
          //         </div>
          //       </Grid>
          //     </Grid>
          //   </div></div>
          //  </div>

        )}

        {sideMenu && (
          <div className="subjectTitleUE " style={{ paddingTop: "12px" }} onClick={() =>
            this.movesubjectidtosubjects(
              subjectId,
              subjectName,
              grouporeventFiles,
              fileType,
              subjectDescription,
              memberShipPrice,
              memberShipType,
              expiryDate,
              createdDate,
              displayName,
              userId,
              startTime,
              endTime,
              eventLocation,
              tagListId,
              startDate,
              type
            )
          }>
            <Grid container spacing={1}>
              {/* <Grid item lg={2} sm={2} md={2} xs={2}>
                {this.state.fileData
                  .slice(0, 1)
                  .map((photo) => (
                    <img
                      className=""
                      style={{ width: "55px", height: "40px" }}
                      src={photo ? photo : dummyimage}
                      alt="user"

                    />
                  ))}
              </Grid> */}
              <Grid item lg={10} sm={10} md={10} xs={10}>
                <p className="" style={{ color: "#000000", paddingRight: "0px" }}>
                  {subjectName}
                </p>
                {/* <div className="followCount">
                  <div className="">
                    <p class="followFriends">{membersCount}  in this Event</p>
                  </div>

                </div> */}
              </Grid>
            </Grid>
          </div>)}
      </div>
    );
  }

  editGroup() {
    this.props.history.push(`/updateEvent/${this.props.subjectId}`);
  }

  deletePost(event, subId) {

    event.preventDefault();
    event.stopPropagation();

    Swal.fire({
      title: "Are you sure to delete this event?",
      text: "",
      icon: 'warning',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        var deleteSubId = {
          subjectId: subId,
        };
        MyYuukke.deleteSubjectById(deleteSubId)
          .then((response) => {
            this.props.getEventsList()
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })

  }

  getTagList(tagList) {
    console.log("tagList")
    console.log(tagList)
    console.log(this.props.tagList)
    var subjectName = new Array()
    if (tagList.length > 0) {
      for (let i = 0; i < tagList.length; i++) {
        for (let j = 0; j < this.props.tagList.data.length; j++) {
          if (tagList[i].tagId == this.props.tagList.data[j].tagId) {

            subjectName.push(this.props.tagList.data[j].tagName)
          }
        }
        var subjects = subjectName.join(",")

        this.setState({ questionTags: subjects })

      }
    }
  }
  async getMessageFiles(files, type) {
    if (type == "image") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
        }
        await this.setState({ fileData: fileList });
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "text") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(dummyimage);
        }

        await this.setState({ fileData: fileList });
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "video") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/video/${files[i].fileName}`);
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "pdf") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "file") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else {
      let fileList = [];
      if (files.length > 0) {
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    }
  }

  movesubjectidtosubjects(
    subjectId,
    subjectName,
    grouporeventFiles,
    fileType,
    subjectDescription,
    memberShipPrice,
    memberShipType,
    expiryDate,
    createdDate,
    displayName,
    userId,
    startTime,
    endTime,
    eventLocation,
    tagListId,
    startDate,
    type
  ) {
    this.props.gotoSubjects(
      subjectId,
      true,
      subjectName,
      "mevent",
      grouporeventFiles,
      fileType,
      subjectDescription,
      memberShipPrice,
      memberShipType,
      expiryDate,
      createdDate,
      displayName,
      userId,
      startTime,
      endTime,
      eventLocation,
      tagListId,
      startDate,
      type
    );
  }

  // saveOrUpdateMemberList(date, userName, subjectId, memberShipPrice) {
  //   let memberList = {
  //     userName: userName,
  //     joinDate: date,
  //     groupsOrEvents: subjectId,
  //     paymentAmount: memberShipPrice,
  //   };
  //   MyYuukke.saveMemberList(memberList)
  //     .then((response) => {})
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // MembersCount(subjectId) {
  //   let membersCount = {
  //     subjectId: subjectId,
  //   };
  //   MyYuukke.getMembersCount(membersCount)
  //     .then((response) => {
  //       var result = response.data;
  //       console.log("membersCount :", result.data[0].membersCount);

  //         this.setState({
  //           membersCount: result.data[0].membersCount,
  //         });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
}

// export default withTranslation()(MyEventCard);
const mapStateToProps = function (state) {
  return {
    tagList: state.commonApi.taglist,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(MyEventCard)));