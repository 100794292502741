import React, { Component } from "react";
import UserImg from "../../../components/UserImg";
import { Button, Form, Modal, InputGroup, FormControl } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import AuthService from "../../../session/AuthService";
import * as imageConversion from "image-conversion";
import choosePhoto from "../../../img/profile/choosePhoto.png";
import ProfileService from "../../../api/ProfileService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import dummyimage from "../../../img/dummyimage.png";
import LoadService from "../../../utils/LoadService";
import FriendsFinder from "../../../api/FriendsFinder";
import { connect } from "react-redux";
import { storeLoginDetails } from "../../../redux/actions/loginDetailsActions";

class FriendBgBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addInterestState: false,
      file: "",
      image: "",
      coverPhotoState: false,
      friendId: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onImagePreview = this.onImagePreview.bind(this);
    this.editPhoto = this.editPhoto.bind(this);
    this.editCoverPhoto = this.editCoverPhoto.bind(this);
    this.popUpProfilePhoto = this.popUpProfilePhoto.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.profilePhotoUpdate = this.profilePhotoUpdate.bind(this);
    this.coverPhotoUpdate = this.coverPhotoUpdate.bind(this);
    this.handleCoverClose = this.handleCoverClose.bind(this);
    this.unFriend = this.unFriend.bind(this);
    this.unFriendId = this.unFriendId.bind(this);
    this.UnFriendhandleClose = this.UnFriendhandleClose.bind(this);
    this.popUpUnfriend = this.popUpUnfriend.bind(this);
  }
  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }
  render() {
    const { coverPhoto, userId, profilePhoto, userName } = this.props;
    return (
      <div className="profieBannerWrapper">
        <img src={coverPhoto ? coverPhoto : dummyimage} alt="Cover Photo"></img>

        <div className="profilePictureEditProfile flexDefault flexJustifySpacebetween">
          <UserImg size="extraLarge" imgSrc={profilePhoto} imgAlt={userName} />
          <div style={{ position: "absolute", right: "0", bottom: "0" }}></div>
        </div>
        {this.state.unfriendState && this.popUpUnfriend()}
        {this.state.addInterestState && this.popUpProfilePhoto()}
        {this.state.coverPhotoState && this.popUpCoverPhoto()}
      </div>
    );
  }
  unFriendId(fid) {
    this.setState({
      unfriendState: true,
      friendId: fid,
    });
  }
  unFriend(e) {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
    } else {
      let unFriend = {
        userId: this.props.loginDetails.uId,
        friendsId: this.props.userId,
      };
      console.log(unFriend);
      FriendsFinder.unfriendHit(unFriend)
        .then((response) => {
          this.UnFriendhandleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  popUpUnfriend() {
    return (
      <div>
        <Modal show="true" onHide={this.UnFriendhandleClose}>
          <Modal.Header>Unfriend</Modal.Header>

          <Form
            id="profileForm"
            noValidate
            validated={this.state.validated}
            onSubmit={this.unFriend}
          >
            <Modal.Body>
              <Form.Group className="formInput">
                <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                  Are you sure to unfriend ?
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={this.UnFriendhandleClose}
              >
                Remove
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
  UnFriendhandleClose() {
    this.setState({ unfriendState: false });
  }
  editPhoto() {
    this.setState({ addInterestState: true });
  }
  handleClose() {
    this.setState({ addInterestState: false });
  }
  editCoverPhoto() {
    this.setState({ coverPhotoState: true });
  }
  handleCoverClose() {
    this.setState({ coverPhotoState: false });
  }
  popUpProfilePhoto() {
    const { t } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Modal show="true" onHide={this.handleClose}>
          <Modal.Header>{t("Edit My Avatar")}</Modal.Header>
          <div style={{ margin: "12px" }}>
            <label>{t("Photo")}</label>
            <div className="choosePhoto d-flex">
              <div className="choosePhotoImg">
                {this.state.image && (
                  <img id="target" alt="photo" src={this.state.image} />
                )}
                {!this.state.image && (
                  <img src={choosePhoto} alt="Choose photo" />
                )}
              </div>
            </div>
            <Form
              id="profileForm"
              noValidate
              validated={this.state.validated}
              onSubmit={this.profilePhotoUpdate}
            >
              <Form.Control
                type="file"
                name="file"
                onChange={(e) => this.onChange(e)}
              />
              <hr />
              <div className="profileSubmitBtn aboutSubmitBtn">
                <Button
                  variant="secondary"
                  style={{ marginRight: "8px" }}
                  onClick={this.handleClose}
                >
                  {t("Close")}
                </Button>
                <Button type="submit" className="btnBlue">
                  {t("Update Avatar")}
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }

  popUpCoverPhoto() {
    const { t } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Modal show="true" onHide={this.handleClose}>
          <Modal.Header>{t("EDIT MY COVER PHOTO")}</Modal.Header>
          <div style={{ margin: "12px" }}>
            <label>{t("Photo")}</label>
            <div className="choosePhoto d-flex">
              <div className="choosePhotoImg">
                {this.state.image && (
                  <img id="target" alt="photo" src={this.state.image} />
                )}
                {!this.state.image && (
                  <img src={choosePhoto} alt="Choose photo" />
                )}
              </div>
            </div>
            <Form
              id="profileForm"
              noValidate
              validated={this.state.validated}
              onSubmit={this.coverPhotoUpdate}
            >
              <Form.Control
                type="file"
                name="file"
                onChange={(e) => this.onChange(e)}
              />
              <hr />
              <div className="profileSubmitBtn aboutSubmitBtn">
                <Button
                  variant="secondary"
                  style={{ marginRight: "8px" }}
                  onClick={this.handleCoverClose}
                >
                  {t("Close")}
                </Button>
                <Button type="submit" className="btnBlue">
                  {t("Update Photo")}
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
  profilePhotoUpdate(e) {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData();

    formData.append("profilePhoto", this.state.file);
    const config = { headers: { "content-type": "multipart/form-data" } };
    ProfileService.updatePhoto(formData, config)
      .then((response) => {
        var result = response.data;

        AuthService.registerAuth("", result.data);
        this.props.storeLoginDetails(
          result.data,
          this.props.authUser.loginType
        );
        this.props.getUserPhoto(result.data.userPhoto);

        this.handleClose();

        LoadService.redirect(this.props.history, "/my-profile");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  coverPhotoUpdate(e) {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData();

    formData.append("profilePhoto", this.state.file);
    const config = { headers: { "content-type": "multipart/form-data" } };
    ProfileService.updateCoverPhoto(formData, config)
      .then((response) => {
        var result = response.data;

        AuthService.registerAuth("", result.data);
        this.props.storeLoginDetails(
          result.data,
          this.props.authUser.loginType
        );
        this.props.getCoverPhoto(result.data.coverPhoto);

        this.handleCoverClose();
        LoadService.redirect(this.props.history, "/my-profile");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onChange(e) {
    let files = e.target.files[0];

    if (files.size > 200000) {
      imageConversion
        .compressAccurately(files, {
          size: 200,
          maxwidth: 300,
          maxheight: 200,
          orientation: 2,
          scale: 0.5,
        })
        .then((res) => {
          this.setState({ file: res });
        });
    } else {
      this.setState({ file: files });
    }

    this.onImagePreview(e);
  }

  onImagePreview(event) {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        image: URL.createObjectURL(event.target.files[0]),
      });
    }
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    storeLoginDetails: (loginDetails, type) =>
      dispatch(storeLoginDetails(loginDetails, type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(FriendBgBanner));
