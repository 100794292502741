import React, { Component } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
export default class CustomToggleClassroomMyLibrary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValue: this.props.activeValue,
    };

    this.handleChangeButton = this.handleChangeButton.bind(this);
  }
  handleChangeButton(event, newValue) {
    if (newValue) this.setState({ initialValue: newValue });

    this.props.choosedValue(newValue)
  }
  render() {
    const { initialValue } = this.state;
    return (
      <div className="toggleButtonWrapper" style={{ textAlign: "left" }}>
        <ToggleButtonGroup
          size="large"
          value={initialValue}
          exclusive
          onChange={this.handleChangeButton}
        >

          <ToggleButton size="large" value="All" >
            <ImportContactsIcon />
          </ToggleButton>
          <ToggleButton size="large" value="Star" >
            <StarOutlineIcon />
          </ToggleButton>

        </ToggleButtonGroup>
      </div>
    );
  }

}
