import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "react-placeholder/lib/reactPlaceholder.css";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import PostService from "../../api/PostService_old";
import MentorsCardBody from "./MentorsCardBody";
import AuthService from "../../session/AuthService";
import i18n from "../../i18n";
import RequestandAcceptedList from "./RequestandAcceptedList";
import { getSingleMentor } from "../../redux/actions/subjectActions";
import BlockUi from "react-block-ui";
import ScheduledCard from "./ScheduledCard";

class Mentor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFieldValue: "",
      mentorsList: [],
      amount: "",
      communicationType: "",
      companyName: "",
      description: "",
      email: "",
      faq: "",
      firstName: "",
      mediaFiles: [],
      mentorId: "",
      owner: "",
      paymentType: "",
      phoneNumber: "",
      specialization: [],
      time: "",
      userId: "",
      type: "",
      descriptionPage: false,
      openFAQPageState: false,
      openRequestListPage: false,
      openAcceptedListPage: false,
      openDescriptionPageState: true,
      openTimingPageState: false,
      requestedList: [],
      acceptedList: [],
      specialization_value: [],
      timingList: [],
      experience: "",
      language: "",
      openMeetingList: false,
    };
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.props.getSingleMentor(+this.props.match.params.id);
  }

  componentDidUpdate(prevProps, nextProps) {
    if (this.props.mentorList !== prevProps.mentorList) {
      // this.setState({ mentorsList: this.props.mentorList });
    }
  }

  render() {
    const { t, mentorDetails } = this.props;

    return (
      <BlockUi
        tag="div"
        message={t("Loading, please wait")}
        style={{ minHeight: "90px" }}
        className="wallLoadingPage"
      >
        {mentorDetails?.mentorId && (
          <Grid container spacing={1}>
            <Grid item sm={12} xs={12} lg={12} md={12}>
              <div
                style={{
                  marginRight: "12px",
                  marginBottom: "12px",
                  marginTop: "9px",
                }}
                className="ansBackQuestion flexDefault uploader"
                onClick={this.backToCard}
              >
                <ArrowBackIcon />
                <p>{t("Back to Mentors List")}</p>
              </div>
              <div class="groupCard">
                <MentorsCardBody
                  updateRequestOption={this.updateRequestOption}
                />

                <div class="main-card-footer mt-3 pt-2 pb-0">
                  <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
                    <li class="nav-item">
                      {" "}
                      <a
                        style={{
                          float: "left",
                          cursor: "pointer",
                          marginBottom: "0.5rem",
                        }}
                        class={
                          this.state.openDescriptionPageState
                            ? "nav-link active nav-link-body-sidemenu-active"
                            : "nav-link nav-link-body"
                        }
                        onClick={this.groupCardDescriptionPage}
                      >
                        {" "}
                        {t("About")}
                      </a>{" "}
                    </li>

                    {mentorDetails.faq && (
                      <li class="nav-item">
                        {" "}
                        <a
                          style={{
                            float: "left",
                            cursor: "pointer",
                            marginBottom: "0.5rem",
                          }}
                          class={
                            this.state.openFAQPageState
                              ? "nav-link active nav-link-body-sidemenu-active"
                              : "nav-link nav-link-body"
                          }
                          onClick={this.openFAQPage}
                        >
                          {t("FAQ")}{" "}
                        </a>{" "}
                      </li>
                    )}
                    {mentorDetails.timeingList?.length !== 0 && (
                      <li class="nav-item">
                        {" "}
                        <a
                          style={{
                            float: "left",
                            cursor: "pointer",
                            marginBottom: "0.5rem",
                          }}
                          class={
                            this.state.openTimingPageState
                              ? "nav-link active nav-link-body-sidemenu-active"
                              : "nav-link nav-link-body"
                          }
                          onClick={this.openTimingPage}
                        >
                          {" "}
                          {t("Timing")}
                        </a>{" "}
                      </li>
                    )}

                    {this.props.loginDetails.uId === mentorDetails.userId && (
                      <>
                        <li class="nav-item">
                          {" "}
                          <a
                            style={{
                              float: "left",
                              cursor: "pointer",
                              marginBottom: "0.5rem",
                            }}
                            class={
                              this.state.openRequestListPage
                                ? "nav-link active nav-link-body-sidemenu-active"
                                : "nav-link nav-link-body"
                            }
                            onClick={this.openRequestList}
                          >
                            {t("Requested List")}{" "}
                          </a>{" "}
                        </li>
                        <li class="nav-item">
                          {" "}
                          <a
                            style={{
                              float: "left",
                              cursor: "pointer",
                              marginBottom: "0.5rem",
                            }}
                            class={
                              this.state.openAcceptedListPage
                                ? "nav-link active nav-link-body-sidemenu-active"
                                : "nav-link nav-link-body"
                            }
                            onClick={this.openAcceptedList}
                          >
                            {t("Accepted List")}{" "}
                          </a>{" "}
                        </li>
                      </>
                    )}
                    {(this.props.loginDetails.uId === mentorDetails.userId ||
                      mentorDetails?.meetingAvailable) && (
                      <li class="nav-item">
                        {" "}
                        <a
                          style={{
                            float: "left",
                            cursor: "pointer",
                            marginBottom: "0.5rem",
                          }}
                          class={
                            this.state.openMeetingList
                              ? "nav-link active nav-link-body-sidemenu-active"
                              : "nav-link nav-link-body"
                          }
                          onClick={this.openMeetingTab}
                        >
                          {t("Scheduled Meetings")}{" "}
                        </a>{" "}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              {this.state.openDescriptionPageState && (
                <div style={{ marginTop: "12px" }}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <div className="askAQuestionCardWrapper">
                        <p
                          className=""
                          style={{ paddingLeft: "12px", fontSize: "14px" }}
                          dangerouslySetInnerHTML={{
                            __html: mentorDetails.description,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
              {this.state.openTimingPageState && (
                <div
                  className="d-flex flex-column mt-2"
                  style={{ gap: "1rem" }}
                >
                  {mentorDetails.timeSlots &&
                    mentorDetails.timeSlots.map(
                      (data) => (
                        <>
                          <div class="askAQuestionCardWrapper">
                            <h6 style={{ fontWeight: "600" }}>{data.day}</h6>
                            <div
                              className="time-slot-wrap"
                              style={{ gap: "1rem" }}
                            >
                              {data?.slots.map((slot) => (
                                <p className="time-slots">{slot.name}</p>
                              ))}
                            </div>
                          </div>
                          {/* {data.morningStart != "" &&
                                  data.morningStart != null && (
                                    <Grid item sm={12} xs={12} lg={3} md={4}>
                                      <div class="alert alert-info">
                                        <strong>{t("Morning")}</strong>
                                        <p style={{ color: "#db2828" }}>
                                          {data.morningStart} -{" "}
                                          {data.morningEnd} Am
                                        </p>
                                      </div>
                                    </Grid>
                                  )}
                                {data.afternoonStart != "" &&
                                  data.afternoonStart != null && (
                                    <Grid item sm={12} xs={12} lg={3} md={4}>
                                      <div class="alert alert-info">
                                        <strong>{t("Afternoon")}</strong>
                                        <p style={{ color: "#db2828" }}>
                                          {data.afternoonStart} -{" "}
                                          {data.afternoonEnd} Pm
                                        </p>
                                      </div>
                                    </Grid>
                                  )}
                                {data.eveningStart != "" &&
                                  data.eveningStart != null && (
                                    <Grid item sm={12} xs={12} lg={3} md={4}>
                                      <div class="alert alert-info">
                                        <strong>{t("Evening")}</strong>
                                        <p style={{ color: "#db2828" }}>
                                          {data.eveningStart} -{" "}
                                          {data.eveningEnd} Pm
                                        </p>
                                      </div>
                                    </Grid>
                                  )} */}
                        </>
                      )
                      // )}
                    )}
                  {mentorDetails.timeSlots.length === 0 && (
                    <div class="askAQuestionCardWrapper">
                      No Time slot available
                    </div>
                  )}
                </div>
              )}
              {this.state.openFAQPageState && (
                <div style={{ marginTop: "12px" }}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <div className="askAQuestionCardWrapper">
                        <p
                          className=""
                          style={{ paddingLeft: "12px", fontSize: "14px" }}
                          dangerouslySetInnerHTML={{
                            __html: mentorDetails.faq,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
              {this.state.openRequestListPage && (
                <div style={{ marginTop: "12px" }}>
                  <RequestandAcceptedList
                    requestedList={this.state.requestedList}
                    mentorId={mentorDetails.mentorId}
                    updateAcceptList={this.updateAcceptList}
                    requestList={true}
                    needAssessment={mentorDetails.selfAssessment}
                    needClose={this.openAcceptedList}
                  />
                </div>
              )}
              {this.state.openAcceptedListPage && (
                <div style={{ marginTop: "12px" }}>
                  <RequestandAcceptedList
                    mentorId={mentorDetails.mentorId}
                    acceptedList={this.state.acceptedList}
                    requestList={false}
                  />
                </div>
              )}
              {this.state.openMeetingList && (
                <ScheduledCard id={mentorDetails.mentorId} />
              )}
            </Grid>
          </Grid>
        )}
      </BlockUi>
    );
  }
  openMeetingTab = () => {
    this.setState({
      openMeetingList: true,
      openFAQPageState: false,
      openTimingPageState: false,
      openRequestListPage: false,
      openAcceptedListPage: false,
      openDescriptionPageState: false,
    });
  };
  updateRequestOption = (mentorId) => {
    var postList = this.state.mentorsList;

    const finalResult = postList.map((person) => {
      if (person.mentorId == mentorId) {
        person.type = "requested";
      }
      return person;
    });

    this.setState({
      mentorsList: finalResult,
    });
    console.log("check", finalResult);
  };
  updateAcceptList = (userId) => {
    var postList = this.state.requestedList;
    console.log("postList", postList);

    postList = this.state.requestedList.filter(
      (item) => item.userId !== userId
    );
    console.log("postList", postList);

    this.setState({
      requestedList: postList,
    });
    var data = {
      mentorId: this.props.mentorDetails.mentorId,
    };
    PostService.getAllAcceptsByMentor(data)
      .then((response) => {
        this.setState({
          acceptedList: response.data.data.acceptList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  openAcceptedList = () => {
    var data = {
      mentorId: this.props.mentorDetails.mentorId,
    };
    PostService.getAllAcceptsByMentor(data)
      .then((response) => {
        this.setState({
          acceptedList: response.data.data.acceptList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({
      openFAQPageState: false,
      openDescriptionPageState: false,
      openAcceptedListPage: true,
      openRequestListPage: false,
      openTimingPageState: false,
      openMeetingList: false,
    });
  };
  openRequestList = () => {
    this.setState({
      openFAQPageState: false,
      openDescriptionPageState: false,
      openAcceptedListPage: false,
      openRequestListPage: true,
      openTimingPageState: false,
      openMeetingList: false,
    });

    if (!this.props.mentorDetails.selfAssessment) {
      var data = {
        mentorId: this.props.mentorDetails.mentorId,
      };
      PostService.getMentorSelfAssessmentDatas(data)
        .then((response) => {
          this.setState({
            requestedList: response.data.data.userList,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // this.setState({ comment: !this.state.comment });
  };
  groupCardDescriptionPage = () => {
    this.setState({
      openFAQPageState: false,
      openDescriptionPageState: true,
      openAcceptedListPage: false,
      openRequestListPage: false,
      openTimingPageState: false,
      openMeetingList: false,
    });
  };
  openTimingPage = () => {
    this.setState({
      openFAQPageState: false,
      openDescriptionPageState: false,
      openAcceptedListPage: false,
      openRequestListPage: false,
      openTimingPageState: true,
      openMeetingList: false,
    });
  };
  openFAQPage = () => {
    this.setState({
      openFAQPageState: true,
      openDescriptionPageState: false,
      openAcceptedListPage: false,
      openRequestListPage: false,
      openTimingPageState: false,
      openMeetingList: false,
    });
  };
  backToCard = () => {
    this.props.history.push(`/${this.props.workspaceType}/mentors`);
  };
  getAllMentorList = () => {
    var data = {};
    PostService.getAllMentorUsers(data)
      .then((response) => {
        if (response.data.data.mentorList) {
          this.setState({
            mentorsList: response.data.data.mentorList,
          });
        } else {
          this.setState({
            mentorsList: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({ comment: !this.state.comment });
  };
}

const mapStateToProps = (state) => {
  return {
    mentorDetails: state.subjects.mentorDetails,
    mentorLoader: state.subjects.mentorLoader,
    mentorSeeMore: state.subjects.mentorSeeMore,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSingleMentor: (id) => dispatch(getSingleMentor(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(Mentor)));
