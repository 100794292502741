import {
  Avatar,
  Chip,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import BlockUi from "react-block-ui";
import { withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { getId, giveUserAction } from "../../../utils";
import {
  needPhotVerification,
  subSpecialization,
} from "../../../redux/actions/commonApiActions";
import { API_URL } from "../../../config/app-config";
import dummyimage from "../../../img/mentor-default.jpg";
import { getAllMentorList } from "../../../redux/actions/MentorActions";
import {
  getMentorIndustry,
  getMentorSpecialization,
} from "../../../redux/actions/MentorActions";
import { SearchIcon } from "@fluentui/react-northstar";
import { BiChevronsLeft } from "react-icons/bi";

const MentorFilterCard = (props) => {
  const { t, subSpecializationList, industryList, loginDetails } = props;
  const [state, setState] = useState({
    specializationFullList: [],
    industryFullList: [],
    filterIndList: [],
    filterSpecList: [],
    experienceYear: "",
    pageNo: 0,
    headerMenu: "Mentors",
    buttonMenu: "",
  });

  const {
    mentorIndustry,
    mentorSpecialization,
    mentorList,
    mentorLoader,
    mentorSeeMore,
    mentorSlotPresent,
  } = useSelector((state) => state.mentor);
  const dispatch = useDispatch();
  const [cardListPage, setCardList] = useState(false);

  useEffect(() => {
    const handleEnter = (e) => {
      if (e?.key === "Enter") {
        filterList(false);
      }
    };
    window.addEventListener("keydown", handleEnter);
    return () => window.removeEventListener("keydown", handleEnter);
  }, []);
  useEffect(() => {
    dispatch(getMentorSpecialization());
    dispatch(getMentorIndustry());
    filterList(false);
  }, []);

  useEffect(() => {
    setState((previous) => ({
      ...previous,
      industryFullList: mentorIndustry,
      specializationFullList: mentorSpecialization,
    }));
  }, [mentorIndustry, mentorSpecialization]);

  useEffect(() => {
    setState((previous) => ({
      ...previous,
      buttonMenu: mentorSlotPresent
        ? "View Mentorship"
        : "+ Enroll as a Mentor",
    }));
  }, [mentorSlotPresent]);
  const filterSpecList = (data, isDelete) => {
    if (isDelete) {
      const filter1 = state.specializationFullList.map((item) => {
        if (item.id === data.id) {
          item.isActive = !item.isActive;
        }
        return item;
      });
      const filter2 = state.filterSpecList.filter(
        (item) => item.id !== data.id
      );
      console.log("Item", filter1);
      console.log("Item", filter2);

      setState((previous) => ({
        ...previous,
        specializationFullList: filter1,
        filterSpecList: filter2,
      }));
      removeData(filter2, state.filterIndList);
    } else {
      state.filterSpecList.push(data);
      const filter1 = state.specializationFullList.map((item) => {
        if (item.id === data.id) {
          item.isActive = !item.isActive;
        }
        return item;
      });
      console.log("Item", filter1);
      console.log("Item", state.filterIndList);
      setState((previous) => ({
        ...previous,
        specializationFullList: filter1,
      }));
    }
  };
  const removeData = (filterSpecList, filterIndList) => {
    let data = {
      industryIds: getId(filterIndList),
      specializationIds: getId(filterSpecList),
      page: 0,
      exp: state.experienceYear === "" ? 0 : +state.experienceYear,
      isMine: cardListPage,
    };
    updateState("pageNo", 1);
    dispatch(getAllMentorList(data, false));
  };
  const filterIndus = (data, isDelete) => {
    if (isDelete) {
      const filter1 = state.industryFullList.map((item) => {
        if (item.id === data.id) {
          item.isActive = !item.isActive;
        }
        return item;
      });
      const filter2 = state.filterIndList.filter((item) => item.id !== data.id);
      console.log("Item", filter1);
      console.log("Item", filter2);

      setState((previous) => ({
        ...previous,
        industryFullList: filter1,
        filterIndList: filter2,
      }));
      removeData(state.filterSpecList, filter2);
    } else {
      state.filterIndList.push(data);
      const filter1 = state.industryFullList.map((item) => {
        if (item.id === data.id) {
          item.isActive = !item.isActive;
        }
        return item;
      });
      console.log("Item", filter1);
      console.log("Item", state.filterIndList);
      setState((previous) => ({
        ...previous,
        industryFullList: filter1,
      }));
    }
  };
  const filterList = (seeMore) => {
    console.log("cardListPage", cardListPage);
    let data = {};
    if (seeMore) {
      data = {
        industryIds: getId(state.filterIndList),
        specializationIds: getId(state.filterSpecList),
        page: state.pageNo,
        exp: state.experienceYear === "" ? 0 : +state.experienceYear,
        isMine: cardListPage,
      };
      updateState("pageNo", state.pageNo + 1);
    } else {
      data = {
        industryIds: getId(state.filterIndList),
        specializationIds: getId(state.filterSpecList),
        page: 0,
        exp: state.experienceYear === "" ? 0 : +state.experienceYear,
        isMine: cardListPage,
      };
      updateState("pageNo", 1);
    }
    dispatch(getAllMentorList(data, seeMore));
  };
  const gotoMentorRegPage = () => {
    if (giveUserAction(props.loginDetails?.profile_complete)) {
      console.log("user-action");
      giveUserAction(needPhotVerification(true));
    } else {
      props.history.push(
        `/${props.workspaceType}/mentor-update/${"mentor-request"}`
      );
    }
  };

  const viewCardList = () => {
    if (giveUserAction(props.loginDetails?.profile_complete)) {
      console.log("user-action");
      dispatch(needPhotVerification(true));
    } else if (mentorSlotPresent && !cardListPage) {
      setCardList(!cardListPage);
      setState((previous) => ({
        ...previous,
        headerMenu: "MentorShip",
        buttonMenu: "+ Enroll New Mentorship",
      }));

      getList(true);
    } else {
      props.history.push(
        `/${props.workspaceType}/mentor-update/${"mentor-request"}`
      );
    }
  };
  const getList = (owner) => {
    if (!owner) {
      setState((previous) => ({
        ...previous,
        buttonMenu: "View Mentorship",
      }));
    }
    setCardList(!cardListPage);
    var data = {
      industryIds: getId(state.filterIndList),
      specializationIds: getId(state.filterSpecList),
      page: 0,
      exp: state.experienceYear === "" ? 0 : +state.experienceYear,
      isMine: owner,
    };
    updateState("pageNo", 1);

    dispatch(getAllMentorList(data, false));
  };
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const mentorDescription = (id) => {
    props.history.push(`/${props.workspaceType}/mentor-description/${id}`);
  };
  const onUpdate = (e) => {
    console.log("e", e.target.value);
    if (e.target.value.length <= 2) {
      updateState("experienceYear", e.target.value);
    }
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap">
        <h3 className="listHomeTitle">{t(state.headerMenu)}</h3>
        <div className="d-flex">
          <button onClick={() => viewCardList()}>{t(state.buttonMenu)}</button>
          {mentorSlotPresent && cardListPage && (
            <button className="ml-2" onClick={() => getList(false)}>
              <BiChevronsLeft /> {t("Back to Mentors List")}
            </button>
          )}
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <div className="aboutMyRequestWrapper">
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                paddingBottom: "5px",
              }}
            >
              {t("Filter by Experience")}..
            </p>

            {/* <hr class="hr-tag" /> */}
            {
              <div className="myRequestTagCard">
                <div className="homePostInputWrapper">
                  <TextField
                    id=""
                    label=""
                    style={{ width: "100%", height: "35px" }}
                    variant="outlined"
                    onChange={(e) => onUpdate(e)}
                    placeholder={t("Type here..demo")}
                    value={state.experienceYear}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <button
                  style={{ width: "100%", marginTop: "12px" }}
                  onClick={() => filterList(false)}
                >
                  {t("Search by Experience")}
                </button>
              </div>
            }
          </div>

          <div className="mt-3 aboutMyRequestWrapper">
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                paddingBottom: "5px",
              }}
            >
              {t("Filter by Specialization")}..
            </p>
            <div class="d-flex flex-wrap gap-2">
              {state.filterSpecList &&
                state.filterSpecList.map((data) => (
                  <Chip
                    style={{
                      overflow: "hidden",
                      Whitespace: "nowrap",
                      fontSize: "12px",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                      maxWidth: "138px",
                    }}
                    avatar={
                      <Avatar>{data.name.charAt(0).toUpperCase()}</Avatar>
                    }
                    label={data.name}
                    onDelete={() => filterSpecList(data, true)}
                  />
                ))}
            </div>

            <hr class="hr-tag" />
            {
              <div className="myRequestTagCard">
                <div className="mt-2 community-tag-wrapper">
                  {mentorSpecialization &&
                    mentorSpecialization.length > 0 &&
                    mentorSpecialization.map((data, index) => (
                      <div
                        class="d-flex mt-3"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => filterSpecList(data, false)}
                      >
                        <div>
                          <p className={data.isActive ? "bg-color" : ""}>
                            {data.name}
                          </p>{" "}
                        </div>
                      </div>
                    ))}
                  {mentorSpecialization &&
                    mentorSpecialization.length === 0 && (
                      <div class="d-flex justify-content-center">
                        {t("Specialization list is empty ..")}
                      </div>
                    )}
                </div>
                <button
                  style={{ width: "100%", marginTop: "12px" }}
                  onClick={() => filterList(false)}
                >
                  {t("Search by Specialization")}
                </button>
              </div>
            }
          </div>

          <div className="aboutMyRequestWrapper mt-3">
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                paddingBottom: "5px",
              }}
            >
              {t("Filter by Industry")}..
            </p>
            <div class="d-flex flex-wrap gap-2">
              {state.filterIndList &&
                state.filterIndList.map((data) => (
                  <Chip
                    style={{
                      overflow: "hidden",
                      Whitespace: "nowrap",
                      fontSize: "12px",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                      maxWidth: "138px",
                    }}
                    avatar={
                      <Avatar>{data?.name.charAt(0).toUpperCase()}</Avatar>
                    }
                    label={data.name}
                    onDelete={() => filterIndus(data, true)}
                  />
                ))}
            </div>

            <hr class="hr-tag" />
            {
              <div className="myRequestTagCard">
                <div className="mt-2 community-tag-wrapper">
                  {state.industryFullList &&
                    state.industryFullList.length > 0 &&
                    state.industryFullList.map((data, index) => (
                      <div
                        class="d-flex mt-3"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => filterIndus(data, false)}
                      >
                        <div>
                          <p className={data.isActive ? "bg-color" : ""}>
                            {data.name}
                          </p>{" "}
                        </div>
                      </div>
                    ))}
                  {state.industryFullList &&
                    state.industryFullList.length === 0 && (
                      <div class="d-flex justify-content-center">
                        {t("Industry list is empty ..")}
                      </div>
                    )}
                </div>
                <button
                  style={{ width: "100%", marginTop: "12px" }}
                  onClick={() => filterList(false)}
                >
                  {t("Search by Industry")}
                </button>
              </div>
            }
          </div>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          style={{ marginBottom: "6rem" }}
        >
          <div className="announcementWrapperOuter">
            <BlockUi
              tag="div"
              message={t("Updating . . .")}
              style={{ minHeight: "90px" }}
              className="wallLoadingPage"
              blocking={mentorLoader}
            >
              <div className="mentor-home-card">
                {mentorList &&
                  mentorList.map((data, index) => (
                    <div onClick={() => mentorDescription(data.mentorId)}>
                      <img
                        src={`${API_URL}auth/image/${data.fileName}`}
                        alt="mentor"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = dummyimage;
                        }}
                      />
                      <div className="flex-grow-1 d-flex  justify-content-center flex-column">
                        <h4>{data.userName}</h4>
                        <p>{data.companyName}</p>
                      </div>
                    </div>
                  ))}{" "}
              </div>
              {!mentorLoader && mentorList?.length === 0 && (
                <div className="aboutMyRequestWrapper mt-4">
                  <p style={{ textAlign: "center" }}>{t("List is empty")}</p>
                </div>
              )}
              {!mentorLoader && mentorSeeMore && (
                <div class="d-flex justify-content-center mt-4">
                  <button onClick={() => filterList(true)}>
                    {t("See more")}
                  </button>
                </div>
              )}
            </BlockUi>

            {/* {!this.props.communityLoading &&
              this.props.searchListPage > 0 &&
              this.props.groupList.length !== 0 &&
              this.props.searchListPage - 1 != this.props.pageNo && (
                <div class="d-flex justify-content-center mt-4">
                  <button onClick={this.onCallingSeeMore}>
                    {t("See more")}
                  </button>
                </div>
              )}
            {!this.props.communityLoading &&
              this.props.groupList.length === 0 && (
                <div className="aboutMyRequestWrapper mt-4">
                  <p style={{ textAlign: "center" }}>
                    {t("No more community available")}
                  </p>
                </div>
              )} */}
          </div>
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = function (state) {
  return {
    subSpecializationList: state.commonApi.subSpecialization,
    industryList: state.commonApi.industrylist.data,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(MentorFilterCard)));
