import {
  GET_ALL_BADGES_LIST,
  GET_RECENTLY_BADGES_LIST,
  GET_BADGES_POINTS_HISTORY,
  GET_LOGOUT_DATA,
} from "../types";

const initialState = {
  list: [],
  badgesPointsHistory: [],
  badgeslist: [],
  pointList: [],
  pointSeeMore: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BADGES_LIST:
      console.log("GET_ALL_BADGES_LIST");
      console.log(action.payload);
      return {
        ...state,
        badgeslist: action.payload,
        loading: false,
      };
    case GET_RECENTLY_BADGES_LIST:
      console.log("GET_RECENTLY_BADGES_LIST");
      console.log(action.payload);

      return {
        ...state,
        list: action.payload,
        loading: false,
      };

    case GET_BADGES_POINTS_HISTORY:
      console.log("GET_BADGES_POINTS_HISTORY", action);
      console.log(action.payload);
      const data =
        action.page === 0
          ? action.payload.content
          : state.pointList.concat(action.payload.content);
      return {
        ...state,
        pointList: data ? data : [],
        pointSeeMore:
          action.payload.totalPages - 1 > action.page ? true : false,
        // badgesPointsHistory: action.payload,
        // loading: false,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");
      console.log(initialState.subjectslist);
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
