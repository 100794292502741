import Modal from "react-bootstrap/Modal";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { metaTags } from "../utils";
import { MdOutlineAttachment } from "react-icons/md";
import Image_1 from "../img/home/refer_1.png";
import Image_2 from "../img/home/refer_2.png";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import PhoneNumberContainer from "./PhoneNumberContainer";
import { useDispatch, useSelector } from "react-redux";
import ProfileService from "../api/ProfileService";
import { updateProfileDetails } from "../redux/actions/loginDetailsActions";
import BlockUi from "react-block-ui";
import OtpInput from "../screens/Login/LoginV2/OtpInput";
function ModelForm(props) {
  const [profileModel, setProfileModel] = useState(false);
  const loginDetails = useSelector(
    (state) => state.loginDetailsApi.loginDetails
  );
  const authUser = useSelector((state) => state.loginDetailsApi.authUser);
  const [phoneNumber, setPhoneNumber] = useState("");
  const dispatch = useDispatch();
  const [state, setState] = useState({
    otp: "",
    otpId: "",
    phoneModelStatus: "",
    loader: false,
  });

  useEffect(() => {
    if (
      !loginDetails?.phoneNumber &&
      loginDetails?.defaultWorkSpace === "default"
    ) {
      setProfileModel(true);
    } else if (props.profile) {
      setProfileModel(true);
    }
    console.log("loginDetails", loginDetails);
  }, [loginDetails]);

  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(`${props.id}`);

      toast.success("Copied");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  const updatePhoneNumber = () => {
    let data = {
      phoneNumber: phoneNumber,
      firstTimeSetup: "true",
      displayName: loginDetails.dName,
    };
    ProfileService.updateUserProfile(data)
      .then((response) => {
        var result = response.data;
        console.log("response", result.data);

        if (result.data?.dName) {
          dispatch(updateProfileDetails(result.data));
          props.handleClose("success", phoneNumber);
        }
        toast.success("PhoneNumber updated successfully");
      })
      .catch((e) => {
        console.log("e", e);
      });
  };
  const sendOtp = () => {
    if (phoneNumber.length < 6) {
      toast.warning("Enter valid phone number");
    } else {
      updateState("loader", true);
      let data = {
        phoneNumber: phoneNumber,
      };
      ProfileService.validatePhoneNumber(data)
        .then((response) => {
          if (response.data.data === "NOT-EXIST") {
            let number = {
              type: "phone",
              phoneNumber: phoneNumber,
              optModelName: "profile",
            };
            ProfileService.sendUserOtp(number)
              .then((response) => {
                if (response.data.data?.id) {
                  setState({
                    ...state,
                    otpId: response.data.data.id,
                    loader: false,
                    phoneModelStatus: "otp-validate",
                  });
                }
              })
              .catch((e) => {
                console.log("e", e);
                toast.error("Something went wrong");
                updateState("loader", false);
              });
          } else {
            toast.warning("Phone number already exist");
            updateState("loader", false);
          }
        })
        .catch((e) => {
          console.log("e", e);
          updateState("loader", false);
        });
    }
  };
  const verifyOtp = () => {
    updateState("loader", true);

    let number = {
      otp: state.otp,
      id: state.otpId,
    };
    ProfileService.validateUserOtp(number)
      .then((response) => {
        if (response.data.data === "VALID_OTP") {
          updatePhoneNumber();
        } else {
          toast.error("Wrong otp");
          updateState("loader", false);
        }
      })
      .catch((e) => {
        console.log("e", e);
        toast.error("Something went wrong");
        updateState("loader", false);
      });
  };
  return (
    <>
      <Modal
        show={true}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          <AiOutlineCloseCircle
            onClick={props.handleClose}
            className="mr-2 mt-2"
            style={{
              cursor: "pointer",
              float: "right",
              marginBottom: "8px",
              width: "20px",
              height: "20px",
              color: "#000000",
            }}
          />
        </div>
        <BlockUi
          tag="div"
          message="Loading, please wait"
          style={{ minHeight: "134px" }}
          className="wallLoadingPage"
          blocking={state.loader}
        >
          {profileModel ? (
            <div>
              <section className="w-auto  mt-4" style={{ height: "27rem" }}>
                <img
                  alt="img"
                  src="https://corporate.yuukke.com/bcav2/auth/image/fb079646-a70a-4af4-bbfa-00e350176582"
                  className="w-100 h-100  "
                  style={{ objectFit: "contain" }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.source = Image;
                  }}
                />
                {/* <FcContacts
            style={{
              width: "75px",
            }}
          /> */}
              </section>
              <div
                style={{ background: "#a00030" }}
                className="text-white p-3 d-flex flex-column align-items-center justify-content-center"
              >
                <div
                  class="input-text text-align-center d-flex flex-column"
                  style={{ width: "fit-content" }}
                >
                  <label className="mt-2 login-details-content font-weight-bold">
                    {state.phoneModelStatus === "otp-validate"
                      ? "Enter otp"
                      : "Phone number"}
                  </label>
                  {state.phoneModelStatus === "otp-validate" ? (
                    <>
                      <div className="d-flex g-1">
                        <OtpInput
                          length={6}
                          onOtpSubmit={(otp) => updateState("otp", otp)}
                        />
                      </div>
                      <button
                        onClick={() => verifyOtp()}
                        type="submit"
                        style={{ width: "fit-content" }}
                        className="refer-phone-btn mt-3 ml-auto mr-auto"
                      >
                        Verify
                      </button>
                    </>
                  ) : (
                    <>
                      <PhoneNumberContainer
                        updateData={(data) => setPhoneNumber(data)}
                        phoneNumber={phoneNumber}
                      />
                      <button
                        style={{ width: "fit-content" }}
                        className="refer-phone-btn mt-3 ml-auto mr-auto"
                        onClick={() => sendOtp()}
                      >
                        Send Otp
                      </button>
                    </>
                  )}
                </div>

                {/* <span
                className="refer-phone-btn mt-3"
                onClick={() => updatePhoneNumber()}
              >
                Update Phone Number
              </span> */}
              </div>
            </div>
          ) : (
            <>
              {props.type === "referral" && (
                <>
                  <section className="w-auto  mt-4" style={{ height: "15rem" }}>
                    <img
                      alt="img"
                      src={
                        localStorage.getItem("R-I") === "A" ? Image_1 : Image_2
                      }
                      className="w-100 h-100  "
                      style={{ objectFit: "contain" }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.source = Image;
                      }}
                    />
                    {/* <FcContacts
            style={{
              width: "75px",
            }}
          /> */}
                  </section>
                  <Modal.Body className="r-model">
                    Unlock awesome rewards with{" "}
                    <p className="bg-color">Yuukke!</p> Refer a friend now and
                    get <p className="bg-color">100</p> points.
                    <br />
                    <p className="mt-3">Use your referral code -</p>
                    <ul
                      className="copy-button mt-3"
                      onClick={() => handleCopy()}
                    >
                      <li className="bg-color">{props.id}</li>
                      <li>
                        {" "}
                        <MdOutlineAttachment />{" "}
                      </li>
                    </ul>
                  </Modal.Body>
                </>
              )}
              <Modal.Footer style={{ background: "ghostwhite" }}>
                <div className="social-icons-share">
                  Share with your friends by using these
                  <ul className="social-icons-share">
                    <li>
                      <WhatsappShareButton
                        url={props.url}
                        title={props.content}
                        separator="  "
                        metaTags={metaTags}
                      >
                        <WhatsappIcon
                          size={52}
                          round
                          style={{ background: "ghostwhite" }}
                        />
                      </WhatsappShareButton>
                      Whatsapp
                    </li>
                    <li>
                      <TelegramShareButton
                        url={props.url}
                        title={props.content}
                        separator="  "
                        metaTags={metaTags}
                      >
                        <TelegramIcon
                          size={52}
                          round
                          style={{ background: "ghostwhite" }}
                        />
                      </TelegramShareButton>
                      Telegram
                    </li>
                    <li>
                      <LinkedinShareButton
                        url={props.url}
                        title={props.content}
                        separator="  "
                        metaTags={metaTags}
                      >
                        <LinkedinIcon size={52} round />
                      </LinkedinShareButton>
                      Linkedin
                    </li>
                    <li>
                      <FacebookShareButton
                        url={props.url}
                        title={props.content}
                        separator="  "
                        metaTags={metaTags}
                      >
                        <FacebookIcon
                          size={52}
                          round
                          style={{ background: "ghostwhite" }}
                        />
                      </FacebookShareButton>
                      FaceBook
                    </li>
                    <li>
                      <TwitterShareButton
                        url={props.url}
                        title={props.content}
                        separator="  "
                        metaTags={metaTags}
                      >
                        <TwitterIcon
                          size={52}
                          round
                          style={{ background: "ghostwhite" }}
                        />
                      </TwitterShareButton>
                      Twitter
                    </li>
                  </ul>
                </div>
                {/* <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button> */}
              </Modal.Footer>
            </>
          )}
        </BlockUi>
      </Modal>
    </>
  );
}

export default ModelForm;
