import React, { Component } from "react";
import AuthService from "../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";
import { withRouter } from "react-router";
import dummyimage from "../img/default_avatar.jpg";
import banner from "../img/Yuukke_Cover.jpg";
import Hoc from "../Hoc";
import Header from "../components/Header/Header";
import "../App.css";
import "./navigationsMenu.css";
import { connect } from "react-redux";
import {
  AiOutlineCopy,
  AiOutlineHome,
  AiOutlineSolution,
  AiTwotoneCopy,
} from "react-icons/ai";
import {
  getAllMyGroupEvents,
  getMyGroupsByMembersandMessages,
} from "../redux/actions/subjectActions";
import {
  getNavigationType,
  getWorkSpaceType,
  needPhotVerification,
  sellerRequest,
  workSpaceLoginStats,
} from "../redux/actions/commonApiActions";
import markerSDK from "@marker.io/browser";
import { BiGridAlt, BiGroup } from "react-icons/bi";
import { FiTruck } from "react-icons/fi";
import { RiTeamLine } from "react-icons/ri";
import { FiLayers } from "react-icons/fi";
import * as APPCONFIG from "../config/app-config.js";
import { IoIosArrowForward } from "react-icons/io";
import {
  referralUserAddToWorkSpace,
  workSpaceUserLogin,
  announcementStatus,
} from "../redux/actions/loginDetailsActions";
import { workSpaceWrongUrl } from "../redux/actions/commonApiActions";
// import NeedHelpModal from "../screens/AskHelp/AskHelpSlider.jsx";
import { Modal } from "@material-ui/core";
import { Form, ModalBody, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { MdOutlineEventNote, MdOutlineLibraryBooks } from "react-icons/md";
import { giveUserAction, premiumUser } from "../utils";
import SharePopup from "../components/ReferralModel";
import { getSubscriptionStatus } from "../redux/actions/calendarAction.js";

class NavigationsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: "",
      mobileMenu: false,
      showMenu: false,
      showEventMenu: true,
      homePage: true,
      connectionsPage: false,
      groupsPage: false,
      contestPage: false,
      marketPlacePage: false,
      mentorPage: false,
      contestResultPage: false,
      meeting: false,
      ouOfferingsPage: false,
      helpPage: false,
      mentorRegPage: false,
      sellerRegPage: false,
      calender: false,
      catalystTalksPage: false,
      bizFeaturesPage: false,
      futureEentrepreneursPage: false,
      userListPage: false,
      showGroupMenu: false,
      communityData: ["My Community", "All Community", "Forum Community"],
      resourceData: ["Resources", "Biz Features", "Catalyst Talks"],
      activeIndex: null,
      activeResourceIndex: null,
      showResourceMenu: false,
      showRegMenu: false,
      activeRegIndex: null,
      registrationData: ["Mentor Registration", "Seller Registration"],
      helpModelStatus: false,
      copyData: false,
      openPopup: false,
    };

    this.gotoCalender = this.gotoCalender.bind(this);
    this.onRouteChanged = this.onRouteChanged.bind(this);
    this.handleMobileMenu = this.handleMobileMenu.bind(this);
    this.showEventMenu = this.showEventMenu.bind(this);
    this.gotoclassroom = this.gotoclassroom.bind(this);
    this.gotoevents = this.gotoevents.bind(this);
    this.gotocgroups = this.gotocgroups.bind(this);
    this.gotomarketplace = this.gotomarketplace.bind(this);
    this.gotocevents = this.gotocevents.bind(this);
    this.gotogroupDescription = this.gotogroupDescription.bind(this);
    this.markerSDKcalling = this.markerSDKcalling.bind(this);

    this.gotomyrequest = this.gotomyrequest.bind(this);
    this.gotochallenges = this.gotochallenges.bind(this);
    this.gotomyprofile = this.gotomyprofile.bind(this);
    this.gotoChallengeDescription = this.gotoChallengeDescription.bind(this);
    this.gotoContestResult = this.gotoContestResult.bind(this);
    this.gotoMeeting = this.gotoMeeting.bind(this);
    this.gotoMentorRegPage = this.gotoMentorRegPage.bind(this);
    this.gotoSellerRegPage = this.gotoSellerRegPage.bind(this);
  }

  handleMobileMenu = () => {
    this.setState({ mobileMenu: !this.state.mobileMenu });
  };
  scrollTrackFunc = () => {
    // if (window.scrollY > 271) {
    //   this.setState({
    //     navPageStatic: true,
    //   });
    // } else {
    //   this.setState({
    //     navPageStatic: false,
    //   });
    // }
  };
  userAccessUrlPath = (pathname) => {
    if (
      this.props.userWorkSpaceList &&
      pathname !== "/workspace" &&
      pathname != "/userProfile" &&
      pathname !== "/photo-set-up" &&
      pathname !== "/my-profile" &&
      !pathname.includes("challenge-description")
    ) {
      console.log("photo-setup", pathname);
      let path = pathname + "/";
      let regex = /\/(.*?)\//;
      let removePatName = path.match(regex);
      let urlPath = removePatName && removePatName[1] ? removePatName[1] : "";
      let result = this.props.userWorkSpaceList.find(
        (item) => item.slug === urlPath
      );
      console.log("urlPath", urlPath);
      if (
        urlPath !== "articles" &&
        urlPath !== "userProfile" &&
        urlPath !== "eventRegistration" &&
        this.checkWorkSpace(urlPath)
      ) {
        if (result) {
          console.log("Push url path");
          this.props.workSpaceUserLogin(
            result?.workSpaceId ? result?.workSpaceId : 0
          );
          sessionStorage.setItem(
            "WORKSPACE",
            result?.slug ? result?.slug : "default"
          );
          console.log("In Correct url path");
          this.props.workSpaceWrongUrl(false, urlPath ? urlPath : "default");
        } else {
          this.props.workSpaceWrongUrl(true, urlPath ? urlPath : "url");
          this.props.history.push(`/${urlPath ? urlPath : "url"}/home`);
        }
        console.log("Inside url path");
      }

      console.log("Login via url1", urlPath);
      console.log("Login via url1", result);
    }
  };
  checkWorkSpace = (urlPath) => {
    console.log("urlPath", urlPath);
    if (
      urlPath &&
      urlPath === "default" &&
      !this.props.loginDetails?.profile_complete &&
      this.props.userWorkSpaceList?.length > 1 &&
      !this.props.workSpaceComplete
    ) {
      console.log("url workspace");
      // toast.error(
      //   "Please complete profile setup ,then only you access yuukke workspace"
      // );
      // this.props.history.push(`/userWorkSpaceList`);
      return false;
    } else {
      return true;
    }
  };
  componentWillUnmount() {
    //  window.removeEventListener("scroll", this.scrollTrackFunc);
  }
  componentDidMount() {
    this.checkAuth();
    // {
    //   this.props.authUser &&
    //     this.props.authenticated &&
    //     this.userAccessUrlPath(window.location.pathname);
    // }
    //if(window.innerWidth > 768){
    // window.addEventListener("scroll", this.scrollTrackFunc);
    // }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.onRouteChanged();
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    //
  }

  componentDidUpdate(prevProps) {
    if (this.props.needHelpButtonState !== prevProps.needHelpButtonState) {
      console.log("Need Help Clicked");
      this.needHelpPage();
    }
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (
      this.props.wrongUrlStatus &&
      prevProps.navigationType !== this.props.navigationType
    ) {
      this.props.workSpaceWrongUrl(false, "url");
    }
  }
  // showMenu(event) {
  //   document.addEventListener("mousedown", this.handleClickOutside);

  //   this.setState({
  //     showMenu: !this.state.showMenu,
  //   });
  // }
  async markerSDKcalling() {
    const widget = await markerSDK.loadWidget({
      destination: "6270d4e18279745438be6ce1",
    });
  }
  showEventMenu(event) {
    document.addEventListener("mousedown", this.handleClickOutside);

    this.setState({
      showEventMenu: !this.state.showEventMenu,
    });
  }
  closeHelpModel = () => {
    this.setState({
      helpModelStatus: !this.state.helpModelStatus,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        {this.state.currentPath.includes("login") ||
        // this.state.currentPath.includes("login") ||
        this.state.currentPath.includes("/userProfile") ||
        this.state.currentPath.includes("first-time-setup") ||
        this.state.currentPath.includes("/workspace") ||
        this.state.currentPath.includes("/photo-set-up") ||
        // this.state.currentPath.includes("register") ||
        this.state.currentPath.includes("forgotPassword") ||
        this.state.currentPath.includes("contact-support") ||
        this.state.currentPath.includes("contact-report") ? (
          //|| this.state.currentPath.includes("tab")
          // this.state.currentPath.includes("login") ||
          this.state.currentPath.includes("error") ||
          // this.state.currentPath.includes("register") ||
          this.state.currentPath.includes("forgotPassword") ||
          this.state.currentPath.includes("contact-support") ||
          this.state.currentPath.includes("contact-report") ? (
            //|| this.state.currentPath.includes("tab")
            <></>
          ) : (
            <>
              <>
                {" "}
                <Hoc />
              </>
            </>
          )
        ) : (
          <>
            {window.location.pathname !== "/workspace" && (
              <div className="headerMainWrapper">
                {this.props.loading && (
                  <>
                    <Hoc />
                  </>
                )}
                <Header />

                {/* <NeedHelpModal
                  show={this.state.helpModelStatus}
                  handleClickAway={this.closeHelpModel}
                /> */}

                <div className="navMainWrapper">
                  <div className="new-desktop-wrapper">
                    <div className="leftside-menu">
                      {/* <div
                    className={
                      navPageStatic ? "leftside-menuStatic" : "leftside-menu"
                    }
                  > */}
                      <div
                        className="menu-items-wrapper"
                        style={{ position: "relative" }}
                      >
                        <nav class="navigationsMenu-navbar navbar-expand-lg navigationsMenu-mx-0">
                          <div
                            class="navigationsMenu-offcanvas navigationsMenu-offcanvas-start"
                            tabindex="-1"
                            id="offcanvasSideNavbar"
                          >
                            {/* <div class="navigationsMenu-offcanvas-header">
                                                        <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                                    </div> */}

                            <div class="navigationsMenu-offcanvas-body navigationsMenu-d-block navigationsMenu-px-2 navigationsMenu-px-lg-0">
                              <div class="navigationsMenu-card navigationsMenu-overflow-hidden">
                                <div
                                  style={{
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                >
                                  <img
                                    class="h-50px"
                                    style={{
                                      width: "100%",
                                      objectFit: "cover",
                                      // width: "300px",
                                      borderTopLeftRadius: "7px",
                                      borderTopRightRadius: "7px",
                                    }}
                                    src={
                                      this.props.loginDetails?.cover_uuid
                                        ? `${APPCONFIG.API_URL}auth/image/${this.props.loginDetails?.cover_uuid}`
                                        : banner
                                    }
                                    alt=""
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = banner;
                                    }}
                                  ></img>
                                </div>

                                <div class="navigationsMenu-card-body pt-0">
                                  <div class="text-center">
                                    <div class="avatar avatar-lg mt-n5 mb-3">
                                      <a href="#!">
                                        <img
                                          onClick={() =>
                                            this.showFriendsProfile(
                                              this.props.loginDetails.uId
                                            )
                                          }
                                          class="avatar-img rounded border border-white border-3"
                                          src={`${APPCONFIG.API_URL}auth/image/${this.props.loginDetails?.profile_uuid}`}
                                          alt=""
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = dummyimage;
                                          }}
                                        />
                                      </a>
                                    </div>

                                    <h5
                                      class="mb-0 ml-2 mr-2 text-center"
                                      style={{
                                        color: "#14191e",
                                        paddingBottom: "10px",
                                      }}
                                    >
                                      {" "}
                                      {this.props.loginDetails?.dName}{" "}
                                      {premiumUser(
                                        this.props.loginDetails?.premiumData
                                      ) && (
                                        <img
                                          src={premiumUser(
                                            this.props.loginDetails?.premiumData
                                          )}
                                          alt=""
                                          className="ml-1"
                                          style={{ width: "20px" }}
                                        />
                                      )}
                                    </h5>
                                    <div className="profileCopy-card d-flex justify-content-center mb-3">
                                      <p
                                        onClick={this.popUpAlertStatus}
                                        class="tour-vcard"
                                      >
                                        VCARD{" "}
                                        {this.props.loginDetails
                                          ?.vcardIsPresent && (
                                          <>
                                            {this.state.copyData ? (
                                              <AiTwotoneCopy
                                                class="copy-icon"
                                                style={{
                                                  color: "rgb(160, 0, 48)",
                                                }}
                                                onClick={this.handleCopy}
                                              />
                                            ) : (
                                              <AiOutlineCopy
                                                class="copy-icon"
                                                onClick={this.handleCopy}
                                              />
                                            )}
                                          </>
                                        )}
                                      </p>
                                    </div>
                                    {this.props.defaultWorkSpaceId === 0 && (
                                      <div
                                        className="profileCopy-card d-flex  alien-items-center justify-content-center"
                                        style={{
                                          color: "#676a79",
                                          fontSize: "0.9375rem",
                                        }}
                                      >
                                        <div class="refer-wrapper">
                                          <a
                                            class="cta"
                                            onClick={() =>
                                              this.setState({
                                                openPopup: true,
                                              })
                                            }
                                          >
                                            <span>{t("Refer A Friend")}</span>
                                            <span>
                                              <svg
                                                width="16px"
                                                height="13px"
                                                viewBox="0 0 66 43"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <g
                                                  id="arrow"
                                                  stroke="none"
                                                  stroke-width="1"
                                                  fill="none"
                                                  fill-rule="evenodd"
                                                >
                                                  <path
                                                    class="one"
                                                    d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                                                    fill="black"
                                                  ></path>
                                                  <path
                                                    class="two"
                                                    d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                                                    fill="black"
                                                  ></path>
                                                  <path
                                                    class="three"
                                                    d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                                                    fill="black"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </span>
                                          </a>
                                        </div>
                                        {(this.state.openPopup ||
                                          this.props.needAnnouncement ===
                                            "referral") && (
                                          <SharePopup
                                            type="referral"
                                            id={
                                              this.props.loginDetails
                                                .referralCode
                                            }
                                            content={`${this.props.loginDetails.dName} invite you to join on Yuukke app Using with referral code ${this.props.loginDetails.referralCode} .Register using this referral code...`}
                                            handleClose={this.openPopup}
                                            url={
                                              "http://yuukke.com/promotion.php"
                                            }
                                          />
                                        )}
                                      </div>
                                    )}
                                    {/* <p style={{ color: "#676a79", fontSize: "0.9375rem",paddingTop:"8px" }} class="">{biography}</p> */}

                                    <div class="hstack gap-2 gap-xl-3 justify-content-center">
                                      {/* <div style={{border:"1px",color: "#676a79"}}>
                                                                            <h6 class="mb-0" style={{  color: "#676a79", fontSize: "0.875em"}}>{this.props.FriendsList.length}</h6>
                                                                            <small style={{ color: "#676a79" }}>Friends</small>
                                                                        </div> */}

                                      {/* <div class="vr"></div>

                                                                        <div>
                                                                            <h6 class="mb-0">2.5K</h6>
                                                                            <small>Followers</small>
                                                                        </div>

                                                                        <div class="vr"></div>

                                                                        <div>
                                                                            <h6 class="mb-0">365</h6>
                                                                            <small>Following</small>
                                                                        </div> */}
                                    </div>
                                  </div>

                                  <hr />
                                  <ul className="nav-new-menus">
                                    <li
                                      class={
                                        window.location.pathname.includes(
                                          "home"
                                        )
                                          ? "nav-active"
                                          : ""
                                      }
                                      onClick={this.gotohome}
                                    >
                                      <span>
                                        <AiOutlineHome />
                                      </span>
                                      <span>{t("Home")} </span>
                                    </li>
                                    <li
                                      onClick={this.gotomynetwork}
                                      class={
                                        /mynetwork|friendDescription/.test(
                                          window.location.pathname
                                        )
                                          ? "nav-active"
                                          : ""
                                      }
                                    >
                                      <span>
                                        <BiGroup />
                                      </span>
                                      <span>{t("Connect")} </span>{" "}
                                    </li>
                                    {this.props.defaultWorkSpaceId !== 0 && (
                                      <li
                                        onClick={this.gotoUserList}
                                        class={
                                          /userList/.test(
                                            window.location.pathname
                                          )
                                            ? "nav-active"
                                            : ""
                                        }
                                      >
                                        <span>
                                          <RiTeamLine />
                                        </span>
                                        <span>{t("User List")} </span>{" "}
                                      </li>
                                    )}
                                    <li
                                      class={
                                        /groups|groupsList|forumCommunity|createGroup|groupDescription/.test(
                                          window.location.pathname
                                        )
                                          ? "nav-active"
                                          : ""
                                      }
                                      onClick={this.gotogroups}
                                    >
                                      <span>
                                        <BiGridAlt />
                                      </span>
                                      <span className="text-overflow">
                                        {t("Community")}
                                      </span>{" "}
                                      <div className="ml-auto mr-3">
                                        <IoIosArrowForward
                                          className={`arrowIcon-home ${
                                            this.state.showGroupMenu &&
                                            "bg-color arrow-rotate"
                                          }`}
                                        />
                                        {/* {!this.state.showGroupMenu ? (
                                          <IoIosArrowForward className="arrowIcon-home" />
                                        ) : (
                                          <IoIosArrowDown className="arrowIcon-home bg-color" />
                                        )} */}
                                      </div>
                                    </li>
                                    {this.state.showGroupMenu && (
                                      <li
                                        className="align-items-start flex-column add-slow not-hover"
                                        style={{ gap: "0.5rem" }}
                                      >
                                        {this.state.communityData.map(
                                          (data, index) => (
                                            <p
                                              key={index}
                                              class={
                                                this.state.activeIndex === index
                                                  ? "bg-color ml-6 sub-nav-menu"
                                                  : "sub-nav-menu"
                                              }
                                              onClick={() =>
                                                this.changeGroup(index)
                                              }
                                            >
                                              {t(`${data}`)}
                                            </p>
                                          )
                                        )}
                                      </li>
                                    )}

                                    <li
                                      class={
                                        /learning-hub|learningAppDesription|special-resource/.test(
                                          window.location.pathname
                                        )
                                          ? "nav-active"
                                          : ""
                                      }
                                      onClick={this.gotoOurOfferings}
                                    >
                                      <span>
                                        <MdOutlineLibraryBooks />
                                      </span>
                                      <span className="text-overflow">
                                        {t("Learning Hub")}
                                      </span>{" "}
                                      <div className="ml-auto mr-3">
                                        <IoIosArrowForward
                                          className={`arrowIcon-home ${
                                            this.state.showResourceMenu &&
                                            "bg-color arrow-rotate"
                                          }`}
                                        />
                                      </div>
                                    </li>
                                    {this.state.showResourceMenu && (
                                      <li
                                        className="align-items-start flex-column not-hover"
                                        style={{ gap: "0.5rem" }}
                                      >
                                        {this.state.resourceData.map(
                                          (data, index) => {
                                            if (
                                              this.checkAvailable(
                                                this.props.defaultWorkSpaceId,
                                                data
                                              )
                                            ) {
                                              return (
                                                <p
                                                  key={index}
                                                  class={
                                                    this.state
                                                      .activeResourceIndex ===
                                                    index
                                                      ? "bg-color ml-6 sub-nav-menu"
                                                      : "sub-nav-menu"
                                                  }
                                                  onClick={() =>
                                                    this.changeResource(index)
                                                  }
                                                >
                                                  {t(`${data}`)}
                                                </p>
                                              );
                                            } else {
                                              return null;
                                            }
                                          }
                                        )}
                                      </li>
                                    )}

                                    <li
                                      onClick={() =>
                                        this.moveToMenu("calendar")
                                      }
                                      class={
                                        /calendar/.test(
                                          window.location.pathname
                                        )
                                          ? "nav-active"
                                          : ""
                                      }
                                    >
                                      <span>
                                        <MdOutlineEventNote />
                                      </span>
                                      <span>{t("Calendar")} </span>{" "}
                                    </li>
                                    {this.checkAvailable(
                                      this.props.defaultWorkSpaceId,
                                      "directoryMenu"
                                    ) && (
                                      <li
                                        class={
                                          /directory|directory-details/.test(
                                            window.location.pathname
                                          )
                                            ? "nav-active"
                                            : ""
                                        }
                                        onClick={() =>
                                          this.gotoExternalResourcePage(
                                            1002,
                                            "future-entrepreneurs"
                                          )
                                        }
                                      >
                                        <span>
                                          <FiLayers />
                                        </span>
                                        <span>{t("Business Directory")}</span>{" "}
                                      </li>
                                    )}
                                    <li
                                      class={
                                        /mentors|mentor-update|mentor-description/.test(
                                          window.location.pathname
                                        )
                                          ? "nav-active"
                                          : ""
                                      }
                                      onClick={this.gotoMentorPage}
                                    >
                                      <span>
                                        <AiOutlineSolution />
                                      </span>
                                      <span>{t("Mentors")}</span>{" "}
                                    </li>
                                    {this.props.defaultWorkSpaceId === 0 &&
                                      this.props.requireMentMenu &&
                                      this.props.requireMentMenu.hasOwnProperty(
                                        "sellerExist"
                                      ) &&
                                      !this.props.requireMentMenu
                                        .sellerExist && (
                                        <li
                                          class={
                                            window.location.pathname.includes(
                                              "seller-registration"
                                            )
                                              ? "nav-active"
                                              : ""
                                          }
                                          onClick={this.gotoSellerRegPage}
                                        >
                                          <span>
                                            <FiTruck />
                                          </span>
                                          <span>
                                            {t("Seller Registration")}
                                          </span>{" "}
                                        </li>
                                      )}
                                  </ul>
                                  {/* Old menu */}
                                  {/* <ul class="navigationsMenu-nav nav-link-secondary flex-column fw-bold gap-2">
                                    <li class="nav-item">
                                      <div
                                        class={
                                          this.state.homePage
                                            ? "nav-link-body-sideMenu-active d-flex  "
                                            : "nav-link-body-sideMenu d-flex  "
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "2.3rem",
                                          alignItems: "center",
                                        }}
                                        onClick={this.gotohome}
                                      >
                                        <AiOutlineHome
                                          style={{
                                            marginRight: "8px",
                                            width: "22px",
                                            height: "22px",
                                          }}
                                        />

                                        <span style={{ fontSize: "0.9375rem" }}>
                                          {t("Home")}{" "}
                                        </span>
                                      </div>
                                    </li>

                                    {this.props.defaultWorkSpaceId !== 0 && (
                                      <li class="nav-item">
                                        <div
                                          class={
                                            this.state.userListPage
                                              ? "nav-link-body-sideMenu-active d-flex  "
                                              : "nav-link-body-sideMenu d-flex  "
                                          }
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "2.3rem",
                                            alignItems: "center",
                                          }}
                                          onClick={this.gotoUserList}
                                        >
                                          <RiTeamLine
                                            style={{
                                              marginRight: "8px",
                                              width: "22px",
                                              height: "22px",
                                            }}
                                          />

                                          <span
                                            style={{ fontSize: "0.9375rem" }}
                                          >
                                            {t("User List")}
                                          </span>
                                        </div>
                                      </li>
                                    )}
                                    <li class="nav-item d-flex">
                                      <div
                                        class={
                                          this.state.connectionsPage
                                            ? "nav-link-body-sideMenu-active d-flex mb-0"
                                            : "nav-link-body-sideMenu d-flex mb-0"
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "2.2rem",
                                          alignItems: "center",
                                        }}
                                        onClick={this.gotomynetwork}
                                      >
                                        <BiGroup
                                          style={{
                                            marginRight: "7px",
                                            width: "24px",
                                            height: "23px",
                                          }}
                                        />

                                        <span style={{ fontSize: "0.9375rem" }}>
                                          {t("Connect")}
                                        </span>
                                      </div>
                                    </li>
                                    <li class="nav-item d-flex justify-content-between">
                                      <div
                                        class={
                                          this.state.groupsPage
                                            ? "nav-link active nav-link-body-sideMenu-active"
                                            : "nav-link nav-link-body-sideMenu"
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "3px",
                                        }}
                                        onClick={this.gotogroups}
                                      >
                                        {this.state.groupsPage ? (
                                          <BiGridAlt
                                            style={{
                                              marginRight: "8px",
                                              width: "22px",
                                              height: "22px",
                                            }}
                                          />
                                        ) : (
                                          <BiGridAlt
                                            style={{
                                              marginRight: "10px",
                                              width: "22px",
                                              height: "22px",
                                            }}
                                          />
                                        )}
                                        <span style={{ fontSize: "0.9375rem" }}>
                                          {t("Community")}{" "}
                                        </span>
                                      </div>
                                      <div
                                        className="mr-3"
                                        onClick={this.gotogroups}
                                        style={{
                                          float: "right",
                                        }}
                                      >
                                        {!this.state.showGroupMenu ? (
                                          <IoIosArrowForward
                                            className="arrowIcon-home"
                                            style={{
                                              color: "#000000",
                                            }}
                                          />
                                        ) : (
                                          <IoIosArrowDown className="arrowIcon-home bg-color" />
                                        )}
                                      </div>
                                    </li>

                                    <li class="home-item-card">
                                      {this.state.showGroupMenu &&
                                        this.state.communityData.map(
                                          (data, index) => (
                                            <p
                                              class={
                                                this.state.activeIndex === index
                                                  ? "bg-color"
                                                  : ""
                                              }
                                              onClick={() =>
                                                this.changeGroup(index)
                                              }
                                            >
                                              {t(`${data}`)}
                                            </p>
                                          )
                                        )}
                                    </li>

                                    <li class="nav-item d-flex justify-content-between tour-learning">
                                      <div
                                        class={
                                          this.state.ouOfferingsPage
                                            ? "nav-link active nav-link-body-sideMenu-active"
                                            : "nav-link nav-link-body-sideMenu"
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "3px",
                                        }}
                                        onClick={this.gotoOurOfferings}
                                      >
                                        <MdOutlineLibraryBooks
                                          style={{
                                            marginRight: "9px",
                                            width: "22px",
                                            height: "22px",
                                            marginBottom: "auto",
                                          }}
                                        />

                                        <span style={{ fontSize: "0.9375rem" }}>
                                          {t("Learning Hub")}
                                        </span>
                                      </div>
                                      <div
                                        className="mr-3"
                                        onClick={this.gotoOurOfferings}
                                        style={{
                                          float: "right",
                                          // marginLeft: "9px",
                                        }}
                                      >
                                        {!this.state.showResourceMenu ? (
                                          <IoIosArrowForward
                                            className="arrowIcon-home"
                                            style={{
                                              color: "#000000",
                                            }}
                                          />
                                        ) : (
                                          <IoIosArrowDown className="arrowIcon-home bg-color" />
                                        )}
                                      </div>
                                    </li>
                                    <li class="home-item-card">
                                      {this.state.showResourceMenu &&
                                        this.state.resourceData.map(
                                          (data, index) => {
                                            if (
                                              this.checkAvailable(
                                                this.props.defaultWorkSpaceId,
                                                data
                                              )
                                            ) {
                                              return (
                                                <p
                                                  class={
                                                    this.state
                                                      .activeResourceIndex ===
                                                    index
                                                      ? "bg-color"
                                                      : ""
                                                  }
                                                  onClick={() =>
                                                    this.changeResource(index)
                                                  }
                                                >
                                                  {t(`${data}`)}
                                                </p>
                                              );
                                            } else {
                                              return null;
                                            }
                                          }
                                        )}
                                    </li>
                                    {this.checkAvailable(
                                      this.props.defaultWorkSpaceId,
                                      "careerLab"
                                    ) && (
                                      <li class="nav-item d-flex">
                                        <div
                                          class={
                                            this.state.contestPage
                                              ? "nav-link-body-sideMenu-active d-flex  "
                                              : "nav-link-body-sideMenu d-flex  "
                                          }
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "2.3rem",
                                            alignItems: "center",
                                          }}
                                          onClick={this.gotochallenges}
                                        >
                                          <FiUserPlus
                                            style={{
                                              marginRight: "8px",
                                              width: "22px",
                                              height: "22px",
                                            }}
                                          />

                                          <span
                                            class="text-overflow"
                                            style={{ fontSize: "0.9375rem" }}
                                          >
                                            {t("Career Labs")}
                                          </span>
                                        </div>
                                      </li>
                                    )}

                                    <li class="nav-item d-flex">
                                      <div
                                        class={
                                          this.state.futureEentrepreneursPage
                                            ? "nav-link-body-sideMenu-active d-flex mb-0"
                                            : "nav-link-body-sideMenu d-flex mb-0"
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "2.2rem",
                                          alignItems: "center",
                                        }}
                                        onClick={() =>
                                          this.gotoExternalResourcePage(
                                            1002,
                                            "future-entrepreneurs"
                                          )
                                        }
                                      >
                                        <div id="firstText">
                                          <FiLayers
                                            style={{
                                              marginRight: "8px",
                                              width: "22px",
                                              height: "23px",
                                            }}
                                          />

                                          <span
                                            class="text-overflow"
                                            style={{ fontSize: "0.9375rem" }}
                                          >
                                            {t("Business Directory")}
                                          </span>
                                        </div>
                                      </div>
                                    </li>

                                    <li class="nav-item d-flex">
                                      <div
                                        class={
                                          this.state.mentorPage
                                            ? "nav-link-body-sideMenu-active d-flex mb-0"
                                            : "nav-link-body-sideMenu d-flex mb-0"
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "2.2rem",
                                          alignItems: "center",
                                        }}
                                        onClick={this.gotoMentorPage}
                                      >
                                        <AiOutlineSolution
                                          style={{
                                            marginRight: "10px",
                                            width: "22px",
                                            height: "23px",
                                          }}
                                        />

                                        <span style={{ fontSize: "0.9375rem" }}>
                                          {t("Mentors")}
                                        </span>
                                      </div>
                                    </li>

                                    <li class="nav-item d-flex">
                                      <div
                                        class={
                                          this.state.mentorRegPage
                                            ? "nav-link-body-sideMenu-active"
                                            : "nav-link-body-sideMenu"
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "2.3rem",
                                          marginTop: "0.3rem",
                                        }}
                                        onClick={this.gotoMentorRegPage}
                                      >
                                        {this.state.mentorRegPage ? (
                                          <FaRegIdCard
                                            style={{
                                              marginRight: "11px",
                                              width: "24px",
                                              height: "23px",
                                              // color: "#A00030",
                                            }}
                                          />
                                        ) : (
                                          <FaRegIdCard
                                            style={{
                                              marginRight: "11px",
                                              width: "24px",
                                              height: "23px",
                                              // color: "#A00030",
                                            }}
                                          />
                                        )}
                                        <span
                                          class="text-overflow"
                                          style={{ fontSize: "0.9375rem" }}
                                        >
                                          {t("Registration")}{" "}
                                        </span>
                                      </div>
                                      <div
                                        className=""
                                        onClick={this.gotoMentorRegPage}
                                        style={{
                                          marginTop: "3px",
                                          flex: 1,
                                          marginLeft: "1.9rem",
                                        }}
                                      >
                                        {!this.state.showRegMenu ? (
                                          <IoIosArrowForward
                                            className="arrowIcon-home"
                                            style={{
                                              color: "#000000",
                                            }}
                                          />
                                        ) : (
                                          <IoIosArrowDown className="arrowIcon-home bg-color" />
                                        )}
                                      </div>
                                    </li>

                                    <li class="home-item-card">
                                      {this.state.showRegMenu &&
                                        this.state.registrationData.map(
                                          (data, index) => (
                                            <p
                                              class={
                                                this.state.activeRegIndex ===
                                                index
                                                  ? "bg-color"
                                                  : ""
                                              }
                                              onClick={() =>
                                                this.changeRegPage(index)
                                              }
                                            >
                                              {t(`${data}`)}
                                            </p>
                                          )
                                        )}
                                    </li>

                                    {this.props.defaultWorkSpaceId === 0 && (
                                      <>
                                        <li class="nav-item">
                                          <div
                                            class={
                                              this.state.mentorRegPage
                                                ? "nav-link-body-sideMenu-active"
                                                : "nav-link-body-sideMenu"
                                            }
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "2.3rem",
                                              marginTop: "0.3rem",
                                            }}
                                            // onClick={() =>
                                            //   window.open(
                                            //     "https://yuukke.com/become-a-mentor/",
                                            //     "_blank"
                                            //   )
                                            // }
                                            onClick={this.gotoMentorRegPage}
                                          >
                                            <FaRegIdCard
                                              style={{
                                                marginRight: "8px",
                                                width: "24px",
                                                height: "23px",
                                              }}
                                            />

                                            <span
                                              style={{ fontSize: "0.9375rem" }}
                                            >
                                              {t("Mentor Registration")}
                                            </span>
                                          </div>
                                        </li>
                                        {!this.props.sellerExist && (
                                          <li class="nav-item">
                                            <div
                                              class={
                                                this.state.sellerRegPage
                                                  ? "nav-link-body-sideMenu-active"
                                                  : "nav-link-body-sideMenu"
                                              }
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "2.3rem",
                                              }}
                                              // onClick={() =>
                                              //   window.open(
                                              //     "https://yuukke.com/merchant/",
                                              //     "_blank"
                                              //   )
                                              // }
                                              onClick={this.gotoSellerRegPage}
                                            >
                                              <FiTruck
                                                style={{
                                                  marginRight: "9px",
                                                  width: "24px",
                                                  height: "23px",
                                                }}
                                              />

                                              <span
                                                style={{
                                                  fontSize: "0.9375rem",
                                                }}
                                              >
                                                {t("Seller Registration")}
                                              </span>
                                            </div>
                                          </li>
                                        )}
                                      </>
                                    )}

                                    <li class="nav-item d-flex">
                                      <div
                                        class={
                                          this.state.marketPlacePage
                                            ? "nav-link active nav-link-body-sideMenu-active"
                                            : "nav-link nav-link nav-link-body-sideMenu"
                                        }
                                        style={{ cursor: "pointer" }}
                                        onClick={this.gotomarketplace}
                                      >
                                        {this.state.marketPlacePage ? (
                                          <img
                                            src={marketFill}
                                            style={{
                                              marginRight: "8px",
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={market}
                                            style={{
                                              marginRight: "8px",
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        )}

                                        <span
                                          class="text-overflow"
                                          style={{
                                            fontSize: "0.9375rem",
                                            marginLeft: "6px",
                                          }}
                                        >
                                          {t("Market Place")}
                                        </span>
                                      </div>
                                    </li>
                                    {this.props.defaultWorkSpaceId === 0 && (
                                      <>
                                        <li class="nav-item">
                                          <div
                                            class={
                                              this.state.catalystTalksPage
                                                ? "nav-link-body-sideMenu-active"
                                                : "nav-link-body-sideMenu"
                                            }
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "32px",
                                              marginTop: "0.3rem",
                                            }}
                                            onClick={() =>
                                              this.gotoExternalResourcePage(
                                                1000,
                                                "catalyst-talks"
                                              )
                                            }
                                          >
                                            {this.state.catalystTalksPage ? (
                                              <RiKakaoTalkLine
                                                style={{
                                                  marginRight: "6px",
                                                  width: "29px",
                                                  height: "28px",
                                                  color: "#A00030",
                                                }}
                                              />
                                            ) : (
                                              <RiKakaoTalkLine
                                                style={{
                                                  marginRight: "6px",
                                                  width: "29px",
                                                  height: "28px",
                                                }}
                                              />
                                            )}
                                            <span
                                              style={{ fontSize: "0.9375rem" }}
                                            >
                                              {t("Catalyst Talks")}
                                            </span>
                                          </div>
                                        </li>
                                        <li class="nav-item">
                                          <div
                                            class={
                                              this.state.bizFeaturesPage
                                                ? "nav-link-body-sideMenu-active"
                                                : "nav-link-body-sideMenu"
                                            }
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "34px",
                                              marginTop: "0.3rem",
                                            }}
                                            onClick={() =>
                                              this.gotoExternalResourcePage(
                                                1001,
                                                "biz-features"
                                              )
                                            }
                                          >
                                            {this.state.bizFeaturesPage ? (
                                              <FiGlobe
                                                style={{
                                                  marginRight: "11px",
                                                  width: "24px",
                                                  height: "23px",
                                                  color: "#A00030",
                                                }}
                                              />
                                            ) : (
                                              <FiGlobe
                                                style={{
                                                  marginRight: "11px",
                                                  width: "24px",
                                                  height: "23px",
                                                }}
                                              />
                                            )}
                                            <span
                                              style={{ fontSize: "0.9375rem" }}
                                            >
                                              {t("Biz Features")}
                                            </span>
                                          </div>
                                        </li>
                                      </>
                                    )}

                                    <li class="nav-item d-flex">
                                      <div
                                        class="nav-link nav-link nav-link-body-sideMenu"
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "5px",
                                        }}
                                        onClick={() => this.coursePage()}
                                        // onClick={() =>
                                        //   window.open(
                                        //     "https://courses.yuukke.com/login/index.php?access_token=Ym1feXV1a2tlX2NvdXJzZXNfMjAyMw==",
                                        //     "_blank"
                                        //   )
                                        // }
                                      >
                                        <BsJournalBookmark
                                          style={{
                                            width: "20px",
                                            height: "19px",
                                          }}
                                        />

                                        <span
                                          style={{
                                            fontSize: "0.9375rem",
                                            marginLeft: "11px",
                                          }}
                                        >
                                          {t("Courses")}
                                        </span>
                                      </div>
                                    </li>

                                    <li class="nav-item">
                                      <div
                                        class={
                                          this.state.calender
                                            ? "nav-link active nav-link-body-sideMenu-active"
                                            : "nav-link nav-link nav-link-body-sideMenu"
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "4px",
                                        }}
                                        onClick={this.gotoCalender}
                                      >
                                        {this.state.gotoCalender ? (
                                          <BsCalendar2Check
                                            style={{
                                              marginRight: "11px",
                                              width: "24px",
                                              height: "23px",
                                              color: "#A00030",
                                            }}
                                          />
                                        ) : (
                                          <BsCalendar2Check
                                            style={{
                                              marginRight: "11px",
                                              width: "24px",
                                              height: "23px",
                                            }}
                                          />
                                        )}

                                        <span style={{ fontSize: "0.9375rem" }}>
                                          {t("Calender")}
                                        </span>
                                      </div>
                                    </li>
                                    <li class="nav-item">
                                      <div
                                        class={
                                          this.state.meeting
                                            ? "nav-link active nav-link-body-sideMenu-active"
                                            : "nav-link nav-link nav-link-body-sideMenu"
                                        }
                                        style={{ cursor: "pointer" }}
                                        onClick={this.gotoMeeting}
                                      >
                                        {this.state.meeting ? (
                                          <img
                                            src={meetingFill}
                                            style={{
                                              marginRight: "8px",
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={meeting}
                                            style={{
                                              marginRight: "8px",
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        )}
                                        <span style={{ fontSize: "0.9375rem" }}>
                                          Meetings
                                        </span>
                                      </div>
                                    </li>

                                    <li class="nav-item">
                                      <div
                                        class={
                                          this.state.helpPage
                                            ? "nav-link active nav-link-body-sideMenu-active"
                                            : "nav-link nav-link nav-link-body-sideMenu"
                                        }
                                        style={{ cursor: "pointer" }}
                                        onClick={this.gotomyrequest}
                                      >
                                        {this.state.helpPage ? (
                                          <BsFillPatchQuestionFill
                                            style={{
                                              marginRight: "8px",
                                              width: "25px",
                                              height: "25px",
                                              // color:"green"
                                            }}
                                          />
                                        ) : (
                                          <BsFillPatchQuestionFill
                                            style={{
                                              marginRight: "8px",
                                              width: "25px",
                                              height: "25px",
                                              // color:"green"
                                            }}
                                          />
                                        )}

                                        <span style={{ fontSize: "0.9375rem" }}>
                                          {t("Need Support")}
                                        </span>
                                      </div>
                                    </li> 
                                     <li class="nav-item">
                                      <div
                                        class={
                                          this.state.helpPage
                                            ? "nav-link active nav-link-body-sideMenu-active"
                                            : "nav-link nav-link nav-link-body-sideMenu"
                                        }
                                        style={{ cursor: "pointer" }}
                                        onClick={this.gotomyrequest}
                                      >
                                        {this.state.helpPage ? (
                                          <BsFillPatchQuestionFill
                                            style={{
                                              marginRight: "8px",
                                              width: "25px",
                                              height: "25px",
                                              // color:"green"
                                            }}
                                          />
                                        ) : (
                                          <BsFillPatchQuestionFill
                                            style={{
                                              marginRight: "8px",
                                              width: "25px",
                                              height: "25px",
                                              // color:"green"
                                            }}
                                          />
                                        )}

                                        <span style={{ fontSize: "0.9375rem" }}>
                                          {t("Need Support")}
                                        </span>
                                      </div>
                                    </li>
                                  </ul> */}
                                </div>

                                <div
                                  class="navigationsMenu-card-footer text-center py-2 mt-3"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.gotomyprofile()}
                                >
                                  <p
                                    class="btn btn-link btn-sm"
                                    style={{
                                      color: "#A00030",
                                      fontSize: "0.9375rem",
                                    }}
                                  >
                                    {t("View Profile")}{" "}
                                  </p>
                                </div>
                                {/* <div
                                  class="navigationsMenu-card-footer text-center py-2 mt-3"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.gotomypremiumProfile()}
                                >
                                  <p
                                    class="btn btn-link btn-sm"
                                    style={{
                                      color: "#A00030",
                                      fontSize: "0.9375rem",
                                    }}
                                  >
                                    {t("Try Premium")}{" "}
                                  </p>
                                </div> */}
                              </div>

                              {/* <ul class="nav small mt-4 justify-content-center lh-1">
                                                            <li class="nav-item">
                                                                <a class="nav-link" href="my-profile-about.html">About</a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a class="nav-link" href="settings.html">Settings</a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a class="nav-link" target="_blank" href="https://support.webestica.com/login">Support </a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a class="nav-link" target="_blank" href="docs/index.html">Docs </a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a class="nav-link" href="help.html">Help</a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a class="nav-link" href="privacy-and-terms.html">Privacy & terms</a>
                                                            </li>
                                                        </ul> */}

                              <div
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  color: "#676a79",
                                  fontSize: "0.875em",
                                  paddingTop: "12px",
                                }}
                              >
                                <p
                                  class="small text-center mt-1"
                                  onClick={() =>
                                    window.open(
                                      "http://www.betamonks.com/",
                                      "_blank"
                                    )
                                  }
                                >
                                  ©2022{" "}
                                  <span class="text-body" target="_blank">
                                    {" "}
                                    {/* {t("Betamonks Innovations Private LTD.")}{" "} */}
                                    {t(
                                      "Yuukke Global Ventures Private Limited."
                                    )}{" "}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            )}
          </>
        )}
        {this.state.popUpAlert && this.popUpAlert()}
      </>
    );
  }

  gotomypremiumProfile = () => {
    this.props.history.replace("/premium-subscription");
  };

  showRegMenu = () => {
    this.setState({
      showRegMenu: !this.state.showRegMenu,
    });
  };
  showResourceMenu = () => {
    this.setState({
      showResourceMenu: !this.state.showResourceMenu,
    });
  };
  showGroupMenu = () => {
    this.setState({
      showGroupMenu: !this.state.showGroupMenu,
    });
  };
  changeResource = (index) => {
    window.scrollTo(0, 0);
    if (!this.state.ouOfferingsPage) {
      this.setState({
        ouOfferingsPage: true,
      });
      this.props.getNavigationType(8);
    }
    this.setState({
      activeResourceIndex: index,
    });
    if (index === 0) {
      this.props.history.push(`/${this.props.workspaceType}/learning-hub`);
    } else if (index === 1) {
      this.props.history.push(
        `/${
          this.props.workspaceType
        }/special-resource/${1001}/${"biz-features"}`
      );
    } else {
      this.props.history.push(
        `/${
          this.props.workspaceType
        }/special-resource/${1000}/${"catalyst-talks"}`
      );
    }
  };
  changeGroup = (index) => {
    window.scrollTo(0, 0);
    if (!this.state.groupsPage) {
      this.setState({
        groupsPage: true,
      });
    }
    this.setState({
      activeIndex: index,
    });
    if (index === 0) {
      this.props.history.push(`/${this.props.workspaceType}/mygroups`);
    } else if (index === 1) {
      this.props.history.push(`/${this.props.workspaceType}/groupsList`);
    } else {
      this.props.history.push(`/${this.props.workspaceType}/forumCommunity`);
    }
  };
  changeRegPage = (index) => {
    window.scrollTo(0, 0);
    if (!this.state.mentorRegPage) {
      this.setState({
        mentorRegPage: true,
      });
      this.props.getNavigationType(11);
    }
    this.setState({
      activeRegIndex: index,
    });
    if (index === 0) {
      this.props.history.push(
        `/${this.props.workspaceType}/mentor-registration`
      );
    } else {
      this.props.history.push(
        `/${this.props.workspaceType}/seller-registration`
      );
    }
  };
  onRouteChanged = () => {
    console.log("currentPath", this.state.currentPath);

    this.setState({
      currentPath: window.location.pathname,
    });
  };

  gotogroupDescription(id, name) {
    var gName = name.trim();
    gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
    this.props.history.push(
      `/${this.props.workspaceType}/myGroupDescription/${id}/${gName}`
    );
  }

  gotohome = () => {
    this.setState({
      showResourceMenu: false,
      showGroupMenu: false,
    });
    this.props.history.push(`/${this.props.workspaceType}/home`);
  };

  gotoclassroom() {
    this.props.history.push("/classroom");
  }
  gotomynetwork = () => {
    this.setState({
      showResourceMenu: false,
      showGroupMenu: false,
    });
    this.props.history.push(`/${this.props.workspaceType}/mynetwork`);
    this.getSubscriptionDetails("friends");
  };
  getSubscriptionDetails = (type) => {
    // this.props.getSubscriptionStatus(type);
  };
  gotoCalender() {
    this.setState({
      homePage: false,
      connectionsPage: false,
      groupsPage: false,
      contestPage: false,
      mentorPage: false,
      marketPlacePage: false,
      contestResultPage: false,
      ouOfferingsPage: false,
      meeting: false,
      helpPage: false,
      calender: true,
      sellerRegPage: false,
      catalystTalksPage: false,
      bizFeaturesPage: false,
      futureEentrepreneursPage: false,
      userListPage: false,
    });

    this.props.history.push(`/${this.props.workspaceType}/calendar`);
    this.props.getNavigationType(14);
  }
  gotoevents() {
    this.props.history.push("/upcomingEvents");
  }

  gotogroups = () => {
    // this.showGroupMenu();

    this.setState({
      showGroupMenu: !this.state.showGroupMenu,
      activeIndex: 1,
      showResourceMenu: false,
    });
    this.props.history.push(`/${this.props.workspaceType}/groupsList`);
  };

  gotocgroups() {
    this.props.history.push(`/${this.props.workspaceType}/createGroup`);
  }

  gotomarketplace() {
    this.setState({
      homePage: false,
      connectionsPage: false,
      groupsPage: false,
      contestPage: false,
      mentorPage: false,
      marketPlacePage: true,
      contestResultPage: false,
      ouOfferingsPage: false,
      meeting: false,
      helpPage: false,
      mentorRegPage: false,
      sellerRegPage: false,
      calender: false,
      catalystTalksPage: false,
      bizFeaturesPage: false,
      futureEentrepreneursPage: false,
      userListPage: false,
    });
    this.props.history.push(`/${this.props.workspaceType}/subscriber-form`);
    //window.open("https://marketplace.yuukke.com/", "_blank");
    this.props.getNavigationType(4);
  }
  gotoMentorPage = () => {
    this.setState({
      showResourceMenu: false,
      showGroupMenu: false,
    });
    this.props.history.push(`/${this.props.workspaceType}/mentors`);
  };
  gotocevents() {
    this.props.history.push("/createEvent");
  }

  gotomyrequest() {
    this.setState({
      homePage: false,
      connectionsPage: false,
      groupsPage: false,
      meeting: false,
      contestPage: false,
      mentorPage: false,
      marketPlacePage: false,
      contestResultPage: false,
      ouOfferingsPage: false,
      helpPage: true,
      mentorRegPage: false,
      sellerRegPage: false,
      calender: false,
      catalystTalksPage: false,
      bizFeaturesPage: false,
      futureEentrepreneursPage: false,
      userListPage: false,
    });
    this.props.getNavigationType(10);
    this.props.history.push(`/${this.props.workspaceType}/my-requests`);
  }
  gotoOurOfferings = () => {
    // this.props.history.push("/classroom");
    // this.showResourceMenu();

    this.setState({
      showGroupMenu: false,
      showResourceMenu: !this.state.showResourceMenu,
      activeResourceIndex: 0,
    });
    this.props.history.push(`/${this.props.workspaceType}/learning-hub`);
  };

  gotoMeeting() {
    //  this.MixpanelcallMynetWork();

    this.props.history.push(`/${this.props.workspaceType}/forum-community`);
    this.setState({
      homePage: false,
      connectionsPage: false,
      groupsPage: false,
      contestPage: false,
      mentorPage: false,
      marketPlacePage: false,
      contestResultPage: false,
      ouOfferingsPage: false,
      meeting: true,
      helpPage: false,
      mentorRegPage: false,
      sellerRegPage: false,
      calender: false,
      catalystTalksPage: false,
      bizFeaturesPage: false,
      futureEentrepreneursPage: false,
      userListPage: false,
    });
    this.props.getNavigationType(9);
    this.props.history.push("/meeting");
  }

  gotochallenges() {
    this.setState({
      homePage: false,
      connectionsPage: false,
      groupsPage: false,
      contestPage: true,
      mentorPage: false,
      marketPlacePage: false,
      contestResultPage: false,
      ouOfferingsPage: false,
      meeting: false,
      helpPage: false,
      mentorRegPage: false,
      sellerRegPage: false,
      calender: false,
      catalystTalksPage: false,
      bizFeaturesPage: false,
      futureEentrepreneursPage: false,
      userListPage: false,
    });
    this.props.history.push(`/${this.props.workspaceType}/career-lab`);

    this.props.getNavigationType(3);
    // this.MixpanelcallChallenge();
  }

  gotoChallengeDescription() {
    this.setState({
      homePage: false,
      connectionsPage: false,
      groupsPage: false,
      mentorPage: false,
      contestPage: true,
      marketPlacePage: false,
      contestResultPage: false,
      ouOfferingsPage: false,
      meeting: false,
      helpPage: false,
      mentorRegPage: false,
      sellerRegPage: false,
      calender: false,
      catalystTalksPage: false,
      bizFeaturesPage: false,
      futureEentrepreneursPage: false,
      userListPage: false,
    });
    this.props.getNavigationType(3);
    this.props.history.push(
      "/challengeDescription/50/unleash-your-hidden-talent"
    );
  }

  gotoContestResult() {
    this.setState({
      homePage: false,
      connectionsPage: false,
      groupsPage: false,
      mentorPage: false,
      contestPage: false,
      marketPlacePage: false,
      contestResultPage: true,
      ouOfferingsPage: false,
      meeting: false,
      helpPage: false,
      mentorRegPage: false,
      sellerRegPage: false,
      calender: false,
      catalystTalksPage: false,
      bizFeaturesPage: false,
      futureEentrepreneursPage: false,
      userListPage: false,
    });
    this.props.history.push("/contest-results");
    this.props.getNavigationType(7);
  }

  gotoMentorRegPage = () => {
    this.props.history.push(`/${this.props.workspaceType}/mentor-registration`);
    this.props.getNavigationType(11);

    // if (giveUserAction(this.props.loginDetails?.profile_complete)) {
    //   console.log("user-action");
    //   this.props.needPhotVerification(true);
    // } else {
    //   if (!this.state.mentorRegPage) {
    //     Swal.fire({
    //       title: "You want to enroll  as a mentor",
    //       icon: "warning",
    //       type: "warning",
    //       showCancelButton: true,
    //       confirmButtonText: "Yes",
    //       cancelButtonText: "No",
    //       reverseButtons: false,
    //     }).then((result) => {
    //       if (result.value) {
    //         this.setState({
    //           activeRegIndex: 0,
    //           showRegMenu: !this.state.showRegMenu,
    //           activeResourceIndex: null,
    //           homePage: false,
    //           connectionsPage: false,
    //           groupsPage: false,
    //           contestPage: false,
    //           mentorPage: false,
    //           marketPlacePage: false,
    //           contestResultPage: false,
    //           ouOfferingsPage: false,
    //           meeting: false,
    //           helpPage: false,
    //           mentorRegPage: true,
    //           sellerRegPage: false,
    //           calender: false,
    //           catalystTalksPage: false,
    //           bizFeaturesPage: false,
    //           futureEentrepreneursPage: false,
    //           userListPage: false,
    //         });
    //         // window.open("http://test.yuukke.betalearnings.com/mentor-program.html", "_blank");
    //         // window.open("https://yuukke.com/mentor-program.html", "_blank")

    //         // this.props.history.push(
    //         //   `/${this.props.workspaceType}/mentor-registration`
    //         // );
    //         this.props.history.push(
    //           `/${this.props.workspaceType}/mentor-update/${"mentor-request"}`
    //         );
    //         this.props.getNavigationType(11);
    //       }
    //     });
    //   }
    // }
  };
  gotoUserList = () => {
    this.setState({
      showResourceMenu: false,
      showGroupMenu: false,
    });
    this.props.history.push(`/${this.props.workspaceType}/userList`);
  };
  gotoSellerRegPage() {
    this.props.history.push(`/${this.props.workspaceType}/seller-registration`);
    this.props.getNavigationType(12);
    // if (giveUserAction(this.props.loginDetails?.profile_complete)) {
    //   console.log("user-action");
    //   this.props.needPhotVerification(true);
    // } else {
    //   if (!this.state.sellerRegPage) {

    // Swal.fire({
    //   title: "You want to enrol  as a seller",
    //   icon: "warning",
    //   type: "warning",
    //   showCancelButton: true,
    //   confirmButtonText: "Yes",
    //   cancelButtonText: "No",
    //   reverseButtons: false,
    // }).then((result) => {
    //   if (result.value) {
    //     Swal.fire({
    //       title: "Registerd successfully",
    //       icon: "success",
    //       type: "success",
    //       showConfirmButton: true,
    //     }).then((result) => {
    //       this.props.sellerRequest();
    //       //this.props.history.push(`/`)
    //     });
    // this.setState({
    //   homePage: false,
    //   connectionsPage: false,
    //   groupsPage: false,
    //   contestPage: false,
    //   mentorPage: false,
    //   marketPlacePage: false,
    //   contestResultPage: false,
    //   ouOfferingsPage: false,
    //   meeting: false,
    //   helpPage: false,
    //   mentorRegPage: false,
    //   sellerRegPage: true,
    //   calender: false,
    //   catalystTalksPage: false,
    //   bizFeaturesPage: false,
    //   futureEentrepreneursPage: false,
    //   userListPage: false,
    // });
    // window.open("http://test.yuukke.betalearnings.com/seller.html", "_blank");
    // window.open("https://yuukke.com/seller.html", "_blank");
    // this.props.history.push(
    //   `/${this.props.workspaceType}/seller-registration`
    // );
    // this.props.history.push(
    //   `/${this.props.workspaceType}/subscriber-form`
    // );

    // this.props.getNavigationType(12);
    // }
    // });
    // }
  }

  gotoExternalResourcePage = (type, name) => {
    // this.props.history.push(`/special-resource/${type}/${name}`);
    // if (name === "catalyst-talks") {
    //   this.props.getNavigationType(13);
    // } else if (name === "biz-features") {
    //   this.props.getNavigationType(15);
    // } else if (name === "future-entrepreneurs") {
    this.setState({
      showResourceMenu: false,
      showGroupMenu: false,
    });
    this.props.history.push(`/${this.props.workspaceType}/directory`);
    // }
  };
  needHelpPage = () => {
    this.closeHelpModel();
    // this.props.history.push(
    //   `/${
    //     this.props.workspaceType ? this.props.workspaceType : "default"
    //   }/my-requests`
    // );
    this.setState({
      homePage: false,
      connectionsPage: false,
      meeting: false,
      groupsPage: false,
      mentorPage: false,
      contestPage: false,
      marketPlacePage: false,
      contestResultPage: false,
      ouOfferingsPage: false,
      helpPage: false,
      mentorRegPage: false,
      sellerRegPage: false,
      calender: false,
      catalystTalksPage: false,
      bizFeaturesPage: false,
      futureEentrepreneursPage: false,
      userListPage: false,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  gotomyprofile() {
    this.props.history.push("/my-profile");
  }
  handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        `${APPCONFIG.BASE_URL}userProfile/${this.props.loginDetails?.vcardUiid}`
      );
      this.setState({
        copyData: true,
      });
      toast.success("Copied");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  popUpAlertStatus = () => {
    if (this.props.loginDetails?.vcardIsPresent) {
      window.open(
        `${APPCONFIG.BASE_URL}userProfile/${this.props.loginDetails.vcardUiid}`
      );
    } else {
      this.setState({
        popUpAlert: !this.state.popUpAlert,
      });
    }
  };
  popUpAlert() {
    const { t } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <ModalBody show="true" onHide={this.popUpAlertStatus}>
          <Modal.Body>
            <Form.Group className="formInput">
              <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                {t("Your vcard is empty")}
              </Form.Label>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.popUpAlertStatus} variant="primary">
              {t("Close")}
            </Button>
          </Modal.Footer>
        </ModalBody>
      </div>
    );
  }
  checkAuth = () => {
    console.log("workSpaceComplete", this.props.workSpaceComplete);
    var workspace = sessionStorage.getItem("WORKSPACE");

    if (
      !this.props.loginDetails?.profile_complete &&
      !this.props.workSpaceComplete &&
      workspace !== "default"
    ) {
      console.log("LOGIN CHECK");
      //Workspace setup
      var invited_email = sessionStorage.getItem("invited_email");
      var dnsp = sessionStorage.getItem("dnsp");

      //Check any default workspace present
      if (
        workspace !== "default" &&
        (!this.props.loginDetails.biography || !this.props.loginDetails.dob)
      ) {
        console.log("Without Bio");
        this.props.history.push(`/workspace`);
      } else if (
        this.props.workspaceType &&
        this.props.loginDetails.userWorkSpaceList?.length > 1 &&
        this.props.loginDetails?.userWorkSpace?.workSpaceId !== 0
      ) {
        console.log("LOGIN VIA WORKSPACE default");
        this.props.workSpaceLoginStats(true);
      }
      // else if (this.props.loginDetails.userWorkSpaceList?.length > 1) {
      //   //Workspace user without photo setup
      //   this.props.history.push(`/workspace`);
      // }
      else if (dnsp && invited_email) {
        //References via email
        this.props.referralUserAddToWorkSpace(invited_email, dnsp);
        this.props.history.push(`/workspace`);
      } else {
        const workspace = sessionStorage.getItem("WORKSPACE");
        this.props.history.push(`/${workspace}/home`);
        // this.props.history.push(`/photo-set-up`);
      }
    } else if (!this.props.loginDetails?.profile_complete) {
      let path = window.location.pathname;
      let regex = /\/(.*?)\//;
      let removePatName = path.match(regex);
      let urlPath = removePatName && removePatName[1] ? removePatName[1] : "";
      if (urlPath === "default") {
        //Access wrong url
        const workspace = sessionStorage.getItem("WORKSPACE");
        this.props.history.push(`/${workspace}/home`);
      }
    }
  };

  checkAvailable = (id, type) => {
    let options = this.props.menuOptions;

    if (id == 0) {
      return true;
    } else if (type === "directoryMenu") {
      return options.directoryMenu;
    } else if (type == "careerLab") {
      return options.careerLab;
    } else if (type == "Biz Features") {
      return options.bizFeatures;
    } else if (type == "Catalyst Talks") {
      return options.catalystTalks;
    } else {
      return true;
    }
  };
  coursePage = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      window.open(
        "https://courses.yuukke.com/login/index.php?access_token=Ym1feXV1a2tlX2NvdXJzZXNfMjAyMw==",
        "_blank"
      );
    }
  };
  openPopup = () => {
    console.log("click", this.state.openPopup);
    this.setState({
      openPopup: false,
    });
    this.props.announcementStatus("");
  };
  showFriendsProfile = (userId) => {
    this.props.getNavigationType(1);
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
    // }
  };
  moveToMenu = (menu) => {
    this.setState({
      showResourceMenu: false,
      showGroupMenu: false,
    });
    this.props.history.push(`/${this.props.workspaceType}/${menu}`);
  };
}

const mapStateToProps = function (state) {
  return {
    menuOptions: state.commonApi.getWorkSpaceOption,
    loginDetails: state.loginDetailsApi.loginDetails,
    tagDatas: state.subjects.myGroupsBasedMessageMembers,
    authUser: state.loginDetailsApi.authUser,
    FriendsList: state.commonApi.friendslist,
    loading: state.commonApi.loading,
    navigationType: state.commonApi.navigationType,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    workspaceListHeader: state.commonApi.workspaceListHeader,
    defaultWorkSpaceId:
      state.loginDetailsApi.loginDetails?.userWorkSpace?.workSpaceId,
    userWorkSpaceList: state.loginDetailsApi.loginDetails?.userWorkSpaceList
      ? state.loginDetailsApi.loginDetails?.userWorkSpaceList
      : [],
    wrongUrlStatus: state.commonApi.wrongUrlStatus,
    authenticated: state.loginDetailsApi.keycloakAuthenticated,
    workSpaceComplete: state.commonApi.workSpaceLogin,
    userRole: state.loginDetailsApi.loginDetails?.userWorkSpace?.role,
    requireMentMenu: state.commonApi.requireMentMenu,
    needToPopUp: state.commonApi.needToPopUp,
    needAnnouncement: state.loginDetailsApi.needAnnouncement,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    getAllMyGroupEvents: (type, page) =>
      dispatch(getAllMyGroupEvents(type, page)),
    getMyGroupsByMembersandMessages: () =>
      dispatch(getMyGroupsByMembersandMessages()),
    getNavigationType: (type) => dispatch(getNavigationType(type)),
    getWorkSpaceType: (type, role, id) =>
      dispatch(getWorkSpaceType(type, role, id)),
    workSpaceUserLogin: (id) => dispatch(workSpaceUserLogin(id)),
    workSpaceWrongUrl: (status, url) =>
      dispatch(workSpaceWrongUrl(status, url)),
    referralUserAddToWorkSpace: (name, dnsp) =>
      dispatch(referralUserAddToWorkSpace(name, dnsp)),
    workSpaceLoginStats: (status) => dispatch(workSpaceLoginStats(status)),
    sellerRequest: () => dispatch(sellerRequest()),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
    announcementStatus: (data) => dispatch(announcementStatus(data)),
    getSubscriptionStatus: (type) => dispatch(getSubscriptionStatus(type)),
  };
};

// export default connect(
//   mapStateToProps,
//   mapDispacthToProps
// )(withTranslation()(withRouter(NavigationsMenu)));

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(NavigationsMenu)));
export default React.memo(ConnectedComponent);
