import React, { Component } from "react";
import { statusConfig } from "./UserInfo";
import Avatar from "@material-ui/core/Avatar";

export default class AreaOfInterestList extends Component {
  render() {
    const { imgUrl, userName, status, tagLine, date, isActive, onClick } =
      this.props;
    const active = isActive ? "activeChat" : " ";
    // const nameLabel = userName.split(" ");
    // const firstLetter =
    //   nameLabel.length > 1
    //     ? `${nameLabel[0].substring(0, 1)}${nameLabel[1].substring(0, 1)}`
    //     : nameLabel[0].substring(0, 1);
    return (
      <div
        className={`${active} chatListWrapper flexDefault`}
        onClick={onClick}
      >
        <div className="chatListDetails">
          <h3 className="chatListName">{userName}</h3>
          {/* <p className="chatListTagLine">{tagLine}</p> */}
        </div>
        <div className="chatDateWrapper">
          {/* <p>{date}</p> */}
        </div>
      </div>
    );
  }
}
