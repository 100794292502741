import React, { Component } from "react";
import PhotoIcon from "@material-ui/icons/Photo";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DescriptionIcon from "@material-ui/icons/Description";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { ToastContainer, toast } from "react-toastify";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import "../../css/mysocial.css";
import "../../css/mysocialprofile.css";
import "../../css/yuukkeTutoring.css";
import PostService from "../../../api/PostService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import AuthService from "../../../session/AuthService";
import { Chip } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
//import ChooseFileComponent from "../../../components/ChooseFileComponent";
import CommunityChooseFile from "./CommunityChooseFile";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router";
import { Button, Form, Modal, InputGroup, FormControl } from "react-bootstrap";
import * as imageConversion from "image-conversion";
import Crop from "./Crop";
import { FiArrowRight } from "react-icons/fi";
import ProfileService from "../../../api/ProfileService";
//FiArrowRight
class CommunityFileOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUrl: null,
      type: null,
      mediaPost: "",
      teaching: [],
      tags: [],
      question: "",
      removeStatus: true,
      addTagsState: false,
      profilePhotoAfterCrop: false,
      addInterestState: false,
      hideCommunity: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.BacktoHome = this.BacktoHome.bind(this);
    this.getQuestionMediaFiles = this.getQuestionMediaFiles.bind(this);
    this.editPhoto = this.editPhoto.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onImagePreview = this.onImagePreview.bind(this);
    this.profilePhotoUpdate = this.profilePhotoUpdate.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    if (this.props.fileOption) {
      this.removeFile("parent");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fileOption !== prevProps.fileOption) {
      if (this.props.fileOption) {
        this.removeFile("parent");
      }
    }
  }
  BacktoHome() {
    this.props.history.push(`/home`);
  }
  render() {
    const { t } = this.props;
    const { type, fileUrl } = this.state;
    const onUploadSuccess = async (file) => {
      console.log("FILES");
      console.log(file);
      let preview = document.getElementById("upload-preview");
      this.setState({ fileState: false, removeStatus: false });

      let files = file;
      if (files.size > 200000) {
        imageConversion
          .compressAccurately(files, {
            size: 200,
            maxwidth: 300,
            maxheight: 200,
            orientation: 2,
            scale: 0.5,
          })
          .then((res) => {
            this.setState({ file: res });
          });
      } else {
        this.setState({ file: files });
      }
      console.log(files);
      this.onImagePreview(files);
    };

    const setChoosedImage = async (file) => {
      console.log("Data", file);
      console.log(file);
      this.setState({ fileData: file, hideCommunity: true });
    };
    return (
      <div
        className="screenInnerGap"
        style={{
          marginLeft: "22px",
          marginRight: "22px",
          marginBottom: "22px",
          marginTop: "80px",
        }}
      >
        <ToastContainer position="top-right" autoClose={5000} />
        <div className="setupPhotoCardWrapperContainer">
          <div className="setupPhotoCardWrapper">
            {" "}
            <Grid container spacing={2}>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <div className="selectCard" onClick={this.editPhoto}>
                  <CommunityChooseFile
                    acceptedType=".jpg,.jpeg,.png"
                    setChoosedImage={setChoosedImage}
                  />
                </div>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <div className="selectCard">
                  {/* <center style={{ paddingTop: "90px", color: "blue" }} onClick={this.DragFile}> */}
                  {!this.state.hideCommunity && (
                    <center style={{ paddingTop: "90px", color: "blue" }}>
                      Drag me !
                    </center>
                  )}
                  {this.state.hideCommunity && (
                    <Crop
                      onUploadSuccess={onUploadSuccess}
                      fileData={this.state.fileData}
                    />
                  )}
                  {/* </center> */}
                </div>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <div className="selectCard">
                  {!this.state.image && (
                    <center style={{ paddingTop: "90px", color: "gray" }}>
                      Don't touch me
                    </center>
                  )}
                  {this.state.image && (
                    <img
                      id="target"
                      alt="photo"
                      style={{ width: "100%", height: "100%" }}
                      src={this.state.image}
                    />
                  )}
                </div>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <div className="selectCardArrow">
                  <center>
                    <FiArrowRight onClick={this.profilePhotoUpdate} />
                  </center>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
  profilePhotoUpdate(e) {
    if (this.state.image == undefined) {
      toast.warn("Please select photo");
    } else {
      e.preventDefault();
      e.stopPropagation();
      const formData = new FormData();
      formData.append("profilePhoto", this.state.file);
      formData.append("firstTimeSetup", "true");
      const config = { headers: { "content-type": "multipart/form-data" } };
      ProfileService.updatePhoto(formData, config)
        .then((response) => {
          var result = response.data;
          toast.success("Update successfully");
          this.BacktoHome();
        })
        .catch((error) => {
          toast.error("Please select photo");
          console.log(error);
        });
    }
  }
  onImagePreview(files) {
    if (files) {
      this.setState({
        image: URL.createObjectURL(files),
      });
    }
  }

  editPhoto() {
    this.setState({ hideCommunity: true });
  }

  handleClose() {
    this.setState({ hideCommunity: false });
  }

  onImagePreview(files) {
    if (files) {
      this.setState({
        image: URL.createObjectURL(files),
      });
    }
  }

  getQuestionBase64String(postFile, type) {
    let getBase64String = {
      resourceString: postFile,
    };

    PostService.getBase64String(getBase64String)
      .then((response) => {
        var value = response.data.data.base64String;
        var base64String = "";
        if (type === "image") {
          base64String = `data:image;base64,${value}`;
          this.setState({ type: "image/png" });
        } else if (type === "video") {
          base64String = `data:video/mp4;base64,${value}`;
          this.setState({ type: "video/mp4" });
        } else if (type === "audio") {
          base64String = `data:audio/wav;base64,${value}`;
          this.setState({ type: "audio/mpeg" });
        } else if (type === "pdf") {
          base64String = `data:application/pdf;base64,${value}`;
          this.setState({ type: "application/pdf" });
        }

        this.setState({ fileUrl: base64String });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async removeFile(index) {
    if (index == "parent") {
      await this.setState({
        name: "",
        type: null,
        removeStatus: false,
        file: null,
        fileUrl: "",
        fileArray: [],
      });
    } else {
      var inpu = this.state.fileArray;
      if (this.state.fileArray[index]) {
        var val = this.state.fileArray;
        val.splice(index, 1);
        this.setState({
          fileArray: val,
        });
      }
    }

    //  this.props.fileOptionEnable();
  }

  handleChange(file, url, type) {
    // this.setState({ removeStatus: false })
    // // let files = event.target.files[0]
    // this.setState({
    //   // name: event.target.files[0].name,
    //   file: file,
    //   fileUrl: url,
    //   type: type,
    //   name:file.name
    // }, () => {
    //   this.onFormSubmit()
    // }
    // );
  }

  onFormSubmit() {
    let files = this.state.file;
    var type = "file";
    if (this.checkImageURL(this.state.file[0].name)) {
      type = "image";
    } else if (this.checkVideoURL(this.state.file[0].name)) {
      type = "video";
    } else if (this.checkAudioURL(this.state.file[0].name)) {
      type = "audio";
    } else if (this.checkPDFURL(this.state.file[0].name)) {
      type = "pdf";
    } else {
      type = "file";
    }
    this.fileUpload(this.state.file, type);
  }

  checkImageURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  checkVideoURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(mp4|webm|ogg)$/) != null;
  }

  checkAudioURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(wav|mp3)$/) != null;
  }

  checkPDFURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(application|pdf)$/) != null;
  }

  fileUpload(file, type) {
    this.getQuestionMediaFiles(file, type);
  }
  getQuestionMediaFiles(files, type) {
    this.setState({ file: files, type: type });
  }
}

export default withTranslation()(withRouter(CommunityFileOption));
