import React, { useEffect, useState } from "react";
import dummyimage from "../../../img/Yuukke_Cover.jpg";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import ProfileService from "../../../api/ProfileService";
import { API_URL } from "../../../config/app-config";
import { MdOutlineGroups } from "react-icons/md";
import { giveUserAction } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { needPhotVerification } from "../../../redux/actions/commonApiActions";
import MyYuukke from "../../../api/MyYuukke";
import Swal from "sweetalert2";
import moment from "moment";

const CommunityPostDescription = (props) => {
  const { t } = props;
  const [communityData, setData] = useState("");
  const [image, setImage] = useState("");
  const dispatch = useDispatch();
  const [requestStatus, setStatus] = useState("");
  const loginDetails = useSelector(
    (state) => state.loginDetailsApi.loginDetails
  );

  useEffect(() => {
    getGroupDetails();
  }, []);
  const getGroupDetails = () => {
    var subjectId = {
      subjectId: props.subjectId,
    };
    ProfileService.editGroupsEventsV2(subjectId)
      .then((response) => {
        setData(response.data.data);
        setStatus(response.data.data.joinType);
        response.data.data?.grouporeventFiles.slice(0, 1).map((item) => {
          setImage(`${API_URL}auth/image/${item.fileName}`);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const joinCommunity = () => {
    if (giveUserAction(loginDetails?.profile_complete)) {
      console.log("user-action");
      dispatch(needPhotVerification(true));
    } else {
      if (requestStatus !== "requested") {
        Swal.fire({
          title:
            communityData.status === "public"
              ? "Do you want to join the community ?"
              : "Do you want to request the community ?",
          showCancelButton: true,
          confirmButtonText:
            communityData.status === "public" ? "confirm" : "request",
          cancelButtonText: "cancel",
          reverseButtons: true,
          icon: "warning",
        }).then((result) => {
          if (result.value) {
            Swal.fire({
              title:
                communityData.status === "public"
                  ? "Joined Successfully"
                  : "Requested successfully",
              showConfirmButton: true,
              icon: "success",
            }).then((result) => {
              if (communityData.status === "public") {
                let date = moment().format("YYYY-MM-DD");
                let memberList = {
                  userName: "",
                  groupsOrEvents: props.subjectId,
                  paymentAmount: "",
                  joinDate: date,
                };
                MyYuukke.saveMemberList(memberList)
                  .then((response) => {
                    props.updateStatus();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                let data = {
                  subjectId: props.subjectId,
                  type: "request",
                };

                MyYuukke.requestOrAcceptOrReject(data)
                  .then((response) => {
                    setStatus("requested");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            });
          }
        });
      }
    }
  };

  return (
    <div className="community-description">
      <div className="d-flex align-items-center justify-content-center flex-column mb-4">
        {communityData?.subjectName && (
          <div className="group-card mb-2">
            <div style={{ height: "10rem" }}>
              <img
                src={image ? image : dummyimage}
                alt="img"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyimage;
                }}
              />
            </div>

            <h6 className="mt-2 font-weight-bold">
              {communityData.subjectName}
            </h6>
            <p className="bg-color">
              <MdOutlineGroups
                style={{
                  width: "20px",
                  height: "24px",
                  color: "#A00030",
                  paddingBottom: "5px",
                  marginRight: "5px",
                }}
              />{" "}
              {communityData.membersCount}
              {communityData.membersCount === 1
                ? " " + t("member")
                : " " + t("members")}
            </p>

            <p
              className="text-secondary"
              dangerouslySetInnerHTML={{
                __html: communityData.subjectDescription,
              }}
            />
            <div className="d-flex justify-content-center mt-2 mb-2">
              <button onClick={() => joinCommunity()} className="calender-btn">
                {requestStatus === "requested"
                  ? t("Request Pending")
                  : t("Join Community")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(withRouter(CommunityPostDescription));
