import React, { Component } from "react";
import AuthService from "../../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { API_URL } from "../../../config/app-config";
import dummyimage from "../../../img/default_avatar.jpg";
import ForumService from "../../../api/ForumService";
import ProfileService from "../../../api/ProfileService";
import banner from "../../../img/banner.jpg";
import FriendsFinder from "../../../api/FriendsFinder";
class GroupConnectionCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      friendsList: [],
    };
    this.chatNow = this.chatNow.bind(this);
    this.friendRequest = this.friendRequest.bind(this);
    this.friendIgnore = this.friendIgnore.bind(this);
    this.unfriendHit = this.unfriendHit.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.getCoverPhoto(this.props.userId);
  }

  componentWillReceiveProps(nextProps) {}
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      console.log("fl 2");
      console.log(this.props.userId);
      this.getCoverPhoto(this.props.userId);
    }
  }

  render() {
    const { t } = this.props;
    const { userId, displayName, biography, type } = this.props;
    return (
      <>
        <div class="friendlist-card-body">
          <div class="d-md-flex friendlist-align-items-center friendlist-mb-4">
            <div class="friendlist-avatar friendlist-me-3 friendlist-mb-3 friendlist-mb-md-0">
              <a href="#!">
                {" "}
                <img
                  class="friend-avatar-img rounded-circle"
                  src={
                    API_URL + "api/getUserPhoto/" + userId + "/" + displayName
                  }
                  alt="user"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = dummyimage;
                  }}
                />{" "}
              </a>
            </div>
            <div class="friendlist-w-100">
              <div class="d-sm-flex align-items-start">
                <h6 class="mb-0" style={{ color: "#14191e" }}>
                  {displayName}{" "}
                </h6>
              </div>
              <p class="small ms-sm-2 mb-0">{biography}</p>
              {/* <ul class="avatar-group mt-1 list-unstyled align-items-sm-center">
                  <li class="avatar avatar-xxs">
                    <img class="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt="avatar"/>
                  </li>
                  <li class="avatar avatar-xxs">
                    <img class="avatar-img rounded-circle" src="assets/images/avatar/02.jpg" alt="avatar"/>
                  </li>
                  <li class="avatar avatar-xxs">
                    <img class="avatar-img rounded-circle" src="assets/images/avatar/03.jpg" alt="avatar"/>
                  </li>
                  <li class="avatar avatar-xxs">
                    <img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt="avatar"/>
                  </li>
                  <li class="avatar avatar-xxs">
                    <div class="avatar-img rounded-circle bg-primary"><span class="smaller text-white position-absolute top-50 start-50 translate-middle">+2</span></div>
                  </li>
                  <li class="small ms-3">
                    Carolyn Ortiz, Frances Guerrero, and 20 other shared connections
                  </li>
                </ul> */}
            </div>
            {type == "friends" && (
              <div class="ms-md-auto d-flex">
                <button
                  class="btn connectionredLabel mb-0 me-2"
                  onClick={() => this.unfriendHit(userId)}
                >
                  {" "}
                  Remove{" "}
                </button>
                <button
                  class="btn mb-0 connectionblueLabel"
                  // onClick={() => this.chatNow(userId)}
                >
                  Message{" "}
                </button>
              </div>
            )}
            {type == "notFriends" && (
              <div class="ms-md-auto d-flex">
                {/*           
          <button class="btn connectionredLabel mb-0 me-2" onClick={() => this.friendIgnore(userId)}> Remove </button> */}
                <button
                  class="btn mb-0 connectiongreenLabel"
                  onClick={() => this.friendRequest(userId)}
                >
                  {" "}
                  Add Friend{" "}
                </button>
              </div>
            )}
            {type == "requestedFriends" && (
              <div class="ms-md-auto d-flex">
                <button class="btn mb-0 connectiongrayLabel">
                  {" "}
                  Requested{" "}
                </button>
              </div>
            )}

            {type == "me" && (
              <div class="ms-md-auto d-flex">
                <button class="btn mb-0 connectiongreenLabel"> Me </button>
              </div>
            )}
          </div>
          {/* 
          <div class="d-grid">
            <a href="#!" role="button" class="btn btn-sm btn-loader btn-primary-soft" data-bs-toggle="button" aria-pressed="true">
              <span class="load-text"> Load more connections </span>
              <div class="load-icon">
                <div class="spinner-grow spinner-grow-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </a>
          </div> */}
        </div>
      </>
    );
  }

  getCoverPhoto(uid) {
    let coverPhoto = {
      userId: uid,
    };
    var response = ForumService.getUserCoverPhotoCache(coverPhoto);
    console.log(response);
    if (response) {
      var value = response.data.data;
      if (value && value.coverPhoto) {
        this.setState({ coverPhoto: response.data.data.coverPhoto });
        // console.log("cache "+base64String)
      }
    } else {
      ProfileService.getUsercoverPhoto(coverPhoto)

        .then((response) => {
          var result = response.data;
          ForumService.setUserCoverPhotoCache(coverPhoto, response);
          this.setState({
            coverPhoto: result.data.coverPhoto,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  chatNow(user_id) {
    this.props.history.push(`/messages/${user_id}`);
  }

  friendRequest(id) {
    let friendRequest = {
      friendsId: id,
      requestStatus: "requested",
    };
    FriendsFinder.friendRequest(friendRequest)
      .then((response) => {
        var result = response.data;
        this.props.loadFriendsList();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  friendIgnore(id) {
    let friendIgnore = {
      recommendfriendsId: id,
      status: "ignored",
    };
    FriendsFinder.recommendedfriendIgnore(friendIgnore)
      .then((response) => {
        var result = response.data;
        this.props.loadFriendsList();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  unfriendHit(uId) {
    let unFriend = {
      userId: this.props.loginDetails.uId,
      friendsId: uId,
    };

    FriendsFinder.unfriendHit(unFriend)
      .then((response) => {
        this.props.loadFriendsList();
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(GroupConnectionCard)));
