import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { Dialog, DialogTitle } from "@material-ui/core";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoCalendarOutline } from "react-icons/io5";
import { IoMdTime } from "react-icons/io";
import {
  generateTimeSlots,
  generateTwoMonthSlots,
  shareUrl,
} from "../../utils";
import { FaRegShareSquare } from "react-icons/fa";
import { MdOutlineEventAvailable } from "react-icons/md";
import { CgUnavailable } from "react-icons/cg";
import SocialShare from "../../components/SocialShare";
import { Modal } from "react-bootstrap";
import BlockUi from "react-block-ui";

const StyledWrapper = styled.div`
  .fc .fc-daygrid-day-frame {
    width: 100%;
  }

  ,
  .fc .fc-button-primary {
    border: "1px solid green !important";
  }
`;

function renderEventContent(eventInfo) {
  return (
    <>
      <p
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          paddingInlineStart: "3px",
          cursor: "pointer",
          background:
            eventInfo.event.title === "NOT-AVAILABLE" ? "#f44336a8" : "#17a2b8",
          color: "white",
          fontWeight: "600",
          padding: "2px",
        }}
      >
        {" "}
        {eventInfo.event.title === "NOT-AVAILABLE" ? (
          <>
            <CgUnavailable className="h-auto ml-2" style={{ width: "19px" }} />{" "}
            Not available
          </>
        ) : (
          <>
            {" "}
            <MdOutlineEventAvailable
              className="h-auto ml-2"
              style={{ width: "19px" }}
            />{" "}
            {eventInfo.event.title}
          </>
        )}
      </p>
    </>
  );
}
const SlotCalender = ({
  slot,
  activeMinutes = 30,
  changeMenu,
  needToSaveCalendar,
  calendarId,
  isSubscribed,
  makeChanges,
}) => {
  const [events, setEvent] = useState();
  const currentDate = new Date();
  const startOfCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  )
    .toISOString()
    .split("T")[0];

  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [eventItem, setEventItem] = useState(null);
  const [openModel, setOpenModel] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (slot.length > 0) {
      const getList = generateTwoMonthSlots(slot);
      console.log("getList", getList);
      const newList = getList.reduce((result, item) => {
        let list = item?.timeSlots;
        if (list.length === 1) {
          list = generateTimeSlots(list[0].from, list[0].to, activeMinutes);
        }
        result.push({
          id: 1,
          start: moment(item.date).format("yyyy-MM-DD"),
          end: moment(item.date).format("yyyy-MM-DD"),
          startDate: item.date,
          title: `${
            list?.length === 0
              ? "NOT-AVAILABLE"
              : list?.length > 1
              ? `${list?.length} slot's available`
              : `${list?.length} slot available`
          }`,
          slotsTime: list,
          slotsDate: item,
        });
        // }
        return result;
      }, []);
      setEvent(newList);
    }
  }, [slot, makeChanges]);

  const handleEventClick = (eventInfo) => {
    console.log("eventInfo", eventInfo.event?.extendedProps);

    setEventItem(eventInfo.event?.extendedProps);
    setShowEventDetailsModal(true);
  };
  const shareCalendar = () => {
    if (!calendarId || !isSubscribed) {
      needToSaveCalendar();
      // setShowEventDetailsModal(false);
      // setLoader(true);
      // setTimeout(() => {
      //   setOpenModel(true);
      //   setLoader(false);
      // }, 2000);
    } else {
      setShowEventDetailsModal(false);
      setOpenModel(true);
    }
  };
  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={loader}
    >
      <StyledWrapper>
        <FullCalendar
          defaultView="dayGridMonth"
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          firstDay={1}
          headerToolbar={{
            left: "prev next today",
            center: "title",
            right: "",
          }}
          initialDate={currentDate}
          validRange={{
            start: startOfCurrentMonth,
          }}
          dayMaxEvents={true}
          editable={false}
          selectable={false}
          selectMirror={false}
          // eventColor="blue"
          aspectRatio={7}
          eventContent={(e) => renderEventContent(e)}
          eventClick={(e) => handleEventClick(e)}
          nowIndicator
        />
      </StyledWrapper>
      {showEventDetailsModal && eventItem && (
        <Dialog
          open={showEventDetailsModal}
          onClose={() => setShowEventDetailsModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            <div className="d-flex justify-content-end mb-3">
              <AiOutlineCloseCircle
                onClick={() => setShowEventDetailsModal(false)}
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginBottom: "8px",
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                }}
              />
            </div>
            <div>
              <h5 className="m-0 font-weight-bold text-align-center">
                Slots Details
              </h5>
              <hr />
              <p
                style={{ fontSize: "13px" }}
                className="text-secondary font-weight-bold"
              >
                Available Slot Date
              </p>
              <ul
                className="l-none d-flex align-items-center flex-wrap g-1 mt-3"
                style={{ fontSize: "13px" }}
              >
                <li className="img-chip d-flex flex-row align-items-center gap-2">
                  <IoCalendarOutline
                    style={{ width: "19px" }}
                    className="ml-2 h-auto"
                  />{" "}
                  <span className="f-12">
                    {eventItem.slotsTime.length === 0
                      ? "Slot not available !. If you need this date, please add selected day in weekly calendar."
                      : moment(eventItem.startDate)
                          .locale("en")
                          .format("dddd, MMMM D, YYYY")}
                  </span>
                </li>
              </ul>
              <p
                style={{ fontSize: "13px" }}
                className="text-secondary font-weight-bold"
              >
                Available Slot time
              </p>
              <ul
                className="l-none directory-card g-1 mt-3"
                style={{ fontSize: "13px" }}
              >
                {eventItem.slotsTime.length === 0 && "Slot's not available"}
                {eventItem.slotsTime &&
                  eventItem.slotsTime.map((tItem, ti) => (
                    <li
                      key={ti}
                      className="img-chip  w-100 d-flex flex-row align-items-center gap-2"
                    >
                      <IoMdTime
                        style={{ width: "19px" }}
                        className="ml-2 h-auto"
                      />{" "}
                      <span className="f-12">
                        {" "}
                        {moment(`2024-01-01T${tItem.from}:00`)
                          .locale("en")
                          .format("LT")}{" "}
                        -{" "}
                        {moment(`2024-01-01T${tItem.from}:00`)
                          .add(activeMinutes, "minutes")
                          .locale("en")
                          .format("LT")}{" "}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            {/* {eventItem.slotsTime.length > 0 ? (
              <button className="w-100 mt-3" onClick={() => shareCalendar()}>
                <FaRegShareSquare className="svg-icons text-white mr-2" />
                SHARE YOUR CALENDER TO OTHER'S
              </button>
            ) : (
              <button className="w-100 mt-3" onClick={() => changeMenu(0)}>
                ADD THIS DAY IN YOUR SLOT
              </button>
            )} */}
          </DialogTitle>
        </Dialog>
      )}
      {openModel && (
        <Modal show="true" onHide={() => setOpenModel(false)}>
          <Modal.Header className="font-weight-bold">
            <p className="d-flex align-items-center">
              Calendar Link
              {/* {message && isSubscribed && calendarId && (
                <>
                  {" "}
                  <span className="badge badge-info ml-1">{message}</span>
                </>
              )} */}
            </p>
            <AiOutlineCloseCircle
              onClick={() => setOpenModel(false)}
              className="ml-auto"
              style={{
                cursor: "pointer",
                float: "right",
                marginBottom: "8px",
                width: "20px",
                height: "20px",
                color: "#000000",
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div
              className="d-flex align-items-center mb-2 mt-2 p-4"
              style={{
                gap: "1rem",
                background: "lightslategray",
                borderRadius: "21px",
              }}
            >
              <SocialShare
                closeNotNeed={true}
                url={shareUrl(
                  `${calendarId}&${eventItem.slotsDate.day}&${eventItem.startDate}`
                )}
                title={"Check my available schedule"}
                closeModel={() => setOpenModel(false)}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </BlockUi>
  );
};

export default SlotCalender;
