import { Dialog } from "@material-ui/core";
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

const AnonymousModel = ({ closeModel }) => {
  return (
    <Dialog
      open={true}
      onClose={() => closeModel(false, false)}
      aria-labelledby="bookaCall"
      aria-describedby="bookaCall"
      maxWidth="sm"
    >
      <div
        className="p-3 d-flex flex-column text-secondary align-items-center"
        style={{ gap: "1rem", fontSize: "13px" }}
      >
        <AiOutlineCloseCircle
          onClick={() => closeModel(false, false)}
          style={{
            cursor: "pointer",
            marginLeft: "auto",
            marginBottom: "8px",
            width: "20px",
            height: "20px",
            color: "#000000",
          }}
        />
        <img
          style={{ height: "8rem", width: "8rem" }}
          src="https://corporate.yuukke.com/bcav2/auth/image/90613e16-aee7-40d8-a084-3d834b006c13"
          alt="img"
        />
        <p>
          Anonymous polling published does not include your name or profile
          photo.
        </p>
        <p>
          Admins, moderators and Yuukke's system can see your name and profile
          photo.
        </p>

        <button className="ml-3" onClick={() => closeModel(true, false)}>
          Create Anonymous Post
        </button>
      </div>
    </Dialog>
  );
};

export default AnonymousModel;
