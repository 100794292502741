import React, { Component } from "react";
import FriendsFinder from "../../../api/FriendsFinder";
import UserImg from "../../../components/UserImg";
import { Card, Form, Row, Container, Col, Modal } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import dummyimage from "../../../img/dummyimage.png";
import ForumService from "../../../api/ForumService";
import { withRouter } from "react-router";
import * as APPCONFIG from "../../../config/app-config.js";
import { API_URL } from "../../../config/app-config";
import {
  getAllCommonFriendsList,
  getAllBlockedUserList,
} from "../../../redux/actions/commonApiActions";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getFriendsList } from "../../../redux/actions/PeerMenuListActions";
import { withTranslation } from "react-i18next";
class FriendsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unfriendState: false,
      image: "",
    };

    this.unFriend = this.unFriend.bind(this);
    this.unFriendId = this.unFriendId.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.popUpUnfriend = this.popUpUnfriend.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
    this.moveFriendsDescription = this.moveFriendsDescription.bind(this);
  }
  componentDidMount() {
    // this.getBase64String(this.props.fUserId);
  }
  render() {
    const { tabIndex, friendId, image, name, Dname, smallDescription, t } =
      this.props;
    return (
      <div className="FriendsCardWrapper">
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid
            item
            md={2}
            xs={3}
            sm={3}
            lg={2}
            justify="left"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => this.moveFriendsDescription(friendId)}
          >
            <UserImg
              size="medium"
              // imgSrc={API_URL + "api/getUserPhoto/" + friendId + "/" + name}
              // imgSrc={
              //   image === ""
              //     ? dummyimage
              //     : `${APPCONFIG.API_URL}auth/file/${image}`
              //   // `data:image;base64,${this.state.image}`
              // }
              imgSrc={`${APPCONFIG.API_URL}auth/image/${image}`}
              imgAlt={name}
            />
          </Grid>
          <Grid
            item
            md={7}
            xs={9}
            sm={9}
            lg={7}
            justify="center"
            alignItems="center"
          >
            <div className="friendsinfo pt-1" style={{ flex: "1 1" }}>
              <h2>
                {Dname && Dname !== "null" && Dname.length > 0 ? Dname : name}
              </h2>
              <p>{smallDescription}</p>
            </div>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            sm={12}
            lg={3}
            justify="center"
            alignItems="center"
          >
            <div>
              {tabIndex === "BlockedUser" ? (
                <button
                  className="FACBtn"
                  onClick={() => this.unBlock(friendId)}
                  style={{ marginTop: "6px" }}
                >
                  {t("Unblock")}
                </button>
              ) : (
                <button
                  className="FACBtn"
                  onClick={() => this.unFriendId(friendId)}
                  style={{ marginTop: "6px" }}
                >
                  {t("Remove")}
                </button>
              )}
            </div>
          </Grid>
        </Grid>
        {this.state.unfriendState && this.popUpUnfriend()}
      </div>
    );
  }

  unFriendId(fid) {
    this.setState({
      unfriendState: true,
      friendId: fid,
    });
  }
  unBlock = (bId) => {
    let unBlockAUser = {
      blockUserId: bId,
    };

    FriendsFinder.unBlockAUser(unBlockAUser)
      .then((response) => {
        toast.success(`${this.props.t("Un blocked successfully")}`);
        this.props.getAllBlockedUserList();
        this.props.getAllCommonFriendsList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleClose() {
    this.setState({ unfriendState: false });
  }

  popUpUnfriend() {
    return (
      <div>
        <Modal show="true" onHide={this.handleClose}>
          <Modal.Header>Unfriend</Modal.Header>

          <Form
            id="profileForm"
            noValidate
            validated={this.state.validated}
            onSubmit={this.unFriend}
          >
            <Modal.Body>
              <Form.Group className="formInput">
                <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                  Are you sure to unfriend ?
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ color: "white" }}
                variant="secondary"
                onClick={this.handleClose}
              >
                Close
              </Button>
              <div></div>
              <Button
                style={{ color: "white" }}
                type="submit"
                variant="primary"
              >
                Remove
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }

  moveFriendsDescription(userId) {
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/friendsPic/${userId}`,
      { from: window.location.pathname }
    );
  }

  unFriend(e) {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
    } else {
      let unFriend = {
        userId: this.props.loginDetails.uId,
        friendsId: this.state.friendId,
      };

      FriendsFinder.unfriendHit(unFriend)
        .then((response) => {
          this.handleClose();
          this.props.unfriendHits();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  getBase64String(fUserId) {
    let getBase64String = { userId: fUserId };
    var response = ForumService.getUserPhotoCache(getBase64String);
    if (response) {
      var value = response.data.data;
      if (value && value.userPhoto) {
        this.setState({
          image: response.data.data.userPhoto,
          base64StringState: true,
        });
      }
    } else {
      ForumService.getUserPhoto(getBase64String)
        .then((response) => {
          ForumService.setUserPhotoCache(getBase64String, response);
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.userPhoto
          ) {
            this.setState({
              image: response.data.data.userPhoto,
              base64StringState: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
const mapDispacthToProps = (dispatch) => {
  return {
    getAllBlockedUserList: () => dispatch(getAllBlockedUserList()),
    getFriendsList: () => dispatch(getFriendsList()),
    getAllCommonFriendsList: () => dispatch(getAllCommonFriendsList()),
  };
};
const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(FriendsCard)));
