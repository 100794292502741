import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { BsCalendarDate } from "react-icons/bs";
import { GoLocation } from "react-icons/go";

class FriendExperience extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workExperience: [],
    };
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    console.log(this.props.workExperience);
    this.setState({ workExperience: this.props.workExperience });
  }

  componentWillReceiveProps(nextProps) {}
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.workExperience !== this.props.workExperience) {
      console.log(this.props.workExperience);
      this.setState({ workExperience: this.props.workExperience });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div class="" style={{ marginTop: "1.5rem" }}>
          <div class="exp-card">
            <div class="exp-card-header border-0 pb-0">
              <h5 class="exp-card-title">{t("Experience")}</h5>
            </div>
            <div class="card-body position-relative pt-0">
              {this.state.workExperience &&
                this.state.workExperience.length > 0 &&
                this.state.workExperience.map((experience, index) => (
                  <div class="d-flex">
                    <div>
                      <h6 class="card-title mb-0" style={{ color: "#14191e" }}>
                        {experience.companyName}.
                      </h6>
                      <p class="exp-small">
                        {experience.fromYear} - {experience.toYear}{" "}
                        {experience.role}{" "}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {};
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(FriendExperience)));
