import * as microsoftTeams from "@microsoft/teams-js";
import AuthService from "../session/AuthService";

class MSDeepLink {
    startChat(email) {
        let chatLink = "https://teams.microsoft.com/l/chat/0/0?users=" + email;
        this.triggerDeepLink(chatLink);
    }

    startCall(email) {
        let callLink = "https://teams.microsoft.com/l/call/0/0?users=" + email + "&withvideo=true";
        this.triggerDeepLink(callLink);
    }

    triggerDeepLink(link) {

        if (AuthService.isMSTeamsApp()) {

            microsoftTeams.executeDeepLink(link);
        } else {

            window.location.assign(link);
        }
    }
}
export default new MSDeepLink();