import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionCard from "../../PremiumBadges/SubscriptionCard";
import {
  getSubscriptionList,
  getSubscriptionStatus,
  getUserSlots,
} from "../../../redux/actions/calendarAction";
import BlockUi from "react-block-ui";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import RazorPay from "../../../components/RazorPay";
import Swal from "sweetalert2";
import moment from "moment";
import MyYuukke from "../../../api/MyYuukke";

const SubscriptionList = ({ onBack }) => {
  const {
    subscriptionList,
    subscriptionStatus,
    subscriptionDetails,
    expiryDate,
  } = useSelector((state) => state.event);
  const { loginDetails } = useSelector((state) => state.loginDetailsApi);

  const [state, setState] = useState({
    loader: false,
    paymentAmount: null,
    paymentSuccess: false,
    subscriptionId: 0,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubscriptionList());
  }, []);
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const openPaymentPage = (prize, id) => {
    if (prize === 0) {
      savePaymentInfo(id === null || id === undefined ? 1 : id);
    } else {
      setState((previous) => ({
        ...previous,
        paymentAmount: prize,
        subscriptionId: id,
      }));
    }
  };
  const updatePaymentStatus = (status) => {
    if (status === "dismiss") {
      Swal.fire({
        icon: "error",
        title: "Payment updating failed!",
        text: "If your money debited in bank account ,please contact our team  +91 04446314646",
        showConfirmButton: true,
      }).then(() => {
        setState((previous) => ({
          ...previous,
          paymentSuccess: false,
          paymentAmount: null,
        }));
      });
    } else {
      dispatch(getUserSlots());
      dispatch(getSubscriptionStatus("calender"));

      setState((previous) => ({
        ...previous,
        paymentSuccess: true,
        paymentAmount: null,
      }));
      onBack("subscription", true);
    }
  };
  const savePaymentInfo = (id) => {
    // Free subscription Info save
    let data = {
      subscriptionId: id,
    };

    MyYuukke.addUserSubscription(data)
      .then((res) => {
        console.log("response", res);
        dispatch(getUserSlots());
        dispatch(getSubscriptionStatus("calender"));
        onBack("subscription", true);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };
  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={state.loader}
    >
      <h4
        className="p-3 mt-2 mb-2   heading-h4-v2"
        style={{ borderBottom: "1px solid #80808063" }}
      >
        <IoArrowBackCircleOutline
          onClick={() => onBack()}
          className="h-auto  mr-3 svg-icons"
          style={{
            width: "2rem",
          }}
        />
        Subscription plans
      </h4>

      {subscriptionStatus ? (
        <>
          <section className="pt-5 p-5">
            <div class="alert alert-primary m-3" role="alert">
              Your plan expiry on{" "}
              {moment(expiryDate).locale("en").format("lll")}
            </div>
          </section>
        </>
      ) : (
        <>
          {state.paymentAmount && (
            <RazorPay
              email={loginDetails.email}
              name={loginDetails.dName}
              userId={loginDetails.userId}
              contactNumber={loginDetails.phoneNumber}
              subscriptionId={state.subscriptionId}
              amount={state.paymentAmount}
              type={"calendar_premium"}
              updatePaymentStatus={updatePaymentStatus}
            />
          )}
          <div className="d-flex flex-wrap g-1 p-4">
            {subscriptionList.map((item, index) => (
              <SubscriptionCard
                data={item}
                index={index}
                selectedTeam={openPaymentPage}
              />
            ))}
          </div>
        </>
      )}
    </BlockUi>
  );
};

export default SubscriptionList;
