import React, { Component } from "react";
import AuthService from "../session/AuthService";
import FriendsFinder from "../api/FriendsFinder";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";
import { withRouter } from "react-router";
import ForumService from "../api/ForumService";
//import dummyimage from '../img/avatar_dummy.png';
import dummyimage from "../img/default_avatar.jpg";
import banner from "../img/banner.jpg";
import ProfileService from "../api/ProfileService";
import MSDeepLink from "../utils/MSDeepLink";
import { connect } from "react-redux";
import FriendProfile from "../screens/Home/ProfileFriends/FriendProfile";
import { API_URL } from "../config/app-config";
import { FaTags } from "react-icons/fa";
import { setSelectedUserAction } from "../redux/actions/chatActions";
import { premiumUser } from "../utils";

//Mobile
if (window.innerWidth <= 768 || window.innerWidth == 768) {
  let myProfileDialog = {
    backgroundColor: "#ffffff",
    color: "#000",
    width: "700px",
    minHeight: "250px",
    padding: "0px",
    borderRadius: "10px",
  };
}

class FriendsOnYuukkeCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      base64String: "",
      base64StringState: false,
      mutualFriendsCount: 0,
      subjectslist: "",
      coverPhoto: "",
      userPhoto: "",
      showFriendsProfile: false,
      backgroundColor: "#ffffff",
      color: "#000",
      width: "700px",
      minHeight: "250px",
      padding: "0px",
      borderRadius: "10px",
    };
    this.getSearchedTagValue = this.getSearchedTagValue.bind(this);
    this.friendIgnore = this.friendIgnore.bind(this);
    this.load = this.load.bind(this);
    this.getUserSchoolAndClass = this.getUserSchoolAndClass.bind(this);
    this.callNow = this.callNow.bind(this);
    this.chatNow = this.chatNow.bind(this);
    this.getCoverPhoto = this.getCoverPhoto.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
    this.showFriendsProfile = this.showFriendsProfile.bind(this);
    this.closeFriendsProfile = this.closeFriendsProfile.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.setState({}, () => {
      // this.load(this.props.userId, this.props.subjects);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId)
      this.setState({}, () => {
        // this.load(this.props.userId, this.props.subjects);
      });
  }
  showFriendsProfile(userId) {
    // this.setState({
    //   showFriendsProfile: true,
    // });
    // this.simpleDialog.show();

    //  this.props.history.push("/friendDescription/userId")
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  }

  closeFriendsProfile() {
    this.setState({
      showFriendsProfile: false,
    });
  }

  getSearchedTagValue(tags) {
    console.log("Tag value", tags);

    const searchKeyword = this.props.searchvalue || "";

    console.log("Tag Value", searchKeyword);

    if (Array.isArray(tags) && tags.length > 0) {
      const result = tags.filter((val) =>
        val.tagName.toLowerCase().startsWith(searchKeyword.toLocaleLowerCase())
      );
      if (result.length > 0) {
        return result[0]?.tagName;
      } else {
        return tags[0].tagName;
      }
    }
    return "-------";
  }

  render() {
    const { t } = this.props;
    const {
      id,
      userId,
      name,
      mutualFriends,
      gender,
      coverPhoto,
      userPhoto,
      email,
      Dname,
      biography,
      nameLocation,
      country,
      profession,
      premiumData,
    } = this.props;
    console.log("Tag Value", this.props.tagName);
    return (
      <>
        <div className="peopleCardWrapperGroup">
          <img
            className="bannerImg"
            src={coverPhoto ? API_URL + "auth/image/" + coverPhoto : banner}
            alt={name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = banner;
            }}
          ></img>
          <div className="w3-row">
            <div className="w3-col w3-container w3-green">
              <div>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => this.showFriendsProfile(userId)}
                  className="userImg"
                  src={
                    userPhoto ? API_URL + "auth/image/" + userPhoto : dummyimage
                  }
                  // src={API_URL + "api/getUserPhoto/" + userId + "/" + name}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = dummyimage;
                  }}
                  alt={name}
                />
              </div>
            </div>
          </div>
          <div className="peopleCardInfor">
            <div
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center justify-content-center"
              onClick={() => this.showFriendsProfile(userId)}
            >
              {/* {name}{" "} */}
              <h3 className="card-dName ml-1">{name}</h3>

              {premiumData && premiumUser(premiumData) && (
                <img
                  src={premiumUser(premiumData)}
                  alt=""
                  className="ml-1 mr-3"
                  style={{ width: "20px", height: "20px" }}
                />
              )}
            </div>

            {/* {this.props.tagsValue && this.props.tagsValue.map((buttonValue, index) => (
              <p>{buttonValue.tagName}</p>
          ))} */}
            {/* {this.props.tagsValue && (
              <p>{this.getSearchedTagValue(this.props.tagsValue)}</p>
            )} */}
            <p>{profession}</p>
            <p>{country}</p>
            <p>{nameLocation}</p>
          </div>
          <div className="peopleCardAddRemove flex">
            <div
              className="peopleCardAdd"
              onClick={() => {
                this.chatNow(userId, name, 1, userPhoto);
              }}
            >
              <p>{t("MESSAGE")}</p>
            </div>
          </div>{" "}
        </div>
        {/* {window.innerWidth <= 768 ? (
          <>
           
            <SkyLight
              dialogStyles={{
                backgroundColor: "#ffffff",
                color: "#000",
                width: "280px",
                minHeight: "280px",
                padding: "0px",
                borderRadius: "10px",
                marginRight:"14px"
              }}
              afterClose={this.closeFriendsProfile}
              hideOnOverlayClicked
              ref={(ref) => (this.simpleDialog = ref)}
            >
              <div className="">
                {this.state.showFriendsProfile && (
                  <FriendProfile uid={userId} />
                )}
              </div>
            </SkyLight>
            
             
          </>
        ) : (
          window.innerWidth > 768 && (
            <>
              <SkyLight
                dialogStyles={{
                  backgroundColor: "#ffffff",
                  color: "#000",
                  width: "700px",
                  minHeight: "250px",
                  padding: "0px",
                  borderRadius: "10px",
                }}
                afterClose={this.closeFriendsProfile}
                hideOnOverlayClicked
                ref={(ref) => (this.simpleDialog = ref)}
              >
                <div className="">
                  {this.state.showFriendsProfile && (
                    <FriendProfile uid={userId} />
                  )}
                </div>
              </SkyLight>
            </>
          )
        )} */}
      </>
    );
  }
  load(data, subjects) {
    this.getBase64String(data);
    //  this.getSchoolName(subjects);
    //  this.getUserSchoolAndClass(data);
    this.getCoverPhoto(data);
  }

  callNow(email) {
    MSDeepLink.startCall(email);
  }

  chatNow(user_id, username, userType, profilePhoto) {
    var data = {
      userId: user_id,
      userName: username,
      userType: userType,
      profilePhoto: profilePhoto,
    };

    // this.props.history.push(`/messages/${user_id}`);
    this.props.setSelectedUserAction(data);
  }

  getUserSchoolAndClass(data) {
    let schoolandclass = {
      userId: data,
    };

    FriendsFinder.getUserSchoolAndClass(schoolandclass)
      .then((response) => {
        var result = response.data;

        if (!result.data.hasOwnProperty("userSchoolAndClass")) {
          this.setState({ schoolName: "", className: "" });
        } else if (result.data.hasOwnProperty("userSchoolAndClass")) {
          this.setState({
            schoolName: result.data.userSchoolAndClass.schoolName,
            className: result.data.userSchoolAndClass.className,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getSchoolName(subjects) {
    var subjectName = new Array();
    for (let i = 0; i < this.props.subjectsList.data.length; i++) {
      for (let j = 0; j < subjects.length; j++) {
        if (
          this.props.subjectsList.data[i].subjectId == subjects[j].subjectId
        ) {
          subjectName.push(this.props.subjectsList.data[i].subjectName);
        }
      }
    }
    var subjects = subjectName.join(",");
    await this.setState({ subjectslist: subjects });
  }

  getClassName(classId) {}

  getCoverPhoto(uid) {
    let coverPhoto = {
      userId: uid,
    };
    var response = ForumService.getUserCoverPhotoCache(coverPhoto);
    console.log(response);
    if (response) {
      var value = response.data.data;
      if (value && value.coverPhoto) {
        this.setState({ coverPhoto: response.data.data.coverPhoto });
        // console.log("cache "+base64String)
      }
    } else {
      ProfileService.getUsercoverPhoto(coverPhoto)

        .then((response) => {
          var result = response.data;
          ForumService.setUserCoverPhotoCache(coverPhoto, response);
          this.setState({
            coverPhoto: result.data.coverPhoto,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getBase64String(userId) {
    let getBase64String = { userId: userId };
    var response = ForumService.getUserPhotoCache(getBase64String);
    if (response) {
      console.log("FRIEND IMAGE IN FRIENDS ON PS");
      console.log(response);
      var value = response.data.data;
      console.log(response.data);
      console.log(response.data.data);
      console.log(value);
      var value = response.data.data;
      if (value && value.userPhoto) {
        var base64String = "";
        base64String = `data:image;base64,${response.data.data.userPhoto}`;
        this.setState({
          userPhoto: response.data.data.userPhoto,
          base64StringState: true,
        });
      }
    } else {
      ForumService.getUserPhoto(getBase64String)
        .then((response) => {
          ForumService.setUserPhotoCache(getBase64String, response);
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.userPhoto
          ) {
            var base64String = "";
            base64String = `data:image;base64,${response.data.data.userPhoto}`;
            this.setState({
              userPhoto: response.data.data.userPhoto,
              base64StringState: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  friendIgnore(id) {
    let friendIgnore = {
      recommendfriendsId: id,
      status: "ignored",
    };
    FriendsFinder.recommendedfriendIgnore(friendIgnore)
      .then((response) => {
        this.props.ignoreSent();
        var result = response.data;
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  friendRequest(id) {
    let friendRequest = {
      friendsId: id,
      requestStatus: "requested",
    };
    FriendsFinder.friendRequest(friendRequest)
      .then((response) => {
        this.props.requestSent();
        var result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  return {
    subjectsList: state.commonApi.subjectslist,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    setSelectedUserAction: (id) => dispatch(setSelectedUserAction(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(FriendsOnYuukkeCard)));
