import { Checkbox } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { IoIosArrowDown, IoMdAdd, IoMdAddCircleOutline } from "react-icons/io";
import { MdOutlineCheck } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { WeeklyCalendar } from "../../FirstTimeProfile/WeeklyCalendar";
import EditableTextFieldInsta from "../../../components/EditableTextFieldInsta";
import EditableTextFieldLinkedIn from "../../../components/EditableTextFieldLinkedIn";
import { TextField } from "@material-ui/core";
import EditableTextFieldTwitter from "../../../components/EditableTextFieldTwitter";
import { withTranslation } from "react-i18next";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import CalendarPreference from "./CalendarPreference";
import {
  getCategoryListV2,
  getDirectoryCategoryV1,
  getSubscriptionList,
  getSubscriptionStatus,
  getUserService,
  getUserSlots,
} from "../../../redux/actions/calendarAction";
import Services from "./Services";
import SubscriptionList from "./SubscriptionList";
import { toast } from "react-toastify";
import {
  giveUserAction,
  instaValidation,
  linkedInvalidation,
  twitterValidation,
} from "../../../utils";
import ProfileService from "../../../api/ProfileService";
import { updateSocialMediaDetails } from "../../../redux/actions/loginDetailsActions";
import BankDetails from "./BankDetails";
import {
  getAllCommonLanguageList,
  needPhotVerification,
} from "../../../redux/actions/commonApiActions";

const CalendarContainer = (props) => {
  const { t } = props;
  const {
    userSlots,
    eventLoader,
    subscriptionList,
    subscriptionStatus,
    subscriptionDetails,
    categoryList,
    calendarServices,
    successCount,
    prefComplete,
    calendarComplete,
    serviceComplete,
    categoryV2,
  } = useSelector((state) => state.event);
  // const [menuList, setMenuList] = useState([
  //   {
  //     title: "Complete your profile details",
  //     description: "Add your profile pic and description",
  //     id: 1,
  //     menuStatus: false,
  //   },
  //   {
  //     title: "Add your preference",
  //     description: "Add your preference in your service",
  //     id: 2,
  //     menuStatus: prefComplete,
  //   },
  //   {
  //     title: "Select your suitable plan",
  //     description: "Pic your suitable plan for your service",
  //     id: 3,
  //     menuStatus: subscriptionStatus,
  //   },
  //   {
  //     title: "Add availability",
  //     description: "Add your availability so your followers can select a slot",
  //     id: 4,
  //     menuStatus: calendarComplete,
  //   },
  //   {
  //     title: "Create a service",
  //     description: "Add a service so that your followers can book it",
  //     id: 5,
  //     menuStatus: serviceComplete,
  //   },
  //   {
  //     title: "Add Your Bank Details",
  //     description: "Add your bank details for service payments",
  //     id: 6,
  //     menuStatus: false,
  //   },
  // ]);
  const [menuList, setMenuList] = useState([
    {
      title: "Complete your profile details",
      description: "Add your profile pic and description",
      id: 1,
      menuStatus: false,
    },
    {
      title: "Add your preference",
      description: "Add your preference in your service",
      id: 2,
      menuStatus: prefComplete,
    },
    {
      title: "Select your suitable plan",
      description: "Pic your suitable plan for your service",
      id: 3,
      menuStatus: subscriptionStatus,
    },
    {
      title: "Add availability",
      description: "Add your availability so your followers can select a slot",
      id: 4,
      menuStatus: calendarComplete,
    },
    {
      title: "Create a service",
      description: "Add a service so that your followers can book it",
      id: 5,
      menuStatus: serviceComplete,
    },
    {
      title: "Add Your Bank Details",
      description: "Add your bank details for service payments",
      id: 6,
      menuStatus: userSlots?.preference?.accountNumber,
    },
  ]);
  const [state, setState] = useState({
    activeMenu: 0,
    loader: false,
    activeWrapperMenu: null,
    biography: "",
    twitter: "",
    location: "",
    instagram: "",
    linkedIn: "",
    initialServicePage: false,
  });
  const { loginDetails } = useSelector((state) => state.loginDetailsApi);
  const { languagelist } = useSelector((state) => state.commonApi);
  const dispatch = useDispatch();
  useEffect(() => {
    setInitialState();
    dispatch(getUserService());
    dispatch(getUserSlots());
    dispatch(getSubscriptionStatus("calender"));
    dispatch(getSubscriptionList());
    if (categoryV2.length === 0) {
      dispatch(getDirectoryCategoryV1());
    }
    if (languagelist.length === 0) {
      dispatch(getAllCommonLanguageList());
    }
  }, []);
  useEffect(() => {
    if (
      !state.initialServicePage &&
      serviceComplete &&
      subscriptionStatus &&
      state.activeWrapperMenu !== 5
    ) {
      setState((previous) => ({
        ...previous,
        activeMenu: 5,
        initialServicePage: true,
      }));
      console.log("Update 5");
    }
  }, [serviceComplete, subscriptionStatus]);
  useEffect(() => {
    let list = menuList.map((menu) => {
      switch (menu.id) {
        case 2:
          return { ...menu, menuStatus: prefComplete };
        case 3:
          return { ...menu, menuStatus: subscriptionStatus };
        case 4:
          return { ...menu, menuStatus: calendarComplete }; // Update this one dynamically
        case 5:
          return { ...menu, menuStatus: serviceComplete };
        case 6:
          return {
            ...menu,
            menuStatus: userSlots?.preference?.accountNumber,
          };
        default:
          return menu;
      }
    });
    setMenuList(list);
  }, [
    calendarComplete,
    prefComplete,
    subscriptionStatus,
    serviceComplete,
    userSlots,
  ]);
  const setInitialState = () => {
    setState((previous) => ({
      ...previous,
      biography: loginDetails.biography,
      twitter: loginDetails.twitter,
      location: loginDetails.location,
      instagram: loginDetails.instagram,
      linkedIn: loginDetails.linkedin,
    }));
    if (
      loginDetails.biography &&
      loginDetails.location &&
      loginDetails.instagram
    ) {
      updateMenu(1);
    }
  };
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const menuStatusChange = (id, data) => {
    if (giveUserAction(loginDetails?.profile_complete)) {
      dispatch(needPhotVerification(true));
      return;
    }
    const unComplete =
      !data.menuStatus && menuList.slice(0, 4).find((item) => !item.menuStatus);
    console.log("un", unComplete);
    if (unComplete && !data.menuStatus) {
      if (id !== state.activeWrapperMenu && unComplete.id !== id) {
        toast.warning(`Please complete ${unComplete.title} step`);
      }

      setState((previous) => ({
        ...previous,
        activeWrapperMenu:
          id === state.activeWrapperMenu ? null : unComplete.id,
        initialServicePage: false,
      }));
      return;
    } else if (!subscriptionStatus && id !== 1 && id !== 2) {
      toast.warning("Please subscribe your suitable plan");
      setState((previous) => ({
        ...previous,
        activeWrapperMenu: 3,
        initialServicePage: false,
      }));
      return;
    }
    setState((previous) => ({
      ...previous,
      activeWrapperMenu: id === state.activeWrapperMenu ? null : id,
    }));
  };
  const removeMenuStatus = (type, status) => {
    if (status && type) {
      const list = menuList.map((item) => {
        if (type === "calendar" && item.id === 2) {
          item.menuStatus = true;
          item.title = "Update your preference details";
        } else if (type === "subscription" && item.id === 3) {
          item.menuStatus = true;
        } else if (type === "bank" && item.id === 6) {
          item.menuStatus = true;
        }

        return item;
      });
      setMenuList(list);
    }
    setState((previous) => ({
      ...previous,
      activeWrapperMenu: null,
      activeMenu: 0,
      initialServicePage: false,
    }));
  };

  const saveProfileChanges = (e) => {
    e.preventDefault();
    if (!state.biography) {
      toast.warning("Enter your service details");
    } else if (!state.location) {
      toast.warning("Enter your location");
    } else if (!state.instagram && !state.linkedIn && !state.twitter) {
      toast.warning("Enter your social media url");
    } else {
      let validCount = 0;
      const data = {};
      data["biography"] = state.biography;
      data["location"] = state.location;
      if (linkedInvalidation(state.linkedIn)) {
        data["linkedin"] = state.linkedIn;
        validCount++;
      }

      if (instaValidation(state.instagram)) {
        data["instagram"] = state.instagram;
        validCount++;
      }

      if (twitterValidation(state.twitter)) {
        data["twitter"] = state.twitter;
        validCount++;
      }
      if (validCount >= 2) {
        updateState("loader", true);
        validProfileDetails(data);
      } else {
        toast.warning(
          "Please add minimum two of your social media profile url"
        );
      }
    }
  };
  const validProfileDetails = (data) => {
    ProfileService.userProfileDetailsUpdated(data)
      .then((res) => {
        console.log("profile-details", res);
        dispatch(updateSocialMediaDetails(data));
        updateMenu(1);
        updateState("loader", false);
        removeMenuStatus();
      })
      .catch((e) => {
        toast.error("Something went wrong !");
        updateState("loader", false);
      });
  };
  const updateMenu = (id) => {
    const list = menuList.map((item) => {
      if (item.id === id && id === 1) {
        item.menuStatus = true;
        item.title = "Update your profile details";
      } else if (item.id === id && id === 4) {
        item.menuStatus = true;
        item.title = "Update your availability";
      } else if (item.id === id && id === 5) {
        item.menuStatus = true;
      }
      return item;
    });
    setMenuList(list);
    // removeMenuStatus();
  };
  console.log("menuList", menuList);
  console.log("state", state);
  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={state.loader || eventLoader}
    >
      <div className="cal-v2-wrap" style={{ marginBottom: "10rem" }}>
        {state.activeMenu === 4 ? (
          <>
            <WeeklyCalendar
              onBack={() => removeMenuStatus()}
              addSuccess={updateMenu}
              userSlots={userSlots?.slots}
            />
          </>
        ) : state.activeMenu === 1 ? (
          <>
            <h4
              className="p-3 mt-2 mb-2 heading-h4-v2"
              style={{ borderBottom: "1px solid #80808063" }}
            >
              <IoArrowBackCircleOutline
                onClick={() => removeMenuStatus()}
                className="h-auto  mr-3 svg-icons"
                style={{
                  width: "2rem",
                }}
              />
              {t("Profile Details")}
            </h4>
            <form onSubmit={saveProfileChanges} className="card cal-grid  p-4">
              <section className="d-flex  w-100">
                <EditableTextFieldInsta
                  label={`${t("Instagram")} *`}
                  value={state.instagram}
                  onDataCheck={(key) => {}}
                  handleClickEdits={() => {}}
                  onChange={(value) => updateState("instagram", value)}
                  getSelectedValue={(value) => updateState("instagram", value)}
                />
              </section>

              <section className="d-flex w-100">
                {" "}
                <EditableTextFieldLinkedIn
                  label={`${t("LinkedIn")} *`}
                  value={state.linkedIn}
                  onDataCheck={(key) => {}}
                  handleClickEdits={() => {}}
                  onChange={(value) => updateState("linkedIn", value)}
                  getSelectedValue={(value) => updateState("linkedIn", value)}
                />
              </section>
              <section className="d-flex w-100">
                {" "}
                <EditableTextFieldTwitter
                  label={`${t("Twitter")} `}
                  value={state.twitter}
                  onDataCheck={(key) => {}}
                  handleClickEdits={() => {}}
                  onChange={(value) => updateState("twitter", value)}
                  getSelectedValue={(value) => updateState("twitter", value)}
                />
              </section>
              <section className="w-100 editableTextWrapper formFieldGroup">
                <label className=" mb-0 label-v2">{t("Location")} *</label>
                <TextField
                  placeholder={t("Delhi...")}
                  style={{
                    marginTop: "5px",
                  }}
                  fullWidth
                  variant="outlined"
                  type="text"
                  required
                  name="location"
                  value={state.location}
                  onChange={(e) => updateState("location", e.target.value)}
                />
              </section>
              <section className="editableTextWrapper formFieldGroup cal-full w-100">
                <label className="label-v2">{t("About Me")} *</label>
                <textarea
                  className="post-form-control post-pe-4 post-bg-light"
                  rows="1"
                  placeholder={t("About your service ...")}
                  type="text"
                  onChange={(e) => updateState("biography", e.target.value)}
                  value={state.biography}
                  name="biography"
                  style={{ minHeight: "8rem" }}
                  required
                ></textarea>
              </section>
              <button className="w-100" type="submit">
                {" "}
                {t("Complete Your Profile")}
              </button>
              {/* <button className="w-100" onClick={() => saveProfileChanges()}>
              Next <MdDoubleArrow className="ml-1 text-white svg-icons" />
            </button> */}
            </form>
          </>
        ) : state.activeMenu === 5 ? (
          <Services
            addSuccess={updateMenu}
            onBack={removeMenuStatus}
            initialServicePage={state.initialServicePage}
          />
        ) : state.activeMenu === 2 ? (
          <>
            <CalendarPreference onBack={removeMenuStatus} />
          </>
        ) : state.activeMenu === 3 ? (
          <SubscriptionList onBack={removeMenuStatus} />
        ) : state.activeMenu === 6 ? (
          <BankDetails
            onBack={removeMenuStatus}
            userSlots={userSlots?.preference}
          />
        ) : (
          <>
            <div className="cal-p" style={{ padding: "2rem 2rem 0.5rem" }}>
              <h4 className="heading-h4-v2">{t("Launch Your Service")} </h4>
              <p className="text-secondary f-12">
                {t("Unlock the potential of your service")}
              </p>
              <ul className="d-flex g-1 l-none p-0 mt-5">
                {menuList.map((item, i) => (
                  <li
                    key={i}
                    style={{
                      flexGrow: 1,
                      height: "8px",
                      background: `${item.menuStatus ? "green" : "gray"}`,
                    }}
                  ></li>
                ))}
              </ul>
            </div>
            <ul className="cal-drawer cal-p m-0">
              {menuList.map((item, i) => (
                <li
                  style={{
                    background:
                      item.id === state.activeWrapperMenu
                        ? "white"
                        : "#b0b0b008",
                  }}
                  className={`cal-box ${
                    item.id === state.activeWrapperMenu ? "open" : ""
                  }`}
                >
                  <section className="d-flex flex-column">
                    <div
                      style={{ height: "4rem" }}
                      className="d-flex align-items-center c-pointer"
                      onClick={() => menuStatusChange(item.id, item)}
                    >
                      {item.menuStatus ? (
                        <span style={{ background: "green" }} className="mr-4">
                          <MdOutlineCheck className="text-white   " />
                        </span>
                      ) : (
                        <p className="cal-dot-border mr-4"></p>
                      )}
                      <p>{t(item.title)}</p>
                      <IoIosArrowDown
                        className={`ml-auto c-pointer arrowIcon-cal ${
                          item.id === state.activeWrapperMenu ? "active" : ""
                        }`}
                      />
                    </div>
                    <div
                      style={{
                        marginTop:
                          item.id === state.activeWrapperMenu
                            ? "-0.9rem"
                            : "0px",
                        marginLeft: "3.3rem",
                        transition: "all 0.5s ease-in-out",
                      }}
                    >
                      <p className="f-12">{t(item.description)}</p>
                      <button
                        className="mt-2"
                        onClick={() => updateState("activeMenu", item.id)}
                      >
                        <IoMdAddCircleOutline
                          className="mr-2"
                          style={{ width: "20px" }}
                        />
                        {t(item.title)}
                      </button>
                    </div>
                  </section>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </BlockUi>
  );
};
export default withTranslation()(CalendarContainer);
