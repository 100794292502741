import {
  GET_ALL_SURVEY_LIST,
  GET_PIE_CHART_LIST,
  GET_ADVERTISEMENT_DATA,
  GET_CHAT_LIST_DATA,
  GET_USER_COMMENTS,
  GET_SINGLE_POST_DATA,
  SINGLE_POST_LOADER,
} from "../types";
import { CLOSE_SURVEY_LIST } from "../types";
import HomeService from "../../api/HomeService";
import PostService_old from "../../api/PostService_old";
//import AuthService from '../../session/AuthService';

export const getAllSurveyList = (personalLanguage) => async (dispatch) => {
  try {
    let getAllSurveyList = {
      lang: personalLanguage,
    };
    HomeService.getAllSurveyList(getAllSurveyList)
      .then((response) => {
        var result = response.data;
        var surveyList = result.data;
        dispatch({
          type: GET_ALL_SURVEY_LIST,
          payload: surveyList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_SURVEY_LIST,
      payload: [],
    });
  }
  return [];
};

export const CloseSurveyList = (surveyList) => async (dispatch) => {
  try {
    dispatch({
      type: CLOSE_SURVEY_LIST,
      payload: surveyList,
    });
  } catch (error) {
    dispatch({
      type: CLOSE_SURVEY_LIST,
      payload: [],
    });
  }
  return [];
};

export const getPieChartList = (postId, type) => async (dispatch) => {
  try {
    let data = {
      postId: postId,
      typeData: type,
    };
    HomeService.post_analytics(data)
      .then((response) => {
        var result = response.data.data.dates;
        dispatch({
          type: GET_PIE_CHART_LIST,
          payload: result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_PIE_CHART_LIST,
      payload: [],
    });
  }
  return [];
};

export const getAdvertisementData = (postId, type) => async (dispatch) => {
  try {
    let data = {
      postId: postId,
      typeData: type,
    };
    HomeService.advertisement_data(data)
      .then((response) => {
        var result = response.data.data;
        dispatch({
          type: GET_ADVERTISEMENT_DATA,
          payload: result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_ADVERTISEMENT_DATA,
      payload: [],
    });
  }
  return [];
};

export const getAdvertisementChatList =
  (postId, type, receiverId, page) => async (dispatch) => {
    try {
      let data = {
        postId: postId,
        typeData: type,
        receiverId: receiverId,
        page: page,
      };
      HomeService.advertisement_chat_list(data)
        .then((response) => {
          var result = response.data.data.chat_list;
          dispatch({
            type: GET_CHAT_LIST_DATA,
            payload: result,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_CHAT_LIST_DATA,
        payload: [],
      });
    }
    return [];
  };

// export const getUserComments = (postId, type) => async (dispatch) => {
//   try {
//     let data = {
//       postId: postId,
//       type: type,
//       postLikeAndCommendType: "commend",
//     };
//     PostService_old.getCommendByIndividualPostId(data)
//       .then((response) => {
//         var result = response.data.data.postCommendsList;
//         dispatch({
//           type: GET_USER_COMMENTS,
//           payload: result,
//         });
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   } catch (error) {
//     dispatch({
//       type: GET_USER_COMMENTS,
//       payload: [],
//     });
//   }
//   return [];
// };

export const getSinglePostData = (postId, type) => async (dispatch) => {
  try {
    dispatch({
      type: SINGLE_POST_LOADER,
      payload: true,
    });
    let data = {
      postId: postId,
      typeData: type,
    };
    PostService_old.getPostByPostIdAndTypeData(data)
      .then((response) => {
        var result = response.data.data;
        dispatch({
          type: GET_SINGLE_POST_DATA,
          payload: result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_POST_DATA,
      payload: [],
    });
  }
  return [];
};
