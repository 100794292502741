import Keycloak from "keycloak-js";
import Http from "../../api/Http";
import { BASE_URL } from "../../config/app-config";
import { GET_LOGOUT_DATA } from "../types";
// import ProfileService from '../../api/ProfileService';

export const getLogoutData = () => async (dispatch) => {

  try {
    dispatch({
      type: GET_LOGOUT_DATA,
      payload: [],
    });
  } catch (error) {

    dispatch({
      type: GET_LOGOUT_DATA,
      payload: [],
    });
  }
  return [];
};
