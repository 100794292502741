import React, { Component } from "react";
import { Container } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AskQuestion from "./AskQuestion";
import CreateChat from "./CreateChat";
import Subjects from "./Subjects/Subjects";
import i18n from "../../i18n";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getDashboardList,
  getRecentlyViewedQuestionsList,
  getMyQuestionsList,
} from "../../redux/actions/dashboardActions";
import {
  getAllSubjectList,
  searchByGroupsAndChallenges,
} from "../../redux/actions/subjectActions";
import {
  updateQuestionValues,
  getMyLibraryQuestionList,
} from "../../redux/actions/MyLibraryQuestionActions";
import { getQuestionsList } from "../../redux/actions/questionActions";

import Peer from "../Square/Peer/Home";
import {
  Button,
  Form,
  Modal,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap";
import MyYuukke from "../../api/MyYuukke";
import { ToastContainer, toast } from "react-toastify";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

var page = 0;
var qpage = 0;
var typeQuestion = "All";
var pageNumber = 1;

var typeQuestionTop = "newest";
var tpage = 0;
var subjectId = 0;

class Community extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 1,
      selectedValue: null,
      showAskQuestion: false,
      editObject: {},
      blocking: false,
      subjectstoquestion: false,
      subjectstolibrary: false,
      SubId: 0,
      redirecttype: "",
      showAskChat: false,
      hide: true,
      invitePopup: false,
      email: "",
      pageNo: 0,
      tagDatas: [],
      challengePage: false,
    };

    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleshowAskQuestion = this.handleshowAskQuestion.bind(this);
    this.handleViewAllQuestions = this.handleViewAllQuestions.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
    this.getMoreDashboardList = this.getMoreDashboardList.bind(this);
    this.getMoreMyQuestionsList = this.getMoreMyQuestionsList.bind(this);
    this.SetWallArrayAfterLike = this.SetWallArrayAfterLike.bind(this);
    this.SetWallArrayInBookmark = this.SetWallArrayInBookmark.bind(this);
    this.GoToSubjectQuestionList = this.GoToSubjectQuestionList.bind(this);
    this.BackToMainPage = this.BackToMainPage.bind(this);
    this.handleshowNewChat = this.handleshowNewChat.bind(this);
    this.idfromSubjectCard = this.idfromSubjectCard.bind(this);
    this.fromGroupsorEventsList = this.fromGroupsorEventsList.bind(this);
    this.closeInvitePage = this.closeInvitePage.bind(this);
    this.handleusers = this.handleusers.bind(this);
    this.onSave = this.onSave.bind(this);
    this.pageNo = this.pageNo.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.getMoreWallData);
  }
  componentDidMount() {
    tpage = 1;
    typeQuestionTop = "newest";
    subjectId = 0;
    if (
      !this.props.QandAquestTotalitems ||
      this.props.QandAquestTotalitems == 0
    ) {
      // this.props.getQuestionsList(
      //   typeQuestionTop,
      //   tpage,
      //   subjectId,
      //   "",
      //   "",
      //   this.props.loginDetails.uId
      // );
    }

    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    page = 0;
    typeQuestion = "All";
    //deepak
    this.setState({ tabIndex: this.props.tabIndex });
    //deepak

    // if (!this.props.solvedTotalitems || this.props.solvedTotalitems == 0) {
    //   this.props.getMyLibraryQuestionList(
    //     typeQuestion,
    //     pageNumber,
    //     this.props.loginDetails.uId
    //   );
    // }
    // if (!this.props.wallListCount || this.props.wallListCount == 0) {
    //   this.props.getDashboardList(0, this.props.loginDetails.uId);
    // }

    if (this.props.tabIndex === 2) {
      // this.pageNo(this.state.pageNo);
    }
    // this.props.getRecentlyViewedQuestionsList();
    qpage = 0;
    // this.props.getMyQuestionsList(qpage);
    if (this.props.showquestions) {
      this.setState({
        tabIndex: 1,
      });
      // this.handleshowAskQuestion();
    } else if (this.props.questions) {
      this.setState({
        tabIndex: 1,
      });
    } else if (this.props.match.params.tid) {
      this.setState({
        tabIndex: parseInt(this.props.match.params.tid),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ blocking: this.props.loading }, () => {});
    }

    if (this.props.showquestions !== prevProps.showquestions) {
      if (this.props.showquestions) {
        this.setState({
          tabIndex: 1,
        });
        // this.handleshowAskQuestion();
      } else if (this.props.questions) {
        this.setState({
          tabIndex: 1,
        });
      }
    }
    //deepak
    if (this.props.tabIndex != prevProps.tabIndex) {
      // alert("didupdate "+prevProps.tabIndex)
      this.setState({
        tabIndex: prevProps.tabIndex,
      });
    }
    //deepak
  }
  //deepak
  componentWillReceiveProps(nextProps) {
    if (this.props.tabIndex != nextProps.tabIndex) {
      // alert("didupdate "+prevProps.tabIndex)
      this.setState({
        tabIndex: nextProps.tabIndex,
      });
    }
    this.setState({
      invitePopup: this.props.invite,
    });
    //  alert(this.props.invite)
  }
  //deepak  //invite={false}
  render() {
    const { t, tabIndex } = this.props;
    // SubId:0;
    const { showAskQuestion } = this.state;
    return (
      <>
        {!showAskQuestion ? (
          tabIndex === 0 ? (
            <></>
          ) : tabIndex === 1 ? (
            <>
              <Peer
                inviteUser={this.props.match.params.id}
                invite={this.props.invite}
              />
            </>
          ) : tabIndex === 2 ? (
            <Subjects
              type={this.props.type}
              searchValue={this.props.searchValue}
              gotoGroups={this.idfromSubjectCard}
              getGroupsorEventsList={this.fromGroupsorEventsList}
              subLoading={this.props.subLoading}
              editQuestion={this.editQuestion}
              redirecttype={this.state.redirecttype}
              subId={this.state.SubId}
              movetoQuestions={(type) => this.BackToMainPage(type)}
              tagDatas={this.props.tagDatas}
              subjectstoquestion={this.state.subjectstoquestion}
              subjectstolibrary={this.state.subjectstolibrary}
              loginDetails={this.props.loginDetails}
              challengePage={this.state.challengePage}
              pagination={this.props.tagDatasPage}
              page={this.state.pageNo}
              pageNo={this.pageNo}
            />
          ) : (
            // : tabIndex === 3 ? (
            //   <></>
            // )

            <></>
          )
        ) : (
          <AskQuestion
            events={false}
            editObject={this.state.editObject}
            handleViewAllQuestions={this.handleViewAllQuestions}
          />
        )}
        {/* </Container> */}
      </>
    );
  }
  async pageNo(data) {
    let page = this.state.pageNo + data;
    // if (page !== this.props.tagDatasPage || page === 0) {
    //   this.setState({
    //     challengePage: true,
    //   });
    // } else {
    //   this.setState({
    //     challengePage: false,
    //   });
    // }
    this.setState({
      pageNo: page,
    });
    this.props.searchByGroupsAndChallenges(page, this.props.type, "");
    // this.props.getAllSubjectList("Groups", page);
    console.log("tagDatas state>>", this.state.tagDatas);
  }
  onSave(e) {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData();
    const form = e.currentTarget;

    if (this.state.email === "") {
      toast.warn("Please enter valid Phone email");
    } else {
      // let value = {
      //   email: this.state.email,
      // };
      let value = {
        email: this.state.email,
      };

      MyYuukke.getReportEmail(value)
        .then((response) => {
          toast.success("User invited successfully");
          this.closeInvitePage();
        })
        .catch((error) => {
          toast.error("Error in saving issue");
          this.closeInvitePage();
        });
    }
  }
  handleusers(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  idfromSubjectCard(
    id,
    key,
    name,
    data,
    editQuestion,
    subjectName,
    subId,
    redirecttype,
    movetoQuestions,
    event,
    grouporeventFiles,
    fileType,
    subjectDescription,
    memberShipPrice,
    memberShipType,
    expiryDate,
    createdDate,
    displayName,
    userId,
    status,
    tagListId,
    startDate
  ) {
    this.props.gotoGroups(
      id,
      key,
      name,
      data,
      editQuestion,
      subjectName,
      subId,
      redirecttype,
      movetoQuestions,
      event,
      grouporeventFiles,
      fileType,
      subjectDescription,
      memberShipPrice,
      memberShipType,
      expiryDate,
      createdDate,
      displayName,
      userId,
      status,
      tagListId,
      startDate
    );
  }

  getMoreMyQuestionsList(pageNo) {
    //this.props.getMyQuestionsList(pageNo);
  }

  getMoreDashboardList(pageNo) {
    page = pageNo;
    this.props.getDashboardList(pageNo, this.props.loginDetails.uId);
  }

  handleChangeTab(event, newIndex) {
    if (window.location.pathname.includes("community/questions") > 0) {
      let path = `/community/${newIndex}`;
      sessionStorage.setItem("APP_REDIRECT_PATH", path);
      this.props.history.push(`/redirect`);
    } else {
      this.setState({
        tabIndex: newIndex,
        showAskQuestion: false,
      });
    }
    if (newIndex !== 2) {
      this.setState({
        SubId: 0,
      });
    }
  }

  handleshowAskQuestion() {
    this.setState({
      showAskQuestion: !this.state.showAskQuestion,
      showAskChat: false,
      hide: false,
      tabIndex: this.state.showAskQuestion ? 1 : null,
      editObject: {},
    });
  }
  handleshowNewChat() {
    this.setState({
      showAskChat: !this.state.showAskChat,
      showAskQuestion: false,
      tabIndex: 3,
      editObject: {},
    });
  }
  handleViewAllQuestions() {
    this.setState({
      tabIndex: 2,
      showAskQuestion: false,
      hide: true,
    });
    this.props.history.push(`groups`);
  }

  editQuestion(obj) {
    if (obj) {
      this.setState({
        showAskQuestion: true,
        editObject: obj,
      });
    } else {
      this.setState({
        editObject: {},
      });
    }
  }

  SetWallArrayInBookmark(bookmark) {
    if (bookmark.id) {
      for (var i = 0; i < this.props.wallList.length; i++) {
        if (this.props.wallList[i].question.questionId == bookmark.questionId) {
          console.log(this.props.wallList[i].question);
          if (this.props.wallList[i].question.questionLikesArray.length > 0) {
            var wall = this.props.wallList[
              i
            ].question.questionLikesArray.filter(
              (wlike) => wlike.userId == bookmark.userId
            );
            if (wall.length > 0) {
              for (
                var l = 0;
                l < this.props.wallList[i].question.questionLikesArray.length;
                l++
              ) {
                if (
                  this.props.wallList[i].question.questionLikesArray[l]
                    .userId == bookmark.userId
                ) {
                  this.props.wallList[i].question.questionLikesArray[l] =
                    bookmark;
                  break;
                }
              }
            } else {
              this.props.wallList[i].question.questionLikesArray.push(bookmark);
            }
          } else {
            this.props.wallList[i].question.questionLikesArray.push(bookmark);
          }
        }
      }
      for (var i = 0; i < this.props.topQuestions.length; i++) {
        if (this.props.topQuestions[i].questionId == bookmark.questionId) {
          if (this.props.topQuestions[i].questionLikesArray.length > 0) {
            var lib = this.props.topQuestions[i].questionLikesArray.filter(
              (wlike) => wlike.userId == bookmark.userId
            );
            if (lib.length > 0) {
              for (
                var l = 0;
                l < this.props.topQuestions[i].questionLikesArray.length;
                l++
              ) {
                if (
                  this.props.topQuestions[i].questionLikesArray[l].userId ==
                  bookmark.userId
                ) {
                  this.props.topQuestions[i].star[0] = bookmark;
                  break;
                }
              }
            } else {
              this.props.topQuestions[i].star.push(bookmark);
            }
          } else {
            this.props.topQuestions[i].star.push(bookmark);
          }
        }
      }

      for (var i = 0; i < this.props.QandAquestionList.length; i++) {
        if (this.props.QandAquestionList[i].questionId == bookmark.questionId) {
          if (
            this.props.QandAquestionList[i].star &&
            this.props.QandAquestionList[i].star.length > 0
          ) {
            this.props.QandAquestionList[i].star[0] = bookmark;
          } else {
            this.props.QandAquestionList[i].star.push(bookmark);
          }
        }
      }
    }
  }

  SetWallArrayAfterLike(like, likeCounts) {
    if (like.id) {
      for (var i = 0; i < this.props.wallList.length; i++) {
        if (this.props.wallList[i].wall.wallId == like.wallId) {
          console.log(this.props.wallList[i].wall);
          if (this.props.wallList[i].wall.wallLikes.length > 0) {
            var wall = this.props.wallList[i].wall.wallLikes.filter(
              (wlike) => wlike.userId == like.userId
            );
            if (wall.length > 0) {
              for (
                var l = 0;
                l < this.props.wallList[i].wall.wallLikes.length;
                l++
              ) {
                if (
                  this.props.wallList[i].wall.wallLikes[l].userId == like.userId
                ) {
                  this.props.wallList[i].wall.wallLikes[l] = like;
                  this.props.wallList[i].wall.wallLikesCount = likeCounts;
                }
              }
            } else {
              this.props.wallList[i].wall.wallLikes.push(like);
              this.props.wallList[i].wall.wallLikesCount = likeCounts;
            }
          } else {
            this.props.wallList[i].wall.wallLikes.push(like);
            this.props.wallList[i].wall.wallLikesCount = likeCounts;
          }
        }
      }
    } else if (!like.id) {
      for (var i = 0; i < this.props.wallList.length; i++) {
        if (this.props.wallList[i].wall.wallId == like.wallId) {
          if (this.props.wallList[i].wall.wallLikes.length > 0) {
            for (
              var l = 0;
              l < this.props.wallList[i].wall.wallLikes.length;
              l++
            ) {
              if (
                this.props.wallList[i].wall.wallLikes[l].userId == like.userId
              ) {
                this.props.wallList[i].wall.wallLikes.splice(l, 1);
                this.props.wallList[i].wall.wallLikesCount = likeCounts;
              }
            }
          } else {
            this.props.wallList[i].wall.wallLikes.splice(0, 1);
            this.props.wallList[i].wall.wallLikesCount = likeCounts;
          }
        }
      }
    }
  }

  fromGroupsorEventsList() {
    this.props.getAllSubjectList("Groups", 0);
  }

  GoToSubjectQuestionList(id, type) {
    if (
      (id && type == "lib") ||
      (id && type == "ques") ||
      (id && type == "dash")
    ) {
      this.setState({
        SubId: id,
        tabIndex: 2,
        redirecttype: type,
      });
    }
  }
  closeInvitePage() {
    alert("hi");
    this.setState({
      invitePopup: false,
    });
  }
  BackToMainPage(type) {
    if (type == "lib") {
      this.setState({
        SubId: 0,
        tabIndex: 3,
        redirecttype: "",
      });
    } else if (type == "ques") {
      this.setState({
        SubId: 0,
        tabIndex: 1,
        redirecttype: "",
      });
    } else if (type == "dash") {
      this.setState({
        SubId: 0,
        tabIndex: 0,
        redirecttype: "",
      });
    }
  }
}

const mapStateToProps = function (state) {
  return {
    wallList: state.dashboard.dashboardList,
    dashboardloading: state.dashboard.dashboardloading,
    wallListCount: state.dashboard.dashboardTotalCount,
    // tagDatas: state.subjects.list,
    // tagDatasPage: state.subjects.listPage,
    // tagDatas: state.subjects.searchList,
    tagDatasPage: state.subjects.searchListPage,
    RecentlyViewedQuestionsList: state.recentlyViewedQuestion.list,
    questionLists: state.myquestions.myQuestionList[0],
    totalItems: state.myquestions.myQuestionList[1],
    subLoading: state.mylibraryquestion.subLoading,
    topQuestions: state.mylibraryquestion.list,
    solvedTotalitems: state.mylibraryquestion.totalItems,
    QandAquestionList: state.topquestList.list,
    QandAquestTotalitems: state.topquestList.totalItems,
    updatedKeyname: state.topquestList.updatedKeyname,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getDashboardList: (page) => dispatch(getDashboardList(page)),
    getAllSubjectList: (type, page) => dispatch(getAllSubjectList(type, page)),
    getRecentlyViewedQuestionsList: () =>
      dispatch(getRecentlyViewedQuestionsList()),
    getMyQuestionsList: (page) => dispatch(getMyQuestionsList(page)),
    getMyLibraryQuestionList: (typeQuestion, pageNumber) =>
      dispatch(getMyLibraryQuestionList(typeQuestion, pageNumber)),
    getQuestionsList: (typeQuestionTop, pageNumber, subjectId) =>
      dispatch(
        getQuestionsList(typeQuestionTop, pageNumber, subjectId, true, false)
      ),
    searchByGroupsAndChallenges: (page, groupOrChallenge, searchKey) =>
      dispatch(searchByGroupsAndChallenges(page, groupOrChallenge, searchKey)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(Community)));
