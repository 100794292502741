import React, { Component } from "react";
import { Grid, Container } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

var ansList = "";
class SurveyUi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: [],
      frPollAnswer: [],
      frSurveyAnswer: [],
      selectGroupName: "",
      pollQuestion: "",
      inputsQuestion: [1],
      surveyQuestionType: "",
      surveyQuestion: [],
      inputsSurveyAnswer: [],
      answers: [],
    };
    this.removeMoreQuestions = this.removeMoreQuestions.bind(this);
    this.removeSurveyAnswers = this.removeSurveyAnswers.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    // console.log("removeSurveyAnswers nextProps",nextProps.DataClear);
    // if(this.props.DataClear !== nextProps.DataClear){
    //   ansList=""
    //   this.setState({
    //     inputsQuestion:[],
    //     surveyQuestion:[],
    //     inputsSurveyAnswer:[],
    //     frSurveyAnswer:[]
    //   })
    //   console.log("removeSurveyAnswers remove","Clear");
    // }
  }

  componentDidMount() {
    if (this.props.editState && this.props.assessmentListUpdated) {
      const count = [];
      const final = this.props.assessmentListUpdated.map((items, index) => {
        count.push(index);
        return {
          answerType: items.answerType === "select" ? "Select" : "Text",
          answers: items.answerType === "select" ? items.answer : [],
          answerLength: items.answerType === "select" ? items.answer : [],
          question: items.question,
          textTypeAnswer: items.answerType === "text" ? items.answer : "",
        };
      });

      this.setState({
        inputsQuestion: final?.length === 0 ? [1] : count,
        surveyQuestion: final,
      });
      console.log("data", final);
      console.log("data count", count);
    }
  }
  render() {
    console.log("");
    return (
      <div>
        <span
          style={{
            cursor: "pointer",
            marginTop: "20px",
            paddingBottom: "20px",
            float: "right",
            color: "blue",
          }}
          class="socialCardTextColor"
          onClick={this.addMoreQuestions}
        >
          + Add more questions
        </span>

        {this.state.inputsQuestion.length > 0 &&
          this.state.inputsQuestion.map((entry, indexQuestion) => (
            <div className="PTCInputWrapper">
              <Grid container>
                <Grid className="gridform" item xs={12} sm={12}>
                  <textarea
                    style={{
                      width: "100%",
                      minHeight: "38px",
                      borderRadius: "5px",
                      padding: "20px 20px",
                      background: "#dceaee",
                      border: "none",
                    }}
                    type="text"
                    onChange={(e) =>
                      this.handleInputQuestion(e, indexQuestion, "name")
                    }
                    required=""
                    name="pollQuestion"
                    //value={this.state.pollQuestion}
                    value={
                      this.state.surveyQuestion &&
                      this.state.surveyQuestion[indexQuestion] &&
                      this.state.surveyQuestion[indexQuestion].question
                    }
                    placeholder="Ask your question"
                  />

                  <Grid container>
                    <Grid
                      className="gridform"
                      item
                      xs={12}
                      sm={12}
                      lg={6}
                      md={6}
                      style={{ marginBottom: "1rem" }}
                    >
                      <div className="homePostInputWrapper">
                        <select
                          name="surveyQuestionType"
                          id="surveyQuestionType"
                          value={
                            this.state.surveyQuestion &&
                            this.state.surveyQuestion[indexQuestion] &&
                            this.state.surveyQuestion[indexQuestion].answerType
                          }
                          onChange={(e) =>
                            this.handleInputQuestion(e, indexQuestion, "type")
                          }
                        >
                          <option value="">Select</option>
                          <option value="Text">Text Type Option</option>
                          <option value="Select">Select Type Option</option>
                        </select>
                      </div>
                    </Grid>
                    <Grid
                      className="gridform"
                      item
                      xs={12}
                      sm={12}
                      lg={6}
                      md={6}
                    >
                      {this.state.inputsQuestion?.length > 1 && (
                        <span
                          style={{
                            cursor: "pointer",
                            color: "red",
                            paddingBottom: "20px",
                            float: "right",
                          }}
                          class="socialCardTextColor"
                          onClick={() =>
                            this.removeMoreQuestions(entry, indexQuestion)
                          }
                        >
                          {" "}
                          - Remove Question{" "}
                        </span>
                      )}
                    </Grid>{" "}
                    {this.state.surveyQuestion &&
                      this.state.surveyQuestion[indexQuestion] &&
                      this.state.surveyQuestion[indexQuestion].answerType ==
                        "Text" && (
                        <Grid
                          className="gridform"
                          item
                          xs={12}
                          sm={12}
                          lg={12}
                          md={12}
                        >
                          <textarea
                            style={{
                              width: "100%",
                              minHeight: "38px",
                              borderRadius: "5px",
                              padding: "20px 20px",
                              // background: "#dceaee",
                              background: "lightgray",
                              border: "none",
                            }}
                            type="text"
                            onChange={(e) =>
                              this.handleInputQuestion(
                                e,
                                indexQuestion,
                                "textTypeAnswer"
                              )
                            }
                            required=""
                            name="textTypeAnswer"
                            value={
                              this.state.surveyQuestion &&
                              this.state.surveyQuestion[indexQuestion] &&
                              this.state.surveyQuestion[indexQuestion]
                                .textTypeAnswer
                            }
                            placeholder="Add your text"
                          />
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                {/* selectQuestion type */}
                {this.state.surveyQuestion &&
                  this.state.surveyQuestion[indexQuestion] &&
                  this.state.surveyQuestion[indexQuestion].answerType ==
                    "Select" && (
                    <>
                      {this.state.surveyQuestion.length > 0 &&
                        this.state.surveyQuestion[indexQuestion].answerLength &&
                        this.state.surveyQuestion[
                          indexQuestion
                        ].answerLength.map((entry, index) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingBlock: "1rem",
                              width: "100%",
                            }}
                          >
                            <span key={index} class="socialCardTextColor">
                              Option - {index + 1}
                            </span>

                            <Grid container spacing={2} key={entry.index}>
                              <Grid className="gridform" item xs={12} sm={11}>
                                <input
                                  className="cpollInput"
                                  fullWidth
                                  type="text"
                                  onChange={(e) =>
                                    this.handleInputAns(
                                      e,
                                      index,
                                      "answerList",
                                      indexQuestion

                                      // e,
                                      // indexQuestion,
                                      // "answerList",
                                      // index
                                    )
                                  }
                                  style={{
                                    width: "100%",
                                    height: "4rem",
                                    border: "none",
                                    backgroundColor: "lightgray",
                                    padding: "20px",
                                  }}
                                  required=""
                                  name="frPollAnswer"
                                  placeholder="Add your option"
                                  value={
                                    this.state.surveyQuestion &&
                                    this.state.surveyQuestion[indexQuestion] &&
                                    this.state.surveyQuestion[indexQuestion]
                                      .answers[index]
                                  }
                                />
                              </Grid>

                              <Grid className="gridform" item xs={12} sm={1}>
                                <span
                                  style={{
                                    display: "block",
                                    cursor: "pointer",
                                    // padding: "20px",
                                    fontSize: "35px",
                                    color: "red",
                                  }}
                                  class="socialCardTextColor"
                                  onClick={() =>
                                    this.removeSurveyAnswers(
                                      indexQuestion,
                                      index
                                    )
                                  }
                                >
                                  {" "}
                                  -{" "}
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        ))}
                      <span
                        style={{
                          cursor: "pointer",
                          marginTop: "20px",
                          paddingBottom: "20px",
                          color: "blue",
                        }}
                        class="socialCardTextColor"
                        // onClick={this.addSurveyAnswers}
                        onClick={() => this.addSurveyAnswers(indexQuestion)}
                      >
                        + Add more option
                      </span>
                    </>
                  )}
              </Grid>
            </div>
          ))}
      </div>
    );
  }

  addSurveyAnswers = (questionIndex) => {
    // let input = this.state.surveyQuestion[qIndex].answerLength;
    // input.push(1);
    // this.setState({
    //   // surveyQuestion: this.state.surveyQuestion,
    // });
    // ansList = "";
    this.setState((prevState) => {
      const updatedSurveyQuestions = [...prevState.surveyQuestion];
      const updatedAnswerLength = [
        ...updatedSurveyQuestions[questionIndex].answerLength,
      ];
      updatedAnswerLength.push(1);
      updatedSurveyQuestions[questionIndex] = {
        ...updatedSurveyQuestions[questionIndex],
        answerLength: updatedAnswerLength,
      };
      return {
        surveyQuestion: updatedSurveyQuestions,
      };
    });

    ansList = "";
  };

  async removeSurveyAnswers(indexQuestion, index) {
    ansList = "";
    if (this.state.surveyQuestion[indexQuestion].answerLength) {
      var val = this.state.surveyQuestion[indexQuestion].answerLength;
      var ans = this.state.surveyQuestion[indexQuestion].answers;
      val.splice(index, 1);
      ans.splice(index, 1);
      this.setState({
        // frSurveyAnswer: val,
      });
      this.props.surveyQuestion(this.state.surveyQuestion);
    }
  }

  async removeMoreQuestions(record, index) {
    ansList = "";
    var inpu = this.state.inputsQuestion;
    if (this.state.inputsQuestion.length > 1) {
      inpu.splice(index, 1);
      if (this.state.surveyQuestion[index]) {
        var val = this.state.surveyQuestion;
        val.splice(index, 1);
        this.setState({
          surveyQuestion: val,
        });
      }
      await this.setState({
        inputsQuestion: inpu,
      });
      this.props.surveyQuestion(val);
    }
  }
  addMoreQuestions = (e) => {
    ansList = "";
    var input = this.state.inputsQuestion;
    if (this.state.inputsQuestion.length === 0) {
      input.push(1);
      this.setState({
        inputsQuestion: input,
      });
    } else if (this.state.surveyQuestion.length !== 0) {
      if (
        this.state.surveyQuestion[this.state.inputsQuestion.length - 1] !==
        undefined
      ) {
        var addMoreEnable =
          this.state.surveyQuestion[this.state.inputsQuestion.length - 1]
            .answerType;
        if (
          this.state.surveyQuestion[this.state.inputsQuestion.length - 1]
            .question === ""
        ) {
          toast.warn("Please fill your questions");
        } else if (addMoreEnable === undefined || addMoreEnable === "") {
          toast.warn("Please select your answer type");
        } else if (
          addMoreEnable === "Text" &&
          this.state.surveyQuestion[this.state.inputsQuestion.length - 1]
            .textTypeAnswer === ""
        ) {
          toast.warn("Please Add your comments");
        } else if (
          addMoreEnable === "Select" &&
          this.state.surveyQuestion[this.state.inputsQuestion.length - 1]
            .answers.length === 0
        ) {
          toast.warn("Please Add your answer poll");
        } else {
          input.push(1);
          this.setState({
            inputsQuestion: input,
          });
        }
      } else {
        // input.push(1);
        // this.setState({
        //   inputsQuestion: input,
        // });
        toast.warn("Please Add your questions ");
      }
      console.log("check addMoreEnable", addMoreEnable);
    } else {
      toast.warn("Please Add your Questions");
    }
  };

  handleInputQuestion = (event, index, type, indexQuestion) => {
    event.preventDefault();
    event.stopPropagation();

    var newAnswers = this.state.surveyQuestion;

    if (newAnswers[index]) {
      if (type == "name") {
        var obj = newAnswers[index];
        obj.question = event.target.value;
        newAnswers[index] = obj;
      } else if (type == "type") {
        var obj = newAnswers[index];
        obj.answerType = event.target.value;
        obj.answerLength = event.target.value === "Select" ? [1] : [];
        obj.answers = [];
        obj.textTypeAnswer = "";
        newAnswers[index] = obj;
      } else if (type == "textTypeAnswer") {
        var obj = newAnswers[index];
        obj.textTypeAnswer = event.target.value;
        newAnswers[index] = obj;
      }
    } else {
      if (type == "name") {
        var obj = newAnswers[index];
        var obj = {
          question: event.target.value,
          answerType: "",
          textTypeAnswer: "",
          answers: [],
          answerLength: [],
        };
        newAnswers[index] = obj;
      } else if (type == "type") {
        var obj = newAnswers[index];
        var obj = {
          question: "",
          answerType: event.target.value,
          textTypeAnswer: "",
          answers: [],
          answerLength: [],
        };
        newAnswers[index] = obj;
      } else if (type == "textTypeAnswer") {
        var obj = newAnswers[index];
        var obj = {
          question: "",
          textTypeAnswer: event.target.value,
          answers: [],
          answerType: "",
          answerLength: [],
        };
        newAnswers[index] = obj;
      } else if (type == "answerList") {
      } else {
      }
    }

    this.setState({
      surveyQuestion: newAnswers,
    });

    this.props.surveyQuestion(newAnswers);
  };

  handleInputAns = (event, index, type, indexQuestion) => {
    event.preventDefault();
    event.stopPropagation();

    var qList = this.state.surveyQuestion;
    var newAnswers = this.state.surveyQuestion[indexQuestion].answers;

    if (newAnswers[index]) {
      if (type == "answerList") {
        ansList = event.target.value;
        var check = qList[indexQuestion].answers;

        if (event.target.value.length === 0) {
          qList[indexQuestion].answers[index] = " ";
        } else if (check[index]) {
          check[index] = ansList;
        } else {
          check.answers.push(ansList);
          ansList = "";
        }
      }
    } else {
      if (type == "answerList") {
        ansList = event.target.value;
        qList[indexQuestion].answers.push(ansList);
      }
    }

    this.setState({
      surveyQuestion: qList,
    });

    this.props.surveyQuestion(qList);
    //  this.setState({answers:answer_id.target.value})
  };
}

export default SurveyUi;
