import React, { useEffect, useState } from "react";
import "./home.css";
import { BsSearch } from "react-icons/bs";
import avatar from "../../assets/img/avatar.jpg";
import dummy_image from "../../assets/img/default_avatar.jpg";
import { users, state } from "../../Data/sampledata";
import MessageList from "../../Component/MessageList/MessageList";
import ChatItemBox from "../../Component/ChatItemBox/ChatItemBox";

import { useDispatch, useSelector, useStore } from "react-redux";
import { API_URL } from "../../../../config/app-config";
import {
  currentFriendMessages,
  // getAllFriends,
  getLastMessage,
  seenMessage,
  deliveredMessage,
  clearMessages,
  updateViewCount,
} from "../../store/actions/MessengerAction";

import AuthService from "../../../../session/AuthService_old";
import i18n from "../../../../i18n";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { withTranslation } from "react-i18next";
import { IoChatboxEllipsesOutline } from "react-icons/io5";

function Home({ t, user, friends, socket, selectedUser, closeCallBack }) {
  const dispatch = useDispatch();
  const userInfo = user;
  const homePageRenderCount = React.useRef(0);
  homePageRenderCount.current = homePageRenderCount.current + 1;
  const store = useStore();
  const [openCard, setOpenCard] = React.useState(false);
  const [showItemBox, setShowItemBox] = React.useState(false);
  const [currentFriend, setCurrentFriend] = React.useState(null);
  const [unseenMessageCount, setUnseenMessageCount] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [socketMessage, setSocketMessage] = React.useState(null);
  const [initialLastMessage, setInitialLastMessage] = React.useState(null);
  const [isSelectedUserNeedstobeClear, setisSelectedUserNeedstobeClear] =
    React.useState(false);
  const [totalMessageCount, settotalMessageCount] = useState(0);
  const [friendsId, setFriendsId] = useState([]);
  const [initialPopUp, setInitialPopup] = useState(false);
  const [receivedMessage, setReceivedMessage] = useState(null);
  // const [messages, setMessages] = React.useState([])
  // const [recivingData,setrecivingData]=useState(null);
  const lastMessages = useSelector((state) => state.messenger?.lastMessages);
  const messages = useSelector((state) => state.messenger.messages);
  const isMessageLoading = useSelector(
    (state) => state.messenger.isMessageLoading
  );

  const filteredFriends = friends;

  console.group("Memoized State : Home js Props");
  console.log("Memoized State : Rendered Count", homePageRenderCount.current);
  console.log("Memoized State : user(Home)", userInfo);
  console.log("Memoized State : friends(Home)", friends);
  console.log("Memoized State : filteredfriends(Home)", filteredFriends);
  console.log("Memoized State : friends(currentFriend)", currentFriend);
  console.log("Memoized State : friends(lastMessages)", lastMessages);
  console.log("Memoized State : friends(messages)", messages);
  console.log("Memoized state : selectedUser", selectedUser);
  console.groupEnd();

  const sortedLastMessage = lastMessages.sort((a, b) => {
    return a.created_at - b.created_at;
  });

  const sortedfriendsListBasedOnNewMessage = filteredFriends.sort((a, b) => {
    console.log("Started Sorting");
    const findlastMessageIndex = (a) =>
      sortedLastMessage.findIndex((message) => {
        return (
          +message.senderId === a?.userId || +message.receiverId === a?.userId
        );
      });

    const firstDataCreatedAt =
      findlastMessageIndex(a) === -1
        ? null
        : sortedLastMessage[findlastMessageIndex(a)].created_at;
    const secondDataCreateAt =
      findlastMessageIndex(b) === -1
        ? null
        : sortedLastMessage[findlastMessageIndex(b)].created_at;

    return new Date(secondDataCreateAt) - new Date(firstDataCreatedAt);
  });

  let i = 0;

  const handleChatListBoxOpen = (friend, id) => {
    console.log("handle-click", id);
    setCurrentFriend(friend);
    if (id) {
      dispatch(
        updateViewCount(id?.messageId ? id.messageId : id?._id, friend.userId)
      );
    }
  };

  const handleChatListBoxClose = () => {
    console.log("CLOSE ENTERING");
    dispatch({
      type: "CHAT_POPUP_ACTION",
      payload: null,
    });
    dispatch(clearMessages());
    setShowItemBox(false);
    setCurrentFriend(null);
    setUnseenMessageCount([]);
    if (selectedUser) {
      setisSelectedUserNeedstobeClear(true);
    }
    console.log("CLOSE CLOSED");
  };

  const showCardBody = () => {
    setSearchQuery("");
    setOpenCard(!openCard);
  };

  React.useEffect(() => {
    if (isSelectedUserNeedstobeClear) {
      console.log("Memoized Stated : Selected Used Cleared ");
      closeCallBack();
    }
  }, [isSelectedUserNeedstobeClear]);

  React.useEffect(() => {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    const friendsId = filteredFriends.map((item) => item.userId);
    setFriendsId(friendsId ? friendsId : []);
  }, []);

  React.useEffect(() => {
    if (selectedUser) {
      console.log("Memoized State : selected Used", selectedUser);
      setCurrentFriend(selectedUser);
      // setShowItemBox(true);
      // setUnseenMessageCount([]);
      // dispatch(clearMessages());
    }
  }, [selectedUser]);

  React.useEffect(() => {
    dispatch(getLastMessage(userInfo?.uId)).then(() => {
      const currentUpdateState = store.getState();
      setInitialLastMessage(currentUpdateState?.messenger?.lastMessages);
    });
  }, []);

  React.useEffect(() => {
    if (currentFriend) {
      console.log("Memoized State : going to dispatch message", currentFriend);
      dispatch(currentFriendMessages(userInfo?.uId, currentFriend?.userId));
    }
  }, [currentFriend?.userId]);

  React.useEffect(() => {
    console.log("Memoized State : isMessage Loading changed", isMessageLoading);
    if (!isMessageLoading) {
      setShowItemBox(true);
      setUnseenMessageCount([]);
    }
  }, [isMessageLoading]);

  React.useEffect(() => {
    socket.current.emit("loginedUsers", userInfo);
  }, []);

  useEffect(() => {
    const handleMessage = (data) => {
      console.log("Memoized State : getting Message", data);
      // setSocketMessage(data);

      dispatch({
        type: "UPDATE_FRIEND_MESSAGE",
        payload: data,
      });
      dispatch({
        type: "UPDATE_RECEIVE_MESSAGE",
        payload: data,
      });
      //received
      setReceivedMessage(data);
    };

    const handleSeenMessageResponse = (data) => {
      console.log("message-seen", data);
      dispatch({
        type: "MESSAGE_SEEN",
        payload: data,
      });
    };

    const handleDeliveredMessageResponse = (data) => {
      console.log("message_delivered", data);
      dispatch({
        type: "MESSAGE_DELIVERED",
        payload: data,
      });
    };

    socket.current.on("getMessage", handleMessage);
    socket.current.on("seenMessageResponse", handleSeenMessageResponse);
    socket.current.on(
      "deliveredMessageResponse",
      handleDeliveredMessageResponse
    );

    return () => {
      socket.current.off("getMessage", handleMessage);
      socket.current.off("seenMessageResponse", handleSeenMessageResponse);
      socket.current.off(
        "deliveredMessageResponse",
        handleDeliveredMessageResponse
      );
    };
  }, []);

  useEffect(() => {
    console.log("%c VIEW MESSAGE : ->", "color:orange", receivedMessage);

    if (receivedMessage && currentFriend) {
      console.log(
        "%c ENTERING DISPATCHING USEEFFECT : ->",
        "color:orange",
        receivedMessage
      );
      if (
        +receivedMessage.senderId === currentFriend?.userId &&
        +receivedMessage.receiverId === userInfo?.uId
      ) {
        console.log("seen-message-update");
        // dispatch({
        //   type: "SOCKET_MESSAGE",
        //   payload: receivedMessage,
        // });

        dispatch(seenMessage(receivedMessage));

        socket.current.emit("seenMessage", receivedMessage);
        // dispatch({
        //   type: "UPDATE_FRIEND_MESSAGE",
        //   payload: receivedMessage,
        // });
      }
    }
  }, [receivedMessage]);
  // React.useEffect(() => {
  //   console.log(
  //     "%c ENTERING DISPATCHING USEEFFECT : ->",
  //     "color:orange",
  //     socketMessage
  //   );

  //   if (socketMessage && currentFriend) {
  //     console.log(
  //       "%c ENTERING DISPATCHING USEEFFECT : ->",
  //       "color:orange",
  //       socketMessage
  //     );
  //     if (
  //       +socketMessage.senderId === currentFriend?.userId &&
  //       +socketMessage.receiverId === userInfo?.uId
  //     ) {
  //       console.log("seen-message");
  //       dispatch({
  //         type: "SOCKET_MESSAGE",
  //         payload: socketMessage,
  //       });

  //       dispatch(seenMessage(socketMessage));

  //       dispatch({
  //         type: "UPDATE_FRIEND_MESSAGE",
  //         payload: socketMessage,
  //       });
  //     }
  //   }
  // }, [socketMessage]);

  React.useEffect(() => {
    if (socketMessage) {
      console.log(
        "%c ENTERING DELIVERY USEEFFECT : ->",
        "color:orange",
        socketMessage
      );
      dispatch(deliveredMessage(socketMessage));
      dispatch({
        type: "UPDATE_FRIEND_MESSAGE",
        payload: socketMessage,
      });
    }
  }, [socketMessage]);

  React.useEffect(() => {
    // if (socketMessage) {
    //   if (
    //     socketMessage.senderId !== currentFriend?.userId &&
    //     socketMessage.receiverId === userInfo?.uId
    //   ) {
    //   }
    //   console.log(
    //     "%c ENTERING MESSAGE COUNT USEEFFECT : -> :",
    //     "color:orange",
    //     socketMessage
    //   );
    //   setUnseenMessageCount([...unseenMessageCount, socketMessage]);
    //   dispatch({
    //     type: "UPDATE_FRIEND_MESSAGE",
    //     payload: socketMessage,
    //   });
    // }
  }, [socketMessage]);
  useEffect(() => {
    console.log("friendsId", friendsId);
    const data = lastMessages.reduce((result, data) => {
      if (
        data?.unseenCount &&
        +data?.receiverId !== +data?.senderId &&
        (friendsId.includes(+data.senderId) ||
          friendsId.includes(+data.receiverId))
      ) {
        result = result + data?.unseenCount;
      }
      return result;
    }, 0);
    console.log("data", data);
    console.log("message-lastMessages", lastMessages);

    settotalMessageCount(data);
    if (data && !initialPopUp) {
      console.log("open-popup", initialPopUp);
      setInitialPopup(true);
      setSearchQuery("");
      setOpenCard(true);
    }
    // console.log("message-count", data);
  }, [lastMessages, friendsId]);
  return (
    <div className="home-container mt-4">
      <div className="chat-card" style={{ cursor: "pointer" }}>
        {window.matchMedia("(min-width:739px)").matches === true ? (
          <>
            <div className="card-header" onClick={showCardBody}>
              <div className="img-container">
                <img
                  src={
                    userInfo.profile_uuid
                      ? `${API_URL}auth/image/${userInfo?.profile_uuid}`
                      : dummy_image
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = dummy_image;
                  }}
                  alt="img"
                  className="avatar-img"
                />
                <span>{t("Messaging")}</span>
              </div>
              <div className="icons-container">
                {totalMessageCount > 0 && (
                  <div className="ml-auto d-flex">
                    <IoChatboxEllipsesOutline
                      style={{ width: "24px" }}
                      className="h-auto bg-color"
                    />
                    <span className="notification-puls rounded-notification">
                      {totalMessageCount}
                    </span>
                  </div>
                )}
                <div onClick={showCardBody}>
                  {openCard ? (
                    <>
                      <MdKeyboardArrowDown className="wizard-icon" />{" "}
                    </>
                  ) : (
                    <>
                      <MdKeyboardArrowUp className="wizard-icon" />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={`card-body ${openCard ? "open" : "close"} `}>
              {/* search Filter */}
              <div className="search-filter">
                <div>
                  <BsSearch className="search-icon" fill="gray" />
                  <input
                    type="text"
                    name=""
                    id=""
                    value={searchQuery}
                    placeholder="Search Users"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
              {/* Message List */}
              <div className="conversation-list">
                {filteredFriends &&
                  filteredFriends.length > 0 &&
                  filteredFriends
                    .filter((friend) =>
                      searchQuery === ""
                        ? friend
                        : friend?.userName
                            .toLowerCase()
                            .includes(searchQuery.toLocaleLowerCase())
                    )
                    .map((friend) => (
                      <MessageList
                        key={friend.userId}
                        userId={userInfo.uId}
                        friend={friend}
                        handleChatList={handleChatListBoxOpen}
                        unseenMessageCount={unseenMessageCount}
                        allFriends={filteredFriends}
                        initialLastMessage={
                          initialLastMessage ? initialLastMessage : []
                        }
                        lastMessage={lastMessages}
                        setUnseenMessageCount={setUnseenMessageCount}
                        isCurrentFriendAvailiable={currentFriend}
                        // onClick={() => setCurrentFriend(friend)}
                      />
                    ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="card-header">
              <div className="img-container">
                <img
                  src={
                    userInfo?.louPhoto
                      ? `data:image/jpeg;base64,${userInfo?.uPhoto}`
                      : dummy_image
                  }
                  alt="img"
                  className="avatar-img"
                />
                <span>{t("Messaging")}</span>
              </div>
              <div className="icons-container">
                <div onClick={showCardBody}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    data-supported-dps="16x16"
                    fill="gray"
                    width="16"
                    height="16"
                    focusable="false"
                    className="chatApp-icon open-icons"
                  >
                    <path d="M15 11L8 6.39 1 11V8.61L8 4l7 4.61z"></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="card-body open">
              {/* search Filter */}
              <div className="search-filter">
                <div>
                  <BsSearch className="search-icon" fill="gray" />
                  <input
                    type="text"
                    name=""
                    id=""
                    value={searchQuery}
                    placeholder="Search Users"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
              {/* Message List */}
              <div className="conversation-list">
                {filteredFriends &&
                  filteredFriends.length > 0 &&
                  sortedfriendsListBasedOnNewMessage
                    .filter((friend) =>
                      searchQuery === ""
                        ? friend
                        : /*friend.userProfile.displayName */ friend?.userName
                            .toLowerCase()
                            .includes(searchQuery.toLocaleLowerCase())
                    )
                    .map((friend) => (
                      <MessageList
                        key={friend.userId}
                        friend={friend}
                        userId={userInfo.uId}
                        handleChatList={handleChatListBoxOpen}
                        unseenMessageCount={unseenMessageCount}
                        allFriends={filteredFriends}
                        initialLastMessage={
                          initialLastMessage ? initialLastMessage : []
                        }
                        lastMessage={lastMessages}
                        setUnseenMessageCount={setUnseenMessageCount}
                        isCurrentFriendAvailiable={currentFriend}
                        // onClick={() => setCurrentFriend(friend)}
                      />
                    ))}
              </div>
            </div>
          </>
        )}
      </div>
      {showItemBox && currentFriend && (
        <ChatItemBox
          handleChatListBoxClose={handleChatListBoxClose}
          currentFriend={currentFriend}
          messages={messages}
          user={userInfo}
          socket={socket}
          isMessageLoading={isMessageLoading}
        />
      )}
    </div>
  );
}

export default React.memo(withTranslation()(Home));
