import api from "./BaseAPI.js";
import auth from "./AuthAPI.js";
import Http from "./Http.js";
import * as APPCONFIG from "../config/app-config.js";
//import Axios from "axios";

let CACHE_USER_PHOTO = {};

let CACHE_COVER_USER_PHOTO = {};

let CACHE_HIT_USER_PHOTO = {};

class ForumService {
  //group or events get popular topics
  getAllTopics() {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}auth/getallsubject`,
    });
  }
  //group or events get name topics
  getNameTopics() {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}auth/getallsubject`,
    });
  }
  //group or events get naw topics
  getNewTopics() {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}auth/getallsubject`,
    });
  }

  getAllQuestionsByUserId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      //  url: `${APPCONFIG.API_URL}api/getAllQuestionsByUserId`,
      data: JSON.stringify(formValues),
    });
  }

  getAnswerById(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getQuestionById`,
      data: JSON.stringify(formValues),
    });
  }

  getLibraryQuestionById(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getLibraryQuestionById`,
      data: JSON.stringify(formValues),
    });
  }

  showviews(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveQuestionViewes`,
      data: JSON.stringify(formValues),
    });
  }

  /**** USER PHOTO CACHE & API  */

  setUserPhotoCache(formValues, response) {
    // console.log("Set - Cache Photo :" + formValues.userId);
    CACHE_USER_PHOTO[formValues.userId] = response;
  }

  getUserPhotoCache(formValues) {
    if (CACHE_USER_PHOTO[formValues.userId]) {
      // console.log("Loading From Cache Photo :" + formValues.userId);
      return CACHE_USER_PHOTO[formValues.userId];
    } else {
      return null;
    }
  }
  setHitUserPhotoCache(formValues, response) {
    // console.log("Set - Cache Photo :" + formValues.userId);
    CACHE_HIT_USER_PHOTO[formValues.userId] = response;
  }
  getHitUserPhotoCache(formValues) {
    if (CACHE_HIT_USER_PHOTO[formValues.userId]) {
      // console.log("Loading From Cache Photo :" + formValues.userId);
      return CACHE_HIT_USER_PHOTO[formValues.userId];
    } else {
      return null;
    }
  }
  /**** USER COVER PHOTO CACHE & API  */

  setUserCoverPhotoCache(formValues, response) {
    CACHE_COVER_USER_PHOTO[formValues.userId] = response;
  }

  getUserCoverPhotoCache(formValues) {
    if (CACHE_COVER_USER_PHOTO[formValues.userId]) {
      return CACHE_COVER_USER_PHOTO[formValues.userId];
    } else {
      return null;
    }
  }
  getUserPhoto(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getUserPhoto`,
      data: JSON.stringify(formValues),
    });
  }

  /**** USER PHOTO CACHE & API  */

  getTopicFilter(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/tagFilters`,
      data: JSON.stringify(formValues),
    });
  }
  getTopicFilterV2(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/tagFiltersV2`,
      data: JSON.stringify(formValues),
    });
  }

  getPastEvents(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/pastEvents`,
      data: JSON.stringify(formValues),
    });
  }

  getmyGroupEventsEvents(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/myGroup`,
      data: JSON.stringify(formValues),
    });
  }
  getmyGroupEventsEventsV2(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/myGroupV2`,
      data: JSON.stringify(formValues),
    });
  }
  getmyGroupEventsEventsHome(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/myGroupHome/${Http.WORK_SPACE()}`,
      data: JSON.stringify(formValues),
    });
  }
  saveQuestion(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveQuestion`,
      data: formValues,
      config,
    });
  }
  saveAskQuestion(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveAskQuestionV2/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  saveQuestionV2(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveQuestionV2`,
      data: formValues,
      config,
    });
  }
  saveSubject(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/savesubject`,
      data: formValues,
      config,
    });
  }
  saveSubjectV2(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveSubjectV2/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }

  updateSubject(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updatesubject`,
      data: formValues,
      config,
    });
  }
  updateSubjectV2(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateSubjectV2/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }

  updateQuestion(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateQuestionById`,
      data: formValues,
      config,
    });
  }

  saveAnswer(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveAnswer`,
      data: formValues,
      config,
    });
  }

  saveAnswerReply(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveAnswerReplyV2/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  updateAnswerReply(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateAnswerReplyV2`,
      data: formValues,
      config,
    });
  }
  updatedAnswerById(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updatedAnswerById`,
      data: formValues,
      config,
    });
  }
  updatedAnswerByIdV2(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updatedAnswerByIdV2`,
      data: formValues,
      config,
    });
  }
  getWallJson(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getWallJson`,
      data: JSON.stringify(formValues),
    });
  }

  saveQuestionUpVotes(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/questonUpvotes`,
      data: JSON.stringify(formValues),
    });
  }

  saveQuestionDownVotes(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/questonDownvotes`,
      data: JSON.stringify(formValues),
    });
  }

  saveAnswerUpvotes(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/answerUpvotes`,
      data: JSON.stringify(formValues),
    });
  }

  saveAnswerDownvotes(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/answerDownvotes`,
      data: JSON.stringify(formValues),
    });
  }

  questionsearchList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/questionsearchList`,

      data: JSON.stringify(formValues),
    });
  }
  askQuestionList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/askQuestionList`,
      data: JSON.stringify(formValues),
    });
  }
  questionsTopSearch(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/questionsTopSearch`,
      data: JSON.stringify(formValues),
    });
  }

  getChallengesandResources(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getChallengesandResources`,
      data: JSON.stringify(formValues),
    });
  }

  getChallengesList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getChallengesAll`,
      data: JSON.stringify(formValues),
    });
  }
  getChallengesListV2(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getChallengesAllV2`,
      data: JSON.stringify(formValues),
    });
  }
  getResourcesList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getResourcesAll`,
      data: JSON.stringify(formValues),
    });
  }
  getResourcesListV2(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getResourcesAllV2`,
      data: JSON.stringify(formValues),
    });
  }

  getDashoardDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getDashBoardDetailsByUser`,
      data: JSON.stringify(formValues),
    });
  }

  saveQuestionLikes(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveWallLike`,
      data: JSON.stringify(formValues),
    });
  }

  getRecentlyViewedQuestions(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/recentlyViewedQuestions`,
      data: JSON.stringify(formValues),
    });
  }

  deleteAnswerById(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteAnswerById`,
      data: JSON.stringify(formValues),
    });
  }

  deleteAnswerreplyById(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteAnswerReply`,
      data: JSON.stringify(formValues),
    });
  }

  deleteQuestionById(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteQuestionById`,
      data: JSON.stringify(formValues),
    });
  }

  solvedUnsolvedQuestion(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/solvedUnsolvedQuestion`,
      data: JSON.stringify(formValues),
    });
  }

  getudVoteStatus(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getQuestionUpvotesByUser`,
      data: JSON.stringify(formValues),
    });
  }

  getudAVoteStatus(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAnswerUpvotesByUser`,
      data: JSON.stringify(formValues),
    });
  }

  reportQuestionByUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/reportQuestionByUser`,
      data: formValues,
    });
  }

  reportAnswerByUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/reportAnswerByUser`,
      data: formValues,
    });
  }
  reportAnswerReplyByUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/reportAnswerReplyByUser`,
      data: formValues,
    });
  }
  // getMyLibraryQuestions(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getMylibraryQuestion`,
  //     data: JSON.stringify(formValues),
  //   });
  // }
  getMyLibraryQuestions(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getMylibraryQuestion`,
      data: JSON.stringify(formValues),
    });
  }

  saveQuesionStar(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveQuesionStar`,
      data: formValues,
    });
  }

  blockeduserForAnswer(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/blockeduserForAnswer`,
      data: formValues,
    });
  }

  getBlockeduser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getBlockeduser`,
      data: formValues,
    });
  }

  getallSubjectGroupsByMembersCount(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getTrendingCommunity/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }

  getMyGroupsByMembersandMessages(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getMyGroupsBasedonMemberandMessage`,
      data: formValues,
    });
  }
  getMyGroupsByMembersandMessagesV2(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getMyGroupsBasedonMemberandMessageV2`,
      data: formValues,
    });
  }
  getPostOverall(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/getPostOverall`,
      url: `${APPCONFIG.API_URL}api/getGroupQuestionList`,
      data: formValues,
    });
  }

  saveChallenge(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/saveChallenge`,
      url: `${APPCONFIG.API_URL}api/v1/careerlab/apply`,
      data: formValues,
      config,
    });
  }

  updateChallenge(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/updateChallenge`,
      url: `${APPCONFIG.API_URL}api/v1/careerlab/updateApply`,
      data: formValues,
      config,
    });
  }
  getResourcePostByImage(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getResourcePostByImageByFilter`,
      data: formValues,
      config,
    });
  }
  getResourcePostByVideo(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getResourcePostByVideoByFilter`,
      data: formValues,
      config,
    });
  }
  getResourcePostByDocument(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getResourcePostByDocumentByFilter`,
      data: formValues,
      config,
    });
  }
  saveSourceLike(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveSourceLike`,
      data: formValues,
      config,
    });
  }
  getAllChallengesApplied(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      //  url: `${APPCONFIG.API_URL}api/getAllChallengesAppliedV2`,
      url: `${APPCONFIG.API_URL}api/v1/carerrlab/getApplyDetails`,
      data: formValues,
      config,
    });
  }

  getSingleChallenge(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getSingleChallenge`,
      data: formValues,
      config,
    });
  }

  saveChallengeLike(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveChallengeLike`,
      data: formValues,
      config,
    });
  }
  searchByGroupsAndChallenges(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/searchByGroupsAndChallenges/${Http.WORK_SPACE()}`,
      data: JSON.stringify(formValues),
    });
  }
  deleteAskQuestion(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteAskQuestion`,
      data: JSON.stringify(formValues),
    });
  }
  askSingleQuestionList(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/askSingleQuestionList`,
      data: formValues,
      config,
    });
  }
  updateAskQuestion(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateAskQuestionV2`,
      data: formValues,
      config,
    });
  }
  saveCalendar(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v2/saveCalendar/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  saveCalendarV3(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v2/saveCalendarV3/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  updateCalendar(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v2/updateCalendar`,
      data: formValues,
      config,
    });
  }
  saveCommonCalendar(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveCommonCalendar/${Http.WORK_SPACE()}`,
      data: JSON.stringify(formValues),
    });
  }
  askQuestionListV2(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/askQuestionListV2`,
      data: JSON.stringify(formValues),
    });
  }
  askQuestionAnswerList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/askQuestionAnswerList`,
      data: JSON.stringify(formValues),
    });
  }
  saveAnswerV2(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveAnswerV2/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  askQuestionAnswerReplyList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/askQuestionAnswerReplyList`,
      data: JSON.stringify(formValues),
    });
  }
  answerUpAndDownVotes(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/answerUpAndDownVotes`,
      data: JSON.stringify(formValues),
    });
  }
  searchForumAskFilter(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/searchForumAskFilter`,
      data: JSON.stringify(formValues),
    });
  }
  forumTagList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/forumTagList`,
      data: JSON.stringify(formValues),
    });
  }
  blockAUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/blockAUser`,
      data: JSON.stringify(formValues),
    });
  }
  reportAUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/report/user/${Http.WORK_SPACE()}`,
      data: JSON.stringify(formValues),
    });
  }
  admin_resource_save(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/admin_save_subject/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  admin_resource_post_save(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${
      //   APPCONFIG.API_URL
      // }api/admin_resource_post_save/${Http.WORK_SPACE()}`,
      url: `${
        APPCONFIG.API_URL
      }api/v1/adminResource/savePost/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  admin_update_subject(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/admin_update_subject`,
      data: formValues,
      config,
    });
  }
  admin_resource_post_edit(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/admin_resource_post_edit`,
      url: `${APPCONFIG.API_URL}api/v1/adminResource/postEdit`,
      data: formValues,
      config,
    });
  }
  myCommunityFilter(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/myCommunityFilter/${Http.WORK_SPACE()}`,
      data: JSON.stringify(formValues),
    });
  }
  getTagsByCommunityType(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/getTagsByCommunityType/${Http.WORK_SPACE()}`,
      data: JSON.stringify(formValues),
    });
  }
  saveUserBusinessData = async (formValues, config) => {
    // const response = await axios.get(`${APPCONFIG.API_URL}api/getPhoneCodesv2`);
    // return response;
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}api/saveUserBusinessData`,
      data: formValues,
      config,
    });
  };
  saveOrUpdateDirectoryList(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}auth/saveOrUpdateDirectoryListV2`,
      url: `${
        APPCONFIG.API_URL
      }api/v1/directory/saveOrUpdate/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  // getknowledgeBaseQuestions(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/knowledgeBase`,
  //     data: JSON.stringify(formValues),
  //   });
  // }

  // deleteQuestionById(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/deleteQuestionById`,
  //     data: JSON.stringify(formValues),
  //   });
  // }
  // getQuestionLikes(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getDashBoardLikeList`,
  //     data: JSON.stringify(formValues),
  //   });
  // }

  // saveQuestionLikes(formValues) {

  //   return auth({
  //       method: 'post',
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/saveQuestionLike`,
  //       data: JSON.stringify(formValues)
  //   })
  // }
  // getLibraryQuestions(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getLibraryQuestions`,
  //     data: JSON.stringify(formValues),
  //   });
  // }

  // questionsTopSearch(formValues, config) {

  //   return auth({
  //       method: 'post',
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/questionsTopSearch`,
  //       data: formValues,
  //       config
  //   })
  // }
  // questionsTopSearch(formValues) {
  //   return auth({
  //       method: 'post',
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/questionsTopSearch`,
  //       data: JSON.stringify(formValues)
  //   })
  // }

  // shareQuestionDetail(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/shareQuestion`,
  //     data: JSON.stringify(formValues),
  //   });
  // }
  // shareAnswerDetail(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/shareAnswers`,
  //     data: JSON.stringify(formValues),
  //   });
  // }
  // saveQuestionDownVotes(formValues) {

  //   return auth({
  //       method: 'post',
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/questonDownvotes`,
  //       data: JSON.stringify(formValues)
  //   })
  // }
  // getTopQuestions() {
  //   return auth({
  //     method: "get",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getAllfourmQuestions`,
  //   });
  // }

  // getSolvedQuestion(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/solvesQuestionsByUser`,
  //     data: JSON.stringify(formValues),
  //   });
  // }

  // getmyParticipants(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/myParticipants`,
  //     data: JSON.stringify(formValues),
  //   });
  // }

  // getunSolvedQuestion(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/unSolvesQuestionsByUser`,
  //     data: JSON.stringify(formValues),
  //   });
  // }

  // getTopQuestionFilters(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/TopQuestionFilters`,
  //     data: JSON.stringify(formValues),
  //   });
  // }

  // getUnansweredQuestions(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/unAnsweredQuestion`,
  //     data: JSON.stringify(formValues),
  //   });
  // }
  // getAllAnswersByQuestionId(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getAllAnswersByQuestionId`,
  //     data: JSON.stringify(formValues),
  //   });
  // }
  // getAllQuestionsSortByTopic(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getAllQuestionsSortByTopic`,
  //     data: JSON.stringify(formValues),
  //   });
  // }
  // getTopicCount(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getTopicCount`,
  //     data: JSON.stringify(formValues),
  //   });
  // }
  // userFilters(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/userFilters`,
  //     data: JSON.stringify(formValues),
  //   });
  //}
  // saveQuestionVotes(formValues) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/saveQuestionVotes`,
  //     data: JSON.stringify(formValues),
  //   });
  // }

  // getQuestionLastActive(formValues, config) {
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/getQuestionLastActive`,
  //     data: formValues,
  //     config,
  //   });
  // }

  // saveAnswerVotes(formValues) {
  //   console.log("save Answer votes api");
  //   return auth({
  //     method: "post",
  //     headers: Http.AUTH_HEADERS(),
  //     url: `${APPCONFIG.API_URL}api/saveAnswerVotes`,
  //     data: JSON.stringify(formValues),
  //   });
  // }
}
export default new ForumService();
