import { Card, Paper } from "@material-ui/core";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  // getUserComments,
  getSinglePostData,
} from "../../../redux/actions/squareHomeActions";
import * as APPCONFIG from "../../../config/app-config.js";
import PostService_old from "../../../api/PostService_old";
import { toast, ToastContainer } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";
import PostCard from "./PostCard";
import BlockUi from "react-block-ui";
import CommunityPostDescription from "./CommunityPostDescription";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AiOutlineWarning } from "react-icons/ai";
import { getVideoStatus } from "../../../utils";
import { getLikeCountPopUp } from "../../../redux/actions/PostCardActions.js";

class HomePostCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postId: 0,
      typeData: "",
      chatUserName: "",
      chatUserId: "",
      friendChatStatus: false,
      userCommentListData: [],
      menuState: false,
      postType: "",
      reportCommentState: false,
      reportCommentId: 0,
      reportCommentDescription: "",
      commentsText: "",
      postData: {},
      fileVideoData: [],
      loaderStats: false,
      fileImageData: [],
      postNotPresent: false,
      notJoin: false,
      isGroupPost: false,
      isNotFriend: false,
      videoStatus: false,
    };
  }

  componentDidMount() {
    // this.props.getSinglePostData(
    //   this.props.match.params.id,
    //   this.props.match.params.type
    // );
    // this.props.getUserComments(3447, "postList");
    // this.setState({
    //   userCommentListData: this.props.userCommentList,
    //   postId: this.props.match.params.id,
    //   postType: this.props.match.params.type,
    //   postData: this.props.singlePost,
    // });
    this.getSinglePostData();
  }
  // componentDidUpdate(previous) {
  //   if (
  //     this.props.singlePost &&
  //     this.props.singlePost !== previous.singlePost
  //   ) {
  //     this.setState({
  //       postData: this.props.singlePost,
  //       postId: this.props.match.params.id,
  //       postType: this.props.match.params.type,
  //       userCommentListData: this.props.userCommentList,
  //     });
  //     this.getMediaFiles(
  //       this.props.singlePost?.postMediaFiles,
  //       this.props.singlePost?.type
  //     );
  //   }
  // }
  onBack = () => {
    this.props.history.push(`/${this.props.workspaceType}/home`);
  };
  render() {
    const { t } = this.props;
    console.log("singlePost", this.state.postData);
    return (
      <>
        <Paper>
          <BlockUi
            tag="div"
            message={t("Post loading please wait ...")}
            style={{ minHeight: "90px" }}
            className="wallLoadingPage"
            blocking={this.state.loaderStats}
          >
            <div class="p-1">
              {/* <div class="adb-card  "> */}
              <div class="adb-card-new  ">
                {this.state.postNotPresent ? (
                  <>
                    <div class="p-3 mb-2 bg-warning-post  m-2 mb-3 d-flex align-items-center justify-content-center text-danger">
                      <AiOutlineWarning
                        style={{ width: "24px", height: "auto" }}
                        className="mr-2"
                      />
                      <p> {t("Post has been deleted")} !</p>
                    </div>
                  </>
                ) : this.state.isNotFriend ? (
                  <div className="community-description font-weight-bold">
                    {t(
                      "Your not authorized this post.Kindly check with admin !"
                    )}
                    <p
                      className="ml-4 bg-color"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.onBack()}
                    >
                      <ArrowBackIcon />
                      {t("Back to home")}
                    </p>
                  </div>
                ) : this.state.postData?.subjectId &&
                  this.props.match.params.type === "groupMessage" &&
                  this.state.notJoin ? (
                  <>
                    <CommunityPostDescription
                      subjectId={this.state.postData?.subjectId}
                      updateStatus={() => this.setState({ notJoin: false })}
                    />{" "}
                  </>
                ) : (
                  <>
                    {!this.state.loaderStats && (
                      // <div className="home-post-card-wrap2">
                      <div className="flex-grow-1">
                        <PostCard
                          index={0}
                          data={this.state.postData}
                          postDescriptionPage={true}
                          likestatusUpdate={this.likeStatusUpdate}
                          commendsUpdate={this.commendsUpdate}
                          subscribeUpdate={this.subscribeUpdate}
                        />
                        {/* <div className="home-post-comment-wrap">
                  <div className="d-flex">
                    <textarea
                      placeholder={t("Add a reply.....")}
                      className="user-post-description-textarea"
                      value={this.state.commentsText}
                      name="commentsText"
                      onChange={this.handleForm}
                    />
                    <button
                      className="sendMsgBtnHome"
                      onClick={(e) =>
                        this.commentHit(this.state.postId, this.state.postType)
                      }
                    >
                      <FiSend />
                    </button>
                  </div>
                </div>
                <div class="home-post-overflow">
                  {this.state.userCommentListData &&
                    this.state.userCommentListData.map((comments) => (
                      <>
                        <div class="media mt-3">
                          <div className="post-avatar post-avatar-story postCardImg-me-2">
                            {" "}
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.showFriendsProfile(
                                  this.state.postValue.userId
                                )
                              }
                              className="post-avatar-img rounded-circle"
                              alt="user"
                              src={
                                API_URL +
                                "api/getUserPhoto/" +
                                comments.userId +
                                "/" +
                                comments.userName
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = dummyimage;
                              }}
                            />{" "}
                          </div>
                          <div class="media-body">
                            <h5 class="mt-0   text-capitalize text-dark">
                              {comments.userName}{" "}
                              <span class="float-right" role="button">
                                <MoreHorizIcon
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={(e) => {
                                    this.handleMenuClick(e);
                                  }}
                                />
                                {this.state.menuState && (
                                  <Menu
                                    id="simple-menu"
                                    anchorEl={this.state.menuState}
                                    keepMounted
                                    open={this.state.menuState}
                                    onClose={this.handleMenuClose}
                                  >
                                    {comments.userId ===
                                      this.props.loginDetails.uId && (
                                      <>
                                        <div
                                          className="menuCard"
                                          style={{ cursor: "pointer" }}
                                          //   onClick={() =>
                                          //     this.editClick(
                                          //       this.state.postValue.postId,
                                          //       this.state.postValue.type
                                          //     )
                                          //   }
                                        >
                                          <Edit className="menuItem" />{" "}
                                          <MenuItem>
                                            <p style={{ color: "black" }}>
                                              {t("Edit")}
                                            </p>
                                          </MenuItem>
                                        </div>
                                        <div
                                          className="menuCard"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.deleteComment(
                                              comments.id,
                                              "postList"
                                              //   this.state.postType
                                            )
                                          }
                                        >
                                          <Delete className="menuItem" />{" "}
                                          <MenuItem>
                                            <p style={{ color: "black" }}>
                                              {t("Delete")}
                                            </p>
                                          </MenuItem>
                                        </div>
                                      </>
                                    )}
                                    {comments.userId !==
                                      this.props.loginDetails.uId && (
                                      <div
                                        className="menuCard"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.reportCommentClick(comments.id)
                                        }
                                      >
                                        <Report className="menuItem" />{" "}
                                        <MenuItem>
                                          <p style={{ color: "black" }}>
                                            {t("Report")}
                                          </p>
                                        </MenuItem>
                                      </div>
                                    )}
                                  </Menu>
                                )}
                              </span>
                              <div className="post-date-small">
                                {" "}
                                {moment(comments.createdDate)
                                  .locale("en")
                                  .format("ll")}
                              </div>
                            </h5>

                            <p>{comments.commends}</p>
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                </div> */}
                      </div>
                    )}
                    {/* {(this.state.postData?.type === "video" ||
                      this.state.postData?.type === "image") && (
                      // <div className="home-post-card-wrap1 img-black-theme mr-2">
                      <div className=" img-black-theme mr-2">
 
                        {this.state.postData?.type === "image" && (
                          <div style={{ width: "96%" }}>
                            <FbGridImages
                              images={this.state.fileImageData}
                              onClickImage={() => {}}
                            />
                            
                          </div>
                        )}
                        {this.state.postData?.type === "video" && (
                          <div>
                            {this.state.fileVideoData &&
                              this.state.fileVideoData.map((photo) => (
                                <VideoPostCard
                                  fileData={photo.file}
                                  imageData={photo.image}
                                  videoStatus={photo.steamStatus}
                                />
                              ))}
                          </div>
                        )}
                      </div>
                    )} */}
                  </>
                )}
                {/* <hr /> */}
              </div>
            </div>
          </BlockUi>
        </Paper>
        {this.state.reportCommentState && this.popUpCommentReport()}
      </>
    );
  }

  getSinglePostData = () => {
    this.setState({
      loaderStats: true,
    });
    let data = {
      postId: this.props.match.params.id,
      typeData: this.props.match.params.type,
    };

    PostService_old.getPostByPostIdAndTypeData(data)
      .then((response) => {
        var result = response.data.data;

        if (
          this.props.match.params.type === "groupMessage" &&
          result.join === false
        ) {
          this.setState({
            notJoin: true,
          });
        } else if (
          this.props.match.params.type !== "groupMessage" &&
          !result.postActive &&
          !result.isFriendPost
        ) {
          this.setState({
            isNotFriend: true,
          });
        }

        this.setState({
          postData: result ? result : [],
          loaderStats: false,
          postNotPresent: result.isDeleted,
          videoStatus: result?.videoStatus,
        });
        this.getMediaFiles(
          response.data?.data?.postMediaFiles,
          response.data?.data?.type,
          response.data?.data?.userId
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // getMediaFiles = async (files, type) => {
  //   if (type == "image") {
  //     let fileList = [];
  //     if (files.length > 0) {
  //       for (let i = 0; i < files.length; i++) {
  //         fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
  //       }
  //       await this.setState({ fileImageData: fileList });
  //     }
  //     console.log("fileImageData", fileList);
  //   }
  // };
  subscribeUpdate = (result, type, userBookMark, id) => {
    if (type === "subscribe") {
      this.setState((prevState) => ({
        postData: {
          ...prevState.postData,
          postSubscribe: {
            subscribeId: prevState.postData.postSubscribe.subscribeId,
            isSubscribe: !prevState.postData.postSubscribe.isSubscribe,
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        postData: {
          ...prevState.postData,
          userBookMark: !prevState.postData.userBookMark,
        },
      }));
    }
  };
  commendsUpdate = (postId, count) => {
    this.setState((prevState) => ({
      postData: {
        ...prevState.postData,
        commendsCount: count,
      },
    }));
  };
  likeStatusUpdate = (result, userLike, index, newLike) => {
    let data = result.data;

    this.setState((prevState) => ({
      postData: {
        ...prevState.postData,
        userlike: userLike,
        allLikes: data.allLikes,
        likesCount: data.likeCount,
        userlikeType: userLike ? data.userLike.likeReactionType : "",
      },
    }));
    console.log("newLike", newLike);
    if (!newLike) {
      this.props.getLikeCountPopUp(0);
    }
  };
  getMediaFiles = async (files, type, userId) => {
    if (type == "image") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
        }
        await this.setState({ fileImageData: fileList });
      }
    } else if (type == "video") {
      let fileLisVideo = [];

      if (files.length > 0) {
        // for (let i = 0; i < files.length; i++) {
        //   if (files[i].thumbnail !== null) {
        //     fileLisVideo.push({
        //       file: files[i].streamUrl,
        //       image: files[i].thumbnail,
        //     });
        //   }
        // }
        await this.setState({
          fileVideoData: getVideoStatus(
            files,
            this.props.loginDetails.uId,
            userId,
            this.props.loginDetails.uType
          ),
        });

        // await this.setState({ fileVideoData: fileLisVideo });
      }
    }
  };
  commentHit = (pid, type) => {
    if (
      this.state.commentsText !== undefined &&
      this.state.commentsText.trim() !== ""
    ) {
      let comment = {
        postId: pid,
        postLikeAndCommendType: "commend",
        comment: this.state.commentsText.trim(),
        type: type,
      };

      PostService_old.createpostCommands(comment)
        .then((response) => {
          var result = response.data.data.commend;
          let newComment = [];
          let reverseList = [];
          let oldList = this.state.commentList;
          if (result) {
            newComment = oldList.concat(result);
            reverseList = newComment.reverse();
          }
          console.log("newComment", reverseList);
          console.log("newComment", reverseList.length);

          this.setState({
            commentsText: "",
            commentList: reverseList,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  popUpCommentReport() {
    const { t } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Modal show="true" onHide={this.handleReportCommentPopupClose}>
          <Modal.Header>{t("ADD DESCRIPTION FOR COMMENT REPORT")}</Modal.Header>
          <div
            className="tab-pane-view"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="profile"
          >
            <div className="profileTabContent">
              <Form
                id="profileForm"
                noValidate
                validated={this.state.validated}
                onSubmit={this.reportComment}
              >
                <Card className="profileCard">
                  <Card.Body>
                    <div className="row ">
                      <div className="col-xs-12 col-md-12">
                        <Form.Group className="formInput">
                          <Form.Label></Form.Label>
                          <textarea
                            name="reportCommentDescription"
                            style={{ width: "100%" }}
                            rows="4"
                            cols="50"
                            value={this.state.reportCommentDescription}
                            onChange={this.handleForm}
                          ></textarea>
                          <Form.Control.Feedback type="invalid">
                            {t("Please Enter Description")}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <br />
                      </div>
                    </div>
                  </Card.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={this.handleReportCommentPopupClose}
                    >
                      {t("Close")}
                    </Button>
                    <Button type="submit" variant="primary">
                      {t("Submit")}
                    </Button>
                  </Modal.Footer>
                </Card>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  handleReportCommentPopupClose = () => {
    this.setState({
      reportCommentState: false,
    });
  };
  reportCommentClick = (id) => {
    this.setState({
      reportCommentState: true,
      reportCommentId: id,
    });
  };
  handleForm = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleMenuClick = (event) => {
    this.setState({ menuState: event.target });
  };
  handleMenuClose = () => {
    this.setState({ menuState: false });
  };
  deleteComment = (id, type) => {
    let deleteCommends = {
      id: id,
      type: type,
    };
    PostService_old.deleteCommends(deleteCommends)
      .then((response) => {
        var postList = this.state.userCommentListData;
        postList = this.state.userCommentListData.filter(
          (item) => item.id != id
        );
        this.setState({
          menuState: false,
          userCommentListData: postList,
        });
        console.log("dd", postList);
        toast.success("Comment Deleted Successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onClickMessage = (id, name) => {};
}

const mapDispacthToProps = (dispatch) => {
  return {
    // getUserComments: (id, type) => dispatch(getUserComments(id, type)),
    getSinglePostData: (id, type) => dispatch(getSinglePostData(id, type)),
    getLikeCountPopUp: (data) => dispatch(getLikeCountPopUp(data)),
  };
};

const mapStateToProps = function (state) {
  return {
    userCommentList: state.surveyList.userCommentList,
    singlePost: state.surveyList.singlePost,
    loaderStats: state.surveyList.singlePostLoader,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(HomePostCard)));
