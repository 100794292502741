import React, { Component } from "react";
//import dummyimage from '../img/avatar_dummy.png'
import dummyimage from '../../img/default_avatar.jpg';

const styleUserImgDiv = {
  boxShadow: "0px 0px 5px #00000029",
  borderRadius: "100%",
  position: "relative",
};

const styledImg = {
  width: "100%",
  height: "100%",
  borderRadius: "100%",
};

const styledUserStatus = {
  position: "absolute",
  width: 5,
  height: 5,
  borderRadius: "50%",
  top: -5,
  right: -5,
};
const styleConfig = {
  tiny: {
    width: 24,
    minWidth: 24,
    maxWidth: 24,
    height: 24,
    border: "1px solid #FFFFFF",
  },
  small: {
    width: 48,
    minWidth: 48,
    maxWidth: 48,
    height: 48,
    border: "2px solid #FFFFFF",
  },
  smedium: {
    width: 55,
    minWidth: 55,
    maxWidth: 55,
    height: 55,
    border: "3px solid #FFFFFF",
  },
  medium: {
    width: 68,
    minWidth: 68,
    maxWidth: 68,
    height: 68,
    border: "3px solid #FFFFFF",
  },
  large: {
    width: 90,
    minWidth: 90,
    maxWidth: 90,
    height: 90,
    border: "3px solid #FFFFFF",
  },
  extraLarge: {
    width: 200,
    minWidth: 200,
    maxWidth: 200,
    height: 200,
    border: "3px solid #FFFFFF",
  },
};

const activeStatus = {
  online: "#31FDB7",
  offline: "#c63928",
  away: "#efc742",
};

export default class JobRequestUserImg extends Component {
  render() {
    const {
      size,
      imgSrc,
      imgAlt,
      isShowStatus = false,
      statusLabel = "offline",
    } = this.props;
    return (
      <div style={{ ...styleUserImgDiv, ...styleConfig[size] }}>
        <img src={imgSrc} alt={imgAlt} style={styledImg} />
        
      </div>
    );
  }
}
