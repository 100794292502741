import React, { useEffect, useState } from "react";
import ImageContainer from "./ImageContainer";
import { MdOutlineFileDownload } from "react-icons/md";
import { toast } from "react-toastify";
import { API_URL } from "../../config/app-config";
import PostService from "../../api/PostService";
import { MenuItem, TextField } from "@material-ui/core";
const EmailFormContainer = (props) => {
  const [state, setState] = useState({
    subject: "",
    oneDayReminderSub: "",
    oneHouReminderSub: "",
    templateList: [],
    fileName: "",
  });
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [htmlFileName, setHtmlFileName] = useState("");
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    let data = props.updateTemplate;
    if (data) {
      getHtmlTemplate(data.file);
      setState({
        ...state,
        subject: data.subject,
        oneDayReminderSub: data.oneDayReminderSub,
        oneHouReminderSub: data.oneHourReminderSub,
      });
      templateList(true);
    } else {
      templateList(false);
      // getHtmlTemplate(null);
    }
  }, []);

  const templateList = (update) => {
    PostService.getTemplateList()
      .then((response) => {
        console.log("response", response);
        setState((previous) => ({
          ...previous,
          templateList: response.data.data,
        }));
        if (!update && response.data.data?.length > 0) {
          getHtmlTemplate(response.data.data[0].file);
        }
      })
      .catch((er) => {
        console.log("e", er);
      });
  };

  const getClassElementsFromHTML = (htmlContent, keys) => {
    const getContent = keys.map((item) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, "text/html");

      const elements = doc.querySelectorAll(`.${item.id}`);

      const content = Array.from(elements).map((element) => {
        if (element.tagName === "IMG") {
          return element.getAttribute("src");
        } else if (element.tagName === "A") {
          return element.getAttribute("href");
        } else {
          return element.innerHTML;
        }
      });

      const combinedContent = content.join(" ").replace(/\n/g, "");

      const cleanedContent = combinedContent.replace(/\s+/g, " ").trim();

      item.value = cleanedContent;
      console.log("item", item);
      return item;
    });

    console.log("Content", getContent);
    setKeys(getContent ? getContent : []);
  };
  const updateImage = (index, item, file) => {
    if (file) {
      const formData = new FormData();
      formData.append("mediaTypeFile", file[0]);
      const config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      PostService.saveImageFile(formData, config)
        .then((response) => {
          const fileName = `${API_URL}auth/image/${response.data.data.fileName}`;
          console.log("fileName", fileName);
          updateState(index, item, fileName);
        })
        .catch((e) => {
          console.log("e", e);
        });
    } else {
    }
  };

  const extractKeysArray = (scriptContent) => {
    // Find the start and end indices of the keys array
    const startIndex = scriptContent.indexOf("[");
    const endIndex = scriptContent.lastIndexOf("]");

    if (startIndex !== -1 && endIndex !== -1) {
      try {
        // Extract the substring containing the keys array
        const keysString = scriptContent.substring(startIndex, endIndex + 1);

        // Parse the keys array
        const keysArray = JSON.parse(keysString);
        return keysArray;
      } catch (error) {
        console.error("Error parsing keys array:", error);
        return [];
      }
    } else {
      console.error("Keys array not found in script content");
      return [];
    }
  };

  const updateState = (index, fields, value) => {
    setKeys((prevKeys) => {
      const newData = [...prevKeys];
      newData[index] = { ...newData[index], value };
      return newData;
    });
    updateTemplateValue(`.${fields.id}`, value, fields);
  };

  const updateTemplateValue = (selector, value, fields) => {
    console.log("selector: -->", selector);
    console.log("value: -->", value);
    if (fields.type === "link") {
      const updatedTemplate = selectedTemplate.replace(
        new RegExp(
          `(<a[^<>]*class="${selector.replace(".", "")}"[^<>]*href=")[^"]*`
        ),
        `$1${value}`
      );
      setSelectedTemplate(updatedTemplate);
    } else if (fields.type === "image") {
      console.log("Inside-Image");
      const updatedTemplate = selectedTemplate.replace(
        new RegExp(
          `(<img[^<>]*class="${selector.replace(".", "")}"[^<>]*src=")[^"]*`
        ),
        `$1${value}`
      );

      setSelectedTemplate(updatedTemplate);
    } else {
      const updatedTemplate = selectedTemplate.replace(
        new RegExp(
          `(?<=<[^<>]*class="${selector.replace(
            ".",
            ""
          )}"[^<>]*>).*(?=<\/[^<>]*>)`
        ),
        value
      );
      setSelectedTemplate(updatedTemplate);
    }
  };
  const handleDownload = () => {
    const blob = new Blob([selectedTemplate], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = htmlFileName || "template.html"; // Set the file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  console.log("keys", keys);
  const saveForm = () => {
    if (!state.subject || !selectedTemplate) {
      toast.warning(
        !state.subject ? "Enter your mail subject" : "Template not present"
      );
    } else {
      const blob = new Blob([selectedTemplate], { type: "text/html" });
      let obj = {
        blob: blob,
        oneHouReminderSub: state.oneHouReminderSub,
        oneDayReminderSub: state.oneDayReminderSub,
        subject: state.subject,
      };
      props.updateDetails(obj);
    }
  };

  const getHtmlTemplate = (id) => {
    // 42d8428e-b4f3-4024-9771-ef07d16a24e
    async function fetchData() {
      try {
        const response = await fetch(`${API_URL}auth/v1/html/${id}`);
        // const response = await fetch(`${API_URL}auth/html/${id}`);
        const html = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const scriptContent = doc.querySelector("script").textContent;
        const keysArray = extractKeysArray(scriptContent);
        let arrayOfObjects =
          keysArray &&
          keysArray.map((item) => {
            const keyValuePair = item.id.split("-").join(" ");
            return {
              id: item.id,
              type: item.type,
              label: keyValuePair,
              value: "",
            };
          });
        // setKeys(arrayOfObjects ? arrayOfObjects : []);
        setSelectedTemplate(html);
        getClassElementsFromHTML(html, arrayOfObjects);
        setState((previous) => ({
          ...previous,
          fileName: id,
        }));
      } catch (error) {
        console.error("Error fetching HTML file:", error);
      }
    }
    fetchData();
  };
  console.log("state", state);
  return (
    <div className="userList-container form-bg" style={{ overflow: "scroll" }}>
      <div className="d-flex justify-content-end">
        <TextField
          style={{ minWidth: "15rem" }}
          className="text-filed-border mt-3 mr-1"
          id="dropdown"
          select
          label=""
          variant="outlined"
          type="text"
          required=""
          name="serviceCategory"
          value={state.fileName}
          onChange={(e) => {
            getHtmlTemplate(e.target.value);
          }}
        >
          {state.templateList &&
            state.templateList.map((item) => (
              <MenuItem value={item.file} id={item.file}>
                {item.title}
              </MenuItem>
            ))}
        </TextField>
      </div>

      <div className="d-flex flex wrap p-3" style={{ gap: "2rem" }}>
        <section
          className="form-wrapper"
          style={{ padding: "2rem", maxWidth: "23rem" }}
        >
          {!selectedTemplate && <h3>No template presents ...</h3>}
          {keys &&
            keys.map((item, index) => (
              <div key={index} className="p-0" style={{ border: "none" }}>
                <label>{item.label}</label>

                {item.type === "image" ? (
                  <ImageContainer
                    formImageStatus={(file) => updateImage(index, item, file)}
                  />
                ) : (
                  <textarea
                    fullWidth
                    className="market-text-area"
                    variant="outlined"
                    type="text"
                    key={item.id}
                    value={item.value}
                    onChange={(e) => updateState(index, item, e.target.value)}
                  />
                )}
              </div>
            ))}
        </section>
        {state.templateList.length !== 0 && (
          <section
            className="form-wrapper"
            style={{ padding: "2rem", overflow: "scroll" }}
          >
            <button
              className="bg-primary mb-3 mt-1"
              onClick={handleDownload}
              style={{ border: "none" }}
            >
              <MdOutlineFileDownload /> Download Mail Template
            </button>

            <div dangerouslySetInnerHTML={{ __html: selectedTemplate }} />

            <label>
              Add Your Email Subject * (This subject use ,after your event
              creation)
            </label>
            <textarea
              fullWidth
              className="market-text-area"
              variant="outlined"
              type="text"
              value={state.subject}
              onChange={(e) => {
                setState({
                  ...state,
                  subject: e.target.value,
                });
              }}
            />

            <label>
              Add Your Email Reminder Subject (This subject use 1 day ago in
              your event start time).This is optional,if you not fill reminder
              mail not will send
            </label>
            <textarea
              fullWidth
              className="market-text-area"
              variant="outlined"
              type="text"
              value={state.oneDayReminderSub}
              onChange={(e) => {
                setState({
                  ...state,
                  oneDayReminderSub: e.target.value,
                });
              }}
            />
            <label>
              Add Your Email Reminder Subject (This subject use 1 hour ago in
              your event start time). This is optional,if you not fill reminder
              mail not will send
            </label>
            <textarea
              fullWidth
              className="market-text-area"
              variant="outlined"
              type="text"
              value={state.oneHouReminderSub}
              onChange={(e) => {
                setState({
                  ...state,
                  oneHouReminderSub: e.target.value,
                });
              }}
            />

            <button
              className="bg-primary mt-3"
              style={{ border: "none" }}
              onClick={saveForm}
            >
              Save Template
            </button>
          </section>
        )}
      </div>
    </div>
  );
};

export default EmailFormContainer;
