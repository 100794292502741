import React, { Component } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextEditorQuestion from "../../components/TextEditorQuestion";
import ProfileService from "../../api/ProfileService";
import ForumService from "../../api/ForumService";
import AuthService from "../../session/AuthService";
import { ToastContainer, toast } from "react-toastify";
import "../css/mysocial.css";
import VideocamIcon from "@material-ui/icons/Videocam";
import "../css/mysocialprofile.css";
import "../css/yuukkeTutoring.css";
import BlockUi from "react-block-ui";
import Form from "react-bootstrap/Form";
import i18n from "../../i18n";
import MenuItem from "@material-ui/core/MenuItem";

import VideoPostCard from "../Home/HomeUpdated/VideoPostCard";
import { withTranslation } from "react-i18next";
import "react-widgets/styles.css";
import Grid from "@material-ui/core/Grid";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Swal from "sweetalert2";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import * as APPCONFIG from "../../config/app-config.js";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { Chip } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import {
  getDashboardList,
  getMyQuestionsList,
} from "../../redux/actions/dashboardActions";
import {
  getAllSubjectList,
  getAllMyGroupEvents,
} from "../../redux/actions/subjectActions";
import { getMyLibraryQuestionList } from "../../redux/actions/MyLibraryQuestionActions";
import { updateEligibility } from "../../redux/actions/loginDetailsActions";
import { getQuestionsList } from "../../redux/actions/questionActions";
import moment from "moment";
import Utils from "../../utils/Utils";
import MessagesFileOption from "../Community/Subjects/GroupEventMessageFIleOption";
import TagMenu from "../../components/TagMenu";
import { withRouter } from "react-router";
import DatePicker from "react-multi-date-picker";
import LearningFileOption from "./LearningFileOption";
import LearningFileOptionVideo from "./LearningFileOptionVideo";
import dummyimage from "../../img/banner.jpg";
import LearningFileOptionImage from "./LearningFileOptionImage";
import Popover from "react-bootstrap/Popover";
import Share from "@material-ui/icons/Share";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { OverlayTrigger } from "react-bootstrap";
import { getAllProofType } from "../../redux/actions/commonApiActions";
const currentDate = new Date().toString();
class ChallengeApplyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: false,
      fileUrl: null,
      type: null,
      mediaPost: "",
      isChecked: false,
      teaching: [],
      tags: [],
      question: "",
      classDetails: [],
      addTagsState: false,
      fileOption: false,
      fileOptionImage: false,
      fileOptionVideo: false,
      gradeDetails: [],
      questionDescribed: "",
      content: "",
      editState: false,
      fileImage: false,
      gradeId: "",
      questionTitleCount: 0,
      descriptionCount: 0,
      curriculumList: [],
      curriculumId: "",
      description: "",
      memberShipPrice: "",
      memberShipType: "",
      expiryDate: "",
      expiry: "",
      checkExpiry: true,
      fileTypeKey: "",
      fileTypeState: false,
      tagName: [],
      status: "",
      startDate: "",
      startTime: "",
      fileVideoData: [],
      endTime: "",
      eventLocation: "",
      editeventorgroupState: false,
      grouporeventFiles: [],
      selectOption: true,
      grouporeventFilesList: [],
      editImage: [],
      participation: "Solo",
      teamLeader: "",
      number: "",
      proofType: "",
      age: "",
      email: "",
      address: "",
      caption: "",
      category: [],
      challenges: "",
      imageOptionOpen: false,
      videoOptionOpen: false,
      selectPage: true,
      selectPageVideo: true,
      selectPageImage: true,
      fileVideo: [],
      fileImages: [],
      imageFile: "",
      editChallengeApplied: false,
      updateButton: false,
      updatechallengeId: "",
      careerLabType: 0,
      subjectName: "",
    };
    this.block = this.block.bind(this);
    this.keyFile = this.keyFile.bind(this);
    this.closeChoosedFileData = this.closeChoosedFileData.bind(this);
    this.closeChoosedFileImage = this.closeChoosedFileImage.bind(this);
    this.closeChoosedFileVideo = this.closeChoosedFileVideo.bind(this);
    this.expirycheck = this.expirycheck.bind(this);
    this.myRef = React.createRef();
    this.getallSubject = this.getallSubject.bind(this);
    this.editorStateValues = this.editorStateValues.bind(this);
    this.handleTeachingMultiSelect = this.handleTeachingMultiSelect.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.getQuestionMediaFiles = this.getQuestionMediaFiles.bind(this);
    this.getQuestionMediaImage = this.getQuestionMediaImage.bind(this);
    this.handleGrade = this.handleGrade.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.fileOptionEnable = this.fileOptionEnable.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    //  this.onFormSubmit = this.onFormSubmit.bind(this);
    this.getAllCurriculum = this.getAllCurriculum.bind(this);
    this.handleCurriculum = this.handleCurriculum.bind(this);
    this.getGradeByCurriculumId = this.getGradeByCurriculumId.bind(this);
    this.getGradeByCurriculumIdUpdate =
      this.getGradeByCurriculumIdUpdate.bind(this);
    this.ViewAllComments = this.ViewAllComments.bind(this);
    this.UpdateQuestionInAllpages = this.UpdateQuestionInAllpages.bind(this);
    this.backtogroups = this.backtogroups.bind(this);
    this.backtoevents = this.backtoevents.bind(this);
    this.getTagId = this.getTagId.bind(this);
    this.getAllTags = this.getAllTags.bind(this);
    this.getTagNameAlone = this.getTagNameAlone.bind(this);
    this.getSingleChallenge = this.getSingleChallenge.bind(this);
    this.getOldTagList = this.getOldTagList.bind(this);
    this.backtoHome = this.backtoHome.bind(this);
    this.gotogroupDescription = this.gotogroupDescription.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.removeImageFile = this.removeImageFile.bind(this);
    this.participation = this.participation.bind(this);
    this.teamLeader = this.teamLeader.bind(this);
    this.imageOptionOpen = this.imageOptionOpen.bind(this);
    this.videoOptionOpen = this.videoOptionOpen.bind(this);
    this.getQuestionMediaVideoFiles =
      this.getQuestionMediaVideoFiles.bind(this);
    this.getQuestionMediaImageFiles =
      this.getQuestionMediaImageFiles.bind(this);
    this.fileOptionEnableImage = this.fileOptionEnableImage.bind(this);
    this.fileOptionEnableVideo = this.fileOptionEnableVideo.bind(this);
    this.onFormSubmitChallenge = this.onFormSubmitChallenge.bind(this);
    this.movetolearningappList = this.movetolearningappList.bind(this);
    this.socialPopover = this.socialPopover.bind(this);
  }

  async componentDidMount() {
    //proofList
    this.props.getAllProofType();

    // alert(this.props.match.params.careerType);
    this.getAllCategoryId(this.props.match.params.subjectId);

    this.setState({
      careerLabType: +this.props.match.params.careerType,
    });

    if (this.props.match.params.date == "Valid") {
      this.setState({
        updateButton: true,
      });
    } else {
      this.setState({
        updateButton: false,
      });
    }
    //get challengeId
    if (this.props.match.params.challengeId == "new") {
      this.setState({
        updatechallengeId: "",
      });
    } else {
      this.setState({
        updatechallengeId: this.props.match.params.challengeId,
      });
    }

    //userApply
    if (this.props.match.params.apply == "Update") {
      this.getSingleChallenge();
    }

    var Edit = this.props.match.params.id;
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    if (Edit === undefined) {
      this.setState({ editeventorgroupState: false });
    } else {
      this.setState({ editeventorgroupState: true });
      this.setState({
        challengeId: this.props.match.params.id,
        challengeName: this.props.match.params.name,
      });
    }
  }

  ViewAllComments(boolean) {
    this.setState({ checkExpiry: boolean });
  }

  handleChangeInput(value) {
    this.setState({
      expiryDate: value,
    });
  }
  render() {
    const { t } = this.props;
    const { handleViewAllQuestions, events, subjectId, subjectName } =
      this.props;
    const { careerLabType } = this.state;
    console.log("challenges", this.state.challenges);
    console.log("challenges", this.state.category);

    return (
      <div
        className="screenInnerGap"
        style={{ marginBottom: "22px", marginLeft: "30px" }}
      >
        <ToastContainer position="top-right" autoClose={5000} />

        <Grid container spacing={1} style={{ paddingTop: "100px" }}>
          <Grid item md={4} sm={12} xs={12} lg={4}>
            {!this.state.editChallengeApplied && (
              <h5>
                {careerLabType === 1 ? (
                  <>{t("Apply Course")}</>
                ) : careerLabType === 2 ? (
                  <>{t("Apply Internship")}</>
                ) : (
                  <>{t("Apply Challenge")}</>
                )}
              </h5>
            )}
            {this.state.editChallengeApplied && (
              <h5>
                {careerLabType === 1 ? (
                  <>{t("Update Course")}</>
                ) : careerLabType === 2 ? (
                  <>{t("Update Internship")}</>
                ) : (
                  <>{t("Update Challenge")}</>
                )}
              </h5>
            )}
          </Grid>
          <Grid item md={4} sm={12} xs={12} lg={4}></Grid>
          <Grid item md={4} sm={12} xs={12} lg={4}>
            {this.state.editChallengeApplied && (
              <p
                onClick={() =>
                  this.movetolearningapp(
                    this.state.subjectId,
                    this.state.subjectName
                  )
                }
                style={{ float: "right", cursor: "pointer", color: "#A00030" }}
              >
                <ArrowBackIcon />
                {careerLabType === 1
                  ? t("Back to Course")
                  : careerLabType === 2
                  ? t("Back to  Internship")
                  : t("Back to Challenge")}
                {/* {t("Back to Career Labs")} */}
              </p>
            )}
            {!this.state.editChallengeApplied && (
              <p
                onClick={() =>
                  this.movetolearningapp(
                    this.props.match.params.subjectId,
                    "title-title"
                  )
                }
                // onClick={this.movetolearningappList}
                style={{ float: "right", cursor: "pointer", color: "#A00030" }}
              >
                <ArrowBackIcon />
                {careerLabType === 1
                  ? t("Back to Course")
                  : careerLabType === 2
                  ? t("Back to  Internship")
                  : t("Back to Challenge")}

                {/* {t("Back to Career Labs")} */}
              </p>
            )}
          </Grid>
        </Grid>

        <div className="askAQuestionCardWrapper">
          <BlockUi
            tag="div"
            blocking={this.state.blocking}
            message={t("Loading, please wait")}
            color="#60c2e7"
          >
            <div className="askAQuestionInputGroup inputLarge">
              {/* {careerLabType === 0 && this.state.editChallengeApplied && ( */}
              <div
                className="postMoreInfo"
                style={{ cursor: "pointer", float: "right" }}
              >
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={this.socialPopover()}
                  rootClose
                >
                  <button
                    style={{ display: "inline-flex", marginRight: "8px" }}
                    //  onClick={this.shareOptionPopup}
                  >
                    <Share
                      style={{
                        //  marginLeft : "8px",
                        width: "20px",
                        height: "20px",
                        color: "white",
                      }}
                    />
                    <p
                      style={{
                        paddingLeft: "8px",
                        paddingRight: "8px",
                      }}
                    >
                      {t("Share")}
                    </p>
                  </button>
                </OverlayTrigger>
              </div>
              {/* )} */}
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <label className="formLabel">
                  {careerLabType === 1
                    ? t("Name")
                    : careerLabType === 2
                    ? t("Name")
                    : t("Title")}{" "}
                  *
                </label>
                {/* <div>
                  {" "}
                  <Tooltip title="Enter Group name">
                    <IconButton className="infoButton">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div> */}

                <input
                  type="text"
                  name="question"
                  value={this.state.question}
                  maxLength="250"
                  onChange={this.handleForm}
                />
              </Grid>
            </div>

            <div className="askAQuestionDescription">
              <label className="formLabel">
                {careerLabType === 1
                  ? t("Short Bio")
                  : careerLabType === 2
                  ? t("Short Bio")
                  : t("Description")}{" "}
                *{" "}
              </label>
              <TextEditorQuestion
                ref={this.myRef}
                editorStateValue={this.editorStateValues}
                editorValue={
                  this.state.questionDescribed
                    ? this.state.questionDescribed
                    : ""
                }
              />
              <p
                className="boxText"
                style={
                  this.state.descriptionCount > 2500
                    ? { color: "red" }
                    : { color: "green" }
                }
              >
                {" "}
                {2500 - this.state.descriptionCount} {t("characters left")}
              </p>
            </div>

            <div>
              {this.state.category && this.state.category?.length !== 0 && (
                <>
                  {" "}
                  <label className="formLabel  mt-2">
                    {careerLabType === 1
                      ? t("Select the Course Category")
                      : careerLabType === 2
                      ? t("Select the Internship Category")
                      : t("Select the category that you want to enter")}{" "}
                    *{" "}
                    {/* {t("Select the category that you want to enter *")} */}
                  </label>
                  <TextField
                    style={{
                      borderRadius: "10px",
                      paddingLeft: "2px",
                      marginBottom: "8px",
                    }}
                    fullWidth
                    id="dropdown"
                    select
                    label=""
                    variant="outlined"
                    type="text"
                    required=""
                    name="challenges"
                    value={this.state.challenges}
                    onChange={this.handleForm}
                  >
                    {this.state.category &&
                      this.state.category.map((option, index) => (
                        <MenuItem
                          key={option.challengesId}
                          value={option.challengesId}
                        >
                          {option.challengesName}
                        </MenuItem>
                      ))}{" "}
                  </TextField>
                </>
              )}
            </div>
            <div className="mt-1">
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <label className="formLabel">{t("Address")} *</label>
                <textarea
                  className="formInputsDescriptions"
                  name="address"
                  rows="4"
                  cols="20"
                  value={this.state.address}
                  maxLength="250"
                  onChange={this.handleForm}
                />
              </Grid>
            </div>
            <div className="askAQuestionInputGroup ">
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <label className="formLabel">{t("Contact Number")} *</label>
                <input
                  type="number"
                  min="0"
                  step="1"
                  onInput={(e) =>
                    (e.target.value = e.target.value.slice(0, 10))
                  }
                  onChange={(e) => {
                    let val = parseInt(e.target.value, 10);
                    if (isNaN(val)) {
                      this.setState({ number: "" });
                    } else {
                      // is A Number
                      val = val >= 0 ? val : 0;
                      this.setState({ number: val });
                    }
                  }}
                  className="w-100"
                  // Assign State
                  value={this.state.number}
                />
              </Grid>
            </div>
            <div className="askAQuestionInputGroup ">
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <label className="formLabel">{t("Email")} *</label>
                <input
                  type="text"
                  required
                  name="email"
                  value={this.state.email}
                  maxLength="250"
                  onChange={this.handleForm}
                />
              </Grid>
            </div>
            {careerLabType === 0 && (
              <>
                <div className="askAQuestionInputGroup ">
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <label className="formLabel">{t("Age")} *</label>
                    <input
                      type="number"
                      min="0"
                      step="1"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 3))
                      }
                      onChange={(e) => {
                        let val = parseInt(e.target.value, 10);
                        if (isNaN(val)) {
                          this.setState({ age: "" });
                        } else {
                          // is A Number
                          val = val >= 0 ? val : 0;
                          this.setState({ age: val });
                        }
                      }}
                      className="w-100"
                      // Assign State
                      value={this.state.age}
                    />
                  </Grid>
                </div>

                <div className="askAQuestionInputGroup ">
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <label className="formLabel">
                      {t("Caption / Tag line")} *
                    </label>
                    <input
                      type="text"
                      required
                      name="caption"
                      value={this.state.caption}
                      maxLength="250"
                      onChange={this.handleForm}
                    />
                  </Grid>
                </div>
                <div className="askAQuestionInputGroup inputLarge">
                  <Form.Group controlId="region">
                    <Form.Label>{t("Participation")} *</Form.Label>
                    <Form.Control
                      as="select"
                      name="memberShipType"
                      value={this.state.participation}
                      onChange={this.participation}
                    >
                      {/* <option value="">{t("Choose")}</option> */}
                      <option value="Solo"> Solo</option>
                      {/* <option value="group"> Group</option> */}
                    </Form.Control>
                  </Form.Group>
                </div>
              </>
            )}
            {/* <div className="askAQuestionInputGroup inputLarge">
              <Form.Group controlId="region">
                <Form.Label>{t("Team Leader")}</Form.Label>
                <Form.Control
                  as="select"
                  name="memberShipType"
                  value={this.state.teamLeader}
                  onChange={this.teamLeader}
                >
                  <option value="">{t("Choose")}</option>
                  <option value="Yes"> Yes</option>
                  <option value="No"> No</option>
                </Form.Control>
              </Form.Group>
            </div> */}
            {!this.props.loginDetails.eligibility && (
              <>
                <div className="askAQuestionInputGroup inputLarge">
                  <Form.Group controlId="region">
                    <Form.Label>{t("Proof Type *")}</Form.Label>
                    <Form.Control
                      as="select"
                      name="proofType"
                      value={this.state.proofType}
                      onChange={this.handleForm}
                    >
                      <option value="">{t("Choose")}</option>
                      <option value="Aadhar card"> Aadhar card</option>
                      <option value="Driving License"> Driving License</option>
                      <option value="Pan Card"> Pan Card</option>
                      <option value="Id Card"> Id Card</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                {this.state.proofType !== "" && (
                  <div>
                    {this.state.proofType === "Aadhar card" && (
                      <div>
                        <p>Select Aadhar card</p>
                      </div>
                    )}
                    {this.state.proofType === "Driving License" && (
                      <div>
                        <p>Select Driving License</p>
                      </div>
                    )}
                    {this.state.proofType === "Pan Card" && (
                      <div>
                        <p>Select Pan Card</p>
                      </div>
                    )}
                    {this.state.proofType === "Id Card" && (
                      <div>
                        <p>Select Id Card</p>
                      </div>
                    )}
                    {/* {!this.state.fileImage && (
                                    <AiOutlineCloseCircle onClick={this.closeChoosedFileData} style={{ cursor: "pointer", float: "right", marginBottom: "8px", width: '20px', height: "20px", color: "#000000" }} />
                                )} */}
                    <LearningFileOption
                      // fileTypeKey={this.state.fileTypeKey}
                      fileOption={this.state.fileOption}
                      fileOptionEnable={this.fileOptionEnable}
                      fromQuestionImage={this.getQuestionMediaImage}
                      fromQuestionMedia={this.getQuestionMediaFiles}
                      editObject={this.props.editObject}
                      grouporeventFiles={this.state.grouporeventFiles}
                      selectPage={this.state.selectPage}
                    ></LearningFileOption>
                  </div>
                )}{" "}
              </>
            )}
            {this.state.editeventorgroupState &&
              this.state.fileImage &&
              this.state.grouporeventFiles.length > 0 && (
                <div key={this.state.grouporeventFiles[0].originalFileName}>
                  <Chip
                    icon={<AttachmentIcon />}
                    className="fileChip"
                    label={this.state.grouporeventFiles[0].originalFileName}
                    size="small"
                    onDelete={() => this.removeImageFile()}
                    variant="outlined"
                  />
                  {
                    <img
                      style={{ margin: "8px", width: "80px", height: "80px" }}
                      src={`${APPCONFIG.API_URL}auth/image/${this.state.grouporeventFiles[0].fileName}`}
                    ></img>
                  }
                </div>
              )}
            <Grid container spacing={1}>
              <Grid item md={12} sm={5} xs={5} lg={12}>
                {this.state.imageOptionOpen && (
                  <>
                    {this.state.selectPageImage && (
                      <AiOutlineCloseCircle
                        onClick={this.closeChoosedFileImage}
                        style={{
                          cursor: "pointer",
                          float: "right",
                          marginBottom: "8px",
                          width: "20px",
                          height: "20px",
                          color: "#000000",
                        }}
                      />
                    )}

                    <LearningFileOptionImage
                      fileOption={this.state.fileOptionImage}
                      fileOptionEnable={this.fileOptionEnableImage}
                      fromQuestionImage={this.getQuestionMediaImageFiles}
                      editObject={this.props.editObject}
                      grouporeventFiles={this.state.grouporeventFiles}
                      selectPage={this.state.selectPageImage}
                    ></LearningFileOptionImage>
                  </>
                )}
                {this.state.videoOptionOpen && (
                  <>
                    {this.state.selectPageVideo && (
                      <AiOutlineCloseCircle
                        onClick={this.closeChoosedFileVideo}
                        style={{
                          cursor: "pointer",
                          float: "right",
                          marginBottom: "8px",
                          width: "20px",
                          height: "20px",
                          color: "#000000",
                        }}
                      />
                    )}
                    <LearningFileOptionVideo
                      fileOption={this.state.fileOptionVideo}
                      fileOptionEnable={this.fileOptionEnableVideo}
                      fromQuestionMedia={this.getQuestionMediaVideoFiles}
                      editObject={this.props.editObject}
                      grouporeventFiles={this.state.grouporeventFiles}
                      selectPage={this.state.selectPageVideo}
                    ></LearningFileOptionVideo>
                  </>
                )}
              </Grid>
              {careerLabType === 0 && (
                <Grid item md={6} sm={3} xs={5} lg={3}>
                  <div
                    onClick={() => this.videoOptionOpen("video")}
                    style={{ cursor: "pointer", color: "#db2828" }}
                  >
                    <VideocamIcon />
                    Upload Video *{" "}
                  </div>

                  {!this.state.videoOptionOpen &&
                    this.state.fileVideoData &&
                    this.state.fileVideoData.map((photo) => (
                      <video
                        style={{ width: "200px", height: "150px" }}
                        controls
                      >
                        <source
                          src={`${APPCONFIG.API_URL}auth/video/${photo.fileName}`}
                        />
                      </video>
                      // <VideoPostCard
                      //   fileData={photo.file}
                      //   imageData={photo.image}
                      // />
                    ))}
                </Grid>
              )}

              <Grid item md={6} sm={3} xs={5} lg={3}></Grid>
            </Grid>

            {!this.state.editChallengeApplied && this.state.updateButton && (
              <div className="askAQuestionBtn" style={{ paddingTop: "10px" }}>
                <button onClick={(e) => this.onFormSubmitChallenge(e)}>
                  {/* {t("APPLY FOR CAREER")} */}
                  {careerLabType === 1
                    ? t("Apply For Course")
                    : careerLabType === 2
                    ? t("Apply For Internship")
                    : t("Apply For Challenge")}
                </button>
              </div>
            )}
            {this.state.editChallengeApplied && this.state.updateButton && (
              <div className="askAQuestionBtn" style={{ paddingTop: "10px" }}>
                <button onClick={(e) => this.onUpdateSubmit(e)}>
                  {/* {t("EDIT CAREER")} */}
                  {careerLabType === 1
                    ? t("Update Course")
                    : careerLabType === 2
                    ? t("Update Internship")
                    : t("Update Challenge")}
                </button>
              </div>
            )}
            {!this.state.updateButton && (
              <div
                className="askAQuestionBtn"
                style={{ paddingTop: "10px", color: "red" }}
              >
                {/* <button style={{color:"block"}} > */}
                {t("CLOSED")}
                {/* </button> */}
              </div>
            )}
          </BlockUi>
          {/* {!this.state.editeventorgroupState && (
                        <div className="askAQuestionBtn">
                            <button onClick={(e) => this.onFormSubmit(e)}>
                                {!events ? t("CREATE GROUP") : t("CREATE EVENT")}
                            </button>
                        </div>
                    )} */}
          {/* {this.state.editeventorgroupState && (
                        <div className="askAQuestionBtn">
                            <button onClick={(e) => this.onUpdateSubmit(e)}>
                                {!events ? t("UPDATE GROUP") : t("UPDATE EVENT")}
                            </button>
                        </div>
                    )} */}
        </div>
      </div>
    );
  }
  socialMediaContent = () => {
    if (this.state.careerLabType === 1) {
      return `Kindly check this Course`;
    } else if (this.state.careerLabType === 2) {
      return `Kindly check this Internship`;
    } else {
      return `Kindly vote to my challenge ${this.state.question}`;
    }
  };
  getUrlLink = () => {
    if (this.state.careerLabType === 1 || this.state.careerLabType === 2) {
      var gName = this.state.subjectName.trim();
      gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
      return `${APPCONFIG.BASE_URL}link-career-lab/${
        this.props.match.params.subjectId
      }/${
        gName ? gName : this.state.careerLabType === 1 ? "course" : "internship"
      }`;
    } else {
      return `${APPCONFIG.BASE_URL}link-challenge-Description/${this.props.match.params.challengeId}`;
    }
  };
  socialPopover() {
    // function removeHTML(str) {
    //   var tmp = document.createElement("DIV");
    //   tmp.innerHTML = str;
    //   return tmp.textContent || tmp.innerText || "";
    // }
    // const content =
    //   this.state.postValue.content != null
    //     ? this.state.postValue.content
    //     : this.state.postValue.fileContent;
    return (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Share in Social media</Popover.Title>
        <Popover.Content
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "4px",
          }}
        >
          <FacebookShareButton
            url={this.getUrlLink()}
            title={this.socialMediaContent()}
            //quote={removeHTML(content)}
            //description={removeHTML(content)}
            //hashtag="#camperstribe"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>{" "}
          <TwitterShareButton
            url={this.getUrlLink()}
            title={this.socialMediaContent()}
            //title={removeHTML(content)}
            hashtag="#camperstribe"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>{" "}
          <WhatsappShareButton
            url={this.getUrlLink()}
            title={this.socialMediaContent()}
            //title={removeHTML(content)}
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>{" "}
          <TelegramShareButton
            title={this.socialMediaContent()}
            url={this.getUrlLink()}
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
        </Popover.Content>
      </Popover>
    );
  }

  keyFile(key) {
    this.setState({ fileTypeKey: key, fileTypeState: true });
    this.setState({ fileImage: false });
  }
  closeChoosedFileImage() {
    this.setState({
      imageOptionOpen: false,
    });
  }
  closeChoosedFileVideo() {
    this.setState({
      videoOptionOpen: false,
    });
  }
  closeChoosedFileData() {
    this.setState({
      fileTypeState: false,
      file: [],
      grouporeventFiles: this.state.grouporeventFilesList,
    });
    //  this.setState({editImage: [], fileTypeState: false, file: [] ,grouporeventFiles: this.state.grouporeventFilesList })

    this.setState({ fileImage: true });
    console.log("update 3", this.state.editImage);
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  getAllTags() {
    this.setState({
      tagDetails: this.props.tagList.data,
    });
    this.getTagNameAlone(this.props.tagList.data);
  }
  getTagNameAlone(result) {
    var arrayTagNames = new Array();
    for (let i = 0; i < result.length; i++) {
      arrayTagNames.push(result[i].tagName);
    }
    this.setState({ tagName: arrayTagNames });
  }
  handleGrade(event) {
    if (this.state.curriculumId === "") {
      Swal.fire({
        title: "Please choose valid program before choosing grade",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
        reverseButtons: true,
      }).then((result) => {});
    } else {
      this.setState({
        gradeId: event.target.value,
      });
    }
  }

  getQuestionMediaFiles(files, type) {
    console.log("update 111", files);
    this.setState({ file: files, type: type });
  }

  async getQuestionMediaImage(files, type) {
    console.log("update video img", files);
    await this.setState({
      selectPage: false,
      imageFile: files,
      type: type,
      selectOption: false,
      grouporeventFiles: [],
      editImage: files,
    });
    console.log(this.state.type);
    console.log(this.state.imageFile);
  }
  async getQuestionMediaImageFiles(files, type) {
    console.log("update video img", files);
    await this.setState({
      selectPageImage: false,
      fileImages: files,
      type: type,
      selectOption: false,
      grouporeventFiles: [],
      editImage: files,
    });
  }

  // async getQuestionMediaVideoFiles(files, type) {
  //     console.log("update 1", files)
  //     await this.setState({ selectPageVideo:false , fileImages: files, type: type, selectOption: false, grouporeventFiles: [], editImage: files });

  // }
  imageOptionOpen(key) {
    this.setState({
      fileTypeKey: key,
      imageOptionOpen: true,
    });
  }
  videoOptionOpen(key) {
    this.setState({
      fileTypeKey: key,
      videoOptionOpen: true,
    });
  }
  editorStateValues(values) {
    console.log("editorStateValues");
    console.log(values);
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        content: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        content: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  }

  handleTeachingMultiSelect(subject_value) {
    this.setState({
      teaching: subject_value,
    });
  }

  getallSubject() {
    this.setState({ app_subjects: this.props.subjectsList.data });
  }

  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        this.setState({ gradeDetails: result.data.grade });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getQuestionMediaVideoFiles(files, type) {
    console.log("update video", files[0]);
    console.log("files");
    this.setState({
      selectPageVideo: false,
      fileVideo: files,
      type: type,
      fileTypeKey: "video",
    });
  }
  async fileOptionEnableImage() {
    await this.setState({ selectPageImage: true, fileOptionImage: false });
  }
  async fileOptionEnableVideo() {
    await this.setState({ selectPageVideo: true, fileOptionVideo: true });
  }
  async fileOptionEnable() {
    console.log("update 2", this.state.editImage);
    await this.setState({
      selectPage: true,
      editImage: [],
      questionFile: null,
      grouporeventFiles: this.state.grouporeventFilesList,
      fileOption: false,
    });

    //await  this.setState({ editImage:[], questionFile: null ,grouporeventFiles: this.state.grouporeventFilesList, fileOption: false });
    console.log("update 3", this.state.editImage);
  }
  onUpdateSubmit() {
    const formData = new FormData();
    if (!this.state.question.trim()) {
      toast.warn("Please enter valid Name");
    }
    // else if (this.state.teamLeader == "") {
    //   toast.warn("Please choose Team Leader");
    // }
    else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
      toast.warn("Please enter valid Email");
    } else if (
      this.state.careerLabType === 0 &&
      this.state.participation == ""
    ) {
      toast.warn("Please choose Participation");
    } else if (!this.state.challenges) {
      toast.warn("Please select any one of the category");
    } else if (this.state.careerLabType === 0 && !this.state.age) {
      toast.warn("Please enter age");
    } else if (
      this.state.number == "" ||
      this.state.number.toString().length != 10
    ) {
      toast.warn("Please enter valid Contact Number");
    } else if (this.state.email == "") {
      toast.warn("Please enter Email");
    } else if (
      this.state.careerLabType === 0 &&
      this.state.caption.trim() == ""
    ) {
      toast.warn("Please enter caption");
    } else if (this.state.address.trim() == "") {
      toast.warn("Please enter address");
    } else {
      if (this.state.fileVideo.length > 0) {
        console.log("fileVideoData fileVideo", this.state.fileVideo);
        formData.append("name", this.state.question.trim());
        formData.append("description", this.state.content.trim());
        formData.append("participation", this.state.participation);
        formData.append("age", this.state.age);
        formData.append("contactNumber", this.state.number);
        formData.append("teamLeader", this.state.teamLeader);
        formData.append("email", this.state.email.trim());
        formData.append("caption", this.state.caption.trim());
        formData.append("tagsId", this.state.challenges);
        formData.append("address", this.state.address.trim());
        formData.append("subjectId", this.props.match.params.subjectId);
        formData.append("challengeId", this.state.updatechallengeId);

        if (this.state.fileVideo.length > 0) {
          for (let i = 0; i < this.state.fileVideo.length; i++) {
            formData.append("videoFiles", this.state.fileVideo[i]);
          }
        }
      } else {
        formData.append("name", this.state.question.trim());
        formData.append("description", this.state.content.trim());
        formData.append("participation", this.state.participation);
        formData.append("age", this.state.age);
        formData.append("contactNumber", this.state.number);
        formData.append("teamLeader", this.state.teamLeader);
        formData.append("email", this.state.email.trim());
        formData.append("caption", this.state.caption.trim());
        formData.append("tagsId", this.state.challenges);
        formData.append("address", this.state.address.trim());
        formData.append("subjectId", this.props.match.params.subjectId);
        formData.append("challengeId", this.state.updatechallengeId);
        console.log("fileVideoData", this.state.fileVideoData);
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      this.block(true);
      ForumService.updateChallenge(formData, config)
        .then((response) => {
          var result = response.data.data;
          toast.success("✓Updated Successfully..");
          this.block(false);
          this.backtogroups(response.data.data.challengeId);
          ///this.gotogroupDescription()
        })
        .catch((error) => {
          this.block(false);
          toast.error("Error to saving");
          //   this.backtogroups();
          console.log(error);
        });
    }
  }

  onFormSubmitChallenge() {
    const formData = new FormData();
    if (!this.state.question.trim()) {
      toast.warn(
        this.state.careerLabType === 0
          ? "Please enter your challenge title"
          : "Enter valid name"
      );
    } else if (
      !this.props.loginDetails.eligibility &&
      this.state.proofType === "" &&
      this.state.imageFile == ""
    ) {
      toast.warn("Please update any one  of the proof");
    }
    // else if (!this.state.teamLeader) {
    //   toast.warn("Please choose Team Leader");
    // }
    else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
      toast.warn("Please enter valid Email");
    } else if (
      this.state.careerLabType === 0 &&
      this.state.fileVideo.length == 0
    ) {
      toast.warn("Please Upload Video");
    } else if (
      this.state.careerLabType === 0 &&
      this.state.participation == ""
    ) {
      toast.warn("Please choose Participation");
    } else if (!this.state.content) {
      toast.warn(
        this.state.careerLabType === 0
          ? "Please add your description"
          : "Please add your bio"
      );
    } else if (!this.state.challenges) {
      toast.warn("Please select any one of the category");
    } else if (this.state.careerLabType === 0 && !this.state.age) {
      toast.warn("Please enter age");
    } else if (
      this.state.number == "" ||
      this.state.number.toString().length != 10
    ) {
      toast.warn("Please enter Valid Contact Number");
    } else if (this.state.email == "") {
      toast.warn("Please enter Email");
    } else if (
      this.state.careerLabType === 0 &&
      this.state.caption.trim() == ""
    ) {
      toast.warn("Please enter caption");
    } else if (this.state.address.trim() == "") {
      toast.warn("Please enter address");
    } else {
      if (!this.props.loginDetails.eligibility && this.state.imageFile != "") {
        formData.append("name", this.state.question.trim());
        formData.append("description", this.state.content.trim());
        formData.append("Participation", this.state.participation);
        formData.append("age", this.state.age);
        formData.append("contactNumber", this.state.number);
        formData.append("proofType", this.state.proofType);
        formData.append("teamLeader", this.state.teamLeader);
        formData.append("email", this.state.email.trim());
        formData.append("caption", this.state.caption.trim());
        formData.append("tagsId", this.state.challenges);
        formData.append("address", this.state.address.trim());
        formData.append("subjectId", this.props.match.params.subjectId);
        console.log("contactNumber :", this.state.number);
        formData.append("messgaeFiles", this.state.imageFile);
        if (this.state.fileVideo.length > 0) {
          for (let i = 0; i < this.state.fileVideo.length; i++) {
            formData.append("videoFiles", this.state.fileVideo[i]);
          }
        }
      } else {
        formData.append("name", this.state.question.trim());
        formData.append("description", this.state.content.trim());
        formData.append("participation", this.state.participation);
        formData.append("age", this.state.age);
        formData.append("contactNumber", this.state.number);
        formData.append("teamLeader", this.state.teamLeader);
        formData.append("email", this.state.email.trim());
        formData.append("caption", this.state.caption.trim());
        formData.append("tagsId", this.state.challenges);
        formData.append("address", this.state.address.trim());
        formData.append("subjectId", this.props.match.params.subjectId);
        // if (this.state.fileImages.length > 0) {
        //   for (let i = 0; i < this.state.fileImages.length; i++) {
        //     formData.append("imageFiles", this.state.fileImages[i]);
        //   }
        //   console.log(this.state.fileImages);
        // }
        if (this.state.fileVideo.length > 0) {
          for (let i = 0; i < this.state.fileVideo.length; i++) {
            formData.append("videoFiles", this.state.fileVideo[i]);
          }
        }
        console.log("contactNumber :", this.state.number);
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.block(true);
      ForumService.saveChallenge(formData, config)
        .then((response) => {
          var result = response.data.data;
          toast.success("✓Saved Successfully..");
          this.block(false);
          if (this.state.proofType !== "") {
            this.props.updateEligibility();
          }
          this.backtogroups(response.data.data.challengeId);
          //  this.gotogroupDescription()
        })
        .catch((error) => {
          this.block(false);
          //   this.backtogroups();
          console.log(error);
        });
    }
  }

  async removeImageFile() {
    var inpu = this.state.fileImage;
    if (this.state.grouporeventFiles) {
      this.setState({
        grouporeventFiles: [],
        editImage: [],
      });
    } else {
    }
  }
  movetolearningapp = (id, name) => {
    this.props.history.push(
      `/${this.props.workspaceType}/challengeDescription/${id}/${name}`
    );
  };
  movetolearningappList() {
    this.props.history.push(`/${this.props.workspaceType}/career-lab`);
  }

  expirycheck(e) {
    this.setState({ expiry: e.target.value, expiryDate: "" });
  }
  participation(e) {
    this.setState({ participation: e.target.value });
  }
  teamLeader(e) {
    this.setState({ teamLeader: e.target.value });
  }

  block(blocking) {
    this.setState({ blocking: blocking });
  }

  handleForm(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getAllCurriculum() {
    this.setState({
      curriculumList: this.props.curriculumtrackList.curriculum,
    });
  }
  async getTagId(selectedTagName) {
    var tagId = new Array();
    for (let i = 0; i < this.state.tagDetails.length; i++) {
      for (let j = 0; j < selectedTagName.length; j++) {
        if (this.state.tagDetails[i].tagName == selectedTagName[j]) {
          tagId.push(this.state.tagDetails[i].tagId);
        }
      }
    }
    await this.setState({ selectedTagId: tagId });
  }

  handleCurriculum(event) {
    this.setState({
      curriculumId: event.target.value,
      gradeId: "",
    });
    this.getGradeByCurriculumId(event.target.value);
  }

  getGradeByCurriculumId(value) {
    this.setState({ gradeId: "" });
    let getGradeByCurriculumId = {
      curriculumId: value,
    };
    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        this.setState({ gradeDetails: response.data.data.gradeListById });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getSingleChallenge() {
    var subjectId = {
      challengeId: this.props.match.params.challengeId,
    };
    ForumService.getSingleChallenge(subjectId)
      .then((response) => {
        var result = response.data.data.challenge;

        this.setState({
          editChallengeApplied: true,
          question: result.title ? result.title : "",
          participation: result.participation ? result.participation : "",
          teamLeader: result.teamLeader ? result.teamLeader : "",
          caption: result.caption ? result.caption : "",
          age: result.age ? result.age : "",
          email: result.alternativeEmail,
          number: result.contactNumber,
          address: result.address,
          questionDescribed: result.description ? result.description : "",
          content: result.description,
          subjectId: response.data.data.subjectId,
          subjectName: response.data.data.subjectName,
          challenges: response.data.data.categoryId,
        });
        // if (result.category.length > 0) {
        //   for (var j = 0; j < result.category.length; j++) {
        //     this.setState({
        //       challenges: result.category[0].categoryId,
        //     });
        //   }
        // }

        this.getMessageFiles(result.challengeMediaFiles);

        // console.log("vallue", result.grouporeventFiles);
        // console.log("update text", result.grouporeventFiles);
        // if (result.fileType == "image") {
        //   // this.setState({fileImage:true ,fileTypeState:true ,editImage:result.grouporeventFiles})
        //   this.setState({ fileImage: true, fileTypeState: true });
        // } else {
        //   this.setState({ fileImage: false, editImage: [] });
        // }

        // this.getOldTagList(result.tagListId);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getMessageFiles(files, type) {
    let fileLisVideo = [];
    //  if(this.state.isvideoOpen === true){
    if (files.length > 0) {
      // for (let i = 0; i < files.length; i++) {
      //   // fileLisVideo.push(
      //   //   `${APPCONFIG.API_URL}auth/video/${files[i].fileName}`
      //   // );
      //   fileLisVideo.push({
      //     file: files[i].streamUrl,
      //     image: files[i].thumbnail,
      //   });
      // }

      await this.setState({
        fileVideoData: files,
        videoOptionOpen: false,
      });
      console.log("FILE LIST");
      console.log(this.state.fileData);
    }
  }

  getGradeByCurriculumIdUpdate(value) {
    let getGradeByCurriculumId = {
      curriculumId: value,
    };

    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        this.setState({ gradeDetails: response.data.data.gradeListById });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  UpdateQuestionInAllpages(question, tags) {
    var QuestionId = question.questionId;
    var condition = this.props.QandAquestionList.filter(
      (data) => data.questionId == QuestionId
    );
    var Libcondition = this.props.topQuestions.filter(
      (data) => data.questionId == QuestionId
    );
    if (condition.length > 0) {
      for (var j = 0; j < this.props.QandAquestionList.length; j++) {
        if (this.props.QandAquestionList[j].questionId == QuestionId) {
          var tObj = question;

          var createdDate = tObj.createdDate;
          moment(createdDate).format("DD/MM/yyyy");
          var Dates = Utils.getLastAskedDate(createdDate);
          this.props.QandAquestionList[j].answer = tObj.answer;
          this.props.QandAquestionList[j].answerSize = tObj.answerSize;
          this.props.QandAquestionList[j].createdDate = tObj.createdDate;
          this.props.QandAquestionList[j].questionDescription =
            tObj.questionDescription;
          this.props.QandAquestionList[j].questionId = tObj.questionId;
          this.props.QandAquestionList[j].questionName = tObj.questionName;
          this.props.QandAquestionList[j].questionTags = tObj.questionTags;
          this.props.QandAquestionList[j].questionviewers =
            tObj.questionviewers;
          this.props.QandAquestionList[j].questionType = tObj.questionType;
          this.props.QandAquestionList[j].solved = tObj.solved;
          this.props.QandAquestionList[j].updatedDate = tObj.updatedDate;
          this.props.QandAquestionList[j].userId = tObj.userId;
          this.props.QandAquestionList[j].userName = tObj.userName;
          this.props.QandAquestionList[j].views = tObj.views;
          this.props.QandAquestionList[j].votes = tObj.votes;
          this.props.QandAquestionList[j].gradeId = tObj.grade;
          this.props.QandAquestionList[j].questionFile = tObj.questionFile;
          this.props.QandAquestionList[j].lastAsked = Dates;
          this.props.QandAquestionList[j].programId = tObj.programId;
          this.props.QandAquestionList[j].originalFileName =
            tObj.originalFileName;
          this.props.QandAquestionList[j].markAsPrivate = tObj.markAsPrivate;
          this.props.QandAquestionList[j].star = tObj.questionLikesArray.filter(
            (star) => star.userId == this.props.loginDetails.uId
          );

          this.props.QandAquestionList[j].status = tObj.status;
          this.props.QandAquestionList[j].answerBlockStatus =
            tObj.answerBlockStatus;
        }
      }
    } else {
      var createdDate = question.createdDate;
      moment(createdDate).format("DD/MM/yyyy");
      var Dates = Utils.getLastAskedDate(createdDate);
      var question_list = {
        answer: question.answer,
        answerSize: question.answerSize,
        createdDate: question.createdDate,
        questionDescription: question.questionDescription,
        questionId: question.questionId,
        questionName: question.questionName,
        questionTags: tags,
        questionviewers: question.questionviewers,
        questionType: question.questionType,
        solved: question.solved,
        updatedDate: question.updatedDate,
        userId: question.userId,
        userName: question.userName,
        views: question.views,
        votes: question.votes,
        gradeId: question.grade,
        questionFile: question.questionFile,
        lastAsked: Dates,
        programId: question.programId,
        questionLikesArray: question.questionLikesArray,
        originalFileName: question.originalFileName,
        markAsPrivate: question.markAsPrivate,
        star: question.questionLikesArray.filter(
          (star) => star.userId == this.props.loginDetails.uId
        ),
        pageNo: 1,
        status: question.status,
        answerBlockStatus: question.answerBlockStatus,
      };

      this.props.QandAquestionList.splice(0, 0, question_list);
    }

    if (Libcondition.length > 0) {
      for (var j = 0; j < this.props.topQuestions.length; j++) {
        if (this.props.topQuestions[j].questionId == QuestionId) {
          var tObj = question;

          var createdDate = tObj.createdDate;
          moment(createdDate).format("DD/MM/yyyy");
          var Dates = Utils.getLastAskedDate(createdDate);
          this.props.topQuestions[j].answer = tObj.answer;
          this.props.topQuestions[j].answerSize = tObj.answerSize;
          this.props.topQuestions[j].createdDate = tObj.createdDate;
          this.props.topQuestions[j].questionDescription =
            tObj.questionDescription;
          this.props.topQuestions[j].questionId = tObj.questionId;
          this.props.topQuestions[j].questionName = tObj.questionName;
          this.props.topQuestions[j].questionTags = tObj.questionTags;
          this.props.topQuestions[j].questionviewers = tObj.questionviewers;
          this.props.topQuestions[j].questionType = tObj.questionType;
          this.props.topQuestions[j].solved = tObj.solved;
          this.props.topQuestions[j].updatedDate = tObj.updatedDate;
          this.props.topQuestions[j].userId = tObj.userId;
          this.props.topQuestions[j].userName = tObj.userName;
          this.props.topQuestions[j].views = tObj.views;
          this.props.topQuestions[j].votes = tObj.votes;
          this.props.topQuestions[j].gradeId = tObj.grade;
          this.props.topQuestions[j].questionFile = tObj.questionFile;
          this.props.topQuestions[j].lastAsked = Dates;
          this.props.topQuestions[j].programId = tObj.programId;
          this.props.topQuestions[j].originalFileName = tObj.originalFileName;
          this.props.topQuestions[j].markAsPrivate = tObj.markAsPrivate;
          this.props.topQuestions[j].star = tObj.questionLikesArray.filter(
            (star) => star.userId == this.props.loginDetails.uId
          );
          this.props.topQuestions[j].status = tObj.status;
          this.props.topQuestions[j].answerBlockStatus = tObj.answerBlockStatus;
        }
      }
    } else {
      var createdDate = question.createdDate;
      moment(createdDate).format("DD/MM/yyyy");
      var Dates = Utils.getLastAskedDate(createdDate);
      var question_list = {
        answer: question.answer,
        answerSize: question.answerSize,
        createdDate: question.createdDate,
        questionDescription: question.questionDescription,
        questionId: question.questionId,
        questionName: question.questionName,
        questionTags: tags,
        questionviewers: question.questionviewers,
        questionType: question.questionType,
        solved: question.solved,
        updatedDate: question.updatedDate,
        userId: question.userId,
        userName: question.userName,
        views: question.views,
        votes: question.votes,
        gradeId: question.grade,
        questionFile: question.questionFile,
        lastAsked: Dates,
        programId: question.programId,
        questionLikesArray: question.questionLikesArray,
        originalFileName: question.originalFileName,
        markAsPrivate: question.markAsPrivate,
        star: question.questionLikesArray.filter(
          (star) => star.userId == this.props.loginDetails.uId
        ),
        pageNo: 1,
        status: question.status,
        answerBlockStatus: question.answerBlockStatus,
      };

      this.props.topQuestions.splice(0, 0, question_list);
    }
  }
  backtoHome() {
    this.props.backToGroupDes();
    //  this.props.history.push(`/groupDescription/${this.state.challengeId}/${this.state.challengeName}`);
  }
  backtogroups(challengeId) {
    if (this.state.careerLabType === 0) {
      this.props.history.push(`/challenge-description/${challengeId}`);
    } else {
      this.movetolearningapp(this.props.match.params.subjectId, "title-title");
    }
  }
  backtoevents() {
    this.props.history.push("/upcomingevents");
  }
  gotogroupDescription(id) {
    this.props.history.push(
      `/${this.props.workspaceName}/myGroupDescription/${id}`
    );
  }
  async getOldTagList(oldTags) {
    var subjectName = [];
    console.log("oldTags");
    console.log(oldTags);
    console.log("this.props.tagList");
    console.log(this.props.tagList);
    if (this.props.tagList.data.length > 0) {
      for (let i = 0; i < this.props.tagList.data.length; i++) {
        for (let j = 0; j < oldTags.length; j++) {
          if (this.props.tagList.data[i].tagId === oldTags[j].tagId) {
            subjectName.push(this.props.tagList.data[i].tagName);
          }
        }
      }
      console.log(subjectName);
      await this.setState({ oldTagNames: subjectName });
      this.getTagId(subjectName);
    }
  }
  getAllCategoryId = (id) => {
    let category = { subjectId: id };
    ProfileService.getAllCategoryId(category)
      .then((response) => {
        console.log("getAllCategoryId >>", response.data.data);
        this.updateData(response.data.data);
        console.log("after category >>", this.state.category);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  updateData = async (data) => {
    await this.setState({ category: data });
  };
}

const mapStateToProps = function (state) {
  return {
    SubjectquestionList: state.SubjectQuestion.list,
    SubjectquestTotalitems: state.SubjectQuestion.totalItems,
    wallList: state.dashboard.dashboardList,
    loading: state.dashboard.loading,
    tagDatas: state.subjects.list,
    questionLists: state.myquestions.myQuestionList[0],
    totalItems: state.myquestions.myQuestionList[1],
    topQuestions: state.mylibraryquestion.list,
    solvedTotalitems: state.mylibraryquestion.totalItems,
    QandAquestionList: state.topquestList.list,
    QandAquestTotalitems: state.topquestList.totalItems,
    subjectsList: state.commonApi.subjectslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    tagList: state.commonApi.taglist,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getAllProofType: () => dispatch(getAllProofType()),
    getDashboardList: () => dispatch(getDashboardList(0)),
    getAllSubjectList: (type, page) => dispatch(getAllSubjectList(type, page)),
    getAllMyGroupEvents: (type, page) =>
      dispatch(getAllMyGroupEvents(type, page)),
    getMyQuestionsList: () => dispatch(getMyQuestionsList(0)),
    getMyLibraryQuestionList: () =>
      dispatch(getMyLibraryQuestionList("All", 1)),
    getQuestionsList: () =>
      dispatch(getQuestionsList("unanswered", 1, 0, true, true)),
    updateEligibility: () => dispatch(updateEligibility()),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ChallengeApplyForm)));
