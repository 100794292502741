import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  DateNavigator,
  Appointments,
  Resources,
  AppointmentForm,
  Toolbar,
  TodayButton,
  AppointmentTooltip,
  MonthView,
  ViewSwitcher,
  DayView,
} from "@devexpress/dx-react-scheduler-material-ui";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreVert";
import Grid from "@material-ui/core/Grid";
import "./mycalendar.css";
import classNames from "clsx";
// import { CompassCalibrationOutlined } from "@material-ui/icons";
//import appointments from '../../../demo-data/today-appointments';
// import Room from "@material-ui/icons/Room";
// import CreateIcon from "@material-ui/icons/Create";
// import moment from "moment";

const style = (theme) => ({
  todayCell: {
    backgroundColor: fade(theme.palette.primary.main, 0.1),
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.14),
    },
    "&:focus": {
      backgroundColor: fade(theme.palette.primary.main, 0.16),
    },
  },
  weekendCell: {
    backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    "&:hover": {
      backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    },
    "&:focus": {
      backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    },
  },
  today: {
    backgroundColor: fade(theme.palette.primary.main, 0.16),
  },
  weekend: {
    backgroundColor: fade(theme.palette.action.disabledBackground, 0.06),
  },
});

const TimeTableCellBase = ({ classes, ...restProps }) => {
  const { startDate } = restProps;
  const date = new Date(startDate);
  if (date.getDate() === new Date().getDate()) {
    return (
      <WeekView.TimeTableCell {...restProps} className={classes.todayCell} />
    );
  }
  if (date.getDay() === 0 || date.getDay() === 6) {
    return (
      <WeekView.TimeTableCell {...restProps} className={classes.weekendCell} />
    );
  }
  return <WeekView.TimeTableCell {...restProps} />;
};

const TimeTableCell = withStyles(style, { name: "TimeTableCell" })(
  TimeTableCellBase
);

const DayScaleCellBase = ({ classes, ...restProps }) => {
  const { startDate, today } = restProps;
  if (today) {
    return <WeekView.DayScaleCell {...restProps} className={classes.today} />;
  }
  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
    return <WeekView.DayScaleCell {...restProps} className={classes.weekend} />;
  }
  return <WeekView.DayScaleCell {...restProps} />;
};

const resources = [
  {
    fieldName: "status",
    title: "Type",
    instances: [
      { id: 1, text: 1, color: "#0061d5" },
      { id: 2, text: 2, color: "#04B486" },
      { id: 3, text: 3, color: "#FD1405" },
    ],
  },
];

const DayScaleCell = withStyles(style, { name: "DayScaleCell" })(
  DayScaleCellBase
);

const getClassByLocation = (classes, location) => {
  if (location === "Room 1") return classes.firstRoom;
  if (location === "Room 2") return classes.secondRoom;
  return classes.thirdRoom;
};

const Content = withStyles(style, { name: "Content" })(
  ({ children, appointmentData, classes, ...restProps }) => (
    <AppointmentTooltip.Content
      {...restProps}
      appointmentData={appointmentData}
    >
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <span>{appointmentData.location}</span>
        </Grid>
      </Grid>
    </AppointmentTooltip.Content>
  )
);

const CommandButton = withStyles(style, { name: "CommandButton" })(
  ({ classes, ...restProps }) => (
    <AppointmentTooltip.CommandButton
      {...restProps}
      className={classes.commandButton}
    />
  )
);

class ScheduleMyCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.value,
      appointments: [],
    };
    this.getData = this.getData.bind(this);
    this.showAlert = this.showAlert.bind(this);
  }

  componentDidMount() {
    this.setState({ appointments: this.props.events });
    console.log("app_eventList");
    console.log(this.props.events);
  }

  componentDidUpdate(nextProps) {
    console.log("eventDatas");
    console.log(this.props.events);

    if (this.props.events != nextProps.events) {
      this.setState({ appointments: this.props.events });
    }
  }

  componentWillReceiveProps(nextValue) {}

  showAlert(appointmentData) {
    alert("Its Working Fine, Self Details");
    alert(JSON.stringify(appointmentData));
  }

  render() {
    const { data, currentDate } = this.state;
    const self = this;

    const Header = withStyles(style, { name: "Header" })(
      ({ children, appointmentData, classes, ...restProps }) => (
        <AppointmentTooltip.Header
          {...restProps}
          className={classNames(
            getClassByLocation(classes, appointmentData.location),
            classes.header
          )}
          appointmentData={appointmentData}
        >
          <IconButton
            onClick={() => {
              self.props.callBack(appointmentData);
            }}
            className={classes.commandButton}
          >
            <MoreIcon />
          </IconButton>
        </AppointmentTooltip.Header>
      )
    );

    return (
      <Paper>
        <Scheduler data={this.state.appointments} height={550}>
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={this.currentDateChange}
            defaultCurrentViewName="Month"
          />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <DayView startDayHour={0} endDayHour={24} />
          <WeekView
            startDayHour={0}
            endDayHour={24}
            timeTableCellComponent={TimeTableCell}
            dayScaleCellComponent={DayScaleCell}
          />
          <MonthView />
          <Appointments />

          <ViewSwitcher />
          {/* <AppointmentTooltip
            headerComponent={Header}
            contentComponent={Content}
            commandButtonComponent={CommandButton}
            showCloseButton
          /> */}

          <Resources data={resources} />
        </Scheduler>
      </Paper>
    );
  }
  getData(data) {}
}
export default ScheduleMyCalendar;
