import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import dummyimage from "../../../img/banner.jpg";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import AuthService from "../../../session/AuthService";
import ProfileService from "../../../api/ProfileService";
import FriendsFinder from "../../../api/FriendsFinder";
import PostService from "../../../api/PostService";
import * as APPCONFIG from "../../../config/app-config.js";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  getNotificationList,
  removeNotificationListById,
} from "../../../redux/actions/notificationActions";
import {
  getRecentlyAddedBadges,
  getAllBadgesList,
} from "../../../redux/actions/badgesActions";
import {
  getAllSurveyList,
  CloseSurveyList,
} from "../../../redux/actions/squareHomeActions";
import MyCommunity from "../../Home/HomeUpdated/MyCommunity";
import {
  getTreadingUsers,
  getallSubjectGroupsByMembersCount,
} from "../../../redux/actions/subjectActions";
import "bootstrap/dist/css/bootstrap.min.css";
// import { getNavigationType } from "../../../redux/actions/commonApiActions";
import BlockUi from "react-block-ui";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  makeActionInDynamicForm,
  storeLoginDetails,
} from "../../../redux/actions/loginDetailsActions";
import {
  getNavigationType,
  getUserWorkSpaceList,
  getAnnouncementList,
} from "../../../redux/actions/commonApiActions";
import { MdOutlineGroups } from "react-icons/md";
import "./HomeDesign.css";
import Nomini from "../../../images/badge/launch.png";

let global_language = "";
var Gobalsurvey = [];
var notificationhit = false;
var buttonsDatas = [
  {
    value: "groups",
    label: "Trending Groups",
  },
  // {
  //   value: "events",
  //   label: "Trending Events",
  // },
];

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trendingGroup: true,
      trendingEvent: false,
      notificationData: [],
      announcementData: [],
      showAnnoucment: false,
      surveyBoxState: false,
      showList: true,
      FeedbackList: [],
      recentlyAddedBadgesList: [],
      FriendsList: [],
      userKnowledgepoint: 0,
      groupBasedMembers: [],
      globalLanguage: AuthService.getCommonLanguages(),
      trendingGrouploading: false,
      trendingPage: 0,
      trendingHasMore: true,
      alertMessage: true,
    };
    this.getKnowledgePoints = this.getKnowledgePoints.bind(this);
    this.openAnnoucmentScreen = this.openAnnoucmentScreen.bind(this);
    this.closeAnnoucmentScreen = this.closeAnnoucmentScreen.bind(this);
    this.showSurveyBox = this.showSurveyBox.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getallHobbies = this.getallHobbies.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.getAnnouncementonlyOpen = this.getAnnouncementonlyOpen.bind(this);
    this.getCallUsername = this.getCallUsername.bind(this);
    this.yuukkeFriend = this.yuukkeFriend.bind(this);
    this.popUpSurveyBox = this.popUpSurveyBox.bind(this);
    this.surveyListState = this.surveyListState.bind(this);
    this.LoadAnnoncementFeebackList =
      this.LoadAnnoncementFeebackList.bind(this);
    this.UpdateFeedbackList = this.UpdateFeedbackList.bind(this);
    this.gotomyprofile = this.gotomyprofile.bind(this);
    this.choosedValueData = this.choosedValueData.bind(this);
    this.getPopularTopics = this.getPopularTopics.bind(this);
    this.getallSubjectGroupsByMembersCount =
      this.getallSubjectGroupsByMembersCount.bind(this);
    this.groupList = this.groupList.bind(this);
    this.gotogroupDescription = this.gotogroupDescription.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    global_language = langValue;
    i18n.changeLanguage(langValue);
    let auth = this.props.authUser.type;

    //this.props.getAllSubjectList("Events");

    if (auth) {
      // if (this.props.workspaceType?.slug !== "default") {
      this.getallSubjectGroupsByMembersCount();
      // }
      // this.props.getTreadingUsers();
      if (this.props.notificationCount > 0) {
        notificationhit = false;
        this.props.getNotificationList(notificationhit);
      } else {
        notificationhit = true;
        this.props.getNotificationList(notificationhit);
      }

      this.props.getAnnouncementList();
    } else {
    }
    // if (AuthService.isMSTeamsApp()) {
    //   microsoftTeams.appInitialization.notifySuccess();
    // }
    if (auth) {
      this.initfunction();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.authUser.type &&
      this.props.workspaceType &&
      this.props.workspaceType !== prevProps.workspaceType
    ) {
      await this.setState({
        trendingPage: 0,
        groupBasedMembers: [],
        // alertMessage: true,
      });
      this.getallSubjectGroupsByMembersCount();
      console.log("get-trending-community");
    }
    if (
      this.props.wrongUrlStatus &&
      this.props.unAuthUrl !== prevProps.unAuthUrl &&
      this.props.authUser.type
    ) {
      console.log("login- unAuthUrl 1", this.props.unAuthUrl);
      this.props.history.push(`/${this.props.unAuthUrl}/home`);
    }
    if (
      !this.props.wrongUrlStatus &&
      this.props.unAuthUrl !== "url" &&
      this.props.unAuthUrl !== prevProps.unAuthUrl &&
      this.props.authUser.type
    ) {
      console.log("login- unAuthUrl 2", this.props.unAuthUrl);
      this.props.history.push(`/${this.props.unAuthUrl}/home`);
    }

    // if (prevProps.match.params!== this.props.match.params) {
    //   console.log("type");
    //   const { type } =prevProps.match.params;
    //   console.log("router path : square : ",type);
    //   console.log("router path : square : userWorkSpaceList ",this.props.userWorkSpaceList);
    //   let result =this.props.userWorkSpaceList.filter(item =>item.slug===type)[0];
    //   console.log("router path : square : result : ",result);
    //   this.setState({
    //     alertMessage:result!==undefined?true:false,
    //   })
    //   if(result!==undefined ){
    //     // if(result.slug!=="default"){
    //       console.log("router path : square : workspaceId : ",result.workSpaceId);
    //       this.workSpaceUrlChange(result.workSpaceId);
    //     // }

    //   }else{
    //     this.props.getNavigationType(null);

    //   }
    // }
  }
  workSpaceUrlChange = (e) => {
    if (!this.props.loginDetails.profile_complete) {
      this.props.history.push(`/photo-set-up`);
    } else {
      console.log("router path : square : workspaceUrl value : ", e);
      console.log(
        "router path : square : workspaceUrl : ",
        this.props.userWorkSpaceList
      );
      let data = this.props.userWorkSpaceList.find(
        (data) => data.workSpaceId === e
      );
      sessionStorage.setItem("WORKSPACE", data.slug);

      // this.props.history.push(`/${data.slug}/home`);
      this.props.getNavigationType(0);
    }
  };

  setAlertMessage = (e) => {
    this.setState({ alertMessage: e });
  };
  moveDescription = (userId) => {
    this.props.history.push(
      `/${this.props.workspaceType?.slug}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  };

  render() {
    const { t } = this.props;
    // buttonsDatas[0].label = t(buttonsDatas[0].label);
    //buttonsDatas[1].label = t(buttonsDatas[1].label);
    // const data = this.props.loginDetails.profile_uuid;

    // const profileImage = data ? `data:image;base64,${data}` : dummyimage;
    return (
      <Grid container spacing={2}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <div className="announcementWrapperOuter">
            {/* {this.props.wrongUrlStatus ? (
                  <BlockUi
                    tag="div"
                    message={t("Loading, please wait")}
                    className="wallLoadingPage"
                    blocking={this.state.blockingUi}
                  >
                    <div className="about-card">
                      <div className="about-card-header border-0 pb-0">
                        {" "}
                        <Alert
                          variant="danger"
                          // onClose={() => this.setAlertMessage(false)}
                          // dismissible
                        >
                          <Alert.Heading>
                            {t("Oh snap! You got an error!")}
                          </Alert.Heading>
                          <p>
                            {t("You are not authorized to this workspace.")}
                          </p>
                          <hr />
                          <div className="d-flex justify-content-end">
                            <p style={{ fontStyle: "italic" }}>
                              {t(
                                "for more information please contact to admin."
                              )}
                            </p>
                            
                          </div>
                        </Alert>
                      </div>
                    </div>
                  </BlockUi>
                ) : ( */}
            <>
              <MyCommunity squarehome={this.props.squarehome} homePost={true} />
            </>
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <div className="cardWrapper">
            <div className="position-relative d-flex align-items-center p-3">
              <MdOutlineGroups className="t-community-logo" />
              <h6 className="font-weight-bold m-0" style={{ fontSize: "15px" }}>
                {/* {this.props.workspaceType?.slug === "default"
                  ? t("Active Users")
                  : t("Trending Communities")} */}
                {t("Trending Communities")}
              </h6>
                {/* <div
                className="nomini"
                onClick={() =>
                  this.props.makeActionInDynamicForm("yuukke_launch_pad_-_cucf")
                }
              >
                <img src={Nomini} alt="img" />
              </div> */}
              {/* <div
                className="cal-war cal-war-nom"
                onClick={() =>
                  this.props.makeActionInDynamicForm(
                    "nomination_for_women_of_impact1176"
                  )
                }
              >
                NOMINATION OPEN FOR WOMEN OF IMPACT
              </div> */}
            </div>

            {/* <div style={{ minHeight: "auto", maxHeight: "350" }}> */}
            <div className="p-2">
              <BlockUi
                tag="div"
                style={{ minHeight: "100px" }}
                blocking={this.props.trendingGroupLoader}
                message={t("")}
                color="#A00030"
              >
                {/* {this.props.workspaceType?.slug === "default" ? (
                  <>
                    <ul className="l-none d-flex flex-column g-2 p-2 m-0 ">
                      {this.props.treadingUser?.length > 0 &&
                        this.props.treadingUser.map((item, index) => (
                          <li
                            className="position-relative trending-user p-3 c-pointer"
                            key={index}
                            onClick={() => this.moveDescription(item.userId)}
                          >
                            <img
                              alt=""
                              className="mt-5"
                              src={
                                APPCONFIG.API_URL +
                                "api/getUserPhoto/" +
                                item.userId +
                                "/" +
                                item.displayName
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = dummyimage;
                              }}
                            />
                            <section
                              className="text-white text-center text-capitalize"
                              style={{ zIndex: "12" }}
                            >
                              <h4 className="mt-3  font-weight-bold rdw-image-modal-upload-option-label">
                                {item.displayName}
                              </h4>
                              {item.professionName && (
                                <p>
                                  <MdWorkOutline
                                    className="text-white h-auto"
                                    style={{ width: "21px" }}
                                  />{" "}
                                  {item.professionName}
                                </p>
                              )}
                              {item.location && (
                                <p className="mb-2  mt-2">
                                  <GoLocation
                                    className="text-white h-auto"
                                    style={{ width: "22px" }}
                                  />{" "}
                                  {item.location}
                                </p>
                              )}

                              <p className="trending-title">
                                {item.type === "like" ? (
                                  <BiLike
                                    className="mr-1 h-auto"
                                    style={{ width: "22px" }}
                                  />
                                ) : item.type === "comment" ? (
                                  <BiComment
                                    className="mr-1 h-auto"
                                    style={{ width: "22px" }}
                                  />
                                ) : (
                                  <MdOutlinePostAdd
                                    className="mr-1 h-auto"
                                    style={{ width: "22px" }}
                                  />
                                )}
                                {item.title}
                              </p>
                            </section>
                            <section className="trending-users-wrap">
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                              <div>
                                <span class="dot"></span>
                              </div>
                            </section>
                          </li>
                        ))}
                    </ul>
                    {!this.props.trendingGroupLoader &&
                      this.props.treadingUser?.length === 0 && (
                        <p className="text-align-center">List not found</p>
                      )}
                  </>
                ) : ( */}
                <InfiniteScroll
                  dataLength={
                    this.props?.groupBasedMembers?.length
                      ? this.props.groupBasedMembers?.length
                      : 0
                  }
                  next={this.getallSubjectGroupsByMembersCount}
                  hasMore={this.props.groupsSeeMore}
                  scrollableTarget="trending_groups"
                  direction="down"
                  scrollThreshold={0.95}
                >
                  <div
                    id="trending_groups"
                    // onScroll={this.handleScroll}
                    style={{
                      // minHeight: "auto",
                      maxHeight: "600px",
                      // overflowY: "auto",
                      overflowX: "hidden",
                      gap: "1rem",
                      // margin: "0px 22px 25px",
                    }}
                    className="d-flex flex-column"
                  >
                    {this.props.groupBasedMembers &&
                      this.props.groupBasedMembers.length > 0 &&
                      this.props.groupBasedMembers.map((subject, index) => (
                        <div
                          className="card flex-row trending-card p-2"
                          onClick={() =>
                            this.gotogroupDescription(
                              subject.subjectId,
                              subject.subjectName,
                              subject.status,
                              subject.join
                            )
                          }
                          key={index}
                        >
                          <div className="post-avatar post-avatar-story post-me-2">
                            <img
                              className="post-avatar-img rounded-circle text-secondary"
                              src={`${APPCONFIG.API_URL}auth/image/${subject.fileName}`}
                              alt=""
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = dummyimage;
                              }}
                            />
                          </div>
                          <div
                            style={{
                              padding: "6px 0px",
                              minHeight: "4.5rem",
                            }}
                          >
                            <p className="home-sub">{subject.subjectName}</p>
                            {(subject.status === "public" ||
                              subject.status === "private" ||
                              subject.status === "protected") && (
                              <p
                                style={{
                                  color: "brown",
                                  fontSize: "10px",
                                }}
                              >
                                <MdOutlineGroups
                                  style={{
                                    width: "17px",
                                    height: "auto",
                                    paddingBottom: "5px",
                                    marginRight: "5px",
                                  }}
                                />
                                {subject.membersCount} {t("Members")}{" "}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    {this.props.groupBasedMembers?.length === 0 &&
                      !this.props.groupsSeeMore && (
                        <p
                          style={{
                            color: "#000000",
                            padding: "8px",
                          }}
                        >
                          {t("No community available")}
                        </p>
                      )}
                  </div>
                </InfiniteScroll>
                {/* )} */}
              </BlockUi>
            </div>
            {/* </div> */}
          </div>
        </Grid>
      </Grid>
    );
  }
  gotogroupDescription(id, name, status, join) {
    var gName = name.trim();
    gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
    if (status == "challenges") {
      this.props.history.push(
        `/${this.props.workspaceName}/challengeDescription/${id}/${gName}`
      );
      this.props.getNavigationType(2);
    } else if (status == "resource") {
      this.props.history.push(
        `/${this.props.workspaceName}/learningAppDesription/${id}/${gName}`
      );
      this.props.getNavigationType(2);
    } else if (
      status == "public" ||
      status == "private" ||
      status == "protected"
    ) {
      if (join) {
        this.props.history.push(
          `/${this.props.workspaceName}/myGroupDescription/${id}/${gName}`
        );
      } else {
        this.props.history.push(
          `/${this.props.workspaceName}/groupDescription/${id}/${gName}`
        );
      }
    }
  }
  surveyListState(index) {
    var survey = this.props.surveyList;
    survey.splice(index, 1);
    Gobalsurvey = survey;
    if (survey.length == 0) {
      this.setState({
        showList: false,
      });
    } else {
      this.setState({
        showList: true,
      });
    }
    this.props.CloseSurveyList(survey);
  }

  popUpSurveyBox() {
    return <div></div>;
  }

  initfunction() {
    // this.getallHobbies();
    // this.getallGrade();
  }

  openAnnoucmentScreen() {
    this.setState({ showAnnoucment: true });
  }

  closeAnnoucmentScreen() {
    this.setState({ showAnnoucment: false });
  }

  getallHobbies() {
    ProfileService.getallHobbies()
      .then((response) => {
        var result = response.data;
        AuthService.storeHobbiesList(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        AuthService.storeGradeList(result.data.grade);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFriendsList() {
    let getFriendsList = {};
    FriendsFinder.getFriendsList(getFriendsList)
      .then((response) => {
        var result = response.data;
        AuthService.storeFriendsList(result.data.friendsList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getallNotifications() {
    let getNotification = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    ProfileService.getallNotifications(getNotification)
      .then((response) => {
        var result = response.data;

        this.setState({
          notificationData: result.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFirstCall(data) {
    // for (let i = 0; i < data.length; i++) {
    //   if (data[i].notification.notificationType === 3) {
    //     var obj = {
    //       receivers: data[i].receivers,
    //       startDate: data[i].startDate,
    //     };
    //     this.yuukkeFriend(obj);
    //   }
    // }
  }

  yuukkeFriend(remainderListDatas) {
    const receiver_List = [];
    //var text = "";
    for (let i = 0; i < remainderListDatas.receivers.length; i++) {
      if (remainderListDatas.receivers[i] != this.props.loginDetails.uId) {
        for (let j = 0; j < this.props.FriendsList.length; j++) {
          if (
            remainderListDatas.receivers[i] ===
            this.props.FriendsList[j].user.userProfile.userId
          ) {
            var firstName =
              this.props.FriendsList[j].user.userProfile.firstName;
            var lastName = this.props.FriendsList[j].user.userProfile.lastName;
            var friendsName = firstName + " " + lastName;
            var friendPhoto = this.props.FriendsList[j].userPhoto;
            receiver_List.push({
              name: friendsName,
              imgUrl: friendPhoto,
            });
          }
        }
      }

      this.getCallUsername(receiver_List);
    }
  }

  getCallUsername(receiver_List) {
    var textarray = [];
    for (let i = 0; i < receiver_List.length; i++) {
      textarray.push(receiver_List[i].name);
    }
    var namelist = textarray.join(",");

    this.setState({ callUserName: namelist });
  }

  // openAnnoucmentScreen(id, title, image, strippedHtml) {
  //   alert(strippedHtml);
  //   this.setState({
  //     id: id,
  //     title: title,
  //     image: image,
  //     strippedHtml: strippedHtml,
  //     showAnnoucment: true,
  //   });
  // }

  getAnnouncementonlyOpen() {
    var langs = {
      lang: this.props.personalLanguage,
    };
    PostService.getAnnouncementonlyOpen(langs)
      .then((response) => {
        var result = response.data;

        this.setState({ announcementData: result.data.announcement });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAllBadges() {
    ProfileService.getAllBadges()
      .then((response) => {
        var result = response.data;
        AuthService.storeBadgesList(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  showSurveyBox() {
    this.props.getAllSurveyList(this.props.personalLanguage);
    this.setState({ surveyBoxState: true });
  }

  LoadAnnoncementFeebackList() {
    var form = {};
    PostService.getAnnouncementFeedbackList(form)
      .then((response) => {
        var result = response.data;
        if (result.data) {
          this.setState({ FeedbackList: result.data.feedbackList });
        } else {
          this.setState({ FeedbackList: [] });
        }
        if (this.props.announcementId) {
          var val = this.state.announcementData.filter(
            (data) => data.id == this.props.announcementId
          );
          if (val[0]) {
            this.openAnnoucmentScreen(
              val[0].id,
              val[0].title,
              val[0].image,
              val[0].strippedHtml
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  UpdateFeedbackList(feedbackrow) {
    var fb = this.state.FeedbackList.filter(
      (data) => data.announcementId !== feedbackrow.announcementId
    );
    fb.push(feedbackrow);

    this.setState({
      FeedbackList: fb,
    });
  }

  getKnowledgePoints() {
    let getKnowledgePoints = {
      userId: this.props.loginDetails.uId,
    };
    ProfileService.getKnowledgePointByUser(getKnowledgePoints)
      .then((response) => {
        var result = response.data;

        if (result.data === null) {
          this.setState({ userKnowledgepoint: 0 });
        } else {
          this.setState({ userKnowledgepoint: result.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  choosedValueData(choosedValue) {
    this.setState({ toggleValue: "groups" });
    if (choosedValue === "groups") {
      //  alert("g")
      this.setState({ trendingGroup: true });
      this.setState({ trendingEvent: false });
    } else if (choosedValue === "events") {
      this.setState({ trendingGroup: false });
      this.setState({ trendingEvent: true });
      //   alert("e")
    }
  }
  getPopularTopics() {
    const sortedActivities = this.props.tagDatas.sort(
      (a, b) => b.membersCount - a.membersCount
    );
    this.setState({ tagDatas: sortedActivities });
  }
  gotomyprofile() {
    this.props.history.push("/my-profile");
  }

  getallSubjectGroupsByMembersCount() {
    this.props.getallSubjectGroupsByMembersCount(this.state.trendingPage);
    this.setState({
      trendingPage: this.state.trendingPage + 1,
    });

    //   });
  }

  async groupList(result) {
    // this.setState({ groupBasedMembers: result })
    //  alert(this.state.groupBasedMembers.length)
  }
}

const mapStateToProps = function (state) {
  console.log("PROFILE_LANG_VALUE_SURVEY");
  console.log(state.surveyList.list);
  return {
    badgesList: state.badgeslist.badgeslist,
    surveyList: state.surveyList.list,
    notificationList: state.notification.list,
    notificationCount: state.notification.list.length,
    recentlyAddedBadgesList: state.badgeslist.list,
    friendsOnlineList: state.notification.friendsOnlinelist,
    personalLanguage: state.profileValue.lang,
    subjectsList: state.commonApi.subjectslist,
    languageList: state.commonApi.languagelist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
    groupBasedMembers: state.subjects.groupsBasedMembers
      ? state.subjects.groupsBasedMembers
      : [],
    groupsSeeMore: state.subjects.groupsSeeMore,
    trendingGroupLoader: state.subjects.trendingGroupLoader,
    eventsData: state.subjects.list2,
    workspaceType: state.loginDetailsApi.loginDetails?.userWorkSpace,
    userWorkSpaceList: state.loginDetailsApi.loginDetails?.userWorkSpaceList,
    wrongUrlStatus: state.commonApi.wrongUrlStatus,
    unAuthUrl: state.commonApi.pathUrl,
    workspaceName: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    announcementList: state.commonApi.announcementList,
    treadingUser: state.subjects.treadingUsers,
    // Loading: state.courses.loading,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    storeLoginDetails: (loginDetails, type) =>
      dispatch(storeLoginDetails(loginDetails, type)),
    getUserWorkSpaceList: () => dispatch(getUserWorkSpaceList()),
    getAllBadgesList: () => dispatch(getAllBadgesList()),
    getRecentlyAddedBadges: () => dispatch(getRecentlyAddedBadges()),
    getAllSurveyList: (personalLanguage) =>
      dispatch(getAllSurveyList(personalLanguage)),
    CloseSurveyList: () => dispatch(CloseSurveyList(Gobalsurvey)),
    getNotificationList: (notificationhit) =>
      dispatch(getNotificationList(notificationhit)),
    removeNotificationListById: (notificationId, notificationList) =>
      dispatch(removeNotificationListById(notificationId, notificationList)),
    getallSubjectGroupsByMembersCount: (page) =>
      dispatch(getallSubjectGroupsByMembersCount(page)),
    getNavigationType: (type) => dispatch(getNavigationType(type)),
    getAnnouncementList: () => dispatch(getAnnouncementList()),
    getTreadingUsers: () => dispatch(getTreadingUsers()),
    makeActionInDynamicForm: (data) => dispatch(makeActionInDynamicForm(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(Home)));
