import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import { BiShoppingBag } from "react-icons/bi";
import { FaRegShareSquare } from "react-icons/fa";
import dummyimage from "../img/default_avatar.jpg";
import PostService from "../api/PostService";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { getProductContent, metaTags } from "../utils";

const MarketPlaceCard = (props) => {
  const loginDetails = useSelector(
    (state) => state.loginDetailsApi.loginDetails
  );

  const [state, setState] = useState({
    products: [],
    loading: false,
    activeIndex: null,
    popUp: false,
    description: "",
    postDetails: {},
  });

  const updateState = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };
  useEffect(() => {
    let data = {
      userId: props?.userId ? props.userId : loginDetails.uId,
      // email: "ranis@mailinator.com",
    };
    updateState("loading", true);

    PostService.getAllProducts(data)
      .then((response) => {
        console.log("Response", response);
        updateState("loading", false);
        updateState("products", response?.data?.data ? response.data.data : []);
      })
      .catch((e) => {
        updateState("loading", false);
        updateState("products", []);
      });
  }, []);
  const sharePost = () => {
    let data = {
      productDetails: state.postDetails,
      description: state.description,
    };

    PostService.productPost(data)
      .then((response) => {
        console.log("Response", response);
        Swal.fire({
          title: `Post created successfully`,
          icon: "success",
          type: "success",
          showConfirmButton: true,
        }).then((result) => {});
      })
      .catch((e) => {
        toast.error("Something went wrong , please try after sometime");
        console.log(e);
      });

    setState({
      ...state,
      description: "",
      postDetails: {},
      popUp: false,
    });
  };

  const sharePostComment = () => {
    return (
      <div>
        <Modal
          show="true"
          onHide={() =>
            setState({
              ...state,
              popUp: false,
              activeIndex: null,
              postDetails: {},
              description: "",
            })
          }
        >
          <Modal.Header>
            PRODUCT
            <AiOutlineCloseCircle
              onClick={() =>
                setState({
                  ...state,
                  popUp: false,
                  activeIndex: null,
                  postDetails: {},
                  description: "",
                })
              }
              style={{
                cursor: "pointer",
                float: "right",
                marginBottom: "8px",
                width: "20px",
                height: "20px",
                color: "#000000",
              }}
            />
          </Modal.Header>
          <Modal.Body className="r-model">
            <div
              className="d-flex"
              style={{ textAlign: "center", gap: "1rem" }}
            >
              <div className="img-chip">
                <img
                  src={state.postDetails?.p_image}
                  alt=""
                  style={{ height: "64px" }}
                  className="w-auto"
                />
                <p className="mt-4">{state.postDetails?.p_name}</p>
              </div>
              <textarea
                placeholder="Add your comments here ..."
                style={{
                  width: "100%",
                  padding: "1rem",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  backgroundColor: "#fafafa",
                }}
                rows="4"
                cols="50"
                value={state.description}
                onChange={(e) => updateState("description", e.target.value)}
              ></textarea>
            </div>
            <div className="text-center">
              <button className="mt-4" onClick={() => sharePost()}>
                <FaRegShareSquare className="pay-svg" />
                Share as home post
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ background: "ghostwhite" }}>
            <div className="social-icons-share">
              Share to your friend by using these
              <ul className="social-icons-share">
                <li>
                  <WhatsappShareButton
                    url={`${state.postDetails?.details_page}`}
                    title={getProductContent(state.postDetails)}
                    separator="  "
                    metaTags={metaTags}
                  >
                    <WhatsappIcon
                      size={52}
                      round
                      style={{ background: "ghostwhite" }}
                    />
                  </WhatsappShareButton>
                  Whatsapp
                </li>
                <li>
                  <TelegramShareButton
                    url={`${state.postDetails?.details_page}`}
                    title={getProductContent(state.postDetails)}
                    separator="  "
                    metaTags={metaTags}
                  >
                    <TelegramIcon
                      size={52}
                      round
                      style={{ background: "ghostwhite" }}
                    />
                  </TelegramShareButton>
                  Telegram
                </li>
                <li>
                  <LinkedinShareButton
                    url={`${state.postDetails?.details_page}`}
                    title={getProductContent(state.postDetails)}
                    separator="  "
                    metaTags={metaTags}
                  >
                    <LinkedinIcon size={52} round />
                  </LinkedinShareButton>
                  Linkedin
                </li>
                <li>
                  <FacebookShareButton
                    url={`${state.postDetails?.details_page}`}
                    title={getProductContent(state.postDetails)}
                    separator="  "
                    metaTags={metaTags}
                  >
                    <FacebookIcon
                      size={52}
                      round
                      style={{ background: "ghostwhite" }}
                    />
                  </FacebookShareButton>
                  FaceBook
                </li>
                <li>
                  <TwitterShareButton
                    url={`${state.postDetails?.details_page}`}
                    title={getProductContent(state.postDetails)}
                    separator="  "
                    metaTags={metaTags}
                  >
                    <TwitterIcon
                      size={52}
                      round
                      style={{ background: "ghostwhite" }}
                    />
                  </TwitterShareButton>
                  Twitter
                </li>
              </ul>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "150px" }}
      className="wallLoadingPage"
      blocking={state.loading}
    >
      <div className="market-grid">
        {!state.loading &&
          Array.isArray(state.products) &&
          state.products.map((item, index) => (
            <div class="market-card-container position-relative" key={index}>
              <div class="position-relative">
                <a
                  class="hero-image-container"
                  onClick={() => window.open(item?.details_page, "_blank")}
                >
                  <img
                    class="hero-image"
                    src={item.p_image}
                    alt="Spinning glass cube"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = dummyimage;
                    }}
                  />
                </a>
              </div>
              <h6 className="mt-4">{item?.p_name}</h6>
              <p className="mt-1 text-secondary" style={{ fontSize: "14px" }}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: item?.product_details,
                  }}
                  className="forum-ans"
                />
              </p>

              <div
                className="d-flex align-items-center justify-content-between mt-4 mb-4 flex-wrap"
                style={{ gap: "1rem" }}
              >
                <p className="text-secondary font-bold">₹{item?.price}</p>
                {loginDetails.email === item?.s_email ? (
                  <span
                    className="m-button"
                    onClick={() =>
                      setState({
                        ...state,
                        postDetails: item,
                        activeIndex: index,
                        popUp: true,
                      })
                    }
                    //  sharePost(item, index)}
                    style={
                      {
                        // cursor:
                        //   state.activeIndex === index ? "not-allowed" : "pointer",
                        // pointerEvents:
                        //   state.activeIndex === index ? "none" : "auto",
                      }
                    }
                  >
                    {" "}
                    <FaRegShareSquare className="pay-svg" />
                    Share
                  </span>
                ) : (
                  <button
                    className="pay-button"
                    onClick={() => window.open(item?.details_page, "_blank")}
                  >
                    <BiShoppingBag />
                    Buy
                  </button>
                )}
              </div>
            </div>
          ))}
        {state.popUp && sharePostComment()}
        {!state.loading && state.products && state.products?.length === 0 && (
          <div className="no-data">
            <p style={{ textAlign: "center" }}>No products available</p>
          </div>
        )}
      </div>
    </BlockUi>
  );
};

export default memo(MarketPlaceCard);
