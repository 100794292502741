import {
  GET_ALL_SURVEY_LIST,
  GET_PIE_CHART_LIST,
  GET_ADVERTISEMENT_DATA,
  GET_CHAT_LIST_DATA,
  GET_USER_COMMENTS,
  GET_SINGLE_POST_DATA,
  SINGLE_POST_LOADER,
} from "../types";
import { CLOSE_SURVEY_LIST } from "../types";
import { GET_LOGOUT_DATA } from "../types";
const initialState = {
  list: [],
  pieChartData: [],
  advertisementData: {},
  chatListData: [],
  userCommentList: [],
  singlePost: {},
  singlePostLoader: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SURVEY_LIST:
      console.log("GET_ALL_SURVEY_LIST");
      console.log(action.payload);
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case CLOSE_SURVEY_LIST:
      console.log("CLOSE_SURVEY_LIST");
      console.log(action.payload);
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");
      console.log(action.payload);
      return {
        ...initialState,
      };
    case GET_PIE_CHART_LIST:
      console.log("GET_PIE_CHART_LIST");
      console.log(action.payload);
      return {
        ...state,
        pieChartData: action.payload,
        loading: false,
      };
    case GET_ADVERTISEMENT_DATA:
      console.log("GET_ADVERTISEMENT_DATA");
      console.log(action.payload);
      return {
        ...state,
        advertisementData: action.payload,
        loading: false,
      };
    case GET_CHAT_LIST_DATA:
      console.log("GET_CHAT_LIST_DATA");
      console.log(action.payload);
      return {
        ...state,
        chatListData: action.payload,
        loading: false,
      };
    // case GET_USER_COMMENTS:
    //   console.log("GET_USER_COMMENTS");
    //   console.log(action.payload);
    //   return {
    //     ...state,
    //     userCommentList: action.payload,
    //     loading: false,
    //   };
    case SINGLE_POST_LOADER:
      console.log("SINGLE_POST_LOADER");
      return {
        ...state,
        singlePostLoader: action.payload,
      };
    case GET_SINGLE_POST_DATA:
      console.log("GET_SINGLE_POST_DATA");
      console.log(action.payload);
      return {
        ...state,
        singlePost: action.payload,
        singlePostLoader: false,
      };

    default:
      return state;
  }
}
