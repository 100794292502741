import React, { useMemo } from "react";
import dummy_image from "../../assets/img/default_avatar.jpg";
import moment from "moment";
import { API_URL } from "../../../../config/app-config";

function MessageList({
  handleChatList,
  friend,
  unseenMessageCount,
  lastMessage,
  initialLastMessage,
  isCurrentFriendAvailiable,
  currentFriend,
  userId,
}) {
  let total =
    unseenMessageCount.length > 0 &&
    unseenMessageCount.filter(
      (totalfriend) => +totalfriend.senderId === friend?.userId
    );

  // console.log("Chat Last Message : total", total);
  // console.log("Chat Last Message : initial", lastMessage);

  // const filteredLastMessage =
  //   lastMessage && Array.isArray(lastMessage) && lastMessage.length > 0
  //     ? lastMessage
  //         .filter((message) => message !== null)
  //         .filter(
  //           (message) =>
  //             +message.senderId === friend?.userId ||
  //             +message.receiverId === friend?.userId
  //         )
  //     : initialLastMessage
  //         .filter((message) => message !== null)
  //         .filter(
  //           (message) =>
  //             +message.senderId === friend?.userId ||
  //             +message.receiverId === friend?.userId
  //         );
  const getList = (lastMessage) => {
    const data =
      lastMessage && Array.isArray(lastMessage) && lastMessage.length > 0
        ? lastMessage
            .filter((message) => message !== null)
            .filter(
              (message) =>
                +message.senderId === friend?.userId ||
                +message.receiverId === friend?.userId
            )
        : initialLastMessage
            .filter((message) => message !== null)
            .filter(
              (message) =>
                +message.senderId === friend?.userId ||
                +message.receiverId === friend?.userId
            );
    return data;
  };
  const filteredLastMessage = useMemo(
    () => getList(lastMessage),
    [lastMessage]
  );

  // console.log("Chat Last Message : final", filteredLastMessage);
  // console.log("Chat Last Message : final", filteredLastMessage[0]?.unseenCount);

  return (
    <>
      <div
        className="conversation-list-item position-relative"
        onClick={() => handleChatList(friend, filteredLastMessage[0])}
        id={friend?.userId}
      >
        <img
          src={`${API_URL}auth/image/${friend.profilePhoto}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = dummy_image;
          }}
          alt="avatar"
          className="conversation-user-avatar"
        />

        <div className="conversation-user-name-details">
          {filteredLastMessage && filteredLastMessage.length > 0 ? (
            <>
              <div className="conversation-user-name-container">
                <h3 className="conversation-user-name">
                  {friend?.userName}

                  {/* {total.length > 0 ? (
                    <span style={{ marginLeft: "8px", color: "green" }}>
                      {filteredLastMessage[0].status === "unseen" &&
                      isCurrentFriendAvailiable?.userId !== friend.userId ? (
                        <>( {total.length} )</>
                      ) : (
                        ""
                      )}
                    </span>
                  ) : (
                    <></>
                  )
                  } */}
                </h3>
                {filteredLastMessage[0]?.unseenCount > 0 && (
                  <span className="unseen-count">
                    {filteredLastMessage[0]?.unseenCount}
                  </span>
                )}
                <span>
                  {moment(filteredLastMessage[0].created_at)
                    .locale("en")
                    .startOf("mini")
                    .fromNow()}
                </span>
              </div>
              <p
                className="conversation-last-message"
                style={{ color: "#898989", fontSize: "13px" }}
              >
                <span>{filteredLastMessage[0]?.senderName}:</span>
                <span>
                  {filteredLastMessage[0].message.text !== ""
                    ? filteredLastMessage[0].message.text
                    : filteredLastMessage[0]?.message?.image.length > 0
                    ? "Sent a file"
                    : filteredLastMessage[0]?.message?.meetingLink !== ""
                    ? filteredLastMessage[0]?.message?.isMeetingfinished ===
                      true
                      ? "Meeting Finished"
                      : "Scheduled a Meeting"
                    : ""}
                </span>
                {/* {+filteredLastMessage[0]?.unseenCount > 0 && */}
                {userId === +filteredLastMessage[0]?.senderId && (
                  <div className="mr-2">
                    <div
                      className={`${
                        filteredLastMessage[0]?.status === "seen"
                          ? "message-seen"
                          : "message-deliver"
                      } message-check position-relative bg-white`}
                      style={{ index: 1 }}
                    ></div>
                    <div
                      className={`${
                        filteredLastMessage[0]?.status === "seen"
                          ? "message-seen"
                          : filteredLastMessage[0]?.status === "sent"
                          ? "d-none"
                          : "message-deliver"
                      } message-check`}
                    ></div>
                  </div>
                )}
              </p>
            </>
          ) : (
            <div className="conversation-user-name-details">
              <div className="conversation-user-name-container">
                <h3 className="conversation-user-name">{friend?.userName}</h3>
              </div>
              <p
                className="conversation-last-message"
                style={{ color: "#898989", fontSize: "13px" }}
              >
                start a conversation
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MessageList;
