import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { Chip } from "@material-ui/core";
import ProfileService from "../../api/ProfileService";
import { toast } from "react-toastify";
import AuthService from "../../session/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { getLogoutData } from "../../redux/actions/logoutActions";
import StepperFileOption from "../Wizard/StepperFileOption";
import { API_URL } from "../../config/app-config";
import { MdOutlineAttachFile } from "react-icons/md";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import PhoneInput from "react-phone-input-2";
import { getCategoryListV2 } from "../../redux/actions/calendarAction";
import Select from "react-select";
import { getAllCommonLanguageList } from "../../redux/actions/commonApiActions";
import OtpInput from "../Login/LoginV2/OtpInput";
import BlockUi from "react-block-ui";
import { decryptData } from "../../utils";
import Swal from "sweetalert2";

const ServiceDirectory = (props) => {
  const [state, setState] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    businessName: "",
    about: "",
    serviceImage: null,
    category: [],
    languageList: [],
    otpScreen: false,
    try: 0,
    otp: "",
    otpId: "",
    successScreen: false,
    directoryId: null,
    filterCategoryList: [],
    loader: false,
  });
  const dispatch = useDispatch();
  const { categoryList } = useSelector((state) => state.event);
  const { languagelist } = useSelector((state) => state.commonApi);

  useEffect(() => {
    console.log("Params", props.match?.params);
    const user = props.match?.params?.id;
    if (user) {
      console.log("User come from encrypt data");
      const encrypt = decryptData(user);
      if (encrypt) {
        let getId = encrypt.replace("$", "");
        // alert(getId);
        let number = {
          directoryId: getId,
        };
        ProfileService.getSingleUnAuthDirectory(number)
          .then((response) => {
            let result = response.data.data;
            console.log("response", result);
            if (result) {
              setState({
                ...state,
                userName: result.name ?? "",
                email: result.email ?? "",
                businessName: result.businessName ?? "",
                about: result.description
                  ? result.description.replace(/<[^>]*>/g, "").trim()
                  : "",
                phoneNumber: result.phoneNumber ?? "",
                directoryId: getId,
              });
              Swal.fire({
                title: "We’re Almost There!",
                text: "Thank you for filling out most of the required information. To ensure we provide the best service and tailor our offerings to your needs, we need just a little more detail from you.Please take a moment to complete the fields below. Your input helps us finalize your profile and offer you the best experience possible!",
                showCancelButton: false,
                confirmButtonText: "Ok",
                reverseButtons: true,
              });
            }
          })
          .catch((e) => {
            console.log("e", e);
            toast.error("Something went wrong");
            updateState("loader", false);
          });
      } else {
        console.log("Invalid  url");
      }
    }
    if (categoryList.length === 0) {
      dispatch(getCategoryListV2());
    }
    if (languagelist.length === 0) {
      dispatch(getAllCommonLanguageList());
    }
  }, []);
  useEffect(() => {
    if (categoryList) {
      const list = categoryList.map((item) => {
        return { ...item, select: false };
      });
      updateState("filterCategoryList", list);
    }
  }, [categoryList]);
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const sendOtp = (e) => {
    e.preventDefault();

    // if (!state.serviceImage) {
    //   toast.warning("Add your business image");
    // }

    const checkCategory = state.filterCategoryList.every((c) => !c.select);
    if (checkCategory) {
      toast.warning("Select your service related category");
    } else if (state.try === 4) {
      toast.warning("You tried maximum time ,please try latter !");
    } else if (state.phoneNumber.length < 6) {
      toast.warning("Enter valid phone number");
    } else {
      updateState("loader", true);
      // updateDetails();
      let number = {
        type: "phone",
        phoneNumber: state.phoneNumber,
        optModelName: "Service",
      };
      ProfileService.sendOtp(number)
        .then((response) => {
          if (response.data.data?.id) {
            setState({
              ...state,
              otpId: response.data.data.id,
              loader: false,
              otpScreen: true,
              try: state.try + 1,
            });
          }
        })
        .catch((e) => {
          console.log("e", e);
          toast.error("Something went wrong");
          updateState("loader", false);
        });
    }
  };
  const verifyOtp = () => {
    if (state.otp.length !== 6) {
      toast.warning("Enter valid otp");
      return;
    }
    updateState("loader", true);

    let number = {
      otp: state.otp,
      id: state.otpId,
    };
    ProfileService.validateUserOtp(number)
      .then((response) => {
        if (response.data.data === "VALID_OTP") {
          updateDetails();
        } else {
          toast.error("Wrong otp");
          updateState("loader", false);
        }
      })
      .catch((e) => {
        toast.error("Something went wrong");
        updateState("loader", false);
      });
  };

  const updateDetails = () => {
    const formData = new FormData();
    formData.append("firstName", state.userName.trim());
    formData.append("lastName", state.userName.trim().substring(0, 1));
    formData.append("email", state.email);
    formData.append("phoneNumber", state.phoneNumber);

    formData.append(
      "serviceName",
      state.businessName ? state.businessName : state.userName.trim()
    );
    formData.append("description", state.about);
    const lang = state.languageList.map((item) => {
      return { language: item.label, id: item.value };
    });

    formData.append("language", JSON.stringify({ data: lang }));
    const selectedList = state.filterCategoryList.filter((item) => item.select);
    const cate = selectedList.map((item) => {
      return { primaryId: item.pId, categoryId: item.value };
    });

    formData.append("categoryV2", JSON.stringify({ data: cate }));

    formData.append("categoryId", selectedList[0]?.value);
    formData.append("categoryName", selectedList[0]?.label);

    if (state.serviceImage) {
      formData.append("businessLogov2", state.serviceImage[0]);
    }
    if (state.directoryId) {
      formData.append("directoryId", state.directoryId);
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    ProfileService.directoryAndServiceRegister(formData, config)
      .then((response) => {
        if (response.data.status !== 1) {
          toast.error("Something went wrong , please try again later");
          setState({
            ...state,
            otpId: null,
            loader: false,
            otp: "",
            otpScreen: false,
          });
        } else {
          setState({
            ...state,
            otpId: null,
            loader: false,
            otp: "",
            otpScreen: false,
            successScreen: true,
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong , please try again later");
        setState({
          ...state,
          otpId: null,
          loader: false,
          otp: "",
          otpScreen: false,
        });
      });
  };

  const serviceImageUpdate = (file) => {
    if (file) {
      updateState("serviceImage", file);
    }
  };
  const removeFiles = (name) => {
    updateState(name, null);
  };
  const loginYuukke = () => {
    AuthService.removeAuth();
    dispatch(getLogoutData());
    AuthService.triggerLogout();
    localStorage.setItem("SERVICE_REGISTER", "yes");
  };
  const makeCategoryChanges = (data) => {
    const list = state.filterCategoryList.map((item) => {
      if (item.value === data.value) {
        item.select = !data.select;
      }
      return item;
    });
    console.log("list", list);
    updateState("filterCategoryList", list);
  };
  console.log("state", state);
  return (
    <Modal
      show="true"
      onHide={() => {}}
      style={{
        background: "#000940",
      }}
    >
      <BlockUi
        tag="div"
        message="Loading, please wait"
        style={{ minHeight: "134px" }}
        className="wallLoadingPage"
        blocking={state.loader}
      >
        {state.successScreen ? (
          <>
            <Card className="profileCard align-items-center p-3">
              <img
                onClick={() => loginYuukke()}
                src="https://corporate.yuukke.com/bcav2/auth/image/7cdb6739-68d5-407b-ac71-4eccc422685c"
                alt=""
                className="w-100 h-100 c-pointer"
              />
              {/* Service created successfully ,now you can visit your service for
              <b>Yuukke</b> app using this phone number{" "}
              <b>{state.phoneNumber}</b> or given email.
              <button className="mt-3" onClick={() => loginYuukke()}>
                <IoLogInOutline className="mr-2 svg-icons  text-white" />
                Login Yuukke
              </button> */}
            </Card>{" "}
          </>
        ) : state.otpScreen ? (
          <>
            <Modal.Header className="justify-content-center align-items-center bg-white flex-column">
              Otp send on your give mobile number, <b>{state.phoneNumber}</b>
            </Modal.Header>
            <Card className="profileCard align-items-center">
              <p
                style={{ fontSize: "12px" }}
                className="text-secondary mt-3 text-center"
              >
                Please enter your otp !
              </p>
              <div className="p-3 d-flex g-1 align-items-center justify-content-center service-otp">
                <OtpInput
                  length={6}
                  onOtpSubmit={(otp) => updateState("otp", otp)}
                />
              </div>
              <button
                className="mt-3 mb-3"
                onClick={() => verifyOtp()}
                style={{ width: "fit-content" }}
              >
                Verify
              </button>
            </Card>
          </>
        ) : (
          <>
            <Modal.Header className="justify-content-center align-items-center bg-white flex-column">
              <img
                src="https://corporate.yuukke.com/bcav2/auth/image/3bc8b6fd-eca4-4aa3-b89b-feb8cbe3efa3"
                alt="img"
                style={{ width: "26rem" }}
                className="h-auto"
              />
            </Modal.Header>
            <Card className="profileCard">
              <p
                style={{ fontSize: "12px" }}
                className="text-secondary mt-3 text-center"
              >
                Let us start with few details to get service registered with
                Yuukke
              </p>
              <Card.Body className="profile-form">
                <div className="formFieldGroup p-3">
                  <form onSubmit={sendOtp}>
                    <label className="mt-2 form-label">Name *</label>
                    <input
                      className="text-input-v2"
                      variant="outlined"
                      type="text"
                      value={state.userName}
                      onChange={(e) => {
                        updateState("userName", e.target.value);
                      }}
                      required
                    />

                    <label className="mt-3 form-label">Email *</label>
                    <input
                      className="text-input-v2"
                      type="email"
                      value={state.email}
                      onChange={(e) => {
                        updateState("email", e.target.value);
                      }}
                      fullWidth={true}
                      required
                    />

                    <label className="mt-3 form-label">Phone Number *</label>
                    <PhoneInput
                      country={"in"}
                      containerClass="input-dark"
                      phoneNumber={state.phoneNumber}
                      onChange={(phone) => updateState("phoneNumber", phone)}
                      required={true}
                    />

                    <label className="mt-3 form-label">Service Name</label>
                    <input
                      className="text-input-v2"
                      type="text"
                      value={state.businessName}
                      onChange={(e) => {
                        updateState("businessName", e.target.value);
                      }}
                    />
                    <label className="mt-3 form-label">Can Speak *</label>
                    <Select
                      value={state.languageList}
                      options={languagelist}
                      onChange={(e) => updateState("languageList", e)}
                      required
                      isMulti
                      className="z-1001"
                    />
                    <label className="mt-3 form-label">Category*</label>
                    <div className="d-flex flex-wrap gap-2 justify-content-center align-items-center">
                      {state.filterCategoryList.map((data) => (
                        <Chip
                          style={{
                            // overflow: "hidden",
                            // Whitespace: "nowrap",
                            fontSize: "12px",
                            // textOverflow: "ellipsis",
                            textTransform: "capitalize",
                            backgroundColor: data.select
                              ? "#a00030"
                              : "#e0e0e0",
                            color: data.select ? "white" : "black",
                            fontWeight: data.select ? "bold" : "500",
                          }}
                          label={data.label}
                          onClick={() => makeCategoryChanges(data)}
                        />
                      ))}
                    </div>
                    {/* <Select
                      options={categoryList}
                      value={state.category}
                      onChange={(e) => updateState("category", e)}
                      required
                      isMulti
                      className="z-1000"
                    /> */}
                    <label className="mt-3 form-label">
                      About Service Details *
                    </label>
                    <textarea
                      className="text-area"
                      style={{
                        border: "1px solid gainsboro",
                      }}
                      required
                      value={state.about}
                      type="text"
                      onChange={(e) => {
                        updateState("about", e.target.value);
                      }}
                    />
                    <label className="mt-3 form-label">Service logo</label>

                    {!state.serviceImage && (
                      <StepperFileOption
                        onUploadSuccess={serviceImageUpdate}
                        acceptedType=".jpg,.jpeg,.png,.svg"
                        count={1}
                        multiple={false}
                      />
                    )}
                    {state.serviceImage &&
                      state.serviceImage.length !== 0 &&
                      state.serviceImage.map((image, index) => (
                        <ShowImageFile
                          files={image}
                          removeFiles={removeFiles}
                          keyFile="serviceImage"
                          index={index}
                          count={1}
                        />
                      ))}
                    <div className="pl-2 pr-2">
                      <button
                        className="w-100 mt-4"
                        type="submit"
                        value="Submit"
                        // onClick={(e) => updateDetails(e)}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </>
        )}
      </BlockUi>
    </Modal>
  );
};

const ShowImageFile = (props) => {
  const file = props?.files;
  const name = file?.name ? file.name : "image";
  return (
    <div key={props.index}>
      <Chip
        icon={<MdOutlineAttachFile />}
        className="fileChip"
        label={name.substring(0, 12)}
        size="small"
        onDelete={() => props.removeFiles(props?.keyFile, props?.index)}
        variant="outlined"
      />

      {file && (
        <img
          style={{
            margin: "8px",
            width: "auto",
            height: "80px",
          }}
          src={
            file?.name
              ? URL.createObjectURL(file)
              : `${API_URL}auth/image/${file}`
          }
          alt="img"
        ></img>
      )}
    </div>
  );
};

export default withTranslation()(withRouter(ServiceDirectory));
