import ForumService from "../../api/ForumService";
import Utils from "../../utils/Utils";
import { GET_MYLIBRARY_QUESTION_LIST } from "../types";
import { GET_MYUPDATEDLIBRARY_QUESTION_LIST } from "../types";
import { MYLIBRARY_QUESTION_LOADER } from "../types";
import moment from "moment";
// import FriendsFinder from "../../api/FriendsFinder";
// import AuthService from "../../session/AuthService";

export const getMyLibraryQuestionList =
  (typeQuestion, pageNo, userId) => async (dispatch) => {
    try {
      dispatch(MyLibraryQuestionListLoader(true));
      let interestingTop = {
        type: typeQuestion,
        page: pageNo - 1,
      };

      ForumService.getMyLibraryQuestions(interestingTop)
        .then((response) => {
          var result = response.data;
          console.log(result);
          console.log(result.data.questions.content.length);

          if (result.data.questions.content.length >= 1) {
            var libraryList = [];
            const question_list = [];
            var userlist = [];
            console.log("LIBRARY QUESTION DATA");

            for (let j = 0; j < result.data.questions.content.length; j++) {
              var createdDate =
                result.data.questions.content[j].question.createdDate;
              moment(createdDate).format("DD/MM/yyyy");
              var Dates = Utils.getLastAskedDate(createdDate);
              question_list.push({
                answer: result.data.questions.content[j].question.answer,
                answerSize:
                  result.data.questions.content[j].question.answerSize,
                createdDate:
                  result.data.questions.content[j].question.createdDate,
                questionDescription:
                  result.data.questions.content[j].question.questionDescription,
                questionId:
                  result.data.questions.content[j].question.questionId,
                questionName:
                  result.data.questions.content[j].question.questionName,
                questionTags:
                  result.data.questions.content[j].question.questionTags,
                questionviewers:
                  result.data.questions.content[j].question.questionviewers,
                questionType:
                  result.data.questions.content[j].question.questionType,
                solved: result.data.questions.content[j].question.solved,
                updatedDate:
                  result.data.questions.content[j].question.updatedDate,
                userId: result.data.questions.content[j].question.userId,
                userName: result.data.questions.content[j].question.userName,
                views: result.data.questions.content[j].question.views,
                votes: result.data.questions.content[j].question.votes,
                gradeId: result.data.questions.content[j].question.grade,
                questionFile:
                  result.data.questions.content[j].question.questionFile,
                lastAsked: Dates,
                answerGiven: result.data.questions.content[j].answerGiven,
                answerUpvote: result.data.questions.content[j].answerUpvote,
                questionAsked: result.data.questions.content[j].questionAsked,
                questionUpvote: result.data.questions.content[j].questionUpvote,
                programId: result.data.questions.content[j].question.programId,
                originalFileName:
                  result.data.questions.content[j].question.originalFileName,
                markAsPrivate:
                  result.data.questions.content[j].question.markAsPrivate,
                questionLikesArray:
                  result.data.questions.content[j].question.questionLikesArray,
                star: result.data.questions.content[
                  j
                ].question.questionLikesArray.filter(
                  (star) => star.userId == userId
                ),
                status: result.data.questions.content[j].question.status,
                answerBlockStatus:
                  result.data.questions.content[j].question.answerBlockStatus,
                aicheck: result.data.questions.content[j].question.aicheck,
                filterCheck:
                  result.data.questions.content[j].question.filterCheck,
              });
              userlist.push(result.data.questions.content[j].question.userId);
            }
            console.log(question_list);
            libraryList["libraryQuestions"] = question_list;
            libraryList["totalItems"] = result.data.totalItems;

            dispatch({
              type: GET_MYLIBRARY_QUESTION_LIST,
              payload: libraryList,
            });
          } else if (
            result.data.questions.content.length == 0 ||
            result.data.questions.length == 0
          ) {
            var libraryList = [];
            libraryList["libraryQuestions"] = [];
            libraryList["totalItems"] = 0;
            dispatch({
              type: GET_MYLIBRARY_QUESTION_LIST,
              payload: libraryList,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_MYLIBRARY_QUESTION_LIST,
        payload: [],
      });
    }
    return [];
  };

export const updateQuestionValues =
  (questionId, topQuestions, solved_total_items, userId) =>
  async (dispatch) => {
    try {
      let obj = {
        questionId: questionId,
      };

      ForumService.getLibraryQuestionById(obj)
        .then((response) => {
          var result = response.data.data;

          console.log("updateQuestionValue");
          console.log("===================");
          console.log(result);

          const question_list = [];
          var questionData = topQuestions;
          var libraryList = [];
          for (let j = 0; j < questionData.length; j++) {
            if (questionData[j].questionId == questionId) {
              var tObj = result.questions;

              var createdDate = tObj.question.createdDate;
              moment(createdDate).format("DD/MM/yyyy");
              var Dates = Utils.getLastAskedDate(createdDate);

              question_list.push({
                answer: tObj.question.answer,
                answerSize: tObj.question.answerSize,
                createdDate: tObj.question.createdDate,
                questionDescription: tObj.question.questionDescription,
                questionId: tObj.question.questionId,
                questionName: tObj.question.questionName,
                questionTags: tObj.question.questionTags,
                questionviewers: tObj.question.questionviewers,
                questionType: tObj.question.questionType,
                solved: tObj.question.solved,
                updatedDate: tObj.question.updatedDate,
                userId: tObj.question.userId,
                userName: tObj.question.userName,
                views: tObj.question.views,
                votes: tObj.question.votes,
                gradeId: tObj.question.grade,
                questionFile: tObj.question.questionFile,
                lastAsked: Dates,
                answerGiven: tObj.answerGiven,
                answerUpvote: tObj.answerUpvote,
                questionAsked: tObj.questionAsked,
                questionUpvote: tObj.questionUpvote,
                programId: tObj.question.programId,
                originalFileName: tObj.question.originalFileName,
                markAsPrivate: tObj.question.markAsPrivate,
                questionLikesArray: tObj.question.questionLikesArray,
                star: tObj.question.questionLikesArray.filter(
                  (star) => star.userId == userId
                ),
                status: tObj.status,
                answerBlockStatus: tObj.answerBlockStatus,
                aicheck: tObj.question.aicheck,
                filterCheck: tObj.question.filterCheck,
              });
              libraryList["libraryQuestions"] = question_list;
              libraryList["totalItems"] = solved_total_items;
            } else {
              question_list.push(questionData[j]);
              libraryList["libraryQuestions"] = question_list;
              libraryList["totalItems"] = solved_total_items;
            }
          }
          dispatch({
            type: GET_MYLIBRARY_QUESTION_LIST,
            payload: libraryList,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_MYUPDATEDLIBRARY_QUESTION_LIST,
        payload: [],
      });
    }
    return [];
  };
export const MyLibraryQuestionListLoader = (type) => async (dispatch) => {
  try {
    dispatch({
      type: MYLIBRARY_QUESTION_LOADER,
      payload: type,
    });
  } catch (error) {
    dispatch({
      type: MYLIBRARY_QUESTION_LOADER,
      payload: false,
    });
  }
  return false;
};
