import React, { useEffect, useState } from "react";
import MyYuukke from "../../../api/MyYuukke";
import BlockUi from "react-block-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscriptionStatus,
  getUserSlots,
} from "../../../redux/actions/calendarAction";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import CheckBox from "../../../components/CheckBox";
import ImagePreview from "../../../components/ImagePreview";
import StepperFileOption from "../../Wizard/StepperFileOption";
import { urlPatternValidation } from "../../../utils";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import TextEditorQuestion from "../../../components/TextEditorQuestion";
import { timeZone } from "../../../sampleData/Options";

function CalendarPlans({ t, onBack }) {
  const [state, setState] = useState({
    activeMenu: 0,
    type: null,
    meetingInYuukke: false,
    paymentNeed: false,
    meetingLink: "",
    bookingCount: true,
    profilePhoto: true,
    freeCalendar: true,
    meetingPrize: "",
    businessLogo: null,
    emailReminder: true,
    whatsappReminder: false,
    apiLogo: false,
    editBusinessLogo: null,
    loader: false,
    paymentPage: false,
    subscribeId: 0,
    prize: 0,
    paymentStatus: "",
    subscriptionId: null,
    serviceName: "",
    serviceImage: "",
    editServiceLogo: null,
    apiGalleryImages: [],
    removedId: [],
    selectedGalleryImage: [],
    serviceApiLogo: false,
    serviceType: [],
    packageName: "",
    successPercent: 35,
    menuState: 0,
    serviceList: [],
    otherServiceName: "",
    pinCode: "",
    removedServiceCategoryId: [],
    categoryIds: [],
    description: "",
    subCategory: [],
    subCategoryList: [],
    timeZone: [],
    language: "",
    editDescription: "",
  });

  const dispatch = useDispatch();
  const { loginDetails } = useSelector((state) => state.loginDetailsApi);
  const { categoryV2, subCategoryV2, calendarServices, userSlots } =
    useSelector((state) => state.event);
  const { languagelist } = useSelector((state) => state.commonApi);
  console.log("languagelist", languagelist);
  useEffect(() => {
    if (userSlots?.preference) {
      console.log("slotDetails", userSlots);
      let slotDetails = userSlots?.preference;
      // let categoryIds = slotDetails.categorys.map((item) => item.categoryId);
      // let serviceType = calendarSpecializationV2.filter((item) =>
      //   categoryIds.includes(item.value)
      // );
      let timeZoneData = timeZone.reduce((result, item) => {
        for (let i = 0; i < item.options.length; i++) {
          if (slotDetails.timeZone == item.options[i].value) {
            result = {
              label: item.options[i].label,
              value: item.options[i].value,
            };
            break;
          }
        }
        return result;
      }, {});
      let lan =
        slotDetails.language &&
        slotDetails.language.map((item) => {
          return { label: item.name, value: item.id };
        });
      let serviceIds = [];
      let cat = slotDetails.categorys.map((item) => {
        serviceIds.push(item.serviceId);
        return {
          label: item.categoryName,
          value: item.categoryId,
          categoryId: item.serviceId,
        };
      });
      let serviceData = [];
      if (serviceIds.length > 0) {
        serviceData = categoryV2.filter((item) =>
          serviceIds.includes(item.value)
        );
      }
      console.log("serviceIds", serviceIds);
      console.log("serviceData", serviceData);
      setState((previous) => ({
        ...previous,
        timeZone: timeZoneData ?? [],
        subCategory: cat ?? [],
        language: lan ?? [],

        // categoryIds: categoryIds ? categoryIds : [],
        serviceType: serviceData,
        meetingInYuukke:
          slotDetails.meetingInApplication === null
            ? true
            : slotDetails.meetingInApplication,
        meetingLink:
          slotDetails.meetingUrl === null ? "" : slotDetails.meetingUrl,
        bookingCount:
          slotDetails.showBookingCount === null
            ? true
            : slotDetails.showBookingCount,
        profilePhoto:
          slotDetails.showProfileImage === null
            ? true
            : slotDetails.showProfileImage,
        // freeCalendar: slotDetails.noFees === null ? "" : slotDetails.noFees,
        // meetingPrize: slotDetails.fees === null ? "" : slotDetails.fees,
        editBusinessLogo: slotDetails.businessLogo,
        apiLogo: true,
        // editServiceLogo: slotDetails.serviceImage,
        apiGalleryImages: slotDetails.serviceGalleryImages
          ? slotDetails.serviceGalleryImages
          : [],
        serviceApiLogo: true,
        serviceName: slotDetails.serviceName,
        pinCode: slotDetails.pinCode,
        editDescription: slotDetails.description ?? "",
        description: slotDetails.description ?? "",
      }));
    }
  }, [userSlots]);

  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const updatePreferenceDetails = (meetingLink) => {
    const formData = new FormData();
    formData.append("meetingInApplication", state.meetingInYuukke);
    formData.append("showBookingCount", state.bookingCount);
    formData.append("showProfileImage", state.profilePhoto);

    // formData.append("noFees", state.freeCalendar);
    if (state.pinCode) {
      formData.append("pinCode", state.pinCode);
    }
    // if (!state.freeCalendar) {
    //   formData.append("fees", state.meetingPrize);
    // } else {
    //   formData.append("fees", 0);
    // }
    if (state.businessLogo) {
      formData.append("businessLogov2", state.businessLogo);
    }
    formData.append("serviceName", state.serviceName);

    // if (state.serviceImage) {
    //   formData.append("serviceImage", state.serviceImage);
    // }
    // formData.append("shareService", true);
    if (meetingLink) {
      formData.append("meetingUrl", meetingLink);
    } else {
      formData.append("meetingUrl", "");
    }

    if (state.selectedGalleryImage.length > 0) {
      for (let i = 0; i < state.selectedGalleryImage.length; i++) {
        formData.append("serviceGalleryImage", state.selectedGalleryImage[i]);
      }
    }
    if (state.removedId.length > 0) {
      formData.append("removedGalleryImageId", state.removedId);
    }
    formData.append("description", state.description);
    formData.append("timeZone", state.timeZone.value);
    const lang = state.language.map((item) => {
      return { language: item.label, id: item.value };
    });

    formData.append("language", JSON.stringify({ data: lang }));
    const cate = state.subCategory.map((item) => {
      return { primaryId: item.categoryId, categoryId: item.value };
    });

    formData.append("categoryV2", JSON.stringify({ data: cate }));

    // const id = state.serviceType.map((item) => item.value);
    // formData.append("serviceCategoryId", id);
    // let ids = [7, 8];
    // formData.append("removedServiceCategoryId", ids);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    MyYuukke.updatePreference(formData, config)
      .then((response) => {
        console.log("pref", response.data.data);
        dispatch(getUserSlots());
        updateState("loader", false);
        onBack("calendar", true);
      })
      .catch((err) => {
        toast.error("Some thing went wrong,please try latter");
        console.log("e", err);
        updateState("loader", false);
      });
  };

  const updatePreference = (e) => {
    e.preventDefault();
    if (!urlPatternValidation(state.meetingLink) && !state.meetingInYuukke) {
      toast.warning("Enter valid url meeting url");
    } else if (!state.freeCalendar && !state.meetingPrize) {
      toast.warning("Enter your slot prize");
    }
    // else if (!state.serviceImage && !state.editServiceLogo) {
    //   toast.warning("Add service image");
    // }
    else if (!state.serviceName) {
      toast.warning("Enter service name");
    } else if (
      state.apiGalleryImages.length === 0 &&
      state.selectedGalleryImage.length === 0
    ) {
      toast.warning("Add your gallery images");
    } else if (!state.serviceType.length) {
      toast.warning("Select your service related serviceType");
    } else if (!state.description) {
      toast.warning("Add your service details");
    } else {
      updateState("loader", true);
      updatePreferenceDetails(state.meetingLink);
    }
  };

  const removeApiGalleryImage = (index, type) => {
    if (type === "apiImage") {
      const list = state.apiGalleryImages.splice(index, 1);
      console.log("list", list);
      updateState("removedId", state.removedId.concat(list));
    } else {
      const list = state.selectedGalleryImage.splice(index, 1);
      console.log("list", list);
      setState((previous) => ({
        ...previous,
      }));
    }
  };
  const getSubcategory = (data) => {
    console.log("data", data);
    updateState("serviceType", data);
    let ids = data.map((item) => item.value);
    let findSubCategory = subCategoryV2.reduce((result, data) => {
      if (ids.includes(data.categoryId)) {
        let list = data.subCategoryList.map((item) => {
          return {
            label: item.subCategoryName,
            value: item.subCategoryId,
            categoryId: data.categoryId,
          };
        });
        result = [...result, ...list];
      }
      return result;
    }, []);
    console.log("subCategoryV2", subCategoryV2);
    updateState("subCategoryList", findSubCategory);
  };
  console.log("plansList", state);
  return (
    <>
      <BlockUi
        tag="div"
        message="Loading, please wait"
        style={{ minHeight: "134px" }}
        className="wallLoadingPage"
        blocking={state.loader}
      >
        <h4
          className="p-3 mt-2 mb-2   heading-h4-v2"
          style={{ borderBottom: "1px solid #80808063" }}
        >
          <IoArrowBackCircleOutline
            onClick={() => onBack()}
            className="h-auto  mr-3 svg-icons"
            style={{
              width: "2rem",
            }}
          />
          {t("Service Profile Details")}
        </h4>
        <form
          onSubmit={updatePreference}
          className="card askAQuestionInputGroup cal-grid  p-4"
        >
          <section>
            <label className="label-v2 mt-3">
              {t("Business Name / Service Name")} *
            </label>
            <input
              style={{ height: "50px" }}
              type="text"
              name="serviceName"
              placeholder="Mentoring"
              value={state.serviceName}
              onChange={(e) => updateState("serviceName", e.target.value)}
              required
            />
          </section>
          <section>
            <label className="label-v2 mt-3">{t("Service Type")} *</label>
            <Select
              options={categoryV2}
              value={state.serviceType}
              onChange={getSubcategory}
              required
              isMulti
              className="z-1000"
            />
          </section>
          <section>
            <label className="label-v2 mt-3">{t("Category")} *</label>
            <Select
              options={state.subCategoryList}
              value={state.subCategory}
              onChange={(e) => {
                updateState("subCategory", e);
              }}
              required
              isMulti
              className="z-1000"
            />
          </section>
          <section>
            <label className="label-v2 mt-3">{t("Timezone")} *</label>
            <Select
              options={timeZone}
              value={state.timeZone}
              onChange={(e) => {
                updateState("timeZone", e);
              }}
              required
            />
          </section>
          <section>
            <label className="label-v2 mt-3">{t("Language")} *</label>
            <Select
              options={languagelist}
              value={state.language}
              onChange={(e) => {
                updateState("language", e);
              }}
              required
              isMulti
            />
          </section>
          <section>
            <label className="label-v2 mt-3">{t("Postal Code")} *</label>
            <input
              style={{ height: "50px" }}
              type="number"
              name="pinCode"
              placeholder=""
              value={state.pinCode}
              onChange={(e) => updateState("pinCode", e.target.value)}
              required
            />
          </section>
          <section className="editableTextWrapper formFieldGroup cal-full w-100">
            <label className="label-v2">Describe your service *</label>
            {/* <textarea
              className="post-form-control post-pe-4 post-bg-light"
              rows="1"
              placeholder={t("About your service ...")}
              type="text"
              onChange={(e) => updateState("description", e.target.value)}
              value={state.description}
              name="description"
              style={{ minHeight: "8rem" }}
              required
            ></textarea> */}
            <TextEditorQuestion
              editorStateValue={(e) => updateState("description", e)}
              editorValue={state.editDescription}
            />
          </section>
          <section>
            <CheckBox
              value={state.bookingCount}
              label={`Need to show your booking slot's count in slot profile`}
              onChange={(e) => updateState("bookingCount", e)}
              questionMark={true}
            />
          </section>
          <section className="h-100">
            <CheckBox
              value={state.profilePhoto}
              label="Need to show your profile photo in slot profile"
              onChange={(e) => updateState("profilePhoto", e)}
              questionMark={true}
            />
          </section>
          <section>
            <CheckBox
              value={state.meetingInYuukke}
              label="Meeting in Yuukke"
              onChange={(e) => updateState("meetingInYuukke", e)}
              questionMark={true}
            />
            {!state.meetingInYuukke && (
              <div className="">
                <label className="label-v2 mt-3 ml-2">
                  {t("Add Meeting Link")} *
                </label>

                <input
                  style={{ marginLeft: "0.5rem", height: "50px" }}
                  type="text"
                  name="meetingLink"
                  placeholder="http://googlemeet.com/"
                  value={state.meetingLink}
                  onChange={(e) => updateState("meetingLink", e.target.value)}
                />
              </div>
            )}
          </section>
          {/* <section>
            <label className="label-v2 mt-3">{t("Service Image")} *</label>

            {state.editServiceLogo || state.serviceImage ? (
              <ImagePreview
                apiLogo={state.serviceApiLogo}
                file={
                  state.serviceApiLogo
                    ? state.editServiceLogo
                    : state.serviceImage
                }
                removeFiles={() => {
                  setState((previous) => ({
                    ...previous,
                    editServiceLogo: null,
                    serviceImage: null,
                  }));
                }}
              />
            ) : (
              <StepperFileOption
                onUploadSuccess={(file) => {
                  setState((previous) => ({
                    ...previous,
                    serviceImage: file[0],
                    serviceApiLogo: false,
                  }));
                }}
                acceptedType=".jpg,.jpeg,.png,.svg"
              />
            )}
          </section> */}

          <section>
            <label className="label-v2 mt-3">{t("Business Logo")}</label>

            {state.editBusinessLogo || state.businessLogo ? (
              <ImagePreview
                apiLogo={state.apiLogo}
                file={
                  state.apiLogo ? state.editBusinessLogo : state.businessLogo
                }
                removeFiles={() => {
                  setState((previous) => ({
                    ...previous,
                    editBusinessLogo: null,
                    businessLogo: null,
                  }));
                }}
              />
            ) : (
              <StepperFileOption
                onUploadSuccess={(file) => {
                  setState((previous) => ({
                    ...previous,
                    businessLogo: file[0],
                    apiLogo: false,
                  }));
                }}
                acceptedType=".jpg,.jpeg,.png,.svg"
              />
            )}
          </section>
          <section>
            <label className="label-v2 mt-3">
              {t("Service Gallery Images (You can select multiple images)")} *
            </label>
            <StepperFileOption
              onUploadSuccess={(file) => {
                setState((previous) => ({
                  ...previous,
                  selectedGalleryImage: [
                    ...state.selectedGalleryImage,
                    ...file,
                  ],
                }));
              }}
              acceptedType=".jpg,.jpeg,.png,.svg"
            />{" "}
            {state.apiGalleryImages && (
              <div className="d-flex flex-wrap g-1">
                {state.apiGalleryImages &&
                  state.apiGalleryImages.map((item, i) => (
                    <ImagePreview
                      key={i}
                      apiLogo={true}
                      file={item}
                      removeFiles={(index) =>
                        removeApiGalleryImage(index, "apiImage")
                      }
                    />
                  ))}
                {state.selectedGalleryImage &&
                  state.selectedGalleryImage.map((item, i) => (
                    <ImagePreview
                      key={i}
                      apiLogo={false}
                      file={item}
                      removeFiles={(index) =>
                        removeApiGalleryImage(index, "selectedImage")
                      }
                    />
                  ))}
              </div>
            )}
          </section>
          <button className="w-100 font-weight-bold mt-3" type="submit">
            {t("UPDATE YOUR PREFERENCE")}
          </button>
        </form>
      </BlockUi>
    </>
  );
}

export default withTranslation()(CalendarPlans);
