// job request
import React, { Component } from "react";
import { Chip, Grid, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import AuthService from "../../session/AuthService";
//import backIcon from "../../images/BACK ICON.svg"
import PostService from "../../api/PostService";
import { Button, Modal } from "react-bootstrap";
import { Form, Row, Container, Col } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import moment from "moment";
import ProfileService from "../../api/ProfileService";
import EditableDropdown from "../../components/EditableDropdown";
import { getJobRequestByUser } from "../../redux/actions/myRequestActions";
import { getAllCommonTagsList } from "../../redux/actions/commonApiActions";
import MyRequestFileOption from "./MyRequestFileOption";
import { Multiselect } from "react-widgets";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { API_URL } from "../../config/app-config";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { MdOutlineAttachFile } from "react-icons/md";

// add new table in taglist for JobRequest

var currentDate = new Date();
class MyRequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      budget: "",
      name: "",
      email: "",
      qualification: "",
      base64String: "",
      image: "",
      feedBack: "",
      iid: this.props.id,
      feedbackState: false,
      feedBackList: [],
      triedSoFar: "",
      description: "",
      type: "",
      blocksend: false,
      areaOfInterest: false, //new
      areaofinterestsList: [],
      question: "",
      questionTitleCount: 0,
      fileTypeState: true,
      areaofInterest: [],
      industrysList: [],
      industrysLists: [],
      areaOfinterestListByUser: [],
      natureRequest: [
        { id: "1", name: "Help" },
        { id: "2", name: "Service Needs" },
        { id: "3", name: "Looking for Referral connects" },
        { id: "4", name: "Hiring Needs" },
        { id: "5", name: "I'm looking for Part Time" },
      ],
      natureRequestValue: "",
      industryLists: [],
      selectedduration: "Help",
      selecteTime: "Immediate",
      selectedJobType: "Full Time",
      tagList: [],
      selectedTagList: [],
      searchTagList: [],
      userSelectedList: [],
      tagunValidData: [{ name: "No Matching Result Fount" }],
      expiryDate: moment(new Date(currentDate)).format("DD/MM/YYYY"),
      location: "",
      jobPost: false,
      outComeJob: [
        "Permanent",
        "Temporary",
        "Intern",
        "Freelance",
        "Apprenticeship",
      ],
      outComeBus: [
        "Export",
        "Franchisee",
        "Dealership",
        "Collaboration",
        "Vendor development",
      ],
      menuItems: [
        "Export",
        "Franchisee",
        "Dealership",
        "Collaboration",
        "Vendor development",
      ],
      ShortBio: "",
      editState: false,
      editFile: null,
      file: null,
      menuType: 0,
      describeMenu: "Describe this request in one short sentence",
      describe: "",
      mainChallenge: "What's your main challenge ?",
      mainChallengeValue: "",
      locationName: "What have you tried so far?",
      desiredOutCome: "What's the desired outcome of this request?",
      desiredOutComeValue: "",
    };
    this.handleFormTags = this.handleFormTags.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.saveList = this.saveList.bind(this);
    this.callBack = this.callBack.bind(this);
    this.getAllAreaofInterest = this.getAllAreaofInterest.bind(this);
    this.getAreaofInterestDetails = this.getAreaofInterestDetails.bind(this);
    this.keyFile = this.keyFile.bind(this);
    this.closeChoosedFileData = this.closeChoosedFileData.bind(this);
    this.getQuestionMediaFiles = this.getQuestionMediaFiles.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleFriendMultiSelect = this.handleFriendMultiSelect.bind(this);
    this.handleTagListMultiSelect = this.handleTagListMultiSelect.bind(this);
    this.getAllIndustry = this.getAllIndustry.bind(this);
    this.getIndustryDetails = this.getIndustryDetails.bind(this);
    this.natureRequest = this.natureRequest.bind(this);
    this.handleChangeDuration = this.handleChangeDuration.bind(this);
    this.handleChangeDurationTime = this.handleChangeDurationTime.bind(this);
    this.backtoHome = this.backtoHome.bind(this);
    this.handleIndustryMultiSelect = this.handleIndustryMultiSelect.bind(this);
    this.getAllTags = this.getAllTags.bind(this);
    this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectedJobType = this.handleSelectedJobType.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  componentDidMount() {
    console.log("natureRequest", this.state.natureRequest);
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.getAreaofInterestDetails(this.props.areaofInterest);
    this.getIndustryDetails(this.props.industrylist);
    // this.getAllAreaofInterest(); //industrylist
    // this.getAllIndustry();
    this.getAllTags();
    this.natureRequest(this.state.natureRequest);
    //  alert("HI")
    if (this.props.match.params.id && this.props.match.params.id !== "new") {
      this.setState({
        editState: true,
        askHelpId: this.props.match.params.id,
      });
      this.getJobRequestById(this.props.match.params.id);
    }
  }
  getJobRequestById = (id) => {
    let requestId = {
      jobId: id,
    };
    ProfileService.getJobRequestById(requestId)
      .then((response) => {
        var result = response.data.data.jobRequest;
        if (
          result.natureOfRequest === "Service Needs" ||
          result.natureOfRequest === "Looking for Referral connects"
        ) {
          console.log("Result 1");
          //2
          this.setState({
            desiredOutComeValue: "",
            describeMenu: "Describe Requirement",
            menuType: 3,
            // selectedduration: duration,
            jobPost: true,
            menuItems: this.state.outComeBus,
            mainChallenge:
              "Give Details Covering Opportunity Description ,Size and Qualifying Criteria",
            locationName: "Location",
            desiredOutCome: "Opportunity Type",
          });
        } else if (result.natureOfRequest === "Help") {
          //1
          console.log("Result 2");

          this.setState({
            desiredOutComeValue: "",
            // selectedduration: duration,
            menuType: 0,
            describeMenu: "Describe this request in one short sentence",
            mainChallenge: "What's your main challenge ?",
            locationName: "What have you tried so far ?",
            desiredOutCome: "What's the desired outcome of this request?",
          });
        } else {
          //3 job
          console.log("Result 3");

          this.setState({
            desiredOutComeValue: "",
            describeMenu: "Job Title",
            menuType: 2,
            // selectedduration: duration,
            jobPost: false,
            menuItems: this.state.outComeJob,
            mainChallenge:
              "Give Details Covering Job Description, Perks and Qualifying Criteria",
            locationName: "Work location",
            desiredOutCome: "Opportunity Type",
          });
        }
        this.getRequestData(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getRequestData = async (result) => {
    const list = result.industryValue.map((item) => {
      return { name: item.industryName, id: item.industryId };
    });
    const ariaList = result.areaOfInterestValue.map((item) => {
      return { name: item.areaName, id: item.areaId };
    });
    const tagsValue = result.tagsValue.map((item) => item.tagName);
    console.log("tagsValue", tagsValue);
    await this.setState({
      desiredOutComeValue: result.opportunityType,
      mainChallengeValue: result.mainChallenge,
      selectedduration: result.natureOfRequest,
      industrysLists: list,
      location: result.location ? result.location : "",
      areaofInterest: ariaList,
      selectedTagList: tagsValue,
      describe: result.description ? result.description : "",
      // triedSoFar: result.opportunityType ? result.opportunityType : "",
    });
    if (
      result.natureOfRequest === "Help" ||
      result.natureOfRequest === "Service Needs"
    ) {
      this.setState({
        jobPost: false,
        menuItems: this.state.outComeBus,
      });
    } else {
      this.setState({
        jobPost: true,
        menuItems: this.state.outComeJob,
      });
    }
    var files = result.fileUpload;
    var type = result.fileType;
    let fileList = [];
    if (files != null && (type === "image" || type === "pdf")) {
      fileList.push(`${API_URL}auth/image/${files}`);
      // if (type === "image") {
      //   fileList.push(`${API_URL}auth/image/${files}`);
      await this.setState({ fileImageData: fileList, editFile: type });
      // } else if (type === "pdf") {
      //   let fileList = [];
      //   fileList.push({
      //     fileName: `${API_URL}auth/file/${files}`,
      //     originalFileName: files[0].files,
      //   });
      //   // }
      //   await this.setState({ fileData: fileList });

      // }
    }
  };

  handleChangeDuration = (duration, index) => {
    if (index === 0) {
      //Help
      this.setState({
        desiredOutComeValue: "",
        selectedduration: duration,
        menuType: 0,
        describeMenu: "Describe this request in one short sentence",
        mainChallenge: "What's your main challenge ?",
        locationName: "What have you tried so far ?",
        desiredOutCome: "What's the desired outcome of this request?",
      });
    } else if (index === 3 || index === 4) {
      //Hiring Needs.I'm looking for Part Time
      this.setState({
        desiredOutComeValue: "",
        describeMenu: "Job Title",
        menuType: 2,
        selectedduration: duration,
        jobPost: false,
        menuItems: this.state.outComeJob,
        mainChallenge:
          "Give Details Covering Job Description, Perks and Qualifying Criteria",
        locationName: "Work location",
        desiredOutCome: "Opportunity Type",
      });
    } else {
      this.setState({
        desiredOutComeValue: "",
        describeMenu: "Describe Requirement",
        menuType: 3,
        selectedduration: duration,
        jobPost: true,
        menuItems: this.state.outComeBus,
        mainChallenge:
          "Give Details Covering Opportunity Description ,Size and Qualifying Criteria",
        locationName: "Location",
        desiredOutCome: "Opportunity Type",
      });
    }

    // else if (duration === "Help" || duration === "Service Needs") {//Service Needs.Looking for Referral connects
    //   this.setState({
    //     selectedduration: duration,
    //     jobPost: false,
    //     menuItems: this.state.outComeBus,
    //     triedSoFar: "Select Type",
    //   });
    // } else {
    //   this.setState({
    //     selectedduration: duration,
    //     jobPost: true,
    //     menuItems: this.state.outComeJob,
    //     triedSoFar: "Select Type",
    //   });
    // }
  };
  handleChangeDurationTime(duration) {
    this.setState({
      selecteTime: duration,
    });
  }
  handleSelectedJobType(type) {
    this.setState({
      selectedJobType: type,
    });
  }
  async getAllTags() {
    var tagList = this.props.tagList.data;

    if (tagList) {
      var lList = [];
      for (var i = 0; i < tagList.length; i++) {
        var obj = {};
        obj["tagId"] = tagList[i].tagId;
        obj["tagName"] = tagList[i].tagName;
        lList.push(obj);
      }

      await this.setState({ tagList: lList });
    }
  }
  async natureRequest(industryList) {
    // console.log("loginDetails", this.props.loginDetails);
    // console.log(industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        var obj = {};
        obj["id"] = industryList[i].id;
        obj["data"] = industryList[i].name;
        lList.push(obj);
      }

      await this.setState({ industryLists: lList });
    }
  }
  callBack() {
    if (this.props.isBack) {
      this.props.handleClickAway();
      this.props.getRequestList();
    } else {
      this.props.history.push(
        `/${this.props.workspaceType}/other-requests-list`
      );
    }
  }
  handleClickEdit() {
    this.setState({ savebtnState: false });
  }
  handleChangeDropdown(event, value) {
    console.log("3", value);
    //  this.props.handleSeletedValue(value);
    this.setState({
      selectedTagList: value,
    });
  }
  handleInputChange(event, value) {
    console.log("4", value);
    this.setState({
      inputValue: value,
    });
  }
  removeImageFile = () => {
    this.setState({
      fileImageData: null,
    });
  };
  render() {
    const { t } = this.props;
    const { searchTagList } = this.state;

    return (
      <div>
        <div className="askAQuestionCardWrapperNew" style={{ height: "auto" }}>
          <Grid container spacing={2}>
            <div
              style={{
                marginTop: "20px",
                marginRight: "12px",
                marginLeft: "0px",
                marginBottom: "12px",
              }}
              className="ansBackQuestion flexDefault"
              onClick={this.callBack}
              disabled={this.state.blocksend}
            >
              <ArrowBackIcon />
              <p>{t("Back")}</p>
            </div>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="BACDurationWrapper formGroup">
                <label>
                  {t("Describe your requests in one short sentence")}
                </label>
                {/* <label>{t("Nature of Request")}</label> */}
                <div className="flexDefault" style={{ color: "gray" }}>
                  {[
                    "Help",
                    "Service Needs",
                    "Looking for Referral connects",
                    "Hiring Needs",
                    "I'm looking for Job",
                  ].map((data, index) => (
                    <div
                      className={
                        data === this.state.selectedduration
                          ? "BACDuration BACActive"
                          : "BACDuration"
                      }
                      onClick={() => this.handleChangeDuration(data, index)}
                      key={index}
                    >
                      <p>{t(`${data}`)}</p>
                    </div>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
          {/* {this.state.selectedduration == "I'm looking for Job" && (
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="BACDurationWrapper formGroup">
                  <label>
                    {t("What do you prefer Part time or Full time ?")}
                  </label>
                  <div className="flexDefault">
                    {["Full Time", "Part Time"].map((data, index) => (
                      <div
                        className={
                          data === this.state.selectedJobType
                            ? "BACDuration BACActive"
                            : "BACDuration"
                        }
                        onClick={() => this.handleSelectedJobType(data)}
                        key={index}
                      >
                        <p>{t(`${data}`)}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
            </Grid>
          )} */}

          {/* <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="BACDurationWrapper formGroup">
              <label>{t("Expiry Date")}</label>
              <div className="flexDefault">
                <DatePicker
                  minDate={new Date()}
                  value={this.state.expiryDate}
                  onChange={this.handleChangeInput}
                  format="DD/MM/YYYY"
                  containerStyle={{
                    width: "100%",
                  }}
                  style={{
                    height: "26px",
                    // width: "auto",
                    fontSize: "14px",
                    padding: "18px 12px",
                    //30px 600px 5px 32px
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid> */}

          {/* <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="BACDurationWrapper formGroup">
                <label>{t("How soon do you want to get this done ?")}</label>
                <div className="flexDefault">
                  {["Immediate", "1 week", "15 days", "1 month", "other"].map(
                    (data, index) => (
                      <div
                        className={
                          data === this.state.selecteTime
                            ? "BACDuration BACActive"
                            : "BACDuration"
                        }
                        onClick={() => this.handleChangeDurationTime(data)}
                        key={index}
                      >
                        <p>{t(`${data}`)}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </Grid>
          </Grid> */}
          <Grid container spacing={2}>
            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="SYICInputWrapper formGroup">
                <label className="">
                 
                  {t("Interest") + "(" + t("Applicable") + ")"}
                </label>
                <Multiselect
                  //  className="profileFormInput aboutInputAddButton"
                  style={{ fontColor: "gray" }}
                  required
                  data={this.state.areaofinterestsList}
                  valueField="id"
                  textField="name"
                  name="areaofInterest"
                  value={this.state.areaofInterest}
                  onChange={(value) => this.handleFriendMultiSelect(value)}
                />
              </div>
            </Grid> */}

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="SYICInputWrapper formGroup">
                <label className="">{t(`${this.state.describeMenu}`)}* </label>
                <textarea
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "5px",
                    border: "1px solid #ccc", // Apply red border if exceeding limit
                  }}
                  rows="2"
                  cols="50"
                  value={this.state.describe}
                  name="describe"
                  onChange={this.handleForm}
                ></textarea>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="SYICInputWrapper formGroup">
                {" "}
                {/* <label>{t("Opportunity Type")} *</label> */}
                <label>{t(`${this.state.mainChallenge}`)} *</label>
                <textarea
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "5px",
                    border: "1px solid #ccc", // Apply red border if exceeding limit
                  }}
                  rows="2"
                  cols="50"
                  value={this.state.mainChallengeValue}
                  name="mainChallengeValue"
                  onChange={this.handleForm}
                ></textarea>
                {/* <TextField
                  style={{ backgroundColor: "white" }}
                  fullWidth
                  id="dropdown"
                  select
                  label=""
                  variant="outlined"
                  type="text"
                  required=""
                  name="triedSoFar"
                  value={this.state.triedSoFar}
                  onChange={this.handleForm}
                >
                  {this.state.menuItems.map((item) => (
                    <MenuItem id={item} value={item}>
                      {t(item)}
                    </MenuItem>
                  ))}
                </TextField> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="SYICInputWrapper formGroup">
                <label className="">{this.state.locationName} </label>
                <TextField
                  id="outlined-textarea"
                  variant="outlined"
                  name="location"
                  type="text"
                  value={this.state.location}
                  onChange={(e) => this.handleForm(e)}
                  style={{ width: "100%" }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: "15px" }} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="SYICInputWrapper formGroup">
                <label className="">{t("Industry")}* </label>
                <Multiselect
                  //  className="profileFormInput aboutInputAddButton"
                  style={{ fontColor: "gray" }}
                  required
                  data={this.state.industrysList}
                  valueField="id"
                  textField="name"
                  name="industrysLists"
                  placeholder={t("Select Your Industry Type ...")}
                  value={this.state.industrysLists}
                  onChange={(value) => this.handleIndustryMultiSelect(value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="SYICInputWrapper formGroup">
                <label className="">
                  {t("Area of Interest")}
                  {/* {t("Interest") + "(" + t("Applicable") + ") *"} */}
                </label>
                <Multiselect
                  //  className="profileFormInput aboutInputAddButton"
                  style={{ fontColor: "gray" }}
                  required
                  data={this.state.areaofinterestsList}
                  valueField="id"
                  textField="name"
                  name="areaofInterest"
                  value={this.state.areaofInterest}
                  placeholder={t("Select Your Interest Type ...")}
                  onChange={(value) => this.handleFriendMultiSelect(value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="SYICInputWrapper formGroup">
                {" "}
                <label>{t(`${this.state.desiredOutCome}`)} *</label>
                {this.state.menuType === 0 ? (
                  <div className="SYICInputWrapper formGroup">
                    {/* <label className="">{this.state.locationName}</label> */}
                    <TextField
                      id="outlined-textarea"
                      variant="outlined"
                      name="desiredOutComeValue"
                      type="text"
                      value={this.state.desiredOutComeValue}
                      onChange={(e) => this.handleForm(e)}
                      style={{ width: "100%" }}
                    />
                  </div>
                ) : (
                  <TextField
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    id="dropdown"
                    select
                    label=""
                    variant="outlined"
                    type="text"
                    required=""
                    name="desiredOutComeValue"
                    value={this.state.desiredOutComeValue}
                    onChange={this.handleForm}
                    placeholder="Select"
                  >
                    {this.state.menuItems.map((item) => (
                      <MenuItem id={item} value={item}>
                        {t(item)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </div>
            </Grid>
          </Grid>
          {/* <Grid container style={{ paddingTop: "15px" }}>
            <Grid item xs={12} sm={12} md={12}>
              <div className="SYICInputWrapper formGroup">
                <label className=" ">{t("Select your TagLists")} *</label>
                <div className="myrequestTextfield">
                  <Autocomplete
                    onChange={this.handleChangeDropdown}
                    onInputChange={this.handleInputChange}
                    multiple
                    id="tags-filled"
                    options={searchTagList.map((option) => option.tagName)}
                    // defaultValue={[top100Films[1].title]}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        //  variant="filled"
                        label={t("Search tags..")}
                        placeholder="Add your tag .."
                        value={this.state.userSelectedList}
                        name="userSelectedList"
                        onChange={this.handleFormTags}
                      />
                    )}
                  />
                </div>{" "}
              </div>
            </Grid>{" "}
          </Grid> */}

          {/* <Grid container spacing={2} style={{ paddingTop: "15px" }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="SYICInputWrapper formGroup">
                <label className="">
                  {this.state.jobPost ? (
                    <>{t("Work Location")}</>
                  ) : (
                    <>{t("Location")}</>
                  )}{" "}
                </label>
                <TextField
                  id="outlined-textarea"
                  variant="outlined"
                  name="location"
                  type="text"
                  value={this.state.location}
                  onChange={(e) => this.handleForm(e)}
                  style={{ width: "100%" }}
                />
              </div>
            </Grid>
             
          </Grid> */}
          {/* {!this.state.editState && (
            <Grid container spacing={2} style={{ paddingTop: "15px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="SYICInputWrapper formGroup">
                  <label className=" ">{t("Select your TagsList")} </label>
                  
                  <Autocomplete
                    style={{
                      border: "1px solid gray",
                      padding: "6px 2px 1px 1px",
                      borderRadius: "5px",
                    }}
                    onChange={this.handleChangeDropdown}
                    onInputChange={this.handleInputChange}
                    multiple
                    id="tags-filled"
                    options={searchTagList.map((option) => option.tagName)}
                    // defaultValue={[top100Films[1].title]}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Search tags") + ".."}
                        placeholder={t("Add your tag") + ".."}
                        value={this.state.userSelectedList}
                        name="userSelectedList"
                        onChange={this.handleFormTags}
                      />
                    )}
                  />
                </div>{" "}  
              </Grid>
            </Grid>
          )} */}
          {/* <Grid container spacing={2} style={{ paddingTop: "15px" }}>
            <Grid item xs={12} sm={12} md={12}>
              <div className="SYICInputWrapper formGroup">
                <label>
                  {this.state.jobPost ? (
                    <>
                      {t(
                        "Give Details Covering Job Description, Perks and Qualifying Criteria"
                      )}
                    </>
                  ) : (
                    <>
                      {t(
                        "Give Details Covering Opportunity Description , Size and Qualifying Criteria"
                      )}
                    </>
                  )}{" "}
                  *
                </label>
                <textarea
                  className="formInputsDescriptions"
                  name="ShortBio"
                  rows="2"
                  cols="50"
                  value={this.state.ShortBio}
                  //  disabled={disbaledTagMenu}
                  onChange={this.handleForm}
                />
              </div>
            </Grid>
          </Grid> */}
          {/* <Grid container spacing={2} style={{ paddingTop: "15px" }}>
            <Grid item xs={12} sm={12} md={12}>
              <div className="SYICInputWrapper formGroup">
                 
                <textarea
                  className="formInputsDescriptions"
                  name="ShortBio"
                  rows="4"
                  cols="50"
                  value={this.state.ShortBio}
                  //  disabled={disbaledTagMenu}
                  onChange={this.handleForm}
                />
              </div>
            </Grid>
          </Grid> */}

          <Grid style={{ marginTop: "20px" }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div style={{ fontWeight: "bold", color: "#999999" }}>
                {t(
                  "Attach Files ( Share Additional Information like presentation and etc)"
                )}
              </div>
            </Grid>
          </Grid>
          {this.state.fileImageData && this.state.editFile && (
            <div key={this.state.fileImageData}>
              <Chip
                icon={<MdOutlineAttachFile />}
                className="fileChip"
                label={
                  this.state.editFile === "pdf" ? " document.pdf" : "image"
                }
                size="small"
                onDelete={() => this.removeImageFile()}
                variant="outlined"
              />
              {this.state.editFile === "image" && (
                <img
                  style={{ margin: "8px", width: "80px", height: "80px" }}
                  src={this.state.fileImageData}
                  alt="img"
                ></img>
              )}
              {this.state.editFile === "pdf" && (
                <DescriptionOutlinedIcon className="mt-1" />
              )}
            </div>
          )}
          {!this.state.fileImageData && (
            <div>
              <MyRequestFileOption
                fileTypeKey={this.state.fileTypeKey}
                fileOption={this.state.fileOption}
                fileOptionEnable={this.fileOptionEnable}
                fromQuestionMedia={this.getQuestionMediaFiles}
                editObject={this.props.editObject}
              ></MyRequestFileOption>
            </div>
          )}

          <Grid container style={{ paddingTop: "10px" }}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <button
                style={{ float: "left" }}
                onClick={this.callBack}
                disabled={this.state.blocksend}
              >
                {t("Cancel")}
              </button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className="PTCWrapper">
                <button
                  style={{ float: "right" }}
                  onClick={this.saveList}
                  disabled={this.state.blocksend}
                >
                  {this.state.editState ? t("Update") : t("Submit")}
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  getAllIndustry() {
    ProfileService.getAllIndustry()
      .then((response) => {
        var result = response.data.data;
        this.getIndustryDetails(result);
        console.log("Id", result);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async getIndustryDetails(industryList) {
    console.log("industryList", industryList);
    console.log(industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        var obj = {};
        obj["id"] = industryList[i].industryId;
        obj["name"] = industryList[i].industryName;
        lList.push(obj);
      }

      await this.setState({ industrysList: lList });
      console.log(lList);
      console.log("industrysList");
      console.log(this.state.industrysList);
    }
  }

  handleFriendMultiSelect(friend_value) {
    this.setState({
      areaofInterest: friend_value,
    });
  }
  handleTagListMultiSelect(friend_value) {
    this.setState({
      selectedTagList: friend_value,
    });
  }
  handleIndustryMultiSelect(friend_value) {
    this.setState({
      industrysLists: friend_value,
    });
  }

  getQuestionMediaFiles(files, type) {
    this.setState({ file: files, type: type });
  }
  closeChoosedFileData() {
    this.setState({ fileTypeState: false, file: [] });
  }
  keyFile(key) {
    this.setState({ fileTypeKey: key, fileTypeState: true });
  }
  getAllAreaofInterest() {
    let data = {
      data: "",
    };
    ProfileService.getAreaOfInterest(data)
      .then((response) => {
        var result = response.data.data.user;

        this.getAreaofInterestDetails(result);
        this.setState({ areaOfinterestListByUser: result });
      })
      .catch((error) => {
        // console.log(error)
      });
  }

  handleChangeInput(value) {
    this.setState({
      expiryDate: moment(new Date(value)).format("DD/MM/YYYY"),
    });
    // alert(moment(new Date(value)).format("MM/DD/YYYY"))
  }

  async getAreaofInterestDetails(areaofinterestList) {
    console.log("areaofinterestsList", areaofinterestList);
    if (areaofinterestList) {
      var lList = [];
      for (var i = 0; i < areaofinterestList.length; i++) {
        var obj = {};
        obj["id"] = areaofinterestList[i].areaId;
        obj["name"] = areaofinterestList[i].areaName;
        lList.push(obj);
      }

      await this.setState({ areaofinterestsList: lList });
      console.log(lList);

      console.log(this.state.areaofinterestsList);
    }
  }
  async handleFormTags(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });

    var value = {
      tagName: this.state.userSelectedList,
    };
    ProfileService.getAllTagLists(value)
      .then((response) => {
        var result = response.data.data.tagList;
        var lList = [];

        if (result.length > 0) {
          for (var i = 0; i < result.length; i++) {
            var obj = {};
            obj["tagId"] = result[i].tagId;
            obj["tagName"] = result[i].tagName;
            lList.push(obj);
          }
          this.setState({
            searchTagList: lList,
          });
          console.log("Not else");
        } else {
          this.setState({
            searchTagList: [],
          });
        }
        //  else {
        //   //tagunValidData
        //   console.log("else");
        //   for (var i = 0; i < this.state.length; i++) {
        //     var obj = {};

        //     obj["tagName"] = result[i].tagName;
        //     lList.push(obj);
        //   }
        // }

        // console.log("Id1", lList);
        // console.log("Id1", result);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleForm = (event) => {
    console.log(event);
    this.setState({
      [event.target.name]: event.target.value,
      // questionTitleCount: event.target.value.length,
    });
  };
  backtoHome() {
    this.props.handleClickAway();
    // alert()
    //  this.props.history.push("/my-requests");
  }
  saveList(e) {
    console.log("value", this.state.selectedTagList);
    const formData = new FormData();
    e.preventDefault();
    e.stopPropagation();
    // if (
    //   this.state.expiryDate ===
    //   moment(new Date(currentDate)).format("DD/MM/YYYY")
    // ) {
    //   toast.warn("Please select expiry Date");
    // } else
    if (
      this.state.describe === "" ||
      this.state.mainChallengeValue === "" ||
      this.state.industrysLists?.length === 0 ||
      this.state.desiredOutComeValue === ""
    ) {
      toast.warn("Please fill all the required field");
    } else {
      // formData.append("duration", this.state.location);
      var areaofInterest = [];
      var industry = [];
      for (let i = 0; i < this.state.areaofInterest.length; i++) {
        areaofInterest.push(this.state.areaofInterest[i].id);
      }
      for (let j = 0; j < this.state.industrysLists.length; j++) {
        industry.push(this.state.industrysLists[j].id);
      }
      formData.append("description", this.state.describe);
      formData.append("natureOfRequest", this.state.selectedduration);
      formData.append("areaOfInterest", areaofInterest);
      formData.append("jobIndustry", industry);
      formData.append("location", this.state.location);
      formData.append("opportunityType", this.state.desiredOutComeValue);
      formData.append("mainChallenge", this.state.mainChallengeValue); //New

      // formData.append("newTagName", this.state.selectedTagList);
      // formData.append("criteria", this.state.triedSoFar);//location

      // formData.append("expiryDate", this.state.expiryDate);
      // if (this.state.selectedduration == "I'm looking for Job") {
      //   formData.append("selectedJobType", this.state.selectedJobType);
      // }
      // console.log("file", this.state.file);selectedJobType
      if (
        this.state.file &&
        this.state.file !== "" &&
        this.state.file.length !== 0
      ) {
        var fileType = "";
        if (this.state.file[0].path.includes(".txt")) {
          fileType = "pdf";
        } else if (this.state.file[0].path.includes(".jpg")) {
          fileType = "image";
        } else if (this.state.file[0].path.includes(".png")) {
          fileType = "image";
        } else if (this.state.file[0].path.includes(".doc")) {
          fileType = "pdf";
        } else if (this.state.file[0].path.includes(".pdf")) {
          fileType = "pdf";
        } else {
          fileType = "";
        }

        for (let i = 0; i < this.state.file.length; i++) {
          formData.append("resumeFile", this.state.file[i]);
        }
        formData.append("fileType", fileType);
      } else if (!this.state.editFile) {
        formData.append("fileType", "text");
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      console.log("data selectedduration", this.state.selectedduration);
      console.log("data describe", this.state.describe);
      console.log("data mainChallengeValue", this.state.mainChallengeValue); // new
      console.log("data location", this.state.location);
      console.log("data industrysList", this.state.industrysLists);
      console.log("data areaofInterest", this.state.areaofInterest);
      console.log("data desiredOutComeValue", this.state.desiredOutComeValue);

      if (this.state.editState) {
        if (
          !this.state.file &&
          !this.state.fileImageData &&
          this.state.editFile
        ) {
          formData.append("fileType", "text");
        }
        formData.append("askHelpId", this.state.askHelpId);

        PostService.jobRequestUpdate(formData, config)
          .then((response) => {
            console.log(response.data);
            toast.success("Request updated successfully");
            this.props.getAllCommonTagsList();

            this.callBack();
          })
          .catch((error) => {
            toast.error("Error submitting ");
            // this.props.getRequestList();
            // this.callBack();
          });
      } else {
        PostService.jobRequest(formData, config)
          .then((response) => {
            console.log(response.data);
            toast.success("Request created successfully");
            this.props.getAllCommonTagsList();
            this.callBack();
          })
          .catch((error) => {
            toast.warn("Error submitting ");
            this.callBack();
          });
      }
    }
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    tagList: state.commonApi.taglist,
    areaofInterest: state.commonApi.areaofinterestlist,
    industrylist: state.commonApi.industrylist.data,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getJobRequestByUser: () => dispatch(getJobRequestByUser()),
    getAllCommonTagsList: () => dispatch(getAllCommonTagsList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(MyRequestForm)));
