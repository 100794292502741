import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import PostService from "../../api/PostService";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import AuthService from "../../session/AuthService";
import { Chip } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";

class QuestionFileOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUrl: null,
      type: null,
      mediaPost: "",
      teaching: [],
      tags: [],
      question: "",
      removeStatus: true,
      addTagsState: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    if (this.props.editObject.type === "text") {
      this.setState({ ansFileType: this.props.editObject });
      this.setState({
        name: "",
        type: null,
        removeStatus: false,
        file: null,
        fileUrl: "",
      });
    } else {
      this.setState({
        ansFileType: this.props.editObject,
        name: this.props.editObject.originalFileName,
      });
      this.getQuestionBase64String(
        this.props.editObject.questionFile,
        this.props.editObject.type
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fileOption !== prevProps.fileOption) {
      if (this.props.fileOption) {
        this.removeFile();
      }
    }

    if (this.props.editObject !== prevProps.editObject) {
      if (this.props.editObject.type === "text") {
        this.setState({ ansFileType: this.props.editObject });
        this.setState({
          name: "",
          type: null,
          removeStatus: false,
          file: null,
          fileUrl: "",
        });
      } else {
        this.setState({
          ansFileType: this.props.editObject,
          nmae: this.props.editObject.originalFileName,
        });
        this.getQuestionBase64String(
          this.props.editObject.questionFile,
          this.props.editObject.type
        );
      }
    }
  }

  render() {
    const { t } = this.props;
    const { type, fileUrl } = this.state;
    const onUploadSuccess = async (file) => {
      console.log("FILES");
      console.log(file);
      let preview = document.getElementById("upload-preview");

      this.setState({ removeStatus: false });
      this.setState(
        {
          file: file[0],
          fileUrl: URL.createObjectURL(file[0]),
          type: file[0].type,
          name: file[0].name,
        },
        () => {
          this.onFormSubmit();
        }
      );
    };
    return (
      <div className="questionMoreOptions">
        {type ? (
          <Chip
            icon={<AttachmentIcon />}
            className="fileChip"
            label={this.state.name}
            size="small"
            onDelete={() => this.removeFile()}
            variant="outlined"
          />
        ) : (
          <div className="formPostMoreOption">
            <div className="overAlluploadicons"></div>
          </div>
        )}
      </div>
    );
  }

  getQuestionBase64String(postFile, type) {
    let getBase64String = {
      resourceString: postFile,
    };

    PostService.getBase64String(getBase64String)
      .then((response) => {
        var value = response.data.data.base64String;
        var base64String = "";
        if (type === "image") {
          base64String = `data:image;base64,${value}`;
          this.setState({ type: "image/png" });
        } else if (type === "video") {
          base64String = `data:video/mp4;base64,${value}`;
          this.setState({ type: "video/mp4" });
        } else if (type === "audio") {
          base64String = `data:audio/wav;base64,${value}`;
          this.setState({ type: "audio/mpeg" });
        } else if (type === "pdf") {
          base64String = `data:application/pdf;base64,${value}`;
          this.setState({ type: "application/pdf" });
        }

        this.setState({ fileUrl: base64String });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async removeFile() {
    await this.setState({
      name: "",
      type: null,
      removeStatus: false,
      file: null,
      fileUrl: "",
    });

    this.props.fileOptionEnable();
  }

  handleChange(file, url, type) {
    this.setState({ removeStatus: false });

    this.setState(
      {
        file: file,
        fileUrl: url,
        type: type,
        name: file.name,
      },
      () => {
        this.onFormSubmit();
      }
    );
  }

  onFormSubmit() {
    let files = this.state.file;
    var type = "file";
    if (this.checkImageURL(this.state.file.name)) {
      type = "image";
    } else if (this.checkVideoURL(this.state.file.name)) {
      type = "video";
    } else if (this.checkAudioURL(this.state.file.name)) {
      type = "audio";
    } else if (this.checkPDFURL(this.state.file.name)) {
      type = "pdf";
    } else {
      type = "file";
    }
    this.fileUpload(files, type);
  }

  checkImageURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  checkVideoURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(mp4|webm|ogg)$/) != null;
  }

  checkAudioURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(wav|mp3)$/) != null;
  }

  checkPDFURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(application|pdf)$/) != null;
  }

  fileUpload(file, type) {
    if (file.size <= 5000000) {
      this.props.fromQuestionMedia(file, type);
    } else {
      toast.warn("File size should not be more than 5mb");
    }
  }
}
export default withTranslation()(QuestionFileOption);
