import React, { Component } from "react";
import FriendBgBanner from "./FriendBgBanner";
import Grid from "@material-ui/core/Grid";
import FriendsCard from "./FriendsCard";
import FriendUserInfo from "./FriendUserInfo";
import Container from "@material-ui/core/Container";
import AuthService from "../../../session/AuthService";
import FriendsFinder from "../../../api/FriendsFinder";
import ProfileService from "../../../api/ProfileService";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import i18n from "../../../i18n";
//import dummyimage from "../../../img/avatar_dummy.png";
import dummyimage from "../../../img/default_avatar.jpg";
import banner from "../../../img/banner.jpg";
import ForumService from "../../../api/ForumService";
import moment from "moment";
import FindFriendsFilter from "../../../api/FindFriendsFilter";

class FriendProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.match.params.uid,
      friendsList: [],
      userKnowledgepoint: "",

      coverPhotoImage: "",
      userPhotoImage: "",
      areaOfInterest: [],
      userName: "",
      biography: "",
      professionID: "",
    };
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getFriendsDetailsbyUserid = this.getFriendsDetailsbyUserid.bind(this);
    this.getKnowledgePoints = this.getKnowledgePoints.bind(this);
    this.getAllIndustry = this.getAllIndustry.bind(this);
    this.loadFriendsDetailsValue = this.loadFriendsDetailsValue.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
    this.getCoverPhoto = this.getCoverPhoto.bind(this);
    this.getIndustryDetails = this.getIndustryDetails.bind(this);
    this.getSchoolDetails = this.getSchoolDetails.bind(this);
    this.getAllSchool = this.getAllSchool.bind(this);
    this.getAllAreaofInterest = this.getAllAreaofInterest.bind(this);
    this.getAreaofInterestDetails = this.getAreaofInterestDetails.bind(this);
    this.getTrackAndCurriculum = this.getTrackAndCurriculum.bind(this);
    this.Findprofession = this.Findprofession.bind(this);
  }

  componentDidMount() {
    console.log("++START================>");

    if (this.props.uid) {
      console.log(`user id ${this.props.uid}`);
      this.setState(
        {
          userId: this.props.uid,
        },
        () => {
          this.getFriendsDetailsbyUserid();
          this.getBase64String(this.props.uid);
          this.getCoverPhoto(this.props.uid);
          this.getFriendsList();
          //   this.getKnowledgePoints();
          this.getTrackAndCurriculum();
          //  this.getAllIndustry();
          //   this.getAllAreaofInterest();
          //  this.getAllSchool();
        }
      );
    }

    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.uid !== nextProps.uid) {
      this.setState(
        {
          userId: nextProps.uid,
        },
        () => {
          this.getFriendsDetailsbyUserid();
          this.getBase64String(nextProps.uid);
          this.getCoverPhoto(nextProps.uid);
          this.getFriendsList();
          // this.getKnowledgePoints();
        }
      );
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.uid !== this.props.uid) {
      this.setState(
        {
          userId: this.props.uid,
        },
        () => {
          this.getFriendsDetailsbyUserid();
        }
      );
    }
  }

  render() {
    const { t } = this.props;
    return (
      // <Container>
      <div
        className="profileMainWrapper"
        style={{ background: "#fff", borderRadius: "10px" }}
      >
        <FriendUserInfo
          city={this.state.city}
          profession={this.state.professionData}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          State={this.state.State}
          Region={this.state.Region}
          //  jobFunctions={this.state.jobFunctions}
          areaOfInterest={this.state.areaofInterest}
          //  industry={this.state.industrys}
          name={this.state.userName}
          personalLanguage={this.state.personalLanguage}
          dob={this.state.dob}
          gender={this.state.gender}
          companyName={this.state.companyName}
          facebook={this.state.facebook}
          linkedin={this.state.linkedin}
          twitter={this.state.twitter}
          biography={this.state.biography}
          //  additionalInfo={this.state.hashTag}
          // pointsCount={this.state.userKnowledgepoint}
          userId={this.state.userId}
          coverPhoto={
            this.state.coverPhotoImage === undefined ||
            this.state.coverPhotoImage === ""
              ? banner
              : `data:image;base64,${this.state.coverPhotoImage}`
          }
          profilePhoto={
            this.state.userPhotoImage == undefined ||
            this.state.userPhotoImage === ""
              ? dummyimage
              : `data:image;base64,${this.state.userPhotoImage}`
          }
        />
      </div>
      // </Container>
    );
  }
  getTrackAndCurriculum() {
    var langs = {
      lang: this.props.personalLanguage,
    };
    ProfileService.getTrackAndCurriculumData(langs)
      .then((response) => {
        var result = response.data;

        this.getCuriculumDetails(result.data.curriculum);
      })
      .catch((error) => {});
  }
  getCuriculumDetails(curriculumList) {
    if (curriculumList) {
      var lList = [];

      for (var i = 0; i < curriculumList.length; i++) {
        if (this.state.curriculumId === curriculumList[i].curriculumId) {
          this.setState({
            Region: curriculumList[i].curriculumName,
          });
        }
      }
    } else {
      console.log("No curriculum present");
    }
    for (var i = 0; i < curriculumList.length; i++) {
      console.log("value", curriculumList[i].grade.length);
      for (var j = 0; j < curriculumList[i].grade.length; j++) {
        if (this.state.gradeId === curriculumList[i].grade[j].gradeId) {
          this.setState({
            State: curriculumList[i].grade[j].gradeName,
          });
        }
      }
    }
  }

  getAllSchool() {
    console.log("getAllSchool");
    FindFriendsFilter.getAllSchool()
      .then((response) => {
        var result = response.data;
        this.getSchoolDetails(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getSchoolDetails(schoolList) {
    if (schoolList) {
      var lList = [];
      for (var i = 0; i < schoolList.length; i++) {
        if (this.state.jobFunction === schoolList[i].schoolId) {
          this.setState({
            jobFunctions: schoolList[i].schoolName,
          });
        }
      }
      this.setState({ schools: lList });
      console.log(lList);
    }
    console.log("schoolList", this.state.jobFunctions);
  }

  async getIndustryDetails(industryList) {
    console.log("loginDetails", this.props.loginDetails);
    console.log(industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        if (this.state.industryId === industryList[i].industryId) {
          this.setState({
            industrys: industryList[i].industryName,
          });
        }
      }
    }
  }

  getAllIndustry() {
    ProfileService.getAllIndustry()
      .then((response) => {
        var result = response.data.data;
        this.getIndustryDetails(result);
        console.log("Id", result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAllAreaofInterest() {
    let data = {
      data: "",
    };
    ProfileService.getAreaOfInterest(data)
      .then((response) => {
        var result = response.data.data.user;
      })
      .catch((error) => {});
  }

  async getAreaofInterestDetails(areaofinterestList) {
    if (areaofinterestList) {
      var lList = [];
      for (var i = 0; i < areaofinterestList.length; i++) {
        // if (this.state.areaOfInterest === areaofinterestList[i].areaId) {
        this.setState({
          areaofInterest: areaofinterestList[i].area_of_interest_value,
        });
        console.log("areaofinterestsList");
        console.log(this.state.areaOfInterest);
        // }
      }

      // await this.setState({ areaofinterestsList: lList });
      // console.log(lList);

      // console.log(this.state.areaofinterestsList);
    }
  }
  async getFriendsDetailsbyUserid() {
    let friendDetails = {
      userId: this.state.userId,
    };
    var response = FriendsFinder.getFriendsDetailsCache(friendDetails);
    if (response) {
      this.loadFriendsDetailsValue(response);
    } else {
      var usersId = [];
      usersId.push(this.state.userId);
      FriendsFinder.getFriendsDetailsbyUserid(usersId)
        .then((response) => {
          // console.log("rr",response)
          var result = response.data;

          this.setState({
            userName: result.data.user.dName,
            biography: result.data.user.biography,
            areaOfInterest: result.data.user.areaOfInterest,
            professionID: result.data.user.userProfile.userProfile.professionId,
            city: result.data.user.userProfile.userProfile.location,
            twitter: result.data.user.userProfile.userProfile.twitter,
            linkedin: result.data.user.userProfile.userProfile.linkedin,
            facebook: result.data.user.userProfile.userProfile.facebook,
          });
          this.setState({ hello: true });
          this.getAreaofInterestDetails(this.state.areaOfInterest);
          this.Findprofession(this.state.professionID);
          FriendsFinder.setFriendsDetailsCache(
            friendDetails,
            response.data.data.user[0]
          );
          var result = response.data;

          //  this.loadFriendsDetailsValue(result);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  Findprofession(professionID) {
    if (professionID === 1) {
      this.setState({
        professionData: "Student",
      });
    } else if (professionID === 2) {
      this.setState({
        professionData: "Working professional",
      });
    } else if (this.state.professionID == 3) {
      this.setState({
        professionData: "practicing profession (Auditoer,lawyer and etc)",
      });
    } else if (professionID === 4) {
      this.setState({
        professionData: "Entreprenur",
      });
    } else if (professionID === 5) {
      this.setState({
        professionData: "Homemaker",
      });
    } else if (professionID === 6) {
      this.setState({
        professionData: "Freelancer",
      });
    }
  }
  loadFriendsDetailsValue(result) {
    this.setState({
      userName:
        result.dName &&
        result.dName !== "" &&
        result.dName !== "null" &&
        result.dName !== " "
          ? result.dName
          : result.firstName + " " + result.lastName,
    });

    this.setState({ hashTag: result.hashTag });
    this.setState({ designation: result.biography });
    this.setState({ linkedin: result.userProfile.userProfile.linkedin });
    this.setState({ facebook: result.userProfile.userProfile.facebook });
    this.setState({
      twitter: result.userProfile.userProfile.twitter,
      companyName: result.userProfile.userProfile.companyName,
      industryId: result.userProfile.userProfile.industryId,
      gender: result.userProfile.userProfile.gender,
      personalLanguage: result.userProfile.userProfile.personalLanguage,
      dob: moment(result.userProfile.userProfile.dob).format("MM/DD/yyyy"),
      areaOfInterest: result.userProfile.userProfile.areaOfInterest,
      jobFunction: result.userProfile.userProfile.schoolId,
      state: result.userProfile.userProfile.gradeId,
      firstName: result.userProfile.userProfile.firstName,
      lastName: result.userProfile.userProfile.lastName,
      curriculumId: result.userProfile.userProfile.curriculumId,
      gradeId: result.userProfile.userProfile.gradeId,
      professionId: result.userProfile.userProfile.professionId,
    });
  }

  getFriendsList() {
    let getFriendsList = {
      userId: this.state.userId,
    };
    var response = FriendsFinder.getFriendsListCache(getFriendsList);
    if (response) {
      var result = response.data;
      this.setState({ friendsList: result.data.friendsList });
    } else {
      FriendsFinder.getFriendsList(getFriendsList)
        .then((response) => {
          FriendsFinder.setFriendsListCache(getFriendsList, response);
          var result = response.data;
          this.setState({ friendsList: result.data.friendsList });
        })
        .catch((error) => {
          console.log(error);
        });
      this.setState({ friendsList: [] });
    }
  }

  async getKnowledgePoints() {
    let getKnowledgePoints = { userId: this.state.userId };
    var response = ProfileService.getKPCache(getKnowledgePoints);
    if (response) {
      var result = response.data;
      if (result.data === null) {
        this.setState({ userKnowledgepoint: 0 });
      } else {
        this.setState({ userKnowledgepoint: result.data });
      }
    } else {
      ProfileService.getKnowledgePointByUser(getKnowledgePoints)
        .then((response) => {
          var result = response.data;
          if (result.data === null) {
            this.setState({ userKnowledgepoint: 0 });
          } else {
            ProfileService.setKPCache(getKnowledgePoints, response);
            this.setState({ userKnowledgepoint: result.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  getBase64String(userId) {
    let getBase64String = { userId: userId };
    var response = ForumService.getUserPhotoCache(getBase64String);
    console.log(response);
    if (response) {
      var value = response.data.data;
      if (value && value.userPhoto) {
        this.setState({
          userPhotoImage: response.data.data.userPhoto,
          base64StringState: true,
        });
      }
    } else {
      // ForumService.getUserPhoto(getBase64String)
      //   .then((response) => {
      //     if (
      //       response &&
      //       response.data &&
      //       response.data.data &&
      //       response.data.data.userPhoto
      //     ) {
      //       ForumService.setUserPhotoCache(getBase64String, response);
      //       this.setState({
      //         userPhotoImage: response.data.data.userPhoto,
      //         base64StringState: true,
      //       });
      //     } else {
      //       ForumService.setUserPhotoCache(getBase64String, response);
      //       this.setState({
      //         userPhotoImage: response.data.data.userPhoto,
      //         base64StringState: true,
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  }

  getCoverPhoto(uid) {
    let coverPhoto = {
      userId: uid,
    };
    var response = ForumService.getUserCoverPhotoCache(coverPhoto);
    console.log(response);
    if (response) {
      var value = response.data.data;
      if (value && value.coverPhoto) {
        this.setState({ coverPhotoImage: response.data.data.coverPhoto });
      }
    } else {
      ProfileService.getUsercoverPhoto(coverPhoto)

        .then((response) => {
          var result = response.data;
          ForumService.setUserCoverPhotoCache(coverPhoto, response);
          this.setState({
            coverPhotoImage: result.data.coverPhoto,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}

export default withTranslation()(withRouter(FriendProfile));
