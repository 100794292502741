import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
class AuthRoute extends Component {
  render() {
    if (this.props.authUser.type) {
      if (
        this.props.path.includes("order") ||
        this.props.path.includes("lead")
      ) {
        if (
          this.props.loginDetails.uType === "9" ||
          this.props.loginDetails.uType === "11" ||
          this.props.loginDetails.uType === "17" ||
          this.props.loginDetails.uType === "18"
        ) {
          return <Route {...this.props} />;
        } else {
          return <Redirect to="/dashboard" />;
        }
      } else {
        return <Route {...this.props} />;
      }
    } else {
      // if (
      //   this.props.path.includes("register") ||
      //   this.props.path.includes("forget") ||
      //   this.props.path.includes("profile")
      // ) {
      //   return <Route {...this.props} />;
      // } else {
      if (this.props.authUser.loginType === "teams_login") {
        window.location.assign("mslogin");
        return <Redirect to="/mslogin" />;
      } else if (
        window.location.pathname &&
        window.location.pathname !== "" &&
        window.location.pathname !== "/photo-set-up" &&
        window.location.pathname !== "/redirectpage"
      ) {
        // let path = window.location.pathname + "/";
        // let regex = /\/(.*?)\//;
        // let removePatName = path.match(regex);
        // let workspace =
        //   removePatName && removePatName[1] ? removePatName[1] : "";
        // console.log("WORKSPACE", workspace);
        // sessionStorage.setItem("WORKSPACE", workspace);
        return <Redirect to="/login" />;
      } else {
        // sessionStorage.setItem("WORKSPACE", "");
        // console.log("Login via normal");
        //window.location.assign('login');
        return <Redirect to="/login" />;
      }
      // }
    }
  }
}

const mapStateToProps = function (state) {
  console.log("PROFILE_LANG_VALUE");
  console.log(state.loginDetailsApi.loginDetails);
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
  };
};

export default connect(mapStateToProps, null)(AuthRoute);
