import React, { Component } from "react";
import UserImg from "../../../components/UserImg";
//import dummyimage from "../../../img/avatar_dummy.png";
import dummyimage from '../../../img/default_avatar.jpg';
import ForumService from "../../../api/ForumService";
export default class FriendsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
    };

    this.getBase64String = this.getBase64String.bind(this);
  }
  componentDidMount() {
    this.getBase64String(this.props.friendId);
  }

  render() {
    const { image, name, Dname, smallDescription } = this.props;
    return (
      <div className="FriendsCardWrapper flexDefault">
        <UserImg
          size="medium"
          imgSrc={
            !this.state.image || this.state.image === ""
              ? dummyimage
              : `data:image;base64,${this.state.image}`
          }
          imgAlt={name}
        />
        <div className="friendsinfo">
          <h2>
            {Dname && Dname !== "null" && Dname.length > 0 ? Dname : name}
          </h2>
          <p>{smallDescription}</p>
        </div>
      </div>
    );
  }
  getBase64String(userId) {
    let getBase64String = { userId: userId };
    var response = ForumService.getUserPhotoCache(getBase64String);

    if (response) {
      var value = response.data.data;
      if (value) {
        this.setState({
          image: response.data.data.userPhoto,
          base64StringState: true,
        });
      } else {
        this.setState({
          image: "",
        });
      }
    } else {
      ForumService.getUserPhoto(getBase64String)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.userPhoto
          ) {
            ForumService.setUserPhotoCache(getBase64String, response);
            this.setState({
              image: response.data.data.userPhoto,
              base64StringState: true,
            });
          } else {
            ForumService.setUserPhotoCache(getBase64String, response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
