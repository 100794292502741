import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import AuthService from "../../../session/AuthService";
import HomeService from "../../../api/HomeService";
import { toast } from "react-toastify";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { withRouter } from "react-router-dom";
class EventModerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      app_friendlist: [],
      searchKey: "",
    };
  }

  componentDidMount() {
    console.log("groupMemberList", this.props.memberList);
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    if (this.props.type === "group") {
      this.getSearchedData(this.props.memberList ? this.props.memberList : []);
      // this.setState({
      //   app_friendlist: this.props.memberList,
      // });
    } else {
      this.searchUsers("");
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.type === "group") {
      if (this.props.memberList !== prevProps.memberList) {
        this.getSearchedData(
          this.props.memberList ? this.props.memberList : []
        );
        console.log("List", this.props.memberList);
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="askAQuestionInputGroup">
        <label className="formLabel mt-4">{t("Add Moderator Users")}</label>
        <div>
          <Typeahead
            id="basic-example"
            onInputChange={(e) => this.handleRefferInputChange(e)}
            onChange={(e) => this.usersetSelected(e)}
            options={this.state.app_friendlist ? this.state.app_friendlist : []}
            placeholder="Search users"
            selected={this.state.useSselected}
            multiple
          />
        </div>
      </div>
    );
  }

  handleRefferInputChange = (value) => {
    this.searchUsers(value);
  };

  usersetSelected = (value) => {
    console.log(value);

    this.setState({
      friends: value,
      useSselected: value,
    });
    this.props.moderatorList(value);
  };

  searchUsers = (value) => {
    if (this.props.type === "global") {
      this.setState({ searchKey: value });
      var searchUsers = {
        searchKey: value,
        type: "public",
      };
      console.log(searchUsers);
      HomeService.searchPublicUsers(searchUsers)
        .then((response) => {
          var result = response.data.data.searchList;
          this.getSearchedData(result);
        })
        .catch(console.log("error"));
    } else {
      const filteredData = this.props.memberList.filter((item) =>
        item?.userName.toLowerCase().includes(value?.toLowerCase())
      );
      console.log("filteredData", filteredData);
      this.getSearchedData(filteredData);
    }
  };

  getSearchedData = async (list) => {
    const receiver_List = [];

    for (let i = 0; i < list.length; i++) {
      var userId = list[i].uId;
      var displayName =
        this.props.type === "Event" ? list[i].dName : list[i].userName;

      receiver_List.push({
        value: userId,
        label: displayName,
      });
    }

    await this.setState({ app_friendlist: receiver_List });
  };
}

export default withTranslation()(withRouter(EventModerator));
