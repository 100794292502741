import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import CustomSelect from "./CustomSelect.jsx";
import "./forms.css";

const batches = ["10-Jan-2024", "Apr-2024", "Jul-2024"];
const gender = ["She", "He", "He-She"];
const timeSlots = ["2 hours", "4 hours", "8 hours"];
const meetUp = ["Yes", "No"];
const YuukkeLaunchPad = (props) => {
  const [state, setState] = useState({
    selectedBatch: "10-Jan-2024",
    selectedGender: "She",
    selectedTime: "2 hours",
    meetUp: "Yes",
  });
  useEffect(() => {
    var main = document.getElementById("base");
    var body = document.getElementById("root");
    main.style.background =
      "linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(45, 113, 253, 1) 100%)";
    body.style.background =
      "linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(45, 113, 253, 1) 100%)";

    // document.querySelector("body").style.background = "green !important";
    console.log("Enter market place");
  }, []);

  const updateState = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };
  const sendData = () => {
    // props.history.push("/form-products");
  };
  return (
    <div className="market-container">
      <section className="form-wrapper row" onSubmit={(e) => sendData(e)}>
        <div>
          <h4>Yuukke Launch Pad</h4>
          <p className="mb-2">Powered by CSUF Startup Incubator</p>
          <p>
            A comprehensive course for women-led social enterprises. Learn
            strategies to go from idea to launch while creating sustainable
            solutions and creating real impact in the world.
          </p>
        </div>
        <div className=" p-3 w-100">
          <h6 className="bg-color">
            Please fill in your details as given below.
          </h6>
          <form className="row custom-form">
            <InputField label={"Name"} required={true} />
            <CustomSelect
              label="Select Batch (Jan,Apr,Jul options available)"
              data={batches}
              required={true}
              onChange={(e) => updateState("selectedBatch", e.target.value)}
              value={state.selectedBatch}
            />
            <InputField label={"Email"} required={true} />
            <CustomSelect
              label="Gender"
              data={gender}
              required={true}
              onChange={(e) => updateState("selectedGender", e.target.value)}
              value={state.selectedGender}
            />
            <InputField label={"Phone Number"} required={true} />
            <div className=" col-12 col-md-6 col-lg-6 mt-3">
              <label>Brief Bio *</label>
              <textarea className="market-text-area" />
            </div>
            <div className=" col-12 col-md-6 col-lg-6 mt-3">
              <label>What is your motivation for taking this course? *</label>
              <textarea className="market-text-area" />
            </div>
            <CustomSelect
              label="How many hours per week can you dedicated to this course?"
              data={timeSlots}
              required={true}
              onChange={(e) => updateState("selectedTime", e.target.value)}
              value={state.selectedTime}
            />
            <CustomSelect
              label="Can you commit to a 1-hour monthly online meetup?"
              data={meetUp}
              required={true}
              onChange={(e) => updateState("meetUp", e.target.value)}
              value={state.meetUp}
            />
            <div className=" col-12 col-md-6 col-lg-6 mt-3">
              <label>Tell us about your organization (if applicable)*</label>
              <textarea className="market-text-area" />
            </div>
            <InputField label={"Your role"} required={true} />
            <div className="col-12 mt-3 mb-3">
              <button className="col-12" type="submit">
                APPLY FOR FREE
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default YuukkeLaunchPad;
