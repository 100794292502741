import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import NavigationsMenu from "./demoside";
import NavigationRouter from "./NavigationRouter";
import AuthService from "../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";
import { Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import ChatIndex from "../chatPanel/src/ChatIndex";
import { setUserSelectedFalse } from "../redux/actions/chatActions";
import { MdKeyboardArrowUp } from "react-icons/md";
import Header from "../components/Header/Header";
import PhotoSetUpModel from "../components/PhotoSetUpModel";
import YuukkeAnnouncementModel from "../components/YuukkeAnnouncementModel";
import DynamicFormModel from "../components/DynamicFormModel";
import SubscriptionContainer from "../screens/PremiumBadges/SubscriptionContainer";

const message =
  "There's a lot to discover out there.But let's get your profile set up first";

class Navigations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenu: false,
      prevScrollStatus: false,
      needHelpButtonState: false,
    };
    this.handleMobileMenu = this.handleMobileMenu.bind(this);
  }

  handleMobileMenu = () => {
    this.setState({ mobileMenu: !this.state.mobileMenu });
  };

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    if (this.props.userDetails) {
      this.state.chatDetails = {
        uId: this.props.userDetails.uId,
        dName: this.props.userDetails.dName,
        profile_uuid: this.props.userDetails.profile_uuid,
        timeZone: this.props.userDetails.timeZone,
        email: this.props.userDetails.email,
        active: this.props.userDetails.active,
        uPhoto: this.props.userDetails.uPhoto,
      };
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userDetails &&
      this.props.userDetails !== prevProps.userDetails
    ) {
      this.state.chatDetails = {
        uId: this.props.userDetails.uId,
        dName: this.props.userDetails.dName,
        profile_uuid: this.props.userDetails.profile_uuid,
        timeZone: this.props.userDetails.timeZone,
        email: this.props.userDetails.email,
        active: this.props.userDetails.active,
      };
    }

    window.addEventListener("scroll", this.handleScroll);
    return () => {
      window.removeEventListener("scroll", this.handleScroll);
    };
  }

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos > 360) {
      this.setState({
        prevScrollStatus: true,
      });
    } else {
      this.setState({
        prevScrollStatus: false,
      });
    }
  };

  render() {
    let currentPath = window.location.pathname;
    console.log("login- path", currentPath);

    if (!this.props.authenticated && sessionStorage.getItem("WORKSPACE")) {
      console.log("clear section data", sessionStorage.getItem("WORKSPACE"));
      console.log("N_WORKSPACE_UPDATE", "");
      sessionStorage.setItem("WORKSPACE", "");
    }
    if (currentPath.includes("link-challenge-Description")) {
      sessionStorage.setItem("CHALLENGE_REDIRECT_PATH", currentPath);
      console.log("Inside redirect path CHALLENGE_REDIRECT_PATH");
    } else if (currentPath.includes("link-career-lab")) {
      sessionStorage.setItem("CAREER_REDIRECT_PATH", currentPath);
      console.log("Inside redirect path CAREER_REDIRECT_PATH");
    } else if (
      currentPath.includes("yuukke_share_post") &&
      !this.props.authenticated
    ) {
      console.log("Inside redirect path SHARE_POST");
      localStorage.removeItem("ARTICLE_SLUG");
      sessionStorage.setItem("SHARE_POST", window.location.href);
    } else if (
      currentPath.includes("yuukke-advertisement") &&
      !this.props.authenticated
    ) {
      console.log("Inside redirect path ADVERTISEMENT_PATH");
      sessionStorage.setItem("ADVERTISEMENT_PATH", currentPath);
    } else if (
      currentPath.includes("yuukke-user")
      // !this.props.authenticated
    ) {
      console.log("Inside redirect path FRIEND_PATH");
      sessionStorage.setItem("FRIEND_PATH", window.location.href);
    } else if (
      currentPath.includes("yuukke-t-community") &&
      !this.props.authenticated
    ) {
      console.log("Inside redirect path FAMOUS_COMMUNITY_PATH");
      sessionStorage.setItem("FAMOUS_COMMUNITY_PATH", window.location.href);
    } else if (
      currentPath.includes("eventRegistration") &&
      !this.props.authenticated
    ) {
      console.log("Inside redirect path EVENT_REGISTER_PATH");
      localStorage.setItem("EVENT_REGISTER_PATH", window.location.href);
    } else if (
      currentPath.includes("yuukke-workspace") &&
      !this.props.authenticated
    ) {
      console.log("Inside redirect path WORK_SPACE_LINK_USER");
      sessionStorage.setItem("WORK_SPACE_LINK_USER", window.location.href);
    } else if (
      currentPath.includes("yuukke_form") &&
      !this.props.authenticated
    ) {
      console.log("Inside redirect path DYNAMIC_FORM_PRESENT");
      sessionStorage.setItem("DYNAMIC_FORM_PRESENT", window.location.href);
    }

    return (
      <Router basename="/">
        {!this.props.authenticated && this.props.renderComponent && <Header />}
        <div
          className={
            !this.props.userLoggedIn ? "d-flex flex-column" : "nav-menu-new"
          }
        >
          {this.props.authUser &&
            this.props.authenticated &&
            this.props.refreshToken !== "" &&
            this.props.accessToken != "" &&
            window.location.pathname !== "/photo-set-up" &&
            window.location.pathname !== "/login" && (
              <nav>
                <NavigationsMenu
                  needHelpButtonState={this.state.needHelpButtonState}
                />
              </nav>
            )}
          <main className="p-relative">
            {this.props.authUser &&
              this.props.authenticated &&
              this.props.needToPopUp && <PhotoSetUpModel />}

            {this.props.authUser &&
              this.props.authenticated &&
              (this.props.needAnnouncement === "location" ||
                this.props.needAnnouncement === "areaOfInterest") && (
                <YuukkeAnnouncementModel message={message} />
              )}
            {this.props.authenticated && this.props.dynamicFormSlug && (
              <DynamicFormModel
                slug={this.props.dynamicFormSlug}
                userEmail={this.props.userDetails?.email}
              />
            )}
            {this.props.subscriptionModel && <SubscriptionContainer />}

            <NavigationRouter />
          </main>

          {this.props.authUser &&
            this.props.authenticated &&
            this.state.prevScrollStatus && (
              <span class="up-arrow">
                <Tooltip title="Scroll to top" placement="top">
                  <div
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      })
                    }
                  >
                    <MdKeyboardArrowUp
                      style={{ color: "white", width: "26px", height: "26px" }}
                    />
                  </div>
                </Tooltip>
              </span>
            )}
          {!window.location.pathname.includes("photo-set-up") &&
            window.location.pathname !== "/workspace" &&
            !window.location.pathname.includes("userProfile") &&
            this.props.authenticated &&
            this.props.accessToken !== "" &&
            this.props.refreshToken !== "" &&
            Array.isArray(this.props.friendsList) &&
            this.props.friendsList.length > 0 &&
            this.state.chatDetails && (
              <ChatIndex
                userDetails={this.state.chatDetails}
                authenticated={this.props.authenticated}
                refreshToken={this.props.refreshToken}
                friendsList={this.props.friendsList}
                accessToken={this.props.accessToken}
                selectedUser={this.props.selectedUser}
                closeCallBack={this.props.removeSelectedUser}
              />
            )}
          {/* {!window.location.pathname.includes("photo-set-up") &&
            window.location.pathname !== "/workspace" &&
            this.props.authUser &&
            this.props.authenticated &&
            this.props.refreshToken !== "" &&
            !window.location.pathname.includes("dyNamicFormSluguserProfile") &&
            this.props.accessToken != "" && (
              <div>
                <span className="badge badge-info ask-badge">
                  {this.props.otherRequestCount}
                </span>
                <button className="my-button" onClick={this.gotoNeedHelp}>
                  {t("Ask Help")}
                </button>
              </div>
            )} */}
        </div>

        {/* {currentPath.includes("contact-support") ||
          currentPath.includes("error") ? (
          <></>
        ) : (
          "" */}
        {/* <footer>
            <div className="footerWrapper">
            <Grid container spacing={1}>
             <Grid item lg={6} xs={6} sm={6} md={6}>
             <div style={{ marginLeft: "6px", padding: "8px" }}>
                  {" "}
                  <p
                    style={{
                      color: "#000000",
                      fontSize: "12px",
                      marginRight: "5px",
                    }}
                  >
                    © Copyright by yuukke
                  </p>
                </div>
              </Grid>
             <Grid item lg={6} xs={6} sm={6} md={8}>
              <div >
                powered by{" "}
                <a href="https://betamonks.com">               
                   <img  src={logoDark} alt="Header Log" />            
                   </a>
              </div>
                         </Grid>
            </Grid></div>

          </footer> */}
        {/* )} */}
      </Router>
    );
  }
  gotoNeedHelp = () => {
    this.setState({
      needHelpButtonState: !this.state.needHelpButtonState,
    });
  };
}

const mapStateToProps = function (state) {
  return {
    userDetails: state.loginDetailsApi.loginDetails,
    friendsList: state.commonApi.chatFriendslist,
    navigationType: state.commonApi.navigationType,
    selectedUser: state.chatList.selectedUser,
    authUser: state.loginDetailsApi.authUser,
    authenticated: state.loginDetailsApi.keycloakAuthenticated,
    refreshToken: state.loginDetailsApi.refreshToken,
    accessToken: state.loginDetailsApi.accessToken,
    // otherRequestCount: state.myRequestReducers.otherRequestCount
    //   ? state.myRequestReducers.otherRequestCount
    //   : 0,
    // defaultWorkSpaceId:
    //   state.loginDetailsApi.loginDetails?.userWorkSpace?.workSpaceId,
    renderComponent: state.commonApi.renderComponent,
    needToPopUp: state.commonApi.needToPopUp,
    // likePointPopUp: state.postList.likePointPopUp,
    needAnnouncement: state.loginDetailsApi.needAnnouncement,
    userLoggedIn: state.loginDetailsApi.userLoggedIn,
    dynamicFormSlug: state.loginDetailsApi.dynamicFormSlug,
    subscriptionModel: state.event.subscriptionModel,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    removeSelectedUser: () => dispatch(setUserSelectedFalse()),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(Navigations));
