import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import AuthService from "../../session/AuthService";
import moment from "moment";
import MyYuukke from "../../api/MyYuukke";
import Swal from "sweetalert2";
import { Multiselect } from "react-widgets";
import { getSubjectsRequestList } from "../../redux/actions/subjectActions";
import { connect } from "react-redux";
import HomeService from "../../api/HomeService";
import { ToastContainer, toast } from "react-toastify";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { withRouter } from "react-router-dom";
import InviteUser from "../../components/InviteUser";
import {
  Button,
  Form,
  Modal,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap";

class InviteFriendGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      app_friendlist: [],
      searchKey: "",
      successList: false,
      invitedUsers: [],
      haveData: false,
      friends: [],
    };
    this.handleRefferInputChange = this.handleRefferInputChange.bind(this);
    this.searchUsers = this.searchUsers.bind(this);
    this.inviteUser = this.inviteUser.bind(this);
    this.getSearchedData = this.getSearchedData.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    // this.searchUsers("");
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        {this.props.isForum ? (
          <>
            {" "}
            <InviteUser
              isForum={true}
              closeInvitePage={this.closePopup}
              isGroup={false}
              emailData={this.emailData}
            />
          </>
        ) : (
          <div className="aboutGroupWrapper">
            <div
              className="BGCParticipants formGroup"
              style={{ marginTop: "20px" }}
            >
              <p className="aboutGroupTitle mb-2">
                {t("Add Participants in your community")}
              </p>
              {/* <InviteUser isGroup={true} emailData={this.emailData} /> */}
              {/* <Multiselect
                        className="profileFormInput aboutInputAddButton"
                        required
                        data={this.state.app_friendlist}
                        valueField="userId"
                        textField="displayName"
                        value={this.state.friends}
                        onChange={(value) => this.handleFriendMultiSelect(value)}
                    /> */}

              <Typeahead
                id="basic-example"
                onInputChange={(e) => this.handleRefferInputChange(e)}
                onChange={(e) => this.usersetSelected(e)}
                options={this.state.app_friendlist}
                placeholder="Search users"
                selected={this.state.useSselected}
                multiple
              />
            </div>

            <button onClick={() => this.inviteUser()}>{t("Invite")}</button>
          </div>
        )}

        {this.state.successList && this.popUpUserStatus()}
      </div>
    );
  }
  emailData = (value) => {
    console.log("email value", value);
    var searchUsers = {
      emails: value.email,
      groupId: this.props.subId,
    };
    HomeService.addUser(searchUsers)
      .then((response) => {
        const result = response.data.data;
        this.setState({
          successList: true,
          invitedUsers: result,
          haveData: true,
        });
        if (!this.props.isForum) {
          this.props.removedSentData(this.props.subId);
        }
      })
      .catch((e) => {
        toast.error("Error in saving");
        console.log(e);
      });
  };
  handleClose = () => {
    this.setState({
      successList: !this.state.successList,
    });
    if (this.props.isForum) {
      this.props.closeInvitePage(true);
    }
  };
  popUpUserStatus() {
    const { t } = this.props;
    return (
      <div>
        <Modal show="true" onHide={this.handleClose}>
          <Modal.Header>{t("User's Status")}</Modal.Header>

          <Form id="profileForm" noValidate validated={this.state.validated}>
            <Modal.Body>
              {this.state.invitedUsers?.success?.length !== 0 && (
                <>
                  <h6>
                    {this.state.invitedUsers?.failed?.length === 1
                      ? t("Invited User")
                      : t("Invited Users")}{" "}
                    ,
                  </h6>
                  <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                    {this.state.invitedUsers?.success.map((item) => (
                      <div className="mentor-slot-chip  ">{item}</div>
                    ))}
                  </div>
                </>
              )}

              {this.state.invitedUsers?.failed?.length !== 0 && (
                <>
                  <h6 className="mt-4">
                    {this.state.invitedUsers?.failed?.length === 1
                      ? t("User Not exist in yuukke")
                      : "User's Not exist in yuukke"}{" "}
                    ,
                  </h6>
                  <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                    {this.state.invitedUsers?.failed.map((item) => (
                      <div className="mentor-slot-chip  ">{item}</div>
                    ))}
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                {t("Close")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
  handleRefferInputChange(value) {
    this.searchUsers(value);
  }

  usersetSelected(value) {
    console.log(value);

    this.setState({
      friends: value,
      useSselected: value,
    });
  }

  searchUsers(value) {
    this.setState({ searchKey: value });
    var searchUsers = {
      searchKey: value,
      groupId: this.props.subId,
    };
    console.log(searchUsers);
    HomeService.groupInvite(searchUsers)
      .then((response) => {
        var result = response.data.data.searchList;
        // this.setState({ app_friendlist: result })
        this.getSearchedData(result);
      })
      .catch(console.log("error"));
  }

  async getSearchedData(list) {
    const receiver_List = [];

    for (let i = 0; i < list.length; i++) {
      var userId = list[i].uId;
      var displayName = list[i].userName;

      receiver_List.push({
        value: userId,
        label: displayName,
      });
    }

    await this.setState({ app_friendlist: receiver_List });
  }
  closePopup = () => {
    this.props.closeInvitePage(this.state.haveData);
  };
  inviteUser() {
    if (this.state.friends?.length !== 0) {
      var userId = [];
      for (let i = 0; i < this.state.friends.length; i++) {
        userId.push(this.state.friends[i].value);
      }
      var inviteGroupList = {
        userIds: userId,
        groupId: this.props.subId,
      };
      console.log("user data");
      console.log(inviteGroupList);
      HomeService.inviteGroupList(inviteGroupList)
        .then((response) => {
          if (response.data.status === 1) {
            toast.success("Invited successfully...");
            this.setState({
              friends: [],
              app_friendlist: [],
              useSselected: [],
            });
            this.props.removedSentData(this.props.subId);
          } else {
            toast.success("Invite Failed...");
          }
        })
        .catch(console.log("error"));
    } else {
      toast.warning("Add valid users ...");
    }
  }
}

export default withTranslation()(withRouter(InviteFriendGroup));
