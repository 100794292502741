import { GET_GROUP_LIST, GET_LOGOUT_DATA } from "../types";

const initialState = {
  list: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GROUP_LIST:
      console.log("GET_GROUP_LIST");
      console.log(action.payload);
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");
      console.log(initialState.subjectslist);
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
