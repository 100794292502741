import React, { useReducer, useEffect, useState } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ProfileService from "../../api/ProfileService";
import {
  ofterTriggerPopup,
  saveTourStatus,
  triggerTourPopup,
} from "../../redux/actions/commonApiActions";
var viewList = [];
const LEARNING_TOUR_STEPS = [
  {
    target: ".tour-learning",
    content: "Click to view  beyond barriers photos.",
    disableBeacon: true,
  },
];

const LEARNING_TOUR_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: LEARNING_TOUR_STEPS,
};

const HEADER_TOUR_STEPS = [
  {
    target: ".tour-headerIcon",
    content: "Welcome to Yuukke! - A Global Ecosystem for Women, by Women.",
    disableBeacon: true,
  },
  {
    target: ".work-space-tour",
    content:
      "Change to workspace beyond barrier to view beyond barrieres photo.",
  },
  {
    target: ".tour-language",
    content: " Select your preferable language here.",
  },
  {
    target: ".tour-notification",
    content:
      "List of in app notifications   both general and private notifications are displayed here.",
  },
  // {
  //   target: ".notificationWrapper",
  //   content:
  //     "In General get all the updates happening in platform and Private get all your personal and post-activity updates",
  // },
  // {
  //   target: ".tour-create",
  //   content: "Create your community and posts",
  // },
  // {
  //   target: ".tour-crate-post",
  //   content: "Post polls, surveys, community posts, and general posts",
  // },
  // {
  //   target: ".tour-invite-user",
  //   content: "Invite your friends.",
  // },
  // {
  //   target: ".tour-create-community",
  //   content:
  //     "Create a Post Community, Forum community, public and private community.",
  // },
  {
    target: ".tour-marketPlace",
    content: "Build your business by registering to the Market Place",
  },
  {
    target: ".tour-calender",
    content:
      "Events scheduled with you in yuukke platform are displayed here. ",
  },
  {
    target: ".tour-profile-up",
    content: "Click to edit or update your profile",
  },
  {
    target: ".tour-profile-visibility",
    content:
      "Click to open and control your visibility in the yuukke application",
  },
  {
    target: ".my-button",
    content: "Asking help",
    placement: "left",
  },

  // {
  //   target: ".tour-profile-visibility",
  //   content: "Thank you for signing up, Yuukke!",
  // },
];

const HEADER_INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: HEADER_TOUR_STEPS,
};
const POST_TOUR_STEPS = [
  {
    target: ".home-tour-type",
    content:
      "You can select general to send feed to all the people in the application.You can select friends to send it your friends only . Or you can select a community to send post to that specific community only.",
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: ".home-tour-category",
    content:
      "Update post as Normal post, Survey Post, advertisement, Polling Post, Article.   When creating a feed you can select different post type other than normal post like Survey post. where you can post a set of questions as a creator you can verify later what answer is been selected by the participants.  Advertisement where you can promote your product in yuukke . Polling can be used to ask for set of question with single choice answer the answers are visible to everyone.",
  },
  {
    target: ".home-tour-posting-Tab",
    content:
      "You can provide your post description here.you can make use of the bold , italic underline functionality while creating description and dont forget to add your  tags in description.",
  },

  // {
  //   target: ".home-tour-image",
  //   content: "Add images to the feed",
  // },
  {
    target: ".home-tour-attach",
    content:
      " Attachment -  You can upload photo , video or documents to your feeds along with your description.",
  },
  // {
  //   target: ".home-tour-video",
  //   content: "Add videos to the feed",
  // },

  {
    target: ".home-tour-publish",
    content: "Press publish to post your first feed !!.",
  },
];

const POST_INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: POST_TOUR_STEPS,
};

const PROFILE_TOUR_STEPS = [
  {
    target: ".tour-profile-edit",
    content: "You can change your profile photo.",
    disableBeacon: true,
    placement: "top",
  },
  {
    target: ".tour-edit-cover",
    content: "You can change your cover photo.",
    disableBeacon: true,
    placement: "top",
  },
  {
    target: ".tour-vcard",
    content:
      "Click here to generate your vcard, you can share your vcard to anyone on the internet.",
    disableBeacon: true,
    placement: "top",
  },
  {
    target: ".tour-profile-card",
    content: "You can update or modify your profile details here.",
    placement: "top",
  },
  {
    target: ".tour-friend-card",
    content: "List of friends you have connected through Yuukke application.",
    placement: "top",
  },
  {
    target: ".tour-blocked-card",
    content:
      "List of users you have blocked are displayed here. You can unblock them here.",
    placement: "top",
  },
  {
    target: ".tour-feed-card",
    content: "List of post created by you are displayed here.",
    placement: "top",
  },
  {
    target: ".tour-bookmarked-card",
    content: "List of post bookmarked by you are displayed here.",
    placement: "top",
  },
];

const PROFILE_INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: PROFILE_TOUR_STEPS,
};

const COMMUNITY_TOUR_STEPS = [
  {
    target: ".community-new",
    content:
      "You can create a group communities or a forum which can be either private or public. People cannot join in private communities unless you invite them.",
    disableBeacon: true,
    position: "top",
    offset: 10,
    // placement: "top",
  },
  {
    target: ".my-community-list",
    content: " Communities created by you or joined by you are displayed here.",
    position: "top",
    offset: 10,
    // placement: "top",
  },
  {
    target: ".all-community-list",
    content:
      "Communities where you can participate communities can be a group or forum",
    // placement: "top",
  },
  {
    target: ".forum-community-list",
    content: "List of forum communities where you can join and participate.",
    // placement: "top",
  },
];

const COMMUNITY_INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: COMMUNITY_TOUR_STEPS,
};

const CONNECTION_TOUR_STEPS = [
  {
    target: ".connection-tour-search",
    content: "You can search and connect with the users here",
    disableBeacon: true,
    position: "top",
    offset: 10,
  },
  // {
  //   target: ".connection-tour-type",
  //   content: "Searching types",
  //   offset: 10,
  // },
  {
    target: ".connection-tour-invite",
    content:
      " Invite your friend through email to collaborate in yuukke platform",
    offset: 10,
  },
  {
    target: ".connection-tour-request-other",
    content: "List of friend request sent by you.",
    offset: 10,
  },
  // {
  //   target: ".connection-tour-requested",
  //   content: "Your Request list",
  //   offset: 10,
  // },
  // {
  //   position: "bottom",
  //   target: ".connection-tour-suggested",
  //   content: "Your suggestion connection list",
  //   offset: 10,
  // },
];

const CONNECTION_INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: CONNECTION_TOUR_STEPS,
};

const ASK_HELP_TOUR_STEPS = [
  {
    target: ".ask-help-tour",
    content: "Need a help from community click here",
    disableBeacon: true,
    position: "bottom",
    offset: 10,
  },

  {
    target: ".other-request-tour",
    content: "A place where other members requests are shown..",
    offset: 10,
  },
  {
    target: ".ask-help-crate-tour",
    content: "Create a new help the post will be shared across the platform.",
    offset: 10,
  },
];
const ASK_HELP_INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: ASK_HELP_TOUR_STEPS,
};

const reducer = (state, action) => {
  console.log("tour action", action);
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, run: true, stepIndex: action.stepIndex };
    case "STOP":
      return { ...state, run: false };
    case "HEADER":
      return {
        ...HEADER_INITIAL_STATE,
        run: true,
        stepIndex: action.stepIndex,
      };
    case "LEARNING":
      return {
        ...LEARNING_TOUR_STATE,
        run: true,
        stepIndex: action.stepIndex,
      };

    case "COMMUNITY":
      return {
        ...COMMUNITY_INITIAL_STATE,
        run: true,
        stepIndex: action.stepIndex,
      };
    case "HOME":
      return { ...POST_INITIAL_STATE, run: true, stepIndex: action.stepIndex };
    case "CONNECTION":
      return {
        ...CONNECTION_INITIAL_STATE,
        run: true,
        stepIndex: action.stepIndex,
      };
    case "PROFILE":
      return {
        ...PROFILE_INITIAL_STATE,
        run: true,
        stepIndex: action.stepIndex,
      };
    case "ASK_HELP":
      return {
        ...ASK_HELP_INITIAL_STATE,
        run: true,
        stepIndex: action.stepIndex,
      };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

const Tour = (props) => {
  const navigatePage = useLocation();
  const dispatch = useDispatch();
  const [isRunning, setIsRunning] = useState(false);
  const [tourState, tourDispatch] = useReducer(
    reducer,
    CONNECTION_INITIAL_STATE
  );
  // const [tourState, postDispatch] = useReducer(reducer, HEADER_INITIAL_STATE);

  const { tourApiCalled, toursStages, popupStatus } = useSelector(
    (state) => state.commonApi
  );
  const popupLocation = useSelector((state) => state.commonApi.popupLocation);
  // const [getUserTours, getUserToursDispatch] = useReducer(
  //   userReducer,
  //   toursStages
  // );

  // const tourNumber = useSelector((state) => state.commonApi.completedTours);

  //Initial
  useEffect(() => {
    console.log("popupLocation", popupLocation);
    if (tourApiCalled) {
      console.log("tourState trigger useEffect result", toursStages);
      if (popupLocation === "header" && !toursStages?.header?.headerFinish) {
        console.log("tourState trigger Header");
        tourDispatch({
          type: "HEADER",
          stepIndex: 0,
        });
      } else if (
        popupLocation == "community" &&
        !toursStages?.community?.communityFinish
      ) {
        tourDispatch({
          type: "COMMUNITY",
          stepIndex: 0,
        });
        console.log("tourState trigger COMMUNITY");
      } else if (popupLocation === "home" && !toursStages?.post?.postFinish) {
        tourDispatch({
          type: "HOME",
          stepIndex: 0,
        });
        console.log("tourState trigger Home post");
      } else if (
        popupLocation === "profile" &&
        !toursStages?.profile?.profileFinish
      ) {
        setIsRunning(true);
        tourDispatch({
          type: "PROFILE",
          stepIndex: 0,
        });

        console.log("tourState trigger PROFILE");
      } else if (
        popupLocation == "connection" &&
        !toursStages?.connection?.connectionFinish
      ) {
        console.log("tourState trigger CONNECTION");
        tourDispatch({
          type: "CONNECTION",
          stepIndex: 0,
        });
      } else if (
        popupLocation == "askHelp" &&
        !toursStages?.askHelp?.askHelpFinish
      ) {
        console.log("tourState trigger ASK_HELP");
        tourDispatch({
          type: "ASK_HELP",
          stepIndex: 0,
        });
      } else if (
        popupLocation === "learning" &&
        !toursStages?.learningHub?.learningHubFinish
      ) {
        console.log("tourState trigger ASK_HELP");
        tourDispatch({
          type: "LEARNING",
          stepIndex: 0,
        });
      }
    }

    // if (
    //   !sessionStorage.getItem("tour") &&
    //   tourApiStatus &&
    //   tourNumber !=
    //   TOUR_STEPS.length - 1
    // ) {
    //   console.log("Tour Number", tourNumber);

    //   sessionStorage.setItem("tour", "complete");
    //   setIsRunning(true);
    //   props.openDropDown(tourNumber ? tourNumber : 0);
    // }
  }, [tourApiCalled, popupLocation]);

  // useEffect(() => {
  //   if (isRunning) {
  //     document.body.classList.add("react-joyride-is-anchored");
  //   } else {
  //     document.body.classList.remove("react-joyride-is-anchored");
  //   }
  // }, [isRunning]);

  //When Updating tour steps
  useEffect(() => {
    console.log("HEADER_INITIAL_STATE", HEADER_TOUR_STEPS.length);
    console.log("tour popupLocation", popupLocation);
    console.log("tour tourApiCalled", tourApiCalled);
    if (tourApiCalled) {
      console.log("tour trigger useEffect result", toursStages);
      if (
        popupLocation === "header" &&
        toursStages?.header?.headerFinish &&
        toursStages?.header?.totalHeaderCount <= HEADER_TOUR_STEPS.length
      ) {
        console.log("tour trigger Header", toursStages.header.totalHeaderCount);
        tourDispatch({
          type: "HEADER",
          stepIndex: toursStages?.header?.totalHeaderCount,
        });
      } else if (
        popupLocation == "community" &&
        !toursStages?.community?.communityFinish &&
        toursStages?.header?.totalHeaderCount <= HEADER_TOUR_STEPS.length
      ) {
        tourDispatch({
          type: "COMMUNITY",
          stepIndex: 0,
        });
        console.log("tourState trigger COMMUNITY");
      } else if (
        popupLocation === "home" &&
        !toursStages?.post?.postFinish &&
        toursStages?.header?.totalHeaderCount <= HEADER_TOUR_STEPS.length
      ) {
        tourDispatch({
          type: "HOME",
          stepIndex: 0,
        });
        console.log("tourState trigger Home post");
      } else if (
        popupLocation === "profile" &&
        !toursStages?.profile?.profileFinish &&
        toursStages?.header?.totalProfileCount <= PROFILE_TOUR_STEPS.length
      ) {
        tourDispatch({
          type: "PROFILE",
          stepIndex: 0,
        });

        console.log("tourState trigger PROFILE");
      } else if (
        popupLocation == "connection" &&
        !toursStages?.connection?.connectionFinish &&
        toursStages?.header?.totalConnectionCount <=
          CONNECTION_TOUR_STEPS.length
      ) {
        console.log("tourState trigger CONNECTION");
        tourDispatch({
          type: "CONNECTION",
          stepIndex: 0,
        });
      } else if (
        popupLocation == "askHelp" &&
        !toursStages?.askHelp?.askHelpFinish &&
        toursStages?.header?.totalAskHelpCount <= ASK_HELP_TOUR_STEPS.length
      ) {
        console.log("tourState trigger ASK_HELP");
        tourDispatch({
          type: "ASK_HELP",
          stepIndex: 0,
        });
      }
    }
  }, [tourApiCalled, popupLocation]);

  useEffect(() => {
    if (tourApiCalled) {
      console.log("After trigger 1", popupLocation);
      if (popupLocation !== "") {
        console.log("After trigger");
        dispatch(ofterTriggerPopup(popupLocation));
      }
    }
  }, [tourApiCalled, popupLocation]);

  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      if (!viewList.includes(data.index)) {
        viewList.push(data.index);
      }
      let userFinished = viewList.slice(-1).pop();
      console.log("tour user finish count", popupLocation);
      console.log("tour user finish count", toursStages);
      const updatedList = saveUserTourStatus(
        userFinished,
        popupLocation,
        toursStages
      );
      console.log("updatedList toursStages", toursStages);
      console.log("updatedList", updatedList);

      dispatch(saveTourStatus(updatedList));

      tourDispatch({ type: "STOP" });
      dispatch(triggerTourPopup(""));
      if (popupLocation === "header" && !toursStages?.header?.headerFinish) {
        props.closeTour();
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      let nexIn = index + (action === ACTIONS.PREV ? -1 : 1);
      if (popupLocation === "header" && !toursStages?.header?.headerFinish) {
        props.openDropDown(nexIn);
      }
      console.log("tourState NEXT_OR_PREV", nexIn);
      tourDispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  const saveUserTourStatus = (count, location, toursStages) => {
    switch (location) {
      case "header":
        return {
          header: {
            headerSkipped: count,
            headerFinish: true,
            totalHeaderCount: HEADER_TOUR_STEPS.length,
          },
          profile: {
            ...toursStages.profile,
            totalProfileCount: PROFILE_TOUR_STEPS.length,
          },
          connection: {
            ...toursStages.connection,
            totalConnectionCount: CONNECTION_TOUR_STEPS.length,
          },
          community: {
            ...toursStages.community,
            totalCommunityCount: COMMUNITY_TOUR_STEPS.length,
          },
          post: {
            ...toursStages.post,
            totalPostCount: POST_TOUR_STEPS.length,
          },
          askHelp: {
            ...toursStages.askHelp,
            totalAskHelpCount: ASK_HELP_TOUR_STEPS.length,
          },
          learningHub: {
            ...toursStages.learningHub,
            totalLearningHubCount: LEARNING_TOUR_STEPS.length,
          },
        };
      case "community":
        return {
          ...toursStages,
          header: {
            ...toursStages.header,
            totalHeaderCount: HEADER_TOUR_STEPS.length,
          },
          profile: {
            ...toursStages.profile,
            totalProfileCount: PROFILE_TOUR_STEPS.length,
          },
          connection: {
            ...toursStages.connection,
            totalConnectionCount: CONNECTION_TOUR_STEPS.length,
          },
          community: {
            communitySkipped: count,
            communityFinish: true,
            totalCommunityCount: COMMUNITY_TOUR_STEPS.length,
          },
          post: {
            ...toursStages.post,
            totalPostCount: POST_TOUR_STEPS.length,
          },
          askHelp: {
            ...toursStages.askHelp,
            totalAskHelpCount: ASK_HELP_TOUR_STEPS.length,
          },
          learningHub: {
            ...toursStages.learningHub,
            totalLearningHubCount: LEARNING_TOUR_STEPS.length,
          },
        };
      case "profile":
        return {
          ...toursStages,
          header: {
            ...toursStages.header,
            totalHeaderCount: HEADER_TOUR_STEPS.length,
          },
          profile: {
            profileSkipped: count,
            profileFinish: true,
            totalProfileCount: PROFILE_TOUR_STEPS.length,
          },
          connection: {
            ...toursStages.connection,
            totalConnectionCount: CONNECTION_TOUR_STEPS.length,
          },
          community: {
            ...toursStages.community,
            totalCommunityCount: COMMUNITY_TOUR_STEPS.length,
          },
          post: {
            ...toursStages.post,
            totalPostCount: POST_TOUR_STEPS.length,
          },
          askHelp: {
            ...toursStages.askHelp,
            totalAskHelpCount: ASK_HELP_TOUR_STEPS.length,
          },
          learningHub: {
            ...toursStages.learningHub,
            totalLearningHubCount: LEARNING_TOUR_STEPS.length,
          },
        };
      case "connection":
        return {
          ...toursStages,
          header: {
            ...toursStages.header,
            totalHeaderCount: HEADER_TOUR_STEPS.length,
          },
          profile: {
            ...toursStages.profile,
            totalProfileCount: PROFILE_TOUR_STEPS.length,
          },
          connection: {
            connectionSkipped: count,
            connectionFinish: true,
            totalConnectionCount: CONNECTION_TOUR_STEPS.length,
          },
          community: {
            ...toursStages.community,
            totalCommunityCount: COMMUNITY_TOUR_STEPS.length,
          },
          post: {
            ...toursStages.post,
            totalPostCount: POST_TOUR_STEPS.length,
          },
          askHelp: {
            ...toursStages.askHelp,
            totalAskHelpCount: ASK_HELP_TOUR_STEPS.length,
          },
          learningHub: {
            ...toursStages.learningHub,
            totalLearningHubCount: LEARNING_TOUR_STEPS.length,
          },
        };
      case "home":
        return {
          ...toursStages,
          header: {
            ...toursStages.header,
            totalHeaderCount: HEADER_TOUR_STEPS.length,
          },
          profile: {
            ...toursStages.profile,
            totalProfileCount: PROFILE_TOUR_STEPS.length,
          },
          connection: {
            ...toursStages.connection,
            totalConnectionCount: CONNECTION_TOUR_STEPS.length,
          },
          community: {
            ...toursStages.community,
            totalCommunityCount: COMMUNITY_TOUR_STEPS.length,
          },
          post: {
            postSkipped: count,
            postFinish: true,
            totalPostCount: POST_TOUR_STEPS.length,
          },
          askHelp: {
            ...toursStages.askHelp,
            totalAskHelpCount: ASK_HELP_TOUR_STEPS.length,
          },
          learningHub: {
            ...toursStages.learningHub,
            totalLearningHubCount: LEARNING_TOUR_STEPS.length,
          },
        };
      case "askHelp":
        return {
          ...toursStages,
          header: {
            ...toursStages.header,
            totalHeaderCount: HEADER_TOUR_STEPS.length,
          },
          profile: {
            ...toursStages.profile,
            totalProfileCount: PROFILE_TOUR_STEPS.length,
          },
          connection: {
            ...toursStages.connection,
            totalConnectionCount: CONNECTION_TOUR_STEPS.length,
          },
          community: {
            ...toursStages.community,
            totalCommunityCount: COMMUNITY_TOUR_STEPS.length,
          },
          post: {
            ...toursStages.post,
            totalPostCount: POST_TOUR_STEPS.length,
          },
          askHelp: {
            askHelpFinish: true,
            askHelpSkipped: count,
            totalAskHelpCount: ASK_HELP_TOUR_STEPS.length,
          },
          learningHub: {
            ...toursStages.learningHub,
            totalLearningHubCount: LEARNING_TOUR_STEPS.length,
          },
        };
      case "learning":
        return {
          ...toursStages,
          header: {
            ...toursStages.header,
            totalHeaderCount: HEADER_TOUR_STEPS.length,
          },
          profile: {
            ...toursStages.profile,
            totalProfileCount: PROFILE_TOUR_STEPS.length,
          },
          connection: {
            ...toursStages.connection,
            totalConnectionCount: CONNECTION_TOUR_STEPS.length,
          },
          community: {
            ...toursStages.community,
            totalCommunityCount: COMMUNITY_TOUR_STEPS.length,
          },
          post: {
            ...toursStages.post,
            totalPostCount: POST_TOUR_STEPS.length,
          },
          askHelp: {
            ...toursStages.askHelp,
            totalAskHelpCount: ASK_HELP_TOUR_STEPS.length,
          },
          learningHub: {
            learningHubSkipped: count,
            learningHubFinish: true,
            totalLearningHubCount: LEARNING_TOUR_STEPS.length,
          },
        };
      default:
        return toursStages;
    }
  };

  // const saveUserTourStatus = (count, location, toursStages) => {
  //   var updatedToursStages = toursStages;

  //   if (location == "header") {
  //     updatedToursStages = {
  //       ...toursStages,
  //       header: {
  //         headerSkipCount: count,
  //         headerFinish: true,
  //         headerTotalCount: HEADER_TOUR_STEPS.length,
  //       },
  //       profile: {
  //         ...toursStages.profile,
  //         totalProfileCount: PROFILE_TOUR_STEPS.length,
  //       },
  //     };
  //   } else if (location == "community") {
  //     updatedToursStages = {
  //       ...toursStages,
  //       community: {
  //         community: count,
  //         communityFinish: true,
  //       },
  //     };
  //   } else if (location == "home") {
  //     updatedToursStages = {
  //       ...toursStages,
  //       post: {
  //         post: count,
  //         postFinish: true,
  //       },
  //     };
  //   } else if (location == "profile") {
  //     updatedToursStages = {
  //       ...toursStages,
  //       profile: {
  //         profile: count,
  //         profileFinish: true,
  //       },
  //     };
  //   } else if (location == "connection") {
  //     updatedToursStages = {
  //       ...toursStages,
  //       connection: {
  //         connection: count,
  //         connectionFinish: true,
  //       },
  //     };
  //   } else if (location == "askHelp") {
  //     updatedToursStages = {
  //       ...toursStages,
  //       askHelp: {
  //         askHelp: count,
  //         askHelpFinish: true,
  //       },
  //     };
  //   }

  //   return updatedToursStages;
  // };

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        continuous={true}
        scrollToFirstStep={false}
        // showProgress
        showSkipButton={true}
        class="tour-option"
        showCloseButton={false}
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#fff",
            primaryColor: "#fff",
            zIndex: 10000,
          },
          tooltipContainer: {
            textAlign: "left",
          },
          buttonNext: {
            backgroundColor: "white",
            color: "white",
          },
          buttonClose: {
            display: "none",
          },

          buttonSkip: {
            backgroundColor: "white",
            color: "white",
          },
        }}
        locale={{
          last: "End tour",
        }}
        scrollOffset={70}
        scrollToSteps={false}
        preventScrolling={false}
        disableScrolling={true}
      />
    </>
  );
};

export default Tour;
