import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import FriendsFinder from "../../api/FriendsFinder";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
import { CardContent, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import banner from "../../img/Yuukke_Cover.jpg";
import ForumService from "../../api/ForumService";
import moment from "moment";
import ProfileService from "../../api/ProfileService";
import * as APPCONFIG from "../../config/app-config.js";
import FriendUserImg from "./FriendUserImg";
import FriendAbout from "./FriendAbout";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { GoLocation } from "react-icons/go";
import { BsCalendarDate } from "react-icons/bs";
import FriendExperience from "./FriendExperience";
import FriendConnections from "./FriendConnections";
import { MdWorkOutline } from "react-icons/md";
import FriendConnectionList from "./FriendConnectionList";
import "./myFriend.css";
import { API_URL } from "../../config/app-config";
import dummyimage from "../../img/default_avatar.jpg";
import FriendProfileInfo from "./FriendProfileInfo";
import FriendPosts from "./FriendPosts";
import PostService from "../../api/PostService_old";
import { timingSafeEqual } from "crypto";
import dummy_image from "../../img/banner.jpg";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Form,
  Modal,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap";
import {
  getAllChatFriendsList,
  getFriendsListBasedWorkSpace,
  getNavigationType,
  needPhotVerification,
} from "../../redux/actions/commonApiActions";
import Swal from "sweetalert2";
import FindFriendsFilter from "../../api/FindFriendsFilter";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { getRouterPath, giveUserAction, premiumUser } from "../../utils";
import { CgUnblock } from "react-icons/cg";
import MarketPlaceCard from "../../components/MarketPlaceCard.jsx";
import BlockUi from "react-block-ui";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { CgArrowTopRightR } from "react-icons/cg";

class FriendDescription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.match.params.userId,
      tabIndex: 0,
      pageNo: 0,
      friendsList: [],
      userKnowledgepoint: "",
      coverPhotoImage: "",
      userPhotoImage: "",
      areaOfInterest: [],
      userName: "",
      country_value: "",
      biography: "",
      professionID: "",
      dob: "",
      location: "",
      openAbout: false,
      openPost: true,
      openConnection: false,
      userPostList: [],
      professionList: [
        {
          id: 1,
          name: "student",
        },
        {
          id: 2,
          name: "Working professional",
        },
        {
          id: 3,
          name: "practicing profession (Auditoer,lawyer and etc)",
        },
        {
          id: 4,
          name: "Entreprenur",
        },
        {
          id: 5,
          name: "Homemaker",
        },
        {
          id: 6,
          name: "Freelancer",
        },
      ],
      reportDescription: "",
      reportStatus: false,
      blockStatus: false,
      noMoreState: false,
      userType: "",
      requestPopup: false,
      blockedUser: false,
      premiumUser: "",
      friendsCount: 0,
      openMarketCard: false,
      professionData: "",
      seller: false,
      blocking: true,
      userEmail: "",
      directoryPresent: false,
    };
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getFriendsDetailsbyUserid = this.getFriendsDetailsbyUserid.bind(this);
    this.getAllIndustry = this.getAllIndustry.bind(this);
    this.loadFriendsDetailsValue = this.loadFriendsDetailsValue.bind(this);
    this.getIndustryDetails = this.getIndustryDetails.bind(this);
    this.getAllAreaofInterest = this.getAllAreaofInterest.bind(this);
    this.getAreaofInterestDetails = this.getAreaofInterestDetails.bind(this);
    this.getTrackAndCurriculum = this.getTrackAndCurriculum.bind(this);
    this.Findprofession = this.Findprofession.bind(this);
    this.getCoverPhoto = this.getCoverPhoto.bind(this);
    this.gotoabout = this.gotoabout.bind(this);
    this.gotoconnections = this.gotoconnections.bind(this);
    this.gotoposts = this.gotoposts.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.filterOption = this.filterOption.bind(this);
    this.movetoconnections = this.movetoconnections.bind(this);
    this.reportUserPopUp = this.reportUserPopUp.bind(this);
    this.blockUserPopUp = this.blockUserPopUp.bind(this);
  }

  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
    });
  }
  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    console.log("++START================>");
    this.filterOption();

    if (this.props.match.params.userId) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      console.log(`user id ${this.props.match.params.userId}`);
      this.setState(
        {
          userId: this.props.match.params.userId,
        },
        () => {
          this.getFriendsDetailsbyUserid(this.props.match.params.userId);
          // this.getCoverPhoto(this.props.match.params.userId);
          // this.getFriendsList();
        }
      );
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.match.params.userId !== nextProps.match.params.userId) {
  //     this.setState(
  //       {
  //         userId: nextProps.match.params.userId,
  //       },
  //       () => {
  //         this.getFriendsDetailsbyUserid(nextProps.match.params.userId);
  //         this.getCoverPhoto(nextProps.match.params.userId);
  //         this.getFriendsList();
  //       }
  //     );
  //   }
  // }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.userId !== this.props.match.params.userId) {
      this.setState(
        {
          userId: this.props.match.params.userId,
        },
        () => {
          window.scrollTo(0, 0);
          this.filterOption();
          this.getFriendsDetailsbyUserid(this.props.match.params.userId);
          // this.getCoverPhoto(this.props.match.params.userId);
          // this.getFriendsList();
        }
      );
    }
  }

  render() {
    const { t } = this.props;

    const { tabIndex } = this.state;
    const { location } = this.props;

    return (
      <>
        <div
          style={{
            marginTop: "0px",
            marginRight: "12px",
            marginLeft: "0px",
            marginBottom: "12px",
          }}
          className="ansBackQuestion flexDefault"
          onClick={this.movetoconnections}
        >
          <ArrowBackIcon />
          {/* {this.props.friendsPic !== undefined && this.props.friendsPic && (
              <p>{t("Back to FriendsList")}</p>
            )} */}
          {<p>{t("Back")}</p>}
        </div>

        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} lg={8} md={8}>
            <div class="card">
              <div class="h-200px rounded-top friend-cover-img">
                <img
                  class="h-200px rounded-top friend-cover-img"
                  src={
                    this.state.coverPhotoImage
                      ? `${APPCONFIG.API_URL}auth/image/${this.state.coverPhotoImage}`
                      : banner
                  }
                  alt="Cover Photo"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = banner;
                  }}
                />
              </div>

              <div class="card-body py-0">
                <div class="d-sm-flex align-items-start text-center text-sm-start flex-wrap">
                  <div>
                    <div class="friend-avatar friend-avatar-xxl mt-n5 mb-3">
                      <img
                        class="friend-avatar-img rounded-circle border border-white border-3"
                        src={
                          API_URL +
                          "api/getUserPhoto/" +
                          this.state.userId +
                          "/" +
                          this.state.userName
                        }
                        alt="user"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = dummyimage;
                        }}
                      />
                    </div>
                  </div>
                  <div class="ms-sm-4 mt-sm-3">
                    <h1 class="mb-0 description-h5 d-flex align-items-center  w-content">
                      {this.state.userName}{" "}
                      {this.state.premiumUser &&
                        premiumUser(this.state.premiumUser) && (
                          <img
                            src={premiumUser(this.state.premiumUser)}
                            alt="img"
                            className="ml-2 mt-auto ml-1"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "8px",
                            }}
                          />
                        )}
                      <i class="bi bi-patch-check-fill text-success small"></i>
                    </h1>
                    {this.state.directoryPresent && (
                      <p
                        onClick={() => this.viewDirectory()}
                        className="bg-color mt-1 c-pointer"
                        style={{
                          fontWeight: "600",
                          fontSize: "13px",
                          width: "fit-content",
                        }}
                      >
                        {t("View My Service")}{" "}
                        <CgArrowTopRightR className="ml-1" />{" "}
                      </p>
                    )}
                    {/* <p className="about-mb-2" style={{ float: "left" }}>
                        <p>{this.state.friendsList.length} -</p> connections
                      </p> */}
                  </div>
                  <div class="flex-grow-1"></div>
                  {this.props.match.params.userId !=
                    this.props.loginDetails.uId && (
                    <div
                      class="mt-3  d-flex align-items-center justify-content-center flex-wrap"
                      style={{ width: "100%", gap: "1rem" }}
                    >
                      {!this.state.blockedUser && (
                        <button
                          class="btn mb-0 report-btn ml-auto"
                          onClick={() =>
                            this.handleReportPopup(
                              this.props.match.params.userId
                            )
                          }
                        >
                          {" "}
                          {t("Report")}
                        </button>
                      )}
                      <button
                        class={
                          this.state.blockedUser
                            ? ""
                            : "btn connectionredLabel mb-0 me-2 "
                        }
                        onClick={() =>
                          this.handleBlockPopup(this.props.match.params.userId)
                        }
                      >
                        {" "}
                        {this.state.blockedUser && (
                          <CgUnblock className="svg-icons-dir mr-1" />
                        )}
                        {this.state.blockedUser ? t("Unblock") : t("Block")}
                      </button>

                      {!this.state.blockedUser &&
                        this.state.userType &&
                        this.state.userType !== "me" && (
                          <button
                            className={
                              this.state.userType === "notFriends"
                                ? "btn mb-0 add-btn add-btn-green"
                                : "btn mb-0 add-btn"
                            }
                            onClick={() => this.handleUser(this.state.userType)}
                          >
                            {this.state.userType === "friends" ? (
                              <>{t("Remove Friend")}</>
                            ) : this.state.userType === "requestedFriends" ? (
                              <>
                                <>{t("Requested")}</>
                              </>
                            ) : this.state.userType === "requestPending" ? (
                              <> {t("Request Pending")}</>
                            ) : (
                              this.state.userType === "notFriends" && (
                                <> {t("Add Friend")}</>
                              )
                            )}
                          </button>
                        )}
                    </div>
                  )}
                  {/* <div class="d-flex mt-3 justify-content-center ms-sm-auto">
                                    <button class="btn btn-danger-soft me-2" type="button"> <i class="bi bi-pencil-fill pe-1"></i> Edit profile </button>
                                    <div class="dropdown">
                                        <button class="icon-md btn btn-light" type="button" id="profileAction2" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="bi bi-three-dots"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profileAction2">
                                            <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark fa-fw pe-2"></i>Share profile in a message</a></li>
                                            <li><a class="dropdown-item" href="#"> <i class="bi bi-file-earmark-pdf fa-fw pe-2"></i>Save your profile to PDF</a></li>
                                            <li><a class="dropdown-item" href="#"> <i class="bi bi-lock fa-fw pe-2"></i>Lock profile</a></li>
                                            <li><hr class="dropdown-divider" /></li>
                                            <li><a class="dropdown-item" href="#"> <i class="bi bi-gear fa-fw pe-2"></i>Profile settings</a></li>
                                        </ul>
                                    </div>
                                </div> */}
                </div>
                <ul
                  class="mt-3 mb-1 p-0 list-none text-secondary d-flex flex-wrap align-items-center"
                  style={{ gap: "1rem", listStyle: "none" }}
                >
                  {this.state.professionData && (
                    <li class="list-inline-item friend-text-correction">
                      <MdWorkOutline className="friend-about-card"></MdWorkOutline>{" "}
                      {this.state.professionData}
                    </li>
                  )}
                  {this.state.location && (
                    <li>
                      <GoLocation className="friend-about-card"></GoLocation>{" "}
                      {this.state.location}
                    </li>
                  )}
                  {this.state.joinDate && (
                    <li>
                      {" "}
                      <BsCalendarDate className="friend-about-card mr-3"></BsCalendarDate>
                      {t("Joined on")}{" "}
                      {moment(this.state.joinDate).locale("en").format("ll")}
                    </li>
                  )}
                </ul>
              </div>
              <div class="main-card-footer mt-3 pt-2 pb-0">
                <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
                  <li class="nav-item">
                    {" "}
                    <a
                      style={{ float: "left", cursor: "pointer" }}
                      class={
                        this.state.openPost
                          ? "nav-link active nav-link-body-sideMenu-active"
                          : "nav-link nav-link-body"
                      }
                      onClick={this.gotoposts}
                    >
                      {t("Posts")}
                    </a>{" "}
                  </li>
                  <li class="nav-item">
                    {" "}
                    <a
                      style={{ float: "left", cursor: "pointer" }}
                      class={
                        this.state.openAbout
                          ? "nav-link active nav-link-body-sideMenu-active"
                          : "nav-link nav-link-body"
                      }
                      onClick={this.gotoabout}
                    >
                      {" "}
                      {t("About")}{" "}
                    </a>{" "}
                  </li>
                  <li class="nav-item">
                    {" "}
                    <a
                      style={{ float: "left", cursor: "pointer" }}
                      class={
                        this.state.openConnection
                          ? "nav-link active nav-link-body-sideMenu-active"
                          : "nav-link nav-link-body"
                      }
                      onClick={this.gotoconnections}
                    >
                      {t("Connections")}
                      <span class="greenLabelConnection">
                        {this.props.totalFriends}
                      </span>{" "}
                    </a>{" "}
                  </li>
                  {this.state.seller && (
                    <li class="nav-item">
                      {" "}
                      <a
                        style={{ float: "left", cursor: "pointer" }}
                        class={
                          this.state.openMarketCard
                            ? "nav-link active nav-link-body-sideMenu-active"
                            : "nav-link nav-link-body"
                        }
                        onClick={this.gotoMarketCard}
                      >
                        {t("Products")}
                      </a>{" "}
                    </li>
                  )}
                </ul>
              </div>
            </div>
            {this.state.openPost &&
              (this.state.blockedUser ? (
                <>
                  <div style={{ textAlign: "center", marginTop: "12px" }}>
                    <Card>
                      <CardContent>
                        <center>
                          <p style={{ textAlign: "center", marginTop: "12px" }}>
                            {t("No  Post Available")}
                          </p>
                        </center>
                      </CardContent>
                    </Card>
                    {/* <h6>No More Post Available</h6> */}
                  </div>
                </>
              ) : (
                <BlockUi
                  tag="div"
                  message={t("Loading, please wait")}
                  style={{ minHeight: "134px" }}
                  className="wallLoadingPage"
                  blocking={this.state.blocking}
                >
                  <FriendPosts
                    userPostList={this.state.userPostList}
                    loadMorePosts={this.loadMorePosts}
                    likeStatus={this.likestatusUpdate}
                    noMoreState={this.state.noMoreState}
                    blockStatus={this.state.blocking}
                  ></FriendPosts>
                </BlockUi>
              ))}
            {this.state.openAbout && (
              <FriendProfileInfo
                areaOfInterest={this.state.areaOfInterest}
                email={this.state.email}
                bio={this.state.biography}
                dob={this.state.dob}
                country_value={this.state.country_value}
                country={this.state.location}
              ></FriendProfileInfo>
            )}
            {this.state.openConnection && (
              <FriendConnectionList
                // friendsList={this.state.friendsList}
                loadFriendsList={this.getFriendsList}
                userId={this.state.userId}
              ></FriendConnectionList>
            )}
            {this.state.openMarketCard && (
              <MarketPlaceCard userId={this.state.userId} />
            )}
          </Grid>
          <Grid item sm={12} xs={12} lg={4} md={4}>
            <FriendAbout
              style={{ marginBottom: "20px" }}
              email={this.state.email}
              bio={this.state.biography}
              dob={this.state.dob}
              country={this.state.location}
            ></FriendAbout>
            <FriendExperience
              style={{ marginBottom: "20px" }}
              workExperience={this.state.workExperience}
            ></FriendExperience>
            <FriendConnections
              style={{ marginBottom: "20px" }}
              // friendsList={this.state.friendsList}
              loadFriendsList={this.getFriendsList}
              userId={this.state.userId}
            ></FriendConnections>
          </Grid>
        </Grid>

        {this.state.reportStatus && this.reportUserPopUp()}
        {this.state.blockStatus && this.blockUserPopUp()}
        {this.state.requestPopup && this.requestStatusPopUp()}
      </>
    );
  }
  likestatusUpdate = (result, userLike) => {
    var userPostList = this.state.userPostList;
    var postId = result.data.postId;
    var like = result.data.userLike;
    var allLikes = result.data.allLikes;
    if (like !== undefined) {
      const finalResult = userPostList.map((person) => {
        if (person.postId === postId) {
          person.userlike = userLike;
          person.userlikeType = userLike ? like.likeReactionType : "";
          person.likesCount = result.data.likeCount;
          person.allLikes = allLikes;
        }
        return person;
      });
      console.log("finalResult : ", finalResult);
      this.setState({
        userPostList: finalResult,
      });
      console.log("state value changed : ", this.state.postData);
    }
  };
  filterOption(data) {
    console.log(data);
    var filterKey = [];
    filterKey.push(this.props.match.params.userId);
    if (filterKey.length > 0) {
      var data = {
        page: 0,
        filterType: "friends",
        filterKey,
      };

      console.log(data);
    }
    this.setState({ blocking: true });

    PostService.getPostByFilter(data)
      .then((response) => {
        console.log("old 10 post");
        this.setState({ blocking: false });
        if (response.data.status === 1) {
          if (response.data.data.totalPages - 1 > this.state.pageNo) {
            this.setState({ noMoreState: true });
          } else {
            this.setState({ noMoreState: false });
          }
          this.setState({
            userPostList: response.data.data.userpost,
            firstTimeSetup: response.data.data.firstTimeSetup,
            pageNo: this.state.pageNo + 1,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleReportPopup = (id) => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      this.setState({
        reportStatus: !this.state.reportStatus,
      });
    }
  };
  handleBlockPopup = (id) => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      this.setState({
        blockStatus: !this.state.blockStatus,
      });
    }
  };
  reportUserPopUp() {
    const { t } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Modal show="true" onHide={this.handleReportPopup}>
          <Modal.Header>{t("ADD DESCRIPTION FOR USER REPORT")}</Modal.Header>
          <div
            className="tab-pane-view"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="profile"
          >
            <div className="profileTabContent">
              <Form
                id="profileForm"
                noValidate
                validated={this.state.validated}
                onSubmit={this.reportAUser}
              >
                <Card className="profileCard">
                  <Card.Body>
                    <div className="row ">
                      <div className="col-xs-12 col-md-12">
                        <Form.Group className="formInput">
                          <Form.Label></Form.Label>
                          <textarea
                            name="reportDescription"
                            style={{ width: "100%" }}
                            rows="4"
                            cols="50"
                            value={this.state.reportDescription}
                            onChange={this.handleForm}
                          ></textarea>
                          <Form.Control.Feedback type="invalid">
                            {t("Please Enter Description")}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <br />
                      </div>
                    </div>
                  </Card.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={this.handleReportPopup}
                    >
                      {t("Close")}
                    </Button>
                    <Button type="submit" variant="primary">
                      {t("Submit")}
                    </Button>
                  </Modal.Footer>
                </Card>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  handelRequest = () => {
    this.setState({
      requestPopup: !this.state.requestPopup,
    });
  };
  friendAccept = () => {
    let friendAccept = {
      userId: this.props.match.params.userId,
      requestStatus: "accepted",
    };
    FriendsFinder.friendAcceptV2(friendAccept)
      .then((response) => {
        console.log("notificationId >>", response.data.notificationId);
        toast.success("Accept successfully...");
        this.setState({
          userType: "friends",
          requestPopup: !this.state.requestPopup,
        });
        this.props.getAllChatFriendsList();
        this.props.getFriendsListBasedWorkSpace();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  friendReject = () => {
    let friendReject = {
      friendsId: this.props.match.params.userId,
      requestStatus: "rejected",
    };
    FriendsFinder.friendReject(friendReject)
      .then((response) => {
        this.setState({
          userType: "notFriends",
          requestPopup: !this.state.requestPopup,
        });
        toast.success("Rejected successfully...");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  requestStatusPopUp() {
    const { t } = this.props;
    return (
      <div>
        <Modal show="true" onHide={this.handelRequest}>
          <Modal.Header>
            {t("Request Friend")}
            <AiOutlineCloseCircle
              onClick={this.handelRequest}
              style={{
                cursor: "pointer",
                float: "right",
                marginBottom: "8px",
                width: "20px",
                height: "20px",
                color: "#000000",
              }}
            />
          </Modal.Header>
          <Form id="profileForm" noValidate validated={this.state.validated}>
            <Modal.Body>
              <Form.Group className="formInput">
                <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                  {t("Do you want to Respond to the request ?")}
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                className={{ marginRight: "1rem" }}
                onClick={() => this.friendReject()}
              >
                {t("Ignore")}
              </Button>
              <Button variant="primary" onClick={() => this.friendAccept()}>
                {t("Accept")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
  blockUserPopUp() {
    const { t } = this.props;
    return (
      <div>
        <Modal show="true" onHide={this.handleBlockPopup}>
          <Modal.Header>
            {this.state.blockedUser ? t("Unblock User") : t("Block User")}
          </Modal.Header>

          <Form
            id="profileForm"
            noValidate
            validated={this.state.validated}
            onSubmit={this.blockAUser}
          >
            <Modal.Body>
              <Form.Group className="formInput">
                <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                  {this.state.blockedUser
                    ? t("Are you sure to unblock this user")
                    : t("Are you sure to block this user")}
                  ?
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleBlockPopup}>
                {t("Close")}
              </Button>
              <Button type="submit" variant="primary">
                {this.state.blockedUser ? t("Unblock User") : t("Block User")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
  reportAUser = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.reportDescription === "") {
      toast.warn(`${this.props.t("Please enter valid details")}`);
    } else {
      let data = {
        reportUserId: this.props.match.params.userId,
        description: this.state.reportDescription,
      };
      ForumService.reportAUser(data)
        .then((response) => {
          //   this.handleReportPopup();
          toast.success(`${this.props.t("User Reported SuccessFully")}`);
          this.props.getNavigationType(0);
          this.props.history.push(`/${this.props.workspaceType}/home`);
        })
        .catch((error) => {
          this.handleReportPopup();
          //   this.props.history.push("/home");
          toast.error(`${this.props.t("Saving Error")}`);
        });
    }
  };
  blockAUser = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var data = { blockUserId: this.props.match.params.userId };

    if (this.state.blockedUser) {
      FriendsFinder.unBlockAUser(data)
        .then((response) => {
          toast.success(`${this.props.t("User unblocked successfully")}`);
          this.setState({
            blockedUser: !this.state.blockedUser,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      ForumService.blockAUser(data)
        .then((response) => {
          toast.success(`${this.props.t("User blocked successFully")}`);
          this.setState({
            blockedUser: !this.state.blockedUser,
          });
          // this.props.getNavigationType(0);
          // this.props.history.push(`/${this.props.workspaceType}/mynetwork`);
        })
        .catch((error) => {
          // this.props.history.push(`/${this.props.workspaceType}/mynetwork`);
          toast.error(`${this.props.t("Updating error")}`);
        });
    }
    this.setState({
      blockStatus: false,
    });
  };
  handleForm = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  async getIndustryDetails(industryList) {
    console.log("loginDetails", this.props.loginDetails);
    console.log(industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        if (this.state.industryId === industryList[i].industryId) {
          this.setState({
            industrys: industryList[i].industryName,
          });
        }
      }
    }
  }

  // getBase64String(userId) {
  //   let getBase64String = { userId: userId };
  //   var response = ForumService.getUserPhotoCache(getBase64String);
  //   console.log(response);
  //   if (response) {
  //     var value = response.data.data;
  //     if (value && value.userPhoto) {
  //       this.setState({
  //         userPhotoImage: response.data.data.userPhoto,
  //         base64StringState: true,
  //       });
  //     }
  //   } else {
  //     ForumService.getUserPhoto(getBase64String)
  //       .then((response) => {
  //         if (
  //           response &&
  //           response.data &&
  //           response.data.data &&
  //           response.data.data.userPhoto
  //         ) {
  //           ForumService.setUserPhotoCache(getBase64String, response);
  //           this.setState({
  //             userPhotoImage: response.data.data.userPhoto,
  //             base64StringState: true,
  //           });
  //         } else {
  //           ForumService.setUserPhotoCache(getBase64String, response);
  //           this.setState({
  //             userPhotoImage: response.data.data.userPhoto,
  //             base64StringState: true,
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // }

  async getFriendsDetailsbyUserid() {
    let usersId = {
      userId: this.state.userId,
    };
    FriendsFinder.getProfileDetails(usersId)
      .then((response) => {
        var result = response.data.data;
        console.log("user result", result);

        this.setState({
          blockedUser: result.blockUser,
          userName: result.userName,
          joinDate: result.joinDate,
          email: result.email,
          biography: result.biography,
          areaOfInterest: result.tagsValue,
          country_value: result.countryValue,
          professionData: result.professionValue,
          seller: result.isVerifySeller,
          location: result.location,
          directoryPresent: result.directoryPresent,
          // twitter: result.data.user.userProfile.userProfile.twitter,
          // linkedin: result.data.user.userProfile.userProfile.linkedin,
          // facebook: result.data.user.userProfile.userProfile.facebook,
          coverPhotoImage: result.coverPhoto,
          // workExperience: result.data.user.userProfile.workExperience,
          dob: result.dob,
          userType: result.type,
          premiumUser: result.premiumData,
          friendsCount: result.friendsCount,
          userEmail: result.userEmail,
        });

        console.log(
          "result.data.user.userProfile.workExperience",
          result.data.user.userProfile.workExperience
        );

        this.setState({ hello: true });
        // this.getAreaofInterestDetails(this.state.areaOfInterest);
        // this.Findprofession(this.state.professionID);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadFriendsDetailsValue(result) {
    // this.setState({
    //     userName:
    //         result.dName &&
    //             result.dName !== "" &&
    //             result.dName !== "null" &&
    //             result.dName !== " "
    //             ? result.dName
    //             : result.firstName + " " + result.lastName,
    // });
    // this.setState({ hashTag: result.hashTag });
    // this.setState({ designation: result.biography });
    // this.setState({ linkedin: result.userProfile.userProfile.linkedin });
    // this.setState({ facebook: result.userProfile.userProfile.facebook });
    // this.setState({
    //     twitter: result.userProfile.userProfile.twitter,
    //     companyName: result.userProfile.userProfile.companyName,
    //     industryId: result.userProfile.userProfile.industryId,
    //     gender: result.userProfile.userProfile.gender,
    //     personalLanguage: result.userProfile.userProfile.personalLanguage,
    //     dob: moment(result.userProfile.userProfile.dob).format("MM/DD/yyyy"),
    //     areaOfInterest: result.userProfile.userProfile.areaOfInterest,
    //     jobFunction: result.userProfile.userProfile.schoolId,
    //     state: result.userProfile.userProfile.gradeId,
    //     firstName: result.userProfile.userProfile.firstName,
    //     lastName: result.userProfile.userProfile.lastName,
    //     curriculumId: result.userProfile.userProfile.curriculumId,
    //     gradeId: result.userProfile.userProfile.gradeId,
    //     professionId: result.userProfile.userProfile.professionId,
    // });
  }

  getFriendsList() {
    let getFriendsList = {
      userId: this.state.userId,
    };

    FriendsFinder.friendslistByUserId(getFriendsList)
      .then((response) => {
        var result = response.data;
        this.setState({ friendsList: result.data.friendsList });
        console.log("this.state.friendsList");
        console.log(this.state.friendsList);
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({ friendsList: [] });
  }

  gotoposts() {
    this.setState({
      openMarketCard: false,
      openPost: true,
      openAbout: false,
      openConnection: false,
    });
  }

  gotoabout() {
    this.setState({
      openMarketCard: false,
      openAbout: true,
      openPost: false,
      openConnection: false,
    });
  }

  gotoconnections() {
    this.setState({
      openMarketCard: false,
      openPost: false,
      openAbout: false,
      openConnection: true,
    });
  }
  gotoMarketCard = () => {
    this.setState({
      openMarketCard: true,
      openPost: false,
      openAbout: false,
      openConnection: false,
    });
  };
  getAllIndustry() {
    ProfileService.getAllIndustry()
      .then((response) => {
        var result = response.data.data;
        this.getIndustryDetails(result);
        console.log("Id", result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAllAreaofInterest() {
    let data = {
      data: "",
    };
    ProfileService.getAreaOfInterest(data)
      .then((response) => {
        var result = response.data.data.user;
      })
      .catch((error) => {});
  }

  async getAreaofInterestDetails(areaofinterestList) {
    if (areaofinterestList) {
      var lList = [];
      for (var i = 0; i < areaofinterestList.length; i++) {
        // if (this.state.areaOfInterest === areaofinterestList[i].areaId) {
        this.setState({
          areaofInterest: areaofinterestList[i].area_of_interest_value,
        });
        console.log("areaofinterestsList");
        console.log(this.state.areaOfInterest);
        // }
      }

      // await this.setState({ areaofinterestsList: lList });
      // console.log(lList);

      // console.log(this.state.areaofinterestsList);
    }
  }
  Findprofession(professionID) {
    if (professionID === 1) {
      this.setState({
        professionData: "Student",
      });
    } else if (professionID === 2) {
      this.setState({
        professionData: "Working professional",
      });
    } else if (this.state.professionID === 3) {
      this.setState({
        professionData: "practicing profession (Auditoer,lawyer and etc)",
      });
    } else if (professionID === 4) {
      this.setState({
        professionData: "Entreprenur",
      });
    } else if (professionID === 5) {
      this.setState({
        professionData: "Homemaker",
      });
    } else if (professionID === 6) {
      this.setState({
        professionData: "Freelancer",
      });
    }
  }

  getTrackAndCurriculum() {
    var langs = {
      lang: this.props.personalLanguage,
    };
    ProfileService.getTrackAndCurriculumData(langs)
      .then((response) => {
        var result = response.data;

        this.getCuriculumDetails(result.data.curriculum);
      })
      .catch((error) => {});
  }

  movetoconnections() {
    if (this.props.location?.state?.from) {
      this.props.history.push(this.props.location?.state?.from);
    } else {
      this.props.history.push(`/${this.props.workspaceType}/mynetwork`);
    }
    // if (this.props.friendsPic !== undefined && this.props.friendsPic) {
    //   this.props.history.push("/my-profile-friends");
    // } else {
    //   this.props.history.push(`/${this.props.workspaceType}/mynetwork`);
    // }
  }

  getCuriculumDetails(curriculumList) {
    if (curriculumList) {
      var lList = [];

      for (var i = 0; i < curriculumList.length; i++) {
        if (this.state.curriculumId === curriculumList[i].curriculumId) {
          this.setState({
            Region: curriculumList[i].curriculumName,
          });
        }
      }
    } else {
      console.log("No curriculum present");
    }
    for (var i = 0; i < curriculumList.length; i++) {
      console.log("value", curriculumList[i].grade.length);
      for (var j = 0; j < curriculumList[i].grade.length; j++) {
        if (this.state.gradeId === curriculumList[i].grade[j].gradeId) {
          this.setState({
            State: curriculumList[i].grade[j].gradeName,
          });
        }
      }
    }
  }

  getCoverPhoto(uid) {
    let coverPhoto = {
      userId: uid,
    };
    var response = ForumService.getUserCoverPhotoCache(coverPhoto);
    console.log(response);
    if (response) {
      var value = response.data.data;
      if (value && value.coverPhoto) {
        this.setState({ coverPhotoImage: response.data.data.coverPhoto });
      }
    } else {
      // ProfileService.getUsercoverPhoto(coverPhoto)
      //   .then((response) => {
      //     var result = response.data;
      //     ForumService.setUserCoverPhotoCache(coverPhoto, response);
      //     this.setState({
      //       coverPhotoImage: result.data.coverPhoto,
      //     });
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  }

  loadMorePosts = () => {
    var filterKey = [];

    filterKey.push(this.state.userId ? this.state.userId : 0);
    if (filterKey.length > 0) {
      var data = {
        page: this.state.pageNo,
        filterType: "friends",
        filterKey,
      };

      console.log(data);
    }
    this.setState({ blocking: true });

    PostService.getPostByFilter(data)
      .then((response) => {
        console.log("old 10 post");
        this.setState({ blocking: false });
        console.log(response.data.data.userpost);

        if (response.data.data.userpost.length > 0) {
          if (response.data.data.totalPages - 1 > this.state.pageNo) {
            this.setState({ noMoreState: true, pageNo: this.state.pageNo + 1 });
          } else {
            this.setState({ noMoreState: false });
          }

          if (this.state.userPostList.length > 0) {
            var oldPostList = this.state.userPostList;
            var newPosts = oldPostList.concat(response.data.data.userpost);
            this.setState({ userPostList: newPosts });
          } else {
            var newPosts = [response.data.data.userpost];
            this.setState({ userPostList: newPosts });
          }
        } else {
          this.setState({ noMoreState: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleUser = (type) => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      if (type === "notFriends") {
        Swal.fire({
          // title: logoutTitle,
          text: `${this.props.t("Do you want to connect")} ?`,
          icon: "warning",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            let getRequestedFriends = {
              friendsId: this.props.match.params.userId,
              requestStatus: "requested",
            };
            FriendsFinder.friendRequest(getRequestedFriends)
              .then((response) => {
                console.log("response", response);
              })

              .catch((error) => {
                console.log("response", error);
              });
            Swal.fire({
              title: `${this.props.t("Requested Successfully")}`,
              icon: "info",
              type: "info",
              showConfirmButton: true,
            }).then((result) => {
              this.setState({
                userType: "requestedFriends",
              });
            });
          }
        });
      } else if (type === "friends") {
        Swal.fire({
          // title: logoutTitle,
          text: `${this.props.t("Do you want to remove a friend")} ?`,
          icon: "warning",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: this.props.t("Yes"),
          cancelButtonText: this.props.t("No"),
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            let unFriend = {
              userId: this.props.loginDetails.uId,
              friendsId: this.props.match.params.userId,
            };

            FriendsFinder.unfriendHit(unFriend)
              .then((response) => {
                this.setState({
                  userType: "notFriends",
                });
                this.props.getAllChatFriendsList();
              })
              .catch((error) => {
                console.log(error);
              });
            Swal.fire({
              title: `${this.props.t("Removed Successfully")}`,
              icon: "info",
              type: "info",
              showConfirmButton: true,
            }).then((result) => {});
          }
        });
      } else if (type === "requestedFriends") {
        Swal.fire({
          // title: logoutTitle,
          text: `${this.props.t("Do you want to cancel the request ")} ?`,
          icon: "warning",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            let config = {
              friendsId: this.props.match.params.userId,
            };
            FindFriendsFilter.friendRequestCancel(config)
              .then((response) => {
                console.log(response);
                this.setState({
                  userType: "notFriends",
                });
              })
              .catch((error) => {
                console.log(error);
              });
            Swal.fire({
              title: `${this.props.t("Request Cancelled Successfully")}`,
              icon: "success",
              type: "success",
              showConfirmButton: true,
            }).then((result) => {});
          }
        });
      } else if (type === "requestPending") {
        this.handelRequest();
      }
    }
  };
  viewDirectory = () => {
    this.props.history.push(`/${this.props.workspaceType}/directory`, {
      userId: this.props.match.params.userId,
      type: "USER_ID_FILTER",
    });
  };
}
const mapDispacthToProps = (dispatch) => {
  return {
    getFriendsListBasedWorkSpace: () =>
      dispatch(getFriendsListBasedWorkSpace()),
    getAllChatFriendsList: () => dispatch(getAllChatFriendsList()),
    getNavigationType: (type) => dispatch(getNavigationType(type)),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

const mapStateToProps = function (state) {
  return {
    totalFriends: state.postList.totalFriends,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(FriendDescription)));
