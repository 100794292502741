import { Chip, Grid } from "@material-ui/core";
import React, { Component } from "react";
import BlockUi from "react-block-ui";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Multiselect } from "react-widgets";
import { connect } from "react-redux";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import ForumService from "../../api/ForumService";
import MessagesFileOption from "../Community/Subjects/MessagesFileOption";
import TextEditorQuestion from "../../components/TextEditorQuestion";
import VideocamIcon from "@material-ui/icons/Videocam";
import DescriptionIcon from "@material-ui/icons/Description";
import ProfileService from "../../api/ProfileService";
import AttachmentIcon from "@material-ui/icons/Attachment";
import * as APPCONFIG from "../../config/app-config.js";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import dummygif from "../../img/dummygif.gif";
import SearchTextField from "../../components/SearchTextField";

class ResourcePostContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      tagsList: [],
      categoryLists: [],
      questionDescribed: "",
      postDescription: "",
      optionTagList: [],
      resourceType: "",
      descriptionCount: 0,
      selectedCategory: [],
      selectedTagList: [],
      selectedCountryList: [],
      fileTypeState: false,
      file: null,
      blocking: false,
      editFileType: "",
      fileData: [],
      updatedMediaFile: [],
      editStatus: false,
      removeImageFileIds: [],
      mediaFiles: [],
    };
  }
  getOldTagList = async (oldTags) => {
    const filtered =
      oldTags &&
      oldTags.map((data) => {
        return { label: data.tagName, value: data.tagId };
      });

    await this.setState({
      selectedTagList: filtered ? filtered : [],
    });
  };
  componentDidMount() {
    if (this.props.match.params.id !== "new") {
      const data = this.props.match.params.resourceType;
      let resourceType;
      if (data === "image") {
        resourceType = "Inspiration";
      }
      // else if (data === "video") {
      //   resourceType = "Video";
      // }
      else {
        resourceType = data;
      }
      this.setState({
        groupId: this.props.match.params.subId,
        resourceType: resourceType,
        resourcePostId: this.props.match.params.id,
        editStatus: true,
      });
      this.editResourcePost();
    } else {
      this.setState({
        resourceType: this.props.match.params.resourceType,
        groupId: this.props.match.params.subId,
      });
    }
  }

  onBack() {}

  handleForm = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleCategoryMultiSelect = (badges_value) => {
    this.setState({
      category: badges_value,
    });
  };
  handleAreaOfInterestMultiSelect = (data) => {};

  handleTagMultiSelect = (data) => {
    this.setState({
      selectedTagList: data,
    });
  };
  handleCountryMultiSelect = (data) => {
    this.setState({
      selectedCountryList: data,
    });
  };
  render() {
    const { t } = this.props;
    const { resourceType } = this.state;
    return (
      <div
        className="screenInnerGap"
        style={{
          marginLeft: "2px",
          marginRight: "2px",
          marginBottom: "22px",
        }}
      >
        <ToastContainer position="top-right" autoClose={5000} />
        <div className="backEventorGroup flexDefault">
          <button onClick={this.backToResourcePage}>Back </button>
        </div>

        <div className="askAQuestionCardWrapper">
          <BlockUi
            tag="div"
            blocking={this.state.blocking}
            message={t("Loading, please wait")}
            color="#A00030"
          >
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="askAQuestionInputGroup  ">
                  <label className="">Title * </label>

                  <input
                    type="text"
                    name="title"
                    value={this.state.title}
                    maxLength="250"
                    onChange={this.handleForm}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="SYICInputWrapper ">
                  <label className="">{t("Tags")}</label>
                  {/* <SearchTextField
                    updateTag={this.newTagList}
                    selectedTagList={this.state.selectedTagList}
                    editNeed={this.state.editStatus}
                  /> */}
                  <Multiselect
                    style={{ fontColor: "gray" }}
                    required
                    data={this.props.tagList}
                    valueField="tagId"
                    textField="tagName"
                    name="selectedTagList"
                    value={this.state.selectedTagList}
                    onChange={(value) => this.handleTagMultiSelect(value)}
                  />
                </div>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div
                  className="SYICInputWrapper "
                  // style={{ marginTop: "24px" }}
                >
                  <label className="">{t("Country")}</label>
                  <Multiselect
                    style={{ fontColor: "gray" }}
                    required
                    data={this.props.countyNameList}
                    valueField="id"
                    textField="nicename"
                    name="selectedTagList"
                    value={
                      this.state.selectedCountryList
                        ? this.state.selectedCountryList
                        : []
                    }
                    onChange={(value) => this.handleCountryMultiSelect(value)}
                  />
                </div>
              </Grid>
            </Grid>
            <div className="askAQuestionDescription">
              <label class="mt-2">{t("Description")}</label>
              <TextEditorQuestion
                ref={this.myRef}
                editorStateValue={this.editorStateValues}
                editorValue={this.state.questionDescribed}
              />
              <p
                className="boxText"
                style={
                  this.state.descriptionCount > 2500
                    ? { color: "red" }
                    : { color: "green" }
                }
              >
                {" "}
                {2500 - this.state.descriptionCount} {t("characters left")}
              </p>
            </div>
            {!this.state.fileTypeState && (
              <Grid
                container
                spacing={8}
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <Grid item md={5} sm={5} xs={5} lg={5}>
                  <ul class="groupPost-nav mt-4 groupPost-nav-pills groupPost-nav-stack groupPost-small groupPost-fw-normal">
                    {console.log(" resource type: " + resourceType)}
                    {resourceType === "Inspiration" && (
                      <li
                        class="groupPost-nav-item"
                        onClick={() => this.keyFile("image")}
                      >
                        <a
                          class="groupPost-nav-link bg-light"
                          href="#!"
                          data-bs-toggle="modal"
                          style={{ cursor: "pointer" }}
                          data-bs-target="#feedActionPhoto"
                        >
                          {" "}
                          <CameraAltIcon
                            style={{
                              color: "#0cbc87",
                            }}
                          />{" "}
                          Photo
                        </a>
                      </li>
                    )}
                    {(resourceType === "Video" ||
                      this.state.editFileType === "video") && (
                      <li
                        class="groupPost-nav-item"
                        onClick={() => this.keyFile("video")}
                      >
                        <a
                          class="groupPost-nav-link bg-light"
                          href="#!"
                          data-bs-toggle="modal"
                          style={{ cursor: "pointer" }}
                          data-bs-target="#feedActionVideo"
                        >
                          {" "}
                          <VideocamIcon
                            style={{
                              color: "#4f9ef8",
                            }}
                          />{" "}
                          Video
                        </a>
                      </li>
                    )}
                    {resourceType === "Document" && (
                      <li
                        class="groupPost-nav-item"
                        onClick={() => this.keyFile("audio")}
                      >
                        <a
                          href="#"
                          class="groupPost-nav-link bg-light"
                          data-bs-toggle="modal"
                          style={{ cursor: "pointer" }}
                          data-bs-target="#modalCreateEvents"
                        >
                          {" "}
                          <DescriptionIcon
                            style={{
                              color: "#dc3545",
                            }}
                          />{" "}
                          File{" "}
                        </a>
                      </li>
                    )}
                  </ul>
                </Grid>
              </Grid>
            )}

            {this.state.editFileType === "image" &&
              this.state.fileData &&
              this.state.fileData.map((file, index) => (
                <div key={file.fileName}>
                  <Chip
                    icon={<AttachmentIcon />}
                    className="fileChip"
                    label={file.originalFileName}
                    size="small"
                    onDelete={() =>
                      this.removeFile(index, file.resourceMediaFilesId)
                    }
                    variant="outlined"
                  />
                  <img
                    style={{ margin: "8px", width: "80px", height: "80px" }}
                    src={`${APPCONFIG.API_URL}auth/image/${file.fileName}`}
                  ></img>
                </div>
              ))}
            {this.state.editFileType === "pdf" &&
              this.state.fileData &&
              this.state.fileData.map((photo) => (
                <div key={photo.fileName}>
                  <Chip
                    icon={<DescriptionOutlinedIcon />}
                    className="fileChip"
                    label={photo.originalFileName}
                    size="small"
                    onDelete={() => this.removeFile(photo)}
                    variant="outlined"
                  />
                  <a
                    // download={photo.originalFileName}
                    style={{ textDecoration: "none", color: "black" }}
                    href={
                      photo.fileName == undefined || photo.fileName == ""
                        ? dummygif
                        : `${APPCONFIG.API_URL}auth/file/${photo.fileName}`
                    }
                  >
                    {/* <DescriptionOutlinedIcon /> */}
                    {/* {photo.originalFileName} */}
                  </a>
                </div>
              ))}
            {this.state.editFileType === "video" &&
              this.state.fileData &&
              this.state.fileData.slice(0, 1).map((video) => (
                <div style={{ margin: "8px" }}>
                  <video style={{ width: "200px", height: "150px" }} controls>
                    <source
                      src={`${APPCONFIG.API_URL}auth/video/${video.fileName}`}
                    />
                  </video>
                </div>
              ))}

            {this.state.fileTypeState && (
              <div>
                {!this.state.fileImage && (
                  <AiOutlineCloseCircle
                    onClick={this.closeChooseFileData}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      marginBottom: "8px",
                      width: "20px",
                      height: "20px",
                      color: "#000000",
                      marginTop: "8px",
                    }}
                  />
                )}

                <MessagesFileOption
                  fileTypeKey={this.state.fileTypeKey}
                  fileOption={this.state.fileOption}
                  fileOptionEnable={this.fileOptionEnable}
                  fromQuestionMedia={this.getQuestionMediaFiles}
                ></MessagesFileOption>
              </div>
            )}
          </BlockUi>
          <div className="askAQuestionBtn mt-4">
            {this.props.match.params.id === "new" ? (
              <button onClick={(e) => this.onFormSubmit(e)}>
                Create {resourceType}
              </button>
            ) : (
              <button onClick={(e) => this.onFormUpdate(e)}>
                Update {resourceType}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
  newTagList = (value) => {
    this.setState({
      selectedTagList: value,
    });
  };
  onFormUpdate = (e) => {
    if (
      this.state.title === ""
      // this.state.selectedTagList.length === 0 ||
      // this.state.postDescription === ""
    ) {
      toast.warning("Select all the field");
    }
    // else if (this.state.fileData.length === 0) {
    //   toast.warning('please select file')
    // }
    else {
      const formData = new FormData();
      var tags = [];
      var data = this.state.selectedTagList;
      var countryList = [];
      data.forEach((element) => {
        tags.push(element.tagId);
      });
      // const newTagList =
      //   this.state.selectedTagList &&
      //   this.state.selectedTagList.map((item) => {
      //     return { tagName: item.label, tagId: item.value };
      //   });
      if (this.state.selectedCountryList.length !== 0) {
        this.state.selectedCountryList.forEach((element) => {
          countryList.push(element.id);
        });
        formData.append("countryIds", countryList);
      }
      // if (this.state.fileData.length === 0 && this.state.file !== null) {
      formData.append("title", this.state.title);
      formData.append("discription", this.state.postDescription);
      formData.append("groupId", this.state.groupId);
      formData.append("fileType", this.state.editFileType);
      formData.append("tagsId", tags);
      if (this.state.file) {
        for (let i = 0; i < this.state.file.length; i++) {
          formData.append("messgaeFiles", this.state.file[i]);
        }
      }

      formData.append("resourcePostId", this.props.match.params.id);
      // formData.append(
      //   "newTagList",
      //   newTagList ? JSON.stringify(newTagList) : []
      // );
      // } else {
      // formData.append(
      //   "newTagList",
      //   newTagList ? JSON.stringify(newTagList) : []
      // );
      // }
      if (
        this.state.file?.length !== 0 &&
        this.state.fileData?.length === 0 &&
        (this.state.editFileType === "pdf" ||
          this.state.editFileType === "video")
      ) {
        const id = this.state.mediaFiles.map(
          (item) => item.resourceMediaFilesId
        );
        formData.append("removeImageFileIds", id);
      }
      if (this.state.editFileType === "image") {
        formData.append("removeImageFileIds", this.state.removeImageFileIds);
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      this.setState({
        blocking: true,
      });
      ForumService.admin_resource_post_edit(formData, config)
        .then((response) => {
          if (response.data.status === -1) {
            toast.error("Error in saving");
            this.setState({
              blocking: false,
            });
          } else {
            this.setState({
              blocking: false,
            });
            toast.success("Resource created successfully");
            this.backToResourcePage();
          }
        })
        .catch((error) => {
          toast.error("Error in saving");
          console.log(error);
        });
    }
  };
  removeFile = (index, resourceMediaFilesId) => {
    // this.setState({
    //   updatedMediaFile: [],
    //   fileData: [],
    // });
    if (this.state.editFileType === "image") {
      const files = this.state.fileData;
      files.splice(index, 1);
      console.log(files);
      this.state.removeImageFileIds.push(resourceMediaFilesId);
      this.setState({
        resourceType: "Inspiration",
        fileData: files,
      });
    } else if (this.state.editFileType === "pdf") {
      this.setState({
        resourceType: "Document",
        updatedMediaFile: [],
        fileData: [],
      });
    } else if (this.state.editFileType === "video") {
      this.setState({
        resourceType: "Video",
        updatedMediaFile: [],
        fileData: [],
      });
    }
  };
  editResourcePost = () => {
    let Edit = this.props.match.params.id;
    var subjectId = {
      resourcePostId: Edit,
    };
    ProfileService.admin_resource_post_byid(subjectId)
      .then((response) => {
        let result = response.data.data.resource_post.data.post;
        this.updateCountryTags(
          response.data.data.resource_post.data.country_value
        );
        this.setState({
          title: result.title,
          selectedTagList: response.data.data.resource_post.data.tags_value,
          questionDescribed: result.discription,
          postDescription: result.discription,
          fileData: result.resourceMediaFiles,
          mediaFiles: result.resourceMediaFiles,
          // selectedCountryList:
          //   response.data.data.resource_post.data.country_value,
          editFileType: result.postType,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateCountryTags = (country) => {
    const countryTags =
      country &&
      country.map((data) => {
        return { id: data.countryId, nicename: data.countryName };
      });

    this.setState({
      selectedCountryList: countryTags ? countryTags : [],
    });
  };
  onFormSubmit = (e) => {
    if (
      this.state.title === ""
      // this.state.selectedTagList.length === 0 ||
      // this.state.postDescription === ""
    ) {
      toast.warning("Select all the field");
    } else if (
      this.state.file === null &&
      this.state.resourceType !== "Video"
    ) {
      toast.warning("please select file");
    } else {
      const formData = new FormData();
      var tags = [];
      var data = this.state.selectedTagList;
      var countryList = [];
      data.forEach((element) => {
        tags.push(element.tagId);
      });
      if (this.state.selectedCountryList.length !== 0) {
        this.state.selectedCountryList.forEach((element) => {
          countryList.push(element.id);
        });
        formData.append("countryIds", countryList);
      }
      // const newTagList =
      //   this.state.selectedTagList &&
      //   this.state.selectedTagList.map((item) => {
      //     return { tagName: item.label, tagId: item.value };
      //   });
      formData.append("title", this.state.title);
      formData.append("discription", this.state.postDescription);
      formData.append("groupId", this.state.groupId);
      formData.append(
        "fileType",
        this.state.fileTypeKey === "audio"
          ? "pdf"
          : this.state.fileTypeKey
          ? this.state.fileTypeKey
          : "video"
      );
      formData.append("tagsId", tags);
      if (this.state.file) {
        for (let i = 0; i < this.state.file.length; i++) {
          formData.append("messgaeFiles", this.state.file[i]);
        }
      }
      // formData.append(
      //   "newTagList",
      //   newTagList ? JSON.stringify(newTagList) : []
      // );
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.setState({
        blocking: true,
      });
      ForumService.admin_resource_post_save(formData, config)
        .then((response) => {
          if (response.data.status === -1) {
            toast.error("Error in saving");
            this.setState({
              blocking: false,
            });
          } else {
            this.setState({
              blocking: false,
            });
            toast.success("Resource created successfully");
            this.backToResourcePage();
          }
        })
        .catch((error) => {
          toast.error("Error in saving");
          console.log(error);
        });
    }
  };
  keyFile = (key) => {
    this.setState({ fileTypeKey: key, fileTypeState: true });
  };
  editorStateValues = (values) => {
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        postDescription: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        postDescription: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  };
  fileOptionEnable = () => {
    this.setState({ fileOption: false, file: null });
  };
  getQuestionMediaFiles = (files, type) => {
    this.setState({ file: files, type: type });
    if (this.state.resourceType !== "Inspiration") {
      this.setState({
        fileData: [],
      });
    }
  };
  closeChooseFileData = () => {
    this.setState({
      fileTypeState: false,
      fileTypeKey: "",
      type: "",
      file: null,
    });
  };
  backToResourcePage = () => {
    this.props.history.push(
      `/${this.props.workspaceName}/learningAppDesription/${this.state.groupId}/${this.props.workspaceType}`
    );
  };
}

const mapStateToProps = function (state) {
  return {
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    tagList: state.commonApi.taglist.data,
    countryList: state.commonApi.adminCountryList,
    workspaceName: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    countyNameList: state.commonApi.countryName,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ResourcePostContainer)));
