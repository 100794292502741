import React from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
} from "@material-ui/core";

function SurveyAnswersList({ data: results = [] }) {
  console.log("Survey Results : ", results);

  //   const userName = results.map(a => a.userName);

  //   const { userName } = results
  //   console.log("UserNAme", userName)
  //   console.log("UserNAme", results)
  const { questions = [], answerList: answers = [] } = results;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>UserName</TableCell>
            {questions.map((question) => (
              <TableCell>
                {" "}
                <div
                  className="ml-2"
                  dangerouslySetInnerHTML={{
                    __html: question,
                  }}
                />{" "}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {answers.map((data) => (
            <TableRow>
              <TableCell>{data.userName}</TableCell>
              {data.userList.map((answer) => (
                <>
                  <TableCell>{answer ? answer : "-"}</TableCell>
                </>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SurveyAnswersList;
