import { GET_RECENTLY_VIEWED_QUESTIONS_LIST } from "../types";
import ForumService from "../../api/ForumService";
import { GET_DASHBOARD_LIST } from "../types";
import { GET_MYQUESTIONS_LIST } from "../types";
import { DASHBOARD_LOADER_RESET } from "../types";
//import AuthService from "../../session/AuthService";
// import FriendsFinder from "../../api/FriendsFinder";

export const getRecentlyViewedQuestionsList = () => async (dispatch) => {
  let getRecentlyViewedQuestions = {};

  ForumService.getRecentlyViewedQuestions(getRecentlyViewedQuestions)
    .then((response) => {
      var result = response.data;
      var recentlyViewedQuestionsList = result.data.recently;
      console.log("reccently");
      console.log(result.data.recently);

      dispatch({
        type: GET_RECENTLY_VIEWED_QUESTIONS_LIST,
        payload: recentlyViewedQuestionsList,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_RECENTLY_VIEWED_QUESTIONS_LIST,
        payload: [],
      });
    });
  return [];
};

export const getDashboardList = (pageNo, userId) => async (dispatch) => {
  dispatch(loaderReset(true));
  try {
    let getWallJson = {
      page: pageNo,
    };
    ForumService.getDashoardDetails(getWallJson)
      .then((response) => {
        var sam = response.data.data.dashboard;
        var result = JSON.parse(sam);
        console.log(result);
        if (result.length > 0) {
          for (var i = 0; i < result.length; i++) {
            result[i].wall = JSON.parse(result[i].wall);
            result[i].question = JSON.parse(result[i].question);
            result[i].answer = JSON.parse(result[i].answer);
          }
          var dash = result;
          console.log("DASHBOARD LIST");
          console.log(dash);
          var userlist = [];
          for (var i = 0; i < dash.length; i++) {
            if (!dash[i].wall.wallLikes) {
              dash[i].wall.wallLikes = [];
            }
            if (
              dash[i].question.questionLikesArray &&
              dash[i].question.questionLikesArray[0]
            ) {
              dash[i].question.questionLikesArray = dash[
                i
              ].question.questionLikesArray.filter(
                (star) => star.userId == userId
              );
              for (
                var m = 0;
                m < dash[i].question.questionLikesArray.length;
                m++
              ) {
                if (dash[i].question.questionLikesArray[m].star == "true") {
                  dash[i].question.questionLikesArray[m].star = true;
                } else {
                  dash[i].question.questionLikesArray[m].star = false;
                }
              }
            } else {
              dash[i].question.questionLikesArray = [];
            }
            userlist.push(dash[i].wall.userId);
          }
          var total =
            pageNo == 0 ? JSON.parse(response.data.data.totalItems) : 0;
          console.log(total);
          var dashboardList = [];
          dashboardList[0] = dash;
          dashboardList[1] = pageNo;
          dashboardList[2] = pageNo == 0 ? total[0].counts : 0;
          dispatch({
            type: GET_DASHBOARD_LIST,
            payload: dashboardList,
          });
        } else if (!result.data.dashboard && pageNo == 0) {
          var dashboardList = [];
          dashboardList[0] = [];
          dashboardList[1] = 0;
          dashboardList[2] = 0;
          dispatch({
            type: GET_DASHBOARD_LIST,
            payload: dashboardList,
          });
        }
        dispatch(loaderReset(false));
      })
      .catch((error) => {
        dispatch(loaderReset(false));
        console.log(error);
      });
  } catch (error) {
    dispatch(loaderReset(false));
    dispatch({
      type: GET_DASHBOARD_LIST,
      payload: [],
    });
  }
  return [];
};

export const loaderReset = (type) => async (dispatch) => {
  dispatch({
    type: DASHBOARD_LOADER_RESET,
    payload: type,
  });
};

export const getMyQuestionsList = (pageNo) => async (dispatch) => {
  try {
    let questionList = {
      page: pageNo,
    };
    ForumService.getAllQuestionsByUserId(questionList)
      .then((response) => {
        var result = response.data;
        if (result.data.questions.length > 0) {
          var joinresult = [];
          joinresult[0] = result.data.questions;
          joinresult[1] = result.data.count[0].count;
          dispatch({
            type: GET_MYQUESTIONS_LIST,
            payload: joinresult,
          });
        } else {
          this.setState({
            type: GET_MYQUESTIONS_LIST,
            payload: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_MYQUESTIONS_LIST,
      payload: [],
    });
  }
  return [];
};
