import { GET_RECENTLY_VIEWED_QUESTIONS_LIST } from "../types";
import { GET_DASHBOARD_LIST } from "../types";
import { GET_MYQUESTIONS_LIST } from "../types";
import { DASHBOARD_LOADER_RESET } from "../types";
import { DELETE_DASHBOARD_QUESTION_LIST } from "../types";
import { GET_LOGOUT_DATA } from "../types";
const initialState = {
  list: [],
  dashboardList: [],
  myQuestionList: [],
  dashboardTotalCount: 0,
  dashboardloading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RECENTLY_VIEWED_QUESTIONS_LIST:
      console.log("GET_RECENTLY_VIEWED_QUESTIONS_LIST");
      console.log(action.payload);

      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case DASHBOARD_LOADER_RESET:
      console.log("DASHBOARD_LOADER_RESET");
      console.log(action.payload);
      return {
        ...state,
        dashboardloading: action.payload,
      };

    case GET_DASHBOARD_LIST:
      console.log("GET_DASHBOARD_LIST");
      console.log(action.payload);
      if (action.payload[1] == 0) {
        return {
          ...state,
          dashboardList: action.payload[0],
          dashboardTotalCount: action.payload[2],
          dashboardloading: false,
        };
      } else {
        return {
          ...state,
          dashboardList: state.dashboardList.concat(action.payload[0]),
          dashboardTotalCount: state.dashboardTotalCount,
          dashboardloading: false,
        };
      }
    case GET_MYQUESTIONS_LIST:
      console.log("GET_MYQUESTIONS_LIST");
      console.log(action.payload);
      return {
        ...state,
        // list: state.list[0].concat(action.payload[0]),
        myQuestionList: action.payload,
        loading: false,
      };
    case DELETE_DASHBOARD_QUESTION_LIST:
      console.log("DELETE_DASHBOARD_QUESTION_LIST");
      console.log(action.payload);
      return {
        ...state,
        dashboardList: action.payload,
        dashboardTotalCount: state.dashboardTotalCount - 1,
        loading: false,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");

      return {
        ...initialState,
      };

    default:
      return state;
  }
}
