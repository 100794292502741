import {
  EVENT_LIST,
  EVENT_LOADER,
  EVENT_VIDEO_LIST,
  GET_SUBSCRIPTION_LIST,
  GET_USER_SLOTS,
  MODIFY_SUBSCRIPTION_STATUS,
  SAVE_USER_SLOT,
  USER_SUBSCRIPTION_STATUS,
  CATEGORY_V2_LIST,
  CALENDAR_SERVICE_LIST,
  SPECIALIZATION_V2_LIST,
  GET_USER_SERVICE,
} from "../types";

const initialState = {
  eventList: [],
  eventLoader: false,
  eventSeeMore: false,
  totalPage: 0,
  userSlots: null,
  subscriptionList: [],
  subscriptionStatus: false,
  subscriptionDetails: null,
  subscriptionType: null,
  subscriptionModel: false,
  categoryList: [],
  calendarServices: [],
  expiryDate: new Date(),
  userServiceList: [],
  prefComplete: false,
  calendarComplete: false,
  serviceComplete: false,
  subCategoryV2: [],
  categoryV2: [],
};

export default function calendarReducer(state = initialState, action) {
  switch (action.type) {
    case EVENT_LOADER:
      return {
        ...state,
        eventLoader: action.payload,
      };
    case EVENT_LIST:
      console.log("EVENT_LIST", action.payload);
      let list =
        action.page === 0
          ? action.payload.content
          : state.eventList.concat(action.payload.content);
      return {
        ...state,
        eventLoader: false,
        eventList: list ? list : [],
        eventSeeMore: action.payload?.totalPages - 1 > action.page,
      };
    case EVENT_VIDEO_LIST:
      console.log("EVENT_VIDEO_LIST", action.payload);
      let eventVideoList =
        action.page === 0
          ? action.payload.content
          : state.eventVideoList.concat(action.payload.content);
      return {
        ...state,
        eventLoader: false,
        eventVideoList: eventVideoList ? eventVideoList : [],
        eventSeeMore: action.payload?.totalPages - 1 > action.page,
      };
    case GET_USER_SLOTS:
      console.log("GET_USER_SLOTS", action.payload);
      return {
        ...state,
        userSlots: action.payload,
        eventLoader: false,
        prefComplete: action.payload.preference !== null,
        calendarComplete: action.payload?.slots?.length !== 0,
      };
    // case SAVE_USER_SLOT:
    //   console.log("SAVE_USER_SLOT", action.payload);
    //   return {
    //     ...state,
    //     userSlots: [state.userSlots, { ...action.payload }],
    //     eventLoader: false,
    //   };
    case GET_SUBSCRIPTION_LIST:
      console.log("GET_SUBSCRIPTION_LIST", action.payload);
      return {
        ...state,
        subscriptionList: action.payload ? action.payload : [],
      };
    case USER_SUBSCRIPTION_STATUS:
      console.log("USER_SUBSCRIPTION_STATUS", action.payload);
      const newDay = new Date(new Date());
      newDay.setDate(new Date().getDate() + action.payload?.daysRemaining ?? 1);
      return {
        ...state,
        subscriptionStatus: action.payload.subscription,
        subscriptionDetails: action.payload,
        subscriptionType: action.subscriptionType,
        expiryDate: newDay,
      };
    case MODIFY_SUBSCRIPTION_STATUS:
      console.log("MODIFY_SUBSCRIPTION_STATUS", action.payload);
      return {
        ...state,
        subscriptionModel: action.payload,
      };
    case CATEGORY_V2_LIST:
      console.log("CATEGORY_V2_LIST", action.payload);
      return {
        ...state,
        categoryList: action.payload,
      };
    case CALENDAR_SERVICE_LIST:
      console.log("CALENDAR_SERVICE_LIST", action.payload);
      return {
        ...state,
        calendarServices: action.payload,
      };
    case SPECIALIZATION_V2_LIST:
      console.log("SPECIALIZATION_V2_LIST", action.payload);
      return {
        ...state,
        categoryV2: action.payload.category ?? [],
        subCategoryV2: action.payload.subCategory ?? [],
      };
    case GET_USER_SERVICE:
      console.log("GET_USER_SERVICE", action.payload);
      return {
        ...state,
        userServiceList: action.payload,
        serviceComplete: action.payload?.length !== 0,
      };

    default:
      return state;
  }
}
