import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import "react-widgets/styles.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Grid, Menu, MenuItem } from "@material-ui/core";
import {
  getJobRequestByUser,
  getJobRequestByOtherUser,
  getJobRequestPostDetails,
} from "../../redux/actions/myRequestActions";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "@material-ui/core";
import { Card, Form, Modal } from "react-bootstrap";
import { API_URL } from "../../config/app-config";
import dummyimage from "../../img/default_avatar.jpg";
import ProfileService from "../../api/ProfileService";
import moment from "moment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Chip from "@material-ui/core/Chip";
import * as APPCONFIG from "../../config/app-config.js";
import PhotoAlbum from "react-photo-album";
import dummygif from "../../img/dummygif.gif";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import TextEditorQuestion from "../../components/TextEditorQuestion";
import PostService from "../../api/PostService";
import InfiniteScroll from "react-infinite-scroll-component";
import BlockUi from "react-block-ui";
import { GoCalendar } from "react-icons/go";
import FbGridImages from "../Home/HomeUpdated/ImageView/Images";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Delete, Edit } from "@material-ui/icons";
import MyYuukke from "../../api/MyYuukke";
import Swal from "sweetalert2";
import { MdDeleteOutline } from "react-icons/md";
import { premiumUser } from "../../utils";

class MyRequestDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postRequest: false,
      tabIndex: 0,
      name: "",
      createdDate: "",
      natureOfRequest: "",
      duration: "",
      description: "",
      industry: "",
      industryData: [],
      jobRequestsAoI: [],
      areaofinterestsList: [],
      industryNameList: [],
      displayName: "",
      premiumData: "",
      photoAvailable: false,
      fileData: [],
      openRequestReplyState: false,
      questionDescribed: "",
      content: "",
      questionTitleCount: 0,
      descriptionCount: 0,
      commentsList: [],
      friendsPage: 0,
      reqId: 0,
      friendsHasMore: true,
      loaderStatus: false,
      triedSoFor: "",
      tagList: [],
      jobId: 0,
      menuType: 0,
      desiredOutCome: "",
      locationName: "",
      mainChallenge: "",
      describeMenu: "",
      areaOfInterestValue: [],
      opportunityType: "",
      mainChallengeValue: "",
    };
    this.myRef = React.createRef();
  }

  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
    });
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    // this.getJobRequestById(id);

    let requestId = {
      jobId: id,
    };
    this.setState({
      jobId: id,
    });
    this.props.getJobRequestPostDetails(requestId);

    this.getAllRequestHelpReplyList(id);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.id &&
      this.props.match.params.id !== prevProps.match.params.id
    ) {
      let id = this.props.match.params.id;

      let requestId = {
        jobId: id,
      };
      this.setState({
        jobId: id,
      });
      this.props.getJobRequestPostDetails(requestId);

      this.getAllRequestHelpReplyList(id);
    }

    if (
      this.props.jobRequestDetails &&
      this.props.jobRequestDetails !== prevProps.jobRequestDetails
    ) {
      console.log("JobRequestDetails updated: ", this.props.jobRequestDetails);
      this.getRequest(this.props.jobRequestDetails);
    }
  }

  // getJobRequestById = async (id) => {
  //   this.setState({
  //     loaderStatus: true,
  //   });
  //   let requestId = {
  //     jobId: id,
  //   };
  //   ProfileService.getJobRequestById(requestId)
  //     .then((response) => {
  //       var result = response.data.data.jobRequest;

  //       this.getRequest(result);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  getRequest = async (result) => {
    console.log("Result-1", result);
    if (
      result.natureOfRequest === "Service Needs" ||
      result.natureOfRequest === "Looking for Referral connects"
    ) {
      //2
      this.setState({
        describeMenu: "Describe this in one sentence",
        menuType: 3,
        mainChallenge: "My main challenge is",
        locationName: "Location",
        desiredOutCome: "Opportunity Type",
      });
    } else if (result.natureOfRequest === "Help") {
      //1
      this.setState({
        menuType: 0,
        describeMenu: "Describe this request in one short sentence",
        mainChallenge: "My main challenge is",
        locationName: "I have tried",
        desiredOutCome: "My desired outcome of this request is",
      });
    } else {
      //3 job
      this.setState({
        menuType: 1,
        describeMenu: "Job Title",
        mainChallenge: "My main challenge is",
        locationName: "Work location",
        desiredOutCome: "Opportunity Type",
      });
    }

    await this.setState({
      createdDate: result.createdDate,
      natureOfRequest: result.natureOfRequest,
      // duration: result.duration,
      description: result.description,
      name: result.userName,
      industryData: result.industryValue,
      areaOfInterestValue: result.areaOfInterestValue,
      displayName: result.userName,
      premiumData: result.premiumData,
      userId: result.userId,
      fileUpload: result.fileUpload,
      location: result.location,
      fileType: result.fileType,
      industryNameList: result.industryValue,
      tagList: result.tagsValue,
      opportunityType: result.opportunityType,
      mainChallengeValue: result.mainChallenge,
    });
    var files = result.fileUpload;
    var type = result.fileType;
    let fileList = [];
    if (files != null) {
      if (type == "image") {
        this.setState({
          photoAvailable: true,
        });
        // fileList.push({
        //   src: `${APPCONFIG.API_URL}auth/image/${files}`,
        //   width: 1600,
        //   height: 900,
        // });
        fileList.push(`${APPCONFIG.API_URL}auth/image/${files}`);
        await this.setState({ fileImageData: fileList });
      } else if (type == "pdf") {
        //alert("pdf")
        this.setState({
          photoAvailable: false,
        });
        let fileList = [];
        // if (files.length > 0) {
        //   for (let i = 0; i < files.length; i++) {
        fileList.push({
          fileName: `${APPCONFIG.API_URL}auth/file/${files}`,
          originalFileName: files[0].files,
        });
        // }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST PDF");
        console.log(this.state.fileData);
      }
    }

    this.setState({
      loaderStatus: false,
    });
  };

  getAllRequestHelpReplyList = (id) => {
    this.setState({
      reqId: id,
    });
    let data = {
      jobRequestId: id,
      page: 0,
    };
    ProfileService.getAllRequestHelpReplyList(data)
      .then((response) => {
        var result = response.data.data.requestReply;
        // alert()
        console.log("result", result);
        this.setState({
          commentsList: result,
          friendsPage: this.state.friendsPage + 1,
        });
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  addHelpForm = () => {
    this.props.history.push(
      `/${this.props.workspaceType}/ask-help-form/${"new"}`
    );
  };
  render() {
    const { t } = this.props;
    const {
      tabIndex,
      name,
      createdDate,
      natureOfRequest,
      duration,
      description,
      industry,
      displayName,
      userId,
      triedSoFor,
      premiumData,
    } = this.state;
    return (
      <>
        <div>
          <ToastContainer position="top-right" autoClose={5000} />
          <BlockUi
            tag="div"
            message={t("Loading, please wait ...")}
            style={{ minHeight: "130px" }}
            className="wallLoadingPage"
            blocking={this.props.jobRequestLoaderStatus}
          >
            <div
              style={
                {
                  // marginTop: "20px",
                  // marginRight: "12px",
                  // marginLeft: "0px",
                  // marginBottom: "12px",
                }
              }
              className="ansBackQuestion d-flex justify-content-between"
              disabled={this.state.blocksend}
            >
              <p onClick={this.backtoHome}>
                <ArrowBackIcon /> {t("Back")}
              </p>
              <button onClick={this.addHelpForm}>{t("Ask Help")}</button>
            </div>
            <Grid container spacing={2}>
              <Grid item md={9} xs={12} sm={12} lg={9}>
                <div
                  className="myRequestCardWrapper"
                  style={{ height: "auto" }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="requestHomeTitle">{natureOfRequest}</h3>
                    {this.state.userId === this.props.loginDetails.uId && (
                      <MoreHorizIcon
                        style={{ cursor: "pointer" }}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          this.handleMenuClick(e);
                        }}
                      />
                    )}

                    <Menu
                      id="simple-menu"
                      anchorEl={this.state.menuState}
                      keepMounted
                      open={this.state.menuState}
                      onClose={this.handleMenuClose}
                    >
                      <div
                        className="menuCard"
                        onClick={(e) =>
                          this.editAskHelp(
                            e,

                            this.state.jobId
                          )
                        }
                      >
                        <Edit style={{ cursor: "pointer", marginTop: "6px" }} />{" "}
                        <MenuItem>{t("Edit")}</MenuItem>
                      </div>
                      <div
                        className="menuCard"
                        onClick={(e) => this.deleteAskHelp(e, this.state.jobId)}
                      >
                        <Delete
                          style={{ cursor: "pointer", marginTop: "6px" }}
                        />{" "}
                        <MenuItem>{t("Delete")}</MenuItem>
                      </div>
                    </Menu>
                  </div>
                  <p
                    style={{
                      color: "gray",
                      fontSize: "12px",
                    }}
                  >
                    <GoCalendar
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                    ></GoCalendar>{" "}
                    {moment(createdDate).locale("en").format("ll")}
                  </p>
                  {/* {this.state.userId == this.props.loginDetails.uId && (
                    <>
                      <button
                        style={{ float: "right" }}
                        onClick={() => this.requestReply()}
                      >
                        Add your comment
                      </button>
                    </>
                  )} */}
                  {/* {this.state.userId !== this.props.loginDetails.uId && ( */}
                  <div style={{ marginBottom: "2rem" }}>
                    <button
                      style={{ float: "right" }}
                      onClick={() => this.requestReply()}
                    >
                      {t("Comment")}
                    </button>
                  </div>
                  {/* )} */}
                  <div className="postCardCloud justify-content-start">
                    {this.state.areaOfInterestValue &&
                      this.state.areaOfInterestValue.map((subject, index) => (
                        <Chip
                          className="myRequestCardDetails"
                          style={{
                            overflow: "hidden",
                            Whitespace: "nowrap",
                            fontSize: "12px",
                            textOverflow: "ellipsis",
                            borderRadius: "10px",
                            backgroundColor: "rgb(242,242,242)",
                          }}
                          label={subject.areaName}
                        ></Chip>
                      ))}
                  </div>

                  <hr />
                  {/* <h6>{this.state.describeMenu}</h6> */}
                  <p style={{ padding: "4px 1px 4px 0px" }}>{description} </p>
                  <div className="mb-2">
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("Here are some things about me..")}
                    </p>
                  </div>
                  <div className="gird-myreq">
                    {this.state.location && (
                      <>
                        <p>{t(`${this.state.locationName}`)} :</p>
                        <ul>
                          <li>{this.state.location}</li>
                        </ul>
                      </>
                    )}
                    {this.state.opportunityType && (
                      <>
                        <p>{t(`${this.state.desiredOutCome}`)} :</p>
                        <ul>
                          <li>{this.state.opportunityType}</li>
                        </ul>
                      </>
                    )}
                    {this.state.industryNameList &&
                      this.state.industryNameList?.length !== 0 && (
                        <>
                          <p>{t("Industry")} :</p>
                        </>
                      )}

                    {/* <p>{t("Fields of Interest")} :</p> */}
                    <ul>
                      {this.state.industryNameList &&
                        this.state.industryNameList.map((subject, index) => (
                          <li>{subject.industryName}</li>
                        ))}
                    </ul>

                    {/* {this.state.tagList && this.state.tagList?.length != 0 && (
                      <>
                        <p>{t("TagList")} :</p>

                        <ul>
                          {this.state.tagList &&
                            this.state.tagList.map((subject, index) => (
                              <li>{subject.tagName}</li>
                            ))}
                        </ul>
                      </>
                    )} */}
                    {/* {this.state.jobRequestsAoI &&
                      this.state.jobRequestsAoI?.length != 0 && (
                        <>
                          <p>{t("Fields of Interest")} :</p>

                          <ul>
                            {this.state.jobRequestsAoI &&
                              this.state.jobRequestsAoI.map(
                                (subject, index) => <li>{subject.areaName}</li>
                              )}
                          </ul>
                        </>
                      )} */}

                    {this.state.mainChallengeValue && (
                      <>
                        <p>{t(`${this.state.mainChallenge}`)} :</p>
                        <ul>
                          <li>{this.state.mainChallengeValue}</li>
                        </ul>
                      </>
                    )}

                    {this.state.fileType !== "text" && (
                      <>
                        <p>{t("My Attachment")} : </p>
                        <div>
                          {this.state.photoAvailable && (
                            <FbGridImages
                              images={this.state.fileImageData}
                              onClickImage={() => {}}
                            />
                            // <PhotoAlbum
                            //   layout="masonry"
                            //   photos={this.state.fileImageData}
                            // />
                          )}

                          {!this.state.photoAvailable &&
                            this.state.fileData &&
                            this.state.fileData.map((photo) => (
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                href={
                                  photo.fileName == undefined ||
                                  photo.fileName == ""
                                    ? dummygif
                                    : photo.fileName
                                }
                                target="_blank"
                              >
                                <DescriptionOutlinedIcon /> document.pdf
                              </a>
                            ))}
                        </div>
                      </>
                    )}
                    {
                      // this.state.userId === this.props.loginDetails.uId &&
                      <div>
                        <h5
                          style={{
                            padding: "12px 4px 9px 0px",
                            color: "gray",
                          }}
                        >
                          {t("Replies ,")}
                          {/* {this.state.userId === this.props.loginDetails.uId ?"Replies":"Our Replies" }     */}
                        </h5>

                        {this.state.commentsList.length === 0 && (
                          <h6 style={{ textAlign: "center", color: "gray" }}>
                            {t("No Replays Available")}
                          </h6>
                        )}
                        <InfiniteScroll
                          dataLength={this.state.commentsList.length}
                          next={this.fetchMoreDataFriends}
                          hasMore={this.state.friendsHasMore}
                          scrollableTarget="scrollableDiv3"
                        >
                          <div
                            id="scrollableDiv3"
                            // onScroll={this.handleScroll}
                            style={{
                              minHeight: "auto",
                              maxHeight: "700px",
                              overflowY: "auto",
                              overflowX: "hidden",
                              // margin: "0px 22px 25px",
                            }}
                          >
                            {this.state.commentsList &&
                              this.state.commentsList.map((comments) => (
                                <>
                                  {comments.description !== "" && (
                                    //  && comments.userId !== this.props.loginDetails.uId
                                    <ul className="comment-wrap list-unstyled">
                                      <li className="comment-item">
                                        <div className="d-flex position-relative">
                                          <div className="post-avatar post-avatar-xs">
                                            <img
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.showFriendsProfile(
                                                  comments.userId
                                                )
                                              }
                                              className="post-avatar-img rounded-circle"
                                              src={
                                                API_URL +
                                                "api/getUserPhoto/" +
                                                comments.userId +
                                                "/" +
                                                comments.userName
                                              }
                                              alt="user"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = dummyimage;
                                              }}
                                            />
                                          </div>

                                          <div
                                            className="ms-2"
                                            style={{ marginLeft: "12px" }}
                                          >
                                            <div className="bg-light rounded-start-top-0 p-3 rounded">
                                              <div className="post-nav post-nav-divider">
                                                <h6
                                                  className="post-nav-item post-card-title post-mb-0"
                                                  style={{
                                                    color: "#14191e",
                                                  }}
                                                >
                                                  {" "}
                                                  {comments.userName}{" "}
                                                </h6>
                                                <span className="post-nav-item post-small">
                                                  {" "}
                                                  {moment(comments.createdDate)
                                                    .locale("en")
                                                    .format("ll")}
                                                </span>

                                                {comments.userId ===
                                                  this.props.loginDetails
                                                    .uId && (
                                                  <span class="ml-2">
                                                    <div
                                                      onClick={(e) =>
                                                        this.deleteComment(
                                                          e,
                                                          comments.helpId
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <MdDeleteOutline
                                                        style={{
                                                          width: "22px",
                                                          height: "22px",
                                                          cursor: "pointer",
                                                          color: "#676a79",
                                                        }}
                                                      />{" "}
                                                    </div>
                                                  </span>
                                                )}
                                              </div>
                                              <p
                                                class="small mb-0"
                                                style={{
                                                  wordBreak: "break-all",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: comments.description,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                  {/* {this.props.match.params.type == "my-req" && comments.description !== "" && comments.userId === this.props.loginDetails.uId && (
                                      <ul className="comment-wrap list-unstyled">
                                        <li className="comment-item">
                                          <div className="d-flex position-relative">
                                            <div className="post-avatar post-avatar-xs">
                                              <img
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  this.showFriendsProfile(
                                                    comments.userId
                                                  )
                                                }
                                                className="post-avatar-img rounded-circle"
                                                src={
                                                  API_URL +
                                                  "api/getUserPhoto/" +
                                                  comments.userId +
                                                  "/" +
                                                  comments.userName
                                                }
                                                alt="user"
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src =
                                                    dummyimage;
                                                }}
                                              />
                                            </div>
                                            <div
                                              className="ms-2"
                                              style={{ marginLeft: "12px" }}
                                            >
                                              <div className="bg-light rounded-start-top-0 p-3 rounded">
                                                <div className="post-nav post-nav-divider">
                                                  <h6
                                                    className="post-nav-item post-card-title post-mb-0"
                                                    style={{ color: "#14191e" }}
                                                  >
                                                    {" "}
                                                    {comments.userName}{" "}
                                                  </h6>
                                                  <span className="post-nav-item post-small">
                                                    {" "}
                                                    {moment(
                                                      comments.createdDate
                                                    )
                                                      .locale("en")
                                                      .format("ll")}
                                                  </span>
                                                  <div className="action-bar"></div>
                                                </div>
                                                <p
                                                  class="small mb-0"
                                                  style={{
                                                    wordBreak: "break-all",
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      comments.description,
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    )} */}
                                </>
                              ))}{" "}
                          </div>
                        </InfiniteScroll>
                      </div>
                    }
                  </div>
                  {/* </Grid> */}
                  {/* </Grid> */}
                </div>
              </Grid>
              <Grid item md={3} xs={12} sm={12} lg={3}>
                <div className="myRequestCardWrapper">
                  <div class="postuserImg">
                    <img
                      onClick={() => this.showFriendsProfile(userId)}
                      className="rounded-circle"
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      src={
                        API_URL +
                        "api/getUserPhoto/" +
                        userId +
                        "/" +
                        displayName
                      }
                      alt="user"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyimage;
                      }}
                    />
                  </div>
                  <hr />
                  <h6 className="d-flex flex-wrap justify-content-center text-secondary  ">
                    {" "}
                    {displayName}
                    {premiumData && premiumUser(premiumData) && (
                      <img
                        src={premiumUser(premiumData)}
                        alt=""
                        className="ml-1 mr-3"
                        style={{ width: "20px", height: "20px" }}
                      />
                    )}
                  </h6>

                  {/* <Grid container spacing={0}>
                  <Grid item md={12} xs={12} sm={12} lg={12}>
                    <ul>
                      <li style={{ color: "gray" }}>{duration}</li>
                    </ul>
                  </Grid>
                </Grid> */}
                </div>
              </Grid>
            </Grid>
          </BlockUi>
        </div>
        {this.state.openRequestReplyState && this.openRequestReplyPopup()}
      </>
    );
  }
  deleteComment = (e, id) => {
    let data = {
      askHelpRequestId: id,
    };
    ProfileService.askHelpReplyDelete(data)
      .then((response) => {
        toast.success("Comment deleted successfully");
        const list = this.state.commentsList.filter(
          (item) => item.helpId !== id
        );
        this.setState({
          commentsList: list,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  fetchMoreDataFriends = () => {
    let data = {
      jobRequestId: this.state.reqId,
      page: this.state.friendsPage,
    };
    ProfileService.getAllRequestHelpReplyList(data)
      .then((response) => {
        var result = response.data.data.requestReply;
        if (response.data.data.totalPages - 1 > this.state.friendsPage) {
          this.setState({
            friendsHasMore: true,
            friendsPage: this.state.friendsPage + 1,
          });
          var oldPostList = this.state.commentsList;
          var newRequests = oldPostList.concat(result);
          this.setState({
            commentsList: newRequests,
          });
          console.log("newRequests", newRequests);
        } else {
          this.setState({
            friendsHasMore: false,
            commentsList: this.state.commentsList,
          });
        }
        // this.setState({
        //   commentsList: result,
        // });
      })
      .catch((error) => {
        //
      });
  };

  requestReply = (qid) => {
    this.setState({
      openRequestReplyState: true,
    });
  };

  handleReportPopupClose = () => {
    this.setState({ openRequestReplyState: false });
  };

  openRequestReplyPopup() {
    const { t } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Modal show="true" onHide={this.handleReportPopupClose}>
          <Modal.Header>{t("ADD REPLY FOR REQUEST")}</Modal.Header>
          <div
            className="tab-pane-view"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="profile"
          >
            <div className="profileTabContent">
              <Form
                id="profileForm"
                noValidate
                validated={this.state.validated}
                onSubmit={this.onRequest}
              >
                <Card className="profileCard">
                  <Card.Body>
                    <div className="row ">
                      <div className="col-xs-12 col-md-12">
                        <Form.Group className="formInput">
                          {/* <Form.Label></Form.Label>
                          <textarea
                            className="formInputsDescriptions"
                            name="description"
                            rows="3"
                            cols="30"
                            value={this.state.description}
                            onChange={this.handleForm}
                          ></textarea>
                          <Form.Control.Feedback type="invalid">
                            {t("Please Enter Description")}
                          </Form.Control.Feedback> */}
                          <TextEditorQuestion
                            ref={this.myRef}
                            editorStateValue={this.editorStateValues}
                            editorValue={this.state.questionDescribed}
                          />
                          <p
                            className="boxText"
                            style={
                              this.state.descriptionCount > 2500
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >
                            {" "}
                            {2500 - this.state.descriptionCount}{" "}
                            {t("characters left")}
                          </p>
                        </Form.Group>
                        <br />
                      </div>
                    </div>
                  </Card.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      style={{ color: "white" }}
                      onClick={this.handleReportPopupClose}
                    >
                      {t("Close")}
                    </Button>
                    <div></div>
                    <Button
                      type="submit"
                      variant="primary"
                      style={{ color: "white" }}
                    >
                      {t("Submit")}
                    </Button>
                  </Modal.Footer>
                </Card>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  onRequest = (e) => {
    e.preventDefault();
    if (
      this.state.content.replace(/([&nbsp;<p></p>\"'])/g, "").trimStart() == ""
    ) {
      toast.warning("Please add your comments");
    } else {
      var data = {
        jobRequestId: this.props.match.params.id,
        description: this.state.content,
      };
      PostService.saveRequestHelpReply(data)
        .then((response) => {
          toast.success("Reply successfully");

          let data = response.data.data.discription;
          let list = [data ? data : [], ...this.state.commentsList];
          console.log("list", list);
          console.log("list", data);
          this.setState({
            commentsList: list,
          });
          // this.getAllRequestHelpReplyList(this.state.reqId);
        })
        .catch((error) => {
          toast.warn("Error submitting ");
        });
      this.setState({ openRequestReplyState: false });
    }
  };
  showFriendsProfile = (userId) => {
    if (userId !== this.props.loginDetails.uId) {
      const route = `/${this.props.workspaceType}/friendDescription/${userId}`;
      // console.log("Show Friends Profile ",route);
      this.props.history.push(route, { from: window.location.pathname });
    }
  };
  backtoHome = () => {
    if (this.state.userId !== this.props.loginDetails.uId) {
      this.props.history.push(
        `/${this.props.workspaceType}/other-requests-list`
      );
    } else {
      this.props.history.push(`/${this.props.workspaceType}/my-requests`);
    }
  };

  editorStateValues = (values) => {
    console.log("editorStateValues");
    console.log(values);
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        content: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        content: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  };
  handleMenuClick = (event) => {
    this.setState({ menuState: event.target });
  };
  handleMenuClose = () => {
    this.setState({
      menuState: false,
    });
  };
  editAskHelp = (e, helpId) => {
    this.props.history.push(
      `/${this.props.workspaceType}/ask-help-form/${helpId}`
    );
  };
  deleteAskHelp = (event, helpId) => {
    event.preventDefault();
    event.stopPropagation();
    this.handleMenuClose();
    Swal.fire({
      title: "Are you sure to delete this request ?",
      text: "",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        var deleteSubId = {
          askHelpId: helpId,
        };
        MyYuukke.askHelpDelete(deleteSubId)
          .then((response) => {
            this.setState({
              menuState: false,
            });
            this.props.history.push(`/${this.props.workspaceType}/my-requests`);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
}

const mapStateToProps = function (state) {
  return {
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    loginDetails: state.loginDetailsApi.loginDetails,
    myRequestList: state.myRequestReducers.myRequestList,
    otherRequestList: state.myRequestReducers.otherRequestList,
    allIndustryList: state.commonApi.industrylist.data,
    areaofInterest: state.commonApi.areaofinterestlist,
    jobRequestLoaderStatus: state.myRequestReducers.jobRequestLoaderStatus,
    jobRequestDetails: state.myRequestReducers.jobRequestDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobRequestPostDetails: (id) => dispatch(getJobRequestPostDetails(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(MyRequestDescription)));
