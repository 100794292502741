import React, { Component } from "react";
import "./PremiumSubscriptionActivation.css";
import PremiumUserApi from "../../api/PremiumUserApi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class PremiumSubscriptionActivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlan: null,
      message: "",
      plans: [],
    };
  }

  componentDidMount() {
    this.getPremiumData();
  }

  getPremiumData = () => {
    try {
      PremiumUserApi.getPremiumBadgeForUser().then((response) => {
        if (response.data.message === "success") {
          this.setState({
            plans: response.data.data,
          });
        }
      });
    } catch (error) {
      console.error("Error fetching premium data:", error);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.state.selectedPlan) {
      this.setState({ message: "Please select a subscription plan." });
      return;
    }

    try {
      let reqdata = {
        activeAmount: this.state.selectedPlan.badgeAmount,
        activePlan: "gold batch",
      };

      PremiumUserApi.saveOrUpdateActivatePermiumBadges(reqdata).then(
        (response) => {
          const result = response.data.data;
          console.log("result", result);
        }
      );
    } catch (error) {
      this.setState({ message: "An error occurred. Please try again." });
    }
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="premium-subscription-activation">
        <h2>Activate Your Premium Subscription</h2>
        <p className="free-subscription-warning">
          You are currently using a free subscription with limited access.
          Please activate a premium subscription to unlock all features.
        </p>
        <p>Choose a subscription plan to enjoy exclusive premium features:</p>
        <div className="subscription-plans">
          {this.state.plans.map((plan) => (
            <div
              key={plan.id}
              className={`plan-card ${
                this.state.selectedPlan &&
                this.state.selectedPlan.id === plan.id
                  ? "selected"
                  : ""
              }`}
              onClick={() => this.setState({ selectedPlan: plan })}
            >
              <div class="hight-badgeName-amount">
                <h3>{plan.badgeName} Plan</h3>
                <h3>₹{plan.badgeAmount}</h3>
              </div>
              <div class="content-of-premium">
                <p>Get access to all premium features for a lower cost.</p>
                <p>Post ads and promote your content</p>
                <p>Manage your schedule with the calendar feature</p>
                <p>Engage with the community</p>
                <p>Connect with like-minded individuals</p>
                <p>Access learning resources</p>
                <p>Host and join meetings</p>
                <p>Share your achievements and progress</p>
              </div>
            </div>
          ))}
        </div>
        <form onSubmit={this.handleSubmit}>
          <button type="submit">Activate</button>
        </form>
        {this.state.message && <p>{this.state.message}</p>}
      </div>
    );
  }
}

export default PremiumSubscriptionActivation;
