import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";

const PhoneNumberContainer = ({
  phoneNumber,
  updateData,
  defaultClass = "",
}) => {
  const countryList = useSelector((state) => state.commonApi.countryName);
  const [state, setState] = useState({
    countryList: [],
    country: "",
    // phoneNumber: "",
  });

  useEffect(() => {
    if (countryList.length > 0) {
      const list = countryList.map((item) => {
        return { value: item.iso, label: item.nicename };
      });
      updateState("countryList", list);
    }
  }, []);

  useEffect(() => {
    if (state.countryList?.length > 0) {
      try {
        fetch("https://ipapi.co/json/")
          .then((response) => response.json())
          .then((data) => {
            console.log("user-location", data.country);

            const selectedList = state.countryList.find(
              (country) => country.value === data.country
            );
            updateState("country", selectedList);
          })
          .catch((error) => {
            console.error("Error fetching user location:", error);
          });
      } catch (e) {
        console.log("e", e);
      }
    }
  }, [state.countryList]);
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  console.log("state", state);
  return (
    <>
      <PhoneInput
        country={
          state.country?.value ? state.country?.value.toLowerCase() : "in"
        }
        containerClass={defaultClass}
        value={phoneNumber}
        onChange={(phone) => updateData(phone)}
        required={true}
      />
    </>
  );
};

export default PhoneNumberContainer;
