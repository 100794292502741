import React, { Component } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ProfileService from "../../../api/ProfileService";
import AuthService from "../../../session/AuthService";
import { toast } from "react-toastify";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import VideocamIcon from "@material-ui/icons/Videocam";
import "./groupPostDesign.css";
import DescriptionIcon from "@material-ui/icons/Description";
import { getMyGroupsByMembersandMessages } from "../../../redux/actions/subjectActions";
import { API_URL } from "../../../config/app-config";
import dummyimage from "../../../img/default_avatar.jpg";
import "../../css/mysocial.css";
import "../../css/mysocialprofile.css";
import "../../css/yuukkeTutoring.css";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import "react-widgets/styles.css";
import Grid from "@material-ui/core/Grid";
import Swal from "sweetalert2";

import { connect } from "react-redux";
import {
  getDashboardList,
  getMyQuestionsList,
} from "../../../redux/actions/dashboardActions";
import { getAllSubjectList } from "../../../redux/actions/subjectActions";
import { getMyLibraryQuestionList } from "../../../redux/actions/MyLibraryQuestionActions";
import { getQuestionsList } from "../../../redux/actions/questionActions";
import moment from "moment";
import Utils from "../../../utils/Utils";
import MessagesFileOption from "./MessagesFileOption";
import {
  getSubjectQuestionsList,
  getOverallPostList,
} from "../../../redux/actions/SubjectquestionActions";
import PostService from "../../../api/PostService_old";
import SurveyUi from "../../Home/HomeUpdated/SurveyUi";
import { getPostTagList } from "../../../redux/actions/commonApiActions";
import MentionsInputStyle from "../../../components/UserMention/Mention";
import { readOnlyWorkspace, removeMentionTags } from "../../../utils";
import AnonymousModel from "../../../components/AnonymousModel";
class MessagesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileTypeKey: "",
      fileTypeState: false,
      blocking: false,
      fileUrl: null,
      type: null,
      mediaPost: "",
      isChecked: false,
      teaching: [],
      tags: [],
      question: "",
      classDetails: [],
      addTagsState: false,
      fileOption: false,
      gradeDetails: [],
      questionDescribed: "",
      content: "",
      editState: false,
      gradeId: "",
      questionTitleCount: 0,
      descriptionCount: 0,
      curriculumList: [],
      curriculumId: "",
      postCategory: "normal",
      posType: "",
      groupName: "",
      groupId: "",
      pollQuestion: "",
      inputs: [],
      frPollAnswer: [],
      SurveyQuestionandAns: [],
      currentValues: false,
      file: [],
      commentsEditText: "",
      needEdit: false,
      loader: false,
      anonymousPost: false,
      anonymousModel: false,
    };
    this.block = this.block.bind(this);
    this.myRef = React.createRef();
    this.getallSubject = this.getallSubject.bind(this);
    this.editorStateValues = this.editorStateValues.bind(this);
    this.handleTeachingMultiSelect = this.handleTeachingMultiSelect.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.getQuestionMediaFiles = this.getQuestionMediaFiles.bind(this);
    this.handleGrade = this.handleGrade.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.fileOptionEnable = this.fileOptionEnable.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.getAllCurriculum = this.getAllCurriculum.bind(this);
    this.handleCurriculum = this.handleCurriculum.bind(this);
    this.getGradeByCurriculumId = this.getGradeByCurriculumId.bind(this);
    this.getGradeByCurriculumIdUpdate =
      this.getGradeByCurriculumIdUpdate.bind(this);
    this.keyFile = this.keyFile.bind(this);
    this.closeChoosedFileData = this.closeChoosedFileData.bind(this);
    this.handleFormEnterKey = this.handleFormEnterKey.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.getGroupMemberList();
  }

  render() {
    const { t } = this.props;
    const { postCategory } = this.state;
    const { handleViewAllQuestions } = this.props;
    return (
      <div className="">
        {/* <div className="askAQuestionCardWrapper">
          <BlockUi
            tag="div"
            blocking={this.state.blocking}
            message={t("Loading, please wait")}
            color="#60c2e7"
          >
            <div
              className="newPostSearchField"
              style={{ paddingBottom: "8px" }}
            >
              <input
                type="text"
                name="question"
                placeholder="What do you want to share"
                onKeyDown={this.handleFormEnterKey}
                value={this.state.question}
                maxLength="250"
                onChange={this.handleForm}
                style={{ fontSize: "15px" }}
              />
            </div>
            {!this.state.fileTypeState && (
              <Grid
                container
                spacing={8}
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <Grid item md={5} sm={5} xs={5} lg={5}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.keyFile("image")}
                  >
                    <CameraAltIcon
                      style={{
                        cursor: "pointer",
                        marginLeft: "12px",
                        color: "#F97516",
                      }}
                    />
                    {"  "}image
                  </div>{" "}
                </Grid>
                <Grid item md={4} sm={4} xs={4} lg={4}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.keyFile("video")}
                  >
                    <VideocamIcon
                      style={{
                        cursor: "pointer",
                        marginLeft: "12px",
                        color: "#F97516",
                      }}
                    />
                    {"  "}video
                  </div>
                </Grid>
                <Grid item md={3} sm={3} xs={3} lg={3}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.keyFile("audio")}
                  >
                    <DescriptionIcon
                      style={{
                        cursor: "pointer",
                        marginLeft: "25px",
                        color: "#F97516",
                      }}
                    />
                    {"  "}file
                  </div>
                </Grid>
              </Grid>
            )}
            {this.state.fileTypeState && (
              <div>
                <AiOutlineCloseCircle
                  onClick={this.closeChoosedFileData}
                  style={{
                    cursor: "pointer",
                    paddingTop: "10px",
                    float: "right",
                    marginBottom: "8px",
                    width: "20px",
                    height: "20px",
                    color: "#000000",
                  }}
                />

                <MessagesFileOption
                  fileTypeKey={this.state.fileTypeKey}
                  fileOption={this.state.fileOption}
                  fileOptionEnable={this.fileOptionEnable}
                  fromQuestionMedia={this.getQuestionMediaFiles}
                  editObject={this.props.editObject}
                ></MessagesFileOption>
              </div>
            )}{" "}
          </BlockUi>
          {!this.state.editState && (
            <div className="askAQuestionBtn">
              <center>
                <button onClick={(e) => this.onFormSubmit(e)}>
                  {t("SUBMIT")}
                </button>
              </center>
            </div>
          )}
        </div> */}

        <div class="groupPost-card groupPost-card-body">
          <div class="groupPost-d-flex groupPost-mb-3">
            <div class="groupPost-avatar groupPost-avatar-xs groupPost-me-2">
              <a href="#">
                {" "}
                <img
                  class="groupPost-avatar-img groupPost-rounded-circle"
                  src={
                    API_URL +
                    "api/getUserPhoto/" +
                    this.props.loginDetails.uId +
                    "/" +
                    this.props.loginDetails.dName
                  }
                  alt="user"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = dummyimage;
                  }}
                />{" "}
              </a>
            </div>
            <form class="groupPost-w-100">
              {postCategory === "polling" && (
                <div
                  className="Polling"
                  style={{
                    marginTop: "2rem",
                  }}
                >
                  <div className="PTCInputWrapper">
                    <Grid container>
                      <Grid className="gridform" item xs={12} sm={12}>
                        <textarea
                          style={{
                            width: "100%",
                            minHeight: "38px",
                            borderRadius: "5px",
                            padding: "20px 20px",
                            background: "#dceaee",
                            border: "none",
                          }}
                          type="text"
                          onChange={this.handleForm}
                          required=""
                          name="pollQuestion"
                          value={this.state.pollQuestion}
                          placeholder="Ask your question"
                        />
                      </Grid>
                      {this.state.inputs.length > 0 &&
                        this.state.inputs.map((entry, index) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingBlock: "1rem",
                              width: "100%",
                            }}
                          >
                            <span key={index} class="socialCardTextColor">
                              Option - {index + 1}
                            </span>

                            <Grid container spacing={2} key={entry.index}>
                              <Grid className="gridform" item xs={12} sm={11}>
                                <input
                                  className="cpollInput"
                                  fullWidth
                                  type="text"
                                  onChange={(e) =>
                                    this.handleInputPollAnswer(e, index, "name")
                                  }
                                  style={{
                                    width: "100%",
                                    height: "4rem",
                                    border: "none",
                                    backgroundColor: "lightgray",
                                    padding: "20px",
                                  }}
                                  required=""
                                  name="frPollAnswer"
                                  placeholder="Add your option"
                                  value={
                                    this.state.frPollAnswer &&
                                    this.state.frPollAnswer[index] &&
                                    this.state.frPollAnswer[index].Sname
                                  }
                                />
                              </Grid>

                              <Grid className="gridform" item xs={12} sm={1}>
                                <span
                                  style={{
                                    display: "block",
                                    cursor: "pointer",
                                    // padding: "20px",
                                    fontSize: "35px",
                                  }}
                                  class="socialCardTextColor"
                                  onClick={() =>
                                    this.removeTranslation(entry, index)
                                  }
                                >
                                  {" "}
                                  -{" "}
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        ))}
                      <span
                        style={{
                          cursor: "pointer",
                          marginTop: "20px",
                          paddingBottom: "20px",
                        }}
                        class="socialCardTextColor"
                        onClick={this.addTranslation}
                      >
                        + Add more Option
                      </span>
                    </Grid>
                    <div
                      onClick={() =>
                        this.anonymousModel(!this.state.anonymousPost, true)
                      }
                      className="d-flex flex-wrap align-items-center mb-3"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="boxcolor mr-1 event-box">
                        <input
                          type="checkbox"
                          value={this.state.anonymousPost}
                          checked={this.state.anonymousPost}
                          defaultChecked={this.state.anonymousPost}
                          id="anonymousPost"
                        />
                      </div>
                      <p
                        style={{ fontSize: "12px" }}
                        className="font-weight-bold"
                      >
                        Do you want this polling as anonymous ?
                      </p>
                    </div>
                    {this.state.anonymousPost && this.state.anonymousModel && (
                      <AnonymousModel closeModel={this.anonymousModel} />
                    )}
                  </div>
                </div>
              )}
              {postCategory === "survey" && (
                <SurveyUi
                  surveyQuestion={this.SurveyQuestionandAns}
                  DataClear={this.state.surveyDataClear}
                />
              )}
              {(this.state.postCategory == "normal" ||
                this.state.postCategory == "advertisement") && (
                // <TextEditorQuestion
                //   ref={this.myRef}
                //   editorStateValue={this.editorStateValues}
                //   editorValue={this.state.questionDescribed}
                //   currentValues={this.state.currentValues}
                // />
                // <TextEditorComments
                //   editorStateValue={this.commentEditValues}
                //   editorValue={this.state.commentsEditText}
                //   friendList={this.props.friendsList}
                //   needEdit={this.state.needEdit}
                //   changeNeedHelpState={this.changeNeedHelpState}
                // />
                <MentionsInputStyle
                  placeholder={"Ask a question.."}
                  editorStateValue={this.commentEditValues}
                  friendList={this.props.friendsList}
                  type={"group"}
                  needEdit={this.state.needEdit}
                  changeNeedHelpState={this.changeNeedHelpState}
                  editorValue={""}
                  isGroup={true}
                />

                // <input
                //   class="groupPost-form-control groupPost-pe-4 groupPost-border-0"
                //   placeholder="Share your thoughts..."
                //   name="content"
                //   onKeyDown={(e) => this.handleFormEnterKey(e)}
                //   value={this.state.content}
                //   maxLength="250"
                //   onChange={this.handleForm}
                //   data-bs-toggle="modal"
                //   data-bs-target="#modalCreateFeed"
                // />
              )}
            </form>
          </div>
          {
            <>
              {!this.state.fileTypeState && (
                <ul
                  style={{ marginBottom: "12px" }}
                  class="groupPost-nav groupPost-nav-pills groupPost-nav-stack groupPost-small groupPost-fw-normal"
                >
                  {postCategory !== "survey" && postCategory !== "polling" && (
                    <li
                      class="groupPost-nav-item"
                      onClick={() => this.keyFile("image")}
                    >
                      <a
                        class="groupPost-nav-link bg-light"
                        href="#!"
                        data-bs-toggle="modal"
                        style={{ cursor: "pointer" }}
                        data-bs-target="#feedActionPhoto"
                      >
                        {" "}
                        <CameraAltIcon
                          style={{
                            color: "#0cbc87",
                          }}
                        />{" "}
                        Photo
                      </a>
                    </li>
                  )}

                  {postCategory !== "advertisement" &&
                    postCategory !== "survey" &&
                    postCategory !== "polling" && (
                      <>
                        {" "}
                        <li
                          class="groupPost-nav-item"
                          onClick={() => this.keyFile("video")}
                        >
                          <a
                            class="groupPost-nav-link bg-light"
                            href="#!"
                            data-bs-toggle="modal"
                            style={{ cursor: "pointer" }}
                            data-bs-target="#feedActionVideo"
                          >
                            {" "}
                            <VideocamIcon
                              style={{
                                color: "#4f9ef8",
                              }}
                            />{" "}
                            Video
                          </a>
                        </li>
                        <li
                          class="groupPost-nav-item"
                          onClick={() => this.keyFile("audio")}
                        >
                          <a
                            href="#"
                            class="groupPost-nav-link bg-light"
                            data-bs-toggle="modal"
                            style={{ cursor: "pointer" }}
                            data-bs-target="#modalCreateEvents"
                          >
                            {" "}
                            <DescriptionIcon
                              style={{
                                color: "#dc3545",
                              }}
                            />{" "}
                            File{" "}
                          </a>
                        </li>
                      </>
                    )}
                  <li class="groupPost-nav-item">
                    <div
                      class="groupPost-nav-link bg-light"
                      data-bs-toggle="modal"
                      style={{ cursor: "pointer" }}
                      data-bs-target="#modalCreateEvents"
                    >
                      <select
                        name="postCategory"
                        style={{
                          border: "none",
                          backgroundColor: "#f8f9fa",
                          // padding: "5px 5px",
                          cursor: "pointer",
                        }}
                        id="postCategory"
                        onChange={this.handlepostingType}
                      >
                        <option value="normal">Normal Post</option>
                        <option value="advertisement">Advertisement</option>
                        <option value="polling">Polling Post</option>
                        <option value="survey">Survey Post</option>
                      </select>
                    </div>
                    {/* <a href="#" class="groupPost-nav-link bg-light" data-bs-toggle="modal" style={{cursor: "pointer"}} data-bs-target="#modalCreateEvents">  <DescriptionIcon
            style={{
              color: "#dc3545",
            }}  /> File </a> */}
                  </li>
                </ul>
              )}
              {this.state.fileTypeState && (
                <div>
                  <AiOutlineCloseCircle
                    onClick={this.closeChoosedFileData}
                    style={{
                      cursor: "pointer",
                      paddingTop: "10px",
                      float: "right",
                      marginBottom: "8px",
                      width: "20px",
                      height: "20px",
                      color: "#000000",
                    }}
                  />

                  <MessagesFileOption
                    fileTypeKey={this.state.fileTypeKey}
                    fileOption={this.state.fileOption}
                    fileOptionEnable={this.fileOptionEnable}
                    fromQuestionMedia={this.getQuestionMediaFiles}
                    editObject={this.props.editObject}
                  ></MessagesFileOption>
                </div>
              )}{" "}
            </>
          }
          {!this.state.editState && (
            <div className="askAQuestionBtn">
              <center>
                {/* <button onClick={(e) => this.onFormSubmit(e)}>
                  {t("SUBMIT")}
                </button> */}

                <button
                  style={{ pointerEvents: this.state.loader ? "none" : "auto" }}
                  class="btn mb-0 connectionSumbitLabel"
                  onClick={(e) => this.onFormSubmit(e)}
                >
                  {this.state.loader ? (
                    <div class="spinner-border text-light" role="status"></div>
                  ) : (
                    <>{t("SUBMIT")}</>
                  )}
                </button>
              </center>
            </div>
          )}
        </div>
      </div>
    );
  }
  getGroupMemberList = () => {
    this.props.getPostTagList("groups", this.props.group_event_id);
  };
  changeNeedHelpState = () => {
    this.setState({
      needEdit: false,
    });
  };
  commentEditValues = (values) => {
    this.setState({
      content: values,
    });
    // var htmlString = values;
    // var plainString = htmlString.replace(/<[^>]+>/g, "");
    // if (plainString.length === 1) {
    //   this.setState({
    //     content: values,
    //   });
    // } else {
    //   this.setState({
    //     content: values,
    //   });
    // }
  };
  SurveyQuestionandAns = (data) => {
    console.log("final ans", data);
    this.setState({
      SurveyQuestionandAns: data,
    });
  };
  handleInputPollAnswer(event, index, type) {
    event.preventDefault();
    event.stopPropagation();

    var newAnswers = this.state.frPollAnswer;
    if (newAnswers[index]) {
      if (type == "name") {
        var obj = newAnswers[index];
        obj.Sname = event.target.value;
        newAnswers[index] = obj;
      } else {
        var obj = newAnswers[index];
        obj.Scode = event.target.value;
        newAnswers[index] = obj;
      }
    } else {
      if (type == "name") {
        var obj = newAnswers[index];
        var obj = {
          Sname: event.target.value,
          Scode: "",
        };
        newAnswers[index] = obj;
      } else {
        var obj = {
          Sname: "",
          Scode: event.target.value,
        };
        newAnswers[index] = obj;
      }
    }

    this.setState({
      frPollAnswer: newAnswers,
    });
    console.log("this.state.frsubjectName");

    console.log(this.state.frPollAnswer);
    //  this.setState({answers:answer_id.target.value})
  }
  addTranslation = (e) => {
    var len = this.state.inputs.length;
    if (
      this.state.frPollAnswer.length !== 0 &&
      this.state.frPollAnswer[len - 1] &&
      this.state.frPollAnswer[len - 1].Sname !== ""
    ) {
      console.log("inp", this.state.frPollAnswer[len - 1].Sname);
      var input = this.state.inputs;
      input.push(1);
      this.setState({
        inputs: input,
      });
    } else {
      toast.warning("Please fill option");
    }
  };
  removeTranslation = (record, index) => {
    console.log("REMOVE TRANSLATION");

    if (this.state.inputs.length > 1) {
      var inpu = this.state.inputs;
      inpu.splice(index, 1);
      if (this.state.frPollAnswer[index]) {
        var val = this.state.frPollAnswer;
        val.splice(index, 1);
        this.setState({
          frPollAnswer: val,
        });
      }
      this.setState({
        inputs: inpu,
      });
    }
  };
  handlepostingType = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      frPollAnswer: [],
      inputs: [1],
      pollQuestion: "",
    });
  };
  handleFormEnterKey(e) {
    if (this.state.content != "") {
      if (e.keyCode === 13) {
        // e.preventDefault();
        // e.stopPropagation();
        this.onFormSubmit(e);
      }
    }
    if (this.state.commentsText == "") {
      //  toast.warn("Comment should not be empty");
    }
  }
  closeChoosedFileData() {
    this.setState({ fileTypeState: false, file: [], postCategory: "normal" });
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  handleGrade(event) {
    if (this.state.curriculumId == "") {
      Swal.fire({
        title: "Please choose valid program before choosing grade",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
        reverseButtons: true,
      }).then((result) => {});
    } else {
      this.setState({
        //[event.target.name]
        gradeId: event.target.value,
      });
    }
  }

  getQuestionMediaFiles(files, type) {
    this.setState({ file: files, type: type });
  }

  keyFile(key) {
    this.setState({ fileTypeKey: key, fileTypeState: true });
  }

  editorStateValues(values) {
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length == 1) {
      this.setState({
        content: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        content: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  }

  handleTeachingMultiSelect(subject_value) {
    this.setState({
      teaching: subject_value,
    });
  }

  getallSubject() {
    this.setState({ app_subjects: this.props.subjectsList.data });
  }

  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        this.setState({ gradeDetails: result.data.grade });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fileOptionEnable() {
    this.setState({ fileOption: false, questionFile: null });
  }
  loaderState = () => {
    this.setState({
      loader: !this.state.loader,
    });
  };

  onFormSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let validationSuccess = false;
    // const paragraph = this.state.content;
    // const regex = /#\w+/g;
    // const found = paragraph.match(regex);
    // console.log("para", found);
    const formData = new FormData();
    if (
      !readOnlyWorkspace(
        this.props.userWorkSpace?.role,
        this.props.userWorkSpace?.typeHandle
      )
    ) {
      if (
        this.state.postCategory !== "survey" &&
        this.state.postCategory !== "polling" &&
        // this.state.content.replace(/([&nbsp;<p></p>\"'])/g, "").trimStart() ==""
        this.state.content == "" &&
        this.state.file &&
        this.state.file.length === 0
      ) {
        toast.warn("Please share something....");
      } else if (
        this.state.postCategory === "advertisement" &&
        (this.state.file.length === 0 ||
          this.state.file === null ||
          this.state.file == "")
      ) {
        toast.warn("Please choose  image....");
      } else {
        this.loaderState();
        if (
          this.state.postCategory === "normal" ||
          this.state.postCategory === "advertisement"
        ) {
          if (this.state.file !== undefined && this.state.file.length !== 0) {
            validationSuccess = true;
            formData.append("userName", this.props.loginDetails.dName);
            formData.append(
              "questionName",
              removeMentionTags(this.state.content)
            );
            formData.append("markAsPrivate", true);
            formData.append("Type", this.state.type);
            formData.append("tagDescription", this.props.group_event_name);
            formData.append("subjectId", this.props.group_event_id);
            formData.append("groupType", false);
            // found && formData("hashtag", found);
            formData.append("postCategory", this.state.postCategory);
            for (let i = 0; i < this.state.file.length; i++) {
              formData.append("messgaeFiles", this.state.file[i]);
            }
            formData.append("isGroup", true);
          } else {
            validationSuccess = true;
            formData.append("userName", this.props.loginDetails.dName);
            formData.append(
              "questionName",
              removeMentionTags(this.state.content)
            );
            formData.append("markAsPrivate", true);
            formData.append("Type", "text");
            formData.append("tagDescription", this.props.group_event_name);
            formData.append("subjectId", this.props.group_event_id);
            formData.append("groupType", false);
            // found && formData("hashtag", this.state.found);
            formData.append("postCategory", this.state.postCategory);
            formData.append("isGroup", true);
          }
        } else if (this.state.postCategory === "polling") {
          if (this.state.pollQuestion === "") {
            toast.warn("Please Add Questions");
          } else if (this.state.frPollAnswer.length === 0) {
            toast.warn("Please Add Answers");
          } else {
            validationSuccess = true;
            var filterAns = [];
            let array = this.state.frPollAnswer;
            array.forEach((element) => {
              filterAns.push(element.Sname);
            });

            formData.append("userName", this.props.loginDetails.dName);
            formData.append("markAsPrivate", true);
            formData.append("Type", "text");
            formData.append("tagDescription", this.props.group_event_name);
            formData.append("subjectId", this.props.group_event_id);
            formData.append("groupType", false);
            formData.append("postCategory", this.state.postCategory);
            formData.append("postQuestion", this.state.posType);
            formData.append("postAnswers", filterAns);
            formData.append("isGroup", true);
            formData.append("isAnonymous", this.state.anonymousPost);
          }
        } else if (this.state.postCategory === "survey") {
          if (this.state.SurveyQuestionandAns.length === 0) {
            toast.warn("Please add your questions and answers");
          } else {
            if (this.state.SurveyQuestionandAns[0].answerType == "") {
              toast.warn("Please select your question type");
            } else if (
              this.state.SurveyQuestionandAns[0].answerType === "Text" &&
              this.state.SurveyQuestionandAns[0].textTypeAnswer == ""
            ) {
              toast.warn("Please fill answer");
            } else if (
              this.state.SurveyQuestionandAns[0].answerType === "Select" &&
              this.state.SurveyQuestionandAns[0].answers.length === 0
            ) {
              toast.warn("Please add your Answer");
            } else {
              validationSuccess = true;
              var arrayList = this.state.SurveyQuestionandAns;
              var final = [];

              arrayList.forEach((data) => {
                if (data.answerType === "Text") {
                  final.push({
                    question: data.question,
                    answer: data.textTypeAnswer,
                    answerType: data.answerType,
                  });
                } else {
                  final.push({
                    question: data.question,
                    answers: data.answers,
                    answerType: data.answerType,
                  });
                }
              });

              var obj = {
                questions: final,
              };
              const myJSON = JSON.stringify(obj);

              formData.append("userName", this.props.loginDetails.dName);
              formData.append(
                "questionName",
                removeMentionTags(this.state.content)
              );
              formData.append("markAsPrivate", true);
              formData.append("Type", "text");
              formData.append("tagDescription", this.props.group_event_name);
              formData.append("subjectId", this.props.group_event_id);
              formData.append("groupType", false);
              formData.append("surveyQA", myJSON);
              formData.append("postCategory", this.state.postCategory);
              formData.append("isGroup", true);
            }
          }
        }
        if (validationSuccess) {
          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };
          PostService.createHomePost(formData, config)
            .then((response) => {
              this.setState({
                content: "",
                pollQuestion: "",
                inputs: [],
                frPollAnswer: [],
                postCategory: "normal",
                file: [],
                questionDescribed: "",
                // currentValues:!this.state.currentValues,
                fileTypeState: false,
                needEdit: true,
              });
              this.props.getOverallPostList(
                this.props.pageNo,
                this.props.group_event_id
              );
              // this.props.getMyGroupsByMembersandMessages();
              this.block(false);
              toast.success("✓ Question Saved Successfully,");
              validationSuccess = false;
              this.loaderState();
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                pollQuestion: "",
                inputs: [],
                frPollAnswer: [],
                postCategory: "normal",
                content: "",
                file: [],
                questionDescribed: "",
                // currentValues:!this.state.currentValues,
                fileTypeState: false,
              });
              validationSuccess = false;
              toast.error("Error in saving post");
              this.loaderState();
            });
        } else {
          this.loaderState();
        }

        // if (this.state.question === "") {
        //   toast.warn("Please enter valid message");
        // } else {
        //   if (this.state.file == undefined || this.state.file == "") {
        //     formData.append("userName", this.props.loginDetails.dName);
        //     formData.append("questionDescription", "");
        //     formData.append("questionName", this.state.question.trim());
        //     formData.append("questionGradeId", 0);
        //     formData.append("programId", 0);
        //     formData.append("markAsPrivate", this.state.isChecked);
        //     formData.append("Type", "text");
        //     formData.append("tagDescription", this.props.group_event_name);
        //     formData.append("subjectId", this.props.group_event_id);
        //     formData.append("groupType", true);
        //   } else {
        //     formData.append("userName", this.props.loginDetails.dName);
        //     //formData.append('QuestionFiles', this.state.file)
        //     formData.append("questionDescription", "");
        //     formData.append("questionName", this.state.question.trim());
        //     formData.append("questionGradeId", 0);
        //     formData.append("programId", 0);
        //     formData.append("markAsPrivate", this.state.isChecked);
        //     formData.append("Type", this.state.type);
        //     formData.append("tagDescription", this.props.group_event_name);
        //     formData.append("subjectId", this.props.group_event_id);
        //     formData.append("groupType", true);
        //     for (let i = 0; i < this.state.file.length; i++) {
        //       formData.append("messgaeFiles", this.state.file[i]);
        //     }
        //     // for (let i = 0; i < this.state.teaching.length; i++) {

        //     // }
        //   }

        //   const config = {
        //     headers: {
        //       "content-type": "multipart/form-data",
        //     },
        //   };
        //   this.block(true);
        // ForumService.saveQuestionV2(formData, config)
        //   .then((response) => {
        //     var result = response.data;
        //     //toast.success("✓ Question Saved Successfully,");
        //     this.Mixpanelcall();
        //     this.props.getOverallPostList(
        //       this.props.pageNo,
        //       this.props.group_event_id
        //     );
        //     // this.props.getPostOverall();
        //     this.setState({ question: "", fileOption: true, file: "" });
        //     this.props.getMyGroupsByMembersandMessages();
        //     //   this.props.getSubjectQuestionsList("newest", 1, this.props.group_event_id, true, true, this.props.loginDetails.uId)

        //     this.block(false);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     toast.error("Error in saving question");
        //   });
      }
    }
  }

  block(blocking) {
    this.setState({ blocking: blocking });
  }

  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
      questionTitleCount: event.target.value.length,
    });
  }

  getAllCurriculum() {
    this.setState({
      curriculumList: this.props.curriculumtrackList.curriculum,
    });
    // ProfileService.getAllCurriculum()
    //   .then(
    //     response => {
    //       var result = response.data;

    //     }
    //   )
    //   .catch(error => {
    //     console.log(error)
    //   }
    //   )
  }

  handleCurriculum(event) {
    this.setState({
      curriculumId: event.target.value,
      gradeId: "",
    });
    this.getGradeByCurriculumId(event.target.value);
  }

  getGradeByCurriculumId(value) {
    this.setState({ gradeId: "" });
    let getGradeByCurriculumId = {
      curriculumId: value,
    };
    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        var result = response.data;

        this.setState({ gradeDetails: response.data.data.gradeListById });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getGradeByCurriculumIdUpdate(value) {
    let getGradeByCurriculumId = {
      curriculumId: value,
    };

    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        var result = response.data;

        this.setState({ gradeDetails: response.data.data.gradeListById });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  anonymousModel = (anonymousPost, anonymousModel) => {
    this.setState({
      anonymousPost: anonymousPost,
      anonymousModel: anonymousModel,
    });
  };
}

const mapStateToProps = function (state) {
  return {
    wallList: state.dashboard.dashboardList,
    loading: state.dashboard.loading,
    tagDatas: state.subjects.list,
    questionLists: state.myquestions.myQuestionList[0],
    totalItems: state.myquestions.myQuestionList[1],
    topQuestions: state.mylibraryquestion.list,
    solvedTotalitems: state.mylibraryquestion.totalItems,
    QandAquestionList: state.topquestList.list,
    QandAquestTotalitems: state.topquestList.totalItems,
    subjectsList: state.commonApi.subjectslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    friendsList: state.commonApi.tagUserList,
    userWorkSpace: state.loginDetailsApi.loginDetails?.userWorkSpace,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getOverallPostList: (pageNo, subId) =>
      dispatch(getOverallPostList(pageNo, subId)),
    getDashboardList: () => dispatch(getDashboardList(0)),
    getAllSubjectList: (type, page) => dispatch(getAllSubjectList(type, page)),
    getMyQuestionsList: () => dispatch(getMyQuestionsList(0)),
    getMyLibraryQuestionList: () =>
      dispatch(getMyLibraryQuestionList("All", 1)),
    getQuestionsList: () =>
      dispatch(getQuestionsList("unanswered", 1, 0, true, true)),
    getSubjectQuestionsList: (
      typeQuestionTop,
      tpage,
      subjectId,
      firstTimeLoad,
      secondTimeLoad,
      userId
    ) =>
      dispatch(
        getSubjectQuestionsList(
          typeQuestionTop,
          tpage,
          subjectId,
          firstTimeLoad,
          secondTimeLoad,
          userId
        )
      ),
    getMyGroupsByMembersandMessages: () =>
      dispatch(getMyGroupsByMembersandMessages()),
    getPostTagList: (type, id) => dispatch(getPostTagList(type, id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(MessagesContainer));
