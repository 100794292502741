import React, { Component } from "react";
import AuthService from "../session/AuthService";
import FriendsFinder from "../api/FriendsFinder";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";
import { withRouter } from "react-router";
import ForumService from "../api/ForumService";
//import dummyimage from '../img/avatar_dummy.png';
import dummyimage from "../img/default_avatar.jpg";
import banner from "../img/banner.jpg";
import ProfileService from "../api/ProfileService";
import MyYuukke from "../api/MyYuukke";
import MSDeepLink from "../utils/MSDeepLink";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {
  Button,
  Form,
  Modal,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap";
import {
  getAllSubjectList,
  getAllMembersDetails,
  updateGroupMember,
} from "../redux/actions/subjectActions";
import { ToastContainer, toast } from "react-toastify";
import FriendProfile from "../screens/Home/ProfileFriends/FriendProfile";
import { API_URL } from "../config/app-config";
import { HiOutlineUserRemove } from "react-icons/hi";
import { needPhotVerification } from "../redux/actions/commonApiActions";
import { giveUserAction, premiumUser } from "../utils";
import { setSelectedUserAction } from "../redux/actions/chatActions";
import { BiUserCircle } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
let myProfileDialog = {
  backgroundColor: "#ffffff",
  color: "#000",
  width: "50%",
  minHeight: "150px",
  padding: "0px",
  borderRadius: "10px",
};
class GroupMembersAndFriendsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      base64String: "",
      base64StringState: false,
      mutualFriendsCount: 0,
      subjectslist: "",
      coverPhoto: "",
      userPhoto: "",
      findFriends: "",
      exitPopup: false,
      showFriendsProfile: false,
    };
    this.friendIgnore = this.friendIgnore.bind(this);
    this.load = this.load.bind(this);
    this.getUserSchoolAndClass = this.getUserSchoolAndClass.bind(this);
    this.callNow = this.callNow.bind(this);
    this.chatNow = this.chatNow.bind(this);
    this.getCoverPhoto = this.getCoverPhoto.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
    // this.findFriends = this.findFriends.bind(this);
    this.popUpCommentDelete = this.popUpCommentDelete.bind(this);
    this.exit = this.exit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.showFriendsProfile = this.showFriendsProfile.bind(this);
    this.closeFriendsProfile = this.closeFriendsProfile.bind(this);
  }

  componentDidMount() {
    console.log("FriendsList****", this.props.FriendsList);
    console.log("createrId**", this.props.adminId);
    console.log("subjectId", this.props.subId);
    // this.findFriends();
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.setState({}, () => {
      // this.load(this.props.userId, this.props.subjects);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId)
      this.setState({}, () => {
        // this.load(this.props.userId, this.props.subjects);
      });
  }
  // findFriends() {
  //   const receiver_List = [];
  //   const receiver_List1 = [];
  //   const receiver_List2 = [];
  //   if (this.props.FriendsList.length > 0) {
  //     for (let i = 0; i < this.props.FriendsList.length; i++) {
  //       if (this.props.FriendsList[i].userId === this.props.userId) {
  //         receiver_List.push({
  //           userId: this.props.FriendsList[i].userId,
  //         });
  //       } else if (
  //         this.props.FriendsList[i].userId !== this.props.userId &&
  //         this.props.userId !== this.props.loginDetails.uId
  //       ) {
  //         receiver_List1.push({
  //           userId: this.props.FriendsList[i].userId,
  //         });
  //       } else {
  //         receiver_List2.push({
  //           userId: this.props.FriendsList[i].userId,
  //         });
  //       }
  //     }
  //   } else {
  //     if (this.props.userId === this.props.loginDetails.uId) {
  //       receiver_List2.push({
  //         userId: this.props.userId,
  //       });
  //     } else {
  //       receiver_List1.push({
  //         userId: this.props.userId,
  //       });
  //     }
  //   }
  //   if (receiver_List.length > 0) {
  //     this.setState({
  //       findFriends: "friend",
  //     });
  //   } else if (receiver_List1.length > 0) {
  //     this.setState({
  //       findFriends: "notFriend",
  //     });
  //   } else if (receiver_List2.length > 0) {
  //     this.setState({
  //       findFriends: "Me",
  //     });
  //   }
  // }
  popUpCommentDelete() {
    const { t } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Modal show="true" onHide={this.handleClose}>
          <Modal.Header>
            {t("Actions")}{" "}
            <AiOutlineCloseCircle
              onClick={this.handleClose}
              style={{
                cursor: "pointer",
                float: "right",
                marginBottom: "8px",
                width: "20px",
                height: "20px",
                color: "#000000",
              }}
            />
          </Modal.Header>

          <Modal.Body>
            <div className="d-flex align-items-center justify-content-center ">
              <div className="connection-card">
                <img
                  className="userImg"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.showFriendsProfile(this.props.userId)}
                  src={
                    this.props.profilePhoto
                      ? `${API_URL}auth/image/${this.props.profilePhoto}`
                      : dummyimage
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = dummyimage;
                  }}
                  alt={this.props.name}
                />

                <h5>{this.props.Dname}</h5>
                <div class="d-flex flex-column " style={{ gap: "1rem" }}>
                  {!this.props.isForum &&
                    this.props.loginUserStatus === "owner" && (
                      <button
                        class="btn"
                        style={{ background: "blue", color: "white" }}
                        onClick={() => this.makeAdmin()}
                      >
                        {this.props.userStatus === "admin"
                          ? "Remove Community Admin"
                          : "Make Community Admin"}{" "}
                      </button>
                    )}

                  <button
                    // class="btn"
                    // style={{ background: "gray", color: "white" }}
                    onClick={() => this.removeUser()}
                  >
                    Remove User
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              {t("Close")}
            </Button>
            <Button onClick={this.removeUser} variant="primary">
              {t("Remove")}
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    );
  }
  exit() {
    this.setState({
      exitPopup: true,
    });
  }
  handleClose() {
    this.setState({ exitPopup: false });
  }
  makeAdmin = (status) => {
    let removeUser = {
      memberUserId: this.props.userId,
      groupsOrEvents: this.props.subId,
      isAdmin: this.props.userStatus === "admin" ? false : true,
    };
    MyYuukke.isAdmin(removeUser)
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("User Status Changed");
          this.handleClose();
          this.props.updateGroupMember(this.props.userId, "change");

          // this.props.removedSentData(this.props.userId);
        } else {
          toast.success("Not Properly send Details...");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  removeUser = () => {
    let removeUser = {
      userId: this.props.userId,
      groupsOrEvents: this.props.subId,
    };
    MyYuukke.removeUser(removeUser)
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("Removed successfully...");
          this.handleClose();
          this.props.removedSentData(this.props.userId);
        } else {
          toast.success("Not Properly send Details...");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  showFriendsProfile(userId) {
    // this.setState({
    //   showFriendsProfile: true,
    // });
    // this.simpleDialog.show();
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  }
  closeFriendsProfile() {
    this.setState({
      showFriendsProfile: false,
    });
  }
  render() {
    const { t } = this.props;
    const {
      id,
      userId,
      name,
      mutualFriends,
      gender,
      coverPhoto,
      userPhoto,
      email,
      profilePhoto,
      Dname,
      isOwner,
    } = this.props;
    return (
      <div className="peopleCardWrapperGroup position-relative">
        <img
          className="bannerImg"
          src={coverPhoto ? `${API_URL}auth/image/${coverPhoto}` : banner}
          alt={name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = banner;
          }}
        ></img>

        {(this.props.userStatus === "owner" ||
          this.props.userStatus === "admin") && (
          <span className="admin-span">
            <MdOutlineAdminPanelSettings />{" "}
            {this.props.userStatus === "owner" ? "Owner" : "Admin"}
          </span>
        )}

        {this.getUserStatus() && (
          <BiUserCircle
            className="remove-user-button"
            onClick={() => this.exit()}
          />
        )}
        {/* {this.props.loginDetails.uId === this.props.adminId &&
          this.props.type !== "me" && (
            <div className="w2-row">
              <IconButton
                aria-label="close"
                style={{ width: "20px", height: "20px" }}
                onClick={() => this.exit()}
              >
                <CloseIcon />
              </IconButton>
            </div>
          )} */}
        <div className="w3-row">
          <div className="w3-col w3-container w3-green">
            <div>
              <img
                className="userImg"
                style={{ cursor: "pointer" }}
                onClick={() => this.showFriendsProfile(userId)}
                src={
                  profilePhoto
                    ? `${API_URL}auth/image/${profilePhoto}`
                    : dummyimage
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyimage;
                }}
                alt={name}
              />
            </div>
          </div>
        </div>
        <div className="peopleCardInfor">
          <div
            style={{ cursor: "pointer" }}
            className="d-flex align-items-center justify-content-center"
            onClick={() => this.showFriendsProfile(userId)}
          >
            <h3 className="card-dName ml-1">{name}</h3>
            {Dname}
            {this.props.premiumUser && premiumUser(this.props.premiumUser) && (
              <img
                src={premiumUser(this.props?.premiumUser)}
                alt=""
                className="ml-1"
                style={{ width: "20px" }}
              />
            )}
          </div>{" "}
        </div>

        {this.props.type === "friends" && (
          <div className="peopleCardAddRemove flex">
            <div
              className="peopleCardAdd"
              onClick={() => {
                this.chatNow(userId);
              }}
            >
              <p>{t("MESSAGE")}</p>
            </div>
          </div>
        )}
        {this.props.type === "requestPending" && (
          <div className="peopleCardAddRemove flex">
            <div
              className="peopleCardAdd"
              // onClick={() => {
              //   this.chatNow(userId);
              // }}
            >
              <p>{t("REQUEST PENDING")}</p>
            </div>
          </div>
        )}
        {this.props.type === "me" && (
          <div className="peopleCardAddReq flex">
            <div className="peopleCardAdd">
              <p>{t("You")}</p>
            </div>
          </div>
        )}

        {this.props.type === "notFriends" && (
          <div className="peopleCardAddRemove flex">
            <div
              className="peopleCardAdd"
              onClick={() => this.friendRequest(userId)}
            >
              <p>{t("ADD FRIEND")}</p>
            </div>
          </div>
        )}
        {this.props.type === "requestedFriends" && (
          <div className="peopleCardAddReq flex">
            <div
              className="peopleCardAdd"
              // onClick={() => this.friendRequest(userId)}
            >
              <p>{t("REQUESTED")}</p>
            </div>
          </div>
        )}
        <div>{this.state.exitPopup && this.popUpCommentDelete()}</div>
      </div>
    );
  }

  getUserStatus = () => {
    if (
      this.props.loginUserStatus === "user" ||
      this.props.userId === this.props.loginDetails.uId
    ) {
      return false;
    } else if (
      this.props.loginUserStatus === "admin" &&
      this.props.userStatus === "owner"
    ) {
      return false;
    } else {
      return true;
    }
  };
  friendRequest(id) {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      let friendRequest = {
        friendsId: id,
        requestStatus: "requested",
      };
      FriendsFinder.friendRequest(friendRequest)
        .then((response) => {
          //   this.props.requestSent()
          var result = response.data;
          toast.success("Friend request sent successfully");
          this.props.getAllMembersDetails(this.props.subId);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  load(data, subjects) {
    this.getBase64String(data);
    //  this.getSchoolName(subjects);
    // this.getUserSchoolAndClass(data);
    this.getCoverPhoto(data);
  }

  callNow(email) {
    MSDeepLink.startCall(email);
  }

  chatNow(user_id) {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      var userData = {
        userId: user_id,
        userName: this.props.Dname,
        userType: 1,
        profilePhoto: "",
      };
      this.props.setSelectedUserAction(userData);
    }
    // this.props.history.push(`/messages/${user_id}`);
  }

  getUserSchoolAndClass(data) {
    let schoolandclass = {
      userId: data,
    };

    FriendsFinder.getUserSchoolAndClass(schoolandclass)
      .then((response) => {
        var result = response.data;

        if (!result.data.hasOwnProperty("userSchoolAndClass")) {
          this.setState({ schoolName: "", className: "" });
        } else if (result.data.hasOwnProperty("userSchoolAndClass")) {
          this.setState({
            schoolName: result.data.userSchoolAndClass.schoolName,
            className: result.data.userSchoolAndClass.className,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getSchoolName(subjects) {
    var subjectName = new Array();
    for (let i = 0; i < this.props.subjectsList.data.length; i++) {
      for (let j = 0; j < subjects.length; j++) {
        if (
          this.props.subjectsList.data[i].subjectId == subjects[j].subjectId
        ) {
          subjectName.push(this.props.subjectsList.data[i].subjectName);
        }
      }
    }
    var subjects = subjectName.join(",");
    await this.setState({ subjectslist: subjects });
  }

  getClassName(classId) {}

  getCoverPhoto(uid) {
    let coverPhoto = {
      userId: uid,
    };
    var response = ForumService.getUserCoverPhotoCache(coverPhoto);
    console.log(response);
    if (response) {
      var value = response.data.data;
      if (value && value.coverPhoto) {
        this.setState({ coverPhoto: response.data.data.coverPhoto });
        // console.log("cache "+base64String)
      }
    } else {
      ProfileService.getUsercoverPhoto(coverPhoto)

        .then((response) => {
          var result = response.data;
          ForumService.setUserCoverPhotoCache(coverPhoto, response);
          this.setState({
            coverPhoto: result.data.coverPhoto,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getBase64String(userId) {
    let getBase64String = { userId: userId };
    var response = ForumService.getUserPhotoCache(getBase64String);
    if (response) {
      console.log("FRIEND IMAGE IN FRIENDS ON PS");
      console.log(response);
      var value = response.data.data;
      console.log(response.data);
      console.log(response.data.data);
      console.log(value);
      var value = response.data.data;
      if (value && value.userPhoto) {
        var base64String = "";
        base64String = `data:image;base64,${response.data.data.userPhoto}`;
        this.setState({
          userPhoto: response.data.data.userPhoto,
          base64StringState: true,
        });
      }
    } else {
      ForumService.getUserPhoto(getBase64String)
        .then((response) => {
          ForumService.setUserPhotoCache(getBase64String, response);
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.userPhoto
          ) {
            var base64String = "";
            base64String = `data:image;base64,${response.data.data.userPhoto}`;
            this.setState({
              userPhoto: response.data.data.userPhoto,
              base64StringState: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  friendIgnore(id) {
    let friendIgnore = {
      recommendfriendsId: id,
      status: "ignored",
    };
    FriendsFinder.recommendedfriendIgnore(friendIgnore)
      .then((response) => {
        this.props.ignoreSent();
        var result = response.data;
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  return {
    subjectsList: state.commonApi.subjectslist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    needPhotVerification: (type) => dispatch(needPhotVerification(type)),
    setSelectedUserAction: (data) => dispatch(setSelectedUserAction(data)),
    getAllMembersDetails: (id) => dispatch(getAllMembersDetails(id)),
    updateGroupMember: (id, status) => dispatch(updateGroupMember(id, status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(GroupMembersAndFriendsList)));
