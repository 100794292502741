import React, { Component } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextEditorQuestion from "../../components/TextEditorQuestion";
import ProfileService from "../../api/ProfileService";
import ForumService from "../../api/ForumService";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import Form from "react-bootstrap/Form";
import { withTranslation } from "react-i18next";
import "react-widgets/styles.css";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { addMinutes } from "date-fns";
import { Dialog, DialogTitle, createMuiTheme } from "@material-ui/core";
import EventModerator from "../MyGroupsandMyEvents/GroupDescription/EventModerator";
import EmailTemplate from "../Forms/EmailTemplate";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { getCommunityMemberId } from "../../redux/actions/subjectActions";
import { urlPatternValidation } from "../../utils";
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#A00030",
      },
      toolbarBtnSelected: {
        backgroundColor: "#A00030",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        backgroundColor: "#A00030",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        // color:"#A00030"
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: "#A00030",
      },
    },
    MuiPickersClockPointer: {
      thumb: {
        backgroundColor: "#A00030",
        border: "14px solid #A00030",
      },
      pointer: {
        backgroundColor: "#A00030",
      },
      pin: {
        backgroundColor: "#A00030",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#A00030",
      },
      daySelected: {
        backgroundColor: "#A00030",
        color: "#A00030",
      },
      dayDisabled: {
        color: "lightgray",
      },
      current: {
        color: "#A00030",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#A00030",
      },
    },
    MuiButton: {
      label: {
        color: "white",
      },
    },
  },
});
const currentDate = new Date();
class ChallengeApplyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      content: "",
      startDate: new Date(),
      expiryDate: new Date().setDate(new Date().getDate() + 1),
      startTime: "",
      endTime: "",
      meetingLink: "",
      newMeeting: true,
      meetingDetails: {},
      descriptionCount: 0,
      oldUrl: "",
      calendarId: 0,
      moderatorList: [],
      groupMemberList: [],
      editEvent: false,
      communityId: "",
      emailConfigure: false,
      emailTemplate: null,
      templatePopUp: false,
      publicEvent: false,
      updateTemplate: null,
      moderatorNeed: true,
      updateMeetingStatus: false,
    };
  }

  componentDidMount() {
    //proofList
    if (this.props.match.params.subjectId === "update-event") {
      this.getEventDetails();
    }
    if (
      this.props.match.params.subjectId !== "update-event" &&
      this.props.match.params.subjectId !== "Event"
    ) {
      const removeLoginUser = this.props.allMembersList.filter(
        (data) => data.uId != this.props.loginDetails.uId
      );
      this.setState({
        groupMemberList: removeLoginUser ? removeLoginUser : [],
      });
      console.log("GroupMembers", removeLoginUser);
    }
  }

  ViewAllComments(boolean) {
    this.setState({ checkExpiry: boolean });
  }

  handleChangeExpiryInput = (value) => {
    const { startDate } = this.state;

    // Check if the expiry time is greater than the start time
    if (startDate && value <= startDate) {
      toast.warn("End time should be greater than start time.");
    }
    // Check if expiry time is at least 15 minutes after the start time
    else if (startDate && value <= addMinutes(startDate, 9)) {
      toast.warn(
        "Expiry date and time must be at least 10 minutes after the start date."
      );
    }
    // If all validations pass, update the expiryDate
    else {
      this.setState({
        expiryDate: value,
      });
    }
  };

  // handleChangeStartInput = (value) => {
  // this.setState({
  // startDate: value,
  // });
  // };
  handleClose = (e) => {
    this.setState({
      emailConfigure: !this.state.emailConfigure,
      templatePopUp: !this.state.emailConfigure ? true : false,
    });
  };
  updateDetails = (data) => {
    console.log("data", data);
    this.setState({
      emailTemplate: data,
      templatePopUp: false,
    });
  };
  render() {
    const { t } = this.props;
    const { handleViewAllQuestions, events, subjectId, subjectName } =
      this.props;
    return (
      <div style={{ marginBottom: "22px" }}>
        {!this.props.isHomePost && (
          <div className="d-flex align-items-center mb-4 justify-content-between">
            <h5>ADD EVENT</h5>

            <p
              onClick={() => this.backToGroup()}
              style={{ float: "right", cursor: "pointer", color: "#A00030" }}
            >
              <ArrowBackIcon />
              {t("Back to Community")}
            </p>
          </div>
        )}

        <div className="askAQuestionCardWrapper">
          <BlockUi
            tag="div"
            blocking={this.state.blocking}
            message={t("Loading, please wait")}
            color="#60c2e7"
          >
            {this.props.isHomePost && (
              <div className="askAQuestionInputGroup inputLarge mt-2 ">
                <Form.Group controlId="region">
                  <Form.Label className="form-label">
                    {t("Choose Community")} *
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="communityId"
                    value={this.state.communityId}
                    onChange={(e) => this.handleChangeForm(e.target.value)}
                    // onChange={(e) => this.handleForm(e)}
                  >
                    <option value="">{t("Choose")}</option>
                    {this.props.communityList.map((option, index) => (
                      <option value={option.subjectId} key={index}>
                        {t(`${option.subjectName}`)}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
            )}
            <div className="askAQuestionInputGroup inputLarge">
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <label className="formLabel">{t("Event Title  *")}</label>

                <input
                  type="text"
                  name="title"
                  value={this.state.title}
                  maxLength="250"
                  onChange={this.handleForm}
                />
              </Grid>
            </div>

            <div
              onClick={this.handleClose}
              className="d-flex flex-wrap align-items-center mb-3"
              style={{ cursor: "pointer" }}
            >
              <div className="boxcolor mr-1 event-box">
                <input
                  type="checkbox"
                  value={this.state.emailConfigure}
                  checked={this.state.emailConfigure}
                  defaultChecked={this.state.emailConfigure}
                  id="emailConfigure"
                />
              </div>
              <p style={{ fontSize: "12px" }}>
                Kindly check the box when you need to email reminder.
              </p>
            </div>

            <div
              className="d-flex flex-wrap align-items-center mb-3"
              style={{ cursor: "pointer" }}
            >
              <div
                className="boxcolor mr-1 event-box"
                onClick={() => this.handleModerateMeeting()}
              >
                <input
                  type="checkbox"
                  value={this.state.moderatorNeed}
                  checked={this.state.moderatorNeed}
                  defaultChecked={this.state.moderatorNeed}
                  id="moderatorNeed"
                />
              </div>
              <p style={{ fontSize: "12px" }}>Moderator Need</p>
            </div>

            <div className="askAQuestionDescription">
              <label className="formLabel">{t("Event Description  *")}</label>
              <TextEditorQuestion
                ref={this.myRef}
                editorStateValue={this.editorStateValues}
                editorValue={
                  this.state.questionDescribed
                    ? this.state.questionDescribed
                    : ""
                }
              />
              <p
                className="boxText"
                style={
                  this.state.descriptionCount > 2500
                    ? { color: "red" }
                    : { color: "green" }
                }
              >
                {" "}
                {2500 - this.state.descriptionCount} {t("characters left")}
              </p>
            </div>
            <Grid container spacing={1} style={{ marginTop: "20px" }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="askAQuestionInputGroup inputLarge">
                  <label className="formLabel">
                    {t("Start Date and Time*")}
                  </label>

                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        value={this.state.startDate}
                        disablePast
                        inputVariant="filled"
                        fullWidth={true}
                        onChange={this.handleChangeStartInput}
                        label="Meeting Date"
                        showTodayButton
                        style={{ marginTop: 3, color: "white" }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <label className="formLabel">{t("Expiry Date And Time")}</label>

                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      value={this.state.expiryDate}
                      disablePast
                      inputVariant="filled"
                      fullWidth={true}
                      onChange={this.handleChangeExpiryInput}
                      label="Meeting Date"
                      showTodayButton
                      style={{ marginTop: 3, color: "white" }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Grid>
            </Grid>
            {!this.state.updateMeetingStatus && (
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <div style={{ display: "inline-flex" }}>
                    <div className="toggle-switch-event">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="newMeeting"
                        id={"newMeeting"}
                        checked={this.state.newMeeting}
                        onClick={() =>
                          this.newMeeting(this.state.newMeeting ? false : true)
                        }
                      />
                      <label className="label" htmlFor={"newMeeting"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    {this.state.newMeeting && (
                      <p style={{ paddingTop: "18px", fontWeight: "500" }}>
                        {t("Meeting IN Yuukke")}
                      </p>
                    )}
                  </div>
                </Grid>
              </Grid>
            )}
            {!this.props.isHomePost &&
              this.props.match.params.subjectId !== "update-event" &&
              this.state.newMeeting && (
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <EventModerator
                      memberList={this.state.groupMemberList}
                      moderatorList={this.setModeratorList}
                      type={
                        this.props.match.params.subjectId == "Event"
                          ? "global"
                          : "group"
                      }
                    />
                  </Grid>
                </Grid>
              )}
            {!this.state.newMeeting && (
              <div className="askAQuestionInputGroup inputLarge mt-4">
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <label className="formLabel">{t("Add Meeting Link *")}</label>

                  <input
                    type="text"
                    name="meetingLink"
                    placeholder="Add  Your Meeting Link Here...."
                    value={this.state.meetingLink}
                    onChange={this.handleForm}
                  />
                </Grid>
              </div>
            )}
            <div className="d-flex flex-wrap align-items-center">
              <div className="boxcolor mr-1 event-box">
                <input
                  type="checkbox"
                  value={this.state.publicEvent}
                  defaultChecked={this.state.publicEvent}
                  id="morningTimeSelect"
                  onClick={this.handleFormEvent}
                />
              </div>
              <p style={{ fontSize: "12px" }}>
                Kindly check the box public events if you want to create a event
                page and what to share the event to entire platform / Internet.
              </p>
            </div>

            <Dialog
              fullWidth
              maxWidth="lg"
              open={this.state.templatePopUp}
              onClose={this.handleClose}
              disableBackdropClick
            >
              <DialogTitle>
                {t("Event Mail Configuration")}{" "}
                <AiOutlineCloseCircle
                  onClick={this.handleClose}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginBottom: "8px",
                    width: "20px",
                    height: "20px",
                    color: "#000000",
                  }}
                />
              </DialogTitle>
              <EmailTemplate
                updateDetails={this.updateDetails}
                updateTemplate={this.state.updateTemplate}
              />
            </Dialog>
            <div className="askAQuestionBtn" style={{ paddingTop: "20px" }}>
              <button onClick={(e) => this.onFormSubmitEvent(e)}>
                {this.props.match.params.subjectId == "update-event" ? (
                  <>{t("Update Event")}</>
                ) : (
                  <>{t("Add Event")}</>
                )}
              </button>
            </div>
          </BlockUi>
        </div>
      </div>
    );
  }
  handleChangeForm = (id) => {
    this.setState({
      communityId: id,
    });
    this.props.getCommunityMemberId(id);
  };
  handleModerateMeeting = () => {
    this.setState({
      moderatorNeed: !this.state.moderatorNeed,
    });
  };
  handleFormEvent = () => {
    this.setState({
      publicEvent: !this.state.publicEvent,
    });
  };
  setModeratorList = (data) => {
    const id = data && data.map((item) => item.value);
    this.setState({
      moderatorList: id ? id : [],
    });
    console.log(id);
  };
  // handleChangeStartInput = (e) => {
  // this.setState({
  // startDate: e,
  // });
  // };
  handleChangeStartInput = (e) => {
    const currentDate = new Date();

    // Validate if the selected start date is after the current time
    if (e <= currentDate) {
      toast.warn("Start date and time must be in the future.");
    } else {
      this.setState({
        startDate: e,
        expiryDate: addMinutes(e, 9), // Automatically set expiry date to 45 minutes after start date
        error: "",
      });
    }
  };

  newMeeting = (boolean) => {
    this.setState({ newMeeting: boolean });
  };
  socialPopover() {
    // function removeHTML(str) {
    //   var tmp = document.createElement("DIV");
    //   tmp.innerHTML = str;
    //   return tmp.textContent || tmp.innerText || "";
    // }
    // const content =
    //   this.state.postValue.content != null
    //     ? this.state.postValue.content
    //     : this.state.postValue.fileContent;
  }

  keyFile(key) {
    this.setState({ fileTypeKey: key, fileTypeState: true });
    this.setState({ fileImage: false });
  }
  closeChoosedFileImage() {
    this.setState({
      imageOptionOpen: false,
    });
  }
  closeChoosedFileVideo() {
    this.setState({
      videoOptionOpen: false,
    });
  }
  closeChoosedFileData() {
    this.setState({
      fileTypeState: false,
      file: [],
      grouporeventFiles: this.state.grouporeventFilesList,
    });
    //  this.setState({editImage: [], fileTypeState: false, file: [] ,grouporeventFiles: this.state.grouporeventFilesList })

    this.setState({ fileImage: true });
    console.log("update 3", this.state.editImage);
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  // async getQuestionMediaVideoFiles(files, type) {
  //     console.log("update 1", files)
  //     await this.setState({ selectPageVideo:false , fileImages: files, type: type, selectOption: false, grouporeventFiles: [], editImage: files });

  // }

  editorStateValues = (values) => {
    console.log("editorStateValues");
    console.log(values);
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        content: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        content: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  };

  handleScheduledMeeting = (data) => {
    let memberList = this.props.isHomePost
      ? this.props.communityMemberId
      : this.props.allMembersList;
    let memberId = [];
    memberList.forEach((element) => {
      memberId.push(element.uId);
    });
    const formData = {
      senderName: this.props.loginDetails.dName,
      senderId: this.props.loginDetails.uId,
      receiverId: undefined,
      receiverName: undefined,
      receiverList:
        this.props.match.params.subjectId == "Event" ? [] : memberId,
      message: {
        meeting: data,
      },
      isMeeting: true,
    };

    console.log("forum", formData);
    if (this.props.match.params.subjectId === "update-event") {
      if (!this.state.newMeeting) {
        this.updateEvent();
      } else {
        let link = this.state.oldUrl.replace(/\?.*/g, "");

        let updateData = {
          meetingUrl: link,
          meetingTime: data.meetingTime,
          meetingDescription: data.meetingDescription,
          meetingName: data.meetingName,
        };
        ProfileService.updateMeeting(updateData)
          .then((response) => {
            this.updateEvent(updateData);
          })
          .catch((error) => {
            console.log(error);
            toast.error("Saving Error");
          });
      } //
    } else if (this.props.match.params.subjectId === "Event") {
      ProfileService.globuleMeetingAction(formData)
        .then((response) => {
          var result = response.data.data;
          console.log("meetind details>>", result);

          var meetingDetails = {
            meetingName: result.message.message.meeting.meetingLink,
            moderatorId: parseInt(result.message.senderId),
            moderatorName: result.message.senderName,
          };

          var link = result.message.message.meeting.meetingLink;

          this.setState({
            meetingDetails: meetingDetails,
            meetingLink: link,
            // link +
            // "?authenticated=true&userId=" +
            // this.props.loginDetails.uId +
            // "&username=" +
            // this.props.loginDetails.dName,
          });
          console.log("meetingDetails", meetingDetails);
          console.log("meetingDetails", this.state.meetingLink);
          this.saveEvent();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Saving Error");
        });
    } else {
      ProfileService.instantMeetingAction(formData)
        .then((response) => {
          var result = response.data.data;
          console.log("meetind details>>", result);

          var meetingDetails = {
            meetingName: result[0].message.meeting.meetingLink,
            moderatorId: parseInt(result[0].senderId),
            moderatorName: result[0].senderName,
          };
          if (result.length !== 0) {
            var link = "";
            for (let i = 0; i < 1; i++) {
              link = result[0].message.meeting.meetingLink;
            }
            this.setState({
              meetingDetails: meetingDetails,
              meetingLink: link,

              // "?authenticated=true&userId=" +
              // this.props.loginDetails.uId +
              // "&username=" +
              // this.props.loginDetails.dName,
            });
            this.saveEvent();
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Saving Error");
        });
    }
  };
  state = {
    URL: "",
    isTrueVal: false,
  };

  getEventDetails = () => {
    let id = {
      calendarId: this.props.match.params.subName,
    };
    ProfileService.getSingleCalendar(id)
      .then((response) => {
        let result = response.data.data.calendar;
        this.setState({
          title: result.taskName,
          questionDescribed: result.taskDescription,
          content: result.taskDescription,
          startDate: new Date(
            moment.utc(result.startDate).local().format("YYYY-MM-DD HH:mm:ss")
          ),
          expiryDate: new Date(
            moment.utc(result.endDate).local().format("YYYY-MM-DD HH:mm:ss")
          ),
          oldUrl: result.url,
          calendarId: this.props.match.params.subName,
          moderatorList: result.moderatorList,
          newMeeting: result.meetingInApplication,
          meetingLink: result.url,
          editEvent: true,
          publicEvent: result.publicEvent,
          emailConfigure: result.emailConfigure,
          updateTemplate: result.emailTemplate,
          moderatorNeed: result?.meetingType === 1 ? false : true,
          updateMeetingStatus: !result.meetingInApplication ? true : false,
          // expiryDate: result.url,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onFormSubmitEvent = () => {
    const isTrueVal = urlPatternValidation(this.state.meetingLink);

    if (this.props.isHomePost && this.props.communityList?.length === 0) {
      toast.warn(
        "Your community list is empty , Please join any community or create your own community."
      );
    } else if (this.props.isHomePost && !this.state.communityId) {
      toast.warn("Please Choose community");
    } else if (this.state.title.trim() == "") {
      toast.warn("Please enter title");
    } else if (!moment(this.state.startDate).isBefore(this.state.expiryDate)) {
      console.log("startDate", this.state.startDate);
      console.log("endDate", this.state.expiryDate);

      toast.warn("Expiry date should not before than start date ");
    } else if (this.state.content == "") {
      toast.warn("Please enter description");
    } else if (this.state.startDate == "") {
      toast.warn("Please add startDate");
    } else if (this.state.expiryDate == "") {
      toast.warn("Please add startDate");
    } else if (!this.state.newMeeting && !isTrueVal) {
      toast.warn("Please enter valid url");
    } else if (!this.state.newMeeting && isTrueVal && !this.state.editEvent) {
      this.saveEvent();
    } else {
      const instantMeetingLink = `Yuukke-${Math.random() * 100}-${
        this.props.loginDetails.uId
      }-${Date.now()}`;
      let content = this.state.content.replace(/(<([^>]+)>)/gi, "");
      const meetingData = {
        meetingName: this.state.title,
        meetingDescription: content,
        meetingLink: instantMeetingLink,
        meetingType: "Scheduled",
        meetingTime: this.state.startDate.toISOString(),
      };
      this.handleScheduledMeeting(meetingData);
    }
  };

  updateEvent = () => {
    let startTime = moment(this.state.startDate).format("h:mm A");
    var endTime = moment(new Date(this.state.expiryDate)).format("h:mm A");
    const startDate = this.state.startDate;
    const endDate = this.state.expiryDate;

    // var data = {
    //   calendarId: this.state.calendarId,
    //   taskName: this.state.title,
    //   taskDescription: this.state.content,
    //   startDate: startDate,
    //   endDate: endDate,
    //   startTime: startTime,
    //   endTime: endTime,
    //   color: "#A00030",
    //   publicEvent: this.state.publicEvent,
    //   meetingInApplication: this.state.newMeeting,
    // };
    const formData = new FormData();
    formData.append("color", "#A00030");
    formData.append("calendarId", this.state.calendarId);
    formData.append("taskName", this.state.title);
    formData.append("taskDescription", this.state.content);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("startTime", startTime);
    formData.append("endTime", endTime);
    formData.append("publicEvent", this.state.publicEvent);
    formData.append("meetingInApplication", this.state.newMeeting);
    formData.append("emailConfigure", this.state.emailConfigure);
    formData.append("meetingType", this.state.moderatorNeed ? 0 : 1);
    if (!this.state.newMeeting && this.state.meetingLink) {
      formData.append("url", this.state.meetingLink);
    }

    if (
      this.state.emailConfigure &&
      this.state.emailTemplate &&
      // this.state.updateTemplate &&
      this.state.emailTemplate?.blob
    ) {
      if (this.state.emailTemplate.oneHouReminderSub) {
        formData.append(
          "oneHourReminderSub",
          this.state.emailTemplate.oneHouReminderSub
        );
      }
      if (this.state.emailTemplate.oneDayReminderSub) {
        formData.append(
          "oneDayReminderSub",
          this.state.emailTemplate.oneDayReminderSub
        );
      }

      formData.append("subject", this.state.emailTemplate.subject);
      formData.append(
        "emailTemplate",
        this.state.emailTemplate.blob,
        "template.html"
      );
      if (this.state.updateTemplate) {
        formData.append("templateId", this.state.updateTemplate.id);
      }
    }
    this.block(true);
    const config = {
      header: {
        "content-type": "application/json",
      },
    };
    ForumService.updateCalendar(formData, config)
      .then((response) => {
        var result = response.data.data;
        toast.success("✓ Event updated Successfully..");
        // this.backToGroup();

        this.props.history.push(`/${this.props.workspaceName}/calender`);
        this.block(false);
      })
      .catch((error) => {
        this.block(false);
        this.backToGroup();
        console.log(error);
      });
  };

  saveEvent = () => {
    this.state.moderatorList.push(this.props.loginDetails.uId);
    let startTime = moment(this.state.startDate).format("h:mm A");
    var endTime = moment(new Date(this.state.expiryDate)).format("h:mm A");
    const startDate = this.state.startDate;
    // const endDate = this.state.expiryDate;
    const endDate = new Date(this.state.expiryDate);
    const formData = new FormData();
    formData.append("meetingInApplication", this.state.newMeeting);
    formData.append("taskName", this.state.title);
    formData.append("taskDescription", this.state.content);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("startTime", startTime);
    formData.append("endTime", endTime);
    formData.append("color", "#A00030");
    formData.append(
      "taskType",
      this.props.isHomePost
        ? "group"
        : Number.isInteger(parseInt(this.props.match.params.subjectId))
        ? "group"
        : "public"
    );
    // formData.append(
    //   "eventType",
    //   this.props.isHomePost
    //     ? null
    //     : Number.isInteger(parseInt(this.props.match.params.subjectId))
    //     ? null
    //     : 1
    // );
    formData.append(
      "subjectId",
      this.props.isHomePost
        ? this.state.communityId
        : Number.isInteger(parseInt(this.props.match.params.subjectId))
        ? this.props.match.params.subjectId
        : 0
    );
    formData.append("url", this.state.meetingLink);
    formData.append(
      "meetingDetails",
      JSON.stringify(this.state.meetingDetails)
    );
    formData.append("moderatorList", this.state.moderatorList);
    formData.append("publicEvent", this.state.publicEvent);
    formData.append("emailConfigure", this.state.emailConfigure);
    // Moderator not need = 1 and 0 or null moderator need
    formData.append("meetingType", this.state.moderatorNeed ? 0 : 1);
    console.log("file", this.state.emailTemplate);
    if (
      this.state.emailConfigure &&
      this.state.emailTemplate &&
      this.state.emailTemplate?.blob
    ) {
      if (this.state.emailTemplate.oneHouReminderSub) {
        formData.append(
          "oneHourReminderSub",
          this.state.emailTemplate.oneHouReminderSub
        );
      }
      if (this.state.emailTemplate.oneDayReminderSub) {
        formData.append(
          "oneDayReminderSub",
          this.state.emailTemplate.oneDayReminderSub
        );
      }

      formData.append("subject", this.state.emailTemplate.subject);
      formData.append(
        "emailTemplate",
        this.state.emailTemplate.blob,
        "template.html"
      );
    }

    // let data = {
    //   meetingInApplication: this.state.newMeeting,
    //   taskName: this.state.title,
    //   taskDescription: this.state.content,
    //   startDate: startDate,
    //   endDate: endDate,
    //   startTime: startTime,
    //   endTime: endTime,
    //   color: "#A00030",
    //   taskType: this.props.isHomePost
    //     ? "group"
    //     : Number.isInteger(parseInt(this.props.match.params.subjectId))
    //     ? "group"
    //     : "public",
    //   eventType: this.props.isHomePost
    //     ? null
    //     : Number.isInteger(parseInt(this.props.match.params.subjectId))
    //     ? null
    //     : 1,
    //   subjectId: this.props.isHomePost
    //     ? this.state.communityId
    //     : Number.isInteger(parseInt(this.props.match.params.subjectId))
    //     ? this.props.match.params.subjectId
    //     : 0,
    //   url: this.state.meetingLink,
    //   meetingDetails: this.state.meetingDetails,
    //   moderatorList: this.state.moderatorList,
    //   publicEvent: this.state.publicEvent,
    // };
    // console.log("================================", data);
    // this.block(true);
    const config = {
      header: {
        "content-type": "application/json",
      },
    };
    ForumService.saveCalendar(formData, config)
      .then((response) => {
        var result = response.data.data;
        if (result !== "expired") {
          toast.success("✓ Event Added Successfully..");
          // this.backToGroup();

          this.props.history.push(`/${this.props.workspaceName}/calender`);
          this.block(false);
        } else {
          this.notify();
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        this.block(false);
        // this.backToGroup();
        // console.log(error);
      });
  };

  notify = () => {
    toast.info(
      <div>
        <p>Your limit has been reached.</p>
        <p>Please recharge your account to continue using the service.</p>
        <a href="http://localhost:3000/premium-subscription">Recharge Now</a>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "center-toast",
      }
    );
  };

  backToGroup = () => {
    if (
      this.props.match.params.subjectId == "Event" &&
      this.props.match.params.subName == "New"
    ) {
      this.props.history.push(`/${this.props.workspaceName}/groupsList`);
    } else if (this.props.match.params.subjectId == "update-event") {
      this.props.history.push(`/${this.props.workspaceName}/calender`);
    } else {
      var gName = this.props.match.params.subName;
      var id = this.props.match.params.subjectId;
      gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
      this.props.history.push(
        `/${this.props.workspaceName}/groupDescription/${id}/${gName}`
      );
    }
  };
  async removeImageFile() {
    var inpu = this.state.fileImage;
    if (this.state.grouporeventFiles) {
      this.setState({
        grouporeventFiles: [],
        editImage: [],
      });
    } else {
    }
  }
  movetolearningapp(id, name) {
    this.props.history.push(`/challengeDescription/${id}/${name}`);
  }
  movetolearningappList() {
    this.props.history.push(`/challenges`);
  }

  expirycheck(e) {
    this.setState({ expiry: e.target.value, expiryDate: "" });
  }
  participation(e) {
    this.setState({ participation: e.target.value });
  }
  teamLeader(e) {
    this.setState({ teamLeader: e.target.value });
  }

  block(blocking) {
    this.setState({ blocking: blocking });
  }

  handleForm = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  backtoHome() {
    this.props.backToGroupDes();
    //  this.props.history.push(`/groupDescription/${this.state.challengeId}/${this.state.challengeName}`);
  }
  backtogroups(challengeId) {
    this.props.history.push(`/challenge-description/${challengeId}`);
  }
  backtoevents() {
    this.props.history.push("/upcomingevents");
  }
  gotogroupDescription(id) {
    this.props.history.push(
      `/${this.props.workspaceName}/myGroupDescription/${id}`
    );
  }
  async getOldTagList(oldTags) {
    var subjectName = [];
    console.log("oldTags");
    console.log(oldTags);
    console.log("this.props.tagList");
    console.log(this.props.tagList);
    if (this.props.tagList.data.length > 0) {
      for (let i = 0; i < this.props.tagList.data.length; i++) {
        for (let j = 0; j < oldTags.length; j++) {
          if (this.props.tagList.data[i].tagId === oldTags[j].tagId) {
            subjectName.push(this.props.tagList.data[i].tagName);
          }
        }
      }
      console.log(subjectName);
      await this.setState({ oldTagNames: subjectName });
      this.getTagId(subjectName);
    }
  }
  async getAllCategoryId(id) {
    let category = { subjectId: id };
    ProfileService.getAllCategoryId(category)
      .then((response) => {
        console.log("getAllCategoryId >>", response.data.data);
        this.setState({ category: response.data.data });
        console.log("after category >>", this.state.category);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    allMembersList: state.subjects.allMembersList,
    workspaceName: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    communityMemberId: state.subjects.communityMemberId,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getCommunityMemberId: (id) => dispatch(getCommunityMemberId(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ChallengeApplyForm)));
