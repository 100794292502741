const auth_state = {
  authenticate: false,
  userInfo: {},
  error: false,
  friends: [],
};

export default function (state = auth_state, { type, payload }) {
  switch (type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        authenticate: true,
        userInfo: payload,
        error: false,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        authenticate: false,
        userInfo: {},
        error: true,
      };
    case "OTHER_ACTION":
      return {
        ...state,
        authenticate: false,
        userInfo: {},
        error: true,
      };
    case "USER_DETAILS":
      return {
        ...state,
        userInfo: payload,
      };
    case "FRIENDS_DETAILS":
      return {
        ...state,
        friends: payload,
      };
    default:
      return state;
  }
}
