import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
let env_check = window.location.host === "connect.yuukke.com";

const persistConfig = {
  key: "root",
  storage: storageSession,
};

const initalState = {};
const middleware = [thunk];
const persistedReducer = persistReducer(persistConfig, rootReducer);

if(env_check){
  console.log = function() {}
}
 

export default () => {

  let store = createStore(
    persistedReducer,
    initalState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  let persistor = persistStore(store);
  return { store, persistor };
};
