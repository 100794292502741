import React, { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ScheduleMyCalendar from "./ScheduleMyCalendar";
import moment from "moment";

import AuthService from "../../session/AuthService";
import { toast } from "react-toastify";
import "./mycalendar.css";

var appointmentList = []
class BookMyCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleUserAvailability: this.props.value,
      app_eventList: [],
      appointments: [],
      createScheduleState: false,
      bookappointmentData: "",
      joinCallState: false,
      Subject_list:[],
      app_appointmentList:[],
    };

    this.callBack = this.callBack.bind(this);
    this.bookAppointment = this.bookAppointment.bind(this);
    this.createScheduleCond = this.createScheduleCond.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.joinMeeting = this.joinMeeting.bind(this);
    this.create_sync_id = this.create_sync_id.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.appoinmentDetails=this.appoinmentDetails.bind(this);
  }

  componentDidMount()
  {
    
    this.appoinmentDetails(this.props.Subject_list);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.Subject_list !== this.props.Subject_list) {
      this.appoinmentDetails(this.props.Subject_list);
    }
  }

  render() {
    console.log(this.props.events);
    console.log("Subject_list",this.props.Subject_list)
    console.log("******************")
    console.log("Calender",this.state.app_appointmentList)
    return (
      <div className="postDetailedCard">
       {this.state.app_appointmentList && <ScheduleMyCalendar
          events={this.state.app_appointmentList}
          callBack={this.callBack}
        />}
        {this.state.createScheduleState && this.bookAppointment()}
      </div>
    );
  }


  callBack(data) {
 
    this.createScheduleCond(data);
  }

  createScheduleCond(data) {

   
    this.setState({
      createScheduleState: true,
      bookappointmentData: data,
    });
    console.log("EVENT DETAILS");
    console.log(data);
    if (data.status == 2) {
      this.enableJoinCall(data);
    }
  }
  enableJoinCall(appointmentData) {
     

    var currentDate = new Date();

    if (
      moment(appointmentData.startDate).format("DD MMM yyyy") ===
      moment(currentDate.startDate).format("DD MMM yyyy")
    ) {
      if (
        moment(appointmentData.startDate).format("HH mm ss") ===
        moment(currentDate.startDate).format("HH mm ss")
      ) {
        this.setState({ joinCallState: true });
      } else if (
        moment(currentDate.startDate).format("HH mm ss") >
          moment(appointmentData.startDate).format("HH mm ss") &&
        moment(currentDate.startDate).format("HH mm ss") <
          moment(appointmentData.endDate).format("HH mm ss")
      ) {
        this.setState({ joinCallState: true });
      } else {
        this.setState({ joinCallState: false });
      }
    } else {
      this.setState({ joinCallState: false });
    }
  }

  handleClose() {
    this.setState({ createScheduleState: false });
  }

  deleteEvent(eventId) {}

  bookAppointment() {
    return (
      <div>
        <Modal show="true">
          <Modal.Header>
            <h2>Event Details : {this.state.bookappointmentData.title}</h2>
          </Modal.Header>
          <Form id="profileForm">
            <Modal.Body>
              <Form.Group className="formInput">
                <Form.Label>
                  {" "}
                  Date :{" "}
                  {moment(this.state.bookappointmentData.startDate).format(
                    "DD MMM yyyy"
                  )}
                </Form.Label>
              </Form.Group>
              <Form.Group className="formInput">
                <Form.Label>
                  Start Time :{" "}
                  {moment(this.state.bookappointmentData.startDate).format(
                    "HH MM A"
                  )}
                </Form.Label>
              </Form.Group>
              <Form.Group className="formInput">
                <Form.Label>
                  End Time :{" "}
                  {moment(this.state.bookappointmentData.endDate).format(
                    "HH MM A"
                  )}
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>

              <Button
                variant="primary"
                onClick={() =>
                  this.deleteEvent(this.state.bookappointmentData.eventId)
                }
              >
                Delete Event
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }



  async appoinmentDetails(Subject_list){
    
    const appointment_List=[]

   if (Subject_list.length>0)
   {
     for (let i = 0; i < Subject_list.length; i++) {
        // var createdDate = moment.utc(Subject_list[i].startDate).toDate().toString()
       // var expiryDate = moment.utc(Subject_list[i].endDate).toDate().toString()
         var createdDate =Subject_list[i].createdDate
         var expiryDate =Subject_list[i].expiryDate
         var startDate =Subject_list[i].startDate
         var endDate =Subject_list[i].endDate
         var memberShipType =Subject_list[i].memberShipType
         var questionsCount =Subject_list[i].questionsCount
         var memberShipPrice=Subject_list.memberShipPrice
         var subjectId =Subject_list[i].subjectId
         var subjectNameLC =Subject_list[i].subjectNameLC
         var tagDescription =Subject_list[i].tagDescription
         var type =Subject_list[i].type
         var membersCount=Subject_list.membersCount
         
         appointment_List.push({
             "membersId": memberShipType,
             "startDate": startDate,
             "endDate": endDate,
             "senderId": subjectId,
             "status": subjectNameLC,
             "title": tagDescription,
             "type": type,
             "meetingLinkId": questionsCount,
             "receivers": memberShipPrice,
             "meetingId": subjectId
          
         })
         //  await this.setState({app_friendlist:obj})
         
     }

     await this.setState({ app_appointmentList: appointment_List })

 }
  
}


  create_sync_id() {
    var dt = new Date().getTime();
    var sid = "xxxx-xxxxx-xxxx-xxyx-xxxx-xxxx-xxx-xxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return sid;
  }
  joinMeeting(scheduleId, receiverId, type, title) {
    this.setState({ joinCallState: false });
    var email = AuthService.getUserdata().user.email;
    console.log(window.location.href);
    var totalEmail = email;

    for (let i = 0; i < receiverId.length; i++) {
      if (receiverId[i] != AuthService.getUserId()) {
        for (let j = 0; j < AuthService.getFriendsList().length; j++) {
          if (
            receiverId[i] ===
            AuthService.getFriendsList()[j].user.userProfile.userId
          ) {
            var email_id = AuthService.getFriendsList()[j].email;
            totalEmail = totalEmail + "," + email_id;
          }
          console.log("total_email");
          console.log(totalEmail);
        }
      }
    }
  }

}

export default BookMyCalendar;
