import React, { Component } from 'react'
import Avatar from './AdvertisementChatAvatar'
import { API_URL } from '../../../config/app-config'
import moment from 'moment'
export default class ChatItem extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div
        style={{ animationDelay: `0.3s` }}
        className={`chat__item ${this.props.user ? this.props.user : ''}`}
      >
        <div className="chat__item__content">
          <div className="chat__msg">{this.props.msg}</div>
          <div className="chat__meta">
            <span>
              {' '}
              {moment(this.props.createdDate)
                .locale('en')
                .startOf('mini')
                .fromNow()}
            </span>
            {/* <span>Seen 1.03PM</span> */}
          </div>
        </div>
        <Avatar
          isOnline="active"
          image={
            API_URL +
            'api/getUserPhoto/' +
            this.props.image +
            '/' +
            this.props.userName
          }
          //  image={this.props.image}
        />
      </div>
    )
  }
}
