import React, { useState, useEffect } from "react";

const Timer = ({ status, resendOtp }) => {
  const [timer, setTimer] = useState(320);
  useEffect(() => {
    if (timer === 0) {
      setTimer(320);
    }
  }, [status]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  //   const minutes = Math.floor(timer / 60);
  //   const seconds = timer % 60;
  return (
    <>
      {timer === 0 && (
        <p className="resend-otp" onClick={(e) => resendOtp(e, true)}>
          Resend Otp
        </p>
      )}
    </>
    // <div className="d-flex mt-3 align-items-center font-weight-bold">
    //   <span>Valid in </span>
    //   <p
    //     style={{ color: minutes === 0 ? "red" : "green" }}
    //     className="ml-2"
    //   >{`${minutes.toString().padStart(2, "0")}:${seconds
    //     .toString()
    //     .padStart(2, "0")}`}</p>
    // </div>
  );
};

export default Timer;
