import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import AuthService from "../../session/AuthService";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import LearningAppList from "../LearningApp/LearningAppList";
import { getResourceList } from "../../redux/actions/subjectActions";

class LearningHub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      notificationData: [],
      announcementData: [],
      showAnnoucment: false,
      surveyBoxState: false,
      showList: true,
      FeedbackList: [],
      recentlyAddedBadgesList: [],
      FriendsList: [],
      userKnowledgepoint: 0,
      globalLanguage: AuthService.getCommonLanguages(),
      showPage: false,
      dataKey: "",

      searchValue: "",
      searchMyValue: "",
      searchLearningValue: "",
      searchChallengeValue: "",
    };

    this.searchLearningApp = this.searchLearningApp.bind(this);
  }

  componentDidMount() {
    let data = {
      page: 0,
      searchKey: "",
    };
    this.props.getResourceList(data, false);
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="homeMainWrapper">
          <>
            <Grid container spacing={2}>
              <Grid item lg={9} md={8} sm={12} xs={12}>
                <h3 className="listHomeTitle group-count">
                  {t("Learning Hub")} -
                  <span className="badge badge-info">
                    {this.props.totalResource}
                  </span>
                </h3>
              </Grid>
              <Grid
                item
                lg={3}
                md={4}
                sm={12}
                xs={12}
                style={{ paddingBottom: "12px" }}
              >
                {(this.props.userRole === 1 || this.props.userRole === 2) && (
                  <button
                    style={{ float: "right" }}
                    onClick={this.createResource}
                  >
                    {t("Create Resource")}
                  </button>
                )}
              </Grid>
            </Grid>

            <div className="spaceFiletCard newPostCardWrapper">
              <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    id="subjects"
                    label=""
                    style={{ width: "100%", height: "100%" }}
                    variant="outlined"
                    onChange={(e) => this.searchLearningApp(e.target.value)}
                    placeholder={t("Search Learning Hub...")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* <div className="badgeMainWrapper"></div> */}
                </Grid>
              </Grid>
            </div>
          </>
        </div>

        <div className="" style={{ marginTop: "10px" }}>
          <div className="squareMainWrapper">
            <>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="announcementWrapperOuter">
                    <Grid style={{ paddingTop: 12 }}>
                      <LearningAppList
                        tabIndex={2}
                        searchValue={this.state.searchLearningValue.trim()}
                      ></LearningAppList>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </>
          </div>
        </div>
      </div>
    );
  }
  createResource = () => {
    let data = "new";
    this.props.history.push(`/${this.props.workspaceType}/newResource/${data}`);
  };

  searchLearningApp(value) {
    this.setState({ searchLearningValue: value });
    let data = {
      page: 0,
      searchKey: value,
    };
    this.props.getResourceList(data, false);
  }
}

// export default (withTranslation()(Home));

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    userRole: state.loginDetailsApi.loginDetails.userWorkSpace?.role,
    totalResource: state.subjects.totalResource,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    getResourceList: (data, pagination) =>
      dispatch(getResourceList(data, pagination)),
  };
};
export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(LearningHub)));
