import React from "react";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ReactPlayer from "react-player";
import AuthService from "../../../session/AuthService_old";
import i18n from "../../../i18n";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import "./myPost.css";
import { Button, Form, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import "./postsImage.css";
import { CgDanger } from "react-icons/cg";

let time;

class VideoPostCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      postId: "",
      isLike: false,
      base64String: [],
      isvideoOpen: false,
      isImageview: true,

      isLikeOptionSelected: false,
      isEnbleComment: false,
      selectedLikeValue: "",
      reportPostState: false,
      reportPostId: "",
      reportPostType: "",
      FirstTwocomment: true,
      fileData: [],
      imageData: [],
      fileVideoData: [],
      photoIndex: 0,
      videoIndex: 0,
      startIndex: 0,
      openCommentBoxes: false,
    };

    this.handleReactplayer = this.handleReactplayer.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }
  componentDidUpdate(nextProps) {}

  render() {
    const { t } = this.props;
    const { fileData } = this.props;
    const { imageData, videoStatus, noMediaFile } = this.props;
    return (
      <div>
        {/* {!this.state.thumbnailStatus &&
          <VideoThumbnail
            className="videoRender"
            videoUrl={imageData}

            thumbnailHandler={(thumbnail) => {
              this.setState({ thumbnail: thumbnail, thumbnailStatus: true });
            }}
          />

        }
        {this.state.thumbnailStatus && */}
        {
          // noMediaFile ? (
          //   <div
          //     className="videoOverAllCard d-flex align-items-center justify-content-center text-white"
          //     style={{ gap: "1rem", height: "10rem" }}
          //   >
          //     <CgDanger className="svg-icons text-danger" />
          //     <h5 className="m-0">Video unavailable</h5>
          //   </div>
          // ) :
          !videoStatus ? (
            <div
              class="videopostcontainer thumbnail-view"
              onClick={() =>
                this.setState({ isvideoOpen: true, isImageview: false })
              }
            >
              <ReactPlayer
                playing={false}
                className="player"
                // controls
                url={fileData}
              />

              {/* <video controls style={{ width: "100%", height: "100%" }}>
              <source src={fileData} />
            </video> */}
              <div class="overlay">
                <a class="icon">
                  <div className="videoCardPlayBtn">
                    <PlayArrowIcon />
                  </div>
                </a>
              </div>
            </div>
          ) : (
            // :  <div className="video-body">
            //   <div class="video-container">
            //     <div class="video-moon">
            //       <div class="video-crater video-crater1"></div>
            //       <div class="video-crater video-crater2"></div>
            //       <div class="video-crater video-crater3"></div>
            //       <div class="video-crater video-crater4"></div>
            //       <div class="video-crater video-crater5"></div>
            //       <div class="video-shadow"></div>
            //       <div class="video-eye video-eye-l"></div>
            //       <div class="video-eye video-eye-r"></div>
            //       <div class="video-mouth"></div>
            //       <div class="video-blush video-blush1"></div>
            //       <div class="video-blush video-blush2"></div>
            //     </div>
            //     <div class="video-orbit">
            //       <div class="video-rocket">
            //         <div class="video-window"></div>
            //       </div>
            //     </div>
            //   </div>
            //   <p className="video-color d-flex align-items-center ">
            //     Video uploading on progress{" "}
            //     <div class="load-wrapp">
            //       <div class="load-1">
            //         <div class="line"></div>
            //         <div class="line"></div>
            //         <div class="line"></div>
            //         <div class="line"></div>
            //       </div>
            //     </div>
            //   </p>
            // </div>
            // !imageData ? (
            //   <div
            //     className="d-flex align-items-center justify-content-center text-white"
            //     style={{ gap: "1rem", height: "10rem" }}
            //   >
            //     <CgDanger className="svg-icons text-danger" />
            //     <h5 className="m-0">Video unavailable</h5>
            //   </div>
            // )
            <div className="videoOverAllCard">
              <center>
                <>
                  {" "}
                  <div
                    class="videopostcontainer"
                    onClick={() =>
                      this.setState({ isvideoOpen: true, isImageview: false })
                    }
                  >
                    <div class="thumbnail-view">
                      <img
                        class="image"
                        style={{ width: "100%", height: "100%" }}
                        src={imageData}
                        alt="file"
                      />
                    </div>
                    {imageData && (
                      <div class="overlay">
                        <a href="#" class="icon">
                          <div className="videoCardPlayBtn">
                            <PlayArrowIcon />
                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                </>
              </center>
            </div>
          )
        }
        {this.state.isvideoOpen && fileData && (
          <div>
            <Modal show="true" onHide={this.handleReactplayer}>
              <Form id="profileForm">
                <Modal.Body>
                  {
                    // <iframe
                    //   src={fileData}
                    //   style={{
                    //     width: "100%",
                    //     height: "400px",
                    //   }}

                    // />
                    <ReactPlayer className="player" controls url={fileData} />
                  }
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    style={{ color: "#ffffff", marginRight: "12px" }}
                    onClick={this.handleReactplayer}
                  >
                    {t("Close")}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>
        )}
      </div>
    );
  }

  handleReactplayer() {
    this.setState({
      isImageview: true,
      isvideoOpen: false,
    });
  }
}
// export default withTranslation()();

export default withTranslation()(withRouter(VideoPostCard));
