import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CreateIcon from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import { MenuItem } from "@material-ui/core";

export default class EditableTextFieldInsta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disbaledField: false,
      value: this.props.value,
      urlStatus: "",
    };

    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.onDataCheck = this.onDataCheck.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  handleClickEdit() {
    this.setState({
      disbaledField: false,
    });
    this.props.handleClickEdits();
  }

  onDataCheck(key) {
    console.log("url-status", key);
    this.setState({
      urlStatus: key,
    });
    this.props.onDataCheck(key);
  }

  handleChangeInput(event) {
    this.setState({
      value: event.target.value,
    });
    this.props.getSelectedValue(event.target.value);
    this.props.handleClickEdits();
  }

  render() {
    const { label } = this.props;
    const { disbaledField, value } = this.state;
    return (
      <div
        className={
          disbaledField
            ? "editableTextWrapper formFieldGroup disabledField"
            : "editableTextWrapper formFieldGroup w-100"
        }
      >
        <label className="label-v2">{label}</label>
        <Grid container spacing={0} alignItems="flex-end" wrap="nowrap">
          <Grid item xs={12}>
            <TextField
              id="textField"
              label=""
              variant="outlined"
              style={{
                border:
                  this.state.urlStatus === "true"
                    ? "2px solid green"
                    : this.state.urlStatus === "false"
                    ? "2px solid red"
                    : "",
                borderRadius: "6px",
              }}
              onPasteCapture={(e) => {
                if (
                  !/^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(
                    e.target.value
                  )
                ) {
                  this.onDataCheck("false");
                } else {
                  this.onDataCheck("true");
                }
              }}
              onPaste={(e) => {
                if (
                  !/^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(
                    e.target.value
                  )
                ) {
                  this.onDataCheck("false");
                } else {
                  this.onDataCheck("true");
                }
              }}
              onKeyUp={(e) => {
                if (
                  !/^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(
                    e.target.value
                  )
                ) {
                  this.onDataCheck("false");
                } else {
                  this.onDataCheck("true");
                }
              }}
              onKeyPress={(e) => {
                if (
                  !/^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(
                    e.target.value
                  )
                ) {
                  this.onDataCheck("false");
                } else {
                  this.onDataCheck("true");
                }
              }}
              type="url"
              value={value}
              onChange={this.handleChangeInput}
              disabled={disbaledField}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
