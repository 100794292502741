import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { announcementStatus } from "../redux/actions/loginDetailsActions";
import Image from "../images/personal_branding.png";
import { RiArrowRightUpFill } from "react-icons/ri";
import { withRouter } from "react-router";

const YuukkeAnnouncementModel = (props) => {
  const loginDetails = useSelector(
    (state) => state.loginDetailsApi.loginDetails
  );
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const onHidePopup = () => {
    setOpen(false);
    dispatch(announcementStatus(""));
  };
  const makeAction = () => {
    setOpen(false);
    dispatch(announcementStatus(""));
    props.history.push("/my-profile");
  };

  return (
    <div>
      <Modal
        show={open}
        onHide={onHidePopup}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          <AiOutlineCloseCircle
            onClick={onHidePopup}
            className="mr-2 mt-2"
            style={{
              cursor: "pointer",
              float: "right",
              marginBottom: "8px",
              width: "20px",
              height: "20px",
              color: "#000000",
            }}
          />
        </div>
        <section className="w-auto  mt-4">
          <img
            alt="img"
            src={Image}
            className="w-100 h-100  "
            style={{ objectFit: "cover" }}
          />
        </section>
        <Modal.Body
          style={{ textAlign: "center" }}
          className="d-flex align-items-center flex-column text-center"
        >
          {/* <h4 style={{ fontSize: "xx-large", color: "hotpink" }}>OOOPS !</h4> */}
          <h5 className="font-weight-bold">
            Welcome , {loginDetails?.dName ? loginDetails?.dName : "User"}
          </h5>
          <p className="text-secondary mb-2 mt-3">{props.message}</p>
          <span
            className="green-btn mb-2 mt-3 font-weight-bold"
            onClick={() => makeAction()}
          >
            Let's Go{" "}
            <RiArrowRightUpFill className="h-auto" style={{ width: "21px" }} />
          </span>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default withRouter(YuukkeAnnouncementModel);
