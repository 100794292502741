import React, { useEffect, useRef, useState } from "react";
import { FaList, FaRegShareSquare } from "react-icons/fa";
import Select from "react-select";
import { BiCalendar, BiEdit } from "react-icons/bi";
import "react-datepicker/dist/react-datepicker.css";
import {
  IoArrowBackCircleOutline,
  IoCalendarOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import { IoMdTime } from "react-icons/io";
import {
  findExitingSlot,
  formatTime,
  getDateFormat,
  getMeetingUrl,
  instaValidation,
  linkedInvalidation,
  shareUrl,
  stringToArray,
  twitterValidation,
  urlPatternValidation,
} from "../../utils";
import moment from "moment";
import SlotCalender from "./SlotCalender";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getCalendarServices,
  getCategoryListV2,
  getSubscriptionList,
  getSubscriptionStatus,
  getUserSlots,
  // saveCalenderSlot,
} from "../../redux/actions/calendarAction";
import BlockUi from "react-block-ui";
import { toast } from "react-toastify";
import MyYuukke from "../../api/MyYuukke";
import SocialShare from "../../components/SocialShare";
import { BASE_URL } from "../../config/app-config";
import {
  MdLibraryAdd,
  MdOutlineMailOutline,
  MdOutlineRemoveRedEye,
  MdOutlineVideocam,
} from "react-icons/md";
import image from "../../img/default_avatar.jpg";
import { FiPhoneCall } from "react-icons/fi";
import ForumService from "../../api/ForumService";
import ProfileService from "../../api/ProfileService";
import LoaderButton from "../../components/LoaderButton";
import CalendarPlans from "../../components/CalendarPlans";
import CheckBox from "../../components/CheckBox";
import { WeeklyCalendar } from "./WeeklyCalendar";
import { GrBusinessService, GrDocumentConfig } from "react-icons/gr";
import RazorPay from "../../components/RazorPay.jsx";
import Swal from "sweetalert2";
import { FaRupeeSign } from "react-icons/fa";
import { withTranslation } from "react-i18next";
import EditableTextFieldInsta from "../../components/EditableTextFieldInsta.jsx";
import { VscCheck, VscChromeClose } from "react-icons/vsc";
import EditableTextFieldFacebook from "../../components/EditableTextFieldFacebook.jsx";
import EditableTextFieldLinkedIn from "../../components/EditableTextFieldLinkedIn.jsx";
import { MdDoubleArrow } from "react-icons/md";
import { updateSocialMediaDetails } from "../../redux/actions/loginDetailsActions.js";
import EditableTextFieldTwitter from "../../components/EditableTextFieldTwitter.jsx";
import Services from "../Calender/ProfileCalendar/Services.jsx";

const plans = [
  {
    packageName: "Free",
    description: "For individuals starting out with basic scheduling",
    amount: "Always free",
    feature: "Free features",
    featureList: [
      "Unlimited 1-1 meetings",
      "One event type",
      "7 day's free trail",
    ],
  },
  // {
  //   packageName: "Standard",
  //   description: "For those with more sophisticated scheduling needs",
  //   amount: "$10",
  //   feature: "Free features plus",
  //   featureList: [
  //     "Unlimited event types",
  //     "Connect multiple calendars",
  //     "10 day's you can use'it",
  //     "Automate reminders & more",
  //     "Stripe, Paypal integration",
  //     "50 day's you can use'it",
  //   ],
  // },
];

const CustomOption = ({ innerRef, innerProps, data }) => (
  <div
    ref={innerRef}
    {...innerProps}
    style={{ fontSize: "12px" }}
    className="d-flex  text-secondary c-pointer p-2 align-items-center justify-content-between"
  >
    <div>{data.label}</div>
    <div className="ml-auto">{data.time}</div>
  </div>
);
const UserCalendarContainer = (props) => {
  const { t } = props;
  const {
    userSlots,
    eventLoader,
    subscriptionList,
    subscriptionStatus,
    subscriptionDetails,
    categoryList,
    calendarServices,
  } = useSelector((state) => state.event);
  const dispatch = useDispatch();
  const { loginDetails } = useSelector((state) => state.loginDetailsApi);
  const activeOptions = [
    { value: 15, label: "15 Minute Meeting" },
    { value: 30, label: "30 Minute Meeting" },
    { value: 45, label: "45 Minute Meeting" },
    // { value: 60, label: "60 Minute Meeting" },
  ];
  const wrapperRef = useRef();
  let slotDetails = [
    {
      from: formatTime(new Date()),
      to: formatTime(new Date(), 15),
    },
  ];
  // let slotDetails = [{ from: formatTime(new Date()) }];

  const defaultSlot = [
    { day: "SUN", timeSlots: [...slotDetails] },
    { day: "MON", timeSlots: [...slotDetails] },
    { day: "TUE", timeSlots: [...slotDetails] },
    { day: "WED", timeSlots: [...slotDetails] },
    { day: "THU", timeSlots: [...slotDetails] },
    { day: "FRI", timeSlots: [...slotDetails] },
    { day: "SAT", timeSlots: [...slotDetails] },
  ];
  const [state, setState] = useState({
    schedule: [],
    view: 0,
    startDate: "",
    dateMenu: false,
    activeMinutes: {
      value: 15,
      label: "15 Minute Meeting",
    },
    weeklyHours: defaultSlot,
    dateSpecific: [],
    calendarViewData: [],
    newScheduleModel: false,
    scheduleName: "",
    calendarPlanModel: false,
    calendarId: null,
    slug: null,
    slugType: null,
    slotNameList: [],
    activeId: 0,
    defaultSlug: null,
    viewWeeklyShare: false,
    viewDayShare: false,
    viewDayShareSlug: "",
    requestList: [],
    requestMenu: false,
    timeZoneOptions: [],
    meetingDetails: null,
    meetingModel: false,
    meetingTitle: "",
    userCalendarUrl: false,
    meetingLink: null,
    meetingInYuukke: false,
    btnLoader: false,
    plansName: null,
    loader: false,
    madeChanges: false,
    slotDetails: "",
    paymentPage: false,
    isSubscribed: false,
    prize: 0,
    subId: null,
    message: "",
    planSelected: false,
    biography: "",
    bioEditState: false,
    shareService: false,
    instagramUrl: "",
    validInstaUrl: "",
    // faceBookUrl: "",
    // validFaceBookUrl: "",
    linkedInUrl: "",
    validlinkedInUrl: "",
    twitterUrl: "",
    validTwitterUrl: "",
    location: "",
    // timeZone: {
    //   label: `India Standard Time  - ${getTimeInZone(5.5)}`,
    //   value: "Asia/Kolkata &-GMT+5:30",
    // },
  });

  useEffect(() => {
    dispatch(getSubscriptionList());
    dispatch(getSubscriptionStatus("calender"));
    dispatch(getUserSlots());
    if (categoryList?.length === 0) {
      dispatch(getCategoryListV2());
    }
    if (calendarServices?.length === 0) {
      dispatch(getCalendarServices());
    }

    // const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log("userTimeZone", userTimeZone);
    // updateState("timeZoneOptions", generateTimeZones());
  }, []);
  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => document.removeEventListener("mousedown", handleClickOutside);
  // }, []);

  // const handleMenuOpen = () => {
  //   updateState("timeZoneOptions", generateTimeZones());
  // };

  useEffect(() => {
    if (userSlots?.preference) {
      console.log("update-slots", userSlots);

      const { preference, slots } = userSlots;
      const activeType = activeOptions.find(
        (item) => item.value === preference?.meetingActiveDuration
      );
      setState((previous) => ({
        ...previous,
        slotDetails: preference,
        calendarId: preference.id,
        weeklyHours: slots?.length !== 0 ? slots : defaultSlot,
        calendarPlanModel: false,
        activeMinutes: activeType ? activeType : previous.weeklyHours,
        // isSubscribed: needPayment ? false : true,
        // message: message,
        // freeSubscribeCompleted: freeSubscribeCompleted,
        planSelected: true,
        bioEditState: false,
        shareService: preference.shareService,
        biography: userSlots.profileDetails?.biography,
        instagramUrl: userSlots.profileDetails?.instagramUrl,
        linkedInUrl: userSlots.profileDetails?.linkedIn,
        twitterUrl: userSlots.profileDetails?.twitter,
        location: userSlots.profileDetails?.location,
      }));
    } else if (userSlots?.slots) {
      setState((previous) => ({
        ...previous,
        weeklyHours:
          userSlots?.slots?.length !== 0 ? userSlots?.slots : defaultSlot,
        bioEditState: false,
        biography: userSlots.profileDetails?.biography,
        instagramUrl: userSlots.profileDetails?.instagramUrl,
        linkedInUrl: userSlots.profileDetails?.linkedIn,
        twitterUrl: userSlots.profileDetails?.twitter,
        location: userSlots.profileDetails?.location,
        shareService: false,
      }));
    }
  }, [userSlots]);

  useEffect(() => {
    setState((previous) => ({
      ...previous,
      isSubscribed: subscriptionStatus,
    }));
  }, [subscriptionStatus]);

  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const modiFyTimeSlot = (type, index, t_i) => {
    let list = state.weeklyHours[index].timeSlots;

    if (type === "delete") {
      console.log("li", list);
      console.log("li", t_i);

      list.splice(t_i, 1);
    } else if (type === "remove") {
      list.length = 0;
    } else {
      const fromTime = findExitingSlot(list, formatTime(new Date(), 0));
      console.log("fromTime", fromTime);
      const timeDuration = [
        {
          from: fromTime,
          to: moment(`2024-01-01T${fromTime}:00`)
            .add(
              state.activeMinutes.value ? state.activeMinutes.value : 30,
              "minutes"
            )
            .locale("en")
            .format("HH:mm"),
        },
      ];
      console.log("timeDuration", timeDuration);
      list.push(...timeDuration);
    }

    setState((previous) => ({
      ...previous,
      madeChanges: state.calendarId ? true : false,
    }));
  };

  const updateTimeSlots = (index, name, time, t_i) => {
    const minutes = formatTime(time);
    const list = state.weeklyHours[index].timeSlots.find(
      (item) => item.from === minutes
    );
    console.log("list", list);
    if (list) {
      toast.warning("This slot already selected,choose another slot");
      return;
    }

    console.log("minutes", minutes);

    setState((previous) => {
      const newTimeDetails = [...previous.weeklyHours];

      const newTimeSlots = [...newTimeDetails[index].timeSlots];

      newTimeSlots[t_i] = {
        ...newTimeSlots[t_i],
        [name]: formatTime(time),
      };

      newTimeDetails[index] = {
        ...newTimeDetails[index],
        timeSlots: newTimeSlots,
      };

      return {
        ...previous,
        madeChanges: state.calendarId ? true : false,
        weeklyHours: newTimeDetails,
      };
    });
  };

  const viewRequestList = () => {
    MyYuukke.getRequestList({ id: state.calendarId })
      .then((res) => {
        console.log("res", res);
        const deatSpeObj = res.data.data.map((item) => {
          item.dateSlots = item.date ? stringToArray(item.date) : "";
          item.timeSlots = JSON.parse(item.timeSlot);
          return item;
        });
        setState((previous) => ({
          ...previous,
          requestMenu: true,
          requestList: deatSpeObj,
        }));
      })
      .catch((err) => {});
  };
  const handleClickContact = (event, data, type) => {
    if (type === "phone") {
      window.location.href = `tel:${data}`;
    } else {
      window.location.href = `mailto:${data}`;
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const scheduleMeeting = (data) => {
    setState((previous) => ({
      ...previous,
      meetingDetails: data,
      meetingModel: true,
      userCalendarUrl: data.url,
    }));
  };
  const createMeeting = () => {
    if (!state.meetingTitle) {
      toast.warning("Enter meeting title");
    } else if (
      !urlPatternValidation(state.meetingLink) &&
      !state.meetingInYuukke
    ) {
      toast.warning("Enter valid url");
    } else if (state.meetingInYuukke) {
      updateState("btnLoader", true);
      const combinedDateTimeString = `${state.meetingDetails.dateSlots[0]} ${state.meetingDetails.timeSlots[0].from}`;
      const combinedDateTime = moment(
        combinedDateTimeString,
        "YYYY-MM-DD HH:mm:ss"
      );

      const instantMeetingLink = `Yuukke-${Math.random() * 100}-${
        loginDetails.uId
      }-${Date.now()}`;
      const meetingData = {
        meetingName: state.meetingTitle,
        meetingDescription: `Schedule Meeting with ${state.meetingDetails.userName}`,
        meetingLink: instantMeetingLink,
        meetingType: "Scheduled",
        meetingTime: combinedDateTime.toISOString(),
      };
      const formData = {
        senderName: loginDetails.dName,
        senderId: loginDetails.uId,
        receiverId: undefined,
        receiverName: undefined,
        receiverList: [loginDetails.uId],
        message: {
          meeting: meetingData,
        },
        isMeeting: true,
      };
      console.log("formData", formData);
      ProfileService.instantMeetingAction(formData)
        .then((response) => {
          var result = response.data.data;
          console.log("meetind details>>", result);
          saveCalendar(instantMeetingLink);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Saving Error");
        });
      updateState("btnLoader", false);
    } else {
      saveCalendar(state.meetingLink);
    }
  };

  const saveCalendar = (link) => {
    const combinedDateTime = `${state.meetingDetails.dateSlots[0]} ${state.meetingDetails.timeSlots[0].from}`;
    let data = {
      meetingInApplication: state.meetingInYuukke,
      taskName: state.meetingTitle,
      taskDescription: `Schedule Meeting with ${state.meetingDetails.userName}`,
      startDate: moment(combinedDateTime).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      endDate: moment(combinedDateTime)
        .add(
          state.meetingDetails.meetingActiveDuration
            ? state.meetingDetails.meetingActiveDuration
            : 30,
          "minutes"
        )
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      subjectId: 0,
      taskType: "slotBooking",
      startTime: "",
      endTime: "",
      color: "#A00030",
      moderatorList: [loginDetails.uId],
      publicEvent: false,
      emailConfigure: false,
      meetingType: 0,
      url: link,
      slotBookingId: state.meetingDetails.bookingId,
      slotSlug: state.meetingDetails.eventslug,
    };

    ForumService.saveCalendarV3(data)
      .then((response) => {
        console.log("resp", response);
        const list = state.requestList.map((item) => {
          if (state.meetingDetails.bookingId === item.bookingId) {
            item.url = link;
            item.meetingInApplication = state.meetingInYuukke;
          }

          return item;
        });
        setState((previous) => ({
          ...previous,
          requestList: list,
          meetingModel: false,
          meetingLink: link,
          btnLoader: false,
          meetingInYuukke: false,
          meetingTitle: "",
        }));
        // updateState("requestList", list);
        toast.success("Meeting created successfully");
      })
      .catch((e) => {
        console.log("e", e);
        toast.error("Something went wrong !");
        updateState("btnLoader", false);
      });
  };
  const clearMeetingData = () => {
    setState((previous) => ({
      ...previous,
      meetingModel: false,
      userCalendarUrl: null,
      btnLoader: false,
      meetingTitle: "",
      meetingLink: "",
    }));
  };
  const saveUserAvailability = (minutes) => {
    if (state.calendarId && state.isSubscribed) {
      updateState("loader", true);
      let data = {
        slots: state.weeklyHours,
        meetingActiveDuration: minutes
          ? minutes.value
          : state.activeMinutes.value,
      };

      MyYuukke.saveUserAvailability(data)
        .then((response) => {
          let result = response.data.data;
          console.log("response", result);
          setState((previous) => ({
            ...previous,
            calendarId: result.details.id,
            loader: false,
            madeChanges: false,
          }));
        })
        .catch((e) => {
          toast.error("Something went wrong");
          console.log("error", e);
          updateState("loader", false);
        });
    } else {
      updateState("calendarPlanModel", true);
    }
  };
  const shareCalendar = () => {
    if (state.calendarId && state.isSubscribed) {
      updateState("calenderShareModel", true);
    } else {
      // saveUserAvailability();
      updateState("calendarPlanModel", true);
    }
  };
  const updatePaymentStatus = (status) => {
    if (status === "dismiss") {
      Swal.fire({
        icon: "error",
        title: "Payment updating failed!",
        text: "If your money debited in bank account ,please contact our team  +91 04446314646",
        showConfirmButton: true,
      }).then(() => {
        setState((previous) => ({
          ...previous,
          calendarPlanModel: true,
          isSubscribed: false,
          paymentPage: false,
        }));
      });
    }
    // else if (status === "dismiss") {
    //   setState((previous) => ({
    //     ...previous,
    //     calendarPlanModel: true,
    //     paymentPage: false,
    //   }));
    // }
    else {
      setState((previous) => ({
        ...previous,
        calendarPlanModel: true,
        isSubscribed: true,
        paymentPage: false,
        planSelected: true,
      }));
    }
  };

  const paymentModelStatus = (prize, id) => {
    setState((previous) => ({
      ...previous,
      calendarPlanModel: false,
      paymentPage: true,
      subscribeId: id,
      prize: prize,
    }));
  };
  const updateModelDetails = () => {
    setState((previous) => ({
      ...previous,
      calendarPlanModel: false,
      isSubscribed: true,
    }));
  };
  const closeCalendarModal = () => {
    // if(state.isSubscribed && !state.slotDetails){
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Update !",
    //     text: "Please update your preference !",
    //     showConfirmButton: true,
    //   })
    // }else{
    updateState("calendarPlanModel", false);

    // }
  };
  const updateSlotDetails = (data) => {
    console.log("slotDetails", data);
    setState((previous) => ({
      ...previous,
      slotDetails: data ? data : state.slotDetails,
    }));
  };
  const saveProfileChanges = () => {
    if (!state.biography) {
      toast.warning("Enter your service details");
    } else if (!state.location) {
      toast.warning("Enter your location");
    } else if (!state.instagramUrl && !state.linkedInUrl && !state.twitterUrl) {
      toast.warning("Enter your social media url");
    } else {
      let validCount = 0;
      const data = {};
      data["biography"] = state.biography;
      data["location"] = state.location;
      if (linkedInvalidation(state.linkedInUrl)) {
        data["linkedin"] = state.linkedInUrl;
        validCount++;
      }

      if (instaValidation(state.instagramUrl)) {
        data["instagram"] = state.instagramUrl;
        validCount++;
      }

      if (twitterValidation(state.twitterUrl)) {
        data["twitter"] = state.twitterUrl;
        validCount++;
      }
      if (validCount >= 2) {
        validProfileDetails(data);
      } else {
        toast.warning(
          "Please add minimum two of your social media profile url"
        );
        // You can also display an error message to the user here
      }
      // data["biography"]=state.biography,
    }
  };
  // let data = {
  //   biography: state.biography,
  //   instagram: state.instagramUrl,
  //   linkedin: state.linkedInUrl,
  // };
  // if (state.validTwitterUrl == "true" && state.twitterUrl) {
  //   data["twitter"] = state.twitterUrl;
  // }

  // if (state.calendarId && state.isSubscribed) {
  //   let data = {
  //     biography: state.biography,
  //   };
  //   ProfileService.userProfileDetailsUpdated(data)
  //     .then((res) => {
  //       setState((previous) => ({
  //         ...previous,
  //         bioEditState: false,
  //         bioChangeMade: false,
  //       }));
  //       toast.success("Updated successfully");
  //     })
  //     .catch((e) => {
  //       setState((previous) => ({
  //         ...previous,
  //         bioEditState: false,
  //         bioChangeMade: false,
  //       }));
  //       toast.error("Something went wrong !");
  //     });
  // } else {
  //   updateState("calendarPlanModel", true);
  // }

  const validProfileDetails = (data) => {
    ProfileService.userProfileDetailsUpdated(data)
      .then((res) => {
        console.log("profile-details", res);
        dispatch(updateSocialMediaDetails(data));
        updateState("shareService", true);
      })
      .catch((e) => {
        toast.error("Something went wrong !");
      });
  };
  console.log("state", state);
  console.log("subscriptionDetails", subscriptionDetails);
  console.log("subscriptionStatus", subscriptionStatus);
  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={state.loader || eventLoader}
    >
      <div style={{ padding: "1rem 0rem" }}>
        <h4 className="font-weight-bold d-flex">
          {state.requestMenu
            ? t("Slot's Booked Users")
            : t("Your Availability")}{" "}
          {state.requestMenu && (
            <p
              className="ml-auto f-14 c-pointer"
              onClick={() => updateState("requestMenu", false)}
            >
              <IoArrowBackCircleOutline className="ml-auto mr-1 svg-icons" />
              {t("Back")}
            </p>
          )}
        </h4>
        {state.paymentPage && (
          <RazorPay
            email={loginDetails.email}
            name={loginDetails.dName}
            userId={loginDetails.userId}
            contactNumber={loginDetails.phoneNumber}
            subscriptionId={state.subscribeId}
            amount={state.prize}
            type={"calendar_premium"}
            updatePaymentStatus={updatePaymentStatus}
          />
        )}
        {/* {!state.requestMenu && (
          <ul className="d-flex flex-wrap p-0 l-none g-1">
            <li
              className="btn2"
              style={{ background: 0 === state.activeId ? "white" : "" }}
              onClick={() => getNewSlotList(0, state.defaultSlug)}
            >
              <BiCalendarCheck className="svg-icons mr-2" /> Working hours
            </li>
            {state.slotNameList.map((item, i) => (
              <>
                {item.type !== "default" ? (
                  <li
                    className="btn2"
                    style={{
                      background:
                        item.event.id === state.activeId ? "white" : "",
                    }}
                    key={i}
                    onClick={() =>
                      getNewSlotList(item.event.id, item.event.eventslug)
                    }
                  >
                    <BiCalendarCheck className="svg-icons  mr-2" />
                    {item.event.eventName}
                  </li>
                ) : (
                  <></>
                )}
              </>
            ))}

            <li className="ml-auto">
              <button onClick={() => updateState("newScheduleModel", true)}>
                Create schedule
                <IoIosAddCircleOutline className="svg-icons ml-2 text-white" />{" "}
              </button>
            </li>
          </ul>
        )} */}
        <div className="cardWrapper position-relative">
          {state.requestMenu ? (
            <div className="cal-grid g-1 p-1">
              {state.requestList.map((item, i) => (
                <div
                  key={i}
                  class="card-profile p-3"
                  style={{
                    border: "1px solid gray",
                    overflow: "hidden",
                    width: "90%",
                    height: "100%",
                  }}
                >
                  <div style={{ flex: 2 }}>
                    <div class="p-4"></div>
                  </div>
                  <div class="card-body-profile " style={{ cursor: "auto" }}>
                    <div class="d-flex justify-content-center">
                      <div class="postuserImg-vcard">
                        <img
                          className="rounded-circle"
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          src={image}
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="text-center">
                      <h5
                        class="mb-0"
                        style={{
                          color: "#14191e",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.userName}
                      </h5>

                      <p class="dis-color f-12 m-2"> {item.bio}</p>
                    </div>
                    <div className="p-3">
                      <section class="d-flex mb-2 mt-2">
                        <span
                          onClick={(e) =>
                            handleClickContact(e, item.mobileNo, "phone")
                          }
                          className="s-box"
                        >
                          <FiPhoneCall class="bg-color" />
                        </span>

                        <div
                          class="ml-3 mr-5 c-pointer"
                          onClick={(e) =>
                            handleClickContact(e, item.mobileNo, "phone")
                          }
                          style={{
                            maxWidth: "calc(100% - 2em)",
                            wordBreak: "break-word",
                          }}
                        >
                          {item.mobileNo}
                        </div>
                      </section>
                      <section class="d-flex mb-4 mt-3 align-items-center">
                        <span
                          class="pl-2 pr-2 c-pointer"
                          onClick={(e) =>
                            handleClickContact(e, item.email, "email")
                          }
                          className="s-box"
                        >
                          <MdOutlineMailOutline class="bg-color" />
                        </span>
                        <span
                          class="pl-2 pr-2 c-pointer f-12"
                          onClick={(e) =>
                            handleClickContact(e, item.email, "email")
                          }
                          style={{
                            maxWidth: "calc(100% - 2em)",
                            wordBreak: "break-word",
                          }}
                        >
                          {item.email}
                        </span>
                      </section>
                      <section className="t-box p-0">
                        <p
                          className="font-weight-bold p-2 text-center"
                          style={{ background: "gainsboro" }}
                        >
                          <IoCalendarOutline
                            style={{ height: "16px" }}
                            className="w-auto text-secondary mr-2"
                          />
                          Slot Date :{" "}
                          {moment(item.dateSlots[0]).locale("en").format("LL")}
                        </p>
                        <p className="p-2 text-center">
                          <IoMdTime
                            style={{ height: "19px" }}
                            className="w-auto text-secondary mr-1"
                          />{" "}
                          Slot Time :{" "}
                          {moment(`2024-01-01T${item.timeSlots[0].from}:00`)
                            .locale("en")
                            .format("LT")}{" "}
                          -{" "}
                          {moment(`2024-01-01T${item.timeSlots[0].from}:00`)
                            .add(item.meetingActiveDuration, "minutes")
                            .locale("en")
                            .format("LT")}
                          {/* {moment(`2024-01-01T${item.timeSlots[0].to}:00`)
                            .locale("en")
                            .format("LT")}{" "} */}
                        </p>
                      </section>
                      {item.amount && (
                        <section
                          className="t-box p-1 mt-3 font-weight-bold"
                          style={{ background: "aliceblue" }}
                        >
                          Paid amount : <FaRupeeSign className="mr-1" />
                          {item.amount}
                        </section>
                      )}
                      <button
                        className="d-flex mt-4 w-100 justify-content-center align-items-center"
                        onClick={() => scheduleMeeting(item)}
                      >
                        {item.url ? (
                          <>
                            {" "}
                            <FaRegShareSquare
                              className="  text-white mr-2 h-auto"
                              style={{ width: "19px" }}
                            />{" "}
                            Share meeting link
                          </>
                        ) : (
                          <>
                            <MdOutlineVideocam className="svg-icons text-white mr-2" />{" "}
                            Schedule Meeting
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {state.requestList.length == 0 && <p>No request present ..</p>}
            </div>
          ) : (
            <>
              {state.shareService && (
                <IoSettingsOutline
                  className="svg-icons position-absolute"
                  style={{ right: "18px", top: "9px" }}
                  onClick={() => updateState("calendarPlanModel", true)}
                />
              )}
              <ul className="p-3 l-none d-flex g-2 flex-wrap align-items-center">
                <li>
                  <h5>Active on</h5>
                  <Select
                    options={activeOptions}
                    value={state.activeMinutes}
                    onChange={(e) => {
                      updateState("activeMinutes", e);
                      if (state.calendarId) {
                        saveUserAvailability(e);
                      }
                    }}
                  />
                </li>

                {/* <li className="ml-auto mt-auto">
                  <button
                    className="b-none user-cal-btn"
                    onClick={() =>
                      updateState("view", state.view === 1 ? 0 : 1)
                    }
                  >
                    <BiCalendar className="svg-icons text-dark mr-2" />
                    {state.view === 1
                      ? "View Day's Slot's"
                      : "View Date Slot's"}
                  </button>
                </li> */}
                {state.shareService && (
                  <li className="mt-auto ml-auto">
                    <button
                      // className="b-none user-cal-btn"
                      onClick={() => shareCalendar()}
                      // updateState("calendarPlanModel", true);
                    >
                      <FaRegShareSquare className="svg-icons text-white mr-2" />
                      {t("Share your calendar to others")}
                    </button>
                  </li>
                )}
                {state.calendarId && state.shareService && (
                  <li className="mt-auto">
                    <button
                      // className="b-none user-cal-btn"
                      onClick={() => viewRequestList()}
                    >
                      <MdOutlineRemoveRedEye className="svg-icons text-white mr-2" />
                      {t("View calendar request list")}
                    </button>
                  </li>
                )}
                {/* <li style={{ width: "17rem" }}>
                  <h5>Time zone</h5>
                  <Select
                    defaultValue={"Demo"}
                    options={state.timeZoneOptions}
                    onChange={(e) => updateState("timeZone", e)}
                    value={state.timeZone}
                    styles={{
                      option: (base) => ({
                        ...base,
                        fontSize: "12px",
                      }),
                      control: (base) => ({
                        ...base,
                        fontSize: "12px",
                      }),
                      groupHeading: (base) => ({
                        ...base,
                        flex: "1 1",
                        color: "white",
                        padding: "12px",
                        background: "black",
                      }),
                    }}
                    onMenuOpen={handleMenuOpen}
                    // components={{ Option: CustomOption }}
                  />
                </li> */}
              </ul>
              <ul className="d-flex flex-wrap g-1 p-0">
                <div className="calendar-op-menu">
                  <li
                    className={`p-2 ${state.view === 0 && "cardWrapper"}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => updateState("view", 0)}
                  >
                    <FaList className="svg-icons mr-2" />{" "}
                    {t("AVAILABILITY TIME")}
                  </li>
                  <li
                    className={`p-2 ${state.view === 1 && "cardWrapper"}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => updateState("view", 1)}
                  >
                    <BiCalendar className="svg-icons mr-2" /> {t("CALENDAR")}
                  </li>
                  <li
                    className={`p-2 ${state.view === 2 && "cardWrapper"}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => updateState("view", 2)}
                  >
                    <GrBusinessService
                      className="svg-icons mr-2"
                      style={{ width: "20px" }}
                    />{" "}
                    {t("SERVICES")}
                  </li>
                </div>
                {!state.shareService && (
                  <div className="position-relative mr-3">
                    <CheckBox
                      value={state.shareService}
                      label="Do you want to share your service and reach more people?"
                      onChange={(e) => {
                        setState((previous) => ({
                          ...previous,
                          // shareService:e,
                          calendarPlanModel: true,
                        }));
                        // updateState("shareService", e)
                      }}
                    />
                    {/* <p>Do you want share your service ?</p>{" "} */}
                    {/* <p className="f-12 text-secondary mb-1">
                    Service Details{" "}
                    {!state.bioEditState && (
                      <BiEdit
                        className="w-auto c-pointer ml-1"
                        style={{
                          height: "24px",
                        }}
                        onClick={() => updateState("bioEditState", true)}
                      />
                    )}
                  </p>
                  {state.bioEditState ? (
                    <>
                      <textarea
                        className="post-form-control post-pe-4 post-bg-light"
                        rows="1"
                        placeholder={t("About your service ...")}
                        type="text"
                        onChange={(e) => {
                          setState((previous) => ({
                            ...previous,
                            biography: e.target.value,
                            bioChangeMade: true,
                          }));
                        }}
                        value={state.biography}
                        name="biography"
                        style={{ minHeight: "8rem" }}
                      ></textarea>
                      {state.bioChangeMade && (
                        <button
                          className="w-100 mt-2 mb-2"
                          onClick={() => saveBioChanges()}
                        >
                          {t("SAVE CHANGES")}
                        </button>
                      )}
                    </>
                  ) : (
                    <div className="post-form-control post-pe-4   cal-text-area   text-align-center">
                      {state.biography ? (
                        <>{state.biography}</>
                      ) : (
                        <>
                          <MdLibraryAdd className="svg-icons" /> Add your
                          service details{" "}
                        </>
                      )}
                    </div>
                  )} */}
                  </div>
                )}
              </ul>
              <hr className="mb-0" />
              <div className="d-flex flex-wrap">
                {state.view === 1 ? (
                  <div style={{ flex: 1 }} className="p-4">
                    {/* <RazorPay
                      email={loginDetails.email}
                      name={loginDetails.dName}
                      userId={loginDetails.userId}
                      contactNumber={loginDetails.phoneNumber}
                      refId={state.calendarId}
                      amount={750}
                    /> */}
                    <SlotCalender
                      // shareUrl={shareUrl("day", state.calendarId)}
                      slot={state.weeklyHours}
                      changeMenu={(e) => updateState("view", e)}
                      // slot={state.calendarViewData}
                      activeMinutes={state.activeMinutes?.value}
                      // needToSaveCalendar={saveUserAvailability}
                      needToSaveCalendar={shareCalendar}
                      calendarId={state.calendarId}
                      isSubscribed={state.isSubscribed}
                      message={state.message}
                    />
                  </div>
                ) : state.view === 2 ? (
                  <>
                    <Services />
                  </>
                ) : (
                  <>
                    <section
                      style={{ flex: 1, overflow: "auto" }}
                      className="p-3 glass-shadow d-flex flex-column align-items-center"
                    >
                      {/* <h5 className="font-weight-bold d-flex mb-4 mr-auto">
                        Weekly hours{" "}
                        <button
                          className="ml-auto user-cal-btn"
                          onClick={() => saveDefaultSlot()}
                        >
                          <BiSave className="svg-icons text-black mr-1" />
                          Save Your Calender
                        </button>
                      </h5> */}
                      <div className="card box-shadow-v1 fit-content position-relative">
                        {state.calendarId && state.madeChanges && (
                          <div
                            className="cal-war"
                            onClick={() => saveUserAvailability()}
                          >
                            {t("SAVE CHANGES")}
                          </div>
                        )}
                        <section
                          className="p-3 text-center font-weight-bold"
                          style={{ borderBottom: "1px solid gray" }}
                        >
                          {t("CONFIGURE YOUR AVAILABILITY")}
                          <GrDocumentConfig className="svg-icons" />
                        </section>
                        <section className="p-3">
                          <WeeklyCalendar
                            weeklyHours={state.weeklyHours}
                            modiFyTimeSlot={modiFyTimeSlot}
                            activeMinutes={state.activeMinutes.value}
                            updateTimeSlots={updateTimeSlots}
                          />
                        </section>
                      </div>

                      {/* <ul
                        style={{ listStyle: "none" }}
                        className="g-2 d-flex flex-column"
                      >
                        {state.weeklyHours.map((item, i) => (
                          <li key={i} className="d-flex align-items-center g-1">
                            <input
                              style={{ cursor: "pointer" }}
                              type="checkbox"
                              value={item.timeSlots?.length > 0}
                              checked={item.timeSlots?.length > 0}
                              defaultChecked={item.timeSlots?.length > 0}
                              className="mb-auto mt-3"
                              onClick={() => modiFyTimeSlot("remove", i)}
                            />
                            <p
                              style={{ width: "4rem" }}
                              className="mb-auto mt-2"
                            >
                              {item.day}
                            </p>
                            <div className="d-flex flex-column g-1">
                              {item.timeSlots.map((time, t_i) => (
                                <div
                                  key={t_i}
                                  className="d-flex align-items-center"
                                >
                                  <DatePicker
                                    selected={
                                      new Date(`2024-01-01T${time.from}:00`)
                                    }
                                    onChange={(date) =>
                                      updateTimeSlots(i, "from", date, t_i)
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={state.activeMinutes.value}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    className="date-picker"
                                    minTime={minTime}
                                    maxTime={maxTime}
                                  />
                                  <span className="mr-4 ml-4"> - </span>
                                  <span className="box-dis">
                                    {moment(`2024-01-01T${time.from}:00`)
                                      .add(
                                        state.activeMinutes.value
                                          ? state.activeMinutes.value
                                          : 30,
                                        "minutes"
                                      )
                                      .locale("en")
                                      .format("LT")}
                                  </span>
                                   <DatePicker
                                    style={{ width: "fit-content" }}
                                    selected={
                                      new Date(`2024-01-01T${time.to}:00`)
                                    }
                                    onChange={(date) =>
                                      updateTimeSlots(i, "to", date, t_i)
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={state.activeMinutes.value}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    className="date-picker"
                                    placeholderText="Select end time"
                                    minTime={
                                      time.from
                                        ? new Date(`1970-01-01T${time.from}:00`)
                                        : minTime
                                    }
                                    maxTime={maxTime}
                                  />
                                  <span
                                    style={{ marginTop: "8px" }}
                                    className="mb-auto ml-4"
                                  >
                                    <IoCloseCircleOutline
                                      className="svg-icons"
                                      onClick={() =>
                                        modiFyTimeSlot("delete", i, t_i)
                                      }
                                    />
                                  </span>
                                </div>
                              ))}
                            </div>
                            {item.timeSlots.length === 0 && (
                              <p className="text-secondary mt-2">
                                Not available ....
                              </p>
                            )}
                            <IoIosAddCircleOutline
                              className="svg-icons mb-auto mt-2"
                              onClick={() => modiFyTimeSlot("add", i)}
                            />
                          </li>
                        ))}
                      </ul> */}
                    </section>
                    {/* <section
                      style={{
                        flex: 1,
                        borderLeft: "1px solid rgba(0, 0, 0, .1)",
                      }}
                      className="p-3"
                    >
                      <h5 className="font-weight-bold">Date-specific hours</h5>
                      <p
                        className="text-secondary"
                        style={{ fontSize: "14px" }}
                      >
                        Override your availability for specific dates when your
                        hours differ from your regular weekly hours.
                      </p>
                      <button
                        className="mt-3 user-cal-btn"
                        onClick={() => {
                          saveDefaultSlotForAction(true);
                          updateState("dateMenu", true);
                        }}
                      >
                        <IoIosAddCircleOutline
                          className="svg-icons-dir"
                          style={{ width: "24px" }}
                        />{" "}
                        Add date-specific hours
                      </button>
                      <ul className="l-none d-flex flex-column g-2 mt-3">
                        {state.dateSpecific.map((dtData, dti) => (
                          <li
                            key={dti}
                            className="slot-card d-flex justify-content-between "
                          >
                            <section style={{ width: "13rem" }}>
                              <span>
                                {moment(dtData?.dateSlot[0])
                                  .locale("en")
                                  .format("LL")}
                              </span>
                              {dtData.dateSlot.length >= 2 && (
                                <span>
                                  {" "}
                                  -{" "}
                                  {moment(
                                    dtData.dateSlot[dtData.dateSlot.length - 1]
                                  )
                                    .locale("en")
                                    .format("LL")}
                                </span>
                              )}
                              <button
                                className="mt-2 user-cal-btn"
                                onClick={() => {
                                  updateState("calendarViewData", dtData);
                                  updateState("view", 1);
                                }}
                              >
                                <HiOutlineEye
                                  className="svg-icons-dir mr-2 text-black"
                                  style={{ width: "24px" }}
                                />
                                View Calendar
                              </button>
                            </section>
                            <section className="d-flex flex-column align-items-center text-secondary g-1">
                              {dtData.timeSlots.map((tItem, ti) => (
                                <span key={ti} className="img-chip">
                                  {moment(`2024-01-01T${tItem.from}:00`)
                                    .locale("en")
                                    .format("LT")}{" "}
                                  -{" "}
                                  {moment(`2024-01-01T${tItem.from}:00`)
                                    .add(
                                      state.activeMinutes.value
                                        ? state.activeMinutes.value
                                        : 30,
                                      "minutes"
                                    )
                                    .locale("en")
                                    .format("LT")}
                                </span>
                              ))}
                            </section>
                            <section>
                              <IoCloseCircleOutline
                                className="svg-icons"
                                onClick={() =>
                                  removeDateTimeSlot(dti, dtData.id)
                                }
                              />
                            </section>
                          </li>
                        ))}
                      </ul>
                      {state.dateMenu && (
                        <DateAndSlot
                          activeMinutes={state.activeMinutes.value}
                          closeDatePicker={() => updateState("dateMenu", false)}
                          onSubmit={updateDateAndSlot}
                          id={state.calendarId}
                        />
                      )}
                    </section> */}
                  </>
                )}
                {/* {state.newScheduleModel && (
                  <Modal
                    show="true"
                    onHide={() => updateState("newScheduleModel", false)}
                  >
                    <Modal.Header>
                      New schedule
                      <AiOutlineCloseCircle
                        onClick={() => updateState("newScheduleModel", false)}
                        style={{
                          cursor: "pointer",
                          float: "right",
                          marginBottom: "8px",
                          width: "20px",
                          height: "20px",
                          color: "#000000",
                        }}
                      />
                    </Modal.Header>
                    <Modal.Body className="d-flex flex-column">
                      <label>Schedule Name</label>
                      <TextField
                        style={{ padding: "1rem" }}
                        type="scheduleName"
                        variant="outlined"
                        value={state.scheduleName}
                        placeholder="Working Hours,Exclusive Hours,etc ..."
                        onChange={(e) =>
                          updateState("scheduleName", e.target.value)
                        }
                      />
                      <button
                        className="mt-3"
                        onClick={() => createNewSchedule()}
                      >
                        Create New Schedule
                      </button>
                    </Modal.Body>
                  </Modal>
                )} */}
                {state.calendarPlanModel && (
                  <Dialog
                    fullWidth
                    maxWidth="md"
                    open={state.calendarPlanModel}
                    onClose={() => {}}
                  >
                    <div
                    // className="glass-shadow"
                    >
                      <DialogTitle className="font-weight-bold text-center">
                        {state.shareService
                          ? t("PREFERENCE")
                          : t("UPDATE YOUR PROFILE")}
                        <AiOutlineCloseCircle
                          onClick={() => closeCalendarModal()}
                          style={{
                            cursor: "pointer",
                            float: "right",
                            marginBottom: "8px",
                            width: "20px",
                            height: "20px",
                            color: "#000000",
                          }}
                        />
                      </DialogTitle>
                      <DialogContent dividers="paper">
                        <section className="d-flex align-items-center justify-content-center">
                          {state.shareService ? (
                            <CalendarPlans
                              loader={state.eventLoader}
                              plansList={plans}
                              subscriptionList={subscriptionList}
                              weeklyHours={state.weeklyHours}
                              modiFyTimeSlot={modiFyTimeSlot}
                              activeMinutes={state.activeMinutes.value}
                              updateTimeSlots={updateTimeSlots}
                              calendarId={state.calendarId}
                              slotDetails={state.slotDetails}
                              homeSlotState={state}
                              paymentModel={paymentModelStatus}
                              isSubscribed={state.isSubscribed}
                              message={state.message}
                              closeModel={updateModelDetails}
                              freeSubscribeCompleted={
                                state.freeSubscribeCompleted
                              }
                              planSelected={state.planSelected}
                              updateSlotDetails={updateSlotDetails}
                              // biography={state.biography}
                            />
                          ) : (
                            <div className="card  box-shadow-v1  flex-column d-flex align-items-center justify-content-center g-1 p-5">
                              <section className="d-flex  w-100">
                                <EditableTextFieldInsta
                                  label={`${t("Instagram")} *`}
                                  value={state.instagramUrl}
                                  onDataCheck={(key) =>
                                    updateState("validInstaUrl", key)
                                  }
                                  handleClickEdits={() => {}}
                                  onChange={(value) =>
                                    updateState("instagramUrl", value)
                                  }
                                  getSelectedValue={(value) =>
                                    updateState("instagramUrl", value)
                                  }
                                />
                              </section>
                              {/* <section className="d-flex">
                                {" "}
                                <EditableTextFieldFacebook
                                  label={`${t("FaceBook")} *`}
                                  value={state.faceBookUrl}
                                  onDataCheck={(key) =>
                                    updateState("validFaceBookUrl", key)
                                  }
                                  handleClickEdits={() => {}}
                                  onChange={(value) =>
                                    updateState("faceBookUrl", value)
                                  }
                                  getSelectedValue={(value) =>
                                    updateState("faceBookUrl", value)
                                  }
                                />
                              </section> */}

                              <section className="d-flex w-100">
                                {" "}
                                <EditableTextFieldLinkedIn
                                  label={`${t("LinkedIn")} *`}
                                  value={state.linkedInUrl}
                                  onDataCheck={(key) =>
                                    updateState("validlinkedInUrl", key)
                                  }
                                  handleClickEdits={() => {}}
                                  onChange={(value) =>
                                    updateState("linkedInUrl", value)
                                  }
                                  getSelectedValue={(value) =>
                                    updateState("linkedInUrl", value)
                                  }
                                />
                              </section>
                              <section className="d-flex w-100">
                                {" "}
                                <EditableTextFieldTwitter
                                  label={`${t("Twitter")} `}
                                  value={state.twitterUrl}
                                  onDataCheck={(key) =>
                                    updateState("validTwitterUrl", key)
                                  }
                                  handleClickEdits={() => {}}
                                  onChange={(value) =>
                                    updateState("twitterUrl", value)
                                  }
                                  getSelectedValue={(value) =>
                                    updateState("twitterUrl", value)
                                  }
                                />
                              </section>
                              <section className="w-100 editableTextWrapper formFieldGroup">
                                <label className="formLabel mb-0 c-pointer">
                                  Location *
                                </label>
                                <TextField
                                  placeholder={t("Delhi...")}
                                  style={{
                                    borderRadius: "10px",
                                    paddingLeft: "2px",
                                    marginBottom: "8px",
                                  }}
                                  fullWidth
                                  variant="outlined"
                                  type="text"
                                  required=""
                                  name="location"
                                  value={state.location}
                                  onChange={(e) =>
                                    updateState("location", e.target.value)
                                  }
                                />
                              </section>
                              <section className="editableTextWrapper formFieldGroup">
                                <label className="formLabel mb-0 c-pointer">
                                  Service details *
                                </label>
                                <textarea
                                  className="post-form-control post-pe-4 post-bg-light"
                                  rows="1"
                                  placeholder={t("About your service ...")}
                                  type="text"
                                  onChange={(e) => {
                                    setState((previous) => ({
                                      ...previous,
                                      biography: e.target.value,
                                    }));
                                  }}
                                  value={state.biography}
                                  name="biography"
                                  style={{ minHeight: "8rem" }}
                                ></textarea>
                              </section>

                              <button
                                className="w-100"
                                onClick={() => saveProfileChanges()}
                              >
                                Next{" "}
                                <MdDoubleArrow className="ml-1 text-white svg-icons" />
                              </button>
                            </div>
                          )}
                        </section>
                      </DialogContent>
                    </div>
                  </Dialog>
                )}
              </div>
            </>
          )}
          {/* Meeting schedule option */}
          {state.meetingDetails && state.meetingModel && (
            <Modal show="true" onHide={() => clearMeetingData()}>
              <Modal.Header>
                {!state.userCalendarUrl &&
                  `Schedule Meeting with ${
                    state.meetingDetails.userName
                  } on ${moment(state.meetingDetails.dateSlots[0])
                    .locale("en")
                    .format("LL")}`}
                <AiOutlineCloseCircle
                  onClick={() => clearMeetingData()}
                  className="ml-auto"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginBottom: "8px",
                    width: "20px",
                    height: "20px",
                    color: "#000000",
                  }}
                />
              </Modal.Header>
              <Modal.Body>
                {state.userCalendarUrl ? (
                  <div
                    className="d-flex align-items-center mb-2 mt-2 p-4"
                    style={{ gap: "1rem", background: "lightslategray" }}
                  >
                    <SocialShare
                      closeNotNeed={true}
                      url={getMeetingUrl(
                        "group",
                        state.userCalendarUrl,
                        state.meetingDetails.meetingInApplication
                      )}
                      title={"Check my available schedule"}
                      closeModel={() => updateState("userCalendarUrl", null)}
                    />
                  </div>
                ) : (
                  <div className="askAQuestionInputGroup">
                    <label className="formLabel">Meeting Title *</label>
                    <input
                      type="text"
                      name="meetingTitle"
                      value={state.meetingTitle}
                      maxLength="200"
                      onChange={(e) =>
                        updateState("meetingTitle", e.target.value)
                      }
                      placeholder="Hi , elsa join the meeting ..."
                    />
                    {!state.meetingInYuukke && (
                      <>
                        <label className="formLabel mt-3">
                          Add Meeting Link *
                        </label>

                        <input
                          type="text"
                          name="meetingLink"
                          placeholder="http://googlemeet.com/"
                          value={state.meetingLink}
                          onChange={(e) =>
                            updateState("meetingLink", e.target.value)
                          }
                        />
                      </>
                    )}
                    <CheckBox
                      value={state.meetingInYuukke}
                      label="Meeting in Yuukke"
                      onChange={(e) => updateState("meetingInYuukke", e)}
                    />
                    <LoaderButton
                      onClick={() => createMeeting()}
                      loaderState={state.btnLoader}
                    />
                    v
                  </div>
                )}
              </Modal.Body>
            </Modal>
          )}
          {state.calenderShareModel && (
            <Modal
              show="true"
              onHide={() => updateState("calenderShareModel", false)}
            >
              <Modal.Header className="font-weight-bold">
                <p>
                  Calendar Link{" "}
                  {state.message && state.isSubscribed && state.slotDetails && (
                    <>
                      <span className="badge badge-info">{state.message}</span>
                    </>
                  )}
                </p>
                <AiOutlineCloseCircle
                  onClick={() => updateState("calenderShareModel", false)}
                  className="ml-auto"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginBottom: "8px",
                    width: "20px",
                    height: "20px",
                    color: "#000000",
                  }}
                />
              </Modal.Header>
              <Modal.Body>
                <div
                  className="d-flex align-items-center mb-2 mt-2 p-4"
                  style={{
                    gap: "1rem",
                    background: "lightslategray",
                    borderRadius: "21px",
                  }}
                >
                  <SocialShare
                    closeNotNeed={true}
                    url={shareUrl(state.calendarId + "&id")}
                    title={"Check my available schedule"}
                    closeModel={() => updateState("userCalendarUrl", null)}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </div>
    </BlockUi>
  );
};

export default withTranslation()(UserCalendarContainer);
