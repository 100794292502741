import axios from "axios";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { API_URL } from "../config/app-config";
import logoDark from "../images/vcard.png";
import dummyimage from "../img/default_avatar.jpg";
import * as APPCONFIG from "../config/app-config.js";
import { RiErrorWarningLine, RiUserAddLine } from "react-icons/ri";
import { Paper } from "@material-ui/core";
import { useState } from "react";
import gmail from "../images/SocialMediaGif/gmail.gif";
import linkedin from "../images/SocialMediaGif/Linkedin.png";
import faceBook from "../images/SocialMediaGif/Facebook.png";
import twitter from "../images/SocialMediaGif/twitter.png";
import phoneCall from "../images/SocialMediaGif/phone.gif";
import yuukke from "../images/SocialMediaGif/yuukke-white.png";
import instagram from "../images/SocialMediaGif/instagram.png";

function Vcard({
  authenticated = false,
  accessToken = "",
  refreshToken = "",
  ...props
}) {
  const location = useLocation();
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [userDetail, setUserDetail] = React.useState(null);
  const [slug, setSlug] = React.useState("");
  const [flipped, setFlipped] = useState(false);
  // const [email, setEmail] = useState("");

  const userSlug = location.pathname;

  const handleClick = (event, email) => {
    window.location.href = `mailto:${email}`;
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClickPhone = (event, number) => {
    window.location.href = `tel:${number}`;
    event.preventDefault();
    event.stopPropagation();
  };
  const openSocialMedia = (event, url) => {
    window.open(url, "_blank");
    event.preventDefault();
    event.stopPropagation();
  };
  useEffect(() => {
    var main = document.getElementById("base");
    var body = document.getElementById("root");
    main.setAttribute("style", "background-color: black");
    body.setAttribute("style", "background-color: black");
    setSlug(userSlug.slice(userSlug.lastIndexOf("/") + 1) ?? "");
    const getarticleContent = async () => {
      if (slug !== "") {
        const response = await axios.get(`${API_URL}auth/vcard/${slug}`);
        setLoaderStatus(false);
        setUserDetail(response.data.data?.jobData);
      }
    };
    getarticleContent();
  }, [location.pathname, slug]);

  return (
    <div
      className="d-flex align-items-center justify-content-center "
      // style={{ height: "100vh" }}
    >
      {userDetail ? (
        <div class="card-profile">
          {!flipped ? (
            <>
              <div
                style={{ flex: 2, cursor: "pointer" }}
                onClick={() => setFlipped(!flipped)}
              >
                <div class="card-header-profile">
                  <img
                    src={logoDark}
                    class="header-logo-inner"
                    style={{
                      transform: flipped ? "rotateY(180deg)" : "rotateY(0)",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  />
                </div>
              </div>
              <div
                class="card-body-profile "
                onClick={() => setFlipped(!flipped)}
              >
                <div class="d-flex justify-content-center">
                  <div class="postuserImg-vcard">
                    <img
                      className="rounded-circle"
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      src={
                        API_URL +
                        "api/getUserPhoto/" +
                        userDetail?.userId +
                        "/" +
                        userDetail?.firstName
                      }
                      alt="name"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyimage;
                      }}
                    />
                  </div>
                  {/* <div class="avatar-profile">
                <a href="#!">
                  <img
                    class="avatar-img rounded border border-white border-3"
                    src={
                      API_URL +
                      "api/getUserPhoto/" +
                      userDetail?.userId +
                      "/" +
                      userDetail?.firstName
                    }
                    alt=""
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = dummyimage;
                    }}
                  />
                </a>
              </div> */}
                </div>
                <div class="text-center p-3">
                  <h5
                    class="mb-0"
                    style={{
                      color: "#14191e",
                      textTransform: "capitalize",
                    }}
                  >
                    {userDetail?.firstName
                      ? userDetail.firstName
                      : userDetail.lastName}
                  </h5>

                  <small class="dis-color">
                    {userDetail?.profession ? userDetail.profession : ""}
                  </small>
                  <div>
                    <small class="mt-2">connect with me </small>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-center mb-4 mt-2"
                    style={{ gap: "1rem" }}
                  >
                    <img
                      src={yuukke}
                      class="animi-icon"
                      onClick={
                        (e) =>
                          openSocialMedia(
                            e,
                            `${APPCONFIG.BASE_URL}yuukke-user/?&id=${userDetail?.userId}`
                          )
                        // window.open(userDetail?.twitterUrl, "_blank")
                      }
                    />
                    {userDetail?.linkedUrl ? (
                      <img
                        src={linkedin}
                        class="animi-icon"
                        onClick={
                          (e) => openSocialMedia(e, userDetail?.linkedUrl)
                          // window.open(userDetail?.twitterUrl, "_blank")
                        }
                      />
                    ) : (
                      ""
                    )}
                    {userDetail?.twitterUrl ? (
                      <img
                        src={twitter}
                        class="animi-icon"
                        onClick={
                          (e) => openSocialMedia(e, userDetail?.twitterUrl)
                          // window.open(userDetail?.twitterUrl, "_blank")
                        }
                      />
                    ) : (
                      ""
                    )}

                    {userDetail?.facebookUrl ? (
                      <img
                        src={faceBook}
                        class="animi-icon"
                        onClick={
                          (e) => openSocialMedia(e, userDetail?.facebookUrl)
                          // window.open(userDetail?.twitterUrl, "_blank")
                        }
                      />
                    ) : (
                      ""
                    )}
                    {userDetail?.instagramUrl && (
                      <img
                        src={instagram}
                        class="animi-icon"
                        onClick={
                          (e) => openSocialMedia(e, userDetail?.instagramUrl)
                          // window.open(userDetail?.twitterUrl, "_blank")
                        }
                      />
                    )}
                  </div>
                </div>
                <div class="d-flex mb-2">
                  {userDetail?.mobileNumber !== null &&
                    userDetail?.mobileNumber !== "" && (
                      <>
                        <div class="option-profile my-element">
                          <img
                            style={{ width: "22px", height: "22px" }}
                            src={phoneCall}
                            onClick={(e) =>
                              handleClickPhone(e, userDetail?.mobileNumber)
                            }
                          />
                          {/* <FiPhoneCall class="bg-color" /> */}
                        </div>

                        <div
                          class="ml-3 mr-5 my-element"
                          onClick={(e) =>
                            handleClickPhone(e, userDetail?.mobileNumber)
                          }
                        >
                          {userDetail?.mobileNumber}
                          <p class="small-font">Personal</p>
                        </div>
                      </>
                    )}
                </div>
                <div class="d-flex mb-4">
                  <div class="option-profile1 my-element">
                    <img
                      src={gmail}
                      style={{ width: "28px", height: "28px" }}
                      onClick={(e) => handleClick(e, userDetail?.emailId)}
                    />
                  </div>
                  <div
                    class="ml-3 mr-5 my-element"
                    onClick={(e) => handleClick(e, userDetail?.emailId)}
                  >
                    {userDetail?.emailId}
                    <p class="small-font">Personal</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div onClick={() => setFlipped(!flipped)} class="qr-card">
                <img
                  style={{
                    transform: flipped ? "rotateY(180deg)" : "rotateY(0)",
                    transition: "transform 0.3s ease-in-out",
                  }}
                  src={
                    `${APPCONFIG.API_URL}auth/image/${userDetail.vcfUuid}`
                      ? `${APPCONFIG.API_URL}auth/image/${userDetail.vcfUuid}`
                      : logoDark
                  }
                  class={flipped ? "qr-present" : "header-logo-inner"}
                />
              </div>
              <hr class="m-0" />
            </>
          )}

          <div style={{ flex: 1 }}>
            <div
              class="d-flex justify-content-center text-dark p-3"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  `${APPCONFIG.API_URL}auth/vcardfile/${userDetail.fileName}`
                )
              }
            >
              <span>
                <RiUserAddLine class="mr-2" />
                Add to contact
              </span>
            </div>
          </div>
        </div>
      ) : !loaderStatus ? (
        <Paper>
          <p class="p-5">
            <RiErrorWarningLine
              style={{
                color: "#A00030",
                width: "25px",
                height: "25px",
                marginRight: "6px",
              }}
            />
            There is some problem in getting content.Please contact an
            administrator ...
          </p>
        </Paper>
      ) : (
        <div
          class="spinner-border text-info"
          style={{ width: "3rem", height: "3rem" }}
        ></div>
      )}
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    userDetails: state.loginDetailsApi,
    friendsList: state.commonApi.chatFriendslist,
    navigationType: state.commonApi.navigationType,
    selectedUser: state.chatList.selectedUser,
    authenticated: state.loginDetailsApi.keycloakAuthenticated,
    refreshToken: state.loginDetailsApi.refreshToken,
    accessToken: state.loginDetailsApi.accessToken,
    userUid: state.loginDetailsApi.loginDetails?.uuid,
  };
};

export default connect(mapStateToProps, null)(Vcard);
