import React, { Component } from "react";
import {
  Button,
  Form,
  Modal,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap";
import CSVReader from "react-csv-reader";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import MyYuukke from "../api/MyYuukke";

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};
class CsvUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: null,
      userList: [],
      page: "upload",
      response: null,
    };
  }
  render() {
    const { t } = this.props;
    return (
      <Modal show="true" onHide={this.closeInvitePage}>
        <Form id="profileForm">
          <Modal.Body>
            <Form.Group className="formInputs">
              <Form.Label style={{ fontWeight: "bold" }}>
                {this.state.page == "result" ? (
                  <>Updated Users Report</>
                ) : (
                  <>Upload Users Csv</>
                )}
              </Form.Label>
            </Form.Group>
            <div className="d-flex align-items-center justify-content-center">
              {this.state.page == "result" ? (
                <div className="d-flex flex-column">
                  <p>Added User Count : {this.state.response?.added?.length}</p>
                  <p>
                    Invited User Count : {this.state.response?.invited?.length}
                  </p>
                </div>
              ) : (
                <>
                  {" "}
                  {this.state.fileName ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <p style={{ color: "gray" }}>{this.state.fileName} </p>
                      <span class="badge badge-pill badge-warning ml-2">
                        {this.state.userList?.length == 1
                          ? `1 User present on this csv file`
                          : ` ${this.state.userList?.length} Users present on this csv file`}
                      </span>
                    </div>
                  ) : (
                    <>
                      <CSVReader
                        onDrop={this.handleDrop}
                        cssClass="csv-button"
                        parserOptions={papaparseOptions}
                        onFileLoaded={(data, fileInfo) =>
                          this.loadCSVdata(data, fileInfo)
                        }
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex" style={{ gap: "1rem", width: "100%" }}>
              <a
                className="underline-text mt-2"
                target="_blank"
                href={this.props.exCsvFile}
              >
                Download sample file
              </a>

              <Button
                variant="secondary"
                style={{
                  color: "#ffffff",
                  marginLeft: "auto",
                }}
                onClick={this.closeInvitePage}
              >
                {t("Close")}
              </Button>
              <Button
                variant="secondary"
                style={{ color: "#ffffff", marginRight: "12px" }}
                onClick={this.uploadCsv}
              >
                {t("Send  ")}
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
  closeInvitePage = () => {
    this.props.closeCsvModel();
  };
  loadCSVdata = (data, fileInfo) => {
    var ndata = [];
    for (var i = 0; i < data.length; i++) {
      var obj = data[i];
      ndata.push(obj);
    }
    this.setState({
      fileName: fileInfo?.name,
      userList: ndata,
    });
    console.log("ndata", ndata);
    console.log("ndata fileData", ndata);
  };
  handleDrop = (data, fileInfo) => {
    var ndata = [];
    for (var i = 0; i < data.length; i++) {
      var obj = data[i];
      ndata.push(obj);
    }
    this.setState({
      fileName: fileInfo?.name,
      userList: ndata,
    });
    console.log("ndata", ndata);
    console.log("ndata fileData", ndata);
  };
  uploadCsv = (e) => {
    e.preventDefault();
    if (!this.state.userList || this.state.userList?.length === 0) {
      toast.warning("Select CSV file");
    } else {
      console.log("csv", this.state.userList);
      var search = {
        csvWorkSpaceUser: this.state.userList,
      };

      MyYuukke.csv_work_space_add_user(search)
        .then((response) => {
          this.setState({
            fileName: null,
            page: "result",
            response: response.data.data,
          });
          this.props.onUploadComplete();
          toast.success("Csv uploaded successfully");
        })
        .catch(console.log("error"));
    }
  };
}
export default withTranslation()(CsvUpload);
