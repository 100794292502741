import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
// import AuthenticationService from "../../api/AuthenticationService ";
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Link } from "@material-ui/core";
import ProfileService from "../../api/ProfileService";
import { toast, ToastContainer } from "react-toastify";
import { JITSI_URL } from "../../config/app-config";
import { useSelector } from "react-redux";
import { generateUlr } from "../../utils";

function MentorMeeting({
  mentorId,
  showModalScreen,
  receiverId,
  receiverName,
  mentor,
  handleClose,
  handleModalScreenVisibility,
}) {
  const [showMeetingScreen, setShowMeetingScreen] = React.useState(false);
  const [meetingType, setMeetingType] = React.useState("Instant");
  const [loginedUser, setLoginedUser] = React.useState(null);
  const [meetingLink, setMeetingLink] = React.useState("");
  const [meetingURL, setMeetingURL] = React.useState(null);
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const loginDetails = useSelector((data) => data.loginDetailsApi);

  console.log(showModalScreen);
  console.log(mentor);
  console.log(meetingType);

  React.useEffect(() => {
    if (!loginedUser) {
      // console.log(AuthenticationService.getLoginValue());
      setLoginedUser(mentor);
    }
  }, []);

  const handleSubmit = async () => {
    if (name !== "") {
      try {
        console.log(loginedUser);

        if (loginedUser) {
          const config = {
            headers: {
              "Content-type": "application/json",
            },
          };

          const instantMeetingLink = `Yuukke-${Math.random() * 100}-${
            loginedUser?.uId
          }-${Date.now()}`;

          const data = {
            receiverId: receiverId,
            senderId: loginedUser?.uId,
            senderName: loginedUser?.displayName
              ? loginedUser?.displayName
              : `${loginedUser?.fName} ${loginedUser?.lName}`,
            message: {
              meeting: {
                meetingName: name,
                meetingDescription: desc,
                meetingLink: instantMeetingLink,
                meetingType: "Instant",
                meetingTime: selectedDate,
                refId: mentorId,
              },
            },
            isMeeting: true,
          };

          console.log(data);

          ProfileService.mentorMeeting(data)
            .then((response) => {
              console.log("response", response.data);
              if (response.data.status === "success") {
                setShowMeetingScreen(true);
                setMeetingLink(instantMeetingLink);
                setMeetingURL(`${instantMeetingLink}?authenticated=true`);
                toast.success("Meeting created successfully");
                //   handleModalScreenVisibility(false)
              }
            })
            .catch((error) => {
              console.log(error);
              toast.error("Saving Error");
            });

          // const request = await Axios.post(
          //           `https://chat.yuukke.betalearnings.com/api/message/groupCall`,
          //           data,
          //           config
          //         );
          // const response = await request.data;

          // if(meetingType === 'scheduled'){
          //     console.log("Schduled")
          //     console.log("Date", selectedDate)
          //     const newData = users.map(({ userId, ...others }) => ({
          //         receiverId: userId,
          //         senderId: loginedUser?.user.userId,
          //         senderName: loginedUser?.user?.userProfile?.displayName
          //           ? loginedUser?.user?.userProfile?.displayName
          //           : `${loginedUser?.user?.userProfile?.firstName} ${loginedUser?.user?.userProfile?.lastName}`,
          //         message: {
          //           meeting: {
          //             meetingName : name,
          //             meetingDescription : desc,
          //             meetingLink: instantMeetingLink,
          //             meetingType: "Scheduled",
          //             meetingTime: selectedDate
          //           },
          //         },
          //         isMeeting: true,
          //       }));
          //       const request = await Axios.post(
          //         `${CHAT_URL}/api/message/groupCall`,
          //         newData,
          //         config
          //       );
          //       const response = await request.data;
          //       console.log(response)
          //       if (response.status === "success") {
          //         setShowMeetingScreen(true);
          //         setMeetingLink(instantMeetingLink)
          //         setMeetingURL(`${instantMeetingLink}?authenticated=true&userId=${loginedUser?.user.userId}`)
          //       //   handleModalScreenVisibility(false)
          //       }
          // }
          // else{
          //     const newData = users.map(({ userId, ...others }) => ({
          //         receiverId: userId,
          //         senderId: loginedUser?.user.userId,
          //         senderName: loginedUser?.user?.userProfile?.displayName
          //           ? loginedUser?.user?.userProfile?.displayName
          //           : `${loginedUser?.user?.userProfile?.firstName} ${loginedUser?.user?.userProfile?.lastName}`,
          //         message: {
          //           meeting: {
          //             meetingName : name,
          //             meetingDescription : desc,
          //             meetingLink: instantMeetingLink,
          //             meetingType: "Instant",
          //             meetingTime: new Date()
          //           },
          //         },
          //         isMeeting: true,
          //       }));
          //       const request = await Axios.post(
          //         `${CHAT_URL}/api/message/groupCall`,
          //         newData,
          //         config
          //       );
          //       const response = await request.data;
          //       if (response.status === "success") {
          //         setShowMeetingScreen(true);
          //         setMeetingLink(instantMeetingLink)
          //         setMeetingURL(`${instantMeetingLink}?authenticated=true&userId=${loginedUser?.user.userId}`)
          //       //   handleModalScreenVisibility(false)
          //       }
          // }
          // const obj = {}
          // obj.userId = loginedUser?.user.userId;
          // obj.senderName = loginedUser?.user?.userProile?.displanyName ? loginedUser?.user?.userProile?.displanyName : `${loginedUser?.user?.userProile?.firsName} ${loginedUser?.user?.userProile?.lastName}`
          // obj.receiverId = user.userId
        }
      } catch (err) {
        console.log("%c ERROR IN POSTING METTING LINK : ", "color:red", err);
      }
    } else {
      toast.warning("Enter meeting Name");
    }
  };

  const handleMeeting = (e) => {
    setMeetingType(e.target.value);
  };

  const handlecloseModal = (e) => {
    e.preventDefault();
    handleModalScreenVisibility(false);
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      <Dialog
        fullWidth="md"
        open={showModalScreen}
        onClose={handleClose}
        disableBackdropClick
      >
        {!showMeetingScreen ? (
          <>
            <DialogTitle>Create Meeting</DialogTitle>
            <DialogContent>
              <FormControl style={{ width: "100%" }}>
                <TextField
                  onChange={(e) => setName(e.target.value)}
                  defaultValue=""
                  fullWidth={true}
                  id="meetingName"
                  type="text"
                  label="Meeting Name"
                  variant="filled"
                />
                <TextField
                  onChange={(e) => setDesc(e.target.value)}
                  fullWidth={true}
                  style={{ marginTop: 20 }}
                  multiline
                  rows={2}
                  id="meetingDesc"
                  variant="filled"
                  type="text"
                  label="Meeting Description"
                />
                {/* <InputLabel htmlFor="outlined-age-native-simple">
                  Meeting Type
                </InputLabel> */}
                {/* <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={meetingType}
                  label="Meeting Type"
                  onChange={handleMeeting}
                  displayEmpty
                  style={{ marginTop: 20 }}
                  // variant="outlined"
                  variant="filled"
                  disabled
                  // className={classes.selectEmpty}
                >
                  <MenuItem value="" disabled>
                    Select Meeting Type
                  </MenuItem>
                  <MenuItem value="instant">Instant Meeting</MenuItem>
                  <MenuItem value="scheduled">Scheduled Meeting</MenuItem>
                </Select> */}
                {meetingType === "scheduled" && (
                  //   <TextField
                  //     style={{ marginTop: 20 }}
                  //     id="datetime-local"
                  //     label="Meeting Time"
                  //     type="datetime-local"
                  //     // defaultValue={{dateTime}}
                  //     // className={classes.textField}
                  //     InputLabelProps={{
                  //       shrink: true,
                  //     }}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      style={{ marginTop: 20 }}
                      label="DateTimePicker"
                      inputVariant="filled"
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} style={{ color: "#ffffff" }}>
                Cancel
              </Button>
              <Button onClick={handleSubmit} style={{ color: "#ffffff" }}>
                Create Meeting
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            {/* <DialogTitle>JOIN MEETING</DialogTitle> */}
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "4rem 0rem",
                }}
              >
                {meetingType === "Instant" ? (
                  <>
                    <p>Join Meeting By clicking the Below Button</p>
                    <Button
                      variant="contained"
                      style={{
                        marginTop: "2rem",
                        color: "white",
                        background: "white",
                      }}
                      color="secondary"
                    >
                      <a
                        onClick={() =>
                          generateUlr(
                            `${JITSI_URL}${meetingURL}?authenticated=true&sp=yes`
                          )
                        }
                        // href={`${JITSI_URL}/${meetingURL}#config`}
                        // href={`http://meeting.jitsi.betalearnings.com/${meetingURL}`}
                        target="_blank"
                      >
                        Join Meeting
                      </a>
                    </Button>
                  </>
                ) : (
                  <>
                    <p>view the Meeting Details by clicking the below Button</p>
                    <Button
                      variant="contained"
                      style={{ marginTop: "2rem" }}
                      color="secondary"
                    >
                      <Link href="/meeting">View Meeting Details</Link>
                    </Button>
                  </>
                )}
                <Button
                  style={{ marginTop: "2rem", width: "100%" }}
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  close
                </Button>
              </div>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
}

export default MentorMeeting;
