import React, { Component } from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SearchPeopleCard from "../../../components/SearchPeopleCardV2";

import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import AuthService from "../../../session/AuthService";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
const styleArrow = {
  width: 42,
  height: 42,
  boxShadow: "0px 3px 6px #00000029",
  border: "1px solid #DEDEDF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
  borderRadius: "100%",
  cursor: "pointer",
  position: "absolute",
  top: "35%",
  zIndex: 10,
};
function NextArrow(props) {
  const { className, style, onClick, currentSlide, slideCount } = props;
  return currentSlide !== slideCount - 3 ? (
    <div style={{ ...styleArrow, right: "5%" }} onClick={onClick}>
      <ArrowForwardIcon />
    </div>
  ) : (
    <></>
  );
}

function PrevArrow(props) {
  const { className, style, onClick, currentSlide } = props;
  return currentSlide !== 0 ? (
    <div style={{ ...styleArrow, left: "5%" }} onClick={onClick}>
      <ArrowBackIcon />
    </div>
  ) : (
    <></>
  );
}
class SearchPeoplesCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recommendedFriendsList: [],
      recommendedFriendsListState: false,
    };
    this.getRecommendedFriendsList = this.getRecommendedFriendsList.bind(this);
    this.requestSentPage = this.requestSentPage.bind(this);
    this.gotoSuggestedFriendsList = this.gotoSuggestedFriendsList.bind(this);
    this.ignoreSentPage = this.ignoreSentPage.bind(this);
    this.ClearAll = this.ClearAll.bind(this);
    this.pageNo = this.pageNo.bind(this);
  }

  componentDidMount() {
    this.getRecommendedFriendsList(
      this.props.recommended_friends_list,
      this.props.recommendedListLength
    );
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.recommended_friends_list !== this.props.recommended_friends_list
    ) {
      this.getRecommendedFriendsList(
        this.props.recommended_friends_list,
        this.props.recommendedListLength
      );
    }
  }
  ClearAll() {
    this.props.ClearAll();
  }
  render() {
    const { t } = this.props;
    const settings = {
      infinite: false,
      slidesToShow: 5,
      speed: 500,
      arrows: true,
      draggable: true,
      dots: false,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            draggable: true,
            dots: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
            draggable: true,
            dots: false,
          },
        },
        {
          breakpoint: 595,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            draggable: true,
            dots: false,
          },
        },
      ],
    };

    return (
      <div className="peopleCarouselWrapper">
        <div className="searchHeading flexDefault flexAlignCenter flexJustifySpacebetween flex-wrap">
          <h3
            className="listHomeTitle"
            // style={{ marginLeft: "6px", marginTop: "12px" }}
          >
            {`${t("Search Results")} (0 - ${this.props.totalcount})`}
          </h3>

          <div
            style={{ cursor: "pointer", display: "inline-flex" }}
            onClick={this.ClearAll}
          >
            {t("Clear all result")}
          </div>
        </div>
        <Grid container spacing={2}>
          {!this.state.recommendedFriendsListState &&
            this.state.recommendedFriendsList.map((people, index) => {
              return (
                <Grid item xs={12} sm={12} md={3} lg={3} key={index}>
                  <React.Fragment key={index}>
                    <SearchPeopleCard
                      userId={people.uId}
                      name={`${people.fName} ${people.lName}`}
                      Dname={people.userName}
                      //curriculum={people.biography !==null?people.biography:"--------"}
                      tagsValue={
                        people.tagsValue.length !== 0
                          ? people.tagsValue
                          : "---------"
                      }
                      profession={
                        people.professionName != null
                          ? people.professionName
                          : "--------"
                      }
                      type={people.type}
                      countryName={
                        people.countryName !== null
                          ? people.countryName
                          : "--------"
                      }
                      nameLocation={
                        people.location !== null ? people.location : "--------"
                      }
                      // subjects={people.userLearn}
                      coverPhoto={people.coverPhoto}
                      ClearAll={this.ClearAll}
                      profilePhoto={people.profilePhoto}
                      requestSent={this.requestSentPage}
                      ignoreSent={this.ignoreSentPage}
                      searchData={this.props.searchData}
                      premiumData={people?.premiumData}
                    />
                  </React.Fragment>
                </Grid>
              );
            })}

          {this.state.recommendedFriendsListState && (
            <div className="no-data">
              <center>
                <p>{t("No Results Found")}</p>
              </center>
            </div>
          )}
        </Grid>
        {!this.state.recommendedFriendsListState &&
          this.props.pagination - 1 != this.props.startPage && (
            <div
              style={{
                margin: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button onClick={this.pageNo}>{t("See more")}</button>
            </div>
          )}
        {this.props.pagination - 1 == this.props.startPage && (
          <div
            style={{
              margin: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <center>
              <p>{t("No More Details")}</p>
            </center>
          </div>
        )}
      </div>
    );
  }
  pageNo() {
    this.props.searchPagination(1);
  }
  ignoreSentPage() {
    this.props.ignoreSentPageData();
  }

  requestSentPage() {
    this.props.requestSentPageData();
  }

  async getRecommendedFriendsList(
    recommended_friends_list,
    recommendedListLength
  ) {
    if (recommended_friends_list.length > 0) {
      await this.setState({
        recommendedFriendsList: recommended_friends_list,
        recommendedFriendsListState: false,
        recommendedListLengths: recommendedListLength,
      });
    } else {
      await this.setState({
        recommendedFriendsListState: true,
        recommendedListLengths: recommendedListLength,
      });
    }
  }

  gotoSuggestedFriendsList() {
    this.props.history.push({
      pathname: "/square",
    });
  }
}

export default withTranslation()(withRouter(SearchPeoplesCarousel));
