const CheckBoxComponent = (props) => {
  const {
    check_box_id,
    check_box_name,
    check_box_label,
    check_box_value,
    isChecked,
    handleCheckBoxChange,
  } = props;
  return (
    <div>
      <input
        className="settingsCheckBox"
        type="checkbox"
        id={check_box_id}
        name={check_box_name}
        value={check_box_value}
        checked={isChecked}
        onChange={() => {
          handleCheckBoxChange(check_box_id);
        }}
      />
      <label htmlFor={check_box_id} className="lh-copy">
        &nbsp;{check_box_label}
      </label>
    </div>
  );
};

CheckBoxComponent.defaultProps = {
  isChecked: false,
};

export default CheckBoxComponent;
