import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import React from "react";

const CustomSelect = ({ label, value, error, onChange, required, data }) => {
  return (
    <div className=" col-12 col-md-6 col-lg-6 mt-3">
      <label>
        {label} {required && "*"}
      </label>
      <FormControl variant="outlined" fullWidth>
        <Select name="" value={value} onChange={(e) => onChange(e)} label="">
          {data.map((item, index) => (
            <MenuItem value={item}>
              <em>{item}</em>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
CustomSelect.defaultProps = {
  label: "",
  type: "text",
  value: " ",
  error: false,
  required: false,
  data: [],
};

export default CustomSelect;
