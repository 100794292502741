import React, { Component, useState } from "react";
import AuthService from "../../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import * as APPCONFIG from "../../../config/app-config.js";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import "./groupdesign.css";
import { API_URL } from "../../../config/app-config";
import dummyimage from "../../../img/Yuukke_Cover.jpg";
import { FiUserPlus } from "react-icons/fi";
import { Button, Form, Modal } from "react-bootstrap";
import { IoIosLogOut } from "react-icons/io";
import { FiFastForward } from "react-icons/fi";
import { FiUpload } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import MyYuukke from "../../../api/MyYuukke";
import Swal from "sweetalert2";
import {
  getAllMyGroupEvents,
  getAllSubjectList,
  getMyGroupsByMembersandMessages,
} from "../../../redux/actions/subjectActions";
import { giveUserAction, readOnlyWorkspace } from "../../../utils";
import {
  getAllUserCommunityList,
  needPhotVerification,
} from "../../../redux/actions/commonApiActions";
import SocialShare from "../../../components/SocialShare.jsx";
import { FaShareAlt } from "react-icons/fa";

const currentDate = new Date().toString();
class GroupCardBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.match.params.userId,
      tabIndex: 0,
      pageNo: 0,
      friendsList: [],
      userKnowledgepoint: "",
      coverPhotoImage: "",
      userPhotoImage: "",
      areaOfInterest: [],
      userName: "",
      country_value: "",
      biography: "",
      professionID: "",
      dob: "",
      location: "",

      openAbout: false,
      openPost: true,
      openConnection: false,
      userPostList: [],
      invitePopup: false,
      items: [],
      emailVerification: "",
      exitPopup: false,
      shareCommunityModel: false,
    };
    this.ApplyForm = this.ApplyForm.bind(this);
    this.inviteUser = this.inviteUser.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.closeInvitePage = this.closeInvitePage.bind(this);
    this.emailInvite = this.emailInvite.bind(this);
    this.exit = this.exit.bind(this);
    this.popUpExit = this.popUpExit.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
    });
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    if (
      this.props.grouporeventFiles == undefined ||
      this.props.grouporeventFiles.length == 0
    ) {
      let fileList = [];

      fileList.push(dummyimage);
      this.setState({ fileData: fileList });
    } else {
      this.getMessageFiles(this.props.grouporeventFiles, this.props.fileType);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.grouporeventFiles !== nextProps.grouporeventFiles) {
      if (
        nextProps.grouporeventFiles == undefined ||
        nextProps.grouporeventFiles.length == 0
      ) {
        let fileList = [];

        fileList.push(dummyimage);
        this.setState({ fileData: fileList });
      } else {
        this.getMessageFiles(nextProps.grouporeventFiles, nextProps.fileType);
      }
    }
    //  this.getMessageFiles(nextProps.grouporeventFiles, nextProps.fileType)
    // this.getTagList(nextProps.tagListId)
  }

  render() {
    const { t, fromWhichCard } = this.props;

    const { tabIndex } = this.state;

    return (
      <>
        <div class="groupCard">
          <div class="groupCard-body">
            <div class="groupCard-d-md-flex groupCard-flex-wrap groupCard-align-items-start groupCard-text-center groupCard-text-md-start">
              <div class="groupCard-mb-2">
                <div class="groupCard-avatar groupCard-avatar-xl">
                  {/* <img style={{ width: "140px", height: "50px" }} class="groupCard-avatar-img groupCard-border-0" src={dummyimage} alt="" /> */}
                  {this.state.fileData &&
                    this.state.fileData.slice(0, 1).map((photo) => (
                      <img
                        alt="img"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = dummyimage;
                        }}
                        src={photo}
                        style={{
                          width: "140px",
                          height: "50px",
                          objectFit: "cover",
                        }}
                        class="groupCard-avatar-img groupCard-border-0"
                      />
                    ))}
                </div>
              </div>

              <div class="groupCard-ms-md-4">
                <h1 class="h5 mb-0 groupCard-h1">
                  {this.props.subjectName}{" "}
                  <i class="bi bi-patch-check-fill groupCard-text-success small"></i>
                </h1>
                {(fromWhichCard == "learningAppDesription" ||
                  fromWhichCard == "challengeDescription") && (
                  <ul class="groupCard-nav groupCard-nav-divider justify-content-center groupCard-justify-content-md-start">
                    <li class="groupCard-nav-item groupCardStatus">
                      {fromWhichCard == "learningAppDesription" && (
                        <>{t("Learning Hub")}</>
                      )}
                      {fromWhichCard == "challengeDescription" && (
                        <>
                          {" "}
                          {this.props.careerLabType === 0
                            ? t("Career Labs")
                            : this.props.careerLabType === 1
                            ? t("Course")
                            : t("Internship")}
                        </>
                      )}
                    </li>
                  </ul>
                )}

                {(fromWhichCard == "groupscard" ||
                  fromWhichCard == "mygroupscard") && (
                  <ul class="groupCard-nav groupCard-nav-divider justify-content-center groupCard-justify-content-md-start">
                    <li
                      class="groupCard-nav-item groupCardStatus"
                      style={{ textTransform: "capitalize" }}
                    >
                      {" "}
                      {this.props.status} {t("community")}{" "}
                    </li>
                    <li class="groupCard-nav-item groupCardStatus">
                      {" "}
                      {this.props.totalCommunityUser}{" "}
                      {this.props.allMembersList?.length === 1
                        ? " " + t("member")
                        : " " + t("members")}
                    </li>
                  </ul>
                )}
              </div>
              <div
                style={{ float: "right" }}
                class="groupd-desc-d-flex groupd-desc-justify-content-center groupd-desc-justify-content-md-start groupd-desc-align-items-center groupd-desc-mt-3 groupd-desc-ms-lg-auto"
              >
                {(this.props.status === "challenges" ||
                  this.props.status === "resource") &&
                  // (this.props.userId === this.props.loginDetails.uId ||
                  (this.props.loginUserType === 12 ||
                    this.props.loginUserRole === 1 ||
                    this.props.loginUserRole === 2) &&
                  this.props.groupWorkspace !== 0 && (
                    <div
                      className="postMoreInfo"
                      style={{ cursor: "pointer", float: "right" }}
                    >
                      <MoreHorizIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          this.handleMenuClick(e);
                        }}
                      />{" "}
                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.menuState}
                        keepMounted
                        open={this.state.menuState}
                        onClose={this.handleMenuClose}
                      >
                        <div
                          className="menuCard"
                          onClick={(e) =>
                            this.editCareerLab(
                              e,
                              this.props.subjectId,
                              this.props.status
                            )
                          }
                        >
                          <Edit
                            style={{ cursor: "pointer", marginTop: "6px" }}
                          />{" "}
                          <MenuItem>{t("Edit")}</MenuItem>
                        </div>
                        <div
                          className="menuCard"
                          onClick={(e) =>
                            this.deleteCommunity(e, this.props.subjectId)
                          }
                        >
                          <Delete
                            style={{ cursor: "pointer", marginTop: "6px" }}
                          />{" "}
                          <MenuItem>{t("Delete")}</MenuItem>
                        </div>
                      </Menu>
                    </div>
                  )}

                {(this.props.status === "protected" ||
                  this.props.status === "public" ||
                  this.props.status === "private") &&
                  this.props.joinedGroup && (
                    <div>
                      {(this.props.userId === this.props.loginDetails.uId ||
                        this.props.loginUserType === 12 ||
                        this.props.loginUserRole !== 0) && (
                        <div
                          className="postMoreInfo"
                          style={{ cursor: "pointer", float: "right" }}
                        >
                          <MoreHorizIcon
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                              this.handleMenuClick(e);
                            }}
                          />{" "}
                          <Menu
                            id="simple-menu"
                            anchorEl={this.state.menuState}
                            keepMounted
                            open={this.state.menuState}
                            onClose={this.handleMenuClose}
                          >
                            <div
                              className="menuCard"
                              onClick={(e) =>
                                this.editGroup(e, this.props.subjectId)
                              }
                            >
                              <Edit
                                style={{ cursor: "pointer", marginTop: "6px" }}
                              />{" "}
                              <MenuItem>{t("Edit")}</MenuItem>
                            </div>
                            <div
                              className="menuCard"
                              onClick={(e) =>
                                this.deleteCommunity(e, this.props.subjectId)
                              }
                            >
                              <Delete
                                style={{ cursor: "pointer", marginTop: "6px" }}
                              />{" "}
                              <MenuItem>{t("Delete")}</MenuItem>
                            </div>

                            <div
                              className="menuCard align-items-center"
                              onClick={(e) => {
                                this.setState({
                                  shareCommunityModel: true,
                                  menuState: false,
                                });
                              }}
                            >
                              <FaShareAlt style={{ cursor: "pointer" }} />{" "}
                              <MenuItem>{t("Share")}</MenuItem>
                            </div>
                          </Menu>
                        </div>
                      )}
                      {this.props.userId !== this.props.loginDetails.uId && (
                        <div
                          className="postMoreInfo"
                          style={{
                            margin: "8px",
                            cursor: "pointer",
                            float: "right",
                          }}
                        >
                          <button
                            onClick={this.exit}
                            className="btn mb-0 connectionSumbitLabel"
                          >
                            <IoIosLogOut
                              style={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                                color: "#ffffff",
                              }}
                            />{" "}
                            {t("Exit")}
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                {this.props.status == "challenges" &&
                  this.props.userId !== this.props.loginDetails.uId &&
                  moment(currentDate).format("yyyy-MM-DD") <=
                    this.props.expiryDate && (
                    <div className="postMoreInfo" style={{ margin: "8px" }}>
                      <button
                        onClick={this.ApplyForm}
                        className="btn mb-0 connectionSumbitLabel"
                      >
                        {this.props.userApply ? (
                          <>
                            {" "}
                            <FiUpload
                              style={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                                color: "#ffffff",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <FiFastForward
                              style={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                                color: "#ffffff",
                              }}
                            />
                          </>
                        )}
                        {this.props.userApply ? (
                          <>
                            {this.props.careerLabType === 0
                              ? t("Update CareerLab Form")
                              : this.props.careerLabType === 1
                              ? t("Update Course Form")
                              : t("Update Internship Form")}
                            {/* {t("Update")} */}
                          </>
                        ) : (
                          <>
                            {this.props.careerLabType === 0
                              ? t("Apply Career Labs")
                              : this.props.careerLabType === 1
                              ? t("Apply Course")
                              : t("Apply Internship")}
                            {/* {t("Apply")} */}
                          </>
                        )}
                      </button>
                    </div>
                  )}
                {this.props.status == "challenges" &&
                  this.props.careerLabType === 0 &&
                  moment(currentDate).format("yyyy-MM-DD") <=
                    this.props.expiryDate && (
                    <>
                      {this.props.userApply ? (
                        <>
                          <button
                            onClick={this.inviteUser}
                            style={{ marginRight: "8px" }}
                          >
                            <FiUserPlus
                              style={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                                color: "#ffffff",
                              }}
                            />{" "}
                            {t("Invite")}
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                {this.props.status == "challenges" &&
                  moment(currentDate).format("yyyy-MM-DD") >
                    this.props.expiryDate && (
                    <div
                      className=""
                      style={{ margin: "8px", float: "right", color: "red" }}
                    >
                      <p>{t("CLOSED")}</p>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <ul class="groupCard-avatar-group groupCard-list-unstyled groupCard-justify-content-center groupCard-justify-content-md-start groupCard-align-items-center groupCard-mb-0 groupCard-mt-3 groupCard-flex-wrap">
          {/* <li class="groupCard-avatar groupCard-avatar-xs">
                <img
                  class="groupCard-avatar-img rounded-circle"
                  src={personal_advice}
                  alt="avatar"
                />
              </li> */}
          {(fromWhichCard == "groupscard" ||
            fromWhichCard == "mygroupscard") && (
            <>
              {this.props.allMembersList &&
                this.props.allMembersList
                  .slice(0, 2)
                  .map((name) => <GroupParticipatePicture fileName={name} />)}
              {this.props.allMembersList?.length > 2 && (
                <>
                  <sub
                    style={{ paddingLeft: "12px" }}
                    className="groupParticipantsImage"
                  >
                    +
                  </sub>
                  <sub
                    style={{ paddingLeft: "2px" }}
                    className="groupParticipantsImage"
                  >
                    {this.props.totalCommunityUser - 2}
                  </sub>
                </>
              )}

              {/* {this.props.allMembersList &&
                this.props.allMembersList
                  .slice(0, 5)
                  .map((name) => (
                    <GroupParticipateName pName={name.displayName} />
                  ))} */}
              {/* <li
                class="groupCard-small groupCard-text-center"
                style={{ marginLeft: "18px" }}
              >
                {this.props.allMembersList.length > 5 && (
                  <>and {this.props.allMembersList.length - 5} in this group.</>
                )}
              </li>{" "} */}
            </>
          )}
        </ul>
        <div className="hashtag-style-group">
          {this.props.status !== "challenges" &&
            this.props.tagList &&
            this.props.tagList
              .slice(0, 8)
              .map((d) => <p className="hash-bg-color">#{d.tagName}</p>)}
          {!readOnlyWorkspace(
            this.props.userWorkSpace?.role,
            this.props.userWorkSpace?.typeHandle
          ) &&
            (this.props.status === "public" ||
              this.props.status === "protected" ||
              this.props.status === "private") &&
            this.props.userId === this.props.loginDetails.uId && (
              <button className="ml-auto mr-3" onClick={this.createEvent}>
                {t("Add Event")}
              </button>
            )}
        </div>

        {(fromWhichCard == "learningAppDesription" ||
          fromWhichCard == "challengeDescription" ||
          fromWhichCard == "special-resource") && (
          <div class="card-body position-relative pt-0">
            {this.props.status === "challenges" &&
              fromWhichCard == "challengeDescription" && (
                <div
                  className="d-flex flex-wrap mt-2 mb-2"
                  style={{ fontSize: "1rem", gap: "1rem" }}
                >
                  {this.props.tagList &&
                    this.props.tagList.map((d) => (
                      <p className="hash-bg-color">#{d.tagName}</p>
                    ))}
                </div>
              )}
            {(fromWhichCard == "learningAppDesription" ||
              fromWhichCard == "special-resource") && (
              <ul class="list-unstyled mt-3 mb-0">
                <li class="about-mb-2 d-flex flex-wrap" style={{ gap: "1rem" }}>
                  <p className="text-dark">{t("Description")}</p> :{" "}
                  {/* <strong> */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.props.subjectDescription,
                    }}
                  />
                </li>
                {this.props.status === "resource" && (
                  <li style={{ display: "flex", flexDirection: "row-reverse" }}>
                    {this.props.groupWorkspace !== 0 &&
                      (this.props.loginUserRole === 1 ||
                        this.props.loginUserRole === 2) && (
                        <>
                          {(this.props.resourceType === 4 ||
                            this.props.resourceType === 0) && (
                            <button
                              style={{ float: "right", marginRight: "14px" }}
                              onClick={() =>
                                this.createResourcePost(
                                  "Document",
                                  this.props.subjectId
                                )
                              }
                            >
                              + {t("Document")}
                            </button>
                          )}
                          {(this.props.resourceType === 2 ||
                            this.props.resourceType === 0) && (
                            <button
                              style={{ float: "right", marginRight: "14px" }}
                              onClick={() =>
                                this.createResourcePost(
                                  "Video",
                                  this.props.subjectId
                                )
                              }
                            >
                              + {t("Video")}
                            </button>
                          )}
                          {(this.props.resourceType === 3 ||
                            this.props.resourceType === 0) && (
                            <button
                              style={{ float: "right", marginRight: "14px" }}
                              onClick={() =>
                                this.createResourcePost(
                                  "Inspiration",
                                  this.props.subjectId
                                )
                              }
                            >
                              + {t("Inspiration")}
                            </button>
                          )}
                        </>
                      )}

                    {/* </strong> */}
                  </li>
                )}
              </ul>
            )}
          </div>
        )}
        {/* <p>{ (this.props.expiryDate)} </p> */}
        {this.props.status === "challenges" && (
          // this.props.userId !== this.props.loginDetails.uId &&
          <p
            style={{
              color: "red",
              fontSize: "16px",
              padding: "0px 0px 0px 21px",
            }}
          >
            {t(
              "Hi, If you have missed anything, don't worry.You can resubmit or Edit your submission till"
            )}{" "}
            {moment(this.props.expiryDate).locale("en").format("ll")} ...
            {/* {moment(this.props.expiryDate).format("D")}st{" "}
              {moment(this.props.expiryDate).format("MMM")}{" "}
              {moment(this.props.expiryDate).format("YY")}.... */}
          </p>
        )}

        {this.state.invitePopup && (
          <div>
            <Modal show="true">
              <Form id="profileForm">
                <Modal.Body>
                  <Form.Group className="formInputs">
                    <Form.Label>{t("Invite Friends")}</Form.Label>

                    <Form.Control
                      className={
                        "emailInput " + (this.state.error && " has-errorEmail")
                      }
                      value={this.state.value}
                      placeholder="Type or paste email addresses and press `Enter`..."
                      onKeyDown={this.handleKeyDown}
                      onChange={this.handleChange}
                      onPaste={this.handlePaste}
                    />
                    {this.state.items.map((item) => (
                      <div
                        style={{ marginTop: "12px" }}
                        className="tag-itemEmail"
                        key={item}
                      >
                        {item}
                        <button
                          //  type="button"
                          className="emailButton"
                          onClick={() => this.handleDelete(item)}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    style={{ color: "#ffffff", marginRight: "12px" }}
                    onClick={this.closeInvitePage}
                  >
                    {t("Close")}
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ color: "#ffffff", marginRight: "12px" }}
                    onClick={this.onSave}
                  >
                    {t("Send")}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>
        )}
        {this.state.exitPopup && this.popUpExit()}
        {this.state.shareCommunityModel && (
          <Modal show="true">
            <Modal.Body
              style={{ background: "lightslategray", borderRadius: "4px" }}
            >
              <Form.Group className="formInputs">
                <SocialShare
                  url={`${APPCONFIG.BASE_URL}yuukke-t-community?id=${this.props.subjectId}&sub=${this.props.subjectName}`}
                  content={`${this.props.loginDetails.dName} is inviting you be part of her community ${this.props.subjectName}.Please visit yuukkapp- http://yuukke.com/promotion.php`}
                  closeModel={() => {
                    this.setState({
                      shareCommunityModel: false,
                    });
                  }}
                />
              </Form.Group>
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
  createResourcePost = (type, id) => {
    let data = "new";
    this.props.history.push(
      `/${this.props.workspaceType}/resourcePost/${data}/${this.props.workspaceType}/${type}/${this.props.subjectId}`
    );
  };
  createEvent = () => {
    var gName = this.props.subjectName.trim();
    gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
    this.props.history.push(
      `/${this.props.workspaceType}/event-form/${this.props.subjectId}/${gName}`
    );
  };
  editCareerLab = (e, subjectId, status) => {
    if (this.props.status === "challenges") {
      this.props.history.push(
        `/${this.props.workspaceType}/newCareer/${subjectId}`
      );
    } else {
      this.props.history.push(
        `/${this.props.workspaceType}/newResource/${subjectId}`
      );
    }
  };
  editGroup = (e, subjectId) => {
    this.props.history.push(`/updateGroup/${subjectId}`);
  };
  handleMenuClick(event, userId) {
    this.setState({ menuState: event.target });
  }
  handleMenuClose() {
    this.setState({
      menuState: false,
    });
  }

  popUpExit() {
    const { t } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Modal show="true" onHide={this.handleClose}>
          <Modal.Header>{t("Exit Community")}</Modal.Header>

          <Modal.Body>
            <Form.Group className="formInput">
              <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                {t("Are you sure to exit in this community?")}
              </Form.Label>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              {t("Cancel")}
            </Button>
            <Button onClick={this.removeUser} variant="primary">
              {t("Exit")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  exit() {
    this.setState({
      exitPopup: true,
      menuState: false,
    });
  }
  handleClose() {
    this.setState({ exitPopup: false });
  }
  removeUser() {
    let removeUser = {
      groupsOrEvents: this.props.subjectId,
    };
    MyYuukke.exitGroup(removeUser)
      .then((response) => {
        if (response.data.status === 1) {
          toast.success(`${this.props.t("Exited successfully...")}`);

          this.handleClose();
          // LoadService.redirect(this.props.history, "/groups");
          this.props.history.push(`/${this.props.workspaceType}/groups`);
          // this.props.getAllSubjectList("Groups", 0);
          // this.props.getAllMyGroupEvents("Groups", 0);
          this.props.getAllUserCommunityList();
          // this.props.getMyGroupsByMembersandMessages();
        } else {
          toast.success(`${this.props.t("Not Properly send Details...")}`);
        }
        var result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  inviteUser() {
    this.setState({
      invitePopup: true,
    });
  }

  deleteCommunity = (event, subId) => {
    event.preventDefault();
    event.stopPropagation();
    this.handleMenuClose();
    Swal.fire({
      title: "Are you sure to delete this community?",
      text: "",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        var deleteSubId = {
          subjectId: subId,
        };
        MyYuukke.deleteSubjectById(deleteSubId)
          .then((response) => {
            // this.props.getGroupsorEventsList();
            if (this.props.status === "challenges") {
              this.props.history.push(`career-lab/${this.props.workspaceType}`);
            } else if (this.props.status === "resource") {
              this.props.history.push(
                `/${this.props.workspaceType}/learning-hub`
              );
            } else {
              this.props.getAllMyGroupEvents("Groups", 0);
              // this.props.getMyGroupsByMembersandMessages();
              this.props.history.push(`/${this.props.workspaceType}/groups`);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  onSave(e) {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData();
    const form = e.currentTarget;

    var emailCheck = true;

    if (this.state.items == "" && this.state.emailVerification == "") {
      toast.warn("Please fill valid  email...");
    } else {
      if (this.state.onEnter == true || this.state.onPaste == true) {
        emailCheck = false;
        let value = {
          email: this.state.items,
        };

        this.emailInvite(value);
      } else if (this.state.emailVerification != "" && emailCheck) {
        var email = this.state.emailVerification.trim();
        if (email && this.isValid(email)) {
          emailCheck = true;

          let value = {
            email: [this.state.emailVerification],
            //  email: [...this.state.oemailVerificatin],
          };
          console.log("this.state.items 2", value);
          this.emailInvite(value);
        } else {
          toast.warn("Please enter valid  email...");
        }
      }
    }
  }
  closeInvitePage() {
    this.setState({
      invitePopup: false,
    });
  }
  emailInvite(value) {
    console.log("value", value);
    MyYuukke.shareChallengeApplied(value)
      .then((response) => {
        toast.success("User invited successfully");
        this.setState({
          items: [],
          emailVerification: [],
          value: "",
        });

        this.closeInvitePage();
      })
      .catch((error) => {
        console.log(error);
        this.closeInvitePage();
      });
  }

  handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.value.trim();

      if (value && this.isValid(value)) {
        this.setState({
          items: [...this.state.items, this.state.value],
          value: "",
          onEnter: true,
        });
      }
    }
  };

  handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !this.isInList(email));

      this.setState({
        items: [...this.state.items, ...toBeAdded],
        onPaste: true,
      });
    }
  };

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }
  handleChange = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null,
      emailVerification: evt.target.value,
    });
  };

  handleDelete = (item) => {
    this.setState({
      items: this.state.items.filter((i) => i !== item),
    });
  };
  isInList(email) {
    return this.state.items.includes(email);
  }
  isValid(email) {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      this.setState({ error });

      return false;
    }

    return true;
  }
  ApplyForm() {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      var d1 = moment(currentDate).format("yyyy-MM-DD");
      var d2 = moment(this.props.expiryDate).format("yyyy-MM-DD");
      var Apply = "";
      var Status = "";
      var date = "";
      var userApply = "";
      if (this.props.userApply) {
        userApply = "Update";
      } else {
        userApply = "New";
      }

      if (moment(d1).isBefore(d2)) {
        Apply = "Valid";
      } else if (moment(d1).isSame(d2)) {
        Apply = "Valid";
      } else {
        Apply = "Expired";
      }
      if (this.props.challengeId == "") {
        Status = "New";
      } else {
        Status = this.props.challengeId;
      }

      this.props.history.push(
        `/${this.props.workspaceType}/challengeApplyForm/${this.props.subjectId}/${Status}/${userApply}/${Apply}/${this.props.careerLabType}`
      );
    }
  }
  async getMessageFiles(files, type) {
    if (type == "image") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
        }
        await this.setState({ fileData: fileList });
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "text") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(dummyimage);
        }
        await this.setState({ fileData: fileList });
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "video") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/video/${files[i].fileName}`);
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "pdf") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "file") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else {
      let fileList = [];
      if (files.length > 0) {
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    }
  }
}

function GroupParticipateName(props) {
  return (
    <li
      class="groupCard-small groupCard-text-center"
      style={{ marginLeft: "18px" }}
    >
      {props.pName},
    </li>
  );
}

function GroupParticipatePicture(props) {
  const [picture, setPicture] = useState("");
  // useEffect(() => {
  //   setPicture(`${APPCONFIG.API_URL}auth/image/${props.fileName}`);
  // }, [props.pId]);

  return (
    <li
      class="groupCard-avatar groupCard-avatar-xs"
      style={{ marginLeft: "8px" }}
    >
      <img
        class="groupCard-avatar-img rounded-circle"
        src={
          API_URL +
          "api/getUserPhoto/" +
          props?.fileName?.uId +
          "/" +
          props?.fileName?.userName
        }
        alt="avatar"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = dummyimage;
        }}
      />
    </li>
  );
}

const mapStateToProps = function (state) {
  return {
    userWorkSpace: state.loginDetailsApi.loginDetails?.userWorkSpace,
    totalCommunityUser: state.subjects.totalCommunityUser,
    allMembersList: state.subjects.allMembersList,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    loginUserType: state.loginDetailsApi.loginDetails?.uType
      ? state.loginDetailsApi.loginDetails.uType
      : 0,
    loginUserRole: state.loginDetailsApi.loginDetails?.userWorkSpace?.role
      ? state.loginDetailsApi.loginDetails.userWorkSpace.role
      : 0,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getAllMyGroupEvents: (type, page) =>
      dispatch(getAllMyGroupEvents(type, page)),
    getAllSubjectList: (type, page) => dispatch(getAllSubjectList(type, page)),
    getMyGroupsByMembersandMessages: () =>
      dispatch(getMyGroupsByMembersandMessages()),
    getAllUserCommunityList: () => dispatch(getAllUserCommunityList()),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(GroupCardBody)));
