import {
  GET_ALL_MENTOR_LIST,
  GET_MENTOR_INDUSTRY,
  GET_MENTOR_SPECIALIZATION,
  MENTOR_LIST_LOADER,
} from "../types";

const initialState = {
  mentorIndustry: [],
  mentorSpecialization: [],
  mentorList: [],
  mentorLoader: false,
  mentorSeeMore: false,
  mentorSlotPresent: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MENTOR_INDUSTRY:
      console.log("GET_MENTOR_INDUSTRY", action.payload);
      return {
        ...state,
        mentorIndustry: action.payload ? action.payload : [],
      };
    case GET_MENTOR_SPECIALIZATION:
      console.log("GET_MENTOR_SPECIALIZATION", action.payload);
      return {
        ...state,
        mentorSpecialization: action.payload ? action.payload : [],
      };
    case GET_ALL_MENTOR_LIST:
      console.log("GET_ALL_MENTOR_LIST", action.payload);
      if (action.pagination) {
        let oldList = state.mentorList.concat(action.payload.mentorList);
        return {
          ...state,
          mentorLoader: false,
          mentorList: oldList ? oldList : state.mentorList,
          mentorSeeMore:
            action.payload.totalPages - 1 > action.page ? true : false,
        };
      } else {
        return {
          ...state,
          mentorLoader: false,
          mentorList: action.payload.mentorList
            ? action.payload.mentorList
            : [],
          mentorSeeMore:
            action.payload.totalPages - 1 > action.page ? true : false,
          mentorSlotPresent: action.payload.mentorSlotPresent,
        };
      }

    case MENTOR_LIST_LOADER:
      return {
        ...state,
        mentorLoader: true,
      };

    default:
      return state;
  }
}
