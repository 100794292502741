import React, { Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
export default class EditableDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disbaledField: false,
      value: this.props.value,
    };

    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);

  }

  componentDidMount() {

    this.setState({
      value: this.props.value,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {

      this.setState({
        value: this.props.value,
      });
    }
  }

  handleClickEdit() {
    this.setState({
      disbaledField: false,
    });
    this.props.handleClickEdits()
  }
  handleChangeInput(event) {
    this.setState({
      value: event.target.value,
    });
    this.props.getSelectedValue(event.target.value);
    this.props.handleClickEdits()
  }
  render() {
    const { label } = this.props;
    const { disbaledField, value } = this.state;
    return (
      <div
        className={
          disbaledField
            ? "editableTextWrapper formFieldGroup disabledField"
            : "editableTextWrapper formFieldGroup"
        }
      >
        <label>{label}</label>
        <Grid container spacing={1} alignItems="flex-end" wrap="nowrap">
          <Grid item xs={11}>
            <TextField
              id="date"
              label=""
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth={true}
              variant="outlined"
              value={value}
              onChange={this.handleChangeInput}
              disabled={disbaledField}
              fullWidth={true}

            />
          </Grid>
          <Grid item xs={1}>
          </Grid>
        </Grid>
      </div>
    );
  }
}