import { Chip, Menu, MenuItem, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Edit } from "@material-ui/icons";
import TextEditorQuestion from "../../components/TextEditorQuestion";
import MessagesFileOption from "../Community/Subjects/MessagesFileOption";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Images from "../Home/HomeUpdated/ImageView/Images";
import { BiCalendarCheck, BiHeart } from "react-icons/bi";
import { needRenderComponent } from "../../redux/actions/commonApiActions";
import { AiOutlineCopy, AiTwotoneCopy } from "react-icons/ai";
import { API_URL, BASE_URL, JITSI_URL } from "../../config/app-config";
import { toast } from "react-toastify";
import axios from "axios";
import PostService from "../../api/PostService";
import moment from "moment";
import { MdOutlineAttachFile } from "react-icons/md";
import CalenderService from "../../api/CalenderService.js";
import { generateUlr } from "../../utils";

function CalenderEventDetails({
  authenticated = false,
  accessToken = "",
  refreshToken = "",
  ...props
}) {
  const { t } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const [menuState, setMenu] = useState(null);
  const [editState, setEdit] = useState(false);
  const handleMenuClick = (event) => {
    setMenu(event.target);
  };
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState([]);
  const [editorData, setEditorValue] = useState("");
  const [chipData, setChipImageData] = useState([]);
  const [copyData, setCopyData] = useState(false);
  const [slug, setSlug] = React.useState("");
  const [meetingCopy, setCopyMeeting] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const eventSlug = location.pathname;
  const [mediaFiles, setMediaFiles] = useState([]);
  let currentDate = moment(new Date()).format("yyyy-MM-DD");
  const [startDate, setStartDate] = useState("");
  const [removedFiles, setRemovedFile] = useState([]);
  const [userMediaFiles, setUserMediaFile] = useState([]);
  const [responseTitle, setResponseTitle] = useState("");
  const [responseDescription, setResponseDescription] = useState("");
  const [interest, setInterest] = useState(false);
  console.log("Event Slug", eventSlug.slice(eventSlug.lastIndexOf("/") + 1));
  const removeFile = (id) => {
    console.log("fileId", removedFiles);
    let fileId = removedFiles.concat(id);
    setRemovedFile(fileId);
    console.log("fileId", fileId);
    const list = userMediaFiles.filter((item) => item.id !== id);
    setUserMediaFile(list);
  };
  useEffect(() => {
    const filterSlug = eventSlug.slice(eventSlug.lastIndexOf("/") + 1) ?? "";
    setSlug(filterSlug);
    if (!authenticated && !accessToken) {
      dispatch(needRenderComponent(true));
    }
    const getarticleContent = async () => {
      console.log("slug", slug);
      if (filterSlug !== "") {
        setLoader(true);
        const url = authenticated
          ? `${API_URL}api/v1/eventDetail/getSingleData/${filterSlug}`
          : `${API_URL}auth/v1/eventDetail/getSingleData/${filterSlug}`;

        PostService.getEventDetails(url, authenticated)
          .then((response) => {
            console.log(
              "calendarEvent",
              response?.data?.data?.calendarEvent?.title
            );
            const result = response?.data?.data?.calendarEvent;
            setEventDetails(result ?? null);
            setInterest(response?.data?.data?.interest);
            if (result?.title) {
              updateMediaFiles(result.mediaFiles);
              setUserMediaFile(result.mediaFiles);
              setDescription(result.description);
              setTitle(result.title);
              setResponseTitle(result.title);
              setResponseDescription(result.description);
              setStartDate(moment(result.startDate).format("yyyy-MM-DD"));
            }
            setLoader(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
    getarticleContent();
  }, []);
  const updateMediaFiles = (data) => {
    const list = data.map((item) => `${API_URL}auth/image/${item.fileName}`);
    console.log("list", list);
    setMediaFiles(list);
  };
  const handleMenuClose = () => {
    setMenu(false);
  };
  console.log("location", location);
  const editorStateValues = (values) => {
    console.log("editorStateValues");
    console.log(values);

    setDescription(values);
  };
  const chipImageData = (data) => {
    setChipImageData(data);
  };
  const getQuestionMediaImage = (file) => {
    setFile(file);
  };
  const getQuestionMediaFiles = (file) => {
    setFile(file);
  };

  const changeMenuState = () => {
    setEdit(true);
    setMenu(false);
    setTimeout(() => {
      setEditorValue(description);
    }, 10);
  };
  const handleCopy = async () => {
    console.log("event -link", window.location.href);
    await navigator.clipboard.writeText(window.location.href);
    setCopyData(true);
    setCopyMeeting(false);
    toast.success("Link coped successfully , You can share the link");
  };

  const handleCopyMeeting = async (link, isMeetingYuukke, eventType) => {
    console.log("Link", link);

    try {
      if (!isMeetingYuukke) {
        await navigator.clipboard.writeText(link);
      } else if (eventType === "mentor") {
        let regex = link.replace(/&.*/, "");
        await navigator.clipboard.writeText(`${JITSI_URL}/${regex}`);
      } else if (eventType === "public") {
        await navigator.clipboard.writeText(
          `${JITSI_URL}/${link}?&isGlob=true&sp=yes#config`
        );
      } else {
        let regex = link.replace(/&.*/, "");
        await navigator.clipboard.writeText(
          `${JITSI_URL}/${regex}?&isGroup=true&sp=yes#config`
        );
      }
      setCopyMeeting(true);
      setCopyData(false);

      toast.success("Meeting link Copied");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  const onHandleClick = (event, link, isMeetingYuukke, eventType) => {
    event.preventDefault();
    event.stopPropagation();
    if (eventType === "mentor") {
      generateUlr(`${JITSI_URL}/${link}?authenticated=true&sp=yes`);
      // window.open(`${JITSI_URL}/${link}?authenticated=true#config`, "_blank");
    } else if (!isMeetingYuukke) {
      window.open(link, "_blank");
    } else if (eventType === "public") {
      generateUlr(`${JITSI_URL}/${link}?authenticated=true&sp=yes&isGlob=true`);
      // window.open(
      //   `${JITSI_URL}/${link}?authenticated=true&isGlob=true#config`,
      //   "_blank"
      // );
    } else {
      generateUlr(
        `${JITSI_URL}/${link}?authenticated=true&sp=yes&isGroup=true`
      );

      // window.open(
      //   `${JITSI_URL}/${link}?authenticated=true&#config`,
      //   "_blank"
      // );
    }
  };

  const updateDetails = () => {
    console.log("file", file);
    if (!title) {
      toast.warning("Please add Title");
    } else if (!description) {
      toast.warning("Please add Description");
    } else {
      const formData = new FormData();
      formData.append("description", description);
      formData.append("title", title);
      formData.append("calendarId", eventDetails.calendarId);
      if (file && file.length !== 0) {
        for (let i = 0; i < file.length; i++)
          formData.append("eventFiles", file[i]);
      }
      if (removedFiles.length !== 0) {
        formData.append("removeIds", removedFiles);
      }
      setLoader(true);

      console.log("details", title, description, file);
      PostService.updateEventCard(formData)
        .then((response) => {
          console.log("Update", response);
          const result = response?.data?.data?.calendarEvent;
          setLoader(false);

          if (result?.title) {
            updateMediaFiles(result.mediaFiles);
            setUserMediaFile(result.mediaFiles);
            setRemovedFile([]);
            setDescription(result.description);
            setStartDate(moment(result.startDate).format("yyyy-MM-DD"));

            setTitle(result.title);
            setResponseTitle(result.title);
            setResponseDescription(result.description);
            setEdit(false);
            toast.success("Details update successfully ...");
          } else {
            toast.error("Update Error");
          }
        })
        .catch((err) => {
          toast.error("Update Error");
        });
    }
  };

  const onBack = () => {
    setEdit(false);
    setEditorValue("");
    setTitle(responseTitle);
    setDescription(responseDescription);
  };
  console.log("editorValue ", editorData);
  const setInterested = (id) => {
    var apiData = {
      calendarId: id,
      interest: true,
    };
    CalenderService.showInterest(apiData)
      .then((response) => {
        console.log("Api Data", apiData);
        toast.success("Thanks for showing interest");
        setInterest(true);
      })
      .catch((error) => {
        console.log("Er", error);
      });
  };
  return (
    <div
      // className={
      //   authenticated && accessToken !== "" && refreshToken !== ""
      //     ? "screenInnerGap"
      //     : "screenInnerGap artical-wrap"
      // }
      style={{ wordWrap: "break-word" }}
    >
      <Paper style={{ padding: "2rem" }}>
        {loader ? (
          <div className="d-flex justify-content-center p-5">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {!editState ? (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <p>
                    {" "}
                    {!title && <>There is no Event Details Present ... </>}
                  </p>

                  {authenticated &&
                    eventDetails?.userId &&
                    props.userDetails.uId === eventDetails?.userId && (
                      <>
                        <MoreHorizIcon
                          style={{ cursor: "pointer" }}
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleMenuClick(e);
                          }}
                        />
                        <Menu
                          id="simple-menu"
                          anchorEl={menuState}
                          keepMounted
                          open={menuState}
                          onClose={handleMenuClose}
                        >
                          <div
                            className="menuCard"
                            onClick={() => changeMenuState()}
                          >
                            <Edit
                              style={{ cursor: "pointer", marginTop: "6px" }}
                            />{" "}
                            <MenuItem>{t("Update")}</MenuItem>
                            {/* <div
                        className="menuCard"
                        onClick={(e) => this.deleteAskHelp(e, this.state.jobId)}
                      >
                        <Delete
                          style={{ cursor: "pointer", marginTop: "6px" }}
                        />{" "}
                        <MenuItem>{t("Delete")}</MenuItem>
                      </div> */}
                          </div>
                        </Menu>
                      </>
                    )}
                </div>
                {eventDetails && title && (
                  <div>
                    <h1 className="mb-4 text-dark">{title}</h1>

                    <p
                      className="about-mb-2"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                    <div className="cardWrapper p-2 mt-3">
                      <div className="time-wrapper">
                        <div className="d-flex bg-color">
                          <BiCalendarCheck className="svg-icons bg-color mr-2" />{" "}
                          <p
                            className="d-flex flex-column"
                            style={{ fontSize: "15px" }}
                          >
                            <span>
                              {moment(eventDetails?.startDate)
                                .locale("en")
                                .format("ll")}
                            </span>{" "}
                            {authenticated &&
                              eventDetails?.userId &&
                              props.userDetails?.uId ===
                                eventDetails?.userId && (
                                <div className="profileCopy-card d-flex">
                                  <p onClick={() => handleCopy()}>
                                    {"Share your Friends"}
                                    {copyData ? (
                                      <AiTwotoneCopy
                                        class="copy-icon"
                                        style={{ color: "rgb(160, 0, 48)" }}
                                      />
                                    ) : (
                                      <AiOutlineCopy class="copy-icon" />
                                    )}
                                  </p>
                                </div>
                              )}
                          </p>{" "}
                        </div>
                        <div>
                          {authenticated &&
                            accessToken &&
                            !interest &&
                            !moment(startDate).isSame(currentDate) && (
                              <button
                                className="mb-1"
                                onClick={(e) =>
                                  setInterested(eventDetails.calendarId)
                                }
                              >
                                <BiHeart
                                  style={{
                                    height: "24px",
                                    width: "auto",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                />
                                I'm Interested
                              </button>
                            )}
                          {authenticated && (
                            <div>
                              {(moment(startDate).isBefore(currentDate) ||
                                moment(startDate).isSame(currentDate)) && (
                                <button
                                  className="mb-1"
                                  onClick={(e) =>
                                    onHandleClick(
                                      e,
                                      eventDetails.url,
                                      eventDetails?.meetingInApplication,
                                      eventDetails.taskType
                                    )
                                  }
                                >
                                  Join Meeting
                                </button>
                              )}

                              {(props.userDetails.uId ===
                                eventDetails?.userId ||
                                interest) && (
                                <div className="profileCopy-card d-flex">
                                  <p
                                    onClick={() =>
                                      handleCopyMeeting(
                                        eventDetails.url,
                                        eventDetails?.meetingInApplication,
                                        eventDetails.taskType
                                      )
                                    }
                                  >
                                    {"Share meeting Link"}
                                    {meetingCopy ? (
                                      <AiTwotoneCopy
                                        class="copy-icon"
                                        style={{ color: "rgb(160, 0, 48)" }}
                                      />
                                    ) : (
                                      <AiOutlineCopy class="copy-icon" />
                                    )}
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {mediaFiles && (
                        <Images images={mediaFiles} onClickImage={undefined} />
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="askAQuestionInputGroup inputLarge">
                <div className="d-flex mb-4  align-items-center justify-content-between">
                  <h5 className="text-secondary m-0 font-weight-bold">
                    {t("Update Event Details")}
                  </h5>
                  <p style={{ cursor: "pointer" }} onClick={() => onBack()}>
                    <ArrowBackIcon /> Back
                  </p>
                </div>
                <div className="p-2">
                  <label className="formLabel">{t("Title")} *</label>
                  <input
                    type="text"
                    name="question"
                    value={title}
                    maxLength="250"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                <div className="p-2 askAQuestionDescription">
                  <label className="formLabel mt-2">{t("Description")} *</label>
                  <TextEditorQuestion
                    editorStateValue={editorStateValues}
                    editorValue={editorData}
                    // friendList={[]}
                    // needEdit={needEdit}
                    // changeNeedHelpState={() => setNeedEdit(false)}
                  />
                </div>
                <div className="p-">
                  <MessagesFileOption
                    chipImageData={chipImageData}
                    fileTypeKey={"image"}
                    fileOptionEnable={() => setFile(null)}
                    fromQuestionImage={getQuestionMediaImage}
                    fromQuestionMedia={getQuestionMediaFiles}
                  ></MessagesFileOption>{" "}
                </div>
                <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                  {userMediaFiles &&
                    userMediaFiles.map((file, index) => (
                      <div key={file.fileName}>
                        <Chip
                          icon={<MdOutlineAttachFile />}
                          className="fileChip"
                          label={file.originalFileName}
                          size="small"
                          onDelete={() => removeFile(file.id)}
                          variant="outlined"
                        />
                        <img
                          style={{
                            margin: "8px",
                            width: "80px",
                            height: "80px",
                          }}
                          src={`${API_URL}auth/image/${file.fileName}`}
                        ></img>
                      </div>
                    ))}
                </div>
                <div className="p-2">
                  <button style={{ width: "100%" }} onClick={updateDetails}>
                    {t("Update Event Details")}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </Paper>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    userDetails: state.loginDetailsApi.loginDetails,
    friendsList: state.commonApi.chatFriendslist,
    navigationType: state.commonApi.navigationType,
    selectedUser: state.chatList.selectedUser,
    authenticated: state.loginDetailsApi.keycloakAuthenticated,
    refreshToken: state.loginDetailsApi.refreshToken,
    accessToken: state.loginDetailsApi.accessToken,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(CalenderEventDetails));
