import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import yuukke from "../../img/default_avatar.jpg";
import { TextField } from "@material-ui/core";
import ProfileService from "../../api/ProfileService";
import PostService_old from "../../api/PostService_old";
import { Multiselect } from "react-widgets";
import { toast } from "react-toastify";
import Utils from "../../utils/Utils";
import Swal from "sweetalert2";
import { getId } from "../../utils";
import AuthService from "../../session/AuthService";
import { useDispatch } from "react-redux";
import { getLogoutData } from "../../redux/actions/logoutActions";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

const SpecialMentor = () => {
  const [state, setState] = useState({
    userName: "",
    email: "",
    mobile: "",
    companyName: "",
    specialization: [],
    industry: [],
    secondarySpecialization: [],
    secondaryIndustry: [],
    role: "",
    experience: "",
    allIndustry: [],
    allSpecialization: [],
    allSubIndustry: [],
    allSubSpecialization: [],
  });
  const dispatch = useDispatch();

  useEffect(() => {
    getList();
  }, []);
  const getList = () => {
    PostService_old.getAllSpecializtion({}).then((response) => {
      console.log("getAllSpecializtion", response.data.data);
      updateState("allSpecialization", response.data.data.Specialization);
    });
    ProfileService.subSpecialization().then((response) => {
      console.log("allSubSpecialization", response.data.data);
      updateState("allSubSpecialization", response.data.data.subSpecialisation);
    });

    ProfileService.subIndustry().then((response) => {
      console.log("subIndustry", response.data.data.subIndustry);
      updateState("allSubIndustry", response.data.data.subIndustry);
    });
    ProfileService.getAllIndustry({}).then((response) => {
      console.log("getAllIndustry", response.data.data);
      const id = response.data.data.map((item) => {
        return { id: item.industryId, name: item.industryName };
      });
      updateState("allIndustry", id);
    });
  };

  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const handleClose = () => {
    console.log("State", state);
  };
  const updateDetails = () => {
    if (
      !state.userName ||
      !state.email ||
      !state.mobile ||
      !state.companyName ||
      state.specialization.length === 0 ||
      state.secondarySpecialization.length === 0 ||
      state.industry.length === 0 ||
      state.secondaryIndustry.length === 0 ||
      !state.role ||
      !state.experience
    ) {
      toast.warning(`${this.props.t("Fill all the required fields ...")}`);
    } else if (!Utils.emailValidation(state.email)) {
      toast.warning(`${this.props.t("Enter valid email address")}`);
    } else {
      const formData = new FormData();
      formData.append("name", state.userName);
      formData.append("email", state.email);
      formData.append("role", state.role);
      formData.append("companyName", state.companyName);
      formData.append("exp", state.experience);
      formData.append("mobile", state.mobile);

      formData.append("subIndustryIds", getId(state.secondaryIndustry));

      formData.append("specialization", getId(state.specialization));

      formData.append(
        "subSpecializationIds",
        getId(state.secondarySpecialization)
      );

      formData.append("industryIds", getId(state.industry));

      formData.append("type", "expert");

      ProfileService.mentorCreate(formData)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title:
              "Mentor slot created successfully , you will receive mail shortly !",
            showConfirmButton: true,
          }).then((result) => {
            AuthService.removeAuth();
            dispatch(getLogoutData());
            AuthService.triggerLogout();
          });
        })
        .catch((error) => {
          toast.error("Something went wrong , please try again later");
        });
    }
  };
  return (
    <Modal show="true" onHide={handleClose}>
      <Modal.Header className="justify-content-center align-items-center bg-white flex-column">
        <h6 style={{ fontWeight: "bold" }}>
          {" "}
          <img src={yuukke} class="animi-icon" alt="icon" /> Become Mentor
        </h6>
        <p style={{ fontSize: "12px" }} className="text-secondary">
          Let us start with few details to get registered with Yuukke
        </p>
      </Modal.Header>
      <Card className="profileCard">
        <Card.Body className="profile-form">
          <div className="formFieldGroup p-3">
            <label className="mt-2 form-label">User Name *</label>
            <TextField
              id="textField"
              label=""
              variant="outlined"
              type="text"
              value={state.userName}
              style={{ background: "white" }}
              onChange={(e) => {
                updateState("userName", e.target.value);
              }}
              fullWidth={true}
            />

            <label className="mt-2 form-label">Email *</label>
            <TextField
              id="textField"
              label=""
              variant="outlined"
              type="email"
              value={state.email}
              style={{ background: "white" }}
              onChange={(e) => {
                updateState("email", e.target.value);
              }}
              fullWidth={true}
            />

            <label className="mt-2 form-label">Mobile *</label>
            <TextField
              id="textField"
              label=""
              variant="outlined"
              type="number"
              value={state.mobile}
              style={{ background: "white" }}
              onChange={(e) => {
                updateState("mobile", e.target.value);
              }}
              fullWidth={true}
            />
            <label className="mt-2 form-label">Company Name *</label>
            <TextField
              id="textField"
              label=""
              variant="outlined"
              type="text"
              value={state.companyName}
              style={{ background: "white" }}
              onChange={(e) => {
                updateState("companyName", e.target.value);
              }}
              fullWidth={true}
            />
            <label className="mt-2 form-label">Specialization *</label>
            <Multiselect
              dataKey="id"
              className="profileFormInput aboutInputAddButton"
              required
              data={state.allSpecialization}
              valueField="id"
              textField="specialization"
              value={state.specialization}
              onChange={(value) => updateState("specialization", value)}
            />
            <label
              className="mt-2"
              style={{ color: "#999999", fontSize: "18px" }}
            >
              Sub Specialization *
            </label>
            <Multiselect
              dataKey="id"
              className="profileFormInput aboutInputAddButton"
              required
              data={state.allSubSpecialization}
              valueField="id"
              textField="specialisationName"
              value={state.secondarySpecialization}
              onChange={(value) =>
                updateState("secondarySpecialization", value)
              }
            />
            <label
              className="mt-2"
              style={{ color: "#999999", fontSize: "18px" }}
            >
              Industry *
            </label>
            <Multiselect
              dataKey="id"
              className="profileFormInput aboutInputAddButton"
              required
              data={state.allIndustry}
              valueField="id"
              textField="name"
              value={state.industry}
              onChange={(value) => updateState("industry", value)}
            />
            <label
              className="mt-2"
              style={{ color: "#999999", fontSize: "18px" }}
            >
              Sub Industry *
            </label>
            <Multiselect
              dataKey="id"
              className="profileFormInput aboutInputAddButton"
              required
              data={state.allSubIndustry}
              valueField="id"
              textField="industryValue"
              value={state.secondaryIndustry}
              onChange={(value) => updateState("secondaryIndustry", value)}
            />
            <label className="mt-2 form-label">Your Role *</label>
            <TextField
              id="textField"
              label=""
              variant="outlined"
              type="text"
              value={state.role}
              style={{ background: "white" }}
              onChange={(e) => {
                updateState("role", e.target.value);
              }}
              fullWidth={true}
            />

            <label className="mt-2 form-label">Experience *</label>
            <TextField
              id="textField"
              label=""
              variant="outlined"
              type="text"
              value={state.experience}
              style={{ background: "white" }}
              onChange={(e) => {
                updateState("experience", e.target.value);
              }}
              fullWidth={true}
            />
            <div className="pl-2 pr-2">
              <button className="w-100 mt-4" onClick={() => updateDetails()}>
                Submit
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Modal>
  );
};
export default withTranslation()(withRouter(SpecialMentor));
