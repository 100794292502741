import auth from "./AuthAPI.js";
import Http from "./Http.js";
import * as APPCONFIG from "../config/app-config.js";

class MyCourseService {
  courseList() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallCourse`,
    });
  }

  getStarArticleByUserId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getStarArticleByUserId`,
      data: formValues,
    });
  }

  getArticleList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getMyArticlesByCourseId`,
      data: formValues,
    });
  }

  getArticleByArticleId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getMyArticlesByArticleId`,
      data: formValues,
    });
  }
  courseFilter(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/courseFilter`,
      data: JSON.stringify(formValues),
    });
  }

  getMyArticles(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallMyArticles`,
      data: JSON.stringify(formValues),
    });
  }

  getMyArticlesByCourseType(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getMyArticlesByCourseType`,
      data: JSON.stringify(formValues),
    });
  }

  getArticleVideosCount(formValues) {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getArticleVideosCount`,
      data: JSON.stringify(formValues),
    });
  }

  getAllTags() {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallTags`,
    });
  }

  saveCourseStar(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveCourseStar`,
      data: formValues,
    });
  }

  getMyLibraryArticlesByUserId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getMyLibraryArticlesByUserId`,
      data: formValues,
    });
  }

  saveCompletedCourseByUserId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveCompletedCourseByUserId`,
      data: formValues,
    });
  }

  getCourseCompleteByUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getCompletedCourceUserId`,
      data: formValues,
    });
  }

  saveCourseFeedBack(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveCourseFeedBack`,
      data: formValues,
    });
  }
  getAllUserCommunityList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/getAllUserCommunityList/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  // getBase64Strings(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/getResourceString`,
  //         data: formValues
  //     })
  // }
  // getCourseUrl(id, config) {
  //     return api({
  //         method: 'get',
  //         url: `${APPCONFIG.API_URL}auth/video/${id}`,
  //         data: config
  //     })
  // }

  // getCourseFeedBackByUser(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/getCourseFeedBackByUser`,
  //         data: formValues
  //     })
  // }
  // getMyCourseDetailsByUserId(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/getallMyCourseByUserId`,
  //         data: formValues
  //     })
  // }

  // getCourseByUserId(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/getCourseByUserId`,
  //         data: formValues
  //     })
  // }
  // saveMyCourse(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/saveMyCourse`,
  //         data: formValues
  //     })
  // }

  // getCourseById(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/getCourseById`,
  //         data: formValues
  //     })
  // }
  // getSingleCourseDetailsById(formValues) {
  //         return auth({
  //             method: 'post',
  //             headers: Http.AUTH_HEADERS(),
  //             url: `${APPCONFIG.API_URL}api/getCourseDetailsbyCourseId`,
  //             data: formValues
  //         })
  //     }
  // getAllMyCourse(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/getallMyCourseByUserId`,
  //         data: JSON.stringify(formValues)
  //     })
  //   }
}

export default new MyCourseService();
