import React, { Component } from "react";
import PhotoIcon from "@material-ui/icons/Photo";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DescriptionIcon from "@material-ui/icons/Description";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { ToastContainer, toast } from "react-toastify";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
// import '../../css/mysocial.css'
// import '../../css/mysocialprofile.css'
// import '../../css/yuukkeTutoring.css'
import PostService from "../../api/PostService";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import AuthService from "../../session/AuthService";
import { Chip } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import ChooseFileComponent from "../../components/ChooseFileComponent";
import ChooseCoverFIleComponent from "../../components/ChooseCoverFIleComponent";

var imgView = true;
class LearningFileOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUrl: null,
      type: null,
      mediaPost: "",
      teaching: [],
      tags: [],
      question: "",
      removeStatus: true,
      addTagsState: false,
      profilePhotoAfterCrop: false,
      selectPage: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.removeImageFile = this.removeImageFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    if (this.props.fileOption) {
      this.removeFile("parent");
    }

    if (this.props.selectPage !== undefined) {
      this.setState({
        selectPage: this.props.selectPage,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fileOption !== prevProps.fileOption) {
      if (this.props.fileOption) {
        this.removeFile("parent");
      }
    }
    if (this.props.selectPage !== prevProps.selectPage) {
      if (this.props.selectPage !== undefined) {
        console.log("this.props.selectPage", this.props.selectPage);
        this.setState({
          selectPage: this.props.selectPage,
        });
      }
    }
  }

  render() {
    const { t } = this.props;
    const { type, fileUrl } = this.state;
    const onUploadSuccess = async (file) => {
      console.log("FILES");
      console.log(file);
      let preview = document.getElementById("upload-preview");

      this.setState({ fileArray: file });
      this.setState({ removeStatus: false });
      if (file.length > 0) {
        this.setState(
          {
            file: file,
            fileUrl: URL.createObjectURL(file[0]),
            type: file[0].type,
            name: file[0].name,
          },
          () => {
            this.onFormSubmit("other");
          }
        );
      } else {
      }
    };
    const onUploadSuccessImage = async (file) => {
      console.log("FILES");
      console.log(file);
      let preview = document.getElementById("upload-preview");

      this.setState({ fileImage: file });
      this.setState({ removeStatus: false });
      if (file) {
        this.setState(
          {
            file: file,
            fileUrl: URL.createObjectURL(file),
            type: file.type,
            name: file.name,
          },
          () => {
            this.onFormSubmit("image");
          }
        );
      } else {
      }
    };

    const setprofilePhotoCrops = async () => {
      this.setState({ profilePhotoAfterCrop: true });
    };
    const backprofilePhotoCrops = async () => {
      this.setState({ profilePhotoAfterCrop: false });
    };
    return (
      <div className="questionMoreOptions">
        <div className="formPostMoreOption">
          <div className="overAlluploadicons">
            {this.props.selectPage && (
              <ChooseCoverFIleComponent
                profilePhotoAfterCrop={this.state.profilePhotoAfterCrop}
                setprofilePhotoCrops={setprofilePhotoCrops}
                backprofilePhotoCrops={backprofilePhotoCrops}
                onUploadSuccess={onUploadSuccessImage}
                acceptedType=".jpg,.jpeg,.png,.svg"
              ></ChooseCoverFIleComponent>
            )}

            {this.props.fileTypeKey == "video" && (
              <ChooseFileComponent
                onUploadSuccess={onUploadSuccess}
                acceptedType=".mp4"
              ></ChooseFileComponent>
            )}
            {this.props.fileTypeKey == "audio" && (
              <ChooseFileComponent
                onUploadSuccess={onUploadSuccess}
                acceptedType=".pdf"
              ></ChooseFileComponent>
            )}
          </div>
          {this.state.fileArray &&
            this.state.fileArray.map((file, index) => (
              <div key={file.name}>
                <Chip
                  icon={<AttachmentIcon />}
                  className="fileChip"
                  label={file.name}
                  size="small"
                  onDelete={() => this.removeFile(index)}
                  variant="outlined"
                />
                {this.props.fileTypeKey == "image" && (
                  <img
                    style={{ margin: "8px", width: "80px", height: "80px" }}
                    src={URL.createObjectURL(file)}
                  ></img>
                )}
              </div>
            ))}
          {this.state.fileImage && (
            <div key={this.state.fileImage.name}>
              <Chip
                icon={<AttachmentIcon />}
                className="fileChip"
                label={this.state.fileImage.name}
                size="small"
                onDelete={() => this.removeImageFile()}
                variant="outlined"
              />
              <img
                style={{ margin: "8px", width: "80px", height: "80px" }}
                src={URL.createObjectURL(this.state.fileImage)}
              ></img>
            </div>
          )}
        </div>
      </div>
    );
  }

  getQuestionBase64String(postFile, type) {
    let getBase64String = {
      resourceString: postFile,
    };

    PostService.getBase64String(getBase64String)
      .then((response) => {
        var value = response.data.data.base64String;
        var base64String = "";
        if (type === "image") {
          base64String = `data:image;base64,${value}`;
          this.setState({ type: "image/png" });
        } else if (type === "video") {
          base64String = `data:video/mp4;base64,${value}`;
          this.setState({ type: "video/mp4" });
        } else if (type === "audio") {
          base64String = `data:audio/wav;base64,${value}`;
          this.setState({ type: "audio/mpeg" });
        } else if (type === "pdf") {
          base64String = `data:application/pdf;base64,${value}`;
          this.setState({ type: "application/pdf" });
        }

        this.setState({ fileUrl: base64String });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async removeFile(index) {
    if (index == "parent") {
      await this.setState({
        name: "",
        type: null,
        removeStatus: false,

        file: null,
        fileUrl: "",
        fileArray: [],
      });
    } else {
      var inpu = this.state.fileArray;
      if (this.state.fileArray[index]) {
        var val = this.state.fileArray;
        val.splice(index, 1);
        this.setState({
          fileArray: val,
        });
      }
    }

    this.props.fileOptionEnable();
  }

  async removeImageFile() {
    var inpu = this.state.fileImage;
    if (this.state.fileImage) {
      this.setState({
        fileImage: "",
        selectPage: true,
      });
    }

    this.props.fileOptionEnable();
  }

  // handleChange(event)
  // {
  //   this.setState({ removeStatus: false })
  //   let files = event.target.files[0]
  //   this.setState({
  //     name: event.target.files[0].name,
  //     file: files,
  //     fileUrl: URL.createObjectURL(event.target.files[0]),
  //     type: event.target.files[0].type,
  //   }, () => {
  //     this.onFormSubmit()
  //   }
  //   );
  // }

  handleChange(file, url, type) {
    // this.setState({ removeStatus: false })
    // // let files = event.target.files[0]
    // this.setState({
    //   // name: event.target.files[0].name,
    //   file: file,
    //   fileUrl: url,
    //   type: type,
    //   name:file.name
    // }, () => {
    //   this.onFormSubmit()
    // }
    // );
  }

  onFormSubmit(key) {
    let files = this.state.file;
    var type = "file";
    if (key == "image") {
      if (this.checkImageURL(this.state.file.name)) {
        type = "image";
      }
    } else {
      if (this.checkVideoURL(this.state.file[0].name)) {
        type = "video";
      } else if (this.checkAudioURL(this.state.file[0].name)) {
        type = "audio";
      } else if (this.checkPDFURL(this.state.file[0].name)) {
        type = "pdf";
      } else {
        type = "file";
      }
    }
    this.fileUpload(this.state.file, type);
  }

  checkImageURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  checkVideoURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(mp4|webm|ogg)$/) != null;
  }

  checkAudioURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(wav|mp3)$/) != null;
  }

  checkPDFURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(application|pdf)$/) != null;
  }

  fileUpload(file, type) {
    // if (file.size <= 5000000) {
    if (type == "image") {
      console.log(file);
      this.props.fromQuestionImage(file, type);
    } else {
      this.props.fromQuestionMedia(file, type);
    }
  }
}
export default withTranslation()(LearningFileOption);
