import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSinglePostData } from "../../../redux/actions/squareHomeActions";
import moment from "moment";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { API_URL } from "../../../config/app-config";
import "./myPost.css";
import dummygif from "../../../img/dummygif.gif";
import { withTranslation } from "react-i18next";
import dummyimage from "../../../img/default_avatar.jpg";
import * as APPCONFIG from "../../../config/app-config.js";
import VideoPostCard from "./VideoPostCard";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-image-lightbox/style.css";
import FbGridImages from "./ImageView/Images";
import {
  extractNumberFromHref,
  getVideoStatus,
  giveUserAction,
  htmlValidation,
  isContainId,
  validateSpanTag,
} from "../../../utils";
import { needPhotVerification } from "../../../redux/actions/commonApiActions";
import { AiOutlineWarning } from "react-icons/ai";
import YoutubeVideoModel from "../../../components/YoutubeVideoModel";

function SinglePost(props) {
  const [postData, setPostData] = useState(null);
  const [fileImageData, setFileImageData] = useState([]);
  const [fileVideoData, setFileVideoData] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [type, setType] = useState(null);
  const loader = useSelector((state) => state.surveyList.singlePostLoader);
  const dispatch = useDispatch();
  const loginDetails = useSelector(
    (state) => state.loginDetailsApi.loginDetails
  );
  const { t } = props;

  useEffect(() => {
    console.log("Single post api", props.postId);
    props.getSinglePostData(props.postId, props.postType);
  }, [props?.postId]);

  useEffect(() => {
    if (
      !loader &&
      props.singlePost?.postId &&
      props.postId === props.singlePost.postId
    ) {
      console.log("Single post get", props.singlePost);
      setPostData(props.singlePost);
      setType(props.singlePost?.type);
      getMediaFiles(props.singlePost?.postMediaFiles, props.singlePost?.type);
    }
  }, [props.singlePost]);

  const getMediaFiles = async (files, type) => {
    if (type == "image") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
        }
        setFileImageData(fileList);
      }
    } else if (type == "video") {
      let fileLisVideo = [];
      if (files.length > 0) {
        // for (let i = 0; i < files.length; i++) {
        //   if (files[i].thumbnail !== null) {
        //     fileLisVideo.push({
        //       file: files[i].streamUrl,
        //       image: files[i].thumbnail,
        //     });
        //   }
        // }
        setFileVideoData(
          getVideoStatus(
            files,
            loginDetails.uId,
            props.singlePost?.userId,
            loginDetails.uType
          )
        );
      }
    } else if (type == "file" || type == "pdf") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        setFileData(fileList);
      }
    }
  };

  const handleHashTag = (event) => {
    const clickedText = event.target.textContent;
    const regex = /^#[^\s]+$/;

    if (regex.test(clickedText)) {
      const newStr = clickedText.replace(/#/g, "");
      props.history.push(`/${props.workspaceType}/${"hash"}/${newStr}`);
      return;
    }

    let htmlElement = event.target;
    const htmlString = htmlElement?.outerHTML;

    if (validateSpanTag(htmlString)) {
      moveToUserProfile(event, htmlString);
    }
  };
  const moveToUserProfile = (event, htmlString) => {
    const id = extractNumberFromHref(htmlString);
    event.preventDefault();
    event.stopPropagation();
    showFriendsProfile(id);
    // event.preventDefault();
    // event.stopPropagation();
    // const regex =
    //   /<span style="color:#D92267;cursor: pointer;">@(\w+)<\/span>/i;
    // const match = htmlString.match(regex);
    // const value = match ? match[1] : null;
    // props.history.push(`/${props.workspaceType}/${"user"}/${value}`);
    // return;
  };
  const userDisPage = () => {
    if (giveUserAction(loginDetails?.profile_complete)) {
      dispatch(needPhotVerification(true));
    } else {
      if (!props.modelPost) {
        props.history.push(
          `/${props.workspaceType}/userPost/${props.postId}/${props.postType}`
        );
      }
    }
  };
  if (loader) {
    return (
      <div className="skeleton">
        {/* <div className="skeleton-img"></div> */}
        <div className="skeleton-text"></div>
      </div>
    );
  }
  const showFriendsProfile = (userId) => {
    props.history.push(`/${props.workspaceType}/friendDescription/${userId}`, {
      from: window.location.pathname,
    });
  };
  return (
    <div>
      {postData && (
        <>
          <div
            className="about-card box-shadow"
            style={{
              marginTop: "1.5rem",
              pointerEvents: props.modelPost ? "none" : "auto",
              cursor: props.modelPost ? "none" : "pointer",
            }}
            onClick={userDisPage}
          >
            <div className="about-card-header border-0 pb-0">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="post-avatar post-avatar-story postCardImg-me-2 mb-auto">
                    {" "}
                    <img
                      style={{ cursor: "pointer" }}
                      // onClick={() => showFriendsProfile(postData.userId)}
                      className="post-avatar-img rounded-circle"
                      src={
                        API_URL +
                        "api/getUserPhoto/" +
                        postData.userId +
                        "/" +
                        postData.userName
                      }
                      alt="user"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyimage;
                      }}
                    />{" "}
                  </div>
                  <div>
                    <div className="post-nav post-nav-divider">
                      <h6
                        className="post-nav-item post-card-title post-mb-0"
                        style={{
                          color: "#14191e",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        {postData.userName}{" "}
                      </h6>
                      <span className="post-nav-item post-small">
                        {" "}
                        {moment(postData.createdDate).locale("en").format("ll")}
                        {/* {moment(postData.createdDate).format(
                            "DD MMM, h:mm a"
                          )} */}
                      </span>
                      {!props.isGroup && (
                        <div style={{ margin: "0px 2px 4px 6px" }}>
                          {postData.postActive == false &&
                            !postData.pstType == "groupMessage" && (
                              <span class="badge badge-pill post-badge-secondary">
                                {t("Post")}
                              </span>
                              // <div className="postcardPost">Post</div>
                            )}
                          {postData.pstType == "groupMessage" && (
                            <span class="badge badge-pill post-badge-secondary">
                              {postData.subjectName}
                            </span>
                          )}
                          {postData.postType == "postList" &&
                            postData.postActive == false && (
                              <span class="badge badge-pill post-badge-secondary">
                                {t("Post")}
                              </span>
                            )}
                          {postData.postType == "postList" &&
                            postData.postActive == true && (
                              <span class="badge badge-pill post-badge-secondary">
                                {postData.postCategory}
                              </span>
                            )}{" "}
                        </div>
                      )}
                    </div>
                    {props.professionList?.map((option, index) => (
                      <>
                        {postData.professionId == option.id && (
                          <p className="post-mb-0 post-small">{option.name}</p>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <div
                  className="d-flex"
                  style={{
                    cursor: "pointer",
                    alignItems: "baseline",
                    gap: "0.6rem",
                  }}
                >
                  <div className="post-avatar"></div>
                </div>
              </div>
            </div>

            {postData.isDeleted ? (
              <>
                {" "}
                <div class="p-3 mb-2 bg-warning-post  m-2 mb-3 d-flex align-items-center justify-content-center text-danger">
                  <AiOutlineWarning
                    style={{ width: "24px", height: "auto" }}
                    className="mr-2"
                  />
                  <p> {t("Post has been deleted")} !</p>
                </div>
              </>
            ) : (
              <>
                <div className="post-card-body">
                  {type == "text" && postData?.content && (
                    <p onClick={handleHashTag}>
                      {htmlValidation(postData.content)}
                    </p>
                  )}
                  {postData.postType == "postList" &&
                    postData.postCategory == "article" &&
                    postData.article && (
                      <p onClick={handleHashTag}>
                        {htmlValidation(postData?.article?.title)}
                      </p>
                    )}
                  {/* {type == "text" && postData?.content && (
                <p onClick={handleHashTag}>
                  {htmlValidation(postData.content)}
                </p>
              )} */}

                  {postData?.fileContent &&
                    (type == "image" ||
                      type == "video" ||
                      type == "file" ||
                      type == "pdf" ||
                      type == "audio") && (
                      <p className="about-mb-2" onClick={handleHashTag}>
                        {postData?.fileContent &&
                          htmlValidation(postData?.fileContent)}
                      </p>
                    )}
                  {Array.isArray(postData?.youtubeUrls) &&
                    postData?.youtubeUrls.length !== 0 && (
                      <div
                        className="d-flex flex-column mb-2 mt-2"
                        style={{ gap: "1rem" }}
                      >
                        {postData?.youtubeUrls.slice(0, 1).map((item) => (
                          <>
                            <YoutubeVideoModel youtubeId={item} />{" "}
                          </>
                        ))}
                      </div>
                    )}
                  {type == "image" && (
                    <>
                      {fileImageData !== undefined &&
                        fileImageData.length == 1 && (
                          <div class="postCardImageSingle">
                            <FbGridImages
                              images={fileImageData}
                              // onClickImage={subscribeAdvertisement}
                            />
                          </div>
                        )}
                      {fileImageData !== undefined &&
                        fileImageData.length > 1 && (
                          <div class="postCardImage">
                            <FbGridImages
                              images={fileImageData}
                              // onClickImage={subscribeAdvertisement}
                            />
                          </div>
                        )}
                    </>
                  )}
                  {type == "video" && (
                    <div>
                      {fileVideoData?.map((photo) => (
                        <VideoPostCard
                          fileData={photo.file}
                          imageData={photo.image}
                          videoStatus={photo.steamStatus}
                        />
                      ))}
                    </div>
                  )}

                  {type === "pdf" && (
                    <div>
                      {fileData &&
                        fileData?.map((photo) => (
                          <div style={{ margin: "8px" }}>
                            <a
                              // download={photo.originalFileName}
                              style={{ textDecoration: "none", color: "black" }}
                              href={
                                photo.fileName == undefined ||
                                photo.fileName == ""
                                  ? dummygif
                                  : photo.fileName
                              }
                            >
                              <DescriptionOutlinedIcon />
                              {photo.originalFileName}
                            </a>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
const mapDispacthToProps = (dispatch) => {
  return {
    getSinglePostData: (id, type) => dispatch(getSinglePostData(id, type)),
  };
};

const mapStateToProps = function (state) {
  return {
    userCommentList: state.surveyList.userCommentList,
    singlePost: state.surveyList.singlePost,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(SinglePost)));
