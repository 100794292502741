import auth from "./AuthAPI.js";
import Http from "./Http.js";
import * as APPCONFIG from "../config/app-config.js";

class ChatService {
  saveChat(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/savechat`,
      data: formValues,
    });
  }

  getChatistbyId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getMessagebyid`,
      data: formValues,
    });
  }
}

export default new ChatService();
