import React, { useState } from "react";
import Swal from "sweetalert2";

const CustomImageComponent = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileUploadSubmit = async (e) => {
    // form reset on submit
    // Swal.fire({
    //   title: "Updated Successfully",
    //   icon: "success",
    // });
    setSelectedFile(null);
    props.formImageStatus(selectedFile);
  };
  const InputChange = (e) => {
    setSelectedFile(e.target.files);
  };
  const deleteSelectFile = (id) => {
    setSelectedFile(null);
    props.formImageStatus(null);
  };
  console.log("selectedFile", selectedFile);
  return (
    <div className="fileupload-view">
      <div className="row justify-content-center m-0">
        <div className="card w-100">
          <div className="card-body">
            <div className="kb-data-box">
              <div className="kb-modal-data-title">
                <div className="kb-data-title">
                  <h6>Upload Image</h6>
                </div>
              </div>
              <div>
                <div className="kb-file-upload">
                  <div className="file-upload-box">
                    <input
                      type="file"
                      id="fileupload"
                      className="file-upload-input"
                      onChange={InputChange}
                      multiple={false}
                      maxLength={1}
                      accept={"image" + "/*"}
                    />
                    <span>
                      Drag and drop or{" "}
                      <span className="file-link">Choose your files</span>
                    </span>
                  </div>
                </div>
                <div className="kb-attach-box mb-3">
                  {selectedFile && (
                    <div className="file-atc-box">
                      <div>
                        <img
                          style={{
                            margin: "8px",

                            height: "80px",
                          }}
                          src={URL.createObjectURL(selectedFile[0])}
                          alt="img"
                        ></img>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`$kb-buttons-box`}
                  style={{
                    pointerEvents: selectedFile ? "auto" : "none",
                    cursor: selectedFile ? "pointer" : "not-allowed",
                  }}
                >
                  <button
                    onClick={() => fileUploadSubmit()}
                    className="btn btn-primary form-submit"
                  >
                    Upload
                  </button>
                  <button
                    onClick={() => deleteSelectFile()}
                    className="btn btn-danger form-submit ml-4"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomImageComponent;
