import React, { Component } from "react";
import * as APPCONFIG from "../../config/app-config.js";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import AuthService from "../../session/AuthService";
import moment from "moment";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import MyYuukke from "../../api/MyYuukke";
import Swal from "sweetalert2";
import dummyimage from "../../img/banner.jpg";
import banner from "../../img/banner.jpg";
import { withRouter } from "react-router";
import { AiFillMessage } from "react-icons/ai";
import { connect } from "react-redux";
class LearningAppCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 0,
      topic_name: "",
      menuStatewithDelete: false,
    };
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  render() {
    const { t } = this.props;
    const { subjectId, subjectName, fileData, tagsValue, status } = this.props;
    return (
      <div
        className="peopleCardWrapperGroup"
        onClick={() => this.movesubjectidtosubjects(subjectId, subjectName)}
        style={{ cursor: "pointer" }}
      >
        {this.props.fileData?.length !== 0 ? (
          this.props.fileData.slice(0, 1).map((photo, index) => (
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = dummyimage;
              }}
              className="bannerGroupImg"
              src={`${APPCONFIG.API_URL}auth/image/${photo.fileName}`}
              alt="name"
            ></img>
          ))
        ) : (
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = dummyimage;
            }}
            className="bannerGroupImg"
            src={dummyimage}
            alt="name"
          ></img>
        )}

        <div className="groupCardInfor">
          <h2>{subjectName}</h2>
          {
            <p style={{ fontSize: "10px", color: "#b3b3b3" }}>
              {this.props.tagsValue &&
                this.props.tagsValue
                  .slice(0, 1)
                  .map((item) => <p>{item.tagName}</p>)}
            </p>
          }

          {/* <p style={{ marginBottom: "12px", textTransform: "capitalize" }}>
            {status}
          </p> */}
        </div>
      </div>
    );
  }

  getSearchedTagValue = (tags) => {
    const searchKeyword = this.props.searchData || "";
    if (Array.isArray(tags) && tags.length > 0) {
      const result = tags.filter((val) =>
        val.tagName.toLowerCase().startsWith(searchKeyword.toLocaleLowerCase())
      );
      if (result.length > 0) {
        return result[0]?.tagName;
      } else {
        return tags[0].tagName;
      }
    }
    return "-------";
  };
  getTagList(tagList) {
    console.log("tagList", tagList);
    console.log(tagList);
    console.log(this.props.tagList);
    var subjectName = new Array();
    if (tagList !== undefined && tagList.length > 0) {
      for (let i = 0; i < tagList.length; i++) {
        for (let j = 0; j < this.props.tagList.data.length; j++) {
          if (tagList[i].tagId == this.props.tagList.data[j].tagId) {
            subjectName.push(this.props.tagList.data[j].tagName);
          }
        }
        var subjects = subjectName.join(",");

        this.setState({ questionTags: subjects });
      }
    }
  }
  editGroup() {
    this.props.history.push(`/updateGroup/${this.props.subjectId}`);
  }

  deletePost(event, subId) {
    event.preventDefault();
    event.stopPropagation();

    Swal.fire({
      title: "Are you sure to delete this event?",
      text: "",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        var deleteSubId = {
          subjectId: subId,
        };
        MyYuukke.deleteSubjectById(deleteSubId)
          .then((response) => {
            this.props.getGroupsorEventsList();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  movesubjectidtosubjects = (id, subjectName) => {
    var gName = subjectName.trim();
    gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
    this.props.history.push(
      `/${this.props.workspaceName}/learningAppDesription/${id}/${gName}`
    );

    // this.props.gotoGroups(
    //   subjectId,
    //   true,
    //   subjectName,
    //   "fgroup",
    //   grouporeventFiles,
    //   fileType,
    //   subjectDescription,
    //   memberShipPrice,
    //   memberShipType,
    //   expiryDate,
    //   createdDate,
    //   displayName,
    //   userId,
    //   status,
    //   tagListId,
    //   startDate
    // );
  };
}

const mapStateToProps = function (state) {
  return {
    tagList: state.commonApi.taglist,
    workspaceName: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(LearningAppCard)));

// export default withTranslation()(withRouter(GroupsCard));
