import React, { Component } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextEditorQuestion from "../../components/TextEditorQuestion";
import { Multiselect } from "react-widgets";
import ProfileService from "../../api/ProfileService";
import ForumService from "../../api/ForumService";
import AuthService from "../../session/AuthService";
import { ToastContainer, toast } from "react-toastify";
import QuestionFileOption from "./QuestionFileOption";
import "../css/mysocial.css";
import "../css/mysocialprofile.css";
import "../css/yuukkeTutoring.css";
import BlockUi from "react-block-ui";
import Form from "react-bootstrap/Form";
import i18n from "../../i18n";
import { withTranslation } from "react-i18next";
import "react-widgets/styles.css";
import Grid from "@material-ui/core/Grid";
import Swal from "sweetalert2";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { connect } from "react-redux";
import {
  getDashboardList,
  getMyQuestionsList,
} from "../../redux/actions/dashboardActions";
import { getAllSubjectList } from "../../redux/actions/subjectActions";
import { getMyLibraryQuestionList } from "../../redux/actions/MyLibraryQuestionActions";
import { getQuestionsList } from "../../redux/actions/questionActions";
import { getAllCommonSubjectList } from "../../redux/actions/commonApiActions";
import moment from "moment";
import Utils from "../../utils/Utils";
class CreateChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: false,
      fileUrl: null,
      type: null,
      mediaPost: "",
      isChecked: false,
      teaching: [],
      tags: [],
      question: "",
      classDetails: [],
      addTagsState: false,
      fileOption: false,
      gradeDetails: [],
      questionDescribed: "",
      content: "",
      editState: false,
      gradeId: "",
      questionTitleCount: 0,
      descriptionCount: 0,
      curriculumList: [],
      curriculumId: "",
    };
    this.block = this.block.bind(this);
    this.myRef = React.createRef();
    this.getallSubject = this.getallSubject.bind(this);
    this.editorStateValues = this.editorStateValues.bind(this);
    this.handleTeachingMultiSelect = this.handleTeachingMultiSelect.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.getQuestionMediaFiles = this.getQuestionMediaFiles.bind(this);
    this.handleGrade = this.handleGrade.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.fileOptionEnable = this.fileOptionEnable.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.getAllCurriculum = this.getAllCurriculum.bind(this);
    this.handleCurriculum = this.handleCurriculum.bind(this);
    this.getGradeByCurriculumId = this.getGradeByCurriculumId.bind(this);
    this.getGradeByCurriculumIdUpdate =
      this.getGradeByCurriculumIdUpdate.bind(this);
    this.UpdateQuestionInAllpages = this.UpdateQuestionInAllpages.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.getallSubject();
    this.getAllCurriculum();
    console.log("loginDetails");
    console.log(this.props.loginDetails.cId);
    if (this.props.loginDetails.cId != null) {
      this.setState({ curriculumId: this.props.loginDetails.cId });
      this.getGradeByCurriculumId(this.props.loginDetails.cId);
    }
    //this.props.getAllCommonSubjectList()
    if (this.props.loginDetails.gradeId != null) {
      this.setState({ gradeId: this.props.loginDetails.gradeId });
    }

    var selectedSubjectList = [];
    if (!Object.keys(this.props.editObject).length) {
    } else {
      for (let i = 0; i < this.props.editObject.questionTags.length; i++) {
        for (let j = 0; j < this.props.subjectsList.data.length; j++) {
          if (
            this.props.editObject.questionTags[i].subjectId ==
            this.props.subjectsList.data[j].subjectId
          ) {
            var obj = {
              subjectId: this.props.subjectsList.data[j].subjectId,
              subjectName: this.props.subjectsList.data[j].subjectName,
            };

            selectedSubjectList.push(obj);
          }
        }
      }

      var hobbies = [];
      var hobbiesStr = [];

      for (let i = 0; i < selectedSubjectList.length; i++) {
        var obj = selectedSubjectList[i];
        if (!hobbiesStr.includes(obj.subjectId)) {
          hobbiesStr.push(obj.subjectId);
          hobbies.push(obj);
        }
      }

      if (this.props.editObject && this.props.editObject.questionId) {
        this.setState(
          {
            editState: true,
            questionId: this.props.editObject.questionId,
            question: this.props.editObject.question,
            content: this.props.editObject.questionDescribed,
            questionDescribed: this.props.editObject.questionDescribed,
            gradeId: this.props.editObject.gradeId[0]
              ? this.props.editObject.gradeId[0].gradeId
              : "",
            teaching: hobbies,
            questionFile: this.props.editObject.questionFile,
            type: this.props.editObject.type,
            curriculumId: this.props.editObject.programId,
            originalFileName: this.props.editObject.originalFileName,
            isChecked: this.props.editObject.markAsPrivate,
          },
          () => {}
        );
        this.getGradeByCurriculumIdUpdate(this.props.editObject.programId);
        var htmlString = this.props.editObject.questionDescribed;
        var plainString = htmlString.replace(/<[^>]+>/g, "");
        this.setState({
          questionTitleCount: this.props.editObject.question.length,
          descriptionCount: plainString.length,
        });
      }
    }
  }

  render() {
    const { t } = this.props;
    const { handleViewAllQuestions } = this.props;
    return (
      <div className="askQuestionWrapper">
        <ToastContainer position="top-right" autoClose={5000} />
        <div
          className="ansBackQuestion flexDefault"
          onClick={handleViewAllQuestions}
        >
          <ArrowBackIcon />
          <p>{t("Browse All List")}</p>
        </div>
        <p className="askAQuestionTitle">{t("Create Group")} </p>
        <div className="askAQuestionCardWrapper">
          <BlockUi
            tag="div"
            blocking={this.state.blocking}
            message={t("Loading, please wait")}
            color="#A00030"
          >
            <div className="askAQuestionInputGroup inputLarge">
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <label className="formLabel">{t("Chat Required")}</label>
                <label className="smart-option">
                  <input
                    type="checkbox"
                    checked={this.state.isChecked}
                    defaultChecked={this.state.isChecked}
                    value={this.state.isChecked}
                    onChange={this.toggleChange}
                  />{" "}
                  Make as private
                </label>
                <div>
                  {" "}
                  <Tooltip title="Only your network can view this question">
                    <IconButton className="infoButton">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>

                <input
                  type="text"
                  name="question"
                  value={this.state.question}
                  maxLength="250"
                  onChange={this.handleForm}
                />
                <p
                  className="smallText"
                  style={
                    this.state.questionTitleCount > 250
                      ? { color: "red" }
                      : { color: "green" }
                  }
                >
                  {" "}
                  {250 - this.state.questionTitleCount} {t("characters left")}
                </p>
              </Grid>
            </div>
            <div className="askAQuestionDescription">
              <label className="formLabel">{t("Description Required")}</label>
              <TextEditorQuestion
                ref={this.myRef}
                editorStateValue={this.editorStateValues}
                editorValue={this.state.questionDescribed}
              />
              <p
                className="boxText"
                style={
                  this.state.descriptionCount > 4000
                    ? { color: "red" }
                    : { color: "green" }
                }
              >
                {" "}
                {4000 - this.state.descriptionCount} {t("characters left")}
              </p>
            </div>
            <QuestionFileOption
              fileOption={this.state.fileOption}
              fileOptionEnable={this.fileOptionEnable}
              fromQuestionMedia={this.getQuestionMediaFiles}
              editObject={this.props.editObject}
            ></QuestionFileOption>
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={12} sm={12} md={12}>
                <Form.Group controlId="region">
                  <Form.Label>Program</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.curriculumId}
                    onChange={this.handleCurriculum}
                  >
                    <option value="">{t("Choose")}</option>
                    {this.state.curriculumList &&
                      this.state.curriculumList.map(
                        (curriculum, curriculumId) => (
                          <option
                            value={curriculum.curriculumId}
                            name="curriculumId"
                            id="curriculumId"
                            key={curriculumId}
                          >
                            {curriculum.curriculumName}
                          </option>
                        )
                      )}
                  </Form.Control>
                </Form.Group>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Form.Group controlId="region">
                  <Form.Label>{t("Choose Grade")}</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.gradeId}
                    onChange={this.handleGrade}
                  >
                    <option value="">{t("Choose")}</option>
                    {this.state.gradeDetails &&
                      this.state.gradeDetails.map((country, gradeId) => (
                        <option
                          value={country.gradeId}
                          name="gradeId"
                          id="gradeId"
                          key={gradeId}
                        >
                          {country.gradeName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className="askAQuestionInputGroup">
                  <Form.Label>{t("Subject Required")}</Form.Label>
                  <Multiselect
                    className="profileFormInput aboutInputAddButton"
                    required
                    data={this.state.app_subjects}
                    valueField="subjectId"
                    textField="subjectName"
                    dataKey="subjectId"
                    name="teaching"
                    value={this.state.teaching}
                    onChange={(value) => this.handleTeachingMultiSelect(value)}
                  />
                </div>
              </Grid>
            </Grid>
            {/* <label className="formLabel">{t('Choose File')}</label>
            <QuestionFileOption fileOption={this.state.fileOption} fileOptionEnable={this.fileOptionEnable} fromQuestionMedia={this.getQuestionMediaFiles} editObject={this.props.editObject}></QuestionFileOption>   */}{" "}
          </BlockUi>
          
          {!this.state.editState && (
            <div className="askAQuestionBtn">
              <button onClick={(e) => this.onFormSubmit(e)}>
                {t("POST YOUR QUESTION")}
              </button>
            </div>
          )}
          {this.state.editState && (
            <div className="askAQuestionBtn">
              <button onClick={(e) => this.onUpdateSubmit(e)}>
                {t("UPDATE YOUR QUESTION")}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  handleGrade(event) {
    if (this.state.curriculumId == "") {
      Swal.fire({
        title: "Please choose valid program before choosing grade",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
        reverseButtons: true,
      }).then((result) => {});
    } else {
      this.setState({
        gradeId: event.target.value,
      });
    }
  }

  getQuestionMediaFiles(files, type) {
    this.setState({ file: files, type: type });
  }

  editorStateValues(values) {
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length == 1) {
      this.setState({
        content: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        content: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  }

  handleTeachingMultiSelect(subject_value) {
    this.setState({
      teaching: subject_value,
    });
  }

  getallSubject() {
    this.setState({ app_subjects: this.props.subjectsList.data });
  }

  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        this.setState({ gradeDetails: result.data.grade });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fileOptionEnable() {
    this.setState({ fileOption: false, questionFile: null });
  }

  onFormSubmit(e) {
    const formData = new FormData();
    if (this.state.question === "") {
      toast.warn("Please enter valid question");
    } else if (this.state.teaching.length === 0) {
      toast.warn("Please choose subjects");
    } else if (this.state.question.length >= 250) {
      toast.warn("Entered question should not be above 250 characters");
    } else if (this.state.curriculumId == undefined) {
      toast.warn("Please choose program");
    } else if (this.state.curriculumId === "") {
      toast.warn("Please choose program");
    } else if (this.state.gradeId == undefined) {
      toast.warn("Please choose grade level");
    } else if (this.state.gradeId === "") {
      toast.warn("Please choose grade level");
    } else if (
      this.state.question != "" &&
      this.state.curriculumId != "" &&
      this.state.gradeId != ""
    ) {
      if (this.state.file == undefined || this.state.file == "") {
        formData.append("userName", this.props.loginDetails.dName);
        formData.append("questionDescription", this.state.content);
        formData.append("questionName", this.state.question);
        formData.append("questionGradeId", this.state.gradeId);
        formData.append("programId", this.state.curriculumId);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("Type", "text");
        for (let i = 0; i < this.state.teaching.length; i++) {
          formData.append("tagDescription", this.state.teaching[i].subjectName);
          formData.append("subjectId", this.state.teaching[i].subjectId);
        }
      } else {
        formData.append("userName", this.props.loginDetails.dName);
        formData.append("QuestionFiles", this.state.file);
        formData.append("questionDescription", this.state.content);
        formData.append("questionName", this.state.question);
        formData.append("questionGradeId", this.state.gradeId);
        formData.append("programId", this.state.curriculumId);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("Type", this.state.type);
        for (let i = 0; i < this.state.teaching.length; i++) {
          formData.append("tagDescription", this.state.teaching[i].subjectName);
          formData.append("subjectId", this.state.teaching[i].subjectId);
        }
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.block(true);
      ForumService.saveQuestion(formData, config)
        .then((response) => {
          var result = response.data;
          this.myRef.current.onClear();
          this.setState({
            content: "",
            question: "",
            teaching: [],
            isChecked: !this.state.isChecked,
            fileOption: true,
            file: "",
            curriculumId: "",
            gradeId: "",
            questionTitleCount: 0,
            descriptionCount: 0,
          });
          this.props.getAllSubjectList("Groups", 0);
          this.UpdateQuestionInAllpages(
            result.data.questions,
            result.data.questionTags
          );
          this.block(false);
          this.props.handleViewAllQuestions();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error in saving question");
        });
    }
  }

  onUpdateSubmit(e) {
    const formData = new FormData();
    if (this.state.question === "") {
      toast.warn("Please enter valid question");
    } else if (this.state.teaching.length === 0) {
      toast.warn("Please choose subjects");
    } else if (this.state.question.length >= 250) {
      toast.warn("Entered question should not be above 250 characters");
    } else if (this.state.curriculumId == undefined) {
      toast.warn("Please choose program");
    } else if (this.state.curriculumId === "") {
      toast.warn("Please choose program");
    } else if (this.state.gradeId == undefined) {
      toast.warn("Please choose grade level");
    } else if (this.state.gradeId === "") {
      toast.warn("Please choose grade level");
    } else if (this.state.question != "" && this.state.gradeId != "") {
      if (this.state.questionFile == null && this.state.file == undefined) {
        formData.append("userName", this.props.loginDetails.dName);
        formData.append("questionDescription", this.state.content);
        formData.append("questionName", this.state.question);
        formData.append("questionGradeId", this.state.gradeId);
        formData.append("questionId", this.state.questionId);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("Type", "text");
        formData.append("programId", this.state.curriculumId);
        for (let i = 0; i < this.state.teaching.length; i++) {
          formData.append("tagDescription", this.state.teaching[i].subjectName);
          formData.append("subjectId", this.state.teaching[i].subjectId);
        }
      } else if (
        this.state.questionFile != null &&
        this.state.file == undefined
      ) {
        formData.append("userName", this.props.loginDetails.dName);
        formData.append("questionDescription", this.state.content);
        formData.append("questionName", this.state.question);
        formData.append("questionId", this.state.questionId);
        formData.append("questionGradeId", this.state.gradeId);
        formData.append("programId", this.state.curriculumId);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("Type", this.state.type);
        for (let i = 0; i < this.state.teaching.length; i++) {
          formData.append("tagDescription", this.state.teaching[i].subjectName);
          formData.append("subjectId", this.state.teaching[i].subjectId);
        }
      } else if (
        this.state.questionFile != null &&
        this.state.file != undefined
      ) {
        formData.append("userName", this.props.loginDetails.dName);
        formData.append("QuestionFiles", this.state.file);
        formData.append("questionDescription", this.state.content);
        formData.append("questionName", this.state.question);
        formData.append("questionId", this.state.questionId);
        formData.append("questionGradeId", this.state.gradeId);
        formData.append("programId", this.state.curriculumId);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("Type", this.state.type);
        for (let i = 0; i < this.state.teaching.length; i++) {
          formData.append("tagDescription", this.state.teaching[i].subjectName);
          formData.append("subjectId", this.state.teaching[i].subjectId);
        }
      } else if (
        this.state.questionFile == null &&
        this.state.file != undefined
      ) {
        formData.append("userName", this.props.loginDetails.dName);
        formData.append("QuestionFiles", this.state.file);
        formData.append("questionDescription", this.state.content);
        formData.append("questionName", this.state.question);
        formData.append("questionId", this.state.questionId);
        formData.append("questionGradeId", this.state.gradeId);
        formData.append("programId", this.state.curriculumId);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("Type", this.state.type);
        for (let i = 0; i < this.state.teaching.length; i++) {
          formData.append("tagDescription", this.state.teaching[i].subjectName);
          formData.append("subjectId", this.state.teaching[i].subjectId);
        }
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.block(true);
      ForumService.updateQuestion(formData, config)
        .then((response) => {
          var result = response.data;
          this.myRef.current.onClear();
          this.setState({
            content: "",
            question: "",
            teaching: [],
            isChecked: !this.state.isChecked,
            fileOption: true,
            questionTitleCount: 0,
            descriptionCount: 0,
          });
          this.props.getDashboardList(0, this.props.loginDetails.uId);
          // this.props.getMyQuestionsList(0);
          this.props.getAllSubjectList();
          this.UpdateQuestionInAllpages(
            result.data.questions,
            result.data.questionTags
          );
          this.block(false);
          this.props.handleViewAllQuestions();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error in saving question");
        });
    }
  }

  block(blocking) {
    this.setState({ blocking: blocking });
  }

  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
      questionTitleCount: event.target.value.length,
    });
  }

  getAllCurriculum() {
    this.setState({
      curriculumList: this.props.curriculumtrackList.curriculum,
    });
  }

  handleCurriculum(event) {
    this.setState({
      curriculumId: event.target.value,
      gradeId: "",
    });
    this.getGradeByCurriculumId(event.target.value);
  }

  getGradeByCurriculumId(value) {
    this.setState({ gradeId: "" });
    let getGradeByCurriculumId = {
      curriculumId: value,
    };
    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        var result = response.data;

        this.setState({ gradeDetails: response.data.data.gradeListById });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getGradeByCurriculumIdUpdate(value) {
    let getGradeByCurriculumId = {
      curriculumId: value,
    };

    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        var result = response.data;

        this.setState({ gradeDetails: response.data.data.gradeListById });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  UpdateQuestionInAllpages(question, tags) {
    var QuestionId = question.questionId;
    var condition = this.props.QandAquestionList.filter(
      (data) => data.questionId == QuestionId
    );
    var Libcondition = this.props.topQuestions.filter(
      (data) => data.questionId == QuestionId
    );
    if (condition.length > 0) {
      for (var j = 0; j < this.props.QandAquestionList.length; j++) {
        if (this.props.QandAquestionList[j].questionId == QuestionId) {
          var tObj = question;

          var createdDate = tObj.createdDate;
          moment(createdDate).format("DD/MM/yyyy");
          var Dates = Utils.getLastAskedDate(createdDate);
          this.props.QandAquestionList[j].answer = tObj.answer;
          this.props.QandAquestionList[j].answerSize = tObj.answerSize;
          this.props.QandAquestionList[j].createdDate = tObj.createdDate;
          this.props.QandAquestionList[j].questionDescription =
            tObj.questionDescription;
          this.props.QandAquestionList[j].questionId = tObj.questionId;
          this.props.QandAquestionList[j].questionName = tObj.questionName;
          this.props.QandAquestionList[j].questionTags = tObj.questionTags;
          this.props.QandAquestionList[j].questionviewers =
            tObj.questionviewers;
          this.props.QandAquestionList[j].questionType = tObj.questionType;
          this.props.QandAquestionList[j].solved = tObj.solved;
          this.props.QandAquestionList[j].updatedDate = tObj.updatedDate;
          this.props.QandAquestionList[j].userId = tObj.userId;
          this.props.QandAquestionList[j].userName = tObj.userName;
          this.props.QandAquestionList[j].views = tObj.views;
          this.props.QandAquestionList[j].votes = tObj.votes;
          this.props.QandAquestionList[j].gradeId = tObj.grade;
          this.props.QandAquestionList[j].questionFile = tObj.questionFile;
          this.props.QandAquestionList[j].lastAsked = Dates;
          this.props.QandAquestionList[j].programId = tObj.programId;
          this.props.QandAquestionList[j].originalFileName =
            tObj.originalFileName;
          this.props.QandAquestionList[j].markAsPrivate = tObj.markAsPrivate;
          this.props.QandAquestionList[j].star = tObj.questionLikesArray.filter(
            (star) => star.userId == this.props.loginDetails.uId
          );

          this.props.QandAquestionList[j].status = tObj.status;
          this.props.QandAquestionList[j].answerBlockStatus =
            tObj.answerBlockStatus;
        }
      }
    } else {
      var createdDate = question.createdDate;
      moment(createdDate).format("DD/MM/yyyy");
      var Dates = Utils.getLastAskedDate(createdDate);
      var question_list = {
        answer: question.answer,
        answerSize: question.answerSize,
        createdDate: question.createdDate,
        questionDescription: question.questionDescription,
        questionId: question.questionId,
        questionName: question.questionName,
        questionTags: tags,
        questionviewers: question.questionviewers,
        questionType: question.questionType,
        solved: question.solved,
        updatedDate: question.updatedDate,
        userId: question.userId,
        userName: question.userName,
        views: question.views,
        votes: question.votes,
        gradeId: question.grade,
        questionFile: question.questionFile,
        lastAsked: Dates,
        programId: question.programId,
        questionLikesArray: question.questionLikesArray,
        originalFileName: question.originalFileName,
        markAsPrivate: question.markAsPrivate,
        star: question.questionLikesArray.filter(
          (star) => star.userId == this.props.loginDetails.uId
        ),
        pageNo: 1,
        status: question.status,
        answerBlockStatus: question.answerBlockStatus,
      };

      this.props.QandAquestionList.splice(0, 0, question_list);
    }

    if (Libcondition.length > 0) {
      for (var j = 0; j < this.props.topQuestions.length; j++) {
        if (this.props.topQuestions[j].questionId == QuestionId) {
          var tObj = question;

          var createdDate = tObj.createdDate;
          moment(createdDate).format("DD/MM/yyyy");
          var Dates = Utils.getLastAskedDate(createdDate);
          this.props.topQuestions[j].answer = tObj.answer;
          this.props.topQuestions[j].answerSize = tObj.answerSize;
          this.props.topQuestions[j].createdDate = tObj.createdDate;
          this.props.topQuestions[j].questionDescription =
            tObj.questionDescription;
          this.props.topQuestions[j].questionId = tObj.questionId;
          this.props.topQuestions[j].questionName = tObj.questionName;
          this.props.topQuestions[j].questionTags = tObj.questionTags;
          this.props.topQuestions[j].questionviewers = tObj.questionviewers;
          this.props.topQuestions[j].questionType = tObj.questionType;
          this.props.topQuestions[j].solved = tObj.solved;
          this.props.topQuestions[j].updatedDate = tObj.updatedDate;
          this.props.topQuestions[j].userId = tObj.userId;
          this.props.topQuestions[j].userName = tObj.userName;
          this.props.topQuestions[j].views = tObj.views;
          this.props.topQuestions[j].votes = tObj.votes;
          this.props.topQuestions[j].gradeId = tObj.grade;
          this.props.topQuestions[j].questionFile = tObj.questionFile;
          this.props.topQuestions[j].lastAsked = Dates;
          this.props.topQuestions[j].programId = tObj.programId;
          this.props.topQuestions[j].originalFileName = tObj.originalFileName;
          this.props.topQuestions[j].markAsPrivate = tObj.markAsPrivate;
          this.props.topQuestions[j].star = tObj.questionLikesArray.filter(
            (star) => star.userId == this.props.loginDetails.uId
          );
          // this.props.topQuestions[j].pageNo = this.props.topQuestions[j].pageNo;
          this.props.topQuestions[j].status = tObj.status;
          this.props.topQuestions[j].answerBlockStatus = tObj.answerBlockStatus;
        }
      }
    } else {
      var createdDate = question.createdDate;
      moment(createdDate).format("DD/MM/yyyy");
      var Dates = Utils.getLastAskedDate(createdDate);
      var question_list = {
        answer: question.answer,
        answerSize: question.answerSize,
        createdDate: question.createdDate,
        questionDescription: question.questionDescription,
        questionId: question.questionId,
        questionName: question.questionName,
        questionTags: tags,
        questionviewers: question.questionviewers,
        questionType: question.questionType,
        solved: question.solved,
        updatedDate: question.updatedDate,
        userId: question.userId,
        userName: question.userName,
        views: question.views,
        votes: question.votes,
        gradeId: question.grade,
        questionFile: question.questionFile,
        lastAsked: Dates,
        programId: question.programId,
        questionLikesArray: question.questionLikesArray,
        originalFileName: question.originalFileName,
        markAsPrivate: question.markAsPrivate,
        star: question.questionLikesArray.filter(
          (star) => star.userId == this.props.loginDetails.uId
        ),
        pageNo: 1,
        status: question.status,
        answerBlockStatus: question.answerBlockStatus,
      };

      this.props.topQuestions.splice(0, 0, question_list);
    }
  }
}

const mapStateToProps = function (state) {
  return {
    wallList: state.dashboard.dashboardList,
    loading: state.dashboard.loading,
    tagDatas: state.subjects.list,
    questionLists: state.myquestions.myQuestionList[0],
    totalItems: state.myquestions.myQuestionList[1],
    topQuestions: state.mylibraryquestion.list,
    solvedTotalitems: state.mylibraryquestion.totalItems,
    QandAquestionList: state.topquestList.list,
    QandAquestTotalitems: state.topquestList.totalItems,
    subjectsList: state.commonApi.subjectslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    curriculumtrackList: state.commonApi.curriculumtracklist,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getDashboardList: () => dispatch(getDashboardList(0)),
    getAllSubjectList: (type, page) => dispatch(getAllSubjectList(type, page)),
    getMyQuestionsList: () => dispatch(getMyQuestionsList(0)),
    getMyLibraryQuestionList: () =>
      dispatch(getMyLibraryQuestionList("All", 1)),
    getQuestionsList: () =>
      dispatch(getQuestionsList("unanswered", 1, 0, true, true)),
    getAllCommonSubjectList: () => dispatch(getAllCommonSubjectList(true, "")),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(CreateChat));
