import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import dummyimage from "../../images/Courses/mentorship.png";
import { API_URL, BASE_URL } from "../../config/app-config";
import { useDispatch, useSelector } from "react-redux";
import { getEventDetails } from "../../redux/actions/calendarAction";
import { HiOutlineUserCircle } from "react-icons/hi";
import moment from "moment";
import BlockUi from "react-block-ui";
import { IoVideocamOutline } from "react-icons/io5";
import { getMeetingUrl, handleCopy, meetingJoin } from "../../utils";
import { FaRegShareSquare } from "react-icons/fa";
import SocialShare from "../../components/SocialShare";

const EventContainer = (props) => {
  const filter = [
    // { title: "All Events", key: "all" },
    { title: "Upcoming Events", key: "upcoming" },
    { title: "Past Events", key: "past" },
  ];
  const dispatch = useDispatch();
  let currentDate = moment(new Date()).format("yyyy-MM-DD");
  const { eventList, eventLoader, eventSeeMore } = useSelector(
    (state) => state.event
  );
  const workspaceType = useSelector(
    (state) => state.loginDetailsApi.loginDetails.userWorkSpace?.slug
  );
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({
    eventList: [],
    page: 0,
    filterType: "past",
    searchKey: "",
    socialShare: false,
    url: "",
    activeIndex: null,
  });
  const { t } = props;

  useEffect(() => {
    let data = {
      page: 0,
      taskType: state.filterType,
      searchKey: state.searchKey,
    };
    dispatch(getEventDetails(data));
  }, []);
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const eventFilter = (type, value, pagination) => {
    updateState("activeIndex", null);
    let data;
    if (type === "filter") {
      updateState("filterType", value);
      data = {
        page: 0,
        taskType: value,
        searchKey: state.searchKey,
      };
    } else if (type === "searchKey") {
      updateState("searchKey", value);
      data = {
        page: 0,
        taskType: state.filterType,
        searchKey: value,
      };
    } else {
      updateState("page", value);
      data = {
        page: value,
        taskType: state.filterType,
        searchKey: state.searchKey,
      };
    }

    dispatch(getEventDetails(data));
  };
  const eventVideo = (e, id) => {
    if (state.filterType === "past") {
      props.history.push(`/${workspaceType}/event-videos/${id}`);
    }
  };
  console.log("state", state);
  return (
    <BlockUi
      tag="div"
      message={t("Loading, please wait")}
      style={{ minHeight: "130px" }}
      className="wallLoadingPage"
      blocking={eventLoader}
    >
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
        <h3 className="listHomeTitle">{t("Events")}</h3>
      </div>
      <div className="row mt-1">
        <section className="col-12 col-md-12 col-lg-4  ">
          <TextField
            id=""
            label=""
            style={{ width: "100%", height: "35px", background: "white" }}
            variant="outlined"
            onChange={(e) => eventFilter("searchKey", e.target.value)}
            placeholder={t("Search Event...")}
            value={state.searchKey}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <section
            className="cardWrapper-2 font-bold d-flex flex-column  mt-4"
            style={{ gap: "1rem" }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                paddingBottom: "5px",
              }}
            >
              {t("Filter events by type")} ...
            </p>

            <hr class="hr-tag m-0" />
            {filter.map((item, index) => (
              <span
                className={`p-2 ${
                  item.key === state.filterType ? "nav-active" : ""
                }`}
                onClick={() => eventFilter("filter", item.key)}
                style={{
                  background:
                    item.key === state.filterType ? "#a52a2a0a" : "white",
                  cursor: "pointer",
                  fontWeight: "500",
                }}
              >
                {t(item.title)}
              </span>
            ))}
          </section>
        </section>
        <section className="col-12 col-md-12 col-lg-8">
          {eventList &&
            eventList.map((item, index) => (
              <section
                onClick={(e) => eventVideo(e, item.calendarId)}
                key={index}
                className="d-flex ring  overflow-hidden position-relative  justify-content-center cardWrapper-2 mb-4 flex-wrap position-relative"
                style={{
                  gap: "2rem",
                  cursor: state.filterType === "past" ? "pointer" : "auto",
                }}
                // onClick={() => directoryDescription(item?.slug)}
              >
                <div className="bd-container h-atuo">
                  <img
                    src={item.image}
                    // src={`${APPCONFIG.API_URL}auth/image/${item?.serviceImage}`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = dummyimage;
                    }}
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <span class="badge badge-pill badge-info">Online</span>
                  <h6 className="font-weight-bold mt-1  mb-0">
                    {item.taskName}
                  </h6>
                  <span
                    className="font-weight-bold"
                    style={{ fontSize: "12px", color: "slategrey" }}
                  >
                    Start Date :{" "}
                    {moment(item.startDate).locale("en").format("LLL")}
                  </span>
                  <span
                    style={{ fontSize: "12px", color: "slategrey" }}
                    className="d-block"
                  >
                    <HiOutlineUserCircle
                      style={{ width: "15px" }}
                      className="h-auto "
                    />{" "}
                    Hosted by {item.userName}
                  </span>
                  <p
                    style={{ fontSize: "12px" }}
                    className="elpsis-line text-secondary mt-2"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                  <div className="d-flex flex-wrap g-1">
                    {state.filterType === "upcoming" && (
                      // moment(
                      //   moment(item.startDate).format("yyyy-MM-DD")
                      // ).isSameOrBefore(currentDate)
                      <button
                        className="b-17 meeting-btn text-black"
                        onClick={(e) => {
                          setState({
                            ...state,
                            socialShare: true,
                            url: `${BASE_URL}meeting-ref/${item.slug}`,
                            activeIndex: index,
                          });
                        }}
                      >
                        {" "}
                        <FaRegShareSquare className="svg-icons mr-2" />
                        Share Meeting Link
                      </button>
                    )}

                    {item.meetingStatus === "LIVE" && (
                      <button
                        className="b-17 meeting-btn text-black"
                        onClick={(e) =>
                          meetingJoin(
                            e,
                            "public",
                            item.link,
                            item.meetingInApplication
                          )
                        }
                      >
                        {" "}
                        <IoVideocamOutline className="svg-icons mr-2" /> Join
                        Meeting
                      </button>
                    )}
                    {state.socialShare && state.activeIndex === index && (
                      <div className="event-hide-wrap">
                        <SocialShare
                          url={state.url}
                          content={`${item.taskName} meeting Link`}
                          closeModel={() => updateState("socialShare", false)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </section>
            ))}
          {!eventLoader &&
            eventSeeMore &&
            eventList &&
            eventList.length > 0 && (
              <div
                class="d-flex justify-content-center mt-4"
                style={{ marginBottom: "12rem" }}
              >
                <button onClick={() => eventFilter("seemore", state.page + 1)}>
                  {t("See more")}
                </button>
              </div>
            )}
          {!eventLoader && eventList && eventList.length === 0 && (
            <div className="aboutMyRequestWrapper mt-4">
              <p style={{ textAlign: "center" }}>
                {state.searchKey
                  ? t("No more events available")
                  : t("Events not available")}
              </p>
            </div>
          )}
        </section>
      </div>
    </BlockUi>
  );
};

export default withTranslation()(withRouter(EventContainer));
