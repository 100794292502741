import likeImg from "../../img/community/postlike/like.png";
import celebrateImg from "../../img/community/postlike/celebrate.png";
import supportImg from "../../img/community/postlike/support.png";
import loveImg from "../../img/community/postlike/love.png";
import insightfulImg from "../../img/community/postlike/insightful.png";
import curiousImg from "../../img/community/postlike/curious.png";
import academic_advice from "../../images/academic_advice.png";
import loginBg from "../../images/loginBg.jpg";
// import ThumbUpIcon from "@material-ui/icons/ThumbUp";
export const eventDatas = [
  {
    startDate: "2022-02-04T09:45",
    endDate: "2022-02-05T11:00",
    status: 1,
    title: "Meeting",
  },
  {
    startDate: "2021-12-16T03:30",
    endDate: "2021-12-16T04:30",
    status: 2,
    title: "Go to a gym",
  },
  {
    startDate: "2021-12-17T02:30",
    endDate: "2021-12-17T03:30",
    status: 3,
    title: "Go to a hospital",
  },
];

export const suggestedFriendListDatas = [
  {
    profile: {
      biography: "senior Developer",
      classes: "4c",
      createdDate: "2020-09-16T09:57:23.000+0000",
      dob: "2020-09-15T18:30:00.000+0000",
      firstName: "Nazhath",
      gender: "male",
      id: 8,
      lastName: "Lamiya",
      modifiedDate: "2020-09-16T09:57:23.000+0000",
      section: "vidyalaya",
      userId: 8,
    },
    userPhoto: "https://www.w3schools.com/howto/img_avatar.png",
  },
  {
    profile: {
      biography: "Junior Developer",
      classes: "4c",
      createdDate: "2020-09-16T09:57:23.000+0000",
      dob: "2020-09-15T18:30:00.000+0000",
      firstName: "Rajesh",
      gender: "male",
      id: 8,
      lastName: "Johnson",
      modifiedDate: "2020-09-16T09:57:23.000+0000",
      section: "vidyalaya",
      userId: 8,
    },
    userPhoto: "https://www.w3schools.com/howto/img_avatar.png",
  },
  {
    profile: {
      biography: "Front end Developer",
      classes: "4c",
      createdDate: "2020-09-16T09:57:23.000+0000",
      dob: "2020-09-15T18:30:00.000+0000",
      firstName: "Rai",
      gender: "male",
      id: 8,
      lastName: "jason",
      modifiedDate: "2020-09-16T09:57:23.000+0000",
      section: "vidyalaya",
      userId: 8,
    },
    userPhoto: "https://www.w3schools.com/howto/img_avatar.png",
  },
];

export const likeOptionDatas = [
  {
    icon: `${likeImg}`,
    label: "Like",
    colorCode: "#0073b1",
  },
  {
    icon: `${celebrateImg}`,
    label: "Celebrate",
    colorCode: "#2f7b15",
  },
  {
    icon: `${supportImg}`,
    label: "Support",
    colorCode: "#665ed0",
  },
  {
    icon: `${loveImg}`,
    label: "Love",
    colorCode: "#e55800",
  },
  {
    icon: `${insightfulImg}`,
    label: "Insightful",
    colorCode: "#9b8443",
  },
  {
    icon: `${curiousImg}`,
    label: "Curious",
    colorCode: "#a76969",
  },
];

export const courseList = [
  {
    icon: `${loginBg}`,
    courseName: "Personal Advice -5 Ways to Max Out Your Brain",
  },
  {
    icon: `${loginBg}`,
    courseName: "Personal Advice – Tips for Growing an Entrepreneurial Mindset",
  },
  {
    icon: `${loginBg}`,
    courseName: "Career Advice – 8 Tips for Video Conferencing",
  },
  {
    icon: `${academic_advice}`,
    courseName: "Career Advice – Hosting Zoom Meetings Etiquette",
  },
  {
    icon: `${likeImg}`,
    courseName: "Career Advice – How to ace a Skype Interview	Career Guidance",
  },
  {
    icon: `${likeImg}`,
    courseName: "Like",
  },
];
