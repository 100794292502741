import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { metaTags } from "../utils";
import { toast } from "react-toastify";
import { AiOutlineCopy } from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";

const SocialShare = ({ url, content, closeModel, closeNotNeed }) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);

      toast.success("Copied");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  return (
    <div className="social-icons-share">
      {!closeNotNeed && (
        <IoCloseCircleOutline
          className="svg-icons ml-auto mr-2 mt-2 textWhite"
          onClick={() => closeModel()}
        />
      )}
      <p
        href={url}
        className="f-13 bg-color text-white"
        onClick={() => handleCopy()}
        style={{ textDecoration: "underline", cursor: "pointer" }}
      >
        <AiOutlineCopy class="copy-icon mr-1" />
        Copy Link
      </p>
      <h5 className="font-weight-bold text-white mt-3">
        Share with your friends by using these
      </h5>

      <ul className="social-icons-share p-3 mt-0">
        <li className="text-white">
          <WhatsappShareButton
            url={url}
            title={content}
            separator="  "
            metaTags={metaTags}
          >
            <WhatsappIcon
              size={52}
              round
              style={{ background: "lightslategray" }}
            />
          </WhatsappShareButton>
          Whatsapp
        </li>
        <li className="text-white">
          <TelegramShareButton
            url={url}
            title={content}
            separator="  "
            metaTags={metaTags}
          >
            <TelegramIcon
              size={52}
              round
              style={{ background: "lightslategray" }}
            />
          </TelegramShareButton>
          Telegram
        </li>
        <li className="text-white">
          <LinkedinShareButton
            url={url}
            title={content}
            separator="  "
            metaTags={metaTags}
          >
            <LinkedinIcon
              size={52}
              round
              style={{ background: "lightslategray" }}
            />
          </LinkedinShareButton>
          Linkedin
        </li>
        <li className="text-white">
          <FacebookShareButton
            url={url}
            title={content}
            separator="  "
            metaTags={metaTags}
          >
            <FacebookIcon
              size={52}
              round
              style={{ background: "lightslategray" }}
            />
          </FacebookShareButton>
          FaceBook
        </li>
        <li className="text-white">
          <TwitterShareButton
            url={url}
            title={content}
            separator="  "
            metaTags={metaTags}
          >
            <TwitterIcon
              size={52}
              round
              style={{ background: "lightslategray" }}
            />
          </TwitterShareButton>
          Twitter
        </li>
      </ul>
    </div>
  );
};

export default SocialShare;
