import React, { Component } from "react";
import AuthService from "../../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  getOverallPostList,
  removeDeletePost,
} from "../../../redux/actions/SubjectquestionActions";
import { Card, CardContent } from "@material-ui/core";
import BlockUi from "react-block-ui";
import Grid from "@material-ui/core/Grid";
import Pagination from "react-js-pagination";
import PostCard from "../../Home/HomeUpdated/PostCard";
import { getLikeCountPopUp } from "../../../redux/actions/PostCardActions";
var firstTimeLoad = false;
var secondTimeLoad = false;
var tpage = 0;

class GroupFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNo: 1,
      noMoreState: false,
      postList: [],
      activeInterestPage: 1,
      blocking: false,
      PostmessageLists: [],
    };
    this.pageNo = this.pageNo.bind(this);
    this.likeStatus = this.likeStatus.bind(this);
    this.getInterestingTopQuestions =
      this.getInterestingTopQuestions.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.submittogetQuestionList = this.submittogetQuestionList.bind(this);
    this.deletePost = this.deletePost.bind(this);
    this.deleteQuestionCards = this.deleteQuestionCards.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.setState({ PostmessageLists: this.props.PostmessageLists });
    firstTimeLoad = true;
    secondTimeLoad = true;
  }

  componentWillReceiveProps(nextProps) {}
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.PostmessageLists &&
      prevProps.PostmessageLists !== this.props.PostmessageLists
    ) {
      this.setState({ PostmessageLists: this.props.PostmessageLists });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <>
          <BlockUi
            tag="div"
            style={{ minHeight: "100px" }}
            blocking={this.props.SubQuestionloading}
            message={t("Loading, please wait")}
            color="#A00030"
          >
            {Array.isArray(this.state.PostmessageLists) &&
              this.state.PostmessageLists.map((communityPostData, index) => {
                return (
                  <PostCard
                    onShareList={this.sharePost}
                    ondeleteList={this.deletePost}
                    postList={this.state.PostmessageLists}
                    data={communityPostData}
                    login={this.props.loginDetails}
                    friends={this.props.commonApi}
                    likestatusUpdate={this.likestatusUpdate}
                    subscribeUpdate={this.subscribeUpdate}
                    commendsUpdate={this.commendsUpdate}
                    sharePostOnWay={this.sharePostOnWay}
                    updatePost={this.updatePost}
                    updateSurveyCompletion={(a) =>
                      this.updateSurveyCompletion(a)
                    }
                    isGroup={true}
                    communityId={+this.props.subjectId}
                    index={index}
                  />
                );
              })}
          </BlockUi>
        </>

        {this.state.PostmessageLists.length === 0 && (
          <Card>
            <CardContent>
              <center>
                <p
                  style={{ textAlign: "center", marginTop: "12px" }}
                  onClick={this.pageNo}
                >
                  {t("No Post Available")}
                </p>
              </center>
            </CardContent>
          </Card>
        )}
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            {this.state.PostmessageLists.length > 0 && (
              // <p>{t("Showing 1-10 Posts")}</p>
              <p>
                {t("Showing")} - {this.state.PostmessageLists.length}{" "}
                {t("Community Posts")}
              </p>
            )}
            {this.state.PostmessageLists.length == 0 && (
              <p>{t("Showing 0 Community Posts")}</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {
              <Pagination
                itemsCountPerPage={10}
                activePage={this.state.activeInterestPage}
                totalItemsCount={this.props.totalItemsLists}
                pageRangeDisplayed={5}
                onChange={this.getInterestingTopQuestions.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
              />
            }
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <div className="paginationWrapperRight">
              <p className="mr-3">{t("Go to page")}</p>
              <input
                type="text"
                className="mr-3"
                style={{ width: "30px" }}
                value={this.state.pageNo}
                name="pageNo"
                onChange={this.handleForm}
              />
              <button
                onClick={() => this.submittogetQuestionList(this.state.pageNo)}
              >
                {t("Go")}
              </button>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
  sharePostOnWay = (result) => {
    var jsonList = this.state.PostmessageLists;
    var saveData = result;
    jsonList.unshift(saveData);
    this.setState({
      PostmessageLists: jsonList,
    });
    console.log("after share : ", this.state.PostmessageLists);
  };
  commendsUpdate = (pid, count) => {
    var postData = this.state.PostmessageLists;

    if (count) {
      const finalResult = postData.map((person) => {
        if (person.postId === pid) {
          person.commendsCount = count;
        }
        return person;
      });
      this.setState({
        PostmessageLists: finalResult
          ? finalResult
          : this.state.PostmessageLists,
      });
      console.log("state value changed : ", this.state.PostmessageLists);
    }
  };
  sharePost = (value) => {
    console.log("share post");
    var itemuserPost = value;
    console.log("USERDETAILS : uid : " + JSON.stringify(itemuserPost));
    var temppostMedia = [];
    if (itemuserPost.hasOwnProperty("fileData")) {
      itemuserPost.fileData.forEach((element) => {
        var jsonItm = {
          postId: itemuserPost.userpost.postId,
          fileName: element,
          fileType: "message",
        };
        temppostMedia.push(jsonItm);
      });

      itemuserPost.userpost.postMediaFiles = temppostMedia;
    }
    var jsonUser = {
      userProfile: {
        userId: this.props.loginDetails.uId,
        firstName: this.props.loginDetails.fName,
        lastName: this.props.loginDetails.lName,
        displayName: this.props.loginDetails.dName,
      },
    };

    itemuserPost.userpost.user = jsonUser;
    console.log(itemuserPost);
    var jsonList = this.state.PostmessageLists;
    jsonList.unshift(itemuserPost.userpost);
    this.setState({
      PostmessageLists: jsonList,
    });
  };
  likestatusUpdate = async (result, userLike, index, likeStatus) => {
    const PostmessageLists = this.state.PostmessageLists;
    var postId = result.data.postId;
    var like = result.data.userLike;
    var allLikes = result.data.allLikes;
    console.log("postId", postId);
    console.log("PostmessageLists", PostmessageLists);
    const oldLikeStatus =
      PostmessageLists?.userlikeType === null && !PostmessageLists?.userlike;
    const likeCount = oldLikeStatus
      ? PostmessageLists.likesCount + 1
      : !likeStatus
      ? PostmessageLists.likesCount - 1
      : PostmessageLists.likesCount;
    if (like !== undefined) {
      const finalResult = PostmessageLists.map((person) => {
        if (person.postId === postId) {
          person.userlike = result.data.userlike;
          person.userlikeType = userLike ? like.likeReactionType : null;
          person.likesCount = likeCount;
          person.allLikes = allLikes;
        }
        return person;
      });
      console.log("finalResult : ", finalResult);
      console.log("result", result);
      console.log("newLike", oldLikeStatus);
      // if (oldLikeStatus) {
      //   this.props.getLikeCountPopUp(index);
      // }
      await this.setState({
        PostmessageLists: finalResult,
      });
    }
  };
  updatePost = (result) => {
    let postData = this.state.PostmessageLists;
    let upData = result;

    if (result) {
      var finalResult = [];
      for (let i = 0; i < postData.length; i++) {
        if (postData[i].postId === result.postId) {
          finalResult.push(upData);
        } else {
          finalResult.push(postData[i]);
        }
      }
      this.setState({
        PostmessageLists: finalResult,
      });
    }
  };

  updateSurveyCompletion = (result) => {
    let postData = this.state.postData;
    if (result) {
      const finalResult = postData.map((person) => {
        if (person.moderationId === result) {
          person.surveyList.isCompleted = true;
        }
        return person;
      });
      this.setState({
        postData: finalResult,
      });
    }
  };
  subscribeUpdate = (result, type, userBookMark, id) => {
    if (type === "subscribe") {
      var postData = this.state.PostmessageLists;
      var postId = result.postId;
      var subscribeId = result.subscribeId;
      var isSubscribe = result.isSubscribe;
      if (postId !== undefined) {
        const finalResult = postData.map((person) => {
          if (person.postId === postId) {
            person.postSubscribe.isSubscribe = isSubscribe;
            person.postSubscribe.subscribeId = subscribeId;
          }
          return person;
        });
        console.log("finalResult : ", finalResult);
        this.setState({
          PostmessageLists: finalResult,
        });
        console.log("state value changed : ", this.state.postData);
      }
    } else {
      var postId = result;
      var postData = this.state.PostmessageLists;
      const finalResult = postData.map((person) => {
        if (person.postId === postId) {
          person.userBookMark = person.userBookMark ? false : true;
        }
        return person;
      });
      console.log("finalResult : ", finalResult);
      this.setState({
        PostmessageLists: finalResult,
      });
    }
  };
  deleteQuestionCards(questionId) {
    // this.props.getOverallPostList(tpage, this.state.subjectId);
    console.log("fl 1", this.state.PostmessageLists);
    var postList = this.state.PostmessageLists;
    postList = this.state.PostmessageLists.filter(
      (item) => item.postId != questionId
    );
    this.setState({
      PostmessageLists: postList,
    });
    console.log("fl 2", this.state.PostmessageLists);
  }
  submittogetQuestionList(pageNo) {
    this.block(true);
    this.setState({ backTypeQuestion: this.state.typeQuestion });
    var page = pageNo - 1;
    firstTimeLoad = true;
    secondTimeLoad = false;
    this.props.getOverallPostList(page, this.props.subjectId);

    this.setState({
      activeInterestPage: pageNo,
    });

    this.block(false);
  }
  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  block(blocking) {
    this.setState({ blocking: blocking });
  }
  deletePost(moderationId, postId) {
    // console.log(this.state.PostmessageLists);
    // var postList = this.state.PostmessageLists;
    // postList = this.state.PostmessageLists.filter(
    //   (item) => item.moderationId != moderationId
    // );
    // this.setState({
    //   PostmessageLists: postList,
    // });

    // this.props.getOverallPostList(tpage, this.props.subjectId);
    this.props.removeDeletePost(moderationId);
    // this.props.getOverallPostList(tpage, this.props.subjectId);
  }
  async getInterestingTopQuestions(pageNo) {
    this.block(true);
    // this.setState({ backTypeQuestion: this.state.typeQuestion });
    tpage = pageNo - 1;

    firstTimeLoad = true;
    secondTimeLoad = false;
    this.props.getOverallPostList(tpage, this.props.subjectId);
    this.setState({
      activeInterestPage: pageNo,
    });

    this.block(false);
  }
  pageNo() {
    this.props.loadMorePosts();
  }

  likeStatus() {
    this.props.likeStatus();
  }
}

const mapStateToProps = function (state) {
  return {
    totalItemsLists: state.SubjectQuestion.totalItemsList,
    PostmessageLists: state.SubjectQuestion.postLists,
    SubQuestionloading: state.SubjectQuestion.SubQuestionloading,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getOverallPostList: (pageNo, subId) =>
      dispatch(getOverallPostList(pageNo, subId)),
    removeDeletePost: (data) => dispatch(removeDeletePost(data)),
    getLikeCountPopUp: (data) => dispatch(getLikeCountPopUp(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(GroupFeed)));
