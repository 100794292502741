import React, { Component } from "react";
import { Container } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import AuthService from "../../session/AuthService";
import AskQuestion from "../Community/AskQuestion";
import { connect } from "react-redux";
import { getCourses } from "../../redux/actions/courseActions";
import MyLibraryCourses from "../Classroom/MyLibrary/MyLibraryCourses";
import HomeClassroom from "./Courses/HomeClassroom";

class Classroom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      selectedValue: null,
      showAskQuestion: false,
      editObject: {},
    };

    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleshowAskQuestion = this.handleshowAskQuestion.bind(this);
    this.handleViewAllQuestions = this.handleViewAllQuestions.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
  }

  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
    });
  }

  handleshowAskQuestion() {
    this.setState({
      showAskQuestion: !this.state.showAskQuestion,
      tabIndex: this.state.showAskQuestion ? 2 : null,
    });
    this.props.history.push(`/ask-a-question`);
  }

  handleViewAllQuestions() {
    this.setState({
      tabIndex: 1,
      showAskQuestion: false,
    });
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  render() {
    const { t } = this.props;
    const { tabIndex, showAskQuestion } = this.state;
    return (
      <div className="screenInnerGap">
        {/* <Container> */}
        {!showAskQuestion ? (
          tabIndex === 0 ? (
            <>
              <HomeClassroom />
            </>
          ) : tabIndex === 1 ? (
            <MyLibraryCourses />
          ) : (
            <></>
          )
        ) : (
          <AskQuestion
            editObject={this.state.editObject}
            handleViewAllQuestions={this.handleViewAllQuestions}
          />
        )}
        {/* </Container> */}
      </div>
    );
  }

  editQuestion(obj) {
    if (obj) {
      this.setState({
        showAskQuestion: true,
        editObject: obj,
      });
    } else {
      this.setState({
        editObject: {},
      });
    }
  }
}

const mapStateToProps = function (state) {
  return {
    list: state.courses.list,
    lastSync: state.courses.lastSync,
    loading: state.courses.loading,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getCourses: (lastSync) => dispatch(getCourses(lastSync)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(Classroom));
