import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withTranslation } from "react-i18next";

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedBack: [],
      title: "",
      tickekPriorityData: {},
      ticketPriorityOptions: {},
      ticketPirorityValues: [],
    };
  }

  componentDidMount() {
    this.getViewPieChart(this.props.pieChartData);
  }
  componentDidUpdate(prevProps) {
    if (this.props.pieChartData !== prevProps.pieChartData) {
      this.getViewPieChart(this.props.pieChartData);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <>
          <Dialog
            fullWidth
            maxWidth="sm"
            open={true}
            onClose={() => this.props.onClose()}
          >
            <DialogTitle>{t("Views")}</DialogTitle>
            <DialogContent dividers="paper">
              <Row>
                <Col xl="12">
                  <Line
                    data={this.state.tickekPriorityData}
                    options={this.state.ticketPriorityOptions}
                  />
                </Col>
              </Row>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.props.onClose()}>{t("Close")}</Button>
            </DialogActions>
          </Dialog>
        </>
      </div>
    );
  }
  getViewPieChart = (data) => {
    // console.log('cc', data)
    var chartData = data;

    var labels = []; //["like", "angry","love"]//,"Completely"]//,"Slightly","VeryMuch"]
    var values = [];
    var listValues = [];

    for (var key in chartData) {
      var value = chartData[key];
      labels.push(moment(value.createdDate).locale("en").format("ll"));
      values.push(value.postCount);
    }

    var v_chartOptions = {
      bezierCurve: false,
      elements: {
        line: {
          tension: 0,
        },
      },
    };

    var v_chartData = {
      datasets: [
        {
          label: "Reach",
          data: values,
          backgroundColor: [
            "white",
            // 'rgba(220,220,220,1)',
            // '#ff6384',
            // '#36a2ec',
            // '#ffcd57',
            //'#33ff33'
          ],
          borderColor: "#d92267",
          // fill: true,
          lineTension: 0.5,
          pointStrokeColor: "#ff6384",
          fillColor: "rgba(220,220,220,0.5)",
          pointColor: "#ff6384",
          strokeColor: "rgba(220,220,220,1)",
        },
      ],
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: labels,
    };

    this.setState({
      tickekPriorityData: v_chartData,
      ticketPriorityOptions: v_chartOptions,
      ticketPirorityValues: listValues,
    });
  };
}

const mapStateToProps = function (state) {
  return {
    pieChartData: state.surveyList.pieChartData,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(PieChart)));
