import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";
import AuthService from "../session/AuthService";
import seller from "../images/seller.jpg";
import Swal from "sweetalert2";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  needPhotVerification,
  sellerRequest,
} from "../redux/actions/commonApiActions";
import { giveUserAction } from "../utils";
import SharePopup from "../components/ReferralModel";

class SellerRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      app_friendlist: [],
      searchKey: "",
      friends: [],
      updatePhoneNumber: false,
    };
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="aboutGroupWrapper d-flex flex-column">
        <div className="seller-container">
          <img src={seller} alt="seller" />
          <h4>{t("Grow doing what you love")}</h4>
        </div>
        <div className="p-4 d-flex flex-column align-items-center">
          <p className="text-secondary">
            {t(
              "We believe in providing support to women who have always dreamt of building their own businesses — the opportunity to make a meaningful income by doing what they love."
            )}
          </p>
          <h6 className="mt-5 d-flex flex-wrap align-items-center mr-auto">
            {t(
              "Building a women’s community devoted to economic empowerment and cultural inclusivity"
            )}{" "}
            -{" "}
            <p className="text-secondary ml-1" style={{ fontSize: "13px" }}>
              {t("that’s why we started Yuukke.")}
            </p>
          </h6>
          <div style={{ height: "7rem", marginTop: "3rem" }}>
            <div class="indicator">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <button onClick={() => this.register()}>{t("Register Here")}</button>
        </div>
        {this.state.updatePhoneNumber && (
          <SharePopup
            type="referral"
            id={this.props.loginDetails.referralCode}
            content={``}
            handleClose={this.handleClose}
            url={"http://yuukke.com/promotion.php"}
          />
        )}
        {/* <div className="aboutGroupWrapper" >
       
            {
                    <iframe
                      src="https://yuukke.com/merchant/"
                      frameborder="0"
                      style={{
                        overflow: "scroll",
                        height: "91vh",
                        width: "100%",
                      }}
                      height="100%"
                      width="100%"
                    />
                  }
          </div> */}
      </div>
    );
  }
  handleClose = (type, number) => {
    this.setState({
      updatePhoneNumber: false,
    });
    if (type === "success" && number) {
      this.makeSellerRegister(number);
    }
  };
  register = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      if (!this.props.loginDetails?.phoneNumber) {
        this.setState({
          updatePhoneNumber: true,
        });
      } else {
        Swal.fire({
          title: this.props.t("You want to enroll as a seller"),
          icon: "warning",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: this.props.t("Yes"),
          cancelButtonText: this.props.t("No"),
          reverseButtons: false,
        }).then((result) => {
          if (result.value) {
            Swal.fire({
              title: this.props.t("Registered successfully"),
              icon: "success",
              type: "success",
              showConfirmButton: true,
            }).then((result) => {
              this.makeSellerRegister(this.props.loginDetails?.phoneNumber);
            });
          }
        });
      }
    }
  };
  makeSellerRegister = (number) => {
    this.props.sellerRequest(number);
    this.props.history.push(`/${this.props.workspaceType}/home`);
  };
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    sellerRequest: (number) => dispatch(sellerRequest(number)),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(SellerRegistration)));
