import React, { useEffect, useState } from "react";
import PostService from "../../api/PostService";
import BlockUi from "react-block-ui";
import { API_URL, JITSI_URL } from "../../config/app-config";
import dummyimage from "../../img/default_avatar.jpg";
import { BiVideo } from "react-icons/bi";
import moment from "moment";
import { generateUlr } from "../../utils";

const ScheduledCard = (props) => {
  const [state, setState] = useState({
    memberList: [],
    loader: false,
  });
  useEffect(() => {
    let data = {
      mentorId: props.id,
    };
    PostService.getMeetingList(data)
      .then((response) => {
        setState({
          ...state,
          loader: false,
          memberList: response.data.data.meetingDetails,
        });
        console.log("Res", response);
      })
      .catch((err) => {
        console.log("e", err);
      });
  }, [props.id]);
  const joinMeeting = (url) => {
    generateUlr(`${JITSI_URL}${url}?authenticated=true`);
    // window.open(`${JITSI_URL}/${url}?authenticated=true#config`, "_blank");
  };
  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "90px" }}
      className="wallLoadingPage"
      blocking={state.loader}
    >
      <div class="about-card" style={{ marginTop: "1.5rem" }}>
        {state.memberList?.length !== 0 && (
          <div class="mentor-selected-slot">
            {state.memberList &&
              state.memberList.map((data, index) => (
                <div
                  key={index}
                  className="friendsCardWrapper-new d-flex flex-column  flex-wrap"
                  style={{ gap: "1rem" }}
                >
                  <div
                    className="post-avatar post-avatar-story mb-auto"
                    style={{ height: "5rem", width: "5rem" }}
                  >
                    <img
                      onClick={() => this.showFriendsProfile(data.userId)}
                      class="friend-avatar-img rounded-circle"
                      src={`${API_URL}api/getUserPhoto/${
                        data.userId
                      }/${"name"}`}
                      alt="user"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyimage;
                      }}
                    />
                  </div>

                  <div
                    className="d-flex flex-column mb-auto mt-3 align-items-center"
                    style={{ gap: "1rem" }}
                  >
                    <h5 className="m-0 font-weight-bold">{data.userName}</h5>
                    <h6 className="m-0">{data.title}</h6>
                    <p
                      className="m-0 text-secondary"
                      style={{ fontSize: "12px" }}
                    >
                      <span className="font-weight-bold">Start Time : </span>
                      {moment(data.startDate)
                        .locale("en")
                        .format("MMMM Do YYYY, h:mm a")}
                    </p>
                    {/* <p className="slot-box">
                            {t("Slot")} : {data?.timeSlot?.name}
                          </p>
                          <p className="slot-box">
                            {t("Day")} : {data?.day}
                          </p> */}
                    <div class=" d-flex">
                      <button
                        size="small"
                        style={{ color: "white" }}
                        onClick={() => joinMeeting(data.url)}
                      >
                        <BiVideo
                          style={{
                            height: "24px",
                            width: "auto",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        />
                        Join Meeting
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
        {!state.loader && state.memberList && state.memberList.length === 0 && (
          <div className="FriendsCardWrapper m-3">No meeting present</div>
        )}
      </div>
    </BlockUi>
  );
};

export default ScheduledCard;
