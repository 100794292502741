import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Form, Modal, Card } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { AiOutlineCloseCircle } from "react-icons/ai";
import PostService from "../../api/PostService";
import PostService_old from "../../api/PostService_old";
import { toast } from "react-toastify";

const questionEx = [
  {
    questionId: 43,
    question: "How may language you now",
    answer: ["1", "2", "3 or more"],
    answerType: "select",
    answerIds: [
      {
        answer: "1",
        answerId: 71,
      },
      {
        answer: "2",
        answerId: 72,
      },
      {
        answerId: 73,
        answer: "3 or more",
      },
    ],
  },
  {
    answerId: 74,
    questionId: 44,
    question: "Add your bio",
    answer: "Text here.  . . ",
    answerType: "text",
  },
  {
    questionId: 45,
    question: "Are you completed collage ?",
    answer: ["Yes", "No"],
    answerType: "select",
    answerIds: [
      {
        answer: "Yes",
        answerId: 75,
      },
      {
        answer: "No",
        answerId: 76,
      },
    ],
  },
];

function MentorQuestionModel(props) {
  const { t, closeModel, timeSlots, selfAssessment } = props;
  const [assessment, setAssessment] = useState([]);
  const [nextPage, setNextPage] = useState(false);
  const [slotTime, setSlotTime] = useState(null);
  const [selectDay, setSelectDay] = useState(null);
  const [show, setShow] = useState(false);
  const [dontNeed, setDontNeed] = useState(false);
  const radioButton = (answerId, index) => {
    const check = assessment.some((item) => item.answerId === answerId);
    return check;
  };
  const [slotName, setSlotName] = useState("");
  console.log("selfAssessment", selfAssessment);
  useEffect(() => {
    if (!selfAssessment) {
      setNextPage(true);
      setDontNeed(true);
    }
  }, [selfAssessment]);

  const modelClose = () => {
    setShow(!show);
    props.closeModel();
  };
  const checkAssessment = (questionId) => {
    const check = assessment.some((item) => item.questionId === questionId);
    return check;
  };
  const findAndUpdate = (answerId, type, data, questionId) => {
    const list = assessment.map((item) => {
      if (item.questionId === questionId) {
        if (type === "select") {
          item.answerId = answerId;
        } else {
          item.answer = data;
        }
      }
      return item;
    });
    setAssessment([...list]);
    console.log("list1", list);
  };
  const updateDetails = (answerId, questionId, data, type, index) => {
    if (checkAssessment(questionId)) {
      findAndUpdate(answerId, type, data, questionId);
    } else {
      let obj;
      if (type === "select") {
        obj = {
          answerId: answerId,
          questionId: questionId,
          answerType: type,
        };
      } else {
        obj = {
          answerId: answerId,
          questionId: questionId,
          answerType: type,
          answer: data,
        };
      }
      setAssessment((prevAssessment) => [...prevAssessment, obj]);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!dontNeed && props.questions.length !== assessment.length) {
      toast.warning(`${props.t("Please submit all the fields")}`);
    } else if (!selectDay && timeSlots.length !== 0) {
      toast.warning(`${props.t("Select your slot time")}`);
    } else {
      let data = {
        mentorId: props.mentorId,
        selfAssessment: assessment,
        day: selectDay,
        timeSlot: slotName,
      };

      PostService_old.userRequestMentor(data)
        .then((response) => {
          props.closeModel();
          props.changeStatus();
          toast.success("Request sent successfully ...");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Some thing went wrong ,Please check ofter some time !");
        });
    }
  };
  const selectedSlot = (day, slot, value) => {
    setSlotTime(slot);
    setSelectDay(day);
    setSlotName(value);
  };
  console.log("slotName", slotName);
  return (
    <div>
      <Modal
        style={{ right: 0 }}
        // className="right"
        show="true"
        className={show ? "right slide-in show" : "right slide-in"}
        onHide={modelClose}
      >
        <Modal.Header>
          {t("FILL THE REQUIRED DETAILS")}
          <AiOutlineCloseCircle
            onClick={modelClose}
            style={{
              cursor: "pointer",
              float: "right",
              marginBottom: "8px",
              width: "20px",
              height: "20px",
              color: "#000000",
            }}
          />
        </Modal.Header>
        <div
          className="tab-pane-view"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="profile"
        >
          <div className="right-model">
            <Form id="profileForm">
              <Card className="profileCard">
                <Card.Body>
                  {!dontNeed && !nextPage ? (
                    <>
                      {props.questions.map((item, index) =>
                        item.answerType === "select" ? (
                          <div className="mb-2">
                            <div className="d-flex font-weight-bold">
                              <p className="model-dot">
                                {index + 1} . {item.question}
                              </p>
                            </div>
                            <div
                              className="d-flex flex-column align-items-start mb-2 mt-2 ml-4"
                              style={{ gap: "1rem" }}
                            >
                              {item.answerIds.map((data) => (
                                <div
                                  className="d-flex"
                                  style={{ gap: "1rem", cursor: "pointer" }}
                                  onClick={() =>
                                    updateDetails(
                                      data.answerId,
                                      item.questionId,
                                      data.answerId,
                                      item.answerType,
                                      index
                                    )
                                  }
                                >
                                  <input
                                    key={data.answerId}
                                    type="radio"
                                    checked={radioButton(data.answerId, index)}
                                    value={data.answerId}
                                  />{" "}
                                  <p>{data.answer}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div className="mb-2">
                            <div className="d-flex font-weight-bold ">
                              <p className="model-dot mb-2">
                                {index + 1} . {item.question}
                              </p>
                            </div>
                            <div className="d-flex flex-column align-items-start ml-4">
                              <TextField
                                fullWidth
                                margin="normal"
                                variant="filled"
                                placeholder={item.question}
                                onChange={(e) =>
                                  updateDetails(
                                    item.answerId,
                                    item.questionId,
                                    e.target.value,
                                    item.answerType,
                                    index
                                  )
                                }
                              />
                            </div>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <div
                      className="d-flex flex-column mt-2"
                      style={{ gap: "1rem" }}
                    >
                      <h6>Pick your slot time</h6>
                      {timeSlots &&
                        timeSlots.map(
                          (data, aIndex) => (
                            <>
                              <div class="askAQuestionCardWrapper p-2">
                                <h6 style={{ fontWeight: "600" }}>
                                  {data.day}
                                </h6>
                                <div className="time-slot-grid">
                                  {data?.slots.map((slot, bIndex) => (
                                    <p
                                      className={
                                        selectDay === data.day &&
                                        slotTime === slot.name
                                          ? "mentor-select-model time-slot-model"
                                          : "time-slot-model"
                                      }
                                      onClick={() =>
                                        selectedSlot(
                                          data.day,
                                          slot.name,
                                          slot.slot
                                        )
                                      }
                                    >
                                      {slot.name}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </>
                          )
                          // )}
                        )}
                      {timeSlots.length === 0 && (
                        <div class="askAQuestionCardWrapper">
                          No Time slot available
                        </div>
                      )}
                    </div>
                  )}
                </Card.Body>

                <Modal.Footer>
                  <div className="d-flex justify-content-between w-100">
                    {!dontNeed && !nextPage && (
                      <>
                        <button onClick={modelClose}>{t("Close")}</button>
                        <button onClick={() => setNextPage(true)}>
                          {t("Next")}
                        </button>{" "}
                      </>
                    )}
                    {nextPage && (
                      <>
                        {!dontNeed && (
                          <button onClick={() => setNextPage(false)}>
                            {t("Previous")}
                          </button>
                        )}
                        <button onClick={onSubmit}>{t("Submit")}</button>{" "}
                      </>
                    )}

                    {/* <button>{t("Next")}</button> */}

                    {/* <button onClick={closeModel}> {t("Close")}</button>:

                    <button onClick={onSubmit}> {t("Submit")}</button> */}
                  </div>
                </Modal.Footer>
              </Card>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
}
const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    loginUserType: state.loginDetailsApi.loginDetails?.uType,
  };
};
export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(MentorQuestionModel)));
