import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import ProfileService from "../../api/ProfileService";
import AuthService from "../../session/AuthService";
import i18n from "../../i18n";
import { withTranslation } from "react-i18next";

class PrivacySettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstNameActive: false,
      lastNameActive: false,
      displayNameActive: true,
      dobActive: false,
      emailActive: false,
      connectionsActive: false,
      memberActive: false,
      forumActive: false,
      friendRequestActive: false,
    };
    this.FirstNameActive = this.FirstNameActive.bind(this);
    this.LastNameActive = this.LastNameActive.bind(this);
    this.DisplayNameActive = this.DisplayNameActive.bind(this);
    this.EmailNameActive = this.EmailNameActive.bind(this);
    this.DobNameActive = this.DobNameActive.bind(this);
    this.getPrivacySettingData = this.getPrivacySettingData.bind(this);
    this.ConnectionsActive = this.ConnectionsActive.bind(this);
    this.MemberActive = this.MemberActive.bind(this);
    this.ForumActive = this.ForumActive.bind(this);
    this.FriendRequestActive = this.FriendRequestActive.bind(this);
  }

  componentDidMount() {
    this.getPrivacySettingData();
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }
  render() {
    const { t } = this.props;
    return (
      <div className="screenInnerGap">
        <div className="privacyCardWrapper">
          <div className="privacyCardInnerWrapper">
            <h4 className="listHomeTitle">
              {t("Profile & network visibility")}
            </h4>
            <p
              style={{
                color: "gray",
                paddingLeft: "8px",
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              {t(
                "Make your profile and contact info only visible to those you choose"
              )}
            </p>
          </div>
          <div style={{ marginBottom: "20px" }}>
            {/* <div className="privacyCardInnerWrapper">
                            <p style={{ color: "#000000", padding: "8px", cursor: "pointer" }}>Profile viewing options</p>
                            <p style={{ color: "gray", paddingLeft: "8px", fontSize: "12px", cursor: "pointer" }}>Choose whether you're visible or viewing in private mode</p>
                        </div> */}
            {/* <div className="privacyCardInnerWrapper">
                            <p style={{ color: "#000000", padding: "8px", cursor: "pointer" }}>Connections</p>
                            <p style={{ color: "gray", paddingLeft: "8px", fontSize: "12px", cursor: "pointer" }}>Choose if your connections can see your friends list</p>
                        </div> */}
            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Do you want other members to view your connections?")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6} s>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="memberActive"
                        id={"memberActive"}
                        checked={this.state.memberActive}
                        onClick={() =>
                          this.MemberActive(
                            this.state.memberActive ? false : true
                          )
                        }
                      />
                      <label className="label" htmlFor={"memberActive"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <p style={{ paddingTop: "20px" }}>{t("All User")}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t(
                      "Do you want your connections to view your connections?"
                    )}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6} s>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="connectionsActive"
                        id={"connectionsActive"}
                        checked={this.state.connectionsActive}
                        onClick={() =>
                          this.ConnectionsActive(
                            this.state.connectionsActive ? false : true
                          )
                        }
                      />
                      <label className="label" htmlFor={"connectionsActive"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <p style={{ paddingTop: "20px" }}>{t("All User")}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t(
                      "Do you want to show your Display Name  and Profile Photo in forum community?"
                    )}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6} s>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="forumActive"
                        id={"forumActive"}
                        checked={this.state.forumActive}
                        onClick={() =>
                          this.ForumActive(
                            this.state.forumActive ? false : true
                          )
                        }
                      />
                      <label className="label" htmlFor={"forumActive"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <p style={{ paddingTop: "20px" }}>{t("All User")}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* <div className="privacyCardInnerWrapper">

                            <Grid container spacing={1}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <p style={{ color: "#000000", padding: "8px", cursor: "pointer" }}>Do you want others to view your first name?</p>
                                    <p style={{ color: "gray", paddingLeft: "8px", fontSize: "12px", cursor: "pointer" }}>Choose yes or no</p>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={6} s>
                                    <div style={{ display: "inline-flex", paddingRight: "2px", float: "right" }}>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="checkbox"
                                                name="firstNameActive"
                                                id={"firstNameActive"}
                                                checked={this.state.firstNameActive}
                                                onClick={() =>
                                                    this.FirstNameActive(this.state.firstNameActive ? false : true)
                                                }
                                            />
                                            <label className="label" htmlFor={"firstNameActive"}>
                                                <span className="inner" />
                                                <span className="switch" />
                                            </label>
                                        </div>
                                        <p style={{ paddingTop: "20px" }}>All User</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="privacyCardInnerWrapper">
                            <Grid container spacing={1}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <p style={{ color: "#000000", padding: "8px", cursor: "pointer" }}>Do you want others to view your last name?</p>
                                    <p style={{ color: "gray", paddingLeft: "8px", fontSize: "12px", cursor: "pointer" }}>Choose yes or no</p>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={6} s>
                                    <div style={{ display: "inline-flex", paddingRight: "2px", float: "right" }}>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="checkbox"
                                                name="lastNameActive"
                                                id={"lastNameActive"}
                                                checked={this.state.lastNameActive}
                                                onClick={() =>
                                                    this.LastNameActive(this.state.lastNameActive ? false : true)
                                                }
                                            />
                                            <label className="label" htmlFor={"lastNameActive"}>
                                                <span className="inner" />
                                                <span className="switch" />
                                            </label>
                                        </div>
                                        <p style={{ paddingTop: "20px" }}>All User</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div> */}
            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t(
                      "Choose yes to show your display name to others or Choose no to show your first name to others?"
                    )}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6} s>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="displayNameActive"
                        id={"displayNameActive"}
                        checked={this.state.displayNameActive}
                        onClick={() =>
                          this.DisplayNameActive(
                            this.state.displayNameActive ? false : true
                          )
                        }
                      />
                      <label className="label" htmlFor={"displayNameActive"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <p style={{ paddingTop: "20px" }}>{t("All User")}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Do you want others to view your email?")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6} s>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="emailActive"
                        id={"emailActive"}
                        checked={this.state.emailActive}
                        onClick={() =>
                          this.EmailNameActive(
                            this.state.emailActive ? false : true
                          )
                        }
                      />
                      <label className="label" htmlFor={"emailActive"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <p style={{ paddingTop: "20px" }}>{t("All User")}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Do you want others to view your date of birth?")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6} s>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="dobActive"
                        id={"dobActive"}
                        checked={this.state.dobActive}
                        onClick={() =>
                          this.DobNameActive(
                            this.state.dobActive ? false : true
                          )
                        }
                      />
                      <label className="label" htmlFor={"dobActive"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <p style={{ paddingTop: "20px" }}>{t("All User")}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t(
                      "Would you like other members to refrain from sending friend requests to you?"
                    )}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6} s>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="friendRequestActive"
                        id={"friendRequestActive"}
                        checked={this.state.friendRequestActive}
                        onClick={() =>
                          this.FriendRequestActive(
                            this.state.friendRequestActive ? false : true
                          )
                        }
                      />
                      <label className="label" htmlFor={"friendRequestActive"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                    <p style={{ paddingTop: "20px" }}>{t("All User")}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
  ConnectionsActive(boolean) {
    this.setState({ connectionsActive: boolean });
    var privacy = {
      type: "profileConnections",
      active: boolean,
    };
    ProfileService.storePrivacySettings(privacy)
      .then((response) => {
        var result = response.data;
      })
      .catch((error) => {});
  }
  ForumActive(boolean) {
    this.setState({ forumActive: boolean });
    var privacy = {
      type: "forum",
      active: boolean,
    };
    ProfileService.storePrivacySettings(privacy)
      .then((response) => {
        var result = response.data;
      })
      .catch((error) => {});
  }
  MemberActive(boolean) {
    this.setState({ memberActive: boolean });
    var privacy = {
      type: "profileMember",
      active: boolean,
    };
    ProfileService.storePrivacySettings(privacy)
      .then((response) => {
        var result = response.data;
      })
      .catch((error) => {});
  }
  FirstNameActive(boolean) {
    this.setState({ firstNameActive: boolean });
    var privacy = {
      type: "profileFirstName",
      active: boolean,
    };
    ProfileService.storePrivacySettings(privacy)
      .then((response) => {
        var result = response.data;
      })
      .catch((error) => {});
  }
  LastNameActive(boolean) {
    this.setState({ lastNameActive: boolean });
    var privacy = {
      type: "profileLastName",
      active: boolean,
    };
    ProfileService.storePrivacySettings(privacy)
      .then((response) => {
        var result = response.data;
      })
      .catch((error) => {});
  }
  DisplayNameActive(boolean) {
    this.setState({ displayNameActive: boolean });
    var privacy = {
      type: "profileDisplayName",
      active: boolean,
    };
    ProfileService.storePrivacySettings(privacy)
      .then((response) => {
        var result = response.data;
      })
      .catch((error) => {});
  }
  EmailNameActive(boolean) {
    this.setState({ emailActive: boolean });
    var privacy = {
      type: "profileEmail",
      active: boolean,
    };
    ProfileService.storePrivacySettings(privacy)
      .then((response) => {
        var result = response.data;
      })
      .catch((error) => {});
  }

  DobNameActive(boolean) {
    this.setState({ dobActive: boolean });
    var privacy = {
      type: "profileDob",
      active: boolean,
    };
    ProfileService.storePrivacySettings(privacy)
      .then((response) => {
        var result = response.data;
      })
      .catch((error) => {});
  }
  FriendRequestActive(boolean) {
    this.setState({ friendRequestActive: boolean });
    var privacy = {
      type: "friendRequest",
      active: boolean,
    };
    ProfileService.storePrivacySettings(privacy)
      .then((response) => {
        var result = response.data;
      })
      .catch((error) => {});
  }
  getPrivacySettingData() {
    var privacy = {};
    ProfileService.getPrivacySetting(privacy)
      .then((response) => {
        var result = response.data.data.privacy;
        this.setState({
          firstNameActive: result.profileFirstName,
          lastNameActive: result.profileLastname,
          displayNameActive: result.profileDisplayName,
          dobActive: result.profileDob,
          emailActive: result.profileEmail,
          connectionsActive: result.custom1,
          memberActive: result.member,
          forumActive: result.forum,
          friendRequestActive: result.friendRequest,
        });
      })
      .catch((error) => {});
  }
}

export default withTranslation()(PrivacySettings);
