import React, { Component } from "react";
import * as APPCONFIG from "../../config/app-config.js";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import AuthService from "../../session/AuthService";
import moment from "moment";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import MyYuukke from "../../api/MyYuukke";
import Swal from "sweetalert2";
import banner from "../../img/banner.jpg";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import dummyimage from "../../img/default_avatar.jpg";
import Grid from "@material-ui/core/Grid";
import UserImg from "./JobRequestUserImg";
import ForumService from "../../api/ForumService";
class JobRequestListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 0,
      topic_name: "",
      menuStatewithDelete: false,
      base64String: "",
    };

    this.getMessageFiles = this.getMessageFiles.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    this.getBase64String(this.props.userId);
    i18n.changeLanguage(langValue);

    // this.getMessageFiles(this.props.grouporeventFiles, this.props.fileType);
  }

  componentWillReceiveProps(nextProps) {
    // this.getMessageFiles(nextProps.grouporeventFiles, nextProps.fileType);
  }

  render() {
    const data = this.props.imgUrl;
    //  const profileImage = data ? `data:image;base64,${data}` : dummyimage;
    const profileImage = dummyimage;
    const { t } = this.props;
    const {
      name,
      imgUrl,
      createdDate,
      displayName,
      imgAlt,
      userId,
      qualification,
    } = this.props;

    return (
      <div className="peopleInvitationCardWrapper1">
        <div style={{ cursor: "pointer" }}>
          <div className="subjectTitleUE ">
            <Grid container spacing={1}>
              <Grid item lg={1.5} sm={1.5} md={1.5} xs={1.5}>
                <div
                  className="headerUserProfile flexDefault flexAlignCenter"
                  onClick={this.showMenu}
                >
                  <UserImg
                    size={this.state.mobileView ? "tiny" : "small"}
                    imgSrc={
                      this.state.base64String === ""
                        ? dummyimage
                        : this.state.base64String
                    }
                    imgAlt={imgAlt}
                    isShowStatus={true}
                    statusLabel="online"
                  />
                </div>
                {/* <img src={imgUrl ?  imgUrl :dummyimage } alt={displayName} /> */}
              </Grid>
              <Grid item lg={9} sm={9} md={9} xs={9}>
                <p className="subjectTitleUE">{displayName}</p>
                <div className="followCount" style={{ float: "left" }}>
                  <div className="">
                    <p class="followFriends">{qualification}</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }

  getBase64String(userId) {
    let getBase64String = { userId: userId };
    var response = ForumService.getUserPhotoCache(getBase64String);
    if (response) {
      var value = response.data.data;
      if (value && value.userPhoto) {
        var base64String = "";
        base64String = `data:image;base64,${response.data.data.userPhoto}`;
        this.setState({ base64String: base64String });
      }
    } else {
      ForumService.getUserPhoto(getBase64String)
        .then((response) => {
          ForumService.setUserPhotoCache(getBase64String, response);
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.userPhoto
          ) {
            var base64String = "";
            base64String = `data:image;base64,${response.data.data.userPhoto}`;
            this.setState({ base64String: base64String });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async getMessageFiles(files, type) {
    if (type == "image") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
        }
        await this.setState({ fileData: fileList });
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "text") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(dummyimage);
        }
        await this.setState({ fileData: fileList });
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "video") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/video/${files[i].fileName}`);
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "pdf") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "file") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else {
      let fileList = [];
      if (files.length > 0) {
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    }
  }
}

const mapStateToProps = function (state) {
  return {
    tagList: state.commonApi.taglist,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(JobRequestListCard)));

// export default withTranslation()(withRouter(GroupsCard));
