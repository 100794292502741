import React, { Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { MenuItem } from "@material-ui/core";
export default class EditableDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disbaledField: false,
      value: null,
      options: [],
      school: false,
    };

    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }
  componentDidMount() {
    this.setState(
      {
        value: this.props.value,
        options: this.props.options,
        school: this.props.school ? true : false,
      },
      () => {}
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
        school: this.props.school ? true : false,
      });
    }
    if (prevProps.options !== this.props.options) {
      this.setState(
        {
          options: this.props.options,
        },
        () => {}
      );
    }
  }

  handleClickEdit() {
    this.setState({
      disbaledField: false,
    });
    this.props.handleClickEdits();
  }
  handleChangeInput(event) {
    this.setState({
      value: event.target.value,
    });
    this.props.getSelectedValue(event.target.value);
    this.props.handleClickEdits();
  }
  render() {
    const { disbaledField, value, school } = this.state;
    return (
      <div
        className={
          disbaledField
            ? "editableTextWrapper formFieldGroup disabledField"
            : "editableTextWrapper formFieldGroup"
        }
      >
        <label className="label-v2">{this.props.label}</label>

        <Grid
          container
          spacing={1}
          alignItems="flex-end"
          wrap="nowrap"
          className={school ? "widthincress" : ""}
        >
          <Grid item xs={11}>
            <TextField
              id="dropdown"
              select
              label=""
              value={value}
              onChange={this.handleChangeInput}
              disabled={disbaledField}
              variant="outlined"
              fullWidth={true}
            >
              {this.state.options.map((option, index) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1}>
            {/* <IconButton
              aria-label="Edit"
              size="small"
              onClick={this.handleClickEdit}
            >
              <CreateIcon fontSize="inherit" />
            </IconButton> */}
          </Grid>
        </Grid>
      </div>
    );
  }
}
