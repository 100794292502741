import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { API_URL } from "../../config/app-config";
import dummyimage from "../../img/default_avatar.jpg";
import FriendConnectionCard from "./FriendConnectionCard";
import FriendConnectionListCard from "./FriendConnectionListCard";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getConnectionFriendList,
  updateConnectionFriendList,
} from "../../redux/actions/PostCardActions";

class FriendConnectionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      friendsList: [],
    };
    this.loadFriendsList = this.loadFriendsList.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    console.log("fl 1");
    console.log(this.props.friendsList);

    // this.setState({ friendsList: this.props.friendsList });
  }

  seeMoreFriends = () => {
    console.log("See more", this.props.activePage);
    this.props.getConnectionFriendList(
      true,
      this.props.activePage + 1,
      this.props.userId
    );
    this.setState({
      pageNo: this.state.pageNo + 1,
    });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        {/* <div  >
                    <div className="connectionfriendTabCardWrapper">
                       <div style={{display:"inline-flex"}}>
                       <p style={{ fontWeight: "bold", fontSize: "16px" }}>Connections </p>
                           <p className="redLabel" >{this.state.friendsList.length}</p>
     
                       </div>                  
                         <Grid container spacing={0} style={{marginTop:"8px"}}>
                            {this.state.friendsList &&
                                this.state.friendsList.length > 0 &&
                                this.state.friendsList.map((friend, index) => (
                                    <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                            
                                        <FriendConnectionListCard
                                        userId = {friend.userId}
                                        displayName = {friend.userProfile.displayName}
                                        // location = {friend.userProfile.location}
                                        biography= {friend.userProfile.biography}

                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </div>

                </div> */}

        <div
          class="about-card "
          style={{ marginTop: "1.5rem", paddingBottom: "4rem" }}
        >
          <div class="about-card-header border-0 pb-0">
            <h5 class="about-card-title"> {t("Connections")}</h5>
          </div>

          <InfiniteScroll
            dataLength={this.props.friendsList.length}
            next={this.seeMoreFriends}
            hasMore={this.props.friendsSeeMorePage}
            scrollableTarget="friends_list_connection"
            direction="down"
            scrollThreshold={0.95}
          >
            <div
              id="friends_list_connection"
              // onScroll={this.handleScroll}
              style={{
                // minHeight: "auto",
                maxHeight: "750px",
                // overflowY: "auto",
                overflowX: "hidden",
                // margin: "0px 22px 25px",
              }}
            >
              {this.props.friendsList &&
                this.props.friendsList.length > 0 &&
                this.props.friendsList.map((friend, index) => (
                  <FriendConnectionListCard
                    userId={friend.userId}
                    type={friend.type}
                    displayName={friend.userName}
                    professionId={friend.professionValue}
                    profilePhoto={friend.profilePhoto}
                    updateUserList={this.loadFriendsList}
                    updateFriendsListStatus={this.updateFriendsListStatus}
                    premiumUser={friend.premiumData}
                    removeFriend={this.removeFriend}
                  />
                ))}
              {this.props.friendsList?.length === 0 &&
                !this.props.friendsSeeMorePage && (
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Friends not found")}
                  </p>
                )}
            </div>
          </InfiniteScroll>
          {/* {this.state.friendsList &&
                    this.state.friendsList.length > 0 &&
                    this.state.friendsList.map((friend, index) => (
                      <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                        <FriendConnectionCard
                          userId={friend.userId}
                          type={friend.type}
                          displayName={friend.userProfile.displayName}
                          professionId={friend.userProfile.professionId}
                          profilePhoto={friend.profilePhoto}
                          loadFriendsList={this.loadFriendsList}
                          updateFriendsListStatus={this.updateFriendsListStatus}
                        />
                      </Grid>
                    ))} */}
          {/* {this.state.friendsList &&
            this.state.friendsList.length > 0 &&
            this.state.friendsList.map((friend, index) => (
              <FriendConnectionListCard
                userId={friend.userId}
                displayName={friend.userProfile.displayName}
                type={friend.type}
                userType={friend.userType}
                biography={friend.profession_value}
                loadFriendsList={this.loadFriendsList}
              />
            ))} */}
        </div>
      </>
    );
  }

  loadFriendsList = (id, type) => {
    // this.props.loadFriendsList()
    // let friendsList = this.state.friendsList;
    // friendsList = this.state.friendsList.filter((item) => item.userId !== id);
    // this.setState({
    //   friendsList: friendsList,
    // });

    let data = {
      id: id,
      // type: type,
      type: "requestedFriends",
    };
    this.props.updateConnectionFriendList(data, false);
  };
  removeFriend = (id) => {
    var data = {
      id: id,
      type: "removeFriend",
    };
    this.props.updateConnectionFriendList(data, true);
  };
}

const mapStateToProps = function (state) {
  return {
    friendsList: state.postList.friendsList,
    friendsSeeMorePage: state.postList.friendsSeeMorePage,
    totalFriends: state.postList.totalFriends,
    friendUpdateState: state.postList.friendUpdateState,
    activePage: state.postList.activePage,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    updateConnectionFriendList: (data, deleteData) =>
      dispatch(updateConnectionFriendList(data, deleteData)),
    getConnectionFriendList: (pagination, page, userId) =>
      dispatch(getConnectionFriendList(pagination, page, userId)),
  };
};
export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(FriendConnectionList)));
