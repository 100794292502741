import React, { Component } from "react";
import PeopleInvitationCard from "../../../components/PeopleInvitationCard";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import AuthService from "../../../session/AuthService";
import { FcRating } from "react-icons/fc";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FcConferenceCall } from "react-icons/fc";
class PeopleInvitations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestedFriendsList: [],
      requestedFriendsListState: false,
    };
    this.getRequestedFriendsList = this.getRequestedFriendsList.bind(this);
    this.rejectPage = this.rejectPage.bind(this);
    this.acceptPage = this.acceptPage.bind(this);
  }

  componentDidMount() {
    this.getRequestedFriendsList(this.props.requested_friends_list);

    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.requested_friends_list !== this.props.requested_friends_list
    ) {
      this.getRequestedFriendsList(this.props.requested_friends_list);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="PIWrapperNew">
        <Grid container spacing={2}>
          {!this.state.requestedFriendsListState &&
            this.state.requestedFriendsList &&
            this.state.requestedFriendsList.map((invitation, index) => (
              <Grid item xs={12} sm={12} md={12}>
                <PeopleInvitationCard
                  userId={invitation.uId}
                  name={invitation.userName}
                  Dname={invitation.dName}
                  nameLocation={
                    invitation.location !== null
                      ? invitation.location
                      : "--------"
                  }
                  country={
                    invitation.countryName !== null
                      ? invitation.countryName
                      : "--------"
                  }
                  tagsValue={
                    invitation.tagsValue.length !== 0
                      ? invitation.tagsValue
                      : "---------"
                  }
                  acceptSent={this.acceptPage}
                  rejectSent={this.rejectPage}
                  searchData={this.props.searchData}
                  profilePhoto={invitation.profilePhoto}
                  premiumData={invitation?.premiumData}
                />
              </Grid>
            ))}
          {this.state.requestedFriendsListState && (
            <div class="no-data">
              <center>
                <FcConferenceCall
                  style={{
                    width: "55px",
                    height: "55px",
                    color: "#000000",
                  }}
                />
                {console.log("props length : ", this.props.requestListLength)}
                {console.log("state length : ", this.props.requestedPage)}
                {this.props.requestListLength === 0 &&
                  this.props.requestedPage !== 0 && (
                    <p style={{ fontSize: "16px" }}>{t("No match found")}</p>
                  )}
                {this.props.requestListLength === this.props.requestedPage && (
                  <p style={{ fontSize: "16px" }}>
                    {t("No Invitation Found")}{" "}
                  </p>
                )}
              </center>
            </div>
          )}
        </Grid>
      </div>
    );
  }

  acceptPage(e, result) {
    this.props.acceptPageData(e, "invitation", result);
    // this.props.refreshFriendsList();
    // this.props.refreshRecentlyAddedFriends();
  }

  rejectPage(e) {
    this.props.rejectPageData(e, "invitation");
  }

  async getRequestedFriendsList(requested_friends_list) {
    if (requested_friends_list.length > 0) {
      await this.setState({
        requestedFriendsList: requested_friends_list,
        requestedFriendsListState: false,
      });
    } else {
      await this.setState({
        requestedFriendsList: [],
        requestedFriendsListState: true,
      });
    }
  }
}
const mapStateToProps = function (state) {
  return {
    FriendsList: state.commonApi.friendslist,
  };
};

export default connect(mapStateToProps)(
  withTranslation()(withRouter(PeopleInvitations))
);
