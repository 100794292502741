import { GET_MYLIBRARY_QUESTION_LIST } from "../types";
import { DELETE_LIBRARY_QUESTION_LIST } from "../types";
import { MYLIBRARY_QUESTION_LOADER } from "../types";
import { GET_LOGOUT_DATA } from "../types";
const initialState = {
  list: [],
  uplist: [],
  MyLibraryloading: true,
  totalItems: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MYLIBRARY_QUESTION_LIST:
      console.log("GET_MYLIBRARY_QUESTION_LIST");
      console.log(action.payload.libraryQuestions);
      return {
        ...state,
        list: action.payload.libraryQuestions,
        totalItems: action.payload.totalItems,
        MyLibraryloading: false,
      };
    case DELETE_LIBRARY_QUESTION_LIST:
      console.log("DELETE_LIBRARY_QUESTION_LIST");
      console.log(action.payload);
      return {
        ...state,
        list: action.payload,
        totalItems: state.totalItems - 1,
        MyLibraryloading: false,
      };
    case MYLIBRARY_QUESTION_LOADER:
      console.log("MYLIBRARY_QUESTION_LOADER");
      console.log(action.payload);
      return {
        ...state,
        MyLibraryloading: action.payload,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");
      console.log(action.payload);
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
