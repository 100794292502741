import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState } from "draft-js";
import AuthService from "../session/AuthService_old";
import i18n from "../i18n";
const MAX_LENGTH = 1500;

export default function TextEditorHomePost(props) {
  const [friendList, setFriendList] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    addFriendList();
    console.log("Get Friends List");
  }, [props.friendList]);
  useEffect(() => {
    console.log("questionDescribed props 1", props);
    if (props.needEdit) {
      props.changeNeedHelpState();
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      const blocksFromHtml = htmlToDraft(props.editorValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
      console.log("questionDescribed Updated content", props.editorValue);
    }
  }, [props.needEdit]);
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);

    props.editorStateValue(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  const handleChange = (event) => {
    const reset = { ...event.blocks[0], text: null };
    // this.setState({
    //   currentValues: event,
    // });
  };
  const onClear = () => {
    setEditorState(EditorState.createEmpty());
  };

  const addFriendList = () => {
    const filterdList =
      props.friendList &&
      props.friendList.map((data) => ({
        text: data.userName,
        value: data.userName,
        url: "friendDescription/" + data.userId,
      }));
    console.log("friendslist", filterdList);
    setFriendList(filterdList);
  };

  const addUpdateData = () => {
    console.log("questionDescribed final", props.editorValue);
    props.textBoxStatusBack();
    const blocksFromHtml = htmlToDraft(props.editorValue);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    console.log(contentState);
    console.log(EditorState.createWithContent(contentState));
    setEditorState(EditorState.createWithContent(contentState));
  };

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      console.log(currentSelection);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }
          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const _handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      console.log("you can type max ten characters");

      return "handled";
    }
  };

  const _handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (
      currentContentLength + pastedText.length - selectedTextLength >
      MAX_LENGTH
    ) {
      console.log("you can type max ten characters");

      return "handled";
    }
  };

  return (
    <div className="editor-wrapper" style={{ borderRadius: "5px" }}>
      <Editor
        toolbarHidden={true}
        editorState={editorState}
        wrapperClassName="textarea-comment"
        editorClassName="form-control-comment"
        toolbarClassName="editor-toolbar"
        placeholder={"What do you want to share"}
        onEditorStateChange={onEditorStateChange}
        onChange={handleChange}
        handleBeforeInput={_handleBeforeInput}
        handlePastedText={_handlePastedText}
        autoHeight={true}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions: friendList,
        }}
      />
    </div>
  );
}
