import React, { Component } from "react";
import BgBanner from "../ProfileSetUp/BgBanner";
import Grid from "@material-ui/core/Grid";
import FriendsCard from "../ProfileSetUp/FriendsCard";
import UserInfo from "../ProfileSetUp/UserInfo";
import Container from "@material-ui/core/Container";
import AuthService from "../../../session/AuthService";
import FriendsFinder from "../../../api/FriendsFinder";
import ProfileService from "../../../api/ProfileService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Select from "@material-ui/core/Select";
import dummyimage from "../../../img/default_avatar.jpg";
import banner from "../../../img/banner.jpg";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";

let newSkills = new Array(2);
class CommunitySkillsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameValue: false,
      experienceValue: false,
      tabIndex: 0,
      friendsList: [],
      userKnowledgepoint: "",
      coverPhotoImage: "",
      savebtnState: false,
      inputs: [1],
      saveButtonEnable: false,
      frSkillName: [],
      dynamicLangArray: [],
      skills: [
        {
          name: "student",
        },
        {
          name: "Sworking professional",
        },
        {
          name: "practicing profession (Auditoer,lawyer and etc)",
        },
        {
          name: "Entreprenur",
        },
      ],
    };

    this.handleInputSkill = this.handleInputSkill.bind(this);
    this.getSkillsDetails = this.getSkillsDetails.bind(this);
    this.saveSkills = this.saveSkills.bind(this);
    this.addTranslation = this.addTranslation.bind(this);
    this.removeTranslation = this.removeTranslation.bind(this);
    this.backtoHome = this.backtoHome.bind(this);
  }

  componentDidMount() {
    this.getSkillsDetails();
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }
  async removeTranslation(record, index) {
    console.log("REMOVE TRANSLATION");
    console.log(index);
    console.log(this.state.inputs);
    var inpu = this.state.inputs;
    inpu.splice(index, 1);
    if (this.state.frSkillName[index]) {
      var val = this.state.frSkillName;
      val.splice(index, 1);
      this.setState({
        frSkillName: val,
      });
    }
    this.setState({
      nameValue: true,
      experienceValue: true,
    });
    await this.setState({
      inputs: inpu,
    });
  }
  addTranslation = (e) => {
    if (!this.state.nameValue || !this.state.experienceValue) {
      toast.warn("Fill all the field");
    } else {
      var input = this.state.inputs;
      input.push(1);
      this.setState({
        inputs: input,
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div
        className="screenInnerGap"
        style={{
          // marginLeft: "96px",
          // marginRight: "96px",
          // marginBottom: "22px",
          marginTop: "80px",
          padding: "5px",
        }}
      >
        <ToastContainer position="top-right" autoClose={5000} />

        <div className={this.props.firstTime ? "" : "screenInnerGap"}>
          <Container>
            <ToastContainer position="top-right" autoClose={5000} />
            <Grid container spacing={1}>
              <Grid item md={9} xs={12} sm={12} lg={9}>
                <div className="peopleInvitationCardWrapper">
                  <h6>Add Skill Details </h6>
                  <div style={{ float: "left" }}>
                    <button onClick={this.backtoHome}>Back </button>
                  </div>
                  {this.state.inputs.length > 0 &&
                    this.state.inputs.map((entry, index) => (
                      <div>
                        <Grid container spacing={0}>
                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <button
                              style={{
                                cursor: "pointer",
                                fontSize: "35px",
                                float: "right",
                                marginTop: "12px",
                              }}
                              class=""
                              onClick={() =>
                                this.removeTranslation(entry, index)
                              }
                            >
                              {" "}
                              -{" "}
                            </button>
                          </Grid>{" "}
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <h6>Add Skill Name</h6>
                            <TextField
                              style={{
                                borderRadius: "10px",
                                marginBottom: "8px",
                              }}
                              fullWidth
                              variant="outlined"
                              type="text"
                              required=""
                              name="frSkillName"
                              onChange={(e) =>
                                this.handleInputSkill(e, index, "skillName")
                              }
                              value={
                                this.state.frSkillName &&
                                this.state.frSkillName[index] &&
                                this.state.frSkillName[index].skillName
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                          <h6>Add Experience Details</h6>
                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <TextField
                              id="dropdown"
                              select
                              label=""
                              value={
                                this.state.frSkillName &&
                                this.state.frSkillName[index] &&
                                this.state.frSkillName[index].experience
                              }
                              onChange={(e) =>
                                this.handleInputSkill(e, index, "experience")
                              }
                              variant="outlined"
                              fullWidth={true}
                              options={this.state.skills}
                            >
                              <MenuItem value="">
                                <>Select</>
                              </MenuItem>
                              <MenuItem value="Beginner">
                                <>Beginner</>
                              </MenuItem>
                              <MenuItem value="Intermediate">
                                <>Intermediate</>
                              </MenuItem>
                              <MenuItem value="Experience">
                                <>Experience</>
                              </MenuItem>
                            </TextField>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  <Grid container spacing={0}>
                    <Grid item md={6} xs={6} sm={6} lg={6}>
                      {
                        <div className="profileFormBtn">
                          <button
                            style={{
                              borderRadius: "10px",
                              padding: "8px",
                            }}
                            onClick={this.saveSkills}
                          >
                            {t("Save")}
                          </button>
                        </div>
                      }
                    </Grid>
                    <Grid item md={6} xs={6} sm={6} lg={6}>
                      <button
                        style={{
                          cursor: "pointer",
                          marginTop: "20px",
                          float: "right",
                        }}
                        class=""
                        onClick={this.addTranslation}
                      >
                        +
                      </button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
  backtoHome() {
    this.setState({
      frSkillName: [],
      inputs: [1],
    });
    this.props.history.push("/home");
  }

  handleInputSkill(event, index, type) {
    // alert(event)
    console.log("inputs values");
    console.log(event);
    if (type == "skillName") {
      if (event.target.value == "") {
        this.setState({
          nameValue: false,
        });
      } else {
        this.setState({
          nameValue: true,
        });
      }
    } else {
      if (event.target.value == "") {
        this.setState({
          experienceValue: false,
        });
      } else {
        this.setState({
          experienceValue: true,
        });
      }
    }
    var newSkills = this.state.frSkillName;
    if (newSkills[index]) {
      if (type == "skillName") {
        event.preventDefault();
        event.stopPropagation();
        var obj = newSkills[index];
        obj.skillName = event.target.value;
        newSkills[index] = obj;
      } else {
        console.log("inputs values 1");
        console.log(event);
        var obj = newSkills[index];
        obj.experience = event.target.value;
        newSkills[index] = obj;
      }
    } else {
      if (type == "experience") {
        event.preventDefault();
        event.stopPropagation();
        var obj = newSkills[index];
        obj.experience = event.target.value;
        newSkills[index] = obj;
      } else {
        var data = event;
        var obj = newSkills[index];
        var obj = {
          skillName: "",
          experience: data,
        };
        console.log("inputsss");
        console.log(obj);
        newSkills[index] = obj;
      }
    }

    this.setState({
      frSkillName: newSkills,
    });
    console.log("this.state.frSkillName");
    console.log(newSkills);
    console.log(this.state.frSkillName);
    //  this.setState({answers:answer_id.target.value})
  }

  saveSkills() {
    if (this.state.nameValue && this.state.experienceValue) {
      var saveSkill = {
        education: this.state.frSkillName,
        firstTimeSetup: "true",
      };
      ProfileService.saveSkillDetails(saveSkill)
        .then((response) => {
          var result = response.data;

          var data = [];
          console.log(this.state.frSkillName);
          for (let i = 0; i < this.state.frSkillName.length; i++) {
            // data.push({
            //   skillName: "",
            //   experience: "",
            // });
            this.setState({ frSkillName: data });
            this.props.history.push(`/home`);
          }
          toast.success("Saved successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.warn("Fill all the field");
    }
  }

  getSkillsDetails() {
    var saveSkills = {};
    ProfileService.getSkillsDetails(saveSkills)
      .then((response) => {
        var result = response.data;

        console.log("skills details");
        console.log(response.data.data);
        if (response.data.status == 1) {
          this.setState({ skillDetails: response.data.data.skills });
          var skillsData = response.data.data.skills;

          console.log("education" + skillsData);
          console.log(skillsData);
          this.setState({
            skillsData,
          });

          Object.keys(skillsData).forEach((k) => {
            console.log(k);
            console.log(k.toString());

            this.setState({
              dynamicLangArray: [...this.state.dynamicLangArray, k],
            });
          });

          let unique_dynamic_key = [...new Set(this.state.dynamicLangArray)];

          unique_dynamic_key.forEach((k) => {
            var skillName = "";
            var experience = "";
            if (k in this.state.skillDetails) {
              console.log(k + " in announment");
              skillName = this.state.skillDetails[k].skillName;
              experience = this.state.skillDetails[k].experience;
            }

            var obj = {
              skillName: skillName,
              experience: experience,
            };

            this.setState({
              frSkillName: [...this.state.frSkillName, obj],
            });
          });

          if (this.state.frSkillName.length > 0) {
            this.setState({ inputs: [] });
            for (let k = 0; k < this.state.frSkillName.length; k++) {
              var input = 1;

              this.setState({
                inputs: [...this.state.inputs, input],
              });
            }
          }
        } else {
          this.setState({ skillDetails: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  console.log("Loading Notification Props");
  console.log(state.loginDetailsApi.loginDetails);

  return {
    subjectsList: state.commonApi.subjectslist,
    languageList: state.commonApi.languagelist,
    tagList: state.commonApi.taglist,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    schoolList: state.commonApi.schoollist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(CommunitySkillsContainer)));
