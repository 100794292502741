import React, { Component } from "react";
import AuthService from "../../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import cup from "../../../images/cups.png";
import winner from "../../../images/winner.png";
import runner from "../../../images/runner.png";
import { Grid } from "@material-ui/core";
import UserImg from "../../../components/UserImg";
// import Sparkle from "react-sparkle";
import * as APPCONFIG from "../../../config/app-config.js";
import dummyimage from "../../../img/default_avatar.jpg";

class ResultsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNo: 1,
      noMoreState: false,
      postList: [],
      activeInterestPage: 1,
      blocking: false,
      PostmessageLists: [],
    };
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  componentWillReceiveProps(nextProps) {}
  componentDidUpdate(prevProps, prevState) {}

  render() {
    const { t } = this.props;

    const data = this.props.loginDetails.profile_uuid;
    const profileImage = data
      ? `${APPCONFIG.API_URL}auth/image/${data}`
      : dummyimage;
    const userName = this.props.loginDetails.dName;
    //const profileImage = data ? `data:image;base64,${data}` : cup;
    let config = {
      num: [4, 7],
      rps: 0.1,
      radius: [5, 40],
      life: [1.5, 3],
      v: [2, 3],
      tha: [-40, 40],
      alpha: [0.6, 0],
      scale: [0.1, 0.4],
      position: "all",
      color: ["random", "#ff0000"],
      cross: "dead",
      // emitter: "follow",
      random: 15,
    };

    if (Math.random() > 0.85) {
      config = Object.assign(config, {
        onParticleUpdate: (ctx, particle) => {
          ctx.beginPath();
          ctx.rect(
            particle.p.x,
            particle.p.y,
            particle.radius * 2,
            particle.radius * 2
          );
          ctx.fillStyle = particle.color;
          ctx.fill();
          ctx.closePath();
        },
      });
    }
    return (
      <div style={{ position: "relative" }}>
        {/* <Sparkle
          color={["red", "#E6E8FA", "#EOAA3E", "#AE8625", "#D4AF37"]}
          minSize={10}
          maxSize={10}
          flicker={true}
          overflowPx={20}
          fadeOutSpeed={50}
          newSparkleOnFadeOut={true}
          flickerSpeed={"faster"}
          // color="red"
          count={150}
        /> */}

        <center>
          <span class="confirm_selection">Announcements</span>
        </center>
        <div>
          <center>
            <img
              src={cup}
              style={{ height: "130px", width: "130px", marginTop: "15px" }}
            ></img>
          </center>

          <div style={{ marginTop: "30px" }}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} md={2} lg={2}>
                <h6
                  style={{
                    color: "#A00030",
                    fontWeight: "bold",
                    padding: "40px",
                  }}
                >
                  Singing
                </h6>
              </Grid>
              <Grid item sm={12} xs={12} md={5} lg={5}>
                <div style={{ position: "relative" }}>
                  <div className="resultData">
                    <div style={{ paddingTop: "4px" }}>
                      <UserImg
                        size={"small"}
                        imgSrc={profileImage}
                        imgAlt={userName}
                      />
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {userName}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "18px",
                        color: "#eeeeee",
                      }}
                    >
                      Winner
                    </p>
                    <p style={{ fontSize: "12px", padding: "18px", right: 0 }}>
                      Winner
                    </p>
                  </div>

                  <img
                    className="resultHeaderIcon"
                    src={winner}
                    style={{ height: "30px", width: "30px", float: "right" }}
                  ></img>
                </div>
              </Grid>
              <Grid item sm={12} xs={12} md={5} lg={5}>
                <div style={{ position: "relative" }}>
                  <div className="resultData">
                    <div style={{ paddingTop: "4px" }}>
                      <UserImg
                        size={"small"}
                        imgSrc={profileImage}
                        imgAlt={userName}
                      />
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {userName}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "18px",
                        color: "#eeeeee",
                      }}
                    >
                      Winner
                    </p>
                    <p style={{ fontSize: "12px", padding: "18px", right: 0 }}>
                      Runner
                    </p>
                  </div>

                  <img
                    className="resultHeaderIcon"
                    src={runner}
                    style={{ height: "30px", width: "30px", float: "right" }}
                  ></img>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} md={2} lg={2}>
                <h6
                  style={{
                    color: "#A00030",
                    fontWeight: "bold",
                    padding: "40px",
                  }}
                >
                  Dance
                </h6>
              </Grid>
              <Grid item sm={12} xs={12} md={5} lg={5}>
                <div style={{ position: "relative" }}>
                  <div className="resultData">
                    <div style={{ paddingTop: "4px" }}>
                      <UserImg
                        size={"small"}
                        imgSrc={profileImage}
                        imgAlt={userName}
                      />
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {userName}
                    </p>

                    <p
                      style={{
                        fontSize: "12px",
                        padding: "18px",
                        color: "#eeeeee",
                      }}
                    >
                      Winner
                    </p>
                    <p style={{ fontSize: "12px", padding: "18px", right: 0 }}>
                      Winner
                    </p>
                  </div>

                  <img
                    className="resultHeaderIcon"
                    src={winner}
                    style={{ height: "30px", width: "30px", float: "right" }}
                  ></img>
                </div>
              </Grid>
              <Grid item sm={12} xs={12} md={5} lg={5}>
                <div style={{ position: "relative" }}>
                  <div className="resultData">
                    <div style={{ paddingTop: "4px" }}>
                      <UserImg
                        size={"small"}
                        imgSrc={profileImage}
                        imgAlt={userName}
                      />
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {userName}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "18px",
                        color: "#eeeeee",
                      }}
                    >
                      Runner
                    </p>
                    <p style={{ fontSize: "12px", padding: "18px", right: 0 }}>
                      Runner
                    </p>
                  </div>

                  <img
                    className="resultHeaderIcon"
                    src={runner}
                    style={{ height: "30px", width: "30px", float: "right" }}
                  ></img>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} md={2} lg={2}>
                <h6
                  style={{
                    color: "#A00030",
                    fontWeight: "bold",
                    padding: "40px",
                  }}
                >
                  Painting
                </h6>
              </Grid>
              <Grid item sm={12} xs={12} md={5} lg={5}>
                <div style={{ position: "relative" }}>
                  <div className="resultData">
                    <div style={{ paddingTop: "4px" }}>
                      <UserImg
                        size={"small"}
                        imgSrc={profileImage}
                        imgAlt={userName}
                      />
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {userName}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "18px",
                        color: "#eeeeee",
                      }}
                    >
                      Winner
                    </p>
                    <p style={{ fontSize: "12px", padding: "18px", right: 0 }}>
                      Winner
                    </p>
                  </div>

                  <img
                    className="resultHeaderIcon"
                    src={winner}
                    style={{ height: "30px", width: "30px", float: "right" }}
                  ></img>
                </div>
              </Grid>
              <Grid item sm={12} xs={12} md={5} lg={5}>
                <div style={{ position: "relative" }}>
                  <div className="resultData">
                    <div style={{ paddingTop: "4px" }}>
                      <UserImg
                        size={"small"}
                        imgSrc={profileImage}
                        imgAlt={userName}
                      />
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {userName}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "18px",
                        color: "#eeeeee",
                      }}
                    >
                      Winner
                    </p>
                    <p style={{ fontSize: "12px", padding: "18px", right: 0 }}>
                      Runner
                    </p>
                  </div>

                  <img
                    className="resultHeaderIcon"
                    src={runner}
                    style={{ height: "30px", width: "30px", float: "right" }}
                  ></img>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} md={2} lg={2}>
                <h6
                  style={{
                    color: "#A00030",
                    fontWeight: "bold",
                    padding: "40px",
                  }}
                >
                  Singing
                </h6>
              </Grid>
              <Grid item sm={12} xs={12} md={5} lg={5}>
                <div style={{ position: "relative" }}>
                  <div className="resultData">
                    <div style={{ paddingTop: "4px" }}>
                      <UserImg
                        size={"small"}
                        imgSrc={profileImage}
                        imgAlt={userName}
                      />
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {userName}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "18px",
                        color: "#eeeeee",
                      }}
                    >
                      Winner
                    </p>
                    <p style={{ fontSize: "12px", padding: "18px", right: 0 }}>
                      Winner
                    </p>
                  </div>

                  <img
                    className="resultHeaderIcon"
                    src={winner}
                    style={{ height: "30px", width: "30px", float: "right" }}
                  ></img>
                </div>
              </Grid>
              <Grid item sm={12} xs={12} md={5} lg={5}>
                <div style={{ position: "relative" }}>
                  <div className="resultData">
                    <div style={{ paddingTop: "4px" }}>
                      <UserImg
                        size={"small"}
                        imgSrc={profileImage}
                        imgAlt={userName}
                      />
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {userName}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "18px",
                        color: "#eeeeee",
                      }}
                    >
                      Winner
                    </p>
                    <p style={{ fontSize: "12px", padding: "18px", right: 0 }}>
                      Runner
                    </p>
                  </div>

                  <img
                    className="resultHeaderIcon"
                    src={runner}
                    style={{ height: "30px", width: "30px", float: "right" }}
                  ></img>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} md={2} lg={2}>
                <h6
                  style={{
                    color: "#A00030",
                    fontWeight: "bold",
                    padding: "40px",
                  }}
                >
                  Singing
                </h6>
              </Grid>
              <Grid item sm={12} xs={12} md={5} lg={5}>
                <div style={{ position: "relative" }}>
                  <div className="resultData">
                    <div style={{ paddingTop: "4px" }}>
                      <UserImg
                        size={"small"}
                        imgSrc={profileImage}
                        imgAlt={userName}
                      />
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {userName}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "18px",
                        color: "#eeeeee",
                      }}
                    >
                      Winner
                    </p>
                    <p style={{ fontSize: "12px", padding: "18px", right: 0 }}>
                      Winner
                    </p>
                  </div>

                  <img
                    className="resultHeaderIcon"
                    src={winner}
                    style={{ height: "30px", width: "30px", float: "right" }}
                  ></img>
                </div>
              </Grid>
              <Grid item sm={12} xs={12} md={5} lg={5}>
                <div style={{ position: "relative" }}>
                  <div className="resultData">
                    <div style={{ paddingTop: "4px" }}>
                      <UserImg
                        size={"small"}
                        imgSrc={profileImage}
                        imgAlt={userName}
                      />
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {userName}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "18px",
                        color: "#eeeeee",
                      }}
                    >
                      Winner
                    </p>
                    <p style={{ fontSize: "12px", padding: "18px", right: 0 }}>
                      Runner
                    </p>
                  </div>

                  <img
                    className="resultHeaderIcon"
                    src={runner}
                    style={{ height: "30px", width: "30px", float: "right" }}
                  ></img>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* <ParticlesBg type="custom" num={20}  bg={false} />  */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ResultsList)));
