import React from "react";
import { FaRegQuestionCircle } from "react-icons/fa";

export default function CheckBox({
  value,
  label = "Select One",
  onChange,
  questionMark,
  uniqueId = `_checkbox-${Math.random().toString(36).substring(2, 9)}`,
}) {
  // const valueStatus = () => {
  //   console.log("update-value", !value);
  //   onChange(!value);
  // };
  return (
    <div className="d-flex mt-3 align-items-center ">
      {/*   */}
      <div className="boxcolor mr-1 event-box c-pointer  checkbox-wrapper-26">
        <input
          type="checkbox"
          value={value}
          checked={value}
          id={uniqueId}
          onChange={() => onChange(!value)}
          // onChange={valueStatus}
        />
        <label htmlFor={uniqueId}>
          <div class="tick_mark"></div>
        </label>
      </div>
      {label && (
        <label className="label-v2 mb-0">
          {label}
          {questionMark && <FaRegQuestionCircle className="ml-2" />}
        </label>
      )}
    </div>
  );
}
