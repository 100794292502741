import {
  STORE_THUMBNAILS,
  GET_COURSES_STARS_ID,
  GET_COURSES_COUNT,
  GET_COURSES_LIST,
  RESET_API_COUNT_COURSE,
  UPDATE_COURSES_STARS_ID,
  COURSE_LOADER_RESET,
} from "../types";
import MyCourseService from "../../api/MyCourseService";
import moment from "moment";
//import AuthService from '../../session/AuthService';
const cacheMinutes = 30;

export const getCourses = (lastSync, courseKey) => async (dispatch) => {
  if (!lastSync || checkTimeGap(lastSync)) {
    dispatch(resetAPICount());
    dispatch(getCourseValues("yuukke Programs", 0, courseKey));
    dispatch(getCourseStars(courseKey));
  }
};

function checkTimeGap(lastSync) {
  var currentTime = moment();
  var duration = moment.duration(currentTime.diff(lastSync));
  var mins = duration.asMinutes();
  if (mins > cacheMinutes) {
    return true;
  } else {
    return false;
  }
}

export const resetAPICount = () => async (dispatch) => {
  dispatch({
    type: RESET_API_COUNT_COURSE,
    payload: {},
  });
};

export const getCourseValues =
  (type, pageNo, courseKey) => async (dispatch) => {
    dispatch(loaderReset());
    if (courseKey == 0) {
      var obj = {};
      obj["courseType"] = "";
      obj["list"] = [];
      obj["items"] = 0;
      dispatch({
        type: GET_COURSES_LIST,
        payload: obj,
      });
    } else {
      try {
        let courseRequestObject = {
          page: pageNo,
          size: 3,
          articleSeemore: type,
          coursekey: courseKey,
        };

        MyCourseService.getMyArticlesByCourseType(courseRequestObject)
          .then((response) => {
            var result = response.data;
            console.log("=========== Request getMyArticlesByCourseType");
            console.log(result);
            console.log(result.data.articles);
            var obj = {};
            obj["courseType"] = type;

            if (result.data.articles == "") {
              obj["list"] = [];
              obj["items"] = 0;
              dispatch({
                type: GET_COURSES_LIST,
                payload: obj,
              });
            } else {
              obj["list"] = result.data.articles;
              obj["items"] = result.data.totalItems;
              dispatch({
                type: GET_COURSES_LIST,
                payload: obj,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        dispatch({
          type: GET_COURSES_LIST,
          payload: [],
        });
      }
      return [];
    }
  };

export const loaderReset = () => async (dispatch) => {
  dispatch({
    type: COURSE_LOADER_RESET,
    payload: {},
  });
};

export const loaderResetoff = () => async (dispatch) => {
  dispatch({
    type: COURSE_LOADER_RESET,
    payload: "off",
  });
};

export const updateCourseStars = (articleId, userId) => async (dispatch) => {
  let articleStarObj = { userId: userId, articleId: articleId };
  dispatch({
    type: UPDATE_COURSES_STARS_ID,
    payload: articleStarObj,
  });
};

export const getCourseStars = (courseKey) => async (dispatch) => {
  try {
    let userObj = {};
    MyCourseService.getStarArticleByUserId(userObj)
      .then((response) => {
        var result = response.data.data;
        dispatch({
          type: GET_COURSES_STARS_ID,
          payload: result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_COURSES_STARS_ID,
      payload: [],
    });
  }
  return [];
};

export const getArticleVideosCount = () => async (dispatch) => {
  try {
    //let userObj = {};
    MyCourseService.getArticleVideosCount()
      .then((response) => {
        var result = response.data.data;

        dispatch({
          type: GET_COURSES_COUNT,
          payload: result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_COURSES_COUNT,
      payload: [],
    });
  }
  return [];
};

export const storeThumbnails = (thumbnails, hash, thumbnail) => (dispatch) => {
  thumbnails[hash] = thumbnail;
  dispatch({
    type: STORE_THUMBNAILS,
    payload: thumbnails,
  });
};
