import {
  GET_ALL_NOTIFICATION_LIST,
  REMOVE_CUSTOM_NOTIFICATION_LIST_BYID,
} from "../types";
import { GET_ALL_NOTIFICATION_LIST_FALSE } from "../types";
import { REMOVE_NOTIFICATION_LIST_BYID } from "../types";
import ProfileService from "../../api/ProfileService";
//import AuthService from "../../session/AuthService";

export const getNotificationList = (HitKey) => async (dispatch) => {
  try {
    let getNotification = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hitKey: HitKey,
    };

    ProfileService.getallNotifications(getNotification)
      .then((response) => {
        var result = response.data;

        var notificationList = [];
        var notification_list = [];

        for (let j = 0; j < result.data.notification.length; j++) {
          console.log("NOTIFICATION LIST REDUX");
          var data = result.data.notification[j].data;
          var str_data;
          console.log("NOTIFICATION LIST REDUX", data);
          if (data !== null) {
            str_data = data;
          } else {
            str_data = {};
          }
          notification_list.push({
            createdDate: result.data.notification[j].createdDate,
            customId: result.data.notification[j].customId,
            dashhBoardType: result.data.notification[j].dashhBoardType,
            data: str_data,
            emailEnabled: result.data.notification[j].emailEnabled,
            emailSend: result.data.notification[j].emailSend,
            emailed: result.data.notification[j].emailed,
            enabled: result.data.notification[j].enabled,
            friendsTableId: result.data.notification[j].friendsTableId,
            meetingId: result.data.notification[j].meetingId,
            modifiedDate: result.data.notification[j].modifiedDate,
            notification: result.data.notification[j].notification,
            notificationId: result.data.notification[j].notificationId,
            notificationSenderId:
              result.data.notification[j].notificationSenderId,
            notificationType: result.data.notification[j].notificationType,
            receiverId: result.data.notification[j].receiverId,
            referenceId: result.data.notification[j].referenceId,
            status: result.data.notification[j].status,
            timeZone: result.data.notification[j].timeZone,
            title: result.data.notification[j].title,
            type: result.data.notification[j].type,
            url: result.data.notification[j].url,
          });
        }

        notificationList[0] = notification_list.reverse();
        notificationList[1] = result.data.friendsOnline;
        if (HitKey) {
          dispatch({
            type: GET_ALL_NOTIFICATION_LIST,
            payload: notificationList,
            pointCount: result?.data?.points,
          });
        } else {
          dispatch({
            type: GET_ALL_NOTIFICATION_LIST_FALSE,
            payload: notificationList,
            pointCount: result?.data?.points,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_NOTIFICATION_LIST,
      payload: [],
      pointCount: 0,
    });
  }
  return [];
};

export const removeNotificationListById =
  (notificationId, notificationList) => async (dispatch) => {
    try {
      var newnotification = [];
      if (notificationId) {
        newnotification = notificationList.filter(
          (data) => data.notificationId !== notificationId
        );
        console.log(newnotification);
        dispatch({
          type: REMOVE_NOTIFICATION_LIST_BYID,
          payload: newnotification,
        });
      } else {
        dispatch({
          type: REMOVE_NOTIFICATION_LIST_BYID,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: REMOVE_NOTIFICATION_LIST_BYID,
        payload: [],
      });
    }
    return [];
  };

export const removeGroupOfNotificationById =
  (groupNotificationId, notificationList) => async (dispatch) => {
    try {
      var newnotification = [];
      if (groupNotificationId) {
        newnotification = notificationList.filter(
          ({ notificationId: id1 }) =>
            !groupNotificationId.some(({ notificationId: id2 }) => id2 === id1)
        );
        dispatch({
          type: REMOVE_CUSTOM_NOTIFICATION_LIST_BYID,
          payload: newnotification,
        });
      } else {
        dispatch({
          type: REMOVE_CUSTOM_NOTIFICATION_LIST_BYID,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: REMOVE_CUSTOM_NOTIFICATION_LIST_BYID,
        payload: [],
      });
    }
    return [];
  };
