import React from "react";

const LoaderButton = ({ onClick, loaderState = false, label = "Submit" }) => {
  return (
    <>
      <button className="mt-3 load-btn" onClick={onClick}>
        {loaderState && (
          <div
            class="spinner-border spinner-border-sm text-light"
            role="status"
          ></div>
        )}
        {label}
      </button>
    </>
  );
};

export default LoaderButton;
