import React, { Component } from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import StarFilledIcon from "@material-ui/icons/Star";
import VideoThumbnail from "react-video-thumbnail";
import * as APPCONFIG from "../config/app-config.js";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  storeThumbnails,
  updateCourseStars,
  loaderReset,
} from "../redux/actions/courseActions";
import logo_thumbnail from "../images/EVENTS.png";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import MyCourseService from "../api/MyCourseService";
// import $ from 'jquery';
// import AuthService from "../session/AuthService";

class CourseCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      starStatus: false,
      thumbnail: null,
      thumbnailStatus: false,
      videotype: "",
      youtubeVimroContent: "",
      completed: false,
    };
    this.movetoarticle = this.movetoarticle.bind(this);
    this.bookmark = this.bookmark.bind(this);
    this.load = this.load.bind(this);
    this.getStarValue = this.getStarValue.bind(this);
    this.checkThumbnail = this.checkThumbnail.bind(this);
    this.getCompletedListByUSer = this.getCompletedListByUSer.bind(this);
  }

  componentDidMount() {
    this.checkStarStatus();
    this.getCompletedListByUSer();
    if (this.checkVimeoUrl(this.props.articleContent)) {
      this.loadthumnailInVimeo(this.props.articleContent);
    } else if (this.isYoutube(this.props.articleContent)) {
      var con = this.props.articleContent.split("=");
      this.setState({
        videotype: "youtube",
        youtubeVimroContent: con[1],
      });
    }
  }

  loadthumnailInVimeo(articleContent) {
    var con = articleContent.split("com/");
    fetch("https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/" + con[1])
      .then((response) => response.json())
      .then((jsonData) => {
        this.setState({
          videotype: "vimeo",
          youtubeVimroContent: jsonData.thumbnail_url,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  checkVimeoUrl(url) {
    url = url.toLowerCase();
    return url.includes("vimeo");
  }
  isYoutube(getURL) {
    if (typeof getURL !== "string") return false;
    var newA = document.createElement("A");
    newA.href = getURL;
    var host = newA.hostname;
    var srch = newA.search;
    var path = newA.pathname;

    if (host.search(/youtube\.com|youtu\.be/) === -1) return false;
    if (host.search(/youtu\.be/) !== -1) return path.slice(1);
    if (path.search(/embed/) !== -1)
      return /embed\/([A-z0-9]+)(\&|$)/.exec(path)[1];
    var getId = /v=([A-z0-9]+)(\&|$)/.exec(srch);
    if (host.search(/youtube\.com/) !== -1) return !getId ? "" : getId[1];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.articleStarArray !== this.props.articleStarArray) {
      this.load(this.props.articleStarArray);
    }

    if (prevProps.stars !== this.props.stars) {
      this.checkStarStatus();
    }
    if (prevProps.stars !== this.props.stars) {
      this.checkStarStatus();
    }
  }

  async checkStarStatus() {
    if (this.props.stars && this.props.stars.articles) {
      if (this.props.stars.articles.length > 0) {
        for (let i = 0; i < this.props.stars.articles.length; i++) {
          if (this.props.stars.articles[i].articleId === this.props.articleId) {
            await this.setState({ starStatus: true });
            break;
          } else {
            await this.setState({ starStatus: false });
          }
        }
      }
    }
  }

  checkThumbnail(hash) {}

  render() {
    const {
      // img,
      // videotitle,
      // articleStarArray,
      title,
      subtitle,
      hastags,
      articleId,
      articleContent,
    } = this.props;

    return (
      <div className="searchCourseCardWrapper">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => this.movetoarticle(articleId)}
        >
          {this.state.videotype !== "youtube" &&
            this.state.videotype !== "vimeo" &&
            !this.state.thumbnailStatus && (
              <VideoThumbnail
                className="videoRender"
                videoUrl={`${APPCONFIG.API_URL}auth/video/${articleContent}`}
                thumbnailHandler={(thumbnail) => {
                  this.setState({
                    thumbnail: thumbnail,
                    thumbnailStatus: true,
                  });
                }}
              />
            )}

          {this.state.videotype === "youtube" && (
            <div class="thumbnail-view">
              <img
                src={`https://img.youtube.com/vi/${this.state.youtubeVimroContent}/0.jpg`}
              />
            </div>
          )}
          {this.state.videotype === "vimeo" && (
            <div class="thumbnail-view">
              <img src={this.state.youtubeVimroContent} />
            </div>
          )}

          {this.state.videotype !== "youtube" &&
            this.state.videotype !== "vimeo" &&
            !this.state.thumbnailStatus && (
              <div class="thumbnail-view">
                <img src={logo_thumbnail} />
              </div>
            )}
          {this.state.thumbnailStatus && (
            <div class="thumbnail-view">
              <img src={this.state.thumbnail} />
            </div>
          )}
          <div className="courseCardPlayBtn">
            <PlayArrowIcon />
          </div>
          {this.state.completed && (
            <div className="CompletecourseCardPlayBtn">
              <button>
                <ImportContactsIcon /> Completed
              </button>
            </div>
          )}
        </div>

        <div className="searchCourseCardWrapper__content">
          <p className="searchCourseCardWrapper__content__title">{title}</p>
          {this.state.starStatus && (
            <p className="bookmarkIconyellowCourse">
              <StarFilledIcon onClick={() => this.bookmark(articleId)} />
            </p>
          )}
          {!this.state.starStatus && (
            <p className="bookmarkIconCourse">
              <StarOutlineIcon onClick={() => this.bookmark(articleId)} />
            </p>
          )}

          <div className="searchCourseCard_desc">
            <small>{subtitle}</small>
          </div>
          <div className="searchCourseCardWrapper__content__hash">
            <span>{hastags}</span>
          </div>
        </div>
      </div>
    );
  }

  movetoarticle(aid) {
    this.props.showArticle(aid);
  }

  bookmark(articleId) {
    this.props.loaderReset();
    let articleBookmark = {
      articleId: articleId,
    };

    MyCourseService.saveCourseStar(articleBookmark)
      .then((response) => {
        this.props.updateCourseStars(articleId, this.props.loginDetails.uId);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  load(data) {
    this.getStarValue(data);
  }

  async getStarValue(articleStarArray) {
    if (articleStarArray.length > 0) {
      for (let i = 0; i < articleStarArray.length; i++) {
        if (
          articleStarArray[i].userId === this.props.loginDetails.uId &&
          articleStarArray[i].star
        ) {
          await this.setState({ starStatus: true });
          break;
        } else {
          await this.setState({ starStatus: false });
        }
      }
    }
  }

  getCompletedListByUSer() {
    let articleBookmark = {
      articleId: this.props.articleId,
    };
    MyCourseService.getCourseCompleteByUser(articleBookmark)
      .then((response) => {
        console.log(response.data);
        if (response.data.data.completeList !== "empty") {
          this.setState({
            completed: true,
          });
        } else {
          this.setState({
            completed: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  return {
    stars: state.courses.stars,
    thumbnails: state.courses.thumbnails,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    loaderReset: () => dispatch(loaderReset()),
    updateCourseStars: (articleId) => dispatch(updateCourseStars(articleId)),
    storeThumbnails: (thumbnails, hash, thumbnail) =>
      dispatch(storeThumbnails(thumbnails, hash, thumbnail)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(CourseCard));
