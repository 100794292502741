import {
  GET_MY_REQUEST_LIST,
  GET_OTHER_REQUEST_LIST,
  CHANGE_REQUEST_LOADER_STATUS,
  GET_LOGOUT_DATA,
  CHANGE_OTHER_REQUEST_LOADER_STATUS,
  GET_JOB_REQUEST_DETAILS,
  GET_JOB_REQUEST_LOADER_STATUS,
  GET_JOB_REQUEST_COUNT,
  GET_JOB_REQUEST_SEARCH_LIST,
  CHANGE_HOME_LOADER_STATUS,
  REMOVE_JOB_REQUEST_COUNT,
} from "../types";

const initialState = {
  list: [],
  myRequestList: [],
  otherRequestList: [],
  myRequestTotalPages: 0,
  requestLoaderStatus: false,
  seeMorePage: false,
  firstTimeRegPage: false,
  otherRequestPages: 0,
  otherRequestLoaderStatus: false,
  otherRequestSeeMorePage: false,
  jobRequestDetails: {},
  jobRequestLoaderStatus: false,
  otherRequestCount: 0,
  homeOtherRequest: [],
  homeRequestSeeMore: false,
  homeOtherRequestCount: 0,
  homeRequestLoader: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_REQUEST_LOADER_STATUS:
      return {
        ...state,
        requestLoaderStatus: true,
      };

    case CHANGE_HOME_LOADER_STATUS:
      return {
        ...state,
        homeRequestLoader: true,
      };

    case CHANGE_OTHER_REQUEST_LOADER_STATUS:
      return {
        ...state,
        otherRequestLoaderStatus: true,
      };
    case GET_JOB_REQUEST_LOADER_STATUS:
      return {
        ...state,
        jobRequestLoaderStatus: true,
      };

    case GET_MY_REQUEST_LIST:
      console.log("GET_MY_REQUEST_LIST", action);
      if (action.pageNo && action.pageNo !== 0) {
        var oldPostList = state.myRequestList;
        return {
          ...state,
          myRequestList: oldPostList.concat(
            action.payload.jobRequestData ? action.payload.jobRequestData : []
          ),
          myRequestTotalPages: action.payload.totalItems,
          requestLoaderStatus: false,
          seeMorePage:
            action.payload.totalPages - 1 > action.payload.pageNo
              ? true
              : false,
          firstTimeRegPage: action.payload?.firstTimeAskHelp,
        };
      } else {
        return {
          ...state,
          myRequestList: action.payload?.jobRequestData
            ? action.payload.jobRequestData
            : [],
          myRequestTotalPages: action.payload?.totalItems,
          requestLoaderStatus: false,
          seeMorePage:
            action.payload?.totalPages - 1 > action?.pageNo ? true : false,
          firstTimeRegPage: action.payload?.firstTimeAskHelp,
        };
      }

    case GET_OTHER_REQUEST_LIST:
      console.log("GET_OTHER_REQUEST_LIST", action);
      if (action.pageNo && action.pageNo !== 0) {
        var oldPostList = state.otherRequestList;
        return {
          ...state,
          otherRequestList: oldPostList.concat(
            action.payload.jobRequestData ? action.payload.jobRequestData : []
          ),
          otherRequestPages: action.payload.totalItems,
          otherRequestLoaderStatus: false,
          otherRequestSeeMorePage:
            action.payload.totalPages - 1 > action.pageNo ? true : false,
        };
      } else {
        return {
          ...state,
          otherRequestList: action.payload?.jobRequestData
            ? action.payload.jobRequestData
            : [],
          otherRequestPages: action.payload?.totalItems,
          otherRequestLoaderStatus: false,
          otherRequestSeeMorePage:
            action.payload?.totalPages - 1 > action?.pageNo ? true : false,
        };
      }

    case GET_JOB_REQUEST_DETAILS:
      console.log("GET_JOB_REQUEST_DETAILS", action);
      return {
        ...state,
        jobRequestLoaderStatus: false,
        jobRequestDetails: action.payload,
      };
    case GET_JOB_REQUEST_COUNT:
      console.log("GET_JOB_REQUEST_COUNT", action.payload);
      return {
        ...state,
        otherRequestCount: action.payload,
      };
    case GET_JOB_REQUEST_SEARCH_LIST:
      console.log("GET_JOB_REQUEST_SEARCH_LIST", action.payload);
      if (action.pageNo && action.pageNo !== 0) {
        var oldPostList = state.homeOtherRequest;
        return {
          ...state,
          homeOtherRequest: oldPostList.concat(
            action.payload.jobRequestData ? action.payload.jobRequestData : []
          ),

          homeRequestSeeMore:
            action.payload?.totalPages - 1 > action?.pageNo ? true : false,
          homeRequestLoader: false,
        };
      } else {
        return {
          ...state,
          homeOtherRequest: action.payload?.jobRequestData
            ? action.payload.jobRequestData
            : [],
          homeRequestSeeMore:
            action.payload?.totalPages - 1 > action?.pageNo ? true : false,
          homeRequestLoader: false,
        };
      }

    case REMOVE_JOB_REQUEST_COUNT:
      console.log("REMOVE_JOB_REQUEST_COUNT", action.payload);

      return {
        ...state,
        otherRequestCount:
          state.otherRequestCount === 0 ? 0 : state.otherRequestCount - 1,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");
      console.log(initialState.subjectslist);
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
