import {
  UPDATE_POST_LIKE,
  GET_POST_LIST,
  GET_POST_LIST_LOADER_STATUS,
  NEW_POST,
  DELETE_POST,
  COMMENTS_COUNT_UPDATE,
  SUBSCRIBE_POST,
  UPDATE_POST,
  POST_LIKE_USER_LIST,
  POST_NOT_PRESENT,
  GET_CONNECTION_FRIEND_LIST,
  UPDATE_CONNECTION_FRIEND_LIST,
  LIKE_POINTS,
  LIKE_AND_COMMENT_UPDATE,
  LOAD_LOCAL_POST,
} from "../types";

const initialState = {
  postList: [],
  loaderStats: false,
  totalPages: 0,
  pagination: false,
  seeMorePage: false,
  postError: false,
  toastData: null,
  toastDataStatus: false,
  firstTimeSetupStatus: {},
  likeUsersList: [],
  likeSeeMore: false,
  likesCount: 0,
  isDeletedPost: false,
  isDeletePostStatus: false,
  friendsList: [],
  friendsSeeMorePage: false,
  totalFriends: 0,
  activePage: 0,
  friendUpdateState: false,
  likePointIndex: null,
  postLikeStatus: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_POST_LIST:
      console.log("GET_POST_LIST", action.payload);
      if (action.pagination && action.payload.userpost) {
        let oldPostList = state.postList;
        let filterData = action.payload.userpost.filter(
          (item2) => !oldPostList.some((item1) => item1.postId === item2.postId)
        );
        // state.postList.push(filterData ? filterData : []);
        console.log("filterData", filterData);
        return {
          ...state,
          postList: oldPostList.concat(filterData ? filterData : []),
          loaderStats: false,
          totalPages: action.payload.totalPages ? action.payload.totalPages : 0,
          seeMorePage:
            action.payload.totalPages - 1 > action.pageNo ? true : false,

          postError: false,
          isDeletedPost: false,
        };
      } else {
        return {
          ...state,
          postList: action.payload.userpost ? action.payload.userpost : [],
          loaderStats: false,
          totalPages: action.payload.totalPages ? action.payload.totalPages : 0,
          seeMorePage:
            action.payload.totalPages - 1 > action.pageNo ? true : false,
          firstTimeSetupStatus: action.payload.firstTimeSetup
            ? action.payload.firstTimeSetup
            : {},
          postError: false,
          isDeletedPost: false,
        };
      }

    case GET_POST_LIST_LOADER_STATUS:
      console.log("GET_POST_LIST_LOADER_STATUS");
      console.log(action.payload);
      return {
        ...state,
        loaderStats: true,
        postError: false,
        isDeletedPost: false,
      };

    case NEW_POST:
      console.log("NEW_POST", action.payload);
      if (action.payload.postId) {
        var oldPostList = state.postList;
        console.log("OLD", oldPostList);
        return {
          ...state,
          postList: [action.payload, ...oldPostList],
          postError: false,
          loaderStats: false,
          toastData: action.toastData,
          toastDataStatus: !state.toastDataStatus,
          isDeletedPost: false,
        };
      } else {
        return {
          ...state,
          postError: true,
          toastData: null,
          isDeletedPost: false,
        };
      }

    case UPDATE_POST_LIKE:
      console.log("UPDATE_POST_LIKE", action);

      if (action.payload) {
        let apiData = action.payload;
        let oldPostList = state.postList;
        const finalResult = oldPostList.map((person) => {
          if (person.postId === apiData.postId) {
            person.userlike = apiData.userlike;
            person.userlikeType = action.userLike ? apiData.userlikeType : "";
            person.likesCount = apiData?.likeCount;
            person.allLikes = apiData?.allLikes;
            person.commendsCount = apiData?.commendsCount;
          }
          return person;
        });
        console.log("finalResult", finalResult);
        return {
          ...state,
          postList: finalResult ? finalResult : state.postList,
          postError: false,
          isDeletedPost: false,
          likePointIndex: action.alreadyLiked ? action.activeIndex : null,
        };
      } else {
        return {
          ...state,
          postError: true,
          isDeletedPost: false,
          likePointIndex: null,
        };
      }

    case DELETE_POST:
      console.log("DELETE_POST", action.payload);
      if (action.payload) {
        var id = action.payload;
        var oldPostList = state.postList;
        oldPostList = oldPostList.filter((data) => data.postId !== id);
        return {
          ...state,
          postList: oldPostList ? oldPostList : state.postList,
          postError: false,
          toastData: action.toastData,
          toastDataStatus: !state.toastDataStatus,
          isDeletedPost: false,
        };
      } else {
        return {
          ...state,
          postError: true,
          isDeletedPost: false,
        };
      }

    case COMMENTS_COUNT_UPDATE:
      console.log("COMMENTS_COUNT_UPDATE", action.payload);
      var oldPostList = state.postList;
      const finalResult = oldPostList.map((person) => {
        if (person.postId === action.payload.postId) {
          person.commendsCount = action.payload.count;
        }
        return person;
      });
      return {
        ...state,
        postList: finalResult ? finalResult : state.postList,
        postError: false,
        isDeletedPost: false,
      };

    case SUBSCRIBE_POST:
      var oldPostList = state.postList;
      console.log("SUBSCRIBE_POST", action.payload);
      let response = action.payload;
      if (response.type === "subscribe") {
        const finalResult = oldPostList.map((person) => {
          if (person.postId === response.result.postId) {
            person.postSubscribe.isSubscribe = response.result.isSubscribe;
            person.postSubscribe.subscribeId = response.result.subscribeId;
          }
          return person;
        });
        console.log("finalResult", finalResult);
        return {
          ...state,
          postList: finalResult ? finalResult : state.postList,
          postError: false,
          isDeletedPost: false,
        };
      } else if (response.type === "bookmark") {
        let finalResult = [];
        if (response.bookmark) {
          var oldPostList = state.postList;
          finalResult = oldPostList.filter(
            (data) => data.postId !== response.result
          );
          console.log("deleteBookMarkPost id", response.result);
        } else {
          finalResult = oldPostList.map((person) => {
            if (person.postId === response.result) {
              person.userBookMark = person.userBookMark ? false : true;
            }
            return person;
          });
        }

        console.log("finalResult", finalResult);
        return {
          ...state,
          postList: finalResult ? finalResult : state.postList,
          postError: false,
          isDeletedPost: false,
        };
      } else {
        return {
          ...state,
          postError: true,
          isDeletedPost: false,
        };
      }
    case UPDATE_POST:
      console.log("UPDATE_POST", action.payload);
      console.log(action.payload);
      if (action.payload) {
        var oldPostList = state.postList;
        var updatePostData = [];
        for (let i = 0; i < oldPostList.length; i++) {
          if (oldPostList[i].postId === action.payload.postId) {
            updatePostData.push(action.payload);
          } else {
            updatePostData.push(oldPostList[i]);
          }
        }
        return {
          ...state,
          postList: updatePostData ? updatePostData : state.postList,
          postError: false,
          toastData: action.toastData,
          toastDataStatus: !state.toastDataStatus,
          isDeletedPost: false,
        };
      } else {
        return {
          ...state,
          postError: true,
          isDeletedPost: false,
        };
      }

    case POST_LIKE_USER_LIST:
      console.log("POST_LIKE_USER_LIST", action.payload);
      console.log(action.payload);
      if (action.pagination) {
        var oldPostList = state.likeUsersList;
        return {
          ...state,
          likeUsersList: oldPostList.concat(
            action.payload.likeUsersList ? action.payload.likeUsersList : []
          ),
          likeSeeMore:
            action.payload.TotalPages - 1 > action.pageNo ? true : false,
        };
      } else {
        let update = state.postList;
        let result = action.payload;
        update.map((item) => {
          if (item.postId === action.postId) {
            item.commendsCount = result.commendsCount;
            item.allLikes = result.allLikes;
          }
          return item;
        });
        return {
          ...state,
          likeUsersList: action.payload.likeUsersList
            ? action.payload.likeUsersList
            : [],
          likeSeeMore:
            action.payload.TotalPages - 1 > action.pageNo ? true : false,
          likesCount:
            action.needCount === "all"
              ? action.payload.TotalElements
              : state.likesCount,
          postList: update ? update : state.postList,
          postLikeStatus: !state.postLikeStatus,
        };
      }

    case POST_NOT_PRESENT:
      var id = action.payload;
      var oldPostList = state.postList;
      oldPostList = oldPostList.filter((data) => data.postId !== id);

      return {
        ...state,
        postList: oldPostList ? oldPostList : state.postList,
        isDeletedPost: true,
        isDeletePostStatus: !state.isDeletePostStatus,
      };

    case GET_CONNECTION_FRIEND_LIST:
      console.log("GET_CONNECTION_FRIEND_LIST", action);
      if (action.pagination) {
        var oldPostList = state.friendsList;
        return {
          ...state,
          friendsList: oldPostList.concat(
            action.payload.friendsList ? action.payload.friendsList : []
          ),
          loaderStats: false,
          friendsSeeMorePage:
            action.payload.totalPages - 1 > action.pageNo ? true : false,
          activePage: action.pageNo,
        };
      } else {
        return {
          ...state,
          friendsList: action.payload.friendsList
            ? action.payload.friendsList
            : [],
          loaderStats: false,
          friendsSeeMorePage:
            action.payload.totalPages - 1 > action.pageNo ? true : false,
          totalFriends: action.payload.totalElements,
          activePage: action.pageNo,
        };
      }

    case UPDATE_CONNECTION_FRIEND_LIST:
      let oldList;
      if (action.deleteData) {
        oldList = state.friendsList.filter(
          (item) => item.userId != action.payload.id
        );
      } else {
        oldList = state.friendsList.forEach((element) => {
          if (element.userId === action.payload.id) {
            element.type = action.payload.type;
          }
        });
      }

      return {
        ...state,
        friendsList: oldList ? oldList : state.friendsList,
        friendUpdateState: !state.friendUpdateState,
        totalFriends: action.deleteData
          ? Number(state.totalFriends) - 1
          : state.totalFriends,
      };
    case LIKE_POINTS:
      console.log("LIKE_POINTS", action.payload);
      return {
        ...state,
        likePointIndex: action.payload,
      };
    case LIKE_AND_COMMENT_UPDATE:
      console.log("LIKE_AND_COMMENT_UPDATE", action.payload);
      let update = state.postList;
      let result = action.payload;
      update.map((item) => {
        if (item.postId === result.postId) {
          item.commendsCount = result.commendsCount;
          item.allLikes = result.allLikes;
        }
        return item;
      });
      console.log("LIKE_AND_COMMENT_UPDATE", update);

      return {
        ...state,
        postList: update ? update : state.postList,
      };

    case LOAD_LOCAL_POST:
      console.log("LOAD_LOCAL_POST", action.payload);
      return {
        ...state,
        postList: action.payload ? action.payload : state.postList,
      };
    default:
      return state;
  }
}
