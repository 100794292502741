/* eslint-disable import/no-anonymous-default-export */
export default {
  control: {
    backgroundColor: "#fff",
    fontSize: 16,
    // fontWeight: 'normal',
  },

  "&multiLine": {
    control: {
      fontFamily: "monospace",
      minHeight: 60,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      padding: 9,
      color: "#29292e",
      border: "1px solid #e1e4e6",
      appearance: "none",
      borderRadius: "0.4rem",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "#fff",
      border: "2px solid rgba(0, 0, 0, 0.15)",
      fontSize: "16px",
      borderRadius: "2px",
      maxHeight: "15rem",
      overflow: "auto",
      overflowX: "hidden",
      scrollbarWidth: "thin",
      scrollbarColor: "#888 #f1f1f1",
      padding: "6px 2px 0px 2px",
      width: "100%",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#a00030",
        color: "white",
      },
    },
  },
};
