import ProfileService from "../../api/ProfileService";
import {
  GET_RECENTLY_BADGES_LIST,
  GET_BADGES_POINTS_HISTORY,
  GET_ALL_BADGES_LIST,
} from "../types";

export const getAllBadgesList = () => async (dispatch) => {
  try {
    ProfileService.getAllBadges()
      .then((response) => {
        console.log(response);
        var result = response.data;

        var result = response.data;
        var badgesList = result.data.badgs;

        dispatch({
          type: GET_ALL_BADGES_LIST,
          payload: badgesList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_BADGES_LIST,
      payload: [],
    });
  }
  return [];
};

export const getRecentlyAddedBadges = () => async (dispatch) => {
  try {
    let badges = {};
    ProfileService.getRecentlyAddedBadges(badges)
      .then((response) => {
        var result = response.data;

        dispatch({
          type: GET_RECENTLY_BADGES_LIST,
          payload: result.data.userBadges,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_RECENTLY_BADGES_LIST,
      payload: [],
    });
  }
  return [];
};

export const getBadgesPoints = (page) => async (dispatch) => {
  try {
    let badges = {
      page: page,
    };
    ProfileService.getBadgesPointsHistory(badges)
      .then((response) => {
        const badgesPoints = response.data.data;
        dispatch({
          type: GET_BADGES_POINTS_HISTORY,
          payload: badgesPoints,
          page: page,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_BADGES_POINTS_HISTORY,
      payload: [],
      page: 0,
    });
  }
  return [];
};
