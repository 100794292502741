import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { AiOutlineCopy, AiTwotoneCopy } from "react-icons/ai";
import i18n from "../../../i18n";
import AuthService from "../../../session/AuthService";
import * as APPCONFIG from "../../../config/app-config.js";
import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Modal,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap";
import { premiumUser } from "../../../utils";
import dummyimage from "../../../img/removed-background-logo.png";
import dummyimage2 from "../../../img/premiumBadge.jpg";

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyData: false,
      popUpAlert: false,
    };
    this.popUpAlert = this.popUpAlert.bind(this);
  }
  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }
  render() {
    console.log("this.props", this.props);
    const { t } = this.props;
    const {
      name,
      designation,
      additionalInfo,
      pointsCount,
      premiumSubscription,
      ispermiumUser,
    } = this.props;
    return (
      <div className="userInfoWrapper">
        <h2>
          {name}{" "}
          {premiumUser(this.props.loginDetails?.premiumData) && (
            <>
              <img
                src={premiumUser(this.props.loginDetails?.premiumData)}
                alt=""
                className="ml-1"
                style={{ width: "20px" }}
              />

              {/* {premiumSubscription.ispermiumUser !== "no-badge-persent" ? (
                <img
                  src={dummyimage2}
                  alt="Cover Photo"
                  style={{ width: "2.5rem", float: "inline-end" }}
                  title={"Yuukke Premium Member"}
                />
              ) : (
                <></>
              )} */}
            </>
          )}
        </h2>
        <p className="userDesignation">{designation}</p>
        <p className="useradditionalInfo">{additionalInfo}</p>
        <div className="profileCopy-card d-flex  ">
          <p onClick={this.popUpAlertStatus} class="tour-vcard">
            VCARD{" "}
          </p>
          {this.props.loginDetails?.vcardIsPresent && (
            <>
              {this.state.copyData ? (
                <AiTwotoneCopy
                  class="copy-icon"
                  style={{ color: "rgb(160, 0, 48)" }}
                  onClick={this.handleCopy}
                />
              ) : (
                <AiOutlineCopy class="copy-icon" onClick={this.handleCopy} />
              )}
            </>
          )}
        </div>
        {/* <div className="activity-badge-container">
          <div className="activity-badge-parent">
            <img src={dummyimage} alt="Cover Photo" style={{height : "1.5rem"}} />
            <div className="activity-badge-text">
              {premiumSubscription.isactivityBadge}
            </div>
          </div>
        </div> */}

        {this.state.popUpAlert && this.popUpAlert()}
      </div>
    );
  }
  popUpAlertStatus = () => {
    if (this.props.loginDetails?.vcardIsPresent) {
      window.open(
        `${APPCONFIG.BASE_URL}userProfile/${this.props.loginDetails.vcardUiid}`
      );
    } else {
      this.setState({
        popUpAlert: !this.state.popUpAlert,
      });
    }
  };
  popUpAlert() {
    const { t } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Modal show="true" onHide={this.popUpAlertStatus}>
          <Modal.Body>
            <Form.Group className="formInput">
              <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                {t("Your vcard is empty")}
              </Form.Label>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.popUpAlertStatus} variant="primary">
              {t("Close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        `${APPCONFIG.BASE_URL}userProfile/${this.props.loginDetails?.vcardUiid}`
      );
      this.setState({
        copyData: true,
      });
      toast.success("Copied");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
}
const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    userSubsription: state.como,
  };
};
export default connect(mapStateToProps, null)(withTranslation()(UserInfo));
