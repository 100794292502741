import React, { useState, useCallback, useEffect, useMemo } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../components/cropImage";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useDropzone } from "react-dropzone";
import Slider from '@material-ui/core/Slider';

import Grid from "@material-ui/core/Grid";

var finalData = []
const baseStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "70px",
 

};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};
const previewStyle = {
    width: "75%",
    height: "300px",
};
const Dropzone = (props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(2);
    const [profilePhotoCrop, setprofilePhotoCrop] = useState(props.profilePhotoAfterCrop)
    const [name, setName] = useState("");
    const [croppedImage, setCroppedImage] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [uploadedImage, setImage] = useState();
    const [files, setFiles] = useState([]);
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
        console.log(croppedArea);
    }, []);
    const onDrop = useCallback((acceptedFiles) => {
        // setFiles(
        //   acceptedFiles.map((file) =>
        //     Object.assign(file, {
        //       preview: URL.createObjectURL(file),
        //     })
        //   )
        // );
        console.log(acceptedFiles)
    }, []);

    const backtochooseimage = () => {
        setprofilePhotoCrop(false)
        props.backprofilePhotoCrops(false)

    };

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            // const reader = new FileReader();
            // reader.addEventListener("load", () => setImage(reader.result));
            // reader.readAsDataURL(e.target.files[0]);
            // console.log(e.target.files[0]);
            // setName(e.target.files[0].name)
            // console.log(e)
            props.setChoosedImage(e)
        }
    };



    const onZoomChange = (data) => {
        setZoom(data)

    };


    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                uploadedImage,
                croppedAreaPixels
            );
            setCroppedImage(croppedImage);
            console.log("cii" + croppedImage);


            const base64Data = croppedImage;
            const base64 = await fetch(base64Data);

            // const base64Response = await fetch(`data:image/jpeg;base64,${base64Data}`);
            const blob = await base64.blob();
            console.log("cii" + base64);
            console.log("ci" + blob);
            console.log(blobToFile(blob, name))
            //props.onUploadSuccess(blobToFile(blob, "file.jpg"))
            var file = new File([blobToFile(blob, name)], name, { type: "image/png" });
            console.log(file)
            props.onUploadSuccess(file)
            setprofilePhotoCrop(false)
            props.backprofilePhotoCrops(false)

        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: "image/jpeg, image/png",
        multiple: true,
        maxSize: 31457280
    });
    useEffect(
        () => () => {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );
    return (
        <div>
            {!profilePhotoCrop && <div {...getRootProps({ style })}   >
                <input
                    {...getInputProps()}
                    onChange={(e) => onSelectFile(e)}
                />

                Please choose any file


            </div>}

            {profilePhotoCrop && uploadedImage ? (
                <>

                    <div
                        style={{
                            paddingTop: "80%",
                            width: "100%",
                            height: "200px",
                            position: "relative",
                            background: "#333333",
                        }}
                    >
                        <Cropper
                            image={uploadedImage}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            cropShape="round"
                            showGrid={false}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={onZoomChange}
                        />

                    </div>
                    <div className="contros">
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, zoom) => onZoomChange(zoom)}
                            classes={{ container: 'slider' }}
                        />
                    </div>

                </>
            ) : null}
        </div>
    );
};


function blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}


export default Dropzone;