import FriendsFinder from "../../api/FriendsFinder";
import MyYuukke from "../../api/MyYuukke";
import AuthService from "../../session/AuthService";
import { GET_FRIENDS_REQUEST_LIST, GET_SEARCH_FRIENDS_LIST } from "../types";
import { GET_FRIENDS_LIST } from "../types";
import { GET_RECOMMENDED_FRIENDS_LIST } from "../types";
import {
  GET_RECENTLY_ADDED_FRIENDS_LIST,
  GET_SEARCH_REQUEST_LIST,
} from "../types";
import {
  GET_USER_REQUESTED_LIST,
  GET_SEARCH_USER_REQUESTED_LIST,
  GET_SEARCH_RECOMMENDED_LIST,
} from "../types";

export const getFriendsRequestList = () => async (dispatch) => {
  let getRequestedFriends = {};
  FriendsFinder.requestedFriends(getRequestedFriends)
    .then((response) => {
      var result = response.data;
      if (result.status == 1) {
        console.log("getRequestedFriends");
        console.log(result);
        var stringArray = result.data.user;
        AuthService.storeInvitedFriendsList(stringArray);
        dispatch({
          type: GET_FRIENDS_REQUEST_LIST,
          payload: stringArray,
        });
      } else {
        dispatch({
          type: GET_FRIENDS_REQUEST_LIST,
          payload: [],
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_FRIENDS_REQUEST_LIST,
        payload: [],
      });
    });
  return [];
};

export const getUserRequestList = () => async (dispatch) => {
  let getUserRequestList = {};
  FriendsFinder.getUserRequestList(getUserRequestList)
    .then((response) => {
      var result = response.data;
      if (result.status == 1) {
        console.log("getUserRequestList");
        console.log(result);
        var stringArray = result.data.user;

        dispatch({
          type: GET_USER_REQUESTED_LIST,
          payload: stringArray,
        });
      } else {
        dispatch({
          type: GET_USER_REQUESTED_LIST,
          payload: [],
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_FRIENDS_REQUEST_LIST,
        payload: [],
      });
    });
  return [];
};

export const getRecommendedFriends = (pageNo) => async (dispatch) => {
  try {
    let getRecommendedFriends = {
      page: 0,
    };
    FriendsFinder.getRecommendedFriends(getRecommendedFriends)
      .then((response) => {
        var result = response.data;
        console.log(result);
        var stringArray = result.data.user;
        AuthService.storeRecommendedFriendsList(stringArray);
        dispatch({
          type: GET_RECOMMENDED_FRIENDS_LIST,
          payload: stringArray,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_RECOMMENDED_FRIENDS_LIST,
      payload: [],
    });
  }
  return [];
};

export const getFriendsList = (pageNo) => async (dispatch) => {
  try {
    let getFriendsList = {};
    FriendsFinder.getFriendsList(getFriendsList)
      .then((response) => {
        var result = response.data;
        // AuthService.storeFriendsList(result.data.friendsList);
        dispatch({
          type: GET_FRIENDS_LIST,
          payload: result.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_FRIENDS_LIST,
      payload: [],
    });
  }
  return [];
};
export const getrecentlyAddedFriends = (pageNo) => async (dispatch) => {
  try {
    let getrecentlyAdded = {};
    FriendsFinder.recentlyAddedFriends(getrecentlyAdded)
      .then((response) => {
        var result = response.data;
        console.log("getrecentlyAdded");
        console.log(result);
        var userlist = [];
        for (var i = 0; i < result.data.getrecentlyAddedFriends.length; i++) {
          userlist.push(result.data.getrecentlyAddedFriends[i][0].friendsId);
        }
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }
        var uniques = userlist.filter(onlyUnique);
        var newuni = [];
        for (var m = 0; m < uniques.length; m++) {
          var val = {
            userId: uniques[m],
          };
          var nnn = FriendsFinder.getFriendsDetailsCache(val);
          if (!nnn) {
            newuni.push(uniques[m]);
          }
        }
        if (newuni.length > 0) {
          FriendsFinder.getFriendsDetailsbyUserid(newuni)
            .then((response) => {
              for (var k = 0; k < response.data.data.user.length; k++) {
                // alert(response.data.data.user[k].userId)
                FriendsFinder.setFriendsDetailsCache(
                  response.data.data.user[k].userId,
                  response.data.data.user[k]
                );
              }
              var stringArray = result.data.getrecentlyAddedFriends;
              dispatch({
                type: GET_RECENTLY_ADDED_FRIENDS_LIST,
                payload: stringArray,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          var stringArray = result.data.getrecentlyAddedFriends;
          dispatch({
            type: GET_RECENTLY_ADDED_FRIENDS_LIST,
            payload: stringArray,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_RECENTLY_ADDED_FRIENDS_LIST,
      payload: [],
    });
  }
  return [];
};
export const searchFriends = (value, page) => async (dispatch) => {
  if (value !== null) {
    var search = {
      searchKey: value,
      page: page,
      type: "friendsList",
    };
  } else {
    var search = {
      page: page,
      searchKey: "",
      type: "friendsList",
    };
  }
  MyYuukke.searchAllUsers(search)
    .then((response) => {
      var result = response.data;
      if (result.status == 1) {
        console.log("getRequestedFriends");
        console.log(result);
        var stringArray = result.data.searchList;
        var pageNo = result.data.totalPages;
        // AuthService.storeInvitedFriendsList(stringArray);
        dispatch({
          type: GET_SEARCH_FRIENDS_LIST,
          payload: stringArray,
          pageNo: pageNo,
          totalCount:result.data.totalElements
        });
      } else {
        dispatch({
          type: GET_SEARCH_FRIENDS_LIST,
          payload: [],
          pageNo: 0,
          totalCount:0
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_SEARCH_FRIENDS_LIST,
        payload: [],
        pageNo: 0,
        totalCount:0
      });
    });
  return [];
};

export const searchRequestedFriendsList = (value, page) => async (dispatch) => {
  if (value !== null) {
    var search = {
      searchKey: value,
      page: page,
      type: "requestedFriends",
    };
  } else {
    var search = {
      page: page,
      searchKey: "",
      type: "requestedFriends",
    };
  }
  MyYuukke.searchAllUsers(search)
    .then((response) => {
      var result = response.data;
      if (result.status == 1) {
        console.log("getRequestedFriends");
        console.log(result);
        var stringArray = result.data.searchList;
        var pageNo = result.data.totalPages;
        // AuthService.storeInvitedFriendsList(stringArray);
        dispatch({
          type: GET_SEARCH_REQUEST_LIST,
          payload: stringArray,
          pageNo: pageNo,
          totalCount:result.data.totalElements
        });
      } else {
        dispatch({
          type: GET_SEARCH_REQUEST_LIST,
          payload: [],
          pageNo: 0,
          totalCount:0
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_SEARCH_REQUEST_LIST,
        payload: [],
        pageNo: 0,
        totalCount:0
      });
    });
  return [];
};
export const searchUserRequestList = (value, page) => async (dispatch) => {
  if (value !== null) {
    var search = {
      searchKey: value,
      page: page,
      type: "userRequestList",
    };
  } else {
    var search = {
      page: page,
      searchKey: "",
      type: "userRequestList",
    };
  }
  MyYuukke.searchAllUsers(search)
    .then((response) => {
      var result = response.data;
      if (result.status == 1) {
        console.log("getRequestedFriends");
        console.log(result);
        var stringArray = result.data.searchList;
        var pageNo = result.data.totalPages;
        // AuthService.storeInvitedFriendsList(stringArray);
        dispatch({
          type: GET_SEARCH_USER_REQUESTED_LIST,
          payload: stringArray,
          pageNo: pageNo,
          totalCount:result.data.totalElements

        });
      } else {
        dispatch({
          type: GET_SEARCH_USER_REQUESTED_LIST,
          payload: [],
          pageNo: 0,
          totalCount:0
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_SEARCH_USER_REQUESTED_LIST,
        payload: [],
        pageNo: 0,
        totalCount:0
      });
    });
  return [];
};
export const searchRecomentedFriendsList =
  (value, page) => async (dispatch) => {
    if (value !== null) {
      var search = {
        searchKey: value,
        page: page,
        type: "recomentedFriends",
      };
    } else {
      var search = {
        page: page,
        searchKey: "",
        type: "recomentedFriends",
      };
    }
    MyYuukke.searchAllUsers(search)
      .then((response) => {
        var result = response.data;
        if (result.status == 1) {
          console.log("getRequestedFriends");
          console.log(result);
          var stringArray = result.data.searchList;
          var pageNo = result.data.totalPages;
          // AuthService.storeInvitedFriendsList(stringArray);
          dispatch({
            type: GET_SEARCH_RECOMMENDED_LIST,
            payload: stringArray,
            pageNo: pageNo,
            recommendUsers: result.data.totalElements,
          });
        } else {
          dispatch({
            type: GET_SEARCH_RECOMMENDED_LIST,
            payload: [],
            pageNo: 0,
            recommendUsers: 0,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_SEARCH_RECOMMENDED_LIST,
          payload: [],
          pageNo: 0,
          recommendUsers: 0,
        });
      });
    return [];
  };
