import React, { Component } from "react";
import logo_ms from "../../images/EVENTS.png";
import home_tag from "../../img/ms-login/home_tag.gif";

class HomeTag extends Component {
  render() {
    return (
      <div>
        <center>
          <br />
          <br />
          <h1>
            <img src={logo_ms} width="75" />
          </h1>
          <br />
          <img src={home_tag} width="400" />
          <br />
          <br />
          <b>
            <p>LOADING ...</p>
          </b>
          <small>VERSION V12.0.2</small>
        </center>
      </div>
    );
  }
}

export default HomeTag;
