import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { askQuestionAnswerList } from "../../../redux/actions/questionActions";
import InfiniteScroll from "react-infinite-scroll-component";
import { API_URL } from "../../../config/app-config";
import dummyimage from "../../../img/default_avatar.jpg";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { Chip, Menu, MenuItem } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
 import * as APPCONFIG from "../../../config/app-config.js";
import { ToastContainer, toast } from "react-toastify";
import ForumService from "../../../api/ForumService";
import { BiChevronsLeft } from "react-icons/bi";
import { BiLike } from "react-icons/bi";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { Form, Modal } from "react-bootstrap";
import { Button } from "@material-ui/core";
import dummygif from "../../../img/dummygif.gif";
import { GrUndo } from "react-icons/gr";
import { AiOutlineSend } from "react-icons/ai";
import { FiRefreshCw } from "react-icons/fi";
import Lightbox from "react-image-lightbox";
import {
  getNavigationType,
  getPostTagList,
} from "../../../redux/actions/commonApiActions";
import TextEditorComments from "../../../components/TextEditorComments";
import MentionsInputStyle from "../../../components/UserMention/Mention";
import {
  extractMentionsFromUpdate,
  extractNumberFromHref,
  htmlValidation,
  removeHtmlTags,
  removeMentionTags,
  validateSpanTag
} from "../../../utils";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import PostService from "../../../api/PostService_old";
import FbGridImages from "../../Home/HomeUpdated/ImageView/Images";
import LikePopUp from "../../../components/LikePopUp";
import { Report } from "@material-ui/icons";
import ReportModel from "../../../components/ReportModel";
import { MdOutlineReport } from "react-icons/md";
import AnswerFileOptions from "../../../components/AnswerFileOptions.jsx";
class ForumDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuStateWithSub: false,
      menuState: false,
      editorValue: "",
      fileOption: false,
      questionId: null,
      selectedAnswerData: null,
      selectedQuestion: null,
      removeFileImage: false,
      fileTypeState: false,
      editOption: false,
      emptyFileState: false,
      removeMediaId: [],
      file: [],
      chipImageData: [],
      ansFileType: "",
      mediaFiles: [],
      fileTypeKey: "",
      imagePresent: false,
      userRequestPage: 0,
      forumAnswer: [],
      hasMore: true,
      editorAnswerValue: "",
      formAnswer: [],
      answerOption: false,
      newReplayStatus: false,
      replyIdex: -1,
      replayFullList: [],
      replayListStatus: false,
      replayIndex: -1,
      replyPageNo: 0,
      replyHasMore: false,
      deleteQuestionState: false,
      showFriendsProfile: false,
      deleteReplyState: false,
      editAns: false,
      replyContent: "",
      editReplyStatus: false,
      photoIndex: 0,
      isOpen: false,
      fileData: [],
      answerCount: 0,
      needEdit: false,
      needEditReply: false,
      questionMediaFile: [],
      likeCountPopUpStatus: false,
      popupId: 0,
      content: "",
      reportId: "",
      reportType: "",
      typeData: "",
      replyId: "",
    };
    this.myRef = React.createRef();
  }

  fetchMoreData = (id) => {
    this.props.askQuestionAnswerList(
      this.state.userRequestPage + 1,
      this.props.match.params.id
    );
    this.setState({
      userRequestPage: this.state.userRequestPage + 1,
    });

    if (this.props.pageNo - 1 > this.state.userRequestPage) {
      this.setState({
        hasMore: true,
      });
    } else {
      this.setState({
        hasMore: false,
      });
    }
  };
  f;
  onChange = () => {
    if (
      !this.props.userJoin &&
      this.props.forumQuestion_userId !== this.props.loginDetails.uId
    ) {
      toast.warning(
        `${this.props.t(
          "Please join this community.Then you can get all the access !"
        )}`
      );
    } else {
      this.setState({
        answerOption: !this.state.answerOption,
      });
    }
  };
  componentDidMount() {
    this.props.askQuestionAnswerList(
      this.state.userRequestPage,
      this.props.match.params.id
    );
    this.setState({
      questionId: this.props.match.params.id,
    });
    this.props.getPostTagList("groups", this.props.match.params.subId);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.forumQuestion_mediaFiles &&
      this.props.forumQuestion_mediaFiles !== prevProps.forumQuestion_mediaFiles
    ) {
      const imageArray = this.props.forumQuestion_mediaFiles.map((item) => {
        return `${APPCONFIG.API_URL}auth/image/${item.fileName}`;
      });
      this.setState({
        questionMediaFile: imageArray ? imageArray : [],
      });
    }
    if (this.props.formAnswer !== prevProps.formAnswer) {
      var oldPostList = this.state.formAnswer;
      var newPosts = oldPostList.concat(this.props.formAnswer);
      console.log(newPosts);
      this.setState({
        formAnswer: newPosts,
      });
    }

    if (this.props.postSubscribe !== prevProps.postSubscribe) {
      console.log("isSubscribe", this.props.postSubscribe);
      this.setState({
        isSubscribe: this.props.postSubscribe,
      });
    }
  }
  render() {
    const { t } = this.props;
    const { photoIndex, isOpen } = this.state;

    return (
      <>
        <ToastContainer position="top-right" autoClose={5000} />
        <div
          onClick={this.backToQuestions}
          style={{
            color: "gray",
            fontSize: "13px",
            cursor: "pointer",
          }}
        >
          <BiChevronsLeft
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          {t("Back To questions")}{" "}
        </div>
        <div class="forum-description-wrapper">
          <div class="forum-description-question-wrapper">
            <p>{this.props.questionName_List}</p>
            {this.props.loginDetails.uId ===
              this.props.forumQuestion_userId && (
              <div>
                <EditIcon
                  className="desIcons"
                  onClick={(event) =>
                    this.editQuestion(this.props.match.params.id)
                  }
                />
                <DeleteOutlineIcon
                  className="desIcons"
                  onClick={(event) =>
                    this.deleteQuestion(this.props.match.params.id)
                  }
                />
              </div>
            )}
            {this.props.userJoin &&
              this.props.loginDetails.uId !==
                this.props.forumQuestion_userId && (
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "2rem",
                    cursor: "pointer",
                  }}
                >
                  <MoreHorizIcon
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => this.handleMenuClick(e)}
                  />
                </div>
              )}
            {this.state.menuStateWithSub && (
              <Menu
                id="simple-menu"
                anchorEl={this.state.menuState}
                keepMounted
                open={this.state.menuState}
                onClose={this.handleMenuClose}
              >
                <>
                  {this.state.isSubscribe ? (
                    <div
                      className="menuCard"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.subscribe(false, this.state.questionId)
                      }
                    >
                      <NotificationsNoneIcon className="menuItem bg-color" />{" "}
                      <MenuItem>
                        <p class="bg-color">{t("unsubscribe")}</p>
                      </MenuItem>
                    </div>
                  ) : (
                    <div
                      className="menuCard"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.subscribe(true, this.state.questionId)
                      }
                    >
                      <NotificationsNoneIcon className="menuItem" />{" "}
                      <MenuItem>
                        <p style={{ color: "black" }}>{t("Subscribe")}</p>
                      </MenuItem>
                    </div>
                  )}
                </>
                <div
                  className="menuCard"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.reportAction("question")}
                >
                  <Report className="menuItem" />{" "}
                  <MenuItem>
                    <p style={{ color: "black" }}>{t("Report")}</p>
                  </MenuItem>
                </div>
              </Menu>
            )}
          </div>

          <div class="forum-big-dis">
            <p onClick={this.handleHashTag}>
              {htmlValidation(
                this.props.questionName_Des ? this.props.questionName_Des : ""
              )}
            </p>
            {/* <p
              dangerouslySetInnerHTML={{
                __html: this.props.questionName_Des,
              }}
            />{" "} */}
          </div>
          <div class="q-tag">
            {this.props.forumQuestion_tags &&
              this.props.forumQuestion_tags.slice(0, 5).map((tags) => (
                <div class="pl-2 pb-2">
                  <div
                    className="subjectTag"
                    style={{
                      background: "rgb(85, 85, 85)",
                      color: "white",
                      cursor: "auto",
                    }}
                  >
                    {tags.tagName}
                  </div>
                </div>

                //  <a href="#" class="badge badge-secondary">
                //   {tags.tagName}
                // </a>
              ))}
          </div>
          <hr />
          <div class="forum-q-image">
            {this.props.forumQuestion_mediaFiles &&
              this.props.forumQuestion_mediaFiles.length !== 0 && (
                <>
                  <FbGridImages
                    images={this.state.questionMediaFile}
                    onClickImage={() => {}}
                  />
                </>
              )}

            {/* {this.props.forumQuestion_mediaFiles &&
              this.props.forumQuestion_mediaFiles.length !== 0 &&
              this.props.forumQuestion_mediaFiles
                .slice(0, 4)
                .map((data) => (
                  <img
                    onClick={() =>
                      this.onpenLightBox(this.props.forumQuestion_mediaFiles)
                    }
                    className="forum_questionMedia"
                    controls
                    src={
                      data.fileName === undefined || data.fileName.length === 0
                        ? dummygif
                        : `${APPCONFIG.API_URL}auth/image/${data.fileName}`
                    }
                    alt="question"
                  ></img>
                ))} */}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12 col-12 col-lg-12 col-md-12 forum-question-list-wrapper">
            <InfiniteScroll
              dataLength={this.state.forumAnswer.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              scrollableTarget="scrollableDiv"
            >
              <div
                id="scrollableDiv"
                style={{
                  minHeight: "auto",
                  maxHeight: "40.5rem",
                  overflow: "auto",
                  overflowX: "hidden",
                  background: "white",
                  borderRadius: "5px",
                  // margin: "0px 22px 25px",
                }}
              >
                {this.state.formAnswer &&
                  this.state.formAnswer.map((data, index) => (
                    <>
                      <div
                        class="row forum-card-ans"
                        // onClick={() => moveDescriptionPage(data.questionId)}
                      >
                        <div class="col-12 col-lg-1 col-md-2 col-sm-4">
                          <div className="post-avatar post-avatar-xs">
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.showFriendsProfile(data.userId)
                              }
                              className="post-avatar-img rounded-circle"
                              src={
                                API_URL +
                                "api/getUserPhoto/" +
                                data.userId +
                                "/" +
                                data.userName
                              }
                              alt="user"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = dummyimage;
                              }}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-lg-11 col-md-10 col-sm-8">
                          <div className="post-nav post-nav-divider">
                            <p
                              className="post-nav-item post-card-title post-mb-0 displayName-forum"
                              style={{ textTransform: "capitalize" }}
                            >
                              {" "}
                              {data.userName}
                            </p>
                            <span className="post-nav-item post-small">
                              {moment(data.createdDate)
                                .locale("en")
                                .format("ll")}{" "}
                            </span>
                            {this.props.loginDetails.uId === data.userId && (
                              <>
                                <EditIcon
                                  className="actionIcon ml-3"
                                  onClick={(event) =>
                                    this.editAnswer(
                                      data.answerId,
                                      data.answer,
                                      data.mediaFiles,
                                      data.answerType
                                    )
                                  }
                                />
                                <DeleteOutlineIcon
                                  className="actionIcon ml-2 "
                                  onClick={(event) =>
                                    this.deleteAns(data.answerId, event)
                                  }
                                />
                              </>
                            )}

                            {this.props.loginDetails.uId !== data.userId && (
                              <>
                                <MdOutlineReport
                                  className="actionIcon ml-3"
                                  onClick={(event) =>
                                    this.reportAction("answer", data.answerId)
                                  }
                                />
                              </>
                            )}
                          </div>

                          <p onClick={this.handleHashTag}>
                            {htmlValidation(data.answer ? data.answer : "")}
                          </p>
                          {/* <p
                            dangerouslySetInnerHTML={{
                              __html: data.answer,
                            }}
                          /> */}
                          {data.answerType === "image" && (
                            <>
                              <div
                                onClick={() =>
                                  this.onpenLightBox(data.mediaFiles)
                                }
                                className="d-flex flex-wrap"
                                style={{ gap: "1rem" }}
                              >
                                {data.mediaFiles &&
                                  data.mediaFiles.map((data) => (
                                    <img
                                      className="forum_questionMedia"
                                      controls
                                      src={
                                        data.fileName === undefined ||
                                        data.fileName.length === 0
                                          ? dummygif
                                          : `${APPCONFIG.API_URL}auth/image/${data.fileName}`
                                      }
                                      alt="question"
                                    ></img>
                                  ))}
                              </div>
                            </>
                          )}
                          {isOpen && (
                            <Lightbox
                              mainSrc={this.state.fileData[photoIndex]}
                              nextSrc={
                                this.state.fileData[
                                  (photoIndex + 1) % this.state.fileData.length
                                ]
                              }
                              prevSrc={
                                this.state.fileData[
                                  (photoIndex +
                                    this.state.fileData.length -
                                    1) %
                                    this.state.fileData.length
                                ]
                              }
                              onCloseRequest={() =>
                                this.setState({ isOpen: false })
                              }
                              onMovePrevRequest={() =>
                                this.setState({
                                  photoIndex:
                                    (photoIndex +
                                      this.state.fileData.length -
                                      1) %
                                    this.state.fileData.length,
                                })
                              }
                              onMoveNextRequest={() =>
                                this.setState({
                                  photoIndex:
                                    (photoIndex + 1) %
                                    this.state.fileData.length,
                                })
                              }
                            />
                          )}
                          <div class="forum-ans-button">
                            {data.replyCount !== 0 && (
                              <span
                                onClick={() =>
                                  this.viewReply(data.answerId, index)
                                }
                              >
                                {"View"} {data.replyCount}{" "}
                                {data.replyCount > 1
                                  ? t("Replies")
                                  : t("Reply")}
                              </span>
                            )}
                            {/* {data.userVote && ( */}
                            <>
                              {" "}
                              {data.votes !== 0 && (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.likeCountPopUp(data.answerId)
                                  }
                                >
                                  <BiLike style={{ marginRight: "7px" }} />

                                  <span style={{ color: "gray" }}>
                                    {data.userVote &&
                                      data.votes === 1 &&
                                      data.votes !== 0 && <>You like this..</>}
                                    {data.userVote && data.votes > 1 && (
                                      <>
                                        {t("You and")} {data.votes - 1}{" "}
                                        {t("other people like this..")}
                                      </>
                                    )}
                                    {data.votes !== 0 && !data.userVote && (
                                      <>{t("One People like this..")}</>
                                    )}
                                  </span>
                                </span>
                              )}
                            </>
                            {/* )} */}

                            {this.state.replyIdex !== index && (
                              <>
                                <p
                                  onClick={() =>
                                    this.addReply(data.answerId, index)
                                  }
                                >
                                  {t("Reply")}
                                </p>
                                <p
                                  className={
                                    data.userVote ? "forum-reply-status" : " "
                                  }
                                  onClick={() =>
                                    this.addLike(
                                      data.answerId,
                                      data.userVote,
                                      index
                                    )
                                  }
                                >
                                  {data.userVote === true
                                    ? t("Liked")
                                    : t("Like")}{" "}
                                </p>
                              </>
                            )}
                          </div>
                          {this.state.newReplayStatus &&
                            this.state.answerId === data.answerId && (
                              <div class="row">
                                <div class="col-9 col-sm-6 col-md-8 col-lg-10 pl-1 pt-2">
                                  <MentionsInputStyle
                                    placeholder={"Add Reply ..."}
                                    editorStateValue={this.replyValues}
                                    editorValue={this.state.editorValue}
                                    friendList={this.props.friendsList}
                                    type={"friends"}
                                    needEdit={this.state.needEditReply}
                                    changeNeedHelpState={
                                      this.changeNeedHelpState
                                    }
                                  />
                                  {/* <textarea
                                    className="post-form-control post-pe-4 post-bg-light"
                                    rows="1"
                                    placeholder={t("Add a Reply...")}
                                    maxLength="255"
                                    type="text"
                                    // onKeyDown={this.handleFormEnterKey}
                                    onChange={this.handleForm}
                                    value={this.state.replyContent}
                                    name="replyContent"
                                    style={{ overflow: "hidden" }}
                                  ></textarea> */}
                                </div>
                                <div class="col-3 col-sm-6 col-md-4 col-lg-2">
                                  {!this.state.editReplyStatus && (
                                    <AiOutlineSend
                                      style={{
                                        width: "28px",
                                        height: "61px",
                                        cursor: "pointer",
                                        color: "#676a79",
                                      }}
                                      onClick={(e) =>
                                        this.onFormSubmitReply(e, data.answerId)
                                      }
                                    />
                                  )}
                                  {this.state.editReplyStatus && (
                                    <FiRefreshCw
                                      style={{
                                        width: "28px",
                                        height: "61px",
                                        cursor: "pointer",
                                        color: "#676a79",
                                      }}
                                      onClick={(e) =>
                                        this.onFormUpdateReply(e, data.answerId)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            )}

                          {this.state.replayFullList &&
                            this.state.replayListStatus &&
                            this.state.answerId === data.answerId &&
                            this.state.replayFullList.map((reply) => (
                              <>
                                <hr />
                                <div class="row mt-2 mb-2">
                                  <div class="col-12 col-lg-1 col-md-2 col-sm-4">
                                    <div className="post-avatar post-avatar-xs">
                                      <img
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.showFriendsProfile(data.userId)
                                        }
                                        className="post-avatar-img rounded-circle"
                                        src={
                                          API_URL +
                                          "api/getUserPhoto/" +
                                          reply.userId +
                                          "/" +
                                          reply.userName
                                        }
                                        alt="user"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = dummyimage;
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div class="col-12 col-lg-11 col-md-10 col-sm-8">
                                    <div className="post-nav post-nav-divider">
                                      <p
                                        className="post-nav-item post-card-title post-mb-0 displayName-forum"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {" "}
                                        {reply.userName}
                                      </p>
                                      <span className="post-nav-item post-small">
                                        {moment(reply.createdDate)
                                          .locale("en")
                                          .format("ll")}{" "}
                                      </span>
                                      {this.props.loginDetails.uId ===
                                        reply.userId && (
                                        <>
                                          <EditIcon
                                            className="actionIcon ml-3"
                                            onClick={(event) =>
                                              this.editReply(
                                                reply.replyId,
                                                reply.answerId,
                                                reply.answerReply
                                              )
                                            }
                                          />
                                          <DeleteOutlineIcon
                                            className="actionIcon ml-2 "
                                            onClick={(event) =>
                                              this.deleteReply(
                                                reply.replyId,
                                                reply.answerId,
                                                event
                                              )
                                            }
                                          />
                                        </>
                                      )}

                                      {this.props.loginDetails.uId !==
                                        reply.userId && (
                                        <>
                                          <MdOutlineReport
                                            className="actionIcon ml-3"
                                            onClick={(event) =>
                                              this.reportAction(
                                                "reply",
                                                reply.replyId,
                                                reply.answerId
                                              )
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                    <p onClick={this.handleHashTag}>
                                      {htmlValidation(
                                        reply.answerReply
                                          ? reply.answerReply
                                          : ""
                                      )}
                                    </p>
                                    {/* <p
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: reply.answerReply,
                                      }}
                                    /> */}
                                    {reply.type === "image" && (
                                      <>
                                        {reply.mediaFiles &&
                                          reply.mediaFiles.map((data) => (
                                            <img
                                              className="forum_questionMedia"
                                              controls
                                              src={
                                                data.fileName === undefined ||
                                                data.fileName === null
                                                  ? dummygif
                                                  : `${APPCONFIG.API_URL}auth/image/${data.fileName}`
                                              }
                                              alt="question"
                                            ></img>
                                          ))}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            ))}
                          {this.state.replayFullList &&
                            this.state.replayListStatus &&
                            this.state.replyHasMore &&
                            this.state.answerId === data.answerId && (
                              <div
                                style={{
                                  marginTop: "1rem",
                                  textAlign: "center",
                                }}
                              >
                                <button
                                  onClick={() =>
                                    this.replyListCall(data.answerId, index)
                                  }
                                  class="btn cardButton-forum"
                                >
                                  {t("Load More")}{" "}
                                </button>
                              </div>
                            )}
                        </div>
                      </div>
                      <hr />
                    </>
                  ))}{" "}
                {!this.state.answerOption && (
                  <div class="mb-4 mt-4 ml-2">
                    <div class="answer-reply">
                      <div className="post-avatar post-avatar-xs post-me-2 ml-2">
                        <a href="#!">
                          {" "}
                          <img
                            className="post-avatar-img rounded-circle"
                            src={
                              API_URL +
                              "api/getUserPhoto/" +
                              this.props.loginDetails.uId +
                              "/" +
                              this.props.loginDetails.displayName
                            }
                            alt="user"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = dummyimage;
                            }}
                          />{" "}
                        </a>
                      </div>
                      <div class="submit-btn" onClick={this.onChange}>
                        {t("Submit your answer....")}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </InfiniteScroll>
            {this.state.answerOption && (
              <Grid container spacing={1} style={{ padding: "15px" }}>
                <Grid item xs={11} sm={11} lg={12} md={12}>
                  <div class="forum-text-Editor cardBox">
                    <Grid container spacing={1}>
                      <Grid item xs={8} sm={10} md={10} lg={11}>
                        <div class="d-flex mt-2">
                          <div className="post-avatar post-avatar-xs post-me-2">
                            <a href="#!">
                              {" "}
                              <img
                                className="post-avatar-img rounded-circle"
                                src={
                                  API_URL +
                                  "api/getUserPhoto/" +
                                  this.props.loginDetails.uId +
                                  "/" +
                                  this.props.loginDetails.displayName
                                }
                                alt="user"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = dummyimage;
                                }}
                              />{" "}
                            </a>
                          </div>

                          <p class="pt-2">
                            <GrUndo style={{ height: "15px", width: "25" }} />
                            {this.props.questionName_List}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={4} sm={2} md={2} lg={1}>
                        <AiOutlineCloseCircle
                          onClick={this.onChange}
                          style={{
                            cursor: "pointer",
                            paddingTop: "10px",
                            float: "right",
                            marginBottom: "8px",
                            width: "30px",
                            height: "30px",
                            color: "#000000",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <div class="mt-2">
                      <TextEditorComments
                        editorStateValue={this.editorStateValues}
                        editorValue={this.state.editorAnswerValue}
                        friendList={this.props.friendsList}
                        needEdit={this.state.needEdit}
                        changeNeedHelpState={this.changeNeedHelpState}
                      />
                      {/* <TextEditor
                        ref={this.myRef}
                        editorStateValue={this.editorStateValues}
                        editorAnswerValues={this.state.editorAnswerValue}
                      /> */}

                      <p
                        className="boxText"
                        style={
                          this.state.answerCount > 4000
                            ? { color: "red" }
                            : { color: "green" }
                        }
                      >
                        {" "}
                        {4000 - this.state.answerCount} {t("characters left")}
                      </p>
                    </div>
                    <Grid item xs={12} sm={12} md={12}>
                      {this.state.imagePresent &&
                        this.state.mediaFiles &&
                        this.state.mediaFiles.map((data, index) => (
                          // <div key={this.state.originalFileName}>
                          <div>
                            <Chip
                              icon={<AttachmentIcon />}
                              className="fileChip"
                              label={data.originalFileName}
                              size="small"
                              onDelete={() =>
                                this.removeImageFile(data.id, index)
                              }
                              variant="outlined"
                            />
                            {data.fileType === "image" && (
                              <img
                                style={{
                                  margin: "8px",
                                  width: "80px",
                                  height: "80px",
                                }}
                                src={`${APPCONFIG.API_URL}auth/image/${data.fileName}`}
                              ></img>
                            )}
                          </div>
                        ))}
                      {this.state.chipImageData &&
                        this.state.chipImageData.map((file, index) => (
                          <div key={file.name}>
                            <Chip
                              icon={<AttachmentIcon />}
                              className="fileChip"
                              label={file.name}
                              size="small"
                              onDelete={() => this.removeFile(index)}
                              variant="outlined"
                            />
                            {this.state.fileTypeKey == "image" && (
                              <img
                                style={{
                                  margin: "8px",
                                  width: "80px",
                                  height: "80px",
                                }}
                                src={URL.createObjectURL(file)}
                              ></img>
                            )}
                          </div>
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} class="mt-3">
                      {this.state.fileTypeState && (
                        <div>
                          <AiOutlineCloseCircle
                            onClick={this.closeChoosedFileData}
                            style={{
                              cursor: "pointer",
                              paddingTop: "10px",
                              float: "right",
                              marginBottom: "8px",
                              width: "30px",
                              height: "30px",
                              color: "#000000",
                            }}
                          />
                          <AnswerFileOptions
                            chipImageData={this.chipImageData}
                            fileTypeKey={this.state.fileTypeKey}
                            fileOption={this.state.fileOption}
                            fileOptionEnable={this.fileOptionEnable}
                            fromQuestionImage={this.getQuestionMediaImage}
                            fromQuestionMedia={this.getQuestionMediaFiles}
                          ></AnswerFileOptions>
                        </div>
                      )}{" "}
                      <ul class="groupPost-nav groupPost-nav-pills groupPost-nav-stack groupPost-small groupPost-fw-normal">
                        {!this.state.fileTypeState && (
                          <li
                            class="groupPost-nav-item"
                            onClick={() => this.keyFile("image")}
                          >
                            <a
                              class="groupPost-nav-link bg-light"
                              href="#!"
                              data-bs-toggle="modal"
                              style={{ cursor: "pointer" }}
                              data-bs-target="#feedActionPhoto"
                            >
                              {" "}
                              <CameraAltIcon
                                style={{
                                  color: "#0cbc87",
                                }}
                              />{" "}
                              {t("Photo")}
                            </a>
                          </li>
                        )}
                        {!this.state.editAns && (
                          <li
                            class="groupPost-nav-item"
                            onClick={(e) => this.onFormSubmit(e)}
                          >
                            <a
                              class="groupPost-nav-link bg-forum-dark"
                              href="#!"
                              data-bs-toggle="modal"
                              style={{ cursor: "pointer" }}
                              data-bs-target="#feedActionPhoto"
                            >
                              {" "}
                              {t("Post Answer")}
                            </a>
                          </li>
                        )}
                        {this.state.editAns && (
                          <li
                            class="groupPost-nav-item"
                            onClick={(e) => this.onUpdateSubmit(e)}
                          >
                            <a
                              class="groupPost-nav-link bg-forum-dark"
                              href="#!"
                              data-bs-toggle="modal"
                              style={{ cursor: "pointer" }}
                              data-bs-target="#feedActionPhoto"
                            >
                              {" "}
                              {t("Update Reply")}
                            </a>
                          </li>
                        )}
                      </ul>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
        {this.state.deleteAnswerState && this.popUpDeleteAnswer()}
        {this.state.deleteReplyState && this.popUpDeleteReply()}
        {this.state.deleteQuestionState && this.popUpDeleteQuestion()}
        {this.state.reportPopup && (
          <ReportModel
            id={this.state.reportId}
            typeData={this.state.typeData}
            reportType={this.state.reportType}
            closePopup={this.reportAction}
            removePost={this.removePost}
          />
        )}
        {this.state.likeCountPopUpStatus && (
          <LikePopUp
            modelClose={this.likeCountPopUp}
            postId={this.state.popupId}
            postType={"forumCommunityAnswer"}
            availableType={null}
          />
        )}
      </>
    );
  }
  likeCountPopUp = async (id) => {
    await this.setState({
      popupId: id,
      likeCountPopUpStatus: !this.state.likeCountPopUpStatus,
    });
  };
  handleMenuClose = () => {
    this.setState({ menuState: false });
  };
  handleMenuClick = (event) => {
    this.setState({ menuState: event.target, menuStateWithSub: true });
  };
  handleHashTag = (event) => {
    const clickedText = event.target.textContent;
    const regex = /^#[^\s]+$/;

    if (regex.test(clickedText)) {
      const newStr = clickedText.replace(/#/g, "");
      this.props.history.push(
        `/${this.props.workspaceType}/${"hash"}/${newStr}`
      );
      return;
    }

    let htmlElement = event.target;
    const htmlString = htmlElement?.outerHTML;
    console.log("givenString", htmlString);

    if (validateSpanTag(htmlString)) {
      this.moveToUserProfile(event, htmlString);
    }
    // let htmlElement = event.target;
    // const htmlString = htmlElement?.outerHTML;
    // if (validateSpanTag(htmlString)) {
    //   this.moveToUserProfile(event, htmlString);
    // }
  };
  moveToUserProfile = (event, htmlString) => {
    const id = extractNumberFromHref(htmlString);
    event.preventDefault();
    event.stopPropagation();
    this.showFriendsProfile(id);
    // event.preventDefault();
    // event.stopPropagation();
    // const regex =
    //   /<span style="color:#D92267;cursor: pointer;">@(\w+)<\/span>/i;
    // const match = htmlString.match(regex);
    // const value = match ? match[1] : null;
    // this.props.history.push(`/${this.props.workspaceType}/${"user"}/${value}`);
    // return;
  };
  showFriendsProfile = (userId) => {
    this.props.getNavigationType(1);
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
    // }
  };
  onpenLightBox = (files) => {
    if (files.length > 0) {
      let fileList = [];

      for (let i = 0; i < files.length; i++) {
        fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
      }
      this.setState({ fileData: fileList, isOpen: true });
      // await this.setState({ fileImageData: fileList });
    }
  };
  removeImageFile = (id, index) => {
    var data = [];
    var mediaId = this.state.removeMediaId;
    data = this.state.mediaFiles.filter((item) => item.id !== id);
    mediaId.push(id);
    this.setState({
      mediaFiles: data,
      removeMediaId: mediaId,
    });
    if (data.length === 0) {
      this.setState({
        emptyFileState: true,
        fileTypeKey: "text",
      });
    }
    console.log(data);
  };
  removeFile = (index) => {
    var inpu = this.state.chipImageData;
    if (this.state.chipImageData[index]) {
      var val = this.state.chipImageData;
      val.splice(index, 1);
      this.setState({
        chipImageData: val,
      });
    }
  };
  deleteQuestion = (questionId) => {
    this.setState({
      deleteQuestionState: !this.state.deleteQuestionState,
      questionId: questionId,
    });
  };
  replyValues = (values) => {
    this.setState({
      replyContent: values,
    });
    console.log("values", values);
  };
  popUpDeleteQuestion = () => {
    const { t } = this.props;
    return (
      <div>
        <Modal show="true" onHide={this.deleteQuestion}>
          <Modal.Header>{t("Delete Question")}</Modal.Header>

          <Form
            id="profileForm"
            noValidate
            validated={this.state.validated}
            onSubmit={this.deleteQuestionById}
          >
            <Modal.Body>
              <Form.Group className="formInput">
                <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                  {t("Are you sure to delete the Question?")}
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.deleteQuestion}>
                {t("Close")}
              </Button>
              <div></div>
              <Button type="submit" variant="primary">
                {t("Delete Question")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  };
  deleteQuestionById = (e) => {
    e.preventDefault();
    e.stopPropagation();

    var deleteQuestionId = {
      questionId: this.state.questionId,
    };

    ForumService.deleteAskQuestion(deleteQuestionId)
      .then((response) => {
        toast.success(`${this.props.t("Question deleted successfully")}`);
        this.backToQuestions();
        //  var result = response.data
        //  let postList= this.state.forumQuestions;
        //  postList = this.state.forumQuestions.filter(
        //   (item) => item.questionId !== this.state.questionId
        // );
        // this.setState({
        //   forumQuestions: postList,
        // });
        // this.deleteQuestion()
      })
      .catch((error) => {
        this.deleteQuestion();
        console.log(error);
      });
  };
  subscribe = (boolean, id) => {
    this.setState({
      menuState: false,
    });
    let type = {
      typeData: "forumCommunity",
      postId: id,
      isSubscribe: boolean,
    };

    PostService.post_subscribe(type)
      .then((response) => {
        this.setState({
          isSubscribe: !this.state.isSubscribe,
        });
        toast.success(
          `${this.props.t(
            boolean
              ? "Subscribed successfully ,now you get this post activity notifications"
              : "Unsubscribed successfully"
          )}`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  editQuestion = (questionId) => {
    var groupId = this.props.match.params.subId;
    this.props.history.push(
      `/ask-question/${questionId}/update-question/${groupId}`
    );
  };
  deleteAns = (deleteAnswerId) => {
    this.setState({
      deleteAnswerState: !this.state.deleteAnswerState,
      deleteAnswerId: deleteAnswerId,
    });
  };
  handleForm = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  popUpDeleteAnswer = () => {
    const { t } = this.props;
    return (
      <div>
        <Modal show="true" onHide={this.deleteAns}>
          <Modal.Header>{t("Delete Answer")}</Modal.Header>

          <Form
            id="profileForm"
            noValidate
            validated={this.state.validated}
            onSubmit={this.deleteAnswerById}
          >
            <Modal.Body>
              <Form.Group className="formInput">
                <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                  {t("Are you sure to delete the Answer?")}
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                style={{ color: "white" }}
                onClick={this.deleteAns}
              >
                {t("Close")}
              </Button>
              <div></div>
              <Button
                type="submit"
                style={{ color: "white" }}
                variant="secondary"
              >
                {t("Delete Answer")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  };
  deleteAnswerById = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var answerId = {
      answerId: this.state.deleteAnswerId,
    };

    ForumService.deleteAnswerById(answerId)
      .then((response) => {
        let postList = this.state.formAnswer;
        postList = this.state.formAnswer.filter(
          (item) => item.answerId !== this.state.deleteAnswerId
        );
        this.setState({
          formAnswer: postList,
        });
        this.deleteAns();
        toast.success(`${this.props.t("Your answer deleted successfully")}`);
      })
      .catch((error) => {
        this.deleteAns();
        console.log(error);
      });
  };
  editAnswer = (answerId, answer, mediaFiles, answerType) => {
    this.setState({
      editorAnswerValue: answer,
      editAnswerId: answerId,
      editAns: true,
      answerOption: true,
      content: answer,
      fileTypeKey: answerType,
      needEdit: true,
    });
    if (mediaFiles !== undefined && mediaFiles.length !== 0) {
      this.setState({
        imagePresent: true,
        mediaFiles: mediaFiles,
      });
    } else {
      this.setState({
        imagePresent: false,
        mediaFiles: [],
        file: [],
      });
    }
  };
  editReply = (replyId, replyAnsId, ansReply) => {
    this.setState({
      answerId: replyAnsId,
      replyId: replyId,
      newReplayStatus: true,
      replyContent: ansReply,
      editorValue: removeHtmlTags(ansReply),
      replayListStatus: false,
      editReplyStatus: true,
      needEditReply: true,
    });
  };
  deleteReply = (replyId, replyAnsId) => {
    this.setState({
      deleteReplyState: !this.state.deleteReplyState,
      replyId: replyId,
      replyAnsId: replyAnsId,
    });
  };
  popUpDeleteReply = () => {
    const { t } = this.props;
    return (
      <div>
        <Modal show="true" onHide={this.deleteReply}>
          <Modal.Header>{t("Delete Reply")}</Modal.Header>

          <Form
            id="profileForm"
            noValidate
            validated={this.state.validated}
            onSubmit={this.deleteReplyById}
          >
            <Modal.Body>
              <Form.Group className="formInput">
                <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                  {t("Are you sure to delete the Reply?")}
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ color: "white" }}
                variant="secondary"
                onClick={this.deleteReply}
              >
                {t("Close")}
              </Button>
              <div></div>
              <Button
                style={{ color: "white" }}
                type="submit"
                variant="secondary"
              >
                {t("Delete Reply")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  };
  deleteReplyById = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    var deleteAnswerId = {
      replyId: this.state.replyId,
    };
    ForumService.deleteAnswerreplyById(deleteAnswerId)
      .then((response) => {
        this.deleteReplyAction();

        this.deleteReply();
      })
      .catch((error) => {
        this.deleteReply();
        console.log(error);
      });
  };

  updateReplayList = (result) => {
    let newComment = [];
    let reverseList = [];
    let oldList = this.state.replayFullList;
    let formAnswer = this.state.formAnswer;
    let replyUpdatedList = [];
    if (result) {
      newComment = oldList.concat(result);
      reverseList = newComment.reverse();
      formAnswer.forEach((element) => {
        if (element.answerId === this.state.answerId) {
          element.replyCount = element.replyCount + 1;
        }
      });
      reverseList.forEach((element) => {
        if (element.answerId === this.state.answerId) {
          replyUpdatedList.push(element);
        }
      });
      this.setState({
        replayFullList: replyUpdatedList,
        newReplayStatus: false,
        replayListStatus: true,
      });
    }
  };
  backToList = () => {
    this.setState({
      replayListStatus: false,
      newReplayStatus: false,
    });
  };
  viewReply = async (id, index) => {
    if (this.state.replayListIndex === index) {
      this.setState({
        answerId: id,
        replayListStatus: false,
        replayListIndex: -1,
        replayListIndexStatus: false,
      });
      console.log("fun_1", index);
    } else {
      await this.setState({
        replayFullList: [],
        replyPageNo: 0,
      });
      this.replyListCall(id, index);
    }
  };
  replyListCall = (id, index) => {
    var data = {
      page: this.state.replyPageNo,
      answerId: id,
    };
    ForumService.askQuestionAnswerReplyList(data)
      .then((response) => {
        if (response.data.data.totalPages - 1 > this.state.replyPageNo) {
          this.setState({
            replyHasMore: true,
          });
        } else {
          this.setState({
            replyHasMore: false,
          });
        }
        var result = response.data.data.questions;
        let oldList = this.state.replayFullList;
        let newComment = [];
        newComment = oldList.concat(result);
        this.setState({
          replyPageNo: this.state.replyPageNo + 1,
          replayFullList: newComment,
          answerId: id,
          replayListStatus: true,
          newReplayStatus: false,
          replayListIndex: index,
        });
        //  this.setState({FirstTwocomment: true})
      })
      .catch((error) => {
        console.log(error);
      });
  };
  changeNeedHelpState = () => {
    this.setState({
      needEdit: false,
      needEditReply: false,
    });
  };
  backToQuestions = () => {
    var id = this.props.match.params.subId;
    var subjectName = this.props.match.params.name;

    this.props.history.push(
      `/${this.props.workspaceType}/forum-community/${id}/${subjectName}`,
      { from: window.location.pathname }
    );
  };
  closeChoosedFileData = () => {
    this.setState({ fileTypeState: false, file: [] });
  };
  addLike = (id, userVote, index) => {
    if (
      !this.props.userJoin &&
      this.props.forumQuestion_userId !== this.props.loginDetails.uId
    ) {
      toast.warning(
        `${this.props.t(
          "Please join this community.Then you can get all the access .."
        )}`
      );
    } else {
      var data = {};
      if (userVote) {
        data = {
          answerId: id,
          forumType: "vote",
          voteType: "downVote",
        };
      } else {
        data = {
          answerId: id,
          forumType: "vote",
          voteType: "upVote",
        };
      }

      ForumService.answerUpAndDownVotes(data)
        .then((response) => {
          var result = response.data.data.userVote;
          let formAnswerList = this.state.formAnswer;

          if (result) {
            formAnswerList.forEach((element) => {
              if (element.answerId === id) {
                element.userVote = true;
                element.votes = element.votes + 1;
              }
            });
          } else {
            formAnswerList.forEach((element) => {
              if (element.answerId === id) {
                element.userVote = false;
                element.votes = element.votes - 1;
              }
            });
          }
          console.log("vo", formAnswerList);
          this.setState({
            formAnswer: formAnswerList,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  addReply = (id, index) => {
    if (
      !this.props.userJoin &&
      this.props.forumQuestion_userId !== this.props.loginDetails.uId
    ) {
      toast.warning(
        `${this.props.t(
          "Please join this community.Then you can get all the access"
        )}`
      );
    } else {
      if (this.state.replayIndex === index) {
        this.setState({
          answerId: id,
          newReplayStatus: false,
          replayIndex: -1,
          replayListStatus: false,
          editReplyStatus: false,
          replyContent: "",
        });
      } else {
        this.setState({
          replyContent: "",
          answerId: id,
          newReplayStatus: true,
          replayIndex: index,
          replayListStatus: false,
          editReplyStatus: false,
        });
      }
    }
    // this.setState({
    //   answerId: id,
    //   newReplayStatus: !this.state.newReplayStatus,
    //   replyIdex: index,
    // });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (!this.state.content) {
      toast.warn(`${this.props.t("Share something")}`);
    } else if (
      this.state.content.replace(/([&nbsp;<p></p>\"'])/g, "").trimStart() === ""
    ) {
      toast.warn(`${this.props.t("Please enter valid answer")}`);
    } else if (this.state.plainStringContent.length == 0) {
      toast.warn(`${this.props.t("Please enter valid answer")}`);
    } else if (this.state.plainStringContent.length >= 4000) {
      toast.warn(
        `${this.props.t("Entered answer should not be above 4000 characters")}`
      );
    } else {
      if (this.state.file.length !== 0 && this.state.file !== undefined) {
        for (let i = 0; i < this.state.file.length; i++) {
          formData.append("mediaFiles", this.state.file[i]);
        }
        formData.append("questionId", this.props.match.params.id);
        formData.append(
          "answer",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("type", this.state.fileTypeKey);
      } else {
        formData.append(
          "answer",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("questionId", this.props.match.params.id);
        formData.append("type", "text");
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      ForumService.saveAnswerV2(formData, config)
        .then((response) => {
          this.setState({
            fileState: false,
            removeFileImage: true,
          });
          var result = response.data.data;

          if (response.data.status == 1) {
            toast.success(`${this.props.t("Answer saved successfully")}`);
            var oldPostList = this.state.formAnswer;
            var newPosts = oldPostList.concat(result.answer);
            console.log(newPosts);
            this.setState({
              formAnswer: newPosts,
            });
            this.setState({
              answerOption: false,
              removeMediaId: [],
              fileTypeKey: "",
              imagePresent: false,
              answerCount: 0,
              content: "",
              file: [],
              fileTypeState: false,
              chipImageData: [],
              mediaFiles: [],
              fileOption: true,
            });
            // this.scrollSmoothHandler();
          } else if (response.data.status == -1) {
            toast.warn(
              `${this.props.t("This question is locked by a moderator")}`
            );
            this.setState({
              answerOption: false,
              fileOption: true,
              blockedUserState: true,
              answerFieldBlock: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          //toast.error("Error in saving answer");
        });
    }
  };
  onUpdateSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (this.state.content === undefined) {
      toast.warn(`${this.props.t("Please enter valid answer")}`);
    } else if (this.state.plainStringContent.length == 0) {
      toast.warn(`${this.props.t("Please enter valid answer")}`);
    } else if (this.state.plainStringContent.length >= 4000) {
      toast.warn(
        `${this.props.t("Entered answer should not be above 4000 characters")}`
      );
    } else if (this.state.content != "") {
      if (
        this.state.removeMediaId.length !== 0 &&
        this.state.file !== undefined &&
        this.state.file.length !== 0
      ) {
        //   formData.append("userName", this.props.loginDetails.dName);
        formData.append(
          "answer",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("answerId", this.state.editAnswerId);
        formData.append("questionId", this.state.questionId);
        formData.append("type", this.state.fileTypeKey);
        for (let i = 0; i < this.state.file.length; i++) {
          formData.append("mediaFiles", this.state.file[i]);
        }
      } else if (
        this.state.removeMediaId.length !== 0 &&
        this.state.file.length === 0
      ) {
        formData.append("removeMediaId", this.state.removeMediaId);
        formData.append("questionId", this.state.questionId);
        formData.append("answerId", this.state.editAnswerId);
        formData.append(
          "answer",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("type", this.state.fileTypeKey);
      } else if (
        this.state.removeMediaId.length === 0 &&
        this.state.file.length !== 0
      ) {
        for (let i = 0; i < this.state.file.length; i++) {
          formData.append("mediaFiles", this.state.file[i]);
        }
        formData.append("questionId", this.state.questionId);
        formData.append("answerId", this.state.editAnswerId);
        formData.append(
          "answer",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("type", this.state.fileTypeKey);
      } else if (
        /// filePresent
        // this.state.emptyFileState &&
        this.state.mediaFiles.length === 0 &&
        this.state.removeMediaId.length === 0 &&
        this.state.file.length === 0
      ) {
        formData.append("questionId", this.state.questionId);
        formData.append("answerId", this.state.editAnswerId);
        formData.append(
          "answer",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("type", "text");
      } else {
        formData.append("questionId", this.state.questionId);
        formData.append("answerId", this.state.editAnswerId);
        formData.append(
          "answer",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("type", this.state.fileTypeKey);
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      ForumService.updatedAnswerByIdV2(formData, config)
        .then((response) => {
          var result = response.data.data.answer;
          var ansData = this.state.formAnswer;
          var newData = [];
          ansData.forEach((element) => {
            if (element.answerId === result.answerId) {
              newData.push(result);
            } else {
              newData.push(element);
            }
          });

          toast.success(`${this.props.t("Answer updated successfully")}`);
          this.setState({
            formAnswer: newData,
            fileTypeKey: "",
            imagePresent: false,
            fileState: false,
            removeFileImage: true,
            fileOption: true,
            postButtonEnable: false,
            ansFileType: "",
            editorAnswerValue: "",
            content: "",
            fileTypeState: false,
            removeMediaId: [],
            chipImageData: [],
            mediaFiles: [],
            file: [],
            answerOption: false,
          });

          // this.myRef.current.onClear();
          // this.UpdateQuestionInAllpages(result.data.questions);

          // this.props.getDashboardList(0, this.props.loginDetails.uId);
          // this.scrollSmoothHandler();
        })
        .catch((error) => {
          console.log(error);
          // toast.error("Error in saving answer");
        });
    }
  };
  chipImageData = (data) => {
    this.setState({ chipImageData: data, fileTypeState: false, file: data });
  };
  keyFile = (key) => {
    this.setState({ fileTypeKey: key, fileTypeState: true });
  };
  getQuestionMediaImage = (files, type) => {
    console.log("img", files);
    this.setState({
      imageFile: files,
      type: type,
      selectOption: false,
      fileTypeState: false,
      editImage: files,
      fileImage: true,
      imagePresent: false,
      file: files,
    });
  };
  fileOptionEnable = () => {
    this.setState({
      fileState: false,
      answerFiles: null,
      file: [],
      removeFileImage: true,
    });
  };

  editorStateValues = (values) => {
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length == 1) {
      this.setState({
        content: values,
        plainStringContent: "",
        answerCount: 0,
      });
    } else {
      this.setState({
        content: values,
        plainStringContent: plainString,
        answerCount: plainString.length,
      });
    }
  };
  getQuestionMediaFiles = (files, type) => {
    this.setState({ file: files, type: type });
  };
  onFormSubmitReply = (e, id) => {
    e.preventDefault();
    const formData = new FormData();
    if (this.state.replyContent == undefined) {
      toast.warn(`${this.props.t("Please enter valid reply")}`);
    } else if (this.state.replyContent == "") {
      toast.warn(`${this.props.t("Please enter valid reply")}`);
    } else if (this.state.replyContent.trimStart() === "") {
      toast.warn(`${this.props.t("Please enter valid reply")}`);
    } else if (this.state.replyContent != "") {
      // formData.append("userName", this.props.loginDetails.dName);
      formData.append("answerId", id);
      formData.append("answer", removeMentionTags(this.state.replyContent));
      formData.append("type", "text");

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      ForumService.saveAnswerReply(formData, config)
        .then((response) => {
          var result = response.data.data.answerReply;
          console.log(result);
          if (response.data.status == 1) {
            toast.success(`${this.props.t("Reply sent successfully")}`);
            this.setState({
              replyContent: "",
            });
          } else if (response.data.status == -1) {
            toast.warn(
              `${this.props.t("This question is locked by a moderator")}`
            );
          }
          this.saveData(result);
        })
        .catch((error) => {
          console.log(error);

          // this.props.hideUpdate();
        });
    }
  };
  saveData = (result) => {
    let newComment = [];
    let reverseList = [];
    let oldList = this.state.replayFullList;
    let formAnswer = this.state.formAnswer;
    let replyUpdatedList = [];
    if (result) {
      newComment = oldList.concat(result);
      reverseList = newComment.reverse();
      formAnswer.forEach((element) => {
        if (element.answerId === this.state.answerId) {
          element.replyCount = element.replyCount + 1;
        }
      });
      reverseList.forEach((element) => {
        if (element.answerId === this.state.answerId) {
          replyUpdatedList.push(element);
        }
      });
      this.setState({
        replayFullList: replyUpdatedList,
        newReplayStatus: false,
        replayListStatus: true,
      });
    }
  };

  onFormUpdateReply = (e, id) => {
    e.preventDefault();
    const formData = new FormData();
    if (this.state.replyContent === undefined) {
      toast.warn(`${this.props.t("Please enter valid reply")}`);
    } else if (this.state.replyContent === "") {
      toast.warn(`${this.props.t("Please enter valid reply")}`);
    } else if (this.state.replyContent != "") {
      // formData.append("userName", this.props.loginDetails.dName);
      formData.append("answerId", id);
      formData.append("answer", removeMentionTags(this.state.replyContent));
      formData.append("type", "text");
      formData.append("replyId", this.state.replyId);
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    ForumService.updateAnswerReply(formData, config)
      .then((response) => {
        var result = response.data.data.answerReply;
        console.log(result);
        if (response.data.status == 1) {
          toast.success(`${this.props.t("Reply updated successfully")}`);
          var ansData = this.state.replayFullList;
          var newData = [];
          ansData.forEach((element) => {
            if (element.replyId === result.replyId) {
              newData.push(result);
            } else {
              newData.push(element);
            }
          });
          this.setState({
            replyContent: "",
            replayFullList: newData,
            newReplayStatus: false,
            editReplyStatus: false,
            replayListStatus: true,
            editorValue: "",
            needEdit: true,
          });
        } else if (response.data.status == -1) {
          toast.warn(
            `${this.props.t("This question is locked by a moderator")}`
          );
        }
      })
      .catch((error) => {
        console.log(error);

        // this.props.hideUpdate();
      });
  };
  reportAction = (type, replyId, answerId) => {
    if (type === "question") {
      this.setState({
        reportId: this.state.questionId,
        reportType: "Question",
        typeData: "forumCommunityQuestion",
      });
    } else if (type === "answer") {
      this.setState({
        reportId: replyId,
        reportType: "Answer",
        typeData: "forumCommunityAnswer",
        deleteAnswerId: replyId,
      });
    } else if (type === "reply") {
      this.setState({
        replyId: replyId,
        replyAnsId: answerId,
        reportId: replyId,
        reportType: "Reply",
        typeData: "forumCommunityAnswerReplay",
      });
    }
    this.setState({
      reportPopup: !this.state.reportPopup,
    });
  };

  removePost = (id, type) => {
    if (type === "Question") {
      this.backToQuestions();
    } else if (type === "Answer") {
      let postList = this.state.formAnswer;
      postList = this.state.formAnswer.filter((item) => item.answerId !== id);
      this.setState({
        formAnswer: postList,
      });
    } else if (type === "Reply") {
      this.deleteReplyAction();
    }
  };

  deleteReplyAction = () => {
    let postList = this.state.replayFullList;
    let formAnswer = this.state.formAnswer;
    postList = this.state.replayFullList.filter(
      (item) => item.replyId !== this.state.replyId
    );
    formAnswer.forEach((element) => {
      if (element.answerId === this.state.replyAnsId) {
        element.replyCount = element.replyCount - 1;
      }
    });
    this.setState({
      replayFullList: postList,
      formAnswer: formAnswer,
    });
  };
}

const mapDispacthToProps = (dispatch) => {
  return {
    askQuestionAnswerList: (tpage, groupId, id) =>
      dispatch(askQuestionAnswerList(tpage, groupId, id)),
    getPostTagList: (type, id) => dispatch(getPostTagList(type, id)),
    getNavigationType: (type) => dispatch(getNavigationType(type)),
  };
};
const mapStateToProps = function (state) {
  return {
    userJoin: state.topquestList.formAnswer_des.join,
    pageNo: state.topquestList.formAnswer_totalItems,
    formAnswer: state.topquestList.formAnswer,
    questionName_Des:
      state.topquestList.formAnswer_des.parentQuestionDescription,
    questionName_List: state.topquestList.formAnswer_des.parentQuestion,
    forumQuestion_tags: state.topquestList.formAnswer_des.tagsValue,
    forumQuestion_mediaFiles: state.topquestList.formAnswer_des.mediaFiles,
    QandAquestionList: state.topquestList.list,
    QandAquestTotalitems: state.topquestList.totalItems,
    tagList: state.commonApi.taglist.data,
    loginDetails: state.loginDetailsApi.loginDetails,
    forumQuestion_userId: state.topquestList.formAnswer_des.userId,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    friendsList: state.commonApi.tagUserList,
    postSubscribe: state.topquestList?.formAnswer_des?.isSubscribe,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ForumDescription)));
