//import i18n from '../i18n';
class AuthService {
  registerEmail(email) {
    sessionStorage.setItem("email", email);
  }

  registerAlertShow() {
    sessionStorage.setItem("alert", true);
  }

  registerAuth(username, loginValue) {
    /*
        sessionStorage.setItem('authUsers', "THIYAGARAAJ");
        sessionStorage.setItem('authType', "11");
        sessionStorage.setItem('authDisplayType', "ADMIN");
        sessionStorage.setItem('authID', 1);
        */

    sessionStorage.setItem("authUsers", loginValue.dName);
    sessionStorage.setItem("authType", loginValue.uType);

    //sessionStorage.setItem('authDisplayType', loginValue.textuserType);
    sessionStorage.setItem("authID", loginValue.uId);
    sessionStorage.setItem("profilePhoto", loginValue.uPhoto);
    sessionStorage.setItem("coverPhoto", loginValue.cPhoto);
    sessionStorage.setItem("loginValue", JSON.stringify(loginValue));
  }

  registerArea(areaValues) {
    sessionStorage.setItem("areaValues", JSON.stringify(areaValues));
  }
  storeCourseId(courseId) {
    sessionStorage.setItem("courseId", courseId);
  }
  storeTopicId(topicId) {
    sessionStorage.setItem("topicId", topicId);
  }
  storeGroupDetailsbyId(groupDatabyId) {
    sessionStorage.setItem("groupDatabyId", JSON.stringify(groupDatabyId));
  }
  storePeerGroupDetailsbyId(peergroupDatabyId) {
    sessionStorage.setItem(
      "peergroupDatabyId",
      JSON.stringify(peergroupDatabyId)
    );
  }
  storeFriendUserdata(friendUserData) {
    sessionStorage.setItem("friendValue", JSON.stringify(friendUserData));
  }
  storeRecommendedFriendUserdata(friendUserData) {
    sessionStorage.setItem(
      "recommendedfriendValue",
      JSON.stringify(friendUserData)
    );
  }
  storeScheduleFriendUserdata(friendUserData) {
    sessionStorage.setItem(
      "schedulefriendValue",
      JSON.stringify(friendUserData)
    );
  }
  storeFriendsList(friendsList) {
    sessionStorage.setItem("friendsList", JSON.stringify(friendsList));
  }
  storeRecommendedFriendsList(recommendedFriendsList) {
    sessionStorage.setItem(
      "recommendedFriendsList",
      JSON.stringify(recommendedFriendsList)
    );
  }

  storerecommendedFriendsReviews(recommendedFriendsReviews) {
    sessionStorage.setItem(
      "recommendedFriendsReviews",
      JSON.stringify(recommendedFriendsReviews)
    );
  }
  storeInvitedFriendsList(invitedFriendsList) {
    sessionStorage.setItem(
      "invitedFriendsList",
      JSON.stringify(invitedFriendsList)
    );
  }
  storeLanguageList(languageList) {
    sessionStorage.setItem("languageList", JSON.stringify(languageList));
  }
  storeHobbiesList(hobbiesList) {
    sessionStorage.setItem("hobbiesList", JSON.stringify(hobbiesList));
  }
  storeSubjectsList(subjectsList) {
    sessionStorage.setItem("subjectsList", JSON.stringify(subjectsList));
  }
  storeTagsList(tagsList) {
    sessionStorage.setItem("tagsList", JSON.stringify(tagsList));
  }
  storeCommonLanguages(selectedLanguage) {
    sessionStorage.setItem("selectedLanguage", selectedLanguage);

    //   console.log(Promise.resolve(sessionStorage.getItem('selectedLanguage')))
  }
  storeClassroomComplete() {
    sessionStorage.setItem("classroomComplete", true);
  }
  storeUserAvailabilities(storeUserAvailabilities) {
    sessionStorage.setItem(
      "storeUserAvailabilities",
      JSON.stringify(storeUserAvailabilities)
    );
  }
  storeGradeList(gradeList) {
    sessionStorage.setItem("gradeList", JSON.stringify(gradeList));
  }
  storeCurriculumTrackList(curriculumtrackList) {
    sessionStorage.setItem(
      "curriculumtrackList",
      JSON.stringify(curriculumtrackList)
    );
  }
  /* For MS Graph API
   */
  setMSEmail(email) {
    sessionStorage.setItem("MS_EMAIL", email);
  }

  setMSUsersData(data) {
    sessionStorage.setItem("MS_USERS_DATA", JSON.stringify(data));
  }

  setMSUserInfo(data) {
    sessionStorage.setItem("MS_USER_INFO", JSON.stringify(data));
  }

  setMSServiceToken(token) {
    sessionStorage.setItem("MS_SERVICE_TOKEN", token);
  }

  setMSGraphToken(token) {
    sessionStorage.setItem("MS_GRAPH_TOKEN", token);
  }

  isMSLogin() {
    //console.log("Logs : Started To Validate");
    let email = sessionStorage.getItem("MS_EMAIL");
    //console.log("Logs :"+user);

    if (email) return true;
    return false;
  }

  getMSEmail() {
    let email = sessionStorage.getItem("MS_EMAIL");
    return email;
  }

  getMSServiceToken() {
    let token = sessionStorage.getItem("MS_SERVICE_TOKEN");
    return token;
  }

  getMSGraphToken() {
    let token = sessionStorage.getItem("MS_GRAPH_TOKEN");
    return token;
  }

  getMSUsersData() {
    //let users_data = sessionStorage.getItem('MS_USERS_DATA');
    //return users_data;

    let udata = sessionStorage.getItem("MS_USERS_DATA");

    var obj = JSON.parse(udata || null);

    return obj;
  }

  getMSUserInfo() {
    //let users_data = sessionStorage.getItem('MS_USERS_DATA');
    //return users_data;

    let udata = sessionStorage.getItem("MS_USER_INFO");

    var obj = JSON.parse(udata || null);

    return obj;
  }

  /* For MS Graph API
   */
  isAuth() {
    //console.log("Logs : Started To Validate");
    let user = sessionStorage.getItem("authUsers");

    if (user) return true;
    return false;
  }

  isAlert() {
    let alert = sessionStorage.getItem("alert");

    if (alert) return true;
    return false;
  }

  getCourseId() {
    let courseId = sessionStorage.getItem("courseId");
    return courseId;
  }
  getTopicId() {
    let topicId = sessionStorage.getItem("topicId");
    return topicId;
  }
  getUsername() {
    let user = sessionStorage.getItem("authUsers");
    return user;
  }

  getUserId() {
    let id = sessionStorage.getItem("authID");
    return id;
  }

  getUserType() {
    let type = sessionStorage.getItem("authType");
    return type;
  }

  getUserDisplayType() {
    let type = sessionStorage.getItem("authDisplayType");
    return type;
  }

  getProfilePhoto() {
    let profilePhoto = sessionStorage.getItem("profilePhoto");
    return profilePhoto;
  }
  getcoverPhoto() {
    let coverPhoto = sessionStorage.getItem("coverPhoto");
    return coverPhoto;
  }
  getEmail() {
    let type = sessionStorage.getItem("email");

    return type;
  }

  getUserdata() {
    let user = sessionStorage.getItem("loginValue");
    var obj = JSON.parse(user);
    return obj;
  }
  getGroupDetailsbyId() {
    let user = sessionStorage.getItem("groupDatabyId");
    var obj = JSON.parse(user);

    return obj;
  }
  getPeerGroupDetailsbyId() {
    let user = sessionStorage.getItem("peergroupDatabyId");
    var obj = JSON.parse(user);

    return obj;
  }
  getFriendUserdata() {
    let user = sessionStorage.getItem("friendValue");
    var obj = JSON.parse(user);

    return obj;
  }

  getRecommendedFriendUserdata() {
    let user = sessionStorage.getItem("recommendedfriendValue");
    var obj = JSON.parse(user);

    return obj;
  }
  getScheduleFriendUserdata() {
    let user = sessionStorage.getItem("schedulefriendValue");
    var obj = JSON.parse(user);

    return obj;
  }

  getAreadata() {
    let areaValues = sessionStorage.getItem("areaValues");
    var obj = JSON.parse(areaValues);

    return obj;
  }

  getFriendsList() {
    let friendsList = sessionStorage.getItem("friendsList");

    var obj = JSON.parse(friendsList || null);

    return obj;
  }

  getInvitedFriendsList() {
    let invitedFriendsList = sessionStorage.getItem("invitedFriendsList");

    var obj = JSON.parse(invitedFriendsList || null);

    return obj;
  }
  getRecommendedFriendsList() {
    let recommendedFriendsList = sessionStorage.getItem(
      "recommendedFriendsList"
    );

    var obj = JSON.parse(recommendedFriendsList);

    return obj;
  }
  getrecommendedFriendsReviews() {
    let recommendedFriendsReviews = sessionStorage.getItem(
      "recommendedFriendsReviews"
    );

    var obj = JSON.parse(recommendedFriendsReviews);

    return obj;
  }
  getLanguageList() {
    let languageList = sessionStorage.getItem("languageList");
    var obj = JSON.parse(languageList);

    return obj;
  }

  getHobbiesList() {
    let hobbiesList = sessionStorage.getItem("hobbiesList");
    var obj = JSON.parse(hobbiesList);

    return obj;
  }

  getSubjectsList() {
    let subjectsList = sessionStorage.getItem("subjectsList");
    var obj = JSON.parse(subjectsList);

    return obj;
  }

  getTagsList() {
    let tagsList = sessionStorage.getItem("tagsList");
    var obj = JSON.parse(tagsList);

    return obj;
  }

  getCommonLanguages() {
    let selectedLanguage = sessionStorage.getItem("selectedLanguage");

    return selectedLanguage;
  }
  getClassroomComplete() {
    let classroomComplete = sessionStorage.getItem("classroomComplete");

    return classroomComplete;
  }
  getGradeList() {
    let gradeList = sessionStorage.getItem("gradeList");
    var obj = JSON.parse(gradeList);

    return obj;
  }
  getCurriculumTrackList() {
    let curriculumtrackList = sessionStorage.getItem("curriculumtrackList");
    var obj = JSON.parse(curriculumtrackList);

    return obj;
  }

  getUserAvailabilities() {
    let storeUserAvailabilities = sessionStorage.getItem(
      "storeUserAvailabilities"
    );
    var obj = JSON.parse(storeUserAvailabilities);

    return obj;
  }

  registerToken(token) {
    sessionStorage.setItem("TOKEN", token);
  }

  getToken() {
    let token = "Bearer " + sessionStorage.getItem("TOKEN");
    return token;
  }

  getMsKey() {
    let mykey = sessionStorage.getItem("MSKEY");
    return mykey;
  }
  getMsEmail() {
    let msemail = sessionStorage.getItem("MSEMAIL");
    return msemail;
  }
  getMsProfile() {
    let myprofile = sessionStorage.getItem("MSPROFILE");
    return myprofile;
  }

  removeAuth() {
    sessionStorage.removeItem("authUsers");
    sessionStorage.removeItem("authType");
    sessionStorage.removeItem("authID");
    sessionStorage.removeItem("loginValue");
    sessionStorage.removeItem("CACHE");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("profilePhoto");
    sessionStorage.removeItem("coverPhoto");
    sessionStorage.removeItem("friendsList");
    sessionStorage.removeItem("languageList");
    sessionStorage.removeItem("hobbiesList");
    sessionStorage.removeItem("subjectsList");
    sessionStorage.removeItem("tagsList");
    sessionStorage.removeItem("selectedLanguage");
    sessionStorage.removeItem("storeUserAvailabilities");
    sessionStorage.removeItem("recommendedFriendsList");
    sessionStorage.removeItem("recommendedFriendsReviews");
    sessionStorage.removeItem("invitedFriendsList");
    sessionStorage.removeItem("friendValue");
    sessionStorage.removeItem("recommendedfriendValue");
    sessionStorage.removeItem("schedulefriendValue");
    sessionStorage.removeItem("groupDatabyId");
    sessionStorage.removeItem("peergroupDatabyId");
    sessionStorage.removeItem("TOKEN");
    sessionStorage.removeItem("areaValues");
    sessionStorage.removeItem("courseId");
    sessionStorage.removeItem("topicId");
    sessionStorage.removeItem("MSKEY");
    sessionStorage.removeItem("MSEMAIL");
    sessionStorage.removeItem("MSPROFILE");
    sessionStorage.removeItem("MSKEY");
    sessionStorage.removeItem("MSEMAIL");
    sessionStorage.removeItem("MSPROFILE");
  }
}

export default new AuthService();
