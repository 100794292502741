import { DELETE_GROUP_POST, GET_SUBJECT_QUESTION_LIST } from "../types";
import { UPDATE_SUBJECT_QUESTION_LIST } from "../types";
import { DELETE_SUBJECT_QUESTION_LIST } from "../types";
import { SUBJECT_QUESTION_LOADER } from "../types";
import { GET_LOGOUT_DATA } from "../types";
import { GET_POSTS_OVERALL, DELETE_POST } from "../types";
const initialState = {
  list: [],
  uplist: [],
  updatedKeyname: "",
  SubQuestionloading: true,
  overallList: [],
  postLists: [],
  totalItemsList: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECT_QUESTION_LIST:
      console.log("GET_SUBJECT_QUESTION_LIST");
      console.log(action.payload.topQuestList);
      return {
        ...state,
        list: action.payload.topQuestList,
        totalItems: action.payload.totalItems,
        updatedKeyname: action.payload.updatedKey,
        SubQuestionloading: false,
      };
    // if (
    //   action.payload.updatedKey == "normal" ||
    //   action.payload.updatedKey == "updated"
    // ) {
    //   if (
    //     state.updatedKeyname == "search" ||
    //     action.payload.updatedKey == "updated"
    //   ) {
    //     // return {
    //     //   ...state,
    //     //   list: action.payload.topQuestList,
    //     //   totalItems: action.payload.totalItems,
    //     //   updatedKeyname: action.payload.updatedKey,
    //     //   SubQuestionloading: false,
    //     // };
    //   } else {

    //   }
    // } else if (action.payload.updatedKey == "search") {
    //   // if(state.updatedKeyname =="normal" || state.updatedKeyname =="updated"){
    //   // return {
    //   //   ...state,
    //   //   list: action.payload.topQuestList,
    //   //   totalItems: action.payload.totalItems,
    //   //   updatedKeyname: action.payload.updatedKey,
    //   //   SubQuestionloading: false,
    //   // };
    // }

    case UPDATE_SUBJECT_QUESTION_LIST:
      console.log("UPDATE_SUBJECT_QUESTION_LIST");
      console.log(action.payload.topQuestList);
      return {
        ...state,
        list: action.payload.topQuestList,
        totalItems: action.payload.totalItems,
        updatedKeyname: action.payload.updatedKey,
        SubQuestionloading: false,
      };
    case GET_POSTS_OVERALL:
      console.log("GET_POSTS_OVERALL ", action.payload.totalItems);
      console.log(action.payload);
      return {
        ...state,
        overallList: action.payload,
        postLists: action.payload.userpost,
        totalItemsList: action.payload.totalItems,
        SubQuestionloading: false,
      };

    case DELETE_GROUP_POST:
      console.log("DELETE_GROUP_POST", action.payload);
      let filterList = state.postLists.filter(
        (item) => item.moderationId !== action.payload
      );
      return {
        ...state,
        postLists: filterList ? filterList : state.postLists,
      };

    case DELETE_SUBJECT_QUESTION_LIST:
      console.log("DELETE_SUBJECT_QUESTION_LIST");
      console.log(action.payload);
      return {
        ...state,
        list: action.payload,
        totalItems: state.totalItems - 1,
      };
    case SUBJECT_QUESTION_LOADER:
      console.log("SUBJECT_QUESTION_LOADER");
      console.log(action.payload);
      return {
        ...state,
        SubQuestionloading: action.payload,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");

      return {
        ...initialState,
      };
    default:
      return state;
  }
}
