import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { needPhotVerification } from "../redux/actions/commonApiActions";
import { withTranslation } from "react-i18next";
import { Button, Form, Modal, Card } from "react-bootstrap";
import { AiOutlineCloseCircle, AiOutlineWarning } from "react-icons/ai";
import { MdOutlineAddAPhoto } from "react-icons/md";
import KYCSetupContainer from "../screens/FirstTimeProfile/KYCSetupContainer";
import Image from "../img/home/photo-verification.png";

const PhotoSetUpModel = (props) => {
  const { t } = props;
  const [photoPage, setPhotoPage] = useState(false);
  const [modelOpen, setModelOpen] = useState(true);

  useEffect(() => {
    return () => {
      setModelOpen(false);
    };
  }, []);
  const onHidePopup = () => {
    setModelOpen(false);

    props.needPhotVerification(false);
  };
  return (
    <>
      {!photoPage ? (
        <Modal
          show={modelOpen}
          onHide={onHidePopup}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div>
            <AiOutlineCloseCircle
              onClick={onHidePopup}
              className="mr-2 mt-2"
              style={{
                cursor: "pointer",
                float: "right",
                marginBottom: "8px",
                width: "20px",
                height: "20px",
                color: "#000000",
              }}
            />
          </div>
          <section className="w-auto  mt-4" style={{ height: "17rem" }}>
            <img
              alt="img"
              src={Image}
              className="w-100 h-100  "
              style={{ objectFit: "cover" }}
            />
          </section>
          <Modal.Body
            style={{ textAlign: "center" }}
            className="d-flex align-items-center flex-column text-center"
          >
            {/* <h4 style={{ fontSize: "xx-large", color: "hotpink" }}>OOOPS !</h4> */}
            <h5>Please Complete Your Profile Verification</h5>
            <p className="text-secondary mb-2">
              {" "}
              As it is a women only platform to ensure that you are an women
              user please verify your profile. This verification is only for
              security purpose and your picture will remain confidential.
            </p>
            <span className="green-btn mb-2" onClick={() => setPhotoPage(true)}>
              {" "}
              {t("Complete Profile SetUp")}
            </span>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal show={modelOpen} onHide={onHidePopup}>
          <div>
            <AiOutlineCloseCircle
              onClick={onHidePopup}
              className="mr-2 mt-2"
              style={{
                cursor: "pointer",
                float: "right",
                marginBottom: "8px",
                width: "20px",
                height: "20px",
                color: "#000000",
              }}
            />
          </div>
          <Modal.Header className="align-items-center">
            <AiOutlineWarning className="svg-icons text-primary" />{" "}
            <div className="kyc-background">
              <p>
                {t(
                  "The pictures you upload on the Yuukke app are strictly for authentication and verification purposes, ensuring privacy and security."
                )}
              </p>
            </div>
          </Modal.Header>
          <Card className="profileCard">
            <div className="d-flex align-items-center justify-content-center">
              <KYCSetupContainer back={onHidePopup} />
            </div>
          </Card>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={onHidePopup}>
              {t("Close")}
            </Button>
          </Modal.Footer> */}
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceType: state.loginDetailsApi.loginDetails?.userWorkSpace?.slug,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    needPhotVerification: (status) => dispatch(needPhotVerification(status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PhotoSetUpModel));
