import React, { Component } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState } from "draft-js";

const MAX_LENGTH = 2499;
export default class TextEditorQuestion extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    currentValues: null,
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.editorStateValue(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.editorValue !== prevProps.editorValue) {
      console.log(this.props.editorValue);
      const blocksFromHtml = htmlToDraft(this.props.editorValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      console.log(contentState);
      console.log(EditorState.createWithContent(contentState));
      this.setState({
        editorState: EditorState.createWithContent(contentState),
      });
    }
    if (this.props.currentValues !== prevProps.currentValues) {
      this.setState({
        editorState: "",
      });
    }
  }

  handleChange = (event) => {
    const reset = { ...event.blocks[0], text: null };
    this.setState({
      currentValues: event,
    });
  };
  onClear = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
    });
  };
  render() {
    const { editorState, currentValues } = this.state;
    return (
      <div className="text-outer-box">
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          onChange={this.handleChange}
          handleBeforeInput={this._handleBeforeInput}
          handlePastedText={this._handlePastedText}
          toolbar={{
            textAlign: { inDropdown: true, className: "hide" },
            // remove: { className: "hide" },
            // history: { className: "hide" },
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline", "strikethrough"],
            },
            list: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["unordered", "ordered"],
            },
            // blockType: {
            //   inDropdown: false,
            //   options: ["Normal", "Blockquote", "Code"],
            //   className: undefined,
            //   component: undefined,
            //   dropdownClassName: undefined,
            // },
            image: {
              className: "hide",
            },
            link: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              dropdownClassName: undefined,
              showOpenOptionOnHover: true,
              defaultTargetOption: "_self",
              options: ["link", "unlink"],
            },
            blockType: {
              className: "hide",
            },
            colorPicker: {
              className: "hide",
            },
            fontSize: {
              className: "hide",
            },
            fontFamily: {
              className: "hide",
            },
            history: {
              className: "hide",
            },
            remove: {
              className: "hide",
            },
            embedded: {
              className: "hide",
            },
          }}
        />
      </div>
    );
  }
  _getLengthOfSelectedText = () => {
    const currentSelection = this.state.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      console.log(currentSelection);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  _handleBeforeInput = () => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      console.log("you can type max ten characters");

      return "handled";
    }
  };

  _handlePastedText = (pastedText) => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (
      currentContentLength + pastedText.length - selectedTextLength >
      MAX_LENGTH
    ) {
      console.log("you can type max ten characters");

      return "handled";
    }
  };
}
