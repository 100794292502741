import React, { Component } from "react";
import { Grid, Row, Col } from "@material-ui/core";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import AuthService from "../../../session/AuthService";
import MyCourseService from "../../../api/MyCourseService";
import { ToastContainer, toast } from "react-toastify";
import happygrey from "../../../images/anouncement/happy@2x copy.png";
import happy from "../../../images/anouncement/happy@2x.png";
import cryinggrey from "../../../images/anouncement/crying@2x copy.png";
import crying from "../../../images/anouncement/crying@2x.png";
import confusedgrey from "../../../images/anouncement/confused@2x copy.png";
import confused from "../../../images/anouncement/confused@2x.png";
import famousgrey from "../../../images/anouncement/famous@2x copy.png";
import famous from "../../../images/anouncement/famous@2x.png";
import sadgrey from "../../../images/anouncement/sad@2x copy.png";
import sad from "../../../images/anouncement/sad@2x.png";

class AnnouncementBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articleId: this.props.articleId,
      feedBack: "",
    };
    this.setFeedBack = this.setFeedBack.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.setState({ articleId: this.props.articleId });
  }

  submitFeedBack(articleId) {
    if (this.state.feedBack != "") {
      let saveCourseFeedBack = {
        articleId: articleId,
        feedBack: this.state.feedBack,
      };

      MyCourseService.saveCourseFeedBack(saveCourseFeedBack)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (this.state.feedBack === "") {
      toast.warn("Please choose your FeedBack");
    }
  }

  setFeedBack(FeedBack) {
    this.setState({ feedBack: FeedBack }, () => {
      this.submitFeedBack(this.props.articleId);
    });
  }

  render() {
  const { t } = this.props;
    return (
      <div>
        <Grid container justify="center">
          <div className="feedbackBox">
            <Grid container spacing={4} justify="center">
              <Grid item xs={12} sm={3} md={3}></Grid>
              <Grid item xs={12} sm={6} md={6} justify="center">
                <center>
                  <p className="feedbackBoxIcon">
                    <ThumbUpIcon fontSize="large"></ThumbUpIcon>
                  </p>
                  <p className="feedbackBoxTitle">
                    {"Did you like the course?"}
                  </p>
                </center>
              </Grid>
              <Grid item xs={12} sm={3} md={3}></Grid>
            </Grid>

            <Grid
              className="feedbackIcons"
              container
              spacing={4}
              justify="center"
            >
              <Grid item xs={12} sm={2} md={2}>
                <center>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setFeedBack("Not At All")}
                  >
                    {(this.state.feedBack == "" ||
                      this.state.feedBack !== "Not At All") && (
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={cryinggrey}
                        alt="crying"
                      />
                    )}
                    {this.state.feedBack == "Not At All" && (
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={crying}
                        alt="crying"
                      />
                    )}
                    <p className="feedbackBoxText">{t("Not at all")}</p>
                  </div>
                </center>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <center>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setFeedBack("Slightly")}
                  >
                    {(this.state.feedBack == "" ||
                      this.state.feedBack !== "Slightly") && (
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={sadgrey}
                        alt="sad"
                      />
                    )}
                    {this.state.feedBack == "Slightly" && (
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={sad}
                        alt="sad"
                      />
                    )}
                    <p className="feedbackBoxText">{t("Slightly")}</p>
                  </div>
                </center>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <center>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setFeedBack("Kinda")}
                  >
                    {(this.state.feedBack == "" ||
                      this.state.feedBack !== "Kinda") && (
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={confusedgrey}
                        alt="confused"
                      />
                    )}
                    {this.state.feedBack == "Kinda" && (
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={confused}
                        alt="confused"
                      />
                    )}
                    <p className="feedbackBoxText">{t("Kinda")}</p>
                  </div>
                </center>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <center>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setFeedBack("Very Much")}
                  >
                    {(this.state.feedBack == "" ||
                      this.state.feedBack !== "Very Much") && (
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={happygrey}
                        alt="happy"
                      />
                    )}
                    {this.state.feedBack == "Very Much" && (
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={happy}
                        alt="happy"
                      />
                    )}
                    <p className="feedbackBoxText">{t("Very Much")}</p>
                  </div>
                </center>
              </Grid>

              <Grid item xs={12} sm={2} md={2}>
                <center>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setFeedBack("Completely")}
                  >
                    {(this.state.feedBack == "" ||
                      this.state.feedBack !== "Completely") && (
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={famousgrey}
                        alt="famous"
                      />
                    )}
                    {this.state.feedBack == "Completely" && (
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={famous}
                        alt="famous"
                      />
                    )}
                    <p className="feedbackBoxText">{t("Completely")}</p>
                  </div>
                </center>
              </Grid>
            </Grid>
            <Grid container spacing={4} justify="center">
              <Grid item xs={12} sm={4} md={4}></Grid>
            </Grid>
          </div>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(AnnouncementBox);
