import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { BsCalendarDate } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineGlobal } from "react-icons/ai";
import moment from "moment";
import { Grid } from "@material-ui/core";

class FriendProfileInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div class="about-card" style={{ marginTop: "1.5rem" }}>
          <div class="about-card-header border-0 pb-0">
            <h5 class="about-card-title">{t("Profile Info")}</h5>
          </div>
          <div class="card-body">
            <div class="rounded border px-3 py-2 mb-3">
              <div class="d-flex align-items-center justify-content-between">
                <h6 className="profileInfoOverview">{t("Overview")}</h6>
                <div class="dropdown ms-auto">
                  <a
                    class="nav-link text-secondary mb-0"
                    href="#"
                    id="aboutAction"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-three-dots"></i>
                  </a>
                  <ul
                    class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="aboutAction"
                  >
                    <li>
                      <a class="dropdown-item" href="#">
                        {" "}
                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{t("Edit")}
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        {" "}
                        <i class="bi bi-trash fa-fw pe-2"></i>{t("Delete")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <p>{this.props.bio} </p>
            </div>
            <div class="row g-4">
              {this.props.dob !== "" && (
                <div class="col-sm-6">
                  <div class="d-flex align-items-center rounded border px-3 py-2">
                    <p class="mb-0 about-body-p">
                      <BsCalendarDate
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "0.25rem",
                        }}
                      ></BsCalendarDate>{" "}
                      {t("Born")}:{" "}
                      <strong>
                        {moment(this.props.dob).format("MMM DD,yyyy")}{" "}
                      </strong>
                    </p>
                  </div>
                </div>
              )}

              {this.props.email !== "" && (
                <div class="col-sm-6">
                  <div class="d-flex align-items-center rounded border px-3 py-2">
                    <p class="mb-0 about-body-p">
                      <AiOutlineMail
                        style={{ width: "15px", height: "15px", color: "gray" }}
                      ></AiOutlineMail>{" "}
                      {t("Email")}: <strong> {this.props.email} </strong>
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div class="row g-4" style={{ marginTop: "1.5rem" }}>
              <div class="col-sm-6">
                <div class="d-flex align-items-center rounded border px-3 py-2">
                  <p class="mb-0 about-body-p">
                    <GoLocation
                      style={{ width: "15px", height: "15px", color: "gray" }}
                    ></GoLocation>{" "}
                    {t("Location")}: <strong> {this.props.country} </strong>
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="d-flex align-items-center rounded border px-3 py-2">
                  <p class="mb-0 about-body-p">
                    <AiOutlineGlobal
                      style={{ width: "15px", height: "15px", color: "gray" }}
                    />{" "}
                    {t("Country")}: <strong> {this.props.country_value} </strong>
                  </p>
                </div>
              </div>
            </div>

            {/* <div class="row g-4"  style={{marginTop:"1.5rem"}}>
                            <div class="col-sm-6">
                                <div class="d-flex align-items-center rounded border px-3 py-2">
                                    <p class="mb-0">
                                        <i class="bi bi-calendar-date fa-fw me-2"></i> Born: <strong> October 20, 1990 </strong>
                                    </p>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="d-flex align-items-center rounded border px-3 py-2">
                                    <p class="mb-0">
                                        <i class="bi bi-heart fa-fw me-2"></i> Status: <strong> Single </strong>
                                    </p>
                                </div>
                            </div>
                         
                        </div> */}
          </div>
        </div>

        <div class="about-card" style={{ marginTop: "1.5rem" }}>
          <div class="about-card-header d-sm-flex justify-content-between border-0 pb-0">
            <h5 class="about-card-title">{t("Interests")}</h5>
            {/* <a class="btn btn-primary-soft btn-sm" href="#!"> See all</a> */}
          </div>

          <div class="card-body">
            <div class="d-flex flex-wrap g-4" style={{ gap: "1rem" }}>
              {this.props.areaOfInterest &&
                this.props.areaOfInterest.map((area, index) => (
                  <div className="mentor-slot-chip  ">
                    {/* <div class="d-flex align-items-center position-relative"> */}
                    {/* <div class="avatar">
                        <img
                          class="avatar-img"
                          src="assets/images/logo/04.svg"
                          alt=""
                        />
                      </div> */}
                    {/* <div class="ms-2">
                        <h6 class="mb-0"> */}{" "}
                    <a class="stretched-link"> {area.tagName} </a>
                    {/* </h6> */}
                    {/* <p class="small mb-0">7,546,224 followers</p> */}
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {};
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(FriendProfileInfo)));
