import React, { Component } from "react";
import TextEditorQuestion from "../../../components/TextEditorQuestion";
import { ToastContainer, toast } from "react-toastify";

import BlockUi from "react-block-ui";
import { withTranslation } from "react-i18next";
import "react-widgets/styles.css";
import Grid from "@material-ui/core/Grid";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { connect } from "react-redux";
import MessagesFileOption from "../../Community/Subjects/GroupEventMessageFIleOption";
import { withRouter } from "react-router";
import EditableDate from "../../../components/EditableDate";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import PostService from "../../../api/PostService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Chip } from "@material-ui/core";
import { AttachmentIcon } from "@fluentui/react-northstar";
import * as APPCONFIG from "../../../config/app-config.js";

class AskQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      announcementTitle: "",
      description: "",
      startDate: 0,
      endDate: 0,
      file: null,
      editStatus: false,
      editorValue: "",
      image: null,
    };
  }
  componentDidMount() {
    console.log("props", this.props.match.params.id);
    console.log("props", this.props.userRole);

    if (
      this.props.match.params.id !== "new" &&
      (this.props.userRole === 1 || this.props.userRole === 2)
    ) {
      this.getAdvertisementDetail(this.props.match.params.id);
      this.setState({
        editStatus: true,
      });
    }
  }

  getAdvertisementDetail = (id) => {
    var langs = {
      id: id,
    };
    PostService.getAnnouncementonlyOpenById(langs)
      .then((response) => {
        var result = response.data.data.announcement;
        this.setState({
          id: result.id,
          announcementTitle: result.title,
          // startDate: result.startDate,
          // endDate: result.endDate,
          image: result.file,
          editorValue: result.description,
          description: result.description,
          endDate: moment(result.endDate).format("DD/MM/yyyy"),

          startDate: moment(result.startDate).format("DD/MM/yyyy"),
        });
        console.log(moment(result.startDate).format("DD/MM/yyyy"));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleForm = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleStartDate = (value) => {
    this.setState({
      startDate: value.toString(),
    });
  };
  handleEndDate = (value) => {
    this.setState({
      endDate: value.toString(),
    });
  };
  render() {
    const { t } = this.props;

    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        {/* <div className="backEventorGroup flexDefault">
           
          <button onClick={this.onBack}>{t("Back")} </button>
        </div> */}

        <div className="askAQuestionCardWrapper">
          <BlockUi
            tag="div"
            blocking={this.state.blocking}
            message={t("Loading, please wait")}
            color="#A00030"
          >
            <p
              onClick={this.onBack}
              className="bg-color float-right"
              style={{ cursor: "pointer" }}
            >
              {" "}
              <ArrowBackIcon className="mr-2" />
              Back
            </p>
            <div
              className="askAQuestionInputGroup inputLarge"
              style={{ padding: "0px 8px" }}
            >
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <label className="formLabel">Announcement Title *</label>

                <input
                  type="text"
                  name="announcementTitle"
                  value={this.state.announcementTitle}
                  maxLength="250"
                  onChange={this.handleForm}
                />
              </Grid>
            </div>
            <Grid
              container
              spacing={2}
              style={{ padding: "0px 8px", marginBottom: "4px" }}
            >
              <Grid item md={6} sm={6} xs={6} lg={6}>
                <label className="formLabel">Start Date *</label>
                <DatePicker
                  minDate={new Date()}
                  value={this.state.startDate}
                  onChange={this.handleStartDate}
                  format="DD/MM/YYYY"
                  style={{
                    height: "26px",
                    width: "100%",
                    fontSize: "14px",
                    padding: "18px 12px",
                  }}
                  editable={false}
                  // inputClass="custom-input"
                />
              </Grid>
              <Grid item md={6} sm={6} xs={6} lg={6}>
                <label className="formLabel">End Date *</label>
                <DatePicker
                  minDate={new Date()}
                  value={this.state.endDate}
                  onChange={this.handleEndDate}
                  format="DD/MM/YYYY"
                  style={{
                    height: "26px",
                    width: "100%",
                    fontSize: "14px",
                    padding: "18px 12px",
                  }}
                  editable={false}
                />
              </Grid>
            </Grid>
            <div
              className="askAQuestionDescription"
              style={{ padding: "0px 8px" }}
            >
              <label className="formLabel">{t("Description")}*</label>
              <div
                style={{
                  border: "2px solid #80808059",
                  borderRadius: "7px",
                }}
              >
                <TextEditorQuestion
                  ref={this.myRef}
                  editorStateValue={this.editorStateValues}
                  editorValue={this.state.editorValue}
                />
              </div>
            </div>

            <div>
              <MessagesFileOption
                fileTypeKey={"image"}
                fileOption={this.state.fileOption}
                fileOptionEnable={this.fileOptionEnable}
                fromQuestionImage={this.getQuestionMediaImage}
                fromQuestionMedia={this.getQuestionMediaFiles}
              ></MessagesFileOption>
            </div>
            {this.state.editStatus && this.state.image && (
              <div key={this.state.image}>
                <Chip
                  icon={<AttachmentIcon />}
                  className="fileChip"
                  label={"announcementImage"}
                  size="small"
                  onDelete={() => this.removeImageFile()}
                  variant="outlined"
                />
                {
                  <img
                    alt="image"
                    style={{ margin: "8px", width: "80px", height: "80px" }}
                    src={`${APPCONFIG.API_URL}auth/image/${this.state.image}`}
                  ></img>
                }
              </div>
            )}
          </BlockUi>

          <div className="askAQuestionBtn">
            <button onClick={(e) => this.onFormSubmit(e)}>
              {this.state.editStatus
                ? "Update Announcement"
                : "Create Announcement"}
            </button>
          </div>
        </div>
      </div>
    );
  }
  removeImageFile = () => {
    this.setState({
      image: null,
    });
  };
  editorStateValues = (values) => {
    console.log("editorStateValues");
    console.log(values);

    this.setState({
      description: values,
    });
  };
  onFormSubmit = () => {
    console.log(this.state.file);
    if (
      !this.state.announcementTitle ||
      !this.state.description.trimStart() ||
      !this.state.startDate ||
      !this.state.endDate
    ) {
      toast.warning(`${this.props.t("Fill all the fields")}`);
    } else if (!this.state.file && !this.state.editStatus) {
      toast.warning(`${this.props.t("Add image in to attachment")}`);
    } else if (
      !moment(this.state.endDate, "DD/MM/YYYY").isAfter(
        moment(this.state.startDate, "DD/MM/YYYY")
      )
    ) {
      toast.warning(
        `${this.props.t(
          "Announcement endDate  should be grater then start Date"
        )}`
      );
    } else {
      const formData = new FormData();
      if (this.state.editStatus) {
        formData.append("announcementId", this.state.id);
      }
      formData.append("title", this.state.announcementTitle);
      formData.append("description", this.state.description);
      if (!this.state.image && this.state.file) {
        formData.append("announceFile", this.state.file);
      }

      formData.append("type", "image");
      // formData.append("startDate", this.state.startDate);
      // formData.append("endDate", this.state.endDate);
      var endDate = moment(this.state.endDate, "DD/MM/YYYY");
      var startDate = moment(this.state.startDate, "DD/MM/YYYY");
      formData.append("endDate", endDate);
      formData.append("startDate", startDate);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      PostService.saveOrUpdateAnnouncement(formData, config)
        .then((response) => {
          toast.success(`${this.props.t("Announcement create successfully")}`);
          this.onBack();
        })
        .catch((error) => {
          toast.error(`${this.props.t("Error to saving")}`);
        });
    }
  };
  onBack = () => {
    this.props.history.push(`/${this.props.workspaceType}/announcement-list`);
  };
  getQuestionMediaFiles = (files, type) => {
    console.log("update 111", files);
    this.setState({ file: files, image: null });
  };

  getQuestionMediaImage = (files, type) => {
    this.setState({ file: files, image: null });
  };
  fileOptionEnable = () => {
    this.setState({
      file: null,
    });
  };
}

const mapStateToProps = function (state) {
  return {
    userRole: state.loginDetailsApi.loginDetails?.userWorkSpace?.role,
    workspaceType: state.loginDetailsApi.loginDetails?.userWorkSpace?.slug, //  tagDatas: state.subjects.myGroupHome,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(AskQuestion)));
