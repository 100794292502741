/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import AuthService from "../../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { withRouter } from "react-router";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";

import moment from "moment";
import BlockUi from "react-block-ui";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./groupdesign.css";
import MyYuukke from "../../../api/MyYuukke";
import Swal from "sweetalert2";
import GroupFeed from "./GroupFeed";
import GroupConnectionList from "./GroupConnectionList";
import ProfileService from "../../../api/ProfileService";
import GroupCardBody from "./GroupCardBody";
import GroupInfoCard from "./GroupInfoCard";
import MessagesContainer from "../../Community/Subjects/MessagesContainer";
import {
  getAllMyGroupEvents,
  getAllSubjectList,
  getSubjectsRequestList,
  getAllMembersDetails,
  getResourceDocumentList,
  getResourceVideoList,
  getResourceInspirationList,
  getChallengeApplyList,
  getResourcePlayList,
  getResourceByPlaylist,
  getResourcePostTags,
  getResourcePostList,
  startResourceLoader,
  getGroupRequestList,
} from "../../../redux/actions/subjectActions";
import { getOverallPostList } from "../../../redux/actions/SubjectquestionActions";
import {
  getAllUserCommunityList,
  getNavigationType,
  needPhotVerification,
} from "../../../redux/actions/commonApiActions";
import Pagination from "react-js-pagination";
import ResourceInfoCard from "./ResourceInfoCard";
import ChallengeInfoCard from "./ChallengeInfoCard";
import { toast, ToastContainer } from "react-toastify";
import InviteFriendGroup from "../InviteFriendGroup";
import PlayListCard from "./PlayListCard";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { Multiselect } from "react-widgets";
import { BiCheck } from "react-icons/bi";
import SearchIcon from "@material-ui/icons/Search";
import ResourceMediaFile from "./ResourceMediaFile";
import { BsSearch } from "react-icons/bs";
import UserCard from "../../../components/UserCard";
import { giveUserAction } from "../../../utils";

var tpage = 0;
var firstTimeLoad = false;
var secondTimeLoad = false;
var typeQuestionTop = "newest";
var subjectId = 0;
var ipage = 0;
var vpage = 0;
var dpage = 0;
var cpage = 0;
var tagsList = [];
let tags = [];
let playListpage = 0;

var optionTypeData = [
  { name: "Sort by Descending Order" },
  { name: "Sort by Ascending Order" },
  { name: "Sort by Year" },
];
class GroupDescription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // userId: this.props.match.params.userId,
      groupWorkspace: 0,
      challengesCategory_value: [],
      tabIndex: 0,
      pageNo: 1,
      userId: 0,
      friendsList: [],
      userKnowledgepoint: "",
      coverPhotoImage: "",
      userPhotoImage: "",
      areaOfInterest: [],
      userName: "",
      country_value: "",
      biography: "",
      professionID: "",
      dob: "",
      location: "",
      openAbout: false,
      openPost: true,
      openConnection: false,
      userPostList: [],
      groupGroupId: "",
      openMessageState: false,
      myGroupState: false,
      openMemberPageState: false,
      openApplyPageState: false,
      openParticipantsPageState: false,
      openSchedulePageState: false,
      openFAQPageState: false,
      openTermsPageState: false,
      openDescriptionPageState: false,
      openInspirationPageState: false,
      openVideoPageState: false,
      openDocumentPageState: false,
      openResultsPageState: false,
      subjectId: "",
      openDoc: false,
      openVideo: false,
      openImage: true,
      showPlusMenu: false,
      filterTagList: [],
      postBymeLists: [],
      tagsListStatus: false,
      userApply: "",
      challengeId: "",
      userJoin: false,
      communityTypeName: "",
      formPage: false,
      openFormCommunityPage: false,
      gridValue: 8,
      groupInfoCardStatus: false,
      catalystTalkPage: false,
      opencatalystPageState: false,
      specialResource: false,
      selectedTagList: [],
      isOpen: false,
      resourceType: 0,
      playListNeed: false,
      resourcePageNo: 0,
      openResourcePage: false,
      resourceFilterType: "image",
      activePage: 1,
      activePlayListVideoPostPage: 1,
      filterId: [],
      showMenu: false,
      optionIndex: 0,
      optionName: "Sort by Descending Order",
      completionYear: [],
      filterYear: "",
      apiFilterKey: "newest",
      searchData: "",
      playlistId: 0,
      careerLabType: 0,
      selectedCountryList: [],
      joinType: null,
      userStatus: "user",
      //  expiryDateAboutPage:"",
    };
    this.gotoabout = this.gotoabout.bind(this);
    this.gotoconnections = this.gotoconnections.bind(this);
    this.gotoposts = this.gotoposts.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.editGroupsEvents = this.editGroupsEvents.bind(this);
    this.movetolearningapp = this.movetolearningapp.bind(this);
    this.movetochallenges = this.movetochallenges.bind(this);
    this.getResourcePostbyVideos = this.getResourcePostbyVideos.bind(this);
    this.getResourcePostbydoc = this.getResourcePostbydoc.bind(this);
    this.getResourcePostbyImages = this.getResourcePostbyImages.bind(this);
    this.openDocumentPage = this.openDocumentPage.bind(this);
    this.openVideoPage = this.openVideoPage.bind(this);
    this.openInspirationPage = this.openInspirationPage.bind(this);
    this.showPlusMenu = this.showPlusMenu.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickMyList = this.handleClickMyList.bind(this);
    this.getMyPosts = this.getMyPosts.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.getChallengeApplied = this.getChallengeApplied.bind(this);
    this.openSchedulePage = this.openSchedulePage.bind(this);
    this.openDescriptionPage = this.openDescriptionPage.bind(this);
    this.openFAQPage = this.openFAQPage.bind(this);
    this.openTermsPage = this.openTermsPage.bind(this);
    this.openParticipantsPage = this.openParticipantsPage.bind(this);
    this.openResultsPage = this.openResultsPage.bind(this);
  }

  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
    });
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    console.log("++START================>");

    if (this.props.match.params.subjectId) {
      var Edit = this.props.match.params.subjectId;
      console.log(`user id ${this.props.match.params.subjectId}`);
      this.setState(
        {
          subjectId: this.props.match.params.subjectId,
          groupGroupId: Edit,
        },
        () => {
          if (this.props.fromWhichCard == "learningAppDesription") {
            this.props.startResourceLoader();
          }
          // this.props.getSubjectsRequestList(this.props.match.params.subjectId);
          this.editGroupsEvents(this.props.match.params.subjectId);
          if (Edit === undefined) {
          } else {
            this.setState({
              status: "",
              joinedGroupState: false,
            });
          }

          if (
            this.props.fromWhichCard == "groupscard" ||
            this.props.fromWhichCard == "mygroupscard"
          ) {
            this.props.getOverallPostList(
              tpage,
              this.props.match.params.subjectId
            );
            this.props.getAllMembersDetails(Edit, "all", 0, false); //Old list
          } else if (this.props.fromWhichCard == "learningAppDesription") {
            // this.props.getResourceInspirationList(
            //   ipage,
            //   Edit,
            //   "empty",
            //   tagsList
            // );

            this.setState({
              openInspirationPageState: true,
              openVideoPageState: false,
              openDocumentPageState: false,
            });
          } else if (this.props.fromWhichCard == "special-resource") {
            // this.props.getResourceVideoList(
            //   ipage,
            //   Edit,
            //   "empty",
            //   tagsList
            // );
            // this.setState({
            //   openInspirationPageState: false,
            //   openVideoPageState: true,
            //   openDocumentPageState: false,
            // });
          } else if (this.props.fromWhichCard == "challengeDescription") {
            this.props.getChallengeApplyList(
              cpage,
              this.props.match.params.subjectId
            );
          }
        }
      );
    }

    console.log("this.props.allMembersList", this.props.allMembersList);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.match.params.subjectId !== nextProps.match.params.subjectId
    ) {
      var Edit = nextProps.match.params.subjectId;
      this.setState(
        {
          subjectId: nextProps.match.params.subjectId,
        },
        () => {}
      );
      firstTimeLoad = true;
      secondTimeLoad = true;
      subjectId = Edit;
      typeQuestionTop = "newest";
      if (this.props.fromWhichCard == "learningAppDesription") {
        this.props.startResourceLoader();
      }
      this.editGroupsEvents(nextProps.match.params.subjectId);
      if (
        this.props.fromWhichCard == "groupscard" ||
        this.props.fromWhichCard == "mygroupscard"
      ) {
        this.props.getOverallPostList(tpage, Edit);
        this.props.getAllMembersDetails(Edit, "all", 0, false); //Old list
      } else if (this.props.fromWhichCard == "learningAppDesription") {
        // this.props.getResourceInspirationList(ipage, Edit, "empty", tagsList);

        this.setState({
          openInspirationPageState: true,
          openVideoPageState: false,
          openDocumentPageState: false,
        });
      } else if (this.props.fromWhichCard == "challengeDescription") {
        this.props.getChallengeApplyList(cpage, Edit);
      }
      this.setState({
        opencatalystPageState: false,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.subjectId !== this.props.match.params.subjectId
    ) {
      this.setState(
        {
          subjectId: this.props.match.params.subjectId,
        },
        () => {}
      );
    }
  }
  searchMembers = (value) => {
    this.setState({
      searchData: value,
    });
    var data = {};
    if (this.state.resourceType === 1) {
      data = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: 0,
        searchTags: value,
        playlistId: this.state.playlistId,
        filterKey: this.state.apiFilterKey,
      };
    } else if (this.state.resourceType === 0) {
      data = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: 0,
        type: this.state.resourceFilterType,
        filterKey: this.state.apiFilterKey,
        searchTags: value,
      };
    } else {
      data = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: 0,
        filterKey: this.state.apiFilterKey,
        searchTags: value,
      };
    }

    this.props.getResourcePostList(data);
  };
  handleTagMultiSelect = (data) => {
    this.setState({
      selectedTagList: data,
      isOpen: false,
      activePlayListVideoPostPage: 0,
    });
    const id = data.map((item) => item.tagId);
    this.setState({
      filterId: id,
    });
    var value = {};
    if (this.state.resourceType === 1) {
      value = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: 0,
        resourceTagIds: id,
        playlistId: this.state.playlistId,
      };
    } else if (this.state.resourceType === 0) {
      value = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: 0,
        type: this.state.resourceFilterType,
        resourceTagIds: id,
      };
    } else {
      value = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: 0,
        resourceTagIds: id,
      };
    }

    this.props.getResourcePostList(value);

    console.log(id);
  };
  handleDropdownToggle = (isDropdownOpen) => {
    this.setState({
      isOpen: isDropdownOpen,
    });
  };
  handleTagSelect = () => {
    this.setState({
      isOpen: false,
    });
  };
  optionIndexData = async (index, name) => {
    let year = [];
    var currentYear = new Date().getUTCFullYear();
    for (var i = 2022; i <= currentYear; i++) {
      var obj = {};
      obj["id"] = i;
      obj["name"] = i;
      year.push(obj);
    }
    await this.setState({
      searchData: "",
      optionIndex: index,
      optionName: name,
      showMenu: false,
      apiFilterKey:
        name === "Sort by Descending Order"
          ? "newest"
          : name === "Sort by Ascending Order"
          ? "oldest"
          : currentYear,
      completionYear: year,
      filterYear: currentYear,
    });
    // if (name === "Sort by Year") {

    // } else {
    var data = {};
    if (this.state.resourceType === 1) {
      data = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: 0,
        searchTags: "",
        filterKey:
          name === "Sort by Year" ? currentYear : this.state.apiFilterKey,
        playlistId: this.state.playlistId,
      };
    } else if (this.state.resourceType === 0) {
      data = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: 0,
        type: this.state.resourceFilterType,
        filterKey:
          name === "Sort by Year" ? currentYear : this.state.apiFilterKey,
        searchTags: "",
      };
    } else {
      data = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: 0,
        filterKey:
          name === "Sort by Year" ? currentYear : this.state.apiFilterKey,
        searchTags: "",
      };
    }

    this.props.getResourcePostList(data);
    // }
  };
  handleYearFilter = (value) => {
    this.setState({
      filterYear: value,
      searchData: "",
      apiFilterKey: value,
    });
    var data = {};
    if (this.state.resourceType === 1) {
      data = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: 0,
        searchTags: "",
        filterKey: value,
        playlistId: this.state.playlistId,
      };
    } else if (this.state.resourceType === 0) {
      data = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: 0,
        type: this.state.resourceFilterType,
        filterKey: value,
        searchTags: "",
      };
    } else {
      data = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: 0,
        filterKey: value,
        searchTags: "",
      };
    }

    this.props.getResourcePostList(data);
  };

  render() {
    const { t } = this.props;
    const {
      showAnswer,
      selectedQuestionId,
      subject_id,
      memberShipPrice,
      memberShipType,
      userName,
      userId,
      status,
      showPlusMenu,
    } = this.state;
    console.log("userStatus", this.state.userStatus);
    return (
      <>
        <ToastContainer position="top-right" autoClose={5000} />

        <>
          {this.props.fromWhichCard == "mygroupscard" && (
            <div
              style={{
                marginRight: "12px",
                marginLeft: "0px",
                marginBottom: "12px",
              }}
              className="ansBackQuestion flexDefault uploader"
              onClick={this.movetomygroups}
            >
              <ArrowBackIcon />
              <p>{t("Back to My Community")}</p>
            </div>
          )}

          {this.props.fromWhichCard == "groupscard" && (
            <div
              style={{
                marginRight: "12px",
                marginLeft: "0px",
                marginBottom: "12px",
              }}
              className="ansBackQuestion flexDefault uploader"
              onClick={this.movetomygroups}
            >
              <ArrowBackIcon />
              <p>{t("Back to Community")}</p>
            </div>
          )}
          {this.props.fromWhichCard == "learningAppDesription" && (
            <div
              style={{
                marginRight: "12px",
                marginLeft: "0px",
                marginBottom: "12px",
              }}
              className="ansBackQuestion flexDefault uploader"
              onClick={this.movetolearningapp}
            >
              <ArrowBackIcon />
              <p>{t("Back to Learning Hub")}</p>
            </div>
          )}

          {this.props.fromWhichCard == "challengeDescription" && (
            <div
              style={{
                marginRight: "12px",
                marginLeft: "0px",
                marginBottom: "12px",
              }}
              className="ansBackQuestion flexDefault uploader"
              onClick={this.movetochallenges}
            >
              <ArrowBackIcon />
              <p>{t("Back to Career Labs")}</p>
            </div>
          )}

          <Grid
            container
            spacing={1}
            // style={{marginLeft:"20px"}}
          >
            <Grid item sm={12} xs={12} lg={12} md={12}>
              <div class="groupCard">
                <GroupCardBody
                  careerLabType={this.state.careerLabType}
                  userApply={this.state.userApply}
                  challengeId={this.state.challengeId}
                  subjectName={this.state.subjectName}
                  fromWhichCard={this.props.fromWhichCard}
                  subjectId={this.props.match.params.subjectId}
                  grouporeventFiles={this.state.grouporeventFiles}
                  fileType={this.state.fileType}
                  createdDate={this.state.createdDate}
                  subjectDescription={this.state.subjectDescription}
                  joinedGroup={this.state.joinedGroupState}
                  status={this.state.status}
                  tagList={this.state.tagListId}
                  allMembersList={this.props.allMembersList}
                  expiryDate={this.state.expiryDate}
                  userId={this.state.userId}
                  challengesCategory_value={this.state.challengesCategory_value}
                  groupWorkspace={this.state.groupWorkspace}
                  resourceType={this.state.resourceType}
                />
                {(this.state.status === "public" ||
                  this.state.status === "private" ||
                  this.state.status === "protected") && (
                  <div class="main-card-footer mt-3 pt-2 pb-0">
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={8} md={9} lg={10}>
                        <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
                          <li class="nav-item">
                            {" "}
                            {this.state.communityTypeName !==
                              "Forum Community" && (
                              <a
                                style={{ float: "left", cursor: "pointer" }}
                                class={
                                  this.state.openPost
                                    ? "nav-link active nav-link-body-sideMenu-active"
                                    : "nav-link nav-link-body"
                                }
                                onClick={this.gotoposts}
                              >
                                {" "}
                                {t("Feed")}{" "}
                              </a>
                            )}
                            {this.state.communityTypeName ===
                              "Forum Community" && (
                              <a
                                style={{ float: "left", cursor: "pointer" }}
                                class={
                                  this.state.openFormCommunityPage
                                    ? "nav-link active nav-link-body-sideMenu-active"
                                    : "nav-link nav-link-body"
                                }
                                onClick={this.openFormCommunityPage}
                              >
                                {" "}
                                {t("Forum Questions")}{" "}
                              </a>
                            )}
                          </li>
                          <li class="nav-item">
                            {" "}
                            <a
                              style={{ float: "left", cursor: "pointer" }}
                              class={
                                this.state.openConnection
                                  ? "nav-link active nav-link-body-sideMenu-active"
                                  : "nav-link nav-link-body"
                              }
                              onClick={this.gotoconnections}
                            >
                              {" "}
                              {t("Connections")}{" "}
                              <span class="greenLabelConnection">
                                {this.props.totalCommunityUser}
                              </span>{" "}
                            </a>{" "}
                          </li>
                        </ul>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} lg={2}>
                        {this.state.communityTypeName == "Forum Community" &&
                          this.state.joinedGroupState &&
                          this.state.myGroupState &&
                          this.state.openFormCommunityPage && (
                            <button onClick={this.askAQuestionPage}>
                              {t("Ask A Question")}
                            </button>
                          )}
                      </Grid>
                    </Grid>
                  </div>
                )}
                {this.state.status === "challenges" && (
                  <div class="main-card-footer mt-3 pt-2 pb-0">
                    <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
                      <li class="nav-item">
                        {" "}
                        <a
                          style={{ float: "left", cursor: "pointer" }}
                          class={
                            this.state.openDescriptionPageState
                              ? "nav-link active nav-link-body-sidemenu-active"
                              : "nav-link nav-link-body"
                          }
                          onClick={this.openDescriptionPage}
                        >
                          {" "}
                          {t("Description")}
                        </a>{" "}
                      </li>

                      <li class="nav-item">
                        {" "}
                        <a
                          style={{ float: "left", cursor: "pointer" }}
                          class={
                            this.state.openParticipantsPageState
                              ? "nav-link active nav-link-body-sidemenu-active"
                              : "nav-link nav-link-body"
                          }
                          onClick={this.openParticipantsPage}
                        >
                          {" "}
                          {t("Participants")}{" "}
                        </a>{" "}
                      </li>

                      {/* <li class="nav-item">
                        {" "}
                        <a
                          style={{ float: "left", cursor: "pointer" }}
                          class={
                            this.state.openResultsPageState
                              ? "nav-link active nav-link-body-sidemenu-active"
                              : "nav-link nav-link-body"
                          }
                          onClick={this.openResultsPage}
                        >
                          {" "}
                          Results{" "}
                          
                        </a>{" "}
                      </li> */}

                      {this.state.subjectCondition && (
                        <li class="nav-item">
                          {" "}
                          <a
                            style={{ float: "left", cursor: "pointer" }}
                            class={
                              this.state.openTermsPageState
                                ? "nav-link active nav-link-body-sidemenu-active"
                                : "nav-link nav-link-body"
                            }
                            onClick={this.openTermsPage}
                          >
                            {" "}
                            {t("Terms And Condition")}{" "}
                          </a>
                        </li>
                      )}

                      {this.state.subjectFaq && (
                        <li class="nav-item">
                          {" "}
                          <a
                            style={{ float: "left", cursor: "pointer" }}
                            class={
                              this.state.openFAQPageState
                                ? "nav-link active nav-link-body-sidemenu-active"
                                : "nav-link nav-link-body"
                            }
                            onClick={this.openFAQPage}
                          >
                            {" "}
                            {t("FAQ")}{" "}
                          </a>{" "}
                        </li>
                      )}

                      {this.state.subjectSchedule && (
                        <li class="nav-item">
                          {" "}
                          <a
                            style={{ float: "left", cursor: "pointer" }}
                            class={
                              this.state.openSchedulePageState
                                ? "nav-link active nav-link-body-sidemenu-active"
                                : "nav-link nav-link-body"
                            }
                            onClick={this.openSchedulePage}
                          >
                            {" "}
                            {t("Schedule")}
                          </a>{" "}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                {this.state.status === "resource" &&
                  !this.state.specialResource &&
                  !this.state.catalystTalkPage &&
                  this.state.resourceType === 0 && (
                    <div class="main-card-footer mt-2 pt-2 pb-0">
                      <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
                        {/* {this.props.fromWhichCard !=="special-resource"&& */}
                        <li class="nav-item">
                          {" "}
                          <a
                            style={{ float: "left", cursor: "pointer" }}
                            class={
                              this.state.resourceFilterType === "image"
                                ? "nav-link active nav-link-body-sidemenu-active"
                                : "nav-link nav-link-body"
                            }
                            onClick={() => this.handleChangeResource("image")}
                          >
                            {t("Inspiration")}
                          </a>{" "}
                        </li>
                        {/* } */}
                        <li class="nav-item">
                          {" "}
                          <a
                            style={{ float: "left", cursor: "pointer" }}
                            class={
                              this.state.resourceFilterType === "video"
                                ? "nav-link active nav-link-body-sidemenu-active"
                                : "nav-link nav-link-body"
                            }
                            // onClick={this.openVideoPage}
                            onClick={() => this.handleChangeResource("video")}
                          >
                            {t("Video")}
                          </a>{" "}
                        </li>
                        {this.props.fromWhichCard !== "special-resource" && (
                          <li class="nav-item">
                            {" "}
                            <a
                              style={{ float: "left", cursor: "pointer" }}
                              class={
                                this.state.resourceFilterType === "document"
                                  ? "nav-link active nav-link-body-sidemenu-active"
                                  : "nav-link nav-link-body"
                              }
                              onClick={() =>
                                this.handleChangeResource("document")
                              }
                              // onClick={this.openDocumentPage}
                            >
                              {t("Document")}
                            </a>{" "}
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
              </div>
              {/* Filter */}
              {this.state.status === "resource" && !this.state.playListNeed && (
                // <Grid
                //   container
                //   spacing={0}
                //   style={{ paddingTop: "10px", paddingBottom: "2px" }}
                // >
                //   <Grid item xs={12} sm={12} md={12} lg={12}>
                <div
                  className="learning-filter  position-relative mt-4 text-center"
                  style={{ gap: "2rem" }}
                >
                  <div>
                    <TextField
                      id="subjects"
                      label=""
                      style={{ width: "100%", background: "white" }}
                      variant="outlined"
                      onChange={(e) => this.searchMembers(e.target.value)}
                      value={this.state.searchData}
                      name="searchData"
                      placeholder={t("Search")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="position-relative">
                    <button
                      class="btn cardButton-com dropdown-toggle"
                      style={{
                        width: "100%",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      onClick={this.showPlusMenu}
                      ref={this.wrapperRef}
                    >
                      {t(this.state.optionName)}{" "}
                    </button>
                    {this.state.showMenu && (
                      <>
                        <div
                          className="cardButton-forum-dropdownCardPost"
                          style={{ width: "100%" }}
                          ref={this.wrapperRef}
                        >
                          {optionTypeData &&
                            optionTypeData.map((data, index) => (
                              <div
                                className={`segmentsList-forum${
                                  this.state.optionIndex === index
                                    ? " selected-forum"
                                    : ""
                                }`}
                                // className="post-nav-item post-card-title post-mb-0 displayName-forum"
                                ref={this.wrapperRef}
                                onClick={() =>
                                  this.optionIndexData(index, data.name)
                                }
                              >
                                {this.state.optionIndex === index && (
                                  <BiCheck
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                )}
                                {t(`${data.name}`)}
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                  </div>
                  {this.state.optionName === "Sort by Year" && (
                    <div>
                      <TextField
                        style={{
                          width: "100%",
                          background: "white",
                        }}
                        fullWidth
                        id="dropdown"
                        select
                        label=""
                        variant="outlined"
                        type="text"
                        required=""
                        name="filterYear"
                        value={this.state.filterYear}
                        onChange={(e) => this.handleYearFilter(e.target.value)}
                      >
                        {this.state.completionYear &&
                          this.state.completionYear.map((option, index) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}{" "}
                      </TextField>{" "}
                    </div>
                  )}
                  {this.state.resourceType === 0 && (
                    <div className="position-relative d-flex align-items-center justify-content-end">
                      <Multiselect
                        style={{ fontColor: "gray", width: "100%" }}
                        required
                        placeholder={t("Search Country Based Post ...")}
                        data={this.props.countyNameList}
                        valueField="id"
                        textField="nicename"
                        name="selectedTagList"
                        value={
                          this.state.selectedCountryList
                            ? this.state.selectedCountryList
                            : []
                        }
                        onToggle={this.handleDropdownToggle}
                        onChange={(value) =>
                          this.handleCountryMultiSelect(value)
                        }
                        open={this.state.isOpen}
                        onSelect={this.handleTagSelect}
                      />
                      <BsSearch className="position-absolute mr-1 bg-white" />
                    </div>
                  )}

                  {/* <Multiselect
                        placeholder="Search tags"
                        style={{ width: "20rem" }}
                        className="custom-multiselect"
                        required
                        data={this.props.resourceTagList}
                        valueField="tagId"
                        textField="tagName"
                        value={this.state.selectedTagList}
                        onChange={(value) => this.handleTagMultiSelect(value)}
                        onToggle={this.handleDropdownToggle}
                        onSelect={this.handleTagSelect}
                        open={this.state.isOpen}
                        // dropUp
                      />
                      <BiSearch className="svg-icons search-icon position-absolute" /> */}
                </div>
                //   </Grid>
                // </Grid>
              )}

              {!this.state.catalystTalkPage &&
                !this.state.specialResource &&
                this.props.fromWhichCard == "learningAppDesription" &&
                this.state.status == "resource" && (
                  <>
                    {/* <Grid
                      container
                      spacing={0}
                      style={{ paddingTop: "10px", paddingBottom: "2px" }}
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                         
                        <button
                          className="cardButton"
                          onClick={this.showPlusMenu}
                          style={{ marginRight: "2rem" }}
                          ref={this.wrapperRef}
                        >
                          <HiOutlineFilter
                            style={{
                              marginRight: "5px",
                              width: "18px",
                              height: "18px",
                            }}
                          />{" "}
                          {t("Filter")}{" "}
                        </button>
                      </Grid>
                    </Grid> */}
                    {/* {showPlusMenu && (
                      <>
                        <div
                          className="dropdownCardGroup"
                          ref={this.wrapperRef}
                        >
                          <p
                            className="chooseCardList"
                            onClick={() => this.optionType(0)}
                            ref={this.wrapperRef}
                          >
                            {t("Tag List")}
                          </p>

                          <p
                            className="chooseCardList"
                            onClick={() => this.optionType(4)}
                          >
                            {t("Clear Filter")}
                          </p>
                        </div>
                      </>
                    )}
                    {this.state.tagsListStatus && (
                      <div className="postCardCloud">
                        <Grid container spacing={2}>
                          {this.state.filterTagList &&
                            this.state.tagsListStatus &&
                            this.state.filterTagList.map((group, index) => (
                              <Grid item lg={2} md={3} sm={6} xs={6}>
                                <Chip
                                  className={
                                    group.myPostColor == "true"
                                      ? "navMenuTextoverFlowOrange"
                                      : "navMenuTextoverFlowGray"
                                  }
                                  style={{
                                    overflow: "hidden",
                                    Whitespace: "nowrap",
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    textOverflow: "ellipsis",
                                  }}
                                  onClick={() =>
                                    this.handleClickMyList(
                                      group.tagName,
                                      group.tagId,
                                      index
                                    )
                                  }
                                  label={group.tagName}
                                ></Chip>
                              </Grid>
                            ))}
                        </Grid>
                      </div>
                    )} */}
                  </>
                )}
              {/*Group and MyGroup Dis */}

              {this.state.myGroupState && (
                <>
                  {this.state.openPost && (
                    <>
                      {" "}
                      <Grid container spacing={1} style={{ marginTop: "20px" }}>
                        <Grid item sm={12} xs={12} lg={8} md={8}>
                          <div>
                            {this.state.status === "public" && (
                              <div>
                                {this.state.joinedGroupState === false && (
                                  <div className="JRWrapper">
                                    <center>
                                      {" "}
                                      <button
                                        onClick={() =>
                                          this.getMemberLists(
                                            subject_id,
                                            memberShipPrice,
                                            memberShipType,
                                            userName,
                                            userId
                                          )
                                        }
                                      >
                                        <div>{t("Join Community")}</div>
                                      </button>
                                    </center>
                                  </div>
                                )}
                              </div>
                            )}
                            {this.state.status === "protected" && (
                              <div>
                                {this.state.joinedGroupState === false && (
                                  <div className="JRWrapper">
                                    <center>
                                      {" "}
                                      <button
                                        className=""
                                        onClick={() =>
                                          this.giveRequest(
                                            subject_id,
                                            this.state.joinType
                                          )
                                        }
                                        style={{
                                          cursor: `${
                                            this.state.joinType === "requested"
                                              ? "not-allowed"
                                              : "pointer"
                                          }`,
                                          background: `${
                                            this.state.joinType === "requested"
                                              ? "gray"
                                              : ""
                                          }`,
                                          border: `${
                                            this.state.joinType === "requested"
                                              ? "none"
                                              : ""
                                          }`,
                                        }}
                                      >
                                        {this.state.joinType === "requested"
                                          ? t("Request Pending")
                                          : t("Request")}
                                      </button>
                                    </center>
                                  </div>
                                )}
                              </div>
                            )}
                            {this.state.joinedGroupState === true &&
                              !this.state.openFormCommunityPage && (
                                <div>
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12}>
                                      <div
                                        className=""
                                        style={{ marginBottom: "12px" }}
                                      >
                                        <MessagesContainer
                                          group_event_name={
                                            this.state.subjectName
                                          }
                                          group_event_id={this.state.subjectId}
                                          GoToSubjectQuestionList={
                                            this.GoToSubjectQuestionList
                                          }
                                          name="Hyser"
                                          pageNo={tpage}
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>

                                  <div>
                                    <GroupFeed
                                      subjectId={
                                        this.props.match.params.subjectId
                                      }
                                    >
                                      {" "}
                                    </GroupFeed>
                                  </div>
                                </div>
                              )}
                          </div>
                        </Grid>
                        {this.state.joinedGroupState &&
                          this.state.myGroupState &&
                          this.state.openFormCommunityPage && (
                            <Grid
                              item
                              sm={12}
                              xs={12}
                              lg={this.state.gridValue}
                              md={this.state.gridValue}
                            >
                              {/* <QuestionsContainer
                                subjectId={this.state.subjectId}
                                setGridValue={this.setGridValue}
                              /> */}
                            </Grid>
                          )}

                        {(this.state.status === "public" ||
                          this.state.status === "private" ||
                          this.state.status === "protected") &&
                          !this.state.groupInfoCardStatus && (
                            <Grid item sm={12} xs={12} lg={4} md={4}>
                              <GroupInfoCard
                                expiry={this.state.expiry}
                                subjectDescription={
                                  this.state.subjectDescription
                                }
                              ></GroupInfoCard>
                              {(this.state.status === "public" ||
                                this.state.status === "private" ||
                                this.state.status === "protected") &&
                                (this.state.userId ===
                                  this.props.loginDetails.uId ||
                                  this.state.userStatus === "admin") && (
                                  <>
                                    <div style={{ marginTop: "12px" }}>
                                      <InviteFriendGroup
                                        subjectName={this.state.subjectName}
                                        subId={this.state.subject_id}
                                        grouporeventFiles={
                                          this.state.grouporeventFiles
                                        }
                                        fileType={this.state.fileType}
                                        createdDate={this.state.createdDate}
                                        subjectDescription={
                                          this.state.subjectDescription
                                        }
                                        joinedGroup={
                                          this.state.joinedGroupState
                                        }
                                        onOpenMessage={this.onOpenMessage}
                                        tagListId={this.state.tagListId}
                                        removedSentData={this.removedSentData}
                                      ></InviteFriendGroup>
                                    </div>
                                    {this.state.status === "protected" && (
                                      <>
                                        <div
                                          className="d-flex flex-column mt-3"
                                          style={{ gap: "1rem" }}
                                        >
                                          <UserCard
                                            subjectId={this.state.subject_id}
                                            showFriendsProfile={
                                              this.showFriendsProfile
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                            </Grid>
                          )}
                      </Grid>
                    </>
                  )}
                  {this.state.openConnection && (
                    <div style={{ paddingTop: "20px" }}>
                      <GroupConnectionList
                        friendsList={this.props.allMembersList}
                        loadFriendsList={this.getFriendsList}
                        communityId={this.props.match.params.subjectId}
                        userId={this.state.userId}
                        userStatus={this.state.userStatus}
                      ></GroupConnectionList>

                      {this.props.allMembersList?.length === 0 && (
                        <div className="cardWrapper mt-2">
                          <center style={{ padding: "12px" }}>
                            <div style={{ justifyContent: "center" }}>
                              <p style={{ margin: "8px", fontSize: "15px" }}>
                                {t("Members not present !")}
                              </p>
                            </div>
                          </center>
                        </div>
                      )}
                    </div>
                  )}{" "}
                </>
              )}

              {/*Challenge Dis */}
              {this.state.openDescriptionPageState && !this.state.formPage && (
                <div style={{ marginTop: "12px" }}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <div className="askAQuestionCardWrapper">
                        <p
                          className=""
                          style={{ paddingLeft: "12px", fontSize: "14px" }}
                          dangerouslySetInnerHTML={{
                            __html: this.state.subjectDescription,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
              {/* {this.state.openResultsPageState && (
            <div style={{ marginTop: "12px" }}>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
               
     
                <div className="resultsCardChallenge">

                  <ResultsList></ResultsList>
                 
                      </div>
                </Grid>
              </Grid>
            </div>
          )} */}
              {this.state.openTermsPageState && (
                <div style={{ marginTop: "12px" }}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <div className="askAQuestionCardWrapper">
                        <p
                          className=""
                          style={{ paddingLeft: "12px", fontSize: "14px" }}
                          dangerouslySetInnerHTML={{
                            __html: this.state.subjectCondition,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
              {this.state.openFAQPageState && (
                <div style={{ marginTop: "12px" }}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <div className="askAQuestionCardWrapper">
                        <p
                          className=""
                          style={{ paddingLeft: "12px", fontSize: "14px" }}
                          dangerouslySetInnerHTML={{
                            __html: this.state.subjectFaq,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
              {this.state.openSchedulePageState && (
                <div style={{ marginTop: "12px" }}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <div className="askAQuestionCardWrapper">
                        <p
                          className=""
                          style={{ paddingLeft: "12px", fontSize: "14px" }}
                          dangerouslySetInnerHTML={{
                            __html: this.state.subjectSchedule,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}

              {this.state.openParticipantsPageState && (
                <div className="" style={{ marginTop: "20px" }}>
                  <div className="participant-list">
                    {this.props.getChallengeAppliedList &&
                      this.props.getChallengeAppliedList.length > 0 &&
                      this.props.getChallengeAppliedList.map(
                        (question, index) => (
                          <ChallengeInfoCard
                            type="My Groups Post"
                            data={question}
                            login={this.props.loginDetails}
                            GoToSubjectQuestionList={
                              this.GoToSubjectQuestionList
                            }
                            BookmarkJoin={this.JoinBookmarkStar}
                            handleShowAnswer={this.handleShowAnswer}
                            deleteQuestionCard={this.deleteQuestionCards}
                            getPostOverall={this.getPosts}
                            careerLabType={this.state.careerLabType}
                          />
                        )
                      )}
                  </div>
                  {!this.props.SubQuestionloading &&
                    this.props.getChallengeAppliedList.length === 0 && (
                      <div class="no-data">
                        <center>
                          <p>{t("No Applicants")}</p>
                        </center>
                      </div>
                    )}
                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      {this.props.getChallengeAppliedList.length > 0 && (
                        <p>
                          {t("Showing")} -{" "}
                          {this.props.getChallengeAppliedList.length}{" "}
                          {t("Participants")}
                        </p>
                      )}
                      {this.props.getChallengeAppliedList.length == 0 && (
                        <p>{t("Showing 0 Participants")}</p>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      {
                        <Pagination
                          itemsCountPerPage={8}
                          activePage={this.state.activeChallenfeApplyPage}
                          totalItemsCount={
                            this.props.challengesAppliedtotalItems
                          }
                          pageRangeDisplayed={5}
                          onChange={this.getChallengeApplied.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <div className="paginationWrapperRight">
                        <p className="mr-3">{t("Go to page")}</p>
                        <input
                          type="text"
                          className="mr-3"
                          style={{ width: "30px" }}
                          value={this.state.pageNo}
                          name="pageNo"
                          onChange={this.handleForm}
                        />
                        <button
                          onClick={() =>
                            this.getChallengeApplied(this.state.pageNo)
                          }
                        >
                          {t("Go")}
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
              {/* Resource Dis */}

              {this.state.openResourcePage && !this.state.catalystTalkPage && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <BlockUi
                        tag="div"
                        style={{ minHeight: "100px" }}
                        blocking={this.props.resourcePostLoader}
                        // message={t("Loading, please wait")}
                        color="#A00030"
                      >
                        <Grid container spacing={2}>
                          {this.props.resourcePosts &&
                            this.props.resourcePosts?.length > 0 &&
                            this.props.resourcePosts.map((question, index) => (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                // md={12}
                                // lg={12}

                                md={
                                  this.state.resourceType === 4 ||
                                  this.state.resourceFilterType === "document"
                                    ? 6
                                    : 12
                                }
                                lg={
                                  this.state.resourceType === 4 ||
                                  this.state.resourceFilterType === "document"
                                    ? 6
                                    : 12
                                }
                              >
                                <React.Fragment key={index}>
                                  <ResourceInfoCard
                                    resourcePostLoader={
                                      this.props.resourcePostLoader
                                    }
                                    resourceType={this.state.resourceType}
                                    resourceFilterType={
                                      this.state.resourceFilterType
                                    }
                                    // type="My Groups Post"
                                    data={question}
                                    login={this.props.loginDetails}
                                    // GoToSubjectQuestionList={
                                    //   this.GoToSubjectQuestionList
                                    // }
                                    // BookmarkJoin={this.JoinBookmarkStar}
                                    // handleShowAnswer={this.handleShowAnswer}
                                    // deleteQuestionCard={
                                    //   this.deleteQuestionCards
                                    // }
                                    // getPostOverall={this.getPosts}
                                    getResourcePost={
                                      this.getResourcePostbyImages
                                    }
                                  />
                                </React.Fragment>
                              </Grid>
                            ))}{" "}
                          {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                            <ResourceMediaFile
                              moveToMediaPage={this.moveToMediaPage}
                              playList={false}
                              playlistId={this.state.playlistId}
                              subjectId={this.state.subjectId}
                              resourceType={this.state.resourceType}
                              resourceFilterType={this.state.resourceFilterType}
                            />
                          </Grid> */}
                        </Grid>
                        {!this.props.resourcePostLoader &&
                          this.props.resourcePosts.length === 0 && (
                            <div class="no-data mt-4">
                              <center>
                                <p>{t("No Posts Available")}</p>
                              </center>
                            </div>
                          )}
                      </BlockUi>
                    </Grid>
                  </Grid>
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    {/* {this.props.resourcePosts?.length > 0 && (
                      // <p>{t("Showing 1-10 Posts")}</p>
                      <p>
                        {t("Showing")} - {this.props.resourcePosts?.length}{" "}
                        {t("Posts")}
                      </p>
                    )}
                    {this.props.resourcePosts?.length == 0 && (
                      <p>{t("Showing 0 Posts")}</p>
                    )} */}

                    <Pagination
                      itemsCountPerPage={10}
                      activePage={this.state.activePage}
                      totalItemsCount={this.props.resourcePostTotalCount}
                      pageRangeDisplayed={5}
                      onChange={this.getResourcePostbyImages.bind(this)}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </>
              )}

              {/* {this.state.openInspirationPageState &&
                !this.state.catalystTalkPage && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <BlockUi
                          tag="div"
                          style={{ minHeight: "100px" }}
                          blocking={this.props.SubQuestionloading}
                          message={t("Loading, please wait")}
                          color="#A00030"
                        >
                          {this.props.getResourcePostbyImage &&
                            this.props.getResourcePostbyImage.length > 0 &&
                            this.props.getResourcePostbyImage.map(
                              (question, index) => (
                                <>
                                  <React.Fragment key={index}>
                                    <ResourceInfoCard
                                      status={"Notcatalyst"}
                                      type="My Groups Post"
                                      data={question}
                                      login={this.props.loginDetails}
                                      GoToSubjectQuestionList={
                                        this.GoToSubjectQuestionList
                                      }
                                      BookmarkJoin={this.JoinBookmarkStar}
                                      handleShowAnswer={this.handleShowAnswer}
                                      deleteQuestionCard={
                                        this.deleteQuestionCards
                                      }
                                      getPostOverall={this.getPosts}
                                      getResourcePost={
                                        this.getResourcePostbyImages
                                      }
                                    />
                                  </React.Fragment>
                                </>
                              )
                            )}
                          {!this.props.SubQuestionloading &&
                            this.props.getResourcePostbyImage.length === 0 && (
                              <div class="no-data">
                                <center>
                                  <p>{t("No Posts")}</p>
                                </center>
                              </div>
                            )}
                        </BlockUi>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        {this.props.getResourcePostbyImage?.length > 0 && (
                          // <p>{t("Showing 1-10 Posts")}</p>
                          <p>
                            {t("Showing")} -{" "}
                            {this.props.getResourcePostbyImage?.length}{" "}
                            {t("Posts")}
                          </p>
                        )}
                        {this.props.getResourcePostbyImage?.length == 0 && (
                          <p>{t("Showing 0 Posts")}</p>
                        )}
                      </Grid>
                      <Grid item xs={0} sm={0} md={0} lg={2}></Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        {
                          <Pagination
                            itemsCountPerPage={10}
                            activePage={this.state.activeImagePostPage}
                            totalItemsCount={this.props.resourceImagetotalItems}
                            pageRangeDisplayed={5}
                            onChange={this.getResourcePostbyImages.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        }
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <div className="paginationWrapperRight">
                          <p className="mr-3">{t("Go to page")}</p>
                          <input
                            type="text"
                            className="mr-3"
                            style={{ width: "30px" }}
                            value={this.state.pageNo}
                            name="pageNo"
                            onChange={this.handleForm}
                          />
                          <button
                            onClick={() =>
                              this.getResourcePostbyImages(this.state.pageNo)
                            }
                          >
                            {t("Go")}
                          </button>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}

              {this.state.openVideoPageState &&
                !this.state.catalystTalkPage && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <BlockUi
                          tag="div"
                          style={{ minHeight: "100px" }}
                          blocking={this.props.SubQuestionloading}
                          message={t("Loading, please wait")}
                          color="#A00030"
                        >
                          {this.props.getResourcePostbyVideo &&
                            this.props.getResourcePostbyVideo?.length > 0 &&
                            this.props.getResourcePostbyVideo.map(
                              (question, index) => (
                                <>
                                  <React.Fragment key={index}>
                                    <ResourceInfoCard
                                      status={"Notcatalyst"}
                                      type="My Groups Post"
                                      data={question}
                                      login={this.props.loginDetails}
                                      GoToSubjectQuestionList={
                                        this.GoToSubjectQuestionList
                                      }
                                      BookmarkJoin={this.JoinBookmarkStar}
                                      handleShowAnswer={this.handleShowAnswer}
                                      deleteQuestionCard={
                                        this.deleteQuestionCards
                                      }
                                      getPostOverall={this.getPosts}
                                      getResourcePost={
                                        this.getResourcePostbyVideos
                                      }
                                    />
                                  </React.Fragment>
                                </>
                              )
                            )}
                          {!this.props.SubQuestionloading &&
                            this.props.getResourcePostbyVideo?.length === 0 && (
                              <div class="no-data">
                                <center>
                                  <p>{t("No Video Posts")}</p>
                                </center>
                              </div>
                            )}
                        </BlockUi>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        {this.props.getResourcePostbyVideo?.length > 0 && (
                          // <p>{t("Showing 1-10 Posts")}</p>
                          <p>
                            {t("Showing")} -{" "}
                            {this.props.getResourcePostbyVideo?.length}{" "}
                            {t("Posts")}
                          </p>
                        )}
                        {this.props.getResourcePostbyVideo?.length == 0 && (
                          <p>{t("Showing 0 Posts")}</p>
                        )}
                      </Grid>
                      <Grid item xs={0} sm={0} md={0} lg={2}></Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        {
                          <Pagination
                            itemsCountPerPage={10}
                            activePage={this.state.activeVideoPostPage}
                            totalItemsCount={this.props.resourceVideototalItems}
                            pageRangeDisplayed={5}
                            onChange={this.getResourcePostbyVideos.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        }
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <div className="paginationWrapperRight">
                          <p className="mr-3">{t("Go to page")}</p>
                          <input
                            type="text"
                            className="mr-3"
                            style={{ width: "30px" }}
                            value={this.state.pageNo}
                            name="pageNo"
                            onChange={this.handleForm}
                          />
                          <button
                            onClick={() =>
                              this.getResourcePostbyVideos(this.state.pageNo)
                            }
                          >
                            {t("Go")}
                          </button>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}

              {this.state.openDocumentPageState &&
                !this.state.catalystTalkPage && (
                  <div className="chatWrapper">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <BlockUi
                          tag="div"
                          style={{ minHeight: "100px" }}
                          blocking={this.props.SubQuestionloading}
                          message={t("Loading, please wait")}
                          color="#A00030"
                        >
                          {this.props.getResourcePostbyDoc &&
                            this.props.getResourcePostbyDoc?.length > 0 &&
                            this.props.getResourcePostbyDoc.map(
                              (question, index) => (
                                // question.pageNo == tpage ? (
                                <>
                                  <React.Fragment key={index}>
                                    <ResourceInfoCard
                                      status={"Notcatalyst"}
                                      type="My Groups Post"
                                      data={question}
                                      login={this.props.loginDetails}
                                      GoToSubjectQuestionList={
                                        this.GoToSubjectQuestionList
                                      }
                                      BookmarkJoin={this.JoinBookmarkStar}
                                      handleShowAnswer={this.handleShowAnswer}
                                      deleteQuestionCard={
                                        this.deleteQuestionCards
                                      }
                                      getPostOverall={this.getPosts}
                                      getResourcePost={
                                        this.getResourcePostbydoc
                                      }
                                    />
                                  </React.Fragment>
                                </>
                              )
                              // ) : (
                              //   ""
                              // )
                            )}
                          {!this.props.SubQuestionloading &&
                            this.props.getResourcePostbyDoc.length === 0 && (
                              <div class="no-data">
                                <center>
                                  <p>{t("No Document Posts")}</p>
                                </center>
                              </div>
                            )}
                        </BlockUi>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        {this.props.getResourcePostbyDoc?.length > 0 && (
                          // <p>{t("Showing 1-10 Posts")}</p>
                          <p>
                            {t("Showing")} -{" "}
                            {this.props.getResourcePostbyDoc.length}{" "}
                            {t("Posts")}
                          </p>
                        )}
                        {this.props.getResourcePostbyDoc.length == 0 && (
                          <p>{t("Showing 0 Posts")}</p>
                        )}
                      </Grid>
                      <Grid item xs={0} sm={0} md={0} lg={2}></Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        {
                          <Pagination
                            itemsCountPerPage={10}
                            activePage={this.state.activeDocPostPage}
                            totalItemsCount={this.props.resourceDoctotalItems}
                            pageRangeDisplayed={5}
                            onChange={this.getResourcePostbydoc.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        }
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <div className="paginationWrapperRight">
                          <p className="mr-3">{t("Go to page")}</p>
                          <input
                            type="text"
                            className="mr-3"
                            style={{ width: "30px" }}
                            value={this.state.pageNo}
                            name="pageNo"
                            onChange={this.handleForm}
                          />
                          <button
                            onClick={() =>
                              this.getResourcePostbydoc(this.state.pageNo)
                            }
                          >
                            {t("Go")}
                          </button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )} */}

              {/* catalystTalkPage */}
              <Grid container spacing={2} style={{ marginTop: "10px" }}>
                {!this.state.opencatalystPageState &&
                  this.state.catalystTalkPage &&
                  this.props.resourcePlayList &&
                  this.props.resourcePlayList.map((people, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        style={{ marginTop: "5px" }}
                      >
                        <React.Fragment key={index}>
                          <PlayListCard
                            userId={people.uId}
                            playlistName={people.playlistName}
                            fileType={people.fileType}
                            fileName={people.fileName}
                            subjectId={people.subjectId}
                            playlistId={people.playlistId}
                            viewPlayListPage={this.viewPlayListVideoPost}
                          />
                        </React.Fragment>
                      </Grid>
                    );
                  })}
                {!this.state.opencatalystPageState &&
                  this.state.catalystTalkPage &&
                  this.props.resourcePlayList.length === 0 && (
                    <div class="no-data mt-1">
                      <center>
                        <p>{t("No Posts Available")}</p>
                      </center>
                    </div>
                  )}
              </Grid>
              {this.state.opencatalystPageState && (
                <>
                  <BlockUi
                    tag="div"
                    style={{ minHeight: "100px" }}
                    blocking={this.props.resourceloading}
                    message={t("Loading, please wait")}
                    color="#A00030"
                  >
                    <p
                      onClick={this.backToPlayList}
                      className="mt-2"
                      style={{
                        fontSize: "15px",
                        cursor: "pointer",
                      }}
                    >
                      <AiOutlineDoubleLeft /> {t("Back to PlayList")}
                    </p>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {this.props.resourcePosts &&
                          this.props.resourcePosts !== undefined &&
                          this.props.resourcePosts.map((question, index) => (
                            <>
                              <React.Fragment key={index}>
                                <ResourceInfoCard
                                  status={this.state.resourceType}
                                  // type="My Groups Post"
                                  data={question}
                                  login={this.props.loginDetails}
                                  // GoToSubjectQuestionList={
                                  //   this.GoToSubjectQuestionList
                                  // }
                                  // BookmarkJoin={this.JoinBookmarkStar}
                                  // handleShowAnswer={this.handleShowAnswer}
                                  // deleteQuestionCard={
                                  //   this.deleteQuestionCards
                                  // }
                                  // getPostOverall={this.getPosts}
                                  getResourcePost={this.getResourcePostbyImages}
                                  // status={"catalyst"}
                                  // type="My Groups Post"
                                  // data={question}
                                  // login={this.props.loginDetails}
                                  // GoToSubjectQuestionList={
                                  //   this.GoToSubjectQuestionList
                                  // }
                                  // BookmarkJoin={this.JoinBookmarkStar}
                                  // handleShowAnswer={this.handleShowAnswer}
                                  // deleteQuestionCard={
                                  //   this.deleteQuestionCards
                                  // }
                                  // getPostOverall={this.getPosts}
                                />
                              </React.Fragment>
                            </>
                          ))}
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                        <ResourceMediaFile
                          moveToMediaPage={this.moveToMediaPage}
                          subjectId={this.state.subjectId}
                          resourceType={2}
                          resourceFilterType={this.state.resourceFilterType}
                          playList={this.state.opencatalystPageState}
                          playlistId={this.state.playlistId}
                        />
                      </Grid> */}
                    </Grid>
                    {!this.props.resourcePostLoader &&
                      this.props.resourcePosts !== undefined &&
                      this.props.resourcePosts.length === 0 && (
                        <div class="no-data">
                          <center>
                            <p>{t("No Video Posts Available")}</p>
                          </center>
                        </div>
                      )}
                  </BlockUi>

                  <div className="d-flex align-items-center justify-content-center mt-3">
                    {
                      <Pagination
                        itemsCountPerPage={10}
                        activePage={this.state.activePlayListVideoPostPage}
                        totalItemsCount={this.props.resourcePostTotalCount}
                        pageRangeDisplayed={5}
                        onChange={this.getResourcePostListVideos.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    }
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </>
      </>
    );
  }
  handleChangeResource = (type) => {
    this.setState({
      resourceFilterType: type,
      apiFilterKey: "newest",
      optionName: "Sort by Descending Order",
      optionIndex: 0,
      searchData: "",
      selectedCountryList: [],
    });
    var value = {
      groupId: this.state.subjectId,
      resourceType: this.state.resourceType,
      page: 0,
      type: type,
      filterKey: this.state.apiFilterKey,
      searchTags: "",
    };
    this.props.getResourcePostList(value);
  };
  backToPlayList = () => {
    this.setState({
      opencatalystPageState: false,
      playListNeed: true,
      // selectedTagList: [],
      // filterId: [],
      searchData: "",
      optionIndex: 0,
      optionName: "Sort by Descending Order",
    });
  };
  viewPlayListVideoPost = (subjectId, playlistId) => {
    // this.props.getResourceByPlaylist(subjectId, playlistId, 0);
    this.setState({
      opencatalystPageState: true,
      playlistId: playlistId,
      playListNeed: false,
      apiFilterKey: "newest",
    });
    let value = {
      groupId: this.state.subjectId,
      resourceType: this.state.resourceType,
      page: 0,
      filterKey: "newest",
      playlistId: playlistId,
      searchTags: "",
    };
    // let tags = {
    //   subjectId: this.state.subjectId,
    //   playlistId: playlistId,
    // };
    // this.props.getResourcePostTags(tags);
    this.props.getResourcePostList(value);
  };
  getResourcePostListVideos = (pageNo) => {
    // if (pageNo == 0) {
    //   playListpage = 0;
    // } else {
    //   playListpage = pageNo - 1;
    // }
    this.setState({
      activePlayListVideoPostPage: pageNo,
    });
    // this.props.getResourceByPlaylist(
    //   this.state.subjectId,
    //   this.state.playlistId,
    //   pageNo
    // );
    let value = {
      groupId: this.state.subjectId,
      resourceType: this.state.resourceType,
      page: pageNo - 1,
      filterKey: this.state.apiFilterKey,
      playlistId: this.state.playlistId,
      searchTags: "",
    };
    this.props.getResourcePostList(value);

    // this.setState({
    //   activePlayListVideoPostPage: pageNo,
    // });
  };
  setGridValue = (value) => {
    if (value === 12) {
      this.setState({
        gridValue: value,
        groupInfoCardStatus: true,
      });
    } else {
      this.setState({
        gridValue: value,
        groupInfoCardStatus: false,
      });
    }
  };
  removedSentData = () => {
    this.props.getAllMembersDetails(this.state.subject_id, "all", 0, false); //Old list
  };
  onOpenMessage = () => {
    this.setState({ openMessageState: !this.state.openMessageState });
  };
  askAQuestionPage = () => {
    var id = this.props.match.params.subjectId;
    this.props.history.push(`/ask-question/${id}/${this.state.subjectName}`);
  };
  openParticipantsPage() {
    this.setState({
      openFeedPageState: false,
      openMemberPageState: false,
      openApplyPageState: false,
      openParticipantsPageState: true,
      openInspirationPageState: false,
      openVideoPageState: false,
      openDocumentPageState: false,
      openSchedulePageState: false,
      openFAQPageState: false,
      openTermsPageState: false,
      openDescriptionPageState: false,
      activeChallenfeApplyPage: 1,
      openResultsPageState: false,
    });

    this.getChallengeApplied(1);
  }

  openSchedulePage() {
    this.setState({
      openFeedPageState: false,
      openMemberPageState: false,
      openApplyPageState: false,
      openParticipantsPageState: false,
      openInspirationPageState: false,
      openVideoPageState: false,
      openDocumentPageState: false,
      openSchedulePageState: true,
      openFAQPageState: false,
      openTermsPageState: false,
      openDescriptionPageState: false,
      openResultsPageState: false,
    });
  }

  openFAQPage() {
    this.setState({
      openFeedPageState: false,
      openMemberPageState: false,
      openApplyPageState: false,
      openParticipantsPageState: false,
      openInspirationPageState: false,
      openVideoPageState: false,
      openDocumentPageState: false,
      openSchedulePageState: false,
      openFAQPageState: true,
      openTermsPageState: false,
      openDescriptionPageState: false,
      openResultsPageState: false,
    });
  }

  openTermsPage() {
    this.setState({
      openFeedPageState: false,
      openMemberPageState: false,
      openApplyPageState: false,
      openParticipantsPageState: false,
      openInspirationPageState: false,
      openVideoPageState: false,
      openDocumentPageState: false,
      openSchedulePageState: false,
      openFAQPageState: false,
      openTermsPageState: true,
      openDescriptionPageState: false,
      openResultsPageState: false,
    });
  }
  openDescriptionPage() {
    this.setState({
      openFeedPageState: false,
      openMemberPageState: false,
      openApplyPageState: false,
      openParticipantsPageState: false,
      openInspirationPageState: false,
      openVideoPageState: false,
      openDocumentPageState: false,
      openSchedulePageState: false,
      openFAQPageState: false,
      openTermsPageState: false,
      openDescriptionPageState: true,
      openResultsPageState: false,
    });
  }

  openResultsPage() {
    this.setState({
      openFeedPageState: false,
      openMemberPageState: false,
      openApplyPageState: false,
      openParticipantsPageState: false,
      openInspirationPageState: false,
      openVideoPageState: false,
      openDocumentPageState: false,
      openSchedulePageState: false,
      openFAQPageState: false,
      openTermsPageState: false,
      openDescriptionPageState: false,
      openResultsPageState: true,
    });
  }

  getChallengeApplied(pageNo) {
    if (pageNo == 0) {
      cpage = 0;
    } else {
      cpage = pageNo - 1;
    }
    this.props.getChallengeApplyList(cpage, this.state.subjectId);

    this.setState({
      activeChallenfeApplyPage: pageNo,
    });
  }
  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    console.log("This groups or", this.props.grouporeventFiles);
  }
  async handleClickMyList(name, id, index) {
    let count = this.state.postBymeLists.length;
    var friendsarray = this.state.filterTagList;

    if (this.state.postBymeLists.length > 0) {
      tags = this.state.postBymeLists.filter((element) => element.tagId !== id);
      await this.setState({ postBymeLists: tags });

      for (let i = 0; i < friendsarray.length; i++) {
        if (friendsarray[i].tagId == id) {
          friendsarray[i].myPostColor = "false";
        }
      }
      this.setState({ filterTagList: friendsarray });
    }

    if (count == tags.length) {
      tags.push({ tagName: name, tagId: id, myPostColor: "true" });
      await this.setState({ postBymeLists: tags });
      let friend = [];
      for (let i = 0; i < friendsarray.length; i++) {
        if (friendsarray[i].tagId == id) {
          friendsarray[i].myPostColor = "true";
        }
      }
      this.setState({ filterTagList: friendsarray });
    } else {
    }
    this.calling(tags);
  }
  calling(list) {
    var data = [];
    for (var i = 0; i < list.length; i++) {
      data.push(list[i].tagId);
    }

    if (tags.length != 0) {
      if (this.state.openInspirationPageState) {
        this.props.getResourceInspirationList(
          ipage,
          this.state.subjectId,
          "tags",
          data
        );
      } else if (this.state.openVideoPageState) {
        this.props.getResourceVideoList(
          vpage,
          this.state.subjectId,
          "tags",
          data
        );
      } else if (this.state.openDocumentPageState) {
        this.props.getResourceDocumentList(
          dpage,
          this.state.subjectId,
          "tags",
          data
        );
      }
    } else {
      if (this.state.openInspirationPageState) {
        this.props.getResourceInspirationList(
          ipage,
          this.state.subjectId,
          "empty",
          tagsList
        );
      } else if (this.state.openVideoPageState) {
        this.props.getResourceVideoList(
          vpage,
          this.state.subjectId,
          "empty",
          tagsList
        );
      } else if (this.state.openDocumentPageState) {
        this.props.getResourceDocumentList(
          dpage,
          this.state.subjectId,
          "empty",
          tagsList
        );
      }
    }
  }
  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      this.wrapperRef.current != null &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        showMenu: false,
      });
    }
  }
  showPlusMenu = (event) => {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };
  // showPlusMenu(event) {
  //   document.addEventListener("mousedown", this.handleClickOutside);
  //   this.setState({
  //     showPlusMenu: !this.state.showPlusMenu,
  //   });
  // }
  async optionType(e) {
    if (e == "0") {
      this.getMyPosts(this.props.tagDatas);
      this.setState({
        showPlusMenu: false,
        tagsListStatus: true,
      });
      await this.setState({
        showPlusMenu: false,
      });
    } else {
      await this.setState({
        showPlusMenu: false,
        tagsListStatus: false,
        filterTagList: [],
      });
      if (this.state.openInspirationPageState) {
        this.props.getResourceInspirationList(
          ipage,
          this.state.subjectId,
          "empty",
          tagsList
        );
      } else if (this.state.openVideoPageState) {
        this.props.getResourceVideoList(
          vpage,
          this.state.subjectId,
          "empty",
          tagsList
        );
      } else if (this.state.openDocumentPageState) {
        this.props.getResourceDocumentList(
          dpage,
          this.state.subjectId,
          "empty",
          tagsList
        );
      }
    }
  }
  getMyPosts(tagList) {
    tags.length = 0;
    var friendData = [];
    for (let i = 0; i < tagList.length; i++) {
      friendData.push({
        tagId: tagList[i].tagId,
        tagName: tagList[i].tagName,
        myPostColor: "false",
      });
    }
    this.setState({ filterTagList: friendData });
  }
  GoToSubjectQuestionList(id, type) {
    this.props.getOverallPostList(tpage, this.state.subjectId);
    if (id !== subjectId) {
      this.setState({
        showAnswer: false,
        subjectId: id,
      });
      typeQuestionTop = "newest";
      firstTimeLoad = true;
      secondTimeLoad = true;
      subjectId = id;
    }
  }
  openInspirationPage() {
    this.props.getResourceInspirationList(
      // ipage,
      0,
      this.state.subjectId,
      "empty",
      tagsList
    );
    this.setState({
      myGroupState: false,
      openMemberPageState: false,
      openApplyPageState: false,
      openParticipantsPageState: false,
      openInspirationPageState: true,
      openVideoPageState: false,
      openDocumentPageState: false,
      openSchedulePageState: false,
      openFAQPageState: false,
      openTermsPageState: false,
      openDescriptionPageState: false,
      openConnection: false,
      openPost: false,
      openAbout: false,
      filterTagList: [],
      openDoc: false,
      openVideo: false,
      openImage: true,
    });
    //  tagsList.length=0
  }

  openVideoPage() {
    this.props.getResourceVideoList(0, this.state.subjectId, "empty", tagsList);
    this.setState({
      myGroupState: false,
      openMemberPageState: false,
      openApplyPageState: false,
      openParticipantsPageState: false,
      openInspirationPageState: false,
      openVideoPageState: true,
      openDocumentPageState: false,
      openSchedulePageState: false,
      openFAQPageState: false,
      openTermsPageState: false,
      openDescriptionPageState: false,
      openConnection: false,
      openPost: false,
      openAbout: false,
      filterTagList: [],
      openDoc: false,
      openVideo: true,
      openImage: false,
    });
    //  tagsList.length=0
  }
  openDocumentPage() {
    this.props.getResourceDocumentList(
      0,
      this.state.subjectId,
      "empty",
      tagsList
    );
    this.setState({
      myGroupState: false,
      openMemberPageState: false,
      openApplyPageState: false,
      openParticipantsPageState: false,
      openInspirationPageState: false,
      openVideoPageState: false,
      openDocumentPageState: true,
      openSchedulePageState: false,
      openFAQPageState: false,
      openTermsPageState: false,
      openDescriptionPageState: false,
      openConnection: false,
      openPost: false,
      openAbout: false,
      openDoc: true,
      openVideo: false,
      openImage: false,

      filterTagList: [],
    });
    // tagsList.length=0
  }

  gotoposts() {
    this.setState({ openPost: true, openAbout: false, openConnection: false });
  }

  gotoabout() {
    this.setState({ openAbout: true, openPost: false, openConnection: false });
  }

  gotoconnections() {
    if (this.state.status === "protected" && !this.state.userJoin) {
      toast.warning(
        `${this.props.t("Community member's only can see the connections")}`
      );
    } else {
      this.setState({
        openFormCommunityPage: false,
        openPost: false,
        openAbout: false,
        openConnection: true,
      });
    }
  }
  openFormCommunityPage = () => {
    this.setState({
      openFormCommunityPage: true,
      openConnection: false,
      openPost: true,
    });
  };

  giveRequest = (subId, type) => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      if (type !== "requested") {
        Swal.fire({
          title: this.props.t("Do you want to join the community ?"),
          showCancelButton: true,
          confirmButtonText: this.props.t("request"),
          cancelButtonText: this.props.t("cancel"),
          reverseButtons: true,
          icon: "warning",
        }).then((result) => {
          if (result.value) {
            Swal.fire({
              icon: "success",
              title: "Requested successfully",
              showConfirmButton: true,
            }).then((result) => {
              let data = {
                subjectId: subId,
                type: "request",
              };

              MyYuukke.requestOrAcceptOrReject(data)
                .then((response) => {
                  this.setState({
                    joinType: "requested",
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          }
        });
      }
    }
  };
  getMemberLists = (subId, memberShipPrice, userName, userId) => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      // let member = {
      //   // date: date,
      //   groupsOrEvents: subId,
      // };
      //  MyYuukke.getMemberList(member)
      //   .then((response) => {
      //     var result = response.data;
      //     if (result.data && result.data.length > 0) {
      //       this.setState({ MemberList: 1 });
      //     } else {
      Swal.fire({
        title:
          this.state.status === "public"
            ? this.props.t("Do you want to join the community ?")
            : this.props.t("Do you want to request the community ?"),
        showCancelButton: true,
        confirmButtonText: this.props.t(
          this.state.status === "public" ? "confirm" : "request"
        ),
        cancelButtonText: this.props.t("cancel"),
        reverseButtons: true,
        icon: "warning",
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title:
              this.state.status === "public"
                ? "Joined Successfully"
                : "Requested successfully",
            showConfirmButton: true,
            icon: "success",
          }).then((result) => {
            if (this.state.status === "public") {
              this.saveOrUpdateMemberList(userName, subId, memberShipPrice);
              // this.props.getAllSubjectList("Groups", 0);
              this.props.getAllUserCommunityList();
              // this.props.getAllMyGroupEvents("Groups", 0);
              //  alert()
            } else {
              this.subjectRequest();
            }
          });
        }
      });
    }
    // })
  };

  subjectRequest() {
    let subjectRequest = {
      createrId: this.props.userId,
      groupId: this.props.subId,
      requestStatus: "requested",
    };
    MyYuukke.RequestSubject(subjectRequest)
      .then((response) => {
        if (response.data.status === 1) {
          toast.success(`${this.props.t("Request sent successfully")}`);
        } else if (response.data.status === -1) {
          toast.warn(`${this.props.t("Request already sent")}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  saveOrUpdateMemberList(userName, subId, memberShipPrice) {
    let date = moment().format("YYYY-MM-DD");
    let memberList = {
      userName: userName,
      groupsOrEvents: subId,
      paymentAmount: memberShipPrice,
      joinDate: date,
    };
    MyYuukke.saveMemberList(memberList)
      .then((response) => {
        if (this.state.communityTypeName === "Forum Community") {
          var gName = this.state.subjectName.trim();
          gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
          this.props.history.push(
            `/${this.props.workspaceType}/forum-community/${this.state.subject_id}/${gName}`,
            { from: window.location.pathname }
          );
        } else {
          this.setState({
            joinedGroupState: true,
          });
          this.props.getOverallPostList(0, subId);
        }

        this.props.getAllMembersDetails(
          this.state.groupGroupId,
          "all",
          0,
          false
        ); //Old list
      })
      .catch((error) => {
        console.log(error);
      });
  }
  editGroupsEvents(groupId) {
    let Edit = groupId;
    var subjectId = {
      subjectId: Edit,
    };
    ProfileService.editGroupsEventsV2(subjectId)
      .then((response) => {
        var result = response.data.data;
        this.setState({
          userStatus: result.userStatus,
          joinType: result.joinType,
          groupWorkspace: result.workSpaceType,
          careerLabType: result.careerLabType,
          memberListCount: result.memberListCount,
          subjectName: result.subjectName,
          subject_id: result.subjectId,
          communityTypeName: result.communityTypeName,
          communityTypeId: result.communityTypeId,
          tagListId: result.tagsValue,
          fileType: result.fileType,
          status: result.status,
          communityType: result.communityType,
          userId: result.createdBy,
          memberShipType: result.memberShipType,
          memberShipPrice: result.memberShipPrice,
          subjectDescription: result.subjectDescription,
          grouporeventFiles: result.grouporeventFiles,
          createdDate: result.createdDate,
          expiry: result.isExpiry,
          //type:result.type,
          expiryDate: moment(result.expiryDate).format("yyyy-MM-DD"),
          //   expiryDateAboutPage:result.expiryDate,
          eventLocation: result.eventLocation,
          endTime: result.endTime,
          startTime: result.startTime,
          startDate: moment(result.startDate, "DD-MM-YYYY hh:mm:ss").format(
            "yyyy-MM-DD"
          ),
          subjectFaq: result.subjectFaq,
          subjectCondition: result.subjectCondition,
          subjectSchedule: result.subjectSchedule,
          userApply: result.userAply,
          challengeId: result.challengeId,
          userJoin: result.join,
          challengesCategory_value: result.challengesCategory_value,
          resourceType: result.resourceType ? result.resourceType : 0,
          // selectedTagList: [],
          // filterId: [],
          playListNeed: false,
          searchData: "",
          filterYear: "",
          apiFilterKey: "newest",
          optionName: "Sort by Descending Order",
          resourceFilterType: "image",
          // selectedTagId:
        });
        // if (this.props.loginDetails.uId === result.createdBy) {
        //   let data = {
        //     page: 0,
        //     subjectId: Edit,
        //   };
        //   this.props.getGroupRequestList(data, false);
        // }
        if (result.resourceType === 1) {
          this.setState({
            playListNeed: true,
          });
        }
        // for (let i = 0; i < this.state.memberListCount.length; i++) {
        //   if (
        //     this.props.loginDetails.uId == this.state.memberListCount[i].userId
        //   ) {
        if (result.join === true) {
          this.setState({
            joinedGroupState: true,
          });
        }
        if (result.join && result.communityTypeName === "Forum Community") {
          var gName = result.subjectName.trim();
          gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
          this.props.history.push(
            `/${this.props.workspaceType}/forum-community/${result.subjectId}/${gName}`,
            { from: window.location.pathname }
          );
        } else if (
          result.communityTypeName === "Forum Community" &&
          !result.join
        ) {
          this.setState({
            formPage: true,
            openFormCommunityPage: true,
          });
        }

        // }
        // }
        if (result.status == "challenges") {
          this.setState({
            myGroupState: false,
            openMemberPageState: false,
            openApplyPageState: false,
            // openParticipantsPageState: false,
            openInspirationPageState: false,
            openVideoPageState: false,
            openDocumentPageState: false,
            //  openSchedulePageState: false,
            // openFAQPageState: false,
            //  openTermsPageState: false,
            // openDescriptionPageState: true,
          });
          if (this.props.participantPage) {
            this.setState({
              openParticipantsPageState: true,
              openSchedulePageState: false,
              openFAQPageState: false,
              openTermsPageState: false,
              openDescriptionPageState: false,
              openResultsPageState: false,
            });
          } else {
            this.setState({
              openParticipantsPageState: false,
              openSchedulePageState: false,
              openFAQPageState: false,
              openTermsPageState: false,
              openDescriptionPageState: true,
              openResultsPageState: false,
            });
          }
        } else if (result.status == "resource") {
          var value = {};
          if (result.resourceType !== 1) {
            if (result.resourceType === 0 || result.resourceType === null) {
              value = {
                groupId: result.subjectId,
                resourceType: result.resourceType ? result.resourceType : 0,
                page: 0,
                type: "image",
                filterKey: this.state.apiFilterKey,
                searchTags: "",
              };
            } else {
              value = {
                groupId: result.subjectId,
                resourceType: result.resourceType ? result.resourceType : 0,
                page: 0,
                filterKey: this.state.apiFilterKey,
                searchTags: "",
              };
            }

            this.props.getResourcePostList(value);
            // let tags = {
            //   subjectId: result.subjectId,
            // };
            // this.props.getResourcePostTags(tags);
          }

          this.setState({
            openResourcePage: true,
            resourcePageNo: 1,
            myGroupState: false,
            openMemberPageState: false,
            openApplyPageState: false,
            openParticipantsPageState: false,
            // openInspirationPageState: true,
            openVideoPageState: false,
            openInspirationPageState:
              this.props.fromWhichCard == "special-resource" ? false : true,
            // openVideoPageState: this.props.fromWhichCard == "special-resource"?true:false,
            openDocumentPageState: false,
            openSchedulePageState: false,
            openFAQPageState: false,
            openTermsPageState: false,
            openDescriptionPageState: false,
            openResultsPageState: false,
          });
          if (result.resourceType === 2) {
            this.setState({
              openVideoPageState: true,
              catalystTalkPage: false,
              openInspirationPageState: false,
              specialResource: true,
            });
            // this.props.getResourceVideoList(0, result.subjectId, "empty", []);
          } else if (result.resourceType === 1) {
            this.setState({
              catalystTalkPage: true,
            });
            this.props.getResourcePlayList(result.subjectId);
          } else if (this.props.fromWhichCard == "special-resource") {
            if (result.resourceType === 0 || result.resourceType === null) {
              this.setState({
                openVideoPageState: true,
                catalystTalkPage: false,
                specialResource: true,
              });
            }
            // this.props.getResourceVideoList(
            //   vpage,
            //   this.state.subjectId,
            //   "empty",
            //   tagsList
            // );
          } else if (
            result.subjectId === 1000 ||
            result.subjectId === 1001 ||
            result.subjectId === 1002
          ) {
            this.setState({
              openVideoPageState: true,
              catalystTalkPage: false,
              specialResource: true,
              openInspirationPageState: false,
              openDocumentPageState: false,
              status: "",
            });
            // this.props.getResourceVideoList(
            //   vpage,
            //   this.state.subjectId,
            //   "empty",
            //   tagsList
            // );
          }
        } else {
          this.setState({
            myGroupState: true,
            openMemberPageState: false,
            openApplyPageState: false,
            openParticipantsPageState: false,
            openInspirationPageState: false,
            openVideoPageState: false,
            openDocumentPageState: false,
            openSchedulePageState: false,
            openFAQPageState: false,
            openTermsPageState: false,
            openDescriptionPageState: false,
            openResultsPageState: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getResourcePostbyImages = (pageNo) => {
    this.setState({
      resourcePageNo: pageNo - 1,
      activePage: pageNo,
    });
    var value = {};
    if (this.state.resourceType === 0) {
      const id = this.state.selectedCountryList.map((item) => item.id);

      value = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: pageNo - 1,
        type: this.state.resourceFilterType,
        filterKey: this.state.apiFilterKey,
        searchTags: "",
        countryIds: id,
      };
    } else {
      value = {
        groupId: this.state.subjectId,
        resourceType: this.state.resourceType,
        page: pageNo - 1,
        filterKey: this.state.apiFilterKey,
        searchTags: "",
      };
    }
    this.props.getResourcePostList(value);

    // if (pageNo == 0) {
    //   ipage = 0;
    // } else {
    //   ipage = pageNo - 1;
    // }

    // this.props.getResourceInspirationList(
    //   ipage,
    //   this.state.subjectId,
    //   "empty",
    //   tagsList
    // );

    // this.setState({
    //   activeImagePostPage: pageNo,
    // });
  };
  getResourcePostbydoc(pageNo) {
    if (pageNo == 0) {
      dpage = 0;
    } else {
      dpage = pageNo - 1;
    }

    this.props.getResourceDocumentList(
      dpage,
      this.state.subjectId,
      "empty",
      tagsList
    );

    this.setState({
      activeDocPostPage: pageNo,
    });
  }

  getResourcePostbyVideos(pageNo) {
    if (pageNo == 0) {
      vpage = 0;
    } else {
      vpage = pageNo - 1;
    }

    this.props.getResourceVideoList(
      vpage,
      this.state.subjectId,
      "empty",
      tagsList
    );

    this.setState({
      activeVideoPostPage: pageNo,
    });
  }
  // movetogroups() {
  //   this.props.history.push(`/${this.props.workspaceType}/groupsList`);
  // }

  movetomygroups = () => {
    console.log("from", this.props.location?.state?.from);
    if (this.props.location?.state?.from) {
      this.props.history.push(this.props.location?.state?.from);
    } else {
      this.props.history.push(`/${this.props.workspaceType}/groupsList`);
    }
  };

  movetolearningapp() {
    // this.props.history.push(`/learningApp`);
    this.props.history.push(`/${this.props.workspaceType}/learning-hub`);
  }
  movetochallenges() {
    this.props.history.push(`/${this.props.workspaceType}/career-lab`);
    // this.props.history.push(`/challenges`);
  }
  moveToMediaPage = (id, type) => {
    this.props.history.push(
      `/${this.props.workspaceType}/resource-post/${id}/${type}`
    );
  };
  handleCountryMultiSelect = (data) => {
    this.setState({
      selectedCountryList: data,
    });
    const id = data.map((item) => item.id);
    var item = {
      groupId: this.state.subjectId,
      resourceType: this.state.resourceType,
      page: 0,
      type: this.state.resourceFilterType,
      filterKey: this.state.apiFilterKey,
      searchTags: this.state.searchData,
      countryIds: id,
    };
    this.props.getResourcePostList(item);
  };
  showFriendsProfile = (userId) => {
    this.props.getNavigationType(1);
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  };
}

const mapStateToProps = function (state) {
  return {
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    totalItemsLists: state.SubjectQuestion.totalItemsList,
    PostmessageLists: state.SubjectQuestion.postLists,
    SubjectquestionList: state.SubjectQuestion.list,
    OverallLists: state.SubjectQuestion.overallList,
    SubjectquestTotalitems: state.SubjectQuestion.totalItems,
    QandAquestionList: state.topquestList.list,
    QandAquestTotalitems: state.topquestList.totalItems,
    updatedKeyname: state.topquestList.updatedKeyname,
    topQuestions: state.mylibraryquestion.list,
    solvedTotalitems: state.mylibraryquestion.totalItems,
    wallList: state.dashboard.dashboardList,
    loginDetails: state.loginDetailsApi.loginDetails,
    allMembersList: state.subjects.allMembersList,
    subjectRequestsList: state.subjects.subjectRequestsList,
    tagList: state.commonApi.taglist,
    getResourcePostbyImage: state.subjects.getResourcePostbyImage,
    resourceImagetotalItems: state.subjects.resourceImagetotalItems,
    getResourcePostbyVideo: state.subjects.getResourcePostbyVideo,
    resourceVideototalItems: state.subjects.resourceVideototalItems,
    getResourcePostbyDoc: state.subjects.getResourcePostbyDoc,
    resourceDoctotalItems: state.subjects.resourceDoctotalItems,
    SubQuestionloading: state.subjects.loading,
    tagDatas: state.commonApi.taglist.data,
    getChallengeAppliedList: state.subjects.getChallengeAppliedList,
    challengesAppliedtotalItems: state.subjects.challengesAppliedtotalItems,
    resourcePlayList: state.subjects.resourcePlayList,
    resourcePlayListDataPage: state.subjects.resourcePlayListDataPage,
    resourcePlayListData: state.subjects.resourcePlayListData,
    resourceloading: state.subjects.resourceloading,
    resourceTagList: state.subjects.resourceTagList,
    resourcePostLoader: state.subjects.resourcePostLoader,
    resourcePosts: state.subjects.resourcePosts,
    resourcePostTotalCount: state.subjects.resourcePostTotalCount,
    countyNameList: state.commonApi.countryName,
    groupRequestList: state.subjects.groupRequestList,
    totalCommunityUser: state.subjects.totalCommunityUser,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getChallengeApplyList: (pageNo, subId) =>
      dispatch(getChallengeApplyList(pageNo, subId)),
    getResourceInspirationList: (pageNo, subId, type, tagList) =>
      dispatch(getResourceInspirationList(pageNo, subId, type, tagList)),
    getOverallPostList: (pageNo, subId) =>
      dispatch(getOverallPostList(pageNo, subId)),
    getAllMyGroupEvents: (type, page) =>
      dispatch(getAllMyGroupEvents(type, page)),
    getAllMembersDetails: (id, type, page, status) =>
      dispatch(getAllMembersDetails(id, type, page, status)),
    getSubjectsRequestList: (groupId) =>
      dispatch(getSubjectsRequestList(groupId)),
    getAllSubjectList: (type, page) => dispatch(getAllSubjectList(type, page)),
    getResourceVideoList: (pageNo, subId, type, tagList) =>
      dispatch(getResourceVideoList(pageNo, subId, type, tagList)),
    getResourceDocumentList: (pageNo, subId, type, tagList) =>
      dispatch(getResourceDocumentList(pageNo, subId, type, tagList)),
    getAllUserCommunityList: () => dispatch(getAllUserCommunityList()),
    getResourcePlayList: (subId) => dispatch(getResourcePlayList(subId)),
    getResourceByPlaylist: (subId, playListId, page) =>
      dispatch(getResourceByPlaylist(subId, playListId, page)),
    getResourcePostTags: (id) => dispatch(getResourcePostTags(id)),
    getResourcePostList: (data) => dispatch(getResourcePostList(data)),
    startResourceLoader: () => dispatch(startResourceLoader()),
    getGroupRequestList: (data, type) =>
      dispatch(getGroupRequestList(data, type)),
    getNavigationType: (type) => dispatch(getNavigationType(type)),
    needPhotVerification: (type) => dispatch(needPhotVerification(type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(GroupDescription)));
