import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as APPCONFIG from "../../../config/app-config.js";
import { Chip } from "@material-ui/core";
import { Button, Card, Form, Modal } from "react-bootstrap";
import MessagesFileOption from "../../Community/Subjects/MessagesFileOption";
import MyYuukke from "../../../api/MyYuukke";
import { toast } from "react-toastify";
import { updateWorkSpaceOptionTypes } from "../../../redux/actions/commonApiActions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { MdOutlineAttachFile } from "react-icons/md";

class WorkSpaceUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventOption: false,
      catalystTalks: false,
      bizFeatures: false,
      careerLab: false,
      imageFile: null,
      addImage: false,
      popupStatus: false,
      fileArray: null,
      workspace: false,
      marketPlace: false,
      directory: false,
      selectedImage: null,
      selectedMobileImage: null,
      mobileLogo: false,
      mobileImageFile: null,
    };
    this.fileUploadPopup = this.fileUploadPopup.bind(this);
  }
  onChangeStatus = (e) => {
    console.log("target", e);
    // this.setState({ [e.target.name]: e.targe.value });
  };
  componentDidMount() {
    let options = this.props.workSpaceOption;
    if (options?.slug) {
      this.setState({
        eventOption: options.syncPublicEvents,
        catalystTalks: options.catalystTalks,
        bizFeatures: options.bizFeatures,
        careerLab: options.careerLab,
        imageFile: options.logo,
        directory: options.directoryMenu,
        marketPlace: options.marketPlaceMenu,
        workspace: options.workSpaceMenu,
        mobileImageFile: options.mobileLogo,
      });
    }
  }
  removeImageFile = () => {
    this.setState({
      imageFile: null,
    });
  };
  removeMobileImageFile = () => {
    this.setState({
      mobileImageFile: null,
    });
  };
  backtoList = () => {
    this.props.history.push(`/${this.props.workspaceType}/home`);
  };
  handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        `${APPCONFIG.BASE_URL}yuukke-workspace/?id=${this.props.workspaceType}`
      );

      toast.success("Copied");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  render() {
    const { t } = this.props;
    console.log("workSpaceOption", this.props.workSpaceOption);
    return (
      <>
        <div className="privacyCardWrapper">
          <div className="privacyCardInnerWrapper d-flex al ign-items-center justify-content-between">
            <h5 className="listHomeTitle d-flex">
              {t("Update your workspace details here")}
              <p
                className="ml-2 text-primary text-decoration-underline c-pointer f-12 font-weight-inherit"
                onClick={() => this.handleCopy()}
                style={{ textDecoration: "underline" }}
              >
                Copy Workspace Link
              </p>
            </h5>
            <p
              onClick={this.backtoList}
              className="bg-color"
              style={{ cursor: "pointer" }}
            >
              {" "}
              <ArrowBackIcon className="mr-2" />
              Back{" "}
            </p>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                    }}
                  >
                    {t(
                      "Do you want to show yuukke  platform public event in your workspace?"
                    )}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="eventOption"
                        id={"eventOption"}
                        checked={this.state.eventOption}
                        onClick={() =>
                          this.setState({
                            eventOption: !this.state.eventOption,
                          })
                        }
                      />
                      <label className="label" htmlFor={"eventOption"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                    }}
                  >
                    {t("Do you want to show catalystTalks?")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="catalystTalks"
                        id={"catalystTalks"}
                        checked={this.state.catalystTalks}
                        onClick={() =>
                          this.setState({
                            catalystTalks: !this.state.catalystTalks,
                          })
                        }
                      />
                      <label className="label" htmlFor={"catalystTalks"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                    }}
                  >
                    {t("Do you want to show bizFeatures ?")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="bizFeatures"
                        id={"bizFeatures"}
                        checked={this.state.bizFeatures}
                        onClick={() =>
                          this.setState({
                            bizFeatures: !this.state.bizFeatures,
                          })
                        }
                      />
                      <label className="label" htmlFor={"bizFeatures"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                    }}
                  >
                    {t("Do you want to show careerLab ?")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="careerLab"
                        id={"careerLab"}
                        checked={this.state.careerLab}
                        onClick={() =>
                          this.setState({ careerLab: !this.state.careerLab })
                        }
                      />
                      <label className="label" htmlFor={"careerLab"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                    }}
                  >
                    {t("Do you want to show Business Directory ?")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="directory"
                        id={"directory"}
                        checked={this.state.directory}
                        onClick={() =>
                          this.setState({ directory: !this.state.directory })
                        }
                      />
                      <label className="label" htmlFor={"directory"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                    }}
                  >
                    {t("Do you want to show MarkePlace ?")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="marketPlace"
                        id={"marketPlace"}
                        checked={this.state.marketPlace}
                        onClick={() =>
                          this.setState({
                            marketPlace: !this.state.marketPlace,
                          })
                        }
                      />
                      <label className="label" htmlFor={"marketPlace"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                    }}
                  >
                    {t("Do you want to show yuukke workspace menu option ?")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                    }}
                  >
                    {t("Choose yes or no")}
                  </p>
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="workspace"
                        id={"workspace"}
                        checked={this.state.workspace}
                        onClick={() =>
                          this.setState({ workspace: !this.state.workspace })
                        }
                      />
                      <label className="label" htmlFor={"workspace"}>
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                    }}
                  >
                    {t("WorkSpace Web Logo")}
                  </p>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    {this.state.selectedImage ? (
                      this.state.selectedImage.map((file, index) => (
                        <div key={file.name}>
                          <Chip
                            icon={<MdOutlineAttachFile />}
                            className="fileChip"
                            label={file.name}
                            size="small"
                            onDelete={() => this.removeFile(index)}
                            variant="outlined"
                          />
                          <img
                            style={{
                              margin: "8px",
                              width: "auto",
                              height: "80px",
                            }}
                            src={URL.createObjectURL(file)}
                          ></img>
                        </div>
                      ))
                    ) : this.state.imageFile ? (
                      <div className="d-flex">
                        <Chip
                          icon={<MdOutlineAttachFile />}
                          className="fileChip"
                          label="Work Space Logo"
                          size="small"
                          onDelete={() => this.removeImageFile()}
                          variant="outlined"
                        />
                        {
                          <img
                            style={{
                              margin: "8px",
                              width: "auto",
                              height: "80px",
                            }}
                            src={`${APPCONFIG.API_URL}auth/image/${this.state.imageFile}`}
                          ></img>
                        }
                      </div>
                    ) : (
                      <div style={{ color: "gray" }}>
                        {" "}
                        <button onClick={() => this.closPopup(false)}>
                          Upload new logo
                        </button>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className="privacyCardInnerWrapper">
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                    }}
                  >
                    {t("WorkSpace Mobile Logo")}
                  </p>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div
                    style={{
                      display: "inline-flex",
                      paddingRight: "2px",
                      float: "right",
                    }}
                  >
                    {this.state.selectedMobileImage ? (
                      this.state.selectedMobileImage.map((file, index) => (
                        <div key={file.name}>
                          <Chip
                            icon={<MdOutlineAttachFile />}
                            className="fileChip"
                            label={file.name}
                            size="small"
                            onDelete={() => this.mobileRemoveFile(index)}
                            variant="outlined"
                          />
                          <img
                            style={{
                              margin: "8px",
                              width: "auto",
                              height: "80px",
                            }}
                            src={URL.createObjectURL(file)}
                          ></img>
                        </div>
                      ))
                    ) : this.state.mobileImageFile ? (
                      <div className="d-flex">
                        <Chip
                          icon={<MdOutlineAttachFile />}
                          className="fileChip"
                          label="Work Space Logo"
                          size="small"
                          onDelete={() => this.removeMobileImageFile()}
                          variant="outlined"
                        />
                        {
                          <img
                            style={{
                              margin: "8px",
                              width: "auto",
                              height: "80px",
                            }}
                            src={`${APPCONFIG.API_URL}auth/image/${this.state.mobileImageFile}`}
                          ></img>
                        }
                      </div>
                    ) : (
                      <div style={{ color: "gray" }}>
                        {" "}
                        <button onClick={() => this.closPopup(true)}>
                          Upload new logo
                        </button>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <button
                style={{ width: "100%", marginTop: "2rem" }}
                onClick={this.updateDetails}
              >
                Update WorkSpace Details
              </button>
            </Grid>
          </div>
        </div>
        {this.state.popupStatus && this.fileUploadPopup()}
      </>
    );
  }
  onBack = () => {
    this.props.history.push(`/${this.props.workspaceType}/home`);
  };
  updateDetails = () => {
    const formData = new FormData();
    formData.append("careerLab", this.state.careerLab);
    formData.append("catalystTalks", this.state.catalystTalks);
    formData.append("bizFeatures", this.state.bizFeatures);
    formData.append("syncPublicEvents", this.state.eventOption);
    formData.append("workSpaceId", this.props.defaultWorkSpaceId);

    formData.append("directoryMenu", this.state.directory);
    formData.append("marketPlaceMenu", this.state.marketPlace);
    formData.append("workSpaceMenu", this.state.workspace);
    if (this.state.selectedImage) {
      formData.append("logo", this.state.selectedImage[0]);
      console.log(this.state.selectedImage[0]);
    }
    if (this.state.selectedMobileImage) {
      formData.append("mobileLogo", this.state.selectedMobileImage[0]);
    }
    const config = {
      header: {
        "content-type": "multipart/form-data",
      },
    };
    this.props.updateWorkSpaceOptionTypes(formData, config);
    toast.success("Work space details updated successfully");
    this.props.history.push(`/${this.props.workspaceType}/home`);
  };
  removeFile = () => {
    if (this.state.mobileLogo) {
      this.setState({
        selectedMobileImage: null,
      });
    } else {
      this.setState({
        selectedImage: null,
      });
    }
  };
  getQuestionMediaImage = (files, type) => {
    if (this.state.mobileLogo) {
      this.setState({
        selectedMobileImage: files,
      });
    } else {
      this.setState({
        selectedImage: files,
      });
    }
  };
  getQuestionMediaFiles = (files, type) => {
    if (this.state.mobileLogo) {
      this.setState({
        selectedMobileImage: files,
      });
    } else {
      this.setState({
        selectedImage: files,
      });
    }
  };
  fileOptionEnable = (files, type) => {
    if (this.state.mobileLogo) {
      this.setState({
        selectedMobileImage: null,
        popupStatus: false,
      });
    } else {
      this.setState({
        selectedImage: null,
        popupStatus: false,
      });
    }
  };

  closPopup = (isMobile) => {
    this.setState({
      popupStatus: !this.state.popupStatus,
      mobileLogo: isMobile,
    });
  };

  fileUploadPopup() {
    const { t } = this.props;
    return (
      <Modal show="true" onHide={() => this.closPopup(this.state.mobileLogo)}>
        <Form id="profileForm">
          <Modal.Body>
            <Form.Group className="formInputs">
              <Form.Label style={{ fontWeight: "bold" }}>
                Upload WorkSpace Logo
              </Form.Label>
            </Form.Group>
            <div className="d-flex align-items-center justify-content-center">
              <MessagesFileOption
                fileTypeKey={"image"}
                fileOptionEnable={this.fileOptionEnable}
                fromQuestionImage={this.getQuestionMediaImage}
                fromQuestionMedia={this.getQuestionMediaFiles}
              ></MessagesFileOption>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex" style={{ gap: "1rem", width: "100%" }}>
              <Button
                variant="secondary"
                style={{
                  color: "#ffffff",
                  marginLeft: "auto",
                }}
                onClick={() => this.closPopup(this.state.mobileLogo)}
              >
                {t("Close")}
              </Button>
              <Button
                variant="secondary"
                style={{ color: "#ffffff", marginRight: "12px" }}
                onClick={() => this.closPopup(this.state.mobileLogo)}
              >
                {t("Upload  ")}
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    workSpaceOption: state.commonApi.getWorkSpaceOption,
    defaultWorkSpaceId:
      state.loginDetailsApi.loginDetails?.userWorkSpace?.workSpaceId,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateWorkSpaceOptionTypes: (formData, config) =>
      dispatch(updateWorkSpaceOptionTypes(formData, config)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(WorkSpaceUpdate)));
