import { GET_MESSAGE_LIST_BY_ID, SET_SELECTED_USER, SET_SELECTED_USER_FALSE } from "../types";
import ChatService from "../../api/ChatService";
var oldUserId = 0
export const getChatList = (fuserId,page) => async (dispatch) => {
  try {
    
    let chat =
    {
     receiverId :fuserId,
     page:page
    }
    ChatService.getChatistbyId(chat)
      .then((response) => {
        var result = response.data;
        const chatlist = [];
        for (let j = 0; j < result.data.userChat.length; j++) {
 
          chatlist.push({
            createdDate: result.data.userChat[j].createdDate,
            id: result.data.userChat[j].id,
            message: result.data.userChat[j].message,
            receiverId: result.data.userChat[j].receiverId,
            senderId: result.data.userChat[j].senderId,
            userName: result.data.userChat[j].userName,
            userId: fuserId
          });
        }
        console.log("Chat list in action")
        console.log(chatlist)
        dispatch({
          type: GET_MESSAGE_LIST_BY_ID,
          payload: chatlist,
        });
      })

      .catch((error) => {
        dispatch({
          type: GET_MESSAGE_LIST_BY_ID,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_MESSAGE_LIST_BY_ID,
      payload: [],
    });
  }
  return [];
};


export const setSelectedUserAction = (id) => async (dispatch) => {
  console.log("SELECTED USER ID :->", id);
  return dispatch({
    type:SET_SELECTED_USER,
    payload:id
  })
}

export const setUserSelectedFalse = (id) => async (dispatch) => {
  console.log("SELECTED USER ID :->", id);
  return dispatch({
    type:SET_SELECTED_USER_FALSE,
    payload:false
  })
}


