import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Card, Modal } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import yuukke from "../img/default_avatar.jpg";
import LearningFileOptionImage from "../screens/LearningApp/LearningFileOptionImage";
import AuthService from "../session/AuthService";
import { useDispatch } from "react-redux";
import { getLogoutData } from "../redux/actions/logoutActions";
import ProfileService from "../api/ProfileService";

const MentorProfileUpdate = (props) => {
  const location = useLocation();
  const slug = location.pathname;
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  useEffect(() => {
    const filterSlug = slug.slice(slug.lastIndexOf("/") + 1) ?? "";
    setUserId(filterSlug);
    console.log("filterSlug", filterSlug);
  }, []);

  const [state, setState] = useState({
    companyName: "",
    shortBio: "",
    file: "",
  });
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const updateDetails = () => {
    if (!state.companyName || !state.shortBio || !state.file) {
      toast.warning("Please fill required details");
    } else {
      const formData = new FormData();
      formData.append("companyName", state.companyName);
      formData.append("description", state.shortBio);
      formData.append("uuid", userId);
      formData.append("mentorImage", state.file[0]);
      const config = { headers: { "content-type": "multipart/form-data" } };

      ProfileService.mentorProfileUpdate(formData, config)
        .then((response) => {})
        .catch((e) => {});
      toast.success("Details update successfully");

      AuthService.removeAuth();
      dispatch(getLogoutData());
      AuthService.triggerLogout();
    }
  };
  console.log("state", state);
  return (
    <Modal show="true" onHide={() => {}}>
      <Modal.Header className="justify-content-center">
        <h6>
          {" "}
          <img src={yuukke} class="animi-icon" alt="icon" /> Update Profile
          Details
        </h6>
      </Modal.Header>
      <Card className="profileCard">
        <Card.Body className="profile-form">
          <div className="formFieldGroup p-1">
            <div className="p-2">
              <label
                className="mt-2"
                style={{ color: "#999999", fontSize: "18px" }}
              >
                CompanyName *
              </label>
              <TextField
                id="textField"
                label=""
                variant="outlined"
                type="text"
                value={state.companyName}
                style={{ background: "white" }}
                onChange={(e) => {
                  updateState("companyName", e.target.value);
                }}
                fullWidth={true}
              />

              <label
                className="mt-3"
                style={{ color: "#999999", fontSize: "18px" }}
              >
                Add Your Brief Biography *
              </label>
              <textarea
                placeholder={"Add your text here.."}
                style={{
                  width: "100%",
                  padding: "1rem",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
                rows="4"
                cols="50"
                value={state.shortBio}
                onChange={(e) => {
                  updateState("shortBio", e.target.value);
                }}
              ></textarea>
              <label
                className="mt-2"
                style={{ color: "#999999", fontSize: "18px" }}
              >
                Upload Profile Photo *
              </label>
            </div>
            <LearningFileOptionImage
              fileTypeKey="image"
              fileOptionEnable={() => updateState("file", null)}
              fromQuestionImage={(file) => updateState("file", file)}
              grouporeventFiles={[]}
              selectPage={true}
            ></LearningFileOptionImage>
            <div className="pl-2 pr-2">
              <button className="w-100 mt-4" onClick={() => updateDetails()}>
                Update Details
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default withTranslation()(withRouter(MentorProfileUpdate));
