import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { connect } from "react-redux";
import AuthService from "../../../session/AuthService";

import {
  getCourses,
  getArticleVideosCount,
  loaderResetoff,
  loaderReset,
  resetAPICount,
} from "../../../redux/actions/courseActions";
import MyCourseService from "../../../api/MyCourseService";
class HomeClassroom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCourse: false,
      showcourseFilter: "",
      courseList: [],
      noarticleState: false,
      showQuestionFilter: false,
      courseName: "",
      showMainCourse: true,
    };

    this.handleForm = this.handleForm.bind(this);
    this.search = this.search.bind(this);
    this.getCoursesList = this.getCoursesList.bind(this);
    this.backtoCourseList = this.backtoCourseList.bind(this);
    this.childFilterLoading = this.childFilterLoading.bind(this);
    this.showQuestionFilter = this.showQuestionFilter.bind(this);
    this.backtolearnings = this.backtolearnings.bind(this);
    this.getSubCourses = this.getSubCourses.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();

    i18n.changeLanguage(langValue);
    this.props.getArticleVideosCount();
    if (!(window.innerWidth <= 768 || window.innerWidth == 768)) {
      window.scrollTo({
        top: 0,
        behavior: "auto",
        //  'smooth'
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      // <>

      //                     {this.state.showMainCourse  && (
      //         <div>
      //             <section className="">
      //                 <div className="">
      //                     <div className="answerviewimginview">
      //                         <div className="courseToolbarWrapper"></div>
      //                     </div>
      //                     <div className="row">
      //                         <div className="col-md-12">
      //                         <div className="mycorses">
      //                         <h3 className="listHomeTitle" style={{marginTop:"15px"}}>Learnings</h3>
      //                             </div>
      //                         </div>
      //                     </div>
      //                 </div>
      //             </section>

      //             {(
      //                <>
      //                <Row>
      //                        <Col md={12}>
      //                            <section className="">
      //                                <div className="">
      //                                    <div className="row">
      //                                        <div
      //                                            className="col-md-4"
      //                                            style={{ cursor: "pointer" }}
      //                                            onClick={() => this.getSubCourses()}
      //                                        >
      //                                            <div className="card-main-wrapper">
      //                                                <div className="">

      //                                                        <img   className="bannerClassroomMainImg" src={entrepreneurship} />{" "}

      //                                                    <br />
      //                                                                </div>
      //                                            </div>
      //                                        </div>

      //                                    </div>
      //                                </div>
      //                            </section>

      //                        </Col>
      //                    </Row></>
      //             )}
      //         </div>
      //     )}

      //     {!this.state.showMainCourse && !this.state.showCourse && (
      //         <div>
      //             <section className="">
      //                 <div className="">
      //                     <div className="answerviewimginview">
      //                         <div className="courseToolbarWrapper"></div>
      //                     </div>
      //                     <div className="row">
      //                         <div className="col-md-12">
      //                         <div className="mycorses">
      //                         {/* <h3 className="listHomeTitle" style={{marginTop:"15px"}}>Back to Learning</h3> */}
      //                         <div className="uploader" style={{display:"inline-flex",cursor:"pointer",marginTop:"20px"}}>  <ArrowBackIcon /> <p style={{marginLeft:"8px"}} onClick={this.backtolearnings}>Back to learnings</p>
      //   </div>
      //                             </div>
      //                         </div>
      //                     </div>
      //                 </div>

      //             </section>

      //             {(!this.state.showCourse )&& (
      //                 <>
      //                     <Row>
      //                         <Col md={12}>
      //                             <section className="">
      //                                 <div className="">
      //                                     <div className="row">
      //                                         <div
      //                                             className="col-md-4"
      //                                             style={{ cursor: "pointer" }}
      //                                             onClick={() => this.getCoursesList(1)}
      //                                         >
      //                                             <div className="card-wrapper">
      //                                                 <div className="">

      //                                                         <img   className="bannerClassroomImg" src={m1} />{" "}

      //                                                     <br />
      //                                                     <hr />
      //                                                     <strong style={{ fontSize: "14px" }}>  A- BASICS OF ENTREPRENEURSHIP </strong>
      //                                                     {/* <p style={{ fontSize: "11px" }}>An initiative developed to enable your women students to be ready for the workspace. The campus to career program is available as a 26-hour course on the YUUKKE App for all student members. It covers topics such as understanding self, time management, personal branding, resumes and interviews along with a multitude of soft and hard skills required to make an impact in the corporate world.</p> */}
      //                                                 </div>
      //                                             </div>
      //                                         </div>
      //                                         <div
      //                                             className="col-md-4"
      //                                             style={{ cursor: "pointer" }}
      //                                             onClick={() => this.getCoursesList(2)}
      //                                         >
      //                                             <div className="card-wrapper">
      //                                                 <div className="">

      //                                                         {" "}
      //                                                         <img  className="bannerClassroomImg"  src={m2} />{" "}

      //                                                     <br />
      //                                                     <hr />
      //                                                     <strong style={{ fontSize: "14px" }}>
      //                                                        B - CHECK YOUR IDEA
      //                                                     </strong>
      //                                                     {/* <p style={{ fontSize: "11px" }}>A tool designed to help companies Identify strengths, gaps, and opportunities in their existing business function. The tool hopes to assist the organization to ask questions of themselves that will move them towards becoming ‘gender positive companies’ that are able to explore their full economic potential as inclusive companies. The process is facilitated, and a report is generated</p> */}
      //                                                 </div>
      //                                             </div>
      //                                         </div>
      //                                         <div
      //                                             className="col-md-4"
      //                                             style={{ cursor: "pointer" }}
      //                                             onClick={() => this.getCoursesList(3)}
      //                                         >
      //                                             <div className="card-wrapper">
      //                                                 <div className="">

      //                                                         <img  className="bannerClassroomImg"  src={m3} />{" "}

      //                                                     <br />
      //                                                     <hr />

      //                                                     <strong style={{ fontSize: "14px" }}>
      //                                                         C - GET YOUR STRATEGY RIGHT
      //                                                     </strong>
      //                                                     {/* <p style={{ fontSize: "11px" }}>A virtual start-up support program designed for aspiring entrepreneurs and early-stage start-ups. It provides structured learning through 5 learning modules that enable participants to think entrepreneurially and hone the skills they need to develop a new enterprise with potential for growth and funding.
      //                                                     </p> */}
      //                                                 </div>
      //                                             </div>
      //                                         </div>
      //                                         {/* <div
      //                                             className="col-md-6"
      //                                             style={{ cursor: "pointer" }}
      //                                             onClick={() => this.getCoursesList(1)}
      //                                         >
      //                                             <div className="card-wrapper">
      //                                                 <div className="">

      //                                                         {" "}
      //                                                         <img   className="bannerClassroomImg" src={executive_presence} />{" "}

      //                                                     <br />
      //                                                     <hr />
      //                                                     <strong style={{ fontSize: "14px" }}>
      //                                                         EXECUTIVE PRESENCE
      //                                                     </strong>
      //                                                     <p style={{ fontSize: "11px" }}>The program is designed to support women who have been identified as potential “leader of communities” and “industry leaders”. The inputs include psychometric tests, workshops and coaching sessions. All alumna of the program will be identified for leadership positions within CII and will be empaneled for speaking opportunities at CII nationally/ internationally on areas of interest. The senior women in the group, if willing, can serve as mentors for industry talent.</p>
      //                                                 </div>
      //                                             </div>
      //                                         </div> */}
      //                                     </div>
      //                                 </div>
      //                             </section>

      //                         </Col>
      //                     </Row>
      //                     <Row>
      //                         <Col md={12}>
      //                             <section className="">
      //                                 <div className="">
      //                                     <div className="row">
      //                                         {/* <div
      //                                             className="col-md-6"
      //                                             style={{ cursor: "pointer" }}
      //                                             onClick={() => this.getCoursesList(3)}
      //                                         >
      //                                             <div className="card-wrapper">
      //                                                 <div className="">

      //                                                         <img  className="bannerClassroomImg" src={personal_branding} />{" "}

      //                                                     <br />
      //                                                     <hr />
      //                                                     <strong style={{ fontSize: "14px" }}>
      //                                                         PERSONAL BRANDING
      //                                                     </strong>
      //                                                     <p style={{ fontSize: "11px" }}>The Personal Branding is a 4-hour master class over four weeks for professional women with 0-5 years of work experience. The course takes you through the 4 steps of personal branding and how to create your own brand.</p>
      //                                                 </div>
      //                                             </div>
      //                                         </div> */}
      //                                         {/* <div
      //                                             className="col-md-6"
      //                                             style={{ cursor: "pointer" }}
      //                                             onClick={() => this.getCoursesList(4)}
      //                                         >
      //                                             <div className="card-wrapper">
      //                                                 <div className="">

      //                                                         {" "}
      //                                                         <img  className="bannerClassroomImg"  src={mentorship} />{" "}

      //                                                     <br />
      //                                                     <hr />

      //                                                     <strong style={{ fontSize: "14px" }}>
      //                                                         MENTORSHIP
      //                                                     </strong>
      //                                                     <p style={{ fontSize: "11px" }}>A program developed to harness the talent of top women professionals with at least
      //                                                         10 years of work experience. A designed 3-month long program where each mentee
      //                                                         receives mentorship from senior industry leaders (CXO/CEOs)</p>
      //                                                 </div>
      //                                             </div>
      //                                         </div> */}
      //                                     </div>
      //                                 </div>
      //                             </section>
      //                         </Col>
      //                     </Row>
      //                     <Row>
      //                         <Col md={12}>
      //                             <section className="">
      //                                 <div className="">
      //                                     <div className="row">

      //                                         <div
      //                                             className="col-md-4"
      //                                             style={{ cursor: "pointer" }}
      //                                             onClick={() => this.getCoursesList(4)}
      //                                         >
      //                                             <div className="card-wrapper">
      //                                                 <div className="">

      //                                                         {" "}
      //                                                         <img  className="bannerClassroomImg"  src={m4} />{" "}

      //                                                     <br />
      //                                                     <hr />
      //                                                     <strong style={{ fontSize: "14px" }}>
      //                                                       D - PLAN YOUR START UP
      //                                                     </strong>
      //                                                     {/* <p style={{ fontSize: "11px" }}>A tool designed to help companies Identify strengths, gaps, and opportunities in their existing business function. The tool hopes to assist the organization to ask questions of themselves that will move them towards becoming ‘gender positive companies’ that are able to explore their full economic potential as inclusive companies. The process is facilitated, and a report is generated</p> */}
      //                                                 </div>
      //                                             </div>
      //                                         </div>
      //                                         <div
      //                                             className="col-md-4"
      //                                             style={{ cursor: "pointer" }}
      //                                             onClick={() => this.getCoursesList(5)}

      //                                         >
      //                                             <div className="card-wrapper">
      //                                                 <div className="">

      //                                                         <img className="bannerClassroomImg"  src={m5} />{" "}

      //                                                     <br />
      //                                                     <hr />
      //                                                     <strong style={{ fontSize: "14px" }}>
      //                                                        E - LAUNCH YOUR START UP
      //                                                     </strong>
      //                                                     {/* <p style={{ fontSize: "11px" }}>

      //                                                         Virtual and in person sessions on POSH to provide information to all your
      //                                                         employees/HR leads.</p> */}
      //                                                 </div>
      //                                             </div>
      //                                         </div>
      //                                     </div>
      //                                 </div>
      //                             </section>
      //                         </Col>
      //                     </Row>
      //                     <Row>
      //                         <Col md={12}>
      //                             <section className="">
      //                                 <div className="">
      //                                     <div className="row">

      //                                     </div>
      //                                 </div>
      //                             </section>
      //                         </Col>
      //                     </Row>

      //                 </>
      //             )}
      //         </div>
      //     )}
      //     {!this.state.showMainCourse && this.state.showCourse && (

      //         <div>
      //             <div
      //        style={{marginBottom:"80px",display:"inline-flex",cursor:"pointer"}}
      //        >   </div>

      //        <ClassRoomSupport backtoCourseLists={this.backtoCourseList} courseName={this.state.courseName}/>

      //              {/* {<iframe
      //                 src={this.state.courseIframe}
      //                 frameborder="0"
      //                 style={{ overflow: "hidden", height: "100vh", width: "100%" }}
      //                 height="100%"
      //                 width="100%"
      //             />} */}
      //         </div>
      //     )}
      // </>
      <></>
    );
  }

  getSubCourses() {
    this.setState({ showMainCourse: false });
  }
  search() {
    this.props.loaderReset();
    if (this.state.keyword.length >= 3) {
      let courseFilter = {
        searchKey: this.state.keyword,
        programType: "",
        tags: [],
        strand: "",
        languageId: null,
        coursekey: 0,
      };

      MyCourseService.courseFilter(courseFilter)
        .then((response) => {
          var result = response.data;

          if (result.data.articles == undefined) {
            this.setState({ noarticleState: true });
          } else {
            this.setState({ noarticleState: false });
          }
          if (result.data && result.data.articles) {
            this.setState({
              courseList: result.data.articles,
              showCourse: true,
              noarticleState: true,
            });
          } else {
            this.setState({
              courseList: [],
              showCourse: false,
              noarticleState: false,
            });
          }
          this.props.loaderResetoff();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  backtolearnings() {
    this.setState({ showMainCourse: true });
  }
  showQuestionFilter() {
    this.setState({
      showQuestionFilter: !this.state.showQuestionFilter,
    });
  }
  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  backtoCourseList() {
    this.setState({
      showCourse: false,
    });
    this.props.resetAPICount();
  }

  getCoursesList(courseTypeId) {
    if (courseTypeId == 1) {
      this.setState({
        showCourse: true,
        courseTypeId: courseTypeId,
        courseName: "BASICS OF ENTREPRENEURSHIP",
      });
    } else if (courseTypeId == 2) {
      this.setState({
        showCourse: true,
        courseTypeId: courseTypeId,
        courseName: "CHECK YOUR IDEA",
      });
    } else if (courseTypeId == 3) {
      this.setState({
        showCourse: true,
        courseTypeId: courseTypeId,
        courseName: "GET YOUR STRATEGY RIGHT",
      });
    } else if (courseTypeId == 4) {
      this.setState({
        showCourse: true,
        courseTypeId: courseTypeId,
        courseName: "PLAN YOUR START UP",
      });
    } else if (courseTypeId == 5) {
      this.setState({
        showCourse: true,
        courseTypeId: courseTypeId,
        courseName: "LAUNCH YOUR START UP",
      });
    }
    // if (courseTypeId == 1) {
    //     this.setState({
    //         showCourse: true,
    //         courseTypeId: courseTypeId,
    //         courseIframe: "https://lms.betalearnings.com/course/card_view.php?id=161"

    //     });
    // }
    // else if (courseTypeId == 2) {
    //     this.setState({
    //         showCourse: true,
    //         courseTypeId: courseTypeId,
    //         courseIframe: "https://lms.betalearnings.com/course/card_view.php?id=160"

    //     });

    // }
    // else if (courseTypeId == 3) {
    //     this.setState({
    //         showCourse: true,
    //         courseTypeId: courseTypeId,
    //         courseIframe: "https://lms.betalearnings.com/course/card_view.php?id=162"

    //     });

    // }
    // else if (courseTypeId == 4) {
    //     this.setState({
    //         showCourse: true,
    //         courseTypeId: courseTypeId,
    //         courseIframe: "https://lms.betalearnings.com/course/card_view.php?id=163"

    //     });

    // }
    // else if (courseTypeId == 5) {
    //     this.setState({
    //         showCourse: true,
    //         courseTypeId: courseTypeId,
    //         courseIframe: "https://lms.betalearnings.com/course/card_view.php?id=164"

    //     });

    // }
    // else if (courseTypeId == 6) {
    //     this.setState({
    //         showCourse: true,
    //         courseTypeId: courseTypeId,
    //         courseIframe: "https://lms.betalearnings.com/course/card_view.php?id=165"

    //     });

    // }
    // else if (courseTypeId == 7) {
    //     this.setState({
    //         showCourse: true,
    //         courseTypeId: courseTypeId,
    //         courseIframe: "https://lms.betalearnings.com/course/card_view.php?id=166"

    //     });

    // }

    /// this.props.getCourses(this.props.lastSync, courseTypeId);
  }

  childFilterLoading(key) {
    if (key) {
      this.props.loaderReset();
    } else {
      this.props.loaderResetoff();
    }
  }
}
const mapStateToProps = function (state) {
  return {
    list: state.courses.list,
    lastSync: state.courses.lastSync,
    loading: state.courses.loading,
    courseCount: state.courses.courseCount,
    couceloading: state.courses.couceloading,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getCourses: (lastSync, courseKey) =>
      dispatch(getCourses(lastSync, courseKey)),
    getArticleVideosCount: () => dispatch(getArticleVideosCount()),
    loaderResetoff: () => dispatch(loaderResetoff()),
    loaderReset: () => dispatch(loaderReset()),
    resetAPICount: () => dispatch(resetAPICount()),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(HomeClassroom));
