import auth from "./AuthAPI.js";
import Http from "./Http.js";
import * as APPCONFIG from "../config/app-config.js";
import api from "./BaseAPI.js";

var CACHE_RESOURCE = {};

class PostService {
  //get post by filter
  getPostByFilter(formValues) {
    console.log(formValues);
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/getPostByFilterV2/${Http.WORK_SPACE()}`,
      url: `${APPCONFIG.API_URL}api/v1/post/filter/${Http.WORK_SPACE()}`,
      data: JSON.stringify(formValues),
    });
  }

  getPost(formValues) {
    console.log(formValues);
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getpostbyuser`,
      data: JSON.stringify(formValues),
    });
  }
  savePost(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createposttext`,
      data: formValues,
      config,
    });
  }
  savePostV2(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createposttextV2`,
      data: formValues,
      config,
    });
  }
  saveImagePost(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createpostimage`,
      data: formValues,
      config,
    });
  }
  saveImagePostV2(formValues, { headers, onUploadProgress }) {
    console.log("Progress Details");
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createpostimageV2`,
      data: formValues,
      // config,
      // headers,
      onUploadProgress,
    });
  }

  saveVideoPost(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createpostvideo`,
      data: formValues,
      config,
    });
  }
  createpostLike(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createpostLike`,
      data: formValues,
    });
  }
  createQuestionLike(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createQuestionLike`,
      data: formValues,
    });
  }
  createQuestionCommends(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createQuestionCommends`,
      data: formValues,
    });
  }
  createpostCommands(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/post/saveComment`,
      // url: `${APPCONFIG.API_URL}api/createpostCommands`,
      data: formValues,
    });
  }
  deleteQuestionCommend(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteQuestionCommend`,
      data: formValues,
    });
  }
  getCommendByQuestion(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getCommendByQuestion`,
      data: formValues,
    });
  }
  getcommendbypost(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getcommendbypost`,
      data: formValues,
    });
  }
  //get likes count by post
  getLikebypost(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getLikeByPost`,
      data: formValues,
    });
  }
  sharePost(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/sharepost`,
      data: formValues,
    });
  }
  deletePost(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deletePost`,
      data: formValues,
    });
  }
  reportPost(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/reportByUser`,
      data: formValues,
    });
  }

  reportByUserComment(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/reportByUserComment`,
      data: formValues,
    });
  }

  /**** USER PHOTO CACHE & API  */

  setResourceCache(formValues, response) {
    //console.log("Set - Cache Resource :" + formValues.resourceString);
    CACHE_RESOURCE[formValues.resourceString] = response;
  }

  getResourceCache(formValues) {
    if (CACHE_RESOURCE[formValues.resourceString]) {
      //console.log("Loading From Cache Resource :" + formValues.resourceString);
      return CACHE_RESOURCE[formValues.resourceString];
    } else {
      return null;
    }
  }

  getBase64String(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getResourceString`,
      data: formValues,
    });
  }

  /**** USER PHOTO CACHE & API  */

  getPostBySchool(formValues) {
    console.log(formValues);
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getPostBySchool`,
      data: JSON.stringify(formValues),
    });
  }
  deleteCommends(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteComment`,
      data: formValues,
    });
  }
  editComment(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/updateCommends`,
      url: `${APPCONFIG.API_URL}api/v1/post/updateComment`,
      data: formValues,
    });
  }
  updateQuestionCommend(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateQuestionCommend`,
      data: formValues,
    });
  }
  getAnnouncementonlyOpen() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAnnouncementonlyOpen`,
    });
  }

  getUserPost(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getUserPost`,
      data: formValues,
      config,
    });
  }

  sharePostVT(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/sharePost_v2`,
      url: `${APPCONFIG.API_URL}api/v1/post/share/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }

  updateHomeAndGroupPost(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateHomeAndGroupPost`,
      data: formValues,
    });
  }
  getAllMentorUsers(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllMentorPagination`,
      data: formValues,
      config,
    });
  }
  getSingleMentorUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getSingleMentorUser`,
      data: formValues,
    });
  }
  getAllSpecializtion = async (formValues) => {
    return api({
      method: "get",
      url: `${APPCONFIG.API_URL}api/v1/specializtion/getAll`,
      data: formValues,
    });
  };
  updateMentorUser(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateMentorUser`,
      data: formValues,
      config,
    });
  }
  mentorRegistration(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/mentorRegistration`,
      url: `${
        APPCONFIG.API_URL
      }api/v1/mentor/registration/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  requestToMentor(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/requestToMentor/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  getAllRequestsByMentor(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllRequestsByMentor`,
      data: formValues,
      config,
    });
  }
  acceptToMentor(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/acceptToMentor/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  rejectToMentor(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/rejectToMentor/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  getAllAcceptsByMentor(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAllAcceptsByMentor`,
      data: formValues,
      config,
    });
  }
  createHomePost(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/createHomePost/${Http.WORK_SPACE()}`,
      url: `${APPCONFIG.API_URL}api/v1/post/create/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  saveUserSurvey(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveUserSurvey`,
      data: formValues,
    });
  }
  saveUserPoll(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveUserPoll`,
      data: formValues,
    });
  }
  getCommendByIndividualPostId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getCommentByIndividualPostId`,
      data: formValues,
    });
  }
  savePostCommandReplay(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/post/saveCommentReply`,
      data: formValues,
    });
  }
  getPostCommandReplayWithoutPagination(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/savePostCommandReplay`,
      url: `${APPCONFIG.API_URL}api/getPostCommentReplyWithoutPagination`,
      data: formValues,
    });
  }
  getPostCommandReplayDelete(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/savePostCommandReplay`,
      url: `${APPCONFIG.API_URL}api/postCommentReplyDelete`,
      data: formValues,
    });
  }

  getSurveyUserListByPostId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/savePostCommandReplay`,
      url: `${APPCONFIG.API_URL}api/getSurveyUserListByPostId`,
      data: formValues,
    });
  }
  updatePostCommandReplay(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/updateCommends`,
      url: `${APPCONFIG.API_URL}api/v1/post/updateCommentReply`,
      data: formValues,
    });
  }
  post_subscribe(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/post/subscribe`,
      data: formValues,
    });
  }
  post_unsubscribe(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/post_unsubscribe`,
      data: formValues,
    });
  }
  advertisement_chat_save(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/v1/advertisement/chatSave/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  getPostByPostIdAndTypeData(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getPostByPostIdAndTypeData`,
      data: formValues,
    });
  }
  post_bookmark(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/post/bookmark 

      `,
      data: formValues,
    });
  }
  getPostTags(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/resourcePostTagBySubjectId`,
      data: formValues,
    });
  }
  resourcePostFilterByType(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${
      //   APPCONFIG.API_URL
      // }api/resourcePostBySearchFilter/${Http.WORK_SPACE()}`,
      url: `${APPCONFIG.API_URL}api/v1/resource/filter/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  userRequestMentor(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/userRequestMentor`
      url: `${APPCONFIG.API_URL}api/v1/mentor/userRequest/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  getMentorSelfAssessmentDatas(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getMentorSelfAssessmentDatas`,
      data: formValues,
    });
  }
  mentorAllRequestAccept(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/mentorAllRequestAccept`,
      data: formValues,
      config,
    });
  }
  articlePostUpdate(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/sharePost_v2`,
      url: `${APPCONFIG.API_URL}api/articlePostUpdate/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  getTimeSlots(formValues, config) {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getTimeSlots`,
      data: formValues,
      config,
    });
  }
  getResourceMediaFiles(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/getResourceMediaFiles`,
      url: `${APPCONFIG.API_URL}api/v1/resource/getMediaFiles`,
      data: formValues,
    });
  }
  mentorDelete(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/mentorDelete`,
      data: formValues,
      config,
    });
  }
  getResourcePost(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/getResourcePost/singledata`,
      data: formValues,
    });
  }
  requestlist(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/group/requestlist`,
      data: formValues,
    });
  }
  getResourceList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/resource/getlist/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  getUserCommunityList() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/v1/getUserCommunityList/${Http.WORK_SPACE()}`,
    });
  }
  getCommunityMemberId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/get-community-members`,
      data: formValues,
    });
  }
  getTreadingUsers() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/topUsers`,
    });
  }
}

export default new PostService();
