import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import AuthService from "../../../session/AuthService";
import { Button, Form, Modal, InputGroup, FormControl } from "react-bootstrap";
import badges_hand from "../../../images/badge/Group 12770@2x.png";
import FriendsFinder from "../../../api/FriendsFinder";
import { connect } from "react-redux";
import { FiTwitter } from "react-icons/fi";
import { FiFacebook } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";
import { Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";

class SuggestedFriendProfileInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addInterestState: false,
      file: "",
      image: "",
      coverPhotoState: false,
      friendId: "",
      profession: "",
    };

    this.unFriend = this.unFriend.bind(this);
    this.unFriendId = this.unFriendId.bind(this);
    this.UnFriendhandleClose = this.UnFriendhandleClose.bind(this);
    this.popUpUnfriend = this.popUpUnfriend.bind(this);
  }
  componentDidMount() {
    // alert(this.props.twitter)
    // alert(this.props.professionId)
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    if (this.props.professionId === 1) {
      this.setState({
        profession: "Salaried",
      });
    } else {
      this.setState({
        profession: "Self-employeed",
      });
    }
  }
  render() {
    const {
      name,
      coverPhoto,
      profilePhoto,
      designation,
      additionalInfo,
      pointsCount,
      userId,
      facebook,
      companyName,
      gender,
      personalLanguage,
      dob,
      linkedin,
      twitter,
      industry,
      jobFunctions,
      areaOfInterest,
      firstName,
      lastName,
      State,
      Region,
      biography,
      title,
    } = this.props;

    const {t}=this.props;

    return (
      <div className=" ">
        <img className="bannerImgPOpup" src={coverPhoto} alt={name}></img>
        <div className="w3-row">
          <div className="w3-col w3-container w3-green">
            <div>
              <img
                style={{ cursor: "pointer" }}
                className="userImg"
                src={profilePhoto}
                alt={name}
              />
            </div>
          </div>
        </div>

        <div className="peopleInvitationCardWrapperPopupSuggested">
          <Grid container spacing={1} style={{ padding: "6px" }}>
            {/* <Grid item md={2} sm={6} xs={6} lg={2}>
              
            </Grid> */}
            <Grid item md={5} sm={3} xs={3} lg={5}></Grid>
            <Grid item md={7} sm={9} xs={9} lg={7}>
              <p
                style={{
                  fontWeight: "bold",
                  color: "#000000",
                  fontSize: "15px",
                }}
              >
                {name}
              </p>
            </Grid>
            {/* <Grid item md={2} sm={6} xs={6} lg={2}></Grid>
            <Grid item md={4} sm={6} xs={6} lg={4}>
              <p style={{ color: "gray", fontSize: "15px", cursor: "pointer" }}>
                {name}
              </p>
            </Grid> */}
            <Grid container spacing={1} style={{ padding: "6px" }}>
              <Grid item md={3} sm={6} xs={6} lg={3}>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#000000",
                    cursor: "pointer",
                    fontSize: "15px",
                  }}
                >
                  {t("About")} :
                </p>
              </Grid>
              <Grid item md={9} sm={6} xs={6} lg={9}>
                <p
                  style={{ color: "gray", fontSize: "15px", cursor: "pointer" }}
                >
                  {biography}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ padding: "6px" }}>
            <Grid item md={9} sm={9} xs={9} lg={9}></Grid>
            <Grid item md={1} sm={1} xs={1} lg={1}>
              {!twitter == "" && (
                <div tabindex="1">
                  <NavLink
                    className=""
                    to={""}
                    exact={true}
                    activeClassName="active"
                    onClick={() => window.open(twitter, "_blank")}
                  >
                    <FiTwitter
                      style={{
                        cursor: "pointer",
                        width: "23px",
                        height: "53px",
                        color: "#F97516",
                      }}
                    />
                  </NavLink>
                </div>
              )}
            </Grid>
            <Grid item md={1} sm={1} xs={1} lg={1}>
              {!facebook == "" && (
                <div tabindex="1">
                  <NavLink
                    className=""
                    to={""}
                    exact={true}
                    activeClassName="active"
                    onClick={() => window.open(facebook, "_blank")}
                  >
                    <FiFacebook
                      style={{
                        cursor: "pointer",
                        width: "23px",
                        height: "53px",
                        color: "#F97516",
                      }}
                    />
                  </NavLink>
                </div>
              )}
            </Grid>
            <Grid item md={1} sm={1} xs={1} lg={1}>
              {!linkedin == "" && (
                <div tabindex="1">
                  <NavLink
                    className=""
                    to={""}
                    exact={true}
                    activeClassName="active"
                    onClick={() => window.open(linkedin, "_blank")}
                  >
                    <FaLinkedinIn
                      style={{
                        cursor: "pointer",
                        width: "23px",
                        height: "53px",
                        color: "#F97516",
                      }}
                    />
                  </NavLink>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  unFriendId(fid) {
    this.setState({
      unfriendState: true,
      friendId: fid,
    });
  }

  unFriend(e) {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
    } else {
      let unFriend = {
        userId: this.props.loginDetails.uId,
        friendsId: this.props.userId,
      };
      console.log(unFriend);
      FriendsFinder.unfriendHit(unFriend)
        .then((response) => {
          this.UnFriendhandleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  popUpUnfriend() {
    return (
      <div>
        <Modal show="true" onHide={this.UnFriendhandleClose}>
          <Modal.Header>Unfriend</Modal.Header>

          <Form
            id="profileForm"
            noValidate
            validated={this.state.validated}
            onSubmit={this.unFriend}
          >
            <Modal.Body>
              <Form.Group className="formInput">
                <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                  Are you sure to unfriend ?
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.UnFriendhandleClose}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Remove
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }

  UnFriendhandleClose() {
    this.setState({ unfriendState: false });
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(SuggestedFriendProfileInfo));
