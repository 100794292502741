import React, { Component } from "react";
import { Card, Form, Row, Container, Col, Modal } from "react-bootstrap";
import moment from "moment";
import microsoft_icon from "../images/microsoft_icon.png";
import logo_square from "../images/EVENTS.png";
import down_arrow from "../images/down_arrow.png";
import { Button } from "@material-ui/core";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

class ConsentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consentState: true,
    };

    this.handleClose = this.handleClose.bind(this);
    this.consentSubmit = this.consentSubmit.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <div>
        {this.state.consentState && (
          <Modal show="true" onHide={this.handleClose}>
            <Modal.Header className="modal-header-consent">
              {" "}
              <img src={microsoft_icon} width="35" height="35" />{" "}
              <p style={{ padding: "8px", fontSize: "16px" }}>Microsoft </p>{" "}
            </Modal.Header>

            <Form
              id="profileForm"
              noValidate
              validated={this.state.validated}
              onSubmit={this.consentSubmit}
            >
              <Modal.Body className="modal-body-consent">
                <Form.Group className="formInput">
                  <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                    admin@contoso.com
                  </Form.Label>
                  <Form.Label
                    style={{
                      marginTop: "8px",
                      marginBottm: "8px",
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    Permissions requested
                  </Form.Label>
                  <Form.Label
                    style={{
                      marginTop: "8px",
                      marginBottm: "8px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    <img
                      src={logo_square}
                      width="25"
                      height="25"
                      style={{ marginRight: "12px" }}
                    />{" "}
                    Yuukke test app
                  </Form.Label>
                  <Form.Label
                    style={{
                      marginTop: "14px",
                      marginBottom: "14px",
                      fontSize: "14px",
                    }}
                  >
                    This app would like too
                  </Form.Label>
                  <Form.Label
                    style={{
                      marginTop: "8px",
                      marginBottm: "8px",
                      fontSize: "14px",
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <img src={down_arrow} width="25" height="25" />
                      <p>Read and write your files</p>
                    </div>
                  </Form.Label>
                  <Form.Label
                    style={{
                      marginTop: "8p4",
                      marginBottm: "8px",
                      fontSize: "14px",
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <img src={down_arrow} width="25" height="25" />
                      <p>Read your calendar</p>
                    </div>
                  </Form.Label>
                  <Form.Label
                    style={{
                      marginTop: "8px",
                      marginBottm: "8px",
                      fontSize: "14px",
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <img src={down_arrow} width="25" height="25" />
                      <p>Sign you in and read your profile</p>
                    </div>
                  </Form.Label>
                  <Form.Label
                    style={{
                      marginTop: "8px",
                      marginBottm: "8px",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    <label className="smart-option-consent">
                      <input type="checkbox" /> Consent on behalf of your
                      organization
                    </label>
                  </Form.Label>
                  <Form.Label
                    style={{
                      marginTop: "8px",
                      marginBottm: "8px",
                      fontSize: "10px",
                    }}
                  >
                    <p>
                      Accepting these permissions means that you allow this app
                      to use your data as specified in their terms of service
                      and privacy statement. You can change these permissions at
                      https:éémyapps.microsoft.com.
                    </p>
                  </Form.Label>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer
                className="modal-footer-consent"
                style={{ display: "inline-flex", float: "right" }}
              >
                <Button
                  className="MuiButton-label-concentcancel"
                  variant="secondary"
                  onClick={this.handleClose}
                >
                  Cancel
                </Button>
                <label>&nbsp;</label>
                <Button
                  className="MuiButton-label-concentaccept"
                  type="submit"
                  variant="primary"
                >
                  Accept
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </div>
    );
  }

  consentSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    this.setState({ consentState: false });
  }

  handleClose() {
    this.setState({ consentState: false });
  }
}

export default ConsentModal;
