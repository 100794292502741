import React, { Component } from "react";
import { Container } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import AuthService from "../../../session/AuthService";
import FriendsFinder from "../../../api/FriendsFinder";
import { withRouter } from "react-router";
import PeoplesCarouselList from "./PeoplesCarouselList";

class SuggestedFriendsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      pageNo: 0,
      friendsState: false,
      totalItems: 0,
      recommendedList: [],
    };

    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.getRecommendedFriends = this.getRecommendedFriends.bind(this);
    this.requestSentData = this.requestSentData.bind(this);
    this.ignoreSentData = this.ignoreSentData.bind(this);
    this.getMoreFriends = this.getMoreFriends.bind(this);
  }
  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
    });
  }
  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.getRecommendedFriends();
  }
  render() {
    const { t } = this.props;
    return (
      <div className="screenInnerGap">
        <div className="">
          <Container>
            <div className="flexDefault subMenu">
              <div className="" style={{marginTop:"50px"}}>
                  <h5 className="">{t("Member directory")}</h5>
              </div>
            </div>
          </Container>
        </div>
    
          <div className="">
             <div className="peopleMainWrapper">
              {this.state.recommendedList && (
                <PeoplesCarouselList
                  ignoreSentPageData={this.ignoreSentData}
                  recommendedListLength={this.state.sizeofRecommendedList}
                  requestSentPageData={this.requestSentData}
                  recommended_friends_list={this.state.recommendedList}
                />
              )}
            </div>
          {this.state.totalItems > this.state.recommendedList.length && (
            <div className="text-center">
              <button onClick={() => this.getMoreFriends()}>
                {t("See more")}
              </button>
            </div>
          )}
          {/* {this.state.totalItems > 0 &&
            this.state.totalItems == this.state.recommendedList.length && (
              <div class="no-data">
                <center>
                  <p>No Results Found</p>
                </center>
              </div>
            )} */}
    </div>
      </div>
    );
  }

    ignoreSentData() {
    this.getRecommendedFriends();
  }
  requestSentData() {
    this.getRecommendedFriends();
  }

  getRecommendedFriends() {
    let getRecommendedFriends = {
      page: this.state.pageNo,
    };
    FriendsFinder.getRecommendedFriends(getRecommendedFriends)
      .then((response) => {
        var result = response.data;
        if (result.data.user.length > 0) {
          var stringArray = result.data.user;

          this.setState({
            sizeofRecommendedList: stringArray.length,
            recommendedList: stringArray,
            totalItems: result.data.totalItems,
          });
          this.setState({ friendsState: true });
          AuthService.storeRecommendedFriendsList(this.state.recommendedList);
        } else {
          //  toast.error()
          this.setState({
            recommendedList: [],
          });
          this.setState({ friendsState: false });
          AuthService.storeRecommendedFriendsList(this.state.recommendedList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getMoreFriends() {
    this.setState({ pageNo: this.state.pageNo + 1 });
    let friendsList = {
      page: this.state.pageNo + 1,
    };
    FriendsFinder.getRecommendedFriends(friendsList)
      .then((response) => {
        var result = response.data;

        if (result.data.user.length > 0) {
          var stringArray = result.data.user;
          this.setState({ sizeofRecommendedList: stringArray.length });
          this.setState({
            recommendedList: this.state.recommendedList.concat(stringArray),
          });

          this.setState({ friendsState: true });

          AuthService.storeRecommendedFriendsList(this.state.recommendedList);
        } else {
          //  toast.error()
          this.setState({
            recommendedList: [],
          });
          this.setState({ friendsState: false });
          AuthService.storeRecommendedFriendsList(this.state.recommendedList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default withTranslation()(withRouter(SuggestedFriendsList));



