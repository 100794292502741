import { get } from "immutable";
import Keycloak from "keycloak-js";
import Http from "../api/Http";
import { BASE_URL } from "../config/app-config";

class AuthService {
  registerEmail(email) {
    sessionStorage.setItem("email", email);
  }

  registerAlertShow() {
    sessionStorage.setItem("alert", true);
  }

  registerAuth(username, loginValue) {}

  storeIpAddress(ipaddress) {
    sessionStorage.setItem("ipaddress", JSON.stringify(ipaddress));
  }

  registerArea(areaValues) {
    sessionStorage.setItem("areaValues", JSON.stringify(areaValues));
  }

  storeCourseId(courseId) {
    sessionStorage.setItem("courseId", courseId);
  }

  storeTopicId(topicId) {
    sessionStorage.setItem("topicId", topicId);
  }

  storeGroupDetailsbyId(groupDatabyId) {
    sessionStorage.setItem("groupDatabyId", JSON.stringify(groupDatabyId));
  }

  storePeerGroupDetailsbyId(peergroupDatabyId) {
    sessionStorage.setItem(
      "peergroupDatabyId",
      JSON.stringify(peergroupDatabyId)
    );
  }

  storeFriendUserdata(friendUserData) {
    sessionStorage.setItem("friendValue", JSON.stringify(friendUserData));
  }

  storeRecommendedFriendUserdata(friendUserData) {
    sessionStorage.setItem(
      "recommendedfriendValue",
      JSON.stringify(friendUserData)
    );
  }

  storeScheduleFriendUserdata(friendUserData) {
    sessionStorage.setItem(
      "schedulefriendValue",
      JSON.stringify(friendUserData)
    );
  }

  storeFriendsList(friendsList) {
    sessionStorage.setItem("friendsList", JSON.stringify(friendsList));
  }

  storeRecommendedFriendsList(recommendedFriendsList) {
    sessionStorage.setItem(
      "recommendedFriendsList",
      JSON.stringify(recommendedFriendsList)
    );
  }

  storerecommendedFriendsReviews(recommendedFriendsReviews) {
    sessionStorage.setItem(
      "recommendedFriendsReviews",
      JSON.stringify(recommendedFriendsReviews)
    );
  }

  storeInvitedFriendsList(invitedFriendsList) {
    sessionStorage.setItem(
      "invitedFriendsList",
      JSON.stringify(invitedFriendsList)
    );
  }

  storeHobbiesList(hobbiesList) {
    sessionStorage.setItem("hobbiesList", JSON.stringify(hobbiesList));
  }

  storeBadgesList(badgesList) {
    sessionStorage.setItem("badgesList", JSON.stringify(badgesList));
  }

  storeCommonLanguages(selectedLanguage) {
    sessionStorage.setItem("selectedLanguage", selectedLanguage);
  }

  storeClassroomComplete() {
    sessionStorage.setItem("classroomComplete", true);
  }

  storeUserAvailabilities(storeUserAvailabilities) {
    sessionStorage.setItem(
      "storeUserAvailabilities",
      JSON.stringify(storeUserAvailabilities)
    );
  }

  storeGradeList(gradeList) {
    sessionStorage.setItem("gradeList", JSON.stringify(gradeList));
  }

  /* For MS Graph API
   */

  setMSTeamsApp() {
    sessionStorage.setItem("MS_TEAMS_APP", true);
  }

  setMSEmail(email) {
    sessionStorage.setItem("MS_EMAIL", email);
  }

  setMSUsersData(data) {
    sessionStorage.setItem("MS_USERS_DATA", JSON.stringify(data));
  }

  setMSUserInfo(data) {
    sessionStorage.setItem("MS_USER_INFO", JSON.stringify(data));
  }

  setMSServiceToken(token) {
    sessionStorage.setItem("MS_SERVICE_TOKEN", token);
  }

  setMSGraphToken(token) {
    sessionStorage.setItem("MS_GRAPH_TOKEN", token);
  }

  isMSLogin() {
    let email = sessionStorage.getItem("MS_EMAIL");

    if (email) return true;
    return false;
  }

  isMSTeamsApp() {
    return sessionStorage.getItem("MS_TEAMS_APP");
  }

  getMSEmail() {
    let email = sessionStorage.getItem("MS_EMAIL");
    return email;
  }

  getMSServiceToken() {
    let token = sessionStorage.getItem("MS_SERVICE_TOKEN");
    return token;
  }

  getMSGraphToken() {
    let token = sessionStorage.getItem("MS_GRAPH_TOKEN");
    return token;
  }

  getMSUsersData() {
    let udata = sessionStorage.getItem("MS_USERS_DATA");

    var obj = JSON.parse(udata || null);

    return obj;
  }

  getMSUserInfo() {
    let udata = sessionStorage.getItem("MS_USER_INFO");

    var obj = JSON.parse(udata || null);

    return obj;
  }

  /* For MS Graph API
   */

  isAlert() {
    let alert = sessionStorage.getItem("alert");

    if (alert) return true;
    return false;
  }

  getCourseId() {
    let courseId = sessionStorage.getItem("courseId");
    return courseId;
  }

  getTopicId() {
    let topicId = sessionStorage.getItem("topicId");
    return topicId;
  }

  getUserDisplayType() {
    let type = sessionStorage.getItem("authDisplayType");
    return type;
  }

  getEmail() {
    let type = sessionStorage.getItem("email");

    return type;
  }

  getGroupDetailsbyId() {
    let user = sessionStorage.getItem("groupDatabyId");
    var obj = JSON.parse(user);

    return obj;
  }

  getPeerGroupDetailsbyId() {
    let user = sessionStorage.getItem("peergroupDatabyId");
    var obj = JSON.parse(user);

    return obj;
  }

  getFriendUserdata() {
    let user = sessionStorage.getItem("friendValue");
    var obj = JSON.parse(user);

    return obj;
  }

  getRecommendedFriendUserdata() {
    let user = sessionStorage.getItem("recommendedfriendValue");
    var obj = JSON.parse(user);

    return obj;
  }

  getScheduleFriendUserdata() {
    let user = sessionStorage.getItem("schedulefriendValue");
    var obj = JSON.parse(user);

    return obj;
  }

  getAreadata() {
    let areaValues = sessionStorage.getItem("areaValues");
    var obj = JSON.parse(areaValues);

    return obj;
  }

  getIpAddress() {
    let ipaddress = sessionStorage.getItem("ipaddress");
    var obj = JSON.parse(ipaddress);
    return obj;
  }

  getFriendsList() {
    let friendsList = sessionStorage.getItem("friendsList");

    var obj = JSON.parse(friendsList || null);

    return obj;
  }

  getInvitedFriendsList() {
    let invitedFriendsList = sessionStorage.getItem("invitedFriendsList");

    var obj = JSON.parse(invitedFriendsList || null);

    return obj;
  }

  getRecommendedFriendsList() {
    let recommendedFriendsList = sessionStorage.getItem(
      "recommendedFriendsList"
    );

    var obj = JSON.parse(recommendedFriendsList);

    return obj;
  }

  getrecommendedFriendsReviews() {
    let recommendedFriendsReviews = sessionStorage.getItem(
      "recommendedFriendsReviews"
    );

    var obj = JSON.parse(recommendedFriendsReviews);

    return obj;
  }

  getHobbiesList() {
    let hobbiesList = sessionStorage.getItem("hobbiesList");
    var obj = JSON.parse(hobbiesList);

    return obj;
  }

  getBadgesList() {
    let badgesList = sessionStorage.getItem("badgesList");
    var obj = JSON.parse(badgesList);

    return obj;
  }

  getCommonLanguages() {
    let selectedLanguage = sessionStorage.getItem("selectedLanguage");

    return selectedLanguage;
  }
  getClassroomComplete() {
    let classroomComplete = sessionStorage.getItem("classroomComplete");

    return classroomComplete;
  }
  getGradeList() {
    let gradeList = sessionStorage.getItem("gradeList");
    var obj = JSON.parse(gradeList);

    return obj;
  }

  getUserAvailabilities() {
    let storeUserAvailabilities = sessionStorage.getItem(
      "storeUserAvailabilities"
    );
    var obj = JSON.parse(storeUserAvailabilities);

    return obj;
  }

  registerToken(token) {
    sessionStorage.setItem("TOKEN", token);
  }

  getToken() {
    let token = "Bearer " + sessionStorage.getItem("TOKEN");
    return token;
  }

  getMsKey() {
    let mykey = sessionStorage.getItem("MSKEY");
    return mykey;
  }
  getMsEmail() {
    let msemail = sessionStorage.getItem("MSEMAIL");
    return msemail;
  }
  getMsProfile() {
    let myprofile = sessionStorage.getItem("MSPROFILE");
    return myprofile;
  }
  removeToke() {
    sessionStorage.removeItem("SESSION");
    sessionStorage.removeItem("TOKEN");
  }

  removeAuth() {
    sessionStorage.removeItem("CACHE");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("friendsList");
    sessionStorage.removeItem("hobbiesList");
    sessionStorage.removeItem("badgesList");
    sessionStorage.removeItem("selectedLanguage");
    sessionStorage.removeItem("storeUserAvailabilities");
    sessionStorage.removeItem("recommendedFriendsList");
    sessionStorage.removeItem("recommendedFriendsReviews");
    sessionStorage.removeItem("invitedFriendsList");
    sessionStorage.removeItem("friendValue");
    sessionStorage.removeItem("recommendedfriendValue");
    sessionStorage.removeItem("schedulefriendValue");
    sessionStorage.removeItem("groupDatabyId");
    sessionStorage.removeItem("peergroupDatabyId");
    // sessionStorage.removeItem("TOKEN");
    sessionStorage.removeItem("areaValues");
    sessionStorage.removeItem("ipaddress");
    sessionStorage.removeItem("courseId");
    sessionStorage.removeItem("topicId");
    sessionStorage.removeItem("MSKEY");
    sessionStorage.removeItem("MSEMAIL");
    sessionStorage.removeItem("MSPROFILE");
    sessionStorage.removeItem("MSKEY");
    sessionStorage.removeItem("MSEMAIL");
    sessionStorage.removeItem("MSPROFILE");
  }

  async triggerLogout() {
    localStorage.removeItem("R_TOKEN");
    let ks = fetch("/keycloak.json")
      .then((res) => {
        return res.json();
      })
      .then((ks) => {
        let url =
          ks["auth-server-url"] +
          "realms/" +
          ks.realm +
          "/protocol/openid-connect/logout?redirect_uri=" +
          encodeURI(BASE_URL);

        window.location.href = url;
        sessionStorage.clear();
      });
    // await ks.init();
  }
}

export default new AuthService();
