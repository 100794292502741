import React from "react";
// import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./store/Reducer";
import thunkMiddleware from "redux-thunk";
// import { store, chatPersistor } from "./store/index.js";
//  import chatPersistor from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import { io } from "socket.io-client";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import axios from "axios";
import { API_URL, CHAT_URL, SOCKET_URL } from "./config/config.js";
import FriendsFinder from "../../api/FriendsFinder";

function Index({
  friendsList,
  authenticated,
  refreshToken,
  accessToken,
  userDetails,
  selectedUser,
  closeCallBack,
}) {
  const calledTime = React.useRef(0);
  // alert(calledTime.current++)

  const persistConfig = {
    key: "chat",
    storage: storageSession,
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, applyMiddleware(thunkMiddleware));
  let chatPersistor = persistStore(store);

  const socket = React.useRef();

  const [backendStatus, setBackendStatus] = React.useState(null);
  const [friends, setFriends] = React.useState(null);
  const [userProfile, setUserProfile] = React.useState(null);

  const [isAuthenticated, setIsauthenticated] = React.useState(false);

  store.subscribe(() => {
    const newState = store.getState();
    console.group("Memoized State : state");
    console.log("Memoized State : store->", newState);
    console.groupEnd("");
  });
  React.useEffect(() => {
    socket.current = io(SOCKET_URL, {});
  }, []);

  React.useEffect(() => {
    setIsauthenticated(authenticated);
  }, [authenticated]);

  React.useEffect(() => {
    setFriends(friendsList);
  }, [friendsList]);

  React.useEffect(() => {
    if (isAuthenticated) {
      const data = async () => {
        const response = await axios.get(`${CHAT_URL}api/status`);
        setBackendStatus(response.data.status);
      };
      setUserProfile(userDetails);
      data();
    }
  }, [isAuthenticated, userDetails]);

  const checkCondition = () => {
    return (
      backendStatus === "success" &&
      authenticated &&
      userDetails &&
      Array.isArray(friends) &&
      friends.length > 0
    );
  };

  console.group("Memoized State");
  console.log("User Details", userDetails);
  console.log("Friends List", friendsList);
  console.log("Selected USer", selectedUser);
  console.groupEnd();

  return (
    <Provider store={store}>
      {checkCondition() && (
        <App
          userDetails={userProfile}
          friendsList={friends}
          socket={socket}
          selectedUser={selectedUser}
          closeCallBack={closeCallBack}
        />
        // <>Hello</>
      )}
    </Provider>
    // <></>
  );
}

const ChatIndex = React.memo(Index);
export default ChatIndex;
