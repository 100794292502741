import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { reportAction } from "../redux/actions/commonApiActions";
import { Button, Form, Modal, Card } from "react-bootstrap";
import { toast } from "react-toastify";

const ReportModel = ({ reportType = "Post", ...props }) => {
  const { t, closePopup } = props;
  const [description, setDescription] = useState("");
  const maxLength = 255;
  const isExceedingLimit = description.length > 254;
  const dispatch = useDispatch();
  const reportData = (e) => {
    e.preventDefault();
    if (!description) {
      toast.warning("Add your reason ...");
    } else {
      let data = {
        id: props.id,
        typeData: props.typeData,
        description: description,
      };
      dispatch(reportAction(data));

      if (reportType !== "Post") {
        toast.success("Reported Successfully ...");
      }
      closePopup();
      props.removePost(props.id, reportType);
    }
  };
  return (
    <div>
      <Modal show="true" onHide={closePopup}>
        <Modal.Header>{t(`Report this ${reportType}`)}</Modal.Header>
        <div
          className="tab-pane-view"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="profile"
        >
          <div className="profileTabContent">
            <Form
              id="profileForm"
              noValidate
              validated={true}
              onSubmit={reportData}
            >
              <Card className="profileCard">
                <Card.Body>
                  <div className="row ">
                    <div className="col-xs-12 col-md-12">
                      <Form.Group className="formInput">
                        <Form.Label></Form.Label>
                        <textarea
                          maxLength={maxLength}
                          placeholder={t("Add your reason that applies")+" .."}
                          style={{
                            width: "100%",
                            padding: "1rem",
                            borderRadius: "5px",
                            border: isExceedingLimit
                              ? "2px solid red"
                              : "1px solid #ccc", // Apply red border if exceeding limit
                          }}
                          rows="4"
                          cols="50"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                        {isExceedingLimit && (
                          <p style={{ color: "red", fontSize: "13px" }}>
                            {t("* Character limit exceeded")}
                          </p>
                        )}
                        <Form.Control.Feedback type="invalid">
                          {t("Please Enter Description")}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <br />
                    </div>
                  </div>
                </Card.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={closePopup}
                    style={{ marginRight: "auto" }}
                  >
                    {t("Close")}
                  </Button>
                  <Button type="submit" variant="primary">
                    {t("Submit")}
                  </Button>
                </Modal.Footer>
              </Card>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withTranslation()(ReportModel);
