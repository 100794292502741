import React, { Component, useState } from "react";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import PostService from "../../../api/PostService";
// import AuthService from "../../../session/AuthService";
import * as APPCONFIG from "../../../config/app-config.js";
import AuthService from "../../../session/AuthService_old";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base64String: "",
      images: [],
    };
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  render() {
    // const { t } = this.props;
    // const { imgUrl, title, content, id } = this.props;
    // const profileImage = peesquareImage;

    // var myHTML = this.props.content;

    // var strippedHtml = myHTML.replace(/<[^>]+>/g, "");
    // var length = 40;
    // var trimmedString = strippedHtml.substring(0, length);
    // var settings = {
    //   dots: true
    // };
    console.log("this.props.announcementData", this.props.announcementData);
    return (
      <div
        className={`cardWrapper ${
          this.props.announcementData?.length > 1 ? "announcement-res-wrap" : ""
        }`}
      >
        <SliderComponent
          announcementData={this.props.announcementData}
          openAnnoucmentScreen={this.callScreen}
        />
        {/* <ReactSlickDemo
          announcementData={this.props.announcementData}
          openAnnoucmentScreen={this.callScreen}
        /> */}
      </div>

      // <div
      //   className=""
      //   onClick={() =>
      //     this.props.openAnnoucmentScreen(
      //       id,
      //       title,
      //       this.state.base64String,
      //       strippedHtml
      //     )
      //   }
      // >
      //   <div className="about-card">
      //     <div className="post-card-body">
      //       <div className="">
      //         <h3>{title}</h3>
      //         {/* <p>{}</p> */}
      //         <p
      //           className=""
      //           style={{ padding: "20px" }}
      //           dangerouslySetInnerHTML={{
      //             __html: this.props.content,
      //           }}
      //         />
      //         {/* <h6 style={{color:"#f97516"}}>{t("Read more")}</h6> */}
      //       </div>

      //       <div>
      //         <div className="announcementImg">
      //           {this.state.base64String && (
      //             <img
      //               style={{ width: "100%", height: "100%" }}
      //               src={this.state.base64String}
      //               alt={title}
      //               onError={({ currentTarget }) => {
      //                 currentTarget.onerror = null; // prevents looping
      //                 currentTarget.src = dummyimage;
      //               }}
      //             />
      //           )}
      //           {!this.state.base64String && (
      //             <img
      //               src={profileImage}
      //               alt={title}
      //               onError={({ currentTarget }) => {
      //                 currentTarget.onerror = null; // prevents looping
      //                 currentTarget.src = dummyimage;
      //               }}
      //             />
      //           )}
      //         </div>
      //       </div>
      //     </div>
      //   </div>
    );
  }
  callScreen = (id) => {
    this.props.openAnnouncement(id);
  };
  getBase64String(postFile) {
    let getBase64String = {
      resourceString: postFile,
    };

    PostService.getBase64String(getBase64String)
      .then((response) => {
        var value = response.data.data.base64String;
        var base64String = "";

        base64String = `data:image;base64,${value}`;

        this.setState({ base64String: base64String });
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const SliderComponent = ({ announcementData, openAnnoucmentScreen }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // centerMode: true,
    // centerPadding: "250px",
  };
  const [slideWidth, setSlideWidth] = useState(0);
  const handleImageLoad = (event) => {
    const img = event.target;
    const ratio = img.naturalWidth / img.naturalHeight;
    setSlideWidth(img.offsetHeight * ratio);
  };
  return (
    <div>
      <Slider {...settings}>
        {announcementData &&
          announcementData.map((announcement, index) => (
            <div
              style={{
                width: slideWidth,
                margin: "0 auto",
                position: "relative",
              }}
              key={index}
              onClick={() => openAnnoucmentScreen(announcement.id)}
            >
              <img
                src={`${APPCONFIG.API_URL}auth/image/${announcement.file}`}
                onLoad={handleImageLoad}
                // onError={({ currentTarget }) => {
                //   currentTarget.onerror = null;
                //   currentTarget.src = dummyimage;
                // }}
                alt="announcement"
                style={{ display: "block", width: "100%" }}
              />
              <div
                style={{ width: slideWidth }}
                className="home-announcement-wrapper"
              >
                <h5>{announcement.title} </h5>

                {/* <p
                  className="home-announcement"
                  dangerouslySetInnerHTML={{
                    __html: announcement.description,
                  }}
                /> */}
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};

function ReactSlickDemo(props) {
  var settings = {
    dots: true,
  };
  const [picture, setPicture] = React.useState([]);

  return (
    <div
      className="annoucmentBox image-container"
      // className="containerSlide"
    >
      <Slider {...settings}>
        {props.announcementData.length > 0 &&
          props.announcementData &&
          props.announcementData.map((announcement, index) => (
            <div onClick={() => props.openAnnoucmentScreen(announcement.id)}>
              {/* <div className="post-card-body"> */}
              <div className="image-container">
                <img
                  // className="announcement-image"
                  // style={{ width: "auto", height: "50%", marginTop: "5px" }}
                  src={`${APPCONFIG.API_URL}auth/image/${announcement.file}`}
                  // onError={({ currentTarget }) => {
                  //   currentTarget.onerror = null;
                  //   currentTarget.src = dummyimage;
                  // }}
                  alt="announcement"
                />
              </div>
              <div className="announcement-overlay">
                <h3 className="annoucmentTitle-Home">{announcement.title}</h3>

                <p
                  className="annoucmentContent-home"
                  dangerouslySetInnerHTML={{
                    __html: announcement.description,
                  }}
                />
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
}

export default withTranslation()(Announcement);
