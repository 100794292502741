import { GET_ALL_NOTIFICATION_LIST } from '../types'
import { GET_ALL_NOTIFICATION_LIST_FALSE } from '../types'
import {
  REMOVE_NOTIFICATION_LIST_BYID,
  REMOVE_CUSTOM_NOTIFICATION_LIST_BYID,
} from '../types'
import { GET_LOGOUT_DATA } from '../types'

const initialState = {
  list: [],
  friendsOnlinelist: [],
  pointCount:0
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_NOTIFICATION_LIST:
      console.log('GET_ALL_NOTIFICATION_LIST')
      console.log(action.payload)
      return {
        ...state,
        list: action.payload[0],
        friendsOnlinelist: action.payload[1],
        loading: false,
        pointCount:action?.pointCount
      }
    case GET_ALL_NOTIFICATION_LIST_FALSE:
      console.log('GET_ALL_NOTIFICATION_LIST_FALSE')
      console.log(action.payload)
      var total = []
      if (state.list && state.list.length > 0) {
        var lists = state.list
        for (var k = 0; k < action.payload[0].length; k++) {
          var fil = lists.filter(
            (data) =>
              data.notificationId == action.payload[0][k].notificationId,
          )
          if (fil.length == 0) {
            total.push(action.payload[0][k])
          }
        }
      } else {
        total = action.payload[0]
      }

      return {
        ...state,
        list: total.concat(state.list),
        friendsOnlinelist: action.payload[1],
        loading: false,
        pointCount:action?.pointCount

      }
    case REMOVE_NOTIFICATION_LIST_BYID:
      console.log('REMOVE_NOTIFICATION_LIST_BYID')
      console.log(action.payload)
      return {
        ...state,
        list: action.payload,
        loading: false,
      }
    case REMOVE_CUSTOM_NOTIFICATION_LIST_BYID:
      console.log('REMOVE_CUSTOM_NOTIFICATION_LIST_BYID')
      console.log(action.payload)
      return {
        ...state,
        list: action.payload,
        loading: false,
      }

    case GET_LOGOUT_DATA:
      console.log('GET_LOGOUT_DATA')

      return {
        ...initialState,
      }
    default:
      return state
  }
}
