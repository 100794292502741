import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Container } from "@material-ui/core";
import Calender from "./Calender";
import EventContainer from "./EventContainer";
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const CalendarContainer = (props) => {
  const { t } = props;
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div>
      <h3 className="listHomeTitle group-count">{t("Calendar")}</h3>
      <div className="peopleCardWrapperGroups ">
        <Container>
          <div className="flexDefault  ">
            <Tabs
              // TabIndicatorProps={{ style: { background: "#f97516" } }}
              value={tabIndex}
              //   textColor="secondary"
              textColor="primary"
              onChange={(e, i) => setTabIndex(i)}
              aria-label="disabled tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                style={{ textTransform: "none" }}
                label={t("MY CALENDAR")}
                {...a11yProps(0)}
              />
              <Tab
                style={{ textTransform: "none" }}
                label={t("EVENTS")}
                {...a11yProps(1)}
              />
            </Tabs>
          </div>
        </Container>
      </div>
      <div className="announcementWrapperOuter">
        {tabIndex === 1 ? <EventContainer /> : <Calender />}
      </div>
    </div>
  );
};

export default withTranslation()(CalendarContainer);
