import axios from "axios";
import * as APPCONFIG from "../config/app-config.js";
const axiosApi = axios.create({
  headers: APPCONFIG.HTTP_HEADERS,
  baseURL: APPCONFIG.API_URL,
});

axiosApi.interceptors.response.use(
  function (response) {
    // Do something with response data
    //alert("Response");
    return response;
  },
  function (error) {
    console.log(error);
    console.log("=============>");
    switch (error.response.status) {
      case 503:
        //alert("Error");
        //props.history.push('/503') //we will redirect user into 503 page
        break;
      default:
        break;
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosApi;
