import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AuthService from "../../session/AuthService";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { connect } from "react-redux";
import { HiOutlineDocument } from "react-icons/hi";
import ChallengeCard from "./ChallengeCard";
import { searchByGroupsAndChallenges } from "../../redux/actions/subjectActions";

var buttonsDatas = [
  {
    value: "a-z",
    label: "A-Z",
  },
  {
    value: "popular",
    label: "Popular",
  },
  {
    value: "new",
    label: "New",
  },
];
// var typeQuestionTop = "unanswered";
// var tpage = 1;
// var subjectId = 0;

const styleArrow = {
  width: 36,
  height: 36,
  boxShadow: "0px 3px 6px #00000029",
  border: "1px solid #DEDEDF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
  borderRadius: "100%",
  cursor: "pointer",
  position: "absolute",
  top: "40%",
  zIndex: 10,
};
function NextArrow(props) {
  const { className, style, onClick, currentSlide, slideCount } = props;
  return currentSlide !== slideCount - 2 ? (
    <div style={{ ...styleArrow, right: "-2%" }} onClick={onClick}>
      <ArrowForwardIcon />
    </div>
  ) : (
    <></>
  );
}

function PrevArrow(props) {
  const { className, style, onClick, currentSlide } = props;
  return currentSlide >= 0 ? (
    <div style={{ ...styleArrow, left: "-3%" }} onClick={onClick}>
      <ArrowBackIcon />
    </div>
  ) : (
    <></>
  );
}
class ChallengeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFieldValue: "",
      tagDatas: [],
      allSubjects: [],
      popularPageNo: 0,
      namePageNo: 0,
      newPageNo: 0,
      noMore: false,
      popularState: false,
      nameState: false,
      newState: false,
      activePage: 1,
      tagDatasState: false,
      questionUi: false,
      blocking: false,
      searchPageNo: 0,
      searchOptionSeeMore: false,
      searchDataValue: "",
      pageNo: 0,
    };

    this.getNameTopics = this.getNameTopics.bind(this);
    this.getNewTopics = this.getNewTopics.bind(this);
    this.choosedValueData = this.choosedValueData.bind(this);
    this.idfromSubjectCard = this.idfromSubjectCard.bind(this);
    this.idfromGroupCard = this.idfromGroupCard.bind(this);
    this.movetosubjects = this.movetosubjects.bind(this);
    this.movetoquestions = this.movetoquestions.bind(this);
    this.movetolibraryquestions = this.movetolibraryquestions.bind(this);
    this.searchSubjects = this.searchSubjects.bind(this);
    this.block = this.block.bind(this);
    this.searchEvents = this.searchEvents.bind(this);
    this.getPopularTopics = this.getPopularTopics.bind(this);
    // this.pageNo = this.pageNo.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.props.searchByGroupsAndChallenges(0, "challenges", "");
    if (this.props.tagDatas.length > 0) {
      // if (this.props.page > 0) {
      //   alert("hi")
      //   var oldSubject = this.state.allSubjects;
      //   console.log("old subject>>",this.state.allSubjects);
      //   var newSubject =oldSubject.concat(this.props.tagDatas);
      //   console.log("new  subject>>",newSubject);
      //   this.setState({ allSubjects: newSubject });
      //   console.log("new state>>",this.state.allSubjects)
      //   this.getNameTopics(this.state.allSubjects);
      // } else {
      //   this.setState({ allSubjects: this.props.tagDatas });
      //   this.getNameTopics(this.props.tagDatas);
      //   //  }
      //   this.setState({ tagDatasState: true });
      //   if (this.props.searchValue != undefined) {
      //     // this.searchSubjects(this.props.searchValue);
      //   }
      // }
      // if (this.props.subId && this.props.redirecttype && !this.state.subjectId) {
      //   this.idfromSubjectCard(this.props.subId, this.props.subId);
      // }
      // if (this.props.searchEventValue != undefined) {
      //   this.searchEvents(this.props.searchEventValue);
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    if (
      this.props.searchValue != undefined &&
      prevProps.searchValue != this.props.searchValue
    ) {
      this.searchSubjects(this.props.searchValue);
    }
    // if (prevProps.searchValue !== this.props.searchValue) {
    //   if (this.props.searchValue != undefined) {
    //      this.searchSubjects(this.props.searchValue);
    //      this.setState({
    //       searchDataValue:prevProps.searchValue
    //     })
    //   }
    // }
    // if (prevProps.tagDatas !== this.props.tagDatas) {
    //   console.log("pageNumber>>", this.props.page);
    //   if (this.props.page > 0) {
    //     //alert("hi")
    //     var oldSubject = this.state.allSubjects;
    //     console.log("old subject>>", this.state.allSubjects);
    //     var newSubject = oldSubject.concat(this.props.tagDatas);
    //     console.log("new  subject>>", newSubject);
    //     this.state.allSubjects = newSubject;
    //     //  this.setState({ allSubjects :  newSubject });
    //     console.log("new state>>", this.state.allSubjects);
    //     this.getNameTopics(this.state.allSubjects);
    //   } else {
    //     this.setState({ allSubjects: this.props.tagDatas });
    //     this.getNameTopics(this.props.tagDatas);
    //   }
    //   // this.setState({ tagDatas: this.props.tagDatas });
    //   this.setState({ tagDatasState: true });
    //   // this.getNameTopics(this.props.tagDatas);
    // }

    // if (prevProps.searchEventValue !== this.props.searchEventValue) {
    //   this.searchEvents(this.props.searchEventValue);
    // }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.searchList != nextProps.searchList) {
      if (this.state.searchOptionSeeMore) {
        if (this.state.searchPageNo > 0) {
          this.setState({
            tagDatas: this.state.tagDatas.concat(nextProps.searchList),
          });
        } else {
          this.setState({
            tagDatas: nextProps.searchList,
          });
        }
      } else {
        if (this.state.pageNo > 0) {
          this.setState({
            tagDatas: this.state.tagDatas.concat(nextProps.searchList),
          });
        } else {
          this.setState({
            tagDatas: nextProps.searchList,
          });
        }
      }
    }
    // if (
    //   this.props.searchValue != undefined &&
    //   this.props.searchList != nextProps.searchList
    // ) {
    //   if (this.state.searchPageNo > 0) {
    //     this.setState({
    //       tagDatas: this.state.tagDatas.concat(nextProps.searchList),
    //     });
    //   } else {
    //     this.setState({
    //       tagDatas: nextProps.searchList,
    //     });
    //   }

    //   // console.log("searchList", this.props.searchList);
    //   // console.log("searchList", nextProps.searchList);
    // }
  }

  render() {
    const { t } = this.props;
    buttonsDatas[0].label = t(buttonsDatas[0].label);
    buttonsDatas[1].label = t(buttonsDatas[1].label);
    buttonsDatas[2].label = t(buttonsDatas[2].label);

    return (
      <div>
        <BlockUi
          tag="div"
          blocking={this.props.subLoading}
          message={t("Loading, please wait")}
          color="#60c2e7"
        >
          <div className="chatWrapperMyEvent">
            <div>
              <Grid container spacing={2}>
                {this.state.tagDatas &&
                  this.state.tagDatas.length > 0 &&
                  this.state.tagDatas.map((subject, index) => (
                    <Grid item xs={12} sm={12} md={3} lg={3} key={index}>
                      <ChallengeCard
                        gotoGroups={this.idfromGroupCard}
                        subjectId={subject.subjectId}
                        subjectName={subject.tagDescription}
                        userId={subject.userId}
                        displayName={subject.displayName}
                        grouporeventFiles={subject.grouporeventFiles}
                        subjectDescription={subject.subjectDescription}
                        fileType={subject.fileType}
                        followCount={subject.subjectFollowByUserCount}
                        questionsCount={subject.questionsCount}
                        createdDate={subject.createdDate}
                        expiryDate={subject.expiryDate}
                        weeksCount={subject.questionsAskedWeek}
                        lastUpdated={subject.lastUpdated}
                        memberShipPrice={subject.memberShipPrice}
                        memberShipType={subject.memberShipType}
                        membersCount={subject.membersCount}
                        event={this.props.event}
                        loginDetails={this.props.loginDetails}
                        tagListId={subject.tagListId}
                        type={subject.type}
                        messageCount={subject.messageCount}
                        tagsValue={subject.tagsValue}
                        searchData={this.props.searchValue}
                      />
                    </Grid>
                  ))}

                {!this.state.searchOptionSeeMore &&
                  this.props.searchList.length === 0 && (
                    <div className="no-data">
                      <p style={{ textAlign: "center" }}>
                        {t("No more Challenge available")}
                      </p>
                    </div>
                  )}
                {
                  this.state.searchOptionSeeMore &&
                  !this.state.searchDataValue == "" && this.props.searchList.length === 0 && (
                    <div className="no-data">
                      <p>{t("No Results found for your search")}</p>
                    </div>
                  )}
              </Grid>
              {!this.state.searchOptionSeeMore && (
                <>
                  {this.props.searchListPage > 0 && this.state.tagDatas.length !== 0 &&
                     this.props.searchListPage - 1 !== this.state.pageNo && (
                      <div
                        style={{
                          margin: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <button onClick={this.pageNo}>{t("See more ")}</button>
                      </div>
                    )}
                  {/* {this.props.pagination > 0 && this.props.page>0 &&
                this.props.pagination - 1 === this.props.page && (
                  <div
                    style={{
                      margin: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                      <p>{t("No More Ch")}</p>
                  </div>
                )} */}
                </>
              )}

              {this.state.searchOptionSeeMore && (
                <>
                  {this.props.searchListPage > 0 &&
                   
                    this.props.searchListPage - 1 !==
                    this.state.searchPageNo && (
                      <div
                        style={{
                          margin: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <button onClick={this.searchOptionSeeMore}>
                          {t("See more")}
                        </button>
                      </div>
                    )}
                  {this.props.searchListPage > 0 &&
                    this.state.searchPageNo > 0 &&
                    this.props.searchListPage - 1 ===
                      this.state.searchPageNo && (
                      <div
                        style={{
                          margin: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {/* <p>{t("No More Results")}</p> */}
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </BlockUi>
      </div>
    );
  }
  searchOptionSeeMore = () => {
    let page = this.state.searchPageNo + 1;

    this.props.searchByGroupsAndChallenges(
      page,
      "challenges",
      this.state.searchDataValue
    );

    this.setState({
      searchPageNo: page,
    });
  };
  pageNo = () => {
    //this.props.pageNo(1);
    let page = this.state.pageNo + 1;
    this.setState({
      pageNo: page,
    });
    this.props.searchByGroupsAndChallenges(page, "challenges",this.state.searchDataValue);
  };
  // pageNo() {
  // this.props.pageNo(1);
  //}
  searchEvents(value) {
    let data = [];
    let tags = [];

    if (value.length > 0) {
      for (var i = 0; i < this.state.allSubjects.length; i++) {
        var subject = this.state.allSubjects[i];
        if (
          subject.tagDescription.toUpperCase().includes(value.toUpperCase())
        ) {
          data.push(subject);
        }
        console.log(subject);

        if (subject.tagListId.length > 0) {
          console.log(subject.tagListId);
          for (let h = 0; h < subject.tagListId.length; h++) {
            var subjectName = new Array();
            for (let j = 0; j < this.props.choosetagList.data.length; j++) {
              if (
                subject.tagListId[h].tagId ==
                this.props.choosetagList.data[j].tagId
              ) {
                subjectName.push(this.props.choosetagList.data[j].tagName);
              }
            }
            console.log(subjectName);
            for (var k = 0; k < subjectName.length; k++) {
              console.log(subjectName[k]);
              console.log(
                subjectName[k].toUpperCase().includes(value.toUpperCase())
              );
              if (subjectName[k].toUpperCase().includes(value.toUpperCase())) {
                tags.push(subject);
              }
            }
          }
        }
        console.log(data);
        let pp = tags.filter(
          (ele, ind) =>
            ind === tags.findIndex((elem) => elem.subjectId === ele.subjectId)
        );

        for (var a = 0; a < data.length; a++) {
          var foundMatch = false;
          console.log("Old_list_item :" + data[a]);
          for (var b = 0; b < pp.length; b++) {
            console.log("new_list_item :" + pp[b]);
            if (data[a].subjectId == pp[b].subjectId) {
              foundMatch = true;
            }
          }
          if (!foundMatch) {
            pp.push(data[a]);
          }
        }
        //  alert(pp.length)
        this.setState({ tagDatas: pp });
      }
    } else {
      this.setState({ tagDatas: this.props.tagDatas });
    }
  }

  block(blocking) {
    this.setState({ blocking: blocking });
  }

  movetoquestions() {
    this.props.gotoquestionpage();
  }

  movetolibraryquestions() {
    this.props.gotolibraryquestionpage();
  }

  movetosubjects() {
    this.setState({ questionUi: false });
  }

  idfromSubjectCard(
    id,
    key,
    name,
    data,
    grouporeventFiles,
    fileType,
    subjectDescription,
    memberShipPrice,
    memberShipType,
    expiryDate,
    createdDate,
    displayName,
    userId,
    startTime,
    endTime,
    eventLocation,
    tagListId,
    startDate,
    type
  ) {
    if (key) {
      this.setState({ subjectId: id });
      this.setState({ subjectName: name });
      this.setState({
        questionUi: true,
        subjectDescription: subjectDescription,
        memberShipPrice: memberShipPrice,
        memberShipType: memberShipType,
        createdDate: createdDate,
        expiryDate: expiryDate,
        grouporeventFiles: grouporeventFiles,
        fileType: fileType,
        displayName: displayName,
        userId: userId,
        type: type,
      });
    }
    this.props.gotoSubjects(
      id,
      key,
      name,
      data,
      this.props.editQuestion,
      this.state.subjectName,
      this.props.subId,
      this.props.redirecttype,
      this.props.movetoQuestions,
      this.props.event,
      grouporeventFiles,
      fileType,
      subjectDescription,
      memberShipPrice,
      memberShipType,
      expiryDate,
      createdDate,
      displayName,
      userId,
      startTime,
      endTime,
      eventLocation,
      tagListId,
      startDate,
      type
    );
  }

  idfromGroupCard(
    id,
    key,
    name,
    data,
    grouporeventFiles,
    fileType,
    subjectDescription,
    memberShipPrice,
    memberShipType,
    expiryDate,
    createdDate,
    displayName,
    userId,
    tagListId,
    type
  ) {
    if (key) {
      this.setState({ subjectId: id });
      this.setState({ subjectName: name });
      this.setState({
        questionUi: true,
        subjectDescription: subjectDescription,
        memberShipPrice: memberShipPrice,
        memberShipType: memberShipType,
        createdDate: createdDate,
        expiryDate: expiryDate,
        grouporeventFiles: grouporeventFiles,
        fileType: fileType,
        displayName: displayName,
        userId: userId,
        type: type,
      });
    }
    this.props.gotoGroups(
      id,
      key,
      name,
      data,
      this.props.editQuestion,
      this.state.subjectName,
      this.props.subId,
      this.props.redirecttype,
      this.props.movetoQuestions,
      this.props.event,
      grouporeventFiles,
      fileType,
      subjectDescription,
      memberShipPrice,
      memberShipType,
      expiryDate,
      createdDate,
      displayName,
      userId,
      tagListId,
      type
    );
  }

  choosedValueData(choosedValue) {
    if (choosedValue === "popular") {
      this.getPopularTopics(this.props.tagDatas);
    }
    if (choosedValue === "a-z") {
      this.getNameTopics(this.props.tagDatas);
    } else if (choosedValue === "new") {
      this.getNewTopics(this.props.tagDatas);
    }
  }

  searchSubjects(value) {
    // alert(value)
    if (value == "") {
      this.setState({
        searchOptionSeeMore: false,
        searchPageNo: 0,
        searchDataValue:value,
      });
    } else {
      this.setState({
        searchOptionSeeMore: true,
        searchPageNo: 0,
        searchDataValue:value,
      });
    }
    this.props.searchByGroupsAndChallenges(0, "challenges", value);
    // let data = [];
    // for (var i = 0; i < this.state.allSubjects.length; i++) {
    //   var subject = this.state.allSubjects[i];
    //   if (subject.tagDescription.toUpperCase().includes(value.toUpperCase())) {
    //     data.push(subject);
    //   }
    // }
    // this.setState({ tagDatas: data });
  }

  getPopularTopics() {
    const sortedActivities = this.props.tagDatas.sort(
      (a, b) => b.questionsCount - a.questionsCount
    );
    this.setState({ tagDatas: sortedActivities });
  }

  getNameTopics(tagDatas) {
    function sort_by_key(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }
    this.setState({ tagDatas: sort_by_key(tagDatas, "subjectNameLC") });
  }

  async getNewTopics(tagDatas) {
    // const newSorted = tagDatas.sort((a, b) => new Date(moment(b.createdDate).format("YYYY-MM-DD")) - new Date(moment(a.createdDate).format("YYYY-MM-DD")));
    let sortedCars1 = tagDatas.sort(
      (a, b) =>
        new Date(...a.createdDate.split("-").reverse()) -
        new Date(...b.createdDate.split("-").reverse())
    );
    console.log("sorted groups");
    console.log(sortedCars1);
    await this.setState({ tagDatas: sortedCars1 });
  }
}
const mapDispacthToProps = (dispatch) => {
  return {
    searchByGroupsAndChallenges: (page, groupOrChallenge, searchKey) =>
      dispatch(searchByGroupsAndChallenges(page, groupOrChallenge, searchKey)),
  };
};

const mapStateToProps = function (state) {
  return {
    choosetagList: state.commonApi.taglist,
    searchList: state.subjects.searchList,
    searchListPage: state.subjects.searchListPage,
    numberOfElements: state.subjects.numberOfElements,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ChallengeList)));
