import { SET_FIREBASE__TOKEN } from "../types";

const initialState = {
    token:""
}

export default function (state = initialState, action) {
    switch (action.type) {
      case SET_FIREBASE__TOKEN:
        console.log("FIREBASE TOKEN:", action)
        return {
          ...state,
          token: action,
        };

      default:
        return state;
    }
  }
  