import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import TextEditorQuestion from "../../components/TextEditorQuestion";
import { Form } from "react-bootstrap";
import { Multiselect } from "react-widgets";
import { withRouter } from "react-router";
import PostService from "../../api/PostService_old";
import { AiOutlineCloseCircle } from "react-icons/ai";
import LearningFileOptionImage from "../LearningApp/LearningFileOptionImage";
import LearningFileOptionVideo from "../LearningApp/LearningFileOptionVideo";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { toast, ToastContainer } from "react-toastify";
import * as APPCONFIG from "../../config/app-config.js";
import { Avatar, Chip } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import SurveyUi from "../Home/HomeUpdated/SurveyUi";
import SelfAssessment from "./AssessmentForm";
import Swal from "sweetalert2";
import {
  getNavigationType,
  subIndustry,
  subSpecialization,
} from "../../redux/actions/commonApiActions";
import {
  BsCameraVideo,
  BsCameraVideoFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
// var slots = [
//   { slot: "09:00 - 14:00", select: false, day: "" },
//   { slot: "00:00 - 05:00", select: false, day: "" },
//   { slot: "15:00 - 20:00", select: false, day: "" },
//   { slot: "06:00 - 11:00", select: false, day: "" },
//   { slot: "14:00 - 19:00", select: false, day: "" },
//   { slot: "05:00 - 10:00", select: false, day: "" },
//   { slot: "20:00 - 01:00", select: false, day: "" },
//   { slot: "11:00 - 16:00", select: false, day: "" },
//   { slot: "19:00 - 00:00", select: false, day: "" },
//   { slot: "10:00 - 15:00", select: false, day: "" },
//   { slot: "01:00 - 06:00", select: false, day: "" },
// ];
class MentorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqDescribed: "",
      description: "",
      faq: "",
      descriptionValue: "",
      firstName: "",
      lastName: "",
      emailData: "",
      password: "",
      phoneNumber: "",
      companyName: "",
      preferred: "",
      paymentType: "",
      memberShipPrice: "",
      Timing: "",
      AllCategory: [],
      specialization: [],
      AreaOfInterestList: [],
      uploadedPhoto: "",
      uploadedVideo: "",
      type: "",
      CategorysList: [],
      allAreaofInterest: [],
      allCityList: [],
      areaofInterest: "",
      city: "",
      faqCount: 0,
      descriptionCount: 0,
      fileOptionImage: false,
      fileOptionVideo: false,
      imageOptionOpen: false,
      videoOptionOpen: false,
      selectPage: true,
      selectPageVideo: true,
      selectPageImage: true,
      fileVideo: [],
      fileImages: [],
      editImage: [],
      mentorId: "",
      mediaFiles: [],
      fileListImage: [],
      fileListVideo: [],
      videoOption: false,
      imageOption: false,
      faqContent: "",
      experience: 0,
      language: "",
      afternoonStart: "",
      afternoonEnd: "",
      morningStart: "",
      morningEnd: "",
      eveningStart: "",
      eveningEnd: "",
      assessmentList: [],
      // morningTimeSelect: false,
      // afternoonTimeSelect: false,
      // eveningTimeSelect: false,
      validationInMorn: false,
      validationInEvn: false,
      validationInAft: false,
      assessmentListUpdated: [],
      removeIds: [],
      slots: [],
      currentWeek: [
        {
          day: "Sunday",
          select: false,
          slot: [],
          backGround: "#b2e2d4",
        },
        {
          day: "Monday",
          select: false,
          slot: [],
          backGround: "#e2b2c0",
        },
        {
          day: "Tuesday",
          select: false,
          slot: [],
          backGround: "#33CEFF",
        },
        {
          day: "Wednesday",
          select: false,
          slot: [],
          backGround: "#C433FF",
        },
        {
          day: "Thursday",
          select: false,
          slot: [],
          backGround: "#33FFB5",
        },
        {
          day: "Friday",
          select: false,
          slot: [],
          backGround: "#FFB833",
        },
        {
          day: "Saturday",
          select: false,
          slot: [],
          backGround: "#FF7D33",
        },
      ],
      selectedDay: "",
      selectedSlot: [],
      selectDayIndex: null,
      needNewForm: false,
      subIndustry: [],
      subSpecialization: [],
      allSpecialization: [],
      industryList: [],
      openDrawer: false,
    };
    this.setTiming = this.setTiming.bind(this);
    this.morningTimeSelect = this.morningTimeSelect.bind(this);
    this.eveningTimeSelect = this.eveningTimeSelect.bind(this);
    this.afternoonTimeSelect = this.afternoonTimeSelect.bind(this);
    this.editorFaqValues = this.editorFaqValues.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.editorDescription = this.editorDescription.bind(this);
    this.preferred = this.preferred.bind(this);
    this.paymentType = this.paymentType.bind(this);
    this.movetoMentorList = this.movetoMentorList.bind(this);
    this.fileOptionEnable = this.fileOptionEnable.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getQuestionMediaFiles = this.getQuestionMediaFiles.bind(this);
    this.loading = this.loading.bind(this);
    this.handledroupDown = this.handledroupDown.bind(this);
    this.videoOptionOpen = this.videoOptionOpen.bind(this);
    this.getQuestionMediaVideoFiles =
      this.getQuestionMediaVideoFiles.bind(this);
    this.getQuestionMediaImageFiles =
      this.getQuestionMediaImageFiles.bind(this);
    this.removeImageFile = this.removeImageFile.bind(this);
    this.getMediaFiles = this.getMediaFiles.bind(this);
    this.fileOptionEnableImage = this.fileOptionEnableImage.bind(this);
    this.fileOptionEnableVideo = this.fileOptionEnableVideo.bind(this);
    this.closeChoosedFileImage = this.closeChoosedFileImage.bind(this);
    this.closeChoosedFileVideo = this.closeChoosedFileVideo.bind(this);
  }
  componentDidMount() {
    // const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    // const today = new Date();
    // const currentDay = today.getDay();
    // const week = [];
    // for (let i = 0; i < 7; i++) {
    //   const date = today.getDate() - currentDay + i;
    //   const dayIndex = (currentDay + i) % 7;
    //   week.push({ date, day: weekdays[dayIndex] });
    // }
    // console.log("week", week);
    // this.setState({
    //   currentWeek: week,
    // });
    console.log("industryList", this.props.industryList);
    this.getSpecial();
    // if (this.props.subIndustryList.length === 0) {
    //   this.props.subIndustry();
    // }
    // if (this.props.subSpecializationList.length === 0) {
    //   this.props.subSpecialization();
    // }
    const list = this.props.countyNameList.map((item) => {
      return { countryId: item.id, countryName: item.nicename };
    });
    this.setState({
      allCityList: list,
    });
    if (this.props.match.params.id === "mentor-request") {
      this.setState({
        needNewForm: true,
        firstName: this.props.loginDetails.fName
          ? this.props.loginDetails.fName
          : "",
        lastName: this.props.loginDetails.lName
          ? this.props.loginDetails.lName
          : "",
      });
    } else {
      this.loading();
    }
  }
  getSpecial = () => {
    var i = {};
    PostService.getAllSpecializtion(i)
      .then((response) => {
        this.setState({
          allSpecialization: response.data.data.Specialization,
        });
        console.log("check", response.data.data.Specialization);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  loading = () => {
    PostService.getTimeSlots()
      .then((response) => {
        var result = response.data.data.timeSlots;
        const extraKeys = result.map((item) => {
          return {
            slot: item.slot,
            select: false,
          };
        });
        console.log("extraKeys", extraKeys);
        const addSlot = this.state.currentWeek.map((item) => {
          item.slot = extraKeys;
          return item;
        });
        console.log("extraKeys", addSlot);

        this.setState({
          slots: extraKeys,
          currentWeek: addSlot,
        });

        // this.updateSlots(selectedList, addSlot);
      })
      .catch((e) => {
        console.log("error", e);
      });

    var id = +this.props.match.params.id;
    var data = {
      mentorId: id,
    };
    PostService.getSingleMentorUser(data)
      .then((response) => {
        var result = response.data.data.mentorList;
        this.setState({
          firstName: result.firstName,
          lastName: result.lastName,
          emailData: result.email,
          phoneNumber: result.phoneNumber,
          companyName: result.companyName,
          preferred: result.communicationType,
          paymentType: result.paymentType,
          memberShipPrice: result.amount ? result.amount : "",
          specialization: result.specialization_value,
          descriptionValue: result.description,
          description: result.description,
          faqDescribed: result.faq,
          mentorId: result.mentorId,
          mediaFiles: result.mediaFiles,
          faqContent: result.faq,
          language: result.language,
          experience: result.exp ? result.exp : 0,
          city: result.country,
          assessmentListUpdated: result.questions,
          selfAssessment: result.selfAssessment,
          // AreaOfInterestList: result.areaOfInterestValue,
          industryList: result.industryIdsValue,
        });
        this.updateSlots(result.timeSlots, this.state.currentWeek);
        this.getMediaFiles(result.mediaFiles);
        this.setTiming(
          result.timeingList,
          result.questions
          // result.subIndustryValue,
          // result.subSpecializationValue
        );
        this.getSpecializationValue(result.specialization);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  updateSlots = (selectedList, fullList) => {
    const updatedWeek = fullList.map((item) => {
      const selectedDay = selectedList.find(
        (selected) => selected.day === item.day
      );
      if (selectedDay) {
        const slotIds = selectedDay.slots.map((data) => data.slot);
        const updatedSlots = item.slot.map((slot) => {
          if (slotIds.includes(slot.slot)) {
            return {
              ...slot,
              select: true,
            };
          }
          return slot;
        });
        return {
          ...item,
          select: true,
          slot: updatedSlots,
        };
      }
      return item;
    });
    console.log("updatedWeek", updatedWeek);
    if (updatedWeek.length !== 0) {
      this.setState({
        selectedDay: "No slot selected",
      });
    }
    this.setState({
      currentWeek: updatedWeek,
    });
  };
  async setTiming(data, questions, subIndustryValue, subSpecializationValue) {
    // data.map((data) => {
    //   console.log("123", data);
    //   if (data.afternoonStart != null && data.afternoonStart != "") {
    //     this.setState({
    //       afternoonEnd: data.afternoonEnd,
    //       afternoonStart: data.afternoonStart,
    //       afternoonTimeSelect: true,
    //     });
    //   } else {
    //     this.setState({
    //       afternoonTimeSelect: false,
    //     });
    //   }
    //   if (data.morningStart != null && data.morningStart != "") {
    //     this.setState({
    //       morningEnd: data.morningEnd,
    //       morningStart: data.morningStart,
    //       morningTimeSelect: true,
    //     });
    //   } else {
    //     this.setState({
    //       morningTimeSelect: false,
    //     });
    //   }
    //   if (data.eveningEnd != null && data.eveningEnd != "") {
    //     this.setState({
    //       eveningEnd: data.eveningEnd,
    //       eveningStart: data.eveningStart,
    //       eveningTimeSelect: true,
    //     });
    //   } else {
    //     this.setState({
    //       eveningTimeSelect: false,
    //     });
    //   }
    // });
    const questionsList = questions.map((item) => {
      return {
        question: item.question,
        answerType: item.answerType,
        answers: item.answer,
        textTypeAnswer: item.answer,
        questionId: item.questionId,
        answerIds: item?.answerType === "select" ? item.answerIds : [],
      };
    });
    // const subIndustry = subIndustryValue.map((item) => {
    //   return {
    //     id: item.id,
    //     name: item.industryValue,
    //   };
    // });
    // const subList = subSpecializationValue.map((item) => {
    //   return {
    //     id: item.id,
    //     name: item.specialisationValue,
    //   };
    // });
    this.setState({
      assessmentList: questionsList ? questionsList : [],
      // subSpecialization: subList,
      // subIndustry: subIndustry,
    });
  }
  getSpecializationValue() {}
  getMediaFiles(files) {
    let videoOption = false;
    let imageOption = false;
    let fileLisVideo = [];
    let fileListImage = [];
    if (files.length !== 0) {
      for (var i = 0; i < files.length; i++) {
        // alert()
        if (files[i].fileType == "image") {
          fileListImage.push(
            files[i]

            // `${APPCONFIG.API_URL}auth/video/${files[i].fileName}`
          );
          imageOption = true;
        }
        if (files[i].fileType == "video") {
          fileLisVideo.push(
            `${APPCONFIG.API_URL}auth/video/${files[i].fileName}`
          );
          videoOption = true;
        }
      }
      this.setState({
        fileImages: fileListImage,
        fileListVideo: fileLisVideo,
        videoOption: videoOption,
        imageOption: imageOption,
      });
      // console.log("check",files)
      console.log("check fileLisVideo", fileLisVideo);
    }
  }
  render() {
    const { morningTimeSelect, eveningTimeSelect, afternoonTimeSelect } =
      this.state;
    const { handleViewAllQuestions, events, subjectId, subjectName } =
      this.props;
    const { t } = this.props;

    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Grid container spacing={1} style={{ marginBottom: "10px" }}>
          <Grid item md={8} sm={12} xs={12} lg={8}>
            <h5 style={{ float: "left", color: "#A00030" }}>
              {this.state.needNewForm ? t("Mentor Form") : t("Update Details")}
            </h5>
          </Grid>
          <Grid item md={4} sm={12} xs={12} lg={4}>
            <p
              onClick={() => this.movetoMentorList()}
              style={{ float: "right", cursor: "pointer", color: "#A00030" }}
            >
              <ArrowBackIcon />
              {t("Back to Mentors List")}
            </p>
          </Grid>
        </Grid>

        <div className="mentorupdateCardWrapper">
          <div className="askAQuestionInputGroup inputLarge">
            <Grid container spacing={2}>
              {!this.state.needNewForm && (
                <Grid item lg={12}>
                  <div
                    className={
                      this.state.openDrawer
                        ? "drawer-bar-active position-relative "
                        : "drawer-bar position-relative"
                    }
                  >
                    <h4
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.setState({ openDrawer: !this.state.openDrawer })
                      }
                    >
                      {t("Update your personal details ,")}
                    </h4>
                    {this.state.openDrawer && (
                      <Grid container spacing={2} className="drawer-body">
                        <Grid item lg={6}>
                          <label className="formLabel">
                            {t("First Name")} *
                          </label>

                          <input
                            type="text"
                            name="firstName"
                            value={this.state.firstName}
                            maxLength="250"
                            onChange={this.handleForm}
                          />
                        </Grid>
                        <Grid item lg={6}>
                          <label className="formLabel">
                            {t("Last Name")} *
                          </label>

                          <input
                            type="text"
                            name="lastName"
                            value={this.state.lastName}
                            maxLength="250"
                            onChange={this.handleForm}
                          />
                        </Grid>
                        {/* <Grid item lg={6}>
                <label className="formLabel">{"Email  *"}</label>

                <input
                  type="email"
                  name="emailData"
                  value={this.state.emailData}
                  maxLength="250"
                  onChange={this.handleForm}
                />
              </Grid> */}
                        {/* <Grid item lg={6} md={6} sm={6} xs={6}>
                <div>
                  <label>Area Specialization</label>
                  <Multiselect
                    dataKey="id"
                    className="profileFormInput aboutInputAddButton"
                    required
                    data={this.state.AllCategory}
                    valueField="id"
                    textField="specialization"
                    value={this.state.specialization}
                    onChange={(value) => this.handleCategoryMultiSelect(value)}
                  />
                </div>
              </Grid> */}
                        <Grid item lg={6}>
                          <label className="formLabel">
                            {t("Phone Number")} *
                          </label>

                          <input
                            type="number"
                            name="phoneNumber"
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 10))
                            }
                            value={this.state.phoneNumber}
                            maxLength="250"
                            onChange={this.handleForm}
                          />
                        </Grid>
                        <Grid item lg={6}>
                          <label className="formLabel">{t("Language")} *</label>

                          <input
                            type="text"
                            name="language"
                            value={this.state.language}
                            maxLength="250"
                            onChange={this.handleForm}
                          />
                        </Grid>{" "}
                      </Grid>
                    )}
                    <BsFillArrowRightCircleFill
                      className={
                        this.state.openDrawer
                          ? "mentor-menu active"
                          : "mentor-menu"
                      }
                      onClick={() =>
                        this.setState({ openDrawer: !this.state.openDrawer })
                      }
                    />
                    {/* <button
                      style={{
                        position: "absolute",
                        top: "21px",
                        right: "12px",
                      }}
                      onClick={() =>
                        this.setState({ openDrawer: !this.state.openDrawer })
                      }
                    >
                      Click
                    </button> */}
                  </div>
                </Grid>
              )}
              {!this.state.needNewForm && (
                <>
                  <Grid item lg={6}>
                    <label className="formLabel">{t("Company Name")} *</label>

                    <input
                      type="text"
                      name="companyName"
                      value={this.state.companyName}
                      maxLength="250"
                      onChange={this.handleForm}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <label className="formLabel">{t("Experience")} *</label>

                    <input
                      type="number"
                      name="experience"
                      value={this.state.experience}
                      maxLength="250"
                      onChange={this.handleForm}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <label className="formLabel">{t("City *")} </label>
                    <TextField
                      id="dropdown"
                      select
                      label=""
                      onChange={(e) => this.handledroupDown(e)}
                      value={this.state.city}
                      name="city"
                      variant="outlined"
                      fullWidth={true}
                    >
                      {this.state.allCityList.map((option, index) => (
                        <MenuItem
                          key={option.countryId}
                          value={option.countryId}
                        >
                          {option.countryName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    lg={this.state.needNewForm ? 12 : 6}
                    md={this.state.needNewForm ? 12 : 6}
                    sm={12}
                    xs={12}
                  >
                    <div className="askAQuestionInputGroup inputLarge">
                      <Form.Group controlId="region">
                        <Form.Label>{t("Meeting Mode*")} </Form.Label>
                        <Form.Control
                          as="select"
                          name="preferred"
                          value={this.state.preferred}
                          onChange={this.preferred}
                        >
                          <option value="">{t("Choose")}</option>
                          <option value="In person">{t("In-Person")}</option>
                          <option value="Video Call">{t("Video Call")}</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </Grid>{" "}
                </>
              )}
            </Grid>
            {!this.state.needNewForm && (
              <div className="askAQuestionInputGroup inputLarge" class="mt-2">
                <Form.Group controlId="region">
                  <Form.Label>{t("Type of Payment*")} </Form.Label>
                  <Form.Control
                    as="select"
                    name="paymentType"
                    value={this.state.paymentType}
                    onChange={this.paymentType}
                  >
                    <option value="">{t("Choose")}</option>
                    <option value="free">{t("Free")}</option>
                    <option value="paid">{t("Paid")}</option>
                  </Form.Control>
                </Form.Group>
              </div>
            )}

            {this.state.paymentType === "paid" && (
              <div className="askAQuestionInputGroup inputLarge">
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <label className="formLabel">{t("Price")}</label>
                  <input
                    type="number"
                    name="memberShipPrice"
                    required
                    value={this.state.memberShipPrice}
                    onChange={this.handleForm}
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 5))
                    }
                  />
                </Grid>
              </div>
            )}
          </div>
          {!this.state.needNewForm && (
            <>
              <label className="formLabel d-flex mb-3">
                {t("Select Available Days  ")}{" "}
                {this.state.selectedDay !== "" && (
                  <p className="text-primary"> - {this.state.selectedDay}</p>
                )}
              </label>

              <div className="mentor-time-slot mb-3">
                {this.state.currentWeek.map((item, index) => (
                  <div
                    className={item.select ? "mentor-selected" : ""}
                    onClick={() =>
                      this.selectDayAction(item.day, item.select, index, false)
                    }
                    key={index}
                  >
                    <span>{item.day}</span>
                  </div>
                ))}
              </div>
              {this.state.selectedDay && (
                <div className="mentor-slot-chip mb-3">
                  {this.state.currentWeek.map((item, index) => (
                    <>
                      {item.select && (
                        <Chip
                          style={{
                            overflow: "hidden",
                            Whitespace: "nowrap",
                            fontSize: "12px",
                            textOverflow: "ellipsis",
                            textTransform: "capitalize",
                            // color:
                            //   this.state.currentWeek[this.state.selectDayIndex]
                            //     .backGround,
                          }}
                          avatar={<Avatar>{item.day.substring(0, 2)}</Avatar>}
                          label={item.day}
                          onDelete={() =>
                            this.selectDayAction(item.day, true, index, true)
                          }
                        />
                      )}
                    </>
                  ))}
                </div>
              )}

              <label className="formLabel">{t("Available Slots *")}</label>
              <div className="mentor-slot mb-3">
                {this.checkDayIsSelected() ? (
                  <>
                    {this.state.slots.map((item, index) => (
                      <span onClick={() => this.selectSlot(true)}>
                        {item.slot}
                      </span>
                    ))}
                  </>
                ) : (
                  <>
                    {this.state.currentWeek[this.state.selectDayIndex].slot.map(
                      (item, index) => (
                        <span
                          style={{
                            background: item.select
                              ? `${
                                  this.state.currentWeek[
                                    this.state.selectDayIndex
                                  ].backGround
                                }`
                              : "",
                          }}
                          className={item.select ? "mentor-slot-select" : ""}
                          onClick={() =>
                            this.selectSlot(false, item.select, index)
                          }
                        >
                          {item.slot}
                        </span>
                      )
                    )}
                  </>
                )}
              </div>

              {!this.checkDayIsSelected() && this.state.selectedDay && (
                <div className="mentor-slot-chip mb-3">
                  {this.state.currentWeek[this.state.selectDayIndex].slot.map(
                    (item, index) => (
                      <>
                        {item.select && (
                          <Chip
                            style={{
                              overflow: "hidden",
                              Whitespace: "nowrap",
                              fontSize: "12px",
                              textOverflow: "ellipsis",
                              textTransform: "capitalize",
                              // color:
                              //   this.state.currentWeek[this.state.selectDayIndex]
                              //     .backGround,
                            }}
                            avatar={
                              <Avatar>
                                {this.state.selectedDay.substring(0, 2)}
                              </Avatar>
                            }
                            label={item.slot}
                            onDelete={() =>
                              this.selectSlot(false, item.select, index)
                            }
                          />
                        )}
                      </>
                    )
                  )}
                  {this.checkSlotAvailable(
                    this.state.currentWeek[this.state.selectDayIndex].slot
                  ) && (
                    <p>
                      There is no slot selected in - {this.state.selectedDay}
                    </p>
                  )}
                </div>
              )}
            </>
          )}
          {/* <Grid container spacing={1}>
            <Grid item lg={4} style={{ display: "inline-flex" }}>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Selected Morining Time
              </p>
              <div className="boxcolor" style={{ marginLeft: "11px" }}>
                <input
                  type="checkbox"
                  value={morningTimeSelect}
                  defaultChecked={morningTimeSelect}
                  id="morningTimeSelect"
                  onClick={this.morningTimeSelect}
                />
              </div>
            </Grid>
            <Grid item lg={4} style={{ display: "inline-flex" }}>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Select Afternoon Time
              </p>
              <div className="boxcolor" style={{ marginLeft: "11px" }}>
                <input
                  type="checkbox"
                  value={afternoonTimeSelect}
                  defaultChecked={afternoonTimeSelect}
                  id="afternoonTimeSelect"
                  onClick={this.afternoonTimeSelect}
                />
              </div>
            </Grid>
            <Grid item lg={4} style={{ display: "inline-flex" }}>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Select Evening Time
              </p>
              <div className="boxcolor" style={{ marginLeft: "11px" }}>
                <input
                  type="checkbox"
                  value={eveningTimeSelect}
                  defaultChecked={eveningTimeSelect}
                  id="eveningTimeSelect"
                  onClick={this.eveningTimeSelect}
                />
              </div>
            </Grid>
          </Grid> */}
          {/* <Grid container spacing={1}>
            <Grid item lg={2} md={6} sm={6} xs={6}>
              {morningTimeSelect && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TextField
                    id="date"
                    label="Morning Start"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth={true}
                    value={this.state.morningStart}
                    variant="filled"
                    name="morningStart"
                    style={{ marginTop: 16 }}
                    onChange={this.handleForm}
                  />
                </MuiPickersUtilsProvider>
              )}
            </Grid>
            <Grid item lg={2} md={6} sm={6} xs={6}>
              {morningTimeSelect && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TextField
                    id="date"
                    label="Morning End"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth={true}
                    value={this.state.morningEnd}
                    variant="filled"
                    name="morningEnd"
                    style={{ marginTop: 16 }}
                    onChange={this.handleForm}
                  />
                </MuiPickersUtilsProvider>
              )}
            </Grid>
            <Grid item lg={2} md={6} sm={6} xs={6}>
              {afternoonTimeSelect && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TextField
                    id="time"
                    label="AfterNoon Start"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth={true}
                    value={this.state.afternoonStart}
                    variant="filled"
                    name="afternoonStart"
                    style={{ marginTop: 16 }}
                    onChange={this.handleForm}
                  />
                </MuiPickersUtilsProvider>
              )}
            </Grid>
            <Grid item lg={2} md={6} sm={6} xs={6}>
              {afternoonTimeSelect && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TextField
                    id="date"
                    label="AfterNoon End"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth={true}
                    value={this.state.afternoonEnd}
                    variant="filled"
                    name="afternoonEnd"
                    style={{ marginTop: 16 }}
                    onChange={this.handleForm}
                  />
                </MuiPickersUtilsProvider>
              )}
            </Grid>
            <Grid item lg={2} md={6} sm={6} xs={6}>
              {eveningTimeSelect && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TextField
                    id="date"
                    label="Evening Start"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth={true}
                    value={this.state.eveningStart}
                    variant="filled"
                    name="eveningStart"
                    style={{ marginTop: 16 }}
                    onChange={this.handleForm}
                  />
                </MuiPickersUtilsProvider>
              )}
            </Grid>
            <Grid item lg={2} md={6} sm={6} xs={6}>
              {eveningTimeSelect && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TextField
                    id="time"
                    label="Evening End "
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth={true}
                    value={this.state.eveningEnd}
                    variant="filled"
                    name="eveningEnd"
                    style={{ marginTop: 16 }}
                    onChange={this.handleForm}
                  />
                </MuiPickersUtilsProvider>
              )}
            </Grid>
          </Grid> */}
          {/* <label className="formLabel">Timing</label>
              <TextField
                defaultValue="01:00"
                name="afternoonStart"
                type="time"
                height="20px"
                required
                value={this.state.afternoonStart}
                onChange={this.handleForm}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ step: 300 }}
              /> */}
          <Grid container spacing={2} style={{ padding: "0.5rem" }}>
            <Grid lg={6} md={6} sm={12} xs={12}>
              <label className="mt-2">{t("Specialization")} *</label>
              <Multiselect
                dataKey="id"
                className="profileFormInput aboutInputAddButton"
                required
                data={this.state.allSpecialization}
                valueField="id"
                textField="specialization"
                value={this.state.specialization}
                onChange={(value) => this.handleCategoryMultiSelect(value)}
              />
              {/* <label>Select Category</label>
                <Multiselect
                  required
                  options={this.state.AllCategory}
                  valueField="challengesId"
                  textField="challengesName"
                  selectedOptions={this.state.specialization}
                  onChange={(value) => this.handleCategoryMultiSelect(value)}
                ></Multiselect> */}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <label>{t("Industry")} *</label>
              {/* <Multiselect
                dataKey="id"
                className="profileFormInput aboutInputAddButton"
                required
                data={this.props.industryList}
                valueField="areaId"
                textField="areaName"
                value={this.state.AreaOfInterestList}
                onChange={(value) =>
                  this.handleAreaOfInterestMultiSelect(value)
                }
              ></Multiselect> */}
              <Multiselect
                dataKey="id"
                className="profileFormInput aboutInputAddButton"
                required
                data={this.props.industryList}
                valueField="industryId"
                textField="industryName"
                value={this.state.industryList}
                onChange={(value) =>
                  this.handleAreaOfInterestMultiSelect(value)
                }
              ></Multiselect>
            </Grid>

            {/* <Grid lg={6} md={6} sm={12} xs={12}>
              <label className="mt-2">{t("Secondary Specialization")} </label>
              <Multiselect
                dataKey="id"
                className="profileFormInput aboutInputAddButton"
                required
                data={
                  this.props.subSpecializationList
                    ? this.props.subSpecializationList
                    : []
                }
                valueField="id"
                textField="name"
                value={this.state.subSpecialization}
                onChange={(value) => this.handleSubSpecial(value)}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <label>{t("Secondary Industry")} </label>
              <Multiselect
                dataKey="id"
                className="profileFormInput aboutInputAddButton"
                required
                data={
                  this.props.subIndustryList ? this.props.subIndustryList : []
                }
                valueField="id"
                textField="name"
                value={this.state.subIndustry}
                onChange={(value) => this.handleSubIndustry(value)}
              ></Multiselect>
            </Grid> */}
          </Grid>
          <div className="askAQuestionDescription mt-1">
            <label className="">{t("About")} *</label>

            <TextEditorQuestion
              ref={this.myRef}
              editorStateValue={this.editorDescription}
              editorValue={this.state.descriptionValue}
            />
            <p
              className="boxText"
              style={
                this.state.descriptionCount > 2500
                  ? { color: "red" }
                  : { color: "green" }
              }
            >
              {" "}
              {2500 - this.state.descriptionCount} {"characters left"}
            </p>
          </div>
          {!this.state.needNewForm && (
            <>
              <div className="askAQuestionDescription">
                <label className="formLabel">{t("FAQ")} *</label>

                <TextEditorQuestion
                  ref={this.myRef}
                  editorStateValue={this.editorFaqValues}
                  editorValue={this.state.faqDescribed}
                />
                <p
                  className="boxText"
                  style={
                    this.state.faqCount > 2500
                      ? { color: "red" }
                      : { color: "green" }
                  }
                >
                  {" "}
                  {2500 - this.state.faqCount} {"characters left"}
                </p>
              </div>

              <div className="d-flex flex-column mt-2 " style={{ gap: "2rem" }}>
                <div
                  className="d-flex align-items-center  "
                  style={{ gap: "1rem" }}
                >
                  <label className="time-slot p-0 m-0">
                    {t("Need user Self-assessment")}
                  </label>
                  <input
                    type="checkbox"
                    value={this.state.selfAssessment}
                    defaultChecked={this.state.selfAssessment}
                    id="selfAssessment"
                    onClick={this.selfAssessmentClick}
                  />
                </div>
                {this.state.selfAssessment && (
                  <div className="selfAssessment-form">
                    <SelfAssessment
                      editState={true}
                      assessmentListUpdated={this.state.assessmentListUpdated}
                      surveyQuestion={this.selfAssessmentQuestion}
                      removedQuestionId={this.removedQuestionId}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          <Grid container spacing={1}>
            <Grid item md={12} sm={5} xs={5} lg={12}>
              {this.state.imageOptionOpen && (
                <>
                  {this.state.selectPageImage && (
                    <AiOutlineCloseCircle
                      onClick={this.closeChoosedFileImage}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        marginBottom: "8px",
                        width: "20px",
                        height: "20px",
                        color: "#000000",
                      }}
                    />
                  )}

                  <LearningFileOptionImage
                    fileTypeKey="image"
                    fileOption={this.state.fileOptionImage}
                    fileOptionEnable={this.fileOptionEnableImage}
                    fromQuestionImage={this.getQuestionMediaImageFiles}
                    editObject={this.props.editObject}
                    grouporeventFiles={this.state.grouporeventFiles}
                    selectPage={this.state.selectPageImage}
                  ></LearningFileOptionImage>
                </>
              )}
              {this.state.videoOptionOpen && (
                <>
                  {this.state.selectPageVideo && (
                    <AiOutlineCloseCircle
                      onClick={this.closeChoosedFileVideo}
                      style={{
                        cursor: "pointer",
                        float: "right",
                        marginBottom: "8px",
                        width: "20px",
                        height: "20px",
                        color: "#000000",
                      }}
                    />
                  )}
                  <LearningFileOptionVideo
                    fileOption={this.state.fileOptionVideo}
                    fileOptionEnable={this.fileOptionEnableVideo}
                    fromQuestionMedia={this.getQuestionMediaVideoFiles}
                    editObject={this.props.editObject}
                    grouporeventFiles={this.state.grouporeventFiles}
                    selectPage={this.state.selectPageVideo}
                  ></LearningFileOptionVideo>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={12} sm={5} xs={5} lg={12}>
              {this.state.imageOption &&
                this.state.fileImages &&
                this.state.fileImages.length > 0 && (
                  <div key={this.state.fileImages[0].originalFileName}>
                    <Chip
                      icon={<AttachmentIcon />}
                      className="fileChip"
                      label={this.state.fileImages[0].originalFileName}
                      size="small"
                      onDelete={() => this.removeImageFile()}
                      variant="outlined"
                    />
                    {
                      <img
                        style={{
                          margin: "8px",
                          width: "80px",
                          height: "80px",
                        }}
                        src={`${APPCONFIG.API_URL}auth/image/${this.state.fileImages[0].fileName}`}
                      ></img>
                    }
                  </div>
                )}{" "}
            </Grid>
            <Grid item md={12} sm={5} xs={5} lg={12}>
              {this.state.videoOption &&
                this.state.fileListVideo &&
                this.state.fileListVideo.map((photo) => (
                  <video
                    style={{
                      width: "15rem",
                      height: "12rem",
                      paddingBottom: "15px",
                    }}
                    controls
                  >
                    <source src={photo} />
                  </video>
                ))}
            </Grid>
          </Grid>
          {/* <Grid container spacing={1}>
            <Grid item md={6} sm={6} xs={6} lg={6}> */}
          <div
            style={{ cursor: "pointer", color: "#A00030" }}
            className="d-flex flex-wrap mt-2 justify-content-between"
          >
            <div onClick={() => this.fileOptionEnableImage()}>
              <CameraAltIcon className="mr-1" /> Upload your mentoring category
              related image
            </div>
            {!this.state.needNewForm && (
              <div onClick={() => this.fileOptionEnableVideo()}>
                <BsCameraVideoFill
                  style={{
                    marginRight: "6px",
                    height: "24px",
                    width: "auto",
                  }}
                />
                Upload your topic related video
              </div>
            )}
          </div>{" "}
          {/* </Grid> */}
          {/* <Grid
                style={{ cursor: "pointer", color: "#A00030" }}
                item
                md={6}
                sm={6}
                xs={6}
                lg={6}
              >
                <div onClick={this.fileOptionEnableVideo}>
                  <VideocamIcon />
                  video
                </div>
              </Grid>{" "} */}
          {/* </Grid> */}
          <div className="askAQuestionBtn" style={{ paddingTop: "20px" }}>
            {this.state.needNewForm ? (
              <button onClick={(e) => this.createMentor(e)}>
                {t("Create Mentor")}
              </button>
            ) : (
              <button onClick={(e) => this.onFormSubmitChallenge(e)}>
                {t("Update Details")}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
  checkSlotAvailable = (array) => {
    const check = array.every((item) => item.select === false);
    return check;
  };

  checkDayIsSelected = () => {
    if (this.state.selectDayIndex === null) {
      return true;
    } else {
      const check = this.state.currentWeek.every(
        (item) => item.select === false
      );
      return check;
    }
  };

  selectDayAction = (day, isSelect, selectIndex, needChange) => {
    if (!isSelect || needChange) {
      const list = this.state.currentWeek.map((item, index) => {
        if (index === selectIndex) {
          item.select = !isSelect;
        }
        return item;
      });
      console.log("newSlot selectedDay", list);
      console.log("newSlot updateSlot", selectIndex);

      this.setState({
        currentWeek: list,
      });
      if (!needChange) {
        this.setState({
          selectDayIndex: isSelect === false ? selectIndex : null,
          selectedDay: needChange ? null : day,
        });
      } else if (this.state.selectedDay === day) {
        this.setState({
          selectDayIndex: null,
          selectedDay: "No one Select",
        });
      }
    } else {
      this.setState({
        selectedDay: day,
        selectDayIndex: selectIndex,
        // currentWeek: list,
      });
    }
  };

  selectSlot = (tost, isSelect, itemIndex) => {
    if (tost) {
      toast.warning("Select One available day");
    } else {
      const updatedSlot = this.state.currentWeek[
        this.state.selectDayIndex
      ].slot.map((item, index) => {
        if (itemIndex === index) {
          return {
            ...item,
            select: !isSelect,
          };
        }
        return item;
      });

      const newWeek = this.state.currentWeek.map((item, index) => {
        if (this.state.selectDayIndex === index) {
          return {
            ...item,
            slot: updatedSlot,
          };
        }
        return item;
      });

      this.setState({
        currentWeek: newWeek,
      });

      console.log("newSlot newSlot", newWeek);
    }
  };

  selfAssessmentClick = () => {
    this.setState({
      selfAssessment: !this.state.selfAssessment,
    });
  };
  removedQuestionId = (data) => {
    let id = [...this.state.removeIds, data];
    this.setState({
      removeIds: id,
    });
  };
  selfAssessmentQuestion = (data) => {
    console.log("assessment", data);
    this.setState({
      assessmentList: data,
    });
  };
  closeChoosedFileImage() {
    this.setState({
      selectPageImage: false,
    });
  }
  closeChoosedFileVideo() {
    this.setState({
      selectPageVideo: false,
    });
  }
  removeImageFile() {
    this.setState({
      imageOption: false,
      fileImages: [],
    });
  }
  createMentor = () => {
    const formData = new FormData();
    if (this.state.specialization.length === 0) {
      toast.warning(`${this.props.t("Select specialization list")}`);
    } else if (this.state.industryList.length === 0) {
      toast.warning(`${this.props.t("Select industry list")}`);
    } else if (this.state.description === "") {
      toast.warning(`${this.props.t("Share some  details in about filed")}`);
    } else {
      if (this.state.editImage.length !== 0) {
        console.log("fileImages", this.state.editImage);
        for (let i = 0; i < this.state.editImage.length; i++) {
          formData.append("mentorImage", this.state.editImage[i]);
        }
      }
      var specialization = [];
      this.state.specialization.forEach((data) => {
        specialization.push(data.id);
      });
      console.log("specialization", specialization);
      var industryList = [];
      this.state.industryList &&
        this.state.industryList.forEach((data) => {
          industryList.push(data.industryId);
        });
      // if (this.state.subSpecialization.length !== 0) {
      //   const subSpecial = this.state.subSpecialization.map((item) => item.id);
      //   formData.append("subSpecializationIds", subSpecial);
      // }
      // if (this.state.subIndustry.length !== 0) {
      //   const subIndustry = this.state.subIndustry.map((item) => item.id);
      //   formData.append("subIndustryIds", subIndustry);
      // }

      formData.append("specialization", specialization);
      formData.append("industryIds", industryList);
      formData.append("description", this.state.description);

      // formData.append("companyName", this.state.companyName);
      // formData.append("experience", this.state.experience);
      //  formData.append("communicationType", this.state.preferred);
      // formData.append("paymentType", this.state.paymentType);
      // if (this.state.paymentType === "paid") {
      //   formData.append("amount", this.state.memberShipPrice);
      // }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      PostService.mentorRegistration(formData, config)
        .then((response) => {
          const mentorId = response.data.data?.mentor?.mentorId;
          console.log("result", mentorId);

          this.movetoMentorList(mentorId);
          toast.success(`${this.props.t("Mentor slot created successfully")}`);
        })
        .catch((error) => {
          this.movetoMentorList(null);
          toast.error("Saving issue");

          console.log(error);
        });
    }
  };
  checkSlotsIsPresent = (array) => {
    const check = array.every((item) => item.slots.length === 0);
    return check;
  };
  onFormSubmitChallenge() {
    console.log("assessmentList", this.state.assessmentList);
    const filteredWeek = this.state.currentWeek
      .filter((day) => day.select)
      .map((day) => ({
        day: day.day,
        slots: day.slot.filter((slot) => slot.select).map((slot) => slot.slot),
      }));

    console.log("filteredWeek", filteredWeek);

    const formData = new FormData();
    if (this.state.firstName === "") {
      toast.warning(`${this.props.t("Please add firstName")}`);
    } else if (this.state.lastName == "") {
      toast.warning(`${this.props.t("Please add lastName")}`);
    } else if (this.state.phoneNumber == "") {
      toast.warning(`${this.props.t("Please add phoneNumber")}`);
    } else if (this.state.language == "") {
      toast.warning(`${this.props.t("Please add  language")}`);
    } else if (
      this.state.companyName == "" ||
      this.state.faqContent == "" ||
      this.state.description == "" ||
      this.state.paymentType == "" ||
      this.state.preferred == ""
      // this.state.specialization?.length == 0
      // ||
      // this.state.experience == ""
    ) {
      toast.warning(`${this.props.t("Please select all the filed")}`);
    } else if (
      this.state.paymentType == "paid" &&
      this.state.memberShipPrice == ""
    ) {
      toast.warning(`${this.props.t("Enter your  required amount")}`);
    }
    // else if (this.state.subIndustry.length === 0) {
    //   toast.warning("Select Sub-Industry list");
    // }
    //  else if (this.state.subSpecialization.length === 0) {
    //   toast.warning("Select Sub-Specialization list");
    // }
    // else if (this.state.AreaOfInterestList.length === 0) {
    //   toast.warning("Select Industry list");
    // }
    else if (this.state.specialization.length === 0) {
      toast.warning(`${this.props.t("Select Area specialization list")}`);
    } else if (
      this.state.selfAssessment &&
      this.state.assessmentList?.length === 0
    ) {
      toast.warning(`${this.props.t("Enter assessment from details")}`);
    } else if (filteredWeek && filteredWeek.length === 0) {
      toast.warning(`${this.props.t("Select any one of the available day")}`);
    } else if (this.checkSlotsIsPresent(filteredWeek)) {
      toast.warning(
        `${this.props.t("Select any one of the available TimeSlot")}`
      );
    }
    // else if (
    //   !this.state.morningStart &&
    //   !this.state.morningEnd &&
    //   !this.state.afternoonStart &&
    //   !this.state.afternoonEnd &&
    //   !this.state.eveningStart &&
    //   !this.state.eveningEnd
    // ) {
    //   toast.warning("Select any one of the time slot");
    // }
    else {
      // if (this.state.afternoonStart) {
      //   formData.append("afternoonStart", this.state.afternoonStart);
      // }
      // if (this.state.afternoonEnd) {
      //   formData.append("afternoonEnd", this.state.afternoonEnd);
      // }
      // if (this.state.eveningStart) {
      //   formData.append("eveningStart", this.state.eveningStart);
      // }
      // if (this.state.eveningEnd) {
      //   formData.append("eveningEnd", this.state.eveningEnd);
      // }
      // if (this.state.morningStart) {
      //   formData.append("morningStart", this.state.morningStart);
      // }
      // if (this.state.morningEnd) {
      //   formData.append("morningEnd", this.state.morningEnd);
      // }
      var specialization = [];
      this.state.specialization.forEach((data) => {
        specialization.push(data.id);
      });
      var industryList = [];
      this.state.industryList &&
        this.state.industryList.forEach((data) => {
          industryList.push(data.industryId);
        });
      formData.append("industryIds", industryList);

      if (
        !this.state.editImage.length == 0 &&
        this.state.fileVideo.length == 0
      ) {
        //  alert('image')
        //image
        if (this.state.paymentType == "paid") {
          formData.append("languages", this.state.language);
          formData.append("exp", this.state.experience);
          formData.append("firstName", this.state.firstName);
          formData.append("lastName", this.state.lastName);
          formData.append("email", this.state.emailData);
          formData.append("mentorId", this.state.mentorId);
          formData.append("amount", this.state.memberShipPrice);
          formData.append("communicationType", this.state.preferred);
          formData.append("companyName", this.state.companyName);
          formData.append("phoneNumber", this.state.phoneNumber);
          formData.append("faq", this.state.faqContent);
          formData.append("paymentType", this.state.paymentType);
          formData.append("description", this.state.description);
          formData.append("specialization", specialization);

          if (this.state.editImage.length > 0) {
            for (let i = 0; i < this.state.editImage.length; i++) {
              formData.append("mentorImage", this.state.editImage[i]);
            }
          }
        } else {
          formData.append("languages", this.state.language);
          formData.append("exp", this.state.experience);
          formData.append("firstName", this.state.firstName);
          formData.append("lastName", this.state.lastName);
          formData.append("email", this.state.emailData);
          if (this.state.editImage.length > 0) {
            for (let i = 0; i < this.state.editImage.length; i++) {
              formData.append("mentorImage", this.state.editImage[i]);
            }
          }
          formData.append("country", this.state.city);
          formData.append("communicationType", this.state.preferred);
          formData.append("companyName", this.state.companyName);
          formData.append("phoneNumber", this.state.phoneNumber);
          formData.append("faq", this.state.faqContent);
          formData.append("paymentType", this.state.paymentType);
          formData.append("description", this.state.description);
          formData.append("specialization", specialization);
          formData.append("mentorId", this.state.mentorId);
          formData.append("amount", "");
        }
      } else if (
        this.state.editImage.length == 0 &&
        !this.state.fileVideo.length == 0
      ) {
        //Video
        // alert("video")
        if (this.state.paymentType == "paid") {
          formData.append("languages", this.state.language);
          formData.append("exp", this.state.experience);
          formData.append("firstName", this.state.firstName);
          formData.append("lastName", this.state.lastName);
          formData.append("email", this.state.emailData);
          formData.append("password", this.state.password);
          if (this.state.fileVideo.length > 0) {
            for (let i = 0; i < this.state.fileVideo.length; i++) {
              formData.append("mentorVideo", this.state.fileVideo[i]);
            }
          }
          formData.append("mentorId", this.state.mentorId);
          formData.append("amount", this.state.memberShipPrice);
          formData.append("communicationType", this.state.preferred);
          formData.append("companyName", this.state.companyName);
          formData.append("phoneNumber", this.state.phoneNumber);
          formData.append("faq", this.state.faqContent);
          formData.append("paymentType", this.state.paymentType);
          formData.append("description", this.state.description);
          formData.append("specialization", specialization);
        } else {
          formData.append("languages", this.state.language);
          formData.append("exp", this.state.experience);
          formData.append("firstName", this.state.firstName);
          formData.append("lastName", this.state.lastName);
          formData.append("email", this.state.emailData);
          formData.append("mentorId", this.state.mentorId);
          if (this.state.fileVideo.length > 0) {
            for (let i = 0; i < this.state.fileVideo.length; i++) {
              formData.append("mentorVideo", this.state.fileVideo[i]);
            }
          }
          formData.append("country", this.state.city);
          formData.append("communicationType", this.state.preferred);
          formData.append("companyName", this.state.companyName);
          formData.append("phoneNumber", this.state.phoneNumber);
          formData.append("faq", this.state.faqContent);
          formData.append("paymentType", this.state.paymentType);
          formData.append("description", this.state.description);
          formData.append("specialization", specialization);
        }
      } else if (
        !this.state.editImage.length == 0 &&
        !this.state.fileVideo.length == 0
      ) {
        //Img and video

        if (this.state.paymentType == "paid") {
          formData.append("languages", this.state.language);
          formData.append("exp", this.state.experience);
          formData.append("firstName", this.state.firstName);
          formData.append("lastName", this.state.lastName);
          formData.append("email", this.state.emailData);
          formData.append("mentorId", this.state.mentorId);
          if (this.state.fileVideo.length > 0) {
            for (let i = 0; i < this.state.fileVideo.length; i++) {
              formData.append("mentorVideo", this.state.fileVideo[i]);
            }
          }
          if (this.state.editImage.length > 0) {
            for (let i = 0; i < this.state.editImage.length; i++) {
              formData.append("mentorImage", this.state.editImage[i]);
            }
          }
          formData.append("amount", this.state.memberShipPrice);
          formData.append("communicationType", this.state.preferred);
          formData.append("companyName", this.state.companyName);
          formData.append("phoneNumber", this.state.phoneNumber);
          formData.append("faq", this.state.faqContent);
          formData.append("paymentType", this.state.paymentType);
          formData.append("description", this.state.description);
          formData.append("specialization", specialization);
        } else {
          formData.append("amount", "");
          formData.append("languages", this.state.language);
          formData.append("exp", this.state.experience);
          formData.append("mentorId", this.state.mentorId);
          formData.append("firstName", this.state.firstName);
          formData.append("lastName", this.state.lastName);
          formData.append("email", this.state.emailData);
          if (this.state.fileVideo.length > 0) {
            for (let i = 0; i < this.state.fileVideo.length; i++) {
              formData.append("mentorVideo", this.state.fileVideo[i]);
            }
          }
          if (this.state.editImage.length > 0) {
            for (let i = 0; i < this.state.editImage.length; i++) {
              formData.append("mentorImage", this.state.editImage[i]);
            }
          }

          formData.append("communicationType", this.state.preferred);
          formData.append("companyName", this.state.companyName);
          formData.append("phoneNumber", this.state.phoneNumber);
          formData.append("faq", this.state.faqContent);
          formData.append("paymentType", this.state.paymentType);
          formData.append("description", this.state.description);
          formData.append("specialization", specialization);
        }
      } else {
        //No image video
        if (this.state.paymentType == "paid") {
          formData.append("languages", this.state.language);
          formData.append("exp", this.state.experience);
          formData.append("mentorId", this.state.mentorId);
          formData.append("firstName", this.state.firstName);
          formData.append("lastName", this.state.lastName);
          formData.append("email", this.state.emailData);
          formData.append("amount", this.state.memberShipPrice);
          formData.append("communicationType", this.state.preferred);
          formData.append("companyName", this.state.companyName);
          formData.append("phoneNumber", this.state.phoneNumber);
          formData.append("faq", this.state.faqContent);
          formData.append("paymentType", this.state.paymentType);
          formData.append("description", this.state.description);
          formData.append("specialization", specialization);
        } else {
          formData.append("languages", this.state.language);
          formData.append("exp", this.state.experience);
          formData.append("mentorId", this.state.mentorId);
          formData.append("firstName", this.state.firstName);
          formData.append("lastName", this.state.lastName);
          formData.append("email", this.state.emailData);
          formData.append("amount", "");
          formData.append("communicationType", this.state.preferred);
          formData.append("companyName", this.state.companyName);
          formData.append("phoneNumber", this.state.phoneNumber);
          formData.append("faq", this.state.faqContent);
          formData.append("paymentType", this.state.paymentType);
          formData.append("description", this.state.description);
          formData.append("specialization", specialization);
        }
      }
      // formData.append("areaOfInterest", AreaOfInterestList);
      formData.append("selfAssessment", this.state.selfAssessment);
      formData.append("removeIds", this.state.removeIds);

      if (this.state.selfAssessment && this.state.assessmentList) {
        const data = this.state.assessmentList.map((item) => {
          if (item.questionId) {
            return {
              question: item.question,
              answer:
                item.answerType === "select"
                  ? item.answerIds
                  : item.textTypeAnswer,
              answerType: item.answerType,
              questionId: item.questionId,
            };
          } else {
            return {
              question: item.question,
              answer:
                item.answerType === "select"
                  ? item.answerIds
                  : item.textTypeAnswer,
              answerType: item.answerType,
            };
          }
        });
        const obj = { questions: data };
        formData.append("selfAssessmentDatas", JSON.stringify(obj));
      }
      if (filteredWeek) {
        formData.append("timeSlots", JSON.stringify(filteredWeek));
      }
      // const subSpecial = this.state.subSpecialization.map((item) => item.id);
      // const subIndustry = this.state.subIndustry.map((item) => item.id);
      // formData.append("subIndustryIds", subIndustry);
      // formData.append("subSpecializationIds", subSpecial);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      PostService.updateMentorUser(formData, config)
        .then((response) => {
          this.movetoMentorList();
          toast.success(`${this.props.t("Mentor updated successfully")}`);
        })
        .catch((error) => {
          this.movetoMentorList();

          console.log(error);
        });
    }
  }

  handleChange(event) {
    let files = event.target.files[0];
    this.setState(
      {
        uploadedVideo: files,
        fileUrl: URL.createObjectURL(event.target.files[0]),
        type: event.target.files[0].type,
      },
      () => {
        console.log(this.state.type);
      }
    );
  }
  fileOptionEnable() {
    this.setState({ fileOption: false, questionFile: null, uploadedPhoto: "" });
  }
  async getQuestionMediaFiles(files, type) {
    console.log("files", files);
    await this.setState({ uploadedPhoto: files, type: type, imgView: false });
  }
  handleForm(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  editorDescription(values) {
    console.log("editorStateValues", values);
    console.log(values);
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        description: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        description: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  }
  movetoMentorList(mentorId) {
    if (this.state.needNewForm) {
      if (mentorId) {
        this.props.history.push(
          `/${this.props.workspaceType}/mentor-description/${mentorId}`
        );
      } else {
        this.props.history.push(`/${this.props.workspaceType}/mentors`);
      }
    } else {
      this.props.history.push(
        `/${this.props.workspaceType}/mentor-description/${this.props.match.params.id}`
      );
    }
    this.props.getNavigationType(5);
  }
  editorFaqValues(values) {
    console.log("editorStateValues", values);
    console.log(values);
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        faqContent: values,
        plainStringContent: "",
        faqCount: 0,
      });
    } else {
      this.setState({
        faqContent: values,
        plainStringContent: plainString,
        faqCount: plainString.length,
      });
    }
  }
  preferred(e) {
    this.setState({ preferred: e.target.value });
  }
  paymentType(e) {
    this.setState({ paymentType: e.target.value });
  }
  handleCategoryMultiSelect(badges_value) {
    this.setState({
      specialization: badges_value,
    });
  }
  handleSubIndustry = (data) => {
    this.setState({
      subIndustry: data,
    });
  };
  handleSubSpecial = (data) => {
    this.setState({
      subSpecialization: data,
    });
  };
  handleAreaOfInterestMultiSelect = (badges_value) => {
    this.setState({
      industryList: badges_value,
    });
  };
  handledroupDown(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  videoOptionOpen(key) {
    this.setState({
      fileTypeKey: key,
      videoOptionOpen: true,
    });
  }

  getQuestionMediaVideoFiles(files, type) {
    console.log("update video", files[0]);
    console.log("files");
    this.setState({
      selectPageVideo: false,
      fileVideo: files,
      type: type,
      fileTypeKey: "video",
      videoOption: false,
      fileOptionVideo: false,
    });
  }
  async getQuestionMediaImageFiles(files, type) {
    console.log("update video img", files);
    await this.setState({
      selectPageImage: false,
      fileImages: files,
      type: type,
      selectOption: false,
      grouporeventFiles: [],
      editImage: files,
      imageOption: false,
    });
  }
  async fileOptionEnableImage() {
    await this.setState({
      selectPageImage: true,
      fileOptionImage: false,
      imageOptionOpen: true,
      fileOptionVideo: false,
    });
  }
  async fileOptionEnableVideo() {
    await this.setState({
      selectPageVideo: true,
      videoOptionOpen: true,
      fileOptionVideo: true,
      fileOptionImage: false,
      // selectPageVideo: true,
      // fileOptionVideo: true,
      // videoOptionOpen: true,
    });
  }
  eveningTimeSelect() {
    this.setState({
      eveningTimeSelect: !this.state.eveningTimeSelect,
      eveningStart: "",
      eveningEnd: "",
      validationInEvn: false,
    });
  }
  afternoonTimeSelect() {
    this.setState({
      afternoonTimeSelect: !this.state.afternoonTimeSelect,
      afternoonStart: "",
      afternoonEnd: "",
      validationInAft: false,
    });
  }
  morningTimeSelect() {
    this.setState({
      morningTimeSelect: !this.state.morningTimeSelect,
      morningStart: "",
      morningEnd: "",
      validationInMorn: false,
    });
  }
}

const mapStateToProps = function (state) {
  console.log("state.commonApi", state.commonApi);
  return {
    subSpecializationList: state.commonApi.subSpecialization,
    subIndustryList: state.commonApi.subIndustry,
    areaofinterestList: state.commonApi.areaofinterestlist,
    countyNameList: state.commonApi.countryName,
    allMembersList: state.subjects.allMembersList,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    industryList: state.commonApi.industrylist.data,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    subSpecialization: () => dispatch(subSpecialization()),

    subIndustry: () => dispatch(subIndustry()),
    getNavigationType: (data) => dispatch(getNavigationType(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(MentorForm)));
