import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SettingsIcon from "@material-ui/icons/Settings";
import dummyimage from "../../img/default_avatar.jpg";

export const statusConfig = {
  online: "#76C00D",
  offline: "#D3D3D3",
  away: "#AFBBC6",
};

export default class UserInfo extends Component {
  render() {
    const { imgUrl, userName, status, onChangeStatus } = this.props;
    return (
      <div className="chatUserInfoWrapper flexDefault">
        <div className="chatUserDetails flexDefault">
          <div className="chatMyUserImg">
            <img
              src={imgUrl}
              alt={userName}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = dummyimage;
              }}
            />
            {/* <div
              className="chatActiveStatus"
              style={{ backgroundColor: statusConfig[status] }}
            ></div> */}
          </div>
          <div className="chatUserNameStatus">
            <h3 className="chatUserName">{userName}</h3>
            {/* <Select id="status" value={status} onChange={onChangeStatus}>
              <MenuItem value={"online"}>Online</MenuItem>
              <MenuItem value={"offline"}>Offline</MenuItem>
              <MenuItem value={"away"}>Away</MenuItem>
            </Select> */}
          </div>
        </div>
        {/* <div className="settingIcon">
          <SettingsIcon />
        </div> */}
      </div>
    );
  }
}
