import { CardContent } from "@material-ui/core";
import React, { Component } from "react";
import BlockUi from "react-block-ui";
import { Card } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getHashTagPost } from "../../../redux/actions/PostCardActions";
import PostCard from "../HomeUpdated/PostCard";

class HashTagContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 0,
    };
  }
  componentDidMount() {
    this.getPostList();
    window.scrollTo({
      top: 0,
    });

    // this.props.match.params.hash
  }
  componentDidUpdate(prProps) {
    if (
      this.props.match.params.hash &&
      this.props.match.params.hash !== prProps.match.params.hash
    ) {
      this.setState({
        pageNo: 0,
      });
      this.getPostList();
      window.scrollTo({
        top: 0,
      });
    }
  }
  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="d-flex mt-3 " style={{ fontWeight: "500" }}>
          Posts Based on{" "}
          <span
            className="d-flex"
            style={{
              color: "#D92267",
              marginLeft: "0.3rem",
              marginRight: "0.3rem",
            }}
          >
            {" "}
            {this.props.match.params.position == "user" ? (
              <>@{this.props.match.params.hash} </>
            ) : (
              "#" + this.props.match.params.hash
            )}
          </span>
          {this.props.match.params.position == "user" ? (
            <>
              <p className="text-dark ml-1"> tagged user</p>
            </>
          ) : (
            ""
          )}
        </div>
        <BlockUi
          tag="div"
          message={t("Loading, please wait")}
          style={{ minHeight: "90px" }}
          className="wallLoadingPage"
          blocking={this.props.loaderStats}
        >
          {Array.isArray(this.props.postList) &&
            this.props.postList.map((communityPostData) => {
              return (
                <PostCard
                  isBookMarkType={this.props.type}
                  postList={this.state.postData}
                  data={communityPostData}
                  login={this.props.loginDetails}
                  friends={this.props.commonApi}
                  isGroup={false}
                />
              );
            })}

          {Array.isArray(this.props.postList) &&
            this.props.postList.length > 0 &&
            !this.props.loaderStats &&
            this.props.seeMorePage && (
              <div className="networkDetailsBtn">
                <button
                  className="networkSeeMore"
                  onClick={this.onChangeCallingSeeMore}
                >
                  {t("See more")}
                </button>
              </div>
            )}

          {!this.props.seeMorePage &&
            !this.props.loaderStats &&
            this.props.postList.length === 0 && (
              <Card style={{ marginTop: "15px" }}>
                <CardContent>
                  <center>
                    <p>{t("No Post Available")}</p>
                  </center>
                </CardContent>
              </Card>
            )}
        </BlockUi>
      </div>
    );
  }
  getPostList = () => {
    let type = this.props.match.params.position == "user" ? "@" : "#";

    this.props.getHashTagPost(type, this.props.match.params.hash, 0, false);
    // this.setState({
    //   pageNo: this.state.pageNo + 1,
    // });
  };
  onChangeCallingSeeMore = () => {
    let type = this.props.match.params.position == "user" ? "@" : "#";
    this.props.getHashTagPost(
      type,
      this.props.match.params.hash,
      this.state.pageNo + 1,
      true
    );
    this.setState({
      pageNo: this.state.pageNo + 1,
    });
  };
}

const mapStateToProps = (state) => {
  return {
    postList: state.postList.postList,
    loaderStats: state.postList.loaderStats,
    seeMorePage: state.postList.seeMorePage,
    postError: state.postList.postError,
    toastData: state.postList.toastData,
    toastDataStatus: state.postList.toastDataStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHashTagPost: (type, data, pageNo, pagination) =>
      dispatch(getHashTagPost(type, data, pageNo, pagination)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(HashTagContainer)));
