import React, { Component } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
export default class TagMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: null,
      selectedMenus: [],
    };

    this.handleOpenMenu = this.handleOpenMenu.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleSeletedMenus = this.handleSeletedMenus.bind(this);
    this.handleDeleteMenu = this.handleDeleteMenu.bind(this);
  }
  componentDidMount() {
    if (this.props.selectedOldName === undefined) {
    } else {
      this.setState({ selectedMenus: this.props.selectedOldName });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedOldName !== prevProps.selectedOldName) {
      this.setState({ selectedMenus: this.props.selectedOldName });
    }
  }

  handleOpenMenu(event) {
    this.setState({
      showMenu: event.currentTarget,
    });
  }
  handleCloseMenu() {
    this.setState({
      showMenu: null,
    });
  }
  handleSeletedMenus(value) {
    var joined = this.state.selectedMenus.concat(value);
    this.setState({
      selectedMenus: joined,
      showMenu: null,
    });

    this.props.getSelectedMenus(joined);
  }
  handleDeleteMenu(menu) {
    let currentData = this.state.selectedMenus.filter((data) => data !== menu);
    this.setState({
      selectedMenus: currentData,
      showMenu: null,
    });
    this.props.getSelectedMenus(currentData);
  }
  render() {
    const { showMenu, selectedMenus } = this.state;
    const { allMenus, addLabel = "Add Tag", isTagMenuFilter } = this.props;
    const visibleMenus = allMenus.filter(
      (menu, index) => !selectedMenus.includes(menu)
    );

    return (
      <div>
        <div className={isTagMenuFilter ? "tagMenuFilter" : ""}>
          <div className="tagSelectedMenuWrapper flexDefault flexAlignCenter">
            <div className="tagSelectedMenus flexDefault flexAlignCenter"></div>
            <Chip
              icon={<AddIcon />}
              label={addLabel}
              onClick={this.handleOpenMenu}
            />
          </div>

          <Menu
            id="simple-menu"
            anchorEl={showMenu}
            keepMounted
            open={Boolean(showMenu)}
            onClose={this.handleCloseMenu}
          >
            {visibleMenus.map((menu, index) => (
              <MenuItem
                onClick={() => this.handleSeletedMenus(menu)}
                key={index}
              >
                <div className="mulListData">{menu}</div>
              </MenuItem>
            ))}
          </Menu>
        </div>
        <div className="ChipBox d-flex flex-wrap">
          {selectedMenus &&
            selectedMenus.map((menu, index) => (
              <div style={{ margin: "10px" }} key={index}>
                <Chip
                  label={menu}
                  onDelete={() => this.handleDeleteMenu(menu)}
                  deleteIcon={<CloseIcon />}
                />
              </div>
            ))}
        </div>
        {/* {selectedMenus &&
              selectedMenus.map((menu, index) => (
                <div style={{margin:"10px"}} className="" key={index}>
                  <Chip
                    label={menu}
                    onDelete={() => this.handleDeleteMenu(menu)}
                    deleteIcon={<CloseIcon />}
                  />
                </div>
              ))} */}
      </div>
    );
  }
}
