import React, { memo, useState } from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { Button, Form, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";

const VideoContainer = ({ data }) => {
  console.log("Image", data);
  const [activeIndex, setActiveIndex] = useState(0);
  const [videoFile, setVideoFile] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false); // Handle error by hiding the loader
  };
  const handleEventClick = (file, e) => {
    setVideoFile(file);
    e.preventDefault();
  };

  return (
    <div className="position-relative">
      {Array.isArray(data) && data.length > 0 && (
        <>
          <div
            class="position-relative w-auto v2-container"
            style={{ cursor: "pointer" }}
            onClick={() => setVideoFile(data[activeIndex].file)}
          >
            {loading && <span class="image-video-loader"></span>}
            <img
              class="image"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: loading ? "none" : "block",
              }}
              src={data[activeIndex].image}
              alt="file"
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          </div>
          <div
            onClick={(e) => handleEventClick(data[activeIndex].file, e)}
            class="video-overlay"
            style={{ display: loading ? "none" : "block" }}
          >
            <PlayArrowIcon className="play-btn" style={{ color: "brown" }} />
          </div>

          <ul className="dot-slider mt-3">
            {data.length > 1 &&
              data.map((item, index) => (
                <li
                  className={`${index === activeIndex && "border-dot"}`}
                  onClick={() => setActiveIndex(index)}
                ></li>
              ))}
          </ul>
          {videoFile && (
            <Modal show="true" onHide={() => setVideoFile(null)}>
              <Form id="profileForm">
                <Modal.Body>
                  {<ReactPlayer className="player" controls url={videoFile} />}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    style={{ color: "#ffffff", marginRight: "12px" }}
                    onClick={() => setVideoFile(null)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default memo(VideoContainer);
