import {
  DIRECTORY_FAVOURITE,
  DIRECTORY_LIST,
  DIRECTORY_LOADER,
  SINGLE_DIRECTORY_LIST,
  DIRECTORY_CATEGORY_LIST,
} from "../types";

const initialState = {
  loaderStatus: false,
  singleDirectoryList: {},
  directoryList: [],
  seeMoreOption: false,
  activePage: 0,
  directoryCategoryList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DIRECTORY_LOADER:
      return {
        ...state,
        loaderStatus: true,
      };
    case DIRECTORY_LIST:
      console.log("DIRECTORY_LIST", action.payload);
      if (action.pagination && action.payload?.directoryList) {
        let oldList = state.directoryList.concat(action.payload?.directoryList);
        return {
          ...state,
          loaderStatus: false,
          directoryList: oldList ? oldList : state.directoryList,
          seeMoreOption:
            action.payload?.totalPages - 1 > action.pageNo ? true : false,
          activePage: action.pageNo,
        };
      } else {
        return {
          ...state,
          loaderStatus: false,
          directoryList: action.payload?.directoryList,
          seeMoreOption:
            action.payload?.totalPages - 1 > action.pageNo ? true : false,
          activePage: action.pageNo,
        };
      }

    case SINGLE_DIRECTORY_LIST:
      return {
        ...state,
        loaderStatus: false,
        singleDirectoryList: action.payload?.directoryList,
      };

    case DIRECTORY_FAVOURITE:
      console.log("DIRECTORY_FAVOURITE", action.payload);
      let filter = state.directoryList.map((item) => {
        if (item.directoryId === action.payload?.directoryId) {
          item.bookmark = !item.bookmark;
        }
        return item;
      });

      return {
        ...state,
        directoryList: filter ? filter : state.directoryList,
      };
    case DIRECTORY_CATEGORY_LIST:
      console.log("DIRECTORY_CATEGORY_LIST", action.payload);
      return {
        ...state,
        directoryCategoryList: action.payload,
      };
    default:
      return state;
  }
}
