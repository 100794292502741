
export const APP_NAME = 'Yuukke';

export const API_URL = "https://corporate.yuukke.com/bcav2/"

export const BASE_URL = "https://corporate.yuukke.com/";


export const profiles = "moodle,keycloak";

export const TEAMS_SSO_URL = "https://testapi.yuukke.com/"

export const CHAT_URL = "https://chatv2.yuukke.com/"
export const JITSI_URL = "https://meet.yuukke.org/"



export const HTTP_HEADERS = {
    'Content-Type': 'application/json',
}
