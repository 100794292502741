import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBadgesPoints } from "../../../redux/actions/badgesActions";
import { Card, CardContent } from "@material-ui/core";
import moment from "moment";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { BiDollarCircle } from "react-icons/bi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaArrowRight } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { generateMarketPlaceUlr, generateUlr } from "../../../utils";

function Badges(props) {
  const { t } = props;
  const { pointList, pointSeeMore } = useSelector((state) => state.badgeslist);
  const { requireMentMenu } = useSelector((state) => state.commonApi);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  useEffect(() => {
    dispatch(getBadgesPoints(0));
    setPage(1);
  }, []);

  const gotoHomePage = () => {
    this.props.history.push(`/${this.props.workspaceType}/home`);
  };
  const fetchMoreDataFriends = () => {
    dispatch(getBadgesPoints(page));

    setPage(page + 1);
  };
  const movetoMarket = () => {
    // window.open(requireMentMenu?.marketplaceUrl, "_blank");
    generateMarketPlaceUlr(requireMentMenu?.marketplaceUrl);
  };

  return (
    <div className="tabWrapperPoints">
      <div className="d-flex pt-3 mr-3 align-items-center justify-content-end market-place-animation flex-wrap">
        {Array.from(
          "Use these reward points to redeem them in the Marketplace"
        ).map((item, index) => (
          <span key={index}>{item == " " ? <>&nbsp;</> : item}</span>
        ))}
      </div>
      <div className="p-3 d-flex flex-wrap justify-content-between">
        <h4>{t("Points History")}</h4>
        <div className="d-flex" onClick={() => movetoMarket()}>
          <BiDollarCircle
            className="svg-icons"
            style={{ color: "yellowgreen" }}
          />
          <FaArrowRight className="moving-arrow mt-1" />
          <section>
            <AiOutlineShoppingCart
              className="svg-icons"
              style={{ color: "brown" }}
            />
            <p style={{ fontSize: "12px" }} className="f-bold text-secondary">
              Shop
            </p>
          </section>
        </div>
      </div>
      <div className="badgesCard">
        <Card className="badgesCard">
          <CardContent>
            <div className="container">
              <ul className="p-container d-flex">
                <li className="font-weight-bold">{t("# of points")}</li>
                <li className=" font-weight-bold">{t("Date")}</li>
                <li className="font-weight-bold  ml-auto mr-4">
                  {t("Reason")}
                </li>
              </ul>
              <InfiniteScroll
                dataLength={pointList.length}
                next={fetchMoreDataFriends}
                hasMore={pointSeeMore}
                scrollableTarget="scrollableDiv4"
              >
                <div
                  id="scrollableDiv4"
                  // onScroll={this.handleScroll}
                  style={{
                    minHeight: "auto",
                    maxHeight: "530px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    // margin: "0px 22px 25px",
                  }}
                >
                  {pointList &&
                    pointList.map((badgesPoint) => (
                      <ul className="d-flex p-container p-sub">
                        <li className=" ">{badgesPoint.userPoints}</li>
                        <li className=" ">
                          {badgesPoint.updatedDate === null
                            ? "-"
                            : moment(badgesPoint.updatedDate)
                                .locale("en")
                                .format("ll")}
                        </li>
                        <li className="ml-auto mr-4">
                          {badgesPoint.knowledgePointName}
                        </li>
                      </ul>
                    ))}
                  {pointList && pointList.length === 0 && (
                    <li style={{ alignItems: "center" }}>
                      <p>No points present</p>
                    </li>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default withTranslation()(withRouter(Badges));
