import React, { Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { MenuItem } from "@material-ui/core";
import Swal from 'sweetalert2'
export default class EditableDropdownGrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disbaledField: false,
      value: null,
      options: []
    };

    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.onClickInput = this.onClickInput.bind(this)
  }

  componentDidMount() {

    this.setState({
      value: this.props.value,
      options: this.props.options
    }, () => {

    });


  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.value !== this.props.value) {


      this.setState({
        value: this.props.value
      });
    }
    if (prevProps.options !== this.props.options) {


      this.setState({
        options: this.props.options
      }, () => {

      });
    }
  }


  handleClickEdit() {

    this.setState({
      disbaledField: false,
    });
    this.props.handleClickEdits()
  }

  onClickInput() {
    if (this.props.getProgramId == "") {
      Swal.fire({
        title: 'Please choose valid program before choosing grade',
        icon: 'warning',
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        reverseButtons: true
      }).then((result) => {

      })

    }

  }

  handleChangeInput(event) {


    this.setState({
      value: event.target.value,
    });
    this.props.getSelectedValue(event.target.value);
    this.props.handleClickEdits()
  }
  render() {

    const { disbaledField, value } = this.state;
    return (
      <div
        className={
          disbaledField
            ? "editableTextWrapper formFieldGroup disabledField"
            : "editableTextWrapper formFieldGroup"
        }
      >
        <label>{this.props.label}</label>

        <Grid container spacing={1} alignItems="flex-end" wrap="nowrap">
          <Grid item xs={11}>

            <TextField
              id="dropdown"
              select
              label=""
              value={value}
              onChange={this.handleChangeInput}

              disabled={disbaledField}
              variant="outlined"
              fullWidth={true}
            >

              {this.state.options.map((option, index) => (

                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1}>
          {/* <IconButton
              aria-label="Edit"
              size="small"
              onClick={this.handleClickEdit}
            >
              <CreateIcon fontSize="inherit" />
            </IconButton> */}
          </Grid>
        </Grid>
      </div>
    );
  }
}