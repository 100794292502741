import React, { Component } from "react";
import LoginService from "../../api/LoginService";
import AuthService from "../../session/AuthService";
import { ToastContainer, toast } from "react-toastify";
import ProfileService from "../../api/ProfileService.js";
import { connect } from "react-redux";
import { storeLoginDetails } from "../../redux/actions/loginDetailsActions";
import HomeTag from "./HomeTag";
import LoadService from "../../utils/LoadService";
var IPdress = "";
class MSLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.getMsLogin = this.getMsLogin.bind(this);
    this.loadApp = this.loadApp.bind(this);
    this.getToken = this.getToken.bind(this);
    this.createInstantUser = this.createInstantUser.bind(this);
  }

  componentDidMount() {
    console.log("Entering component did mount");
    user_location();
    let email = AuthService.getMSEmail();
    this.getMsLogin(email);
  }

  createInstantUser(email) {
    var nameParts = email.split("@");
    var name = "";
    console.log("Entering create instant user");
    console.log(nameParts);
    if (nameParts.length >= 2) {
      name = nameParts[0];
      console.log("Entering if in create instant user");
      console.log(name);
    }
    var userObj = {
      firstName: name,
      lastName: name,
      email: email,
    };

    LoginService.createInstantUser(userObj)
      .then((response) => {
        var result = response.data;
        console.log(result);
        console.log("LOGIN ==== VALUES IN CREATE INSTANT USER " + result.data);
        if (response.data.status === 1) {
          console.log("Entering status sccess 1");
          this.getMsLogin(email);
        } else if (response.data.status === -1) {
          console.log("Entering status success -1");
          this.props.history.push(`/error`);
        } else {
          console.log("Entering else error");
          this.props.history.push(`/error`);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Network error, Please try again later instant catch");
        this.setState({ message: false });
        this.setState({ hasLoginFailed: true });
      });
  }

  getMsLogin(queryParameter) {
    console.log("Entering ms login");
    var navi =
      navigator.userAgent +
      " & " +
      navigator.vendor +
      " & " +
      navigator.language;
    console.log(navi);
    var loginUser = {
      email: queryParameter,
      SystemDetails: navi,
      ipAddress: IPdress,
      navication: navi,
    };
    LoginService.getMsLogin(loginUser)
      .then((response) => {
        var result = response.data;
        console.log("LOGIN ====");
        console.log(result);
        console.log("LOGIN ==== VALUES " + result.data);

        if (response.data.status === 1) {
          console.log("Entering success response");
          AuthService.registerAuth(this.state.username, result.data);
          this.props.storeLoginDetails(result.data, "teams_login");
          AuthService.registerToken(result.data.token);
          this.getToken(result.data);
        } else if (response.data.status === -1) {
          console.log("Entering error -1");
          this.props.history.push(`/error`);
        } else if (response.data.status === 0) {
          console.log("Entering status 0");
          this.createInstantUser(queryParameter);
        } else {
          this.props.history.push(`/error`);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Network error, Please try again later");
        this.setState({ message: false });
        this.setState({ hasLoginFailed: true });
      });
  }

  loadApp(data) {
    if (data.fTLogin == true) {
      this.props.history.push(`/${this.props.workspaceType}/home`);
    } else {
      if (data.profile_complete == false) {
        LoadService.redirect(this.props.history, "/first-time-setup");
      } else {
        this.props.history.push(`/${this.props.workspaceType}/home`);
      }
    }
  }

  getToken(data) {
    console.log("Entering get token");
    ProfileService.getToken()
      .then((response) => {
        console.log(response.data);

        console.log("ALERT MS EMAIL LOGIN");
        if (response.data !== "ERROR") {
          console.log("MS EMAIL LOGIN");
          console.log(data.email);
          AuthService.setMSEmail(data.email);
          AuthService.setMSServiceToken(response.data);
          this.loadApp(data);
        } else {
          console.log("MS EMAIL LOGIN ELSE");
          toast.error("Authencation Token error, Please try again later");
        }
      })
      .catch((error) => {
        toast.error("Authencation Token error, Please try again later");
        console.log(error);
      });
  }

  render() {
    return <HomeTag status="Loading Information ..." />;
  }
}
function user_location() {
  console.log("Entering user location");
  var xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function () {
    console.log("Entering on ready state change");
    if (this.readyState == 4 && this.status == 200) {
      console.log("Entering ready state");
      console.log("Entering status");
      console.log(this.readyState);
      console.log(this.status);
      console.log(this.responseText);
      IPdress = this.responseText;
    }
  };
  xhttp.open("GET", "//api.ipify.org?format=json", true);
  xhttp.send();
}

const mapStateToProps = function (state) {
  return {
    workspaceType:
      state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    storeLoginDetails: (loginDetails, type) =>
      dispatch(storeLoginDetails(loginDetails, type)),
  };
};

export default connect(mapStateToProps, mapDispacthToProps)(MSLogin);
