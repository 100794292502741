import React, { Component } from "react";
import {
  Button,
  Form,
  Modal,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

class InviteUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteUser: "",
      items: [],
      value: "",
      error: null,
      emailVerification: "",
    };
  }
  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.isGroup ? (
          <>
            <Form.Group className="formInputs">
              <Form.Control
                className={
                  "emailInput " + (this.state.error && " has-errorEmail")
                }
                value={this.state.value}
                placeholder={t("Type or paste email addresses...")}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
                onPaste={this.handlePaste}
              />
              {this.state.items.map((item) => (
                <div
                  style={{ marginTop: "12px" }}
                  className="tag-itemEmail"
                  key={item}
                >
                  {item}
                  <button
                    //  type="button"
                    className="emailButton"
                    onClick={() => this.handleDelete(item)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </Form.Group>

            <Button
              variant="secondary"
              style={{ color: "#ffffff", marginRight: "12px" }}
              onClick={this.onSave}
            >
              {t("Invite member")}
            </Button>
          </>
        ) : (
          <Modal show="true" onHide={this.closeInvitePage}>
            <Form id="profileForm">
              <Modal.Body>
                <Form.Group className="formInputs">
                  <Form.Label>
                    {this.props.isForum ? (
                      <>Invite user's</>
                    ) : (
                      <>
                        {" "}
                        {t(
                          this.props.workSpace
                            ? `Invite Friends on ${this.props.workSpace}`
                            : "Invite Friends on Yuukke"
                        )}
                      </>
                    )}
                  </Form.Label>

                  <Form.Control
                    className={
                      "emailInput " + (this.state.error && " has-errorEmail")
                    }
                    value={this.state.value}
                    placeholder={t(
                      this.props.workSpace
                        ? "Type or paste email addresses and press `Enter`..."
                        : "Type or paste email addresses or phoneNumber and press `Enter`..."
                    )}
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                    onPaste={this.handlePaste}
                  />
                  {this.state.items.map((item) => (
                    <div
                      style={{ marginTop: "12px" }}
                      className="tag-itemEmail"
                      key={item}
                    >
                      {item}
                      <button
                        //  type="button"
                        className="emailButton"
                        onClick={() => this.handleDelete(item)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  style={{ color: "#ffffff", marginRight: "12px" }}
                  onClick={this.closeInvitePage}
                >
                  {t("Close")}
                </Button>
                <Button
                  variant="secondary"
                  style={{ color: "#ffffff", marginRight: "12px" }}
                  onClick={this.onSave}
                >
                  {t("Send")}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </>
    );
  }
  closeInvitePage = () => {
    this.props.closeInvitePage();
  };
  onSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    var emailCheck = true;

    if (this.state.items === "" && this.state.emailVerification === "") {
      toast.warn("Please fill valid  email..");
    } else {
      if (this.state.onEnter || this.state.onPaste) {
        emailCheck = false;
        let value = {
          email: this.state.items,
        };

        this.emailInvite(value);
      } else if (this.state.emailVerification !== "" && emailCheck) {
        var email = this.state.emailVerification.trim();
        if (email && this.isValid(email)) {
          emailCheck = true;

          let value = {
            email: [this.state.emailVerification],
            //  email: [...this.state.oemailVerificatin],
          };
          console.log("this.state.items 2", value);
          this.emailInvite(value);
        } else {
          toast.warn("Please enter valid data...");
        }
      }
    }
  };
  emailInvite(value) {
    console.log("value", value);
    this.props.emailData(value);
    this.setState({
      items: [],
      value: "",
    });
  }
  handleSeletedValue(value) {
    var filter = this.state.filterValues;
    if (value) {
      filter["searchKey"] = value;
    } else {
      filter["searchKey"] = "";
    }
    this.setState({
      filterValues: filter,
    });
    if (value) {
      var obj = {
        searchName: value,
      };

      var list = this.state.searchList;

      list.push(obj);

      this.setState({
        searchList: list,
      });

      this.setState({
        selectedValue: value,
        showResults: true,
      });
    } else {
      this.setState({
        showResults: false,
      });
    }
  }
  handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.value.trim();
      console.log("value", value);
      if (value && this.isValid(value)) {
        this.setState({
          items: [...this.state.items, this.state.value],
          value: "",
          onEnter: true,
        });
      }
    }
  };

  handleChange = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null,
      emailVerification: evt.target.value,
    });
  };

  handleDelete = (item) => {
    this.setState({
      items: this.state.items.filter((i) => i !== item),
    });
  };

  handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !this.isInList(email));

      this.setState({
        items: [...this.state.items, ...toBeAdded],
        onPaste: true,
      });
    }
  };

  isValid(email) {
    let error = null;
    if (!this.props.workSpace && this.isPhoneNumber(email)) {
      return true;
    }
    if (this.isInList(email)) {
      error = `* ${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = `* ${email} is not a valid email address.`;
    }

    if (error) {
      this.setState({ error: error });

      return false;
    }

    return true;
  }
  isPhoneNumber(email) {
    return /^\d{10}$/.test(email);
  }
  isInList(email) {
    return this.state.items.includes(email);
  }
  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }
}
export default withTranslation()(InviteUser);
// export default InviteUser
