import moment from "moment";
import * as APPCONFIG from "./config/app-config";
import {
  CLIENT_ID,
  ENCRYPT_KEY,
  KEY_CLOAK_URL,
} from "./config/keycloak-config";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

export function getUrlParams(params) {
  const queryString = global.window?.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(params);
}
export function removeHTML(str) {
  if (str) {
    var plainString = str.replace(/<[^>]+>/g, "");
    var smallText = plainString.substring(0, 70);
    console.log("smallText", smallText?.length);
    return smallText && smallText !== ""
      ? plainString?.length > 70
        ? smallText + "..."
        : smallText
      : "";
  } else {
    return "";
  }
}
// export function htmlValidation(paragraph) {
//   if (paragraph) {
//     const urlRegex = /(<a\b[^>]*>[^<]*<\/a>)|(https?:\/\/[^\s<]+[^\s<\.)])/g;
//     const newText = paragraph.replace(urlRegex, (match, anchorTag, url) => {
//       // if the match is already an anchor tag, return it as-is
//       if (anchorTag) {
//         return anchorTag;
//       }
//       // otherwise, wrap the URL in a new anchor tag
//       return `<a href="${url}" target="_blank">${url}</a>`;
//     });
//     return (
//       <p dangerouslySetInnerHTML={{ __html: newText ? newText : paragraph }} />
//     );
//   } else {
//     return "";
//   }
// }
// export function htmlValidation(paragraph) {
//   if (paragraph) {
//     const newlineRegex = /\n/g;
//     const newText = paragraph.replace(newlineRegex, "<br />");

//     const urlRegex =
//       /(<a\b[^>]*>[^<]*<\/a>)|((?:https?:\/\/|www\.)[^\s<]+[^\s<\.)])/g;
//     const finalText = newText.replace(urlRegex, (match, anchorTag, url) => {
//       // if the match is already an anchor tag, return it as-is
//       if (anchorTag) {
//         return anchorTag;
//       }
//       // otherwise, wrap the URL in a new anchor tag
//       const completeURL = url.startsWith("http") ? url : `http://${url}`;
//       return `<a href="${completeURL}" target="_blank">${url}</a>`;
//     });

//     return (
//       <p
//         dangerouslySetInnerHTML={{ __html: finalText ? finalText : paragraph }}
//       />
//     );
//   } else {
//     return "";
//   }
// }

// export function htmlValidation(paragraph) {
//   if (paragraph) {
//     const tagRegex = /\r\n<p><\/p>\r\n/g;
//     const brText = paragraph.replace(tagRegex, "<br />");

//     const urlRegex =
//       /(<a\b[^>]*>[^<]*<\/a>)|((?:https?:\/\/|www\.)[^\s<]+[^\s<\.)])/g;
//     const finalText = brText.replace(urlRegex, (match, anchorTag, url) => {
//       // if the match is already an anchor tag, return it as-is
//       if (anchorTag) {
//         return anchorTag;
//       }
//       // otherwise, wrap the URL in a new anchor tag
//       const completeURL = url.startsWith("http") ? url : `http://${url}`;
//       return `<a href="${completeURL}" target="_blank">${url}</a>`;
//     });

//     return (
//       <p
//         dangerouslySetInnerHTML={{ __html: finalText ? finalText : paragraph }}
//       />
//     );
//   } else {
//     return "";
//   }
// }

export function htmlValidation(paragraph) {
  if (paragraph) {
    const tagRegex = /\r\n<p><\/p>\r\n/g;
    const brText = paragraph.replace(tagRegex, "<br />");

    const spanTagRegex = /<a([^>]*)><span([^>]*)>([^<]*)<\/span><\/a>/g;
    const finalText = brText.replace(spanTagRegex, (match, p1, p2, p3) => {
      // Keep the attribute value and the text inside the <span> tag
      return `<a${p1}>${p3}</a>`;
    });

    const urlRegex =
      /(<a\b[^>]*>[^<]*<\/a>)|((?:https?:\/\/|www\.)[^\s<]+[^\s<\.)])/g;
    const modifiedText = finalText.replace(
      urlRegex,
      (match, anchorTag, url) => {
        // If the match is already an anchor tag, return it as-is
        if (anchorTag) {
          return anchorTag;
        }
        // Otherwise, wrap the URL in a new anchor tag
        const completeURL = url.startsWith("http") ? url : `http://${url}`;
        return `<a href="${completeURL}" target="_blank">${url}</a>`;
      }
    );

    return (
      <p
        dangerouslySetInnerHTML={{
          __html: modifiedText ? modifiedText : paragraph,
        }}
      />
    );
  } else {
    return "";
  }
}

export function isContainId(htmlString) {
  const isValid =
    /^<a\b[^>]*\bhref="[^"]*friendDescription[^"]*"[^>]*\bdata-value="([^"]*)"[^>]*>@[^<]*<\/a>$/.test(
      htmlString
    );
  return isValid;
}
export function extractMentionsFromHTML(htmlString) {
  if (htmlString) {
    const output = htmlString.replace(
      /<a\s+[^>]*class="wysiwyg-mention"[^>]*>(@[^<]*)<\/a>/gi,
      function (match, p1) {
        var modifiedName = p1.replace(/\s+/g, "_");
        return modifiedName;
      }
    );
    return output;
  } else {
    return "";
  }
}

export function validateSpanTag(text) {
  const regex =
    /^<a\s+href="friendDescription\/\d+"\s+style="color:#a00030">@\w+<\/a>$/;
  return regex.test(text);
  // export function validateString(str) {
  // const regex = /^<a\s+href="friendDescription\/\d+">\S+<\/a>$/;
  // return regex.test(text);
  // }

  // if (text) {
  //   const regex =
  //     /^<span style="color:#D92267;cursor: pointer;">@[a-zA-Z0-9_]+<\/span>$/;
  //   return regex.test(text.trim());
  // } else {
  //   return "";
  // }
}

export function removeMentionTags(values) {
  if (values) {
    const pattern = /\@\[([\w\s]+?)\]\(([\w\s]+?)\)/g;
    const result = values.replace(pattern, function (match, p1, p2) {
      const username = p1.replace(/\s+/g, "_");
      return `@${username}`;
    });
    // const replacedText = values.replace(/\@\[([\w\s]+)\]\([\w]+\)/g, "@$1");
    return result;
  } else {
    return "";
  }
}

export function removeHtmlTags(values) {
  if (values) {
    const string = values.replace(/<[^>]+>/g, "");
    // const s1 = string.replace(/&\w+;/g, '');
    // return string;
    return addMentionEditorFormat(string);
  } else {
    return "";
  }
}
export function addMentionEditorFormat(values) {
  if (values) {
    let string = values.replace(/@(\w+)/g, `@[$1]($1)`);
    return string;
  } else {
    return "";
  }
}

export function extractMentionsFromUpdate(htmlString) {
  if (htmlString) {
    var modifiedHTML = htmlString.replace(
      /<a[^>]*>(<span[^>]*>(@[^<]*)<\/span>|(@[^<]*))<\/a>/gi,
      function (match, p1, p2) {
        var modifiedText = p2 ? p2.replace(/\s+/g, "_") : p1;
        return modifiedText;
      }
    );

    return modifiedHTML;
  } else {
    return "";
  }
}

export function extractNumberFromHref(href) {
  const regex = /friendDescription\/(\d+)/;
  const match = href.match(regex);
  if (match) {
    return match[1];
  }
  return null;
}
export function removeSpanTag(paragraph) {
  const spanTagRegex = /<a([^>]*)><span([^>]*)>([^<]*)<\/span><\/a>/g;
  const finalText = paragraph.replace(spanTagRegex, (match, p1, p2, p3) => {
    // Keep the attribute value and the text inside the <span> tag
    return `<a${p1}>${p3}</a>`;
  });
  return finalText;
}

export function readOnlyWorkspace(role, workspaceType) {
  if (workspaceType === 1 && role === 0) {
    //Don't need pop-up
    return true;
  } else {
    return false;
  }
}
export function giveUserAction(profileSetUp) {
  let workspace = sessionStorage.getItem("WORKSPACE");
  console.log("WORKSPACE", workspace);
  console.log("WORKSPACE", profileSetUp);

  if (workspace === "default" && !profileSetUp) {
    return true;
  } else {
    return false;
  }
}
// APPCONFIG
export const getVideoStatus = (item, loginId, postUserId, uType) => {
  if (loginId === postUserId || uType === 12) {
    const list = item.map((data) => {
      if (data.videoStatus && data.streamUrl && data.thumbnail) {
        return {
          file: data.streamUrl,
          image: data.thumbnail,
          steamStatus: true,
        };
      } else {
        return {
          file: `${APPCONFIG.API_URL}auth/video/${data.fileName}`,
          image: `${APPCONFIG.API_URL}auth/video/${data.fileName}`,
          steamStatus: false,
        };
      }
    });
    console.log("streamUrl>> list", list);

    return list;
  } else {
    const list = item.reduce((result, item) => {
      // if (item.videoStatus) {
      result.push({
        file: item.streamUrl,
        image: item.thumbnail,
        steamStatus: true,
      });
      // }
      return result;
    }, []);
    console.log("streamUrl>> list", list);

    return list;
  }
};

export const getId = (data) => {
  let id = data.map((item) => item.id);
  return id;
};

export const metaTags = [
  { property: "og:title", content: "My post" },
  { property: "og:description", content: "Like my post" },
  {
    property: "og:image",
    content:
      "https://corporate.yuukke.com/bcav2/auth/image/a0d067ff-8d2a-4eae-a8a8-99517e7ff8d9",
  },
];

export const premiumUser = (data) => {
  if (data?.userBadge && data?.badgeFileName) {
    return `${APPCONFIG.API_URL}auth/image/${data?.badgeFileName}`;
  } else {
    return false;
  }
};

export function extractValuesFromUrl(url, k1, k2) {
  const urlObj = new URL(url);
  const id = urlObj.searchParams.get(k1);
  const sub = urlObj.searchParams.get(k2);

  return { id, sub };
}

export function getProductContent(data) {
  if (data) {
    return `Take a look at this product ${data?.p_name} in yuukke`;
  }
  return "Take a look at this product in yuukke";
}

export function getWorkSpaceOption(data, key) {
  let workspace = sessionStorage.getItem("WORKSPACE");
  if (workspace === "default") return true;
  if (Object.keys(data).length === 0) return false;
  return data[`${key}`];
}

export function passwordCheck(data) {
  const valPassword = new RegExp(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@*()]).{8,}$/
  );
  return data.match(valPassword);
}

export function generateUlr(redirectUrl) {
  const accessToken = sessionStorage.getItem("TOKEN");
  // const url =
  //   KEY_CLOAK_URL +
  //   "realms/" +
  //   "yuukke" +
  //   "/protocol/openid-connect/auth?client_id=" +
  //   CLIENT_ID +
  //   "&redirect_uri=" +
  //   `${redirectUrl}` +
  //   "&response_mode=fragment&response_type=code&scope=openid&bm_token=" +
  //   accessToken;
  const url =
    KEY_CLOAK_URL +
    "realms/" +
    "yuukke" +
    "/protocol/openid-connect/auth?client_id=" +
    CLIENT_ID +
    "&response_mode=fragment&response_type=code&scope=openid&bm_token=" +
    accessToken +
    "&redirect_uri=" +
    `${encodeURIComponent(redirectUrl)}`;
  console.log("window-url", url);
  window.open(`${url}`, "_blank");
  return;
}

export const setLocalStorage = (key, value) => {
  typeof value === "object"
    ? localStorage.setItem(key, JSON.stringify(value))
    : localStorage.setItem(key, value);
};
export const getLocalStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch {
    return localStorage.getItem(key);
  }
};
export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
export const convertString = (data) => {
  return data.replace(/"/g, '\\"').toString();
};

export const getImageAndVideo = (data) => {
  if (data && data?.type === "image" && data?.postMediaFiles?.length > 0) {
    return `${APPCONFIG.API_URL}auth/image/${data?.postMediaFiles[0]?.fileName}`;
  }
  if (data && data?.type === "video" && data?.postMediaFiles?.length > 0) {
    return data?.postMediaFiles[0]?.thumbnail;
  }
  if (data && data?.postCategory === "product" && data?.productDetails) {
    return " " + data?.productDetails?.p_image;
  }
  return "";
};

export const meetingJoin = (event, type, link, meetingInYuukke) => {
  event.preventDefault();
  event.stopPropagation();
  if (type === "mentor") {
    generateUlr(`${APPCONFIG.JITSI_URL}/${link}?authenticated=true&sp=yes`);
  } else if (!meetingInYuukke) {
    window.open(link, "_blank");
  } else if (type === "public") {
    generateUlr(
      `${APPCONFIG.JITSI_URL}/${link}?authenticated=true&isGlob=true&sp=yes`
    );
  } else {
    generateUlr(
      `${APPCONFIG.JITSI_URL}/${link}?authenticated=true&isGroup=true&sp=yes`
    );
  }
};

export const getDateFormat = (data) => {
  console.log("data", data);
  const list = data && data.map((item) => item.toString()).sort();
  return list;
};

export const formatTime = (date, duration = 0, roundMin = 15) => {
  // Add the duration
  date.setMinutes(date.getMinutes() + duration);

  // Round down to the nearest 15 minutes
  const minutes = date.getMinutes();
  const roundedMinutes = minutes - (minutes % roundMin);
  date.setMinutes(roundedMinutes);

  const hours = date.getHours().toString().padStart(2, "0");
  const formattedMinutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}:${formattedMinutes}`;

  // date.setMinutes(date.getMinutes() + duration);
  // const hours = date.getHours().toString().padStart(2, "0");
  // const minutes = date.getMinutes().toString().padStart(2, "0");
  // return `${hours}:${minutes}`;
};

export const stringToArray = (data) => {
  console.log("string data", data);
  console.log("string type", typeof data);
  if (typeof data != "string") return [];
  const withoutSting = data.slice(1, -1);
  if (!withoutSting) return "";
  return withoutSting && withoutSting.split(",").map((date) => date.trim());
};

export const handleCopy = async (type, link, meetingInApplication) => {
  if (!meetingInApplication) {
    await navigator.clipboard.writeText(link);
  } else if (type === "mentor") {
    await navigator.clipboard.writeText(
      `${APPCONFIG.JITSI_URL}/${link}?&sp=yes#config`
    );
  } else if (type === "public") {
    await navigator.clipboard.writeText(
      `${APPCONFIG.JITSI_URL}/${link}?&isGlob=true&sp=yes#config`
    );
  } else {
    await navigator.clipboard.writeText(
      `${APPCONFIG.JITSI_URL}/${link}?&isGroup=true&sp=yes#config`
    );
  }
};

export const getMeetingUrl = (type, link, meetingInApplication) => {
  if (!meetingInApplication) {
    return link;
  } else if (type === "mentor") {
    return `${APPCONFIG.JITSI_URL}/${link}?&sp=yes#config`;
  } else if (type === "public") {
    return `${APPCONFIG.JITSI_URL}/${link}?&isGlob=true&sp=yes#config`;
  } else {
    return `${APPCONFIG.JITSI_URL}/${link}?&isGroup=true&sp=yes#config`;
  }
};

export const urlPatternValidation = (URL) => {
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  return regex.test(URL);
};

export function generateMarketPlaceUlr(redirectUrl) {
  const accessToken = sessionStorage.getItem("TOKEN");

  // const url = `https://marketplace.betalearnings.com/Oauth/tLogin/${accessToken}`;

  console.log("window-url", `${redirectUrl}Oauth/tLogin/${accessToken}`);
  window.open(`${redirectUrl}Oauth/tLogin/${accessToken}`, "_blank");
  return;
}

export function extractValuesSingleFromUrl(url, key) {
  const urlObj = new URL(url);
  return urlObj.searchParams.get(key);
}

export const generateMonthDates = (dayList) => {
  const startDate = new Date();
  const endDate = new Date(startDate);
  endDate.setMonth(endDate.getMonth() + 1); // Set the end date to one month ahead

  const dates = [];
  const options = { weekday: "short", day: "numeric", month: "short" };
  const formatter = new Intl.DateTimeFormat("en-US", options);

  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const formattedDate = formatter.format(currentDate).split(", ");
    const [day, datePart] = formattedDate;
    const dateObj = {
      day,
      date: `${datePart}`,
      available: dayList.includes(day.toUpperCase()),
      value: moment(currentDate).format("YYYY/MM/DD"),
    };
    dates.push(dateObj);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const findExitingSlot = (slotTime, time) => {
  console.log("slotTime", slotTime);
  console.log("slotTime time", time);

  const list = slotTime.find((item) => item.from === time);
  console.log("slotTime list", list);

  if (list) {
    let addedTime = formatTime(new Date(), 15);
    let addedTimePresent = slotTime.find((item) => item.from === addedTime);

    let hours = 1;
    while (addedTimePresent) {
      let hoursSet = new Date();
      hoursSet.setHours(hoursSet.getHours() + hours);
      addedTime = formatTime(hoursSet, 15);
      addedTimePresent = slotTime.find((item) => item.from === addedTime);
      hours++;
    }

    return addedTime;
  } else {
    return formatTime(new Date());
  }
};

export const generateTwoMonthSlots = (slotData) => {
  const result = [];
  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const getNextDay = (date) => {
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    return nextDay;
  };

  let currentDate = new Date();
  const twoMonthsLater = new Date();
  twoMonthsLater.setMonth(twoMonthsLater.getMonth() + 2);

  while (currentDate <= twoMonthsLater) {
    const dayOfWeek = daysOfWeek[currentDate.getDay()];
    const dayData = slotData.find((d) => d.day === dayOfWeek);
    result.push({
      day: dayOfWeek,
      // date: currentDate.toLocaleDateString("en-GB"),
      date: moment(currentDate).format("YYYY/MM/DD"),
      timeSlots: dayData ? dayData.timeSlots : [],
    });

    currentDate = getNextDay(currentDate);
  }

  return result;
};

export const generateTimeSlots = (startTime, endTime, interval) => {
  const slots = [];
  let current = moment(startTime, "HH:mm");
  const end = moment(endTime, "HH:mm");

  while (current < end) {
    const fromTime = current.format("HH:mm");
    current.add(interval, "minutes");
    const toTime = current.format("HH:mm");

    slots.push({ from: fromTime, to: toTime });
  }

  return slots;
};

export const encryptData = (data) => {
  const ciphertext = CryptoJS.AES.encrypt(data, ENCRYPT_KEY).toString();

  const urlSafeCiphertext = ciphertext
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  return urlSafeCiphertext;
};

export const decryptData = (data) => {
  const regularCiphertext = data.replace(/-/g, "+").replace(/_/g, "/");
  const bytes = CryptoJS.AES.decrypt(regularCiphertext, ENCRYPT_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const shareUrl = (data = "not-present") => {
  console.log("data", data);
  const encrpt = encryptData(data);
  return `${APPCONFIG.BASE_URL}available-calender/?&id=${encrpt}`;
  // return `${BASE_URL}user-calender/?type=${type}&id=${slug}`;
};

export function workSpaceAdmin(role) {
  let workspace = sessionStorage.getItem("WORKSPACE");
  if (workspace === "default") {
    return true;
  }

  if (role === 1 || role === 2) {
    return true;
  } else {
    return false;
  }
}

export function isYuukkeWorkspace() {
  let workspace = sessionStorage.getItem("WORKSPACE");
  if (workspace === "default") {
    return true;
  }
  return false;
}

export const linkedInvalidation = (value) => {
  const data =
    /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(
      value
    );
  return data;
};

export const instaValidation = (value) => {
  const data = /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(value);
  return data;
};
export const twitterValidation = (value) => {
  const data = /http(?:s)?:\/\/(?:www\.)?x\.com\/([a-zA-Z0-9_]+)/.test(value);
  return data;
};

export const mailInit = (e, email) => {
  window.location.href = `mailto:${email}`;
  e.preventDefault();
  e.stopPropagation();
};

export const phoneInit = (e, number) => {
  window.location.href = `tel:${number}`;
  e.preventDefault();
  e.stopPropagation();
};
export const getFlagEmoji = (countryCode) => {
  return countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt()));
};

export const checkImageURL = (name) => {
  name = name.toLowerCase();
  return name.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

export const checkVideoURL = (name) => {
  name = name.toLowerCase();
  return name.match(/\.(mp4|webm|ogg|m4v)$/) != null;
};

export const checkAudioURL = (name) => {
  name = name.toLowerCase();
  return name.match(/\.(wav|mp3|m4v)$/) != null;
};

export const checkPDFURL = (name) => {
  name = name.toLowerCase();
  return name.match(/\.(pdf|xlsx|csv|ods|pptx|zip)$/) != null;
};
