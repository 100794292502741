import React, { Component } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextEditorQuestion from "../../components/TextEditorQuestion";
import { toast } from "react-toastify";
import QuestionFileOption from "./QuestionFileOption";
// import './mysocial.css'
// import './mysocialprofile.css'
// import './yuukkeTutoring.css'
import { Card, CardContent, CardHeader } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import AnnouncementService from "../../api/AnnouncementService";
import Swal from "sweetalert2";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import { withRouter } from "react-router";
//import UserService from '../../api/UserService.js';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
let newAnswer = new Array(2);

class AddAnnouncemment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: false,
      fileUrl: null,
      type: null,
      mediaPost: "",
      teaching: [],
      tags: [],
      question: "",
      classDetails: [],
      addTagsState: false,
      fileOption: false,
      gradeDetails: [],
      questionDescribed: "",
      editState: false,
      announceStartDate: "",
      announceEndDate: "",
      announceTitle: "",
      frannouncementName: [],
      inputs: [],
    };
    this.block = this.block.bind(this);
    this.myRef = React.createRef();
    //this.getallSubject = this.getallSubject.bind(this);
    this.editorStateValues = this.editorStateValues.bind(this);
    this.editorStateValuesAnnouncement =
      this.editorStateValuesAnnouncement.bind(this);
    this.handleTeachingMultiSelect = this.handleTeachingMultiSelect.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.getQuestionMediaFiles = this.getQuestionMediaFiles.bind(this);
    //this.handleGrade = this.handleGrade.bind(this)
    //this.getallGrade = this.getallGrade.bind(this)
    this.fileOptionEnable = this.fileOptionEnable.bind(this);
    //this.onUpdateSubmit = this.onUpdateSubmit.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.addTranslation = this.addTranslation.bind(this);
    this.removeTranslation = this.removeTranslation.bind(this);
    this.refreshlanguage = this.refreshlanguage.bind(this);
    this.handleInputAnnouncement = this.handleInputAnnouncement.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  componentDidMount() {
    this.refreshlanguage();
  }

  handleInputAnnouncement(event, index, type) {
    // alert(event)
    console.log("inputs values");
    console.log(event);

    var newAnswers = this.state.frannouncementName;
    if (newAnswers[index]) {
      if (type == "name") {
        event.preventDefault();
        event.stopPropagation();
        var obj = newAnswers[index];
        obj.Sname = event.target.value;
        newAnswers[index] = obj;
      } else if (type == "desp") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newAnswers[index];
        obj.Sdesp = event;
        newAnswers[index] = obj;
      } else {
        event.preventDefault();
        event.stopPropagation();
        var obj = newAnswers[index];
        obj.Scode = event.target.value;
        newAnswers[index] = obj;
      }
    } else {
      if (type == "name") {
        event.preventDefault();
        event.stopPropagation();
        var obj = newAnswers[index];
        var obj = {
          Sname: event.target.value,
          Sdesp: "",
          Scode: "",
        };
        newAnswers[index] = obj;
      } else if (type == "desp") {
        var data = event;
        var obj = newAnswers[index];
        var obj = {
          Sname: "",
          Sdesp: data,
          Scode: "",
        };
        console.log("inputsss");
        console.log(obj);
        newAnswers[index] = obj;
      } else {
        event.preventDefault();
        event.stopPropagation();
        var obj = {
          Sname: "",
          Sdesp: "",
          Scode: event.target.value,
        };
        newAnswers[index] = obj;
      }
    }

    this.setState({
      frannouncementName: newAnswers,
    });
    console.log("this.state.frannouncementName");
    console.log(newAnswer);
    console.log(this.state.frannouncementName);
    //  this.setState({answers:answer_id.target.value})
  }

  addTranslation = (e) => {
    var input = this.state.inputs;
    input.push(1);
    this.setState({
      inputs: input,
    });
  };

  async removeTranslation(record, index) {
    console.log("REMOVE TRANSLATION");
    console.log(index);
    console.log(this.state.inputs);
    var inpu = this.state.inputs;
    inpu.splice(index, 1);
    if (this.state.frannouncementName[index]) {
      var val = this.state.frannouncementName;
      val.splice(index, 1);
      this.setState({
        frannouncementName: val,
      });
    }
    await this.setState({
      inputs: inpu,
    });
  }

  render() {
    return (
      <div className="screenInnerGapAA">
        <div className="userListContainerWrapper">
          <Card>
            <CardHeader title="CREATE NEW ANNOUNCEMENT"></CardHeader>
            <CardContent className="boxPage">
              <div className="toolbar">
                <div className="rightBox" style={{ float: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.onFormSubmit}
                    startIcon={<SaveIcon />}
                  >
                    {" "}
                    Save Announcement
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="default"
                    onClick={this.onBack}
                    startIcon={<ArrowBackIcon />}
                  >
                    {" "}
                    Back to List
                  </Button>
                </div>
              </div>
              <Grid container spacing={3}>
                <Grid className="gridform" item xs={12} sm={6}>
                  {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={this.addTranslation}
                  startIcon={<AddIcon />}
                > Add Translation
                </Button> */}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {/*<div className="askAnnouncementCardWrapper">*/}

                <Grid className="gridform" item xs={4} sm={4}>
                  <label className="formLabel">Announcement Title</label>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    required="required"
                    name="announceTitle"
                    label="Title"
                    onChange={this.handleForm}
                    value={this.state.announceTitle}
                  />
                </Grid>
                <Grid className="gridform" item xs={4} sm={4}>
                  <label className="formLabel">Start Date</label>

                  <TextField
                    fullWidth
                    variant="outlined"
                    type="date"
                    required="required"
                    name="announceStartDate"
                    onChange={this.handleForm}
                    value={this.state.announceStartDate}
                  />
                </Grid>

                <Grid className="gridform" item xs={4} sm={4}>
                  <label className="formLabel">End Date</label>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="date"
                    required="required"
                    name="announceEndDate"
                    onChange={this.handleForm}
                    value={this.state.announceEndDate}
                  />
                </Grid>
                <Grid className="gridform" item xs={12} sm={12}>
                  <div
                    style={{ height: "220px", marginBottom: "10px" }}
                    className="askAQuestionDescription"
                  >
                    <label className="formLabel">Description*</label>
                    <TextEditorQuestion
                      ref={this.myRef}
                      editorStateValue={this.editorStateValues}
                      editorValue={this.state.questionDescribed}
                    />
                  </div>
                </Grid>
                <Grid
                  className="gridform"
                  item
                  xs={12}
                  sm={12}
                  style={{ marginTop: "65px" }}
                >
                  <QuestionFileOption
                    fileOption={this.state.fileOption}
                    fileOptionEnable={this.fileOptionEnable}
                    fromQuestionMedia={this.getQuestionMediaFiles}
                    editObject={this.props.editObject}
                  ></QuestionFileOption>
                </Grid>

                {this.state.inputs.length > 0 &&
                  this.state.inputs.map((entry, index) => (
                    <Grid container spacing={3} key={entry.index}>
                      <Grid className="gridform" item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          required=""
                          name="frannouncementName"
                          label="Announcement Name"
                          onChange={(e) =>
                            this.handleInputAnnouncement(e, index, "name")
                          }
                          value={
                            this.state.frannouncementName &&
                            this.state.frannouncementName[index] &&
                            this.state.frannouncementName[index].Sname
                          }
                        />
                      </Grid>

                      <Grid className="gridform" item xs={12} sm={4}>
                        <TextEditorQuestion
                          key={index}
                          ref={this.myRef}
                          editorStateValue={(value) =>
                            this.editorStateValuesAnnouncement(value, index)
                          }
                          editorValue={
                            this.state.frannouncementName &&
                            this.state.frannouncementName[index]
                              ? this.state.frannouncementName[index].Sdesp
                              : ""
                          }
                        />
                      </Grid>
                      <Grid className="gridform" item xs={12} sm={3}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="school-label">
                            Select Language Code
                          </InputLabel>
                          <Select
                            labelId="Select Language Code"
                            name="Select Language Code"
                            // value={this.state.langShortCode}
                            value={
                              this.state.frannouncementName &&
                              this.state.frannouncementName[index] &&
                              this.state.frannouncementName[index].Scode
                            }
                            onChange={(e) =>
                              this.handleInputAnnouncement(e, index, "code")
                            }
                            label="Select Language Code"
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            {this.state.language &&
                              this.state.language.map((language) => (
                                <MenuItem
                                  key={language.languageId}
                                  value={language.langShortCode}
                                >
                                  {language.languageName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <br />
                      </Grid>
                      <Grid className="gridform" item xs={12} sm={1}>
                        <Button
                          variant="contained"
                          color="default"
                          onClick={() => this.removeTranslation(entry, index)}
                        >
                          {" "}
                          -
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  handleGrade(event) {
    console.log("inside Grade");

    this.setState({
      //[event.target.name]
      gradeId: event.target.value,
    });

    //console.log(event.target.value)
  }

  getQuestionMediaFiles(files, type) {
    console.log(files);
    console.log(type);
    this.setState({ file: files, type: type });
  }

  editorStateValues(values) {
    console.log(values);
    this.setState({ content: values });
  }

  editorStateValuesAnnouncement(values, index) {
    console.log("values");
    console.log(values);

    this.handleInputAnnouncement(values, index, "desp");
  }

  handleTeachingMultiSelect(subject_value) {
    this.setState({
      teaching: subject_value,
    });
  }

  fileOptionEnable() {
    this.setState({ fileOption: false, questionFile: null });
  }

  onFormSubmit(e) {
    console.log("onFormSubmits Announcement");
    console.log(this.state.announceStartDate);
    console.log(this.state.announceEndDate);
    console.log(this.state.announceTitle);
    console.log(this.state.content);
    console.log(this.state.file);
    console.log(this.state.type);
    console.log(this.state.content.length);

    const formData = new FormData();
    if (this.state.announceTitle === "") {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please Enter Valid Announcement Title !",
      });
    } else if (this.state.announceStartDate === "") {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please Choose Valid Start Date !",
      });
    } else if (this.state.announceEndDate === "") {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please Choose Valid End Date !",
      });
    } else if (this.state.content === undefined) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please Enter Valid Announcement Description !",
      });
    } else if (this.state.content.length >= 2500) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Entered  description should not be above 2500 characters !",
      });
    } else if (
      this.state.announceTitle !== "" &&
      this.state.content !== "" &&
      this.state.announceStartDate !== ""
    ) {
      var fina = this.state.frannouncementName;

      for (let i = 0; i < fina.length; i++) {
        if (fina[i].Sname !== "" && fina[i].Sdesp == "") {
          formData.append(fina[i].Scode, fina[i].Sname);
        } else if (fina[i].Sdesp !== "" && fina[i].Sname == "") {
          formData.append(fina[i].Scode + "_desc", fina[i].Sdesp);
        } else if (fina[i].Sdesp !== "" && fina[i].Sname !== "") {
          formData.append(fina[i].Scode, fina[i].Sname);
          formData.append(fina[i].Scode + "_desc", fina[i].Sdesp);
        }
      }

      if (this.state.file !== undefined) {
        formData.append("title", this.state.announceTitle);
        formData.append("description", this.state.content);
        formData.append("announcementFile", this.state.file);
        formData.append("type", this.state.type);
        formData.append(
          "startDate",
          moment(this.state.announceStartDate).format("DD/MM/YYYY")
        );
        formData.append(
          "endDate",
          moment(this.state.announceEndDate).format("DD/MM/YYYY")
        );
        formData.append("en", "data");
        formData.append("en" + "_desc", "data");
      } else {
        formData.append("title", this.state.announceTitle);
        formData.append("description", this.state.content);
        formData.append(
          "startDate",
          moment(this.state.announceStartDate).format("DD/MM/YYYY")
        );
        formData.append(
          "endDate",
          moment(this.state.announceEndDate).format("DD/MM/YYYY")
        );
        formData.append("en", "data");
        formData.append("en" + "_desc", "data");
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      AnnouncementService.saveAnnouncement(formData, config)
        .then((response) => {
          /*var result = response.data;*/
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Announcement Saved Succesfully !",
          });
          this.myRef.current.onClear();
          this.setState({
            content: "",
            question: "",
            teaching: [],
            fileOption: true,
            file: "",
          });
          //this.block(false);
          this.props.history.push(`/${this.props.workspaceType}/home`);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error in saving question");
        });
    }
  }

  block(blocking) {
    this.setState({ blocking: blocking });
  }

  handleForm(event) {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }

  onBack() {
    this.props.history.push(`/${this.props.workspaceType}/home`);
  }

  refreshlanguage() {
    // UserService.getLangList()
    //   .then(
    //     response => {
    //       console.log(response.data.data)
    //       var languageList = []
    //       if (response.data.data.length > 0) {
    //         for (let i = 0; i < response.data.data.length; i++) {
    //           if (response.data.data[i].langShortCode !== "en" && (response.data.data[i].langShortCode == "" || response.data.data[i].langShortCode !== null)) {
    //             languageList.push({
    //               "langShortCode": response.data.data[i].langShortCode,
    //               "languageName": response.data.data[i].languageName
    //             })
    //           }
    //         }
    //         this.setState({ language: languageList })
    //       }
    //     })
  }
}
const mapStateToProps = function (state) {
  return {
    workspaceType:
      state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

export default connect(mapStateToProps, null)(withRouter(AddAnnouncemment));
// export default withRouter(AddAnnouncemment);
