import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import AuthService from "../../session/AuthService";
import MyGroupsContainer from "../../screens/MyGroupsandMyEvents/MyGroupsandeventsList";
import { Container } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  needPhotVerification,
  triggerTourPopup,
} from "../../redux/actions/commonApiActions";
import { giveUserAction, readOnlyWorkspace, workSpaceAdmin } from "../../utils";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationData: [],
      announcementData: [],
      showAnnoucment: false,
      surveyBoxState: false,
      showList: true,
      FeedbackList: [],
      recentlyAddedBadgesList: [],
      FriendsList: [],
      userKnowledgepoint: 0,
      globalLanguage: AuthService.getCommonLanguages(),
      showPage: false,
      dataKey: "",
      creategroupview: false,
      searchValue: "",
      searchMyValue: "",
      searchLearningValue: "",
      searchChallengeValue: "",
      allCommunityFilterOptions: [
        {
          filerType: "Friends Community",
          key: "friendsCommunity",
          isActive: false,
        },
        {
          filerType: "Forum Community",
          key: "forumCommunity",
          isActive: false,
        },
        {
          filerType: "Post Community",
          key: "postCommunity",
          isActive: false,
        },
      ],
      myCommunityFilterOptions: [
        {
          filerType: "My Community",
          key: "myCommunity",
          isActive: false,
        },
        {
          filerType: "Friends Community",
          key: "friendsCommunity",
          isActive: false,
        },
        {
          filerType: "Forum Community",
          key: "forumCommunity",
          isActive: false,
        },
        {
          filerType: "Post Community",
          key: "postCommunity",
          isActive: false,
        },
      ],
      forumFilterOptions: [
        {
          filerType: "Friends Community",
          key: "friendsCommunity",
          isActive: false,
        },
      ],
      enterKey: false,
    };
    this.idfromSubjectCard = this.idfromSubjectCard.bind(this);
    this.movetosubjects = this.movetosubjects.bind(this);
    this.idfromGroupCard = this.idfromGroupCard.bind(this);
    this.createGroup = this.createGroup.bind(this);
    this.searchSubjects = this.searchSubjects.bind(this);
    this.searchLearningApp = this.searchLearningApp.bind(this);
    this.searchChallenge = this.searchChallenge.bind(this);
    this.searchMySubjects = this.searchMySubjects.bind(this);
  }

  handleChangeTab = (event, newIndex) => {
    this.setState({
      tabIndex: newIndex,
    });
    if (newIndex == 0) {
      this.props.history.push(`/${this.props.workspaceType}/mygroups`);
    } else if (newIndex == 1) {
      this.props.history.push(`/${this.props.workspaceType}/groupsList`);
    }
    //  else if (newIndex == 2) {
    //   this.props.history.push(`/learningApp`);
    // }
    else if (newIndex == 3) {
      this.props.history.push(`/challenges`);
    } else if (newIndex == 2) {
      this.props.history.push(`/${this.props.workspaceType}/forumCommunity`);
    }
  };

  componentDidMount() {
    if (!this.props.toursStages) {
      console.log("Trigger Tour popup");
      this.props.triggerTourPopup("community");
    }
    if (this.props.openTab == "myGroups") {
      this.setState({
        tabIndex: 0,
      });
    } else if (this.props.openTab == "groups") {
      this.setState({
        tabIndex: 1,
      });
    }
    // else if (this.props.openTab == "learningApp") {
    //   this.setState({
    //     tabIndex: 2,
    //   });
    // }
    else if (this.props.openTab == "challenges") {
      this.setState({
        tabIndex: 3,
      });
    } else if (this.props.openTab == "forumCommunity") {
      this.setState({
        tabIndex: 2,
      });
    }
    window.addEventListener("keydown", this.enterPress);
    return () => window.removeEventListener("keydown", this.enterPress);
  }
  enterPress = (e) => {
    console.log("User press key", e);
    if (e.key === "Enter") {
      this.setState({
        enterKey: !this.state.enterKey,
      });
    }
  };
  componentDidUpdate(prevProps) {
    if (this.props.openTab !== prevProps.openTab) {
      if (this.props.openTab == "myGroups") {
        this.setState({
          tabIndex: 0,
        });
      } else if (this.props.openTab == "groups") {
        this.setState({
          tabIndex: 1,
        });
      }
      // else if (this.props.openTab == "learningApp") {
      //   this.setState({
      //     tabIndex: 2,
      //   });
      // }
      else if (this.props.openTab == "challenges") {
        this.setState({
          tabIndex: 3,
        });
      } else if (this.props.openTab == "forumCommunity") {
        this.setState({
          tabIndex: 2,
        });
      }
    }
  }

  render() {
    const { t } = this.props;

    const { tabIndex } = this.state;

    return (
      <>
        {" "}
        {this.props.viewMyGroups && (
          <div
            className=""
            style={{ padding: "10px", backgroundColor: "#f5f5f5" }}
          >
            <MyGroupsContainer
              tabIndex={2}
              gotoGroups={this.idfromGroupCard}
            ></MyGroupsContainer>
          </div>
        )}
        {!this.props.viewMyGroups && (
          <>
            <div className="homeMainWrapper">
              <>
                <Grid container spacing={2}>
                  <Grid item lg={9} md={8} sm={12} xs={12}>
                    {this.state.tabIndex == 0 && (
                      <h3 className="listHomeTitle group-count">
                        {t("Joined Community")} -{" "}
                        {this.props.communityLoading ? (
                          <div
                            class="spinner-border text-info spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only"></span>
                          </div>
                        ) : (
                          <span className="badge badge-info">
                            {this.props.totalCommunity}
                          </span>
                        )}
                      </h3>
                    )}
                    {this.state.tabIndex == 1 && (
                      <h3 className="listHomeTitle group-count">
                        {t("All Community")} -{" "}
                        {this.props.communityLoading ? (
                          <div
                            class="spinner-border text-info spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only"></span>
                          </div>
                        ) : (
                          <span className="badge badge-info">
                            {this.props.totalCommunity}
                          </span>
                        )}
                      </h3>
                    )}
                    {this.state.tabIndex == 2 && (
                      <h3 className="listHomeTitle group-count">
                        {t("Forum Community")} -{" "}
                        {this.props.communityLoading ? (
                          <div
                            class="spinner-border text-info spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only"></span>
                          </div>
                        ) : (
                          <span className="badge badge-info ">
                            {this.props.totalCommunity}
                          </span>
                        )}
                      </h3>
                    )}
                    {/* {this.state.tabIndex == 2  &&<h3 className="listHomeTitle">Learning Hub</h3>}
                      {this.state.tabIndex == 3  &&<h3 className="listHomeTitle">Career Labs</h3>} */}
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ paddingBottom: "12px" }}
                  >
                    {!readOnlyWorkspace(
                      this.props.userWorkSpace?.role,
                      this.props.userWorkSpace?.typeHandle
                    ) &&
                      workSpaceAdmin(this.props.userWorkSpace?.role) && (
                        <button
                          className="community-new"
                          style={{ float: "right" }}
                          onClick={this.createGroup}
                        >
                          {t("Create Community")}
                        </button>
                      )}
                  </Grid>
                </Grid>

                {tabIndex == 3 && (
                  <div className="spaceFiletCard newPostCardWrapper">
                    <Grid container spacing={1}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ marginTop: "12px" }}
                      >
                        <TextField
                          id="subjects"
                          label=""
                          style={{ width: "100%", height: "100%" }}
                          variant="outlined"
                          onChange={(e) => this.searchChallenge(e.target.value)}
                          placeholder={t("Search Challenge...")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />

                        <div className="badgeMainWrapper"></div>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {/* {tabIndex == 2 && (
                    <div className="spaceFiletCard newPostCardWrapper">
                      <Grid container spacing={1}>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ marginTop: "12px" }}
                        >
                          <TextField
                            id="subjects"
                            label=""
                            style={{ width: "100%", height: "100%" }}
                            variant="outlined"
                            onChange={(e) =>
                              this.searchSubjects(e.target.value)
                            }
                            placeholder="Search Forum Community..."
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />

                          <div className="badgeMainWrapper"></div>
                        </Grid>
                      </Grid>
                    </div>
                  )} */}
              </>
            </div>

            <div className="" style={{ marginTop: "10px" }}>
              {/* {this.state.tabIndex == 0 && (
                <h3 className="listHomeTitle">My Community</h3>
              )}
              {this.state.tabIndex == 1 && (
                <h3 className="listHomeTitle">All Community</h3>
              )}
              {this.state.tabIndex == 2 && (
                <h3 className="listHomeTitle">Learning Hub</h3>
              )}
              {this.state.tabIndex == 3 && (
                <h3 className="listHomeTitle">Career Labs</h3>
              )} */}
              <div className="squareMainWrapper">
                <div className="peopleCardWrapperGroups ">
                  <Container>
                    <div className="flexDefault  ">
                      <Tabs
                        // TabIndicatorProps={{ style: { background: "#f97516" } }}
                        value={tabIndex}
                        //   textColor="secondary"
                        textColor="primary"
                        onChange={this.handleChangeTab}
                        aria-label="disabled tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        <Tab
                          style={{ textTransform: "none" }}
                          label={t("JOINED COMMUNITY")}
                          {...a11yProps(0)}
                          className="my-community-list"
                        />
                        <Tab
                          style={{ textTransform: "none" }}
                          label={t("ALL COMMUNITY")}
                          {...a11yProps(1)}
                          className="all-community-list"
                        />
                        <Tab
                          style={{ textTransform: "none" }}
                          label={t("FORUM COMMUNITY")}
                          {...a11yProps(2)}
                          className="forum-community-list"
                        />
                        {/* <Tab
                          style={{ textTransform: "none" }}
                          label={t("LEARNING HUB")}
                          {...a11yProps(2)}
                        />
                        <Tab
                          style={{ textTransform: "none" }}
                          label={t("CAREER LABS")}
                          {...a11yProps(3)}
                        /> */}
                      </Tabs>
                    </div>
                  </Container>
                </div>

                {tabIndex === 0 ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="announcementWrapperOuter">
                          <Grid style={{ paddingTop: 12 }}>
                            <MyGroupsContainer
                              type={"myCommunity"}
                              communityFilterOptions={
                                this.state.myCommunityFilterOptions
                              }
                              enterKey={this.state.enterKey}
                            ></MyGroupsContainer>{" "}
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : tabIndex === 1 ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="announcementWrapperOuter">
                          <Grid style={{ paddingTop: 12 }}>
                            <MyGroupsContainer
                              type={"allCommunity"}
                              communityFilterOptions={
                                this.state.allCommunityFilterOptions
                              }
                              enterKey={this.state.enterKey}
                            ></MyGroupsContainer>
                          </Grid>
                          <div style={{ marginBottom: "20px" }}></div>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : //  : tabIndex === 2 ? (
                //   <>
                //     <Grid container spacing={2}>
                //       <Grid item lg={12} md={12} sm={12} xs={12}>
                //         <div className="announcementWrapperOuter">
                //           <Grid style={{ paddingTop: 12 }}>
                //             <LearningAppContainer
                //               tabIndex={2}
                //               gotoGroups={this.idfromGroupCard}
                //               searchValue={this.state.searchLearningValue.trim()}
                //             ></LearningAppContainer>
                //           </Grid>
                //         </div>
                //       </Grid>
                //     </Grid>
                //   </>
                // )
                tabIndex === 2 ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="announcementWrapperOuter">
                          <Grid style={{ paddingTop: 12 }}>
                            <MyGroupsContainer
                              type={"allForumCommunity"}
                              communityFilterOptions={
                                this.state.forumFilterOptions
                              }
                              enterKey={this.state.enterKey}
                            ></MyGroupsContainer>
                          </Grid>
                          <div style={{ marginBottom: "20px" }}></div>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {/* <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="announcementWrapperOuter">
                          <Grid style={{ paddingTop: 12 }}>
                            <ChallengeContainer
                              tabIndex={2}
                              gotoGroups={this.idfromGroupCard}
                              searchValue={this.state.searchChallengeValue.trim()}
                            ></ChallengeContainer>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid> */}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  movetosubjects() {
    this.setState({ showPage: false, dataKey: "" });
  }

  searchSubjects(value) {
    this.setState({ searchValue: value });
  }

  searchMySubjects(value) {
    this.setState({ searchMyValue: value });
  }

  searchLearningApp(value) {
    this.setState({ searchLearningValue: value });
  }

  searchChallenge(value) {
    this.setState({ searchChallengeValue: value });
  }
  createGroup = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      this.props.history.push(`/${this.props.workspaceType}/createGroup`);
    }
  };
  idfromSubjectCard(
    id,
    key,
    name,
    data,
    editQuestion,
    subjectName,
    subId,
    redirecttype,
    movetoQuestions,
    event,
    grouporeventFiles,
    fileType,
    subjectDescription,
    memberShipPrice,
    memberShipType,
    expiryDate,
    createdDate,
    displayName,
    userId,
    status,
    tagListId
  ) {
    if (key) {
      console.log("First idform////");
      this.setState({ fullView: false });
      this.setState({ subjectId: id });
      this.setState({ subjectName: name });
      this.setState({ questionUi: true });
      this.setState({ dataKey: data });
      this.setState({
        showPage: true,
        createdDate: createdDate,
        subjectDescription: subjectDescription,
      });
      this.setState({
        editQuestion: editQuestion,
        redirecttype: redirecttype,
        movetoQuestions: movetoQuestions,
        event: event,
        grouporeventFiles: grouporeventFiles,
        fileType: fileType,
        memberShipPrice: memberShipPrice,
        memberShipType: memberShipType,
        expiryDate: expiryDate,
        displayName: displayName,
        userId: userId,
        status: status,
        tagListId: tagListId,
      });
      console.log("idform");
    }
    //this.props.gotoSubjects(id,key,name,data)
  }

  idfromGroupCard(
    id,
    key,
    name,
    data,
    editQuestion,
    subjectName,
    subId,
    redirecttype,
    movetoQuestions,
    event,
    grouporeventFiles,
    fileType,
    subjectDescription,
    memberShipPrice,
    memberShipType,
    expiryDate,
    createdDate,
    displayName,
    userId,
    tagListId,
    type,
    communityTypeName
  ) {
    if (key) {
      console.log("First idform////");
      this.setState({ fullView: false });
      this.setState({ gsubjectId: id });
      this.setState({ gsubjectName: name });
      this.setState({ questionUi: true });
      this.setState({ dataKey: data });
      this.setState({
        showPage: true,
        createdDate: createdDate,
        subjectDescription: subjectDescription,
      });
      this.setState({
        editQuestion: editQuestion,
        redirecttype: redirecttype,
        movetoQuestions: movetoQuestions,
        gevent: event,
        subjectDescription: subjectDescription,
        grouporeventFiles: grouporeventFiles,
        fileType: fileType,
        memberShipPrice: memberShipPrice,
        memberShipType: memberShipType,
        expiryDate: expiryDate,
        displayName: displayName,
        userId: userId,
        tagListId: tagListId,
        type: type,
      });
    }
    var gName = name.trim();
    gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
    // if (communityTypeName === "Forum Community") {
    //   //forum
    //   this.props.history.push(`/forum-community/${id}/${gName}`);
    // } else {
    // }
    this.props.history.push(
      `/${this.props.workspaceType}/myGroupDescription/${id}/${gName}`
    );
  }
}

// export default (withTranslation()(Home));
const mapDispacthToProps = (dispatch) => {
  return {
    triggerTourPopup: (slug) => dispatch(triggerTourPopup(slug)),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};
const mapStateToProps = function (state) {
  return {
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    loginDetails: state.loginDetailsApi.loginDetails,
    toursStages: state.commonApi.communityPopupStatus,
    userWorkSpace: state.loginDetailsApi.loginDetails?.userWorkSpace,
    totalCommunity: state.subjects.totalCommunity,
    authUser: state.loginDetailsApi.authUser,
    communityLoading: state.subjects.communityLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(Home)));
