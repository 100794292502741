import React, { Component } from "react";
import BgBanner from "./BgBanner";
import Grid from "@material-ui/core/Grid";
import FriendsCard from "./FriendsCard";
import UserInfo from "./UserInfo";
import Container from "@material-ui/core/Container";
import AuthService from "../../../session/AuthService";
import ProfileService from "../../../api/ProfileService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import * as APPCONFIG from "../../../config/app-config.js";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ProfileDetailsForm from "../../../screens/FirstTimeProfile/ProfileDetailsForm";
import dummyimage from "../../../img/default_avatar.jpg";
import banner from "../../../img/banner.jpg";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { Card, CardContent } from "@material-ui/core";
import { withRouter } from "react-router";
import {
  getAllCommonFriendsList,
  getAllBlockedUserList,
  triggerTourPopup,
  getFriendsListBasedWorkSpace,
} from "../../../redux/actions/commonApiActions";
import MyCommunity from "../HomeUpdated/MyCommunity";
import MarketPlaceCard from "../../../components/MarketPlaceCard.jsx";
import { CgProfile } from "react-icons/cg";
import { GoBriefcase } from "react-icons/go";
import { BsBookmarkStar, BsClipboard } from "react-icons/bs";
import { GrValidate } from "react-icons/gr";
import { AiOutlineSafety } from "react-icons/ai";
// import UserCalendarContainer from "../../FirstTimeProfile/UserCalendarContainer.jsx";
import { MdOutlineVideoSettings } from "react-icons/md";
import { isYuukkeWorkspace } from "../../../utils.js";
import CalendarContainer from "../../Calender/ProfileCalendar/CalendarContainer.jsx";
const menus = [
  {
    title: "Profile",
    icon: <CgProfile />,
  },
  {
    title: "Value Match",
    icon: <AiOutlineSafety />,
  },
  {
    title: "Education",
    icon: <BsClipboard />,
  },
  {
    title: "Skills",
    icon: <BsBookmarkStar />,
  },
  {
    title: "Work Experience",
    icon: <GoBriefcase />,
  },
];
class ProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      friendsList: [],
      userKnowledgepoint: "",
      coverPhotoImage: "",
      businessProfile: false,
      industrysList: [],
      activeMenu: 0,
    };
    this.gotoBusinessProfile = this.gotoBusinessProfile.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getProfilePhoto = this.getProfilePhoto.bind(this);
    this.getUserCoverPhoto = this.getUserCoverPhoto.bind(this);
    this.getKnowledgePoints = this.getKnowledgePoints.bind(this);
    this.getUpdatedFriendsList = this.getUpdatedFriendsList.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.getCoverPhotos = this.getCoverPhotos.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getallHobbies = this.getallHobbies.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.gotoprofession = this.gotoprofession.bind(this);
    this.gotoeducation = this.gotoeducation.bind(this);
    this.gotoprofile = this.gotoprofile.bind(this);
    this.gotoskills = this.gotoskills.bind(this);
    this.getIndustry = this.getIndustry.bind(this);
    this.gotoValueMatch = this.gotoValueMatch.bind(this);
  }

  componentDidMount() {
    if (!this.props.toursStages) {
      console.log("Trigger Tour popup");

      this.props.triggerTourPopup("profile");
    }
    if (this.props?.match?.params?.menu === "service") {
      this.setState({
        tabIndex: 5,
      });
    }
    this.props.getAllBlockedUserList();
    if (this.props.backfriendList) {
      this.setState({
        tabIndex: 1,
      });
    }
    if (
      this.props.loginDetails.profession_Id == 3 ||
      this.props.loginDetails.profession_Id == 4
    ) {
      this.setState({
        businessProfile: true,
      });
    } else {
      this.setState({
        businessProfile: false,
      });
    }
    //this.getKnowledgePoints();
    // this.getIndustry();
    const data = this.props.loginDetails.profile_uuid;
    const profileImage = data
      ? `${APPCONFIG.API_URL}auth/image/${data}`
      : dummyimage;
    console.log("friends****", this.props.FriendsList);
    this.setState({ userPhotoImage: profileImage });
    if (!this.props.loginDetails.hasOwnProperty("cover_uuid")) {
      this.setState({
        coverPhotoImage: banner,
      });
    } else {
      this.getCoverPhotos(this.props.loginDetails.cover_uuid);
    }
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.setState({
      friendsList: AuthService.getFriendsList(),
    });
  }
  initfunction() {
    this.getallHobbies();
    this.getallGrade();
    this.getFriendsList();
  }
  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
      showAskQuestion: false,
    });
  }

  async getCoverPhotos(coverPhoto) {
    console.log("coverPhotoImage");

    if (this.props.loginDetails.cover_uuid != "") {
      console.log("entering if");
      await this.setState({
        coverPhotoImage: `${APPCONFIG.API_URL}auth/image/${coverPhoto}`,
      });
      console.log(this.state.coverPhotoImage);
    } else {
      console.log("entering else");
      await this.setState({ coverPhotoImage: banner });
      console.log(this.state.coverPhotoImage);
    }
  }
  changeMenu = (index) => {
    if (index === 1) {
      this.props.history.push("/value-match");
    } else if (index === 2) {
      this.props.history.push("/my-education");
    } else if (index === 3) {
      this.props.history.push("/my-skills");
    } else if (index === 4) {
      this.props.history.push("/my-profession");
    }
  };
  render() {
    const { t } = this.props;
    return (
      <Container>
        <ToastContainer position="top-right" autoClose={5000} />
        {/* <Card>
          <CardContent> */}
        <div className="profileMainWrapper">
          {!this.props.firstTime && (
            <h2 className="pageHeading">{t("Profile")}</h2>
          )}
          <div className="profileWrapper">
            {!this.props.firstTime && (
              <BgBanner
                coverPhoto={this.state.coverPhotoImage}
                profilePhoto={this.state.userPhotoImage}
                userName={"User name"}
                getUserPhoto={this.getProfilePhoto}
                getCoverPhoto={this.getUserCoverPhoto}
              />
            )}
            {!this.props.firstTime && (
              <Grid container spacing={2} className="userDetailsWrapper">
                <Grid item md={12}>
                  <UserInfo
                    name={this.props.loginDetails.dName}
                    designation={this.props.loginDetails.biography}
                    additionalInfo={this.props.loginDetails.hsTag}
                    pointsCount={this.state.userKnowledgepoint}
                    loginDetails={this.props.loginDetails}
                    premiumSubscription={this.props.premiumSubscription}
                  />
                </Grid>
              </Grid>
            )}
            {isYuukkeWorkspace() && (
              <button
                style={{ marginLeft: "2.5rem" }}
                onClick={() =>
                  this.setState({
                    tabIndex: 5,
                  })
                }
              >
                <MdOutlineVideoSettings className="mr-2 text-white svg-icons" />
                {t("Manage Your Calendar")}
              </button>
            )}
            {!this.props.firstTime && (
              <div className="tabWrapper">
                <div className="">
                  <Container>
                    <div className="flexDefault  ">
                      <Tabs
                        value={this.state.tabIndex}
                        // indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChangeTab}
                        aria-label="disabled tabs example"
                      >
                        <Tab
                          className="tour-profile-card"
                          label={t("PROFILE")}
                        />

                        <Tab
                          className="tour-friend-card"
                          label={t("FRIENDS")}
                        />
                        <Tab
                          className="tour-blocked-card"
                          label={t("BLOCKED USER")}
                        />
                        <Tab className="tour-feed-card" label={t("FEED")} />
                        <Tab
                          className="tour-bookmarked-card"
                          label={t("BOOKMARKED POSTS")}
                        />
                        {isYuukkeWorkspace() && (
                          <Tab
                            className="tour-user-calendar"
                            label={t("MANAGE YOUR SERVICE")}
                          />
                        )}
                        {this.props.loginDetails?.isVerifySeller && (
                          <Tab label={t("PRODUCTS")} />
                        )}
                      </Tabs>
                    </div>
                  </Container>
                </div>
                <div className="profilepageWrapperProfileSetup">
                  {this.state.tabIndex === 5 ? (
                    <Grid container spacing={0}>
                      <Grid item md={12} xs={12} sm={12} lg={12}>
                        <CalendarContainer />
                      </Grid>
                    </Grid>
                  ) : this.state.tabIndex === 0 ? (
                    <div>
                      <Grid container spacing={0}>
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <div className="">
                            <Grid container spacing={1}>
                              <Grid item md={3} xs={12} sm={12} lg={3}>
                                <ul className="profile-card-v1">
                                  {menus.map((item, index) => (
                                    <li
                                      className={
                                        index === this.state.activeMenu
                                          ? "bg-color"
                                          : ""
                                      }
                                      key={index}
                                      onClick={() => this.changeMenu(index)}
                                    >
                                      {item.icon}
                                      <h4>{t(`${item.title}`)}</h4>
                                    </li>
                                  ))}
                                </ul>
                                {/* <div
                                      className="navHomeProfile"
                                      tabindex="1"
                                      onClick={this.gotoPreference}
                                    >
                                      <p style={{ color: "#000000" }}>
                                        <IoSettingsOutline
                                          style={{
                                            width: "26px",
                                            height: "26px",
                                            marginRight: "17px",
                                            color: "gray",
                                          }}
                                        />

                                        {t("Preferences")}
                                      </p>
                                    </div> */}
                                {/* {this.state.businessProfile && (
                                      <div
                                        className="navHomeProfession"
                                        tabindex="1"
                                        onClick={this.gotoBusinessProfile}
                                      >
                                        <p
                                          className=""
                                          style={{
                                            color: "#000000",
                                            fontSize: "15px",
                                          }}
                                        >
                                          <FcViewDetails
                                            style={{
                                              marginRight: "8px",
                                              width: "22px",
                                              height: "22px",
                                              color: "#000000",
                                            }}
                                          />
                                          {t("Business Profile")}
                                        </p>
                                      </div>
                                    )} */}
                              </Grid>

                              <Grid item md={9} xs={12} sm={12} lg={9}>
                                <div className="profileselectCard">
                                  <ProfileDetailsForm></ProfileDetailsForm>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : this.state.tabIndex === 1 ? (
                    <div>
                      <Grid
                        container
                        spacing={2}
                        className="userDetailsWrapper-friend"
                      >
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <p className="boldLabel" style={{ marginBottom: 10 }}>
                            {t("Friends")}
                          </p>
                        </Grid>
                        {this.props.friendsListBasedOnWST &&
                          this.props.friendsListBasedOnWST.map((data) => (
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              {/* <FriendsCard
                                tabIndex={"Friends"}
                                friendId={data.userId}
                                fUserId={data.userProfile.userId}
                                image={data.profilePhoto}
                                name={`${data.userProfile.firstName} ${data.userProfile.lastName}`}
                                Dname={data.userProfile.displayName}
                                smallDescription={data.userProfile.biography}
                                unfriendHits={this.getUpdatedFriendsList}
                              /> */}
                              <FriendsCard
                                tabIndex={"Friends"}
                                friendId={data.userId}
                                fUserId={data.userId}
                                image={data.profilePhoto}
                                name={`${data.userName}`}
                                Dname={data.userName}
                                smallDescription={data.biography}
                                unfriendHits={this.getUpdatedFriendsList}
                              />
                            </Grid>
                          ))}
                        {this.props.FriendsList.length === 0 && (
                          <Card>
                            <CardContent>
                              <center>
                                <p>{t("No friends available")}</p>
                              </center>
                            </CardContent>
                          </Card>
                        )}{" "}
                      </Grid>
                    </div>
                  ) : this.state.tabIndex === 2 ? (
                    <div>
                      <Grid
                        container
                        spacing={2}
                        className="userDetailsWrapper-friend"
                      >
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <p className="boldLabel" style={{ marginBottom: 10 }}>
                            {t("Blocked User")}
                          </p>{" "}
                        </Grid>
                        {this.props.blockedUserList &&
                          this.props.blockedUserList.map((data) => (
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <FriendsCard
                                tabIndex={"BlockedUser"}
                                friendId={data.blockUserId}
                                fUserId={data.blockUserId}
                                image={data.profilePhoto}
                                name={data.userName}
                                Dname={data.userName}
                                // smallDescription={data.userProfile.biography}
                                unfriendHits={this.getUpdatedFriendsList}
                              />
                            </Grid>
                          ))}
                        {this.props.blockedUserList.length === 0 && (
                          <Card>
                            <CardContent>
                              <center>
                                <p>{t("No More Blocked List Available")}</p>
                              </center>
                            </CardContent>
                          </Card>
                        )}{" "}
                      </Grid>
                    </div>
                  ) : this.state.tabIndex === 3 || this.state.tabIndex === 4 ? (
                    <div style={{ marginTop: "5px" }}>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <MyCommunity
                            homePost={false}
                            type={
                              this.state.tabIndex === 3 ? "Feed" : "BookMark"
                            }
                          />
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <Grid container spacing={0}>
                      <Grid item md={12} xs={12} sm={12} lg={12}>
                        <MarketPlaceCard />
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* </CardContent>
        </Card> */}
      </Container>
    );
  }

  gotoprofession() {
    this.props.history.push("/my-profession");
  }
  gotoPreference = () => {
    this.props.history.push("/my-preference");
  };

  gotoeducation() {
    this.props.history.push("/my-education");
  }

  gotoprofile() {
    this.props.history.push("/my-profile");
  }
  gotoBusinessProfile() {
    this.props.history.push("my-business-profile");
  }
  gotoskills() {
    this.props.history.push("/my-skills");
  }

  getUpdatedFriendsList() {
    this.props.getFriendsListBasedWorkSpace();
  }

  gotoValueMatch() {
    this.props.history.push("/value-match");
  }

  getProfilePhoto(userPhoto) {
    this.setState({
      userPhotoImage: `${APPCONFIG.API_URL}auth/image/${userPhoto}`,
    });
  }
  getUserCoverPhoto(coverPhoto) {
    // this.setState({
    //   coverPhotoImage: `data:image/jpeg;base64,${coverPhoto}`,
    //   coverPhotoImageState: true,
    // });
    this.setState({
      coverPhotoImage: `${APPCONFIG.API_URL}auth/image/${coverPhoto}`,
      coverPhotoImageState: true,
    });
  }
  getIndustry() {
    // alert()
    var industryList = this.props.industryList;
    console.log("loginDetails", this.props.loginDetails);
    console.log("industryList", industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        var obj = {};
        obj["id"] = industryList[i].industryId;
        obj["name"] = industryList[i].industryName;
        lList.push(obj);
      }

      this.setState({ industrysList: lList });
      console.log("industrysList:", lList);
    }
    this.getEducationDetails(lList);
  }
  getEducationDetails() {
    var saveEducation = {};
    ProfileService.getWorkExperienceDetails(saveEducation).then((response) => {
      var result = response.data;

      if (response.data.status === 1) {
        this.setState({
          //    educationDetails: response.data.data.workExperience,
        });
        var educationData = response.data.data.workExperience;

        for (var j = 0; j < this.state.industrysList.length; j++) {
          for (var i = 0; i < educationData.length; i++) {
            if (this.state.industrysList[j].id === educationData[i].industry) {
              if (educationData[i].industry === 18) {
                this.setState({
                  businessProfile: true,
                });
              } else {
                this.setState({
                  businessProfile: false,
                });
              }
            }
          }
        }
      }
    });
  }
  async getKnowledgePoints() {
    let getKnowledgePoints = { userId: this.props.loginDetails.uId };
    var response = ProfileService.getKPCache(getKnowledgePoints);
    if (response) {
      var result = response.data;
      if (result.data === null) {
        this.setState({ userKnowledgepoint: 0 });
      } else {
        this.setState({ userKnowledgepoint: result.data });
      }
    } else {
      ProfileService.getKnowledgePointByUser(getKnowledgePoints)
        .then((response) => {
          var result = response.data;
          if (result.data === null) {
            this.setState({ userKnowledgepoint: 0 });
          } else {
            ProfileService.setKPCache(getKnowledgePoints, response);
            this.setState({ userKnowledgepoint: result.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getallHobbies() {
    ProfileService.getallHobbies()
      .then((response) => {
        console.log(response);
        var result = response.data;
        AuthService.storeHobbiesList(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        AuthService.storeGradeList(result.data.grade);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFriendsList() {
    var friend = AuthService.getFriendsList();
    this.setState({
      FriendsList: friend,
    });
  }
}

const mapStateToProps = function (state) {
  return {
    subjectsList: state.commonApi.subjectslist,
    languageList: state.commonApi.languagelist,
    tagList: state.commonApi.taglist,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    schoolList: state.commonApi.schoollist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    industryList: state.commonApi.industrylist.data,
    blockedUserList: state.commonApi.blockedUserList,
    toursStages: state.commonApi.profilePopupStatus,
    friendsListBasedOnWST: state.commonApi.workspaceFriendList,
    premiumSubscription: state.commonApi.requireMentMenu,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    triggerTourPopup: (slug) => dispatch(triggerTourPopup(slug)),
    getAllCommonFriendsList: () => dispatch(getAllCommonFriendsList()),
    getAllBlockedUserList: () => dispatch(getAllBlockedUserList()),
    getFriendsListBasedWorkSpace: () =>
      dispatch(getFriendsListBasedWorkSpace()),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ProfileContainer)));
