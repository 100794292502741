// job request 
import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import AuthService from "../../session/AuthService";
//import backIcon from "../../images/BACK ICON.svg"
import PostService from '../../api/PostService'
import { Button, Modal } from "react-bootstrap";
import { Form, Row, Container, Col } from "react-bootstrap";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ProfileService from "../../api/ProfileService";
import EditableDropdown from "../../components/EditableDropdown";
import EditableTextField from "../../components/EditableTextField";
import DescriptionIcon from "@material-ui/icons/Description";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import MessagesFileOption from "../../screens/Community/Subjects/MessagesFileOption";
import JobRequestFileOption from "./JobRequestFileOption"

class JobRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            qualification: "",
            base64String: "",
            image: "",
            feedBack: '',
            iid: this.props.id,
            feedbackState: false,
            feedBackList: [],
            description: "",
            type: "",
            blocksend: false,
            areaOfInterest: false, //new
            areaofinterestsList: [],
            question: "",
            questionTitleCount: 0,
            fileTypeState: true,
        };
        this.handleForm = this.handleForm.bind(this)
        this.saveList = this.saveList.bind(this)
        this.callBack = this.callBack.bind(this)
        this.getAllAreaofInterest = this.getAllAreaofInterest.bind(this);
        this.getAreaofInterestDetails = this.getAreaofInterestDetails.bind(this);
        this.keyFile = this.keyFile.bind(this);
        this.closeChoosedFileData = this.closeChoosedFileData.bind(this)
        this.getQuestionMediaFiles = this.getQuestionMediaFiles.bind(this)
        this.handleClickEdit = this.handleClickEdit.bind(this);
    }

    componentDidMount() {
        let langValue = AuthService.getCommonLanguages()
        i18n.changeLanguage(langValue)
        this.getAllAreaofInterest();
        //  alert("HI")

    }

    callBack() {

        this.props.handleClickAway();
    }
    handleClickEdit() {
        this.setState({ savebtnState: false });
    }
    render() {

        const { t } = this.props;

        return (
            <>
                <Dialog
                    open={true}
                    fullWidth={true}
                    style={{
                        height: "110%"
                    }}


                >
                    <DialogContent className="surveyBo">
                        <div
                            style={{
                                //marginTop: "20px",
                                marginRight: "1px",
                                marginLeft: "0px",
                                marginBottom: "12px",
                            }}
                            className="ansBackQuestion flexDefault"
                            onClick={this.callBack}
                        // onClick={this.props.handleClickAway}
                        >
                            <ArrowBackIcon />
                            <p>
                                {t("Back to Home")}
                            </p>
                        </div>
                        <Grid container justify="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div style={{ display: "inline-flex" }} >
                                    <centre>
                                        <p style={{ fontWeight: "bold", color: "#000000", fontSize: "20px" }}>{t("Job Request")}
                                        </p></centre>
                                </div>


                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6}>

                                <Grid item xs={1} sm={1} md={1}>
                                </Grid>
                                <Grid item xs={11} sm={11} md={11}>

                                    <div className="SYICInputWrapper">
                                        <Row>
                                            <Col xs="12 " sm="12">
                                                <EditableTextField
                                                    label={t("Name")}
                                                    value={this.state.name}
                                                    handleClickEdits={this.handleClickEdit}
                                                    getSelectedValue={(value) => {
                                                        this.setState({
                                                            name: value,
                                                            disabledSaveBtn: false,
                                                        });
                                                    }}
                                                />

                                            </Col>
                                        </Row>

                                    </div>


                                </Grid></Grid>
                            <Grid item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6}>
                                <Grid item xs={6} sm={6} md={6}></Grid>
                                <Grid container  >
                                    <Grid item xs={1} sm={1} md={1}>
                                    </Grid>
                                    <Grid item xs={11} sm={11} md={11}>

                                        <div className="SYICInputWrapper">
                                            <EditableTextField
                                                label={t("Email")}
                                                value={this.state.email}
                                                handleClickEdits={this.handleClickEdit}
                                                getSelectedValue={(value) => {
                                                    this.setState({
                                                        email: value,
                                                        disabledSaveBtn: false,
                                                    });
                                                }}
                                            />


                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid> </Grid>
                        <Grid container spacing={2}>
                            <Grid item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6}>

                                <Grid item xs={1} sm={1} md={1}>
                                </Grid>
                                <Grid item xs={11} sm={11} md={11}>

                                    <div className="SYICInputWrapper">
                                        <EditableTextField
                                            label={t("Qualification")}
                                            value={this.state.qualification}
                                            handleClickEdits={this.handleClickEdit}
                                            getSelectedValue={(value) => {
                                                this.setState({
                                                    qualification: value,
                                                    disabledSaveBtn: false,
                                                });
                                            }}
                                        />


                                    </div>


                                </Grid></Grid>
                            <Grid item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6}>
                                <Grid item xs={6} sm={6} md={6}></Grid>
                                <Grid container  >
                                    <Grid item xs={1} sm={1} md={1}>
                                    </Grid>
                                    <Grid item xs={11} sm={11} md={11}>

                                        <div className="SYICInputWrapper">

                                            <EditableDropdown
                                                label={t("Area of Interest")}
                                                value={this.state.areaOfInterest}
                                                options={this.state.areaofinterestsList}
                                                handleClickEdits={this.handleClickEdit}
                                                getSelectedValue={(value) => {
                                                    console.log(value);
                                                    this.setState({
                                                        areaOfInterest: value,
                                                        disabledSaveBtn: false,
                                                    });
                                                }}
                                            />


                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid> </Grid>
                        <Grid style={{ marginTop: "20px" }} >
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div style={{ cursor: "pointer" }}
                                > Resume Upload
                                </div>
                            </Grid>
                        </Grid>
                        <div>

                            <JobRequestFileOption fileTypeKey={this.state.fileTypeKey} fileOption={this.state.fileOption} fileOptionEnable={this.fileOptionEnable} fromQuestionMedia={this.getQuestionMediaFiles} editObject={this.props.editObject}></JobRequestFileOption>
                        </div>

                        <Grid container >
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <button style={{ float: "left" }} onClick={this.callBack} disabled={this.state.blocksend}>Cancel</button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>

                                <div className="PTCWrapper" >
                                    <button style={{ float: "right" }} onClick={this.saveList} disabled={this.state.blocksend}>Submit</button>
                                </div>
                            </Grid>
                        </Grid>


                    </DialogContent>

                </Dialog>

            </>
        );
    }
    getQuestionMediaFiles(files, type) {
        this.setState({ file: files, type: type })
    }
    closeChoosedFileData() {
        this.setState({ fileTypeState: false, file: [] })
    }
    keyFile(key) {
        this.setState({ fileTypeKey: key, fileTypeState: true })
    }
    getAllAreaofInterest() {
        let data = {
            data: ""
        }
        ProfileService.getAreaOfInterest(data)
            .then((response) => {
                var result = response.data.data.user;

                this.getAreaofInterestDetails(result)
            })
            .catch((error) => {
                // console.log(error)
            });
    }

    async getAreaofInterestDetails(areaofinterestList) {
        console.log("areaofinterestsList");
        console.log(areaofinterestList);
        if (areaofinterestList) {
            var lList = [];
            for (var i = 0; i < areaofinterestList.length; i++) {
                var obj = {};
                obj["id"] = areaofinterestList[i].areaId;
                obj["name"] = areaofinterestList[i].areaName;
                lList.push(obj);
            }

            await this.setState({ areaofinterestsList: lList });
            console.log(lList);

            console.log(this.state.areaofinterestsList);
        }
    }

    handleForm(event) {

        this.setState({ [event.target.name]: event.target.value, questionTitleCount: event.target.value.length })

    }
    saveList(e) {
 
        const formData = new FormData();
        e.preventDefault();
        e.stopPropagation();
        if (this.state.name == "") {
            toast.warn("Please enter name");
        } else if (this.state.email == "") {
            toast.warn("Please enter email");
        } else if (this.state.qualification == "") {
            toast.warn("Please enter  qualification");
        } else if (this.state.file == undefined) {
            toast.warn("Please insert File");
        }
        else {
            formData.append('displayName', this.state.name)
            formData.append('email', this.state.email)
            formData.append('qualification', this.state.qualification)
            formData.append('jobType', this.state.areaOfInterest)
            for (let i = 0; i < this.state.file.length; i++) {
                formData.append("resumeFile", this.state.file[i]);

            }

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            PostService.jobRequest(formData, config)
                .then(
                    response => {
                        console.log(response.data)
                        toast.success("Resume submitted successfully");
                        this.props.handleClickAway();
                    }
                )
                .catch(

                    error => {
                        toast.warn("Error submitting ");
                    }
                )
        }
    }

}



const mapStateToProps = function (state) {
    return {

        loginDetails: state.loginDetailsApi.loginDetails
    }
}



export default connect(
    mapStateToProps,
    null
)
    (withTranslation()(JobRequest));