import React, { Component } from "react";
import PhotoIcon from "@material-ui/icons/Photo";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DescriptionIcon from "@material-ui/icons/Description";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { ToastContainer, toast } from 'react-toastify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import '../../screens/css/mysocial.css';
import '../../screens/css/mysocialprofile.css'
import '../../screens/css/yuukkeTutoring.css'
import PostService from '../../api/PostService'
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import AuthService from "../../session/AuthService";
import { Chip } from "@material-ui/core";
import AttachmentIcon from '@material-ui/icons/Attachment';
import ChooseFileComponent from "../../components/ChooseFileComponent";

class JobRequestFileOption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileUrl: null,
            type: null,
            mediaPost: "",
            teaching: [],
            tags: [],
            question: "",
            removeStatus: true,
            addTagsState: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.removeFile = this.removeFile.bind(this);
    }

    componentDidMount() {
        let langValue = AuthService.getCommonLanguages()
        i18n.changeLanguage(langValue)
        if (this.props.fileOption) {

            this.removeFile("parent")
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.fileOption !== prevProps.fileOption) {


            if (this.props.fileOption) {

                this.removeFile("parent")
            }
        }

        // if (this.props.editObject !== prevProps.editObject) {
        //   if (this.props.editObject.type === "text") {
        //     this.setState({ ansFileType: this.props.editObject })
        //     this.setState({
        //       name: '',
        //       type: null,
        //       removeStatus: false,
        //       name: "",
        //       file: null,
        //       fileUrl: ""
        //     })
        //   }
        //   else {
        //     this.setState({ ansFileType: this.props.editObject,nmae:this.props.editObject.originalFileName })
        //     this.getQuestionBase64String(this.props.editObject.questionFile, this.props.editObject.type);
        //   }
        // }
    }

    render() {
        const { t } = this.props
        const { type, fileUrl } = this.state;
        const onUploadSuccess = async (file) => {
            console.log("FILES")
            console.log(file)
            let preview = document.getElementById("upload-preview");
            // const img = document.createElement("embed");
            // img.src = URL.createObjectURL(file[0]);
            // img.classList.add("img-fluid");
            // img.height = 80;
            // img.width = 80;
            // preview.replaceChild(img, preview.childNodes[0]);

            this.setState({ fileArray: file })
            this.setState({ removeStatus: false })
            if (file.length > 0) {
                this.setState({
                    file: file,
                    fileUrl: URL.createObjectURL(file[0]),
                    type: file[0].type,
                    name: file[0].name,
                }, () => {
                    this.onFormSubmit()
                }
                );
            }
            else {

            }
        };
        return (
            <div className="questionMoreOptions">


                {/* {type ?
          <Chip
            icon={<AttachmentIcon />}
            className="fileChip"
            label={this.state.name}
            size="small"
            onDelete={() => this.removeFile()}
            variant="outlined"
          /> : */}
                <div className="formPostMoreOption">
                    <div className="overAlluploadicons">
                        {/* <div className="overAlluploadicons"> */}
                        {/* <input type="file" type="file" accept=".gif,.txt,.jpg,.jpeg,.pdf,.png,.doc,.docx,.svg" value={this.state.files} name="file" onChange={(e) => this.handleChange(e)} /> */}
                        {this.props.fileTypeKey == "image" && <ChooseFileComponent onUploadSuccess={onUploadSuccess} acceptedType=".jpg,.jpeg,.png,.svg"></ChooseFileComponent>
                        }
                        {this.props.fileTypeKey == "video" && <ChooseFileComponent onUploadSuccess={onUploadSuccess} acceptedType=".mp4"></ChooseFileComponent>
                        }
                        {this.props.fileTypeKey == "audio" && <ChooseFileComponent onUploadSuccess={onUploadSuccess} acceptedType=".pdf"></ChooseFileComponent>
                        }
                        <ChooseFileComponent onUploadSuccess={onUploadSuccess} acceptedType=".jpg,.jpeg,.png,.svg"></ChooseFileComponent>

                        {/* </div> */}
                    </div>
                    {this.state.fileArray && this.state.fileArray.map((file, index) => (
                        <div key={file.name}>
                            <Chip
                                icon={<AttachmentIcon />}
                                className="fileChip"
                                label={file.name}
                                size="small"
                                onDelete={() => this.removeFile(index)}
                                variant="outlined"
                            />
                            { <img style={{ margin: "8px", width: "80px", height: "80px" }} src={URL.createObjectURL(file)}></img>
                            }
                        </div>
                    ))}
                </div>


            </div>

        );
    }

    getQuestionBase64String(postFile, type) {
        let getBase64String =
        {
            "resourceString": postFile
        }

        PostService.getBase64String(getBase64String)
            .then(
                response => {

                    var value = response.data.data.base64String;
                    var base64String = "";
                    if (type === 'image') {
                        base64String = `data:image;base64,${value}`
                        this.setState({ type: 'image/png' })
                    }
                    else if (type === 'video') {
                        base64String = `data:video/mp4;base64,${value}`
                        this.setState({ type: 'video/mp4' })
                    }
                    else if (type === 'audio') {
                        base64String = `data:audio/wav;base64,${value}`
                        this.setState({ type: 'audio/mpeg' })
                    }
                    else if (type === 'pdf') {
                        base64String = `data:application/pdf;base64,${value}`
                        this.setState({ type: 'application/pdf' })
                    }

                    this.setState({ fileUrl: base64String })
                }
            )
            .catch(error => {
                console.log(error)
            }
            )
    }

    async removeFile(index) {
        if (index == "parent") {

            await this.setState({
                name: '', type: null, removeStatus: false,
                name: "",
                file: null,
                fileUrl: "",
                fileArray: []
            })
        }
        else {

            var inpu = this.state.fileArray
            if (this.state.fileArray[index]) {
                var val = this.state.fileArray
                val.splice(index, 1)
                this.setState({
                    fileArray: val
                });
            }
        }


     //   this.props.fileOptionEnable();
    }

    // handleChange(event) 
    // {
    //   this.setState({ removeStatus: false })
    //   let files = event.target.files[0]
    //   this.setState({
    //     name: event.target.files[0].name,
    //     file: files,
    //     fileUrl: URL.createObjectURL(event.target.files[0]),
    //     type: event.target.files[0].type,
    //   }, () => {
    //     this.onFormSubmit()
    //   }
    //   );
    // }

    handleChange(file, url, type) {
        // this.setState({ removeStatus: false })
        // // let files = event.target.files[0]
        // this.setState({
        //   // name: event.target.files[0].name,
        //   file: file,
        //   fileUrl: url,
        //   type: type,
        //   name:file.name
        // }, () => {
        //   this.onFormSubmit()
        // }
        // );
    }

    onFormSubmit() {

        let files = this.state.file
        var type = "file"
        if (this.checkImageURL(this.state.file[0].name)) {
            type = 'image';
        } else if (this.checkVideoURL(this.state.file[0].name)) {
            type = 'video';
        } else if (this.checkAudioURL(this.state.file[0].name)) {
            type = 'audio';
        } else if (this.checkPDFURL(this.state.file[0].name)) {
            type = 'pdf';
        } else {
            type = 'file';
        }
        this.fileUpload(this.state.file, type)
    }

    checkImageURL(name) {
        name = name.toLowerCase();
        return (name.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }

    checkVideoURL(name) {
        name = name.toLowerCase();
        return (name.match(/\.(mp4|webm|ogg)$/) != null);
    }

    checkAudioURL(name) {
        name = name.toLowerCase();
        return (name.match(/\.(wav|mp3)$/) != null);
    }

    checkPDFURL(name) {
        name = name.toLowerCase();
        return (name.match(/\.(application|pdf)$/) != null);
    }

    fileUpload(file, type) {
        // if (file.size <= 5000000) {
        this.props.fromQuestionMedia(file, type);
        // }
        // else {
        //   toast.warn("File size should not be more than 5mb")
        // }

    }

}
export default withTranslation()(JobRequestFileOption);