import MyYuukke from "../api/MyYuukke";
import AuthService from "../session/AuthService";
import moment from "moment";
class Utils {
  getCommonDate(peergroupDatabyId) {
    var dateString = "Thu Jul 15 2016 19:31:44 GMT+0200 (CEST)";
    var dateObj = new Date(dateString);
    var momentObj = moment(dateObj);
    var momentString = momentObj.format("YYYY-MM-DD");
    return momentString;
  }

  getCommonDate(peergroupDatabyId) {
    var dateString = "";
    var dateObj = new Date(dateString);
    var momentObj = moment(dateObj);
    var momentString = momentObj.format("HH mm");
    return momentString;
  }
  getMsCommonLink(
    video_url,
    origin_window,
    email,
    title,
    scheduleId,
    returnType
  ) {
    if (origin_window.includes("/teams")) {
      console.log("MS VIDEO");
      window.location.assign(
        `https://teams.microsoft.com/l/chat/0/0?users=${email}&topicName=${title}&message="Hi Teams"`
      );
    } else {
      if (AuthService.getUserdata().settings.length > 0) {
        for (let i = 0; i < AuthService.getUserdata().settings.length; i++) {
          console.log("SETTINGS VALUE");
          // if(AuthService.getUserdata().settings[i].settingsType=== 'PEER_WEB')
          if (
            AuthService.getUserdata().settings[i].settingsType === "YUUKKE_WEB"
          ) {
            console.log("SETTINGS VALUE 1");
            if (
              AuthService.getUserdata().settings[i].settingsKey === "VIDEO_URL"
            ) {
              console.log("SETTINGS VALUE");
              console.log(AuthService.getUserdata().settings[i].settingsValue);
              video_url = AuthService.getUserdata().settings[i].settingsValue;
            }
          }
        }
      } else {
      }
      //  var video_url = AuthService.getUserdata().settings[0].settingsValue
      var sync_id = this.create_sync_id();
      console.log(sync_id);
      var org_sync_id = AuthService.getUserId() + sync_id;
      console.log(org_sync_id);
      let getJoinCallData = {
        meetingLinkId: scheduleId,
        synqId: org_sync_id,
        // "userId": AuthService.getUserId()
      };
      MyYuukke.getJoinCallData(getJoinCallData)
        .then((response) => {
          var result = response.data;
          console.log(result.data.synqId);
          console.log(returnType);
          window.location.assign(
            `${video_url}?sid=${result.data.synqId}&rt=${returnType}`
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  create_sync_id() {
    var dt = new Date().getTime();
    var sid = "xxxx-xxxxx-xxxx-xxyx-xxxx-xxxx-xxx-xxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return sid;
  }

  getLastAskedDate(createdDate) {
    const date1 = new Date(createdDate);

    var currentDate = new Date();

    var delta = Math.abs(currentDate - date1) / 1000;

    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    var seconds = Math.floor(delta % 60);

    return (
      days +
      " Days " +
      hours +
      " Hours " +
      minutes +
      " Minutes " +
      seconds +
      " Seconds "
    );
  }

  getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }

  getDifferenceInHours(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60);
  }

  getDifferenceInMinutes(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60);
  }

  getDifferenceInSeconds(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / 1000;
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  getGradeName(gradeId) {
    var gradeName = "";
    for (let k = 0; k < gradeId.length; k++) {
      for (let i = 0; i < AuthService.getGradeList().length; i++) {
        if (gradeId[k].gradeId === AuthService.getGradeList()[i].gradeId) {
          console.log("gradename_in_utils");
          console.log(
            gradeId[k].gradeId === AuthService.getGradeList()[i].gradeId
          );
          gradeName = AuthService.getGradeList()[i].gradeName;
          console.log(gradeName);
        }
      }
    }
    return gradeName;
  }
  emailValidation(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
}

export default new Utils();
