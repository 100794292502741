import React, { Component } from "react";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import UserImg from "../../../components/UserImg";
import { Button, Form, Modal, InputGroup, FormControl } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import AuthService from "../../../session/AuthService";
import * as imageConversion from "image-conversion";
import ProfileService from "../../../api/ProfileService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { storeLoginDetails } from "../../../redux/actions/loginDetailsActions";
import LoadService from "../../../utils/LoadService";
import ForumService from "../../../api/ForumService";
import ChooseFileComponent from "../../../components/ChooseFileComponent";
import { connect } from "react-redux";
import ChooseImageFileComponent from "../../../components/ChooseImageFileComponent";
import ChooseCoverFIleComponent from "../../../components/ChooseCoverFIleComponent";
import dummyimage from "../../../img/banner.jpg";
import * as APPCONFIG from "../../../config/app-config.js";

class BgBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addInterestState: false,
      file: "",
      image: "",
      coverImage: "",
      coverPhotoState: false,
      profilePhotoAfterCrop: false,
      coverPhotoAfterCrop: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onCoverChange = this.onCoverChange.bind(this);
    this.onImagePreview = this.onImagePreview.bind(this);
    this.onCoverImagePreview = this.onCoverImagePreview.bind(this);
    this.editPhoto = this.editPhoto.bind(this);
    this.editCoverPhoto = this.editCoverPhoto.bind(this);
    this.popUpProfilePhoto = this.popUpProfilePhoto.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updatePhoto = this.updatePhoto.bind(this);
    this.profilePhotoUpdate = this.profilePhotoUpdate.bind(this);
    this.coverPhotoUpdate = this.coverPhotoUpdate.bind(this);
    this.handleCoverClose = this.handleCoverClose.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  render() {
    const { t } = this.props;
    const { coverPhoto, profilePhoto, userName } = this.props;

    return (
      <div className="profieBannerWrapper">
        <img
          src={coverPhoto}
          alt="Cover Photo"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = dummyimage;
          }}
        />

        <div className="profilePictureEditProfile flexDefault flexJustifySpacebetween">
          <UserImg size="extraLarge" imgSrc={profilePhoto} imgAlt={userName} />

          <div
            className="editProfileButton tour-profile-edit"
            style={{ marginTop: "10px" }}
          >
            <button onClick={this.editPhoto}>
              <CameraAltOutlinedIcon />
              <p>{t("Edit Profile Photo")}</p>
            </button>
          </div>

          <div className="editProfileButton tour-edit-cover">
            <button onClick={this.editCoverPhoto}>
              <CameraAltOutlinedIcon />
              <p>{t("Edit cover photo")}</p>
            </button>
          </div>
        </div>
        {this.state.addInterestState && this.popUpProfilePhoto()}
        {this.state.coverPhotoState && this.popUpCoverPhoto()}
      </div>
    );
  }

  editPhoto() {
    this.setState({ addInterestState: true });
  }

  handleClose() {
    this.setState({ addInterestState: false, image: "" });
  }
  updatePhoto() {
    if (this.state.image === "") {
      // toast.warn("Please select photo");
    }
  }
  editCoverPhoto() {
    this.setState({ coverPhotoState: true });
  }

  handleCoverClose() {
    this.setState({ coverPhotoState: false });
  }

  popUpProfilePhoto() {
    const { t } = this.props;

    const onUploadSuccess = async (file) => {
      console.log("FILES");
      console.log(file);
      let preview = document.getElementById("upload-preview");
      this.setState({ fileState: false, removeStatus: false });

      let files = file;
      if (files.size > 200000) {
        imageConversion
          .compressAccurately(files, {
            size: 200,
            maxwidth: 300,
            maxheight: 200,
            orientation: 2,
            scale: 0.5,
          })
          .then((res) => {
            this.setState({ file: res });
          });
      } else {
        this.setState({ file: files });
      }
      console.log(files);
      this.onImagePreview(files);
    };
    const setprofilePhotoCrops = async () => {
      this.setState({ profilePhotoAfterCrop: true });
    };
    const backprofilePhotoCrops = async () => {
      this.setState({ profilePhotoAfterCrop: false });
    };
    const { coverPhoto, profilePhoto, userName } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Modal show="true" onHide={this.handleClose}>
          <Modal.Header>{t("Edit My Profile Picture")}</Modal.Header>
          <div style={{ margin: "12px" }}>
            <label>{t("Photo")}</label>
            <ChooseImageFileComponent
              onUploadSuccess={onUploadSuccess}
              acceptedType=".jpg,.jpeg,.png"
              profilePhotoAfterCrop={this.state.profilePhotoAfterCrop}
              setprofilePhotoCrops={setprofilePhotoCrops}
              backprofilePhotoCrops={backprofilePhotoCrops}
            ></ChooseImageFileComponent>
            <hr />
            <div className="choosePhoto d-flex">
              <div className="choosePhotoImg">
                {this.state.image && (
                  <img id="target" alt="photo" src={this.state.image} />
                )}
                {!this.state.image && (
                  <img src={profilePhoto} alt="Choose photo" />
                )}
              </div>
            </div>
            {!this.state.profilePhotoAfterCrop && (
              <Form
                id="profileForm"
                noValidate
                validated={this.state.validated}
                onSubmit={this.profilePhotoUpdate}
              >
                <br />

                <div className="profileSubmitBtn">
                  <Button
                    variant="secondary"
                    style={{ marginRight: "8px" }}
                    onClick={this.handleClose}
                  >
                    {t("Close")}
                  </Button>
                  <Button
                    type="submit"
                    className="btnBlue"
                    // onClick={this.updatePhoto}
                  >
                    {t("Update Picture")}
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </Modal>
      </div>
    );
  }

  popUpCoverPhoto() {
    const { t } = this.props;
    const onUploadSuccess = async (file) => {
      console.log("FILES");
      console.log(file);

      this.setState({ fileState: false, removeStatus: false });

      let files = file;
      if (files.size > 200000) {
        imageConversion
          .compressAccurately(files, {
            size: 200,
            maxwidth: 300,
            maxheight: 200,
            orientation: 2,
            scale: 0.5,
          })
          .then((res) => {
            this.setState({ fileCover: res });
          });
      } else {
        this.setState({ fileCover: files });
      }
      console.log(files);
      this.onCoverImagePreview(files);
    };
    const coverPhotoAfterCrops = async () => {
      this.setState({ coverPhotoAfterCrop: true });
    };
    const backcoverPhotoCrops = async () => {
      this.setState({ coverPhotoAfterCrop: false });
    };
    const { coverPhoto, profilePhoto, userName } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Modal show="true" onHide={this.handleClose}>
          <Modal.Header>{t("Edit My Cover Photo")}</Modal.Header>
          <div style={{ margin: "12px" }}>
            <label>{t("Photo")}</label>
            <ChooseCoverFIleComponent
              onUploadSuccess={onUploadSuccess}
              acceptedType=".jpg,.jpeg,.png"
              profilePhotoAfterCrop={this.state.coverPhotoAfterCrop}
              setprofilePhotoCrops={coverPhotoAfterCrops}
              backprofilePhotoCrops={backcoverPhotoCrops}
            ></ChooseCoverFIleComponent>
            <hr />

            <div className="choosePhoto d-flex">
              <div className="choosePhotoImg">
                {this.state.coverImage && (
                  <img id="target" alt="photo" src={this.state.coverImage} />
                )}
                {!this.state.coverImage && (
                  <img src={coverPhoto} alt="Choose photo" />
                )}
              </div>
            </div>
            {!this.state.coverPhotoAfterCrop && (
              <Form
                id="profileForm"
                noValidate
                validated={this.state.validated}
                onSubmit={this.coverPhotoUpdate}
              >
                <br />

                <div className="profileSubmitBtn">
                  <Button
                    variant="secondary"
                    style={{ marginRight: "8px" }}
                    onClick={this.handleCoverClose}
                  >
                    {t("Close")}
                  </Button>
                  <Button
                    type="submit"
                    className="btnBlue"
                    onClick={this.updatePhoto}
                  >
                    {t("Update Cover")}
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </Modal>
      </div>
    );
  }

  profilePhotoUpdate(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("file", this.state.file);
    if (!this.state.file) {
      toast.warning("Please select profile photo");
      return;
    }
    const formData = new FormData();
    formData.append("profilePhoto", this.state.file);
    formData.append("firstTimeSetup", "false");
    const config = { headers: { "content-type": "multipart/form-data" } };
    ProfileService.updatePhoto(formData, config)
      .then((response) => {
        var result = response.data;

        AuthService.registerAuth("", result.data);
        this.props.storeLoginDetails(
          result.data,
          this.props.authUser.loginType
        );
        // this.getBase64String(this.props.loginDetails.uId);
        this.props.getUserPhoto(result.data.profile_uuid);

        this.handleClose();
        this.setState({ profilePhotoAfterCrop: false });

        LoadService.redirect(this.props.history, "/my-profile");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getBase64String(userId) {
    let getBase64String = { userId: userId };
    ForumService.getUserPhoto(getBase64String)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.userPhoto
        ) {
          ForumService.setUserPhotoCache(getBase64String, response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  coverPhotoUpdate(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.fileCover) {
      toast.warn(`${this.props.t("Please select cover photo")}`);

      return;
    }
    const formData = new FormData();
    formData.append("profilePhoto", this.state.fileCover);
    formData.append("firstTimeSetup", "false");
    const config = { headers: { "content-type": "multipart/form-data" } };
    ProfileService.updateCoverPhoto(formData, config)
      .then((response) => {
        var result = response.data;

        AuthService.registerAuth("", result.data);
        this.props.storeLoginDetails(
          result.data,
          this.props.authUser.loginType
        );
        console.log(result.data.cPhoto);
        this.props.getCoverPhoto(result.data.cover_uuid);
        this.setState({ coverPhotoAfterCrop: false });
        this.handleCoverClose();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChange(files, url, type, name) {
    if (files.size > 200000) {
      imageConversion
        .compressAccurately(files, {
          size: 200,
          maxwidth: 300,
          maxheight: 200,
          orientation: 2,
          scale: 0.5,
        })
        .then((res) => {
          this.setState({ file: res });
        });
    } else {
      this.setState({ file: files });
    }
    console.log(files);

    this.onImagePreview(files);
  }

  onImagePreview(files) {
    if (files) {
      this.setState({
        image: URL.createObjectURL(files),
      });
    }
  }

  onCoverChange(files) {
    if (files.size > 200000) {
      imageConversion
        .compressAccurately(files, {
          size: 200,
          maxwidth: 300,
          maxheight: 200,
          orientation: 2,
          scale: 0.5,
        })
        .then((res) => {
          this.setState({ fileCover: res });
        });
    } else {
      this.setState({ fileCover: files });
    }
    console.log(files);

    this.onCoverImagePreview(files);
  }

  onCoverImagePreview(files) {
    if (files) {
      this.setState({
        coverImage: URL.createObjectURL(files),
      });
    }
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    storeLoginDetails: (loginDetails, type) =>
      dispatch(storeLoginDetails(loginDetails, type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(BgBanner));
