import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import "./AppResponsive.css";
import Navigations from "./routers/Navigations";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import persistStore from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Helmet } from "react-helmet";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={persistStore().store}>
      <PersistGate loading={null} persistor={persistStore().persistor}>
        <Helmet>
          <title>YUUKKE</title>
          <meta name="description" content="App Description" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <Navigations />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
