import axios from "axios";
import { CHAT_URL } from "../../config/config.js";

// const CHATAPP_URL = "http:localhost:8800/api";

export const getAllFriends = () => async (dispatch) => {
  try {
    const response = await axios.get(`${CHAT_URL}api/users`);

    dispatch({
      type: "GETALL_FRIENDS_SUCCESS",
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: "GETALL_FRIENDS_FAILURE",
      payload: err.response.data,
    });
  }
};

export const getLastMessage = (senderId) => async (dispatch) => {
  try {
    const response = await axios.get(`${CHAT_URL}api/lastMessage/${senderId}`);
    console.log("response", response);
    dispatch({
      type: "GETLAST_MESSAGE_SUCCESS",
      payload: response.data,
    });
  } catch (err) {
    console.log("%c ERROR IN GETTING LAST MESSAGE : ", "color:red", err);
  }
};

export const sendMessage = (data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const response = await axios.post(`${CHAT_URL}api/message/`, data, config);
    dispatch({
      type: "SEND_MESSAGE_SUCCESS",
      payload: response.data,
    });
  } catch (err) {
    console.log("%c ERROR IN POSTING MESSAGE : ", "color:red", err);
  }
};

export const InstantMeetingAction = (data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const response = await axios.post(`${CHAT_URL}api/message/`, data, config);
    dispatch({
      type: "SEND_MEETING_SUCCESS",
      payload: response.data,
    });
  } catch (err) {
    console.log("%c ERROR IN POSTING METTING LINK : ", "color:red", err);
  }
};

export const GroupMeetingAction = (data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const response = await axios.post(
      `${CHAT_URL}api/groupCallMessage/`,
      data,
      config
    );

    dispatch({
      type: "SEND_MEETING_SUCCESS",
      payload: response.data,
    });
  } catch (err) {
    console.log("%c ERROR IN POSTING METTING LINK : ", "color:red", err);
  }
};

export const ImageSendAction = (data) => async (dispatch) => {
  try {
    console.log(data);
    const response = await axios.post(`${CHAT_URL}api/image/`, data);
    dispatch({
      type: "SEND_IMAGE_SUCCESS",
      payload: response.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const clearMessages = () => async (dispatch) => {
  dispatch({
    type: "CLEAR_CURRENT_USER_MESSAGES",
  });
};

export const currentFriendMessages =
  (senderId, receiverId) => async (dispatch) => {
    try {
      if (receiverId && senderId) {
        dispatch({
          type: "CLEAR_CURRENT_USER_MESSAGES",
          payload: true,
        });

        const response = await axios.get(
          `${CHAT_URL}api/message/${senderId}/${receiverId}`
        );
        dispatch({
          type: "CURRENT_FRIEND_MESSAGE_SUCCESS",
          payload: { data: response.data.data, friendId: receiverId },
        });
      }
    } catch (err) {
      console.log(
        "%c ERROR IN GETTING CURRENT FRIEND MESSAGE : ",
        "color:red",
        err
      );
    }
  };

export const seenMessage = (message) => async (dispatch) => {
  console.info("Message Status : inital seen", message);

  try {
    const response = await axios.post(`${CHAT_URL}api/message/seen`, message);
    console.info("Message status : updated seen", response.data);

    // dispatch({
    //   type: "MESSAGE_SEEN_STATUS",
    //   payload: response.data,
    // });
  } catch (err) {
    console.log(err);
  }
};

export const deliveredMessage = (message) => async (dispatch) => {
  console.count(`message status`);
  console.log(message);

  try {
    const response = await axios.post(
      `${CHAT_URL}api/message/delivered`,
      message
    );
    // dispatch({
    //   type: "MESSAGE_DELIVERED_STATUS",
    //   payload: response.data,
    // });
  } catch (err) {
    console.log(err);
  }
};

export const updateViewCount = (id, friendId) => async (dispatch) => {
  dispatch({
    type: "UPDATE_VIEW_COUNT",
    // payload: id,
    payload: {
      id: id,
      friendId: friendId,
    },
  });
};
