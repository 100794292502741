import { AttachmentIcon } from "@fluentui/react-northstar";
import { Chip, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Stepper from "./Stepper";
import StepperFileOption from "./StepperFileOption";
import Utils from "../../utils/Utils";
import ForumService from "../../api/ForumService";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { getAllCountryName } from "../../redux/actions/commonApiActions";
const steps = [
  {
    title: "Contact Information",
    content: "Contact Information",
  },
  {
    title: "Business PAN",
    content: "Business PAN",
  },
  {
    title: "Business Owner PAN",
    content: "Business Owner PAN",
  },
  {
    title: "Business Details",
    content: "Business Details",
  },
  {
    title: "Business Registration Details",
    content: "Business Registration Details",
  },
  {
    title: "Bank Account Details",
    content: "Bank Account Details",
  },
  {
    title: "Business Owner Details",
    content: "Business Owner Details",
  },
  {
    title: "Details of Beneficial Ownership",
    content: "Details of Beneficial Ownership",
  },
];

const StepperFormOptions = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const uId = useSelector((state) => state.loginDetailsApi.loginDetails.uId);
  const workspaceName = useSelector(
    (state) => state.loginDetailsApi.loginDetails?.userWorkSpace?.slug
  );
  const refreshToken = useSelector(
    (state) => state.loginDetailsApi.refreshToken
  );
  const accessToken = useSelector((state) => state.loginDetailsApi.accessToken);
  const authenticated = useSelector(
    (state) => state.loginDetailsApi.keycloakAuthenticated
  );
  const countyNameList = useSelector((state) => state.commonApi.countryName);
  const [state, setState] = useState({
    businessPan: "",
    businessName: "",
    email: "",
    stepperContent: 0,
    mobileNumber: "",
    businessPanName: "",
    businessOwnerPan: "",
    businessOwnerName: "",
    businessModel: "",
    businessType: "",
    businessSubcategory: "",
    cIn: "",
    GSTIN: false,
    businessAddress: "",
    city: "",
    pinCode: "",
    userState: "",
    countryId: 0,
    whatsappUpdate: false,
    sameAddressToGst: false,
    accountNumber: "",
    ifsc: "",
    bankAccountFile: null,
    bankAccountFileType: null,
    politicalExperience: null,
    aadhaarNumber: "",
    mobileLikedAadhaar: false,
    proofFrontSideFile: null,
    proofFrontSideType: null,
    proofBackSideFile: null,
    proofBackSideType: null,
    idProof: null,
  });
  const updateState = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const statusChange = (status) => {
    updateState("stepperContent", status);
  };

  useEffect(() => {
    console.log("state", state);
  });
  useEffect(() => {
    dispatch(getAllCountryName());
  }, []);
  // const handleBlur = () => {
  //   setIsValid(Utils.emailValidation(state.email));
  // };

  const getBankFile = (file, type) => {
    console.log("Get files", file);
    console.log("Get files", type);
    if (file) {
      updateState("bankAccountFile", file[0]);
      updateState("bankAccountFileType", type);
    }
  };
  const getFrontSideProof = (file, type) => {
    console.log("Get files", file);
    console.log("Get files", type);
    if (file) {
      updateState("proofFrontSideFile", file[0]);
      updateState("proofFrontSideType", type);
    }
  };
  const getBackSideProof = (file, type) => {
    console.log("Get files", file);
    console.log("Get files", type);
    if (file) {
      updateState("proofBackSideFile", file[0]);
      updateState("proofBackSideType", type);
    }
  };

  const removeFiles = (keyFile, keyFileType) => {
    updateState(keyFile, null);
    updateState(keyFileType, null);
  };
  const updateMarketPlace = () => {
    if (!state.email || !Utils.emailValidation(state.email)) {
      toast.warning(`${props.t("Enter Valid Email")}`);
    } else if (
      !state.email ||
      !state.mobileNumber ||
      !state.businessPan ||
      !state.businessPanName ||
      !state.businessOwnerPan ||
      !state.businessOwnerName ||
      !state.businessName ||
      !state.businessType ||
      !state.businessModel ||
      !state.businessSubcategory ||
      !state.cIn ||
      !state.GSTIN ||
      !state.businessAddress ||
      !state.city ||
      !state.pinCode ||
      !state.userState ||
      !state.countryId ||
      !state.accountNumber ||
      // !state.politicalExperience ||
      !state.aadhaarNumber ||
      !state.idProof
    ) {
      toast.warning(`${props.t("Please fill all the fields")}`);
    } else if (!state.proofBackSideFile || !state.proofFrontSideFile) {
      toast.warning(`${props.t("Please add proof file")}`);
    } else {
      const formData = new FormData();
      formData.append("aadhaarNumber", state.aadhaarNumber);
      formData.append("accountNumber", state.accountNumber);
      if (state.bankAccountFile) {
        formData.append("bankProofFile", state.bankAccountFile);
      }

      formData.append("businessAddress", state.businessAddress);

      // formData.append("businessCategory",state.businessSubcategory)
      formData.append("businessModel", state.businessModel);
      formData.append("businessPanName", state.businessPanName);
      formData.append("businessPan", state.businessPan);
      formData.append("businessOwnerPan", state.businessOwnerPan);

      formData.append("businessOwnerPanName", state.businessOwnerName);

      formData.append("businessSubCategory", state.businessSubcategory);

      formData.append("businessType", state.businessType);
      formData.append("cin", state.cIn);
      formData.append("city", state.city);
      formData.append("country", state.countryId);

      formData.append("email", state.email);
      formData.append("gstin", state.GSTIN);
      formData.append("ifsc", state.ifsc);
      formData.append("mobileNumber", state.mobileNumber);

      formData.append("pinCode", state.pinCode);
      formData.append(
        "politicallyExposedPerson",
        state.politicalExperience ? state.politicalExperience : false
      );
      formData.append("proofId", state.idProof);
      formData.append("proofIdFileFrontSide", state.proofFrontSideFile);

      formData.append("proofIdFileBackSide", state.proofBackSideFile);
      formData.append("receiveUpdatesViaWhatsApp", state.whatsappUpdate);
      formData.append("state", state.userState);
      formData.append("mobileNumberLinkAadhaar", state.mobileLikedAadhaar);
      formData.append("sameGstAddress", state.sameAddressToGst);
      if (refreshToken && accessToken && uId) {
        formData.append("userId", uId);
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      ForumService.saveUserBusinessData(formData, config)
        .then((response) => {
          if (response.data.status == -1) {
            toast.error("Saving Error");
          } else {
            toast.success("Details Update Successfully");
            if (refreshToken && accessToken && authenticated) {
              props.history.push(`/${workspaceName}/home`);
            }
          }
        })
        .catch(() => {
          toast.error("Saving Error");
          if (refreshToken && accessToken && authenticated) {
            props.history.push(`/${workspaceName}/home`);
          }
        });
    }
  };
  return (
    <div
      className="screenInnerGap  "
      style={{ marginTop: "4.6rem", marginBottom: "2rem" }}
    >
      <h3 className="listHomeTitle">Sign Up Market Place</h3>
      <div
        className="  d-flex "
        style={{
          gap: window.innerWidth <= 768 ? "0.2rem" : "1.2rem",
        }}
      >
        <ToastContainer position="top-right" autoClose={5000} />

        <div class="stepper-left">
          <Stepper
            changeIndex={state.stepperContent}
            steps={steps}
            changeStepper={statusChange}
          />
        </div>
        <div class="stepper">
          {state.stepperContent === 0 ? (
            <div className="stepper-box">
              <div>
                <h3>Contact Information</h3>
                <p>
                  We will be contacting you on the below details for any account
                  related updates.
                </p>
                <p class="mt-4 mb-1 text-secondary">Email *</p>
                <TextField
                  type="email"
                  variant="outlined"
                  value={state.email}
                  style={{ backgroundColor: "white" }}
                  onChange={(e) => updateState("email", e.target.value)}
                />
                <p class="mt-4 mb-1 text-secondary">Mobile Number *</p>
                <TextField
                  type="number"
                  variant="outlined"
                  value={state.mobileNumber}
                  style={{ backgroundColor: "white" }}
                  onChange={(e) => updateState("mobileNumber", e.target.value)}
                />
                <div class="d-flex mt-2">
                  <div
                    class="market-checkbox"
                    onClick={() =>
                      updateState("whatsappUpdate", !state.whatsappUpdate)
                    }
                  >
                    <input
                      type="checkbox"
                      value={state.whatsappUpdate}
                      checked={state.whatsappUpdate}
                    />
                    <p class="ml-2 m text-dark text-secondary">
                      Receive account update via WhatsApp
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => updateState("stepperContent", 1)}
                style={{
                  marginRight: "3.7rem",
                  float: "right",
                }}
              >
                <BsFillArrowRightCircleFill
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                      scroll: "smooth",
                    })
                  }
                  class="wizard-icon"
                />
              </div>
            </div>
          ) : state.stepperContent === 1 ? (
            <div className="stepper-box">
              <div>
                <h3>Business PAN</h3>
                <p>
                  We need your PAN details associated with your business to
                  verify your account. If you have an unregistered business or
                  you are a sole proprietor, enter your personal PAN details.
                </p>
                <p class="mt-4 mb-1 text-secondary">Business PAN *</p>
                <TextField
                  id="outlined-textarea"
                  // label="Pan"

                  variant="outlined"
                  name="businessPan"
                  type="text"
                  value={state.businessPan}
                  onChange={(e) => updateState("businessPan", e.target.value)}
                  style={{ backgroundColor: "white" }}
                />
                <p class="mt-4 mb-1 text-secondary">Name as per PAN *</p>
                <TextField
                  type="text"
                  id="outlined-textarea"
                  // label="Pan Number"
                  placeholder=""
                  variant="outlined"
                  name="businessPanName"
                  value={state.businessPanName}
                  onChange={(e) =>
                    updateState("businessPanName", e.target.value)
                  }
                  style={{ backgroundColor: "white" }}
                />
              </div>
              <div class="d-flex justify-content-between mt-4">
                <div onClick={() => updateState("stepperContent", 0)}>
                  <BsFillArrowLeftCircleFill
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        scroll: "smooth",
                      })
                    }
                    class="wizard-icon"
                  />
                </div>
                <div
                  onClick={() => updateState("stepperContent", 2)}
                  style={{
                    marginRight: "3.7rem",
                  }}
                >
                  <BsFillArrowRightCircleFill
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        scroll: "smooth",
                      })
                    }
                    class="wizard-icon"
                  />
                </div>
              </div>
            </div>
          ) : state.stepperContent === 2 ? (
            <div className="stepper-box">
              <div>
                <h3>Business Owner PAN</h3>
                <p>
                  We require the PAN details of the business owner or any
                  individual who owns, or is entitled to, 25 % or more of the
                  shares, capital, or profits of the company.
                </p>
                <p class="mt-4 mb-1 text-secondary">Business Owner PAN *</p>
                <TextField
                  id="outlined-textarea"
                  // label="Pan"

                  variant="outlined"
                  name="businessOwnerPan"
                  type="text"
                  value={state.businessOwnerPan}
                  onChange={(e) =>
                    updateState("businessOwnerPan", e.target.value)
                  }
                  style={{ backgroundColor: "white" }}
                />
                <p class="mt-4 mb-1 text-secondary">Name as per PAN *</p>
                <TextField
                  type="text"
                  id="outlined-textarea"
                  // label="Name"
                  placeholder=""
                  variant="outlined"
                  name="businessOwnerName"
                  value={state.businessOwnerName}
                  onChange={(e) =>
                    updateState("businessOwnerName", e.target.value)
                  }
                  style={{ backgroundColor: "white" }}
                />
              </div>
              <div class="d-flex justify-content-between mt-4">
                <div onClick={() => updateState("stepperContent", 1)}>
                  <BsFillArrowLeftCircleFill
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        scroll: "smooth",
                      })
                    }
                    class="wizard-icon"
                  />
                </div>
                <div
                  onClick={() => updateState("stepperContent", 3)}
                  style={{
                    marginRight: "3.7rem",
                  }}
                >
                  <BsFillArrowRightCircleFill
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        scroll: "smooth",
                      })
                    }
                    class="wizard-icon"
                  />
                </div>
              </div>
            </div>
          ) : state.stepperContent === 3 ? (
            <div className="stepper-box">
              <div>
                <h3>Business Details</h3>
                <p>
                  We need this information to serve you better with the payment
                  products suited for your business.
                </p>
                <p class="mt-4 mb-1 text-secondary">Business Name *</p>
                <TextField
                  id="outlined-textarea"
                  // label="Pan"

                  variant="outlined"
                  name="businessName"
                  type="text"
                  value={state.businessName}
                  onChange={(e) => updateState("businessName", e.target.value)}
                  style={{ backgroundColor: "white" }}
                />

                <p class="mt-4 mb-1 text-secondary">Select Business Type *</p>
                <div class="d-flex gap-2 radio-box flex-wrap">
                  <input
                    type="radio"
                    className="radio-box"
                    name="businessType"
                    checked={
                      state.businessType == "Private Limited" ? true : false
                    }
                    value={state.businessType}
                    onChange={(e) =>
                      updateState("businessType", "Private Limited")
                    }
                  />
                  <p class="text-dark">Private Limited </p>
                  <input
                    type="radio"
                    name="businessType"
                    value={state.businessType}
                    checked={
                      state.businessType == "Public Limited" ? true : false
                    }
                    onChange={(e) =>
                      updateState("businessType", "Public Limited")
                    }
                  />
                  <p class="text-dark">Public Limited</p>
                </div>

                <p class="mt-4 mb-1 text-secondary">Select Business Model *</p>
                <div class="d-flex gap-2 radio-box flex-wrap">
                  <input
                    type="radio"
                    className="radio-box"
                    name="businessModel"
                    checked={state.businessModel == "Both" ? true : false}
                    value={state.businessModel}
                    onChange={(e) => updateState("businessModel", "Both")}
                  />
                  <p class="text-dark">Both</p>
                  <input
                    type="radio"
                    name="businessModel"
                    checked={state.businessModel == "B2B" ? true : false}
                    value={state.businessModel}
                    onChange={(e) => updateState("businessModel", "B2B")}
                  />
                  <p class="text-dark">B2B</p>
                  <input
                    type="radio"
                    name="businessModel"
                    checked={state.businessModel == "B2C" ? true : false}
                    value={state.businessModel}
                    onChange={(e) => updateState("businessModel", "B2C")}
                  />
                  <p class="text-dark">B2C</p>
                </div>
                <p class="mt-4 mb-1 text-secondary">
                  Select Business Category *{" "}
                </p>
                <TextField
                  style={{ backgroundColor: "white", width: "85%" }}
                  fullWidth
                  id="dropdown"
                  select
                  label=""
                  variant="outlined"
                  type="text"
                  required=""
                  name="businessSubcategory"
                  value={state.businessSubcategory}
                  onChange={(e) => {
                    updateState("businessSubcategory", e.target.value);
                  }}
                >
                  <MenuItem value="">
                    <>{t("Select")}</>
                  </MenuItem>
                  <MenuItem value="MarketPlace">
                    <>{t("MarketPlace")}</>
                  </MenuItem>
                  <MenuItem value="Clinic">
                    <>{t("Clinic")}</>
                  </MenuItem>
                  <MenuItem value="Gym">
                    <>{t("Gym")}</>
                  </MenuItem>
                  <MenuItem value="Health Practitioner Or Medical Service">
                    <>{t("Health Practitioner Or Medical Service")}</>
                  </MenuItem>
                  <MenuItem value="Health And Lifestyle Coaching">
                    <>{t("Health And Lifestyle Coaching")}</>
                  </MenuItem>

                  <MenuItem value="Health Products">
                    <>{t("Health Products")}</>
                  </MenuItem>
                  <MenuItem value="Medical Equipment Supplies">
                    <>{t("Medical Equipment Supplies")}</>
                  </MenuItem>
                  <MenuItem value="Hospital">
                    <>{t("Hospital")}</>
                  </MenuItem>
                  <MenuItem value="Nutritionist Or Dietitian">
                    <>{t("Nutritionist Or Dietitian")}</>
                  </MenuItem>
                  <MenuItem value="Doctors and Physicians">
                    <>{t("Doctors and Physicians")}</>
                  </MenuItem>
                  <MenuItem value="Massage Parlours">
                    <>{t("Massage Parlours")}</>
                  </MenuItem>
                </TextField>
              </div>
              <div class="d-flex justify-content-between mt-4">
                <div onClick={() => updateState("stepperContent", 2)}>
                  <BsFillArrowLeftCircleFill
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        scroll: "smooth",
                      })
                    }
                    class="wizard-icon"
                  />
                </div>
                <div
                  onClick={() => updateState("stepperContent", 4)}
                  style={{
                    marginRight: "3.7rem",
                  }}
                >
                  <BsFillArrowRightCircleFill
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        scroll: "smooth",
                      })
                    }
                    class="wizard-icon"
                  />
                </div>
              </div>
            </div>
          ) : state.stepperContent === 4 ? (
            <div className="stepper-box">
              <div>
                <h3>Business Registration Details</h3>
                <p>We require this information to verify your business.</p>
                <p class="mt-4 mb-1 text-secondary">CIN *</p>
                <TextField
                  id="outlined-textarea"
                  // label="Pan"

                  variant="outlined"
                  name="cIn"
                  type="text"
                  value={state.cIn}
                  onChange={(e) => updateState("cIn", e.target.value)}
                  style={{ backgroundColor: "white" }}
                />

                <p class="mt-4 mb-1 text-secondary">GSTIN *</p>
                <div class="d-flex gap-2 radio-box flex-wrap">
                  <input
                    type="radio"
                    className="radio-box"
                    name="GSTIN"
                    checked={state.GSTIN == "I have GSTIN" ? true : false}
                    value={state.GSTIN}
                    onChange={(e) => updateState("GSTIN", "I have GSTIN")}
                  />
                  <p class="text-dark">I have GSTIN </p>
                  <input
                    type="radio"
                    name="GSTIN"
                    checked={
                      state.GSTIN == "I do not have GSTIN" ? true : false
                    }
                    value={state.GSTIN}
                    onChange={(e) =>
                      updateState("GSTIN", "I do not have GSTIN")
                    }
                  />
                  <p class="text-dark">I do not have GSTIN</p>
                </div>

                <div class="d-flex mt-4 mb-1">
                  <p class="text-secondary mr-2">Business Address *</p>
                  <div
                    class="market-checkbox"
                    onClick={() =>
                      updateState("sameAddressToGst", !state.sameAddressToGst)
                    }
                  >
                    <input
                      type="checkbox"
                      value={state.sameAddressToGst}
                      checked={state.sameAddressToGst}
                    />
                    <p class="ml-2 m text-dark text-secondary">
                      Same address as GST Address
                    </p>
                  </div>
                </div>

                <TextField
                  type="text"
                  id="outlined-textarea"
                  // label="Pan Number"
                  placeholder=""
                  variant="outlined"
                  name="businessAddress"
                  value={state.businessAddress}
                  onChange={(e) =>
                    updateState("businessAddress", e.target.value)
                  }
                  style={{ backgroundColor: "white" }}
                />

                <p class="mt-4 mb-1 text-secondary">{t("City *")}</p>
                <TextField
                  type="text"
                  id="outlined-textarea"
                  // label="Pan Number"
                  placeholder=""
                  variant="outlined"
                  name="city"
                  value={state.city}
                  onChange={(e) => updateState("city", e.target.value)}
                  style={{ backgroundColor: "white" }}
                />
                <p class="mt-4 mb-1 text-secondary">{t("PIN Code *")}</p>
                <TextField
                  type="number"
                  variant="outlined"
                  value={state.pinCode}
                  onChange={(e) => updateState("pinCode", e.target.value)}
                  style={{ backgroundColor: "white" }}
                />
                <p class="mt-4 mb-1 text-secondary">{t("State *")}</p>
                <TextField
                  type="text"
                  id="outlined-textarea"
                  // label="Pan Number"
                  placeholder=""
                  variant="outlined"
                  name="userState"
                  value={state.userState}
                  onChange={(e) => updateState("userState", e.target.value)}
                  style={{ backgroundColor: "white" }}
                />
                <p class="mt-4 mb-1 text-secondary">{t("Country *")}</p>
                <TextField
                  style={{
                    backgroundColor: "white",
                    width: "85%",
                  }}
                  fullWidth
                  id="dropdown"
                  select
                  label=""
                  variant="outlined"
                  type="text"
                  required=""
                  name="countryId"
                  value={state.countryId}
                  onChange={(e) => {
                    updateState("countryId", e.target.value);
                  }}
                >
                  {countyNameList &&
                    countyNameList.map((option, index) => (
                      <MenuItem key={option.nicename} value={option.nicename}>
                        {option.nicename}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
              <div class="d-flex justify-content-between mt-4">
                <div onClick={() => updateState("stepperContent", 3)}>
                  <BsFillArrowLeftCircleFill
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        scroll: "smooth",
                      })
                    }
                    class="wizard-icon"
                  />
                </div>
                <div
                  onClick={() => updateState("stepperContent", 5)}
                  style={{
                    marginRight: "3.7rem",
                  }}
                >
                  <BsFillArrowRightCircleFill
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        scroll: "smooth",
                      })
                    }
                    class="wizard-icon"
                  />
                </div>
              </div>
            </div>
          ) : state.stepperContent === 5 ? (
            <div className="stepper-box">
              <div>
                <h3>{t("Bank Account Details")}</h3>
                <p>
                  {t(
                    "We need your bank account details to process settlements and to recharge your payout wallet."
                  )}
                </p>
                <p class="mt-4 mb-1 text-secondary">{t("Account Number *")}</p>
                <TextField
                  id="outlined-textarea"
                  // label="Pan"
                  variant="outlined"
                  type="number"
                  value={state.accountNumber}
                  onChange={(e) => updateState("accountNumber", e.target.value)}
                  style={{ backgroundColor: "white" }}
                />
                <p class="mt-4 mb-1 text-secondary">{t("IFSC *")}</p>
                <TextField
                  type="text"
                  id="outlined-textarea"
                  // label="Pan Number"
                  placeholder=""
                  variant="outlined"
                  name="ifsc"
                  value={state.ifsc}
                  onChange={(e) => updateState("ifsc", e.target.value)}
                  style={{ backgroundColor: "white" }}
                />
                <div
                  class="mt-2"
                  style={{
                    textAlign: "center",
                    marginRight: "20%",
                  }}
                >
                  {" "}
                  <p>or</p>
                </div>
                <div style={{ width: "85%" }}>
                  {!state.bankAccountFile && (
                    <StepperFileOption
                      onUploadSuccess={getBankFile}
                      acceptedType=".jpg,.jpeg,.pdf,.png,.svg"
                    />
                  )}
                </div>
                {state.bankAccountFile && state.bankAccountFileType && (
                  <ShowImageFile
                    files={state.bankAccountFile}
                    removeFiles={removeFiles}
                    keyFile={"bankAccountFile"}
                    keyFileType={"bankAccountFileType"}
                    fileType={state.bankAccountFileType}
                  />
                )}
              </div>

              <div class="d-flex justify-content-between mt-4">
                <div onClick={() => updateState("stepperContent", 4)}>
                  <BsFillArrowLeftCircleFill
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        scroll: "smooth",
                      })
                    }
                    class="wizard-icon"
                  />
                </div>
                <div
                  onClick={() => updateState("stepperContent", 6)}
                  style={{
                    marginRight: "3.7rem",
                  }}
                >
                  <BsFillArrowRightCircleFill
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        scroll: "smooth",
                      })
                    }
                    class="wizard-icon"
                  />
                </div>
              </div>
            </div>
          ) : state.stepperContent === 6 ? (
            <div className="stepper-box">
              <div>
                <h3>{t("Business Owner Details")} </h3>

                <p>
                  {" "}
                  {t(
                    "We need the ID proof of the business owner for verification purposes."
                  )}{" "}
                </p>

                <p class="mt-4 mb-1 text-secondary">
                  Are you a Politically Exposed Person? *
                </p>
                <div class="d-flex gap-2 radio-box flex-wrap">
                  <input
                    type="radio"
                    className="radio-box"
                    name="politicalExperience"
                    checked={state.politicalExperience === false ? true : ""}
                    value={state.politicalExperience}
                    onChange={(e) => updateState("politicalExperience", false)}
                  />
                  <p class="text-dark">No</p>
                  <input
                    type="radio"
                    name="politicalExperience"
                    checked={state.politicalExperience === true ? true : ""}
                    value={state.politicalExperience}
                    onChange={(e) => updateState("politicalExperience", true)}
                  />
                  <p class="text-dark">Yes</p>
                </div>

                <p class="mt-4 mb-1 text-secondary">Aadhaar Number *</p>
                <TextField
                  id="outlined-textarea"
                  // label="Pan"
                  variant="outlined"
                  name="aadhaarNumber"
                  type="number"
                  value={state.aadhaarNumber}
                  onChange={(e) => updateState("aadhaarNumber", e.target.value)}
                  style={{ backgroundColor: "white" }}
                />

                <div class="d-flex mt-2">
                  <div
                    class="market-checkbox"
                    onClick={() =>
                      updateState(
                        "mobileLikedAadhaar",
                        !state.mobileLikedAadhaar
                      )
                    }
                  >
                    <input
                      type="checkbox"
                      value={state.mobileLikedAadhaar}
                      checked={state.mobileLikedAadhaar}
                    />
                    <p class="ml-2 m text-dark text-secondary">
                      I do not have my mobile number linked to this aadhar
                    </p>
                  </div>
                </div>

                <p class="mt-4 mb-1 text-secondary">
                  Selected Id Proof and Upload *{" "}
                </p>
                <TextField
                  style={{
                    backgroundColor: "white",
                    width: "84%",
                  }}
                  fullWidth
                  id="dropdown"
                  select
                  label=""
                  variant="outlined"
                  type="text"
                  required=""
                  name="idProof"
                  value={state.idProof}
                  onChange={(e) => {
                    updateState("idProof", e.target.value);
                  }}
                >
                  <MenuItem value="Aadhaar">
                    <>{t("Aadhaar")}</>
                  </MenuItem>
                  <MenuItem value="Driving License">
                    <>{t("Driving License")}</>
                  </MenuItem>
                  <MenuItem value="Voter ID">
                    <>{t("Voter ID")}</>
                  </MenuItem>
                  <MenuItem value="Passport">
                    <>{t("Passport")}</>
                  </MenuItem>
                </TextField>
                {state.idProof && (
                  <>
                    <p class="mt-4 mb-1 text-secondary">
                      {state.idProof}- Copy for Front Side *
                    </p>
                    <div style={{ width: "85%" }}>
                      {!state.proofFrontSideFile && (
                        <StepperFileOption
                          onUploadSuccess={getFrontSideProof}
                          acceptedType=".jpg,.jpeg,.pdf,.png,.svg"
                        />
                      )}
                    </div>
                    {state.proofFrontSideFile && state.proofFrontSideType && (
                      <ShowImageFile
                        files={state.proofFrontSideFile}
                        removeFiles={removeFiles}
                        keyFile={"proofFrontSideFile"}
                        keyFileType={"proofFrontSideType"}
                        fileType={state.proofFrontSideType}
                      />
                    )}

                    <p class="mt-4 mb-1 text-secondary">
                      {state.idProof}- Copy for Back Side *
                    </p>
                    <div style={{ width: "85%" }}>
                      {!state.proofBackSideFile && (
                        <StepperFileOption
                          onUploadSuccess={getBackSideProof}
                          acceptedType=".jpg,.jpeg,.pdf,.png,.svg"
                        />
                      )}
                    </div>
                    {state.proofBackSideFile && state.proofBackSideType && (
                      <ShowImageFile
                        files={state.proofBackSideFile}
                        removeFiles={removeFiles}
                        keyFile={"proofBackSideFile"}
                        keyFileType={"proofBackSideType"}
                        fileType={state.proofBackSideType}
                      />
                    )}
                  </>
                )}
              </div>
              <div class="d-flex justify-content-between mt-4">
                <div onClick={() => updateState("stepperContent", 5)}>
                  <BsFillArrowLeftCircleFill class="wizard-icon" />
                </div>
                <div
                  onClick={() => updateState("stepperContent", 7)}
                  style={{
                    marginRight: "3.7rem",
                  }}
                >
                  <BsFillArrowRightCircleFill
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        scroll: "smooth",
                      })
                    }
                    class="wizard-icon"
                  />
                </div>
              </div>
            </div>
          ) : state.stepperContent === 7 ? (
            <div className="stepper-box">
              <div>
                <h3>Declaration of Beneficial Ownership</h3>
                <p>
                  We would need the declaration of beneficial ownership by your
                  business directors/trustees/partners.
                </p>
              </div>
              <div class="details-card">
                <div>
                  <h6>Document Status:</h6>
                  <p>
                    {" "}
                    Thank you for signing the document. Our team is currently
                    reviewing it and will get back to you within 24 to 48
                    working hours.
                  </p>
                </div>
              </div>
              <div>
                <button class="float-right" onClick={updateMarketPlace}>
                  Save
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

const ShowImageFile = (props) => {
  const file = props.files;
  return (
    <div key={file?.name}>
      <Chip
        icon={<AttachmentIcon />}
        className="fileChip"
        label={file?.name}
        size="small"
        onDelete={() => props.removeFiles(props.keyFile, props.keyFileType)}
        variant="outlined"
      />
      {props && props.fileType != "pdf" && (
        <img
          style={{
            margin: "8px",
            width: "80px",
            height: "80px",
          }}
          src={URL.createObjectURL(file)}
        ></img>
      )}
    </div>
  );
};

export default withTranslation()(withRouter(StepperFormOptions));
