import React, { Component } from "react";
import ChooseFileComponent from "./ChooseFileComponent";
import { withTranslation } from "react-i18next";
import AuthService from "../session/AuthService";
import i18n from "../i18n";
import PostService from "../api/PostService";

class AnswerFileOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUrl: null,
      type: null,
      mediaPost: "",
      teaching: [],
      tags: [],
      question: "",
      removeStatus: true,
      addTagsState: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    if (this.props.fileOption) {
      this.removeFile("parent");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fileOption !== prevProps.fileOption) {
      if (this.props.fileOption) {
        this.removeFile("parent");
      }
    }

    // }
  }

  render() {
    const { t } = this.props;
    const { type, fileUrl } = this.state;
    const onUploadSuccess = async (file) => {
      console.log("FILES");
      console.log(file);
      let preview = document.getElementById("upload-preview");
      // const img = document.createElement("embed");
      // img.src = URL.createObjectURL(file[0]);
      // img.classList.add("img-fluid");
      // img.height = 80;
      // img.width = 80;
      // preview.replaceChild(img, preview.childNodes[0]);

      this.setState({ fileArray: file });
      this.props.chipImageData(file);
      this.setState({ removeStatus: false });
      if (file.length > 0) {
        this.setState(
          {
            file: file,
            fileUrl: URL.createObjectURL(file[0]),
            type: file[0].type,
            name: file[0].name,
          },
          () => {
            this.onFormSubmit();
          }
        );
      } else {
      }
    };
    return (
      <div className="questionMoreOptions">
        {/* {type ?
          <Chip
            icon={<AttachmentIcon />}
            className="fileChip"
            label={this.state.name}
            size="small"
            onDelete={() => this.removeFile()}
            variant="outlined"
          /> : */}
        {/* {this.state.fileArray && this.state.fileArray.map((file, index) => (
            <div key={file.name}>
              <Chip
                icon={<AttachmentIcon />}
                className="fileChip"
                label={file.name}
                size="small"
                onDelete={() => this.removeFile(index)}
                variant="outlined"
              />
              {this.props.fileTypeKey == "image" && <img style={{ margin: "8px", width: "80px", height: "80px" }} src={URL.createObjectURL(file)}></img>
              }
            </div>
          ))} */}
        <div className="formPostMoreOption">
          <div className="overAlluploadicons">
            {/* <div className="overAlluploadicons"> */}
            {/* <input type="file" type="file" accept=".gif,.txt,.jpg,.jpeg,.pdf,.png,.doc,.docx,.svg" value={this.state.files} name="file" onChange={(e) => this.handleChange(e)} /> */}
            {this.props.fileTypeKey == "image" && (
              <ChooseFileComponent
                onUploadSuccess={onUploadSuccess}
                acceptedType=".jpg,.jpeg,.png,.svg"
              ></ChooseFileComponent>
            )}
            {this.props.fileTypeKey == "video" && (
              <ChooseFileComponent
                onUploadSuccess={onUploadSuccess}
                acceptedType=".mp4"
              ></ChooseFileComponent>
            )}
            {this.props.fileTypeKey == "audio" && (
              <ChooseFileComponent
                onUploadSuccess={onUploadSuccess}
                acceptedType=".pdf"
              ></ChooseFileComponent>
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }

  async removeFile(index) {
    if (index == "parent") {
      await this.setState({
        name: "",
        type: null,
        removeStatus: false,
        file: null,
        fileUrl: "",
        fileArray: [],
      });
    } else {
      var inpu = this.state.fileArray;
      if (this.state.fileArray[index]) {
        var val = this.state.fileArray;
        val.splice(index, 1);
        this.setState({
          fileArray: val,
        });
      }
    }

    this.props.fromQuestionMedia();

    this.props.fileOptionEnable();
  }

  // handleChange(event)
  // {
  //   this.setState({ removeStatus: false })
  //   let files = event.target.files[0]
  //   this.setState({
  //     name: event.target.files[0].name,
  //     file: files,
  //     fileUrl: URL.createObjectURL(event.target.files[0]),
  //     type: event.target.files[0].type,
  //   }, () => {
  //     this.onFormSubmit()
  //   }
  //   );
  // }

  handleChange(file, url, type) {
    // this.setState({ removeStatus: false })
    // // let files = event.target.files[0]
    // this.setState({
    //   // name: event.target.files[0].name,
    //   file: file,
    //   fileUrl: url,
    //   type: type,
    //   name:file.name
    // }, () => {
    //   this.onFormSubmit()
    // }
    // );
  }

  onFormSubmit() {
    let files = this.state.file;
    var type = "file";
    if (
      this.checkImageURL(this.state.file[0].name) &&
      this.state.file[0].name !== null
    ) {
      type = "image";
    } else if (
      this.checkVideoURL(this.state.file[0].name) &&
      this.state.file[0].name !== null
    ) {
      type = "video";
    } else if (
      this.checkAudioURL(this.state.file[0].name) &&
      this.state.file[0].name !== null
    ) {
      type = "audio";
    } else if (
      this.checkPDFURL(this.state.file[0].name) &&
      this.state.file[0].name !== null
    ) {
      type = "pdf";
    } else if (this.state.file[0].name !== null) {
      type = "file";
    }
    this.fileUpload(this.state.file, type);
  }

  checkImageURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  checkVideoURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(mp4|webm|ogg)$/) != null;
  }

  checkAudioURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(wav|mp3)$/) != null;
  }

  checkPDFURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(application|pdf)$/) != null;
  }

  fileUpload(file, type) {
    // if (file.size <= 5000000) {
    this.props.fromQuestionMedia(file, type);
    // }
    // else {
    //   toast.warn("File size should not be more than 5mb")
    // }
  }
}

export default withTranslation()(AnswerFileOptions);
