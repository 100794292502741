import React from "react";
import moment from "moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Delete from "@material-ui/icons/Delete";
import PostService from "../../../api/PostService_old";
import ForumService from "../../../api/ForumService";
import AuthService from "../../../session/AuthService_old";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import Edit from "@material-ui/icons/Edit";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import "react-image-lightbox/style.css";
import * as APPCONFIG from "../../../config/app-config.js";
import { connect } from "react-redux";
import dummygif from "../../../img/dummygif.gif";
import documnetImg from "../../../images/badges_icon.jpg";
//import dummyimage from "../../../img/avatar_dummy.png";
import dummyimage from "../../../img/default_avatar.jpg";
import VideoPostCard from "../../Home/HomeUpdated/VideoPostCard";
import Chip from "@material-ui/core/Chip";
import PhotoAlbum from "react-photo-album";
import { AiFillLike } from "react-icons/ai";
import MyYuukke from "../../../api/MyYuukke";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import FbGridImages from "../../Home/HomeUpdated/ImageView/Images";
import { FcDocument } from "react-icons/fc";
import LikePopUp from "../../../components/LikePopUp";
import { getVideoStatus, giveUserAction, htmlValidation } from "../../../utils";
import { needPhotVerification } from "../../../redux/actions/commonApiActions";
import { BsCloudArrowDown } from "react-icons/bs";
import YoutubeVideoModel from "../../../components/YoutubeVideoModel";

let time;
class ResourceInfoCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      postId: "",
      isLike: false,
      isLikeOptionSelected: false,
      isEnbleComment: false,
      selectedLikeValue: "",
      reportPostState: false,
      reportPostId: "",
      reportPostType: "",
      FirstTwocomment: true,
      pic: "",
      fileData: [],
      thumnailData: [],
      likeStatusState: false,
      photoIndex: 0,
      isOpen: false,
      openCommentBoxes: false,
      typeCard: "",
      pdfFile: null,
      fileImageData: [],
      fileVideoData: [],
      likeCountPopUpStatus: false,
    };

    this.handleClickDefaultLike = this.handleClickDefaultLike.bind(this);
    this.checkContent = this.checkContent.bind(this);
    this.isYoutube = this.isYoutube.bind(this);
    this.checkImageURL = this.checkImageURL.bind(this);
    this.checkVideoURL = this.checkVideoURL.bind(this);
    this.likesButton = this.likesButton.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
    this.getTags = this.getTags.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    console.log("Props Values");
    // this.getBase64String1(this.props.data.userId);
    console.log("check 1 did", this.props.data);
    this.getTags(this.props.data);

    this.getMessageFiles(
      this.props.data.resourceMediaFiles,
      this.props.data.postType
    );

    this.setState({
      postValue: this.props.data,
      typeCard: this.props.type,
      likeStatusState: this.props.data.userLike,
      likesLength: this.props.data.likesCount,
      commendsCount: this.props.data.commendsCount,
    });
  }

  componentDidUpdate(previousProps) {
    if (this.props.data !== previousProps.data) {
      console.log("check 1", this.props.data);

      this.setState({
        postValue: this.props.data,
        typeCard: this.props.type,
        likeStatusState: this.props.data.userLike,
        likesLength: this.props.data.likesCount,
        commendsCount: this.props.data.commendsCount,
      });
      // this.getBase64String1(nextProps.data.userId);
      this.getMessageFiles(
        this.props.data.resourceMediaFiles,
        this.props.data.postType
      );

      this.getTags(this.props.data);
    }
  }

  render() {
    const { t } = this.props;
    const { photoIndex, isOpen } = this.state;
    // console.log("fileVideoData", this.state.fileVideoData);
    return (
      <>
        {this.state.postValue && (
          <div
            className={
              this.props.resourcePostLoader ? "skeleton-loader" : "about-card"
            }
            style={{ marginTop: "1.0rem" }}
          >
            <div
              className={
                this.props.resourcePostLoader
                  ? "d-none"
                  : "about-card-header border-0 pb-0"
              }
            >
              <div className="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div className="post-avatar post-avatar-story post-me-2">
                    <a href="#!">
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={
                          this.props.data.userId
                            ? `${APPCONFIG.API_URL}auth/image/${this.props.data.userId}`
                            : dummyimage
                        }
                        alt="user"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = dummyimage;
                        }}
                      />
                    </a>
                  </div>
                  <div className="post-nav post-nav-divider">
                    <h6
                      className="post-nav-item post-card-title post-mb-0"
                      style={{ color: "#14191e" }}
                    >
                      Yuukke
                    </h6>

                    <span className="post-nav-item post-small">
                      {moment(this.state.postValue.createdDate)
                        .locale("en")
                        .format("ll")}
                    </span>
                  </div>
                </div>
                {this.props.data.userId &&
                  (this.props.userRole === 1 || this.props.userRole === 2) && (
                    // this.props.data.userId === this.props.loginDetails.uId &&
                    <div
                      className="postMoreInfo"
                      style={{ cursor: "pointer", float: "right" }}
                    >
                      <MoreHorizIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          this.handleMenuClick(e);
                        }}
                      />{" "}
                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.menuState}
                        keepMounted
                        open={this.state.menuState}
                        onClose={this.handleMenuClose}
                      >
                        <div
                          className="menuCard"
                          onClick={(e) =>
                            this.editPost(
                              e,
                              this.props.data.resourcePostId,
                              this.props.data.postType,
                              this.props.data.groupId
                            )
                          }
                        >
                          <Edit
                            style={{ cursor: "pointer", marginTop: "6px" }}
                          />{" "}
                          <MenuItem>{t("Edit")}</MenuItem>
                        </div>
                        <div
                          className="menuCard"
                          onClick={(e) =>
                            this.deletePost(e, this.props.data.resourcePostId)
                          }
                        >
                          <Delete
                            style={{ cursor: "pointer", marginTop: "6px" }}
                          />{" "}
                          <MenuItem>{t("Delete")}</MenuItem>
                        </div>
                      </Menu>
                    </div>
                  )}
              </div>
            </div>
            <div
              className={
                this.props.resourcePostLoader ? "d-none" : "post-card-body"
              }
            >
              {this.props.resourceType !== 4 &&
                this.props.resourceFilterType !== "document" &&
                (this.state.postValue.postType == "image" ||
                  this.state.postValue.postType == "video" ||
                  this.state.postValue.postType == "file" ||
                  this.state.postValue.postType == "pdf" ||
                  this.state.postValue.postType == "audio") && (
                  <>
                    <h6
                      className="post-nav-item post-card-title post-mb-0"
                      style={{ color: "#14191e" }}
                    >
                      {this.state.postValue.tittle}
                    </h6>
                    {/* <p
                      className="about-mb-2"
                      dangerouslySetInnerHTML={{
                        __html: this.state.postValue.discription,
                      }}
                    /> */}
                    {htmlValidation(
                      this.state.postValue?.discription
                        ? this.state.postValue?.discription
                        : ""
                    )}
                  </>
                )}
              {(this.props.resourceType === 4 ||
                this.props.resourceFilterType === "document") && (
                <div className="d-flex " style={{ height: "9rem" }}>
                  <div className="col-2">
                    <FcDocument className="svg-icon-doc" />
                    {/* <img
                      className="resource-img-icon"
                      src={documnetImg}
                      alt="img"
                    /> */}
                  </div>
                  <div className="col-10 d-flex flex-column">
                    <h6
                      className="post-nav-item post-card-title post-mb-0"
                      style={{ color: "#14191e" }}
                    >
                      {this.state.postValue.tittle}
                    </h6>
                    {/* <p
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      className="about-mb-2 "
                      dangerouslySetInnerHTML={{
                        __html: this.state.postValue.discription,
                      }}
                    /> */}
                    <p
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      className="about-mb-2 "
                    >
                      {htmlValidation(
                        this.state.postValue?.discription
                          ? this.state.postValue?.discription
                          : ""
                      )}
                    </p>
                  </div>
                </div>
              )}
              {this.state.postValue &&
                Array.isArray(this.state.postValue?.youtubeUrls) &&
                this.state.postValue?.youtubeUrls.length !== 0 && (
                  <div
                    className="d-flex flex-column mb-2 mt-2"
                    style={{ gap: "1rem" }}
                  >
                    {this.state.postValue?.youtubeUrls
                      .slice(0, 1)
                      .map((item) => (
                        <>
                          <YoutubeVideoModel youtubeId={item} />{" "}
                        </>
                      ))}
                  </div>
                )}

              {this.state.postValue.postType === "image" ? (
                <div className="resource-grid">
                  <FbGridImages
                    images={this.state.fileImageData}
                    onClickImage={this.downloadPdf}
                  />
                  {/* <PhotoAlbum
                    layout="masonry"
                    photos={this.state.fileImageData}
                  /> */}
                </div>
              ) : this.state.postValue.postType === "video" ? (
                <>
                  {this.state.fileVideoData &&
                    this.state.fileVideoData
                      .slice(0, 1)
                      .map((photo) => (
                        <VideoPostCard
                          fileData={photo.file}
                          imageData={photo.image}
                          videoStatus={photo.steamStatus}
                        />
                      ))}
                  {/* {this.state.fileVideoData &&
                    this.state.fileVideoData.length === 0 && (
                      <VideoPostCard
                        fileData={null}
                        imageData={null}
                        videoStatus={null}
                        noMediaFile={true}
                      />
                    )} */}
                </>
              ) : this.state.postValue.postType === "pdf" ? (
                <div style={{ marginTop: "12px" }}>
                  {this.state.fileData &&
                    this.state.fileData.map((photo) => (
                      <div style={{ margin: "8px" }}>
                        <a
                          style={{ textDecoration: "none", color: "black" }}
                          href={
                            photo.fileName == undefined || photo.fileName == ""
                              ? dummygif
                              : photo.fileName
                          }
                          target="_blank"
                        >
                          {" "}
                          <DescriptionOutlinedIcon />
                          {photo.originalFileName}
                        </a>
                      </div>
                    ))}
                </div>
              ) : this.state.postValue.postType === "file" ? (
                <div className="postCardBody">
                  <p
                    style={{ textAlign: "left", margin: "8px" }}
                    dangerouslySetInnerHTML={{
                      __html: this.state.postValue.discription,
                    }}
                  ></p>

                  <div className="postCardBody">
                    <p style={{ textAlign: "left", margin: "8px" }}>{}</p>

                    {this.state.fileData &&
                      this.state.fileData.map((photo) => (
                        <div style={{ margin: "8px" }}>
                          <a
                            style={{ textDecoration: "none", color: "black" }}
                            href={
                              photo.fileName == undefined ||
                              photo.fileName == ""
                                ? dummygif
                                : photo.fileName
                            }
                            target="_blank"
                          >
                            <DescriptionOutlinedIcon />
                            {photo.originalFileName}
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="hashtag-style">
                {this.state.filterTagList &&
                  this.state.filterTagList
                    .slice(0, 2)
                    .map((group, index) => (
                      <span className="hash-bg-color">#{group.tagName}</span>
                    ))}
              </div>
              {/* {this.props.status !== 1  && ( */}
              <ul className="resource-like">
                <li
                  className="resource-item"
                  style={{ height: "18px" }}
                  onClick={() => this.likeCountPopUp(this.state.likesLength)}
                >
                  {this.state.likesLength !== 0 && (
                    <>
                      {this.state.likesLength === 1 &&
                      this.state.likeStatusState
                        ? "You like this post"
                        : this.state.likesLength === 1
                        ? "One user like this post"
                        : this.state.likeStatusState
                        ? "You and " +
                          this.state.likesLength +
                          " users like this post"
                        : this.state.likesLength + " Users like this post"}
                    </>
                  )}{" "}
                </li>

                <li className="nav-item">
                  <a
                    className={
                      this.state.likeStatusState
                        ? "comment-nav-link active"
                        : "comment-nav-link"
                    }
                    onClick={() => this.handleClickDefaultLike("like")}
                    style={{ float: "left", cursor: "pointer" }}
                  >
                    {" "}
                    <AiFillLike
                      style={{
                        width: "15px",
                        height: "15px",
                        marginRight: "0.25rem",
                      }}
                    ></AiFillLike>{" "}
                    {this.state.likeStatusState ? t("Liked") : t("Like")} (
                    {this.state.likesLength})
                  </a>
                </li>
                {(this.props.resourceType === 4 ||
                  this.props.resourceFilterType === "document") && (
                  <li>
                    {this.state.fileData &&
                      this.state.fileData.map((photo) => (
                        <a
                          style={{ textDecoration: "none", color: "black" }}
                          href={photo.pdfFile ? photo.pdfFile : dummygif}
                          download={photo.pdfFile}
                        >
                          <button>
                            <BsCloudArrowDown
                              className="mr-2 h-auto"
                              style={{ width: "18px" }}
                            />
                            {t("Download Document")}
                          </button>
                        </a>
                      ))}
                  </li>
                )}
              </ul>
              {/* )} */}
            </div>
          </div>
        )}
        {this.state.likeCountPopUpStatus && (
          <LikePopUp
            modelClose={this.likeCountPopUp}
            postId={this.props.data.resourcePostId}
            postType={"resource"}
            availableType={null}
          />
        )}
      </>
    );
  }
  downloadPdf = () => {
    if (
      this.state.postValue.postType === "image" &&
      this.state.postValue.pdfFileName
    ) {
      window.open(
        `${APPCONFIG.API_URL}auth/mediaType/${this.state.postValue.pdfFileName}`
      );
    }
  };

  likeCountPopUp = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      if (this.state.likesLength !== 0) {
        this.setState({
          likeCountPopUpStatus: !this.state.likeCountPopUpStatus,
        });
      }
    }
  };
  editPost = (e, postId, type, groupId) => {
    this.props.history.push(
      `/${this.props.workspaceType}/resourcePost/${postId}/${this.props.workspaceType}/${type}/${groupId}`
    );
  };
  handleMenuClick = (event, userId) => {
    this.setState({ menuState: event.target });
  };
  deletePost = (event, subId) => {
    this.setState({
      menuState: false,
    });
    event.preventDefault();
    event.stopPropagation();
    Swal.fire({
      title: "Are you sure to delete this post?",
      text: "",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        var deleteSubId = {
          resourcePostId: subId,
        };
        MyYuukke.admin_resource_post_delete(deleteSubId)
          .then((response) => {
            this.props.getResourcePost(1);
            toast.success("Post deleted successfully");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  handleMenuClose = () => {
    this.setState({
      menuState: false,
    });
  };
  getTags(data) {
    console.log("123data", data);
    var tagList = [];

    for (var j = 0; j < data.resourceTagsIsdAndValues.length; j++) {
      tagList.push({
        tagId: data.resourceTagsIsdAndValues[j].tagId,
        tagName: data.resourceTagsIsdAndValues[j].tagName,
      });
    }
    this.setState({
      filterTagList: tagList,
    });
  }

  async likeStatus() {
    await this.setState({ likeStatusState: !this.state.likeStatusState });
  }

  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  checkContent(url) {
    var type = "text";
    if (url) {
      if (url.startsWith("http")) {
        if (this.checkImageURL(url)) {
          type = "image";
        } else if (this.isYoutube(url) || this.checkVideoURL(url)) {
          type = "video";
        }
      }
    }
    this.setState({
      type: type,
    });
  }

  checkImageURL(url) {
    url = url.toLowerCase();
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  checkVideoURL(url) {
    url = url.toLowerCase();
    return url.match(/\.(mp4|webm|ogg)$/) != null;
  }

  isYoutube(getURL) {
    if (typeof getURL !== "string") return false;
    var newA = document.createElement("A");
    newA.href = getURL;
    var host = newA.hostname;
    var srch = newA.search;
    var path = newA.pathname;

    if (host.search(/youtube\.com|youtu\.be/) === -1) return false;
    if (host.search(/youtu\.be/) !== -1) return path.slice(1);
    if (path.search(/embed/) !== -1)
      return /embed\/([A-z0-9]+)(\&|$)/.exec(path)[1];
    var getId = /v=([A-z0-9]+)(\&|$)/.exec(srch);
    if (host.search(/youtube\.com/) !== -1) return !getId ? "" : getId[1];
  }

  handleClickDefaultLike() {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      if (this.state.likeStatusState) {
        var postId = this.state.postValue.resourcePostId;

        this.likesButton(postId, 0);
      } else {
        var postId = this.state.postValue.resourcePostId;

        this.likesButton(postId, 1);
      }
    }
  }

  likesButton(pid, likeValue) {
    let createpostLike = {
      resourceId: pid,
      like: likeValue,
    };

    ForumService.saveSourceLike(createpostLike)
      .then((response) => {
        var result = response.data;

        var likeCounts = result.data.likesCount;
        this.setState({ likesLength: likeCounts });
        this.likeStatus();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getBase64String(postFile) {
    let getBase64String = {
      resourceString: postFile,
    };

    PostService.getBase64String(getBase64String)
      .then((response) => {
        var value = response.data.data.base64String;
        var base64String = "";
        if (this.state.postValue.type === "image") {
          base64String = `data:image;base64,${value}`;
        } else if (this.state.postValue.type === "video") {
          base64String = `data:video/mp4;base64,${value}`;
          console.log("video");
        } else if (this.state.postValue.type === "audio") {
          base64String = `data:audio/wav;base64,${value}`;
          console.log("audio");
        }

        this.setState({ base64String: base64String });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getMessageFiles = async (files, type) => {
    console.log("media files>> files", files);
    if (type === "image") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);

          //   fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
        }
        await this.setState({ fileImageData: fileList });
        //  alert(fileList)
      }
    } else if (type === "video") {
      let fileList = [];
      console.log("media video files>> ", files);
      if (files.length > 0) {
        // for (let i = 0; i < files.length; i++) {
        //   //  fileList.push(`${APPCONFIG.API_URL}auth/video/${files[i].fileName}`);
        //   if (files[i]?.fileType === "video") {
        //     fileList.push({
        //       file: files[i].streamUrl,
        //       image: files[i].thumbnail,
        //     });
        //   }
        // }
        await this.setState({
          fileVideoData: getVideoStatus(
            files,
            this.props.loginDetails.uId,
            this.props.data.userId,
            this.props.loginDetails.uType
          ),
        });
        console.log(
          "streamUrl>>",
          getVideoStatus(
            files,
            this.props.loginDetails.uId,
            null,
            this.props.loginDetails.uType
          )
        );
        console.log("FILE LIST");
        console.log(this.state.fileVideoData);
      }
    } else if (type == "pdf") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
            pdfFile: `${APPCONFIG.API_URL}auth/mediaType/${files[i].fileName}`,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      }
    } else if (type == "file") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
            pdfFile: `${APPCONFIG.API_URL}auth/mediaType/${files[i].fileName}`,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      }
    }
  };

  async getBase64String1(userId) {
    let getBase64String = { userId: userId };
    var apihit = ForumService.getHitUserPhotoCache(getBase64String);
    var response = ForumService.getUserPhotoCache(getBase64String);
    console.log(response);
    if (response) {
      var value = response.data.data;
      if (value && value.userPhoto) {
        await this.setState({ pic: response.data.data.userPhoto });
      } else {
        this.setState({ pic: "" });
      }
    } else if (!apihit) {
      ForumService.setHitUserPhotoCache(getBase64String, "1");
      ForumService.getUserPhoto(getBase64String)
        .then((response) => {
          ForumService.setUserPhotoCache(getBase64String, response);
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.userPhoto
          ) {
            this.setState({ pic: response.data.data.userPhoto });
          } else {
            this.setState({ pic: "" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
const mapStateToProps = function (state) {
  return {
    subjectsList: state.commonApi.subjectslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    userRole: state.loginDetailsApi.loginDetails.userWorkSpace?.role,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ResourceInfoCards)));
