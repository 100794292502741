import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
// import image from "../../images/Decoding_funding_image.jpg";
import CloseIcon from "@material-ui/icons/Close";
import { getFundingImageState } from "../../redux/actions/commonApiActions";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

function Image(props) {
  const [showModal, setShowModal] = React.useState(true);

  const handleClose = () => {
    setShowModal(false);
    props.getFundingImageState(false);
    // this.props.onCloseImage();
  };
  const onHandleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.open("https://yuukke.com/decoding-funding-webinar/", "_blank");
  };
  return (
    <div>
      <Dialog
        open={showModal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>

        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            cursor: "pointer",
          }}
        >
          {/* <img alt="contest" src={image} onClick={onHandleClick} /> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = function (dispatch) {
  return {
    getFundingImageState: (status) => dispatch(getFundingImageState(status)),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Image));
