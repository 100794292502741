import React, { Component, createRef } from 'react'
import './chatContent.css'
import Avatar from './AdvertisementChatAvatar'
import ChatItem from './ChatItem'
import { FiSend } from 'react-icons/fi'
import { API_URL } from '../../../config/app-config'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import PostService from '../../../api/PostService_old'
import { getAdvertisementChatList } from '../../../redux/actions/squareHomeActions'
import { MdOutlineArrowCircleDown } from 'react-icons/md'
import { BsArrowDownSquare, BsChevronDoubleDown } from 'react-icons/bs'

class ChatReply extends Component {
  messagesEndRef = createRef(null)

  constructor(props) {
    super(props)
    this.state = {
      chat: [],
      msg: '',
      chatListTotalElements: 0,
      numberOfElements: 0,
      hasMore: true,
      pageNo: 0,
    }
  }

  scrollToBottom = () => {
    const scrollHeight = this.messagesEndRef?.current.scrollHeight
    this.messagesEndRef.current.scrollTop = scrollHeight

    // this.messagesEndRef.current?.scrollIntoView({
    //   block: 'end',
    //   behavior: 'smooth',
    // })
  }

  async componentDidMount() {
    if (this.props.chatList) {
      await this.setState({
        chat: this.props.chatList ? this.props.chatList : [],
      })
      this.scrollToBottom()
    }

    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        this.saveReply(this.props.ownerUserId)
      }
    })
  }
  async componentDidUpdate(previous) {
    if (this.props.chatList && this.props.chatList !== previous.chatList) {
      await this.setState({
        chat: this.props.chatList,
      })
      this.scrollToBottom()
    }
  }

  saveReply = () => {
    if (this.state.msg !== '') {
      const formData = new FormData()
      formData.append('chat', this.state.msg)
      formData.append('postId', this.props.postId)
      formData.append('typeData', this.props.typeData)
      formData.append('receiverId', this.props.chatUserId)
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }

      PostService.advertisement_chat_save(formData, config)
        .then((response) => {
          let result = response.data.data.advertisement_chat
          let list = this.state.chat.push(result)
          this.setState({ msg: '' })
          this.scrollToBottom()
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
  onStateChange = (e) => {
    this.setState({ msg: e.target.value })
  }

  render() {
    return (
      <div className="main__chatcontent">
        <div className="content__header">
          <div className="blocks">
            <div className="current-chatting-user">
              {this.props.chatUserName !== '' && this.props.chatUserId !== '' && (
                <>
                  <Avatar
                    isOnline="active"
                    image={
                      API_URL +
                      'api/getUserPhoto/' +
                      this.props.chatUserId +
                      '/' +
                      this.props.chatUserName
                    }
                  />
                  <p>{this.props.chatUserName}</p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="content__body mt-2" ref={this.messagesEndRef}>
          <div className="chat__items">
            {this.props.chatUserName !== '' &&
              this.props.chatUserId !== '' &&
              this.state.chat &&
              this.state.chat.map((itm, index) => {
                return (
                  <ChatItem
                    animationDelay={index + 2}
                    key={itm.chatId}
                    user={itm.mychat ? 'me' : 'other'}
                    msg={itm.chat}
                    image={itm.senderId}
                    userName={itm.userName}
                    createdDate={itm.createdDate}
                  />
                )
              })}
            {/* <div ref={this.messagesEndRef}></div> */}
          </div>
        </div>

        <div className="content__footer">
          {this.state.chat && this.state.chat.length > 3 && (
            <BsChevronDoubleDown
              className="down-icon"
              onClick={this.scrollToBottom}
            />
          )}

          <div className="sendNewMessage">
            <input
              type="text"
              placeholder="Type a message here"
              onChange={this.onStateChange}
              value={this.state.msg}
              name="msg"
            />
            <button
              className="btnSendMsg"
              id="sendMsgBtn"
              onClick={(e) => this.saveReply(this.props.ownerUserId)}
            >
              <FiSend />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispacthToProps = (dispatch) => {
  return {
    getAdvertisementChatList: (id, type, receiverId, page) =>
      dispatch(getAdvertisementChatList(id, type, receiverId, page)),
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
  }
}

export default connect(
  mapStateToProps,
  mapDispacthToProps,
)(withTranslation()(withRouter(ChatReply)))
