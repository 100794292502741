import React, { useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { connect, useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineCloseCircle } from "react-icons/ai";
import loveFill from "../img/love.png";
import agreeFill from "../img/agree.png";
import supportFill from "../img/support.png";
import careFill from "../img/care.png";
import celebrateFill from "../img/celebrate.png";
import { API_URL } from "../config/app-config";
import dummyimage from "../img/default_avatar.jpg";
import { getPostLikeUsers } from "../redux/actions/PostCardActions";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import FriendsFinder from "../api/FriendsFinder";
import { toast } from "react-toastify";
import { giveUserAction } from "../utils";
import { needPhotVerification } from "../redux/actions/commonApiActions";

const list = [
  {
    profilePhoto: "c251f38c-4439-4e02-a51a-90e229f09d57",
    userName: "SADHANAA K",
    type: "friend",
    likeReactionType: 5,
    userId: 818,
  },
  {
    profilePhoto: "c251f38c-4439-4e02-a51a-90e229f09d57",
    userName: "SADHANAA K",
    type: "notFriends",
    likeReactionType: 4,
    userId: 814,
  },
  {
    profilePhoto: "c251f38c-4439-4e02-a51a-90e229f09d57",
    userName: "SADHANAA K",
    type: "requestPending",
    likeReactionType: 3,
    userId: 1562,
  },
  {
    profilePhoto: "c251f38c-4439-4e02-a51a-90e229f09d57",
    userName: "SADHANAA K",
    type: "notFriends",
    likeReactionType: 5,
    userId: 1562,
  },
  {
    profilePhoto: "c251f38c-4439-4e02-a51a-90e229f09d57",
    userName: "SADHANAA K",
    type: "me",
    likeReactionType: 5,
    userId: 1562,
  },
];

const LikePopUp = (props) => {
  const { t, modelClose, availableType } = props;
  const [activeMenu, setActiveMenu] = useState(0);
  const [modifyList, setModifyLikeList] = useState([]);
  const [pagNo, setPageNo] = useState(0);
  const dispatch = useDispatch();
  const loginDetails = useSelector(
    (state) => state.loginDetailsApi.loginDetails
  );

  const { likeSeeMore, likeUsersList, likesCount } = useSelector(
    (state) => state.postList
  );
  const [likeTypes, setLikeTypes] = useState({
    love: false,
    care: false,
    celebrate: false,
    support: false,
    agree: false,
    loveCount: 0,
    careCount: 0,
    celebrateCount: 0,
    supportCount: 0,
    agreeCount: 0,
  });
  const [modelOpen, setModelOpen] = useState(true);
  const { postId, postType } = props;
  const divRef = useRef();

  useEffect(() => {
    let data = {
      postId: postId,
      typeData: postType,
      page: 0,
    };
    dispatch(getPostLikeUsers(data, false, "all"));
    return () => {
      setModelOpen(false);
    };
  }, []);

  useEffect(() => {
    setModifyLikeList(likeUsersList);
    if (availableType) {
      modifyLikeList(availableType);
    }
  }, [likeUsersList]);

  const showFriendsProfile = (userId) => {
    props.history.push(`/${props.workspaceType}/friendDescription/${userId}`, {
      from: window.location.pathname,
    });
  };

  const modifyLikeList = (availableType) => {
    setLikeTypes({
      love: availableType.hasOwnProperty("1"),
      care: availableType.hasOwnProperty("2"),
      celebrate: availableType.hasOwnProperty("3"),
      support: availableType.hasOwnProperty("4"),
      agree: availableType.hasOwnProperty("5"),
      loveCount: availableType["1"] ? availableType["1"] : 0,
      careCount: availableType["2"] ? availableType["2"] : 0,
      celebrateCount: availableType["3"] ? availableType["3"] : 0,
      supportCount: availableType["4"] ? availableType["4"] : 0,
      agreeCount: availableType["5"] ? availableType["5"] : 0,
    });
  };

  const changeFilterType = (activeId) => {
    setActiveMenu(activeId);
    setPageNo(0);
    var data = {};

    if (activeId !== 0) {
      data = {
        postId: postId,
        typeData: postType,
        reactionType: activeId,
        page: 0,
      };
    } else {
      data = {
        postId: postId,
        typeData: postType,
        page: 0,
      };
    }
    dispatch(getPostLikeUsers(data, false, "no"));
  };

  const needMoreData = () => {
    var data = {};
    if (activeMenu !== 0) {
      data = {
        postId: postId,
        typeData: postType,
        reactionType: activeMenu,
        page: pagNo + 1,
      };
    } else {
      data = {
        postId: postId,
        typeData: postType,
        page: pagNo + 1,
      };
    }
    dispatch(getPostLikeUsers(data, true, "no"));
    setPageNo(pagNo + 1);
    console.log("likeList needMore");
  };
  console.log("likeTypes likeSeeMore", likeUsersList);
  //   console.log("likeTypes totalElements", totalElements);
  //   console.log("likeTypes likeTypes", likeTypes);

  const addFriend = (id, type) => {
    if (giveUserAction(loginDetails?.profile_complete)) {
      console.log("user-action");
      setModelOpen(false);
      dispatch(needPhotVerification(true));
    } else {
      if (type === "notFriends") {
        let getRequestedFriends = {
          friendsId: id,
          requestStatus: "requested",
        };
        FriendsFinder.friendRequest(getRequestedFriends)
          .then((response) => {
            console.log("response", response);
            const changeStatus = modifyList.map((item) => {
              if (item.userId === id) {
                item.type = "giveRequest";
              }
              return item;
            });
            console.log("updated-list", changeStatus);
            setModifyLikeList(changeStatus ? changeStatus : modifyList);
            toast.success(`${props.t("Request sent successfully")}`);
          })

          .catch((error) => {
            console.log("response", error);
          });
      }
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      classes={{ paper: "top-dialog-paper" }}
      open={modelOpen}
      onClose={() => modelClose()}
    >
      <DialogTitle>
        {t("People who reacted")}

        <AiOutlineCloseCircle
          onClick={modelClose}
          style={{
            cursor: "pointer",
            float: "right",
            marginBottom: "8px",
            width: "20px",
            height: "20px",
            color: "#000000",
          }}
        />
        <hr className="mt-0" />
        <ul
          style={{ marginBottom: availableType ? "-1px" : "17px" }}
          className="pop-up-images"
        >
          <li
            className={activeMenu === 0 ? "popup-active" : ""}
            style={{ cursor: "pointer" }}
            onClick={() => changeFilterType(0)}
          >
            <p>{t("All")}</p>
            <span class="badge badge-info ml-1">{likesCount}</span>
          </li>
          {likeTypes.love && (
            <li
              onClick={() => changeFilterType(1)}
              className={activeMenu === 1 ? "popup-active" : ""}
            >
              {" "}
              <img
                src={loveFill}
                style={{
                  height: "54px",
                  width: "auto",
                }}
                alt="img"
              />
              <span class="badge badge-info ml-1">{likeTypes.loveCount}</span>
            </li>
          )}
          {likeTypes.care && (
            <li
              onClick={() => changeFilterType(2)}
              className={activeMenu === 2 ? "popup-active" : ""}
            >
              {" "}
              <img
                style={{
                  height: "49px",
                  width: "auto",
                }}
                src={careFill}
                alt="img"
              />{" "}
              <span class="badge badge-info ml-1">{likeTypes.careCount}</span>
            </li>
          )}
          {likeTypes.celebrate && (
            <li
              onClick={() => changeFilterType(3)}
              className={activeMenu === 3 ? "popup-active" : ""}
            >
              {" "}
              <img
                style={{
                  height: "52px",
                  width: "auto",
                  //   marginBottom: "5px",
                }}
                src={celebrateFill}
                alt="img"
              />
              <span class="badge badge-info ml-1">
                {likeTypes.celebrateCount}
              </span>
            </li>
          )}
          {likeTypes.support && (
            <li
              onClick={() => changeFilterType(4)}
              className={activeMenu === 4 ? "popup-active" : ""}
            >
              {" "}
              <img
                src={supportFill}
                style={{
                  height: "49px",
                  width: "auto",
                }}
                alt="img"
              />
              <span class="badge badge-info ml-1">
                {likeTypes.supportCount}
              </span>
            </li>
          )}
          {likeTypes.agree && (
            <li
              onClick={() => changeFilterType(5)}
              className={activeMenu === 5 ? "popup-active" : ""}
            >
              {" "}
              <img
                src={agreeFill}
                style={{
                  height: "49px",
                  width: "43px",
                }}
                alt="img"
              />
              <span class="badge badge-info ml-1">{likeTypes.agreeCount}</span>
            </li>
          )}
        </ul>
      </DialogTitle>
      <DialogContent dividers="paper" style={{ overflow: "hidden" }}>
        <InfiniteScroll
          dataLength={modifyList?.length ? modifyList?.length : 0}
          next={needMoreData}
          hasMore={likeSeeMore}
          scrollableTarget="scrollable_div"
          direction="down"
          //   scrollThreshold={0.95}
        >
          <div
            id="scrollable_div"
            style={{
              minHeight: "auto",
              maxHeight: "29.5rem",
              overflow: "auto",
              overflowX: "hidden",
            }}
            ref={divRef}
          >
            {modifyList.map((item) => (
              <div className="d-flex align-items-center mt-2 mb-3">
                <div className="post-avatar post-avatar-story postCardImg-me-2">
                  {" "}
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => showFriendsProfile(item.userId)}
                    className="post-avatar-img rounded-circle"
                    src={
                      API_URL +
                      "api/getUserPhoto/" +
                      item.userId +
                      "/" +
                      item.userName
                    }
                    alt="user"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = dummyimage;
                    }}
                  />{" "}
                </div>

                <h6
                  className="post-nav-item post-card-title post-mb-0 text-capitalize"
                  style={{ cursor: "pointer" }}
                  onClick={() => showFriendsProfile(item.userId)}
                >
                  {" "}
                  {item.userName}{" "}
                </h6>
                {(item.type === "notFriends" ||
                  item.type === "giveRequest") && (
                  <button
                    className="ml-auto"
                    onClick={() => addFriend(item.userId, item.type)}
                  >
                    {item.type === "notFriends"
                      ? "+ Connect"
                      : "Request Pending"}
                  </button>
                )}
              </div>
            ))}
          </div>
        </InfiniteScroll>
        {modifyList.length === 0 && <p>No Likes present</p>}
      </DialogContent>
    </Dialog>
  );
};
const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    loginUserType: state.loginDetailsApi.loginDetails?.uType,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};
export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(LikePopUp)));
