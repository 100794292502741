import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import PhotoAlbum from "react-photo-album";
import * as APPCONFIG from "../../config/app-config.js";
import { AiFillLike } from "react-icons/ai";
import { FaComment } from "react-icons/fa";
import banner from "../../img/banner.jpg";
import dummyimage from "../../img/default_avatar.jpg";
import { API_URL } from "../../config/app-config";
import PostService from "../../api/PostService_old";
import dummygif from "../../img/dummygif.gif";
import VideoPostCard from "../Home/HomeUpdated/VideoPostCard";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import FbGridImages from "../Home/HomeUpdated/ImageView/Images";
import { OverlayTrigger } from "react-bootstrap";
import defaultFill from "../../img/defaultFill.jpg";
import defaultUnFill from "../../img/defaultUnFill.jpg";
// import loveFill from "../../img/loveFill.jpg";
import loveUnFill from "../../img/loveUnFill.jpg";
import recommendFill from "../../img/recommendFill.jpg";
import supportUnFill from "../../img/supportUnFill.jpg";
import recommendUnFill from "../../img/recommendUnFill.jpg";
import Popover from "react-bootstrap/Popover";
import "bootstrap/dist/css/bootstrap.min.css";
import Tooltip from "@material-ui/core/Tooltip";
import loveFill from "../../img/love.png";
import agreeFill from "../../img/agree.png";
import supportFill from "../../img/support.png";
import careFill from "../../img/care.png";
import celebrateFill from "../../img/celebrate.png";
import { BiLike } from "react-icons/bi";
import {
  extractNumberFromHref,
  giveUserAction,
  htmlValidation,
  validateSpanTag,
  getVideoStatus,
  premiumUser,
} from "../../utils";
import { needPhotVerification } from "../../redux/actions/commonApiActions";
import { getAdvertisementData } from "../../redux/actions/squareHomeActions";
import SinglePost from "../Home/HomeUpdated/SinglePost";
import { Link } from "react-router-dom";
import { Button, Form, Modal, Card } from "react-bootstrap";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import SurveyAnswersList from "../Home/HomeUpdated/surveyAnswerList";
import YoutubeVideoModel from "../../components/YoutubeVideoModel";
import ProductCard from "../../components/ProductCard.jsx";

class FriendPostCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      professionID: "",
      fileData: [],
      commentState: false,
      fileVideoData: [],
      professionList: [
        {
          id: 1,
          name: "student",
        },
        {
          id: 2,
          name: "Working professional",
        },
        {
          id: 3,
          name: "practicing profession (Auditoer,lawyer and etc)",
        },
        {
          id: 4,
          name: "Entreprenur",
        },
        {
          id: 5,
          name: "Homemaker",
        },
        {
          id: 6,
          name: "Freelancer",
        },
      ],
      commentList: [],
      likesCount: 0,
      userLike: false,
      polling: [],
      survey: [],
      surveySelectcheckedIndex: [],
      surveyAnswers: [],
      textAnswers: [],
      showSurveyResults: false,
      surveyQuestionsLoader: true,
    };

    this.getMessageFiles = this.getMessageFiles.bind(this);
    this.commentHit = this.commentHit.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.handleFormEnterKey = this.handleFormEnterKey.bind(this);
    this.commentList = this.commentList.bind(this);
    this.handleClickDefaultLike = this.handleClickDefaultLike.bind(this);
    this.likesButton = this.likesButton.bind(this);
    this.commentState = this.commentState.bind(this);
    this.load = this.load.bind(this);
    this.handleSurveySelect = this.handleSurveySelect.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    // this.load(this.props.data);
    this.getMessageFiles(
      this.props.data.postMediaFiles,
      this.props.data.type,
      this.props.data.userId
    );
    if (
      this.props.data.postCategory === "polling" ||
      this.props.data.postCategory === "survey"
    ) {
      this.setState({
        polling: this.props.data.polling,
        survey: this.props.data.surveyList ? this.props.data.surveyList : [],
      });
    }
    // this.commentList(this.props.data.postId, this.props.data.postType);
  }

  componentWillReceiveProps(nextProps) {
    // console.log("noMoreState will", this.props.data);
    if (this.props.data !== nextProps.data) {
      if (
        this.props.data.postCategory === "polling" ||
        this.props.data.postCategory === "survey"
      ) {
        this.setState({
          polling: nextProps.data?.polling,
          survey: nextProps.data?.surveyList ? nextProps.data?.surveyList : [],
        });
      }

      // this.load(this.props.data);
      // console.log("noMoreState data", this.props.data);
      this.getMessageFiles(
        nextProps.data.postMediaFiles,
        nextProps.data.type,
        nextProps.data.userId
      );
    }

    //  this.commentList(this.props.data.postId, this.props.data.postType);
  }
  handleSurveySelect(questionId, answerId, type) {
    console.log("Event Target Selected:", questionId);
    console.log("Event Target Selected:", answerId);
    let surveySelectList = {};
    surveySelectList.questionId = questionId;
    surveySelectList.answerType = type;

    if (type === "Select") {
      surveySelectList.answersId = [answerId];
      console.log("Event Target Selected:", surveySelectList);
      const index = this.state.surveyAnswers.findIndex(
        (data) => data.questionId === questionId
      );
      if (index === -1) {
        console.log("Event Target Selected", index);
        this.setState({
          surveyAnswers: [...this.state.surveyAnswers, surveySelectList],
        });
      } else {
        console.log("Event Target Selected", index);
        const newState = this.state.surveyAnswers.map((data, idx) =>
          index === idx
            ? {
                ...data,
                answersId: [answerId],
              }
            : data
        );
        console.log("Event Target Selected", newState);
        this.setState({
          surveyAnswers: [...newState],
        });
      }
    }
    // return
  }
  handleTextType = (questionId, answerId, type, textAnswer) => {
    this.setState({ sample: textAnswer });
    let surveySelectList = {};
    surveySelectList.questionId = questionId;
    surveySelectList.answerType = type;
    surveySelectList.answersId = answerId;
    surveySelectList.answer = textAnswer;
    console.log("Event Target Selected", surveySelectList);
    const index = this.state.textAnswers.findIndex(
      (data) => data.questionId === questionId
    );
    if (index === -1) {
      console.log("Event Target Selected index exist", index);
      this.setState({
        textAnswers: [...this.state.textAnswers, surveySelectList],
      });
    } else {
      console.log("Event Target Selected index Not Exist", index);
      const newState = this.state.textAnswers.map((data, idx) =>
        index === idx
          ? {
              ...data,
              answerId: answerId,
              answer: textAnswer,
            }
          : data
      );
      console.log("Event Target Selected", newState);
      this.setState({
        textAnswers: [...newState],
      });
    }
  };
  render() {
    const { t } = this.props;
    const { userLikeType } = this.props;

    const {
      postMediaFiles,
      commendsCount,
      postType,
      userName,
      postId,
      type,
      userId,
      subjectId,
      content,
      createdDate,
      professionId,
      postLikeCommends,
      postActive,
      user,
      fileContent,
      subjectName,
      allLikes,
      isSharePost,
      shareDetails,
      postCategory,
      article,
      polling,
      survey,
    } = this.props.data;
    let trimmedString;
    if (this.props.data.postCategory === "article") {
      var myHTML =
        this.props.data.postCategory == "article"
          ? this.props.data.article?.description
            ? this.props.data.article.description
            : ""
          : "";
      // var strippedHtml = myHTML.replace(/<[^>]+>/g, "");
      const trimmedString1 = myHTML.split("</p>", 1);
      trimmedString = trimmedString1[0] ? trimmedString1[0] : "" + "</p>";
    }
    console.log("survey", this.state.survey);
    return (
      <>
        <div className="about-card" style={{ marginTop: "1.5rem" }}>
          <div className="about-card-header border-0 pb-0">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="post-avatar post-avatar-story post-me-2">
                  <a href="#!">
                    {" "}
                    <img
                      className="post-avatar-img rounded-circle"
                      src={
                        API_URL + "api/getUserPhoto/" + userId + "/" + userName
                      }
                      alt="user"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyimage;
                      }}
                    />{" "}
                  </a>
                </div>
                <div>
                  <div className="post-nav post-nav-divider">
                    <h6
                      className="post-nav-item post-card-title post-mb-0"
                      style={{ color: "#14191e" }}
                    >
                      {" "}
                      {userName}{" "}
                      {premiumUser(this.props.data?.premiumData) && (
                        <img
                          src={premiumUser(this.props.data?.premiumData)}
                          alt=""
                          className="ml-1"
                          style={{ width: "20px" }}
                        />
                      )}
                    </h6>
                    <span className="post-nav-item post-small">
                      {moment(createdDate)
                        .locale("en")
                        .startOf("day")
                        .fromNow()}
                    </span>
                  </div>
                  {this.state.professionList.map((option, index) => (
                    <>
                      {professionId == option.id && (
                        <p className="post-mb-0 post-small">{option.name}</p>
                      )}
                    </>
                  ))}
                </div>
              </div>
              {postCategory === "survey" && (
                <div
                  className="d-flex"
                  style={{
                    cursor: "pointer",
                    alignItems: "baseline",
                    gap: "0.6rem",
                  }}
                >
                  {this.state.survey &&
                    this.props.loginDetails.uId === userId && (
                      // this.state?.survey?.isCompleted &&
                      <div
                        style={{
                          border: "2px solid rgb(220, 53, 69)",
                          padding: "4px 8px",
                          borderRadius: "100px",
                          background: "rgb(220, 53, 69,0.1)",
                          fontSize: "10px",
                        }}
                      >
                        <p onClick={() => this.handleShowSurveyResults()}>
                          {t("View Results")}
                        </p>
                      </div>
                    )}

                  {this.state.survey &&
                    this.props.loginDetails.uId !== userId &&
                    this.state.survey?.isCompleted && (
                      <div
                        style={{
                          border: "2px solid rgb(220, 53, 69)",
                          padding: "4px 8px",
                          borderRadius: "100px",
                          background: "rgb(220, 53, 69,0.1)",
                          fontSize: "10px",
                        }}
                      >
                        <p>{t("Survey Submitted")}</p>
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>

          <div className="post-card-body">
            {!isSharePost && (
              <>
                {type == "text" && (
                  <p className="about-mb-2 " onClick={this.handleHashTag}>
                    {/* <p
                      dangerouslySetInnerHTML={{
                        __html: content,
                      }}
                    /> */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: (content ? content : "").replace(
                          /<p><\/p>/g,
                          "<br/>"
                        ),
                      }}
                    />
                    {/* {htmlValidation(content ? content : "")} */}
                  </p>
                  // <p
                  //   className="about-mb-2"
                  //   dangerouslySetInnerHTML={{
                  //     __html: content,
                  //   }}
                  // />
                )}
                {(type == "image" ||
                  type == "video" ||
                  type == "file" ||
                  type == "audio") && (
                  <p onClick={this.handleHashTag}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: (fileContent ? fileContent : "").replace(
                          /<p><\/p>/g,
                          "<br/>"
                        ),
                      }}
                    />
                    {/* <p
                      className="about-mb-2"
                      dangerouslySetInnerHTML={{
                        __html: fileContent,
                      }}
                    /> */}
                    {/* {htmlValidation(fileContent ? fileContent : "")} */}
                  </p>
                  // <p
                  //   className="about-mb-2"
                  //   dangerouslySetInnerHTML={{
                  //     __html: fileContent,
                  //   }}
                  // />
                )}

                {Array.isArray(this.props.data?.youtubeUrls) &&
                  this.props.data?.youtubeUrls.length !== 0 && (
                    <div
                      className="d-flex flex-column mb-2 mt-2"
                      style={{ gap: "1rem" }}
                    >
                      {this.props.data?.youtubeUrls.slice(0, 1).map((item) => (
                        <>
                          <YoutubeVideoModel youtubeId={item} />{" "}
                        </>
                      ))}
                    </div>
                  )}

                {type == "image" && (
                  <div class="mb-2">
                    {this.state.fileImageData !== undefined &&
                      this.state.fileImageData.length == 1 && (
                        <div class="postCardImageSingle">
                          <FbGridImages
                            images={this.state.fileImageData}
                            onClickImage={() => {}}
                          />
                        </div>
                      )}
                    {this.state.fileImageData !== undefined &&
                      this.state.fileImageData.length > 1 && (
                        <div class="postCardImage">
                          <FbGridImages
                            images={this.state.fileImageData}
                            onClickImage={() => {}}
                          />
                        </div>
                      )}
                  </div>
                )}
                {type == "video" && (
                  <div className="d-flex flex-column" style={{ gap: "1rem" }}>
                    {this.state.fileVideoData.map((photo) => (
                      <VideoPostCard
                        fileData={photo.file}
                        imageData={photo.image}
                        videoStatus={photo.steamStatus}
                      />
                    ))}
                  </div>
                )}
                {type == "pdf" && (
                  <div>
                    {this.state.fileData &&
                      this.state.fileData.map((photo) => (
                        <div style={{ margin: "8px" }}>
                          <a
                            // download={photo.originalFileName}
                            style={{ textDecoration: "none", color: "black" }}
                            href={
                              photo.fileName == undefined ||
                              photo.fileName == ""
                                ? dummygif
                                : photo.fileName
                            }
                          >
                            <DescriptionOutlinedIcon />
                            {photo.originalFileName}
                          </a>
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}
            {isSharePost && (
              <>
                {content && (
                  <>
                    <p onClick={this.handleHashTag}>
                      {/* {htmlValidation(content)} */}
                      <p
                        className="about-mb-2"
                        dangerouslySetInnerHTML={{
                          __html: content,
                        }}
                      />
                    </p>
                  </>
                )}
                <div className="mb-4">
                  <SinglePost
                    professionList={this.state.professionList}
                    workspaceType={this.props.workspaceType}
                    postId={shareDetails?.postId}
                    postType={shareDetails?.typeData}
                    modelPost={false}
                  />
                </div>
              </>
            )}
            {postCategory === "article" && article && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <h5>{article?.title ? article.title : "Article"}</h5>
                <p className="about-mb-2" onClick={this.handleHashTag}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: trimmedString,
                    }}
                  />
                  {/* {htmlValidation(trimmedString ? trimmedString : "")} */}
                </p>

                <Link
                  to={{
                    pathname: `/articles/${article?.slug}`,
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      color: "#A00030",
                    }}
                  >
                    {t("Read More")}
                  </p>
                </Link>
              </div>
            )}
            {postCategory === "polling" && this.state.polling && (
              <div>
                <div>
                  <p className="about-mb-2" onClick={this.handleHashTag}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.polling?.postQuestion,
                      }}
                    />
                  </p>
                  {/* <p>{this.state.polling?.postQuestion}</p> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    alignItems: "space-between",
                    paddingBlock: "1rem",
                  }}
                >
                  {Array.isArray(this.state.polling?.answer) &&
                    this.state.polling?.answer
                      .sort((a, b) =>
                        a.postAnswersId > b.postAnswersId ? 1 : -1
                      )
                      .map((data) => (
                        <div>
                          <button
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              background: "none",
                              color: "#A00030",
                              border: data.isSelected
                                ? "1px solid #A00030"
                                : "1px solid lightgray",
                            }}
                            onClick={() =>
                              this.handlePolling(
                                this.state.polling?.answer,
                                data
                              )
                            }
                          >
                            <p>{data.postAnswers}</p>
                            <p className="poll-count-position">
                              {/* {Utils.CheckPollIDisMatching(UserpollArray, data.id)} */}
                              {data.count}
                              {/* 4 */}
                            </p>
                          </button>
                        </div>
                      ))}
                </div>
                <span className="miniText">
                  {this.state.polling?.totalCount + " " + t("Votes")}
                  {/* 1 votes */}
                </span>
              </div>
            )}
            {postCategory === "product" && (
              <ProductCard data={this.props.data?.productDetails} />
            )}
            {/* survey */}
            {postCategory === "survey" &&
              this.state.survey &&
              this.state.survey?.survey && (
                <>
                  <Form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        gap: "1rem",
                        flexDirection: "column",
                      }}
                    >
                      <p>
                        {!this.state.survey?.isCompleted
                          ? t("Please Take a Survey")
                          : t("Survey Results")}
                      </p>
                      {this.state.survey?.survey.map((surveyData, index) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "0.6rem",
                          }}
                        >
                          <p className="d-flex">
                            {index + 1}.
                            <div
                              className="ml-2"
                              dangerouslySetInnerHTML={{
                                __html: surveyData.surveyQuestions,
                              }}
                            />
                          </p>
                          {surveyData.answerType === "Select" ? (
                            <>
                              {surveyData?.postSurveyAnswers.map((data) => (
                                <label
                                  style={{
                                    display: "flex",
                                    gap: "0.6rem",
                                  }}
                                >
                                  {data.isSelected ? (
                                    <fieldset disabled={data.isSelected}>
                                      <input
                                        type="radio"
                                        disabled={this.state.survey.isCompleted}
                                        checked={data.isSelected}
                                        name={surveyData.surveyQuestions}
                                        value={data.surveyAnswers}
                                        onChange={() =>
                                          this.handleSurveySelect(
                                            surveyData.postSurveyQuestionsId,
                                            data.postSurveyAnswersId,
                                            "Select"
                                          )
                                        }
                                      />
                                    </fieldset>
                                  ) : (
                                    <input
                                      type="radio"
                                      disabled={this.state.survey.isCompleted}
                                      name={surveyData.surveyQuestions}
                                      value={data.surveyAnswers}
                                      onChange={() =>
                                        this.handleSurveySelect(
                                          surveyData.postSurveyQuestionsId,
                                          data.postSurveyAnswersId,
                                          "Select"
                                        )
                                      }
                                    />
                                  )}
                                  {data.surveyAnswers}
                                </label>
                              ))}
                            </>
                          ) : (
                            <>
                              <div style={{ width: "99%" }}>
                                <TextField
                                  id="servey text"
                                  // value={this.state.sample}
                                  value={
                                    this.state.sample
                                      ? this.findData(
                                          surveyData.postSurveyAnswers?.[0]
                                            .postSurveyAnswersId
                                        )
                                      : ""
                                  }
                                  style={{ margin: 8 }}
                                  placeholder={
                                    !this.state.survey.isCompleted
                                      ? surveyData.postSurveyAnswers?.[0]
                                          .surveyAnswers
                                      : surveyData.postSurveyAnswers?.[0]
                                          ?.answer
                                  }
                                  // helperText="value"
                                  fullWidth
                                  margin="normal"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e) =>
                                    this.handleTextType(
                                      surveyData.postSurveyQuestionsId,
                                      surveyData.postSurveyAnswers?.[0]
                                        .postSurveyAnswersId,
                                      "Text",
                                      e.target.value
                                    )
                                  }
                                  variant="filled"
                                  disabled={this.state.survey.isCompleted}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                      {!this.state.survey.isCompleted && (
                        <Button
                          variant="primary"
                          onClick={(e) => this.handleSurvey(e)}
                        >
                          {" "}
                          {t("Submit")}{" "}
                        </Button>
                      )}
                    </div>
                  </Form>
                </>
              )}

            {postCategory !== "advertisement" && (
              <>
                {/* {this.props.data.allLikes !== null && (
                  <>
                    <p className="like-count">
                      {this.props.data.allLikes?.love && (
                        <Tooltip
                          title={"Loved - " + allLikes?.love}
                          placement="top"
                        >
                          <span>
                            <img
                              className="home-like-img-icon"
                              src={loveFill}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {this.props.data.allLikes?.recommend && (
                        <Tooltip
                          title={"Recommend - " + allLikes?.recommend}
                          placement="top"
                        >
                          <img
                            className="home-like-img-icon"
                            src={recommendFill}
                          />
                        </Tooltip>
                      )}
                      {this.props.data.allLikes?.support && (
                        <Tooltip
                          title={"Supported - " + allLikes?.support}
                          placement="top"
                        >
                          <img
                            className="home-like-img-icon"
                            src={supportFill}
                          />
                        </Tooltip>
                      )}
                      {this.props.data.allLikes?.like && (
                        <Tooltip
                          title={"Liked - " + allLikes?.like}
                          placement="top"
                        >
                          <img
                            className="home-like-img-icon"
                            src={defaultFill}
                          />
                        </Tooltip>
                      )}{" "}
                      {this.props.data.userlike ? (
                        <>
                          {this.props.data.likesCount === 1 ? (
                            <>{t("You")}</>
                          ) : (
                            <>
                              {t("You and ")}
                              {this.props.data.likesCount - 1}

                              {this.props.data.likesCount === 2
                                ? t(" other")
                                : t(" others")}
                            </>
                          )}
                        </>
                      ) : this.props.data.likesCount !== 0 ? (
                        <>
                          {" "}
                          {this.props.data.likesCount}
                          {this.props.data.likesCount > 1
                            ? t(" others")
                            : t(" other")}
                        </>
                      ) : (
                        <></>
                      )}
                    </p>
                    <hr class="m-0 " />
                  </>
                )} */}

                <ul className="nav comment-nav-stack comment-py-3 comment-small mt-2">
                  {/* <li className="nav-item">
                    <div>
                      <OverlayTrigger
                        // show={this.state.likePopoverStatus}
                        trigger="click"
                        placement="top"
                        overlay={this.likePopover()}
                        rootClose={true}
                      >
                        <a
                          className={
                            this.props.data.userlike
                              ? "comment-nav-link active"
                              : "comment-nav-link"
                          }
                          onClick={() =>
                            this.userLikeType("like", this.props.data.postType)
                          }
                          style={{
                            float: "left",
                            cursor: "pointer",
                            textTransform: "capitalize",
                          }}
                        >
                          {userLikeType == "1" ? (
                            <img
                              className="home-like-img-icon"
                              src={loveFill}
                              style={{
                                height: "55px",
                                width: "auto",
                              }}
                            />
                          ) : userLikeType == "2" ? (
                            <img
                              className="home-like-img-icon"
                              src={careFill}
                            />
                          ) : userLikeType == "3" ? (
                            <img
                              className="home-like-img-icon"
                              src={celebrateFill}
                              style={{ marginBottom: "8px" }}
                            />
                          ) : userLikeType == "4" ? (
                            <img
                              className="home-like-img-icon"
                              src={supportFill}
                            />
                          ) : this.props.data?.userlike &&
                            this.props.data?.userlikeType == "5" ? (
                            <img
                              className="home-like-img-icon"
                              src={agreeFill}
                            />
                          ) : (
                            <BiLike
                              style={{
                                width: "18px",
                                height: "20px",
                                marginBottom: "2px",
                              }}
                            />
                          )}
                          {this.props.data.userlike ? (
                            // ? t("Liked")
                            <span class="ml-1">
                              {t(
                                `${
                                  userLikeType == 1
                                    ? "Love"
                                    : userLikeType == 2
                                    ? "Care"
                                    : userLikeType == 3
                                    ? "Celebrate"
                                    : userLikeType == 4
                                    ? "Support"
                                    : userLikeType == 5
                                    ? "Agree"
                                    : "Like"
                                }`
                              )}
                            </span>
                          ) : (
                            //  : this.state.likesLength > 1 ? (
                            //   <span class="ml-1">{t("Likes")}</span>
                            // ) :
                            <span class="ml-1">{t("Like")}</span>
                          )}{" "}
                          {this.props.data.likesCount !== 0 &&
                            "(" + this.props.data.likesCount + ")"}
                        </a>
                      </OverlayTrigger>
                    </div>
                  </li> */}
                  {/* <li className="nav-item">
                                <a className="comment-nav-link" style={{ float: "left", cursor: "pointer" }}> <FaComment style={{ width: "15px", height: "15px", marginRight: "0.25rem" }} /> Comments ({commendsCount})</a>
                            </li> */}
                </ul>
              </>
            )}

            {/* 
                        <div className="d-flex mb-3">
                            <div className="post-avatar post-avatar-xs post-me-2">
                                <a href="#!"> <img className="post-avatar-img rounded-circle" src={
                                    API_URL +
                                    "api/getUserPhoto/" +
                                    userId +
                                    "/" +
                                    userName
                                }
                                    alt="user"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = dummyimage;
                                    }} /> </a>
                            </div>
                            <form className="post-position-relative post-w-100">
                                <textarea className="post-form-control post-pe-4 post-bg-light" rows="1" placeholder="Add a comment..."
                                    maxLength="255"
                                    type="text"
                                    onKeyDown={this.handleFormEnterKey}
                                    onChange={this.handleForm}
                                    value={this.state.commentsText}
                                    name="commentsText"
                                ></textarea>
                            </form>
                        </div> */}

            {/* {!this.state.commentState &&
                            <div>
                                {this.state.commentList &&
                                    this.state.commentList.splice(0, 2).map((comments, index) =>
                                        <ul className="comment-wrap list-unstyled">
                                            <li className="comment-item">
                                                <div className="d-flex position-relative">
                                                    <div className="post-avatar post-avatar-xs">
                                                        <a href="#!"><img className="post-avatar-img rounded-circle" src={
                                                            API_URL +
                                                            "api/getUserPhoto/" +
                                                            comments.userId +
                                                            "/" +
                                                            comments.userName
                                                        }
                                                            alt="user"
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null; // prevents looping
                                                                currentTarget.src = dummyimage;
                                                            }} /></a>
                                                    </div>
                                                    <div className="ms-2" style={{ marginLeft: "12px" }}>
                                                        <div className="bg-light rounded-start-top-0 p-3 rounded">

                                                            <div className="post-nav post-nav-divider">
                                                                <h6 className="post-nav-item post-card-title post-mb-0" style={{ color: "#14191e" }}> {comments.userName} </h6>
                                                                <span className="post-nav-item post-small" >   {moment(comments.createdDate).format(
                                                                    "DD MMM, h:mm a"
                                                                )}</span>
                                                            </div>
                                                            <p class="small mb-0">{comments.commends}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </li>

                                        </ul>)}
                               { this.state.commentList.length>0 && <p className="load-more-comment" style={{ cursor: "pointer" }} onClick={() => this.commentState()}>

                                    Load more comments
                                </p>}
                            </div>}



                        {this.state.commentState && this.state.commentList &&
                            this.state.commentList.map((comments, index) =>
                                <ul className="comment-wrap list-unstyled">
                                    <li className="comment-item">
                                        <div className="d-flex position-relative">
                                            <div className="post-avatar post-avatar-xs">
                                                <a href="#!"><img className="post-avatar-img rounded-circle" src={
                                                    API_URL +
                                                    "api/getUserPhoto/" +
                                                    comments.userId +
                                                    "/" +
                                                    comments.userName
                                                }
                                                    alt="user"
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = dummyimage;
                                                    }} /></a>
                                            </div>
                                            <div className="ms-2" style={{ marginLeft: "12px" }}>
                                                <div className="bg-light rounded-start-top-0 p-3 rounded">

                                                    <div className="post-nav post-nav-divider">
                                                        <h6 className="post-nav-item post-card-title post-mb-0" style={{ color: "#14191e" }}> {comments.userName} </h6>
                                                        <span className="post-nav-item post-small" >   {moment(comments.createdDate).format(
                                                            "DD MMM, h:mm a"
                                                        )}</span>
                                                    </div>
                                                    <p class="small mb-0">{comments.commends}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </li>

                                </ul>)} */}
          </div>
        </div>
        {this.state.showSurveyResults && (
          <>
            <Dialog
              fullWidth
              maxWidth="md"
              open={this.state.showSurveyResults}
              onClose={() => this.handleModalclose()}
            >
              <DialogTitle>{t("Survey Results")}</DialogTitle>
              <DialogContent dividers="paper">
                {this.state.surveyQuestionsLoader ? (
                  <div
                    style={{
                      width: "100%",
                      height: "50vh",
                      display: "grid",
                      placeContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <SurveyAnswersList data={this.state.surveyQuestions} />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleModalclose()}>Close</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    );
  }
  subscribeAdvertisement = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      if (this.props.data.postCategory === "advertisement") {
        this.props.getAdvertisementData(
          this.props.data.postId,
          this.props.data.postType
        );
        this.props.history.push(
          `/${this.props.workspaceType}/advertisement/${this.props.data.postId}/${this.props.data.postType}`
        );
      }
    }
  };
  handleModalclose = () => {
    this.setState({
      showSurveyResults: false,
    });
  };
  handleShowSurveyResults = () => {
    const params = {
      page: 0,
      modertionId: this.props.data.moderationId,
      postId: this.props.data.postId,
      type: this.props.data.postType,
    };

    this.setState({
      showSurveyResults: true,
    });
    PostService.getSurveyUserListByPostId(params).then((res) => {
      console.log("Survey Results", res.data.data.userList);
      if (res?.data?.data?.userList) {
        console.log("Survey Results", res.data.data);
        this.setState({
          surveyQuestions: res?.data?.data?.userList,
          surveyQuestionsLoader: false,
        });
      }
    });
  };
  handleSurvey = (e) => {
    // console.log(data)
    e.preventDefault();
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      const data = {
        postId: this.props.data.moderationId,
        survey: [...this.state.surveyAnswers, ...this.state.textAnswers],
      };
      console.log("Survey Data :", data);
      console.log("Event Target Selected:", e.target.value);

      PostService.saveUserSurvey(data)
        .then((res) => {
          console.log("Response Survey", res.data?.data);
          this.setState({
            survey: res.data.data?.surveyList ?? null,
            sample: "",
            surveyAnswers: [],
            textAnswers: [],
          });
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
    // return
  };
  findData = (id) => {
    let check =
      (this.state.textAnswers &&
        this.state.textAnswers.find((item) => item.answerId == id)?.answer) ||
      "";
    return check ? check : "";
  };
  handlePolling(pollingOptions = [], pollId) {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      let newState = Object.assign({}, this.state);
      pollingOptions.filter((data) => {
        if (data.postAnswersId === pollId.postAnswersId) {
          console.log("Selected Polling Id:", data);
          console.log("Selected Polling Id:", newState);
          const params = {
            postId: this.props.data.moderationId,
            answerId: data.postAnswersId,
            questionId: data.postQuestionId,
          };
          PostService.saveUserPoll(params).then((res) => {
            console.log("Selected Polling Id (api call):", res.data.data);
            this.setState({
              polling: res.data.data?.polling ?? null,
            });
          });
        }
      });
    }
  }

  handleHashTag = (event) => {
    const clickedText = event.target.textContent;
    const regex = /^#[^\s]+$/;

    if (regex.test(clickedText)) {
      const newStr = clickedText.replace(/#/g, "");
      this.props.history.push(
        `/${this.props.workspaceType}/${"hash"}/${newStr}`
      );
      return;
    }
    let htmlElement = event.target;
    const htmlString = htmlElement?.outerHTML;
    // if (validateSpanTag(htmlString)) {
    //   this.moveToUserProfile(event, htmlString);
    // }
    if (validateSpanTag(htmlString)) {
      this.moveToUserProfile(event, htmlString);
    }
  };
  moveToUserProfile = (event, htmlString) => {
    const id = extractNumberFromHref(htmlString);
    event.preventDefault();
    event.stopPropagation();
    // this.showFriendsProfile(id);
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${id}`,
      { from: window.location.pathname }
    );
    // event.preventDefault();
    // event.stopPropagation();
    // const regex =
    //   /<span style="color:#D92267;cursor: pointer;">@(\w+)<\/span>/i;
    // const match = htmlString.match(regex);
    // const value = match ? match[1] : null;
    // this.props.history.push(`/${this.props.workspaceType}/${"user"}/${value}`);
    // return;
  };
  load(data) {
    // this.setState({
    //   userlike: data.userlike,
    //   likesCount: data.likesCount,
    // });
  }
  async commentState() {
    await this.setState({ commentState: true });
    this.commentList(this.props.data.postId, this.props.data.postType);
  }

  handleFormEnterKey(e) {
    if (
      this.state.commentsText !== "" &&
      this.state.commentsText !== undefined
    ) {
      if (e.keyCode === 13) {
        this.commentHit(this.props.data.postId, this.props.data.postType);
      }
    }
  }

  userLikeType = () => {
    this.setState({
      likePopoverStatus: !this.state.likePopoverStatus,
    });
  };
  likePopover() {
    return (
      <Popover id="popover-basic">
        {/* <Popover.Title as="h3">Share your SocialMedia</Popover.Title> */}
        <Popover.Content>
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <img
              class="home-img-icon-like "
              src={loveFill}
              onClick={() => this.handleClickDefaultLike(1)}
              style={{
                height: "60px",
              }}
            />
            <img
              class="home-img-icon-like "
              src={agreeFill}
              style={{
                height: "54px",
              }}
              onClick={() => this.handleClickDefaultLike(5)}
            />
            <img
              class="home-img-icon-like "
              src={supportFill}
              style={{
                height: "51px",
                width: "46px",
              }}
              onClick={() => this.handleClickDefaultLike(4)}
            />

            <img
              class="home-img-icon-like"
              src={careFill}
              style={{
                height: "55px",
                width: "43px",
              }}
              onClick={() => this.handleClickDefaultLike(2)}
            />
            <img
              class="home-img-icon-like"
              style={{
                height: "49px",
                width: "50px",
              }}
              src={celebrateFill}
              onClick={() => this.handleClickDefaultLike(3)}
            />
            {/* ) : (
            <img
              class="home-img-icon"
              src={supportUnFill}
              onClick={() => this.handleClickDefaultLike("support")}
              onMouseOver={(e) => (e.currentTarget.src = supportFill)}
              onMouseOut={(e) => (e.currentTarget.src = supportUnFill)}
            />
          )} */}

            {/* )} */}
          </div>

          {/* </FacebookShareButton>{' '} */}
        </Popover.Content>
      </Popover>
    );
  }
  handleClickDefaultLike(likeType) {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
      document.body.click();
      this.setState({
        likePopoverStatus: !this.state.likePopoverStatus,
      });
    } else {
      var postId = this.props.data.postId;
      var type = this.props.data.postType;

      this.setState({
        likeType: likeType,
        likePopoverStatus: !this.state.likePopoverStatus,
        //isLikeOptionSelected: true,
      });

      this.likesButton(postId, likeType, type);
      document.body.click();
    }
  }
  likesButton = (pid, likeType, type) => {
    let createpostLike = {
      userId: this.props.loginDetails.uId,
      postId: pid,
      postLikeAndCommendType: "like",
      likes: 1,
      type: type,
      likeReactionType: likeType,
      userLike: this.props.data.userlikeType === likeType ? false : true,
    };
    PostService.createpostLike(createpostLike)
      .then((response) => {
        var result = response.data;
        var likeCounts = result.data.likeCount;
        this.setState({ likesLength: likeCounts });
        this.props.likestatusUpdate(
          result,
          this.props.data.userlikeType === likeType ? false : true
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  commentList(pid, type) {
    let getcommendbypost = {
      postId: pid,
      postLikeAndCommendType: "commend",
      type: type,
    };

    PostService.getcommendbypost(getcommendbypost)
      .then((response) => {
        var result = response.data;

        this.setState({ commentList: result.data.postCommendsList });
        console.log("COMMENT LIST TOGGLE");
        console.log(this.state.commentList);
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({ comment: !this.state.comment });
  }

  commentHit(pid, type) {
    if (this.state.commentsText === "") {
      toast.warn("Comment should not be empty");
    } else {
      let createpostCommands = {
        userId: this.props.loginDetails.uId,
        postId: pid,
        postLikeAndCommendType: "commend",
        comment: this.state.commentsText,
        userName: this.props.loginDetails.dName,
      };

      PostService.createpostCommands(createpostCommands)
        .then((response) => {
          var result = response.data;
          // this.MixpanelCallinComment();
          this.setState({ commentsText: [] });
          this.commentList(pid, type);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async getMessageFiles(files, type, userId) {
    if (type == "image") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          // fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);

          // fileList.push({
          //     src: `${APPCONFIG.API_URL}auth/image/${files[i].fileName}`,
          //     width: 1600,
          //     height: 900,
          // });
          fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
        }
        await this.setState({ fileImageData: fileList });
      }
    } else if (type == "video") {
      let fileLisVideo = [];
      //  if(this.state.isvideoOpen === true){
      if (files.length > 0) {
        // for (let i = 0; i < files.length; i++) {
        // fileLisVideo.push(
        //     `${APPCONFIG.API_URL}auth/video/${files[i].fileName}`
        // );
        // fileLisVideo.push({
        //   file: files[i].streamUrl,
        //   image: files[i].thumbnail,
        // });
        // }
        await this.setState({
          fileVideoData: getVideoStatus(
            files,
            this.props.loginDetails.uId,
            userId,
            this.props.loginDetails.uType
          ),
        });
        // await this.setState({ fileVideoData: fileLisVideo });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      }
      let fileList = [];
      if (files.videoThumbnail !== null) {
        // alert("Null")
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            fileList.push(
              `${APPCONFIG.API_URL}auth/image/${files[i].videoThumbnail}`
            );
          }

          await this.setState({ fileData: fileList });
          console.log("FILE LIST");
          console.log(this.state.fileData);
        }
      }
    } else if (type == "pdf") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST PDF");
        console.log(this.state.fileData);
      }
    } else if (type == "file") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      }
    }
  }
}
const mapDispacthToProps = (dispatch) => {
  return {
    getAdvertisementData: (id, type) =>
      dispatch(getAdvertisementData(id, type)),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(FriendPostCard)));
