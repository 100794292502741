import React, { Component } from 'react'
import dummyimage from '../../../img/default_avatar.jpg'
export default class Avatar extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="avatar">
        <div className="avatar-img">
          <img
            src={this.props.image}
            alt="#"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = dummyimage
            }}
          />
        </div>
        {/* <span className={`isOnline ${this.props.isOnline}`}></span> */}
      </div>
    )
  }
}
