import React, { Component } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState } from "draft-js";
import { API_URL } from "../config/app-config";
import axios from "axios";
import PostService from "../api/PostService_old";
import AuthService from "../session/AuthService_old";
import i18n from "../i18n";
import { withTranslation } from "react-i18next";
const MAX_LENGTH = 3999;

function uploadImageCallBack(file) {
  console.group("Article : Started");
  console.log("File name :", file);

  const uploadFile = async (data) => {
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${API_URL}auth/point-to-image-url`,
        data,
        config
      );
      return response.data.data.articleUrl ?? null;
    } catch (err) {
      console.log("Error Uploading File :", err);
    }
  };

  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onloadend = async () => {
      const imagedata = new FormData();
      imagedata.append("articleFile", file);
      const response = await uploadFile(imagedata);
      console.log("Article Response", response);
      resolve({ data: { link: response } });
    };
    reader.readAsDataURL(file);
  });
}

class TextEditorHomePost extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    currentValues: null,
    friendList: [],
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.editorStateValue(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };
  componentDidMount() {
    this.addFriendList();
  }
  addFriendList = () => {
    const filterdList = this.props.friendslist.map((data) => ({
      text: data.userName,
      value: data.userName,
      url: "friendDescription/" + data.userId,
    }));
    console.log("friendslist", filterdList);
    this.setState({
      friendList: filterdList,
    });
  };

  componentWillReceiveProps(nextProps) {
    console.log(
      "questionDescribed componentWillReceiveProps 1",
      this.props.editorValue
    );
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("questionDescribed props 1", this.props.editorValue);
    console.log("questionDescribed prevProps 1", prevProps.editorValue);
    if (this.props.friendslist !== prevProps.friendslist) {
      this.addFriendList();
    }
    if (this.props.editorValue !== prevProps.editorValue) {
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      console.log("questionDescribed props 2", this.props.editorValue);
      const blocksFromHtml = htmlToDraft(this.props.editorValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      console.log(contentState);
      console.log(EditorState.createWithContent(contentState));
      this.setState({
        editorState: EditorState.createWithContent(contentState),
      });
    }
  }
  handleChange = (event) => {
    const reset = { ...event.blocks[0], text: null };
    this.setState({
      currentValues: event,
    });
  };
  onClear = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
    });
  };
  render() {
    const { t } = this.props;
    const { editorState, currentValues } = this.state;
    // console.log("questionDescribed props render", this.props.editorValue);
    if (this.props.textBoxStatus) {
      this.addUpdateData();
    }
    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          // editorStyle={{ border: "1px solid gray" }}
          editorClassName="demo-editor"
          placeholder={t("What do you want to share")}
          onEditorStateChange={this.onEditorStateChange}
          onChange={this.handleChange}
          handleBeforeInput={this._handleBeforeInput}
          handlePastedText={this._handlePastedText}
          hashtag={{
            separator: " ",
            trigger: "#",
          }}
          mention={{
            separator: " ",
            trigger: "@",
            suggestions: this.state.friendList,

            // suggestions: [
            //   { text: "JavaScript", value: "javascript", url: "js" },
            //   { text: "Golang", value: "golang", url: "go" },
            // ],
          }}
          toolbar={{
            // options: [
            //   "inline",
            //   "link",
            //   "list",
            //   "textAlign",
            //   "colorPicker",
            //   "emoji",
            //   "history",
            // ],
            textAlign: { inDropdown: true, className: "hide" },
            remove: { className: "hide" },
            history: { className: "hide" },

            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline"],
            },

            list: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: [],
            },

            blockType: {
              inDropdown: false,
              options: [],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },

            link: {
              inDropdown: this.props.isArticle ? true : false,
              // inDropdown:false,
              // className: this.props.isArticle ? "show" : "hide",
              // className:undefined,
              component: undefined,
              popupClassName: undefined,
              dropdownClassName: undefined,
              showOpenOptionOnHover: true,
              defaultTargetOption: "_self",
              options: ["link"],
              // options: [],
              // inDropdown:true,
              // className:"show"
            },

            colorPicker: {
              className: "hide",
            },
            emoji: {
              className: "show",
            },
            image: {
              className: this.props.isArticle ? "show" : "hide",
              // uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true }
              uploadCallback: uploadImageCallBack,
            },
            // blockType: {
            //   className: "hide",
            // },
            fontSize: {
              className: "hide",
            },
            fontFamily: {
              className: "hide",
            },
            // history: {
            //   className: "hide",
            // },
            // remove: {
            //   className: "hide",
            // },
            embedded: {
              className: "hide",
            },
          }}
        />
      </div>
    );
  }

  addUpdateData = () => {
    console.log("questionDescribed final", this.props.editorValue);
    this.props.textBoxStatusBack();
    const blocksFromHtml = htmlToDraft(this.props.editorValue);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    console.log(contentState);
    console.log(EditorState.createWithContent(contentState));
    this.setState({
      editorState: EditorState.createWithContent(contentState),
    });
  };

  _getLengthOfSelectedText = () => {
    const currentSelection = this.state.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      console.log(currentSelection);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }
          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  _handleBeforeInput = () => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      console.log("you can type max ten characters");

      return "handled";
    }
  };

  _handlePastedText = (pastedText) => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (
      currentContentLength + pastedText.length - selectedTextLength >
      MAX_LENGTH
    ) {
      console.log("you can type max ten characters");

      return "handled";
    }
  };
}

export default withTranslation()(TextEditorHomePost);
