import { combineReducers } from "redux";
import courseReducer from "./courseReducer";
import notificationReducer from "./notificationReducer";
import dashboardReducer from "./dashboardReducer";
import subjectReducer from "./subjectReducer";
import callReducer from "./callReducer";
import mylibraryQuestion from "./MyLibraryQuestionsReducer";
import callReminderReducer from "./callReminderReducer";
import peermenulist from "./PeerMenuListReducer";
import surveyList from "./squareHomeReducer";
import topquestList from "./questionReducer";
import SubjectQuestion from "./SubjectquestionReducer";
import badgesReducer from "./badgesReducer";
import profileReducer from "./profileReducers";
import commonApiReducers from "./commonApiReducers";
import loginDetailsReducer from "./loginDetailsReducer";
import chatReducer from "./chatReducer";
import myRequestReducers from "./myRequestReducers";
import firebaseReducer from "./firebaseReducer";
import postCardReducer from "./PostCardReducer";
import directoryReducer from "./directoryReducer";
import mentorReducer from "./MentorReducer";
import calendarReducer from "./calendarReducer";
// import askHelpReducer from "./askHelpReducer";
export default combineReducers({
  courses: courseReducer,
  notification: notificationReducer,
  recentlyViewedQuestion: dashboardReducer,
  subjects: subjectReducer,
  getGroupList: callReducer,
  dashboard: dashboardReducer,
  myquestions: dashboardReducer,
  mylibraryquestion: mylibraryQuestion,
  myuplibraryquestion: mylibraryQuestion,
  getReminderList: callReminderReducer,
  PeerPageFriendsList: peermenulist,
  surveyList: surveyList,
  topquestList: topquestList,
  SubjectQuestion: SubjectQuestion,
  badgeslist: badgesReducer,
  profileValue: profileReducer,
  commonApi: commonApiReducers,
  loginDetailsApi: loginDetailsReducer,
  chatList: chatReducer,
  myRequestReducers: myRequestReducers,
  firebaseReducer: firebaseReducer,
  postList: postCardReducer,
  directory: directoryReducer,
  mentor: mentorReducer,
  event: calendarReducer,
  // askHelp: askHelpReducer,
});
