import React, { Component } from "react";
// import UsersService from '../../api/UserService.js';
// import SchoolService from '../../api/SchoolService.js';
import Swal from "sweetalert2";
import MaterialTable from "material-table";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import CSVReader from "react-csv-reader";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import { withRouter } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import AuthenticationService from '../../api/AuthenticationService .js';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import ProfileService from "../../api/ProfileService";
import users from "../../img/users.csv";
const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

class UserUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersName: "",
      usersType: "",
      successmessage: null,
      users: [],
      page: "new",
      schools: [],
      school: "",
      // SchoolId:"",
      title: "",
    };

    this.goUsersPage = this.goUsersPage.bind(this);

    this.loadCSVdata = this.loadCSVdata.bind(this);
    this.newUploadFile = this.newUploadFile.bind(this);
    this.saveUsers = this.saveUsers.bind(this);
    this.handleusers = this.handleusers.bind(this);
  }
  handleusers(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  newUploadFile() {
    this.setState({
      page: "new",
      users: [],
    });
  }

  loadCSVdata(data, fileInfo) {
    //alert(data.length)
    console.log("Data========>");
    console.log(data);
    console.log("fileInfo========>");
    console.log(fileInfo);

    var ndata = [];

    for (var i = 0; i < data.length; i++) {
      var obj = data[i];

      // Do Operations

      //console.log(obj)
      ndata.push(obj);
    }

    this.setState({
      page: "list",
      users: ndata,
    });
  }

  saveUsers() {
    // alert("Call Save API :" + this.state.school);
    // this.loader(true);

    // if (this.state.school) {
    //   const userTypeDefine = AuthenticationService.getLoginValue().user.userType;
    //   console.log(userTypeDefine);
    //   console.log("gettin usertype from sesson")
    //   var schoolId = this.state.school;
    //   if (userTypeDefine === 5) {
    //     const userSchool = AuthenticationService.getLoginValue().user.userProfile.schoolId;
    //     schoolId = userSchool;
    //   }
    /*
      else {
        schoolId = this.state.school;
        console.log(this.state.school)
      }*/

    var usersList = [];

    for (var i = 0; i < this.state.users.length; i++) {
      var obj = this.state.users[i];

      var userObj = {};
      userObj["username"] = obj["email"];
      userObj["email"] = obj["email"];
      userObj["firstName"] = obj["first_name"];
      userObj["lastName"] = obj["last_name"];
      userObj["value"] = obj["password"];
      usersList.push(userObj);
      console.log(userObj);
    }

    /*
      var usersList = {
        "userList": this.state.users,
        "schoolId": schoolId
      }*/
    console.log("user list");
    console.log(usersList);
    ProfileService.csvCreateUser(usersList)
      .then((response) => {
        //this.handleResponse(response)
        console.log(response.data);
        if (response.data.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Your CSV List has been processed successfully",
            showConfirmButton: true,
          }).then((result) => {
            //this.props.getList()

            var resultValue = response.data;
            console.log(resultValue);
            this.setState(
              {
                page: "result",
                result: resultValue.data,
              },
              () => {}
            );
            //this.loader(false);
          });
        } else {
          var title = "Oops...";
          var text = "Something went wrong!";

          Swal.fire({
            icon: "error",
            title: title,
            text: text,
          });
          //this.loader(false);
        }
      })
      .catch((response) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  }
  //   else{
  //     Swal.fire({
  //       icon: 'info',
  //       title: 'Select School',
  //       text: 'Please select valid school'
  //     })
  //   }

  // }

  goUsersPage() {
    //  <Link to="/AddUsers"/>
    //  window.open("/AddUsers");
    this.props.history.push(`/users`);
    //  window.location.href = '/AddUsers';
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidMount() {
    this.getAllSchoolListForCsv();
    //const userTypeDefine = AuthenticationService.getLoginValue().user.userType;
    // if (userTypeDefine === 5) {
    //   this.setState(
    //     {
    //       "school": AuthenticationService.getLoginValue().user.userProfile.schoolId
    //     }
    //   )
    // }
  }

  getAllSchoolListForCsv() {
    // SchoolService.getAllSchoolList()
    //   .then(
    //     response => {
    //       console.log("schools retrive")
    //       console.log(response.data.data)
    //       this.setState({ schools: response.data.data })
    //       console.log(this.state.schools)
    //     })
  }

  render() {
    // const userTypeDefine = AuthenticationService.getLoginValue().user.userType;
    // console.log(userTypeDefine);
    console.log("gettin usertype from sesson");
    return (
      <div className="screenInnerGap">
        {/* <Card> */}
        <CardHeader title="USERS UPLOAD - CSV"></CardHeader>
        <CardContent className="boxPage">
          <div style={{ marginBottom: "30px" }}>
            {this.state.page === "list" && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.saveUsers}
                startIcon={<SaveIcon />}
              >
                {" "}
                Save All
              </Button>
            )}
            &nbsp;&nbsp;
            {(this.state.page === "list" || this.state.page === "result") && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.newUploadFile}
                startIcon={<AddIcon />}
              >
                {" "}
                New Upload
              </Button>
            )}
            &nbsp;&nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={this.goUsersPage}
              startIcon={<ArrowBackIcon />}
            >
              {" "}
              Back
            </Button>
            &nbsp;&nbsp;
            {this.state.page === "new" && (
              <Button
                //   style={{marginLeft:"60%"}}
                variant="contained"
                color="primary"
                target="_blank"
                href={users}
                required
                startIcon={<GetAppIcon />}
              >
                {" "}
                Download Sample File
              </Button>
            )}
          </div>

          {this.state.page === "new" && (
            <Card>
              <CardHeader title="Upload CSV - Users Source"></CardHeader>
              <CardContent>
                <CSVReader
                  parserOptions={papaparseOptions}
                  onFileLoaded={(data, fileInfo) =>
                    this.loadCSVdata(data, fileInfo)
                  }
                />
              </CardContent>
            </Card>
          )}
          {/* {(this.state.page === 'list' && userTypeDefine !== 5) && */}
          {/* <Grid container spacing={3}>
                <Grid className="gridform" item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="school-label">School</InputLabel>
                    <Select
                      labelId="school"
                      name="school"
                      value={this.state.school}
                      onChange={this.handleusers}
                      label="Select School"
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {this.state.schools.map((school) => (<MenuItem key={school.schoolId} value={school.schoolId}>{school.schoolName}</MenuItem>))}

                    </Select>
                  </FormControl>
                  <br />
                </Grid>
              </Grid> */}

          {this.state.page === "result" && (
            <Card>
              <CardHeader title="CSV Save Users - Report"></CardHeader>
              <CardContent>
                <p>
                  Total Records :{" "}
                  {this.state.result.total ? this.state.result.total : 0}
                </p>
                <p>
                  Success Records :{" "}
                  {this.state.result.success ? this.state.result.success : 0}
                </p>
                <p>
                  Existing/Duplicate Records :{" "}
                  {this.state.result.existing ? this.state.result.existing : 0}
                </p>
              </CardContent>
            </Card>
          )}
          {this.state.users.length > 0 && this.state.page === "list" && (
            <div>
              <MaterialTable
                style={{ width: "100%" }}
                columns={[
                  {
                    title: "First Name",
                    field: "first_name",
                    cellStyle: {
                      backgroundColor: "#FAFAFA",
                      color: "#000",
                    },
                  },
                  {
                    title: "Last Name",
                    field: "last_name",
                  },
                  {
                    title: "Email",
                    field: "email",
                  },
                  { title: "Password", field: "password" },
                ]}
                data={this.state.users}
                title={this.state.title}
                options={{
                  pageSize: 10,
                  exportButton: true,
                  search: true,
                  sorting: true,
                  headerStyle: {
                    backgroundColor: "#A00030",
                    color: "#FFF",
                  },
                  actionsColumnIndex: -1,
                }}
                onRowClick={(event, rowData) => {
                  console.log(rowData);
                }}
              />
            </div>
          )}
        </CardContent>
        {/* </Card> */}
      </div>
    );
  }
}

export default withRouter(UserUpload);
