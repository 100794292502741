import { Chip, FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";
import BlockUi from "react-block-ui";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Multiselect } from "react-widgets";
import TextEditorQuestion from "../../components/TextEditorQuestion";
import { connect } from "react-redux";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import MessagesFileOption from "./Subjects/MessagesFileOption";
import ForumService from "../../api/ForumService";
import ProfileService from "../../api/ProfileService";
import * as APPCONFIG from "../../config/app-config.js";
import AttachmentIcon from "@material-ui/icons/Attachment";
import SearchTextField from "../../components/SearchTextField";

class NewResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      tagsList: [],
      categoryLists: [],
      questionDescribed: "",
      subjectDescription: "",
      optionTagList: [],
      resourceType: 0,
      descriptionCount: 0,
      selectedCategory: [],
      selectedTagList: [],
      fileTypeState: false,
      file: null,
      blocking: false,
      fileType: "",
      imageFile: [],
      editStatus: false,
    };
  }
  componentDidMount() {
    if (this.props.match.params.id !== "new") {
      this.setState({
        subjectId: this.props.match.params.id,
        editStatus: true,
      });
      this.editResource();
    }
  }
  editResource = () => {
    let Edit = this.props.match.params.id;
    var subjectId = {
      subjectId: Edit,
    };
    ProfileService.editGroupsEventsV2(subjectId)
      .then((response) => {
        let result = response.data.data;
        this.setState({
          title: result.subjectName,
          // selectedTagList: result.tagsValue ,
          questionDescribed: result.subjectDescription,
          imageFile: result.grouporeventFiles,
          fileType: result.fileType,
          subjectDescription: result.subjectDescription,
        });
        this.getOldTagList(result.tagsValue);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onBack() {}
  getOldTagList = async (oldTags) => {
    const filtered =
      oldTags &&
      oldTags.map((data) => {
        return { tagName: data.tagName, tagId: data.tagId };
      });

    await this.setState({
      selectedTagList: filtered ? filtered : [],
    });
    console.log(
      "tagsList this.state.selectedTagList",
      this.state.selectedTagList
    );
  };
  handleForm = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleCategoryMultiSelect = (badges_value) => {
    this.setState({
      category: badges_value,
    });
  };
  handleAreaOfInterestMultiSelect = (data) => {};

  handleTagMultiSelect = (data) => {
    this.setState({
      selectedTagList: data,
    });
  };
  render() {
    const { t } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <div className="backEventorGroup flexDefault">
          <button onClick={this.backToResourcePage}>Back </button>
        </div>

        <div className="askAQuestionCardWrapper">
          <BlockUi
            tag="div"
            blocking={this.state.blocking}
            message={t("Loading, please wait")}
            color="#A00030"
          >
            <div className="askAQuestionInputGroup inputLarge ">
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <label className="">Title * </label>

                <input
                  type="text"
                  name="title"
                  value={this.state.title}
                  maxLength="250"
                  onChange={this.handleForm}
                />
              </Grid>
            </div>
            <Grid container spacing={1}>
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="SYICInputWrapper ">
                  <label className="">{t('Select category *')}</label>
                  <Multiselect
                    style={{ fontColor: 'gray' }}
                    required
                    data={this.state.categoryLists}
                    valueField="id"
                    textField="name"
                    name="selectedCategory"
                    value={this.state.selectedCategory}
                    onChange={(value) => this.handleCategoryMultiSelect(value)}
                  />
                </div>
              </Grid> */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="SYICInputWrapper ">
                  <label className="">{t("Tags *")}</label>
                  {/* <SearchTextField
                    updateTag={this.newTagList}
                    selectedTagList={this.state.selectedTagList}
                    editNeed={this.state.editStatus}
                  /> */}
                  <Multiselect
                    style={{ fontColor: "gray" }}
                    required
                    data={this.props.tagList}
                    valueField="tagId"
                    textField="tagName"
                    name="selectedTagList"
                    value={this.state.selectedTagList}
                    onChange={(value) => this.handleTagMultiSelect(value)}
                  />
                </div>
              </Grid>
              {!this.state.editStatus && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {/* <Grid className="" item xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="outlined" fullWidth>
                  <label className="">Resource Type*</label>
                  <Select
                    labelId="userType"
                    name="resourceType"
                    value={this.state.resourceType}
                    onChange={this.handleUserType}
                    label="User Type"
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={0}>General</MenuItem>
                    <MenuItem value={1}>PlayList</MenuItem>
                    <MenuItem value={2}>TimeLine</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
                  <FormControl variant="outlined" fullWidth>
                    <label className="">Resource Type*</label>
                    <Select
                      labelId="userType"
                      name="resourceType"
                      value={this.state.resourceType}
                      onChange={this.handleUserType}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={0}>General</MenuItem>
                      <MenuItem value={1}>PlayList</MenuItem>
                      <MenuItem value={2}>TimeLine</MenuItem>
                      <MenuItem value={3}>Image</MenuItem>
                      <MenuItem value={4}>Document</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <div className="askAQuestionDescription mt-2">
              <label class="mt-2">{t("Description *")}</label>
              <TextEditorQuestion
                ref={this.myRef}
                editorStateValue={this.editorStateValues}
                editorValue={this.state.questionDescribed}
              />
              <p
                className="boxText"
                style={
                  this.state.descriptionCount > 2500
                    ? { color: "red" }
                    : { color: "green" }
                }
              >
                {" "}
                {2500 - this.state.descriptionCount} {t("characters left")}
              </p>
            </div>
            {this.state.imageFile.length === 0 && !this.state.fileTypeState && (
              <Grid
                container
                spacing={8}
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <Grid item md={5} sm={5} xs={5} lg={5}>
                  <div
                    style={{ cursor: "pointer", color: "#A00030" }}
                    onClick={() => this.keyFile("image")}
                  >
                    <CameraAltIcon /> Image
                  </div>{" "}
                </Grid>
              </Grid>
            )}
            {this.state.fileType === "image" &&
              this.state.imageFile &&
              this.state.imageFile.map((file, index) => (
                <div key={file.fileName}>
                  <Chip
                    icon={<AttachmentIcon />}
                    className="fileChip"
                    label={file.originalFileName}
                    size="small"
                    onDelete={() => this.removeFile(index)}
                    variant="outlined"
                  />
                  <img
                    style={{ margin: "8px", width: "80px", height: "80px" }}
                    src={`${APPCONFIG.API_URL}auth/image/${file.fileName}`}
                  ></img>
                </div>
              ))}
            {this.state.fileTypeState && (
              <div>
                {!this.state.fileImage && (
                  <AiOutlineCloseCircle
                    onClick={this.closeChoosedFileData}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      marginBottom: "8px",
                      width: "20px",
                      height: "20px",
                      color: "#000000",
                      marginTop: "8px",
                    }}
                  />
                )}

                <MessagesFileOption
                  fileTypeKey={this.state.fileTypeKey}
                  fileOption={this.state.fileOption}
                  fileOptionEnable={this.fileOptionEnable}
                  fromQuestionImage={this.getQuestionMediaImage}
                  fromQuestionMedia={this.getQuestionMediaFiles}
                ></MessagesFileOption>
              </div>
            )}
          </BlockUi>
          <div className="askAQuestionBtn mt-4">
            {this.props.match.params.id === "new" ? (
              <button onClick={(e) => this.onFormSubmit(e)}>
                Create Resource
              </button>
            ) : (
              <button onClick={(e) => this.onFormUpdate(e)}>
                Update Resource
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
  newTagList = (value) => {
    this.setState({
      selectedTagList: value,
    });
  };
  removeFile = () => {
    this.setState({
      imageFile: [],
      fileType: "text",
    });
  };
  closeChoosedFileData = () => {
    this.setState({
      fileTypeState: false,
      fileImage: false,
      file: [],
    });
  };
  fileOptionEnable = () => {
    this.setState({
      file: null,
      fileOption: false,
      fileImage: false,
    });
  };

  getQuestionMediaImage = (files, type) => {
    this.setState({
      file: files,
      type: type,
      selectOption: false,
      fileImage: true,
    });
  };
  getQuestionMediaFiles = (files, type) => {
    this.setState({ file: files, type: type });
  };
  keyFile = (key) => {
    this.setState({ fileTypeKey: key, fileTypeState: true, fileType: key });
  };
  onFormSubmit = (e) => {
    if (
      this.state.title === "" ||
      this.state.selectedTagList.length === 0 ||
      // this.state.resourceType === '' ||
      this.state.subjectDescription === ""
    ) {
      toast.warning("Select all the field");
    } else {
      const formData = new FormData();
      var tags = [];
      var data = this.state.selectedTagList;
      // var category = []
      // this.state.selectedCategory.forEach((element) => {
      //   category.push(element.tagId)
      // })
      data.forEach((element) => {
        tags.push(element.tagId);
      });
      const newTagList =
        this.state.selectedTagList &&
        this.state.selectedTagList.map((item) => {
          return { tagName: item.label, tagId: item.value };
        });

      if (this.state.file !== null) {
        formData.append("subjectName", this.state.title);
        formData.append("subjectDescription", this.state.subjectDescription);
        formData.append("isExpiry", "No");
        formData.append("type", "groups");
        formData.append("fileType", "image");
        formData.append("status", "resource");
        formData.append("memberShipType", "free");
        formData.append("tagsId", tags);
        formData.append("categoryId", []);
        formData.append("messgaeFiles", this.state.file[0]);
        formData.append("resourceType", this.state.resourceType);
        formData.append("memberShipType", "free");
        // formData.append(
        //   "newTagList",
        //   newTagList ? JSON.stringify(newTagList) : []
        // );
      } else {
        formData.append("subjectName", this.state.title);
        formData.append("subjectDescription", this.state.subjectDescription);
        formData.append("isExpiry", "NO");
        formData.append("type", "groups");
        formData.append("fileType", "text");
        formData.append("status", "resource");
        formData.append("memberShipType", "free");
        formData.append("tagsId", tags);
        formData.append("categoryId", []);
        formData.append("resourceType", this.state.resourceType);
        // formData.append(
        //   "newTagList",
        //   newTagList ? JSON.stringify(newTagList) : []
        // );
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.setState({
        blocking: true,
      });
      ForumService.admin_resource_save(formData, config)
        .then((response) => {
          if (response.data.status === -1) {
            toast.error("Error in saving");
            this.setState({
              blocking: false,
            });
          } else {
            this.setState({
              blocking: false,
            });
            toast.success("Resource created successfully");
            this.backToResourcePage();
          }
        })
        .catch((error) => {
          toast.error("Error in saving");
          console.log(error);
        });
    }

    //}
  };
  onFormUpdate = (e) => {
    if (
      this.state.title === "" ||
      this.state.selectedTagList.length === 0 ||
      this.state.subjectDescription === ""
    ) {
      toast.warning("Select all the field");
    } else {
      const formData = new FormData();
      var tags = [];
      var data = this.state.selectedTagList;
      const newTagList =
        this.state.selectedTagList &&
        this.state.selectedTagList.map((item) => {
          return { tagName: item.label, tagId: item.value };
        });
      data.forEach((element) => {
        tags.push(element.tagId);
      });

      if (
        this.state.fileType === "image" &&
        this.state.imageFile.length !== 0
      ) {
        formData.append("subjectName", this.state.title);
        formData.append("subjectDescription", this.state.subjectDescription);
        formData.append("isExpiry", "No");
        formData.append("type", "groups");
        formData.append("fileType", "image");
        formData.append("status", "resource");
        formData.append("memberShipType", "free");
        formData.append("tagsId", tags);
        formData.append("categoryId", []);
        formData.append("subjectId", this.state.subjectId);
        formData.append("memberShipType", "free");
        // formData.append(
        //   "newTagList",
        //   newTagList ? JSON.stringify(newTagList) : []
        // );
      } else if (
        this.state.fileType === "image" &&
        this.state.imageFile.length === 0 &&
        this.state.file !== null
      ) {
        formData.append("subjectName", this.state.title);
        formData.append("subjectId", this.state.subjectId);
        formData.append("subjectDescription", this.state.subjectDescription);
        formData.append("isExpiry", "NO");
        formData.append("type", "groups");
        formData.append("fileType", "image");
        formData.append("status", "resource");
        formData.append("memberShipType", "free");
        formData.append("tagsId", tags);
        formData.append("categoryId", []);
        formData.append("messgaeFiles", this.state.file[0]);
        // formData.append(
        //   "newTagList",
        //   newTagList ? JSON.stringify(newTagList) : []
        // );
      } else {
        formData.append("subjectName", this.state.title);
        formData.append("subjectId", this.state.subjectId);
        formData.append("subjectDescription", this.state.subjectDescription);
        formData.append("isExpiry", "NO");
        formData.append("type", "groups");
        formData.append("fileType", "text");
        formData.append("status", "resource");
        formData.append("memberShipType", "free");
        formData.append("tagsId", tags);
        formData.append("categoryId", []);
        // formData.append(
        //   "newTagList",
        //   newTagList ? JSON.stringify(newTagList) : []
        // );
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.setState({
        blocking: true,
      });
      ForumService.admin_update_subject(formData, config)
        .then((response) => {
          if (response.data.status === -1) {
            toast.error("Error in saving");
            this.setState({
              blocking: false,
            });
          } else {
            this.setState({
              blocking: false,
            });
            toast.success("Resource updated successfully");
            this.backToResourcePage();
          }
        })
        .catch((error) => {
          toast.error("Error in saving");
          console.log(error);
        });
    }

    //}
  };
  handleUserType = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  editorStateValues = (values) => {
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        subjectDescription: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        subjectDescription: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  };

  backToResourcePage = () => {
    this.props.history.push(`/${this.props.workspaceType}/learning-hub`);
  };
}
const mapStateToProps = function (state) {
  return {
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    tagList: state.commonApi.taglist.data,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(NewResource)));
