import React, { Component } from "react";
import { Grid, Container } from "@material-ui/core";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

var ansListId = {
  answer: "",
  answerId: null,
};
var ansList = "";
class SelfAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: [],
      frPollAnswer: [],
      frSurveyAnswer: [],
      selectGroupName: "",
      pollQuestion: "",
      inputsQuestion: [1],
      surveyQuestionType: "",
      surveyQuestion: [],
      inputsSurveyAnswer: [],
      answers: [],
    };
    this.removeMoreQuestions = this.removeMoreQuestions.bind(this);
    this.removeSurveyAnswers = this.removeSurveyAnswers.bind(this);
  }
  componentDidMount() {
    let one = {
      questions: [
        {
          question: "How do we walk",
          answers: ["leg", "hand"],
          answerType: "Select",
        },
        {
          question: "Do you want food",
          answer: "Give explain ",
          answerType: "Text",
        },
      ],
    };

    if (this.props.editState && this.props.assessmentListUpdated) {
      const count = [];
      const final = this.props.assessmentListUpdated.map((items, index) => {
        count.push(index);
        return {
          answerType: items.answerType,
          answers:
            items.answerType === "select" ? items.answers || items.answer : [],
          answerLength:
            items.answerType === "select" ? items.answers || items.answer : [],
          question: items.question,
          textTypeAnswer:
            items.answerType === "text" ? items.answers || items.answer : "",
          answerIds: items.answerType === "select" ? items.answerIds : [],
          questionId: items.questionId,
          answerId: items.answerId,
        };
      });
      this.setState({
        inputsQuestion: count.length === 0 ? [1] : count,
        surveyQuestion: final,
      });
      console.log("data", final);
      console.log("data count", count);
    }
  }

  render() {
    const { t } = this.props;
    // console.log("surveyQuestion inputsQuestion", this.state.inputsQuestion);
    return (
      <div className="selfAssessment-form">
        <h4 className="p-3 mt-2 mb-2   heading-h4-v2  ">
          {t("Add your questions and option's")}
        </h4>
        <span
          style={{
            cursor: "pointer",
            marginTop: "20px",
            paddingBottom: "20px",
            float: "right",
            color: "blue",
          }}
          class="socialCardTextColor"
          onClick={this.addMoreQuestions}
        >
          {t("+ Add more questions")}
        </span>

        {this.state.inputsQuestion.length > 0 &&
          this.state.inputsQuestion.map((entry, indexQuestion) => (
            <div className="PTCInputWrapper">
              <Grid container spacing={2}>
                <Grid className="gridform" item xs={12} sm={12}>
                  <textarea
                    id={indexQuestion}
                    key={indexQuestion}
                    style={{
                      width: "100%",
                      minHeight: "38px",
                      borderRadius: "5px",
                      padding: "20px 20px",
                      background: "#dceaee",
                      border: "none",
                    }}
                    type="text"
                    onChange={(e) =>
                      this.handleInputQuestion(e, indexQuestion, "name")
                    }
                    required=""
                    name="pollQuestion"
                    //value={this.state.pollQuestion}
                    value={
                      this.state.surveyQuestion &&
                      this.state.surveyQuestion[indexQuestion] &&
                      this.state.surveyQuestion[indexQuestion].question
                    }
                    placeholder={t("Ask your question")}
                  />

                  <Grid container spacing={2}>
                    <Grid
                      className="gridform"
                      item
                      xs={12}
                      sm={12}
                      lg={6}
                      md={6}
                      style={{ marginBottom: "1rem" }}
                    >
                      <div className="homePostInputWrapper">
                        <select
                          name="surveyQuestionType"
                          id="surveyQuestionType"
                          value={
                            this.state.surveyQuestion &&
                            this.state.surveyQuestion[indexQuestion] &&
                            this.state.surveyQuestion[indexQuestion].answerType
                          }
                          onChange={(e) =>
                            this.handleInputQuestion(e, indexQuestion, "type")
                          }
                        >
                          <option value="">{t("Select")}</option>
                          <option value="text">{t("Text Field Option")}</option>
                          <option value="select">
                            {t("Select Type Option")}
                          </option>
                        </select>
                      </div>
                    </Grid>
                    <Grid
                      className="gridform"
                      item
                      xs={12}
                      sm={12}
                      lg={6}
                      md={6}
                    >
                      {this.state.inputsQuestion.length > 1 && (
                        <span
                          style={{
                            cursor: "pointer",
                            float: "right",
                            color: "red",
                            paddingBottom: "20px",
                          }}
                          class="socialCardTextColor"
                          onClick={() =>
                            this.removeMoreQuestions(
                              entry,
                              indexQuestion,
                              this.state.surveyQuestion[indexQuestion]
                                ?.questionId
                            )
                          }
                        >
                          {" "}
                          - Remove Question{" "}
                        </span>
                      )}
                    </Grid>{" "}
                    {this.state.surveyQuestion &&
                      this.state.surveyQuestion[indexQuestion] &&
                      this.state.surveyQuestion[indexQuestion].answerType ==
                        "text" && (
                        <Grid
                          className="gridform"
                          item
                          xs={12}
                          sm={12}
                          lg={12}
                          md={12}
                        >
                          <textarea
                            style={{
                              width: "100%",
                              minHeight: "38px",
                              borderRadius: "5px",
                              padding: "20px 20px",
                              // background: "#dceaee",
                              background: "lightgray",
                              border: "none",
                            }}
                            type="text"
                            onChange={(e) =>
                              this.handleInputQuestion(
                                e,
                                indexQuestion,
                                "textTypeAnswer"
                              )
                            }
                            required=""
                            name="textTypeAnswer"
                            value={
                              this.state.surveyQuestion &&
                              this.state.surveyQuestion[indexQuestion] &&
                              this.state.surveyQuestion[indexQuestion]
                                .textTypeAnswer
                            }
                            placeholder={t("Add your placeholder text here")}
                          />
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                {/* selectQuestion type */}
                <Grid container spacing={2} style={{ padding: "10px" }}>
                  <Grid item xs={12} sm={12}>
                    {this.state.surveyQuestion &&
                      this.state.surveyQuestion[indexQuestion] &&
                      this.state.surveyQuestion[indexQuestion].answerType ===
                        "select" && (
                        <>
                          {this.state.surveyQuestion.length > 0 &&
                            this.state.surveyQuestion[indexQuestion]
                              .answerLength &&
                            this.state.surveyQuestion[
                              indexQuestion
                            ].answerLength.map((entry, index) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingBlock: "1rem",
                                  width: "100%",
                                }}
                                key={index}
                              >
                                <span key={index} class="socialCardTextColor">
                                  Option - {index + 1}
                                </span>

                                <Grid container spacing={2} key={entry.index}>
                                  <Grid
                                    className="gridform"
                                    item
                                    xs={12}
                                    sm={11}
                                  >
                                    <input
                                      key={index}
                                      className="cpollInput"
                                      fullWidth
                                      type="text"
                                      onChange={(e) =>
                                        this.handleInputAns(
                                          e,
                                          index,
                                          "answerList",
                                          indexQuestion,
                                          this.state.surveyQuestion

                                          // this.state.surveyQuestion[
                                          //   indexQuestion
                                          // ] &&
                                          // this.state.surveyQuestion[
                                          //     indexQuestion
                                          //   ]?.answerIds[index] &&
                                          // this.state.surveyQuestion[
                                          //   indexQuestion
                                          // ]?.answerIds[index]?.answerId
                                        )
                                      }
                                      style={{
                                        width: "100%",
                                        height: "4rem",
                                        border: "none",
                                        backgroundColor: "lightgray",
                                        padding: "20px",
                                      }}
                                      required=""
                                      name="frPollAnswer"
                                      placeholder={t("Add your option")}
                                      value={
                                        this.state.surveyQuestion &&
                                        this.state.surveyQuestion[
                                          indexQuestion
                                        ] &&
                                        this.state.surveyQuestion[indexQuestion]
                                          .answers[index]
                                      }
                                    />
                                  </Grid>

                                  <Grid
                                    className="gridform"
                                    item
                                    xs={12}
                                    sm={1}
                                  >
                                    <span
                                      style={{
                                        display: "block",
                                        cursor: "pointer",
                                        // padding: "20px",
                                        fontSize: "35px",
                                        color: "red",
                                      }}
                                      class="socialCardTextColor"
                                      onClick={() =>
                                        this.removeSurveyAnswers(
                                          indexQuestion,
                                          index
                                        )
                                      }
                                    >
                                      {" "}
                                      -{" "}
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                            ))}
                          <span
                            style={{
                              cursor: "pointer",
                              marginTop: "20px",
                              paddingBottom: "20px",
                              color: "blue",
                            }}
                            class="socialCardTextColor"
                            onClick={() => this.addSurveyAnswers(indexQuestion)}
                          >
                            {t("+ Add more option")}
                          </span>
                        </>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ))}
      </div>
    );
  }

  addSurveyAnswers = (questionIndex) => {
    this.setState((prevState) => {
      const updatedSurveyQuestions = [...prevState.surveyQuestion];
      const updatedAnswerLength = [
        ...updatedSurveyQuestions[questionIndex].answerLength,
      ];
      updatedAnswerLength.push(1);
      updatedSurveyQuestions[questionIndex] = {
        ...updatedSurveyQuestions[questionIndex],
        answerLength: updatedAnswerLength,
      };
      return {
        surveyQuestion: updatedSurveyQuestions,
      };
    });

    ansListId = {
      answer: "",
      answerId: null,
    };
    ansList = "";
  };

  async removeSurveyAnswers(indexQuestion, index) {
    ansList = "";

    ansListId = {
      answer: "",
      answerId: null,
    };
    if (this.state.surveyQuestion[indexQuestion].answerLength) {
      var val = this.state.surveyQuestion[indexQuestion].answerLength;
      var ans = this.state.surveyQuestion[indexQuestion].answers;
      var ansId = this.state.surveyQuestion[indexQuestion].answerIds;
      val.splice(index, 1);
      ans.splice(index, 1);
      ansId.splice(index, 1);
      this.setState({
        // frSurveyAnswer: val,
      });
      this.props.surveyQuestion(this.state.surveyQuestion);
    }
  }

  async removeMoreQuestions(record, index, questionId) {
    ansList = "";

    ansListId = {
      answer: "",
      answerId: null,
    };
    var inpu = this.state.inputsQuestion;
    if (this.state.inputsQuestion.length > 1) {
      inpu.splice(index, 1);
      if (this.state.surveyQuestion[index]) {
        var val = this.state.surveyQuestion;
        val.splice(index, 1);
        this.setState({
          surveyQuestion: val,
        });
      }
      await this.setState({
        inputsQuestion: inpu,
      });
      this.props.surveyQuestion(val);
    }
    if (this.props.editState) {
      console.log("questionId", questionId);
      this.props.removedQuestionId(questionId);
    }
  }
  addMoreQuestions = (e) => {
    ansList = "";

    ansListId = {
      answer: "",
      answerId: null,
    };
    var input = this.state.inputsQuestion;
    if (this.state.inputsQuestion.length === 0) {
      input.push(1);
      this.setState({
        inputsQuestion: input,
      });
    } else if (this.state.surveyQuestion.length !== 0) {
      if (
        this.state.surveyQuestion[this.state.inputsQuestion.length - 1] !==
        undefined
      ) {
        var addMoreEnable =
          this.state.surveyQuestion[this.state.inputsQuestion.length - 1]
            .answerType;
        if (
          this.state.surveyQuestion[this.state.inputsQuestion.length - 1]
            .question === ""
        ) {
          Swal.fire({
            icon: "warning",
            text: "Please fill your question",
            timer: 3000,
          });
        } else if (addMoreEnable === undefined || addMoreEnable === "") {
          Swal.fire({
            icon: "warning",
            text: "Please select your answer type",
            timer: 3000,
          });
        } else if (
          addMoreEnable === "text" &&
          this.state.surveyQuestion[this.state.inputsQuestion.length - 1]
            .textTypeAnswer === ""
        ) {
          Swal.fire({
            icon: "warning",
            text: "Please Add your comments",
            timer: 3000,
          });
        } else if (
          addMoreEnable === "select" &&
          this.state.surveyQuestion[this.state.inputsQuestion?.length - 1]
            .answers.length === 0
        ) {
          Swal.fire({
            icon: "warning",
            text: "Please Add your answer poll",
            timer: 3000,
          });
        } else {
          input.push(1);
          this.setState({
            inputsQuestion: input,
          });
        }
      } else {
        // input.push(1);
        // this.setState({
        //   inputsQuestion: input,
        // });
        Swal.fire({
          icon: "warning",
          text: "Please Add your questions",
          timer: 3000,
        });
      }
      console.log("check addMoreEnable", addMoreEnable);
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please Add your Questions",
        timer: 3000,
      });
    }
  };

  handleInputQuestion = (event, index, type, indexQuestion) => {
    event.preventDefault();
    event.stopPropagation();

    var newAnswers = this.state.surveyQuestion;
    console.log("newAnswers", newAnswers);

    if (newAnswers[index]) {
      //Remove empty fields

      console.log("newAnswers inside");

      if (type == "name") {
        var obj = newAnswers[index];
        obj.question = event.target.value;
        newAnswers[index] = obj;
      } else if (type == "type") {
        var obj = newAnswers[index];
        obj.answerType = event.target.value;
        obj.answerLength = event.target.value === "select" ? [1] : [];
        obj.answers = [];
        obj.textTypeAnswer = "";
        newAnswers[index] = obj;
      } else if (type == "textTypeAnswer") {
        var obj = newAnswers[index];
        obj.textTypeAnswer = event.target.value;
        newAnswers[index] = obj;
      }
    } else {
      if (type == "name") {
        var obj = newAnswers[index];
        var obj = {
          question: event.target.value,
          answerType: "",
          textTypeAnswer: "",
          answers: [],
          answerLength: [],
          answerIds: [],
        };
        newAnswers[index] = obj;
      } else if (type == "type") {
        var obj = newAnswers[index];
        var obj = {
          question: "",
          answerType: event.target.value,
          textTypeAnswer: "",
          answers: [],
          answerLength: [],
          answerIds: [],
        };
        newAnswers[index] = obj;
      } else if (type == "textTypeAnswer") {
        var obj = newAnswers[index];
        var obj = {
          question: "",
          textTypeAnswer: event.target.value,
          answers: [],
          answerType: "",
          answerLength: [],
          answerIds: [],
        };
        newAnswers[index] = obj;
      }
    }

    this.setState({
      surveyQuestion: newAnswers,
    });

    this.props.surveyQuestion(newAnswers);
  };

  handleInputAns = (event, index, type, indexQuestion) => {
    var answerId =
      this.state.surveyQuestion[indexQuestion]?.answerIds[index]?.answerId;

    event.preventDefault();
    event.stopPropagation();

    var qList = this.state.surveyQuestion;
    var newAnswers = this.state.surveyQuestion[indexQuestion]?.answers;
    console.log("newAnswers", newAnswers);
    console.log("newAnswers", qList);
    console.log("newAnswers", answerId);
    if (newAnswers && newAnswers[index]) {
      console.log("newAnswers if", qList);
      if (type == "answerList") {
        ansListId = {
          answer: event.target.value,
          answerId: answerId ? answerId : null,
        };
        ansList = event.target.value;
        var checkId = qList[indexQuestion].answerIds;
        var check = qList[indexQuestion].answers;

        if (event.target.value.length === 0) {
          qList[indexQuestion].answerIds[index] = {
            answer: "",
            answerId: answerId ? answerId : null,
          };
          qList[indexQuestion].answers[index] = " ";

          // check.length = 0;
        } else if (check[index]) {
          checkId[index] = ansListId;
          check[index] = ansList;
        } else {
          checkId.answerIds.push(ansListId);
          check.answers.push(ansList);
          ansListId = {
            answer: "",
            answerId: answerId ? answerId : null,
          };
          ansList = "";
        }
      }
    } else {
      console.log("newAnswers else", qList);
      if (type === "answerList") {
        let obj = {
          answer: event.target.value,
          answerId: null,
        };
        console.log("newAnswers alist", qList[indexQuestion].answerIds);
        ansList = event.target.value;
        qList[indexQuestion].answers.push(ansList);
        qList[indexQuestion].answerIds.push(obj);
      }
      console.log("assessment else", qList);
    }

    this.setState({
      surveyQuestion: qList,
    });

    this.props.surveyQuestion(qList);

    //  this.setState({answers:answer_id.target.value})
  };
}

export default withTranslation()(withRouter(SelfAssessment));
