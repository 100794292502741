import React, { useEffect, useRef, useState } from "react";
import dummyimage from "../img/default_avatar.jpg";
import { API_URL } from "../config/app-config";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getAllMembersDetails,
  getGroupRequestList,
} from "../redux/actions/subjectActions";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import MyYuukke from "../api/MyYuukke";
import { toast } from "react-toastify";

const UserCard = (props) => {
  const divRef = useRef();
  const { t } = props;
  const loginDetails = useSelector(
    (state) => state.loginDetailsApi.loginDetails
  );
  const { groupRequestList, groupRequestSeeMore, totalRequest } = useSelector(
    (state) => state.subjects
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [requestedList, setRequestedList] = useState([]);
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(0);
  useEffect(() => {
    let data = {
      page: 0,
      subjectId: props.subjectId,
    };
    dispatch(getGroupRequestList(data, false));
    setPageNo(0);
  }, []);

  useEffect(() => {
    setRequestedList(groupRequestList);
    setTotalRecords(totalRequest);
  }, [groupRequestList]);

  const getRequestList = () => {
    let data = {
      page: pageNo + 1,
      subjectId: props.subjectId,
    };
    setPageNo(pageNo + 1);
    dispatch(getGroupRequestList(data, true));
  };
  const userAction = (status, id) => {
    let data = {
      subjectId: props.subjectId,
      requestUserId: id,
      type: status,
    };
    MyYuukke.requestOrAcceptOrReject(data)
      .then((response) => {
        setTotalRecords(totalRecords - 1);
        toast.success(
          props.t(
            status === "accept"
              ? "User added successfully ..."
              : "User ignored successfully"
          )
        );
        const list = groupRequestList.filter((item) => item.userId !== id);
        setRequestedList(list);
        if (status === "accept") {
          dispatch(getAllMembersDetails(props.subjectId));
        }
        if (props.forumGroup) {
          let data = {
            page: 0,
            subjectId: props.subjectId,
          };
          dispatch(getGroupRequestList(data, false));
          setPageNo(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {totalRecords !== 0 && (
        <h5 className="mt-2">
          {t("Requested List")} -{" "}
          <span class="badge badge-info">{totalRecords}</span>
        </h5>
      )}
      <InfiniteScroll
        dataLength={groupRequestList?.length ? groupRequestList?.length : 0}
        next={getRequestList}
        hasMore={groupRequestSeeMore}
        scrollableTarget="media_div"
        direction="down"
        //   scrollThreshold={0.95}
      >
        <div
          className="aboutGroupWrapper d-flex flex-column"
          id="media_div"
          style={{
            minHeight: "auto",
            maxHeight: "40.5rem",
            overflow: "auto",
            overflowX: "hidden",
            cursor: "auto",
            gap: "2rem",
          }}
          ref={divRef}
        >
          {Array.isArray(requestedList) &&
            requestedList.map((item, index) => (
              <div
                className="group-help-card"
                key={index}
                // onClick={() =>
                //   this.gotoDis(request.jobId, request.userSaw)
                // }
              >
                <div class=" ask-help-imgCard mb-auto">
                  <img
                    onClick={() => props.showFriendsProfile(item.userId)}
                    className="rounded-circle"
                    style={{
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    src={
                      item.userId
                        ? API_URL +
                          "api/getUserPhoto/" +
                          item.userId +
                          "/" +
                          item.userName
                        : dummyimage
                    }
                    alt="user"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = dummyimage;
                    }}
                  />
                </div>
                <div className="ml-2 flex-grow-1 d-flex flex-wrap flex-column">
                  <div className="d-flex flex-wrap flex-column">
                    <h6 className="text-capitalize m-0">{item.userName}</h6>
                    <p style={{ fontSize: "12px" }} className="text-secondary">
                      {item.professionValue}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-wrap  mt-2"
                    style={{ gap: "1rem" }}
                  >
                    <button
                      className="float-right survey-btn"
                      onClick={() => userAction("accept", item.userId)}
                    >
                      {"Accept"}
                    </button>
                    <button
                      className="float-right"
                      onClick={() => userAction("reject", item.userId)}
                    >
                      {"Ignore"}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          {requestedList.length === 0 && <p>{t("No Request present ..")}</p>}
        </div>
      </InfiniteScroll>
    </>
  );
};

export default withTranslation()(withRouter(UserCard));
