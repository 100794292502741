class LoadService {
  redirect(history, path) {
    sessionStorage.setItem('APP_REDIRECT_PATH', path)
    history.push('/redirectpage')
  }

  load(history) {
    let path = sessionStorage.getItem('APP_REDIRECT_PATH')
    if (path) {
      history.push(path)
      sessionStorage.removeItem('APP_REDIRECT_PATH')
    } else {
      history.push('/type/home')
    }
  }
}

export default new LoadService()
