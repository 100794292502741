import React, { Component } from "react";
import AuthService from "../../../session/AuthService";
import FriendsFinder from "../../../api/FriendsFinder";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { withRouter } from "react-router";
import ForumService from "../../../api/ForumService";
import banner from "../../../img/Yuukke_Cover.jpg";
import ProfileService from "../../../api/ProfileService";
import MyYuukke from "../../../api/MyYuukke";
import { connect } from "react-redux";

import {
  getAllSubjectList,
  getAllMembersDetails,
} from "../../../redux/actions/subjectActions";
import { ToastContainer, toast } from "react-toastify";
import { BsCameraVideo } from "react-icons/bs";
import * as APPCONFIG from "../../../config/app-config.js";

let myProfileDialog = {
  backgroundColor: "#ffffff",
  color: "#000",
  width: "50%",
  minHeight: "150px",
  padding: "0px",
  borderRadius: "10px",
};
class GroupMembersAndFriendsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      base64String: "",
      base64StringState: false,
      mutualFriendsCount: 0,
      subjectslist: "",
      coverPhoto: "",
      userPhoto: "",
      findFriends: "",
      exitPopup: false,
      showFriendsProfile: false,
    };
  }

  componentDidMount() {}

  render() {
    const { t } = this.props;
    const { userId, playlistName, fileType, subjectId, fileName, playlistId } =
      this.props;

    return (
      <div className="peopleCardWrapperGroup">
        <img
          className="bannerImg-playList"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = banner;
          }}
          src={
            fileName === null
              ? banner
              : `${APPCONFIG.API_URL}auth/image/${fileName}`
          }
          alt={"pic"}
        ></img>
        {/* <div className="w3-list-row">
          <div className="w3-col w3-container w3-green">
            <div>
              <img
                className="userImg"
                style={{ cursor: "pointer" }}
                onClick={()=>this.showFriendsProfile(userId)}
                src={
                    fileName === null
                    ? dummyimage
                    : `data:image;base64,${fileName}`
                }
                alt={"name"}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = dummyimage;
                  }}
              />
            </div>
          </div>
        </div> */}
        <div className="peopleCardInfor-play">
          <h2>{playlistName}</h2>
          <button onClick={() => this.viewPlayList(subjectId, playlistId)}>
            <BsCameraVideo
              style={{ marginRight: "5px", width: "17px", height: "17px" }}
            />
            {t("Watch Now")}
          </button>
        </div>
      </div>
    );
  }
  viewPlayList = (subjectId, playlistId) => {
    this.props.viewPlayListPage(subjectId, playlistId);
  };
}

const mapStateToProps = function (state) {
  return {
    subjectsList: state.commonApi.subjectslist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    getAllMembersDetails: (id) => dispatch(getAllMembersDetails(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(GroupMembersAndFriendsList)));
