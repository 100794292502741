import React from "react";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { withTranslation } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ChooseFileComponent(props) {
  const { t } = props;
  console.log("trans");
  console.log(t);
  console.log("acceptedType", props.acceptedType);
  return (
    <div className="">
      <div className="modal-content">
        <div className="modal-body p-0 ">
          <Dropzone
            accept=".mp4"
            minSize={0}
            maxSize={262144000}
            // maxFiles={1}
            multiple={false}
            onDrop={props.onUploadSuccess}
            onDropRejected={(e) => {
              toast.warn("File not supported", e);
            }}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragReject,
              rejectedFiles,
            }) => (
              <section>
                <div
                  {...getRootProps()}
                  className="card page-background text-center file-uploader"
                >
                  <div className="card-body">
                    <div className="uploader">
                      <input {...getInputProps()} />
                      <div className="upload-icon">
                        <CloudUploadIcon fontSize="inherit" />
                      </div>
                      <div className="text-large-weighted mb-3">
                        {t(
                          "Choose a mp4 file here"
                        )}
                        <p>
                          Note: Choose file below 250 mb else it will be auto
                          removed from the list
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(ChooseFileComponent);
