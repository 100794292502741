import React, { Component } from "react";
import * as APPCONFIG from "../../config/app-config.js";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import AuthService from "../../session/AuthService";
import moment from "moment";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import MyYuukke from "../../api/MyYuukke";
import Swal from "sweetalert2";
import dummyimage from "../../img/banner.jpg";
import banner from "../../img/banner.jpg";
import { withRouter } from "react-router";
import { AiFillMessage } from "react-icons/ai";
import { connect } from "react-redux";
class ChallengeCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 0,
      topic_name: "",
      menuStatewithDelete: false,
    };

    this.movesubjectidtosubjects = this.movesubjectidtosubjects.bind(this);
    this.deletePost = this.deletePost.bind(this);
    this.editGroup = this.editGroup.bind(this);
    this.getMessageFiles = this.getMessageFiles.bind(this);
    this.getTagList = this.getTagList.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.getMessageFiles(this.props.grouporeventFiles, this.props.fileType);
    this.getTagList(this.props.tagListId);
  }

  componentWillReceiveProps(nextProps) {
    this.getMessageFiles(nextProps.grouporeventFiles, nextProps.fileType);
    this.getTagList(nextProps.tagListId);
  }

  render() {
    const { t } = this.props;
    const {
      subjectId,
      subjectName,
      questionsCount,
      createdDate,
      expiryDate,
      memberShipPrice,
      memberShipType,
      membersCount,
      grouporeventFiles,
      subjectDescription,
      fileType,
      displayName,
      userId,
      status,
      tagListId,
      startDate,
    } = this.props;
    return (
      <div
        className="peopleCardWrapperGroup"
        onClick={() =>
          this.movesubjectidtosubjects(
            subjectId,
            subjectName,
            grouporeventFiles,
            fileType,
            subjectDescription,
            memberShipPrice,
            memberShipType,
            expiryDate,
            createdDate,
            displayName,
            userId,
            status,
            tagListId,
            startDate
          )
        }
        style={{ cursor: "pointer" }}
      >
        {this.state.fileData &&
          this.state.fileData.slice(0, 1).map((photo) => (
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = dummyimage;
              }}
              className="bannerGroupImg"
              src={photo}
              alt="name"
            ></img>
          ))}

        <div className="groupCardInfor">
          <h2>{subjectName}</h2>
          {
            <p style={{ fontSize: "10px", color: "#b3b3b3" }}>
              <p>{this.getSearchedTagValue(this.props.tagsValue)}</p>
            </p>
          }
          {/* <p style={{padding:"8px"}}>
            {this.state.questionTags
              ? this.state.questionTags.substring(0, 20)
              : "-"}{" "}
            {this.state.questionTags &&
              this.state.questionTags.length > 19 &&
              "..."}
          </p> */}
          {/* {membersCount <= 1 ? ( */}
          <p style={{ marginBottom: "12px" }}>{membersCount} participants</p>
          {/* ) : (
            <p style={{ marginBottom: "12px" }}>{membersCount} Members</p>
          )} */}
        </div>
      </div>
    );
  }
  getSearchedTagValue = (tags) => {
    const searchKeyword = this.props.searchData || "";

    if (Array.isArray(tags) && tags.length > 0) {
      const result = tags.filter((val) =>
        val.tagName.toLowerCase().startsWith(searchKeyword.toUpperCase())
      );
      console.log("Tag searchvalue", result);

      if (result.length > 0) {
        return result[0]?.tagName;
      } else {
        return tags[0].tagName;
      }
    }
    return "-------";
  };
  getTagList(tagList) {
    console.log("tagList");
    console.log(tagList);
    console.log(this.props.tagList);
    var subjectName = new Array();
    if (tagList !== undefined && tagList.length > 0) {
      for (let i = 0; i < tagList.length; i++) {
        for (let j = 0; j < this.props.tagList.data.length; j++) {
          if (tagList[i].tagId == this.props.tagList.data[j].tagId) {
            subjectName.push(this.props.tagList.data[j].tagName);
          }
        }
        var subjects = subjectName.join(",");

        this.setState({ questionTags: subjects });
      }
    }
  }
  editGroup() {
    this.props.history.push(`/updateGroup/${this.props.subjectId}`);
  }

  deletePost(event, subId) {
    event.preventDefault();
    event.stopPropagation();

    Swal.fire({
      title: "Are you sure to delete this event?",
      text: "",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        var deleteSubId = {
          subjectId: subId,
        };
        MyYuukke.deleteSubjectById(deleteSubId)
          .then((response) => {
            this.props.getGroupsorEventsList();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  movesubjectidtosubjects(id, subjectName) {
    var gName = subjectName.trim();
    gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
    this.props.history.push(
      `/${this.props.workspaceName}/challengeDescription/${id}/${gName}`
    );

    // this.props.gotoGroups(
    //   subjectId,
    //   true,
    //   subjectName,
    //   "fgroup",
    //   grouporeventFiles,
    //   fileType,
    //   subjectDescription,
    //   memberShipPrice,
    //   memberShipType,
    //   expiryDate,
    //   createdDate,
    //   displayName,
    //   userId,
    //   status,
    //   tagListId,
    //   startDate
    // );
  }

  async getMessageFiles(files, type) {
    if (type == "image") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
        }
        await this.setState({ fileData: fileList });
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "text") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(dummyimage);
        }
        await this.setState({ fileData: fileList });
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "video") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/video/${files[i].fileName}`);
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "pdf") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "file") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "pdf") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else {
      let fileList = [];
      if (files.length > 0) {
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    }
  }
}

const mapStateToProps = function (state) {
  return {
    tagList: state.commonApi.taglist,
    workspaceName: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(ChallengeCard)));

// export default withTranslation()(withRouter(GroupsCard));
