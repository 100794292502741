import { act } from "react-dom/test-utils";
import {
  GET_ALL_COMMON_SCHOOL,
  GET_ALL_COMMON_FRIENDS_LIST,
  GET_ALL_BLOCKED_USER_LIST,
  GET_ALL_COMMON_SUBJECTS,
  GET_LOGOUT_DATA,
  GET_ALL_COMMON_LANGUAGE,
  GET_ALL_COMMON_TAGS,
  GET_ALL_COMMON_GRADES,
  GET_ALL_COMMON_CURRICULUM_TRACK,
  GET_INDUSTRY_LIST,
  GET_REGION_LIST,
  GET_AREA_OF_INTEREST_LIST,
  GET_ALL_COMMON_PROFESSION,
  GET_ALL_COMMON_EXPECTATION_HELP,
  GET_ALL_COMMON_EXPECTATION_INTEREST,
  GET_ALL_COMMON_ALL_PROOF,
  NAVIGATION_TYPE,
  GET_USER_POINTS,
  GET_ALL_USER_COMMUNITY_LIST,
  GET_ALL_COMMON_CHAT_FRIENDS_LIST,
  HOME_IMAGE_STATUS,
  WORK_SPACE_TYPE,
  GET_USER_WORKSPACE_LIST,
  GET_ADMIN_CATEGORY_LIST,
  GET_ALL_COUNTRY_PHONE_CODE,
  WORK_SPACE_LOGIN,
  WORK_SPACE_WRONG_URL,
  GET_TOUR_STATUS,
  TRIGGER_TOUR_POPUP,
  SAVE_TOUR_STATUS,
  AFTER_TRIGGER_POPUP,
  ANNOUNCEMENT_DETAILS,
  GET_TAG_USER_LIST,
  GET_WORKSPACE_OPTIONS,
  HASH_TAG_LIST,
  NEED_RENDER_COMPONENT,
  GET_REQUIREMENT_STATUS,
  NEED_PHOTO_VERIFICATION,
  GET_SUB_SPECIALIZATION,
  GET_SUB_INDUSTRY,
  GET_FRIENDS_LIST_BASED_WORKSPACE,
} from "../types";

const initialState = {
  subjectslist: [],
  languagelist: [],
  taglist: [],
  gradelist: [],
  curriculumtracklist: [],
  schoollist: [],
  friendslist: [],
  industrylist: [],
  regionlist: [],
  areaofinterestlist: [],
  professionList: [],
  expectationInterestList: [],
  expectationHelpList: [],
  joinedSubjectList: [],
  chatFriendslist: [],
  blockedUserList: [],
  homeImageState: false,
  workspaceType: "default",
  userWorkSpaceList: [],
  workspaceListHeader: "default",
  role: 0,
  adminCategoryList: [],
  countryName: [],
  workSpaceLogin: false,
  loading: true,
  wrongUrlStatus: false,
  pathUrl: "url",
  tourApiCalled: false,
  completedTours: 0,
  popupLocation: "",
  toursStages: {
    header: {
      headerFinish: true,
      totalHeaderCount: 0,
      headerSkipped: 0,
    },
    profile: {
      profileFinish: true,
      totalProfileCount: 0,
      profileSkipped: 0,
    },
    connection: {
      connectionFinish: true,
      totalConnectionCount: 0,
      connectionSkipped: 0,
    },
    community: {
      communityFinish: true,
      communitySkipped: 0,
      totalCommunityCount: 0,
    },
    post: {
      postFinish: true,
      totalPostCount: 0,
      postSkipped: 0,
    },
    askHelp: {
      askHelpFinish: true,
      totalAskHelpCount: 0,
      askHelpSkipped: 0,
    },
    learningHub: {
      learningHubFinish: true,
      totalLearningHubCount: 0,
      learningHubSkipped: 0,
    },
  },
  popupStatus: false,
  profilePopupStatus: false,
  communityPopupStatus: false,
  connectionPopupStatus: false,
  homePopupStatus: false,
  askHelpPopupStatus: false,
  headerPopupStatus: false,
  learningPopupStatus: false,
  announcementList: [],
  tagUserList: [],
  adminCountryList: [],
  getWorkSpaceOption: {},
  hashTagList: [],
  renderComponent: false,
  requireMentMenu: {},
  needToPopUp: false,
  subIndustry: [],
  subSpecialization: [],
  workspaceFriendList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NEED_PHOTO_VERIFICATION:
      console.log("NEED_PHOTO_VERIFICATION", action.payload);
      return {
        ...state,
        needToPopUp: action.payload,
      };
    case GET_ALL_COMMON_SUBJECTS:
      console.log("GET_ALL_SUBJECTS");
      console.log(action.payload);
      return {
        ...state,
        subjectslist: action.payload,
        loading: false,
      };
    case GET_ALL_USER_COMMUNITY_LIST:
      console.log("GET_ALL_USER_COMMUNITY_LIST");
      console.log(action.payload);
      return {
        ...state,
        joinedSubjectList: action.payload.data,
        loading: false,
      };
    case GET_ALL_COMMON_LANGUAGE:
      console.log("GET_ALL_COMMON_LANGUAGE");
      console.log(action.payload);
      return {
        ...state,
        languagelist: action.payload,
        loading: false,
      };
    case GET_ALL_COMMON_ALL_PROOF:
      console.log("GET_ALL_COMMON_ALL_PROOF");
      console.log(action.payload);
      return {
        ...state,
        proofList: action.payload,
        loading: false,
      };
    case GET_ALL_COMMON_TAGS:
      console.log("GET_ALL_COMMON_TAGS");
      console.log(action.payload);
      return {
        ...state,
        taglist: action.payload,
        loading: false,
      };
    case GET_ALL_COMMON_GRADES:
      console.log("GET_ALL_COMMON_GRADES");
      console.log(action.payload);
      return {
        ...state,
        gradelist: action.payload,
        loading: false,
      };
    case GET_ALL_COMMON_CURRICULUM_TRACK:
      console.log("GET_ALL_COMMON_CURRICULUM_TRACK");
      console.log(action.payload);
      return {
        ...state,
        curriculumtracklist: action.payload,
        loading: false,
      };
    case GET_ALL_COMMON_SCHOOL:
      console.log("GET_ALL_COMMON_SCHOOL");
      console.log(action.payload);
      return {
        ...state,
        schoollist: action.payload,
        loading: false,
      };
    case GET_ALL_COMMON_FRIENDS_LIST:
      console.log("GET_ALL_COMMON_FRIENDS_LIST");
      console.log("Get All Common Friends List", action.payload);
      return {
        ...state,
        friendslist: action.payload,
        loading: false,
      };

    case GET_ALL_BLOCKED_USER_LIST:
      console.log("GET_ALL_BLOCKED_USER_LIST");
      console.log("action.payload");
      return {
        ...state,
        blockedUserList: action.payload,
        loading: false,
      };

    case GET_ALL_COMMON_CHAT_FRIENDS_LIST:
      return {
        ...state,
        chatFriendslist: action.payload,
        loading: false,
      };
    case GET_INDUSTRY_LIST:
      console.log("GET_INDUSTRY_LIST");
      console.log(action.payload);
      return {
        ...state,
        industrylist: action.payload,
        loading: false,
      };
    case GET_REGION_LIST:
      console.log("GET_REGION_LIST");
      console.log(action.payload);
      return {
        ...state,
        regionlist: action.payload,
        loading: false,
      };
    case GET_AREA_OF_INTEREST_LIST:
      console.log("GET_AREA_OF_INTEREST_LIST");
      console.log(action.payload);
      return {
        ...state,
        areaofinterestlist: action.payload,
        loading: false,
      };
    case GET_ALL_COMMON_PROFESSION:
      console.log("GET_ALL_COMMON_PROFESSION");
      console.log(action.payload);
      return {
        ...state,
        professionList: action.payload,
        loading: false,
      };
    case GET_ALL_COMMON_EXPECTATION_HELP:
      console.log("GET_ALL_COMMON_EXPECTATION_HELP");
      console.log(action.payload);
      return {
        ...state,
        expectationHelpList: action.payload,
        loading: false,
      };
    case GET_ALL_COMMON_EXPECTATION_INTEREST:
      console.log("GET_ALL_COMMON_EXPECTATION_INTEREST");
      console.log(action.payload);
      return {
        ...state,
        expectationInterestList: action.payload,
        loading: false,
      };

    case NAVIGATION_TYPE:
      console.log("NAVIGATION_TYPE");
      console.log(action.payload);
      return {
        ...state,
        navigationType: action.payload,
      };
    case HOME_IMAGE_STATUS:
      console.log("HOME_IMAGE_STATUS");
      console.log(action.payload);
      return {
        ...state,
        homeImageState: action.payload,
      };

    case GET_USER_POINTS:
      console.log("GET_USER_POINTS");
      console.log(action.payload);
      return {
        ...state,
        totalUserPoints: action.payload,
      };
    case WORK_SPACE_TYPE:
      console.log("WORK_SPACE_TYPE");
      console.log("WORK_SPACE_TYPE", action.payload);
      var name = action.payload.type?.trim();
      name = name?.replace(/[, ]+/g, "-").trim();
      return {
        ...state,
        workspaceListHeader: action.payload.type,
        workspaceType: name,
        role: action.payload.role,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");
      console.log(initialState.subjectslist);
      return {
        ...initialState,
      };
    case GET_USER_WORKSPACE_LIST:
      console.log("GET_USER_WORKSPACE_LIST");
      console.log("action.payload");
      return {
        ...state,
        userWorkSpaceList: action.payload,
        loading: false,
      };
    case GET_ADMIN_CATEGORY_LIST:
      console.log("GET_ADMIN_CATEGORY_LIST");
      console.log(action.payload);
      return {
        ...state,
        adminCategoryList: action.payload,
        loading: false,
      };
    case GET_ALL_COUNTRY_PHONE_CODE:
      console.log("GET_ALL_COUNTRY_PHONE_CODE");
      console.log(action.payload);
      return {
        ...state,
        countryName: action.payload,
        // loading: false,
      };

    case WORK_SPACE_LOGIN:
      console.log("WORK_SPACE_LOGIN");
      console.log(action.payload);
      return {
        ...state,
        workSpaceLogin: action.payload,
      };

    case WORK_SPACE_WRONG_URL:
      console.log("WORK_SPACE_WRONG_URL", action.payload);
      return {
        ...state,
        wrongUrlStatus: action.payload,
        pathUrl: action.pathUrl,
      };

    case GET_TOUR_STATUS:
      console.log("GET_TOUR_STATUS", action.payload);
      return {
        ...state,
        toursStages: action.payload ? action.payload : state.toursStages,
        tourApiCalled: true,
      };

    case TRIGGER_TOUR_POPUP:
      console.log("TRIGGER_TOUR_POPUP", action.payload);
      return {
        ...state,
        popupLocation: action.payload,
        popupStatus: !state.popupStatus,
      };
    case AFTER_TRIGGER_POPUP:
      console.log("AFTER_TRIGGER_POPUP", action.payload);
      switch (action.payload) {
        case "header":
          return {
            ...state,
            headerPopupStatus: true,
          };
        case "home":
          return {
            ...state,
            homePopupStatus: true,
          };
        case "connection":
          return {
            ...state,
            connectionPopupStatus: true,
          };
        case "profile":
          return {
            ...state,
            profilePopupStatus: true,
          };
        case "askHelp":
          return {
            ...state,
            askHelpPopupStatus: true,
          };
        case "community":
          return {
            ...state,
            communityPopupStatus: true,
          };
        case "learning":
          return {
            ...state,
            learningPopupStatus: true,
          };
        default:
          return state;
      }

    case SAVE_TOUR_STATUS:
      console.log("SAVE_TOUR_STATUS", action.payload);
      return {
        ...state,
        toursStages: action.payload ? action.payload : state.toursStages,
      };
    case ANNOUNCEMENT_DETAILS:
      console.log("ANNOUNCEMENT_DETAILS", action.payload);
      return {
        ...state,
        announcementList: action.payload ? action.payload : [],
      };
    case GET_TAG_USER_LIST:
      console.log("GET_TAG_USER_LIST", action.payload);
      return {
        ...state,
        tagUserList: action.payload ? action.payload : [],
      };

    case GET_WORKSPACE_OPTIONS:
      console.log("GET_WORKSPACE_OPTIONS", action.payload);
      return {
        ...state,
        getWorkSpaceOption: action.payload ? action.payload : {},
      };
    case HASH_TAG_LIST:
      console.log("HASH_TAG_LIST", action.payload);
      return {
        ...state,
        hashTagList: action.payload ? action.payload : {},
      };
    case NEED_RENDER_COMPONENT:
      console.log("NEED_RENDER_COMPONENT", action.payload);
      return {
        ...state,
        renderComponent: action.payload,
      };
    case GET_REQUIREMENT_STATUS:
      console.log("GET_REQUIREMENT_STATUS", action.payload);
      if (action.needUpdate) {
        const old = state.requireMentMenu;
        old.sellerExist = true;
        console.log("update-seller", old);
        return {
          ...state,
          requireMentMenu: old ? old : state.requireMentMenu,
        };
      } else {
        return {
          ...state,
          requireMentMenu: action.payload,
        };
      }
    case GET_SUB_SPECIALIZATION:
      return {
        ...state,
        subSpecialization: action.payload ? action.payload : [],
      };
    case GET_SUB_INDUSTRY:
      return {
        ...state,
        subIndustry: action.payload ? action.payload : [],
      };

    case GET_FRIENDS_LIST_BASED_WORKSPACE:
      return {
        ...state,
        workspaceFriendList: action.payload ? action.payload : [],
      };
    default:
      return state;
  }
}
