import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import AuthService from "../../session/AuthService";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ChallengeContainer from "../LearningApp/ChallengeContainer";

let global_language = "";
var Gobalsurvey = [];
var notificationhit = false;
var notificationList = [];
var notificationId = 0;

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      notificationData: [],
      announcementData: [],
      showAnnoucment: false,
      surveyBoxState: false,
      showList: true,
      FeedbackList: [],
      recentlyAddedBadgesList: [],
      FriendsList: [],
      userKnowledgepoint: 0,
      globalLanguage: AuthService.getCommonLanguages(),
      showPage: false,
      dataKey: "",
      creategroupview: false,
      searchValue: "",
      searchMyValue: "",
      searchLearningValue: "",
      searchChallengeValue: "",
    };

    this.searchChallenge = this.searchChallenge.bind(this);
  }

  componentDidMount() {
    if (!(window.innerWidth <= 768 || window.innerWidth == 768)) {
      console.log("scroll");
      window.scrollTo({
        top: 0,
        behavior: "auto",
        //  'smooth'
      });
    }
    if (this.props.openTab == "myGroups") {
      this.setState({
        tabIndex: 0,
      });
    } else if (this.props.openTab == "groups") {
      this.setState({
        tabIndex: 1,
      });
    } else if (this.props.openTab == "learningApp") {
      this.setState({
        tabIndex: 2,
      });
    } else if (this.props.openTab == "challenges") {
      this.setState({
        tabIndex: 3,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.openTab !== prevProps.openTab) {
      if (this.props.openTab == "myGroups") {
        this.setState({
          tabIndex: 0,
        });
      } else if (this.props.openTab == "groups") {
        this.setState({
          tabIndex: 1,
        });
      } else if (this.props.openTab == "learningApp") {
        this.setState({
          tabIndex: 2,
        });
      } else if (this.props.openTab == "challenges") {
        this.setState({
          tabIndex: 3,
        });
      }
    }
  }

  render() {
    const { t } = this.props;

    const { tabIndex } = this.state;

    return (
      <div>
        {" "}
        {!this.props.viewMyGroups && (
          <div
          //  style={{ marginLeft: "20px" }}
          >
            <div className="homeMainWrapper">
              <>
                <>
                  <Grid container spacing={2}>
                    <Grid item lg={9} md={8} sm={12} xs={12}>
                      <h3 className="listHomeTitle group-count">
                        {t("Career Labs")} -
                        <span className="badge badge-info">
                          {this.props.totalResource}
                        </span>
                      </h3>
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ paddingBottom: "12px" }}
                    >
                      {(this.props.userRole === 1 ||
                        this.props.userRole === 2) && (
                        <button
                          style={{ float: "right" }}
                          onClick={this.createCareer}
                        >
                          {t("Create Career")}
                        </button>
                      )}
                    </Grid>
                  </Grid>

                  <div className="spaceFiletCard newPostCardWrapper">
                    <Grid container spacing={1}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          id="subjects"
                          label=""
                          style={{ width: "100%", height: "100%" }}
                          variant="outlined"
                          onChange={(e) => this.searchChallenge(e.target.value)}
                          placeholder={t("Search Career Labs...")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div className="badgeMainWrapper">
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="announcementWrapperOuter">
                          <Grid style={{ paddingTop: 12 }}>
                            <ChallengeContainer
                              tabIndex={2}
                              gotoGroups={this.idfromGroupCard}
                              searchValue={this.state.searchChallengeValue.trim()}
                            ></ChallengeContainer>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </>
              </>
            </div>
          </div>
        )}
      </div>
    );
  }
  createCareer = () => {
    let update = "new";
    this.props.history.push(`/${this.props.workspaceType}/newCareer/${update}`);
  };

  searchChallenge(value) {
    this.setState({ searchChallengeValue: value });
  }
}

// export default (withTranslation()(Home));

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    userRole: state.loginDetailsApi.loginDetails.userWorkSpace?.role,
    totalResource: state.subjects.totalResource,
  };
};

export default connect(mapStateToProps)(withTranslation()(withRouter(Home)));
