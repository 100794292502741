import { GET_FORUM_QUESTION_LIST,GET_FORUM_TAG_LIST,GET_QUESTION_LIST,GET_ASK_QUESTION_LIST,GET_FORUM_ANSWER__LIST,GET_FORUM_SEARCH_QUESTION_LIST } from "../types";
import { UPDATE_QUESTION_LIST } from "../types";
import { DELETE_QUESTION_LIST } from "../types";
import { QUESTION_LOADER } from "../types";
import { GET_LOGOUT_DATA } from "../types";
const initialState = {
  list: [],
  uplist: [],
  updatedKeyname: "loading",
  loading: true,
  totalItems: 0,
  formQuestions:[],
  formQuestions_totalItems:0,
  formAnswer:[],
  formAnswer_des:"",
  formAnswer_totalItems:0,
  forumQuestion_tags:[],
  formSearchQuestions:[],
  formSearchQuestions_totalItems:0,
  formTagList:[],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_QUESTION_LIST:
      console.log("GET_QUESTION_LIST");
      console.log(action.payload.topQuestList);
      if (
        action.payload.updatedKey == "normal" ||
        action.payload.updatedKey == "updated"
      ) {
        if (
          state.updatedKeyname == "search" ||
          action.payload.updatedKey == "updated"
        ) {
          return {
            ...state,
            list: action.payload.topQuestList,
            totalItems: action.payload.totalItems,
            updatedKeyname: action.payload.updatedKey,
            loading: false,
          };
        } else {
          return {
            ...state,
            list: state.list.concat(action.payload.topQuestList),
            totalItems: action.payload.totalItems,
            updatedKeyname: action.payload.updatedKey,
            loading: false,
          };
        }
      } else if (action.payload.updatedKey == "search") {
        return {
          ...state,
          list: action.payload.topQuestList,
          totalItems: action.payload.totalItems,
          updatedKeyname: action.payload.updatedKey,
          loading: false,
        };
      }
      case GET_FORUM_QUESTION_LIST:
       
      console.log("GET_FORUM_QUESTION_LIST",action.payload);
      console.log(action.payload.question_list);
      return {
        ...state,
        formQuestions: action.payload.questions,
        formQuestions_totalItems: action.payload.totalPages,
        loading: false,
      };
      case GET_FORUM_ANSWER__LIST:
       
        console.log("GET_FORUM_ANSWER__LIST",action.payload);
        console.log(action.payload.answer_list);
        return {
          ...state,
          formAnswer: action.payload.questions,
          formAnswer_des: action.payload,
          formAnswer_totalItems: action.payload.totalPages,
          // forumQuestion_tags:action.payload.parentQuestionDescription,
          loading: false,
        };
      case GET_ASK_QUESTION_LIST:
       
        console.log("GET_ASK_QUESTION_LIST",action.payload.topQuestList);
        console.log(action.payload.topQuestList);
        return {
          ...state,
          list: action.payload.topQuestList,
          totalItems: action.payload.totalItems,
          updatedKeyname: action.payload.updatedKey,
          loading: false,
        };
  
    case UPDATE_QUESTION_LIST:
      console.log("UPDATE_QUESTION_LIST");
      console.log(action.payload.topQuestList);
      return {
        ...state,
        list: action.payload.topQuestList,
        totalItems: action.payload.totalItems,
        updatedKeyname: action.payload.updatedKey,
        loading: false,
      };
    case DELETE_QUESTION_LIST:
      console.log("DELETE_QUESTION_LIST");
      console.log(action.payload);
      return {
        ...state,
        list: action.payload,
        totalItems: state.totalItems - 1,
      };
    case QUESTION_LOADER:
      console.log("QUESTION_LOADER");
      console.log(action.payload);
      return {
        ...state,
        loading: action.payload,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");
      console.log(action.payload);
      return {
        ...initialState,
      };
      case GET_FORUM_SEARCH_QUESTION_LIST:
       
        console.log("GET_FORUM_SEARCH_QUESTION_LIST",action.payload);
         return {
          ...state,
          formSearchQuestions: action.payload.questions,
          formSearchQuestions_totalItems: action.payload.totalPages,
          loading: false,
        };
        case GET_FORUM_TAG_LIST:
       
          console.log("GET_FORUM_TAG_LIST",action.payload);
           return {
            ...state,
            formTagList: action.payload.forumTags,
          
          };
    default:
      return state;
  }
}
