import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";
import AuthService from "../session/AuthService";
import { connect } from "react-redux";
import {
  getNavigationType,
  needPhotVerification,
} from "../redux/actions/commonApiActions";
import { withRouter } from "react-router";
import Mentor from "../images/Mentor-Banner.jpg";
import { giveUserAction } from "../utils";
import Swal from "sweetalert2";

class MentorRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      app_friendlist: [],
      searchKey: "",
      friends: [],
    };
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="aboutGroupWrapper d-flex flex-column">
          <div className="seller-container">
            <img src={Mentor} alt="seller" />
            <h4>{t("Mentor Program")}</h4>
          </div>
          <div
            className="p-4 d-flex flex-column align-items-center"
            style={{ gap: "1rem" }}
          >
            <p className="text-secondary">
              {t(
                "Having a mentor serves as the missing piece of the entrepreneurial success puzzle. Women in Business face unique challenges especially when they attempt to enter male-dominated industries."
              )}
            </p>
            <p className="text-secondary">
              {t(
                "We at Yuukke through our Mentor Program connect Women in Business with seasoned mentors to help you with issues in everything from funding to marketing to providing feedback to perfect your business plan, discuss roadblocks, point you in the right direction and guide you in scaling your business."
              )}
            </p>
            <p className="text-secondary mr-auto">
              {t(
                "Some of the key benefits of our Mentoring Program include , Address your entrepreneurial challenges with expert guidance"
              )}
            </p>
            <p className="text-secondary">
              {t(
                "Gain Fresh ideas and Perspectives Feel confident in your decision making as you scale up Gain the Knowledge and Experience from experts who get the grind and lift each other up"
              )}
            </p>

            <div style={{ height: "7rem", marginTop: "3rem" }}>
              <div class="indicator">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <button onClick={() => this.gotoMentorRegPage()}>
              {t("Register Here")}
            </button>
          </div>

          {/* <div className="aboutGroupWrapper" >
     
          {
                  <iframe
                    src="https://yuukke.com/merchant/"
                    frameborder="0"
                    style={{
                      overflow: "scroll",
                      height: "91vh",
                      width: "100%",
                    }}
                    height="100%"
                    width="100%"
                  />
                }
        </div> */}
        </div>
      </>
    );
  }

  gotoMentorRegPage = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      if (!this.state.mentorRegPage) {
        Swal.fire({
          title: this.props.t("You want to enroll as a mentor"),
          icon: "warning",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: this.props.t("Yes"),
          cancelButtonText: this.props.t("No"),
          reverseButtons: false,
        }).then((result) => {
          if (result.value) {
            this.props.history.push(
              `/${this.props.workspaceType}/mentor-update/${"mentor-request"}`
            );
          }
        });
      }
    }
  };
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(MentorRegistration)));
