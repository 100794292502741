import React, { Component } from "react";
import PhotoIcon from "@material-ui/icons/Photo";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DescriptionIcon from "@material-ui/icons/Description";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { ToastContainer, toast } from "react-toastify";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
// import './mysocial.css'
// import './mysocialprofile.css'
// import './yuukkeTutoring.css'
//import PostService from '../../api/PostService'

//import i18n from '../../i18n';
//import AuthService from "../../session/AuthService";

export default class QuestionFileOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUrl: null,
      type: null,
      mediaPost: "",
      teaching: [],
      tags: [],
      question: "",
      removeStatus: true,
      addTagsState: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  componentDidMount() {}
  //   componentWillReceiveProps(nextProps) {

  //     if(nextProps.fileOption){

  //       this.removeFile()
  //     }
  //     if(nextProps.editObject){
  //       console.log("console questionFile")
  //       console.log(nextProps.editObject.questionFile)
  //       console.log(nextProps.editObject.type)

  //     }
  //     if(this.state.removeStatus){
  //       if(nextProps.editObject.type){
  //         console.log("wuestion File")
  //         console.log(nextProps.editObject.type)
  //      //   this.setState({ ansFileType: nextProps.ansFileType })
  //         this.getQuestionBase64String(nextProps.editObject.questionFile, nextProps.editObject.type);
  //       }
  //     }
  //  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fileOption !== prevProps.fileOption) {
      if (this.props.fileOption) {
        this.removeFile();
      }
    }

    if (this.props.editObject !== prevProps.editObject) {
      if (this.props.editObject.type === "text") {
        console.log("inside text");
        this.setState({ ansFileType: this.props.editObject });
        this.setState({
          name: "",
          type: null,
          removeStatus: false,
          file: null,
          fileUrl: "",
        });
      } else {
        console.log("inside else");
        this.setState({ ansFileType: this.props.editObject });
        this.getQuestionBase64String(
          this.props.editObject.questionFile,
          this.props.editObject.type
        );
      }
    }
  }

  render() {
    const { t } = this.props;
    const { type, fileUrl } = this.state;
    return (
      <div>
        {type && type === "video/mp4" ? (
          <div className="previewWrapper">
            <a className="removeLink" onClick={() => this.removeFile()}>
              <u>Remove</u>
            </a>
            <br />
            <video controls>
              <source src={fileUrl} type={type} />
            </video>
          </div>
        ) : type === "image/png" || type === "image/jpeg" ? (
          <div className="previewWrapper">
            <a className="removeLink" onClick={() => this.removeFile()}>
              <u>Remove</u>
            </a>
            <br />
            <img src={fileUrl} />
          </div>
        ) : type === "audio/mpeg" ? (
          <div className="previewWrapper">
            <a className="removeLink" onClick={() => this.removeFile()}>
              <u>Remove</u>
            </a>
            <br />
            <video controls>
              <source src={fileUrl} />
            </video>
          </div>
        ) : type === "application/pdf" ? (
          <div className="previewWrapper">
            <a className="removeLink" onClick={() => this.removeFile()}>
              <u>Remove</u>
            </a>
            <br />
            <div>
              <InsertDriveFileIcon style={{ fontSize: "50px" }} />
            </div>
            <p style={{ margin: "20px" }}>{this.state.name}</p>
          </div>
        ) : (
          <div className="formPostMoreOption flexDefault mblTabFlex">
            {/*<MusicNoteIcon style={{ cursor: "pointer" }} />
                          <SubscriptionsIcon style={{ cursor: "pointer" }} />
                          {/* <LocationOnIcon style={{ cursor: "pointer" }} /> *
                          <DescriptionIcon style={{ cursor: "pointer" }} />
                          {/* <MoreHorizIcon style={{ cursor: "pointer" }} /> */}
            <input
              type="file"
              accept=".jpg,.jpeg,.png,"
              value={this.state.files}
              name="file"
              onChange={(e) => this.handleChange(e)}
            />

            <div className="flexDefault mblTabFlex"></div>
          </div>
        )}
      </div>
    );
  }
  getQuestionBase64String(postFile, type) {
    let getBase64String = {
      resourceString: postFile,
    };
  }
  async removeFile() {
    console.log("while clicking remove");
    console.log(this.state.removeStatus);
    await this.setState({
      name: "",
      type: null,
      removeStatus: false,
      file: null,
      fileUrl: "",
    });
    console.log(this.state.removeStatus);
    this.props.fileOptionEnable();
  }

  handleChange(event) {
    this.setState({ removeStatus: false });
    let files = event.target.files[0];
    this.setState(
      {
        name: event.target.files[0].name,
        file: files,
        fileUrl: URL.createObjectURL(event.target.files[0]),
        type: event.target.files[0].type,
      },
      () => {
        this.onFormSubmit();
      }
    );
  }

  onFormSubmit() {
    let files = this.state.file;
    var type = "file";
    if (this.checkImageURL(this.state.file.name)) {
      type = "image";
    } else if (this.checkVideoURL(this.state.file.name)) {
      type = "video";
    } else if (this.checkAudioURL(this.state.file.name)) {
      type = "audio";
    } else if (this.checkPDFURL(this.state.file.name)) {
      type = "pdf";
    }
    this.fileUpload(files, type);
  }

  checkImageURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  checkVideoURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(mp4|webm|ogg)$/) != null;
  }

  checkAudioURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(wav|mp3)$/) != null;
  }

  checkPDFURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(application|pdf)$/) != null;
  }

  fileUpload(file, type) {
    if (file.size <= 5000000) {
      this.props.fromQuestionMedia(file, type);
    } else {
      toast.warn("File size should not be more than 5mb");
    }
  }
}
