import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AuthService from "../../session/AuthService";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { connect } from "react-redux";
import LearningAppCard from "./LearningAppCard";
import {
  getResourceList,
  searchByGroupsAndChallenges,
} from "../../redux/actions/subjectActions";

// var typeQuestionTop = "unanswered";
// var tpage = 1;
// var subjectId = 0;

class LearningAppList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFieldValue: "",
      tagDatas: [],
      allSubjects: [],
      popularPageNo: 0,
      namePageNo: 0,
      newPageNo: 0,
      noMore: false,
      popularState: false,
      nameState: false,
      newState: false,
      activePage: 1,
      tagDatasState: false,
      questionUi: false,
      blocking: false,
      searchPageNo: 0,
      searchOptionSeeMore: false,
      searchDataValue: "",
      pageNo: 0,
    };

    // this.pageNo = this.pageNo.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    // this.props.searchByGroupsAndChallenges(0, "resource", "");

    // if (this.props.tagDatas.length > 0) {
    //   this.setState({ allSubjects: this.props.tagDatas });
    //   this.setState({ tagDatasState: true });
    //   this.getNameTopics(this.props.tagDatas);
    //   if (this.props.searchValue != undefined) {
    //     this.searchSubjects(this.props.searchValue);
    //   }
    // }
    // if (this.props.subId && this.props.redirecttype && !this.state.subjectId) {
    //   this.idfromSubjectCard(this.props.subId, this.props.subId);
    // }
    // if (this.props.searchEventValue != undefined) {
    //   this.searchEvents(this.props.searchEventValue);
    // }
  }

  componentDidUpdate(prevProps, nextProps) {
    // if (
    //   this.props.searchValue != undefined &&
    //   prevProps.searchValue != this.props.searchValue
    // ) {
    //   this.searchSubjects(this.props.searchValue);
    // }
    // if (prevProps.searchValue !== this.props.searchValue) {
    //   if (this.props.searchValue != undefined) {
    //     this.searchSubjects(this.props.searchValue);
    //   }
    // }
    // if (prevProps.tagDatas !== this.props.tagDatas) {
    //   if (this.props.page > 0) {
    //     //alert("hi")
    //     var oldSubject = this.state.allSubjects;
    //     console.log("old subject>>", this.state.allSubjects);
    //     var newSubject = oldSubject.concat(this.props.tagDatas);
    //     console.log("new  subject>>", newSubject);
    //     this.state.allSubjects = newSubject;
    //     //  this.setState({ allSubjects :  newSubject });
    //     console.log("new state>>", this.state.allSubjects);
    //     this.getNameTopics(this.state.allSubjects);
    //   } else {
    //     this.setState({ allSubjects: this.props.tagDatas });
    //     this.getNameTopics(this.props.tagDatas);
    //   }
    //   //this.setState({ allSubjects: this.props.tagDatas });
    //   // this.setState({ tagDatas: this.props.tagDatas });
    //   this.setState({ tagDatasState: true });
    //   //this.getNameTopics(this.props.tagDatas);
    // }
    // if (prevProps.searchEventValue !== this.props.searchEventValue) {
    //   this.searchEvents(this.props.searchEventValue);
    // }
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <BlockUi
          tag="div"
          blocking={this.props.subLoading}
          message={t("Loading, please wait ...")}
          color="#60c2e7"
        >
          <div className="chatWrapperMyEvent">
            <Grid container spacing={2}>
              {this.props.resourceList &&
                this.props.resourceList.map((subject, index) => (
                  <Grid item xs={12} sm={12} md={3} lg={3} key={index}>
                    <LearningAppCard
                      subjectId={subject.subjectId}
                      subjectName={subject.subjectName}
                      fileData={subject.grouporeventFiles}
                      tagsValue={subject.tagsValue}
                      status={subject.status}
                    />
                  </Grid>
                ))}
            </Grid>
            {this.props.searchValue !== "" &&
              this.props.resourceList?.length === 0 && (
                <div className="no-data">
                  <p style={{ textAlign: "center" }}>
                    {t("No More Results Available")}
                  </p>
                </div>
              )}

            {this.props.resourceList?.length !== 0 &&
              !this.props.subLoading &&
              this.props.resourceSeeMore && (
                <div
                  style={{
                    margin: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: "4rem",
                  }}
                >
                  <button onClick={this.searchOptionSeeMore}>
                    {t("See more ")}
                  </button>
                </div>
              )}
            {this.props.resourceList?.length === 0 &&
              !this.props.subLoading && (
                <div
                  style={{
                    margin: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p>{t("Learning Hub is empty")}</p>
                </div>
              )}
          </div>
        </BlockUi>
      </div>
    );
  }

  searchOptionSeeMore = () => {
    let page = this.state.searchPageNo + 1;
    let data = {
      page: page,
      searchKey: this.props.searchValue,
    };
    this.props.getResourceList(data, true);
    // this.props.searchByGroupsAndChallenges(
    //   page,
    //   "resource",
    //   this.state.searchDataValue
    // );

    this.setState({
      searchPageNo: page,
    });
  };
}

const mapStateToProps = function (state) {
  return {
    choosetagList: state.commonApi.taglist,
    resourceList: state.subjects.resourceList,
    resourceSeeMore: state.subjects.resourceSeeMore,
    subLoading: state.subjects.subLoading,
    numberOfElements: state.subjects.numberOfElements,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    getResourceList: (data, pagination) =>
      dispatch(getResourceList(data, pagination)),
    searchByGroupsAndChallenges: (page, groupOrChallenge, searchKey) =>
      dispatch(searchByGroupsAndChallenges(page, groupOrChallenge, searchKey)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(LearningAppList)));
