import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Card, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const AssessmentForm = ({ questions = [], updateAssessment }) => {
  const [assessment, setAssessment] = useState([]);
  const checkAssessment = (questionId) => {
    const check = assessment.some((item) => item.questionId === questionId);
    return check;
  };
  const findAndUpdate = (answerId, type, data, questionId) => {
    const list = assessment.map((item) => {
      if (item.questionId === questionId) {
        if (type === "select") {
          item.answerId = answerId;
        } else {
          item.answer = data;
        }
      }
      return item;
    });
    setAssessment([...list]);
    console.log("list1", list);
  };
  const updateDetails = (answerId, questionId, data, type, index) => {
    if (checkAssessment(questionId)) {
      findAndUpdate(answerId, type, data, questionId);
    } else {
      let obj;
      if (type === "select") {
        obj = {
          answerId: answerId,
          questionId: questionId,
          answerType: type,
        };
      } else {
        obj = {
          answerId: answerId,
          questionId: questionId,
          answerType: type,
          answer: data,
        };
      }
      setAssessment((prevAssessment) => [...prevAssessment, obj]);
    }
  };
  const radioButton = (answerId, index) => {
    const check = assessment.some((item) => item.answerId === answerId);
    return check;
  };
  const updateAnswer = (e) => {
    e.preventDefault();
    if (questions.length !== assessment.length) {
      toast.warning("Fill all the fields");
    } else {
      updateAssessment(assessment);
    }
  };
  return (
    <Modal show={true} onHide={() => {}}>
      <Modal.Header className="font-weight-bold">
        <p>Assessment Form</p>
      </Modal.Header>
      <Modal.Body>
        <Form id="profileForm">
          <div className="card p-4 m-3">
            {questions.map((item, index) =>
              item.answerType === "select" ? (
                <div className="mb-2">
                  <div className="d-flex font-weight-bold">
                    <p className="model-dot">
                      {index + 1} . {item.question}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column align-items-start mb-2 mt-2 ml-4"
                    style={{ gap: "1rem" }}
                  >
                    {item.answerIds.map((data) => (
                      <div
                        className="d-flex"
                        style={{ gap: "1rem", cursor: "pointer" }}
                        onClick={() =>
                          updateDetails(
                            data.answerId,
                            item.questionId,
                            data.answerId,
                            item.answerType,
                            index
                          )
                        }
                      >
                        <input
                          className="radio-btn"
                          key={data.answerId}
                          type="radio"
                          checked={radioButton(data.answerId, index)}
                          value={data.answerId}
                        />{" "}
                        <p>{data.answer}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="mb-2">
                  <div className="d-flex font-weight-bold ">
                    <p className="model-dot mb-2">
                      {index + 1} . {item.question}
                    </p>
                  </div>
                  <div className="d-flex flex-column align-items-start ml-4">
                    <input
                      style={{
                        caretColor: "black",
                        height: "3rem",
                        border: "1px solid #8080805e",
                      }}
                      className="input-text-input text-dark"
                      type="text"
                      required={true}
                      placeholder={item.question}
                      onChange={(e) =>
                        updateDetails(
                          item.answerId,
                          item.questionId,
                          e.target.value,
                          item.answerType,
                          index
                        )
                      }
                    />
                    {/* <TextField
                      fullWidth
                      margin="normal"
                      variant="filled"
                      placeholder={item.question}
                      required
                      onChange={(e) =>
                        updateDetails(
                          item.answerId,
                          item.questionId,
                          e.target.value,
                          item.answerType,
                          index
                        )
                      }
                    /> */}
                  </div>
                </div>
              )
            )}
            <button onClick={(e) => updateAnswer(e)} className="mt-3">
              Save
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AssessmentForm;
