import React, { useState } from "react";
import BlockUi from "react-block-ui";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import MyYuukke from "../../../api/MyYuukke";
import { useDispatch } from "react-redux";
import { getUserSlots } from "../../../redux/actions/calendarAction";

const BankDetails = ({ onBack, userSlots }) => {
  const [state, setState] = useState({
    bankId: userSlots?.accountNumber ?? "",
  });
  const dispatch = useDispatch();
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const updateDetails = (e) => {
    e.preventDefault();
    let data = {
      accountNumber: state.bankId,
    };
    MyYuukke.updateDetails(data)
      .then((res) => {
        dispatch(getUserSlots());
        toast.success("Updated successfully");
        onBack("bank", true);
      })
      .catch((e) => {
        toast.error("Something went wrong !");
      });
  };
  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={state.loader}
    >
      <h4
        className="p-3 mt-2 mb-2   heading-h4-v2"
        style={{ borderBottom: "1px solid #80808063" }}
      >
        <IoArrowBackCircleOutline
          onClick={() => onBack()}
          className="h-auto  mr-3 svg-icons"
          style={{
            width: "2rem",
          }}
        />
        Bank Account Details
      </h4>
      <form
        onSubmit={updateDetails}
        className="card askAQuestionInputGroup cal-grid  p-4"
      >
        <section>
          <label className="label-v2 mt-3">
            {userSlots?.timeZone === "Asia/Kolkata" ? "UPI ID" : "PAYPAL ID"}
          </label>
          <input
            style={{ height: "50px" }}
            type="text"
            // type={userSlots?.timeZone === "Asia/Kolkata" ? "number" : "text"}
            name="bankId"
            placeholder=""
            value={state.bankId}
            onChange={(e) => updateState("bankId", e.target.value)}
            required
          />
        </section>

        <button className="w-100 font-weight-bold mt-3" type="submit">
          Update Details
        </button>
      </form>
    </BlockUi>
  );
};

export default BankDetails;
