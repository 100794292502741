import React, { useEffect, useMemo, useState } from "react";
import FaceBook from "../../../images/SocialMediaGif/Facebook.png";
import Phone from "../../../images/SocialMediaGif/telephone.png";
import Logo from "../../../images/SocialMediaGif/home_yuukke.png";
import Wave from "../../../images/SocialMediaGif/wave.png";
import { FaPhoneSquare, FaRegUser } from "react-icons/fa";
import Select from "react-select";
import { MdArrowBackIos } from "react-icons/md";
import OtpInput from "./OtpInput";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountryName } from "../../../redux/actions/commonApiActions";
import GoogleLogin from "./GoogleLogin";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  keyCloakLoader,
  loginErrorUpdate,
  updateKeyCloakToken,
  mobileNumberRegister,
  registerErrorUpdate,
  socialKeyClockLoginAction,
  getRefreshTokenDetails,
  getUserDetailsByToken,
} from "../../../redux/actions/loginDetailsActions";
import Swal from "sweetalert2";
import ErrorUser from "../ErrorUser";
import { withRouter } from "react-router-dom";
import {
  CLIENT_ID,
  GOOGLE_CLIENT_ID,
  KEY_CLOAK_URL,
} from "../../../config/keycloak-config";
import Email from "../../../images/SocialMediaGif/mail.png";
import MailRegister from "./MailRegister";
import { toast } from "react-toastify";
import LoginService from "../../../api/LoginService";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import MeetingExpiry from "./MeetingExpiry";
import { getLocalStorage } from "../../../utils";
import Notfound from "../../../images/icons/page-not-found.png";
import { language } from "../../../sampleData/Options";

const AuthContainer = (props) => {
  const countryList = useSelector((state) => state.commonApi.countryName);
  const {
    loginLoader,
    loginError,
    userLoggedIn,
    loginUserError,
    loginDetails,
    registerErrorStatus,
  } = useSelector((state) => state.loginDetailsApi);
  const details = {
    name: "",
    country: "",
    phoneNumber: "",
    menu: 0,
    countryList: [],
    otp: "",
    signInStatus: "",
    onResentOtp: false,
    email: null,
    language: { label: "English", value: "English" },
  };
  const [state, setState] = useState(details);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(keyCloakLoader(false));
    dispatch(getAllCountryName());
    //Check access token present or not
    const token = getLocalStorage("R_TOKEN");
    if (!userLoggedIn && token) {
      const details = {
        token: token,
        client_id: CLIENT_ID,
      };
      dispatch(getUserDetailsByToken(details));
    }
  }, []);
  useEffect(() => {
    if (userLoggedIn) {
      props.history.push(
        `/${
          loginDetails?.defaultWorkSpace
            ? loginDetails?.defaultWorkSpace
            : "default"
        }/home`
      );
    }
    if (loginError) {
      // setState(details);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          loginUserError === "blocked_by_admin"
            ? "User blocked by admin"
            : "Something went wrong,to login !",
      });
      dispatch(loginErrorUpdate(false));
      setState(details);
    }
    if (registerErrorStatus) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${
          registerErrorStatus
            ? registerErrorStatus
            : "Something went wrong,please try latter !"
        }!`,
      });
      dispatch(registerErrorUpdate(null));
      setState(details);
    }
  }, [loginError, userLoggedIn, registerErrorStatus]);

  useEffect(() => {
    if (countryList.length > 0) {
      const list = countryList.map((item) => {
        return { value: item.iso, label: item.nicename, id: item.id };
      });
      updateState("countryList", list);
    }
  }, [countryList]);
  useEffect(() => {
    if (state.countryList.length > 0 && state.menu === 1 && !state.country) {
      try {
        fetch("https://ipapi.co/json/")
          .then((response) => response.json())
          .then((data) => {
            console.log("user-location", data.country);

            const selectedList = state.countryList.find(
              (country) => country.value === data.country
            );
            updateState("country", selectedList);
          })
          .catch((error) => {
            console.error("Error fetching user location:", error);
          });
      } catch (e) {
        console.log("e", e);
      }
    }
  }, [state.menu]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      borderRadius: "6px",
      padding: "5px 5px",
      outline: "none",
      cursor: "pointer",
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "lightblue" : "white",
      color: state.isFocused ? "black" : "black",
      ":hover": {
        backgroundColor: "lightgrey",
        color: "black",
        cursor: "pointer",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const onOtpSubmit = (otp) => {
    console.log("Login Successful", otp);
    updateState("otp", otp);
  };
  const getHidedList = () => {
    if (!state.phoneNumber) {
      return "";
    }
    const data = state.phoneNumber.slice(0, -4) + "*".repeat(4);
    return data;
  };
  const previewPhoneNumber = useMemo(() => getHidedList(), [state.phoneNumber]);

  const errorGettingToken = (type, e) => {
    console.error(`Error getting ${type} token :`, e);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong,when try to get your social login details!.",
    });
  };
  const verifyOtp = () => {
    if (
      state.otp &&
      state.otp.length === 6 &&
      state.signInStatus !== "mobile-signIn"
    ) {
      // Register verify
      dispatch(keyCloakLoader(true));

      let data = {
        phoneNumber: state.phoneNumber,
        otp: state.otp,
      };
      LoginService.validateOtp(data)
        .then((response) => {
          console.log("response", response);
          if (response.data.data === "VALID_OTP") {
            // const getLocation = countryList.find(
            //   (item) => item.iso === state.country?.value
            // );

            let details = {
              name: state.name,
              phoneNumber: state.phoneNumber,
              clientId: CLIENT_ID,
              location: state.country.label,
              email: state.email,
            };
            let loginUpdate = {
              countryId: state.country.id,
              personalLanguage: state.language.value,
              country: state.country.label,
            };
            console.log("details", details);

            dispatch(mobileNumberRegister(details, loginUpdate));
            // updateState("menu", 3);
          } else {
            toast.error("Invalid otp !");
            dispatch(keyCloakLoader(false));
          }
        })
        .catch((e) => {
          console.log("e", e);
          dispatch(keyCloakLoader(false));
          toast.error("Some thing went wrong ,please try latter !");
        });
    } else if (
      state.otp &&
      state.otp?.length === 6 &&
      state.signInStatus === "mobile-signIn"
    ) {
      // Login verify
      dispatch(keyCloakLoader(true));

      let data = {
        mobile_number: state.phoneNumber,
        otp: state.otp,
        client_id: CLIENT_ID,
      };
      try {
        LoginService.validateLoginOtp(data)
          .then((response) => {
            const result = response.data;
            console.log("Login-Otp", result);

            if (result?.access_token) {
              dispatch(updateKeyCloakToken(result));
            } else {
              toast.error("Enter valid otp !");
              dispatch(keyCloakLoader(false));
            }
          })
          .catch((error) => {
            console.log("e", error.response);

            dispatch(keyCloakLoader(false));
            toast.error("Enter valid otp !");
          });
      } catch (e) {
        console.log("e", e);
        dispatch(keyCloakLoader(false));
        toast.error("Some thing went wrong ,please try latter !");
      }
    } else {
      toast.warning("Please fill valid otp");
    }
  };
  const submitForm = (e, resend) => {
    e.preventDefault();
    e.stopPropagation();
    // Check mobile number
    if (!state.country?.value && !state.signInStatus === "mobile-signIn") {
      toast.warning("Select your country");
    } else if (
      state.phoneNumber.length <= 8 ||
      state.phoneNumber.length >= 16
    ) {
      toast.warning("Enter valid phone number");
    } else {
      dispatch(keyCloakLoader(true));
      let data = {
        phoneNumber: state.phoneNumber,
      };
      if (state.signInStatus === "mobile-signIn") {
        // Login

        LoginService.phoneExitCheck(data)
          .then((response) => {
            if (response.data.data === "PHONE_NUMBER_EXIST") {
              loginOtpSend(resend);
            } else {
              Swal.fire({
                title: "PHONE NUMBER NOT EXIST",
                showCancelButton: true,
                confirmButtonText: "Create New Account",
                cancelButtonText: "Reenter Phone Number",
                reverseButtons: true,
                icon: "warning",
                iconHtml: `<img src=${Notfound} style="width: 50px; height: 50px;">`,
              }).then((result) => {
                if (result.value) {
                  // toast.error("Invalid credentials !");
                  createAccountPage(2);
                }
              });
              // toast.error("Phone number not exist, please check number");
            }
            dispatch(keyCloakLoader(false));
          })
          .catch((error) => {
            toast.error("Something went wrong ,please try later");
            dispatch(keyCloakLoader(false));
          });
      } else {
        // Register
        if (state.email) {
          data.email = state.email;
        }
        LoginService.generateOtp(data)
          .then((response) => {
            console.log("response", response);
            if (response.data.data === "OTP_SENT") {
              updateState("menu", 3);
              if (resend) {
                updateState("onResentOtp", !state.onResentOtp);
              }
            } else if (
              response.data.data === "PHONE_NUMBER_ALREADY_REGISTERED"
            ) {
              toast.error(
                "Given mobile number already registered,try to login!"
              );
            } else if (response.data.data === "EMAIL_ALREADY_EXIST") {
              toast.error("Given email already exist,try to new email!");
            } else {
              toast.error("Some thing went wrong ,please try latter !");
            }

            dispatch(keyCloakLoader(false));
          })
          .catch((e) => {
            console.log("e", e);
            dispatch(keyCloakLoader(false));
            toast.error("Some thing went wrong ,please try latter !");
          });
      }
    }
  };

  const loginOtpSend = (resend) => {
    axios({
      method: "post",
      url: `${KEY_CLOAK_URL}realms/yuukke/sms-authentication/otp/${state.phoneNumber}`,
    })
      .then((response) => {
        console.log("response", response);
        dispatch(keyCloakLoader(false));
        updateState("menu", 3);
        if (resend) {
          console.log("resend-otp", resend);
          updateState("onResentOtp", !state.onResentOtp);
        }
      })
      .catch((e) => {
        console.log("e", e);
        // toast.error("Something went wrong");
        updateState("menu", 3);
        dispatch(keyCloakLoader(false));
        if (resend) {
          updateState("onResentOtp", !state.onResentOtp);
        }
      });
  };

  const updateToken = (type, token) => {
    if (token?.access_token) {
      dispatch(socialKeyClockLoginAction(type, token?.access_token));
    } else {
      console.log("Access token not present");
      errorGettingToken(type, token);
    }
  };

  const singInForm = (type) => {
    if (type === "mobile-signIn") {
      setState({
        ...state,
        signInStatus: type,
        menu: 2,
      });
    } else if (type === "mail-signIn") {
      setState({
        ...state,
        signInStatus: type,
        menu: 4,
      });
    }
  };
  const backForm = () => {
    if (state.signInStatus) {
      setState({
        ...state,
        signInStatus: "",
        menu: 0,
        name: "",
        country: "",
        phoneNumber: "",
        otp: "",
      });
    } else if (state.menu === 4) {
      updateState("menu", 1);
    } else {
      setState((previous) => ({
        ...previous,
        menu: state.menu - 1,
        name: "",
        phoneNumber: "",
        email: "",
      }));
      // updateState("menu", state.menu - 1);
    }
  };
  const emailRegisterSuccess = (e) => {
    setState(details);
    // props.history.push(`/yuukke-email-verification`);
  };
  const createAccountPage = (menu = 2) => {
    setState((previous) => ({
      ...previous,
      signInStatus: "",
      menu: 2,
      name: "",
      phoneNumber: "",
      email: "",
    }));
  };
  if (
    loginUserError === "blocked_by_admin" ||
    loginUserError === "some_thing_wrong"
  ) {
    return <ErrorUser />;
  }
  // console.log("state", state);
  return (
    <div
      className="login-container position-relative"
      style={{ overflowX: state.menu === 0 ? "auto" : "hidden" }}
    >
      {loginLoader && (
        <div className="blur-loader-bg">
          <span class="loader"></span>
        </div>
      )}
      {state.menu !== 0 && state.menu !== 3 && (
        <MdArrowBackIos
          className={`arrow-icon wrap-menu-${state.menu}`}
          onClick={() => backForm()}
        />
      )}

      {state.menu === 1 ? (
        // Register Options
        <section
          className={`mt-4 mb-auto login-option-wrap wrap-menu-${state.menu}`}
        >
          <img src={Logo} alt="logo" className="mb-5" />
          <h2 className="mb-5 font-weight-bold text-center">
            Welcome to Yuukke{" "}
          </h2>
          <ul className="login-options-opt">
            <li
              className="login-options-btn"
              onClick={() => updateState("menu", 2)}
            >
              <img src={Phone} alt="" className="login-options-img" />
              Register with Mobile Number
            </li>
            {/* <li
              className="login-options-btn"
              onClick={() => updateState("menu", 4)}
            >
              <img src={Email} alt="" className="login-options-img" />
              Register with email
            </li> */}
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <GoogleLogin
                outer={true}
                updateToken={updateToken}
                className="login-options-img"
                errorGettingToken={errorGettingToken}
                title="Register With Google"
              />
            </GoogleOAuthProvider>

            {/* <li className="login-options-btn">
              <img src={FaceBook} alt="" className="login-options-img" />
              Sign in with Facebook
            </li> */}
          </ul>
        </section>
      ) : state.menu === 2 ? (
        // Phone Register
        <section className={`mt-0 login-option-wrap wrap-menu-${state.menu}`}>
          {state.signInStatus === "mobile-signIn" ? (
            <>
              <img src={Logo} alt="logo" className="mb-5" />
              <h6 className="mb-3 font-weight-bold">
                Enter your register mobile number
              </h6>{" "}
            </>
          ) : (
            <h2 className="mb-5 font-weight-bold">
              Woo-Hoo <img src={Wave} alt="" className="login-options-img" />!
              Let's start..
            </h2>
          )}

          <form
            className="login-details-wrapper"
            onSubmit={(e) => submitForm(e, false)}
          >
            {state.signInStatus !== "mobile-signIn" && (
              <>
                <label className="mt-2">What is your name ? *</label>
                <div class="input-text">
                  <input
                    className="input-text-input"
                    value={state.name}
                    type="text"
                    placeholder="Enter your name"
                    required={true}
                    onChange={(e) => updateState("name", e.target.value)}
                  />
                  <FaRegUser />
                </div>
                <label className="mt-2 login-details-content">
                  Which country are you from ? *
                </label>
                <Select
                  // defaultValue={"IN"}
                  onChange={(e) => updateState("country", e)}
                  options={state.countryList}
                  value={state.country}
                  placeholder="Select Country"
                  styles={customStyles}
                  required={true}
                  className="z-1000"
                />
                <label className="mt-2 login-details-content">Language *</label>
                <Select
                  onChange={(e) => updateState("language", e)}
                  options={language}
                  value={state.language}
                  placeholder="Select Langauge"
                  styles={customStyles}
                  required={true}
                />
                <label className="mt-2">Email *</label>
                <div class="input-text">
                  <input
                    className="input-text-input"
                    value={state.email}
                    type="email"
                    placeholder="Enter your email"
                    required={true}
                    onChange={(e) => updateState("email", e.target.value)}
                  />
                  <FaRegUser />
                </div>
              </>
            )}
            <label className="mt-2 login-details-content">
              {state.signInStatus === "mobile-signIn"
                ? "Phone number"
                : "Can we get your mobile number"}{" "}
              *
            </label>
            <div class="input-text">
              <PhoneInput
                country={
                  state.country?.value
                    ? state.country?.value.toLowerCase()
                    : "in"
                }
                value={state.phoneNumber}
                onChange={(phone) => updateState("phoneNumber", phone)}
                required={true}
              />
              {/* <input
                className="input-text-input"
                style={{ padding: "0 10px" }}
                placeholder="Phone Number"
                type="number"
                required={true}
                value={state.phoneNumber}
                onChange={(e) => updateState("phoneNumber", e.target.value)}
                
              /> */}
            </div>
            <button
              type="submit"
              style={{ color: "black", background: "white" }}
              className="login-options-btn mt-3 justify-content-center font-weight-bold btn-hover"
            >
              {state.signInStatus === "mobile-signIn" ? "Send Otp" : "Continue"}
            </button>
          </form>
        </section>
      ) : state.menu === 3 ? (
        // Phone Register Otp
        <section className={`login-option-wrap wrap-menu-${state.menu}`}>
          <h2 className="mb-5 font-weight-bold">Verify your number</h2>
          <h6 style={{ marginTop: "1rem", marginBottom: "2rem" }}>
            Enter the code we've sent by text to {previewPhoneNumber}
          </h6>
          <div
            className="d-flex align-items-center justify-content-center mt-3 mb-5"
            style={{ gap: "1rem" }}
          >
            <OtpInput
              // length={state.signInStatus === "mobile-signIn" ? 6 : 4}
              length={6}
              onOtpSubmit={onOtpSubmit}
            />
          </div>
          <button
            onClick={verifyOtp}
            type="submit"
            style={{ color: "black", background: "white" }}
            className="login-options-btn mt-3 justify-content-center font-weight-bold btn-hover"
          >
            Verify
          </button>

          <MeetingExpiry resendOtp={submitForm} status={state.onResentOtp} />
          <p
            className="mt-5 resend-otp"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setState({
                ...state,
                menu: 2,
                otp: "",
              });
            }}
          >
            Change Phone Number
          </p>
        </section>
      ) : state.menu === 4 ? (
        // Email Register
        <section className=" login-option-wrap ">
          <img src={Logo} alt="logo" className="mb-5" />

          <h5 className="mt-3 mb-3 font-weight-bold">
            {state.signInStatus === "mail-signIn" ? "Sign In" : "Register"}{" "}
          </h5>
          <MailRegister
            signIn={state.signInStatus === "mail-signIn"}
            emailRegisterSuccess={emailRegisterSuccess}
            createAccountPage={createAccountPage}
          />
        </section>
      ) : (
        // Sign in option
        <section className=" login-option-wrap ">
          <img src={Logo} alt="logo" />
          <p className="text-white text-center mt-5">
            By tapping Sign in,you agree to our{" "}
            <a
              className="reg-btn w-auto"
              href="https://www.yuukke.com/privacy-policy.html"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
          <ul className="login-options-opt">
            <li
              className="login-options-btn"
              onClick={() => singInForm("mobile-signIn")}
            >
              <img src={Phone} alt="" className="login-options-img" />
              Sign in with Mobile Number
            </li>
            <li
              className="login-options-btn"
              onClick={() => singInForm("mail-signIn")}
            >
              <img src={Email} alt="" className="login-options-img" />
              Sign in with Email
            </li>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <GoogleLogin
                outer={true}
                updateToken={updateToken}
                className="login-options-img"
                errorGettingToken={errorGettingToken}
                title="Sign in with Google"
              />
            </GoogleOAuthProvider>

            {/* <li className="login-options-btn">
              <img src={FaceBook} alt="" className="login-options-img" />
              Sign in with Facebook
            </li> */}

            <div className="login-other-option">
              <p className="align-items-center text-white d-flex justify-content-center">
                New user?{" "}
                <span
                  onClick={() => updateState("menu", 1)}
                  className="reg-btn ml-2"
                >
                  Create New Account
                </span>
              </p>
              {/* <p
                className="mt-3 text-white"
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                Trouble Logging in?
              </p> */}
            </div>
          </ul>
        </section>
      )}
    </div>
  );
};

export default withRouter(AuthContainer);
