import moment from "moment";
import {
  COURSE_LOADER_RESET,
  GET_LOGOUT_DATA,
  GET_COURSES_COUNT,
  STORE_THUMBNAILS,
  GET_COURSES_STARS_ID,
  GET_COURSES_LIST,
  RESET_API_COUNT_COURSE,
  UPDATE_COURSES_STARS_ID,
} from "../types";

const initialState = {
  list: [],
  articles: [],
  split: [],
  yuukkePrograms: [],
  accreditedPrograms: [],
  sponseredPrograms: [],
  psListCount: 0,
  apListCount: 0,
  spListCount: 0,
  thumbnails: {},
  stars: [],
  apiCount: 0,
  lastSync: null,
  loading: false,
  couceloading: true,
  courseCount: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_API_COUNT_COURSE:
      console.log("RESET_API_COUNT_COURSE");
      console.log(action.payload);
      return {
        ...state,
        apiCount: 0,
        yuukkePrograms: [],
        accreditedPrograms: [],
        sponseredPrograms: [],
        loading: true,
      };
    case COURSE_LOADER_RESET:
      console.log("COURSE_LOADER_RESET");
      console.log(action.payload);
      if (action.payload && action.payload == "off") {
        return {
          ...state,
          couceloading: false,
        };
      } else {
        return {
          ...state,
          loading: true,
          couceloading: true,
        };
      }

    case GET_COURSES_LIST:
      console.log("GET_COURSES_LIST");
      console.log(action.payload.courseType);
      console.log("GET_COURSES_LIST ==> 1");
      console.log(action.payload.list);
      console.log("GET_COURSES_LIST ==> 2");
      console.log(state.yuukkePrograms);
      var lastSync = state.lastSync;
      var loading = state.loading;
      if (state.apiCount >= 3) {
        loading = false;

        lastSync = moment().format();
      }

      var list = action.payload.list;
      if (action.payload.courseType === "yuukke Programs") {
        if (state.yuukkePrograms)
          var list = state.yuukkePrograms.concat(list);

        console.log("GET_COURSES_LIST : yuukke Programs");
        return {
          ...state,
          yuukkePrograms: list,
          psListCount: action.payload.items,
          apiCount: state.apiCount + 1,
          loading: loading,
          lastSync: lastSync,
          couceloading: false,
        };
      } else if (action.payload.courseType === "Accredited Programs") {
        if (state.accreditedPrograms)
          var list = state.accreditedPrograms.concat(list);
        console.log("GET_COURSES_LIST : Accredited Programs");
        return {
          ...state,
          accreditedPrograms: list,
          apListCount: action.payload.items,
          apiCount: state.apiCount + 1,
          loading: loading,
          lastSync: lastSync,
        };
      } else if (action.payload.courseType === "Sponsered Programs") {
        if (state.sponseredPrograms)
          var list = state.sponseredPrograms.concat(list);
        console.log("GET_COURSES_LIST : Sponsered Programs");
        return {
          ...state,
          sponseredPrograms: list,
          spListCount: action.payload.items,
          apiCount: state.apiCount + 1,
          loading: loading,
          lastSync: lastSync,
        };
      }

    case STORE_THUMBNAILS:
      console.log("STORE_THUMBNAILS");
      console.log(action.payload);
      return {
        ...state,
        thumbnails: action.payload,
      };

    case GET_COURSES_STARS_ID:
      console.log("GET_COURSES_STARS_ID");
      console.log(action.payload);
      var lastSync = state.lastSync;
      var loading = state.loading;
      if (state.apiCount >= 3) {
        loading = false;
        lastSync = moment.moment().format();
      }
      return {
        ...state,
        stars: action.payload,
        apiCount: state.apiCount + 1,
        lastSync: lastSync,
        loading: loading,
      };
    case UPDATE_COURSES_STARS_ID:
      console.log("UPDATE_COURSES_STARS_ID");
      console.log(action.payload);
      var articles = [];
      var i = 0;
      var obj = action.payload;
      var contains = false;

      if (state.stars.articles) {
        for (i = 0; state.stars.articles.length > i; i++) {
          if (state.stars.articles[i].articleId === obj.articleId) {
            contains = true;
          } else {
            articles.push(state.stars.articles[i]);
          }
        }
        if (!contains) {
          articles.push(action.payload);
        }
      }
      var stars = {};
      stars["articles"] = articles;
      return {
        ...state,
        loading: false,
        stars: stars,
      };
    case GET_COURSES_COUNT:
      return {
        ...state,
        courseCount: action.payload,
        loading: false,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");

      return {
        ...initialState,
      };
    default:
      return state;
  }
}
