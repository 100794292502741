import React from "react";
import {
  getQuestionsList,
  askQuestionListV2,
  forumTagList,
  searchForumAskFilter,
} from "../../../redux/actions/questionActions";
// import {

//   forumTagList
// } from "../../../redux/actions/subjectActions";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./forum.css";
import { BiCheck } from "react-icons/bi";
import { FiRefreshCw } from "react-icons/fi";
import { API_URL } from "../../../config/app-config";
import dummyimage from "../../../img/default_avatar.jpg";
import banner from "../../../img/banner.jpg";
import moment from "moment";
import { FaRegComment } from "react-icons/fa";
import { FcLock } from "react-icons/fc";
import InfiniteScroll from "react-infinite-scroll-component";
import { BiChevronsLeft } from "react-icons/bi";
import { getRecentlyViewedQuestionsList } from "../../../redux/actions/dashboardActions";
import MyYuukke from "../../../api/MyYuukke";
import Swal from "sweetalert2";
import ProfileService from "../../../api/ProfileService";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { IoIosLogOut, IoMdNotificationsOutline } from "react-icons/io";
import { Button, Form, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import InviteFriendGroup from "../../MyGroupsandMyEvents/InviteFriendGroup";
import * as APPCONFIG from "../../../config/app-config.js";
import { MdOutlineGroups } from "react-icons/md";
import { giveUserAction, readOnlyWorkspace } from "../../../utils";
import { needPhotVerification } from "../../../redux/actions/commonApiActions";
import GroupConnectionList from "../../MyGroupsandMyEvents/GroupDescription/GroupConnectionList";
import {
  getAllMembersDetails,
  getGroupRequestList,
} from "../../../redux/actions/subjectActions";
import { Tooltip } from "@material-ui/core";
import UserCard from "../../../components/UserCard";

let totalPage = 0;
var optionTypeData = [
  { name: "Top" },
  { name: "Newest" },
  { name: "Latest" },
  { name: "Oldest" },
];
let tagsId = [];

class ForumContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      forumQuestions: [],
      hasMore: true,
      optionIndex: -1,
      userRequestPage: 0,
      deleteQuestionState: false,
      tagList: [],
      selectedList: [],
      searchData: "",
      optionName: "Top",
      userJoin: false,
      creatorUserId: 0,
      exitPopup: false,
      status: "",
      privateGroupState: false,
      fileData: [],
      subjectName: "",
      membersCount: 0,
      inviteUser: false,
      connectionPage: false,
      notificationComing: false,
      pageView: "question",
      userStatus: "",
    };
    this.wrapperRef = React.createRef();
    this.popUpExit = this.popUpExit.bind(this);
    this.divRef = React.createRef();
  }

  // fetchMoreData = (id) => {
  //   this.props.askQuestionAnswerList(
  //     this.state.userRequestPage + 1,
  //     this.props.match.params.id
  //   );
  //   this.setState({
  //     userRequestPage: this.state.userRequestPage + 1,
  //   });

  //   if (this.props.pageNo - 1 > this.state.userRequestPage) {
  //     this.setState({
  //       hasMore: true,
  //     });
  //   } else {
  //     this.setState({
  //       hasMore: false,
  //     });
  //   }
  // };
  onChange = () => {
    this.setState({
      answerOption: !this.state.answerOption,
    });
    console.log("onChange");
  };
  componentDidMount() {
    // this.props.askQuestionListV2(
    //   this.state.userRequestPage,
    //   // 218
    //   this.props.match.params.id
    // );
    tagsId.length = 0;
    this.editGroupsEvents(this.props.match.params.id);
    this.props.forumTagList(this.props.match.params.id);
    var obj = {
      page: 0,
      searchKey: this.state.searchData,
      tags: [],
      filterKey: this.state.optionName.toLowerCase(),
      subjectId: +this.props.match.params.id,
    };
    this.props.searchForumAskFilter(obj);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.formTagList_Data !== prevProps.formTagList_Data) {
      this.getTagList(this.props.formTagList_Data);
    }
    if (this.props.formSearchQuestions !== prevProps.formSearchQuestions) {
      var oldPostList = this.state.forumQuestions;
      var newPosts = oldPostList.concat(this.props.formSearchQuestions);
      console.log(newPosts);
      this.setState({
        forumQuestions: newPosts,
      });
      if (this.props.pageNo - 1 > this.state.userRequestPage) {
        this.setState({
          hasMore: true,
        });
      } else {
        this.setState({
          hasMore: false,
        });
      }
    }

    const react = this.divRef.current?.getBoundingClientRect();
    const x = react?.left + react?.width / 2;
    const y = react?.top + react?.height / 2;

    // const div = document.getElementById("myDiv");
    // const { x, y, width, height } = div.getBoundingClientRect();
    console.log(`The center of the div is at (${x}, ${y}).`);
    // if (this.props.formQuestions !== prevProps.formQuestions) {
    //    var oldPostList = this.state.forumQuestions;
    //   var newPosts = oldPostList.concat(this.props.formQuestions);
    //   console.log(newPosts);
    //   this.setState({
    //     forumQuestions: newPosts,
    //   });
    // }
  }
  emailData = () => {};
  onScroll = (e) => {
    console.log("onScroll", e.target.childNodes);
  };
  render() {
    // console.log("formSearchQuestions", this.props.formTagList);
    const { t } = this.props;
    const { showMenu, forumQuestions, privateGroupState } = this.state;
    console.log("allMembersList", this.props.allMembersList);
    return (
      <>
        <div
          onClick={this.backToCommunity}
          style={{
            color: "gray",
            fontSize: "13px",
            cursor: "pointer",
            marginBottom: "12px",
          }}
        >
          <BiChevronsLeft
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          {t("Back")}
        </div>
        <div class="forum-module">
          <div class="forum-wrapper-search">
            {/* <div class="col-8"></div>
            <div class="col-4"> */}
            <div class="order-3  flex-grow-1">
              <div
                class="d-flex"
                onClick={() =>
                  this.viewConnection(
                    this.state.pageView === "connection"
                      ? "question"
                      : "connection"
                  )
                }
              >
                {this.state.fileData &&
                  this.state.fileData.slice(0, 1).map((photo) => (
                    <div class="postuserImg-fourm ml-2">
                      <img
                        className="rounded-circle"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={photo}
                        alt="name"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = banner;
                        }}
                      />
                    </div>
                  ))}
                <h6
                  style={{
                    color: "#000000",
                    paddingTop: "3px",
                    paddingLeft: "8px",
                    cursor: "pointer",
                  }}
                >
                  {this.state.subjectName}
                  <div class="d-flex">
                    <MdOutlineGroups
                      style={{
                        width: "20px",
                        height: "24px",
                        color: "#A00030",
                        paddingBottom: "5px",
                      }}
                    />{" "}
                    {this.props.totalCommunityUser <= 1 ? (
                      <p className="memberCount">
                        {this.props.totalCommunityUser} {t("Member")}
                      </p>
                    ) : (
                      <p className="memberCount">
                        {this.props.totalCommunityUser} {t("Members")}
                      </p>
                    )}
                  </div>
                </h6>
              </div>
            </div>
            {!this.state.userJoin &&
              this.state.creatorUserId !== this.props.loginDetails.uId && (
                <div class="ml-2 mr-auto">
                  <button
                    class="join-Button"
                    onClick={() =>
                      this.getMemberLists(this.props.match.params.id)
                    }
                  >
                    {this.state.status === "protected" ? (
                      <>
                        {" "}
                        {this.state.joinType === "requested"
                          ? t("Request Pending")
                          : t("Request")}
                      </>
                    ) : (
                      t("Join Community")
                    )}
                  </button>
                </div>
              )}
            {this.state.userJoin && (
              <>
                {this.state.creatorUserId === this.props.loginDetails.uId && (
                  <div
                    className="postMoreInfo"
                    style={{
                      cursor: "pointer",
                      float: "right",
                      marginLeft: "9px",
                      marginRight: "4px",
                    }}
                  >
                    <MoreHorizIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => {
                        this.handleMenuClick(e);
                      }}
                    />{" "}
                    <Menu
                      id="simple-menu"
                      anchorEl={this.state.menuState}
                      keepMounted
                      open={this.state.menuState}
                      onClose={this.handleMenuClose}
                    >
                      <div
                        className="menuCard"
                        onClick={(e) =>
                          this.editGroup(e, this.props.match.params.id)
                        }
                      >
                        <Edit style={{ cursor: "pointer", marginTop: "6px" }} />{" "}
                        <MenuItem>{t("Edit")}</MenuItem>
                      </div>
                      <div
                        className="menuCard"
                        onClick={(e) =>
                          this.deleteCommunity(e, this.props.match.params.id)
                        }
                      >
                        <Delete
                          style={{ cursor: "pointer", marginTop: "6px" }}
                        />{" "}
                        <MenuItem>{t("Delete")}</MenuItem>
                      </div>
                    </Menu>
                  </div>
                )}
                {this.state.userStatus === "user" && (
                  <div class="ml-2">
                    <button onClick={this.exit} class="join-Button">
                      <IoIosLogOut
                        style={{
                          cursor: "pointer",
                          width: "18px",
                          height: "16px",
                          color: "#A00030",
                        }}
                      />{" "}
                      {t("Exit")}
                    </button>
                  </div>
                )}
              </>
            )}

            <div
              className="wrapper-forum"
              // className={
              //   privateGroupState ? "private-forum-wrapper" : "wrapper-forum"
              // }
            >
              <div className="search">
                <input
                  id="searchData"
                  type="search"
                  name="searchData"
                  value={this.state.searchData}
                  placeholder={t("Search Forum")}
                  onChange={this.onInput}
                  autocomplete="on"
                />
                <button type="search">{t("Search")}</button>
                {/* </div> */}
              </div>
              {/* <Search /> */}
            </div>
            {this.props.totalRequest !== 0 && (
              <div className="position-relative mr-4">
                <Tooltip title="RequestUser" placement="bottom">
                  <div>
                    <IoMdNotificationsOutline
                      className={`ml-2 mr-2 svg-icons ${
                        this.state.notificationComing ? "shake" : ""
                      }`}
                      onClick={() =>
                        this.viewConnection(
                          this.state.pageView === "request"
                            ? "question"
                            : "request"
                        )
                      }
                    />
                    <span className="badge badge-info bell-svg">
                      {this.props.totalRequest}
                    </span>
                  </div>
                </Tooltip>
              </div>
            )}

            {this.state.creatorUserId === this.props.loginDetails.uId && (
              <a
                href="#"
                className="mr-2"
                onClick={() =>
                  this.setState({
                    inviteUser: !this.state.inviteUser,
                  })
                }
              >
                Invite user's
              </a>
            )}
            {this.state.inviteUser && (
              <InviteFriendGroup
                closeInvitePage={this.closePopup}
                isForum={true}
                subId={this.props.match.params.id}
              />
            )}
          </div>

          <hr />

          {this.state.pageView === "connection" ? (
            <div style={{ paddingTop: "20px" }} className="mb-3">
              <div
                onClick={() => this.viewConnection("question")}
                style={{
                  color: "gray",
                  fontSize: "13px",
                  cursor: "pointer",
                  marginBottom: "12px",
                }}
              >
                <BiChevronsLeft
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                {t("Back to Questions")}
              </div>
              <GroupConnectionList
                friendsList={this.props.allMembersList}
                communityId={+this.props.match.params.id}
                userId={this.state.userId}
                isForum={true}
                userStatus={this.state.userStatus}
              ></GroupConnectionList>
            </div>
          ) : this.state.pageView === "request" ? (
            <>
              <div
                onClick={() => this.viewConnection("question")}
                style={{
                  color: "gray",
                  fontSize: "13px",
                  cursor: "pointer",
                  marginBottom: "12px",
                }}
              >
                <BiChevronsLeft
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                {t("Back to Questions")}
              </div>
              <div className="d-flex flex-column mt-3" style={{ gap: "1rem" }}>
                <UserCard
                  subjectId={this.state.subjectId}
                  showFriendsProfile={this.showFriendsProfile}
                  forumGroup={true}
                />
              </div>
            </>
          ) : (
            <div class="container">
              <div class="row">
                <div class="col-12 col-lg-2 col-md-3 col-sm-4  ">
                  {!readOnlyWorkspace(
                    this.props.userWorkSpace?.role,
                    this.props.userWorkSpace?.typeHandle
                  ) && (
                    <button
                      class="discussionButton"
                      onClick={this.askAQuestionPage}
                    >
                      {t("Start a Discussion")}
                    </button>
                  )}
                  <ul class="navigationsMenu-nav nav-link-secondary flex-column fw-bold gap-2 forum-menu mt-3">
                    <li class="nav-item">
                      <FaRegComment
                        style={{
                          width: "14px",
                          height: "13px",
                        }}
                      />
                      <span style={{ fontSize: "0.79rem", marginLeft: "8px" }}>
                        {t("All Discussion")}{" "}
                      </span>
                    </li>

                    {/* <li class="nav-item forum-tags">
                  <BsGridFill
                    style={{
                      width: "14px",
                      height: "13px",
                    }}
                  />
                  <span >{"Tags"} </span>
                </li> */}
                  </ul>
                  <div class="tag-List">
                    {this.state.tagList &&
                      this.state.tagList.map((tag, index) => (
                        <p
                          className={
                            tag.myPostColor == "true"
                              ? "tag-List-select"
                              : "tag-List-Unselect"
                          }
                          onClick={() =>
                            this.handleClickTagList(
                              tag.tagName,
                              tag.tagId,
                              index
                            )
                          }
                        >
                          {" "}
                          {tag.tagName}
                        </p>
                      ))}
                  </div>
                </div>

                <div class="col-12 col-lg-10 col-md-9 col-sm-8 ">
                  <div class="row">
                    <div class="col-8  col-offset-8 col-lg-11 col-md-10 col-sm-4">
                      <button
                        class="btn cardButton-forum dropdown-toggle"
                        onClick={this.showPlusMenu}
                        ref={this.wrapperRef}
                      >
                        {this.state.optionName}{" "}
                      </button>
                      {showMenu && (
                        <>
                          <div
                            className="cardButton-forum-dropdownCardPost"
                            ref={this.wrapperRef}
                          >
                            {optionTypeData &&
                              optionTypeData.map((data, index) => (
                                <div
                                  className={`segmentsList-forum${
                                    this.state.optionIndex === index
                                      ? " selected-forum"
                                      : ""
                                  }`}
                                  // className="post-nav-item post-card-title post-mb-0 displayName-forum"
                                  ref={this.wrapperRef}
                                  onClick={() =>
                                    this.optionIndexData(index, data.name)
                                  }
                                >
                                  {this.state.optionIndex === index && (
                                    <BiCheck
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  )}
                                  {t(`${data.name}`)}
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                    </div>
                    <div class="col-4  col-offset-8 col-lg-1 col-md-2 col-sm-8">
                      <span class="refresh-forum">
                        <FiRefreshCw
                          onClick={this.refreshData}
                          style={{ width: "15px", height: "15px" }}
                        />
                      </span>
                    </div>
                  </div>
                  <div class="col-12 col-12 col-lg-12 col-md-12 forum-question-list-wrapper">
                    <InfiniteScroll
                      dataLength={forumQuestions.length}
                      next={this.fetchMoreData}
                      hasMore={this.state.hasMore}
                      // scrollableTarget="scrollableDiv"
                      // loader={<h4>{t("Loading...")}</h4>}
                      scrollableTarget={this.onScroll}
                    >
                      <div
                        id="scrollableDiv"
                        style={{
                          minHeight: "auto",
                          maxHeight: "31.5rem",
                          overflow: "auto",
                          overflowX: "hidden",
                          // margin: "0px 22px 25px",
                        }}
                        ref={this.divRef}
                      >
                        {forumQuestions &&
                          forumQuestions.map((data, index) => (
                            <>
                              <div
                                class="row forum-card"
                                onClick={(event) =>
                                  this.moveDescriptionPage(
                                    data.questionId,
                                    event,
                                    data.questionName
                                  )
                                }
                                // id={data.questionId}
                                key={data.questionId}
                              >
                                <div class="col-12 col-lg-1 col-md-2 col-sm-4">
                                  <div className="post-avatar post-avatar-xs">
                                    <img
                                      style={{ cursor: "pointer" }}
                                      // onClick={() => this.showFriendsProfile(data.userId)}
                                      className="post-avatar-img rounded-circle"
                                      src={
                                        API_URL +
                                        "api/getUserPhoto/" +
                                        data.userId +
                                        "/" +
                                        data.userName
                                      }
                                      alt="user"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = dummyimage;
                                      }}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-lg-8 col-md-6 col-sm-8">
                                  <div class="d-flex">
                                    <h6>{data.questionName}</h6>
                                    {data.markAsPrivate && (
                                      <FcLock
                                        style={{
                                          width: "16px",
                                          height: "18px",
                                          marginLeft: "12px",
                                        }}
                                      />
                                    )}{" "}
                                    {/* {this.props.loginDetails.uId === data.userId &&
                                 <> 
                             <EditIcon className="actionIcon ml-3" onClick={(event)=>this.editQuestion(data.questionId,event)}/>
                             <DeleteOutlineIcon className="actionIcon ml-2 "onClick={(event)=>this.deleteQuestion(data.questionId,event)}/>
                             </>
                                 }                            */}
                                  </div>
                                  <div className="post-nav post-nav-divider">
                                    <p
                                      className="post-nav-item post-card-title post-mb-0 displayName-forum"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {" "}
                                      {data.questionId}
                                    </p>
                                    <span className="post-nav-item post-small">
                                      {"Started"}{" "}
                                      {moment(data.createdDate)
                                        .locale("en")
                                        .format("ll")}{" "}
                                    </span>
                                  </div>

                                  <p
                                    class="forum-ans"
                                    dangerouslySetInnerHTML={{
                                      __html: data.questionDescription,
                                    }}
                                  />
                                  {/* {data.questionType === "image" &&
                                 <>  
                                 {data.mediaFiles &&
                                  data.mediaFiles.map((data) => (
                                    <img
                                      className="forum_questionMedia"
                                      controls
                                      src={
                                        data.fileName === undefined ||
                                        data.fileName.length === 0
                                          ? dummygif
                                          : `${APPCONFIG.API_URL}auth/image/${data.fileName}`
                                      }
                                      alt="question"
                                    ></img>
                                  ))}
                                  </>
                             } */}
                                </div>
                                <div class="col-6 col-lg-2 col-md-3 col-sm-7">
                                  <div class="forum-tags-badges">
                                    {data.tagsValue &&
                                      data.tagsValue.slice(0, 5).map((tags) => (
                                        <a
                                          href="#"
                                          class="badge badge-primary mr-2"
                                        >
                                          {tags.tagName}
                                        </a>
                                      ))}
                                  </div>
                                </div>{" "}
                                <div class="col-6 col-lg-1 col-md-1 col-sm-1">
                                  <div
                                    style={{
                                      fontSize: "13px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <FaRegComment
                                      style={{
                                        width: "14px",
                                        height: "13px",
                                        marginRight: "0.25rem",
                                      }}
                                    />{" "}
                                    {data.answerCount}
                                  </div>
                                </div>
                              </div>
                            </>
                            // </div>
                          ))}{" "}
                      </div>
                    </InfiniteScroll>
                    {forumQuestions && forumQuestions.length == 0 && (
                      <div style={{ textAlign: "center" }}>
                        {t("No Questions Present..")}
                      </div>
                    )}
                    {/* <div style={{ marginTop: "1rem", textAlign: "center" }}>
                    <button class="btn cardButton-forum">{"Load More"} </button>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.exitPopup && this.popUpExit()}
      </>
    );
  }
  removedSentData = () => {
    // this.props.getAllMembersDetails(this.state.subject_id);
  };
  viewConnection = (data) => {
    this.setState({
      pageView: data,
    });
  };
  popUpExit() {
    const { t } = this.props;
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Modal show="true" onHide={this.handleClose}>
          <Modal.Header>{t("Exit Community")}</Modal.Header>

          <Modal.Body>
            <Form.Group className="formInput">
              <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                {t("Are you sure to exit in this community?")}
              </Form.Label>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              {t("Cancel")}
            </Button>
            <Button onClick={this.removeUser} variant="primary">
              {t("Exit")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  handleMenuClick = (event, userId) => {
    this.setState({ menuState: event.target });
  };
  handleClose = () => {
    this.setState({ exitPopup: false });
  };
  removeUser = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let removeUser = {
      groupsOrEvents: this.props.match.params.id,
    };
    MyYuukke.exitGroup(removeUser)
      .then((response) => {
        if (response.data.status === 1) {
          toast.success(`${this.props.t("Exited successfully")}`);
          this.handleClose();
          this.backToCommunity();
          // LoadService.redirect(this.props.history, "/groups");
        } else {
          toast.warning(`${this.props.t("Not Properly send Details")}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  editGroup(e, subjectId) {
    this.props.history.push(`/updateGroup/${subjectId}`);
  }
  exit = () => {
    this.setState({
      exitPopup: true,
      menuState: false,
    });
  };
  handleMenuClose = () => {
    this.setState({
      menuState: false,
    });
  };
  getMemberLists = (subId) => {
    let userName = this.props.loginDetails.dName;
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");

      this.props.needPhotVerification(true);
    } else {
      if (
        this.state.status === "protected" &&
        this.state.joinType !== "requested"
      ) {
        Swal.fire({
          title: "Do you want to join the community ?",
          showCancelButton: true,
          confirmButtonText: "request",
          cancelButtonText: "cancel",
          reverseButtons: true,
          icon: "warning",
        }).then((result) => {
          if (result.value) {
            Swal.fire({
              icon: "success",
              title: "Requested successfully",
              showConfirmButton: true,
            }).then((result) => {
              let data = {
                subjectId: subId,
                type: "request",
              };

              MyYuukke.requestOrAcceptOrReject(data)
                .then((response) => {
                  this.setState({
                    joinType: "requested",
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          }
        });
      } else if (
        this.state.status === "public" ||
        this.state.status === "private"
      ) {
        Swal.fire({
          title: `${this.props.t("Do you want to join the community ?")}`,
          showCancelButton: true,
          confirmButtonText: `${this.props.t("confirm")}`,
          cancelButtonText: `${this.props.t("cancel")}`,
          reverseButtons: true,
          icon: "warning",
        })
          .then((result) => {
            if (result.value) {
              Swal.fire({
                title: `${this.props.t("Joined Successfully")}`,
                showConfirmButton: true,
                icon: "success",
              }).then((result) => {
                this.saveOrUpdateMemberList(userName, subId, "");
              });
            }
          })

          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  saveOrUpdateMemberList(userName, subId, memberShipPrice) {
    let date = moment().format("YYYY-MM-DD");
    let memberList = {
      userName: userName,
      groupsOrEvents: subId,
      paymentAmount: memberShipPrice,
      joinDate: date,
    };
    MyYuukke.saveMemberList(memberList)
      .then((response) => {
        this.setState({
          userJoin: true,
          membersCount: this.state.membersCount + 1,
        });
        this.props.getAllMembersDetails(subId, "all", 0, false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  refreshData = () => {
    var obj = {
      page: 0,
      searchKey: "",
      tags: [],
      filterKey: "top",
      subjectId: +this.props.match.params.id,
    };
    tagsId.length = 0;
    this.setState({
      optionName: "Top",
      searchData: "",
      userRequestPage: 0,
      forumQuestions: [],
    });
    this.props.searchForumAskFilter(obj);
  };
  onInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    let tagData = [];
    if (tagsId.length !== 0) {
      tagsId.forEach((element) => {
        tagData.push(element.tagId);
      });
    }
    var obj = {
      page: 0,
      searchKey: e.target.value,
      tags: tagData,
      filterKey: this.state.optionName.toLowerCase(),
      subjectId: +this.props.match.params.id,
    };
    // Api Call
    this.setState({
      forumQuestions: [],
      userRequestPage: 0,
    });
    this.props.searchForumAskFilter(obj);

    console.log("fData", obj);
  };

  optionIndexData = (index, name) => {
    this.setState({
      optionIndex: index,
      optionName: name,
      showMenu: false,
    });
    let tagData = [];
    if (tagsId.length !== 0) {
      tagsId.forEach((element) => {
        tagData.push(element.tagId);
      });
    }
    var obj = {
      page: 0,
      searchKey: this.state.searchData,
      tags: tagData,
      filterKey: name.toLowerCase(),
      subjectId: +this.props.match.params.id,
    };
    this.setState({
      forumQuestions: [],
      userRequestPage: 0,
    });
    this.props.searchForumAskFilter(obj);
    // Api Call
    console.log("fData", obj);
  };
  closePopup = (haveData) => {
    if (haveData) {
      this.editGroupsEvents(this.props.match.params.id);
    }
    this.setState({
      inviteUser: !this.state.inviteUser,
    });
  };
  handleClickTagList = async (name, id, index) => {
    var count = this.state.selectedList.length;
    var stateTagList = this.state.tagList;
    if (this.state.selectedList.length > 0) {
      tagsId = this.state.selectedList.filter(
        (element) => element.tagId !== id
      );
      await this.setState({ selectedList: tagsId });

      for (let i = 0; i < stateTagList.length; i++) {
        if (stateTagList[i].tagId === id) {
          stateTagList[i].myPostColor = "false";
        }
      }
      this.setState({ tagList: stateTagList });
    }
    if (count === tagsId.length) {
      tagsId.push({ tagsName: name, tagId: id, myPostColor: "true" });
      await this.setState({ selectedList: tagsId });
      for (let i = 0; i < stateTagList.length; i++) {
        if (stateTagList[i].tagId == id) {
          stateTagList[i].myPostColor = "true";
        }
      }
      this.setState({ tagList: stateTagList });
    }
    // Api Call
    let tagData = [];
    if (tagsId.length !== 0) {
      tagsId.forEach((element) => {
        tagData.push(element.tagId);
      });
    }
    var obj = {
      page: 0,
      searchKey: this.state.searchData,
      tags: tagData,
      filterKey: this.state.optionName.toLowerCase(),
      subjectId: +this.props.match.params.id,
    };
    this.setState({
      forumQuestions: [],
      userRequestPage: 0,
    });
    this.props.searchForumAskFilter(obj);
    console.log("fData", obj);
  };
  backToCommunity = () => {
    // if (
    //   this.props.location?.state?.from &&
    //   !this.props.location?.state?.from.includes("forum-description")
    // ) {
    //   this.props.history.push(this.props.location?.state?.from);
    // } else {
    this.props.history.push(`/${this.props.workspaceType}/forumCommunity`);
    // }
  };
  askAQuestionPage = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");

      this.props.needPhotVerification(true);
    } else {
      if (
        !this.state.userJoin &&
        this.state.creatorUserId !== this.props.loginDetails.uId
      ) {
        toast.warning(
          `${this.props.t(
            "Please join this community.Then you can get all the access !"
          )}`
        );
      } else {
        var id = this.props.match.params.id;
        var questionName = this.props.match.params.name.trim();
        questionName = questionName.replace(/[, ]+/g, "-").trim().toLowerCase();
        this.props.history.push(`/ask-question/${id}/${questionName}/${id}`);
      }
    }
  };
  deleteQuestion = (questionId) => {
    this.setState({
      deleteQuestionState: !this.state.deleteQuestionState,
      questionId: questionId,
    });
  };
  deleteCommunity(event, subId) {
    event.preventDefault();
    event.stopPropagation();
    this.handleMenuClose();
    Swal.fire({
      title: "Are you sure to delete this community?",
      text: "",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        var deleteSubId = {
          subjectId: subId,
        };
        MyYuukke.deleteSubjectById(deleteSubId)
          .then((response) => {
            toast.success(`${this.props.t("Community deleted successfully")}`);
            this.props.history.push(`/${this.props.workspaceType}/groups`);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  moveDescriptionPage = (questionId, event, questionName) => {
    event.preventDefault();
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");

      this.props.needPhotVerification(true);
    } else {
      var gName = "forum-answer";
      this.props.getRecentlyViewedQuestionsList();
      this.props.history.push(
        `/${this.props.workspaceType}/forum-description/${questionId}/${gName}/${this.props.match.params.id}`
      );
    }
  };

  fetchMoreData = (id) => {
    let tagData = [];
    if (tagsId.length !== 0) {
      tagsId.forEach((element) => {
        tagData.push(element.tagId);
      });
    }
    var obj = {
      page: this.state.userRequestPage + 1,
      searchKey: this.state.searchData,
      tags: tagData,
      filterKey: this.state.optionName.toLowerCase(),
      subjectId: +this.props.match.params.id,
    };

    this.props.searchForumAskFilter(obj);
    // this.props.askQuestionListV2(this.state.userRequestPage + 1, 218);
    this.setState({
      userRequestPage: this.state.userRequestPage + 1,
    });

    if (this.props.pageNo - 1 > this.state.userRequestPage) {
      this.setState({
        hasMore: true,
      });
    } else {
      this.setState({
        hasMore: false,
      });
    }
  };
  editGroupsEvents = (groupId) => {
    var subjectId = {
      subjectId: groupId,
    };
    ProfileService.editGroupsEventsV2(subjectId)
      .then((response) => {
        var result = response.data.data;
        this.setState({
          userStatus: result.userStatus,
          communityName: result.subjectName,
          communityTypeName: result.communityTypeName,
          membersCount: result.membersCount != null ? result.membersCount : 0,
          userJoin: result.join,
          creatorUserId: result.createdBy,
          status: result.status,
          subjectName: result.subjectName,
          subjectId: result.subjectId,
          userId: result.createdBy,
          joinType: result.joinType,
        });
        if (
          result.status === "protected" &&
          result.createdBy === this.props.loginDetails.uId
        ) {
          let data = {
            page: 0,
            subjectId: groupId,
          };
          this.props.getGroupRequestList(data, false);
        }
        this.getMessageFiles(result.grouporeventFiles, result.fileType);
        this.props.getAllMembersDetails(groupId, "all", 0, false);
        if (
          result.status === "private" &&
          result.createdBy === this.props.loginDetails.uId
        ) {
          this.setState({
            privateGroupState: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  showFriendsProfile = (userId) => {
    this.props.getNavigationType(1);
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  };

  getMessageFiles = async (files, type) => {
    console.log("files", files);
    console.log("files", type);
    if (type == "image") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < 1; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
        }
        await this.setState({ fileData: fileList });
      } else {
        fileList.push(banner);
        await this.setState({ fileData: fileList });
      }
    } else {
      let fileList = [];
      fileList.push(banner);
      await this.setState({ fileData: fileList });
    }
  };

  showPlusMenu = (event) => {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };
  handleClickOutside = (event) => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current != null &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        showMenu: false,
      });
    }
  };
  getTagList = (tagList) => {
    let list = [];
    tagList.forEach((element) => {
      list.push({
        tagName: element.tagName,
        tagId: element.tagId,
        myPostColor: "false",
      });
    });
    this.setState({
      tagList: list,
    });
  };
  requestPage = () => {};
}

const mapDispacthToProps = (dispatch) => {
  return {
    getQuestionsList: (tpage, groupId, id) =>
      dispatch(getQuestionsList(tpage, groupId, id)),
    askQuestionListV2: (tpage, groupId, id) =>
      dispatch(askQuestionListV2(tpage, groupId, id)),
    searchForumAskFilter: (data) => dispatch(searchForumAskFilter(data)),
    forumTagList: (data) => dispatch(forumTagList(data)),
    getRecentlyViewedQuestionsList: () =>
      dispatch(getRecentlyViewedQuestionsList()),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
    getAllMembersDetails: (id, type, page, status, searchData) =>
      dispatch(getAllMembersDetails(id, type, page, status, searchData)),
    getGroupRequestList: (data, need) =>
      dispatch(getGroupRequestList(data, need)),
  };
};
const mapStateToProps = function (state) {
  return {
    // pageNo: state.topquestList.formQuestions_totalItems,
    formQuestions: state.topquestList.formQuestions,
    QandAquestionList: state.topquestList.list,
    QandAquestTotalitems: state.topquestList.totalItems,
    tagList: state.commonApi.taglist.data,
    loginDetails: state.loginDetailsApi.loginDetails,
    formSearchQuestions: state.topquestList.formSearchQuestions,
    pageNo: state.topquestList.formSearchQuestions_totalItems,
    formTagList_Data: state.topquestList.formTagList,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    userWorkSpace: state.loginDetailsApi.loginDetails?.userWorkSpace,
    allMembersList: state.subjects.allMembersList,
    totalRequest: state.subjects.totalRequest,
    totalCommunityUser: state.subjects.totalCommunityUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ForumContainer)));
