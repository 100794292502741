import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as APPCONFIG from "../../config/app-config.js";
import dummyimage from "../../img//mentor-default.jpg";
import { FiFastForward } from "react-icons/fi";
import PostService from "../../api/PostService_old";
import {
  getNavigationType,
  needPhotVerification,
} from "../../redux/actions/commonApiActions";
import { BiMessageSquareDetail, BiVideo } from "react-icons/bi";
import { setSelectedUserAction } from "../../redux/actions/chatActions";
import MentorQuestionModel from "./MentorQuestionModel";
import { toast } from "react-toastify";
import { Menu, MenuItem } from "@material-ui/core";
import { DeleteForever, Edit } from "@material-ui/icons";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Swal from "sweetalert2";
import { giveUserAction } from "../../utils";
import { MdOutlinePending } from "react-icons/md";

class GroupCardBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileData: [],
      request: "",
      mentorModel: false,
    };
    this.getMessageFiles = this.getMessageFiles.bind(this);
  }

  componentDidMount() {
    this.getMessageFiles(this.props.mediaFiles);
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.mediaFiles !== nextProps.mediaFiles) {
      this.getMessageFiles(nextProps.mediaFiles);
    }
  }

  render() {
    const { mentorData } = this.props;

    const { t } = this.props;

    return (
      <>
        <div class="groupCard-body">
          {mentorData?.mentorId && (
            <>
              <div class="d-flex align-items-center flex-wrap">
                <div
                  className="d-flex align-items-center "
                  style={{ gap: "0.5rem" }}
                >
                  <div style={{ width: "7rem", height: "auto" }}>
                    <img
                      className="post-avatar-img rounded-circle"
                      src={
                        mentorData.userId
                          ? `${APPCONFIG.API_URL}api/getUserPhoto/${
                              mentorData.userId
                            }/${"name"}`
                          : dummyimage
                      }
                      onClick={() => this.showFriendsProfile(mentorData.userId)}
                      alt="img"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyimage;
                      }}
                    />

                    {/* {this.state.fileData &&
                      this.state.fileData.slice(0, 1).map((photo) => (
                        <img
                          src={photo}
                          style={{ width: "140px", height: "50px" }}
                          class="groupCard-avatar-img groupCard-border-0"
                          onClick={() => this.setState({ isOpen: true })}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = dummyimage;
                          }}
                        />
                      ))} */}
                  </div>

                  <div class="groupCard-ms-md-4">
                    <h1
                      class="h5 mb-0 groupCard-h1"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.showFriendsProfile(mentorData.userId)}
                    >
                      {mentorData.userName}{" "}
                    </h1>
                    <p className="sub-font">{mentorData.companyName}</p>
                    <p className="sub-font">{mentorData.exp}</p>
                    {!mentorData.owner && (
                      <button className="mt-1" onClick={this.messageFriend}>
                        <BiMessageSquareDetail className="message-icon" />{" "}
                        {t("Message")}
                      </button>
                    )}
                  </div>
                </div>
                <div className="ml-auto">
                  {!giveUserAction(this.props.loginDetails?.profile_complete) &&
                    mentorData.owner && (
                      <div
                        className="postMoreInfo"
                        style={{ cursor: "pointer", float: "right" }}
                      >
                        <MoreHorizIcon
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => {
                            this.handleMenuClick(e);
                          }}
                        />{" "}
                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.menuState}
                          keepMounted
                          open={this.state.menuState}
                          onClose={this.handleMenuClose}
                        >
                          <div
                            className="menuCard"
                            onClick={() => this.ApplyForm(mentorData.mentorId)}
                          >
                            <Edit
                              style={{ cursor: "pointer", marginTop: "6px" }}
                            />{" "}
                            <MenuItem>{t("Update")}</MenuItem>
                          </div>
                          <div
                            className="menuCard"
                            onClick={(e) =>
                              this.deleteMentor(mentorData.mentorId)
                            }
                          >
                            <DeleteForever
                              style={{ cursor: "pointer", marginTop: "6px" }}
                            />{" "}
                            <MenuItem>{t("Delete")}</MenuItem>
                          </div>
                        </Menu>
                      </div>

                      // <button onClick={() => this.ApplyForm(mentorData.mentorId)}>
                      //   {t("Update your profile")}
                      // </button>
                    )}
                  {!mentorData.owner &&
                    this.state.request != "requested" &&
                    (mentorData.type == "nothing" ||
                      mentorData.type == "rejected") && (
                      <button onClick={this.requestForm}>
                        <>
                          {" "}
                          <FiFastForward
                            style={{
                              cursor: "pointer",
                              width: "20px",
                              height: "20px",
                              color: "#ffffff",
                            }}
                          />
                        </>
                        {t("Request")}
                      </button>
                    )}
                  {!mentorData.owner &&
                    mentorData.type == "accepted" &&
                    !mentorData.meetingAvailable && (
                      <p className="greenLabel">
                        {t(
                          "Congratulations! You’re selected, mentor shortly contact you"
                        )}
                      </p>
                    )}
                  {!mentorData.owner &&
                    mentorData.type == "accepted" &&
                    mentorData.meetingAvailable && (
                      <span className="bg-color d-flex">
                        <BiVideo
                          style={{
                            height: "24px",
                            width: "auto",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        />
                        <p className="lineUp ">
                          Mentor Scheduled meeting for you{" "}
                        </p>
                      </span>
                    )}
                  {mentorData.owner && !mentorData.accept && (
                    <button
                      style={{
                        background: "blue ",
                        border: "none",
                        cursor: "not-allowed",
                      }}
                      className="mr-2 text-white"
                    >
                      <MdOutlinePending className="mr-2" />
                      Pending Approval
                    </button>
                  )}
                  {/* {!mentorData.owner && mentorData.type == "rejected" && (
                    <p style={{ color: "gray" }}>
                      {t("Slot filled please choose another slot.")}
                    </p>
                  )} */}
                  {!mentorData.owner &&
                    (mentorData.type === "requested" ||
                      this.state.request === "requested") && (
                      <button
                        style={{
                          cursor: "not-allowed",
                          background: "gray",
                          border: "none",
                        }}
                      >
                        {t("Requested")}
                      </button>
                    )}
                </div>
              </div>

              {/* <hr /> */}
              {/* <Grid container spacing={1}> */}
              <div className="hashtag-style mb-0">
                {mentorData.specialization_value &&
                  mentorData.specialization_value.map((data) => (
                    <span class="hash-bg-color">#{data.specialization}</span>
                  ))}{" "}
              </div>
              {this.state.mentorModel && mentorData.questions && (
                <MentorQuestionModel
                  mentorId={mentorData.mentorId}
                  closeModel={this.mentorModelState}
                  questions={mentorData.questions}
                  onSubmitAssessment={this.onSubmitAssessment}
                  changeStatus={this.requestStatus}
                  timeSlots={mentorData.timeSlots ? mentorData.timeSlots : []}
                  selfAssessment={this.props.mentorData.selfAssessment}
                />
              )}
            </>
          )}
        </div>
      </>
    );
  }
  deleteMentor = (id) => {
    this.handleMenuClose();
    Swal.fire({
      title: "Are you sure to  delete this slot !",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: false,
    }).then((result) => {
      if (result.value) {
        var data = {
          mentorId: id,
        };
        PostService.mentorDelete(data)
          .then((response) => {
            toast.success(
              `${this.props.t("Mentor slot deleted successfully")}`
            );
            this.props.history.push(`/${this.props.workspaceType}/mentors`);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  handleMenuClick = (event, userId) => {
    this.setState({ menuState: event.target });
  };
  handleMenuClose = () => {
    this.setState({
      menuState: false,
    });
  };
  requestStatus = () => {
    this.setState({
      request: "requested",
    });
  };
  onSubmitAssessment = (data) => {
    console.log(data);
  };
  mentorModelState = () => {
    this.setState({
      mentorModel: !this.state.mentorModel,
    });
  };
  messageFriend = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      var userData = {
        userId: this.props.mentorData.userId,
        userName: this.props.mentorData.firstName,
        userType: 1,
        profilePhoto: "",
      };
      this.props.setSelectedUserAction(userData);
    }
  };

  closeInvitePage() {
    this.setState({
      invitePopup: false,
    });
  }

  handleChange = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null,
      emailVerification: evt.target.value,
    });
  };

  // handleDelete = (item) => {
  //   this.setState({
  //     items: this.state.items.filter((i) => i !== item),
  //   });
  // };

  ApplyForm = () => {
    // if (this.props.owner) {
    this.props.history.push(
      `/${this.props.workspaceType}/mentor-update/${this.props.mentorData.mentorId}`
    );
    //  }
  };
  requestForm = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      if (
        this.props.mentorData.selfAssessment ||
        this.props.mentorData.timeSlots.length !== 0
      ) {
        this.mentorModelState();
      } else {
        var data = {
          mentorId: this.props.mentorData.mentorId,
        };
        PostService.requestToMentor(data)
          .then((response) => {
            toast.success(`${this.props.t("Request sent successfully")}`);
            this.setState({
              request: "requested",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  async getMessageFiles(files, type) {
    if (files) {
      if (files && files.length !== 0) {
        let fileList = [];
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            if (files[i].fileType == "image") {
              fileList.push(
                `${APPCONFIG.API_URL}auth/image/${files[i].fileName}`
              );
            }
          }

          await this.setState({ fileData: fileList });
        } else {
          fileList.push(dummyimage);
          await this.setState({ fileData: fileList });
        }
      } else {
        let fileList = [];
        if (files.length > 0) {
        } else {
          fileList.push(dummyimage);
          await this.setState({ fileData: fileList });
        }
      }
    }
  }
  showFriendsProfile = (userId) => {
    this.props.getNavigationType(1);
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  };
}

const mapStateToProps = function (state) {
  return {
    allMembersList: state.subjects.allMembersList,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    mentorData: state.subjects.mentorDetails,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getNavigationType: (type) => dispatch(getNavigationType(type)),
    setSelectedUserAction: (data) => dispatch(setSelectedUserAction(data)),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(GroupCardBody)));
