import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../Login/Login.css";
import workspaceImg from "../../images/Entrepreneurship.png";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { FiFastForward } from "react-icons/fi";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
  workSpaceUserLogin,
  workSpaceUserUpdate,
} from "../../redux/actions/loginDetailsActions";
import {
  getWorkSpaceOptionTypes,
  workSpaceLoginStats,
} from "../../redux/actions/commonApiActions";
import { toast, ToastContainer } from "react-toastify";
import ProfileService from "../../api/ProfileService";
import { getAllNotSawAskHelpListCount } from "../../redux/actions/myRequestActions";
import EditableDatePicker from "../../components/EditableDatePicker";
import Swal from "sweetalert2";
import AuthService from "../../session/AuthService";
class LoginUserWorkSpace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workSpaceId: "",
      age: "",
      shortBio: "",
    };
  }
  componentDidMount() {
    console.log("loginDetails", this.props.loginDetails);
    if (this.props.loginDetails) {
      const id = this.props.loginDetails?.userWorkSpaceList.find(
        (item) => item.slug === this.props.loginDetails?.defaultWorkSpace
      );
      console.log("default Work space", id);
      if (id) {
        this.setState({
          workSpaceId: id.workSpaceId,
        });
      }
    }
  }
  backToLogin = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to back to login?",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        AuthService.removeAuth();
        AuthService.triggerLogout();
      }
    });
  };
  render() {
    const { t } = this.props;
    return (
      <div class="workspace-card">
        <ToastContainer position="top-right" autoClose={5000} />

        <div className="workspace-card-wrap1 mr-2">
          <img src={workspaceImg} class="card__image" alt="brown couch" />
        </div>
        <div className="workspace-card-wrap2">
          <h5 className="text-center mb-4 font-weight-bold">
            Update Your Profile Details
          </h5>
          <div className="d-flex flex-column" style={{ gap: "1rem" }}>
            {this.props.userWorkSpaceList?.length > 1 && (
              <div>
                <p>{t("Select your workspace")} *</p>
                <TextField
                  id="dropdown"
                  select
                  label=""
                  value={this.state.workSpaceId}
                  onChange={this.workSpaceDropDown}
                  variant="outlined"
                  fullWidth={true}
                  style={{ width: "91%" }}
                >
                  {this.props.userWorkSpaceList &&
                    this.props.userWorkSpaceList.map((option, index) => (
                      <MenuItem
                        key={option.workSpaceName}
                        value={option.workSpaceId}
                      >
                        {option.workSpaceName === "Default"
                          ? "Yuukke"
                          : option.workSpaceName}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
            )}
            <div>
              <EditableDatePicker
                label={t("Date of birth") + " *"}
                value={this.state.age}
                getSelectedValue={(value) => {
                  this.setState({
                    age: value,
                    disabledSaveBtn: false,
                  });
                }}
                handleClickEdits={this.handleClickEdit}
              />
            </div>
            <div>
              <p>{t("Share about your bio") + " * "}</p>
              <textarea
                className="formInputsDescriptions-new"
                name="shortBio"
                rows="4"
                cols="50"
                value={this.state.shortBio}
                onChange={this.handleForm}
              />
            </div>
            <div className="d-flex flex-wrap justify-content-between">
              <a
                href="#"
                onClick={() => this.backToLogin()}
                style={{ textDecoration: "underline" }}
              >
                Back to login
              </a>
              <button class="workspace-btn" onClick={this.gotoHome}>
                <FiFastForward /> Go to home
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  handleClickEdit = () => {
    this.setState({ savebtnState: false });
  };
  handleForm = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  gotoHome = () => {
    if (
      this.props.userWorkSpaceList?.length > 2 &&
      this.state.workSpaceId === ""
    ) {
      toast.warning("Please choose workspace");
    } else if (!this.state.age) {
      toast.warning("Please select date of birth ..");
    } else if (!this.state.shortBio) {
      toast.warning("Please add your shot bio ..");
    } else {
      if (this.state.workSpaceId === 0) {
        // this.props.history.push(`/photo-set-up`);
        console.log("N_WORKSPACE_UPDATE", "default");
        sessionStorage.setItem("WORKSPACE", "default");
        var data = {
          dob: this.state.age,
          biography: this.state.shortBio,
          workSpaceType: 0,
        };
        this.props.workSpaceUserUpdate(data);
        this.enterDefaultWorkSpace(0);
        this.props.workSpaceLoginStats(true);
        this.props.getWorkSpaceOptionTypes();
        this.props.history.push(`/${"default"}/home`);
      } else {
        var item =
          this.props.userWorkSpaceList &&
          this.props.userWorkSpaceList.find(
            (item) => item.workSpaceId === this.state.workSpaceId
          );
        var data = {};
        if (this.props.userWorkSpaceList?.length > 1) {
          data = {
            dob: this.state.age,
            biography: this.state.shortBio,
            workSpaceType: this.state.workSpaceId,
          };
        } else {
          data = { dob: this.state.age, biography: this.state.shortBio };
        }
        console.log("login- kyc 3", data);
        const slug = item ? item?.slug : "default";
        console.log("N_WORKSPACE_UPDATE", slug);
        sessionStorage.setItem("WORKSPACE", slug);
        console.log("Slug", slug);
        this.props.workSpaceUserUpdate(data);
        // this.props.workSpaceUserLogin(this.state.workSpaceId);
        this.props.workSpaceLoginStats(true);
        this.enterDefaultWorkSpace(this.state.workSpaceId);
        this.props.getWorkSpaceOptionTypes();
        // this.props.getAllNotSawAskHelpListCount();

        this.props.history.push(`/${slug}/home`);
      }
    }
  };
  workSpaceDropDown = (e) => {
    this.setState({
      workSpaceId: e.target.value,
    });
  };
  enterDefaultWorkSpace = (id) => {
    let data = {
      workSpaceType: id,
    };
    ProfileService.default_workspace_change(data)
      .then((response) => {
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const mapStateToProps = (state) => ({
  userWorkSpaceList: state.loginDetailsApi.loginDetails?.userWorkSpaceList,
  workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  loginDetails: state.loginDetailsApi.loginDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {
    workSpaceUserUpdate: (data) => dispatch(workSpaceUserUpdate(data)),
    workSpaceUserLogin: (id) => dispatch(workSpaceUserLogin(id)),
    workSpaceLoginStats: (status) => dispatch(workSpaceLoginStats(status)),
    getWorkSpaceOptionTypes: () => dispatch(getWorkSpaceOptionTypes()),
    getAllNotSawAskHelpListCount: () =>
      dispatch(getAllNotSawAskHelpListCount()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(LoginUserWorkSpace)));
