import auth from "./AuthAPI.js";
import Http from "./Http.js";
import * as APPCONFIG from "../config/app-config.js";
import api from "./BaseAPI.js";

let CACHE_RESOURCE = {};

class PostService {
  //job request
  jobRequest(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveRequestJobV2/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  getPost(formValues) {
    console.log(formValues);
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getpostbyuser`,
      data: JSON.stringify(formValues),
    });
  }
  savePost(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createposttext`,
      data: formValues,
      config,
    });
  }
  savePostV2(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createposttextV2`,
      data: formValues,
      config,
    });
  }
  saveImagePost(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createpostimage`,
      data: formValues,
      config,
    });
  }
  saveImagePostV2(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createpostimageV2`,
      data: formValues,
      config,
    });
  }
  saveVideoPost(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createpostvideo`,
      data: formValues,
      config,
    });
  }
  createpostLike(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createpostLike`,
      data: formValues,
    });
  }
  createpostCommands(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/createPostComment`,
      data: formValues,
    });
  }
  getcommendbypost(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getcommendbypost`,
      data: formValues,
    });
  }
  sharePost(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/sharepost`,
      data: formValues,
    });
  }
  deletePost(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deletePost`,
      data: formValues,
    });
  }
  reportPost(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/reportByUser`,
      data: formValues,
    });
  }

  reportByUserComment(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/reportByUserComment`,
      data: formValues,
    });
  }

  /**** USER PHOTO CACHE & API  */

  setResourceCache(formValues, response) {
    //console.log("Set - Cache Resource :" + formValues.resourceString);
    CACHE_RESOURCE[formValues.resourceString] = response;
  }

  getResourceCache(formValues) {
    if (CACHE_RESOURCE[formValues.resourceString]) {
      //console.log("Loading From Cache Resource :" + formValues.resourceString);
      return CACHE_RESOURCE[formValues.resourceString];
    } else {
      return null;
    }
  }

  getBase64String(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getResourceString`,
      data: formValues,
    });
  }

  /**** USER PHOTO CACHE & API  */
  getPostBySchool(formValues) {
    console.log(formValues);
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getPostBySchool`,
      data: JSON.stringify(formValues),
    });
  }
  deleteCommends(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteComment`,
      data: formValues,
    });
  }
  editComment(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/updateComment`,
      data: formValues,
    });
  }

  getAnnouncementonlyOpen() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/getAnnouncementonlyOpenV1/${Http.WORK_SPACE()}`,
    });
  }

  saveAnnouncementFeedBack(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveAnnouncementFeedBack`,
      data: formValues,
    });
  }

  getAnnouncementFeedbackList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAnnouncementFeedbackListByUserId`,
      data: formValues,
    });
  }

  validUser(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/validUser`,
      data: formValues,
      config,
    });
  }
  saveBusinessProfile(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveBusinessProfile`,
      data: formValues,
      config,
    });
  }
  saveRequestHelpReply(formValues) {
    console.log(formValues);
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveRequestHelpReply`,
      data: JSON.stringify(formValues),
    });
  }
  getAnnouncementonlyOpenById(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getAnnouncementonlyOpenByIdV1`,
      data: formValues,
    });
  }
  getUserByMentionType(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getUserByMentionType/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  getWorkSpaceOptionTypes() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/work_space_details/${Http.WORK_SPACE()}`,
    });
  }
  deleteAnnouncement(track) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deleteAnnouncement`,
      data: JSON.stringify(track),
    });
    // return axios.post(API_URL + `getallSubjectByid`, subject)
  }
  saveOrUpdateAnnouncement(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/saveOrUpdateAnnouncementv2/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  getAnnouncementByWorkspace() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/getAnnouncementByWorkspace/${Http.WORK_SPACE()}`,
    });
  }
  jobRequestUpdate(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/askHelp/update`,
      data: formValues,
      config,
    });
  }
  updateEventCard(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/eventDetail/saveOrUpdate`,
      data: formValues,
      config,
    });
  }
  productPost(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/productPost/create/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  getAllProducts(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/marketplace/getAllProducts`,
      data: formValues,
      config,
    });
  }
  getMeetingList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/mentor/getAllMeetingDetails
 `,
      data: formValues,
    });
  }
  getEventDetails(url, authenticated) {
    if (authenticated) {
      return auth({
        method: "get",
        headers: Http.AUTH_HEADERS(),
        url: url,
      });
    } else {
      return api({
        method: "get",
        headers: Http.HEADERS(),
        url: url,
      });
    }
  }
  saveImageFile(formValues, config) {
    return api({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}auth/storeMediaTypeFile`,
      data: formValues,
      config,
    });
  }
  getTemplateList() {
    return api({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/get_calendar_template`,
    });
  }
}

export default new PostService();
