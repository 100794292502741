import PostService_old from "../../api/PostService_old";
import ProfileService from "../../api/ProfileService";
import {
  GET_ALL_MENTOR_LIST,
  GET_MENTOR_INDUSTRY,
  GET_MENTOR_SPECIALIZATION,
  MENTOR_LIST_LOADER,
} from "../types";

export const getMentorSpecialization = () => async (dispatch) => {
  ProfileService.mentorSpecialization()
    .then((response) => {
      let result = response.data.data.specialization;
      const filterSpecList = result.map((item) => {
        return { id: item.id, name: item.name, isActive: false };
      });
      dispatch({
        type: GET_MENTOR_SPECIALIZATION,
        payload: filterSpecList,
      });
    })
    .catch((err) => {
      console.log("er", err);
    });
};

export const getMentorIndustry = () => async (dispatch) => {
  ProfileService.mentorIndustry()
    .then((response) => {
      let result = response.data.data.industry;
      const filterIndList = result.map((item) => {
        return { id: item.id, name: item.name, isActive: false };
      });
      dispatch({
        type: GET_MENTOR_INDUSTRY,
        payload: filterIndList,
      });
    })
    .catch((err) => {
      console.log("er", err);
    });
};

export const getAllMentorList = (apiData, pagination) => async (dispatch) => {
  try {
    dispatch({
      type: MENTOR_LIST_LOADER,
      payload: true,
    });
    ProfileService.getMentorList(apiData)
      .then((response) => {
        var mentorList = response.data.data;
        if (mentorList) {
          dispatch({
            type: GET_ALL_MENTOR_LIST,
            payload: mentorList,
            pagination: pagination,
            page: apiData.page,
          });
        } else {
          dispatch({
            type: GET_ALL_MENTOR_LIST,
            payload: [],
            pagination: false,
            page: 0,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch({
          type: GET_ALL_MENTOR_LIST,
          payload: [],
          pagination: false,
          page: 0,
        });
      });
  } catch (e) {
    console.log(e);
  }
};
