import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EditableDatePicker from "./EditableDatePicker";
import { withRouter } from "react-router";
import { Card, Modal } from "react-bootstrap";
import EditableTextField from "./EditableTextField";
import EditableDropdown from "./EditableDropdown";
import DatePicker from "react-multi-date-picker";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";

const UserProfileUpdate = (props) => {
  const { t } = props;
  const { countryId, dob, profession_Id, location, phoneNumber } = useSelector(
    (state) => state.loginDetailsApi.loginDetails
  );
  const { professionList, countryName } = useSelector(
    (state) => state.commonApi
  );

  const [state, setState] = useState({
    countryId: countryId ? countryId : "",
    phoneNumber: phoneNumber ? phoneNumber : "",
    location: location ? location : "",
    dob: dob ? dob : "",
    professionId: profession_Id ? profession_Id : "",
    country: "",
  });
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const updateDetails = () => {
    console.log("country", countryName);
    var country;
    if (!state.dob || !state.professionId || !state.location) {
      toast.warning("Please fill required details");
    } else {
      if (state.countryId) {
        let data = countryName.find((item) => item.id === state.countryId);
        country = data?.nicename;
      }
      props.userDetails(
        state.countryId,
        state.phoneNumber,
        state.location,
        state.dob,
        state.professionId,
        country
      );
    }
  };
  return (
    <Modal show="true" onHide={props.handleClose}>
      <Modal.Header>
        {t("Update Profile Details")}
        <AiOutlineCloseCircle
          onClick={props.handleClose}
          style={{
            cursor: "pointer",
            float: "right",
            marginBottom: "8px",
            width: "20px",
            height: "20px",
            color: "#000000",
          }}
        />
      </Modal.Header>
      <Card className="profileCard">
        <Card.Body>
          <div className="formFieldGroup p-4">
            {!dob && (
              <>
                <label
                  className="mt-2"
                  style={{ color: "#999999", fontSize: "18px" }}
                >
                  {t("Date of Birth")} *
                </label>
                <DatePicker
                  maxDate={new Date()}
                  value={state.dob}
                  onChange={(e) => updateState("dob", e.toString())}
                  format="DD/MM/YYYY"
                  style={{
                    height: "26px",
                    width: "100%",
                    fontSize: "14px",
                    padding: "18px 12px",
                  }}
                  editable={false}
                  // inputClass="custom-input"
                />
              </>
            )}
            {!profession_Id && (
              <>
                <label
                  className="mt-2"
                  style={{ color: "#999999", fontSize: "18px" }}
                >
                  {t("Profession")} *
                </label>
                <TextField
                  id="dropdown"
                  select
                  label=""
                  value={state.professionId}
                  onChange={(e) => {
                    updateState("professionId", e.target.value);
                  }}
                  variant="outlined"
                  fullWidth={true}
                >
                  {professionList.map((option, index) => (
                    <MenuItem
                      key={option.professionId}
                      value={option.professionId}
                    >
                      {option.professionName}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}
            {!countryId && (
              <>
                <label
                  className="mt-2"
                  style={{ color: "#999999", fontSize: "18px" }}
                >
                  {t("Country")}
                </label>
                <TextField
                  id="dropdown"
                  select
                  label=""
                  value={state.countryId}
                  onChange={(e) => {
                    updateState("countryId", e.target.value);
                  }}
                  variant="outlined"
                  fullWidth={true}
                >
                  {countryName.map((option, index) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.nicename}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}
            {!location && (
              <>
                <label
                  className="mt-2"
                  style={{ color: "#999999", fontSize: "18px" }}
                >
                  {t("Location")} *
                </label>
                <TextField
                  id="textField"
                  label=""
                  variant="outlined"
                  value={state.location}
                  onChange={(e) => {
                    updateState("location", e.target.value);
                  }}
                  fullWidth={true}
                />
              </>
            )}
            {!phoneNumber && (
              <>
                <label
                  className="mt-2"
                  style={{ color: "#999999", fontSize: "18px" }}
                >
                  {t("Phone Number")}
                </label>
                <TextField
                  id="textField"
                  type="number"
                  label=""
                  variant="outlined"
                  value={state.phoneNumber}
                  onChange={(e) => {
                    updateState("phoneNumber", e.target.value);
                  }}
                  fullWidth={true}
                />
              </>
            )}
            <button className="w-100 mt-4" onClick={() => updateDetails()}>
              Update Details
            </button>
          </div>
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default withTranslation()(withRouter(UserProfileUpdate));
