import { Component } from "react";
import { Modal } from "react-bootstrap";

class FullPageLoading extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div className="customLoading">
          <Modal className="border-none customLoading" show="true" style={{ background: "white" }}>
            <Modal.Body
              className="border-none"
              style={{
                background: "tranparent",
                margin: "0 auto",
                border: "unset",
              }}
            >
              <img src="/loading.gif" alt="loading" />
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

export default FullPageLoading;
