import HomeService from "../../api/HomeService";
import {
  DIRECTORY_LIST,
  SINGLE_DIRECTORY_LIST,
  DIRECTORY_LOADER,
  DIRECTORY_FAVOURITE,
  DIRECTORY_CATEGORY_LIST,
} from "../types";

export const getAllDirectory =
  (values, pageNo, pagination) => async (dispatch) => {
    try {
      let data = {
        page: pageNo,
        searchKey: values.searchKey,
        owner: values.owner,
        category: values.category,
        location: values.location,
        userId: values.userId,
        bookmark: values.bookmark,
      };
      if (values?.type) {
        data.type = values.type;
      }
      dispatch({
        type: "DIRECTORY_LOADER",
        payload: true,
      });
      HomeService.getAllDirectoryList(data)
        .then((response) => {
          if (response.data.data.directoryList) {
            dispatch({
              type: DIRECTORY_LIST,
              payload: response.data.data,
              pagination: pagination,
              pageNo: pageNo,
            });
          } else {
            dispatch({
              type: DIRECTORY_LIST,
              payload: [],
              pagination: false,
              pageNo: pageNo,
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  };

export const getSingleDirectory = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: "DIRECTORY_LOADER",
      payload: true,
    });
    let data = {
      slug: slug,
    };
    HomeService.getSingleDirectory(data)
      .then((response) => {
        dispatch({
          type: SINGLE_DIRECTORY_LIST,
          payload: response.data.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: SINGLE_DIRECTORY_LIST,
          payload: {},
        });
      });
  } catch (e) {
    console.error(e);
  }
};

export const userFavourite = (data) => async (dispatch) => {
  HomeService.addUserFavourite(data)
    .then((item) => {
      dispatch({
        type: DIRECTORY_FAVOURITE,
        payload: data,
      });
    })
    .catch((e) => {
      console.error(e);
    });
};

export const getDirectoryCategoryList = (key) => async (dispatch) => {
  let data = {
    searchKey: key ? key : "",
  };
  HomeService.getDirCategoryList(data)
    .then((response) => {
      const value =
        response.data.data &&
        response.data.data.map((item) => {
          return {
            label: item.categoryName,
            value: item.categoryValue,
            id: item.id,
          };
        });
      console.log("value", value);
      dispatch({
        type: DIRECTORY_CATEGORY_LIST,
        payload: value ? value : [],
      });
    })
    .catch((e) => {
      console.log("e", e);
    });
};
