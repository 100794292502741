import { Grid, Container } from "@material-ui/core";
import React, { createRef, Component } from "react";
import UserInfo from "./UserInfo";
import AddIcon from "@material-ui/icons/Add";
import AreaOfInterestList from "./AreaOfInterestList";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { connect } from "react-redux";
//import dummyimage from "../../img/avatar_dummy.png";
import dummyimage from "../../img/default_avatar.jpg";
import { API_URL } from "../../config/app-config";
import ChatService from "../../api/ChatService";
import { withRouter } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import ProfileService from "../../api/ProfileService";
import RequestListCard from "./JobRequestListCard";
import * as APPCONFIG from "../../config/app-config.js";

var LibraryQuestions = [];
var page = 0;
class GetJobRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMessage: false,
      chatId: null,
      chatUserId: this.props.match.params.id,
      chatUserName: "",
    };

    this.scrollRef = React.createRef();

    this.getAreaofInterestDetails = this.getAreaofInterestDetails.bind(this);
    this.getJobRequestList = this.getJobRequestList.bind(this);
    this.back = this.back.bind(this);
  }
  componentDidMount() {
    this.getAllAreaofInterest();
    window.addEventListener("scroll", this.scrollTrackFunc);

    LibraryQuestions = this.props.FriendsList.filter(
      (data) => data.userId == this.props.match.params.id
    );
  }

  componentDidUpdate() {
    if (this.refs.mainPanel) {
      this.refs.scrollRef.scrollTop = 0;
    } else {
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollTrackFunc);
  }

  scrollBottom = () => {
    this.scrollRef.scrollIntoView({ behavior: "smooth" });
  };

  back() {
    this.props.history.push(`/${this.props.workspaceType}/home`);
  }

  render() {
    const data = this.props.loginDetails.profile_uuid;
    const userName = this.props.loginDetails.dName;
    const profileImage = data
      ? `${APPCONFIG.API_URL}auth/image/${data}`
      : dummyimage;

    // const profileImage = data ? `data:image;base64,${data}` : dummyimage;
    const { statusLabel, chatId, isMessage } = this.state;
    return (
      <div className="screenInnerGap" style={{ paddingTop: "100px" }}>
        <div style={{ display: "flex" }}>
          <div>
            <h1 className="activeChatTitle">ALL JOB REQUEST LIST</h1>
          </div>
          <div style={{ marginLeft: "70%" }}>
            <button onClick={this.back}>BACK TO HOME</button>
          </div>
        </div>
        <div className="chatMainContainer">
          {/* <Container> */}
          <div className="chatContainer">
            <Grid container spacing={1}>
              {window.innerWidth <= 768 && !isMessage ? (
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <div className="ChatListDetailsWrapper">
                    <div className="activeChats flexDefault">
                      <h4 className="activeChatTitle">JOB TYPE</h4>
                    </div>

                    <div className="chatListsWrapper">
                      {this.state.areaofinterestsList &&
                        this.state.areaofinterestsList.map((friends, index) => (
                          <AreaOfInterestList
                            key={1}
                            userName={friends.name}
                            // tagLine={"Typing…"}
                            // date="08:05"
                            isActive={true}
                            onClick={() => this.getJobRequestList(friends.id)}
                          />
                        ))}
                    </div>
                  </div>
                </Grid>
              ) : (
                window.innerWidth > 768 && (
                  <Grid item lg={3} md={3} sm={3} xs={3}>
                    <div className="ChatListDetailsWrapper">
                      <div className="activeChats flexDefault">
                        <h4
                          className="activeChatTitle"
                          style={{ color: "black" }}
                        >
                          JOB TYPE
                        </h4>
                      </div>

                      <div className="chatListsWrapper">
                        {this.state.areaofinterestsList &&
                          this.state.areaofinterestsList.map(
                            (friends, index) => (
                              <AreaOfInterestList
                                key={1}
                                userName={friends.name}
                                // status={friends.status}
                                // tagLine={"Typing…"}
                                // date="08:05"
                                isActive={true}
                                onClick={() =>
                                  this.getJobRequestList(friends.id)
                                }
                              />
                            )
                          )}
                      </div>
                    </div>
                  </Grid>
                )
              )}
              {window.innerWidth <= 768 && isMessage ? (
                <Grid item lg={9} md={9} sm={9} xs={9}>
                  <div className="messageMainWrapper">
                    <div className="messageHeaderBackBtn">
                      <ArrowBackIcon />
                    </div>

                    <div className="messageUserNameWrapoer">
                      <p className="messageHeaderUserName">
                        {this.state.chatUserName}
                      </p>
                    </div>

                    <div className="listContentMessage1" ref={this.scrollRef}>
                      {this.state.requestList &&
                        this.state.requestList.reverse().map((chat, index) => (
                          <div>
                            <div className="messagesBodyWrapper">
                              <RequestListCard
                                name={chat.name}
                                createdDate={chat.createdDate}
                                qualification={chat.qualification}
                                displayName={chat.user.displayName}
                                userId={chat.userId}
                                imgUrl={
                                  API_URL +
                                  "api/getUserPhoto/" +
                                  chat.userId +
                                  "/" +
                                  chat.user.displayName
                                }
                                imgAlt={chat.user.displayName}
                              ></RequestListCard>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </Grid>
              ) : (
                window.innerWidth > 768 && (
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <div className="messageMainWrapper">
                      {/* <div className="messageUserNameWrapoer">
                          <p className="messageHeaderUserName">
                            {this.state.chatUserName}
                          </p>
                        </div> */}
                      <div className="listContentMessage1" ref={this.scrollRef}>
                        {this.state.requestList &&
                          this.state.requestList
                            .reverse()
                            .map((chat, index) => (
                              <Grid
                                container
                                spacing={1}
                                style={{ paddingTop: "5px" }}
                              >
                                <Grid item lg={10} md={10} sm={12} xs={12}>
                                  <RequestListCard
                                    name={chat.name}
                                    qualification={chat.qualification}
                                    createdDate={chat.createdDate}
                                    displayName={chat.user.displayName}
                                    userId={chat.userId}
                                    //     const profileImage = data ? `data:image;base64,${data}` : dummyimage;
                                    imgUrl={
                                      API_URL +
                                      "api/getUserPhoto/" +
                                      chat.userId +
                                      "/" +
                                      chat.user.displayName
                                    }
                                    imgAlt={chat.user.displayName}
                                  ></RequestListCard>
                                </Grid>
                              </Grid>
                            ))}
                      </div>
                    </div>
                  </Grid>
                )
              )}
            </Grid>
          </div>
          {/* </Container> */}
        </div>
      </div>
    );
  }

  getAllAreaofInterest() {
    let data = {
      data: "",
    };
    ProfileService.getAreaOfInterest(data)
      .then((response) => {
        var result = response.data.data.user;

        this.getAreaofInterestDetails(result);
      })
      .catch((error) => {
        // console.log(error)
      });
  }
  getJobRequestList(id) {
    let data = {
      jobType: id,
    };
    ProfileService.getJobRequestList(data)
      .then((response) => {
        var result = response.data.data.jobRequest;
        this.setState({ requestList: result });
      })
      .catch((error) => {
        // console.log(error)
      });
  }

  async getAreaofInterestDetails(areaofinterestList) {
    console.log("areaofinterestsList");
    console.log(areaofinterestList);
    if (areaofinterestList) {
      var lList = [];
      for (var i = 0; i < areaofinterestList.length; i++) {
        var obj = {};
        obj["id"] = areaofinterestList[i].areaId;
        obj["name"] = areaofinterestList[i].areaName;
        lList.push(obj);
      }

      this.setState({ areaofinterestsList: lList });
      console.log(lList);

      console.log(this.state.areaofinterestsList);
    }
  }
  scrollTrackFunc = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      this.props.FriendsList > this.props.FriendsList.length
    ) {
      page = page + 1;

      this.props.getChatList(this.state.chatUserId, page);
    }
  };
}

const mapStateToProps = function (state) {
  console.log("MESSAGES_LIST_IN_CHAT");

  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    FriendsList: state.commonApi.friendslist,
    friendsOnlineList: state.notification.friendsOnlinelist,
    chatList: state.chatList.messageList,
    workspaceType:
      state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withRouter(GetJobRequest));
