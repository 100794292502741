import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import AuthService from "../../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import * as imageConversion from "image-conversion";
import CheckBoxComponent from "./CheckBoxComponent";
import ProfileService from "../../../api/ProfileService";
import BlockUi from "react-block-ui";
import { ToastContainer, toast } from "react-toastify";
//import dummyimage from "../../../img/avatar_dummy.png";
import dummyimage from "../../../img/default_avatar.jpg";
import {
  getAllCommonSubjectList,
  getAllCommonCurriculumTrackList,
} from "../../../redux/actions/commonApiActions";
import * as APPCONFIG from "../../../config/app-config.js";
import { connect } from "react-redux";
import { storePersonalLanguage } from "../../../redux/actions/profileActions";
import { storeLoginDetails } from "../../../redux/actions/loginDetailsActions";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: false,
      languageData: [
        { languageId: 0, languageName: "English" },
        { languageId: 1, languageName: "French" },
        { languageId: 2, languageName: "Luxembourgish" },
      ],

      checkBoxArray: [
        {
          id: "5",
          order: "1",
          notifyId: "1",
          label: "Someone answered your post",
        },
        {
          id: "7",
          order: "2",
          notifyId: "2",
          label: "Someone upvoted your post",
        },
        {
          id: "8",
          order: "3",
          notifyId: "3",
          label: "Someone reacted your post",
        },
        { id: "6", order: "4", notifyId: "4", label: "New course" },
        { id: "4", order: "5", notifyId: "5", label: "New Announcement" },
        {
          id: "3",
          order: "6",
          notifyId: "6",
          label: "Call scheduled by your friend",
        },
        {
          id: "2",
          order: "7",
          notifyId: "7",
          label: "Your friend request is accepted",
        },
        {
          id: "1",
          order: "8",
          notifyId: "8",
          label: "You have a new friend request",
        },
      ],
      isChecked: false,

      checkBoxArraySetting: [
        {
          id: "5",
          order: "1",
          notifyId: "9",
          label: "Someone answered your post",
        },
        {
          id: "7",
          order: "2",
          notifyId: "10",
          label: "Someone upvoted your post",
        },
        {
          id: "8",
          order: "3",
          notifyId: "11",
          label: "Someone reacted your post",
        },
        { id: "6", order: "4", notifyId: "12", label: "New course" },
        { id: "4", order: "5", notifyId: "13", label: "New Announcement" },
        {
          id: "3",
          order: "6",
          notifyId: "14",
          label: "Call scheduled by your friend",
        },
        {
          id: "2",
          order: "7",
          notifyId: "15",
          label: "Your friend request is accepted",
        },
        {
          id: "1",
          order: "8",
          notifyId: "16",
          label: "You have a new friend request",
        },
      ],

      isCheckedEmail: false,
    };

    this.block = this.block.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.handlelanguage = this.handlelanguage.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleCheckBoxChangeEmail = this.handleCheckBoxChangeEmail.bind(this);
    this.onImagePreview = this.onImagePreview.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  componentDidMount() {
    const data = this.props.loginDetails.profile_uuid;
    const profileImage = data
      ? `${APPCONFIG.API_URL}auth/image/${data}`
      : dummyimage;
    this.setState({ userPhotoImage: profileImage });
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    if (
      this.props.loginDetails.notifiSettings &&
      this.props.loginDetails.notifiSettings.length == 0
    ) {
      this.setState({
        checkBoxArray: [
          {
            id: "5",
            order: "1",
            notifyId: "1",
            isChecked: true,
            label: "Someone answered your post",
          },
          {
            id: "7",
            order: "2",
            notifyId: "2",
            isChecked: true,
            label: "Someone upvoted your post",
          },
          {
            id: "8",
            order: "3",
            notifyId: "3",
            isChecked: true,
            label: "Someone reacted your post",
          },
          {
            id: "6",
            order: "4",
            notifyId: "4",
            isChecked: true,
            label: "New course",
          },
          {
            id: "4",
            order: "5",
            notifyId: "5",
            isChecked: true,
            label: "New Announcement",
          },
          {
            id: "3",
            order: "6",
            notifyId: "6",
            isChecked: true,
            label: "Call scheduled by your friend",
          },
          {
            id: "2",
            order: "7",
            notifyId: "7",
            isChecked: true,
            label: "Your friend request is accepted",
          },
          {
            id: "1",
            order: "8",
            notifyId: "8",
            isChecked: true,
            label: "You have a new friend request",
          },
        ],
      });
    } else {
      if (this.props.loginDetails.notifiSettings.length > 0) {
        const checkbox_array = [];
        var notifyId = 0;
        var fields = this.props.loginDetails.notifiSettings;
        fields.sort(function (a, b) {
          return parseInt(a.norder) - parseInt(b.norder);
        });
        console.log("ABCD");
        console.log(fields);

        for (let i = 0; i < fields.length; i++) {
          notifyId = notifyId + 1;
          checkbox_array.push({
            id: fields[i].id,
            isChecked: fields[i].isChecked,
            isCheckedEmail: fields[i].isCheckedEmail,
            label: fields[i].label,
            notificationSettingsId: fields[i].notificationSettingsId,
            userId: fields[i].userId,
            order: fields[i].norder,
            notifyId: notifyId,
          });
        }

        this.setState({ checkBoxArray: checkbox_array });
        console.log("NOTIFICATION COMPONENT DID MOUNT");
        console.log(checkbox_array);
        console.log(this.state.checkBoxArray);
      }
    }

    if (
      this.props.loginDetails.notifiSettings &&
      this.props.loginDetails.notifiSettings.length == 0
    ) {
      this.setState({
        checkBoxArraySetting: [
          {
            id: "5",
            order: "1",
            notifyId: "9",
            isCheckedEmail: false,
            label: "Someone answered your post",
          },
          {
            id: "7",
            order: "2",
            notifyId: "10",
            isCheckedEmail: false,
            label: "Someone upvoted your post",
          },
          {
            id: "8",
            order: "3",
            notifyId: "11",
            isCheckedEmail: false,
            label: "Someone reacted your post",
          },
          {
            id: "6",
            order: "4",
            notifyId: "12",
            isCheckedEmail: false,
            label: "New course",
          },
          {
            id: "4",
            order: "5",
            notifyId: "13",
            isCheckedEmail: false,
            label: "New Announcement",
          },
          {
            id: "3",
            order: "6",
            notifyId: "14",
            isCheckedEmail: true,
            label: "Call scheduled by your friend",
          },
          {
            id: "2",
            order: "7",
            notifyId: "15",
            isCheckedEmail: false,
            label: "Your friend request is accepted",
          },
          {
            id: "1",
            order: "8",
            notifyId: "16",
            isCheckedEmail: true,
            label: "You have a new friend request",
          },
        ],
      });
    } else {
      if (this.props.loginDetails.notifiSettings.length > 0) {
        const checkbox_array = [];
        var notifyId = 6;
        var fields = this.props.loginDetails.notifiSettings;
        fields.sort(function (a, b) {
          return parseInt(a.norder) - parseInt(b.norder);
        });
        console.log("ABCD EMAIL");
        console.log(fields);
        for (let i = 0; i < fields.length; i++) {
          notifyId = notifyId + 1;
          checkbox_array.push({
            id: fields[i].id,
            isChecked: fields[i].isChecked,
            isCheckedEmail: fields[i].isCheckedEmail,
            label: fields[i].label,
            notificationSettingsId: fields[i].notificationSettingsId,
            userId: fields[i].userId,
            order: fields[i].norder,
            notifyId: notifyId,
          });
        }
        this.setState({ checkBoxArraySetting: checkbox_array });
        console.log("EMAIL NOTIFICATION COMPONENT DID MOUNT");
        console.log(checkbox_array);
        console.log(this.state.checkBoxArraySetting);
      }
    }

    this.setState({ languageId: this.props.loginDetails.pLanguage });
  }

  render() {
    const { t } = this.props;

    return (
      <Container>
        <ToastContainer position="top-right" autoClose={5000} />

        <div className="profileMainWrapper">
          <h2 className="pageHeading">{t("Settings")}</h2>

          <BlockUi
            tag="div"
            blocking={this.state.blocking}
            message={t("Loading, please wait")}
            color="#A00030"
          >
            <div className="pageWrapper">
              <div stle={{ margin: "28px" }}>
                <Row className="settingsRow">
                  <Col xs="12 " sm="6">
                    <p className="settingsText">
                      <strong>{t("Language")}</strong>
                    </p>
                  </Col>
                  <Col xs="12 " sm="6">
                    <Form.Group className="formInput">
                      <Form.Control
                        as="select"
                        value={this.state.languageId}
                        required
                        id="languageId"
                        name="languageId"
                        onChange={this.handleForm}
                        custom
                      >
                        <option value="">Choose Language</option>
                        <option value="English">English</option>
                        <option value="French">French</option>
                        <option value="Luxembourgish">Luxembourgish</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="settingsRow">
                  <Col xs="12 " sm="6">
                    <p className="settingsText">
                      <strong>{t("Notifications")}</strong>
                    </p>
                  </Col>
                  <Col xs="12 " sm="6">
                    {this.state.checkBoxArray &&
                      this.state.checkBoxArray.map((object, index) => {
                        return (
                          <CheckBoxComponent
                            key={object.id}
                            check_box_id={object.notifyId}
                            check_box_name="check_box_name"
                            check_box_label={t(object.label)}
                            check_box_value={object.id}
                            isChecked={object.isChecked}
                            handleCheckBoxChange={this.handleCheckBoxChange} //you can pass id here but affect performance
                          />
                        );
                      })}
                  </Col>
                </Row>
                <Row className="settingsRow">
                  <Col xs="12 " sm="6">
                    <p className="settingsText">
                      <strong>{t("Email Notifications")}</strong>
                    </p>
                  </Col>
                  <Col xs="12 " sm="6">
                    {this.state.checkBoxArraySetting &&
                      this.state.checkBoxArraySetting.map((object, index) => {
                        return (
                          object.id != 7 &&
                          object.id != 8 && (
                            <CheckBoxComponent
                              key={object.id}
                              check_box_id={object.notifyId}
                              check_box_name="check_box_name"
                              check_box_label={t(object.label)}
                              check_box_value={object.id}
                              isChecked={object.isCheckedEmail}
                              handleCheckBoxChange={
                                this.handleCheckBoxChangeEmail
                              } //you can pass id here but affect performance
                            />
                          )
                        );
                      })}
                  </Col>
                </Row>
                <Row className="settingsRow">
                  <Col xs="12 " sm="6"></Col>
                  <Col xs="12 " sm="6">
                    <button onClick={(e) => this.handleSubmit(e)}>
                      {t("Save")}
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </BlockUi>
        </div>
      </Container>
    );
  }

  //This method is used to show block ui status
  block(blocking) {
    this.setState({ blocking: blocking });
  }

  //This method is used to handle input values
  handleForm(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  //This method is used for saving settings details
  //INPUT: USERID,PERSONAL LANGUAGE,CHECKED ID,CHECKED LABEL,CHECKED STATUS)
  //OUTPUT: It returns login details

  handleSubmit = (event) => {
    const { t } = this.props;
    event.preventDefault();
    event.preventDefault();
    event.stopPropagation();
    var id = [];
    var label = [];
    var isChecked = [];
    var order = [];
    for (var i = 0; i < this.state.checkBoxArray.length; i++) {
      id.push(this.state.checkBoxArray[i].id);
      label.push(this.state.checkBoxArray[i].label);
      order.push(this.state.checkBoxArray[i].order);

      if (this.state.checkBoxArray[i].isChecked) {
        isChecked.push(this.state.checkBoxArray[i].isChecked);
      } else {
        isChecked.push("false");
      }
    }

    var idEmail = [];
    var labelEmail = [];
    var isCheckedEmail = [];
    var order = [];
    for (var i = 0; i < this.state.checkBoxArraySetting.length; i++) {
      idEmail.push(this.state.checkBoxArraySetting[i].id);
      labelEmail.push(this.state.checkBoxArraySetting[i].label);
      order.push(this.state.checkBoxArraySetting[i].order);

      if (this.state.checkBoxArraySetting[i].isCheckedEmail) {
        isCheckedEmail.push(this.state.checkBoxArraySetting[i].isCheckedEmail);
      } else {
        isCheckedEmail.push("false");
      }
    }

    const formData = new FormData();
    formData.append("personalLanguage", this.state.languageId);
    formData.append("id", id);
    formData.append("label", label);
    formData.append("order", order);
    formData.append("isChecked", isChecked);
    formData.append("isCheckedEmail", isCheckedEmail);

    const config = { headers: { "content-type": "multipart/form-data" } };
    this.block(true);
    ProfileService.settingsSave(formData, config)
      .then((response) => {
        var result = response.data;
        AuthService.registerAuth("", result.data);
        this.props.storeLoginDetails(
          result.data,
          this.props.authUser.loginType
        );
        const { t } = this.props;

        this.state.checkBoxArray[0].label = t(
          this.state.checkBoxArray[0].label
        );
        this.state.checkBoxArray[1].label = t(
          this.state.checkBoxArray[1].label
        );
        this.state.checkBoxArray[2].label = t(
          this.state.checkBoxArray[2].label
        );
        this.state.checkBoxArray[3].label = t(
          this.state.checkBoxArray[3].label
        );
        this.state.checkBoxArray[4].label = t(
          this.state.checkBoxArray[4].label
        );
        this.state.checkBoxArray[5].label = t(
          this.state.checkBoxArray[5].label
        );
        this.state.checkBoxArray[6].label = t(
          this.state.checkBoxArray[6].label
        );
        this.state.checkBoxArray[7].label = t(
          this.state.checkBoxArray[7].label
        );
        this.state.checkBoxArraySetting[0].label = t(
          this.state.checkBoxArraySetting[0].label
        );
        this.state.checkBoxArraySetting[1].label = t(
          this.state.checkBoxArraySetting[1].label
        );
        this.state.checkBoxArraySetting[2].label = t(
          this.state.checkBoxArraySetting[2].label
        );
        this.state.checkBoxArraySetting[3].label = t(
          this.state.checkBoxArraySetting[3].label
        );
        this.state.checkBoxArraySetting[4].label = t(
          this.state.checkBoxArraySetting[4].label
        );
        this.state.checkBoxArraySetting[5].label = t(
          this.state.checkBoxArraySetting[5].label
        );
        this.state.checkBoxArraySetting[6].label = t(
          this.state.checkBoxArraySetting[6].label
        );
        this.state.checkBoxArraySetting[7].label = t(
          this.state.checkBoxArraySetting[7].label
        );

        this.setState({
          userPhotoImage: `data:image;base64,${result.data.uPhoto}`,
        });
        if (result.data.notifiSettings.length == 0) {
          this.setState({
            checkBoxArray: [
              {
                id: "5",
                order: "1",
                notifyId: "1",
                isChecked: true,
                label: "Someone answered your post",
              },
              {
                id: "7",
                order: "2",
                notifyId: "2",
                isChecked: true,
                label: "Someone upvoted your post",
              },
              {
                id: "8",
                order: "3",
                notifyId: "3",
                isChecked: true,
                label: "Someone reacted your post",
              },
              {
                id: "6",
                order: "4",
                notifyId: "4",
                isChecked: true,
                label: "New course",
              },
              {
                id: "4",
                order: "5",
                notifyId: "5",
                isChecked: true,
                label: "New Announcement",
              },
              {
                id: "3",
                order: "6",
                notifyId: "6",
                isChecked: true,
                label: "Call scheduled by your friend",
              },
              {
                id: "2",
                order: "7",
                notifyId: "7",
                isChecked: true,
                label: "Your friend request is accepted",
              },
              {
                id: "1",
                order: "8",
                notifyId: "8",
                isChecked: true,
                label: "You have a new friend request",
              },
            ],
          });
        } else {
          if (result.data.notifiSettings.length > 0) {
            const checkbox_array = [];
            var notifyId = 0;
            var fields = result.data.notifiSettings;
            fields.sort(function (a, b) {
              return parseInt(a.norder) - parseInt(b.norder);
            });
            console.log("ABCD EMAIL");
            console.log(fields);
            for (let i = 0; i < fields.length; i++) {
              notifyId = notifyId + 1;

              checkbox_array.push({
                id: fields[i].id,
                isChecked: fields[i].isChecked,
                isCheckedEmail: fields[i].isCheckedEmail,
                label: fields[i].label,
                notificationSettingsId: fields[i].notificationSettingsId,
                userId: fields[i].userId,
                order: fields[i].norder,
                notifyId: notifyId,
              });
            }
            this.setState({ checkBoxArray: checkbox_array });
          }
        }
        if (result.data.notifiSettings.length == 0) {
          this.setState({
            checkBoxArraySetting: [
              {
                id: "5",
                order: "1",
                notifyId: "9",
                isCheckedEmail: false,
                label: "Someone answered your post",
              },
              {
                id: "7",
                order: "2",
                notifyId: "10",
                isCheckedEmail: false,
                label: "Someone upvoted your post",
              },
              {
                id: "8",
                order: "3",
                notifyId: "11",
                isCheckedEmail: false,
                label: "Someone reacted your post",
              },
              {
                id: "6",
                order: "4",
                notifyId: "12",
                isCheckedEmail: false,
                label: "New course",
              },
              {
                id: "4",
                order: "5",
                notifyId: "13",
                isCheckedEmail: false,
                label: "New Announcement",
              },
              {
                id: "3",
                order: "6",
                notifyId: "14",
                isCheckedEmail: true,
                label: "Call scheduled by your friend",
              },
              {
                id: "2",
                order: "7",
                notifyId: "15",
                isCheckedEmail: false,
                label: "Your friend request is accepted",
              },
              {
                id: "1",
                order: "8",
                notifyId: "16",
                isCheckedEmail: true,
                label: "You have a new friend request",
              },
            ],
          });
        } else {
          // this.setState({ checkBoxArraySetting: result.data.notifiSettings })

          if (result.data.notifiSettings.length > 0) {
            const checkbox_array = [];
            var notifyId = 6;
            var fields = result.data.notifiSettings;
            fields.sort(function (a, b) {
              return parseInt(a.norder) - parseInt(b.norder);
            });
            console.log("ABCD EMAIL");
            console.log(fields);
            for (let i = 0; i < fields.length; i++) {
              notifyId = notifyId + 6;
              checkbox_array.push({
                id: fields[i].id,
                isChecked: fields[i].isChecked,
                isCheckedEmail: fields[i].isCheckedEmail,
                label: fields[i].label,
                notificationSettingsId: fields[i].notificationSettingsId,
                userId: fields[i].userId,
                order: fields[i].norder,
                notifyId: notifyId,
              });
            }
            this.setState({ checkBoxArraySetting: checkbox_array });
          }
        }
        this.setState({ languageId: result.data.pLanguage });
        this.changeLanguage(result.data.pLanguage);

        this.block(false);
      })
      .catch((error) => {
        this.block(false);
        console.log(error);
      });
  };

  changeLanguage(lang) {
    i18n.changeLanguage(lang);

    if (lang === "English") {
      AuthService.storeCommonLanguages("en");
      this.props.storePersonalLanguage("en");
      //  this.props.getAllCommonSubjectList(false);
      this.props.getAllCommonCurriculumTrackList(false);

      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
    } else if (lang === "French") {
      AuthService.storeCommonLanguages("fr");
      this.props.storePersonalLanguage("fr");
      //  this.props.getAllCommonSubjectList(false);
      this.props.getAllCommonCurriculumTrackList(false);
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
    } else if (lang === "Luxembourgish") {
      AuthService.storeCommonLanguages("lx");
      this.props.storePersonalLanguage("lx");
      //  this.props.getAllCommonSubjectList(false);
      this.props.getAllCommonCurriculumTrackList(false);
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
    } else {
      AuthService.storeCommonLanguages("en");
      this.props.storePersonalLanguage("en");

      //  this.props.getAllCommonSubjectList(false);
      this.props.getAllCommonCurriculumTrackList(false);
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
    }
  }

  handleCheckBoxChange = (id) => {
    const checkBoxArray = [...this.state.checkBoxArray];
    const index = checkBoxArray.findIndex((object) => object.notifyId === id);
    checkBoxArray[index].isChecked = !checkBoxArray[index].isChecked;
    this.setState({
      checkBoxArray,
    });
  };

  handleCheckBoxChangeEmail = (id) => {
    const checkBoxArraySetting = [...this.state.checkBoxArraySetting];
    const index = checkBoxArraySetting.findIndex(
      (object) => object.notifyId === id
    );
    checkBoxArraySetting[index].isCheckedEmail =
      !checkBoxArraySetting[index].isCheckedEmail;
    this.setState({
      checkBoxArraySetting,
    });
  };

  handlelanguage(event) {
    this.setState({
      languageId: event.target.value,
    });
  }

  onChange(e) {
    let files = e.target.files[0];

    if (files.size > 200000) {
      imageConversion
        .compressAccurately(files, {
          size: 200,
          maxwidth: 300,
          maxheight: 200,
          orientation: 2,
          scale: 0.5,
        })
        .then((res) => {
          this.setState({ file: res });
        });
    } else {
      this.setState({ file: files });
    }
  }

  onImagePreview(event) {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        image: URL.createObjectURL(event.target.files[0]),
      });
    }
  }
}

const mapStateToProps = function (state) {
  console.log("Login details in settings");
  console.log(state.loginDetailsApi.authUser);
  console.log(state.loginDetailsApi.authUser.loginType);

  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    storePersonalLanguage: (language) =>
      dispatch(storePersonalLanguage(language)),
    getAllCommonSubjectList: (key) => dispatch(getAllCommonSubjectList(key)),
    storeLoginDetails: (loginDetails, type) =>
      dispatch(storeLoginDetails(loginDetails, type)),
    getAllCommonCurriculumTrackList: (key) =>
      dispatch(getAllCommonCurriculumTrackList(key)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(Settings));
