import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@material-ui/core";
import moment from "moment";
// import { Height } from '@material-ui/icons';
// import { he } from 'date-fns/locale';
import styled from "@emotion/styled";
import CalenderService from "../../api/CalenderService.js";
import ReactDOM from "react-dom";
import { Delete, Edit } from "@material-ui/icons";
import { BASE_URL, JITSI_URL } from "../../config/app-config.js";
import { useDispatch, useSelector } from "react-redux";
import {
  AiOutlineCloseCircle,
  AiOutlineCopy,
  AiOutlineFolderView,
  AiTwotoneCopy,
} from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { BiHeart, BiVideo } from "react-icons/bi";
import UserProfileUpdate from "../../components/UserProfileUpdate.jsx";
import { updateProfileDetails } from "../../redux/actions/loginDetailsActions.js";
import Swal from "sweetalert2";
import { generateUlr } from "../../utils.js";

let currentDate = moment(new Date()).format("yyyy-MM-DD");
const eventType = {
  default: {
    textColor: "white",
    backgroundColor: "#A00030",
  },
  group: {
    textColor: "white",
    backgroundColor: "#A00030",
  },
  public: {
    textColor: "white",
    backgroundColor: "#A00030",
  },
  event: {
    textColor: "white",
    backgroundColor: "#A00030",
  },
};

function renderEventContent(eventInfo) {
  return (
    <>
      {console.log(
        "renderEventContent ::",
        eventInfo.event.extendedProps.eventType
      )}
      <p
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          // border: "1px solid #A00030",
          textOverflow: "ellipsis",
          paddingInlineStart: "3px",
          // color: "black",
          cursor: "pointer",
          background: "#A00030",
          color: "white",
          // background: `${
          //   eventType[eventInfo.event.extendedProps.eventType]
          //     .backgroundColor || eventType["default"].backgroundColor
          // } `,
          // color: `${
          //   eventType[eventInfo.event.extendedProps.eventType].textColor ||
          //   eventType["default"].textColor
          // }`,
          // opacity:"0.1"
          // color:"white"
        }}
      >
        {eventInfo.event.title}
      </p>
    </>
  );
}

const StyledWrapper = styled.div`
  .fc .fc-daygrid-day-frame {
    width: 100%;
  }

  ,
  .fc .fc-button-primary {
    border: "1px solid green !important";
  }import ProfileDetails from '../Home/ProfileDetails/ProfileDetails';

`;

function Calender(props) {
  const { t } = props;
  const { countryId, dob, profession_value, location, phoneNumber } =
    useSelector((state) => state.loginDetailsApi.loginDetails);
  const [copyData, setCopyData] = React.useState(false);
  const [currentEvents, setCurrentEvents] = React.useState([]);
  const [events, setEvents] = React.useState(null);
  const [showEventDetailsModal, setShowEventDetailsModal] =
    React.useState(false);
  const [eventItem, setEventItem] = React.useState(null);
  const loginDetails = useSelector((data) => data.loginDetailsApi);
  const workspaceName = useSelector(
    (state) => state.loginDetailsApi.loginDetails.userWorkSpace?.slug
  );
  const [showFilterList, setShowFilterList] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileStatus, setProfileStatus] = useState(false);
  const [calendarId, setCalenderId] = useState("");
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleShowFilterList = (event) => {
    console.log("Event Target :", event.target);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilterList = () => {
    setAnchorEl(null);
  };

  const handleEventModalClose = () => {
    setShowEventDetailsModal(false);
    setCopyData(false);
  };

  function handleEventClick(eventInfo) {
    setEventItem(eventInfo);
    setShowEventDetailsModal(true);
  }

  const EventDetail = ({ event, el }) => {
    const content = (
      <div>
        {event.title}
        <div>{event.extendedProps.description}</div>
      </div>
    );

    ReactDOM.render(content, el);

    return el;
  };

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    CalenderService.getCalendarListByUserId().then((res) => {
      console.log("Calender List", res.data.data.calendar);
      // setEvents(res.data.data.calendar)
      const keyValues = res.data.data.calendar.map((event, index) => ({
        id: index,
        title: event.taskName,
        publicEvent: event.publicEvent,
        slug: event.slug,
        start: event.startDate,
        end: event.endDate,
        startT: event.startTime ? event.startTime : new Date(),
        endT: event.endTime ? event.endTime : new Date(),
        // backgroundColor: event.color,
        description: event.taskDescription,
        textColor: "white",
        eventType: event.taskType,
        meetingUrl: event.url,
        startDate: moment(event.startDate).format("yyyy-MM-DD"),
        adminUserId: event.adminUserId,
        calendarId: event.calendarId,
        subjectId: event.subjectId,
        isMeetingInApplication: event.meetingInApplication,
        interest: event.interest,
      }));
      console.log("calender Details : list >> ", keyValues);
      setEvents(keyValues);
    });
    console.log("eventItem", eventItem);
  };

  const onHandleClick = (event, link, isMeetingYuukke, eventType) => {
    event.preventDefault();
    event.stopPropagation();
    if (eventType === "mentor") {
      // window.open(
      //   `${JITSI_URL}/${link}?authenticated=true&userId=${loginDetails.loginDetails.uId}&username=${loginDetails?.loginDetails?.dName}`,
      //   "_blank"
      // );
      // window.open(`${JITSI_URL}/${link}?authenticated=true#config`, "_blank");
      generateUlr(`${JITSI_URL}/${link}?authenticated=true&sp=yes`);
    } else if (!isMeetingYuukke) {
      window.open(link, "_blank");
    } else if (eventType === "public") {
      // window.open(
      //   `${JITSI_URL}/${link}?authenticated=true&isGlob=true#config`,
      //   "_blank"
      // );
      generateUlr(`${JITSI_URL}/${link}?authenticated=true&isGlob=true&sp=yes`);
    } else {
      // window.open(
      //   `${JITSI_URL}/${link}&isGroup=true`,
      //   "_blank"
      // );
      generateUlr(
        `${JITSI_URL}/${link}?authenticated=true&isGroup=true&sp=yes`
      );
    }
  };
  const handleCopy = async (slug) => {
    // console.log("link, isMeetingYuukke, eventType");

    try {
      await navigator.clipboard.writeText(`${BASE_URL}meeting-ref/${slug}`);
      // if (!isMeetingYuukke) {
      //   await navigator.clipboard.writeText(link);
      // } else if (eventType === "mentor") {
      //   let regex = link.replace(/&.*/, "");
      //   await navigator.clipboard.writeText(`${JITSI_URL}/${regex}?#config`);
      // } else if (eventType === "public") {
      //   await navigator.clipboard.writeText(
      //     `${JITSI_URL}/${link}?&isGlob=true#config`
      //   );
      // } else {
      //   let regex = link.replace(/&.*/, "");
      //   await navigator.clipboard.writeText(
      //     `${JITSI_URL}/${regex}?&isGroup=true#config`
      //   );
      // }
      setCopyData(true);
      toast.success("Copied");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  const viewEvent = (slug) => {
    if (slug) {
      props.history.push(`/eventRegistration/${slug}`);
    } else {
      toast.warning("Something went wrong");
    }
  };
  const editEvent = (sId, cId) => {
    props.history.push(`/${workspaceName}/event-form/${"update-event"}/${cId}`);
    console.log("sid", sId);
    console.log("sid", cId);
  };
  const profilePopUpStatus = () => {
    if (showEventDetailsModal) {
      setShowEventDetailsModal(!showEventDetailsModal);
    }
    setProfileStatus(!profileStatus);
  };
  console.log("profileStatus", profileStatus);
  const checkMeetingStatus = (interest, adminUserId, startDate) => {
    console.log("interest", interest);

    console.log(
      "interest same user",
      loginDetails.loginDetails.uId === adminUserId
    );

    if (loginDetails.loginDetails.uId === adminUserId || interest) {
      return false;
    } else if (
      !moment(startDate).isBefore(currentDate) &&
      !moment(startDate).isSame(currentDate)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const userDetails = (
    countryId,
    phoneNumber,
    location,
    dob,
    professionId,
    country
  ) => {
    let data = {
      countryId: countryId,
      phoneNumber: phoneNumber,
      location: location,
      dob: moment(dob).format("DD/MM/yyyy"),
      professionId: professionId,
      interest: true,
      calendarId: calendarId,
      country: country,
    };
    console.log("Update", data);

    updateFields(true, data);
    dispatch(updateProfileDetails(data));
  };
  const setInterest = (calendarId) => {
    setCalenderId(calendarId);
    if (location && dob && profession_value) {
      updateFields(false, calendarId);
    } else {
      profilePopUpStatus();
    }
  };
  const updateFields = (profileDetails, data) => {
    var apiData;
    if (profileDetails) {
      apiData = data;
    } else {
      apiData = {
        calendarId: data,
        interest: true,
      };
    }
    console.log("apiData", apiData);
    CalenderService.showInterest(apiData)
      .then((response) => {
        console.log("Api Data", apiData);
        toast.success("Thanks for showing interest");
        setProfileStatus(false);
        getData();
        setShowEventDetailsModal(false);
      })
      .catch((error) => {
        console.log("Er", error);
      });
  };
  const deleteEvent = (id) => {
    setShowEventDetailsModal(false);

    Swal.fire({
      title: "Are you sure delete this event ?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
      icon: "warning",
    }).then((result) => {
      if (result.value) {
        let data = {
          calendarId: id,
        };
        CalenderService.deleteCalendar(data)
          .then((response) => {
            toast.success("Deleted successfully");
            getData();
            setShowEventDetailsModal(false);
          })
          .catch((error) => {
            console.log("Er", error);
          });
      }
    });
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      <Paper>
        {events && Array.isArray(events) ? (
          <div
            style={{
              padding: "3rem",
            }}
          >
            <StyledWrapper>
              <FullCalendar
                defaultView="dayGridMonth"
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                events={events}
                firstDay={1}
                headerToolbar={{
                  left: "prev next today",
                  center: "title",
                  // right: "dayGridMonth timeGridWeek timeGridDay newEvent",
                  right: "dayGridMonth timeGridWeek timeGridDay",
                }}
                dayMaxEvents={true}
                editable={false}
                selectable={false}
                selectMirror={false}
                eventColor="#A00030"
                aspectRatio={7}
                eventContent={(e) => renderEventContent(e)}
                eventClick={(e) => handleEventClick(e)}
                // customButtons={{
                //   newEvent: {
                //     text: "Filters",
                //     click: (e) => {
                //       handleShowFilterList(e);
                //     },
                //   },
                // }}
                nowIndicator
              />
            </StyledWrapper>
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              height: "75vh",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress />
            </div>
          </div>
        )}
        {showEventDetailsModal && eventItem && (
          <Dialog
            open={showEventDetailsModal}
            onClose={handleEventModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">
              <div className="d-flex justify-content-end">
                <AiOutlineCloseCircle
                  onClick={handleEventModalClose}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginBottom: "8px",
                    width: "20px",
                    height: "20px",
                    color: "#000000",
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="m-0">{eventItem.event.title} </h5>
                <div className="ml-4">
                  {eventItem.event.extendedProps.eventType !== "slotBooking" &&
                    eventItem.event.extendedProps.eventType !== "mentor" &&
                    eventItem.event.extendedProps.adminUserId ==
                      loginDetails.loginDetails.uId && (
                      <Edit
                        className="calender-edit mr-1"
                        onClick={() =>
                          editEvent(
                            eventItem.event.extendedProps.subjectId,
                            eventItem.event.extendedProps.calendarId
                          )
                        }
                      />
                    )}
                  {eventItem.event.extendedProps.eventType !== "slotBooking" &&
                    eventItem.event.extendedProps.adminUserId ===
                      loginDetails.loginDetails.uId && (
                      <Delete
                        style={{ cursor: "pointer" }}
                        className="menuItem m-0 text-secondary"
                        onClick={() =>
                          deleteEvent(eventItem.event.extendedProps.calendarId)
                        }
                      />
                    )}
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <p
                    style={{}}
                    dangerouslySetInnerHTML={{
                      __html: eventItem.event.extendedProps.description,
                    }}
                  ></p>

                  {eventItem.event.extendedProps.eventType !== "mentor" && (
                    <p style={{ fontSize: "10px" }}>
                      {moment(eventItem.event.start).locale("en").format("LLL")}{" "}
                      - {moment(eventItem.event.end).locale("en").format("LLL")}
                      {/* {moment(eventItem.event.start)
                        .locale("en")
                        .format("MMM Do YY")}
                      ,{" "}
                      {eventItem.event.extendedProps.startT
                        ? eventItem.event.extendedProps.startT
                        : ""}
                      -{" "}
                      {moment(eventItem.event.end)
                        .locale("en")
                        .format("MMM Do YY")}
                      ,{" "}
                      {eventItem.event.extendedProps.endT
                        ? eventItem.event.extendedProps.endT
                        : ""} */}
                    </p>
                  )}
                  {eventItem.event.extendedProps.publicEvent && (
                    <div className="d-flex align-items-center justify-content-center mt-3 mb-2">
                      <button
                        className="b-none calender-btn"
                        onClick={() =>
                          viewEvent(eventItem.event.extendedProps.slug)
                        }
                      >
                        <AiOutlineFolderView className="svg-icons-dir mr-1" />
                        {t("View Event Details")}
                      </button>
                    </div>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div
                className="d-flex align-items-center justify-content-center p-2 flex-wrap"
                style={{ width: "100%", gap: "3rem" }}
              >
                {eventItem.event.extendedProps.meetingUrl && (
                  <>
                    {/* {eventItem.event.extendedProps.eventType !== "mentor" && (
                        <div className="profileCopy-card d-flex">
                          <p
                            onClick={() =>
                              handleCopy(
                                eventItem.event.extendedProps.meetingUrl,
                                eventItem.event.extendedProps
                                  .isMeetingInApplication,
                                eventItem.event.extendedProps.eventType
                              )
                            }
                          >
                            Copy meeting link
                            {copyData ? (
                              <AiTwotoneCopy
                                class="copy-icon"
                                style={{ color: "rgb(160, 0, 48)" }}
                              />
                            ) : (
                              <AiOutlineCopy class="copy-icon" />
                            )}
                          </p>
                        </div>
                      )} */}
                    {eventItem.event.extendedProps.eventType !== "mentor" && (
                      <div className="profileCopy-card d-flex align-items-center">
                        {checkMeetingStatus(
                          eventItem.event.extendedProps.interest,
                          eventItem.event.extendedProps.adminUserId,
                          eventItem.event.extendedProps.startDate
                        ) ? (
                          <button
                            onClick={() =>
                              setInterest(
                                eventItem.event.extendedProps.calendarId
                              )
                            }
                          >
                            <BiHeart
                              style={{
                                height: "24px",
                                width: "auto",
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            />
                            I'm Interested
                          </button>
                        ) : (
                          <p
                            onClick={() =>
                              handleCopy(
                                eventItem.event.extendedProps.slug
                                // eventItem.event.extendedProps.meetingUrl,
                                // eventItem.event.extendedProps
                                //   .isMeetingInApplication,
                                // eventItem.event.extendedProps.eventType
                              )
                            }
                          >
                            Copy meeting link
                            {copyData ? (
                              <AiTwotoneCopy
                                class="copy-icon"
                                style={{ color: "rgb(160, 0, 48)" }}
                              />
                            ) : (
                              <AiOutlineCopy class="copy-icon" />
                            )}
                          </p>
                        )}
                      </div>
                    )}
                    {(moment(eventItem.event.extendedProps.startDate).isBefore(
                      currentDate
                    ) ||
                      moment(eventItem.event.extendedProps.startDate).isSame(
                        currentDate
                      )) && (
                      <button
                        onClick={(e) =>
                          onHandleClick(
                            e,
                            eventItem.event.extendedProps.meetingUrl,
                            eventItem.event.extendedProps
                              .isMeetingInApplication,
                            eventItem.event.extendedProps.eventType
                          )
                        }
                        size="small"
                        style={{ color: "white" }}
                      >
                        <BiVideo
                          style={{
                            height: "24px",
                            width: "auto",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        />
                        {t("Join Meeting")}{" "}
                      </button>
                    )}
                  </>
                )}
                {/* <button
                  size="small"
                  onClick={handleEventModalClose}
                  style={{ color: "white" }}
                >
                  Close
                </button> */}
              </div>
            </DialogActions>
          </Dialog>
        )}
        {/* {showFilterList && (
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleCloseFilterList}
            TransitionComponent={Fade}
            style={{
              top: "3rem",
            }}
          >
            <MenuItem onClick={handleCloseFilterList}>Filter 1</MenuItem>
            <MenuItem onClick={handleCloseFilterList}>Filter 2</MenuItem>
            <MenuItem onClick={handleCloseFilterList}>Filter 3</MenuItem>
          </Menu>
        )} */}
        {profileStatus && (
          <UserProfileUpdate
            handleClose={profilePopUpStatus}
            userDetails={userDetails}
          />
        )}
      </Paper>
    </>
  );
}

export default withTranslation()(withRouter(Calender));
