import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { BsCalendarDate } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { AiFillLike } from "react-icons/ai";
import { FaComment } from "react-icons/fa";
import banner from "../../img/banner.jpg";
import dummyimage from "../../img/default_avatar.jpg";
import FriendPostCard from "./FriendPostCard";
import { Card, CardContent } from "@material-ui/core";

class FriendPosts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNo: 0,
      postList: [],
    };
    this.pageNo = this.pageNo.bind(this);
    this.likeStatus = this.likeStatus.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.setState({ postList: this.props.userPostList });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userPostList !== this.props.userPostList) {
      this.setState({ postList: this.props.userPostList });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.postList &&
          this.state.postList.length > 0 &&
          this.state.postList.map((post, index) => (
            <FriendPostCard
              data={post}
              userLikeType={post.userlikeType}
              likestatusUpdate={this.likeStatus}
            ></FriendPostCard>
          ))}
        {this.props.noMoreState ? (
          <div className="networkDetailsBtn" style={{ marginTop: "12px" }}>
            <button className="networkSeeMore" onClick={this.pageNo}>
              {t("See more")}
            </button>
          </div>
        ) : !this.props.blockStatus ? (
          <div style={{ textAlign: "center", marginTop: "12px" }}>
            <Card>
              <CardContent>
                <center>
                  <p style={{ textAlign: "center", marginTop: "12px" }}>
                    {t("No More Post Available")}
                  </p>
                </center>
              </CardContent>
            </Card>
            {/* <h6>No More Post Available</h6> */}
          </div>
        ) : (
          <></>
        )}
        {/* {this.state.postList.length === 0 && (
          <Card>
            <CardContent>
              <center>
                <p style={{ textAlign: "center", marginTop: "12px" }} onClick={this.pageNo}>{t("No Post Available")}</p>
              </center>
            </CardContent>
          </Card>
        )} */}
      </>
    );
  }

  pageNo() {
    this.props.loadMorePosts();
  }

  likeStatus = (result, userLike) => {
    this.props.likeStatus(result, userLike);
  };
}

const mapStateToProps = function (state) {
  return {};
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(FriendPosts)));
