import React, { Component } from "react";
import { Container } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
//import AskQuestion from "./AskQuestion";
//import CreateChat from "./CreateChat";
//import Subjects from "./Subjects/Subjects";
import i18n from "../../i18n";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getChallengesList } from "../../redux/actions/subjectActions";

import ChallengeList from "./ChallengeList";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class ChallengeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 1,
      selectedValue: null,
      showAskQuestion: false,
      editObject: {},
      blocking: false,
      subjectstoquestion: false,
      subjectstolibrary: false,
      SubId: 0,
      pageNo: 0,
      tagDatas: [],
      redirecttype: "",
      showAskChat: false,
      challengePage:false,
    };
    this.pageNo=this.pageNo.bind(this);
  }

  componentDidMount() {
    console.log("challenge>>",this.props.challengePage);
  
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
   // this.pageNo(this.state.pageNo);
    
  }
 
  render() {
    console.log("state",this.state)
    const { t, tabIndex } = this.props;
    return (
      <div className="">
        <div>
        <ChallengeList
          gotoGroups={this.idfromSubjectCard}
          subLoading={this.props.subLoading}
          editQuestion={this.editQuestion}
          redirecttype={this.state.redirecttype}
          subId={this.state.SubId}
          movetoQuestions={(type) => this.BackToMainPage(type)}
          tagDatas={this.props.tagDatas}
          subjectstoquestion={this.state.subjectstoquestion}
          subjectstolibrary={this.state.subjectstolibrary}
          loginDetails={this.props.loginDetails}
          searchValue={this.props.searchValue}
          challengePage={this.state.challengePage}
          pagination={this.props.challengePage}
          // page={this.state.pageNo}
          // pageNo={this.pageNo}
        />
       
        </div>
      
      </div>
    );
  }
  async pageNo(data) {
    //alert("hi")
    let page = this.state.pageNo + data;
    // if(page!==this.props.challengePage || page===0){
    //   this.setState({
    //     challengePage:true
    //   })
    // }else{
    //   // alert("bye")
    //   this.setState({
    //     challengePage:false
    //   })
    // }
     this.setState({
      pageNo:page
    })
    this.props.getChallengesList(page);
    console.log("tagDatas state>>",this.state.tagDatas);
  }
  idfromSubjectCard(
    id,
    key,
    name,
    data,
    editQuestion,
    subjectName,
    subId,
    redirecttype,
    movetoQuestions,
    event,
    grouporeventFiles,
    fileType,
    subjectDescription,
    memberShipPrice,
    memberShipType,
    expiryDate,
    createdDate,
    displayName,
    userId,
    tagListId,
    type
  ) {
    this.props.gotoGroups(
      id,
      key,
      name,
      data,
      editQuestion,
      subjectName,
      subId,
      redirecttype,
      movetoQuestions,
      event,
      grouporeventFiles,
      fileType,
      subjectDescription,
      memberShipPrice,
      memberShipType,
      expiryDate,
      createdDate,
      displayName,
      userId,
      tagListId,
      type
    );
  }
}

const mapStateToProps = function (state) {
  return {
    tagDatas: state.subjects.getChallengeLists,
    challengePage:state.subjects.getChallengePage,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getChallengesList: (page) => dispatch(getChallengesList(page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ChallengeContainer)));
