import React, { useEffect, useMemo, useState } from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import "../../src/screens/Home/HomeUpdated/myPost.css";
import ReactPlayer from "react-player";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";

const YoutubeVideoModel = ({ youtubeId }) => {
  const [openModel, setOpenModel] = useState(false);
  const [videoDetails, setVideoDetails] = useState([]);
  // useEffect(() => {
  //   const getVideoId = (underline) => {
  //     const match = youtubeId.match(
  //       /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=|watch\?v%3D|watch\?feature=player_embedded&v%3D|embed\/videoseries\?list=))(.*?)(?:\?|&|\/|$)/
  //     );

  //     return match && match[1];
  //   };
  //   // const videoIds = videos && videos.map((item) => getVideoId(item));
  //   // console.log("Extracted Video IDs:", videoIds);
  //   setId(getVideoId());

  //   console.log("youtubeId", getVideoId());
  // }, []);

  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const getVideoId = () => {
          const match = youtubeId.match(
            /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=|watch\?v%3D|watch\?feature=player_embedded&v%3D|embed\/videoseries\?list=))(.*?)(?:\?|&|\/|$)/
          );

          return match && match[1];
        };
        const id = getVideoId();
        const apiKey = "AIzaSyBfTsBG9e6ZK809dJF8_TXD6WZxnDDTUj8"; // Replace with your YouTube Data API key
        const videoDetailsResponse = await axios.get(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${id}&key=${apiKey}`
        );
        console.log("videoDetailsResponse", videoDetailsResponse.data.items);
        setVideoDetails(videoDetailsResponse.data.items);
      } catch (error) {
        console.error("Error fetching video details:", error);
      }
    };

    fetchVideoDetails();
  }, [youtubeId]);
  console.log("videoDetails", videoDetails);
  console.log("videoDetails youtubeId", youtubeId);
  const getDescription = (paragraph) => {
    const maxWords = 70;
    if (paragraph) {
      const words = paragraph.split(" ");
      if (words.length <= maxWords) {
        return paragraph;
      }
      const truncatedWords = words.slice(0, maxWords);
      return truncatedWords.join(" ") + "...";
    } else {
      return "";
    }
  };
  const description = useMemo(() => getDescription, [videoDetails]);
  return (
    <div className="youtube-container">
      <div>
        {videoDetails &&
          videoDetails.map((video) => (
            <div key={video?.id}>
              <h5>{video?.snippet.title}</h5>
              <p style={{ fontSize: "12px" }} className="text-secondary">
                {description(video?.snippet.description)}
              </p>
              {/* <img
            src={video.snippet.thumbnails.medium.url}
            alt="Video Thumbnail"
          /> */}
            </div>
          ))}
      </div>

      <div
        style={{
          marginBottom: "-1.7 rem",
          // background: "black",
          borderRadius: "5px",
        }}
        className="d-flex align-items-center justify-content-center  position-relative p-2"
      >
        <iframe
          width="765"
          height="390"
          src={youtubeId + "?showinfo=0&rel=0"}
          title="YouTube Video Player"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
};
YoutubeVideoModel.defaultProps = {
  youtubeId: "",
};

export default React.memo(YoutubeVideoModel);
