import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import Google from "../../../images/SocialMediaGif/google.png";

function App({
  outer,
  className = "m1-option-img",
  updateToken,
  title,
  errorGettingToken,
}) {
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => updateToken("google", codeResponse),
    onError: (error) => errorGettingToken("google", error),
  });
  return (
    <li className={outer ? "login-options-btn" : ""} onClick={() => login()}>
      <img src={Google} alt="logo" className={className} />
      {title}
    </li>
  );
}

export default App;
