import React, { Component } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextEditorQuestion from "../../components/TextEditorQuestion";
import ProfileService from "../../api/ProfileService";
import ForumService from "../../api/ForumService";
import AuthService from "../../session/AuthService";
import { ToastContainer, toast } from "react-toastify";
import "../css/mysocial.css";
import "../css/mysocialprofile.css";
import "../css/yuukkeTutoring.css";
import BlockUi from "react-block-ui";
import Form from "react-bootstrap/Form";
import i18n from "../../i18n";
import { withTranslation } from "react-i18next";
import "react-widgets/styles.css";
import Grid from "@material-ui/core/Grid";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Swal from "sweetalert2";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import * as APPCONFIG from "../../config/app-config.js";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { Chip } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import {
  getDashboardList,
  getMyQuestionsList,
} from "../../redux/actions/dashboardActions";
import {
  getAllSubjectList,
  getAllMyGroupEvents,
  getAlltypeofCommunity,
} from "../../redux/actions/subjectActions";
import { getMyLibraryQuestionList } from "../../redux/actions/MyLibraryQuestionActions";
import { getQuestionsList } from "../../redux/actions/questionActions";
import moment from "moment";
import Utils from "../../utils/Utils";
import MessagesFileOption from "./Subjects/GroupEventMessageFIleOption";
import MuliImage from "./Subjects/MessagesFileOption";

import TagMenu from "../../components/TagMenu";
import { withRouter } from "react-router";
import DatePicker from "react-multi-date-picker";
import MenuItem from "@material-ui/core/MenuItem";
import { getAllUserCommunityList } from "../../redux/actions/commonApiActions";
import SearchTextField from "../../components/SearchTextField";
class AskQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AllCommunityType: [],
      communityType: 0,
      blocking: false,
      fileUrl: null,
      type: null,
      mediaPost: "",
      isChecked: false,
      teaching: [],
      tags: [],
      question: "",
      classDetails: [],
      addTagsState: false,
      fileOption: false,
      gradeDetails: [],
      questionDescribed: "",
      content: "",
      editState: false,
      fileImage: false,
      gradeId: "",
      questionTitleCount: 0,
      descriptionCount: 0,
      curriculumList: [],
      curriculumId: "",
      description: "",
      memberShipPrice: "",
      memberShipType: "",
      expiryDate: "",
      expiry: "",
      checkExpiry: true,
      fileTypeKey: "",
      fileTypeState: false,
      tagName: [],
      status: "",
      startDate: "",
      startTime: "",
      endTime: "",
      eventLocation: "",
      editeventorgroupState: false,
      imageFile: "",
      grouporeventFiles: [],
      selectOption: true,
      grouporeventFilesList: [],
      editImage: [],
      selectedTagId: [],
      selectedTagList: [],
      multiFileOption: false,
      postImages: [],
      postButton: false,
    };
    this.block = this.block.bind(this);
    this.keyFile = this.keyFile.bind(this);
    this.closeChoosedFileData = this.closeChoosedFileData.bind(this);
    this.expirycheck = this.expirycheck.bind(this);
    this.myRef = React.createRef();
    this.getallSubject = this.getallSubject.bind(this);
    this.editorStateValues = this.editorStateValues.bind(this);
    this.handleTeachingMultiSelect = this.handleTeachingMultiSelect.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.getQuestionMediaFiles = this.getQuestionMediaFiles.bind(this);
    this.getQuestionMediaImage = this.getQuestionMediaImage.bind(this);
    this.handleGrade = this.handleGrade.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.fileOptionEnable = this.fileOptionEnable.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.getAllCurriculum = this.getAllCurriculum.bind(this);
    this.handleCurriculum = this.handleCurriculum.bind(this);
    this.getGradeByCurriculumId = this.getGradeByCurriculumId.bind(this);
    this.getGradeByCurriculumIdUpdate =
      this.getGradeByCurriculumIdUpdate.bind(this);
    this.ViewAllComments = this.ViewAllComments.bind(this);
    this.UpdateQuestionInAllpages = this.UpdateQuestionInAllpages.bind(this);
    this.backtogroups = this.backtogroups.bind(this);
    this.backtoevents = this.backtoevents.bind(this);
    this.getTagId = this.getTagId.bind(this);
    this.getAllTags = this.getAllTags.bind(this);
    this.getTagNameAlone = this.getTagNameAlone.bind(this);
    this.editGroupsEvents = this.editGroupsEvents.bind(this);
    this.getOldTagList = this.getOldTagList.bind(this);
    this.backtoHome = this.backtoHome.bind(this);
    this.gotogroupDescription = this.gotogroupDescription.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.removeImageFile = this.removeImageFile.bind(this);
  }

  componentDidMount() {
    this.props.getAlltypeofCommunity();
    var Edit = this.props.match.params.subjectId;
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.getAllTags();

    if (Edit === undefined) {
      this.setState({ editeventorgroupState: false });
    } else {
      // alert("else")
      this.setState({ editeventorgroupState: true });
      this.editGroupsEvents();
    }
  }

  ViewAllComments(boolean) {
    this.setState({ checkExpiry: boolean });
  }

  handleChangeInput(value) {
    this.setState({
      expiryDate: moment(new Date(value)).format("MM/DD/YYYY"),
    });
    // alert(moment(new Date(value)).format("MM/DD/YYYY"))
  }
  communityButton = () => {
    this.setState({
      postButton: !this.state.postButton,
    });
  };
  render() {
    const { t } = this.props;
    const { handleViewAllQuestions, events, subjectId } = this.props;
    return (
      <div
        style={{
          marginLeft: "2px",
          marginRight: "2px",
          marginBottom: "22px",
        }}
      >
        <ToastContainer position="top-right" autoClose={5000} />
        <div
          className="backEventorGroup flexDefault"
          onClick={() => this.backtoHome()}
        >
          <ArrowBackIcon /> {t("Back")}
        </div>

        <div className="askAQuestionCardWrapper">
          <BlockUi
            tag="div"
            blocking={this.state.blocking}
            message={t("Loading, please wait")}
            color="#A00030"
          >
            <div className="askAQuestionInputGroup inputLarge">
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <label className="formLabel">
                  {!events ? t("Community Name") : t("Event Name Required")} *
                </label>
                {/* <div>
                  {" "}
                  <Tooltip title="Enter Group name">
                    <IconButton className="infoButton">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div> */}

                <input
                  type="text"
                  name="question"
                  value={this.state.question}
                  maxLength="250"
                  onChange={this.handleForm}
                />
              </Grid>
            </div>
            <div className="mb-3">
              <label className="formLabel">{t("Select Tags")} *</label>
              <TagMenu
                allMenus={this.state.tagName}
                addLabel={t("Add Tag")}
                selectedOldName={this.state.oldTagNames}
                isTagMenuFilter={true}
                getSelectedMenus={(data) => {
                  this.setState({ selectedTagName: data });
                  this.getTagId(data);
                }}
              />
              {/* <SearchTextField
                updateTag={this.newTagList}
                selectedTagList={this.state.selectedTagList}
                editNeed={this.state.editeventorgroupState}
              /> */}
            </div>
            <div className="askAQuestionDescription">
              <label className="formLabel">{t("Description")} *</label>
              <TextEditorQuestion
                ref={this.myRef}
                editorStateValue={this.editorStateValues}
                editorValue={this.state.questionDescribed}
              />
              <p
                className="boxText"
                style={
                  this.state.descriptionCount > 2500
                    ? { color: "red" }
                    : { color: "green" }
                }
              >
                {" "}
                {2500 - this.state.descriptionCount} {t("characters left")}
              </p>
            </div>

            {/* {!events && (
              <div className="askAQuestionInputGroup inputLarge">
                <Form.Group controlId="region">
                  <Form.Label>{t("Is Expiry")}</Form.Label>
                  <Form.Control
                    as="select"
                    name="memberShipType"
                    value={this.state.expiry}
                    onChange={this.expirycheck}
                  >
                    <option value="">{t("Choose")}</option>
                    <option value="Yes"> Yes</option>
                    <option value="No"> No</option>
                  </Form.Control>
                </Form.Group>
              </div>
            )} */}
            {/* {this.state.expiry === "Yes" && (
              <div className="askAQuestionInputGroup inputLarge">
                <label className="formLabel">{t("Expiry Date")}</label>
                <DatePicker
                  minDate={new Date()}
                  value={this.state.expiryDate}
                  onChange={this.handleChangeInput}
                  format="DD/MM/YYYY"
                  containerStyle={{
                    width: "100%",
                  }}
                  style={{
                    height: "26px",
                    // width: "auto",
                    fontSize: "14px",
                    padding: "18px 12px",
                    //30px 600px 5px 32px
                  }}
                  
                />
                
              </div>
            )} */}
            {!this.state.editeventorgroupState && (
              <>
                <div className="askAQuestionInputGroup inputLarge mt-2 formLabel">
                  {/* <h6>Community Type</h6> */}
                  <Form.Group controlId="region">
                    <Form.Label className="f-5">
                      {t("Community Type")} *
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="communityType"
                      value={this.state.communityType}
                      onChange={(e) => this.handleFormcommunityType(e)}
                    >
                      <option value="0">{t("Choose")}</option>
                      {this.props.allCommunityTypeList.map((option, index) => (
                        <option value={option.communityTypeId}>
                          {t(`${option.communityTypeName}`)}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="askAQuestionInputGroup inputLarge">
                  <Form.Group controlId="region">
                    <Form.Label className="f-5">{t("Status")} *</Form.Label>
                    <Form.Control
                      as="select"
                      name="status"
                      value={this.state.status}
                      onChange={this.handleForm}
                    >
                      <option value="0">{t("Choose")}</option>
                      {this.props.communityStatusType.map((option, index) => (
                        <option value={option.value}>
                          {t(`${option.name}`)}
                        </option>
                      ))}
                      {/* <option value="">{t("Choose")}</option>
                      <option value="public">{t("Public")}</option>
                      <option value="private">{t("Private")}</option> */}
                    </Form.Control>
                  </Form.Group>
                </div>{" "}
              </>
            )}

            {events && (
              <>
                <div className="askAQuestionInputGroup inputLarge">
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <label className="formLabel">{t("Start Date")}</label>
                    <input
                      type="date"
                      name="startDate"
                      min={new Date()}
                      required
                      value={this.state.startDate}
                      onChange={this.handleForm}
                    />
                  </Grid>
                </div>
                {/* <div className="askAQuestionInputGroup inputLarge">
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <label className="formLabel">{t("Expiry Date")}</label>
                    <input
                      type="date"
                      name="expiryDate"
                      min={new Date()}
                      required
                      value={this.state.expiryDate}
                      onChange={this.handleForm}
                    />
                  </Grid>
                </div> */}
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <div className="askAQuestionInputGroup inputLarge">
                      <Grid
                        // container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <label className="formLabel">{t("Start Time")}</label>
                        <TextField
                          defaultValue="01:00"
                          name="startTime"
                          type="time"
                          required
                          value={this.state.startTime}
                          onChange={this.handleForm}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // 5 minutes
                          inputProps={{ step: 300 }}
                        />
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <div className="askAQuestionInputGroup inputLarge">
                      <Grid
                        // container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <label className="formLabel">{t("End Time")}</label>
                        <TextField
                          defaultValue="01:00"
                          name="endTime"
                          type="time"
                          required
                          value={this.state.endTime}
                          onChange={this.handleForm}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // 5 minutes
                          inputProps={{ step: 300 }}
                        />
                      </Grid>
                    </div>
                  </Grid>
                </Grid>

                <div className="askAQuestionInputGroup inputLarge">
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Form.Group controlId="region">
                      <Form.Label>{t("Membership Type")}</Form.Label>
                      <Form.Control
                        as="select"
                        name="memberShipType"
                        value={this.state.memberShipType}
                        onChange={this.handleForm}
                      >
                        <option value="">{t("Choose")}</option>
                        <option value="paid">Paid</option>
                        <option value="free"> Free</option>
                      </Form.Control>
                    </Form.Group>
                  </Grid>
                </div>
                {this.state.memberShipType === "paid" && (
                  <div className="askAQuestionInputGroup inputLarge">
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <label className="formLabel">{t("Price")}</label>
                      <input
                        type="number"
                        name="memberShipPrice"
                        required
                        value={this.state.memberShipPrice}
                        onChange={this.handleForm}
                      />
                    </Grid>
                  </div>
                )}
                <div className="askAQuestionInputGroup inputLarge">
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <label className="formLabel">Event Location</label>
                    <input
                      type="text"
                      name="eventLocation"
                      value={this.state.eventLocation}
                      maxLength="250"
                      onChange={this.handleForm}
                    />
                  </Grid>
                </div>
              </>
            )}

            <div
              className="d-flex justify-content-between"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              {/* <div className="uploadTypeIcons" style={{ display: "inline-flex",color: "#F97516" }}> */}
              {!this.state.fileTypeState && (
                <div
                  style={{ cursor: "pointer", color: "#A00030" }}
                  onClick={() => this.keyFile("image")}
                >
                  <CameraAltIcon /> {t("Add Community Image")}
                </div>
              )}
              {!this.state.editeventorgroupState &&
                !this.state.postButton &&
                this.state.communityType == 1 && (
                  <div
                    style={{ cursor: "pointer", color: "#A00030" }}
                    onClick={() => this.communityButton()}
                  >
                    <CameraAltIcon /> {t("Add Community Related Post")}
                  </div>
                )}

              {/* <Grid item md={4} sm={4} xs={4} lg={4}>
                   <div onClick={() => this.keyFile("video")}>
                  <VideocamIcon
                    style={{ cursor: "pointer",color: "#F97516" }}

                  />video
                </div></Grid> 
                <Grid item md={3} sm={3} xs={3} lg={3}>
                <div onClick={() => this.keyFile("audio")} >
                  <DescriptionIcon
                    style={{ cursor: "pointer",color: "#F97516" }}

                  />file
                </div>
                </Grid> */}
              {/* </div> */}
            </div>

            {this.state.fileTypeState && (
              <div>
                {!this.state.fileImage && (
                  <AiOutlineCloseCircle
                    onClick={this.closeChoosedFileData}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      marginBottom: "8px",
                      width: "20px",
                      height: "20px",
                      color: "#000000",
                    }}
                  />
                )}

                <MessagesFileOption
                  fileTypeKey={this.state.fileTypeKey}
                  fileOption={this.state.fileOption}
                  fileOptionEnable={this.fileOptionEnable}
                  fromQuestionImage={this.getQuestionMediaImage}
                  fromQuestionMedia={this.getQuestionMediaFiles}
                  editObject={this.props.editObject}
                  grouporeventFiles={this.state.grouporeventFiles}
                ></MessagesFileOption>
              </div>
            )}
            {this.state.editeventorgroupState &&
              this.state.fileImage &&
              this.state.grouporeventFiles.length > 0 && (
                <div key={this.state.grouporeventFiles[0].originalFileName}>
                  <Chip
                    icon={<AttachmentIcon />}
                    className="fileChip"
                    label={this.state.grouporeventFiles[0].originalFileName}
                    size="small"
                    onDelete={() => this.removeImageFile()}
                    variant="outlined"
                  />
                  {
                    <img
                      style={{ margin: "8px", width: "80px", height: "80px" }}
                      src={`${APPCONFIG.API_URL}auth/image/${this.state.grouporeventFiles[0].fileName}`}
                    ></img>
                  }
                </div>
              )}
            {this.state.postButton && (
              <div className="community-img">
                <AiOutlineCloseCircle
                  onClick={this.communityButton}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginBottom: "8px",
                    width: "20px",
                    height: "20px",
                    color: "#000000",
                  }}
                />

                <p className="d-block text-secondary">
                  Add community related image{" "}
                </p>
                <MuliImage
                  fileTypeKey={"image"}
                  fileOption={this.state.multiFileOption}
                  fileOptionEnable={this.postImageOption}
                  fromQuestionMedia={this.postImageFiles}
                  needCount={false}
                ></MuliImage>
              </div>
            )}
          </BlockUi>
          {!this.state.editeventorgroupState && (
            <div className="askAQuestionBtn">
              <button onClick={(e) => this.onFormSubmit(e)}>
                {!events ? t("CREATE COMMUNITY") : t("CREATE EVENT")}
              </button>
            </div>
          )}
          {this.state.editeventorgroupState && (
            <div className="askAQuestionBtn">
              <button onClick={(e) => this.onUpdateSubmit(e)}>
                {t("UPDATE COMMUNITY")}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  postImageOption = () => {
    this.setState({ postImages: null });
  };
  postImageFiles = (files) => {
    // alert();
    console.log("Files", files);
    this.setState({ postImages: files });
  };

  newTagList = (value) => {
    this.setState({
      selectedTagList: value,
    });
  };
  keyFile(key) {
    this.setState({ fileTypeKey: key, fileTypeState: true });
    this.setState({ fileImage: false });
  }

  closeChoosedFileData() {
    this.setState({
      fileTypeState: false,
      fileImage: false,
      file: [],
      grouporeventFiles: this.state.grouporeventFilesList,
    });
    //  this.setState({editImage: [], fileTypeState: false, file: [] ,grouporeventFiles: this.state.grouporeventFilesList })

    this.setState({ fileImage: true });
    console.log("update 3", this.state.editImage);
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  getAllTags() {
    this.setState({
      tagDetails: this.props.tagList.data,
    });
    this.getTagNameAlone(this.props.tagList.data);
  }
  getTagNameAlone(result) {
    var arrayTagNames = new Array();
    for (let i = 0; i < result.length; i++) {
      arrayTagNames.push(result[i].tagName);
    }
    this.setState({ tagName: arrayTagNames });
  }
  handleGrade(event) {
    if (this.state.curriculumId === "") {
      Swal.fire({
        title: "Please choose valid program before choosing grade",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
        reverseButtons: true,
      }).then((result) => {});
    } else {
      this.setState({
        gradeId: event.target.value,
      });
    }
  }

  getQuestionMediaFiles(files, type) {
    console.log("update 111", files);
    this.setState({ file: files, type: type });
  }

  async getQuestionMediaImage(files, type) {
    console.log("update 1", files);
    await this.setState({
      imageFile: files,
      type: type,
      selectOption: false,
      grouporeventFiles: [],
      editImage: files,
      fileImage: true,
    });
    console.log(this.state.type);
    console.log(this.state.imageFile);
  }

  editorStateValues(values) {
    console.log("editorStateValues");
    console.log(values);
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        content: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        content: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  }

  handleTeachingMultiSelect(subject_value) {
    this.setState({
      teaching: subject_value,
    });
  }

  getallSubject() {
    this.setState({ app_subjects: this.props.subjectsList.data });
  }

  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        this.setState({ gradeDetails: result.data.grade });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async fileOptionEnable() {
    console.log("update 2", this.state.editImage);
    await this.setState({
      editImage: [],
      questionFile: null,
      grouporeventFiles: this.state.grouporeventFilesList,
      fileOption: false,
      fileImage: false,
    });

    //await  this.setState({ editImage:[], questionFile: null ,grouporeventFiles: this.state.grouporeventFilesList, fileOption: false });
    console.log("update 3", this.state.editImage);
  }

  onFormSubmit = (e) => {
    const formData = new FormData();

    // if (this.props.events) {
    if (this.state.question.trim() == "") {
      toast.warn(`${this.props.t("Please enter community Name")}`);
    } else if (this.state.question.trim().length > 50) {
      toast.warn(
        `${this.props.t("The community name should be less then 50 character")}`
      );
    } else if (this.state.communityType == 0) {
      toast.warn(`${this.props.t("Please select community type")}`);
    }
    // else if (!this.state.expiry) {
    //   toast.warn("Please choose expiry");
    // }
    // else if (this.state.expiry === "Yes" && !this.state.expiryDate) {
    //   toast.warn("Please enter expiryDate");
    // }
    else if (!this.state.status) {
      toast.warn(`${this.props.t("Please choose community status")}`);
    } else if (this.state.selectedTagId?.length === 0) {
      toast.warn(`${this.props.t("Please select community related tag's")}`);
    } else {
      // const newTagList =
      // this.state.selectedTagList &&
      // this.state.selectedTagList.map((item) => {
      //   return { tagName: item.label, tagId: item.value };
      // });
      if (this.state.file == undefined && this.state.imageFile == "") {
        if (this.state.expiry === "No") {
          formData.append("subjectName", this.state.question.trim());
          formData.append("subjectDescription", this.state.content.trim());
          formData.append("isExpiry", "No");
          formData.append("type", "groups");
          formData.append("fileType", "text");
          formData.append("memberShipType", "free");
          formData.append("status", this.state.status);
          formData.append("communityType", this.state.communityType);
          // formData.append(
          //   "newTagList",
          //   newTagList ? JSON.stringify(newTagList) : []
          // );
          for (let i = 0; i < this.state.selectedTagId.length; i++) {
            formData.append("tagsId", this.state.selectedTagId[i]);
          }
        } else {
          formData.append("subjectName", this.state.question.trim());
          formData.append("subjectDescription", this.state.content.trim());
          formData.append("isExpiry", "No");
          formData.append("type", "groups");
          formData.append("fileType", "text");
          // formData.append(
          //   "date",this.state.expiryDate
          // );
          formData.append("memberShipType", "free");
          formData.append("status", this.state.status);
          // formData.append(
          //   "newTagList",
          //   newTagList ? JSON.stringify(newTagList) : []
          // );
          for (let i = 0; i < this.state.selectedTagId.length; i++) {
            formData.append("tagsId", this.state.selectedTagId[i]);
          }
          formData.append("communityType", this.state.communityType);
        }
      } else {
        if (this.state.expiry === "No") {
          this.block(true);
          formData.append("subjectName", this.state.question.trim());
          formData.append("subjectDescription", this.state.content.trim());
          formData.append("isExpiry", "No");
          formData.append("type", "groups");
          formData.append("fileType", this.state.type);
          formData.append("memberShipType", "free");
          formData.append("status", this.state.status);
          if (this.state.type == "image") {
            formData.append("messgaeFiles", this.state.imageFile);
          } else {
            for (let i = 0; i < this.state.file.length; i++) {
              formData.append("messgaeFiles", this.state.file[i]);
            }
          }
          for (let i = 0; i < this.state.selectedTagId.length; i++) {
            formData.append("tagsId", this.state.selectedTagId[i]);
          }
          // formData.append(
          //   "newTagList",
          //   newTagList ? JSON.stringify(newTagList) : []
          // );
          formData.append("communityType", this.state.communityType);
        } else {
          this.block(true);
          formData.append("subjectName", this.state.question.trim());
          formData.append("subjectDescription", this.state.content.trim());
          formData.append("isExpiry", "No");
          formData.append("type", "groups");
          formData.append("fileType", this.state.type);
          formData.append("status", this.state.status);
          // formData.append(
          //   "date",this.state.expiryDate
          // );
          formData.append("memberShipType", "free");
          if (this.state.type == "image") {
            formData.append("messgaeFiles", this.state.imageFile);
          } else {
            for (let i = 0; i < this.state.file.length; i++) {
              formData.append("messgaeFiles", this.state.file[i]);
            }
          }
          for (let i = 0; i < this.state.selectedTagId.length; i++) {
            formData.append("tagsId", this.state.selectedTagId[i]);
          }
          // formData.append(
          //   "newTagList",
          //   newTagList ? JSON.stringify(newTagList) : []
          // );
          formData.append("communityType", this.state.communityType);
        }
      }
      if (this.state.postImages && this.state.communityType == 1) {
        for (let i = 0; i < this.state.postImages.length; i++) {
          formData.append("groupPosts", this.state.postImages[i]);
        }
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.block(true);
      ForumService.saveSubjectV2(formData, config)
        .then((response) => {
          var result = response.data.data;
          console.log("response data=>>", response);
          if (response.data.message === "faild") {
            this.block(false);
            if (response.data.data === "The group name already exists") {
              toast.warning(
                `${this.props.t("This community name already exists")}`
              );
            } else {
              toast.error(
                `${this.props.t(
                  "Something went wrong ,Please try again later"
                )}`
              );
            }
          } else if (response.data.data === "expired") {
            this.notify();
          } else {
            console.log("subject_id>>>", result.subjectId);
            toast.success(`${this.props.t("Community Saved Successfully")}`);
            this.setState({ description: "", fileOption: true, question: "" });
            this.props.getAllMyGroupEvents("Groups", 0);
            this.props.getAllSubjectList("Groups", 0);
            this.props.getAllUserCommunityList();
            this.block(false);
            //  this.backtogroups();
            //  this.props.handleViewAllQuestions();
            this.gotogroupDescription(
              result.subjectId,
              result.subjectName,
              result.communityTypeName
            );
            // this.gotogroupDescription(result.subjectId, result.subjectName);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    //  }
    // if (this.props.events) {
    //   if (!this.state.question) {
    //     toast.warn("Please enter valid Name");
    //   } else if (!this.state.selectedTagId) {
    //     toast.warn("Please select tag");
    //   }
    //   // else if (!this.state.expiryDate) {
    //   //   toast.warn("Please enter endDate");
    //   // }
    //   else if (!this.state.startTime) {
    //     toast.warn("Please enter  startTime");
    //   } else if (!this.state.endTime) {
    //     toast.warn("Please enter endTime");
    //   } else if (!this.state.startDate) {
    //     toast.warn("Please enter startDate");
    //   } else if (!this.state.eventLocation) {
    //     toast.warn("Please enter eventLocation");
    //   } else if (!this.state.memberShipType) {
    //     toast.warn("Please choose memberShipType");
    //   } else if (
    //     this.state.memberShipType === "paid" &&
    //     !this.state.memberShipPrice
    //   ) {
    //     toast.warn("Please enter memberShipPrice");
    //   } else {
    //     this.block(true);

    //     if (this.state.file == undefined && this.state.imageFile == "") {
    //       formData.append("endTime", this.state.endTime);
    //       formData.append("startTime", this.state.startTime);
    //       formData.append(
    //         "startDate",
    //         moment(this.state.startDate).format("MM/DD/yyyy")
    //       );
    //       formData.append("eventLocation", this.state.eventLocation);
    //       formData.append("subjectName", this.state.question.trim());
    //       formData.append("subjectDescription", this.state.content.trim());
    //       formData.append("isExpiry", this.state.expiry);
    //       formData.append("type", "events");
    //       formData.append("fileType", "text");
    //       // formData.append(
    //       //   "date",
    //       //   moment(this.state.expiryDate).format("MM/DD/yyyy")
    //       // );
    //       formData.append("memberShipType", this.state.memberShipType);
    //       formData.append("memberShipPrice", this.state.memberShipPrice);
    //       for (let i = 0; i < this.state.selectedTagId.length; i++) {
    //         formData.append("tagsId", this.state.selectedTagId[i]);
    //       }
    //     } else {
    //       formData.append("endTime", this.state.endTime);
    //       formData.append("startTime", this.state.startTime);
    //       formData.append(
    //         "startDate",
    //         moment(this.state.startDate).format("MM/DD/yyyy")
    //       );
    //       formData.append("eventLocation", this.state.eventLocation);
    //       formData.append("subjectName", this.state.question.trim());
    //       formData.append("subjectDescription", this.state.content.trim());
    //       formData.append("isExpiry", this.state.expiry);
    //       formData.append("type", "events");
    //       formData.append("fileType", this.state.type);
    //       // formData.append(
    //       //   "date",
    //       //   moment(this.state.expiryDate).format("MM/DD/yyyy")
    //       // );
    //       formData.append("memberShipType", this.state.memberShipType);
    //       formData.append("memberShipPrice", this.state.memberShipPrice);
    //       if (this.state.type == "image") {
    //         formData.append("messgaeFiles", this.state.imageFile);
    //       } else {
    //         for (let i = 0; i < this.state.file.length; i++) {
    //           formData.append("messgaeFiles", this.state.file[i]);
    //         }
    //       }
    //       for (let i = 0; i < this.state.selectedTagId.length; i++) {
    //         formData.append("tagsId", this.state.selectedTagId[i]);
    //       }
    //     }
    //     const config = {
    //       headers: {
    //         "content-type": "multipart/form-data",
    //       },
    //     };
    //     this.block(true);
    //     ForumService.saveSubjectV2(formData, config)
    //       .then((response) => {
    //         toast.success("✓ Event Saved Successfully");

    //         this.setState({ description: "", question: "" });
    //         this.setState({ question: "", fileOption: true, file: "" });
    //         this.props.getAllSubjectList("Events",0);
    //         this.block(false);
    //         this.backtoevents();
    //         this.props.handleViewAllQuestions();
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    // }
  };
  async removeImageFile() {
    var inpu = this.state.fileImage;
    if (this.state.grouporeventFiles) {
      this.setState({
        grouporeventFiles: [],
        editImage: [],
        fileImage: false,
      });
    } else {
    }
  }

  onUpdateSubmit(e) {
    const formData = new FormData();
    // if (!this.props.events) {
    if (this.state.question.trim() === "") {
      toast.warn(`${this.props.t("Please enter community Name")}`);
    } else if (this.state.question.trim().length > 50) {
      toast.warn(
        `${this.props.t("The community name should be less then 50 character")}`
      );
    }
    // else if (!this.state.expiry) {
    //   toast.warn("Please choose expiry");
    // }
    // else if (this.state.expiry === "Yes" && !this.state.expiryDate) {
    //   toast.warn("Please enter expiryDate");
    // else if (
    //   this.state.communityType == 0 ||
    //   this.state.communityType == null
    // ) {
    //   toast.warn("Please select community type");
    // }
    else if (!this.state.status) {
      toast.warn(`${this.props.t("Please choose community status")}`);
    } else if (this.state.selectedTagId?.length === 0) {
      toast.warn(`${this.props.t("Please select community related tag's")}`);
    } else {
      // const newTagList =
      //   this.state.selectedTagList &&
      //   this.state.selectedTagList.map((item) => {
      //     return { tagName: item.label, tagId: item.value };
      //   });
      if (
        this.state.grouporeventFiles.length !== 0 &&
        this.state.editImage.length === 0 &&
        this.state.fileImage
      ) {
        if (this.state.expiry === "No") {
          this.block(true);

          formData.append("subjectName", this.state.question.trim());
          formData.append("subjectId", this.state.subjectId);
          formData.append("subjectDescription", this.state.content.trim());
          formData.append("isExpiry", "No");
          formData.append("type", "groups");
          // formData.append("fileType", "image");
          formData.append("memberShipType", "free");
          formData.append("status", this.state.status);

          // formData.append(
          //   "newTagList",
          //   newTagList ? JSON.stringify(newTagList) : []
          // );
          formData.append("communityType", this.state.communityType);
        } else {
          this.block(true);

          formData.append("subjectName", this.state.question.trim());
          formData.append("subjectId", this.state.subjectId);
          formData.append("isExpiry", "No");
          formData.append("subjectDescription", this.state.content.trim());
          formData.append("type", "groups");
          // formData.append("fileType", "image");
          formData.append("status", this.state.status);
          // formData.append(
          //   "date",
          //   moment(this.state.expiryDate).format("MM/DD/yyyy")
          // );
          formData.append("memberShipType", "free");

          // formData.append(
          //   "newTagList",
          //   newTagList ? JSON.stringify(newTagList) : []
          // );
          formData.append("communityType", this.state.communityType);
        }
      } else if (
        this.state.editImage === undefined ||
        this.state.editImage.length === 0
      ) {
        if (this.state.expiry === "No") {
          formData.append("subjectName", this.state.question.trim());
          formData.append("subjectId", this.state.subjectId);
          formData.append("subjectDescription", this.state.content.trim());
          formData.append("isExpiry", "No");
          formData.append("type", "groups");

          formData.append("memberShipType", "free");
          formData.append("status", this.state.status);
          // formData.append(
          //   "newTagList",
          //   newTagList ? JSON.stringify(newTagList) : []
          // );
          formData.append("communityType", this.state.communityType);
        } else {
          formData.append("subjectName", this.state.question.trim());
          formData.append("subjectId", this.state.subjectId);
          formData.append("subjectDescription", this.state.content.trim());
          formData.append("isExpiry", "No");
          formData.append("type", "groups");

          // formData.append(
          //   "date",
          //   moment(this.state.expiryDate).format("MM/DD/yyyy")
          // );
          formData.append("memberShipType", "free");
          formData.append("status", this.state.status);
          // formData.append(
          //   "newTagList",
          //   newTagList ? JSON.stringify(newTagList) : []
          // );
          formData.append("communityType", this.state.communityType);
        }
      } else if (this.state.editImage.length !== 0) {
        // else if(this.state.editImage.length !== 0 && this.state.grouporeventFilesList.length !== 0){

        if (this.state.expiry === "No") {
          this.block(true);

          formData.append("subjectName", this.state.question.trim());
          formData.append("subjectId", this.state.subjectId);
          formData.append("subjectDescription", this.state.content.trim());
          formData.append("isExpiry", "No");
          formData.append("type", "groups");
          formData.append("memberShipType", "free");
          formData.append("status", this.state.status);

          formData.append("messgaeFiles", this.state.editImage);

          // formData.append(
          //   "newTagList",
          //   newTagList ? JSON.stringify(newTagList) : []
          // );
          formData.append("communityType", this.state.communityType);
        } else {
          this.block(true);

          formData.append("subjectName", this.state.question.trim());
          formData.append("subjectId", this.state.subjectId);
          formData.append("isExpiry", "No");
          formData.append("subjectDescription", this.state.content.trim());
          formData.append("type", "groups");
          formData.append("status", this.state.status);
          // formData.append(
          //   "date",
          //   moment(this.state.expiryDate).format("MM/DD/yyyy")
          // );
          formData.append("memberShipType", "free");

          formData.append("messgaeFiles", this.state.editImage);

          // formData.append(
          //   "newTagList",
          //   newTagList ? JSON.stringify(newTagList) : []
          // );
          formData.append("communityType", this.state.communityType);
        }
      }
      if (this.state.fileImage) {
        formData.append("fileType", "image");
      } else {
        formData.append("fileType", "text");
      }
      for (let i = 0; i < this.state.selectedTagId.length; i++) {
        formData.append("tagsId", this.state.selectedTagId[i]);
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.block(true);
      ForumService.updateSubjectV2(formData, config)
        .then((response) => {
          var result = response.data.data;
          toast.success(`${this.props.t("Community updated successfully")}`);
          this.setState({ description: "", fileOption: true, question: "" });
          this.props.getAllMyGroupEvents("Groups", 0);
          this.props.getAllSubjectList("Groups", 0);
          this.block(false);
          this.gotogroupDescription(
            result.subjectId,
            result.subjectName,
            result.communityTypeName
          );
          // this.backtogroups();
          this.props.handleViewAllQuestions();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // }

    // if (this.props.events) {
    //   if (!this.state.question) {
    //     toast.warn("Please enter valid Name");
    //   } else if (!this.state.selectedTagId) {
    //     toast.warn("Please select tag");
    //   }
    //   // else if (!this.state.expiryDate) {
    //   //   toast.warn("Please enter endDate");
    //   // }
    //    else if (!this.state.startTime) {
    //     toast.warn("Please enter  startTime");
    //   } else if (!this.state.endTime) {
    //     toast.warn("Please enter endTime");
    //   } else if (!this.state.startDate) {
    //     toast.warn("Please enter startDate");
    //   } else if (!this.state.eventLocation) {
    //     toast.warn("Please enter eventLocation");
    //   } else if (!this.state.memberShipType) {
    //     toast.warn("Please choose memberShipType");
    //   } else if (
    //     this.state.memberShipType === "paid" &&
    //     !this.state.memberShipPrice
    //   ) {
    //     toast.warn("Please enter memberShipPrice");
    //   } else {
    //     //  this.block(true);

    //     if (this.state.file == undefined && this.state.imageFile == "") {
    //       formData.append("endTime", this.state.endTime);
    //       formData.append("startTime", this.state.startTime);
    //       formData.append(
    //         "startDate",
    //         moment(this.state.startDate).format("MM/DD/yyyy")
    //       );
    //       formData.append("eventLocation", this.state.eventLocation);
    //       formData.append("subjectName", this.state.question.trim());
    //       formData.append("subjectId", this.state.subjectId);
    //       formData.append("isExpiry", this.state.expiry);
    //       formData.append("subjectDescription", this.state.content.trim());
    //       formData.append("type", "events");
    //       formData.append("fileType", this.state.type);
    //       // formData.append(
    //       //   "date",
    //       //   moment(this.state.expiryDate).format("MM/DD/yyyy")
    //       // );
    //       formData.append("memberShipType", this.state.memberShipType);
    //       formData.append("memberShipPrice", this.state.memberShipPrice);
    //       for (let i = 0; i < this.state.selectedTagId.length; i++) {
    //         formData.append("tagsId", this.state.selectedTagId[i]);
    //       }
    //     } else {
    //       formData.append("endTime", this.state.endTime);
    //       formData.append("startTime", this.state.startTime);
    //       formData.append(
    //         "startDate",
    //         moment(this.state.startDate).format("MM/DD/yyyy")
    //       );
    //       formData.append("eventLocation", this.state.eventLocation);
    //       formData.append("subjectName", this.state.question.trim());
    //       formData.append("subjectId", this.state.subjectId);
    //       formData.append("isExpiry", this.state.expiry);
    //       formData.append("subjectDescription", this.state.content.trim());
    //       formData.append("type", "events");
    //       formData.append("fileType", this.state.type);
    //       // formData.append(
    //       //   "date",
    //       //   moment(this.state.expiryDate).format("MM/DD/yyyy")
    //       // );
    //       formData.append("memberShipType", this.state.memberShipType);
    //       formData.append("memberShipPrice", this.state.memberShipPrice);
    //       if (this.state.type == "image") {
    //         formData.append("messgaeFiles", this.state.imageFile);
    //       } else {
    //         for (let i = 0; i < this.state.file.length; i++) {
    //           formData.append("messgaeFiles", this.state.file[i]);
    //         }
    //       }
    //       for (let i = 0; i < this.state.selectedTagId.length; i++) {
    //         formData.append("tagsId", this.state.selectedTagId[i]);
    //       }
    //     }
    //     const config = {
    //       headers: {
    //         "content-type": "multipart/form-data",
    //       },
    //     };
    //     //   this.block(true);
    //     // ForumService.updateSubject(formData, config)
    //     //   .then((response) => {
    //     //     toast.success("✓ Event Updated Successfully");
    //     //     this.setState({ description: "", question: "" });
    //     //     this.setState({ question: "", fileOption: true, file: "" });
    //     //     this.props.getAllSubjectList("Events");
    //     //   //  this.block(false);
    //     //     this.backtoevents();
    //     //     this.props.handleViewAllQuestions();
    //     //   })
    //     //   .catch((error) => {
    //     //     console.log(error);
    //     //   });
    //   }
    // }
  }

  expirycheck(e) {
    this.setState({ expiry: e.target.value, expiryDate: "" });
  }

  // onUpdateSubmit(e) {
  //   const formData = new FormData();
  //   if (this.state.question === "") {
  //     toast.warn("Please enter valid question");
  //   } else if (this.state.teaching.length === 0) {
  //     toast.warn("Please choose subjects");
  //   } else if (this.state.question.length >= 250) {
  //     toast.warn("Entered question should not be above 250 characters");
  //   } else if (this.state.curriculumId == undefined) {
  //     toast.warn("Please choose program");
  //   } else if (this.state.curriculumId === "") {
  //     toast.warn("Please choose program");
  //   } else if (this.state.gradeId == undefined) {
  //     toast.warn("Please choose grade level");
  //   } else if (this.state.gradeId === "") {
  //     toast.warn("Please choose grade level");
  //   } else if (this.state.question != "" && this.state.gradeId != "") {
  //     if (this.state.questionFile == null && this.state.file == undefined) {
  //       formData.append("userName", this.props.loginDetails.dName);
  //       formData.append("questionDescription", this.state.content);
  //       formData.append("questionName", this.state.question);
  //       formData.append("questionGradeId", this.state.gradeId);
  //       formData.append("questionId", this.state.questionId);
  //       formData.append("markAsPrivate", this.state.isChecked);
  //       formData.append("Type", "text");
  //       formData.append("programId", this.state.curriculumId);
  //       for (let i = 0; i < this.state.teaching.length; i++) {
  //         formData.append("tagDescription", this.state.teaching[i].subjectName);
  //         formData.append("subjectId", this.state.teaching[i].subjectId);
  //       }
  //     } else if (
  //       this.state.questionFile != null &&
  //       this.state.file == undefined
  //     ) {
  //       formData.append("userName", this.props.loginDetails.dName);
  //       formData.append("questionDescription", this.state.content);
  //       formData.append("questionName", this.state.question);
  //       formData.append("questionId", this.state.questionId);
  //       formData.append("questionGradeId", this.state.gradeId);
  //       formData.append("programId", this.state.curriculumId);
  //       formData.append("markAsPrivate", this.state.isChecked);
  //       formData.append("Type", this.state.type);
  //       for (let i = 0; i < this.state.teaching.length; i++) {
  //         formData.append("tagDescription", this.state.teaching[i].subjectName);
  //         formData.append("subjectId", this.state.teaching[i].subjectId);
  //       }
  //     } else if (
  //       this.state.questionFile != null &&
  //       this.state.file != undefined
  //     ) {
  //       formData.append("userName", this.props.loginDetails.dName);
  //       formData.append("QuestionFiles", this.state.file);
  //       formData.append("questionDescription", this.state.content);
  //       formData.append("questionName", this.state.question);
  //       formData.append("questionId", this.state.questionId);
  //       formData.append("questionGradeId", this.state.gradeId);
  //       formData.append("programId", this.state.curriculumId);
  //       formData.append("markAsPrivate", this.state.isChecked);
  //       formData.append("Type", this.state.type);
  //       for (let i = 0; i < this.state.teaching.length; i++) {
  //         formData.append("tagDescription", this.state.teaching[i].subjectName);
  //         formData.append("subjectId", this.state.teaching[i].subjectId);
  //       }
  //     } else if (
  //       this.state.questionFile == null &&
  //       this.state.file != undefined
  //     ) {
  //       formData.append("userName", this.props.loginDetails.dName);
  //       formData.append("QuestionFiles", this.state.file);
  //       formData.append("questionDescription", this.state.content);
  //       formData.append("questionName", this.state.question);
  //       formData.append("questionId", this.state.questionId);
  //       formData.append("questionGradeId", this.state.gradeId);
  //       formData.append("programId", this.state.curriculumId);
  //       formData.append("markAsPrivate", this.state.isChecked);
  //       formData.append("Type", this.state.type);
  //       for (let i = 0; i < this.state.teaching.length; i++) {
  //         formData.append("tagDescription", this.state.teaching[i].subjectName);
  //         formData.append("subjectId", this.state.teaching[i].subjectId);
  //       }
  //     }

  //     const config = {
  //       headers: {
  //         "content-type": "multipart/form-data",
  //       },
  //     };
  //     this.block(true);
  //     ForumService.updateQuestion(formData, config)
  //       .then((response) => {
  //         var result = response.data;
  //         this.myRef.current.onClear();
  //         this.setState({
  //           content: "",
  //           question: "",
  //           teaching: [],
  //           isChecked: !this.state.isChecked,
  //           fileOption: true,
  //           questionTitleCount: 0,
  //           descriptionCount: 0,
  //         });
  //         this.props.getDashboardList(0, this.props.loginDetails.uId);
  //         this.props.getMyQuestionsList(0);
  //         this.props.getAllSubjectList();
  //         this.UpdateQuestionInAllpages(
  //           result.data.questions,
  //           result.data.questionTags
  //         );
  //         this.block(false);
  //         this.props.handleViewAllQuestions();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         toast.error("Error in saving question");
  //       });
  //   }
  // }

  block(blocking) {
    this.setState({ blocking: blocking });
  }
  handleFormcommunityType = (e) => {
    this.setState({
      communityType: e.target.value,
    });
  };
  handleForm(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getAllCurriculum() {
    this.setState({
      curriculumList: this.props.curriculumtrackList.curriculum,
    });
  }
  async getTagId(selectedTagName) {
    var tagId = new Array();
    for (let i = 0; i < this.state.tagDetails.length; i++) {
      for (let j = 0; j < selectedTagName.length; j++) {
        if (this.state.tagDetails[i].tagName == selectedTagName[j]) {
          tagId.push(this.state.tagDetails[i].tagId);
        }
      }
    }
    await this.setState({ selectedTagId: tagId });
  }

  handleCurriculum(event) {
    this.setState({
      curriculumId: event.target.value,
      gradeId: "",
    });
    this.getGradeByCurriculumId(event.target.value);
  }

  getGradeByCurriculumId(value) {
    this.setState({ gradeId: "" });
    let getGradeByCurriculumId = {
      curriculumId: value,
    };
    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        this.setState({ gradeDetails: response.data.data.gradeListById });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  notify = () => {
    toast.info(
      <div>
        <p>Your limit has been reached.</p>
        <p>Please recharge your account to continue using the service.</p>
        <a href="http://localhost:3000/premium-subscription">Recharge Now</a>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "center-toast",
      }
    );
  };

  editGroupsEvents() {
    let Edit = this.props.match.params.subjectId;
    var subjectId = {
      subjectId: Edit,
    };
    ProfileService.editGroupsEventsV2(subjectId)
      .then((response) => {
        var result = response.data.data;
        console.log("edit event");
        console.log(result.startDate);

        this.setState({
          communityType: result.communityTypeId,
          question: result.subjectName,
          subjectId: result.subjectId,
          type: result.fileType,
          status: result.status,
          memberShipType: result.memberShipType,
          memberShipPrice: result.memberShipPrice,
          questionDescribed: result.subjectDescription,
          content: result.subjectDescription,
          expiry: result.isExpiry,
          //type:result.type,
          expiryDate: moment(result.expiryDate).format("DD/MM/YYYY"),
          eventLocation: result.eventLocation,
          grouporeventFiles: result.grouporeventFiles,
          endTime: result.endTime,
          startTime: result.startTime,
          startDate: moment(result.startDate, "DD-MM-YYYY hh:mm:ss").format(
            "yyyy-MM-DD"
          ),
          grouporeventFilesList: result.grouporeventFiles,
          // selectedTagId:
        });
        this.getOldTagList(result.tagsId);
        // this.getOldTagList(result.tagsValue);
        console.log("vallue", result.grouporeventFiles);
        console.log("update text", result.grouporeventFiles);
        if (result.fileType == "image") {
          // this.setState({fileImage:true ,fileTypeState:true ,editImage:result.grouporeventFiles})
          this.setState({ fileImage: true, fileTypeState: true });
        } else {
          this.setState({ fileImage: false, editImage: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getGradeByCurriculumIdUpdate(value) {
    let getGradeByCurriculumId = {
      curriculumId: value,
    };

    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        this.setState({ gradeDetails: response.data.data.gradeListById });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  UpdateQuestionInAllpages(question, tags) {
    var QuestionId = question.questionId;
    var condition = this.props.QandAquestionList.filter(
      (data) => data.questionId == QuestionId
    );
    var Libcondition = this.props.topQuestions.filter(
      (data) => data.questionId == QuestionId
    );
    if (condition.length > 0) {
      for (var j = 0; j < this.props.QandAquestionList.length; j++) {
        if (this.props.QandAquestionList[j].questionId == QuestionId) {
          var tObj = question;

          var createdDate = tObj.createdDate;
          moment(createdDate).format("DD/MM/yyyy");
          var Dates = Utils.getLastAskedDate(createdDate);
          this.props.QandAquestionList[j].answer = tObj.answer;
          this.props.QandAquestionList[j].answerSize = tObj.answerSize;
          this.props.QandAquestionList[j].createdDate = tObj.createdDate;
          this.props.QandAquestionList[j].questionDescription =
            tObj.questionDescription;
          this.props.QandAquestionList[j].questionId = tObj.questionId;
          this.props.QandAquestionList[j].questionName = tObj.questionName;
          this.props.QandAquestionList[j].questionTags = tObj.questionTags;
          this.props.QandAquestionList[j].questionviewers =
            tObj.questionviewers;
          this.props.QandAquestionList[j].questionType = tObj.questionType;
          this.props.QandAquestionList[j].solved = tObj.solved;
          this.props.QandAquestionList[j].updatedDate = tObj.updatedDate;
          this.props.QandAquestionList[j].userId = tObj.userId;
          this.props.QandAquestionList[j].userName = tObj.userName;
          this.props.QandAquestionList[j].views = tObj.views;
          this.props.QandAquestionList[j].votes = tObj.votes;
          this.props.QandAquestionList[j].gradeId = tObj.grade;
          this.props.QandAquestionList[j].questionFile = tObj.questionFile;
          this.props.QandAquestionList[j].lastAsked = Dates;
          this.props.QandAquestionList[j].programId = tObj.programId;
          this.props.QandAquestionList[j].originalFileName =
            tObj.originalFileName;
          this.props.QandAquestionList[j].markAsPrivate = tObj.markAsPrivate;
          this.props.QandAquestionList[j].star = tObj.questionLikesArray.filter(
            (star) => star.userId == this.props.loginDetails.uId
          );
          // this.props.QandAquestionList[j].pageNo =
          //   this.props.QandAquestionList[j].pageNo;
          this.props.QandAquestionList[j].status = tObj.status;
          this.props.QandAquestionList[j].answerBlockStatus =
            tObj.answerBlockStatus;
        }
      }
    } else {
      var createdDate = question.createdDate;
      moment(createdDate).format("DD/MM/yyyy");
      var Dates = Utils.getLastAskedDate(createdDate);
      var question_list = {
        answer: question.answer,
        answerSize: question.answerSize,
        createdDate: question.createdDate,
        questionDescription: question.questionDescription,
        questionId: question.questionId,
        questionName: question.questionName,
        questionTags: tags,
        questionviewers: question.questionviewers,
        questionType: question.questionType,
        solved: question.solved,
        updatedDate: question.updatedDate,
        userId: question.userId,
        userName: question.userName,
        views: question.views,
        votes: question.votes,
        gradeId: question.grade,
        questionFile: question.questionFile,
        lastAsked: Dates,
        programId: question.programId,
        questionLikesArray: question.questionLikesArray,
        originalFileName: question.originalFileName,
        markAsPrivate: question.markAsPrivate,
        star: question.questionLikesArray.filter(
          (star) => star.userId == this.props.loginDetails.uId
        ),
        pageNo: 1,
        status: question.status,
        answerBlockStatus: question.answerBlockStatus,
      };

      this.props.QandAquestionList.splice(0, 0, question_list);
    }

    if (Libcondition.length > 0) {
      for (var j = 0; j < this.props.topQuestions.length; j++) {
        if (this.props.topQuestions[j].questionId == QuestionId) {
          var tObj = question;

          var createdDate = tObj.createdDate;
          moment(createdDate).format("DD/MM/yyyy");
          var Dates = Utils.getLastAskedDate(createdDate);
          this.props.topQuestions[j].answer = tObj.answer;
          this.props.topQuestions[j].answerSize = tObj.answerSize;
          this.props.topQuestions[j].createdDate = tObj.createdDate;
          this.props.topQuestions[j].questionDescription =
            tObj.questionDescription;
          this.props.topQuestions[j].questionId = tObj.questionId;
          this.props.topQuestions[j].questionName = tObj.questionName;
          this.props.topQuestions[j].questionTags = tObj.questionTags;
          this.props.topQuestions[j].questionviewers = tObj.questionviewers;
          this.props.topQuestions[j].questionType = tObj.questionType;
          this.props.topQuestions[j].solved = tObj.solved;
          this.props.topQuestions[j].updatedDate = tObj.updatedDate;
          this.props.topQuestions[j].userId = tObj.userId;
          this.props.topQuestions[j].userName = tObj.userName;
          this.props.topQuestions[j].views = tObj.views;
          this.props.topQuestions[j].votes = tObj.votes;
          this.props.topQuestions[j].gradeId = tObj.grade;
          this.props.topQuestions[j].questionFile = tObj.questionFile;
          this.props.topQuestions[j].lastAsked = Dates;
          this.props.topQuestions[j].programId = tObj.programId;
          this.props.topQuestions[j].originalFileName = tObj.originalFileName;
          this.props.topQuestions[j].markAsPrivate = tObj.markAsPrivate;
          this.props.topQuestions[j].star = tObj.questionLikesArray.filter(
            (star) => star.userId == this.props.loginDetails.uId
          );
          // this.props.topQuestions[j].pageNo = this.props.topQuestions[j].pageNo;
          this.props.topQuestions[j].status = tObj.status;
          this.props.topQuestions[j].answerBlockStatus = tObj.answerBlockStatus;
        }
      }
    } else {
      var createdDate = question.createdDate;
      moment(createdDate).format("DD/MM/yyyy");
      var Dates = Utils.getLastAskedDate(createdDate);
      var question_list = {
        answer: question.answer,
        answerSize: question.answerSize,
        createdDate: question.createdDate,
        questionDescription: question.questionDescription,
        questionId: question.questionId,
        questionName: question.questionName,
        questionTags: tags,
        questionviewers: question.questionviewers,
        questionType: question.questionType,
        solved: question.solved,
        updatedDate: question.updatedDate,
        userId: question.userId,
        userName: question.userName,
        views: question.views,
        votes: question.votes,
        gradeId: question.grade,
        questionFile: question.questionFile,
        lastAsked: Dates,
        programId: question.programId,
        questionLikesArray: question.questionLikesArray,
        originalFileName: question.originalFileName,
        markAsPrivate: question.markAsPrivate,
        star: question.questionLikesArray.filter(
          (star) => star.userId == this.props.loginDetails.uId
        ),
        pageNo: 1,
        status: question.status,
        answerBlockStatus: question.answerBlockStatus,
      };

      this.props.topQuestions.splice(0, 0, question_list);
    }
  }
  backtoHome() {
    this.props.history.push(`/${this.props.workspaceType}/groups`);
  }
  backtogroups() {
    this.props.history.push(`/${this.props.workspaceType}/groups`);
  }
  backtoevents() {
    this.props.history.push("/upcomingevents");
  }
  gotogroupDescription(id, name, communityTypeName) {
    console.log("gotogroup description", id, name, communityTypeName);
    if (communityTypeName == "Forum Community") {
      //forum
      this.props.history.push(
        `/${this.props.workspaceType}/forum-community/${id}/${name}`,
        { from: window.location.pathname }
      );
    } else {
      this.props.history.push(
        `/${this.props.workspaceType}/myGroupDescription/${id}/${name}`
      );
    }
    // this.props.history.push(`/myGroupDescription/${id}/${name}`);
  }
  getOldTagList = async (oldTags) => {
    // const filtered =
    //   oldTags &&
    //   oldTags.map((data) => {
    //     return { label: data.tagName, value: data.tagId };
    //   });

    // await this.setState({
    //   selectedTagList: filtered ? filtered : [],
    // });
    // console.log(
    //   "tagsList this.state.selectedTagList",
    //   this.state.selectedTagList
    // );
    var subjectName = [];
    console.log("oldTags");
    console.log(oldTags);
    console.log("this.props.tagList");
    console.log(this.props.tagList);
    if (this.props.tagList.data.length > 0) {
      for (let i = 0; i < this.props.tagList.data.length; i++) {
        for (let j = 0; j < oldTags.length; j++) {
          if (this.props.tagList.data[i].tagId === oldTags[j].tagId) {
            subjectName.push(this.props.tagList.data[i].tagName);
          }
        }
      }
      console.log(subjectName);
      await this.setState({ oldTagNames: subjectName });
      this.getTagId(subjectName);
    }
  };
}

const mapStateToProps = function (state) {
  return {
    SubjectquestionList: state.SubjectQuestion.list,
    SubjectquestTotalitems: state.SubjectQuestion.totalItems,
    wallList: state.dashboard.dashboardList,
    loading: state.dashboard.loading,
    tagDatas: state.subjects.list,
    questionLists: state.myquestions.myQuestionList[0],
    totalItems: state.myquestions.myQuestionList[1],
    topQuestions: state.mylibraryquestion.list,
    solvedTotalitems: state.mylibraryquestion.totalItems,
    QandAquestionList: state.topquestList.list,
    QandAquestTotalitems: state.topquestList.totalItems,
    subjectsList: state.commonApi.subjectslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    tagList: state.commonApi.taglist,
    allCommunityTypeList: state.subjects.allCommunityTypeList,
    communityStatusType: state.subjects.communityStatusType,

    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getDashboardList: () => dispatch(getDashboardList(0)),
    getAllSubjectList: (type, page) => dispatch(getAllSubjectList(type, page)),
    getAlltypeofCommunity: () => dispatch(getAlltypeofCommunity()),
    getAllMyGroupEvents: (type, page) =>
      dispatch(getAllMyGroupEvents(type, page)),
    getMyQuestionsList: () => dispatch(getMyQuestionsList(0)),
    getMyLibraryQuestionList: () =>
      dispatch(getMyLibraryQuestionList("All", 1)),
    getQuestionsList: () =>
      dispatch(getQuestionsList("unanswered", 1, 0, true, true)),
    getAllUserCommunityList: () => dispatch(getAllUserCommunityList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(AskQuestion)));
