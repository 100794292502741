import React, { Component } from "react";
import BlockUi from "react-block-ui";
import { connect } from "react-redux";
import ResourceInfoCard from "./ResourceInfoCard";
import { getSingleResourcePost } from "../../../redux/actions/subjectActions";
import { withRouter } from "react-router";
import { BiChevronsLeft } from "react-icons/bi";
import { toast } from "react-toastify";

class SingleResourcePost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceType: "",
    };
  }

  componentDidMount() {
    this.setState({
      resourceType: this.props.match.params.resourceType,
      postId: this.props.match.params.id,
    });
    this.props.getSingleResourcePost(this.props.match.params.id);
  }
  componentDidUpdate(previous) {
    if (this.props.resourcePostError !== previous.resourcePostError) {
      if (this.props.resourcePostError) {
        toast.error("Fetching data error !");
        this.props.history.push(`/${this.props.workspaceType}/home`);
      }
    }
  }
  backToList = () => {
    this.props.history.push(`/${this.props.workspaceType}/learning-hub`);
  };
  render() {
    console.log("sdsdi", this.props.resourceData);

    return (
      <div>
        <div
          onClick={this.backToList}
          style={{
            color: "gray",
            fontSize: "13px",
            cursor: "pointer",
            marginBottom: "12px",
          }}
        >
          <BiChevronsLeft
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          {"Back"}
        </div>
        <BlockUi
          tag="div"
          message={"Loading, please wait"}
          style={{ minHeight: "90px" }}
          className="wallLoadingPage"
          blocking={this.props.loaderStats}
        >
          {this.props.resourceData?.resourcePostId && (
            <ResourceInfoCard
              resourceType={this.state.resourceType}
              data={this.props.resourceData}
              login={this.props.loginDetails}
              getResourcePost={this.getResourcePostbyImages}
            />
          )}
          {this.props.resourcePostError && <p>Fetching Error</p>}
        </BlockUi>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    resourceData: state.subjects.resourcePost,
    loaderStats: state.subjects.resourcePostLoader,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    resourcePostError: state.subjects.resourcePostError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSingleResourcePost: (id) => dispatch(getSingleResourcePost(id)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SingleResourcePost));
