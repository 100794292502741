import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import AuthService from "../../../session/AuthService";
import FriendsFinder from "../../../api/FriendsFinder";
import ProfileService from "../../../api/ProfileService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import dummyimage from "../../../img/default_avatar.jpg";
import banner from "../../../img/banner.jpg";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

let newEducation = new Array(2);
class FirstTimeEducation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      friendsList: [],
      userKnowledgepoint: "",
      coverPhotoImage: "",
      savebtnState: false,
      inputs: [1],
      saveButtonEnable: false,
      frEducationName: [],
      addButton: false,
      nameValue: false,
      endTimeValue: false,
      majorValue: false,
      titleValue: false,
      completionYear: [],
    };

    this.handleInputEducation = this.handleInputEducation.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.addTranslation = this.addTranslation.bind(this);
    this.removeTranslation = this.removeTranslation.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.saveEducation = this.saveEducation.bind(this);
    this.getEducationDetails = this.getEducationDetails.bind(this);
    this.backtoHome = this.backtoHome.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    let year = [];
    var currentYear = new Date().getUTCFullYear();
    for (var i = 1970; i <= currentYear; i++) {
      var obj = {};
      obj["id"] = i;
      obj["name"] = i;
      year.push(obj);
    }
    this.setState({ completionYear: year });
    this.getEducationDetails();
  }

  addTranslation = (e) => {
    if (
      !this.state.nameValue ||
      !this.state.endTimeValue ||
      !this.state.majorValue ||
      !this.state.titleValue
    ) {
      toast.warn("Fill all the field");
    } else {
      var input = this.state.inputs;
      input.push(1);
      this.setState({
        inputs: input,
        nameValue: false,
        endTimeValue: false,
        majorValue: false,
        titleValue: false,
      });
    }
  };

  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
      showAskQuestion: false,
    });
  }

  async removeTranslation(record, index) {
    console.log("REMOVE TRANSLATION");
    console.log(index);
    console.log(this.state.inputs);
    var inpu = this.state.inputs;
    inpu.splice(index, 1);
    if (this.state.frEducationName[index]) {
      var val = this.state.frEducationName;
      val.splice(index, 1);
      this.setState({
        frEducationName: val,
      });
    }
    await this.setState({
      inputs: inpu,
    });
    this.setState({
      addButton: true,
    });

    this.setState({
      nameValue: true,
      endTimeValue: true,
      majorValue: true,
      titleValue: true,
    });
  }
  backtoHome() {
    this.setState({
      frEducationName: [],
      inputs: [1],
    });

    this.props.history.push(`/${this.props.workspaceType}/home`);
  }
  render() {
    const { t } = this.props;
    return (
      <div
        className="screenInnerGap"
        style={{
          // marginLeft: "22px",
          // marginRight: "22px",
          // marginBottom: "22px",
          marginTop: "80px",
          padding: "5px",
        }}
      >
        <ToastContainer position="top-right" autoClose={5000} />

        <div className="">
          <div className={this.props.firstTime ? "" : "screenInnerGap"}>
            <Container>
              <ToastContainer position="top-right" autoClose={5000} />
              {/* <Card>
          <CardContent> */}
              <div className="">
                <div>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12} lg={12}>
                      <div className="peopleInvitationCardWrapper">
                        <Grid container spacing={1}>
                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <h6>{t("Add Education Details")}</h6>
                            <div style={{ float: "left" }}>
                              <button onClick={this.backtoHome}>
                                {t("Back")}{" "}
                              </button>
                            </div>
                            {this.state.inputs.length > 0 &&
                              this.state.inputs.map((entry, index) => (
                                <div>
                                  <Grid container spacing={0}>
                                    <Grid item md={12} xs={12} sm={12} lg={12}>
                                      <button
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "35px",
                                          float: "right",
                                          marginTop: "12px",
                                        }}
                                        class=""
                                        onClick={() =>
                                          this.removeTranslation(entry, index)
                                        }
                                      >
                                        {" "}
                                        -{" "}
                                      </button>
                                    </Grid>{" "}
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid item md={6} xs={6} sm={6} lg={6}>
                                      <h6>{t("Institution Name")}</h6>
                                      <TextField
                                        style={{
                                          borderRadius: "10px",
                                          marginBottom: "8px",
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        required=""
                                        name="frEducationName"
                                        onChange={(e) =>
                                          this.handleInputEducation(
                                            e,
                                            index,
                                            "name"
                                          )
                                        }
                                        value={
                                          this.state.frEducationName &&
                                          this.state.frEducationName[index] &&
                                          this.state.frEducationName[index]
                                            .educationName
                                        }
                                      />
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6} lg={6}>
                                      <h6
                                        style={{
                                          paddingLeft: "8px",
                                        }}
                                      >
                                        {t("Completion Year")}
                                      </h6>
                                      {/* <TextField
                                        style={{
                                          borderRadius: "10px",
                                          paddingLeft: "2px",
                                          marginBottom: "8px",
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        required=""
                                        name="frEducationName"
                                        onChange={(e) =>
                                          this.handleInputEducation(
                                            e,
                                            index,
                                            "endYear"
                                          )
                                        }
                                        value={
                                          this.state.frEducationName &&
                                          this.state.frEducationName[index] &&
                                          this.state.frEducationName[index]
                                            .endYear
                                        }
                                      /> */}
                                      <TextField
                                        id="dropdown"
                                        select
                                        label=""
                                        value={
                                          this.state.frEducationName &&
                                          this.state.frEducationName[index] &&
                                          this.state.frEducationName[index]
                                            .endYear
                                        }
                                        onChange={(e) =>
                                          this.handleInputEducation(
                                            e,
                                            index,
                                            "endYear"
                                          )
                                        }
                                        variant="outlined"
                                        fullWidth={true}
                                      >
                                        {this.state.completionYear.map(
                                          (option, index) => (
                                            <MenuItem
                                              key={option.id}
                                              value={option.id}
                                            >
                                              {option.name}
                                            </MenuItem>
                                          )
                                        )}
                                      </TextField>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={0}>
                                    <Grid item md={6} xs={6} sm={6} lg={6}>
                                      <h6>{t("Title")}</h6>
                                      <TextField
                                        id="dropdown"
                                        select
                                        label=""
                                        value={
                                          this.state.frSkillName &&
                                          this.state.frSkillName[index] &&
                                          this.state.frSkillName[index].title
                                        }
                                        onChange={(e) =>
                                          this.handleInputEducation(
                                            e,
                                            index,
                                            "title"
                                          )
                                        }
                                        variant="outlined"
                                        fullWidth={true}
                                        options={this.state.skills}
                                      >
                                        <MenuItem value="">
                                          <>{t("Select")}</>
                                        </MenuItem>
                                        <MenuItem value="UnderGraduate">
                                          <>{t("Under Graduate")}</>
                                        </MenuItem>
                                        <MenuItem value="PostGraduate">
                                          <>{t("Post Graduate")}</>
                                        </MenuItem>
                                        <MenuItem value="Doctorate">
                                          <>{t("Doctorate")}</>
                                        </MenuItem>
                                        <MenuItem value="Diploma">
                                          <>{t("Diploma")}</>
                                        </MenuItem>
                                        <MenuItem value="Other">
                                          <>{t("Other")}</>
                                        </MenuItem>
                                      </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6} lg={6}>
                                      <h6
                                        style={{
                                          paddingLeft: "8px",
                                        }}
                                      >
                                        {t("Major")}
                                      </h6>
                                      <TextField
                                        style={{
                                          borderRadius: "10px",
                                          paddingLeft: "8px",
                                          marginBottom: "8px",
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        required=""
                                        name="frEducationName"
                                        onChange={(e) =>
                                          this.handleInputEducation(
                                            e,
                                            index,
                                            "major"
                                          )
                                        }
                                        value={
                                          this.state.frEducationName &&
                                          this.state.frEducationName[index] &&
                                          this.state.frEducationName[index]
                                            .major
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              ))}
                            <Grid container spacing={0}>
                              <Grid item md={6} xs={6} sm={6} lg={6}>
                                {
                                  <div className="profileFormBtn">
                                    <button
                                      style={{
                                        borderRadius: "10px",
                                        padding: "8px",
                                      }}
                                      onClick={this.saveEducation}
                                    >
                                      {t("Save")}
                                    </button>
                                  </div>
                                }
                              </Grid>
                              <Grid item md={6} xs={6} sm={6} lg={6}>
                                {this.state.addButton && (
                                  <button
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "20px",
                                      float: "right",
                                    }}
                                    class=""
                                    onClick={this.addTranslation}
                                  >
                                    +
                                  </button>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  handleInputEducation(event, index, type) {
    if (type == "name") {
      if (event.target.value == "") {
        this.setState({
          nameValue: false,
        });
      } else {
        this.setState({
          nameValue: true,
        });
      }
    } else if (type == "endYear") {
      if (event.target.value == "") {
        this.setState({
          endTimeValue: false,
        });
      } else {
        this.setState({
          endTimeValue: true,
        });
      }
    } else if (type == "major") {
      if (event.target.value == "") {
        this.setState({
          majorValue: false,
        });
      } else {
        this.setState({
          majorValue: true,
        });
      }
    } else if (type == "title") {
      if (event.target.value == "") {
        this.setState({
          titleValue: false,
        });
      } else {
        this.setState({
          titleValue: true,
        });
      }
    }

    console.log("inputs values");
    console.log(event);

    var newEducations = this.state.frEducationName;
    if (newEducations[index]) {
      if (type == "name") {
        event.preventDefault();
        event.stopPropagation();
        var obj = newEducations[index];
        obj.educationName = event.target.value;
        newEducations[index] = obj;
      } else if (type == "endYear") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        obj.endYear = event.target.value;
        newEducations[index] = obj;
      } else if (type == "major") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        obj.major = event.target.value;
        newEducations[index] = obj;
      } else if (type == "title") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        obj.title = event.target.value;
        newEducations[index] = obj;
      } else {
        event.preventDefault();
        event.stopPropagation();
        var obj = newEducations[index];
        obj.endYear = event.target.value;
        newEducations[index] = obj;
      }
    } else {
      if (type == "name") {
        event.preventDefault();
        event.stopPropagation();
        var obj = newEducations[index];
        var obj = {
          educationName: event.target.value,
          fromYear: "",
          endYear: "",
        };
        newEducations[index] = obj;
      } else if (type == "endYear") {
        var data = event.target.value;
        var obj = newEducations[index];
        var obj = {
          educationName: "",
          // fromYear: data,
          endYear: "",
        };
        console.log("inputsss");
        console.log(obj);
        newEducations[index] = obj;
      } else if (type == "major") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        obj.major = event.target.value;
        newEducations[index] = obj;
      } else if (type == "title") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        obj.title = event.target.value;
        newEducations[index] = obj;
      } else {
        event.preventDefault();
        event.stopPropagation();
        var obj = {
          educationName: "",
          // fromYear: "",
          endYear: event.target.value,
        };
        newEducations[index] = obj;
      }
    }

    this.setState({
      frEducationName: newEducations,
    });
    console.log("this.state.frEducationName");
    console.log(newEducation);
    console.log(this.state.frEducationName);
    //  this.setState({answers:answer_id.target.value})
  }
  handleForm(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleClickEdit() {
    this.setState({ savebtnState: false });
  }

  saveEducation() {
    if (
      this.state.nameValue &&
      this.state.endTimeValue &&
      this.state.majorValue &&
      this.state.titleValue
    ) {
      var saveEducation = {
        education: this.state.frEducationName,
        firstTimeSetup: "true",
      };
      ProfileService.saveEducationDetails(saveEducation)
        .then((response) => {
          var result = response.data;

          var data = [];
          console.log(this.state.frEducationName);
          for (let i = 0; i < this.state.frEducationName.length; i++) {
            data.push({
              educationName: "",
              fromYear: "",
              endYear: "",
            });
            this.setState({ frEducationName: data });
          }
          toast.success("Saved successfully");
          this.props.history.push(`/home`);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.warning("Please fill the all Filed..");
    }
  }

  getEducationDetails() {
    var saveEducation = {};
    ProfileService.getEducationDetails(saveEducation)
      .then((response) => {
        var result = response.data;

        console.log("education details");
        console.log(response.data.data);
        if (response.data.status == 1) {
          this.setState({ educationDetails: response.data.data.education });
          var educationData = response.data.data.education;

          console.log("education" + educationData);
          console.log(educationData);
          this.setState({
            educationData,
          });
          this.setState({ addButton: true });
          Object.keys(educationData).forEach((k) => {
            console.log(k);
            console.log(k.toString());

            this.setState({
              dynamicLangArray: [...this.state.dynamicLangArray, k],
            });
          });

          let unique_dynamic_key = [...new Set(this.state.dynamicLangArray)];

          unique_dynamic_key.forEach((k) => {
            var educationName = "";
            var fromYear = "";
            var endYear = "";
            if (k in this.state.educationData) {
              console.log(k + " in announment");
              educationName = this.state.educationData[k].educationName;
              fromYear = this.state.educationData[k].fromYear;
              endYear = this.state.educationData[k].endYear;
            }

            var obj = {
              educationName: educationName,
              fromYear: fromYear,
              endYear: endYear,
            };

            this.setState({
              frEducationName: [...this.state.frEducationName, obj],
            });
          });

          if (this.state.frEducationName.length > 0) {
            this.setState({ inputs: [] });
            for (let k = 0; k < this.state.frEducationName.length; k++) {
              var input = 1;

              this.setState({
                inputs: [...this.state.inputs, input],
              });
            }
          }
        } else {
          this.setState({ educationDetails: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  console.log("Loading Notification Props");
  console.log(state.loginDetailsApi.loginDetails);

  return {
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    subjectsList: state.commonApi.subjectslist,
    languageList: state.commonApi.languagelist,
    tagList: state.commonApi.taglist,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    schoolList: state.commonApi.schoollist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(FirstTimeEducation)));
