import ForumService from "../../api/ForumService";
import Utils from "../../utils/Utils";
import {
  GET_QUESTION_LIST,
  GET_ASK_QUESTION_LIST,
  GET_FORUM_QUESTION_LIST,
  GET_FORUM_ANSWER__LIST,
  GET_FORUM_SEARCH_QUESTION_LIST,
  GET_FORUM_TAG_LIST,
} from "../types";
import { UPDATE_QUESTION_LIST } from "../types";
import { DELETE_QUESTION_LIST } from "../types";
import { DELETE_DASHBOARD_QUESTION_LIST } from "../types";
import { DELETE_LIBRARY_QUESTION_LIST } from "../types";
import { DELETE_SUBJECT_QUESTION_LIST } from "../types";
import { QUESTION_LOADER } from "../types";
import moment from "moment";
// import AuthService from "../../session/AuthService";
// import FriendsFinder from "../../api/FriendsFinder";
export const askQuestionListV2 =
  (pageNo, subjectId, userId) => async (dispatch) => {
    try {
      let interestingTop;
      interestingTop = {
        page: pageNo,
        groupId: subjectId,
      };

      ForumService.askQuestionListV2(interestingTop)
        .then((response) => {
          var question_list = [];
          question_list = response.data.data;
          dispatch({
            type: GET_FORUM_QUESTION_LIST,
            payload: question_list,
          });
        })
        .catch((error) => {
          console.log(error);
        });
      // }
    } catch (error) {
      dispatch({
        type: GET_FORUM_QUESTION_LIST,
        payload: [],
      });
    }
    return [];
  };
export const searchForumAskFilter = (data) => async (dispatch) => {
  try {
    ForumService.searchForumAskFilter(data)
      .then((response) => {
        var question_list = [];
        question_list = response.data.data;
        console.log(data);
        dispatch({
          type: GET_FORUM_SEARCH_QUESTION_LIST,
          payload: question_list,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // }
  } catch (error) {
    dispatch({
      type: GET_FORUM_SEARCH_QUESTION_LIST,
      payload: [],
    });
  }
  return [];
};
export const forumTagList = (data) => async (dispatch) => {
  try {
    let obj = {
      subjectId: +data,
    };
    ForumService.forumTagList(obj)
      .then((response) => {
        var tag_list = [];
        tag_list = response.data.data;
        console.log(data);
        dispatch({
          type: GET_FORUM_TAG_LIST,
          payload: tag_list,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // }
  } catch (error) {
    dispatch({
      type: GET_FORUM_TAG_LIST,
      payload: [],
    });
  }
  return [];
};
export const askQuestionAnswerList =
  (pageNo, subjectId, userId) => async (dispatch) => {
    try {
      let interestingTop;
      interestingTop = {
        page: pageNo,
        questionId: subjectId,
      };

      ForumService.askQuestionAnswerList(interestingTop)
        .then((response) => {
          var answer_list = [];
          answer_list = response.data.data;
          console.log("GET_FORUM_ANSWER__LIST result", answer_list);
          dispatch({
            type: GET_FORUM_ANSWER__LIST,
            payload: answer_list,
          });
        })
        .catch((error) => {
          console.log(error);
        });
      // }
    } catch (error) {
      dispatch({
        type: GET_FORUM_ANSWER__LIST,
        payload: [],
      });
    }
    return [];
  };
export const getQuestionsList =
  (pageNo, subjectId, userId) => async (dispatch) => {
    try {
      dispatch(QuestionListLoader(true));
      let interestingTop;
      interestingTop = {
        page: pageNo - 1,
        groupId: subjectId,
      };
      // if (subjectId > 0) {
      //   interestingTop = {
      //     type: typeQuestion,
      //     page: pageNo - 1,
      //     subjectkey: subjectId,
      //   };
      // } else {
      //   interestingTop = {
      //     type: typeQuestion,
      //     page: pageNo - 1,
      //     subjectkey: 0,
      //   };
      // }
      //  alert(firstTimeLoad)
      //  if (firstTimeLoad) {
      ForumService.askQuestionList(interestingTop)
        .then((response) => {
          var result = response.data;

          if (result.data.questions.length >= 1) {
            var topqestList = [];
            const question_list = [];

            for (let j = 0; j < result.data.questions.length; j++) {
              var createdDate = result.data.questions[j].createdDate;
              moment(createdDate).format("DD/MM/yyyy");
              var Dates = Utils.getLastAskedDate(createdDate);
              question_list.push({
                answer: result.data.questions[j].answer,
                answerSize: result.data.questions[j].answerSize,
                createdDate: result.data.questions[j].createdDate,
                questionDescription:
                  result.data.questions[j].questionDescription,
                questionId: result.data.questions[j].questionId,
                questionName: result.data.questions[j].questionName,
                questionTags: result.data.questions[j].questionTags,
                questionviewers: result.data.questions[j].questionviewers,
                questionType: result.data.questions[j].questionType,
                solved: result.data.questions[j].solved,
                updatedDate: result.data.questions[j].updatedDate,
                userId: result.data.questions[j].userId,
                userName: result.data.questions[j].userName,
                views: result.data.questions[j].views,
                mediaFiles: result.data.questions[j].mediaFiles,
                votes: result.data.questions[j].votes,
                gradeId: result.data.questions[j].grade,
                questionFile: result.data.questions[j].questionFile,
                lastAsked: Dates,
                programId: result.data.questions[j].programId,
                tagsValue: result.data.questions[j].tagsValue,
                questionLikesArray: result.data.questions[j].questionLikesArray,
                originalFileName: result.data.questions[j].originalFileName,
                markAsPrivate: result.data.questions[j].markAsPrivate,
                star: result.data.questions[j].questionLikesArray.filter(
                  (star) => star.userId == userId
                ),
                pageNo: pageNo,
                status: result.data.questions[j].status,
                answerBlockStatus: result.data.questions[j].answerBlockStatus,
                aicheck: result.data.questions[j].aicheck,
                filterCheck: result.data.questions[j].filterCheck,
              });
            }
            console.log(question_list);
            topqestList["topQuestList"] = question_list;
            topqestList["totalItems"] = result.data.totalItems;
            topqestList["updatedKey"] = "normal";
            dispatch({
              type: GET_ASK_QUESTION_LIST,
              payload: topqestList,
            });
            // if (firstTimeLoad && !secondTimeLoad) {
            //   dispatch({
            //     type: GET_QUESTION_LIST,
            //     payload: topqestList,
            //   });
            // } else {
            //   dispatch({
            //     type: UPDATE_QUESTION_LIST,
            //     payload: topqestList,
            //   });
            // }
          } else if (result.data.questions.length === 0) {
            var topqestList = [];
            topqestList["topQuestList"] = [];
            topqestList["totalItems"] = 0;
            topqestList["updatedKey"] = "normal";
            dispatch({
              type: GET_ASK_QUESTION_LIST,
              payload: topqestList,
            });
            // if (firstTimeLoad && !secondTimeLoad) {
            //   dispatch({
            //     type: GET_QUESTION_LIST,
            //     payload: topqestList,
            //   });
            // } else {
            //   dispatch({
            //     type: UPDATE_QUESTION_LIST,
            //     payload: topqestList,
            //   });
            // }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // }
    } catch (error) {
      dispatch({
        type: GET_ASK_QUESTION_LIST,
        payload: [],
      });
    }
    return [];
  };

export const updateQuestionValues =
  (questionId, topQuestions, solved_total_items, userId) =>
  async (dispatch) => {
    try {
      let obj = {
        questionId: questionId,
      };

      ForumService.getLibraryQuestionById(obj)
        .then((response) => {
          var result = response.data.data;

          // const question_list = [];
          var questionData = topQuestions;
          var topqestList = [];
          for (let j = 0; j < questionData.length; j++) {
            if (questionData[j].questionId == questionId) {
              var tObj = result.questions.question;

              var createdDate = tObj.createdDate;
              moment(createdDate).format("DD/MM/yyyy");
              var Dates = Utils.getLastAskedDate(createdDate);
              questionData[j].answer = tObj.answer;
              questionData[j].answerSize = tObj.answerSize;
              questionData[j].createdDate = tObj.createdDate;
              questionData[j].questionDescription = tObj.questionDescription;
              questionData[j].questionId = tObj.questionId;
              questionData[j].questionName = tObj.questionName;
              questionData[j].questionTags = tObj.questionTags;
              questionData[j].questionviewers = tObj.questionviewers;
              questionData[j].questionType = tObj.questionType;
              questionData[j].solved = tObj.solved;
              questionData[j].updatedDate = tObj.updatedDate;
              questionData[j].userId = tObj.userId;
              questionData[j].userName = tObj.userName;
              questionData[j].views = tObj.views;
              questionData[j].votes = tObj.votes;
              questionData[j].gradeId = tObj.grade;
              questionData[j].questionFile = tObj.questionFile;
              questionData[j].lastAsked = Dates;
              questionData[j].programId = tObj.programId;
              questionData[j].originalFileName = tObj.originalFileName;
              questionData[j].markAsPrivate = tObj.markAsPrivate;
              questionData[j].star = tObj.questionLikesArray.filter(
                (star) => star.userId == userId
              );
              // questionData[j].pageNo = questionData[j].pageNo;
              questionData[j].status = tObj.status;
              questionData[j].answerBlockStatus = tObj.answerBlockStatus;
              questionData[j].aicheck = tObj.aicheck;
              questionData[j].filterCheck = tObj.filterCheck;
            } else {
            }
          }
          topqestList["topQuestList"] = questionData;
          topqestList["totalItems"] = solved_total_items;
          topqestList["updatedKey"] = "updated";
          dispatch({
            type: GET_QUESTION_LIST,
            payload: topqestList,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_QUESTION_LIST,
        payload: [],
      });
    }
    return [];
  };

export const SearchQuestionsList =
  (searchQuestionList, pageNo, userId) => async (dispatch) => {
    try {
      var result = searchQuestionList;
      var topqestList = [];
      var question_list = [];
      var userlist = [];
      if (result.questions && result.questions.length > 0) {
        // dispatch(QuestionListLoader(true));
        for (let j = 0; j < result.questions.length; j++) {
          var createdDate = result.questions[j].createdDate;
          moment(createdDate).format("DD/MM/yyyy");
          var Dates = Utils.getLastAskedDate(createdDate);
          question_list.push({
            answer: result.questions[j].answer,
            answerSize: result.questions[j].answerSize,
            createdDate: result.questions[j].createdDate,
            questionDescription: result.questions[j].questionDescription,
            questionId: result.questions[j].questionId,
            questionName: result.questions[j].questionName,
            questionTags: result.questions[j].questionTags,
            questionviewers: result.questions[j].questionviewers,
            questionType: result.questions[j].questionType,
            solved: result.questions[j].solved,
            updatedDate: result.questions[j].updatedDate,
            userId: result.questions[j].userId,
            userName: result.questions[j].userName,
            views: result.questions[j].views,
            votes: result.questions[j].votes,
            gradeId: result.questions[j].grade,
            questionFile: result.questions[j].questionFile,
            lastAsked: Dates,
            programId: result.questions[j].programId,
            questionLikesArray: result.questions[j].questionLikesArray,
            originalFileName: result.questions[j].originalFileName,
            markAsPrivate: result.questions[j].markAsPrivate,
            star: result.questions[j].questionLikesArray.filter(
              (star) => star.userId == userId
            ),
            pageNo: pageNo,
            status: result.questions[j].status,
            answerBlockStatus: result.questions[j].answerBlockStatus,
            aicheck: result.questions[j].aicheck,
            filterCheck: result.questions[j].filterCheck,
          });
          userlist.push(result.questions[j].userId);
        }
        topqestList["topQuestList"] = question_list;
        topqestList["totalItems"] = result.totalItems;
        topqestList["updatedKey"] = "search";
        dispatch({
          type: GET_QUESTION_LIST,
          payload: topqestList,
        });
      } else {
        topqestList["topQuestList"] = [];
        topqestList["totalItems"] = 0;
        topqestList["updatedKey"] = "search";
        dispatch({
          type: GET_QUESTION_LIST,
          payload: topqestList,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_QUESTION_LIST,
        payload: [],
      });
    }
    return [];
  };

export const DeleteQuestionsList =
  (QuestionList, DashList, LibList, SubjectQuestionList) =>
  async (dispatch) => {
    try {
      dispatch({
        type: DELETE_QUESTION_LIST,
        payload: QuestionList,
      });
      dispatch({
        type: DELETE_DASHBOARD_QUESTION_LIST,
        payload: DashList,
      });
      dispatch({
        type: DELETE_LIBRARY_QUESTION_LIST,
        payload: LibList,
      });
      dispatch({
        type: DELETE_SUBJECT_QUESTION_LIST,
        payload: SubjectQuestionList,
      });
    } catch (error) {
      dispatch({
        type: DELETE_QUESTION_LIST,
        payload: [],
      });
      dispatch({
        type: DELETE_DASHBOARD_QUESTION_LIST,
        payload: [],
      });
      dispatch({
        type: DELETE_LIBRARY_QUESTION_LIST,
        payload: [],
      });
      dispatch({
        type: DELETE_SUBJECT_QUESTION_LIST,
        payload: [],
      });
    }
    return [];
  };

export const SaveOrUpdateQuestionsList =
  (QuestionList, DashList, LibList) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_LIBRARY_QUESTION_LIST,
        payload: LibList,
      });
    } catch (error) {
      dispatch({
        type: DELETE_QUESTION_LIST,
        payload: [],
      });
    }
    return [];
  };

export const QuestionListLoader = (type) => async (dispatch) => {
  try {
    dispatch({
      type: QUESTION_LOADER,
      payload: type,
    });
  } catch (error) {
    dispatch({
      type: QUESTION_LOADER,
      payload: false,
    });
  }
  return false;
};
