import React, { Component } from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MyYuukke from "../../../api/MyYuukke";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import AuthService from "../../../session/AuthService";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import Grid from "@material-ui/core/Grid";
import PeopleCard from "../../../components/PeopleCard";
import { connect } from "react-redux";
const styleArrow = {
  width: 42,
  height: 42,
  boxShadow: "0px 3px 6px #00000029",
  border: "1px solid #DEDEDF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
  borderRadius: "100%",
  cursor: "pointer",
  position: "absolute",
  top: "35%",
  zIndex: 10,
};
function NextArrow(props) {
  const { className, style, onClick, currentSlide, slideCount } = props;
  return currentSlide !== slideCount - 3 ? (
    <div style={{ ...styleArrow, right: "5%" }} onClick={onClick}>
      <ArrowForwardIcon />
    </div>
  ) : (
    <></>
  );
}

function PrevArrow(props) {
  const { className, style, onClick, currentSlide } = props;
  return currentSlide !== 0 ? (
    <div style={{ ...styleArrow, left: "5%" }} onClick={onClick}>
      <ArrowBackIcon />
    </div>
  ) : (
    <></>
  );
}
class PeoplesCarouselList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recommendedFriendsList: [],
      recommendedFriendsListState: false,
      searchList: [],
    };
    this.getRecommendedFriendsList = this.getRecommendedFriendsList.bind(this);
    this.requestSentPage = this.requestSentPage.bind(this);
    this.gotoSearchPage = this.gotoSearchPage.bind(this);
    this.ignoreSentPage = this.ignoreSentPage.bind(this);
    this.searchMembers = this.searchMembers.bind(this);
  }
  componentDidMount() {
    this.getRecommendedFriendsList(
      this.props.recommended_friends_list,
      this.props.recommendedListLength
    );
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.recommended_friends_list !== this.props.recommended_friends_list
    ) {
      this.getRecommendedFriendsList(
        this.props.recommended_friends_list,
        this.props.recommendedListLength
      );
    }
  }

  render() {
    const { t } = this.props;
    const settings = {
      infinite: false,
      slidesToShow: 5,
      speed: 500,
      arrows: true,
      draggable: true,
      dots: false,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            draggable: true,
            dots: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
            draggable: true,
            dots: false,
          },
        },
        {
          breakpoint: 595,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            draggable: true,
            dots: false,
          },
        },
      ],
    };

    return (
      <div className="peopleCarouselWrapper">
        {/* <div className="spaceFiletCard newPostCardWrapper">
          <Grid container spacing={1}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: "12px" }}
            >
              <TextField
                id="subjects"
                label=""
                style={{ width: "100%", height: "100%" }}
                variant="outlined"
                onChange={(e) => this.searchMembers(e.target.value)}
                placeholder={t("Search Suggested Friends...")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <div className="badgeMainWrapper"></div>
            </Grid>
          </Grid>
        </div> */}
        <div className="searchHeading flexDefault flexAlignCenter flexJustifySpacebetween">
          <div>
            <p className="boldLabel">{t("People you may know")}</p>
          </div>

          <div
            style={{ cursor: "pointer", display: "inline-flex" }}
            onClick={this.gotoSearchPage}
          >
            <ArrowBackIcon style={{ color: "rgba(69, 109, 149, 1)" }} />
            <p
              className="boldLabel"
              style={{ color: "rgba(69, 109, 149, 1)", cursor: "pointer" }}
            >
              {t("Back to search")}
            </p>
          </div>
        </div>

        <Grid container spacing={2}>
          {this.state.searchList &&
            this.state.searchList.map((people, index) => {
              return (
                <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
                  <React.Fragment key={index}>
                    <PeopleCard
                      userId={people.uId}
                      Dname={people.dName}
                      // subjects={people.user.userLearn}
                      name={`${people.firstName} ${people.lastName}`}
                      biography={people.biography}
                      requestSent={this.requestSentPage}
                      ignoreSent={this.ignoreSentPage}
                    />
                  </React.Fragment>
                </Grid>
              );
            })}

          {this.state.searchList.length == 0 && (
            <div class="no-data">
              <center>
                <p>{t("No Results Found")}</p>
              </center>
            </div>
          )}
          {/* </Slider> */}
        </Grid>
      </div>
    );
  }
  searchMembers(value) {
    if (value !== null) {
      var search = {
        searchKey: value,
      };
    } else {
      var search = {
        searchKey: "",
      };
    }
    MyYuukke.searchUsers(search)
      .then((response) => {
        var result = response.data;

        var stringArray = result.data.searchList;

        if (stringArray.length > 0) {
          this.setState({
            searchList: stringArray,
          });
        } else {
          this.setState({
            searchList: [],
          });
        }
        if (value === null || value === "") {
          this.setState({
            searchList: this.props.recommended_friends_list,
          });
        }
        console.log("searchList");
        console.log(result.data.searchList);
        console.log(stringArray);
        console.log(this.state.searchList);
      })

      .catch((error) => {
        console.log(error);
      });
  }

  ignoreSentPage() {
    this.props.ignoreSentPageData();
  }
  requestSentPage() {
    this.props.requestSentPageData();
  }
  async getRecommendedFriendsList(
    recommended_friends_list,
    recommendedListLength
  ) {
    if (recommended_friends_list.length > 0) {
      await this.setState({
        searchList: recommended_friends_list,
        recommendedFriendsListState: false,
        recommendedListLengths: recommendedListLength,
        //  FriendsListSearch: recommended_friends_list,
      });
      console.log("recommendedFriendsList");
    } else {
      await this.setState({
        recommendedFriendsListState: true,
        recommendedListLengths: recommendedListLength,
        //  FriendsListSearch: recommended_friends_list,
      });
    }
  }

  gotoSearchPage() {
    this.props.history.push({
      //pathname: '/search'
      pathname: `/${this.props.workspaceType}/mynetwork`,
    });
  }
}
const mapStateToProps = function (state) {
  return {
    workspaceType:
      state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(PeoplesCarouselList)));
