import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import i18n from "../../i18n";
import { withTranslation } from "react-i18next";
import "react-widgets/styles.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Grid } from "@material-ui/core";
import {
  getJobRequestByOtherUser,
  userSawAskHelpEntrySave,
} from "../../redux/actions/myRequestActions";
import { API_URL } from "../../config/app-config";
import dummyimage from "../../img/default_avatar.jpg";
import moment from "moment";
import { GoCalendar } from "react-icons/go";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import BlockUi from "react-block-ui";
import * as APPCONFIG from "../../config/app-config.js";
import ProfileService from "../../api/ProfileService";
import { premiumUser } from "../../utils";

var interest = [];
var industry = [];
var tags = [];
class OtherRequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postRequest: false,
      tabIndex: 0,
      pageNo: 0,
      selectAreaName: false,
      selectIndustryName: "",
      addInterestList: [],
      addIndustryList: [],
      addTagsList: [],
      jobRequest: {},
      othersRequest: [],
      noMoreState: false,
      tagList: [],
      allTaList: [],
      activeWord: -1,
      areaOfInterestData: [],
      industryData: [],
    };
    this.searchArea = this.searchArea.bind(this);
    this.searchIndustry = this.searchIndustry.bind(this);
    this.handleChangeRequestCard = this.handleChangeRequestCard.bind(this);
    this.onChangeCallingSeeMore = this.onChangeCallingSeeMore.bind(this);
    this.getOthersJobRequest = this.getOthersJobRequest.bind(this);
    this.searchSubjects = this.searchSubjects.bind(this);
    this.backtoHome = this.backtoHome.bind(this);
    // this.onPostRequest= this.onPostRequest.bind(this);
  }

  addActiveStatus = (filterData) => {
    const checkBoxStatus = filterData.map((data) => {
      data.isActive = false;
      return data;
    });
    return checkBoxStatus;
  };

  componentDidMount() {
    interest = [];
    industry = [];
    tags = [];
    this.setState({
      //   tagList: this.props.tagList,
      allTaList: this.props.tagList,
    });
    if (this.props.tagList) {
      this.setState({ tagList: this.addActiveStatus(this.props.tagList) });
    }

    if (this.props.interestList) {
      this.setState({
        areaOfInterestData: this.addActiveStatus(this.props.interestList),
      });
    }
    if (this.props.industryList) {
      this.setState({
        industryData: this.addActiveStatus(this.props.industryList),
      });
    }

    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    var data = {
      page: this.state.pageNo,
      industryIds: industry,
      interestIds: interest,
      tagIds: tags,
      filterType: "other",
    };
    var stateData = {
      industry,
      interest,
      tags,
    };
    this.setState({ jobRequest: stateData });
    // this.getOthersJobRequest(data);
    this.props.getJobRequestByOtherUser(data, 0);
    this.setState({
      pageNo: 0,
    });
  }

  render() {
    console.log("useRequestList", this.props.myRequestList);

    const { t } = this.props;
    const { tabIndex, tagList } = this.state;

    return (
      <>
        <div className={this.props.otherRequest ? "otherRequestPage" : ""}>
          {this.props.viewList && (
            <h3 className="listHomeTitle" style={{ paddingTop: "6rem" }}>
              {t("Others Requests")}
            </h3>
          )}
          {!this.props.firstTimeRegPage && (
            <button
              onClick={() => this.onPostRequest()}
              style={{ float: "right" }}
            >
              {t("Request")}
            </button>
          )}
          {/* <div
      myRequestList
            style={{
              marginTop: "20px",
              marginRight: "12px",
              marginLeft: "0px",
              marginBottom: "12px",
              float:"right"
            }}
            className="ansBackQuestion flexDefault"
            onClick={this.backtoHome}
            disabled={this.state.blocksend}
          >
        
            <p>{t("Back")}</p>
            <ArrowForwardIcon />
          </div> */}
          <BlockUi
            tag="div"
            message={t("Loading, please wait")}
            style={{ minHeight: "130px" }}
            className="wallLoadingPage"
            blocking={this.props.otherRequestLoaderStatus}
          >
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className="aboutMyRequestWrapper">
                  <p className="aboutGroupTitle">{t("Tags")}</p>
                  {
                    <div className="myRequestTagCard">
                      <div
                        className="homePostInputWrapper"
                        style={{ marginTop: "12px" }}
                      >
                        <TextField
                          id=""
                          label=""
                          style={{ width: "100%", height: "20px" }}
                          variant="outlined"
                          onChange={(e) => this.searchSubjects(e.target.value)}
                          placeholder={t("Search...")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      <div className="homeMyRequestCard">
                        {
                          <div style={{ marginBottom: "20px" }}>
                            {tagList &&
                              tagList.length > 0 &&
                              tagList.map((subject, index) => (
                                <div
                                  className={`segmentsList${
                                    subject.isActive ? " selected" : ""
                                  }`}
                                >
                                  <div
                                    onClick={() =>
                                      this.selectedAreaofinterest(
                                        subject.tagId,
                                        index,
                                        "Tags"
                                      )
                                    }
                                  >
                                    <p
                                      style={{
                                        padding: "8px",
                                      }}
                                    >
                                      {subject.tagName}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            {tagList?.length === 0 && (
                              <p>{t("No tags present..")}</p>
                            )}
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
                <div
                  className="aboutMyRequestWrapper"
                  style={{ marginTop: "16px" }}
                >
                  <p className="aboutGroupTitle">{t("Area of Interest")}</p>

                  {this.state.areaOfInterestData.length > 0 && (
                    <div className="homeMyRequestCard">
                      {
                        <div style={{ marginBottom: "20px" }}>
                          {this.state.areaOfInterestData &&
                            this.state.areaOfInterestData.length > 0 &&
                            this.state.areaOfInterestData.map((area, index) => (
                              <div>
                                <div>
                                  <div style={{ paddingRight: "2px" }}>
                                    <div
                                      className="otherRequestPage-wrapper"
                                      onClick={() =>
                                        this.selectedAreaofinterest(
                                          area.areaId,
                                          index,
                                          "Interest"
                                        )
                                      }
                                    >
                                      <p
                                        style={{
                                          padding: "8px",
                                        }}
                                      >
                                        {area.areaName}
                                      </p>

                                      <div>
                                        <input
                                          checked={area.isActive}
                                          className="react-switch-checkbox"
                                          type="checkbox"
                                        />
                                        {/* <Switch
                                          id={area.areaId}
                                          // isOn={this.state.selectAreaName}
                                          onColor="#EF476F"
                                          handleToggle={() =>
                                            this.selectedAreaofinterest(
                                              area.areaId,
                                              index,
                                              "Interest"
                                            )
                                          }
                                        /> */}
                                      </div>
                                    </div>
                                    {/* </Grid>{" "}
                                    </Grid> */}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      }
                    </div>
                  )}
                </div>
                <div
                  className="aboutMyRequestWrapper"
                  style={{ marginTop: "16px" }}
                >
                  <p className="aboutGroupTitle">{t("Industry")}</p>

                  {this.state.industryData.length > 0 && (
                    <div className="homeMyRequestCard">
                      {
                        <div style={{ marginBottom: "20px" }}>
                          {this.state.industryData &&
                            this.state.industryData.length > 0 &&
                            this.state.industryData.map((industry, index) => (
                              <div>
                                <div>
                                  <div style={{ paddingRight: "2px" }}>
                                    <div
                                      className="otherRequestPage-wrapper"
                                      onClick={() =>
                                        this.selectedAreaofinterest(
                                          industry.industryId,
                                          index,
                                          "Industry"
                                        )
                                      }
                                    >
                                      <p
                                        style={{
                                          padding: "8px",
                                        }}
                                      >
                                        {industry.industryName}
                                      </p>

                                      <div>
                                        <input
                                          checked={industry.isActive}
                                          className="react-switch-checkbox"
                                          type="checkbox"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      }
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <div className="announcementWrapperOuter">
                  {this.props.otherRequestList &&
                    this.props.otherRequestList.length > 0 &&
                    this.props.otherRequestList.map((request, index) => (
                      <div
                        className="peopleInvitationCardWrapper1"
                        style={{ marginBottom: "8px" }}
                        onClick={() =>
                          this.handleChangeRequestCard(
                            request.jobId,
                            request.userSaw
                          )
                        }
                      >
                        <Grid container spacing={2}>
                          <Grid item lg={2} md={2} sm={12} xs={12}>
                            <div class="postuserImg">
                              <img
                                className="rounded-circle"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                src={
                                  request.userBusiness
                                    ? `${APPCONFIG.API_URL}auth/image/${request.profilePhoto}`
                                    : API_URL +
                                      "api/getUserPhoto/" +
                                      request.userId +
                                      "/" +
                                      request.displayName
                                }
                                alt="user"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = dummyimage;
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item lg={10} md={10} sm={12} xs={12}>
                            {" "}
                            <div className="d-flex">
                              <h6
                                className="text-capitalize mr-2"
                                style={{
                                  fontWeight: !request.userSaw
                                    ? "bolder"
                                    : "300",
                                }}
                                // style={{
                                //   color: "#000000",
                                //   cursor: "pointer",
                                //   textTransform: "capitalize",
                                //   marginRight: "5px",
                                // }}
                              >
                                {request.userName}
                                {request.premiumData &&
                                  premiumUser(request.premiumData) && (
                                    <img
                                      src={premiumUser(request.premiumData)}
                                      alt=""
                                      className="ml-1 mr-3"
                                      style={{ width: "20px", height: "20px" }}
                                    />
                                  )}
                              </h6>
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "12px",
                                }}
                              >
                                <GoCalendar
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                  }}
                                ></GoCalendar>{" "}
                                {moment(request.createdDate)
                                  .locale("en")
                                  .format("ll")}
                              </p>
                            </div>
                            <p
                              style={{
                                color: "#000000",
                                cursor: "pointer",
                                fontSize: "11px",
                              }}
                            >
                              {request.natureOfRequest}
                            </p>
                            <p
                              style={{
                                color: "gray",
                                // paddingLeft: "8px",
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              {request.description}{" "}
                            </p>
                          </Grid>
                          {/* <Grid item lg={2} md={2} sm={12} xs={12}>
                        <button style={{ float: "right" }}>Apply</button>
                      </Grid> */}
                        </Grid>
                      </div>
                    ))}

                  {!this.props.otherRequestLoaderStatus &&
                    this.props.otherRequestSeeMorePage && (
                      <div className="networkDetailsBtn mb-5 mt-2">
                        <button
                          className="networkSeeMore"
                          onClick={this.onChangeCallingSeeMore}
                        >
                          {t("See more")}
                        </button>
                      </div>
                    )}
                  {!this.props.otherRequestLoaderStatus &&
                    this.props.otherRequestList?.length === 0 && (
                      <div className="aboutMyRequestWrapper mt-4">
                        <div style={{ textAlign: "center" }}>
                          <h6>{t("No More Request Available")}</h6>
                        </div>
                      </div>
                    )}
                </div>
              </Grid>
            </Grid>
          </BlockUi>
        </div>
      </>
    );
  }
  onPostRequest() {
    this.props.handleClickAway();
  }
  searchSubjects(value) {
    let data = [];
    let tags = [];
    // alert(value)
    if (value.length > 0) {
      for (var i = 0; i < this.state.allTaList.length; i++) {
        var subject = this.state.allTaList[i];
        if (subject.tagName.toUpperCase().includes(value.toUpperCase())) {
          data.push(subject);
        }
        this.setState({ tagList: data });
      }
    } else {
      this.setState({ tagList: this.props.tagList });
    }
  }
  selectedAreaofinterest = (id, idx, type) => {
    if (type == "Interest") {
      let count = this.state.addInterestList.length;

      if (this.state.addInterestList.length > 0) {
        interest = this.state.addInterestList.filter(
          (element) => element !== id
        );
        const checkBoxStatus = this.state.areaOfInterestData.forEach((data) => {
          if (data.areaId === id) {
            return (data.isActive = false);
          }
        });
        this.setState({ addInterestList: interest });
      }

      if (count == interest.length) {
        interest.push(id);
        const checkBoxStatus = this.state.areaOfInterestData.map((data) => {
          if (data.areaId === id) {
            data.isActive = true;
          }
          return data;
        });
        this.setState({ addInterestList: interest });
      }
    }

    if (type == "Industry") {
      let count = this.state.addIndustryList.length;

      if (this.state.addIndustryList.length > 0) {
        industry = this.state.addIndustryList.filter(
          (element) => element !== id
        );
        const checkBoxStatus = this.state.industryData.forEach((data) => {
          if (data.industryId === id) {
            return (data.isActive = false);
          }
        });
        this.setState({ addIndustryList: industry });
      }
      if (count == industry.length) {
        industry.push(id);
        const checkBoxStatus = this.state.industryData.forEach((data) => {
          if (data.industryId === id) {
            return (data.isActive = true);
          }
        });
        this.setState({ addIndustryList: industry });
      }
    }

    if (type == "Tags") {
      // this.setState({ activeWord: idx });
      let count = this.state.addTagsList.length;

      if (this.state.addTagsList.length > 0) {
        tags = this.state.addTagsList.filter((element) => element !== id);
        const checkBoxStatus = this.state.tagList.forEach((data) => {
          if (data.tagId === id) {
            return (data.isActive = false);
          }
        });

        this.setState({ addTagsList: tags });
      }
      if (count == tags.length) {
        tags.push(id);
        const checkBoxStatus = this.state.tagList.forEach((data) => {
          if (data.tagId === id) {
            return (data.isActive = true);
          }
        });
        this.setState({ addTagsList: tags });
      }
    }

    var data = {
      page: 0,
      industryIds: industry,
      interestIds: interest,
      tagIds: tags,
      filterType: "other",
    };
    var checkData = {
      industry,
      interest,
      tags,
    };
    this.setState({ jobRequest: checkData });
    console.log(data);
    // this.getOthersJobRequest(data);
    this.props.getJobRequestByOtherUser(data, 0);
    this.setState({
      pageNo: 1,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  onChangeCallingSeeMore = () => {
    var industryIds = this.state.jobRequest.industry;

    var interestIds = this.state.jobRequest.interest;

    var tagIds = this.state.jobRequest.tags;

    // this.state.pageNo = this.state.pageNo + 1;
    var data = {
      page: this.state.pageNo + 1,
      industryIds,
      interestIds,
      tagIds,
      filterType: "other",
    };
    console.log("data see more", data);

    this.props.getJobRequestByOtherUser(data, this.state.pageNo);
    this.setState({
      pageNo: this.state.pageNo + 1,
    });
    // ProfileService.getJobRequestByFilter(data)
    //   .then((response) => {
    //     var result = response.data.data.jobRequestData;
    //     if (response.data.data.jobRequestData.length > 0) {
    //       if (this.state.othersRequest.length > 0) {
    //         var oldPostList = this.state.othersRequest;
    //         var newRequests = oldPostList.concat(
    //           response.data.data.jobRequestData
    //         );
    //         this.setState({ othersRequest: newRequests });
    //       } else {
    //         var newRequests = [response.data.data.jobRequestData];
    //         this.setState({ othersRequest: newRequests });
    //       }
    //     } else {
    //       this.setState({ noMoreState: false });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  backtoHome() {}

  getOthersJobRequest(data) {
    ProfileService.getJobRequestByFilter(data)
      .then((response) => {
        tags.length = 0;
        var result = response.data.data.jobRequestData;

        if (response.data.data.jobRequestData.length > 0) {
          this.setState({ noMoreState: true });
          this.setState({ othersRequest: result });
        } else {
          this.setState({ noMoreState: false });
          this.setState({ othersRequest: [] });
        }
      })
      .catch((error) => {
        tags.length = 0;
        console.log(error);
      });
  }

  handleChangeRequestCard = (e, userSaw) => {
    if (!userSaw) {
      this.props.userSawAskHelpEntrySave(e);
    }
    this.props.history.push(
      `/${this.props.workspaceName}/request-dis/${e}/${"other-req"}`
    );
  };

  searchArea(event, value) {
    this.setState({
      [event.target.name]: event.target.value,
      selectAreaName: event.target.value,
    });
    let data = [];
    for (var i = 0; i < this.state.allInterest.length; i++) {
      var area = this.state.allInterest[i];
      if (area.areaName.toUpperCase().includes(value.toUpperCase())) {
        data.push(area);
      }
    }
    this.setState({ areaOfInterestlist: data });
  }

  searchIndustry(event, value) {
    this.setState({
      [event.target.name]: event.target.value,
      selectIndustryName: event.target.value,
    });
    let data = [];
    for (var i = 0; i < this.state.allindustry.length; i++) {
      var industry = this.state.allindustry[i];
      if (industry.industryName.toUpperCase().includes(value.toUpperCase())) {
        data.push(industry);
      }
    }
    this.setState({ industryList: data });
  }
}

const mapStateToProps = function (state) {
  return {
    workspaceName: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    otherRequestSeeMorePage: state.myRequestReducers.otherRequestSeeMorePage,
    otherRequestList: state.myRequestReducers.otherRequestList,
    otherRequestLoaderStatus: state.myRequestReducers.otherRequestLoaderStatus,
    industryList: state.commonApi.industrylist.data,
    interestList: state.commonApi.areaofinterestlist,
    tagList: state.commonApi.taglist.data,
    // myRequestList: state.myRequestReducers.myRequestList,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getJobRequestByOtherUser: (data, pageNo) =>
      dispatch(getJobRequestByOtherUser(data, pageNo)),
    userSawAskHelpEntrySave: (id) => dispatch(userSawAskHelpEntrySave(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(OtherRequestList)));
