import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  AiOutlineAppstoreAdd,
  AiOutlineShoppingCart,
  AiOutlineSolution,
} from "react-icons/ai";
import Grid from "@material-ui/core/Grid";
import { AiOutlineHome } from "react-icons/ai";
import { RiTeamLine } from "react-icons/ri";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { profiles } from "../../config/keycloak-config";
import { BsCalendar2Check, BsGridFill } from "react-icons/bs";
import { FiLayers, FiUserPlus } from "react-icons/fi";
import { AiOutlineRight } from "react-icons/ai";
import { AiOutlineDown } from "react-icons/ai";
import { BsCalendarEvent } from "react-icons/bs";
import AuthService from "../../session/AuthService";
import i18n from "../../i18n";
import { getAllMyGroupEvents } from "../../redux/actions/subjectActions";
import { FiTruck } from "react-icons/fi";
import logoDark from "../../images/yuukke.png";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";
import SharePopup from "../ReferralModel";
import { generateMarketPlaceUlr } from "../../utils";
class RightModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showEventMenu: true,
      openPopup: false,
    };
    this.gotogroups = this.gotogroups.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.showEventMenu = this.showEventMenu.bind(this);
    this.gotomynetwork = this.gotomynetwork.bind(this);
    this.gotogroupDescription = this.gotogroupDescription.bind(this);
    this.gotocgroups = this.gotocgroups.bind(this);
    this.gotochallenges = this.gotochallenges.bind(this);
    this.gotomarketplace = this.gotomarketplace.bind(this);
    this.gotomyrequest = this.gotomyrequest.bind(this);
    this.gotoofferings = this.gotoofferings.bind(this);
    this.gotomentora = this.gotomentora.bind(this);
    this.gotomeetings = this.gotomeetings.bind(this);
    this.gotoLearningResource = this.gotoLearningResource.bind(this);
    this.gotoMentorRegPage = this.gotoMentorRegPage.bind(this);
    this.gotoSellerRegPage = this.gotoSellerRegPage.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.gotohome = this.gotohome.bind(this);
    // if (this.props.authUser.type) {
    //   this.props.getAllMyGroupEvents("Groups");
    // }
  }

  showMenu(event) {
    document.addEventListener("mousedown", this.handleClickOutside);

    this.setState({
      showMenu: !this.state.showMenu,
    });
  }

  showEventMenu(event) {
    this.setState({
      showEventMenu: !this.state.showEventMenu,
    });
  }

  render() {
    const { t } = this.props;

    const { showMenu, showEventMenu, general, addSpace } = this.state;
    return (
      <>
        <Modal
          style={{ width: "300px", right: 0 }}
          className="right"
          show={this.props.show}
          onHide={this.props.handleClickAway}
        >
          {/* <Modal.Header   onHide={this.props.handleClickAway} style={{background:"#FAFAFA"}} >
          <button    class="close"><span>×</span><span class="sr-only">Close</span></button>
          </Modal.Header> */}

          <Modal.Title style={{ height: "auto" }}>
            {/* <IconButton aria-label="close" onClick={this.gotogroups}>
              <CloseIcon />
            </IconButton> */}
            <div className="righdropdownIcon">
              <div className="rightModal">
                <div className="headerDynamicLogo tour-headerIcon">
                  <img
                    src={this.props.headerLog ? this.props.headerLog : logoDark}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = logoDark;
                    }}
                    // class="header-logo-inner"
                    alt="Header Logo"

                    // onClick={() => window.open(APPCONFIG.BASE_URL)}
                  />
                </div>
                <div>
                  {this.props.userWorkSpaceList &&
                    this.props.userWorkSpaceList.length > 1 && (
                      <div class="workspace">
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={this.props.workSpaceId}
                            onChange={this.props.onChangeStatus}
                            label="Age"
                            accent1Color={"blue"}
                          >
                            {this.props.userWorkSpaceList &&
                              this.props.userWorkSpaceList.map(
                                (option, index) => (
                                  <MenuItem
                                    key={option.workSpaceId}
                                    value={option.workSpaceId}
                                  >
                                    {option.workSpaceName}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                </div>
                <p
                  style={{
                    color: "#000000",
                    fontSize: "15px",
                    paddingLeft: "7px",
                    paddingTop: "13px",
                  }}
                >
                  You have
                  <span className="badgesTextPink">
                    {this.props.totalUserPoints} {t("points")} !
                  </span>
                </p>
                <div class="refer-wrapper mt-2">
                  <a class="cta" onClick={() => this.openPopup()}>
                    <span> Refer A Friend</span>
                    <span>
                      <svg
                        width="16px"
                        height="13px"
                        viewBox="0 0 66 43"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          id="arrow"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <path
                            class="one"
                            d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                            fill="black"
                          ></path>
                          <path
                            class="two"
                            d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                            fill="black"
                          ></path>
                          <path
                            class="three"
                            d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                            fill="black"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </a>
                </div>
                {this.state.openPopup && (
                  <SharePopup
                    type="referral"
                    id={this.props.loginDetails.referralCode}
                    content={`${this.props.loginDetails.dName} invite you to join on Yuukke app Using with referral code ${this.props.loginDetails.referralCode} .Register using this referral code...`}
                    handleClose={this.openPopup}
                    url={"http://yuukke.com/promotion.php"}
                  />
                )}
                <hr />
                <div className="menu-items-wrapper">
                  {/* <div style={{ padding: "5px" }}>
                    <TextField
                      id="subjects"
                      label=""
                      style={{ width: "95%", height: "100%" }}
                      variant="outlined"
                      placeholder="Filter"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div> */}
                  <div
                    className="navGroup"
                    tabindex="1"
                    onClick={this.gotohome}
                  >
                    <div
                      className=""
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                      }}
                    >
                      <AiOutlineHome
                        style={{
                          marginRight: "8px",
                          width: "22px",
                          height: "22px",
                          color: "#000000",
                        }}
                      />
                      <p
                        className=""
                        style={{ color: "#000000", fontSize: "15px" }}
                      >
                        {t("Home")}
                      </p>
                    </div>
                  </div>

                  <div
                    className="navMember"
                    tabindex="1"
                    onClick={this.gotomynetwork}
                  >
                    <div
                      className=""
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                        marginRight: "5px",
                      }}
                    >
                      <RiTeamLine
                        style={{
                          marginRight: "8px",
                          width: "22px",
                          height: "22px",
                          color: "#000000",
                        }}
                      />
                      <p
                        className="  "
                        style={{ color: "#000000", fontSize: "15px" }}
                      >
                        {t("Connect")}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      className="navMember"
                      tabindex="1"
                      onClick={this.gotogroups}
                    >
                      <div
                        className=""
                        style={{
                          cursor: "pointer",
                          display: "inline-flex",
                          marginRight: "5px",
                        }}
                      >
                        <BsGridFill
                          style={{
                            marginRight: "8px",
                            width: "22px",
                            height: "22px",
                            color: "#000000",
                          }}
                        />
                        <p
                          className=" "
                          style={{ color: "#000000", fontSize: "15px" }}
                        >
                          {t("Community")}
                        </p>
                      </div>
                    </div>

                    {/* <div
                      className="navMember"
                      tabindex="1"
                      onClick={this.gotoMentorRegPage}
                    >
                      <div
                        className=""
                        style={{
                          cursor: "pointer",
                          display: "inline-flex",
                          marginRight: "5px",
                        }}
                      >
                        <FaRegIdCard
                          style={{
                            marginRight: "8px",
                            width: "22px",
                            height: "22px",
                            color: "#000000",
                          }}
                        />
                        <p
                          className="  "
                          style={{ color: "#000000", fontSize: "14px" }}
                        >
                          {t("Mentor Registration")}
                        </p>
                      </div>
                    </div> */}
                    {!this.props.sellerExist && (
                      <div
                        className="navMember"
                        tabindex="1"
                        onClick={this.gotoSellerRegPage}
                      >
                        <div
                          className=""
                          style={{
                            cursor: "pointer",
                            display: "inline-flex",
                            marginRight: "5px",
                          }}
                        >
                          <FiTruck
                            style={{
                              marginRight: "8px",
                              width: "22px",
                              height: "22px",
                              color: "#000000",
                            }}
                          />
                          <p
                            className="  "
                            style={{ color: "#000000", fontSize: "15px" }}
                          >
                            {t("Seller Registration")}
                          </p>
                        </div>
                      </div>
                    )}
                    <div
                      className="navMember"
                      tabindex="1"
                      onClick={this.gotomentora}
                    >
                      <div
                        className=""
                        style={{
                          cursor: "pointer",
                          display: "inline-flex",
                          marginRight: "5px",
                        }}
                      >
                        <AiOutlineSolution
                          style={{
                            marginRight: "8px",
                            width: "22px",
                            height: "22px",
                            color: "#000000",
                          }}
                        />
                        <p
                          className="  "
                          style={{ color: "#000000", fontSize: "15px" }}
                        >
                          {t("Mentors")}
                        </p>
                      </div>
                    </div>

                    {/* <div className="navMember" tabindex="1" onClick={this.gotoofferings}>

                        <div
                          className=""
                          style={{
                            cursor: "pointer",
                            display: "inline-flex",
                            marginRight: "5px",
                          }}
                        >

                          <AiOutlineSnippets
                            style={{
                              marginRight: "8px",
                              width: "22px",
                              height: "22px",
                              color: "#000000",
                            }}
                          />
                          <p
                            className="  "
                            style={{ color: "#000000", fontSize: "15px" }}
                          >
                            {t("Learnings")}
                          </p>
                        </div>
                    </div> */}

                    {/* {!showMenu && (
                      <div style={{ marginBottom: "20px" }}>
                        {" "}
                        {this.props.tagDatas &&
                          this.props.tagDatas.length > 0 &&
                          this.props.tagDatas.map((subject, index) => (
                            <div onClick={() => this.gotogroupDescription(subject.subjectId, subject.tagDescription)}>
                              <p style={{ color: "#000000", paddingRight: "8px", paddingLeft: "22px", paddingTop: "8px", paddingBottom: "8px", fontSize: "14px", cursor: "pointer" }}>{subject.tagDescription}</p>
                            </div>))}
                      </div>
                    )} */}
                  </div>
                  <div
                    className="navMember"
                    tabindex="1"
                    onClick={this.movetoCalender}
                  >
                    <div
                      className=""
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                        marginRight: "5px",
                      }}
                    >
                      <BsCalendar2Check
                        style={{
                          marginRight: "8px",
                          width: "22px",
                          height: "22px",
                          color: "#000000",
                        }}
                      />
                      <p
                        className="  "
                        style={{ color: "#000000", fontSize: "15px" }}
                      >
                        {t("Calender")}
                      </p>
                    </div>
                  </div>

                  {/* <div
                    className="navMember"
                    tabindex="1"
                    onClick={this.gotochallenges}
                  >
                    <div
                      className=""
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                        marginRight: "5px",
                      }}
                    >
                      <FiUserPlus
                        style={{
                          marginRight: "8px",
                          width: "22px",
                          height: "22px",
                          color: "#000000",
                        }}
                      />
                      <p
                        className="  "
                        style={{ color: "#000000", fontSize: "15px" }}
                      >
                        {t("Career Labs")}
                      </p>
                    </div>
                  </div> */}

                  <div
                    className="navMember"
                    tabindex="1"
                    onClick={() => this.gotoDirectory()}
                  >
                    <div
                      className=""
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                        marginRight: "5px",
                      }}
                    >
                      <FiLayers
                        style={{
                          marginRight: "8px",
                          width: "22px",
                          height: "22px",
                          color: "#000000",
                        }}
                      />

                      <p
                        className="  "
                        style={{ color: "#000000", fontSize: "15px" }}
                      >
                        {t("Business Directory")}
                      </p>
                    </div>
                  </div>

                  <div
                    className="navMember"
                    tabindex="1"
                    onClick={this.movetoMarket}
                  >
                    <div
                      className=""
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                        marginRight: "5px",
                      }}
                    >
                      <AiOutlineShoppingCart
                        style={{
                          marginRight: "8px",
                          width: "22px",
                          height: "22px",
                          color: "#000000",
                        }}
                      />
                      <p
                        className="  "
                        style={{ color: "#000000", fontSize: "15px" }}
                      >
                        {t("Market Place")}
                      </p>
                    </div>
                  </div>

                  {/* {profiles.includes("events") && (
                    <div>
                      <div
                        className="navEvent"
                        tabindex="1"
                        onClick={this.gotoevents}
                      >
                        <Grid container spacing={1}>
                          <Grid item lg={9}>
                            <div
                              className=""
                              style={{
                                cursor: "pointer",
                                display: "inline-flex",
                                marginRight: "5px",
                              }}
                            >
                              <BsCalendarEvent
                                style={{
                                  marginRight: "8px",
                                  width: "22px",
                                  height: "22px",
                                  color: "#000000",
                                }}
                              />
                              <p
                                className=" "
                                style={{ color: "#000000", fontSize: "15px" }}
                              >
                                {t("Events")}
                              </p>
                            </div>
                          </Grid>
                          <Grid item lg={3}>
                            <div
                              className=""
                              onClick={this.showEventMenu}
                              style={{ float: "right" }}
                            >
                              {showEventMenu ? (
                                <AiOutlineRight
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    color: "#000000",
                                  }}
                                />
                              ) : (
                                <AiOutlineDown
                                  style={{
                                    marginRight: "8px",
                                    width: "16px",
                                    height: "16px",
                                    color: "#000000",
                                  }}
                                />
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>

                      
                    </div>
                  )} */}
                  {/* <div
                    className="navMember"
                    tabindex="1"
                    onClick={this.gotomarketplace}
                  >
                    <div
                      className=""
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                      }}
                    >
                      <FaMapMarkedAlt
                        style={{
                          marginRight: "8px",
                          width: "22px",
                          height: "22px",
                          color: "#000000",
                        }}
                      />
                      <p
                        className=""
                        style={{ color: "#000000", fontSize: "15px" }}
                      >
                        {t("Market Place")}
                      </p>
                    </div>
                  </div> */}

                  <div
                    className="navMember"
                    tabindex="1"
                    onClick={this.gotoLearningResource}
                  >
                    <div
                      className=""
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                      }}
                    >
                      <MdOutlineLibraryBooks
                        style={{
                          marginRight: "8px",
                          width: "25px",
                          height: "25px",
                          color: "#000000",
                        }}
                      />
                      {/* <img
                        src={ourOfferFill}
                        style={{
                          marginRight: "8px",
                          width: "25px",
                          height: "25px",
                        }}
                      /> */}
                      <p
                        className=""
                        style={{ color: "#000000", fontSize: "15px" }}
                      >
                        {t("Learning Hub")}
                      </p>
                    </div>
                  </div>

                  {/* 
                  <div className="navMember" tabindex="1">
                    <NavLink
                      className=""
                      to={""}
                      exact={true}
                      activeClassName="active"
                      onClick={() => window.open("http://marketplace.fempire.betalearnings.com/", "_blank")}
                    >
                      <div
                        className=""
                        style={{
                          cursor: "pointer",
                          display: "inline-flex",
                          marginRight: "5px",
                        }}
                      >
                     
                        <FaMapMarkedAlt
                          style={{
                            marginRight: "8px",
                            width: "22px",
                            height: "22px",
                            color: "#000000",
                          }}
                        />
                        <p
                          className="  "
                          style={{ color: "#000000", fontSize: "15px" }}
                        >
                          {t("Market Place")}
                        </p>
                      </div>
                    </NavLink>
                  </div> */}
                  {/* <div
                    className="navCGroup"
                    tabindex="1"
                    onClick={this.gotocgroups}
                  >
                    <div
                      className=""
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                        marginRight: "5px",
                      }}
                    >
                      <AiOutlineUsergroupAdd
                        style={{
                          marginRight: "8px",
                          width: "22px",
                          height: "22px",
                          color: "#000000",
                        }}
                      />
                      <p
                        className=" "
                        style={{ color: "#000000", fontSize: "15px" }}
                      >
                        {t("Create Community")}
                      </p>
                    </div>
                  </div> */}

                  {/* <div className="navMember" tabindex="1" onClick={this.gotomeetings}>

<div
  className=""
  style={{
    cursor: "pointer",
    display: "inline-flex",
  }}
>
  <FiVideo
    style={{
      marginRight: "8px",
      width: "22px",
      height: "22px",
      color: "#000000",
    }}
  />
  <p
    className=""
    style={{ color: "#000000", fontSize: "15px" }}
  >
    {t("Meetings")}
  </p>
</div>

</div>

                  <div className="navCGroup" tabindex="1" onClick={this.gotomyrequest}>
                  
                  <div
                    className=""
                    style={{
                      cursor: "pointer",
                      display: "inline-flex",
                    }}
                  >
                    <BsFillPatchQuestionFill
                      style={{
                        marginRight: "8px",
                        width: "22px",
                        height: "22px",
                        color: "#000000",
                      }}
                    />
                    <p
                      className=""
                      style={{ color: "#000000", fontSize: "15px" }}
                    >
                      {t("Need Support")}
                    </p>
                  </div>
                
              </div> */}
                  {/* {profiles.includes("events") && (
                    <div
                      className="navCEvent"
                      tabindex="1"
                      onClick={this.gotocevents}
                    >
                      <div
                        className=""
                        style={{
                          cursor: "pointer",
                          display: "inline-flex",
                          marginRight: "5px",
                        }}
                      >
                        <AiOutlineAppstoreAdd
                          style={{
                            marginRight: "8px",
                            width: "22px",
                            height: "22px",
                            color: "#000000",
                          }}
                        />
                        <p
                          className=" "
                          style={{ color: "#000000", fontSize: "15px" }}
                        >
                          {t("Create Event")}
                        </p>
                      </div>
                    </div>
                  )} */}
                </div>
                {/* <div style={{ marginLeft: "6px", padding: "8px" }}>
                  {" "}
                  <p
                    style={{
                      color: "#000000",
                      fontSize: "12px",
                      marginRight: "5px",
                    }}
                  >
                    © Copyright by yuukke
                  </p>
                </div>
                <div style={{ marginLeft: "6px", padding: "8px" }}>
                  <p
                    style={{
                      color: "#000000",
                      fontSize: "12px",
                      paddingTop: "12px",
                      marginRight: "5px",
                    }}
                  >
                    Powered by betamonks{" "}
                  </p>
                </div> */}
              </div>
            </div>{" "}
          </Modal.Title>

          <Modal.Body></Modal.Body>
        </Modal>
      </>
    );
  }
  movetoMarket = () => {
    generateMarketPlaceUlr(this.props.marketplaceUrl);

    // window.open("https://marketplace.yuukke.com/openid/oauth2", "_blank");
    // this.props.history.push(`/${this.props.workspaceName}/subscriber-form`);
  };
  movetoCalender = () => {
    this.props.history.push(`/${this.props.workspaceType}/calendar`);
    this.props.handleClickAway();
  };
  gotoofferings() {
    this.props.history.push("/classroom");
    this.props.handleClickAway();
  }

  gotomentora() {
    this.props.history.push(`/${this.props.workspaceType}/mentors`);
    this.props.handleClickAway();
  }

  gotomeetings() {
    this.props.history.push("/meeting");
    this.props.handleClickAway();
  }

  gotogroups() {
    this.props.history.push(`/${this.props.workspaceType}/groups`);
    this.props.handleClickAway();
  }

  gotochallenges() {
    //this.props.history.push("/challenges");
    this.props.handleClickAway();
    this.props.history.push(`/${this.props.workspaceType}/career-lab`);
  }
  gotohome() {
    sessionStorage.setItem("CHALLENGE_REDIRECT_PATH", "");
    this.props.history.push(`/${this.props.workspaceType}/home`);
    this.props.handleClickAway();
  }
  gotomynetwork() {
    this.props.history.push(`/${this.props.workspaceType}/mynetwork`);
    this.props.handleClickAway();
  }
  gotogroupDescription(id, name) {
    var gName = name.trim();
    gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
    this.props.history.push(
      `/${this.props.workspaceType}/myGroupDescription/${id}/${gName}`
    );
    this.props.handleClickAway();
  }
  gotocgroups() {
    this.props.history.push(`/${this.props.workspaceName}/createGroup`);
    this.props.handleClickAway();
  }

  gotomarketplace() {
    this.props.history.push(`/${this.props.workspaceType}/subscriber-form`);
    //window.open("https://marketplace.yuukke.com/", "_blank")
    this.props.handleClickAway();
  }
  gotomyrequest() {
    this.props.history.push(`/${this.props.workspaceType}/my-requests`);
    this.props.handleClickAway();
  }

  gotoLearningResource() {
    // this.props.history.push("/classroom");
    this.props.history.push(`/${this.props.workspaceType}/learning-hub`);
    this.props.handleClickAway();
  }

  gotoMentorRegPage() {
    this.props.history.push(`/${this.props.workspaceType}/mentor-registration`);
    this.props.handleClickAway();

    //window.open("http://test.yuukke.betalearnings.com/mentor-program.html", "_blank");
    //window.open("https://yuukke.com/mentor-program.html", "_blank")
    // this.props.history.push(`/${this.props.workspaceType}/mentor-registration`);
    // this.props.handleClickAway();
  }

  gotoSellerRegPage() {
    this.props.history.push(`/${this.props.workspaceType}/seller-registration`);
    this.props.handleClickAway();

    // window.open("http://test.yuukke.betalearnings.com/seller.html", "_blank");
    //window.open("https://yuukke.com/seller.html", "_blank");
    // this.props.history.push("/sller-registration");

    // this.props.handleClickAway();
  }
  openPopup = () => {
    this.setState({
      openPopup: !this.state.openPopup,
    });
  };
  gotoDirectory = () => {
    this.props.history.push(`/${this.props.workspaceType}/directory`);
    this.props.handleClickAway();
  };
}

// export default connect(mapStateToProps,mapDispacthToProps)(
//   withTranslation()(withRouter(NavigationsMenu))
// );
const mapDispacthToProps = (dispatch) => {
  return {
    getAllMyGroupEvents: (type, page) =>
      dispatch(getAllMyGroupEvents(type, page)),
  };
};
const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    tagDatas: state.subjects.myeventsandgroups,
    authUser: state.loginDetailsApi.authUser,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    totalUserPoints: state.notification.totalUserPoints,
    sellerExist: state.commonApi.requireMentMenu?.sellerExist,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(RightModal)));
