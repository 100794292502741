import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import yuukkeImage from "../../../images/FINAL LOGO.jpg";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import happygrey from "../../../images/anouncement/happy@2x copy.png";
import happy from "../../../images/anouncement/happy@2x.png";
import cryinggrey from "../../../images/anouncement/crying@2x copy.png";
import crying from "../../../images/anouncement/crying@2x.png";
import confusedgrey from "../../../images/anouncement/confused@2x copy.png";
import confused from "../../../images/anouncement/confused@2x.png";
import famousgrey from "../../../images/anouncement/famous@2x copy.png";
import famous from "../../../images/anouncement/famous@2x.png";
import sadgrey from "../../../images/anouncement/sad@2x copy.png";
import sad from "../../../images/anouncement/sad@2x.png";
import AuthService from "../../../session/AuthService";
import PostService from "../../../api/PostService";
import * as APPCONFIG from "../../../config/app-config.js";
import { connect } from "react-redux";
import dummyimage from "../../../img/default_avatar.jpg";
import { Delete, Edit } from "@material-ui/icons";
import { withRouter } from "react-router";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Swal from "sweetalert2";
class AnnouncementBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base64String: "",
      image: "",
      feedBack: "",
      iid: this.props.id,
      feedbackState: false,
      feedBackList: [],
      menuState: false,
    };

    this.setFeedBack = this.setFeedBack.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.setState({ id: this.props.id });

    if (this.props.imgUrl != "") {
      console.log();
      this.setState({ image: this.props.imgUrl });
    } else {
      this.setState({ image: "" });
    }

    if (this.props.FeedbackList && this.props.FeedbackList.length > 0) {
      var feedBack = this.props.FeedbackList.filter(
        (data) => data.announcementId == this.props.id
      );
      if (feedBack.length > 0) {
        this.setState({
          feedBackList: feedBack,
        });
      } else {
        var obj = {
          userId: this.props.loginDetails.uId,
          feedBack: "",
          announcementId: this.props.id,
        };

        var feedback = [];
        feedback.push(obj);
        this.setState({
          feedBackList: feedback,
        });
      }
    } else {
      var obj = {
        userId: this.props.loginDetails.uId,
        feedBack: "",
        announcementId: this.props.id,
      };

      var feedback = [];
      feedback.push(obj);
      this.setState({
        feedBackList: feedback,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.FeedbackList !== this.props.FeedbackList) {
      if (this.props.FeedbackList && this.props.FeedbackList.length > 0) {
        var feedBack = this.props.FeedbackList.filter(
          (data) => data.announcementId == this.props.id
        );
        if (feedBack.length > 0) {
          this.setState({
            feedBackList: feedBack,
          });
        } else {
          var obj = {
            userId: this.props.loginDetails.uId,
            feedBack: "",
            announcementId: this.props.id,
          };

          var feedback = [];
          feedback.push(obj);
          this.setState({
            feedBackList: feedback,
          });
        }
      } else {
        var obj = {
          userId: this.props.loginDetails.uId,
          feedBack: "",
          announcementId: this.props.id,
        };

        var feedback = [];
        feedback.push(obj);
        this.setState({
          feedBackList: feedback,
        });
      }
    }
  }

  setFeedBack(FeedBack, id) {
    this.setState({ feedBack: FeedBack }, this.submitFeedBack(id, FeedBack));
  }

  submitFeedBack(id, FeedBack) {
    if (FeedBack != "") {
      let saveAnnouncementFeedBack = {
        announcementId: id,
        feedBack: FeedBack,
      };

      PostService.saveAnnouncementFeedBack(saveAnnouncementFeedBack)
        .then((response) => {
          var val = [];
          val.push(response.data.data.feedBack);
          this.setState({
            feedBackList: val,
          });
          this.props.UpdateFeedbackList(response.data.data.feedBack);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (FeedBack === "") {
      toast.warn("Please choose your FeedBack");
    }
  }
  addAnnouncement = (id) => {
    this.props.history.push(
      `/${this.props.workspaceType}/announcement-form/${id}/update-form`
    );
  };
  deleteAnnouncement = (id) => {
    Swal.fire({
      title: "Are you sure to delete this announcement",
      text: "You won't be able to revert this!",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        let data = {
          id: id,
        };
        PostService.deleteAnnouncement(data)
          .then((response) => {
            Swal.fire({
              title: "Deleted Successfully",
              icon: "info",
              type: "info",
              showConfirmButton: true,
            });
            this.props.history.push(`/${this.props.workspaceType}/home`);
          })
          .catch((error) => {
            console.log("error");
            Swal.fire({
              title: "Error in delete",
              icon: "error",
              type: "error",
              showConfirmButton: true,
            });
          });
      }
    });
  };
  handleMenuClick = (event) => {
    this.setState({ menuState: event.target });
  };
  handleMenuClose = (event) => {
    this.setState({ menuState: false });
  };
  render() {
    const profileImage = yuukkeImage;
    const { imgUrl, title, content, id } = this.props;

    const { t } = this.props;

    return (
      <div className="annoucmentBox image-container">
        {/* {this.props.workSpaceId !== 0 &&
          this.props.userRole === 1 &&
          this.props.workSpaceId === this.props.userWorkSpaceId && (
            <div className="announcement-edit">
              <MoreHorizIcon
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => {
                  this.handleMenuClick(e);
                }}
              />

              <Menu
                id="simple-menu"
                anchorEl={this.state.menuState}
                keepMounted
                open={this.state.menuState}
                onClose={this.handleMenuClose}
              >
                <div
                  className="menuCard"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.deleteAnnouncement(this.props.id)}
                >
                  <Delete className="menuItem" />{" "}
                  <MenuItem>
                    <p style={{ color: "black" }}>{t("Delete")}</p>
                  </MenuItem>
                </div>
                <div
                  className="menuCard"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.addAnnouncement(this.props.id)}
                >
                  <Edit className="menuItem" />{" "}
                  <MenuItem>
                    <p style={{ color: "black" }}>{t("Edit")}</p>
                  </MenuItem>
                </div>
              </Menu>
             
            </div>
          )} */}
        {/* <h3 className="mr-auto">AnnounceMent</h3> */}
        <div className="image-container">
          <img
            src={`${APPCONFIG.API_URL}auth/image/${imgUrl}`}
            alt={title}
            // onError={({ currentTarget }) => {
            //   currentTarget.onerror = null; // prevents looping
            //   currentTarget.src = dummyimage;
            // }}
          />
        </div>
        <div className="announcement-overlay">
          <p className="annoucmentTitle">{title}</p>

          <p
            className="annoucmentContent"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </div>
      // <Grid container justify="center">
      //   <Grid item lg={8} md={8} sm={12} xs={12}>
      //     <Grid
      //       container
      //       spacing={2}
      //       justify="center"
      //       className="annoucmentBox"
      //     >
      //       <Grid item lg={4} md={4} sm={12} xs={12}>
      //         {imgUrl && (
      //           <img
      //             style={{ width: "300px", height: "150px" }}
      //             src={`${APPCONFIG.API_URL}auth/image/${imgUrl}`}
      //             alt={title}
      //             onError={({ currentTarget }) => {
      //               currentTarget.onerror = null; // prevents looping
      //               currentTarget.src = dummyimage;
      //             }}
      //           />
      //         )}
      //         {!imgUrl && (
      //           <img
      //             src={profileImage}
      //             alt={title}
      //             onError={({ currentTarget }) => {
      //               currentTarget.onerror = null; // prevents looping
      //               currentTarget.src = dummyimage;
      //             }}
      //           />
      //         )}
      //       </Grid>
      //       <Grid item lg={8} md={8} sm={12} xs={12}>
      //         <CloseIcon
      //           onClick={this.props.closeAnnoucmentScreen}
      //           className="announcementClose"
      //           style={{ cursor: "pointer" }}
      //         />
      //         <p className="annoucmentTitle">{title}</p>

      //         <p
      //           className="annoucmentContent"
      //           dangerouslySetInnerHTML={{
      //             __html: content,
      //           }}
      //         />
      //         <p className="annoucmentContent">{content}</p>
      //         <>
      //           <p className="annoucmentContent">
      //             {t("Did you like this announcement?")}
      //           </p>

      //           <Grid container spacing={4} justify="center">
      //             <Grid item xs={12} sm={3} md={3}></Grid>
      //           </Grid>

      //           <Grid
      //             className="feedbackIcons"
      //             container
      //             spacing={4}
      //             justify="center"
      //           >
      //             <Grid item xs={12} sm={2} md={2}>
      //               <center>
      //                 <div
      //                   style={{ cursor: "pointer" }}
      //                   onClick={() => this.setFeedBack("Not At All", id)}
      //                 >
      //                   {this.state.feedBackList[0] &&
      //                     (this.state.feedBackList[0].feedBack == "" ||
      //                       this.state.feedBackList[0].feedBack !==
      //                       "Not At All") && (
      //                       <img
      //                         style={{ width: "40px", height: "40px" }}
      //                         src={cryinggrey}
      //                         alt="crying"
      //                       />
      //                     )}
      //                   {this.state.feedBackList[0] &&
      //                     this.state.feedBackList[0].feedBack ==
      //                     "Not At All" && (
      //                       <img
      //                         style={{ width: "40px", height: "40px" }}
      //                         src={crying}
      //                         alt="crying"
      //                       />
      //                     )}
      //                   <p className="feedbackBoxText">{t("Not At All")}</p>
      //                 </div>
      //               </center>
      //             </Grid>
      //             <Grid item xs={12} sm={2} md={2}>
      //               <center>
      //                 <div
      //                   style={{ cursor: "pointer" }}
      //                   onClick={() => this.setFeedBack("Slightly", id)}
      //                 >
      //                   {this.state.feedBackList[0] &&
      //                     (this.state.feedBackList[0].feedBack == "" ||
      //                       this.state.feedBackList[0].feedBack !==
      //                       "Slightly") && (
      //                       <img
      //                         style={{ width: "40px", height: "40px" }}
      //                         src={sadgrey}
      //                         alt="sad"
      //                       />
      //                     )}
      //                   {this.state.feedBackList[0] &&
      //                     this.state.feedBackList[0].feedBack ==
      //                     "Slightly" && (
      //                       <img
      //                         style={{ width: "40px", height: "40px" }}
      //                         src={sad}
      //                         alt="sad"
      //                       />
      //                     )}
      //                   <p className="feedbackBoxText">{t("Slightly")}</p>
      //                 </div>
      //               </center>
      //             </Grid>
      //             <Grid item xs={12} sm={2} md={2}>
      //               <center>
      //                 <div
      //                   style={{ cursor: "pointer" }}
      //                   onClick={() => this.setFeedBack("Kinda", id)}
      //                 >
      //                   {this.state.feedBackList[0] &&
      //                     (this.state.feedBackList[0].feedBack == "" ||
      //                       this.state.feedBackList[0].feedBack !==
      //                       "Kinda") && (
      //                       <img
      //                         style={{ width: "40px", height: "40px" }}
      //                         src={confusedgrey}
      //                         alt="confused"
      //                       />
      //                     )}
      //                   {this.state.feedBackList[0] &&
      //                     this.state.feedBackList[0].feedBack == "Kinda" && (
      //                       <img
      //                         style={{ width: "40px", height: "40px" }}
      //                         src={confused}
      //                         alt="confused"
      //                       />
      //                     )}
      //                   <p className="feedbackBoxText">{t("Kinda")}</p>
      //                 </div>
      //               </center>
      //             </Grid>
      //             <Grid item xs={12} sm={2} md={2}>
      //               <center>
      //                 <div
      //                   style={{ cursor: "pointer" }}
      //                   onClick={() => this.setFeedBack("Very Much", id)}
      //                 >
      //                   {this.state.feedBackList[0] &&
      //                     (this.state.feedBackList[0].feedBack == "" ||
      //                       this.state.feedBackList[0].feedBack !==
      //                       "Very Much") && (
      //                       <img
      //                         style={{ width: "40px", height: "40px" }}
      //                         src={happygrey}
      //                         alt="happy"
      //                       />
      //                     )}
      //                   {this.state.feedBackList[0] &&
      //                     this.state.feedBackList[0].feedBack ==
      //                     "Very Much" && (
      //                       <img
      //                         style={{ width: "40px", height: "40px" }}
      //                         src={happy}
      //                         alt="happy"
      //                       />
      //                     )}
      //                   <p className="feedbackBoxText">{t("Very Much")}</p>
      //                 </div>
      //               </center>
      //             </Grid>

      //             <Grid item xs={12} sm={2} md={2}>
      //               <center>
      //                 <div
      //                   style={{ cursor: "pointer" }}
      //                   onClick={() => this.setFeedBack("Completely", id)}
      //                 >
      //                   {this.state.feedBackList[0] &&
      //                     (this.state.feedBackList[0].feedBack == "" ||
      //                       this.state.feedBackList[0].feedBack !==
      //                       "Completely") && (
      //                       <img
      //                         style={{ width: "40px", height: "40px" }}
      //                         src={famousgrey}
      //                         alt="famous"
      //                       />
      //                     )}
      //                   {this.state.feedBackList[0] &&
      //                     this.state.feedBackList[0].feedBack ==
      //                     "Completely" && (
      //                       <img
      //                         style={{ width: "40px", height: "40px" }}
      //                         src={famous}
      //                         alt="famous"
      //                       />
      //                     )}
      //                   <p className="feedbackBoxText">{t("Completely")}</p>
      //                 </div>
      //               </center>
      //             </Grid>
      //             <Grid item xs={12} sm={2} md={2}></Grid>
      //           </Grid>

      //           {this.state.feedBackList[0] &&
      //             this.state.feedBackList[0].feedBack !== "" && (
      //               <Grid
      //                 className="feedbackResult"
      //                 container
      //                 spacing={4}
      //                 justify="center"
      //               >
      //                 <cenetr>
      //                   <Grid item xs={12} sm={12} md={12}></Grid>
      //                 </cenetr>
      //               </Grid>
      //             )}
      //         </>
      //       </Grid>
      //     </Grid>
      //   </Grid>
      // </Grid>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    loginDetails: state.loginDetailsApi.loginDetails,
    userRole: state.loginDetailsApi.loginDetails?.userWorkSpace?.role,
    userWorkSpaceId:
      state.loginDetailsApi.loginDetails?.userWorkSpace?.workSpaceId,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(AnnouncementBox)));
