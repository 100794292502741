import ProfileService from "../../api/ProfileService";
import {
  GET_MY_REQUEST_LIST,
  GET_OTHER_REQUEST_LIST,
  CHANGE_REQUEST_LOADER_STATUS,
  CHANGE_OTHER_REQUEST_LOADER_STATUS,
  GET_JOB_REQUEST_DETAILS,
  GET_JOB_REQUEST_LOADER_STATUS,
  GET_JOB_REQUEST_COUNT,
  GET_JOB_REQUEST_SEARCH_LIST,
  CHANGE_HOME_LOADER_STATUS,
  REMOVE_JOB_REQUEST_COUNT,
} from "../types";

export const getJobRequestByUser = (pageNo) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_REQUEST_LOADER_STATUS,
      payload: true,
    });
    var request = {
      page: pageNo,
      filterType: "my",
    };
    ProfileService.getJobRequestByFilter(request)
      .then((response) => {
        try {
          var result = response.data.data;
          console.log("result", result);
          if (!result.firstTimeAskHelp) {
            ProfileService.saveFirstTimeAskHelpEntry()
              .then((response) => {
                console.log("Updated", response);
              })
              .catch((err) => {
                console.error(err);
              });

            dispatch({
              type: GET_MY_REQUEST_LIST,
              payload: result,
              pageNo: pageNo,
            });
          } else {
            dispatch({
              type: GET_MY_REQUEST_LIST,
              payload: result,
              pageNo: pageNo,
            });
          }
        } catch (e) {
          console.error(e);
          dispatch({
            type: GET_MY_REQUEST_LIST,
            payload: [],
            pageNo: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_MY_REQUEST_LIST,
      payload: [],
      pageNo: 0,
    });
  }
  return [];
};

export const getJobRequestByOtherUser = (data, page) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_OTHER_REQUEST_LOADER_STATUS,
      payload: true,
    });
    ProfileService.getJobRequestByFilter(data)
      .then((response) => {
        try {
          var result = response.data.data;
          dispatch({
            type: GET_OTHER_REQUEST_LIST,
            payload: result,
            pageNo: page,
          });
        } catch (err) {
          dispatch({
            type: GET_OTHER_REQUEST_LIST,
            payload: [],
            pageNo: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_OTHER_REQUEST_LIST,
      payload: [],
      pageNo: 0,
    });
  }
  return [];
};

export const getJobRequestPostDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_REQUEST_LOADER_STATUS,
      payload: true,
    });
    ProfileService.getJobRequestById(data)
      .then((response) => {
        try {
          var result = response.data.data.jobRequest;
          dispatch({
            type: GET_JOB_REQUEST_DETAILS,
            payload: result,
          });
        } catch (err) {
          dispatch({
            type: GET_JOB_REQUEST_DETAILS,
            payload: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_JOB_REQUEST_DETAILS,
      payload: [],
    });
  }
  return [];
};

export const getAllNotSawAskHelpListCount = () => async (dispatch) => {
  try {
    ProfileService.getAllNotSawAskHelpListCount()
      .then((response) => {
        var result = response.data.data.askHelpCount;
        dispatch({
          type: GET_JOB_REQUEST_COUNT,
          payload: result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: GET_JOB_REQUEST_COUNT,
      payload: 0,
    });
  }
  return [];
};

export const getAllNotSawAskHelpList =
  (page, searchKey, tabType) => async (dispatch) => {
    let data = {
      page: page,
      searchKey: searchKey,
      type: tabType,
    };
    dispatch({
      type: CHANGE_HOME_LOADER_STATUS,
      payload: true,
    });
    try {
      ProfileService.getAllNotSawAskHelpList(data)
        .then((response) => {
          var result = response.data.data;
          try {
            dispatch({
              type: GET_JOB_REQUEST_SEARCH_LIST,
              payload: result,
              pageNo: page,
            });
          } catch (e) {
            dispatch({
              type: GET_JOB_REQUEST_SEARCH_LIST,
              payload: {},
              pageNo: 0,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      dispatch({
        type: GET_JOB_REQUEST_SEARCH_LIST,
        payload: 0,
        page: 0,
      });
    }
    return [];
  };

export const userSawAskHelpEntrySave = (id) => async (dispatch) => {
  try {
    let helpId = {
      helpId: id,
    };
    ProfileService.userSawAskHelpEntrySave(helpId)
      .then((response) => {
        dispatch({
          type: REMOVE_JOB_REQUEST_COUNT,
          payload: id,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    dispatch({
      type: REMOVE_JOB_REQUEST_COUNT,
      payload: 0,
    });
  }
  return [];
};
