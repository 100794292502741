import React, { Component } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextEditorQuestion from "../../../components/TextEditorQuestion";
import { Multiselect } from "react-widgets";
import ProfileService from "../../../api/ProfileService";
import ForumService from "../../../api/ForumService";
import AuthService from "../../../session/AuthService";
import { ToastContainer, toast } from "react-toastify";
 // import '../css/mysocial.css'
// import '../css/mysocialprofile.css'
// import '../css/yuukkeTutoring.css'
import BlockUi from "react-block-ui";
import Form from "react-bootstrap/Form";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import "react-widgets/styles.css";
import Grid from "@material-ui/core/Grid";
import Swal from "sweetalert2";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { connect } from "react-redux";
import {
  getDashboardList,
  getMyQuestionsList,
} from "../../../redux/actions/dashboardActions";
import { getAllSubjectList } from "../../../redux/actions/subjectActions";
import { getMyLibraryQuestionList } from "../../../redux/actions/MyLibraryQuestionActions";
import { getQuestionsList } from "../../../redux/actions/questionActions";
import moment from "moment";
import Utils from "../../../utils/Utils";
import { withRouter } from "react-router";
import { Chip } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import * as APPCONFIG from "../../../config/app-config.js";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import VideocamIcon from "@material-ui/icons/Videocam";
import DescriptionIcon from "@material-ui/icons/Description";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { getPostTagList } from "../../../redux/actions/commonApiActions";
import TextEditorComments from "../../../components/TextEditorComments";
import {
  extractMentionsFromHTML,
  extractMentionsFromUpdate,
} from "../../../utils";
import AnswerFileOptions from "../../../components/AnswerFileOptions.jsx";

class AskQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaFiles: [],
      fileTypeState: false,
      imagePresent: false,
      blocking: false,
      fileUrl: null,
      type: null,
      mediaPost: "",
      isChecked: false,
      teaching: [],
      tags: [],
      question: "",
      classDetails: [],
      addTagsState: false,
      fileOption: false,
      gradeDetails: [],
      questionDescribed: "",
      content: "",
      editState: false,
      gradeId: "",
      questionTitleCount: 0,
      descriptionCount: 0,
      curriculumList: [],
      curriculumId: "",
      tagId: [],
      imageFile: "",
      subjectId: 0,
      questionType: "",
      editOption: false,
      emptyFileState: false,
      removeMediaId: [],
      file: [],
      chipData: [],
      needEdit: false,
    };
    this.block = this.block.bind(this);
    this.myRef = React.createRef();
    this.getallSubject = this.getallSubject.bind(this);
    this.editorStateValues = this.editorStateValues.bind(this);
    this.handleTeachingMultiSelect = this.handleTeachingMultiSelect.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.getQuestionMediaFiles = this.getQuestionMediaFiles.bind(this);
    this.handleGrade = this.handleGrade.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.fileOptionEnable = this.fileOptionEnable.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.getAllCurriculum = this.getAllCurriculum.bind(this);
    this.handleCurriculum = this.handleCurriculum.bind(this);
    this.getGradeByCurriculumId = this.getGradeByCurriculumId.bind(this);
    this.getGradeByCurriculumIdUpdate =
      this.getGradeByCurriculumIdUpdate.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.name === "update-question") {
      this.setState({
        editState: true,
      });
      this.updateQuestion(this.props.match.params.id);
      this.props.getPostTagList("groups", this.props.match.params.groupId);
    } else {
      this.props.getPostTagList("groups", this.props.match.params.id);
    }

    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    console.log("tagList", this.props.tagList);

    var selectedSubjectList = [];
  }

  render() {
    const { t } = this.props;
    const { handleViewAllQuestions } = this.props;
    return (
      <div
        className="screenInnerGap"
        style={{
          marginLeft: "2px",
          marginRight: "2px",
          marginBottom: "22px",
          marginTop: "86px",
        }}
      >
        <div className="backEventorGroup flexDefault" onClick={this.backtoHome}>
          <ArrowBackIcon />
          <p>{t("Back to Community Description")}</p>
          {/* <p className="askAQuestionTitle">{t("Ask a Question")} </p> */}
        </div>

        <div className="askAQuestionCardWrapper">
          <BlockUi
            tag="div"
            blocking={this.state.blocking}
            message={t("Loading, please wait")}
            color="#60c2e7"
          >
            <div className="askAQuestionInputGroup inputLarge">
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <label className="formLabel">{t("Question")} *</label>
                <label className="smart-option">
                  <input
                    type="checkbox"
                    checked={this.state.isChecked}
                    defaultChecked={this.state.isChecked}
                    value={this.state.isChecked}
                    onChange={this.toggleChange}
                  />{" "}
                  {t("Make as Private")}
                </label>
                <div>
                  {" "}
                  <Tooltip title={t("You only can view this question")}>
                    <IconButton className="infoButton">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>

                <input
                  type="text"
                  name="question"
                  value={this.state.question}
                  maxLength="250"
                  onChange={this.handleForm}
                />
                <p
                  className="smallText"
                  style={
                    this.state.questionTitleCount > 250
                      ? { color: "red" }
                      : { color: "green" }
                  }
                >
                  {" "}
                  {250 - this.state.questionTitleCount} {t("characters left")}
                </p>
              </Grid>
            </div>
            <div className="askAQuestionDescription">
              <label className="formLabel">{t("Description")} *</label>
              {/* <TextEditorQuestion
                ref={this.myRef}
                editorStateValue={this.editorStateValues}
                editorValue={this.state.questionDescribed}
              /> */}
              <TextEditorComments
                editorStateValue={this.editorStateValues}
                editorValue={this.state.questionDescribed}
                friendList={this.props.friendsList}
                needEdit={this.state.needEdit}
                changeNeedHelpState={this.changeNeedHelpState}
              />
              <p
                className="boxText"
                style={
                  this.state.descriptionCount > 2500
                    ? { color: "red" }
                    : { color: "green" }
                }
              >
                {" "}
                {2500 - this.state.descriptionCount} {t("characters left")}
              </p>
            </div>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12}>
                {/* <Form.Group controlId="region">
                  <Form.Label>{t('Choose Grade')}</Form.Label>
                  <Form.Control as="select" value={this.state.gradeId} onChange={this.handleGrade}>
                    <option value="">{t('Choose')}</option>
                    {this.state.gradeDetails && this.state.gradeDetails.map((country, gradeId) => (
                      <option value={country.gradeId} name="gradeId" id="gradeId" key={gradeId}>
                        {country.gradeName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group> */}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className="askAQuestionInputGroup">
                  <Form.Label>{t("Select Tags")} *</Form.Label>
                  <Multiselect
                    className="profileFormInput aboutInputAddButton"
                    required
                    data={this.props.tagList}
                    dataKey="tagId"
                    textField="tagName"
                    name="tagId"
                    style={{ color: "#292d3b !important" }}
                    value={this.state.tagId}
                    onChange={(value) => this.handleTeachingMultiSelect(value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {this.state.imagePresent &&
                  this.state.mediaFiles &&
                  this.state.editOption &&
                  this.state.mediaFiles.map((data, index) => (
                    // <div key={this.state.originalFileName}>
                    <div>
                      <Chip
                        icon={<AttachmentIcon />}
                        className="fileChip"
                        label={data.originalFileName}
                        size="small"
                        onDelete={() => this.removeImageFile(data.id, index)}
                        variant="outlined"
                      />
                      {this.state.questionType === "image" && (
                        <img
                          style={{
                            margin: "8px",
                            width: "80px",
                            height: "80px",
                          }}
                          src={`${APPCONFIG.API_URL}auth/image/${data.fileName}`}
                        ></img>
                      )}
                    </div>
                  ))}
                {this.state.chipImageData &&
                  this.state.chipImageData.map((file, index) => (
                    <div key={file.name}>
                      <Chip
                        icon={<AttachmentIcon />}
                        className="fileChip"
                        label={file.name}
                        size="small"
                        onDelete={() => this.removeFile(index)}
                        variant="outlined"
                      />
                      {this.state.fileTypeKey == "image" && (
                        <img
                          style={{
                            margin: "8px",
                            width: "80px",
                            height: "80px",
                          }}
                          src={URL.createObjectURL(file)}
                        ></img>
                      )}
                    </div>
                  ))}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {this.state.fileTypeState && (
                  <div>
                    <AiOutlineCloseCircle
                      onClick={this.closeChoosedFileData}
                      style={{
                        cursor: "pointer",
                        paddingTop: "10px",
                        float: "right",
                        marginBottom: "8px",
                        width: "30px",
                        height: "30px",
                        color: "#000000",
                      }}
                    />
                    <AnswerFileOptions
                      chipImageData={this.chipImageData}
                      fileTypeKey={this.state.fileTypeKey}
                      fileOption={this.state.fileOption}
                      fileOptionEnable={this.fileOptionEnable}
                      fromQuestionImage={this.getQuestionMediaImage}
                      fromQuestionMedia={this.getQuestionMediaFiles}
                    ></AnswerFileOptions>{" "}
                  </div>
                )}{" "}
              </Grid>
              {!this.state.fileTypeState && this.state.editOption && (
                <ul
                  style={{ marginBottom: "12px" }}
                  class="groupPost-nav groupPost-nav-pills groupPost-nav-stack groupPost-small groupPost-fw-normal"
                >
                  {(this.state.questionType === "image" ||
                    this.state.emptyFileState ||
                    this.state.questionType === "text") && (
                    <li
                      class="groupPost-nav-item"
                      onClick={() => this.keyFile("image")}
                    >
                      <a
                        class="groupPost-nav-link bg-light"
                        href="#!"
                        data-bs-toggle="modal"
                        style={{ cursor: "pointer" }}
                        data-bs-target="#feedActionPhoto"
                      >
                        {" "}
                        <CameraAltIcon
                          style={{
                            color: "#0cbc87",
                          }}
                        />{" "}
                        Photo
                      </a>
                    </li>
                  )}
                  {/* {(this.state.questionType === "video" ||
                    this.state.emptyFileState ||
                    this.state.questionType === "text") && (
                    <li
                      class="groupPost-nav-item"
                      onClick={() => this.keyFile("video")}
                    >
                      <a
                        class="groupPost-nav-link bg-light"
                        href="#!"
                        data-bs-toggle="modal"
                        style={{ cursor: "pointer" }}
                        data-bs-target="#feedActionVideo"
                      >
                        {" "}
                        <VideocamIcon
                          style={{
                            color: "#4f9ef8",
                          }}
                        />{" "}
                        Video
                      </a>
                    </li>
                  )}
                  {(this.state.questionType === "file" ||
                    this.state.emptyFileState ||
                    this.state.questionType === "text") && (
                    <li
                      class="groupPost-nav-item"
                      onClick={() => this.keyFile("audio")}
                    >
                      <a
                        href="#"
                        class="groupPost-nav-link bg-light"
                        data-bs-toggle="modal"
                        style={{ cursor: "pointer" }}
                        data-bs-target="#modalCreateEvents"
                      >
                        {" "}
                        <DescriptionIcon
                          style={{
                            color: "#dc3545",
                          }}
                        />{" "}
                        File{" "}
                      </a>
                    </li>
                  )} */}
                </ul>
              )}

              {!this.state.fileTypeState &&
                !this.state.editOption &&
                this.state.mediaFiles.length === 0 && (
                  <ul
                    style={{ marginBottom: "12px" }}
                    class="groupPost-nav groupPost-nav-pills groupPost-nav-stack groupPost-small groupPost-fw-normal"
                  >
                    <li
                      class="groupPost-nav-item"
                      onClick={() => this.keyFile("image")}
                    >
                      <a
                        class="groupPost-nav-link bg-light"
                        href="#!"
                        data-bs-toggle="modal"
                        style={{ cursor: "pointer" }}
                        data-bs-target="#feedActionPhoto"
                      >
                        {" "}
                        <CameraAltIcon
                          style={{
                            color: "#0cbc87",
                          }}
                        />{" "}
                        Photo
                      </a>
                    </li>

                    {/* <li
                      class="groupPost-nav-item"
                      onClick={() => this.keyFile("video")}
                    >
                      <a
                        class="groupPost-nav-link bg-light"
                        href="#!"
                        data-bs-toggle="modal"
                        style={{ cursor: "pointer" }}
                        data-bs-target="#feedActionVideo"
                      >
                        {" "}
                        <VideocamIcon
                          style={{
                            color: "#4f9ef8",
                          }}
                        />{" "}
                        Video
                      </a>
                    </li>

                    <li
                      class="groupPost-nav-item"
                      onClick={() => this.keyFile("audio")}
                    >
                      <a
                        href="#"
                        class="groupPost-nav-link bg-light"
                        data-bs-toggle="modal"
                        style={{ cursor: "pointer" }}
                        data-bs-target="#modalCreateEvents"
                      >
                        {" "}
                        <DescriptionIcon
                          style={{
                            color: "#dc3545",
                          }}
                        />{" "}
                        File{" "}
                      </a>
                    </li> */}
                  </ul>
                )}
            </Grid>
          </BlockUi>
          {!this.state.editState && (
            <div className="askAQuestionBtn">
              <button onClick={(e) => this.onFormSubmit(e)}>
                {t("POST YOUR QUESTION")}
              </button>
            </div>
          )}
          {this.state.editState && (
            <div className="askAQuestionBtn">
              <button onClick={(e) => this.onUpdateSubmit(e)}>
                {t("UPDATE YOUR QUESTION")}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
  removeFile = (index) => {
    var inpu = this.state.chipImageData;
    if (this.state.chipImageData[index]) {
      var val = this.state.chipImageData;
      val.splice(index, 1);
      this.setState({
        chipImageData: val,
      });
    }
  };
  chipImageData = (data) => {
    this.setState({ chipImageData: data });
  };
  closeChoosedFileData = () => {
    this.setState({ fileTypeState: false, file: [], chipImageData: [] });
  };
  keyFile = (key) => {
    this.setState({ fileTypeKey: key, fileTypeState: true });
  };
  removeImageFile = (id, index) => {
    var data = [];
    var mediaId = this.state.removeMediaId;
    data = this.state.mediaFiles.filter((item) => item.id !== id);
    mediaId.push(id);
    this.setState({
      mediaFiles: data,
      removeMediaId: mediaId,
    });
    if (data.length === 0) {
      this.setState({
        emptyFileState: true,
        type: "text",
      });
    }
    console.log(data);
  };
  updateQuestion = (id) => {
    var subjectId = {
      questionId: id,
    };
    ForumService.askSingleQuestionList(subjectId)
      .then((response) => {
        var result = response.data.data.questions;
        console.log(result.startDate);
        this.setState({
          isChecked: result.markAsPrivate,
          question: result.questionName,
          tagId: result.questionTags,
          questionDescribed: result.questionDescription,
          subjectId: result.subjectId,
          mediaFiles: result.mediaFiles,
          questionType: result.questionType,
          editOption: true,
          type: result.questionType,
          needEdit: true,
          content: result.questionDescription,
        });
        if (result.mediaFiles.length !== 0) {
          this.setState({
            imagePresent: true,
            originalFileName: result.originalFileName,
            questionFile: result.questionFile,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  handleGrade(event) {
    if (this.state.curriculumId == "") {
      Swal.fire({
        title: "Please choose a valid Program before choosing a Grade",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
        reverseButtons: true,
      }).then((result) => {});
    } else {
      this.setState({
        //[event.target.name]
        gradeId: event.target.value,
      });
    }
  }

  getQuestionMediaFiles(files, type) {
    this.setState({ file: files, type: type });
    console.log("files getmedia", files);
  }

  editorStateValues = (values) => {
    console.log("editorStateValues");
    console.log(values);
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        content: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        content: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  };

  handleTeachingMultiSelect(subject_value) {
    this.setState({
      tagId: subject_value,
    });
  }

  getallSubject() {
    this.setState({ app_subjects: this.props.subjectsList.data });
  }

  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        this.setState({ gradeDetails: result.data.grade });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fileOptionEnable() {
    console.log("files option Enable");
    this.setState({
      file: null,
      editImage: [],
      questionFile: null,
      fileOption: false,
      fileImage: false,
      // imageFile: "",
      // imagePresent: false,
    });
  }
  getQuestionMediaImage = (files, type) => {
    this.setState({
      imageFile: files,
      type: type,
      selectOption: false,

      editImage: files,
      fileImage: true,
      imagePresent: false,
      file: files,
    });
  };
  onFormSubmit(e) {
    console.log("file,", this.state.type);
    const formData = new FormData();
    if (this.state.question == "") {
      toast.warn(`${this.props.t("Please enter valid question")}`);
    } else if (this.props.match.params.id === undefined) {
      toast.warn(
        `${this.props.t("Something wrong please try after few minutes")}`
      );
    } else if (this.state.question.length >= 250) {
      toast.warn(
        `${this.props.t("Entered question should not be above 250 characters")}`
      );
    }
    // else if (this.state.questionDescribed == "") {
    //     toast.warn("Please ");
    //   }
    else if (this.state.tagId?.length === 0) {
      toast.warn(`${this.props.t("Please choose question related tags")}`);
    }
    // else if (this.state.gradeId == undefined) {
    //   toast.warn("Please choose grade level");
    // }
    else {
      var data = this.state.tagId;
      var tags = [];
      data.forEach((element) => {
        tags.push(element.tagId);
      });
      if (
        this.state.file === undefined ||
        this.state.file === null ||
        this.state.file.length === 0
      ) {
        //  formData.append("userName", this.props.loginDetails.dName);
        formData.append(
          "questionDescription",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("questionName", this.state.question);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("type", "text");
        formData.append("groupId", this.props.match.params.id);
        formData.append("questionTags", tags);
      } else {
        for (let i = 0; i < this.state.file.length; i++) {
          formData.append("mediaFiles", this.state.file[i]);
        }
        //  formData.append("userName", this.props.loginDetails.dName);
        formData.append(
          "questionDescription",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("questionName", this.state.question);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("type", this.state.type);
        formData.append("groupId", this.props.match.params.id);
        formData.append("questionTags", tags);
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.block(true);
      ForumService.saveAskQuestion(formData, config)
        .then((response) => {
          var result = response.data;

          toast.success(`${this.props.t("✓ Question Saved Successfully")}`);
          this.myRef.current.onClear();
          this.block(false);
          this.backtoHome();
        })
        .catch((error) => {
          console.log(error);
          this.backtoHome();
          // toast.error("Error in saving question");
        });
    }
  }

  onUpdateSubmit(e) {
    const formData = new FormData();
    if (this.state.question == "") {
      toast.warn(`${this.props.t("Please enter valid question")}`);
    } else if (this.state.subjectId === 0) {
      toast.warn(
        `${this.props.t("Something wrong please try after few minutes")}`
      );
    } else if (this.state.question.length >= 250) {
      toast.warn(
        `${this.props.t("Entered question should not be above 250 characters")}`
      );
    }
    // else if (this.state.tagId == "") {
    //   toast.warn("Please choose program");
    // }
    // else if (this.state.questionDescribed == "") {
    //     toast.warn("Please ");
    //   }
    else if (this.state.tagId.length === 0) {
      toast.warn(`${this.props.t("Please choose question related tags")}`);
    }
    // else if (this.state.gradeId == undefined) {
    //   toast.warn("Please choose grade level");
    // }
    else {
      var data = this.state.tagId;
      var tags = [];
      data.forEach((element) => {
        tags.push(element.tagId);
      });
      if (
        this.state.removeMediaId.length !== 0 &&
        this.state.file !== undefined &&
        this.state.file.length !== 0
      ) {
        // formData.append("userName", this.props.loginDetails.dName);
        formData.append(
          "questionDescription",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("questionName", this.state.question);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("type", this.state.type);
        formData.append("groupId", this.state.subjectId);
        formData.append("questionId", this.props.match.params.id);
        formData.append("questionTags", tags);
        formData.append("removeMediaId", this.state.removeMediaId);
        for (let i = 0; i < this.state.file.length; i++) {
          formData.append("mediaFiles", this.state.file[i]);
        }
      } else if (
        this.state.removeMediaId.length !== 0 &&
        this.state.file.length === 0
      ) {
        //  formData.append("userName", this.props.loginDetails.dName);
        formData.append(
          "questionDescription",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("questionName", this.state.question);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("type", this.state.type);
        formData.append("groupId", this.state.subjectId);
        formData.append("questionId", this.props.match.params.id);
        formData.append("questionTags", tags);
        formData.append("removeMediaId", this.state.removeMediaId);
      } else if (
        this.state.removeMediaId.length === 0 &&
        this.state.file.length !== 0
      ) {
        for (let i = 0; i < this.state.file.length; i++) {
          formData.append("mediaFiles", this.state.file[i]);
        }
        //   formData.append("userName", this.props.loginDetails.dName);
        formData.append(
          "questionDescription",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("questionName", this.state.question);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("type", this.state.type);
        formData.append("groupId", this.state.subjectId);
        formData.append("questionId", this.props.match.params.id);
        formData.append("questionTags", tags);
      } else if (
        this.state.removeMediaId.length === 0 &&
        this.state.file.length === 0
      ) {
        //   formData.append("userName", this.props.loginDetails.dName);
        formData.append(
          "questionDescription",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("questionName", this.state.question);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("type", "text");
        formData.append("groupId", this.state.subjectId);
        formData.append("questionId", this.props.match.params.id);
        formData.append("questionTags", tags);
      } else {
        formData.append("questionId", this.props.match.params.id);
        //     formData.append("userName", this.props.loginDetails.dName);
        formData.append(
          "questionDescription",
          extractMentionsFromUpdate(this.state.content)
        );
        formData.append("questionName", this.state.question);
        formData.append("markAsPrivate", this.state.isChecked);
        formData.append("type", this.state.type);
        formData.append("groupId", this.state.subjectId);
        formData.append("questionTags", tags);
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.block(true);
      ForumService.updateAskQuestion(formData, config)
        .then((response) => {
          var result = response.data;
          toast.success("✓ Question Updated Successfully,");
          this.block(false);
          this.backtoHome();
        })
        .catch((error) => {
          console.log(error);
          this.block(false);
          this.backtoHome();
          // toast.error("Error in saving question");
        });
    }
  }
  backtoHome = () => {
    var id = this.props.match.params.groupId;
    var subjectName = this.props.match.params.name;
    // var sub = this.props.match.params.id;
    // if (this.state.editState) {
    //   sub = this.state.subjectId;
    // } else {
    //   sub = this.props.match.params.id;
    // }
    // this.props.history.push(`/forum-description/${sub}`);
    this.props.history.push(
      `/${this.props.workspaceType}/forum-community/${id}/${subjectName}`,
      { from: window.location.pathname }
    );
    // this.props.history.push(`/communityDescription/${sub}/questionList`);
  };
  block(blocking) {
    this.setState({ blocking: blocking });
  }

  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
      questionTitleCount: event.target.value.length,
    });
  }
  handleCurriculum(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  getAllCurriculum() {
    this.setState({
      curriculumList: this.props.curriculumtrackList.curriculum,
    });
    // ProfileService.getAllCurriculum()
    //   .then(
    //     response => {
    //       var result = response.data;

    //     }
    //   )
    //   .catch(error => {
    //     console.log(error)
    //   }
    //   )
  }
  changeNeedHelpState = () => {
    this.setState({
      needEdit: false,
    });
  };

  getGradeByCurriculumId(value) {
    this.setState({ gradeId: "" });
    let getGradeByCurriculumId = {
      curriculumId: value,
    };
    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        var result = response.data;

        this.setState({ gradeDetails: response.data.data.gradeListById });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getGradeByCurriculumIdUpdate(value) {
    let getGradeByCurriculumId = {
      curriculumId: value,
    };

    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        var result = response.data;

        this.setState({ gradeDetails: response.data.data.gradeListById });
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  return {
    friendsList: state.commonApi.tagUserList,
    wallList: state.dashboard.dashboardList,
    loading: state.dashboard.loading,
    tagDatas: state.subjects.list,
    questionLists: state.myquestions.myQuestionList[0],
    totalItems: state.myquestions.myQuestionList[1],
    topQuestions: state.mylibraryquestion.list,
    solvedTotalitems: state.mylibraryquestion.totalItems,
    QandAquestionList: state.topquestList.list,
    QandAquestTotalitems: state.topquestList.totalItems,
    subjectsList: state.commonApi.subjectslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    tagList: state.commonApi.taglist.data,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getDashboardList: (page, uid) => dispatch(getDashboardList(0, uid)),
    getAllSubjectList: () => dispatch(getAllSubjectList()),
    getMyQuestionsList: () => dispatch(getMyQuestionsList(0)),
    getMyLibraryQuestionList: () =>
      dispatch(getMyLibraryQuestionList("All", 1)),
    getQuestionsList: () =>
      dispatch(getQuestionsList("unanswered", 1, 0, true, true)),
    getPostTagList: (type, id) => dispatch(getPostTagList(type, id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(AskQuestion)));
