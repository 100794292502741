import React, { Component } from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FriendsOnYuukkeCard from "../../../components/FriendsOnYuukkeCard";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import AuthService from "../../../session/AuthService";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { HiOutlineDocument } from "react-icons/hi";
import { FcConferenceCall } from "react-icons/fc";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { isThisSecond } from "date-fns";

const styleArrow = {
  width: 42,
  height: 42,
  boxShadow: "0px 3px 6px #00000029",
  border: "1px solid #DEDEDF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
  borderRadius: "100%",
  cursor: "pointer",
  position: "absolute",
  top: "35%",
  zIndex: 10,
};

function NextArrow(props) {
  const { className, style, onClick, currentSlide, slideCount } = props;
  return currentSlide !== slideCount - 3 ? (
    <div style={{ ...styleArrow, right: "5%" }} onClick={onClick}>
      <ArrowForwardIcon />
    </div>
  ) : (
    <></>
  );
}

function PrevArrow(props) {
  const { className, style, onClick, currentSlide } = props;
  return currentSlide !== 0 ? (
    <div style={{ ...styleArrow, left: "5%" }} onClick={onClick}>
      <ArrowBackIcon />
    </div>
  ) : (
    <></>
  );
}

class FriendsOnYuukke extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FriendsList: [],
      recommendedFriendsList: [],
      recommendedFriendsListState: false,
      // items: Array.from({ length: 6 })
    };
    this.getRecommendedFriendsList = this.getRecommendedFriendsList.bind(this);
    this.requestSentPage = this.requestSentPage.bind(this);
    this.gotoSuggestedFriendsList = this.gotoSuggestedFriendsList.bind(this);
    this.ignoreSentPage = this.ignoreSentPage.bind(this);
  }

  componentDidMount() {
    this.setState({
      FriendsList: this.props.FriendsList,
    });
    this.getRecommendedFriendsList(
      this.props.recommended_friends_list,
      this.props.recommendedListLength
    );
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("FriendsList", this.props.recommended_friends_list);
    if (
      prevProps.recommended_friends_list !== this.props.recommended_friends_list
    ) {
      this.getRecommendedFriendsList(
        this.props.recommended_friends_list,
        this.props.recommendedListLength
      );
    }
  }
  fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      // this.setState({
      //   items: this.props.recommended_friends_list.concat(Array.from({ length: 6 }))
      // });
    }, 1500);
  };
  render() {
    const { t } = this.props;

    return (
      <div
        className="peopleCarouselWrapper"
        style={{ background: "#f5f5f5 0 0 no-repeat padding-box" }}
      >
        {/* <div className="flexDefault flexAlignCenter flexJustifySpacebetween">
          <div>
            <h3 className="listHomeTitle">{t("Friends On Yuukke")}</h3>
          </div>
        </div> */}

        <Grid container spacing={2}>
          {this.props.recommended_friends_list &&
            this.props.recommended_friends_list.map((people, index) => {
              return (
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <React.Fragment key={index}>
                    <FriendsOnYuukkeCard
                      userId={people.uId}
                      name={people.userName}
                      Dname={people.dName}
                      searchvalue={this.props.searchvalue}
                      coverPhoto={people.coverPhoto}
                      userPhoto={people.profilePhoto}
                      // gender={people.userProfile.gender}
                      // email={people.email}
                      nameLocation={
                        people.location !== null ? people.location : "--------"
                      }
                      country={
                        people.countryName !== null
                          ? people.countryName
                          : "--------"
                      }
                      profession={
                        people.professionName !== null
                          ? people.professionName
                          : "--------"
                      }
                      tagsValue={
                        people.tagsValue.length !== 0
                          ? people.tagsValue
                          : "---------"
                      }
                      // subjects={people.userLearn}
                      requestSent={this.requestSentPage}
                      ignoreSent={this.ignoreSentPage}
                      premiumData={people?.premiumData}
                    />
                  </React.Fragment>
                </Grid>
              );
            })}
        </Grid>

        {this.props.recommended_friends_list.length == 0 && (
          <div
            className="communityCardWrapper cardWrapper"
            style={{ padding: "70px", marginTop: "20px" }}
          >
            <center>
              <div style={{ justifyContent: "center" }}>
                <FcConferenceCall
                  style={{
                    width: "55px",
                    height: "55px",
                    color: "#000000",
                  }}
                />

                {this.props.statefriendsPage !== 0 &&
                  this.props.propsfriendsPage == 0 && (
                    <p style={{ margin: "8px", fontSize: "20px" }}>
                      {t("No match found")}
                    </p>
                  )}
                {this.props.propsfriendsPage == this.props.statefriendsPage && (
                  <p style={{ margin: "8px", fontSize: "18px" }}>
                    {t("Yet to make connection")}
                  </p>
                )}
              </div>
            </center>
          </div>
        )}
      </div>
    );
  }

  ignoreSentPage() {
    this.props.ignoreSentPageData();
  }

  requestSentPage() {
    this.props.requestSentPageData();
  }

  async getRecommendedFriendsList(
    recommended_friends_list,
    recommendedListLength
  ) {
    console.log(recommended_friends_list);
    if (recommended_friends_list.length > 0) {
      await this.setState({
        recommendedFriendsList: recommended_friends_list,
        recommendedFriendsListState: false,
        recommendedListLengths: recommendedListLength,
      });
    } else {
      await this.setState({
        recommendedFriendsListState: true,
        recommendedListLengths: recommendedListLength,
      });
    }
  }

  gotoSuggestedFriendsList() {
    this.props.history.push({
      pathname: "/suggested-friends-list",
    });
  }
}

const mapStateToProps = function (state) {
  return {
    FriendsList: state.commonApi.friendslist,
  };
};
export default connect(mapStateToProps)(
  withTranslation()(withRouter(FriendsOnYuukke))
);
