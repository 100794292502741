//Market place Config

export const MARKET_CONFIG = "https://marketplace.yuukke.com/";
export const KEY_CLOAK_URL = "https://login.yuukke.com/auth/";
export const GOOGLE_CLIENT_ID =
  "828565342140-hssdtcntis0pklgeedtaqlgkougiinas.apps.googleusercontent.com";
export const CLIENT_ID = "iwnFrontend";
export const profiles = null;
export const ENCRYPT_KEY = "B";
export const DYNAMIC_FORM_URL = "https://forms.yuukke.com/yuukke";
// export const CLIENT_ID = "iwn-mobile";
// export const profiles = "moodle,keycloak";
