import React, { Component } from "react";
import AuthService from "../../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { HiOutlineDocument } from "react-icons/hi";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { API_URL } from "../../../config/app-config";
import dummyimage from "../../../img/default_avatar.jpg";
import FriendConnectionListCard from "./GroupConnectionCard";
import GroupMembersAndFriendsList from "../../../components/GroupMembersAndFriendsList";
import {
  getAllMembersDetails,
  updateGroupMember,
} from "../../../redux/actions/subjectActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

class GroupConnectionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      friendsList: [],
      pageNo: 0,
      searchData: "",
      filterValue: "all",
    };
    this.loadFriendsList = this.loadFriendsList.bind(this);
    this.searchMembers = this.searchMembers.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    console.log("fl 1");
    console.log(this.props.friendsList);
    // this.setState({ friendsList: this.props.friendsList });
    this.props.getAllMembersDetails(this.props.communityId, "all", 0, false);
    this.setState({
      pageNo: 0,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.friendsList !== this.props.friendsList) {
    //   console.log("fl 2");
    //   console.log(this.props.friendsList);
    //   // this.setState({ friendsList: this.props.friendsList });
    // }
  }

  seeMoreFriend = () => {
    this.props.getAllMembersDetails(
      this.props.communityId,
      this.state.filterValue,
      this.state.pageNo + 1,
      true,
      this.state.searchData
    );
    this.setState({
      pageNo: this.state.pageNo + 1,
    });
  };

  searchMembers = async (e, search) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    if (search === "search") {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      // Define your delay (in milliseconds)
      const delay = 500; // 1 second (adjust as needed)

      // Set a new timeout for the API call
      setTimeout(() => {
        this.props.getAllMembersDetails(
          this.props.communityId,
          this.state.filterValue,
          0,
          false,
          this.state.searchData
        );
      }, delay);
    } else {
      this.props.getAllMembersDetails(
        this.props.communityId,
        this.state.filterValue,
        0,
        false,
        this.state.searchData
      );
    }

    this.setState({
      pageNo: 0,
    });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        {/* <div  >
                    <div className="connectionfriendTabCardWrapper">
                       <div style={{display:"inline-flex"}}>
                       <p style={{ fontWeight: "bold", fontSize: "16px" }}>Connections </p>
                           <p className="redLabel" >{this.state.friendsList.length}</p>
     
                       </div>                  
                         <Grid container spacing={0} style={{marginTop:"8px"}}>
                            {this.state.friendsList &&
                                this.state.friendsList.length > 0 &&
                                this.state.friendsList.map((friend, index) => (
                                    <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                            
                                        <FriendConnectionListCard
                                        userId = {friend.userId}
                                        displayName = {friend.userProfile.displayName}
                                        // location = {friend.userProfile.location}
                                        biography= {friend.userProfile.biography}

                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </div>

                </div> */}

        {/* <div class="about-card" style={{ marginTop: "1.5rem" }}>
                    <div class="about-card-header border-0 pb-0">
                        <h5 class="about-card-title"> Connections</h5>
                    </div>
                    {this.state.friendsList &&
                        this.state.friendsList.length > 0 &&
                        this.state.friendsList.map((friend, index) => (

                            <FriendConnectionListCard
                                userId={friend.userId}
                                displayName={friend.displayName}
                                type={friend.type}
                                biography={friend.biography}
                                loadFriendsList={this.loadFriendsList}

                            />
                        ))}
                </div> */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {!this.props.isForum && (
              <div className="group-filter d-flex align-items-center justify-content-end ">
                <h5 className="mr-auto mb-0">Community Members</h5>
                <TextField
                  id="dropdown"
                  select
                  label=""
                  style={{ background: "white" }}
                  variant="outlined"
                  onChange={(e) => this.searchMembers(e)}
                  value={this.state.filterValue}
                  name="filterValue"
                  placeholder={t("Search")}
                >
                  <MenuItem value="all">
                    <>{t("All")}</>
                  </MenuItem>
                  <MenuItem value="admin">
                    <>{t("Admin")}</>
                  </MenuItem>
                  <MenuItem value="user">
                    <>{t("User")}</>
                  </MenuItem>
                </TextField>

                <TextField
                  id="subjects"
                  label=""
                  style={{ background: "white" }}
                  variant="outlined"
                  onChange={(e) => this.searchMembers(e, "search")}
                  value={this.state.searchData}
                  name="searchData"
                  placeholder={t("Search")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          </Grid>
          {this.props.friendsList &&
            this.props.friendsList.map((people, index) => {
              return (
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <React.Fragment key={index}>
                    {/* {people.uId !==this.props.loginDetails.uId &&( */}
                    <GroupMembersAndFriendsList
                      isOwner={people.userId === this.props.loginDetails.uId}
                      userId={people.userId}
                      // name={`${people.fName} ${people.lName}`}

                      Dname={people.userName}
                      // coverPhoto={people.coverPhoto}
                      // userPhoto={people.userPhoto}
                      gender={people.gender}
                      email={people.email}
                      subjects={people.biography}
                      // subId={subId}
                      adminId={people.userId}
                      type={people.type}
                      subId={this.props.communityId}
                      profilePhoto={people.profilePhoto}
                      coverPhoto={people.coverPhoto}
                      removedSentData={this.removedSentData}
                      userStatus={people.userStatus}
                      loginUserStatus={this.props.userStatus}
                      isForum={this.props.isForum}
                      premiumUser={people.premiumData}
                      // requestSent={this.requestSentPage}
                      // ignoreSent={this.ignoreSentPage}
                    />
                    {/* )} */}
                  </React.Fragment>
                </Grid>
              );
            })}
          {this.props.connectionSeeMore && (
            <Grid item xs={12}>
              <div className="networkDetailsBtn">
                <button
                  className="networkSeeMore"
                  style={{ width: "max-content", padding: "6px" }}
                  onClick={this.seeMoreFriend}
                >
                  {t("See more connections")}
                </button>
              </div>
            </Grid>
          )}

          {/* {this.state.friendsListState && (
            <div
              className="communityCardWrapper cardWrapper"
              style={{ padding: "70px" }}
            >
              <center>
                <div style={{ justifyContent: "center" }}>
                  <HiOutlineDocument
                    style={{
                      width: "55px",
                      height: "55px",
                      color: "#000000",
                    }}
                  />
                  <p style={{ margin: "8px", fontSize: "20px" }}>
                    {t("Nothing here!")}
                  </p>
                </div>
              </center>
            </div>
          )} */}
        </Grid>
      </>
    );
  }
  removedSentData = (userId) => {
    this.props.updateGroupMember(userId, "remove");
    // const list = this.state.friendsList.filter((item) => item.uId !== userId);
    // this.setState({
    //   friendsList: list,
    // });
  };
  loadFriendsList() {
    this.props.loadFriendsList();
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    connectionSeeMore: state.subjects.connectionSeeMore,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    updateGroupMember: (id, status) => dispatch(updateGroupMember(id, status)),
    getAllMembersDetails: (id, type, page, status, searchData) =>
      dispatch(getAllMembersDetails(id, type, page, status, searchData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(GroupConnectionList)));
