// job request
import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import AuthService from "../../session/AuthService";
//import backIcon from "../../images/BACK ICON.svg"
import PostService from "../../api/PostService";
import { withRouter } from "react-router";
import LoadService from "../../utils/LoadService";
import { updateLoginDetails } from "../../redux/actions/loginDetailsActions";
import Webcam from "react-webcam";
import Utils from "../../utils/Utils";
import { isThisSecond } from "date-fns/esm";
import {
  getWorkSpaceType,
  getUserWorkSpaceList,
  needPhotVerification,
} from "../../redux/actions/commonApiActions";
import { MdOutlineVerified } from "react-icons/md";

class KYCSetupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      budget: "",
      name: "",
      email: "",
      qualification: "",
      base64String: "",
      image: "",
      feedBack: "",
      iid: this.props.id,
      feedbackState: false,
      feedBackList: [],
      PageRefrehedCount: 0,
      description: "",
      type: "",
      blocksend: false,
      areaOfInterest: false, //new
      areaofinterestsList: [],
      question: "",
      questionTitleCount: 0,
      fileTypeState: true,
      areaofInterest: [],
      industrysList: [],
      industrysLists: [],
      areaOfinterestListByUser: [],
      natureRequest: [
        { id: "1", name: "Doubt" },
        { id: "2", name: "Service Need" },
        { id: "3", name: "Looking for Referral connects" },
        { id: "4", name: "Hiring Need" },
      ],
      natureRequestValue: "",
      industryLists: [],
      selectedduration: "Service Need",
      selecteTime: "Immediate",
      camera: true,
      iswomen: true,
      kycImageSrc: "",
      spinner: false,
      tryCount: 0,
    };

    this.handleForm = this.handleForm.bind(this);
    this.saveList = this.saveList.bind(this);
    this.keyFile = this.keyFile.bind(this);
    this.closeChoosedFileData = this.closeChoosedFileData.bind(this);
    this.getQuestionMediaFiles = this.getQuestionMediaFiles.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.webcamRef = React.createRef();
  }

  componentDidMount() {
    console.log("natureRequest", this.state.natureRequest);
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.setState({
      PageRefrehedCount: +window.localStorage.getItem("PageRefrehedCount"),
    });

    window.onbeforeunload = () => {
      window.localStorage.setItem(
        "PageRefrehedCount",
        JSON.stringify(this.state.PageRefrehedCount + 1)
      );
      console.log("Hello,", this.state.PageRefrehedCount);
    };
  }

  componentDidUpdate(prevProps) {
    console.log("Called", prevProps.PageRefrehedCount);
    console.log("Called", this.state.PageRefrehedCount);
    if (prevProps.PageRefrehedCount !== this.state.PageRefrehedCount) {
      console.log("Called", prevProps.PageRefrehedCount);
      // this.setState( {
      //   PageRefrehedCount : +window.localStorage.getItem("PageRefrehedCount")
      // })
    }
  }

  handleClickEdit() {
    this.setState({ savebtnState: false });
  }

  render() {
    const { t } = this.props;
    if (this.state.iswomen || this.state.tryCount < 2) {
      // if (this.state.iswomen || this.state.PageRefrehedCount < 5) {
      return (
        <div style={{ position: "relative" }}>
          {/* <div className="first-time-setup" style={{ position: "relative" }}> */}
          {!this.state.spinner && (
            // <div className="kycContainerInside">
            <div>
              <ToastContainer position="top-right" autoClose={5000} />
              {/* <div className="kyc-background">
                  <p>
                    The pictures you upload on the Yuukke app are strictly for
                    authentication and verification purposes, ensuring privacy
                    and security.
                  </p>
                </div> */}
              {this.state.camera && (
                <div
                  className="m-1 mb-2 text-secondary "
                  style={{ fontSize: "10px", textAlign: "center" }}
                >
                  {t(
                    "Please record your original photo, its only for validation purpose"
                  )}
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center">
                {this.state.camera ? (
                  <>
                    <Webcam
                      audio={false}
                      screenshotFormat="image/png"
                      ref={this.webcamRef}
                      height={"100%"}
                      // height={250}
                      width={
                        window.matchMedia("(min-width:739px)").matches
                          ? 500
                          : "90%"
                      }
                      onUserMediaError={() => {
                        this.setState({
                          camera: false,
                        });
                      }}
                    />
                  </>
                ) : (
                  <>
                    <p className="p-4">
                      {t(
                        "Error reading face please make sure your web cam is working properly or make use of your mobile phone to complete the verification process"
                      )}
                    </p>
                  </>
                )}

                {/* <KYCFileOption
                fileTypeKey={this.state.fileTypeKey}
                fileOption={this.state.fileOption}
                fileOptionEnable={this.fileOptionEnable}
                fromQuestionMedia={this.getQuestionMediaFiles}
                editObject={this.props.editObject}
              ></KYCFileOption> */}
              </div>
              {/* <hr /> */}
              <div className="d-flex justify-content-center mt-2 mb-3">
                {/* <button
                  className="ml-3"
                  onClick={() => this.props.needPhotVerification(false)}
                >
                  {t("Close")}
                </button> */}
                {this.state.camera && (
                  <span
                    className="green-btn mb-2"
                    onClick={(e) => this.saveList(e)}
                  >
                    {t("Submit")}
                  </span>
                )}
                {/* {this.state.camera && (
                   <button
                    style={{ float: "right" }}
                    onClick={this.saveList}
                    disabled={this.state.blocksend}
                  >
                    {t("Submit")}
                  </button>
                 )} */}
              </div>
            </div>
          )}
          {this.state.spinner && (
            <div
              class="d-flex justify-space-between align-items-center"
              style={{ height: "50vh", gap: "2rem" }}
            >
              <div
                class="spinner-border text-info"
                style={{ width: "3rem", height: "3rem" }}
              ></div>

              <div>
                <p>{t("Processing please wait")}...</p>{" "}
              </div>
            </div>
            // <div className="kycContainerInsideLoading ">
            //   <div class="row">
            //     <div class="col-lg-2">
            //       <div
            //         class="spinner-border text-info"
            //         style={{ width: "3rem", height: "3rem" }}
            //       ></div>
            //     </div>
            //     <div class="col-lg-5">
            //       <p>Processing please wait...</p>{" "}
            //     </div>
            //   </div>{" "}
            // </div>
          )}
        </div>
      );
    } else {
      return (
        // <div className="first-time-setup" style={{ position: "relative" }}>
        <div style={{ position: "relative" }}>
          {/* <div className="kycContainerInside" style={{ "max-width": "50vw" }}> */}
          <ToastContainer position="top-right" autoClose={5000} />

          {/* <Grid style={{ marginTop: "20px" }}> */}
          {this.state.camera && (
            <>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}> */}
              <div className="d-flex justify-content-center align-items-center flex-column p-4">
                <div>
                  <img
                    className="rounded-circle"
                    style={{
                      position: "relative",
                      marginBottom: "12px",
                      width: "250px",
                      height: "250px",
                    }}
                    src={this.state.kycImageSrc}
                  ></img>
                </div>
                <p className="mb-4">
                  {/* {this.state.PageRefrehedCount > 5 ? (
                         
                           <>Please dont refresh the page, while taking photo</>
                          
                        ) : (
                          <> */}
                  {t(
                    "Thank you for showing interest over Yuukke, this app is specifically designed for women only."
                  )}{" "}
                  <a
                    className="ml-1"
                    href="#"
                    onClick={() => this.props.back()}
                  >
                    Back to home
                  </a>
                  {/* </>
                        )} */}
                </p>
              </div>
              {/* <button
                className="ml-3 mb-3"
                onClick={() => this.props.needPhotVerification(false)}
              >
                {t("Close")}
              </button> */}
              {/* </Grid> */}
            </>
          )}
          {/* </Grid> */}
        </div>
        // </div>
      );
    }
  }

  getQuestionMediaFiles(files, type) {
    this.setState({ file: files, type: type });
  }
  closeChoosedFileData() {
    this.setState({ fileTypeState: false, file: [] });
  }
  keyFile(key) {
    this.setState({ fileTypeKey: key, fileTypeState: true });
  }

  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
      questionTitleCount: event.target.value.length,
    });
  }
  saveList = (e) => {
    const imgSrc = this.webcamRef.current.getScreenshot();

    const file = Utils.dataURLtoFile(imgSrc, "abc.png");

    console.log("KYC FILE", imgSrc);

    this.setState({
      file: file,
      kycImageSrc: imgSrc,
    });

    const formData = new FormData();
    e.preventDefault();
    e.stopPropagation();
    if (file == undefined) {
      toast.warn("Please choose proper file");
    } else {
      this.setState({
        spinner: true,
      });
      formData.append("firstProfilePhoto", file);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      PostService.validUser(formData, config)
        .then((response) => {
          this.setState({
            spinner: false,
          });
          console.log(response.data.data.validUser);
          if (response.data.data.validUser) {
            let workSpace =
              this.props.loginDetails.userWorkSpaceList &&
              this.props.loginDetails.userWorkSpaceList.find(
                (data) => data.workSpaceId === 0
              );
            console.log("N_WORKSPACE_UPDATE", "default");
            sessionStorage.setItem("WORKSPACE", "default");
            this.props.updateLoginDetails(workSpace);
            this.props.needPhotVerification(false);
            // this.props.getWorkSpaceType("Default", 0, 0);
            // this.props.getUserWorkSpaceList();
            console.log("kyc login", workSpace);
            var loginWorkSpace = "default";
            toast.success(
              `${this.props.t(
                "Thank you for your photo verification ,continue to yuukke"
              )}`
            );
            this.props.history.push(`/${loginWorkSpace}/home`);
            // LoadService.redirect(this.props.history, `/${"default"}/home`);
          } else if (response.data.data.validUser == false) {
            this.setState({
              iswomen: false,
              tryCount: this.state.tryCount + 1,
            });
            if (this.state.tryCount < 2) {
              toast.warn("Face didn't recognized properly please retake");
            } else {
              toast.warn("This platform is only for women");
            }
          } else {
            this.setState({
              iswomen: false,
              tryCount: this.state.tryCount + 1,
            });
            if (this.state.tryCount < 2) {
              toast.warn("Face didn't recognized properly please retake");
            } else {
              toast.warn("This platform is only for women");
            }
          }
        })
        .catch((error) => {
          toast.warn("Error submitting ");
        });
    }
  };
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    updateLoginDetails: (workSpace) => dispatch(updateLoginDetails(workSpace)),
    getWorkSpaceType: (type, role, id) =>
      dispatch(getWorkSpaceType(type, role, id)),
    getUserWorkSpaceList: () => dispatch(getUserWorkSpaceList()),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(KYCSetupContainer)));
