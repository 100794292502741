import React, { Component } from "react";
import {
  Card,
  Form,
  Row,
  Container,
  Col,
  Button,
  Modal,
} from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "./mycalendar.css";
import { eventDatas } from "./eventDatas";
import BookMyCalendar from "./BookMyCalendar";

const currentDate = moment(new Date()).format("yyyy-MM-DD");
class MyCalendarContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Subject_list:[],
      userData: [],
      friendsList: [],
      firstName: "",
      lastName: "",
      gender: "",
      biography: "",
      selfUpdate: 0,
      friendUpdate: 0,
      class: "",
      starttime: "09:00",
      endtime: "21:00",
      date: currentDate,
      userId: "",
      createScheduleState: false,
      validated: false,
      singleUserAvailability: {},
      userAvailability: false,
      time: "",
      title: "",
      event: {},
      status: 1,
    };

    this.createScheduleCond = this.createScheduleCond.bind(this);
    this.createSchedule = this.createSchedule.bind(this);
    this.getFormattedDate = this.getFormattedDate.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.submitScheduleNowDetail = this.submitScheduleNowDetail.bind(this);
    this.storeCallScheduleDetails = this.storeCallScheduleDetails.bind(this);
    this.getFormattedDateCondition = this.getFormattedDateCondition.bind(this);
    this.handleUser = this.handleUser.bind(this);
    this.cancelSchedule = this.cancelSchedule.bind(this);
    this.getEventList = this.getEventList.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
  }

  componentDidMount() {
    this.getEventList();

    if (this.props.Subject_list.length > 0) {
      this.setState({ Subject_list: this.props.Subject_list });
       
    }

    // var name=this.props.Subject_list
  }

  render() {
    console.log("events");
    console.log(eventDatas);
    return (
      <div className="screenMainWrapper homeMainWrapper">
        <Row>
          <Col lg={12} className="mainBar">
            {/* <div className="col-md-12">
              <button
                className="archivoBtn"
                style={{ float: "right", margin: "8px" }}
                onClick={this.createScheduleCond}
              >
                Create An Event
              </button>
            </div> */}
            <BookMyCalendar
              deleteEvent={this.deleteEvent}
              events={eventDatas}
              update={this.state.selfUpdate}
              Subject_list={this.state.Subject_list}            
            ></BookMyCalendar>
          </Col>
        </Row>
        {this.state.createScheduleState && this.createSchedule()}
      </div>
    );
  }

  deleteEvent() {
    this.getEventList();
  }
 

  createSchedule() {
    return (
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Modal show="true">
          <Modal.Header>Create Event</Modal.Header>
          <Form
            id="profileForm"
            noValidate
            validated={this.state.validated}
            onSubmit={this.submitScheduleNowDetail}
          >
            <Modal.Body>
              <Row>
                <Col lg={9}>
                  <Form.Group className="formInput">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      onChange={this.handleForm}
                      required
                      type="text"
                      placeholder="Title"
                      value={this.state.title}
                      name="title"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid title
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Color Tag</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleForm}
                      value={this.state.status}
                      name="status"
                    >
                      <option value={1}>Blue</option>
                      <option value={2}>Green</option>
                      <option value={3}>Red</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="formInput">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      onChange={this.handleForm}
                      required
                      type="date"
                      placeholder="Title"
                      value={this.state.date}
                      name="date"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid date
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="formInput">
                    <Form.Label>Start Time</Form.Label>
                    <Form.Control
                      onChange={this.handleForm}
                      required
                      type="time"
                      maxLength="5"
                      placeholder="HH:MM"
                      value={this.state.starttime}
                      name="starttime"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid start time
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="formInput">
                    <Form.Label>End Time</Form.Label>
                    <Form.Control
                      onChange={this.handleForm}
                      required
                      maxLength="5"
                      type="time"
                      placeholder="HH:MM"
                      value={this.state.endtime}
                      name="endtime"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid end time
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Save Event
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }

  getEventList() {
    this.setState({ events: eventDatas });
  }

  handleForm(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  createScheduleCond() {
     
    console.log("TagDatas********")
    console.log(this.state.Subject_list)
    this.setState({ createScheduleState: true });
  }
  handleClose() {
    this.setState({ createScheduleState: false });
  }
  onChangeDate(date) {
    var cdate = this.getFormattedDate(date);
    this.setState({
      date: cdate,
    });
  }
  getFormattedDate(date) {
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const [{ value: month }, , { value: day }, , { value: year }] =
      dateTimeFormat.formatToParts(date);
    let mdate = `${day}/${month}/${year}`;
    console.log(mdate);
    return mdate;
  }

  submitScheduleNowDetail(e) {
    console.log("HII")
    e.preventDefault();
    e.stopPropagation();
    this.setState({ createScheduleState: false });
  }

  async storeCallScheduleDetails() {}

  getFormattedDateCondition(date) {
    var pieces = date.split("/");
    console.log(pieces);
    let mdate = `${pieces[2]}/${pieces[1]}/${pieces[0]}`;
    return mdate;
  }

  cancelSchedule() {
  }

  handleUser(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
}

export default MyCalendarContainer;
