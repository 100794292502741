import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// import AuthenticationService from "../../";
import Axios from "axios";
import { CHAT_URL, JITSI_URL } from "../../config/config";
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Link } from "@material-ui/core";
import moment from "moment";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { yellow } from "@material-ui/core/colors";

import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import AuthService from "../../../../session/AuthService";
import { generateUlr } from "../../../../utils";

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#A00030",
      },
      toolbarBtnSelected: {
        backgroundColor: "#A00030",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        backgroundColor: "#A00030",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        // color:"#A00030"
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: "#A00030",
      },
    },
    MuiPickersClockPointer: {
      thumb: {
        backgroundColor: "#A00030",
        border: "14px solid #A00030",
      },
      pointer: {
        backgroundColor: "#A00030",
      },
      pin: {
        backgroundColor: "#A00030",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#A00030",
      },
      daySelected: {
        backgroundColor: "#A00030",
        color: "#A00030",
      },
      dayDisabled: {
        color: "lightgray",
      },
      current: {
        color: "#A00030",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#A00030",
      },
    },
    MuiButton: {
      label: {
        color: "white",
      },
    },
  },
});

function ScheduleForm({
  t,
  showModalScreen,
  users,
  handleClose,
  handleModalScreenVisibility,
  loginedUserDetails,
  handleSchduledMeeting,
}) {
  const [showMeetingScreen, setShowMeetingScreen] = React.useState(false);
  const [meetingType, setMeetingType] = React.useState("");
  const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [loginedUser, setLoginedUser] = React.useState(null);
  const [meetingLink, setMeetingLink] = React.useState("");
  const [meetingURL, setMeetingURL] = React.useState(null);
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const formRef = React.useRef();

  console.log(showModalScreen);
  console.log(users);
  console.log(meetingType);

  React.useEffect(() => {
    if (!loginedUser) {
      //   console.log(AuthenticationService.getLoginValue());
      //   setLoginedUser(AuthenticationService.getLoginValue());
      setLoginedUser(loginedUserDetails);
    }
  }, []);

  React.useEffect(() => {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }, []);

  const handleSubmit = async () => {
    console.log("Meeting Link", formRef.current.reportValidity());
    if (formRef.current.reportValidity()) {
      try {
        if (loginedUser) {
          //   console.log(name)
          //   console.log(desc)
          //   console.log(selectedDate)

          const instantMeetingLink = `Yuukke-${Math.random() * 100}-${
            loginedUser?.user?.userId
          }-${Date.now()}`;
          //   console.log(instantMeetingLink)
          const data = {
            meetingName: name,
            meetingDescription: desc,
            meetingLink: instantMeetingLink,
            meetingType: "Scheduled",
            meetingTime: selectedDate.toISOString(),
          };
          console.log(data);
          handleModalScreenVisibility(false);
          return handleSchduledMeeting(data);
        }
      } catch (err) {
        console.log("%c ERROR IN POSTING METTING LINK : ", "color:red", err);
      }
    }
  };

  const handleMeeting = (e) => {
    setMeetingType(e.target.value);
  };

  const handlecloseModal = (e) => {
    e.preventDefault();
    handleModalScreenVisibility(false);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleDesc = (e) => {
    setDesc(e.target.value);
  };

  return (
    <>
      <Dialog
        fullWidth="md"
        open={showModalScreen}
        onClose={handleClose}
        disableBackdropClick
      >
        {!showMeetingScreen ? (
          <>
            <DialogTitle>{t("Create Meeting")}</DialogTitle>
            <DialogContent>
              <form ref={formRef}>
                <TextField
                  required
                  onChange={(e) => setName(e.target.value)}
                  defaultValue=""
                  fullWidth={true}
                  id="meetingName"
                  type="text"
                  label={t("Meeting Name")}
                  variant="filled"
                />
                <TextField
                  onChange={(e) => setDesc(e.target.value)}
                  fullWidth={true}
                  style={{ marginTop: 20 }}
                  multiline
                  rows={2}
                  id="meetingDesc"
                  variant="filled"
                  type="text"
                  label={t("Meeting Description")}
                />
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      value={selectedDate}
                      // defaultValue={currentDate}
                      disablePast
                      // onChange={handleDateChange}
                      inputVariant="filled"
                      fullWidth={true}
                      onChange={handleDateChange}
                      label="Meeting Time"
                      showTodayButton
                      style={{ marginTop: 16, color: "white" }}
                    />
                    {/* <TextField
                      id="date"
                      label="Meeting Date"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth={true}
                      variant="filled"
                      style={{marginTop:16}}
                      // value={this.state.date}
                      name="datetime"
                      required

                      onChange={ e => {
                        // console.log(moment(e.target.value).add(330, "m"));
                        handleDateChange(new Date(e.target.value).toISOString())    
                      }}
                      
                      // value={selectedDate}
                    /> */}

                    {/* <TextField
                      id="tie"
                      label="Meeting Time"
                      type="time"

                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth={true}
                      variant="filled"
                      style={{marginTop:16}}
                      // value={this.state.date}
                      name="date"
                      // onChange={this.handleForm}
                      // defaultValue={currentDate}
                    /> */}
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </form>
            </DialogContent>
            <DialogActions>
              <Button style={{ color: "white" }} onClick={handleClose}>
                Cancel
              </Button>
              <Button style={{ color: "white" }} onClick={handleSubmit}>
                Create Meeting
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            {/* <DialogTitle>JOIN MEETING</DialogTitle> */}
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "4rem 0rem",
                }}
              >
                {meetingType === "instant" ? (
                  <>
                    <p>Join Meeting By clicking the Below Button</p>
                    <Button variant="contained" style={{ marginTop: "2rem" }}>
                      <a
                        onClick={() =>
                          generateUlr(
                            `${JITSI_URL}${meetingURL}?authenticated=true&sp=yes`
                          )
                        }
                        // href={`${JITSI_URL}${meetingURL}#config`}
                        target="_blank"
                      >
                        Join Meeting
                      </a>
                    </Button>
                  </>
                ) : (
                  <>
                    <p>view the Meeting Details by clicking the below Button</p>
                    <Button variant="contained" style={{ marginTop: "2rem" }}>
                      <Link href="/meeting-details">View Meeting Details</Link>
                    </Button>
                  </>
                )}
                <Button
                  style={{ marginTop: "2rem", width: "100%", color: "white" }}
                  variant="contained"
                  onClick={handleClose}
                >
                  close
                </Button>
              </div>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
}

export default withTranslation()(ScheduleForm);
