import React, { Component } from "react";
import { Card, CardContent, Container } from "@material-ui/core";

export default class PageConstruction extends Component {
  render() {
    return (
      <div className="tabWrapper">
        <div className="tabs">
          <Container>

          </Container>
        </div>
        <Container>
          <div>

            <Card>
              <CardContent>
                <center>
                  <h2>{this.props.name}</h2>
                  <img width="300" src="https://cdn.dribbble.com/users/50886/screenshots/4939619/dribbble.gif" />
                  <br />
                  <h4>Page under construction</h4>
                </center>
              </CardContent>
            </Card>
          </div>
        </Container>
      </div>
    );
  }
}