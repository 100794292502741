import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { API_URL } from "../../config/app-config";
import dummyimage from "../../img/default_avatar.jpg";
import FriendConnectionCard from "./FriendConnectionCard";
import {
  getConnectionFriendList,
  updateConnectionFriendList,
} from "../../redux/actions/PostCardActions";
import InfiniteScroll from "react-infinite-scroll-component";

class FriendConnections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      friendsList: [],
      pageNo: 0,
    };
    this.loadFriendsList = this.loadFriendsList.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    console.log("fl 1");
    this.props.getConnectionFriendList(false, 0, this.props.userId);
    // this.setState({ friendsList: this.props.friendsList });
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.friendsList !== this.props.friendsList) {
    //   console.log("fl 2");
    //   console.log(this.props.friendsList);
    //   this.setState({ friendsList: this.props.friendsList });
    // }
    if (this.props.userId !== prevProps.userId) {
      console.log("Update - list");
      this.props.getConnectionFriendList(false, 0, this.props.userId);
    }
  }
  seeMoreFriends = () => {
    console.log("See more", this.props.activePage);
    this.props.getConnectionFriendList(
      true,
      this.props.activePage + 1,
      this.props.userId
    );
  };
  render() {
    const { t } = this.props;

    return (
      <>
        {/* <div style={{ marginBottom: "20px" }} >
                    <div className="aboutFriendWrapper" style={{ padding: "12px" }} >
                       <div style={{display:"inline-flex"}}>
                       <p style={{ fontWeight: "bold", fontSize: "16px" }}>Connections </p>
                           <p className="redLabel">{this.state.friendsList.length}</p>
     
                       </div>                  
                         <Grid container spacing={0}>
                            {this.state.friendsList &&
                                this.state.friendsList.length > 0 &&
                                this.state.friendsList.map((friend, index) => (
                                    <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                            
                                        <FriendConnectionCard
                                        userId = {friend.userId}
                                        displayName = {friend.userProfile.displayName}

                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </div>

                </div> */}
        <div style={{ marginTop: "1.5rem" }}>
          <div class="">
            <div class="exp-card">
              <div class="exp-card-header d-sm-flex justify-content-between align-items-center border-0">
                <h5 class="about-card-title">
                  {t("Friends")}
                  <span class="redLabel">{this.props.totalFriends}</span>
                </h5>
                {/* <a class="btn btn-primary-soft btn-sm" href="#!"> See all friends</a> */}
              </div>
              <div
                style={{ minHeight: "auto", maxHeight: "350" }}
                class="friend-connection-card-body friend-connection-position-relative friend-connection-pt-0"
              >
                {/* <div class="connection-side-row g-3"> */}
                <InfiniteScroll
                  dataLength={this.props.friendsList.length}
                  next={this.seeMoreFriends}
                  hasMore={this.props.friendsSeeMorePage}
                  scrollableTarget="friends_list"
                  direction="down"
                  scrollThreshold={0.95}
                >
                  <div
                    id="friends_list"
                    // onScroll={this.handleScroll}
                    style={{
                      // minHeight: "auto",
                      maxHeight: "1000px",
                      // overflowY: "auto",
                      overflowX: "hidden",
                      // margin: "0px 22px 25px",
                    }}
                  >
                    {
                      <div className="connection-grid-container">
                        {this.props.friendsList &&
                          this.props.friendsList.length > 0 &&
                          this.props.friendsList.map((friend, index) => (
                            <div className="" key={index}>
                              <FriendConnectionCard
                                userId={friend.userId}
                                type={friend.type}
                                displayName={friend.userName}
                                professionId={friend.professionValue}
                                profilePhoto={friend.profilePhoto}
                                loadFriendsList={this.loadFriendsList}
                                updateFriendsListStatus={
                                  this.updateFriendsListStatus
                                }
                                premiumUser={friend.premiumData}
                                removeFriend={this.removeFriend}
                              />
                            </div>
                          ))}
                        {this.props.friendsList?.length === 0 &&
                          !this.props.friendsSeeMorePage && (
                            <p
                              style={{
                                color: "#000000",
                                padding: "8px",
                                cursor: "pointer",
                              }}
                            >
                              {t("Friends not found")}
                            </p>
                          )}
                      </div>
                    }
                  </div>
                </InfiniteScroll>
                {/* {this.state.friendsList &&
                    this.state.friendsList.length > 0 &&
                    this.state.friendsList.map((friend, index) => (
                      <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                        <FriendConnectionCard
                          userId={friend.userId}
                          type={friend.type}
                          displayName={friend.userProfile.displayName}
                          professionId={friend.userProfile.professionId}
                          profilePhoto={friend.profilePhoto}
                          loadFriendsList={this.loadFriendsList}
                          updateFriendsListStatus={this.updateFriendsListStatus}
                        />
                      </Grid>
                    ))} */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  updateFriendsListStatus = (id) => {
    // var data = this.state.friendsList;
    var data = {
      id: id,
      type: "requestedFriends",
    };
    this.props.updateConnectionFriendList(data, false);
    // data.forEach((element) => {
    //   if (element.userId === id) {
    //     element.type = "requestedFriends";
    //   }
    // });
    // this.setState({
    //   friendsList: data,
    // });
  };
  removeFriend = (id) => {
    var data = {
      id: id,
      type: "removeFriend",
    };
    this.props.updateConnectionFriendList(data, true);
  };
  loadFriendsList(id) {
    // this.props.loadFriendsList()
    let friendsList = this.state.friendsList;
    friendsList = this.state.friendsList.filter((item) => item.userId !== id);
    this.setState({
      friendsList: friendsList,
    });
  }
}

const mapStateToProps = function (state) {
  return {
    activePage: state.postList.activePage,
    friendsList: state.postList.friendsList,
    friendsSeeMorePage: state.postList.friendsSeeMorePage,
    totalFriends: state.postList.totalFriends,
    friendUpdateState: state.postList.friendUpdateState,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    updateConnectionFriendList: (data, deleteData) =>
      dispatch(updateConnectionFriendList(data, deleteData)),
    getConnectionFriendList: (pagination, page, userId) =>
      dispatch(getConnectionFriendList(pagination, page, userId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(FriendConnections)));
