import {
  GET_ALL_SUBJECT_lIST,
  GET_COMMUNITY_MEMBER_ID,
  GET_LOGOUT_DATA,
  GET_RESOURCE_LIST_V2,
  GET_USER_COMMUNITY_LIST,
  MENTOR_DETAILS,
  MENTOR_LIST_LOADER,
  RESOURCE_POST_LOADER,
  TREADING_USER_LIST,
} from "../types";
import {
  GET_ALL_SUBJECT_lIST_BY_GROUPS,
  GET_ALL_SUBJECT_lIST_BY_EVENTS,
  GET_MY_GROUPS_BY_MESSAGES_AND_MEMBERS,
  GET_MY_EVENTS_AND_GROUPS,
  GET_PAST_EVENTS,
  GET_SUBJECT_REQUESTS_LIST,
  GET_All_MEMBER_LIST,
  GET_ALL_GROUPS_BY_MEMBER_COUNT,
  GET_CHALLENGES_AND_RESOURCES,
  GET_RESOURCE_POST_BY_DOCUMENT,
  GET_RESOURCE_POST_BY_IMAGE,
  GET_RESOURCE_POST_BY_VIDEO,
  GET_CHALLENGES_APPLY_LIST,
  GET_CHALLENGE_LIST,
  GET_RESOURCE_LIST,
  GET_MY_GROUPS_HOME,
  GET_SEARCH_CHALLENGE_AND_GROUPS,
  GET_ALL_TYPE_OF_COMMUNITY,
  GET_REQUESTS_PLAY_LIST,
  GET_REQUESTS_PLAY_LIST_DATA,
  GET_MY_COMMUNITY_LIST,
  GET_COMMUNITY_TAG_LIST,
  GROUP_LOADER,
  GET_ALL_MENTOR_LIST,
  GET_RESOURCE_POST_TAGS,
  GET_RESOURCE_POST,
  GET_COMMUNITY_LIST_LOADER,
  RESOURCE_MEDIA_FILES,
  UPDATE_MEMBER_LIST,
  SINGLE_RESOURCE_POST,
  GROUP_REQUEST_LIST,
} from "../types";
import { SUBJECT_LOADER } from "../types";
const initialState = {
  list: [],
  subLoading: true,
  getChallengePage: 0,
  myeventsandgroupsPage: 0,
  list2: [],
  pastevents: [],
  myeventsandgroups: [],
  subjectRequestsList: [],
  allMembersList: [],
  groupsBasedMembers: [],
  getAllChallegnesandResourcesList: [],
  getResourcePostbyImage: [],
  getResourcePostbyVideo: [],
  getResourcePostbyDoc: [],
  getChallengeAppliedList: [],
  resourceImagetotalItems: 0,
  resourceVideototalItems: 0,
  resourceDoctotalItems: 0,
  challengesAppliedtotalItems: 0,
  myGroupsBasedMessageMembers: [],
  getChallengeLists: [],
  getResourceLists: [],
  listPage: 0,
  getResourceListsPage: 0,
  myGroupHome: [],
  searchList: [],
  searchListPage: 0,
  numberOfElements: 0,
  allCommunityTypeList: [],
  resourcePlayList: [],
  resourcePlayListData: [],
  resourcePlayListDataPage: 0,
  resourceloading: false,
  myCommunityList: [],
  communityLoading: false,
  communityTotalItems: 0,
  communityTags: [],
  groupsSeeMore: true,
  trendingGroupLoader: false,
  mentorList: [],
  resourceTagList: [],
  resourcePosts: [],
  resourcePostTotalCount: 0,
  groupListSeeMore: false,
  mentorSeeMore: false,
  mentorLoader: false,
  mentorDetails: {},
  resourceMediaFiles: [],
  resourceMediaSeeMore: false,
  resourcePost: {},
  resourcePostLoader: false,
  resourcePostError: false,
  communityStatusType: [],
  groupRequestList: [],
  groupRequestSeeMore: false,
  totalRequest: 0,
  totalCommunity: 0,
  totalResource: 0,
  connectionSeeMore: false,
  totalCommunityUser: 0,
  resourceList: [],
  resourceSeeMore: false,
  totalResourceV2: 0,
  userOwnedCommunity: [],
  communityMemberId: [],
  treadingUsers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SUBJECT_lIST_BY_GROUPS:
      console.log("GET_ALL_SUBJECT_lIST");
      console.log(action.payload);
      return {
        ...state,
        list: action.payload,
        listPage: action.page,
        loading: false,
      };
    case GET_SEARCH_CHALLENGE_AND_GROUPS:
      console.log("GET_SEARCH_CHALLENGE_AND_GROUPS", action.payload);
      console.log(action.payload);
      return {
        ...state,
        searchList: action.payload,
        searchListPage: action.page,
        numberOfElements: action.NumberOfElements,
        loading: false,
        totalResource: action.totalElements,
      };
    case GET_ALL_TYPE_OF_COMMUNITY:
      console.log("GET_ALL_TYPE_OF_COMMUNITY");
      console.log(action.payload);
      return {
        ...state,
        allCommunityTypeList: action.payload.typeOfCommunity,
        communityStatusType: action.payload.communityStatus,
        loading: false,
      };
    case GET_ALL_GROUPS_BY_MEMBER_COUNT:
      console.log("GET_ALL_GROUPS_BY_MEMBER_COUNT", action.payload);
      if (action.pageNo !== 0) {
        console.log("GET_ALL_GROUPS_BY_MEMBER_COUNT concat");
        var oldGroupList = state.groupsBasedMembers;
        var newList = oldGroupList.concat(action.payload.list);
        return {
          ...state,
          groupsBasedMembers: newList ? newList : state.groupsBasedMembers,
          groupsSeeMore: action.seeMoreStatus,
          trendingGroupLoader: false,
        };
      } else {
        return {
          ...state,
          groupsBasedMembers: action.statusError
            ? state.groupsBasedMembers
            : action.payload.list,
          groupsSeeMore: action.seeMoreStatus,
          trendingGroupLoader: false,
        };
      }

    case GET_MY_GROUPS_HOME:
      console.log("GET_MY_GROUPS_HOME");
      console.log(action.payload);
      return {
        ...state,
        myGroupHome: action.payload,
        loading: false,
      };
    case GET_SUBJECT_REQUESTS_LIST:
      console.log("GET_SUBJECT_REQUESTS_LIST");
      console.log(action.payload);
      return {
        ...state,
        subjectRequestsList: action.payload,
        loading: false,
      };
    case GET_All_MEMBER_LIST:
      console.log("GET_All_MEMBER_LIST", action.payload);
      if (action.pagination) {
        const data = state.allMembersList.concat(action.payload.user);

        return {
          ...state,
          allMembersList: data ? data : state.allMembersList,
          loading: false,
          connectionSeeMore:
            action.payload.totalPages - 1 > action.pageNo ? true : false,
        };
      } else {
        return {
          ...state,
          allMembersList: action.payload.user ? action.payload.user : [],
          loading: false,
          totalCommunityUser: +action.payload.totalElements,
          connectionSeeMore:
            action.payload.totalPages - 1 > action.pageNo ? true : false,
        };
      }

    case UPDATE_MEMBER_LIST:
      console.log("UPDATE_MEMBER_LIST", state.allMembersList);

      if (action.payload.status === "remove") {
        let old = state.allMembersList.filter(
          (item) => item.userId !== action.payload.id
        );

        return {
          ...state,
          allMembersList: old ? old : state.allMembersList,
          loading: false,
        };
      } else {
        let old = state.allMembersList.map((item) => {
          if (item.userId === action.payload.id) {
            item.userStatus = item.userStatus === "admin" ? "user" : "admin";
          }
          return item;
        });

        return {
          ...state,
          allMembersList: old ? old : state.allMembersList,
          loading: false,
        };
      }

    case GET_ALL_SUBJECT_lIST_BY_EVENTS:
      console.log("GET_ALL_SUBJECT_lIST_BY_EVENTS");
      console.log(action.payload);
      return {
        ...state,
        list2: action.payload,
        loading: false,
      };
    case GET_MY_EVENTS_AND_GROUPS:
      console.log("GET_MY_EVENTS_AND_GROUPS");
      console.log(action.payload);
      return {
        ...state,
        myeventsandgroups: action.payload,
        myeventsandgroupsPage: action.page,
        loading: false,
      };
    case GET_PAST_EVENTS:
      console.log("GET_PAST_EVENTS");
      console.log(action.payload);
      return {
        ...state,
        pastevents: action.payload,
        loading: false,
      };
    case GET_MY_GROUPS_BY_MESSAGES_AND_MEMBERS:
      console.log("GET_ALL_SUBJECT_lIST_BY_EVENTS");
      console.log(action.payload);
      return {
        ...state,
        myGroupsBasedMessageMembers: action.payload,
        loading: false,
      };
    case GET_CHALLENGES_AND_RESOURCES:
      console.log("GET_CHALLENGES_AND_RESOURCES");
      console.log(action.payload);
      return {
        ...state,
        getAllChallegnesandResourcesList: action.payload,
        loading: false,
      };
    case GET_CHALLENGE_LIST:
      console.log("GET_CHALLENGES_AND_RESOURCES");
      console.log(action.payload);
      return {
        ...state,
        getChallengeLists: action.payload,
        getChallengePage: action.page,
        loading: false,
      };
    case GET_RESOURCE_LIST:
      console.log("GET_CHALLENGES_AND_RESOURCES");
      console.log(action.payload);
      return {
        ...state,
        getResourceLists: action.payload,
        getResourceListsPage: action.page,
        loading: false,
      };
    case GET_RESOURCE_POST_BY_IMAGE:
      console.log("GET_RESOURCE_POST_BY_IMAGE");
      console.log(action.payload);
      return {
        ...state,
        getResourcePostbyImage: action.payload,
        resourceImagetotalItems: action.totalItems,
        loading: false,
      };
    case GET_RESOURCE_POST_BY_VIDEO:
      console.log("GET_RESOURCE_POST_BY_VIDEO");
      console.log(action.payload);
      return {
        ...state,
        getResourcePostbyVideo: action.payload,
        resourceVideototalItems: action.totalItems,
        loading: false,
      };
    case GET_RESOURCE_POST_BY_DOCUMENT:
      console.log("GET_RESOURCE_POST_BY_DOCUMENT");
      console.log(action.payload);
      return {
        ...state,
        getResourcePostbyDoc: action.payload,
        resourceDoctotalItems: action.totalItems,
        loading: false,
      };
    case GET_CHALLENGES_APPLY_LIST:
      console.log("GET_CHALLENGES_APPLY_LIST");
      console.log(action.payload);
      return {
        ...state,
        getChallengeAppliedList: action.payload,
        challengesAppliedtotalItems: action.totalItems,
        loading: false,
      };
    case SUBJECT_LOADER:
      console.log("SUBJECT_LOADER");
      console.log(action.payload);
      return {
        ...state,
        subLoading: action.payload,
      };
    case GET_REQUESTS_PLAY_LIST:
      console.log("GET_REQUESTS_PLAY_LIST", action.payload);
      console.log(action.payload);
      return {
        ...state,
        resourcePlayListPage: action.page,
        resourcePlayList: action.payload,
        loading: false,
      };
    case GET_REQUESTS_PLAY_LIST_DATA:
      console.log("GET_REQUESTS_PLAY_LIST_DATA", action.payload);
      console.log(action.payload);
      return {
        ...state,
        resourcePlayListDataPage: action.totalPages,
        resourcePlayListData: action.payload.content,
        resourceloading: false,
      };

    case GET_MY_COMMUNITY_LIST:
      console.log("GET_MY_COMMUNITY_LIST", action.payload);
      console.log(action.payload);
      if (action.pagination && action.payload.community) {
        // let oldList=state.myCommunityList
        var newList = state.myCommunityList.concat(action.payload.community);
        return {
          ...state,
          myCommunityList: newList ? newList : state.myCommunityList,
          communityTotalItems: action.payload.totalPages,
          communityLoading: false,
          groupListSeeMore:
            action.payload.totalPages - 1 > action.page ? true : false,
          totalCommunity: action.payload.totalItems,
        };
      } else {
        return {
          ...state,
          myCommunityList: action.payload.community,
          communityTotalItems: action.payload.totalPages,
          communityLoading: false,
          groupListSeeMore:
            action.payload.totalPages - 1 > action.page ? true : false,
          totalCommunity: action.payload.totalItems,
        };
      }

    case GET_COMMUNITY_TAG_LIST:
      console.log("GET_COMMUNITY_TAG_LIST", action.payload);
      console.log(action.payload);
      return {
        ...state,
        communityTags: action.payload,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");
      console.log(initialState.list);

    case GROUP_LOADER:
      console.log("GROUP_LOADER", action.payload);
      return {
        ...state,
        trendingGroupLoader: action.payload,
      };
    // case MENTOR_LIST_LOADER:
    //   return {
    //     ...state,
    //     mentorLoader: true,
    //   };
    // case GET_ALL_MENTOR_LIST:
    //   console.log("GET_ALL_MENTOR_LIST", action.payload);
    //   if (action.pagination) {
    //     let oldList = state.mentorList.concat(action.payload.mentorList);
    //     return {
    //       ...state,
    //       mentorLoader: false,
    //       mentorList: oldList ? oldList : state.mentorList,
    //       mentorSeeMore:
    //         action.payload.totalPages - 1 > action.page ? true : false,
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       mentorLoader: false,
    //       mentorList: action.payload.mentorList
    //         ? action.payload.mentorList
    //         : [],
    //       mentorSeeMore:
    //         action.payload.totalPages - 1 > action.page ? true : false,
    //     };
    //   }

    case GET_RESOURCE_POST_TAGS:
      console.log("GET_RESOURCE_POST_TAGS", action.payload);
      return {
        ...state,
        resourceTagList: action.payload,
      };
    case GET_RESOURCE_POST:
      console.log("GET_RESOURCE_POST", action.payload);
      return {
        ...state,
        resourcePosts: action.payload,
        resourcePostLoader: false,
        resourcePostTotalCount: action.totalItems,
      };

    case RESOURCE_POST_LOADER:
      console.log("RESOURCE_POST_LOADER");
      return {
        ...state,
        resourcePostLoader: true,
      };

    case GET_COMMUNITY_LIST_LOADER:
      console.log("GET_COMMUNITY_LIST_LOADER");
      return {
        ...state,
        communityLoading: true,
      };

    case MENTOR_DETAILS:
      console.log("MENTOR_DETAILS", action.payload);
      return {
        ...state,
        mentorDetails: action.payload.mentorList,
      };

    case RESOURCE_MEDIA_FILES:
      console.log("RESOURCE_MEDIA_FILES", action);
      console.log(action.payload);
      if (action.pagination) {
        var oldPostList = state.resourceMediaFiles;
        return {
          ...state,
          resourceMediaFiles: oldPostList.concat(
            action.payload.mediaFiles ? action.payload.mediaFiles : []
          ),
          resourceMediaSeeMore:
            action.payload.totalPages - 1 > action.pageNo ? true : false,
        };
      } else {
        return {
          ...state,
          resourceMediaFiles: action.payload.mediaFiles
            ? action.payload.mediaFiles
            : [],
          resourceMediaSeeMore:
            action.payload.totalPages - 1 > action.pageNo ? true : false,
        };
      }

    case GROUP_REQUEST_LIST:
      console.log("GROUP_REQUEST_LIST", action);
      if (action.pagination) {
        var oldPostList = state.groupRequestList;

        return {
          ...state,
          groupRequestList: oldPostList.concat(
            action.payload.list ? action.payload.list : []
          ),
          groupRequestSeeMore:
            action.payload.totalPages - 1 > action.pageNo ? true : false,
          totalRequest: action.payload.totalElements,
        };
      } else {
        return {
          ...state,
          groupRequestList: action.payload.list ? action.payload.list : [],
          groupRequestSeeMore:
            action.payload.totalPages - 1 > action.pageNo ? true : false,
          totalRequest: action.payload.totalElements,
        };
      }

    case SINGLE_RESOURCE_POST:
      console.log("SINGLE_RESOURCE_POST", action.payload);
      return {
        ...state,
        resourcePost: action.payload,
        resourcePostLoader: false,
        resourcePostError: action.payload?.resourcePostId ? false : true,
      };
    case GET_USER_COMMUNITY_LIST:
      console.log("GET_USER_COMMUNITY_LIST", action.payload);
      return {
        ...state,
        userOwnedCommunity: action.payload,
      };
    case GET_COMMUNITY_MEMBER_ID:
      console.log("GET_COMMUNITY_MEMBER_ID", action.payload);
      return {
        ...state,
        communityMemberId: action.payload,
      };
    case GET_RESOURCE_LIST_V2:
      console.log("GET_RESOURCE_LIST_V2", action.payload);
      if (action.pagination) {
        let list = state.resourceList.concat(action.payload.list);
        return {
          ...state,
          subLoading: false,

          resourceList: list ? list : state.resourceList,
          resourceSeeMore:
            action.payload.TotalPages - 1 > action.pageNo ? true : false,
        };
      } else {
        return {
          ...state,
          subLoading: false,
          resourceList: action.payload.list,
          totalResourceV2: action.payload.TotalPages,
          resourceSeeMore:
            action.payload.TotalPages - 1 > action.pageNo ? true : false,
          totalResource: action.payload.TotalElements,
        };
      }
    case TREADING_USER_LIST:
      return {
        ...state,
        treadingUsers: action.payload,
        trendingGroupLoader: false,
      };

    default:
      return state;
  }
}
