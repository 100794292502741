import ProfileService from "../../api/ProfileService";
import MyCourseService from "../../api/MyCourseService";
import {
  GET_ALL_COMMON_SUBJECTS,
  GET_ALL_USER_COMMUNITY_LIST,
  GET_ALL_COUNTRY_PHONE_CODE,
  WORK_SPACE_LOGIN,
  WORK_SPACE_WRONG_URL,
  GET_TOUR_STATUS,
  SAVE_TOUR_STATUS,
  AFTER_TRIGGER_POPUP,
  ANNOUNCEMENT_DETAILS,
  HASH_TAG_LIST,
  NEED_RENDER_COMPONENT,
  GET_REQUIREMENT_STATUS,
  NEED_PHOTO_VERIFICATION,
  GET_SUB_SPECIALIZATION,
  GET_SUB_INDUSTRY,
  GET_FRIENDS_LIST_BASED_WORKSPACE,
} from "../types";
import { GET_ALL_COMMON_LANGUAGE } from "../types";
import { GET_ALL_COMMON_PROFESSION } from "../types";
import { GET_ALL_COMMON_TAGS } from "../types";
import { GET_ALL_COMMON_CURRICULUM_TRACK } from "../types";
import { GET_ALL_COMMON_SCHOOL } from "../types";
import {
  GET_ALL_COMMON_FRIENDS_LIST,
  GET_ALL_BLOCKED_USER_LIST,
  GET_USER_WORKSPACE_LIST,
} from "../types";
import { GET_REGION_LIST } from "../types";
import { GET_INDUSTRY_LIST } from "../types";
import { GET_AREA_OF_INTEREST_LIST } from "../types";
import {
  GET_ALL_COMMON_ALL_PROOF,
  GET_ALL_COMMON_CHAT_FRIENDS_LIST,
  GET_ALL_COMMON_EXPECTATION_HELP,
  GET_ALL_COMMON_EXPECTATION_INTEREST,
  NAVIGATION_TYPE,
  GET_USER_POINTS,
  HOME_IMAGE_STATUS,
  WORK_SPACE_TYPE,
  GET_ADMIN_CATEGORY_LIST,
  TRIGGER_TOUR_POPUP,
  GET_TAG_USER_LIST,
  GET_WORKSPACE_OPTIONS,
  UPDATE_WORKSPACE_LOGO,
} from "../types";
import FindFriendsFilter from "../../api/FindFriendsFilter";
import FriendsFinder from "../../api/FriendsFinder";
import PostService from "../../api/PostService";
import MyYuukke from "../../api/MyYuukke";
import HomeService from "../../api/HomeService";
// import AuthService from "../../session/AuthService";
//import { GET_ALL_COMMON_GRADES } from "../types";

export const getAllCommonSubjectList =
  (key, personalLanguage) => async (dispatch) => {
    try {
      if (key) {
        //    alert(personalLanguage)
        var langs = {
          lang: personalLanguage,
        };
        ProfileService.getallSubjects(langs)
          .then((response) => {
            var result = response.data;
            // AuthService.storeSubjectsList(result)

            dispatch({
              type: GET_ALL_COMMON_SUBJECTS,
              payload: result,
            });
          })
          .catch((error) => {
            // console.log(error)
            dispatch({
              type: GET_ALL_COMMON_SUBJECTS,
              payload: [],
            });
          });
      } else {
        dispatch({
          type: GET_ALL_COMMON_SUBJECTS,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_COMMON_SUBJECTS,
        payload: [],
      });
    }
    return [];
  };

export const getAllCommonLanguageList = () => async (dispatch) => {
  try {
    ProfileService.getallLanguage()
      .then((response) => {
        let result = response.data.data;
        if (Array.isArray(result)) {
          result = result.map((item, i) => {
            return { label: item.languageName, value: item.languageId };
          });
        }
        console.log("result", result);
        dispatch({
          type: GET_ALL_COMMON_LANGUAGE,
          payload: result,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: GET_ALL_COMMON_LANGUAGE,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_COMMON_LANGUAGE,
      payload: [],
    });
  }
  return [];
};

export const getAllUserCommunityList = () => async (dispatch) => {
  try {
    var data = {};
    MyCourseService.getAllUserCommunityList(data)
      .then((response) => {
        var result = response.data;
        dispatch({
          type: GET_ALL_USER_COMMUNITY_LIST,
          payload: result,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: GET_ALL_USER_COMMUNITY_LIST,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_USER_COMMUNITY_LIST,
      payload: [],
    });
  }
  return [];
};

export const getAllCommonTagsList = () => async (dispatch) => {
  try {
    MyCourseService.getAllTags()
      .then((response) => {
        var result = response.data;
        dispatch({
          type: GET_ALL_COMMON_TAGS,
          payload: result,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: GET_ALL_COMMON_TAGS,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_COMMON_TAGS,
      payload: [],
    });
  }
  return [];
};

export const getAllCommonCurriculumTrackList =
  (key, personalLanguage) => async (dispatch) => {
    try {
      if (key) {
        var langs = {
          lang: personalLanguage,
        };
        ProfileService.getTrackAndCurriculumData(langs)
          .then((response) => {
            var result = response.data;
            //AuthService.storeCurriculumTrackList(result.data)
            dispatch({
              type: GET_ALL_COMMON_CURRICULUM_TRACK,
              payload: result.data,
            });
          })
          .catch((error) => {
            // console.log(error)
            dispatch({
              type: GET_ALL_COMMON_CURRICULUM_TRACK,
              payload: [],
            });
          });
      } else {
        dispatch({
          type: GET_ALL_COMMON_CURRICULUM_TRACK,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_COMMON_CURRICULUM_TRACK,
        payload: [],
      });
    }
    return [];
  };

export const getAllCommonSchoolList = () => async (dispatch) => {
  try {
    FindFriendsFilter.getAllSchool()
      .then((response) => {
        var result = response.data;
        dispatch({
          type: GET_ALL_COMMON_SCHOOL,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_COMMON_SCHOOL,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_COMMON_SCHOOL,
      payload: [],
    });
  }
  return [];
};

export const getAllCommonFriendsList = () => async (dispatch) => {
  try {
    let getFriendsList = {};
    FriendsFinder.getFriendsList(getFriendsList)
      .then((response) => {
        var result = response.data;
        dispatch({
          type: GET_ALL_COMMON_FRIENDS_LIST,
          payload: result.data.friendsList,
        });
      })

      .catch((error) => {
        dispatch({
          type: GET_ALL_COMMON_FRIENDS_LIST,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_COMMON_FRIENDS_LIST,
      payload: [],
    });
  }
  return [];
};

export const getAllChatFriendsList = () => async (dispatch) => {
  try {
    let getFriendsList = {};
    FriendsFinder.getAllFriends()
      .then((response) => {
        var result = response.data;
        console.log("Get All Common  Chat Friends List", response.data?.data);
        dispatch({
          type: GET_ALL_COMMON_CHAT_FRIENDS_LIST,
          payload: result.data ?? [],
        });
      })

      .catch((error) => {
        dispatch({
          type: GET_ALL_COMMON_CHAT_FRIENDS_LIST,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_COMMON_CHAT_FRIENDS_LIST,
      payload: [],
    });
  }
  return [];
};

export const getAllCommonIndustry = () => async (dispatch) => {
  try {
    ProfileService.getAllIndustry()
      .then((response) => {
        var result = response.data;
        //AuthService.storeGradeList(result.data.grade)
        dispatch({
          type: GET_INDUSTRY_LIST,
          payload: result,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: GET_INDUSTRY_LIST,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_INDUSTRY_LIST,
      payload: [],
    });
  }
  return [];
};

export const getAllCommonRegion = () => async (dispatch) => {
  try {
    ProfileService.getAllRegion()
      .then((response) => {
        var result = response.data;
        //AuthService.storeGradeList(result.data.grade)
        dispatch({
          type: GET_REGION_LIST,
          payload: result,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: GET_REGION_LIST,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_REGION_LIST,
      payload: [],
    });
  }
  return [];
};

export const getAllAreaOfInterest = () => async (dispatch) => {
  try {
    let data = {
      data: "",
    };
    ProfileService.getAreaOfInterest(data)
      .then((response) => {
        var result = response.data.data.user;
        //AuthService.storeGradeList(result.data.grade)
        dispatch({
          type: GET_AREA_OF_INTEREST_LIST,
          payload: result,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: GET_AREA_OF_INTEREST_LIST,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_AREA_OF_INTEREST_LIST,
      payload: [],
    });
  }
  return [];
};

export const getAllCommonProfessionList = () => async (dispatch) => {
  try {
    var profile = {};

    ProfileService.getProfessionDetails(profile)
      .then((response) => {
        let result = response.data.data;
        // result.push({
        //   professionId: 0,
        //   professionName: "Others",
        // });

        dispatch({
          type: GET_ALL_COMMON_PROFESSION,
          payload: result,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: GET_ALL_COMMON_PROFESSION,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_COMMON_PROFESSION,
      payload: [],
    });
  }
  return [];
};

export const getAllCommonExpectationHelpList = () => async (dispatch) => {
  try {
    var profile = { "": "" };

    ProfileService.getExpectationsHelpDetails(profile)
      .then((response) => {
        var result = response.data.data;

        dispatch({
          type: GET_ALL_COMMON_EXPECTATION_HELP,
          payload: result,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: GET_ALL_COMMON_EXPECTATION_HELP,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_COMMON_EXPECTATION_HELP,
      payload: [],
    });
  }
  return [];
};

export const getAllCommonExpectationInterestList = () => async (dispatch) => {
  try {
    var profile = { "": "" };

    ProfileService.getExpectationsInterestDetails(profile)
      .then((response) => {
        var result = response.data.data;

        dispatch({
          type: GET_ALL_COMMON_EXPECTATION_INTEREST,
          payload: result,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: GET_ALL_COMMON_EXPECTATION_INTEREST,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_COMMON_EXPECTATION_INTEREST,
      payload: [],
    });
  }
  return [];
};

export const getAllProofType = () => async (dispatch) => {
  try {
    var profile = {};

    ProfileService.getAllProofType(profile)
      .then((response) => {
        var result = response.data.data;

        dispatch({
          type: GET_ALL_COMMON_ALL_PROOF,
          payload: result,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: GET_ALL_COMMON_ALL_PROOF,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_COMMON_ALL_PROOF,
      payload: [],
    });
  }
  return [];
};
// export const getAllCommonCurriculumTrackList = () => async dispatch => {
//     try {

//         ProfileService.getTrackAndCurriculumData()
//             .then(
//                 response => {
//                     var result = response.data;
//                     //AuthService.storeCurriculumTrackList(result.data)
//                     dispatch({
//                         type: GET_ALL_COMMON_CURRICULUM_TRACK,
//                         payload: result.data
//                     })
//                 }
//             )
//             .catch(error => {
//                 // console.log(error)
//                 dispatch({
//                     type: GET_ALL_COMMON_CURRICULUM_TRACK,
//                     payload: [],
//                 })
//             }
//             )

//     }
//     catch (error) {
//         dispatch({
//             type: GET_ALL_COMMON_CURRICULUM_TRACK,
//             payload: [],
//         })
//     }
//     return [];
// }

export const getNavigationType = (type) => async (dispatch) => {
  console.log("type123 ", type);
  if (type == 0) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "home",
    });
  } else if (type == 1) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "connections",
    });
  } else if (type == 2) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "groups",
    });
  } else if (type == 3) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "contest",
    });
  } else if (type == 4) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "marketPlace",
    });
  } else if (type == 6) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "common",
    });
  } else if (type == 5) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "mentor",
    });
  } else if (type == 9) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "mentorMeeting",
    });
  } else if (type == 14) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "calender",
    });
  } else if (type == 7) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "contest-result",
    });
  } else if (type == 8) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "ourOfferings",
    });
  } else if (type == 10) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "help",
    });
  } else if (type == 13) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "catalystTalksPage",
    });
  } else if (type == 15) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "bizFeaturesPage",
    });
  } else if (type == 16) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "futureEntrepreneursPage",
    });
  } else if (type == 12) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "seller_reg",
    });
  } else if (type == 11) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "mentor_reg",
    });
  } else if (type == 17) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "user_list",
    });
  } else if (type == 18) {
    dispatch({
      type: NAVIGATION_TYPE,
      payload: "no_match",
    });
  } else if (type == null) {
    dispatch({ type: NAVIGATION_TYPE, payload: "" });
  }
};

export const getAllUserPoints = () => async (dispatch) => {
  try {
    var langs = {};
    ProfileService.getAllUserPoints(langs)
      .then((response) => {
        var result = response.data;
        // AuthService.storeSubjectsList(result)

        dispatch({
          type: GET_USER_POINTS,
          payload: result.data.TotalPoints,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: GET_USER_POINTS,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_USER_POINTS,
      payload: [],
    });
  }
  return [];
};

export const getAllBlockedUserList = () => async (dispatch) => {
  try {
    let getFriendsList = {};
    FriendsFinder.getUserBLockList(getFriendsList)
      .then((response) => {
        var result = response.data.data.blockList;
        dispatch({
          type: GET_ALL_BLOCKED_USER_LIST,
          payload: result,
        });
      })

      .catch((error) => {
        dispatch({
          type: GET_ALL_BLOCKED_USER_LIST,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_BLOCKED_USER_LIST,
      payload: [],
    });
  }
  return [];
};

export const getUserWorkSpaceList = () => async (dispatch) => {
  try {
    let getFriendsList = {};
    FriendsFinder.getUserWorkSpaceList(getFriendsList)
      .then((response) => {
        var result = response.data.data.workSpace;
        dispatch({
          type: GET_USER_WORKSPACE_LIST,
          payload: result,
        });
      })

      .catch((error) => {
        dispatch({
          type: GET_USER_WORKSPACE_LIST,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_USER_WORKSPACE_LIST,
      payload: [],
    });
  }
  return [];
};
export const getWorkSpaceType = (type, role, userId) => async (dispatch) => {
  let data = {
    type: type,
    role: role,
    userId: userId,
  };
  dispatch({
    type: WORK_SPACE_TYPE,
    payload: data,
  });
};
export const getFundingImageState = (type) => async (dispatch) => {
  dispatch({
    type: HOME_IMAGE_STATUS,
    payload: type,
  });
};

export const getAdminCategoryList = () => async (dispatch) => {
  try {
    let getFriendsList = {};
    FriendsFinder.admin_category_list()
      .then((response) => {
        var result = response.data.data.resource.data;
        dispatch({
          type: GET_ADMIN_CATEGORY_LIST,
          payload: result,
        });
      })

      .catch((error) => {
        dispatch({
          type: GET_ADMIN_CATEGORY_LIST,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ADMIN_CATEGORY_LIST,
      payload: [],
    });
  }
  return [];
};

export const getAllCountryName = () => async (dispatch) => {
  try {
    ProfileService.getAllCountryPhoneCode()
      .then((response) => {
        var result = response.data.data;

        dispatch({
          type: GET_ALL_COUNTRY_PHONE_CODE,
          payload: result,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: GET_ALL_COUNTRY_PHONE_CODE,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: GET_ALL_COUNTRY_PHONE_CODE,
      payload: [],
    });
  }
  return [];
};

export const workSpaceLoginStats = (type) => async (dispatch) => {
  dispatch({
    type: WORK_SPACE_LOGIN,
    payload: type,
  });
};

export const workSpaceWrongUrl = (urlStatus, url) => async (dispatch) => {
  dispatch({
    type: WORK_SPACE_WRONG_URL,
    payload: urlStatus,
    pathUrl: url,
  });
};

export const getTourStatus = () => async (dispatch) => {
  try {
    ProfileService.getTourStatus()
      .then((response) => {
        let status = response.data.data.userTour;
        dispatch({
          type: GET_TOUR_STATUS,
          payload: status ? status : null,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOUR_STATUS,
          payload: null,
        });
        console.error(error);
      });
  } catch (error) {
    dispatch({
      type: GET_TOUR_STATUS,
      payload: null,
    });
    console.error(error);
  }
};

export const saveTourStatus = (data) => async (dispatch) => {
  let stages = {
    tourDetails: data,
  };
  console.log("stages", stages);
  try {
    ProfileService.saveUserFirstTimeLoginTour(stages)
      .then((response) => {
        let status = response.data.data;
        dispatch({
          type: SAVE_TOUR_STATUS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SAVE_TOUR_STATUS,
          payload: data,
        });
        console.error(error);
      });
  } catch (error) {
    dispatch({
      type: SAVE_TOUR_STATUS,
      payload: data,
    });
    console.error(error);
  }
};

export const getAnnouncementList = (data) => async (dispatch) => {
  try {
    PostService.getAnnouncementonlyOpen(data)
      .then((response) => {
        dispatch({
          type: ANNOUNCEMENT_DETAILS,
          payload: response.data.data.announcement,
        });
      })
      .catch((error) => {
        dispatch({
          type: ANNOUNCEMENT_DETAILS,
          payload: [],
        });
      });
  } catch (error) {
    dispatch({
      type: ANNOUNCEMENT_DETAILS,
      payload: [],
    });
  }
};
export const triggerTourPopup = (data) => async (dispatch) => {
  dispatch({
    type: TRIGGER_TOUR_POPUP,
    payload: data,
  });
};

export const ofterTriggerPopup = (data) => async (dispatch) => {
  dispatch({
    type: AFTER_TRIGGER_POPUP,
    payload: data,
  });
};

export const getPostTagList = (type, id) => async (dispatch) => {
  try {
    var data = {};
    if (type == "groups") {
      data = { type: type, subjectId: id };
    } else {
      data = { type: "friends" };
    }
    PostService.getUserByMentionType(data)
      .then((response) => {
        dispatch({
          type: GET_TAG_USER_LIST,
          payload: response.data.data.mentionUsers,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TAG_USER_LIST,
          payload: [],
        });
      });
  } catch (e) {
    dispatch({
      type: GET_TAG_USER_LIST,
      payload: [],
    });
  }
};

export const getWorkSpaceOptionTypes = () => async (dispatch) => {
  try {
    PostService.getWorkSpaceOptionTypes()
      .then((response) => {
        dispatch({
          type: GET_WORKSPACE_OPTIONS,
          payload: response.data.data.workspace,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_WORKSPACE_OPTIONS,
          payload: [],
        });
      });
  } catch (e) {
    dispatch({
      type: GET_WORKSPACE_OPTIONS,
      payload: [],
    });
  }
};

export const updateWorkSpaceOptionTypes =
  (formData, config) => async (dispatch) => {
    try {
      MyYuukke.update_work_space_details(formData, config)
        .then((response) => {
          dispatch({
            type: GET_WORKSPACE_OPTIONS,
            payload: response.data.data.workspace,
          });
          dispatch({
            type: UPDATE_WORKSPACE_LOGO,
            payload: response.data.data.workspace?.logo,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_WORKSPACE_OPTIONS,
            payload: [],
          });
        });
    } catch (e) {
      dispatch({
        type: GET_WORKSPACE_OPTIONS,
        payload: [],
      });
    }
  };

export const getTagListBasedOnHasTag = (value) => async (dispatch) => {
  try {
    var searchUsers = {
      searchKey: value,
    };
    HomeService.getHashtagList(searchUsers)
      .then((response) => {
        var result = response.data.data.tagList;
        if (result) {
          const filterList =
            result &&
            result.map((items) => {
              return { value: items.tagId, label: items.tagName };
            });
          dispatch({
            type: HASH_TAG_LIST,
            payload: filterList ? filterList : [],
          });
        } else {
          dispatch({
            type: HASH_TAG_LIST,
            payload: [],
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: HASH_TAG_LIST,
          payload: [],
        });
      });
  } catch (e) {
    dispatch({
      type: HASH_TAG_LIST,
      payload: [],
    });
  }
};
export const needRenderComponent = (data) => async (dispatch) => {
  dispatch({
    type: NEED_RENDER_COMPONENT,
    payload: data,
  });
};

export const getApplicationRequirements = () => async (dispatch) => {
  try {
    ProfileService.getApplicationRequirements()
      .then((response) => {
        let status = response.data.data;
        dispatch({
          type: GET_REQUIREMENT_STATUS,
          payload: status,
          needUpdate: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_REQUIREMENT_STATUS,
          payload: null,
          needUpdate: false,
        });
        console.error(error);
      });
  } catch (error) {
    dispatch({
      type: GET_REQUIREMENT_STATUS,
      payload: null,
      needUpdate: false,
    });
    console.error(error);
  }
};

export const sellerRequest = (number) => async (dispatch) => {
  // try {
  let data = {
    mobileNumber: number,
  };
  ProfileService.sellerRequest(data)
    .then((response) => {
      dispatch({
        type: GET_REQUIREMENT_STATUS,
        payload: true,
        needUpdate: true,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_REQUIREMENT_STATUS,
        payload: false,
        needUpdate: true,
      });
      console.error(error);
    });
  // } catch (error) {
  //   dispatch({
  //     type: GET_REQUIREMENT_STATUS,
  //     payload: false,
  //     needUpdate: true,
  //   });
  //   console.error(error);
  // }
};

export const needPhotVerification = (data) => async (dispatch) => {
  dispatch({
    type: NEED_PHOTO_VERIFICATION,
    payload: data,
  });
};

export const reportAction = (data) => async (dispatch) => {
  ProfileService.reportAction(data)
    .then((response) => {
      console.log("response", response);
    })
    .catch((err) => {
      console.log("er", err);
    });
  console.log("Report-Action", data);
};

export const subSpecialization = () => async (dispatch) => {
  ProfileService.subSpecialization()
    .then((response) => {
      const getList = response.data.data.subSpecialisation.map((item) => {
        return { name: item.specialisationName, id: item.id };
      });
      console.log("response", getList);

      dispatch({
        type: GET_SUB_SPECIALIZATION,
        payload: getList,
      });
    })
    .catch((err) => {
      console.log("er", err);
    });
};

export const subIndustry = () => async (dispatch) => {
  ProfileService.subIndustry()
    .then((response) => {
      const getList = response.data.data.subIndustry.map((item) => {
        return { name: item.industryName, id: item.id };
      });
      console.log("response", getList);
      dispatch({
        type: GET_SUB_INDUSTRY,
        payload: getList,
      });
    })
    .catch((err) => {
      console.log("er", err);
    });
};

export const getFriendsListBasedWorkSpace = () => async (dispatch) => {
  ProfileService.getFriendsListBasedWorkSpace()
    .then((response) => {
      dispatch({
        type: GET_FRIENDS_LIST_BASED_WORKSPACE,
        payload: response.data.data.users,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_FRIENDS_LIST_BASED_WORKSPACE,
        payload: [],
      });
    });
};
