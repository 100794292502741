import React from "react";

const SubscriptionCard = ({ data, index, selectedTeam }) => {
  return (
    <section
      key={index}
      className="cal-plans p-3 c-pointer d-flex flex-column"
      style={{ width: "16rem" }}
    >
      <h5 className="m-0 font-weight-bold mb-3">{data.subscriptionName}</h5>
      <p
        className="text-secondary f-12 mt-2 mb-2"
        style={{ minHeight: "3rem" }}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: data.subscribeDescription.replace(/<p><\/p>/g, "<br/>"),
          }}
        />
      </p>
      {data.prize !== 0 && (
        <h6 className="font-weight-bold mt-auto pt-2 pb-3">
          Prize : {data.prize}
        </h6>
      )}
      <p
        className={`btn2 w-100 text-center ${
          data.prize === 0 ? "mt-auto" : ""
        }`}
        onClick={() => selectedTeam(data.prize, data.id)}
      >
        Get started
      </p>
    </section>
  );
};
export default SubscriptionCard;
