import authReducer from "./authReducer";
import messengerReducer from "./messengerReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  user: authReducer,
  messenger: messengerReducer,
});

export default rootReducer;
