import React, { useEffect, useState } from "react";
import CheckBox from "./CheckBox";
import ImagePreview from "./ImagePreview";
import StepperFileOption from "../screens/Wizard/StepperFileOption";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { WeeklyCalendar } from "../screens/FirstTimeProfile/WeeklyCalendar";
import MyYuukke from "../api/MyYuukke";
import BlockUi from "react-block-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryListV2,
  getSubscriptionStatus,
  getUserSlots,
} from "../redux/actions/calendarAction";
import { toast } from "react-toastify";
import { urlPatternValidation } from "../utils";
import SubscriptionCard from "../screens/PremiumBadges/SubscriptionCard";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { IoMdArrowRoundBack } from "react-icons/io";

function CalendarPlans({
  plansList,
  weeklyHours,
  modiFyTimeSlot,
  activeMinutes = 15,
  updateTimeSlots,
  calendarId,
  slotDetails,
  homeSlotState,
  paymentModel,
  subscriptionList,
  isSubscribed,
  message,
  closeModel,
  freeSubscribeCompleted,
  planSelected,
  updateSlotDetails,
  t,
}) {
  const [state, setState] = useState({
    activeMenu: 0,
    type: null,
    meetingInYuukke: false,
    paymentNeed: false,
    meetingLink: "",
    bookingCount: true,
    profilePhoto: true,
    freeCalendar: true,
    meetingPrize: "",
    businessLogo: null,
    emailReminder: true,
    whatsappReminder: false,
    apiLogo: false,
    editBusinessLogo: null,
    loader: false,
    paymentPage: false,
    subscribeId: 0,
    prize: 0,
    paymentStatus: "",
    subscriptionId: null,
    serviceName: "",
    serviceImage: "",
    editServiceLogo: null,
    apiGalleryImages: [],
    removedId: [],
    selectedGalleryImage: [],
    serviceApiLogo: false,
    category: "",
    serviceType: "",
    packageName: "",
    successPercent: 35,
    menuState: 0,
    serviceList: [],
    otherServiceName: "",
  });

  const dispatch = useDispatch();
  const { loginDetails } = useSelector((state) => state.loginDetailsApi);
  const { categoryList, calendarServices } = useSelector(
    (state) => state.event
  );
  const [allServiceList, setAllServiceList] = useState([]);
  // const [allServiceList, setAllServiceList] = useState([
  //   {
  //     value: "one_to_one_consultation",
  //     label: "One-to-One Consultation",
  //     prize: 0,
  //     select: false,
  //   },
  //   {
  //     value: "primary_discussion",
  //     label: "Primary Discussion",
  //     prize: 0,
  //     select: false,
  //   },
  //   {
  //     value: "follow_up_session",
  //     label: "Follow-Up Session",
  //     prize: 0,
  //     select: false,
  //   },
  //   { value: "package", label: "Package", prize: 0, select: false },
  //   { value: "others", label: "Others", prize: 0, select: false },
  // ]);
  useEffect(() => {
    const list = calendarServices.map((item) => {
      item.value = item.name;
      item.amount = 0;
      item.select = false;
      item.label = item.name;
      return item;
    });
    setAllServiceList(list);
  }, [calendarServices]);
  useEffect(() => {
    if (isSubscribed) {
      setState((previous) => ({
        ...previous,
        activeMenu: 1,
      }));
    }
  }, [isSubscribed]);

  useEffect(() => {
    if (slotDetails) {
      console.log("slotDetails", slotDetails);
      let categoryData = "";
      let serviceType = "";

      if (slotDetails?.categoryId) {
        console.log("categoryList", categoryList);
        const data = categoryList.find(
          (item) => item.value === slotDetails?.categoryId
        );
        console.log("categoryData", data);

        if (data) {
          categoryData = data;
        }
      }
      if (slotDetails?.serviceType) {
        const data = allServiceList.find(
          (item) => item.value === slotDetails?.serviceType
        );
        console.log("categoryData", data);

        if (data) {
          serviceType = data;
        }
      }

      setState((previous) => ({
        ...previous,
        meetingInYuukke:
          slotDetails.meetingInApplication === null
            ? true
            : slotDetails.meetingInApplication,
        meetingLink:
          slotDetails.meetingUrl === null ? "" : slotDetails.meetingUrl,
        bookingCount:
          slotDetails.showBookingCount === null
            ? true
            : slotDetails.showBookingCount,
        profilePhoto:
          slotDetails.showProfileImage === null
            ? true
            : slotDetails.showProfileImage,
        freeCalendar: slotDetails.noFees === null ? "" : slotDetails.noFees,
        meetingPrize: slotDetails.fees === null ? "" : slotDetails.fees,
        editBusinessLogo: slotDetails.businessLogo,
        apiLogo: true,
        editServiceLogo: slotDetails.serviceImage,
        apiGalleryImages: slotDetails.serviceGalleryImages
          ? slotDetails.serviceGalleryImages
          : [],
        serviceApiLogo: true,
        serviceName: slotDetails.serviceName,
        category: categoryData,
        packageName: slotDetails.packageName,
        serviceType: serviceType,
      }));
    }
  }, [slotDetails]);

  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const updateCalendarSlot = (i, type, date, t_i) => {
    updateTimeSlots(i, type, date, t_i);
  };
  const updateCalendarTimeSlot = (type, i, t_i) => {
    modiFyTimeSlot(type, i, t_i);
  };
  const updatePreferenceDetails = (meetingLink) => {
    const formData = new FormData();
    formData.append("meetingInApplication", state.meetingInYuukke);
    formData.append("showBookingCount", state.bookingCount);
    formData.append("showProfileImage", state.profilePhoto);
    formData.append(
      "meetingActiveDuration",
      homeSlotState.activeMinutes?.value
        ? homeSlotState.activeMinutes.value
        : 15
    );
    formData.append("noFees", state.freeCalendar);
    formData.append("categoryId", state.category.value);
    if (!state.freeCalendar) {
      formData.append("fees", state.meetingPrize);
      if (state.serviceType) {
        formData.append("serviceType", state.serviceType.value);

        formData.append(
          "packageName",
          state.serviceType?.value === "package" ? state.packageName : ""
        );
      }
    } else {
      formData.append("fees", 0);
    }
    if (state.businessLogo) {
      formData.append("file", state.businessLogo);
    }
    formData.append("serviceName", state.serviceName);

    if (state.serviceImage) {
      formData.append("serviceImage", state.serviceImage);
    }
    formData.append("shareService", true);
    if (meetingLink) {
      formData.append("meetingUrl", meetingLink);
    } else {
      formData.append("meetingUrl", "");
    }

    if (state.selectedGalleryImage.length > 0) {
      for (let i = 0; i < state.selectedGalleryImage.length; i++) {
        formData.append("serviceGalleryImage", state.selectedGalleryImage[i]);
      }
    }
    if (state.removedId.length > 0) {
      formData.append("removedGalleryImageId", state.removedId);
    }
    const list = [
      {
        serviceTypeId: 3,
        amount: 0,
        duration: 15,
        serviceTitle: "Title One",
      },
      {
        serviceTypeId: 4,
        amount: 0,
        duration: 5,
        serviceTitle: "Title Two",
      },
    ];
    let dv2 = {
      list: list,
    };
    formData.append("userServiceList", JSON.stringify(dv2));
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    MyYuukke.updatePreference(formData, config)
      .then((response) => {
        console.log("pref", response.data.data);
        console.log("slotDetails", slotDetails);

        // if (!homeSlotState?.calendarId) {
        // if (!slotDetails) {
        //   console.log("default-slot entry");
        //   let data = {
        //     weeklyHours: homeSlotState.weeklyHours,
        //     meetingActiveDuration: homeSlotState.activeMinutes.value,
        //   };

        //   MyYuukke.saveUserAvailability(data)
        //     .then((response) => {
        //       console.log("response", response);
        //       updateState("loader", false);
        //       toast.success("Calendar updated successfully !");
        if (state.subscriptionId !== null) {
          // Free subscription
          savePaymentInfo(state.subscriptionId);
        } else {
          dispatch(getUserSlots());
          dispatch(getSubscriptionStatus("calender"));

          closeModel();
        }
        //     })
        //     .catch((e) => {
        //       console.log("update error", e);
        //       updateState("loader", false);
        //     });
        // } else {
        //   updateSlotDetails(response.data.data);
        // }

        // dispatch(saveUserSlot(response.data.data));

        // console.log("res", response);
      })
      .catch((err) => {
        toast.error("Some thing went wrong,please try latter");
        console.log("e", err);
      });
  };
  // const saveCalendar = (link) => {
  //   const today = new Date();
  //   const oneYearLater = new Date(today);
  //   oneYearLater.setFullYear(today.getFullYear() + 1);
  //   let data = {
  //     meetingInApplication: state.meetingInYuukke,
  //     taskName: "Calendar meeting schedule",
  //     taskDescription: `Available meeting slot`,
  //     startDate: today,
  //     endDate: oneYearLater,
  //     subjectId: 0,
  //     taskType: "slot_booking",
  //     startTime: "",
  //     endTime: "",
  //     color: "#A00030",
  //     moderatorList: [loginDetails.uId],
  //     publicEvent: false,
  //     emailConfigure: false,
  //     meetingType: 0,
  //     url: link,
  //     slotBookingId: slotDetails.id,
  //   };

  //   ForumService.saveCalendarV3(data)
  //     .then((response) => {
  //       console.log("resp", response);

  //       updatePreferenceDetails(link);
  //     })
  //     .catch((e) => {
  //       console.log("e", e);
  //       toast.error("Something went wrong !");
  //       updateState("loader", false);
  //     });
  // };
  const updatePreference = () => {
    if (!urlPatternValidation(state.meetingLink) && !state.meetingInYuukke) {
      toast.warning("Enter valid url");
    } else if (!state.freeCalendar && !state.meetingPrize) {
      toast.warning("Enter your slot prize");
    } else if (!state.serviceImage && !state.editServiceLogo) {
      toast.warning("Add service image");
    } else if (!state.serviceName) {
      toast.warning("Enter service name");
    } else if (
      state.apiGalleryImages.length === 0 &&
      state.selectedGalleryImage.length === 0
    ) {
      toast.warning("Add your gallery images");
    } else if (!state.category) {
      toast.warning("Select your service related category");
    } else {
      updateState("loader", true);
      updatePreferenceDetails(state.meetingLink);
    }
  };
  const savePaymentInfo = (id) => {
    let data = {
      subscriptionId: id,
    };

    MyYuukke.addUserSubscription(data)
      .then((res) => {
        console.log("response", res);
        closeModel();
        dispatch(getUserSlots());
        dispatch(getSubscriptionStatus("calender"));
      })
      .catch((e) => {
        closeModel();
      });
  };
  const openPaymentPage = (prize, id) => {
    // if (!slotDetails && !state.isSubscribed && prize === 0) {
    //   savePaymentInfo(id);
    // } else
    if (!prize || state.isSubscribed) {
      setState((previous) => ({
        ...previous,
        activeMenu: 1,
        subscriptionId: id,
      }));
    } else {
      paymentModel(prize, id);
      // setState((previous) => ({
      //   ...previous,
      //   prize: prize,
      //   subscribeId: id,
      //   paymentPage: true,
      // }));
    }
  };
  const removeApiGalleryImage = (index, type) => {
    if (type === "apiImage") {
      const list = state.apiGalleryImages.splice(index, 1);
      console.log("list", list);
      updateState("removedId", state.removedId.concat(list));
    } else {
      const list = state.selectedGalleryImage.splice(index, 1);
      console.log("list", list);
      setState((previous) => ({
        ...previous,
      }));
    }
  };
  const updateServiceDetails = (i, amount) => {
    state.serviceList[i].amount = amount;
    console.log("data", state.serviceList);
    setState((previous) => ({
      ...previous,
    }));
  };

  const movePage = (e, type) => {
    e.preventDefault();
    if (type === "serviceType" && state.serviceList.length === 0) {
      toast.warning("Select your service type");
      return;
    }
    setState((previous) => ({
      ...previous,
      menuState: state.menuState + 1,
      successPercent: type === "serviceType" ? 60 : state.successPercent + 25,
    }));
  };
  const backPage = (percent) => {
    setState((previous) => ({
      ...previous,
      menuState: state.menuState - 1,
      successPercent: percent,
    }));
  };
  const addSelectedService = (data) => {
    const listExists = state.serviceList.some(
      (item) => item.value === data.value
    );
    const updatedList = listExists
      ? state.serviceList.filter((item) => item.value !== data.value)
      : [...state.serviceList, data];
    console.log("updatedList", updatedList);
    updateState("serviceList", updatedList);
    serviceBackGround(data);
  };
  const serviceBackGround = (data) => {
    const details = allServiceList.map((item) => {
      if (item.value === data.value) {
        item.select = !item.select;
      }
      return item;
    });
    setAllServiceList(details);
  };
  console.log("plansList", state);
  return (
    <>
      <BlockUi
        tag="div"
        message="Loading, please wait"
        style={{ minHeight: "134px" }}
        className="wallLoadingPage"
        blocking={state.loader}
      >
        <div
          className="card box-shadow  flex-column d-flex align-items-center justify-content-center"
          style={{ maxWidth: "31rem" }}
        >
          <section className="d-flex w-100 p-4 align-items-center  justify-content-center g-1">
            <h5 className="font-weight-bold m-0 ml-auto mr-auto">
              {state.activeMenu === 1
                ? t("UPDATE YOUR SLOT PROFILE SETTING")
                : state.activeMenu === 2
                ? t("UPDATE YOUR AVAILABLE TIME SLOTS")
                : t("PICK THE PERFECT PLAN FOR YOUR TEAM")}
              {/* {message && isSubscribed && (
                <span className="badge badge-info">{message}</span>
              )} */}
            </h5>

            {state.activeMenu !== 0 && !isSubscribed && (
              <FaArrowLeft
                className="ml-auto c-pointer text-secondary"
                onClick={() => updateState("activeMenu", state.activeMenu - 1)}
              />
            )}
          </section>
          <div
            className="w-100"
            style={{
              height: "8px",
              background: `linear-gradient(to right, #07d410 ${
                state.successPercent
              }%, #808080cc ${state.successPercent - 100}%)`,
              borderRadius: "7px",
            }}
          ></div>

          {/* {state.activeMenu === 0 && (!slotDetails || !isSubscribed) ? ( */}
          {state.activeMenu === 0 && (!isSubscribed || !planSelected) ? (
            <div className="d-flex flex-wrap g-1 justify-content-center p-4">
              {subscriptionList.map((item, index) => (
                <SubscriptionCard
                  data={item}
                  index={index}
                  selectedTeam={openPaymentPage}
                />
                // <section
                //   onClick={() => openPaymentPage(item.prize, item.id)}
                //   key={index}
                //   className="cal-plans p-3 c-pointer d-flex flex-column"
                //   style={{ width: "16rem" }}
                // >
                //   <h5 className="m-0 font-weight-bold mb-3">
                //     {item.subscriptionName}
                //   </h5>
                //   <p
                //     className="text-secondary f-12 mt-2 mb-2"
                //     style={{ minHeight: "3rem" }}
                //   >
                //     {/* {item.subscribeDescription} */}
                //     <p
                //       // className="about-mb-2"
                //       dangerouslySetInnerHTML={{
                //         __html: item.subscribeDescription.replace(
                //           /<p><\/p>/g,
                //           "<br/>"
                //         ),
                //       }}
                //     />
                //   </p>
                //   <h6 className="font-weight-bold mt-auto pt-2 pb-3">
                //     Prize : {item.prize}
                //   </h6>
                //   <p className="btn2 w-100 text-center  ">Get started</p>
                // </section>
              ))}
              {subscriptionList.length === 0 && <p>Options not available</p>}
            </div>
          ) : state.activeMenu === 2 ? (
            <div className="p-4">
              <WeeklyCalendar
                weeklyHours={weeklyHours}
                modiFyTimeSlot={updateCalendarTimeSlot}
                activeMinutes={activeMinutes}
                updateTimeSlots={updateCalendarSlot}
              />
            </div>
          ) : (
            <div className="askAQuestionInputGroup cal-label p-4">
              {state.menuState === 1 ? (
                <div className="d-flex flex-column">
                  <p
                    className="c-pointer ml-auto text-secondary"
                    onClick={() => backPage(30)}
                  >
                    <IoMdArrowRoundBack className="mr-2 svg-icons text-secondary" />{" "}
                    Back
                  </p>
                  {/* <label className="formLabel mt-3">
                    {t("Service charge or not ?")} *
                  </label>
                  <label className="toggle-label">
                    <input
                      type="checkbox"
                      value={!state.freeCalendar}
                      defaultChecked={!state.freeCalendar}
                      checked={!state.freeCalendar}
                      onChange={(e) =>
                        updateState("freeCalendar", !state.freeCalendar)
                      }
                    />
                    <span
                      className={
                        !state.freeCalendar
                          ? "toggle-v2-active"
                          : "toggle-v2-un-active"
                      }
                    />
                  </label><label className="formLabel mt-3">
                    {t("Service charge or not ?")} *
                  </label>
                  <label className="toggle-label">
                    <input
                      type="checkbox"
                      value={!state.freeCalendar}
                      defaultChecked={!state.freeCalendar}
                      checked={!state.freeCalendar}
                      onChange={(e) =>
                        updateState("freeCalendar", !state.freeCalendar)
                      }
                    />
                    <span
                      className={
                        !state.freeCalendar
                          ? "toggle-v2-active"
                          : "toggle-v2-un-active"
                      }
                    />
                  </label> */}
                  <CheckBox
                    value={state.bookingCount}
                    label={`Need to show your booking slot's count in slot profile`}
                    onChange={(e) => updateState("bookingCount", e)}
                    questionMark={true}
                  />
                  <CheckBox
                    value={state.profilePhoto}
                    label="Need to show your profile photo in slot profile"
                    onChange={(e) => updateState("profilePhoto", e)}
                    questionMark={true}
                  />
                  {/* <CheckBox
          value={state.emailReminder}
          label="Need email Reminder"
          onChange={(e) => updateState("emailReminder", e)}
          questionMark={true}
        />
        <CheckBox
          value={state.whatsappReminder}
          label="Need whatsapp reminder"
          onChange={(e) => updateState("whatsappReminder", e)}
          questionMark={true}
        /> */}
                  <CheckBox
                    value={state.meetingInYuukke}
                    label="Meeting in Yuukke"
                    onChange={(e) => updateState("meetingInYuukke", e)}
                    questionMark={true}
                  />
                  {!state.meetingInYuukke && (
                    <>
                      <label className="formLabel mt-3">
                        {t("Add Meeting Link")} *
                      </label>

                      <input
                        type="text"
                        name="meetingLink"
                        placeholder="http://googlemeet.com/"
                        value={state.meetingLink}
                        onChange={(e) =>
                          updateState("meetingLink", e.target.value)
                        }
                      />
                    </>
                  )}
                  <button
                    onClick={(e) => movePage(e)}
                    type="submit"
                    className="w-100 font-weight-bold mt-3 text-white"
                  >
                    Next <FaArrowRight className="  ml-3" />
                  </button>
                </div>
              ) : state.menuState === 2 ? (
                <div className="d-flex flex-column">
                  <p
                    className="c-pointer ml-auto text-secondary"
                    onClick={() => backPage(60)}
                  >
                    <IoMdArrowRoundBack className="mr-2 svg-icons text-secondary" />{" "}
                    Back
                  </p>
                  <label className="formLabel mt-3">
                    {t("Service Image")} *
                  </label>

                  {state.editServiceLogo || state.serviceImage ? (
                    <ImagePreview
                      apiLogo={state.serviceApiLogo}
                      file={
                        state.serviceApiLogo
                          ? state.editServiceLogo
                          : state.serviceImage
                      }
                      removeFiles={() => {
                        setState((previous) => ({
                          ...previous,
                          editServiceLogo: null,
                          serviceImage: null,
                        }));
                      }}
                    />
                  ) : (
                    <StepperFileOption
                      onUploadSuccess={(file) => {
                        setState((previous) => ({
                          ...previous,
                          serviceImage: file[0],
                          serviceApiLogo: false,
                        }));
                      }}
                      acceptedType=".jpg,.jpeg,.png,.svg"
                    />
                  )}

                  <label className="formLabel mt-3">
                    {t(
                      "Service Gallery Images (You can select multiple images)"
                    )}{" "}
                    *
                  </label>
                  <StepperFileOption
                    onUploadSuccess={(file) => {
                      setState((previous) => ({
                        ...previous,
                        selectedGalleryImage: [
                          ...state.selectedGalleryImage,
                          ...file,
                        ],
                      }));
                    }}
                    acceptedType=".jpg,.jpeg,.png,.svg"
                  />
                  <div className="d-flex flex-wrap g-1">
                    {state.apiGalleryImages &&
                      state.apiGalleryImages.map((item, i) => (
                        <ImagePreview
                          key={i}
                          apiLogo={true}
                          file={item}
                          removeFiles={(index) =>
                            removeApiGalleryImage(index, "apiImage")
                          }
                        />
                      ))}
                    {state.selectedGalleryImage &&
                      state.selectedGalleryImage.map((item, i) => (
                        <ImagePreview
                          key={i}
                          apiLogo={false}
                          file={item}
                          removeFiles={(index) =>
                            removeApiGalleryImage(index, "selectedImage")
                          }
                        />
                      ))}
                  </div>
                  <label className="formLabel mt-3">{t("Business Logo")}</label>

                  {state.editBusinessLogo || state.businessLogo ? (
                    <ImagePreview
                      apiLogo={state.apiLogo}
                      file={
                        state.apiLogo
                          ? state.editBusinessLogo
                          : state.businessLogo
                      }
                      removeFiles={() => {
                        setState((previous) => ({
                          ...previous,
                          editBusinessLogo: null,
                          businessLogo: null,
                        }));
                      }}
                    />
                  ) : (
                    <StepperFileOption
                      onUploadSuccess={(file) => {
                        setState((previous) => ({
                          ...previous,
                          businessLogo: file[0],
                          apiLogo: false,
                        }));
                      }}
                      acceptedType=".jpg,.jpeg,.png,.svg"
                    />
                  )}

                  <button
                    className="w-100 font-weight-bold mt-3"
                    onClick={() => updatePreference()}
                    // onClick={() => updateState("activeMenu", 2)}
                  >
                    {t("UPDATE YOUR PREFERENCE")}
                  </button>
                </div>
              ) : (
                <form onSubmit={(e) => movePage(e, "serviceType")}>
                  <label className="formLabel mt-3">
                    {t("Service Name")} *
                  </label>
                  <input
                    style={{ height: "50px" }}
                    type="text"
                    name="serviceName"
                    placeholder="Mentoring"
                    value={state.serviceName}
                    onChange={(e) => updateState("serviceName", e.target.value)}
                    required
                  />

                  <label className="formLabel mt-3">
                    {t("Service Category")} *
                  </label>
                  <Select
                    options={categoryList ? categoryList : []}
                    value={state.category}
                    onChange={(e) => {
                      updateState("category", e);
                    }}
                    required
                  />
                  <label className="formLabel mt-3">
                    {t("Service Type")} *
                  </label>
                  <ul className="l-none d-flex flex-wrap g-1 align-items-center">
                    {allServiceList.map((item, i) => (
                      <li
                        style={{ background: item.select ? "gainsboro" : "" }}
                        className="small-box"
                        key={i}
                        onClick={() => addSelectedService(item)}
                      >
                        {item.label}
                      </li>
                    ))}
                  </ul>
                  {/* <Select
                    menuPlacement="top"
                    options={allServiceList}
                    value={state.serviceList}
                    isMulti
                    onChange={(e) => {
                      updateState("serviceList", e);
                    }}
                    required
                  /> */}
                  {state.serviceList.map((item, i) => (
                    <>
                      {item.value === "Others" ? (
                        <div key={i}>
                          <label className="formLabel mt-3">
                            Enter your Service Type Name (Others) *
                          </label>

                          <input
                            style={{ height: "50px", background: "#607d8b1c" }}
                            type="text"
                            placeholder="Teaching"
                            value={state.otherServiceName}
                            onChange={(e) =>
                              updateState("otherServiceName", e.target.value)
                            }
                            required
                          />
                        </div>
                      ) : item.value === "Package" ? (
                        <div key={i}>
                          <label className="formLabel mt-3">
                            Package Name *
                          </label>

                          <input
                            style={{ height: "50px", background: "#607d8b1c" }}
                            type="text"
                            placeholder="Monthly Package"
                            value={state.packageName}
                            onChange={(e) =>
                              updateState("packageName", e.target.value)
                            }
                            required
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                  {state.serviceList.map((item, i) => (
                    <div key={i}>
                      <label className="formLabel mt-3">
                        {item.label} Service Prize ₹
                      </label>

                      <input
                        style={{ height: "50px", background: "#607d8b1c" }}
                        type="number"
                        placeholder="0"
                        value={item.amount}
                        onChange={(e) =>
                          updateServiceDetails(i, e.target.value)
                        }
                      />
                    </div>
                  ))}
                  {/* {state.serviceType?.value === "package" && (
                    <>
                      <label className="formLabel mt-3">
                        {t("Package Name")} *
                      </label>

                      <input
                        type="text"
                        name="Service Type"
                        placeholder="Monthly package"
                        value={state.packageName}
                        onChange={(e) =>
                          updateState("packageName", e.target.value)
                        }
                      />
                    </>
                  )} */}

                  {/* <label className="formLabel mt-3">
                    {t("Enter Service Prize")} $
                  </label>

                  <input
                    type="number"
                    name="meetingPrize"
                    placeholder="5000"
                    value={state.meetingPrize}
                    onChange={(e) =>
                      updateState("meetingPrize", e.target.value)
                    }
                    maxLength="6"
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 6))
                    }
                  /> */}
                  <button
                    type="submit"
                    className="w-100 font-weight-bold mt-3 text-white"
                  >
                    Next <FaArrowRight className="  ml-3" />
                  </button>
                </form>
              )}

              {/* <CheckBox
                value={state.freeCalendar}
                label="Service charge or not ?"
                onChange={(e) => updateState("freeCalendar", e)}
                questionMark={true}
              /> */}

              {/* {!state.freeCalendar && (
                <>
                  <label className="formLabel mt-3">{t("Service Type")}</label>
                  <Select
                    options={allServiceList}
                    value={state.serviceType}
                    onChange={(e) => {
                      updateState("serviceType", e);
                    }}
                  />
                  {state.serviceType?.value === "package" && (
                    <>
                      <label className="formLabel mt-3">
                        {t("Package Name")} *
                      </label>

                      <input
                        type="text"
                        name="Service Type"
                        placeholder="Monthly package"
                        value={state.packageName}
                        onChange={(e) =>
                          updateState("packageName", e.target.value)
                        }
                      />
                    </>
                  )}

                  <label className="formLabel mt-3">
                    {t("Enter Service Prize")} $
                  </label>

                  <input
                    type="number"
                    name="meetingPrize"
                    placeholder="5000"
                    value={state.meetingPrize}
                    onChange={(e) =>
                      updateState("meetingPrize", e.target.value)
                    }
                    maxLength="6"
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 6))
                    }
                  />
                </>
              )} */}

              {/* <button
          className="w-100 font-weight-bold mt-3"
          onClick={() => updateState("activeMenu", 2)}
        >
          Next <FaArrowRight className="text-white ml-3" />
        </button> */}
            </div>
          )}
        </div>
      </BlockUi>
    </>
  );
}

export default withTranslation()(CalendarPlans);
