import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RazorPay from "../../components/RazorPay";
import SubscriptionCard from "./SubscriptionCard";
import MyYuukke from "../../api/MyYuukke";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  getSubscriptionStatus,
  subscriptionModelStatus,
} from "../../redux/actions/calendarAction";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const SubscriptionContainer = () => {
  const {
    subscriptionList,
    subscriptionStatus,
    subscriptionDetails,
    subscriptionType,
  } = useSelector((state) => state.event);
  const { loginDetails } = useSelector((state) => state.loginDetailsApi);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    subscribeId: null,
    prize: 0,
  });
  const openPaymentPage = (prize, id) => {
    if (prize === 0) {
      let data = {
        subscriptionId: id,
      };

      MyYuukke.addUserSubscription(data)
        .then((res) => {
          console.log("response", res);
          dispatch(getSubscriptionStatus(subscriptionType));
          closeSubscriptionModel();
        })
        .catch((e) => {
          toast.error("Something went wrong !");
          closeSubscriptionModel();
        });
    } else {
      setState((previous) => ({
        ...previous,
        subscribeId: id,
        prize: prize,
      }));
    }
  };

  const updatePaymentStatus = (status) => {
    if (status === "dismiss") {
      Swal.fire({
        icon: "error",
        title: "Payment updating failed!",
        text: "If your money debited in bank account ,please contact our team  +91 04446314646",
        showConfirmButton: true,
      }).then(() => {
        setState((previous) => ({
          ...previous,
          subscribeId: null,
          prize: null,
        }));
        closeSubscriptionModel();
      });
    } else {
      dispatch(getSubscriptionStatus(subscriptionType));
      closeSubscriptionModel();
    }
  };
  const closeSubscriptionModel = () => {
    dispatch(subscriptionModelStatus(false));
  };
  if (subscriptionStatus && subscriptionType === "calendar") {
    return <></>;
  }

  return (
    <>
      {state.subscribeId ? (
        <RazorPay
          email={loginDetails.email}
          name={loginDetails.dName}
          userId={loginDetails.userId}
          contactNumber={loginDetails.phoneNumber}
          subscriptionId={state.subscribeId}
          amount={state.prize}
          type={`premium`}
          updatePaymentStatus={updatePaymentStatus}
        />
      ) : (
        <Dialog fullWidth maxWidth="md" open={true} onClose={() => {}}>
          <DialogTitle className="font-weight-bold text-center">
            SELECT YOUR MATCHING PLAN
            <AiOutlineCloseCircle
              onClick={() => closeSubscriptionModel()}
              style={{
                cursor: "pointer",
                float: "right",
                marginBottom: "8px",
                width: "20px",
                height: "20px",
                color: "#000000",
              }}
            />
          </DialogTitle>
          <DialogContent dividers="paper">
            <div className="d-flex flex-wrap g-1 justify-content-center p-4">
              {subscriptionList.map((item, index) => (
                <SubscriptionCard
                  data={item}
                  index={index}
                  selectedTeam={openPaymentPage}
                />
              ))}
            </div>
          </DialogContent>
        </Dialog>
      )}
      {subscriptionList.length === 0 && <p>Options not available</p>}
    </>
  );
};

export default SubscriptionContainer;
