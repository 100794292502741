import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { Box, Card, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles({
//     root: {
//       width: '100%',
//     },
// });

const PERCENT = "percent";
const SPINNER = "spinner";

function LinearProgressWithLabel(props) {
  const [showwhichLoaderItem, setShowWhichloaderItem] = React.useState(PERCENT);
  console.log("progress Details", props);
  return (
    <Box display="flex" flexDirection="column">
      {props.value < 100 && <Typography>uploading ...</Typography>}
      <Box as="div" display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          {props.value < 100 ? (
            <LinearProgress variant="determinate" {...props} color="primary" />
          ) : (
            <Typography>
              Uploading completed, Please wait while we are processing
            </Typography>
          )}
        </Box>
        <Box minWidth={35}>
          {showwhichLoaderItem === PERCENT && props.value < 100 ? (
            <Typography variant="body2" color="textSecondary">
              {`${Math.round(props.value)}%`}
            </Typography>
          ) : (
            <CircularProgress color="primary" />
          )}
        </Box>
      </Box>
    </Box>
  );
}

function LinearProgressBar(props) {
  // const classes = useStyles();
  const { progressCompletedPercent, processingStatus } = props;
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    console.log("Progress Details :", progressCompletedPercent);
    console.log("Progress Detail  :", progress);
    if (processingStatus === "processing") {
      setProgress(progressCompletedPercent);
    } else {
      setProgress(0);
    }

    // if(progress > 100){

    // }
  }, [progressCompletedPercent]);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return (
    <div>
      <LinearProgressWithLabel value={progress} />
    </div>
  );
}

export default LinearProgressBar;
