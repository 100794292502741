import * as APPCONFIG from "../config/app-config.js";
import auth from "./AuthAPI.js";
import Http from "./Http.js";

class HomeService {
  getDashboard() {
    console.log("getDashboard Called");
    return auth({
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/dashboard`,
    });
  }

  getAllSurveyList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getallSurveyList`,
      data: formValues,
    });
  }

  SaveSurveyList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/saveUserAnsweredSurvey`,
      data: formValues,
    });
  }

  inviteGroupList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/inviteGroupList`,
      data: formValues,
    });
  }

  searchPrivateUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/searchPrivateUsers`,
      data: formValues,
    });
  }
  post_analytics(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/post_analytics`,
      data: formValues,
    });
  }
  advertisement_data(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v2/advertisement/postdata`,
      data: formValues,
    });
  }
  advertisement_chat_list(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/advertisement/chatlist`,
      data: formValues,
    });
  }
  getPostByFilter(formValues) {
    console.log(formValues);
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/getPostByFilterV2/${Http.WORK_SPACE()}`,
      url: `${APPCONFIG.API_URL}api/v1/post/filter/${Http.WORK_SPACE()}`,
      data: JSON.stringify(formValues),
    });
  }
  createHomePost(formValues, config) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/createHomePost/${Http.WORK_SPACE()}`,
      url: `${APPCONFIG.API_URL}api/v1/post/create/${Http.WORK_SPACE()}`,
      data: formValues,
      config,
    });
  }
  createHomePostV2(formValues, { headers, onUploadProgress }) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/createHomePost/${Http.WORK_SPACE()}`,
      url: `${APPCONFIG.API_URL}api/v1/post/create/${Http.WORK_SPACE()}`,
      data: formValues,
      onUploadProgress,
    });
  }
  groupInvite(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/v1/searchUsers/groupInvite/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  createpostLike(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}${
        !formValues.userLike ? "api/v1/post/dislike" : "api/v1/post/like"
      }`,
      data: formValues,
    });
  }
  deletePost(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/deletePost`,
      data: formValues,
    });
  }

  getPostByHashTag(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getPostByHashTag/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }

  getHashtagList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getHashtagList`,
      data: formValues,
    });
  }
  getAllDirectoryList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/directory/getAll/${Http.WORK_SPACE()}`,
      // }auth/getAllDirectoryListV2/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  getSingleDirectory(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}auth/getSingleDirectoryList`,
      url: `${APPCONFIG.API_URL}api/v1/directory/single/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  searchPublicUsers(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/searchPublicUsers/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  getPostLikeUsers(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getPostLikeUsers/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  addUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/group/adduser/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }

  deleteDirectory(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/directory/delete`,
      data: formValues,
    });
  }
  friendList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/friends/list/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  addUserFavourite(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/directory/bookmark/${Http.WORK_SPACE()}`,
      data: formValues,
    });
  }
  getDirCategoryList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/directory_category/get`,
      data: formValues,
    });
  }
}

export default new HomeService();
