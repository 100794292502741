import moment from "moment";
import { GET_MESSAGE_LIST_BY_ID, GET_LOGOUT_DATA, SET_SELECTED_USER, SET_SELECTED_USER_FALSE } from "../types";

const initialState = {
  messageList: [],
  selectedUser:null,
  isUserSelected:false
};

var total = [];
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MESSAGE_LIST_BY_ID:
      var lists = [];
      let tempMgsLidt = state.messageList;
      console.log("chat list in reducer");
      console.log(action.payload);
      if (tempMgsLidt.length > 0) {
        for (var i = 0; i < action.payload.length; i++) {
          var isAvailable = false;
          for (var j = 0; j < tempMgsLidt.length; j++) {
            if (action.payload[i].id == tempMgsLidt[j].id) {
              // console.log("action.payload[i]")
              isAvailable = true;
            }
          }
          if (!isAvailable) {
            // console.log(`isAvilable${action.payload}`)
            lists.push(action.payload[i]);
          } else {
            //console.lo g(`!!isAvilable${JSON.stringify(action.payload)}`)
            // console.log(action.payload)
          }
        }
      } else {
        lists = action.payload;
      }
      console.log("LISTS");
      console.log(state.messageList);
      console.log(lists);
      let temp = [...state.messageList, ...lists];
      console.log(temp);

      // console.log(total);

      console.log(
        temp.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
      );
      //  console.log(moment(time).format("DD MMMM yy , HH mm a"))

      return {
        ...state,
        messageList: temp,
        loading: false,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");
      console.log(initialState.subjectslist);
      return {
        ...initialState,
      };
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
        isUserSelected:true
      };
    case SET_SELECTED_USER_FALSE:
      return {
        ...state,
        selectedUser: null,
        isUserSelected:action.payload
      }
    default:
      return state;
  }
}
