import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getEventVideos } from "../../redux/actions/calendarAction";
import BlockUi from "react-block-ui";
import VideoPostCard from "../Home/HomeUpdated/VideoPostCard";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const EventVideo = (props) => {
  const dispatch = useDispatch();
  const { eventVideoList, eventLoader, eventSeeMore } = useSelector(
    (state) => state.event
  );
  const workspaceType = useSelector(
    (state) => state.loginDetailsApi.loginDetails.userWorkSpace?.slug
  );
  const [page, setPage] = useState(0);

  const { t } = props;

  useEffect(() => {
    eventFilter();
  }, []);

  const back = (e, id) => {
    props.history.push(`/${workspaceType}/calendar`);
  };
  const eventFilter = () => {
    let data = {
      page: page,
      calendarId: props.match.params.id,
    };
    dispatch(getEventVideos(data));
    setPage(page + 1);
  };

  return (
    <BlockUi
      tag="div"
      message={t("Loading, please wait")}
      style={{ minHeight: "130px" }}
      className="wallLoadingPage"
      blocking={eventLoader}
    >
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
        <h3 className="listHomeTitle">{t("Events Related Video's")}</h3>
        <div
          style={{
            marginRight: "12px",
            marginLeft: "0px",
            marginBottom: "12px",
          }}
          className="ansBackQuestion flexDefault uploader"
          onClick={() => back()}
        >
          <ArrowBackIcon />
          <p> Back</p>
        </div>
      </div>
      <div className="row mt-1">
        {eventVideoList &&
          eventVideoList.map((item, index) => (
            <div className="col-12 col-md-6 col-lg-6 ">
              <section
                className="cardWrapper-2 mb-4 position-relative"
                key={index}
                style={{ gap: "2rem", cursor: "pointer" }}
                // onClick={() => directoryDescription(item?.slug)}
              >
                <p className="font-weight-bold mt-1  mb-0">{item?.eventName}</p>
                <p className="text-secondary mt-2 mb-3 ">{item?.description}</p>
                <div
                  className="bd-container w-100 d-flex align-items-center justify-content-center"
                  style={{ height: "24rem" }}
                >
                  <VideoPostCard
                    fileData={item.streamUrl}
                    imageData={item.thumbnail}
                    videoStatus={true}
                  />
                </div>
              </section>
            </div>
          ))}
      </div>
      {!eventLoader &&
        eventSeeMore &&
        eventVideoList &&
        eventVideoList.length > 0 && (
          <div class="d-flex justify-content-center mt-4 mb-4">
            <button onClick={() => eventFilter(true)}>{t("See more")}</button>
          </div>
        )}
      {!eventLoader && eventVideoList && eventVideoList.length === 0 && (
        <div className="aboutMyRequestWrapper mt-4">
          <p style={{ textAlign: "center" }}>No more video available</p>
        </div>
      )}
    </BlockUi>
  );
};

export default withTranslation()(withRouter(EventVideo));
