import React from "react";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ChooseFileComponent(props) {
  const { t } = props;

  const checkPDFURL = (name) => {
    name = name.toLowerCase();
    return name.match(/\.(application|pdf)$/) != null;
  };
  const onUploadSuccess = async (file) => {
    if (file.length > 0) {
      var type = "file";
      if (checkPDFURL(file[0]?.name)) {
        type = "pdf";
      } else {
        type = "image";
      }
      props.onUploadSuccess(file, type);
    }
  };
  return (
    <div>
      <Dropzone
        accept={props.acceptedType}
        minSize={0}
        maxSize={31457280}
        maxFiles={props.count}
        multiple={props.multiple}
        onDrop={onUploadSuccess}
        onDropRejected={(e) => {
          console.log("error", e);
          if (props.count === 1) {
            toast.warn("Select single image");
          } else {
            toast.warn("File not supported");
          }
        }}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragReject,
          rejectedFiles,
        }) => (
          <section>
            <div {...getRootProps()} className="file-uploader">
              <input {...getInputProps()} />
              <div className="upload-icon">
                <CloudUploadIcon fontSize="inherit" />
              </div>
              <div className="text-large-weighted mb-3">
                <p>{t("Add your image")}</p>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}

export default withTranslation()(ChooseFileComponent);
