import { GET_FRIENDS_REQUEST_LIST } from "../types";
import { GET_FRIENDS_LIST } from "../types";
import {
  GET_RECOMMENDED_FRIENDS_LIST,
  GET_SEARCH_USER_REQUESTED_LIST,
} from "../types";
import {
  GET_RECENTLY_ADDED_FRIENDS_LIST,
  GET_USER_REQUESTED_LIST,
} from "../types";
import {
  GET_LOGOUT_DATA,
  GET_SEARCH_FRIENDS_LIST,
  GET_SEARCH_REQUEST_LIST,
  GET_SEARCH_RECOMMENDED_LIST,
} from "../types";

const initialState = {
  friendsrequestlist: [],
  friendsList: [],
  recommendedfriendsList: [],
  recentlyaddedfriendsList: [],
  userRequestedList: [],
  searchFriendsList: [],
  searchRequestedFriendsList: [],
  searchUserRequestedFriendsList: [],
  searchRecommendedFriendsList: [],
  searchUserRequestedFriendsPage: 0,
  searchRequestedFriendsPage: 0,
  searchRecommendedFriendsPage: 0,
  searchFriendsPage: 0,
  recommendUsers: 0,
  totalFriendsCount:0,
  invitationCount:0,
  requestedCount:0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SEARCH_FRIENDS_LIST:
      console.log("GET_SEARCH_FRIENDS_LIST");
      console.log(action.payload);
      return {
        ...state,
        searchFriendsList: action.payload,
        searchFriendsPage: action.pageNo,
        totalFriendsCount:action.totalCount,
        loading: false,
      };
    case GET_SEARCH_REQUEST_LIST:
      console.log("GET_SEARCH_REQUEST_LIST");
      console.log(action.payload);
      return {
        ...state,
        searchRequestedFriendsList: action.payload,
        searchRequestedFriendsPage: action.pageNo,
        invitationCount:action.totalCount,
        loading: false,
      };
    case GET_SEARCH_RECOMMENDED_LIST:
      console.log("GET_SEARCH_RECOMMENDED_LIST");
      console.log(action.payload);
      return {
        ...state,
        searchRecommendedFriendsList: action.payload,
        searchRecommendedFriendsPage: action.pageNo,
        loading: false,
        recommendUsers: action.recommendUsers,
      };
    case GET_SEARCH_USER_REQUESTED_LIST:
      console.log("GET_SEARCH_USER_REQUESTED_LIST");
      console.log(action.payload);
      return {
        ...state,
        searchUserRequestedFriendsList: action.payload,
        searchUserRequestedFriendsPage: action.pageNo,
        requestedCount:action.totalCount,
        loading: false,
      };
    case GET_FRIENDS_REQUEST_LIST:
      console.log("GET_FRIENDS_REQUEST_LIST");
      console.log(action.payload);
      return {
        ...state,
        friendsrequestlist: action.payload,
        loading: false,
      };
    case GET_FRIENDS_LIST:
      console.log("GET_FRIENDS_LIST");
      console.log(action.payload);
      return {
        ...state,
        friendsList: action.payload,
        loading: false,
      };
    case GET_RECOMMENDED_FRIENDS_LIST:
      console.log("GET_RECOMMENDED_FRIENDS_LIST");
      console.log(action.payload);
      return {
        ...state,
        recommendedfriendsList: action.payload,
        loading: false,
      };
    case GET_RECENTLY_ADDED_FRIENDS_LIST:
      console.log("GET_RECENTLY_ADDED_FRIENDS_LIST");
      console.log(action.payload);
      return {
        ...state,
        recentlyaddedfriendsList: action.payload,
        loading: false,
      };
    case GET_USER_REQUESTED_LIST:
      console.log("GET_USER_REQUESTED_LIST");
      console.log(action.payload);
      return {
        ...state,
        userRequestedList: action.payload,
        loading: false,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA");
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
