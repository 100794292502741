import React, { Component } from "react";
import NotificationCard from "./NotificationCard";
import AuthService from "../../session/AuthService";
import ProfileService from "../../api/ProfileService";
import { ToastContainer } from "react-toastify";
import { withRouter } from "react-router";
import i18n from "../../i18n";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  removeGroupOfNotificationById,
  removeNotificationListById,
} from "../../redux/actions/notificationActions";
import Delete from "@material-ui/icons/Delete";

var notificationList = [];
var notificationId = 0;
let global_language = "";
class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationList: [],
      deleteNotificationState: false,
      tabIndex: 0,
      filterNotificationList: [],
      generalNotificationLength: 0,
      personalNotificationLength: 0,
    };

    this.getallNotifications = this.getallNotifications.bind(this);
    this.deleteNotification = this.deleteNotification.bind(this);
    this.directNotification = this.directNotification.bind(this);
    this.deleteOneNotification = this.deleteOneNotification.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.getFirstCall(this.props.notificationList);
    let langValue = AuthService.getCommonLanguages();
    global_language = langValue;
    i18n.changeLanguage(langValue);
    this.notificationTypeChange(this.state.tabIndex);
    console.log("meeting>>", this.props.notificationList);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.notificationList &&
      prevProps.notificationList !== this.props.notificationList
    ) {
      this.getFirstCall(this.props.notificationList);
      this.notificationTypeChange(this.state.tabIndex);
    }
  }

  generalNotification = (index, lengthCheck) => {
    const general =
      this.props.notificationList &&
      this.props.notificationList.filter(
        (d) =>
          d.type !== "advertisement_chat" &&
          d.type !== "post_subscribe_info" &&
          d.type !== "post_subscribe" &&
          // d.status !== "new_chat_message" &&
          d.notificationType !== 9 &&
          d.type !== "mentor" &&
          d.type !== "private_post_mention" &&
          d.type !== "private-forum-community-answer-and-reply-mention" &&
          d.type !== "private-forum-community-question-mention" &&
          d.type !== "friend_send" &&
          d.type !== "friend_accept" &&
          d.type !== "private_post_share" &&
          d.notificationType !== 18 &&
          d.type !== "slot_booking"
      );
    console.log("general", general);
    if (lengthCheck) {
      this.setState({
        generalNotificationLength: general.length,
      });
    } else {
      this.setState({
        filterNotificationList: general,
        tabIndex: index,
        generalNotificationLength: general?.length ?? 0,
      });
    }
    // let unique = [],
    //   notificationDetails = [];
    // general.forEach((v, i) => {
    //   if (!unique.includes(v.notification)) {
    //     unique.push(v.notification);
    //     notificationDetails.push({
    //       notificationDetail: general.filter(
    //         (val) => val.notification === v.notification
    //       ),
    //       notificationMessage: v.notification,
    //     });
    //   }
    // });

    // if (lengthCheck) {
    //   this.setState({
    //     generalNotificationLength: notificationDetails.length,
    //   });
    // } else {
    //   this.setState({
    //     filterNotificationList: notificationDetails,
    //     tabIndex: index,
    //     generalNotificationLength: notificationDetails.length,
    //   });
    // }
  };
  privateNotification = (index, lengthCheck) => {
    const personal =
      this.props.notificationList &&
      this.props.notificationList.filter(
        (d) =>
          d.type === "advertisement_chat" ||
          d.type === "post_subscribe_info" ||
          d.type === "post_subscribe" ||
          d.notificationType === 9 ||
          d.type === "mentor" ||
          d.type === "private_post_mention" ||
          d.type === "private-forum-community-answer-and-reply-mention" ||
          d.type === "private-forum-community-question-mention" ||
          d.type === "friend_send" ||
          d.type === "friend_accept" ||
          d.type === "private_post_share" ||
          d.notificationType === 18 ||
          d.type === "slot_booking"
      );
    console.log("personal", personal);
    if (lengthCheck) {
      this.setState({
        personalNotificationLength: personal.length,
      });
    } else {
      this.setState({
        filterNotificationList: personal,
        tabIndex: index,
        personalNotificationLength: personal?.length ?? 0,
      });
    }
    // let unique = [],
    //   notificationDetails = [];
    // personal.forEach((v, i) => {
    //   if (!unique.includes(v.notification)) {
    //     unique.push(v.notification);
    //     notificationDetails.push({
    //       notificationDetail: personal.filter(
    //         (val) => val.notification === v.notification
    //       ),
    //       notificationMessage: v.notification,
    //     });
    //   }
    // });

    // if (lengthCheck) {
    //   this.setState({
    //     personalNotificationLength: notificationDetails.length,
    //   });
    // } else {
    //   this.setState({
    //     filterNotificationList: notificationDetails,
    //     tabIndex: index,
    //     personalNotificationLength: notificationDetails.length,
    //   });
    // }
  };
  notificationTypeChange = (index) => {
    if (index === 1) {
      //Private
      this.privateNotification(index, false);
      this.generalNotification(index, true);
    } else {
      //General
      this.privateNotification(index, true);
      this.generalNotification(index, false);
    }
  };

  groupByNotificationId = (arr) => {
    return arr.reduce((acc, val) => {
      if (acc[val.notification]) {
        acc[val.notification].count += 1;
        // acc= {
        //   ...acc,
        // }
        return acc;
      } else {
        acc = {
          ...acc,
          [val.notification]: {
            ...val,
            [val.notification]: val.notification,
            count: 1,
          },
        };
        return acc;
      }
    }, {});
  };
  render() {
    // console.log("filterNotificationList", this.state.filterNotificationList);
    // console.log("notificationList", this.props.notificationList);
    const { t } = this.props;
    return (
      <>
        <ToastContainer position="top-right" autoClose={5000} />
        <div className="notificationWrapper">
          <div className="notifyHeader flexDefault flexAlignCenter flexJustifySpacebetween">
            <p className="notifyTitle">{t("Notifications")}</p>
            <p className="notifyCount">
              {this.props.notificationList.length} new
            </p>
            <p
              className="notifyCount"
              onClick={() => this.handleClick()}
              style={{ cursor: "pointer" }}
            >
              {/* <div className="notify-action-icon"  >
                <HighlightOffIcon  />   
              </div> */}
              {t("Close")}
            </p>
            {this.props.notificationList.length > 0 && (
              <div
                className="notify-action-icon"
                onClick={() => this.deleteNotification()}
              >
                <Delete style={{ cursor: "pointer", color: "white" }} />{" "}
              </div>
            )}
          </div>
          <div class="d-flex">
            <button
              class={
                this.state.tabIndex === 0 ? "active-menu" : "un-active-menu"
              }
              onClick={() => this.notificationTypeChange(0)}
            >
              {t("General")}
              {/* {this.state.tabIndex === 0 && ( */}
              <span class="badge badge-info ml-2">
                {this.state.generalNotificationLength}
              </span>
              {/* )} */}
            </button>
            <button
              class={
                this.state.tabIndex === 1 ? "active-menu" : "un-active-menu"
              }
              onClick={() => this.notificationTypeChange(1)}
            >
              {t("Private")}
              {/* {this.state.tabIndex === 1 && ( */}
              <span class="badge badge-info ml-2">
                {this.state.personalNotificationLength}
              </span>
              {/* )} */}
            </button>
          </div>
          <div className="norfityReminderCardWrapper">
            {/* {this.props.notificationList &&
              this.props.notificationList.map((wall) => (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    this.directNotification(
                      wall.notification.notificationType,
                      wall.notification.notificationId,
                    )
                  }
                >
                  {wall.lastUpcommingCall && (
                    <div className="notifyReminder flexDefault flexAlignCenter">
                      <div className="notifyReminderIcon">
                        <AlarmIcon />
                      </div>

                      <div className="notifyReminderInfo">
                        <p className="notifyReminderText">
                          {t('Meeting starts on')}{' '}
                          {moment(wall.startDate).format('DD/MM/YYYY')} at{' '}
                          {moment(wall.startDate).format('H:mm a')}
                        </p>
                        <div className="notifyReminderGroup">
                          <AvatarGroup max={3}>
                            {this.state.app_receiverList &&
                              this.state.app_receiverList.map((info, index) => (
                                <Avatar
                                  alt={info.name}
                                  src={`data:image;base64,${info.imgUrl}`}
                                  key={index}
                                />
                              ))}
                          </AvatarGroup>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))} */}

            {this.state.filterNotificationList &&
              this.state.filterNotificationList.map((wall, index) => (
                // <NotificationCard
                //   tabIndex={this.state.tabIndex}
                //   isNotAnswered={true}
                //   description={`${t(wall.notificationMessage)}`}
                //   notificationDetail={wall.notificationDetail}
                //   notificationLength={wall.notificationDetail.length}
                //   time={wall.createdDate}
                //   dashBoardType={wall.type}
                //   referenceId={wall.referenceId}
                //   notificationType={wall.notificationType}
                //   nSenderId={wall.notificationSenderId}
                //   notificationId={wall.notificationId}
                //   status={wall.status}
                //   data={wall.notificationDetail}
                //   postDetail={wall.data}
                //   deletesingleNotifications={this.deleteOneNotification}
                //   deleteCustomNotification={this.deleteGroupOfNotification}
                //   handleClick={this.handleClick}
                //   notification={wall.notification}
                // />
                <NotificationCard
                  tabIndex={this.state.tabIndex}
                  isNotAnswered={true}
                  description={`${t(wall.notification)}`}
                  // notificationDetail={wall.notificationDetail}
                  // notificationLength={wall.notificationDetail.length}
                  time={wall.createdDate}
                  dashBoardType={wall.type}
                  referenceId={wall.referenceId}
                  notificationType={wall.notificationType}
                  nSenderId={wall.notificationSenderId}
                  notificationId={wall.notificationId}
                  status={wall.status}
                  data={wall}
                  postDetail={wall.data}
                  deletesingleNotifications={this.deleteOneNotification}
                  deleteCustomNotification={this.deleteGroupOfNotification}
                  handleClick={this.handleClick}
                  notification={wall.notification}
                />
              ))}

            {/* {this.state.filterNotificationList &&
              this.state.filterNotificationList.map((wall) => (
                <div>
                  {wall.notificationType == 5 &&
                    (wall.dashhBoardType == 'answer' ||
                      wall.dashhBoardType == 'answerReply') && (
                      <NotificationCard
                        isNotAnswered={true}
                        // description={`${wall.data.n} ${t(
                        //   "has added an answer to your question"
                        // )}`}
                        description={`${t(wall.notification)}`}
                        dashhBoardType={wall.dashhBoardType}
                        questionId={wall.referenceId}
                        nSenderId={wall.notificationSenderId}
                        time={wall.createdDate}
                        data={wall.data}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 5 &&
                    wall.dashhBoardType == 'LastLikedQuestion' &&
                    wall.type == 'liked_question' && (
                      <NotificationCard
                        time={wall.createdDate}
                        dashhBoardType={wall.dashhBoardType}
                        questionId={wall.referenceId}
                        //description={wall.notification}
                        description={`${wall.data.n} ${t(
                          'reacted to a question',
                        )}`}
                        notificationType={wall.notificationType}
                        nSenderId={wall.notificationSenderId}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 5 &&
                    wall.dashhBoardType == 'LastLikedQuestion' &&
                    wall.type == 'liked_answer' && (
                      <NotificationCard
                        time={wall.createdDate}
                        dashhBoardType={wall.dashhBoardType}
                        questionId={wall.referenceId}
                        //description={wall.notification}
                        description={`${wall.data.n} ${t(
                          'reacted to a answer',
                        )}`}
                        notificationType={wall.notificationType}
                        nSenderId={wall.notificationSenderId}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 5 &&
                    wall.dashhBoardType == 'LastLikedQuestion' &&
                    wall.type == 'liked_upvoted_question' && (
                      <NotificationCard
                        time={wall.createdDate}
                        dashhBoardType={wall.dashhBoardType}
                        questionId={wall.referenceId}
                        //description={wall.notification}
                        description={`${wall.data.n} ${t(
                          'reacted to your upvote to a question',
                        )}`}
                        notificationType={wall.notificationType}
                        nSenderId={wall.notificationSenderId}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}

                  {wall.notificationType == 5 &&
                    wall.dashhBoardType == 'upVotesAnswer' && (
                      <NotificationCard
                        dashhBoardType={wall.dashhBoardType}
                        questionId={wall.referenceId}
                        notificationType={wall.notificationType}
                        isNotAnswered={true}
                        //description={wall.notification}
                        description={`${wall.data.n} ${t(
                          'has upvoted your answer',
                        )}`}
                        time={wall.createdDate}
                        nSenderId={wall.notificationSenderId}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 7 &&
                    wall.dashhBoardType == 'questionUpvotes' && (
                      <NotificationCard
                        dashhBoardType={wall.dashhBoardType}
                        questionId={wall.referenceId}
                        notificationType={wall.notificationType}
                        isNotAnswered={true}
                        nSenderId={wall.notificationSenderId}
                        //description={wall.notification}
                        description={`${wall.data.n} ${t(
                          'has upvoted your question',
                        )}`}
                        time={wall.createdDate}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 8 &&
                    wall.dashhBoardType == 'LastLikedQuestion' &&
                    wall.type == 'liked_question' && (
                      <NotificationCard
                        time={wall.createdDate}
                        dashhBoardType={wall.dashhBoardType}
                        questionId={wall.referenceId}
                        //description={wall.notification}
                        description={`${wall.data.n} ${t(
                          'reacted to a question',
                        )}`}
                        notificationType={wall.notificationType}
                        nSenderId={wall.notificationSenderId}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}

                  {wall.notificationType == 3 && wall.type == 'groups' && (
                    <NotificationCard
                      type={'Call Schedule'}
                      isNotAnswered={true}
                      description={wall.notification}
                      time={wall.createdDate}
                      nSenderId={wall.notificationSenderId}
                      notificationType={wall.notificationType}
                      notificationId={wall.notificationId}
                      deletesingleNotifications={this.deleteOneNotification}
                    />
                  )}
                  {wall.notificationType == 9 &&
                    wall.type == 'common_notification' && (
                      <NotificationCard
                        type={'common_notification'}
                        isNotAnswered={true}
                        description={wall.notification}
                        time={wall.createdDate}
                        nSenderId={wall.notificationSenderId}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 3 &&
                    wall.type == 'Expiry Events' && (
                      <NotificationCard
                        type={'Call Schedule'}
                        isNotAnswered={true}
                        description={wall.notification}
                        time={wall.createdDate}
                        nSenderId={wall.notificationSenderId}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 3 && wall.type == 'events' && (
                    <NotificationCard
                      type={'Call Schedule'}
                      isNotAnswered={true}
                      // description={`${(wall.data.n)} ${t('has scheduled a')} ${(wall.data.t)} ${t('call with you')}`}
                      description={wall.notification}
                      time={wall.createdDate}
                      nSenderId={wall.notificationSenderId}
                      notificationType={wall.notificationType}
                      notificationId={wall.notificationId}
                      deletesingleNotifications={this.deleteOneNotification}
                    />
                  )}
                  {wall.notificationType == 3 && wall.type == 'post_subscribe' && (
                    <NotificationCard
                      type={'post_subscribe'}
                      isNotAnswered={true}
                      // description={`${(wall.data.n)} ${t('has scheduled a')} ${(wall.data.t)} ${t('call with you')}`}
                      description={wall.notification}
                      time={wall.createdDate}
                      nSenderId={wall.notificationSenderId}
                      notificationType={wall.notificationType}
                      notificationId={wall.notificationId}
                      deletesingleNotifications={this.deleteOneNotification}
                    />
                  )}
                  {wall.notificationType == 3 &&
                    wall.type == 'post_subscribe_info' && (
                      <NotificationCard
                        type={'post_subscribe'}
                        isNotAnswered={true}
                        // description={`${(wall.data.n)} ${t('has scheduled a')} ${(wall.data.t)} ${t('call with you')}`}
                        description={wall.notification}
                        time={wall.createdDate}
                        nSenderId={wall.notificationSenderId}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 10 &&
                    (wall.type === 'advertisement_chat' ||
                      wall.status === 'advertisement_chat') && (
                      <NotificationCard
                        type={wall.type}
                        isNotAnswered={true}
                        data={wall.data}
                        // description={`${(wall.data.n)} ${t('has scheduled a')} ${(wall.data.t)} ${t('call with you')}`}
                        description={wall.notification}
                        time={wall.createdDate}
                        nSenderId={wall.notificationSenderId}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 3 &&
                    wall.type == 'peer_call_reschedule' && (
                      <NotificationCard
                        type={'Call Schedule'}
                        isNotAnswered={true}
                        description={`${wall.data.n} ${t(
                          'has rescheduled a call with you',
                        )}`}
                        time={wall.createdDate}
                        nSenderId={wall.notificationSenderId}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 3 &&
                    wall.type == 'group_call_reschedule' && (
                      <NotificationCard
                        type={'Call Schedule'}
                        isNotAnswered={true}
                        description={`${wall.data.n} ${t(
                          'has rescheduled a',
                        )} ${wall.data.t} ${t('call with you')}`}
                        time={wall.createdDate}
                        nSenderId={wall.notificationSenderId}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}

                  {wall.notificationType == 3 &&
                    wall.type == 'peer_call_cancel' && (
                      <NotificationCard
                        type={'Call Schedule'}
                        isNotAnswered={true}
                        description={`${wall.data.n} ${t(
                          'has cancelled the scheduled call',
                        )}`}
                        time={wall.createdDate}
                        nSenderId={wall.notificationSenderId}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 3 &&
                    wall.type == 'group_call_cancel' && (
                      <NotificationCard
                        type={'Call Schedule'}
                        isNotAnswered={true}
                        description={`${wall.data.n} ${t(
                          'has cancelled the scheduled',
                        )} ${wall.data.t} ${t('call')}`}
                        time={wall.createdDate}
                        nSenderId={wall.notificationSenderId}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 1 && wall.type == 'friend_send' && (
                    <NotificationCard
                      isNotAnswered={true}
                      description={wall.notification}
                      //description={`${t('You have a new friend request from')} ${wall.data.n}`}
                      time={wall.createdDate}
                      nSenderId={wall.notificationSenderId}
                      notificationType={wall.notificationType}
                      notificationId={wall.notificationId}
                      deletesingleNotifications={this.deleteOneNotification}
                    />
                  )}
                  {wall.notificationType == 2 && wall.type == 'friend_accept' && (
                    <NotificationCard
                      isNotAnswered={true}
                      description={wall.notification}
                      // description={`${wall.data.n} ${t('has accepted your friend request')}`}
                      time={wall.createdDate}
                      nSenderId={wall.notificationSenderId}
                      notificationType={wall.notificationType}
                      notificationId={wall.notificationId}
                      deletesingleNotifications={this.deleteOneNotification}
                    />
                  )}
                  {wall.notificationType == 3 && wall.type == 'instantMeeting' && (
                    <NotificationCard
                      isNotAnswered={true}
                      description={wall.notification}
                      url={wall.url}
                      type={wall.type}
                      // description={`${wall.data.n} ${t('has accepted your friend request')}`}
                      time={wall.createdDate}
                      nSenderId={wall.notificationSenderId}
                      notificationType={wall.notificationType}
                      notificationId={wall.notificationId}
                      deletesingleNotifications={this.deleteOneNotification}
                    />
                  )}
                  {wall.notificationType == 1 && wall.type == 'profile_update' && (
                    <NotificationCard
                      isNotAnswered={true}
                      nSenderId={wall.notificationSenderId}
                      //description={wall.notification}
                      description={t('Your profile has been updated')}
                      time={wall.createdDate}
                      notificationType={wall.notificationType}
                      notificationId={wall.notificationId}
                      deletesingleNotifications={this.deleteOneNotification}
                    />
                  )}

                  {wall.notificationType == 4 && (
                    <NotificationCard
                      isNotAnswered={true}
                      nSenderId={wall.notificationSenderId}
                      // description={wall.notification}
                      description={wall.notification}
                      announcementId={wall.referenceId}
                      time={wall.createdDate}
                      notificationType={wall.notificationType}
                      notificationId={wall.notificationId}
                      deletesingleNotifications={this.deleteOneNotification}
                    />
                  )}
                  {wall.notificationType == 6 &&
                    wall.type == 'course_save' &&
                    global_language == 'en' && (
                      <NotificationCard
                        isNotAnswered={true}
                        nSenderId={wall.notificationSenderId}
                        description={`${wall.data.n} course has been added.`}
                        time={wall.createdDate}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 6 &&
                    wall.type == 'course_save' &&
                    global_language == 'fr' && (
                      <NotificationCard
                        isNotAnswered={true}
                        nSenderId={wall.notificationSenderId}
                        description={`Le cours ${wall.data.n} a été mis à jour`}
                        time={wall.createdDate}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 6 &&
                    wall.type == 'course_save' &&
                    global_language == 'lx' && (
                      <NotificationCard
                        isNotAnswered={true}
                        nSenderId={wall.notificationSenderId}
                        description={`De Cours ${wall.data.n} ass aktualiséiert ginn`}
                        time={wall.createdDate}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}

                  {wall.notificationType == 6 &&
                    wall.type == 'course_update' &&
                    global_language == 'en' && (
                      <NotificationCard
                        isNotAnswered={true}
                        nSenderId={wall.notificationSenderId}
                        description={`${wall.data.n} course has been updated`}
                        time={wall.createdDate}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 6 &&
                    wall.type == 'course_update' &&
                    global_language == 'fr' && (
                      <NotificationCard
                        isNotAnswered={true}
                        nSenderId={wall.notificationSenderId}
                        description={`Le cours ${wall.data.n} a été mis à jour`}
                        time={wall.createdDate}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 6 &&
                    wall.type == 'course_update' &&
                    global_language == 'lx' && (
                      <NotificationCard
                        isNotAnswered={true}
                        nSenderId={wall.notificationSenderId}
                        description={`De Cours ${wall.data.n} ass aktualiséiert ginn`}
                        time={wall.createdDate}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}

                  {wall.notificationType == 7 &&
                    wall.type == 'profile_photo_update' && (
                      <NotificationCard
                        isNotAnswered={true}
                        nSenderId={wall.receiverId}
                        //description={wall.notification}
                        description={t('Your profile photo has been rejected')}
                        time={wall.createdDate}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                  {wall.notificationType == 7 &&
                    wall.type == 'cover_photo_update' && (
                      <NotificationCard
                        isNotAnswered={true}
                        nSenderId={wall.receiverId}
                        //description={wall.notification}
                        description={t('Your cover photo has been rejected')}
                        time={wall.createdDate}
                        notificationType={wall.notificationType}
                        notificationId={wall.notificationId}
                        deletesingleNotifications={this.deleteOneNotification}
                      />
                    )}
                </div>
              ))} */}
          </div>
        </div>
      </>
    );
  }

  directNotification(typeid, notificationId, dashhBoardType, question_id) {
    var path = "search";
    if (typeid == 5 && dashhBoardType == "question") {
      path = `/community/questions/${question_id}`;
    } else if (typeid == 5 && dashhBoardType == "answer") {
      path = `/community/questions/${question_id}`;
    }
    if (typeid == 5 && dashhBoardType == "questionUpvotes") {
      path = `/community/questions/${question_id}`;
    }
    if (typeid == 5 && dashhBoardType == "LastLikedQuestion") {
      path = `/community`;
    } else if (typeid == 3) {
      path = `/calls`;
    } else if (typeid == 1) {
      path = `/calls`;
    }
    sessionStorage.setItem("APP_REDIRECT_PATH", path);
    this.props.history.push(`/redirect`);
  }

  deleteOneNotification(id) {
    this.props.showNotification(true, true);
    this.props.removeNotificationListById(id, this.props.notificationList);
    notificationId = id;
    notificationList = this.props.notificationList;
  }
  deleteGroupOfNotification = (id) => {
    // this.props.showNotification(true, true)
    this.props.removeGroupOfNotificationById(id, this.props.notificationList);
  };
  deleteNotification() {
    this.props.deleteNotifications();
  }

  getallNotifications() {
    let getNotification = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    ProfileService.getallNotifications(getNotification)
      .then((response) => {
        var result = response.data;

        this.setState({
          notificationList: result.data,
        });
        if (result.data.length > 0) {
          this.props.notificationCounts(result.data.length);
        } else {
          this.props.zeroNotifications(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFirstCall(data) {
    // for (let i = 0; i < data.length; i++) {
    //   if (!data[i].hasOwnProperty('lastUpcommingCall')) {
    //     console.log("lastUpcommingCall")
    //   }
    //   else if (data[i].hasOwnProperty('lastUpcommingCall')) {
    //     var obj = {
    //       receivers: data[i].receivers,
    //       startDate: data[i].startDate
    //     }
    //     this.peerFriend(obj)
    //   }
    // }
  }

  handleClick() {
    this.props.handleAway();
  }
  peerFriend(remainderListDatas) {
    const receiver_List = [];
    var text = "";
    for (let i = 0; i < remainderListDatas.receivers.length; i++) {
      if (remainderListDatas.receivers[i] != this.props.loginDetails.uId) {
        for (let j = 0; j < AuthService.getFriendsList().length; j++) {
          if (
            remainderListDatas.receivers[i] ===
            AuthService.getFriendsList()[j].user.userProfile.userId
          ) {
            var firstName =
              AuthService.getFriendsList()[j].user.userProfile.firstName;
            var lastName =
              AuthService.getFriendsList()[j].user.userProfile.lastName;
            var friendsName = firstName + " " + lastName;
            var friendPhoto = AuthService.getFriendsList()[j].userPhoto;
            receiver_List.push({
              name: friendsName,
              imgUrl: friendPhoto,
            });
          }
        }
      }
      this.setState({
        app_receiverList: receiver_List,
      });
    }
  }
}

const mapStateToProps = function (state) {
  return {
    notificationList: state.notification.list,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    removeNotificationListById: (notificationId, notificationList) =>
      dispatch(removeNotificationListById(notificationId, notificationList)),
    removeGroupOfNotificationById: (notificationId, notificationList) =>
      dispatch(removeGroupOfNotificationById(notificationId, notificationList)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(Notifications)));
