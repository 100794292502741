import React, { Component } from "react";
//import DatePicker from "react-datepicker";
import Grid from "@material-ui/core/Grid";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-multi-date-picker";

export default class EditableDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disbaledField: false,
    };

    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  handleClickEdit() {
    this.setState({
      disbaledField: false,
    });
    this.props.handleClickEdits();
  }

  render() {
    const { label } = this.props;
    const { disbaledField, value } = this.state;
    return (
      <div
        className={
          disbaledField
            ? "editableTextWrapper formFieldGroup disabledField"
            : "editableTextWrapper formFieldGroup"
        }
      >
        <label className="label-v2">{label}</label>
        <Grid container spacing={1} alignItems="flex-end" wrap="nowrap">
          <Grid item xs={11}>
            {/* <TextField
              id="date"
              label=""
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth={true}
              variant="outlined"
              value={value}
              onChange={this.handleChangeInput}
              disabled={disbaledField}
              fullWidth={true}

            /> */}
            <DatePicker
              maxDate={new Date()}
              value={value}
              onChange={this.handleChangeInput}
              format="DD/MM/YYYY"
              style={{
                height: "26px",
                width: "100%",
                fontSize: "14px",
                padding: "18px 12px",
              }}
              editable={false}
              // inputClass="custom-input"
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  handleChangeInput(value) {
    this.setState({
      value: value.toString,
    });
    this.props.getSelectedValue(value.toString());
    this.props.handleClickEdits();
  }
}
