export const timeZone = [
  {
    label: "Africa",
    options: [
      { label: "Africa-Cairo", value: "Africa/Cairo", offset: "GMT+2" },
      {
        label: "Africa-Johannesburg",
        value: "Africa/Johannesburg",
        offset: "GMT+2",
      },
      { label: "Africa-Lagos", value: "Africa/Lagos", offset: "GMT+1" },
      { label: "Africa-Nairobi", value: "Africa/Nairobi", offset: "GMT+3" },
    ],
  },
  {
    label: "America",
    options: [
      { label: "America-New_York", value: "America/New_York", offset: "GMT-4" },
      {
        label: "America-Los_Angeles",
        value: "America/Los_Angeles",
        offset: "GMT-7",
      },
      {
        label: "America-Sao_Paulo",
        value: "America/Sao_Paulo",
        offset: "GMT-3",
      },
      { label: "America-Chicago", value: "America/Chicago", offset: "GMT-5" },
    ],
  },
  {
    label: "Asia",
    options: [
      { label: "Asia-Tokyo", value: "Asia/Tokyo", offset: "GMT+9" },
      { label: "Asia-Dubai", value: "Asia/Dubai", offset: "GMT+4" },
      { label: "Asia-Shanghai", value: "Asia/Shanghai", offset: "GMT+8" },
      { label: "Asia-Kolkata", value: "Asia/Kolkata", offset: "GMT+5:30" },
    ],
  },
  {
    label: "Europe",
    options: [
      { label: "Europe-London", value: "Europe/London", offset: "GMT+0" },
      { label: "Europe-Berlin", value: "Europe/Berlin", offset: "GMT+2" },
      { label: "Europe-Moscow", value: "Europe/Moscow", offset: "GMT+3" },
      { label: "Europe-Paris", value: "Europe/Paris", offset: "GMT+2" },
    ],
  },
  {
    label: "Australia",
    options: [
      {
        label: "Australia-Sydney",
        value: "Australia/Sydney",
        offset: "GMT+10",
      },
      { label: "Australia-Perth", value: "Australia/Perth", offset: "GMT+8" },
      {
        label: "Australia-Melbourne",
        value: "Australia/Melbourne",
        offset: "GMT+10",
      },
    ],
  },
];

export const language = [
  { value: "English", label: "English" },
  { value: "Tamil", label: "தமிழ்" },
  { value: "Hindi", label: "हिंदी" },

  { value: "Gujarati", label: "ગુજરાતી" },

  { value: "Kannadam", label: "ಕನ್ನಡ" },

  { value: "Odia", label: "ଓଡିଆ" },
  { value: "Telugu", label: "తెలుగు" },
  { value: "Assamese", label: "অসমীয়া" },
  { value: "Kashmiri", label: "کٲشُر" },

  { value: "Bengali", label: "বাংলা" },
  { value: "Arabic", label: "عربي" },

  { value: "French", label: "Français" },

  { value: "Spanish", label: "Española" },
  { value: "Mandarin", label: "普通话" },
];
