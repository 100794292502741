import React, { Component } from "react";
import { Form, Row, Container, Col, Card } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlockUi from "react-block-ui";
import "react-day-picker/lib/style.css";
import { withTranslation } from "react-i18next";
import "rc-time-picker/assets/index.css";
import i18n from "../../i18n";
import AuthService from "../../session/AuthService";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ThumbUpAlt from "@material-ui/icons/ThumbUpAlt";
import ProfileService from "../../api/ProfileService";
import { withRouter } from "react-router";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";

class SubscriberForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      blocking: false,
      contactEmail: "",
      issueId: "Technical Support",
      captchachecked: false,
      contactDescription: "",
      emailBoxEnable: false,
      courseName: "",
      contactName: "",
      contactNumber: "",
      jobType: "",
      businessName: "",
      passWord: "",
    };

    this.onChange = this.onChange.bind(this);
    this.submitSupport = this.submitSupport.bind(this);
    this.block = this.block.bind(this);
    this.backtohome = this.backtohome.bind(this);
    this.backtoCourseList = this.backtoCourseList.bind(this);
    this.handleForm = this.handleForm.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.setState({
      courseName: this.props.courseName,
    });

    if (
      this.props.loginDetails.dName == null &&
      this.props.loginDetails == null
    ) {
      this.setState({
        emailBoxEnable: false,
      });
    } else {
      this.setState({
        emailBoxEnable: true,
        contactName: this.props.loginDetails.dName,
        contactEmail: this.props.loginDetails.email,
        lastName: this.props.loginDetails.lName,
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <div
          className="screenInnerGap"
          style={{ marginTop: "100px", marginLeft: "0px" }}
        >
          <Container>
            <Grid container justify="center">
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <BlockUi
                  tag="div"
                  blocking={this.state.blocking}
                  message={t("Loading, please wait")}
                  color="#60c2e7"
                >
                  {" "}
                  {!this.state.success && (
                    <div>
                      <Card>
                        <ToastContainer position="top-right" autoClose={5000} />
                        <div>
                          <Form id="profileForm" noValidate>
                            <h4 style={{ margin: "15px" }}>
                              {/* {t("Grow your business with Yuukke")} */}
                              {t("Seller Registration")}
                            </h4>
                            <Card.Body>
                              {this.state.emailBoxEnable && (
                                <div>
                                  <Row>
                                    <Col xs="12 " sm="12">
                                      <Form.Group className="formInputs">
                                        <Form.Label>
                                          {t("First Name")}
                                        </Form.Label>
                                        <Form.Control
                                          onChange={this.handleFormWithHandle}
                                          required
                                          type="text"
                                          value={this.state.contactName}
                                          name="contactName"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs="12 " sm="12">
                                      <Form.Group className="formInputs">
                                        <Form.Label>
                                          {t("Last Name")}
                                        </Form.Label>
                                        <Form.Control
                                          onChange={this.handleFormWithHandle}
                                          required
                                          type="text"
                                          value={this.state.lastName}
                                          name="lastName"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs="12 " sm="12">
                                      <Form.Group className="formInputs">
                                        <Form.Label>{t("Email")}</Form.Label>
                                        <Form.Control
                                          onChange={this.handleForm}
                                          required
                                          type="email"
                                          value={this.state.contactEmail}
                                          name="contactEmail"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xs="12 " sm="12">
                                      <Form.Group className="formInputs">
                                        <Form.Label>{t("Password")}</Form.Label>
                                        <Form.Control
                                          type="password"
                                          maxLength="10"
                                          onChange={this.handleForm}
                                          onInput={(e) =>
                                            (e.target.value =
                                              e.target.value.slice(0, 10))
                                          }
                                          required
                                          placeholder={t("PassWord")}
                                          value={this.state.passWord}
                                          name="passWord"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xs="12 " sm="12">
                                      <Form.Group className="formInputs">
                                        <Form.Label>
                                          {t("Phone Number")}
                                        </Form.Label>
                                        <Form.Control
                                          type="number"
                                          maxLength="10"
                                          onChange={this.handleForm}
                                          onInput={(e) =>
                                            (e.target.value =
                                              e.target.value.slice(0, 10))
                                          }
                                          required
                                          placeholder={t("Phone Number")}
                                          value={this.state.contactNumber}
                                          name="contactNumber"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                                // <div>
                                //   <Row>
                                //     <Col xs="12 " sm="12">
                                //       <Form.Group className="formInputs">
                                //         <Form.Label>{t("Name")}</Form.Label>
                                //         <Form.Control
                                //           onChange={this.handleFormWithHandle}
                                //           required
                                //           type="text"
                                //           value={this.state.contactName}
                                //           name="contactName"
                                //         />
                                //       </Form.Group>
                                //     </Col>
                                //   </Row>

                                //   <Row>
                                //     <Col xs="12 " sm="12">
                                //       <Form.Group className="formInputs">
                                //         <Form.Label>
                                //           {t("Email address")}
                                //         </Form.Label>
                                //         <Form.Control
                                //           onChange={this.handleForm}
                                //           required
                                //           type="email"
                                //           value={this.state.contactEmail}
                                //           name="contactEmail"
                                //         />
                                //       </Form.Group>
                                //     </Col>
                                //   </Row>
                                //   <Row>
                                //     <Col xs="12 " sm="12">
                                //       <Form.Group className="formInputs">
                                //         <Form.Label>
                                //           {t("Business Name")}
                                //         </Form.Label>
                                //         <Form.Control
                                //           onChange={this.handleFormWithHandle}
                                //           required
                                //           type="text"
                                //           value={this.state.businessName}
                                //           name="businessName"
                                //         />
                                //       </Form.Group>
                                //     </Col>
                                //   </Row>

                                //   <Row>
                                //     <Col xs="12 " sm="12">
                                //       <Form.Group className="formInputs">
                                //         <Form.Label>
                                //           {t("Phone Number")}
                                //         </Form.Label>
                                //         <Form.Control
                                //           type="number"
                                //           maxLength="10"
                                //           onChange={this.handleForm}
                                //           onInput={(e) =>
                                //             (e.target.value =
                                //               e.target.value.slice(0, 10))
                                //           }
                                //           required
                                //           placeholder={t("Phone Number")}
                                //           value={this.state.contactNumber}
                                //           name="contactNumber"
                                //         />
                                //       </Form.Group>
                                //     </Col>
                                //   </Row>

                                //   <Row>
                                //     <Col xs="12 " sm="12">
                                //       <Form.Group className="formInputs">
                                //         <Form.Label>
                                //           {t("Product Category")}
                                //         </Form.Label>
                                //         <br />
                                //         <textarea
                                //           className="formInputsDescriptions"
                                //           name="jobType"
                                //           rows="3"
                                //           cols="50"
                                //           maxlength="700"
                                //           value={this.state.jobType}
                                //           onChange={this.handleForm}
                                //         ></textarea>
                                //       </Form.Group>
                                //     </Col>
                                //   </Row>
                                // </div>
                              )}
                              <Row>
                                <Col xs="12 " sm="12" lg={12} md={12}>
                                  <button
                                    className="formInputs"
                                    style={{ width: "100%" }}
                                    onClick={this.submitSupport}
                                  >
                                    {t("APPLY")}
                                  </button>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Form>
                        </div>
                      </Card>
                    </div>
                  )}
                </BlockUi>
              </Grid>
            </Grid>
            {this.state.success && (
              <div>
                <Grid container justify="center">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Card className="">
                      <Card.Body>
                        <Row>
                          <Col xs="12 " sm="4">
                            <ThumbUpAlt className="confirmBoxIcon" />
                          </Col>
                        </Row>

                        <br />
                        <br />
                        <Row>
                          <Col xs="12 " sm="12">
                            <p>
                              {t(
                                "You've been added to our waitlist Thanks for submitting your info. Once our team has a chance to review your details, we’ll reach out to set up your shop on Yuukke. If you want add more details about your brand to share with our team, you can write to us support@yuukke.com."
                              )}
                            </p>
                          </Col>
                        </Row>
                        <br />
                        <button
                          className="formInputss outlinedBtn"
                          style={{ marginBottom: "15px", marginRight: "10px" }}
                          onClick={this.backtohome}
                        >
                          {t("BACK TO HOME")}
                        </button>
                      </Card.Body>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            )}
          </Container>
        </div>
      </>
    );
  }

  backtoCourseList() {
    this.props.backtoCourseLists();
  }

  backtohome() {
    this.props.history.push(`/${this.props.workspaceType}/home`);
  }

  block(blocking) {
    this.setState({ blocking: blocking });
  }
  handleFormWithHandle = (event) => {
    if (/^[A-Za-z\s]+$/.test(event.target.value) || event.target.value == "") {
      this.setState({ [event.target.name]: event.target.value });
    }
  };
  handleForm(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onChange(value) {
    console.log("Captcha value:", value);
  }
  submitSupport(e) {
    e.preventDefault();
    e.stopPropagation();
    var valEmail = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$");
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
    } else {
      if (this.state.contactName === "") {
        toast.warn("Please enter valid name");
      } else if (!this.state.contactEmail.match(valEmail)) {
        toast.warn("Please enter valid email");
      } else if (this.state.businessName == "") {
        toast.warn("Please enter business name");
      } else if (this.state.businessName.length > 50) {
        toast.warn("Please enter valid business Name");
      } else if (this.state.contactNumber === "") {
        toast.warn("Please enter valid Phone Number");
      } else if (this.state.jobType == "") {
        toast.warn("Please enter valid product category");
      } else {
        let value = {
          userName: this.state.contactName,
          email: this.state.contactEmail,
          businessName: this.state.businessName,
          phoneNumber: this.state.contactNumber,
          productCategory: this.state.jobType,
          formType: "market_place",
        };
        ProfileService.saveCourseSupport(value)
          .then((response) => {
            toast.success("Details saved successfully");
            this.setState({ success: true });
            this.block(false);
          })
          .catch((error) => {
            console.log(error);
            this.block(false);
            toast.error("Error in saving issue");
          });
      }
    }
  }
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(SubscriberForm)));
