import { useState } from "react";
import { Card, Modal } from "react-bootstrap";
import CSVReader from "react-csv-reader";
import { withRouter } from "react-router";
import { encryptData } from "../../utils";

const Encoder = () => {
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };
  const [csvList, seCsvList] = useState([]);
  const handleDrop = (data, info) => {
    console.log("data", data);
    console.log("info", info);
    const getData = data.map((item) => {
      console.log("item", item.id);
      return { id: item.id, encryptData: encryptData(item.id + "$") };
    });
    seCsvList(getData);
    console.log("getData", getData);
    // downloadCsv();
  };
  const generateCsvContent = () => {
    const data = [
      { id: 1, name: "Alice", age: 25 },
      { id: 2, name: "Bob", age: 30 },
      { id: 3, name: "Charlie", age: 35 },
    ];
    const headers = ["Id", "EncryptData"];
    const rows = csvList.map((row) => [row.id, row.encryptData]);

    // Combine headers and rows
    const csvArray = [headers, ...rows];

    // Convert array to CSV string
    const csvContent = csvArray
      .map((row) => row.join(",")) // Join columns with commas
      .join("\n"); // Join rows with new lines

    return csvContent;
  };
  const downloadCsv = () => {
    const csvContent = generateCsvContent();

    // Create a Blob from CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.download = "yuukke_encode.csv"; // File name for the CSV

    // Append link to the body, trigger click, and remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Modal
      show="true"
      onHide={() => {}}
      style={{
        background: "#000940",
      }}
    >
      <Card className="profileCard align-items-center p-3 mt-5 mb-5">
        <h5 className="mb-5">
          Upload Your Csv and Get encrypt Id. That csv should contain unique id
          in id parameters.
        </h5>
        <CSVReader
          onDrop={handleDrop}
          cssClass="csv-button"
          parserOptions={papaparseOptions}
          onFileLoaded={(data) => handleDrop(data)}
        />
        {csvList?.length > 0 && (
          <p
            className="mb-3 mt-5 c-pointer"
            style={{ textDecoration: "underline", color: "brown" }}
            onClick={() => downloadCsv()}
          >
            Download Csv Data -
            <span className="badge badge-info ml-2">
              {csvList.length} records present
            </span>
          </p>
        )}
      </Card>
    </Modal>
  );
};

export default withRouter(Encoder);
